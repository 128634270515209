import React, { useEffect, useState } from "react";
import "./Header.css";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "./Skool_First_Logo.png";
import * as Constants from "../../action/appAction";
import * as Service from "../../services/index";
import { getData } from "../../api/index";
import { connect } from "react-redux";

const SignupHeader = (props) => {
  const { LoadImage } = props;
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = Service.baseUrl + "/" + LoadImage?.document?.path;
    document.title = LoadImage?.name;
  }, [LoadImage]);

  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader">
          <Toolbar>
            <Grid container spacing={10}>
              <Grid item xs={10} md={10}>
                <div className="logoformatting">
                  {LoadImage?.document && LoadImage?.document?.path && (
                    <img
                      src={`${Service.baseUrl}/${LoadImage?.document?.path}`}
                      alt=""
                      className="image_hader"
                    />
                  )}
                </div>
              </Grid>

              <Grid item xs={2} md={2}></Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  LoadImage: getData(state, Constants.LOADIMAGE),
});

export default connect(mapStateToProps, null)(SignupHeader);
