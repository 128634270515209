import React, { useState, useEffect } from "react";
import "./SignIn.css";
import HOC2 from "../../Hoc2.jsx";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";

import {
  blankValidator,
  loadComponent,
  onScriptLoad,
  showNotificationMsz,
} from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useApolloClient } from "@apollo/client";
import logo from "../../Header/Skool_First_Logo.png";
//------------api calls ----------------------
import { useMutation } from "@apollo/react-hooks";
import {
  FETCH_USER,
  Get_Academic_session,
  getOrganizationImage,
  baseUrl,
  getGatWay,
} from "../../../services/index";
//------------redux----------------------
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
const config = require("../../../config/default.json");

const SignIn = ({ dispatch, navigate }) => {
  //-----------------------global id------------------------
  let navigation = navigate;
  //-----------------------api calls------------------------
  const client = useApolloClient();
  const [FETCH_USERHOOK, {}] = useMutation(FETCH_USER);
  //---------------------local state ----------------------
  const grecaptchaObject = window.grecaptcha;

  const [showPassword, setshowPassword] = useState(false);
  const [loginid, setLoginid] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginidError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordLengthError, setpasswordLengthError] = useState(false);
  const [schoolName, setschoolName] = useState("");
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isloading, setisloading] = useState(false);
  //----------------------methods ----------------------

  const something = (event) => {
    if (event.keyCode === 13) {
      loginHandler();
    }
  };
  const getGateWayHandler = async (insId) => {
    try {
      const gateWay = await client.query({
        query: getGatWay,
        variables: {
          institution_id: insId,
          gateway_name: config.config.gateWayName,
        },
      });
      if (gateWay.data && gateWay.data.getGatWay) {
        const { getGatWay } = gateWay.data;

        let url = `https://${config.config.paytm_hostname}/merchantpgpui/checkoutjs/merchants/${getGatWay.secret}.js`;
        const loaded = await loadComponent(url);
        if (loaded) onScriptLoad();
      }
    } catch (error) {}
  };

  const getAcadimSessionDetails = async (institution_id) => {
    try {
      setisloading(true);
      // to imp paytm gateway initials...
      await getGateWayHandler(institution_id);

      const { data } = await client.query({
        query: Get_Academic_session,
        variables: {
          institution_id,
        },
      });
      if (!data.getAcademicSession.id) {
        alert("There is no active session");
        setisloading(false);
        dispatch({
          type: Constants.ACADAMIC_DATA,
          state: { id: 0 },
          key: Constants.ACADAMIC_DATA,
        });
      } else {
        dispatch({
          type: Constants.ACADAMIC_DATA,
          state: data.getAcademicSession,
          key: Constants.ACADAMIC_DATA,
        });
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const loginHandler = async () => {
    try {
      if (!blankValidator(loginid)) {
        setLoginidError(true);
      }
      if (!blankValidator(password)) {
        setPasswordError(true);
        return;
      }
      if (!recaptchaValue && config.config.displaycaptcha) {
        setRecaptchaErr(true);
        return;
      }
      setisloading(true);
      const response = await FETCH_USERHOOK({
        variables: {
          loginid,
          password,
        },
      });
      const loginResponse = response.data.login;
      const { tokenid, usertype, institution_id, message } = loginResponse;
      if (tokenid) {
        if (loginResponse.userData && loginResponse.userData.length > 0) {
          loginResponse["userid"] = loginResponse.userData[0].user_id;
        } else {
          alert("Please conatct to admin for assign class and section ");
          setisloading(false);
          return;
        }
      } else {
        showNotificationMsz(message, "danger");
        setisloading(false);
        return;
      }

      dispatch({
        type: Constants.IS_AUTHENTICATED,
        state: true,
        key: Constants.AUTHENTICATION,
      });
      dispatch({
        type: Constants.USER_INFO,
        state: loginResponse,
        key: Constants.TOKEN,
      });
      if (tokenid) {
        if (usertype === "SUPERADMIN") {
          navigation("/organisationSetup");
        } else if (usertype === "ADMIN") {
          navigation("/institutionSetup");
        } else if (usertype === "TEACHER" || usertype === "Teacher") {
          await getAcadimSessionDetails(institution_id);
          navigation("/teacherHomePage");
        } else if (usertype === "STUDENT") {
          await getAcadimSessionDetails(institution_id);
          navigation("/StudentMange");
        } else if (usertype === "PARENT") {
          await getAcadimSessionDetails(institution_id);
          navigation("/ParentMange");
        } else {
          await getAcadimSessionDetails(institution_id);
          navigation("/employeeHomePage");
        }
      } else {
        showNotificationMsz(message, "danger");
      }
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  useEffect(() => {
    if (window.location.href) {
      getWindowLoadData();
    }
  }, []);

  const getWindowLoadData = () => {
    setisloading(true);
    try {
      client
        .query({
          query: getOrganizationImage,
          variables: {
            url: window.location.href,
          },
        })
        .then(async (response) => {
          await setschoolName(response.data?.getOrganizationImage?.name);

          await dispatch({
            type: Constants.LOADIMAGE,
            state: response.data.getOrganizationImage,
            key: Constants.LOADIMAGE,
          });
          setisloading(false);
        })
        .catch((e) => {
          console.log("catch error", e);
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
      setisloading(false);
    }
  };
  var callback = function () {
    console.log("Done!!!!");
  };
  return (
    <div>
      <div className="main_back_color">
        <div className="main_div_Sigin">
          <div className="form_width">
            <p className="signupheading">{schoolName && schoolName}</p>
            {/* <p className="sign_powered_by_font">
              Powered by <img src={logo} alt="no_image" /> SkoolFirst™
            </p> */}
            <div className="main_padding_top_bottom">
              <div className=" inputfiledformatting ">
                <TextField
                  id="standard-basic"
                  label="Login Id"
                  className="MuiFormControl-fullWidth"
                  value={loginid}
                  autoComplete="off"
                  onKeyDown={(e) => something(e)}
                  onChange={(e) => {
                    setLoginidError(false);
                    setLoginid(e.target.value);
                  }}
                />

                {loginError && (
                  <p className="text-danger text-left">Please enter user id</p>
                )}
              </div>

              <div className="inputfiledformatting margin_input_filed mt-2">
                <FormControl className="MuiFormControl-fullWidth">
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => {
                      setPasswordError(false);
                      setpasswordLengthError(false);
                      setPassword(e.target.value.trim());
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowPassword(!showPassword)}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                {passwordError && (
                  <p className="text-danger text-left">Enter the Pasword</p>
                )}
                {passwordLengthError && (
                  <p className="text-danger text-left">
                    Pasword must have 8 characters
                  </p>
                )}
              </div>
              <div className="inputfiledformatting">
                <p className="sighn_para_new_font">
                  By continuing, you agree to our Legal{" "}
                  <span
                    className="sign_page_color_font"
                    onClick={() => navigate("/termsOfUse")}
                  >
                    (Terms of Use)
                  </span>
                  , End User License Agreement
                  <span
                    className="sign_page_color_font"
                    onClick={() => navigate("/endUserAggreement")}
                  >
                    (EULA){" "}
                  </span>
                  &{" "}
                  <span
                    className="sign_page_color_font"
                    onClick={() => navigate("/privacyPolicy")}
                  >
                    Privacy Statement
                  </span>
                  .
                </p>
              </div>
              <div className=" inputfiledformatting recaptacode">
                <p>
                  {config.config.displaycaptcha && (
                    <ReCAPTCHA
                      sitekey={config.config.siteKey}
                      grecaptcha={grecaptchaObject}
                      onChange={(code) => {
                        if (recaptchErr) setRecaptchaErr(false);
                        setRecaptchaValue(code);
                      }}
                    />
                  )}
                </p>
                {recaptchErr && (
                  <p className="text-danger text-left">
                    Please validate recaptcha
                  </p>
                )}
              </div>

              <div className="inputfiledformatting mt-2">
                <Button
                  variant="contained"
                  className="login_button button_login_decoration"
                  onClick={loginHandler}
                >
                  Log In
                </Button>
              </div>
              <div className="forgotidPAss inputfiledformatting mt-3">
                <p
                  className="ancor_formatting"
                  onClick={() => navigation("/forgotPassword")}
                >
                  Forgot Password?
                </p>
                <p
                  className="ancor_formatting"
                  onClick={() => navigation("/forgotUserId")}
                >
                  Forgot Id?
                </p>
              </div>
            </div>
          </div>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};

export default connect(null, null)(HOC2(SignIn));
