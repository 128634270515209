import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { blankValidator, timeStampToDate } from "../../../utils/Validation";

const PtNoticeBoard = (props) => {
  const { institution_Name } = props;
  const [page, setPage] = React.useState(0);
  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Card>
        <div className="card_admissiondetails_height ">
          <div className="textfiled_margin mb-2">
            <div className="mt-2 mybarwidth"></div>

            <Grid className="grid_main_div gapfromanotherinput mb-3">
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Search"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>

              <Grid item md={5}></Grid>
            </Grid>
            <div className="">
              <div className="table_foramtitng mt-3">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className="table_header">
                          Title
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Subject
                        </StyledTableCell>

                        <StyledTableCell align="left" className="table_header">
                          Publish Date
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Expiry Date
                        </StyledTableCell>

                        <StyledTableCell align="left" className="table_header">
                          Publisher Name
                        </StyledTableCell>

                        <StyledTableCell align="left" className="table_header">
                          Status
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Action
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? schoolnoticedata.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : schoolnoticedata
                      ).map((row) => (
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            {!blankValidator(row.title) ? (
                              "No Data"
                            ) : (
                              <span>{row.title.substring(0, 20)}</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell scope="row" align="left">
                            {!blankValidator(row.subject) ? (
                              "No Data"
                            ) : (
                              <span>{row.subject.substring(0, 20)}</span>
                            )}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {timeStampToDate(row.publish_date)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!blankValidator(row.expiry_date) ? (
                              "No Data"
                            ) : (
                              <span>{timeStampToDate(row.expiry_date)}</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!blankValidator(row.admin) ? (
                              "No Data"
                            ) : (
                              <span>
                                {!blankValidator(row.admin.name) ? (
                                  "No Data"
                                ) : (
                                  <span>
                                    {!blankValidator(
                                      row.admin.name.first_name
                                    ) ? (
                                      ""
                                    ) : (
                                      <span>{row.admin.name.first_name}</span>
                                    )}{" "}
                                    {!blankValidator(
                                      row.admin.name.middle_name
                                    ) ? (
                                      ""
                                    ) : (
                                      <span>{row.admin.name.middle_name}</span>
                                    )}{" "}
                                    {!blankValidator(
                                      row.admin.name.last_name
                                    ) ? (
                                      ""
                                    ) : (
                                      <span>{row.admin.name.last_name}</span>
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!blankValidator(row.status) ? (
                              "No Data"
                            ) : (
                              <span>{row.status}</span>
                            )}
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            className="row_details_color"
                            onClick={() =>
                              props.navigate("/noticeDetail", {
                                state: {
                                  item: row,
                                  type: "SCHOOLNOTICE",
                                  institution_Name: institution_Name,
                                },
                              })
                            }
                          >
                            View
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    true
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={schoolnoticedata.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default PtNoticeBoard;
