import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Loder from "../../Loder/Loder";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { baseUrl } from "../../../services/index.js";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  CapitalizeFirstFn,
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation";


//redux
import * as Service from "../../../services/index";

import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const ReviewMyLeaves = (props) => {
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const [UploadFileArr, setUploadFileArr] = useState([]);
  const parse = require("html-react-parser");

  const [Approve_LeaveRequestByApproverIdAndId, {}] = useMutation(
    Service.Approve_LeaveRequestByApproverIdAndId
  );
  const [Reject_Student_Leave, {}] = useMutation(Service.Reject_Student_Leave);

  // const [filegroup] = useState([
  //   { name: "abc.doc" },
  //   { name: "xyz.doc" },
  //   { name: "aaa.doc" },
  //   { name: "ddd.doc" },
  //   { name: "xxx.doc" },
  // ]);
  const [notes, setNotes] = useState("");

  const [leaveType, setLeaveType] = useState("");

  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [openrejectdialogConfimation, setopenrejectdialogConfimation] =
    useState(false);
  const [studentLeaves, setstudentLeaves] = useState(false);

  const [subject, setSubject] = useState("");
  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [isloading, setisloading] = useState(false);

  const [days, setDays] = useState("");
  const [status, setstatus] = useState("");
  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  useEffect(() => {
    let leavesummarydata = props.location.state.data;

    if (leavesummarydata.subject != null) {
      setSubject(leavesummarydata.subject);
    }
    if (leavesummarydata.notes != null) {
      setNotes(leavesummarydata.notes);
    }
    if (leavesummarydata.student != null) {
      setstudentLeaves(true);
    }

    if (
      leavesummarydata.from_date != null &&
      leavesummarydata.from_date !== undefined
    ) {
      setStartDate(leavesummarydata.from_date);
    }
    if (
      leavesummarydata.to_date != null &&
      leavesummarydata.to_date !== undefined
    ) {
      setEndDate(leavesummarydata.to_date);
    }

    if (
      leavesummarydata.leave_type != null &&
      leavesummarydata.leave_type !== undefined
    ) {
      setLeaveType(leavesummarydata.leave_type);
    }

    if (leavesummarydata.days != null && leavesummarydata.days !== undefined) {
      setDays(leavesummarydata.days);
    }

    if (
      leavesummarydata.status != null &&
      leavesummarydata.status !== undefined
    ) {
      setstatus(leavesummarydata.status);
    }
    if (
      leavesummarydata.documents != null &&
      leavesummarydata.documents !== undefined
    ) {
      setUploadFileArr(leavesummarydata.documents);
    }
  }, [props]);

  const approveLeaveApi = () => {
    try {
      setisloading(true);
      Approve_LeaveRequestByApproverIdAndId({
        variables: {
          approver_id: parseInt(props.data.userid),
          id: parseInt(props.location.state.data.id),
          institution_id: parseInt(props.data.institution_id),
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.approveLeaveRequestByApproverIdAndId.message,
            "success"
          );

          setopendialogConfimation(false);
          setisloading(false);
          if (studentLeaves) {
           props.navigate("/manageLeaveRequests");
          } else {
           props.navigate("/adminManageTeacherLeave");
          }
        },
        (err) => {
          setopendialogConfimation(false);
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      console.log("t and c error", error);
      setopendialogConfimation(false);
      setisloading(false);
    }
  };

  const rejectLeaveApi = () => {
    try {
      setisloading(true);
      Reject_Student_Leave({
        variables: {
          rejector_id: parseInt(props.data.userid),
          id: parseInt(props.location.state.data.id),
          institution_id: parseInt(props.data.institution_id),
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.cancelLeaveRequestByRequestId.message,
            "success"
          );

          setopenrejectdialogConfimation(false);
          setisloading(false);

          if (studentLeaves) {
           props.navigate("/manageLeaveRequests");
          } else {
           props.navigate("/adminManageTeacherLeave");
          }
        },
        (err) => {
          setopenrejectdialogConfimation(false);
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      console.log("t and c error", error);
      setopenrejectdialogConfimation(false);
      setisloading(false);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">
                    Review Leave
                    {props.location.state.data.student === undefined ||
                    props.location.state.data.student === null ? (
                      ""
                    ) : (
                      <div>
                        {props.location.state.data.student.student_profile
                          .name === undefined ||
                        props.location.state.data.student.student_profile
                          .name === null ? (
                          " "
                        ) : (
                          <span className="font-weight-bold text-info">
                            {" "}
                            {CapitalizeFirstFn(
                              props.location.state.data.student.student_profile
                                .name.first_name
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={subject}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={startDate}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={endDate}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            {/* <div className="">
                              <label for="exampleInputEmail1">Days</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                placeholder="5"
                                days={days}
                              />
                            </div> */}
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <div class="form-group">
                            <label for="exampleFormControlTextarea1">
                              Message
                            </label>
                            <div className="textarea form-control notesDiv">
                              {parse.default(notes)}
                            </div>
                            {/* <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="lorem ipsum...."
                              value={parse.default(notes)}
                            ></textarea> */}
                          </div>
                        </div>
                        <label className="mt-2">Attachment Lists</label>
                        <div className="mb-2 attachList ml-2">
                          {UploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2 pr-2"
                              >
                                {item.name}
                              </span>
                              {/* <span className="pl-3 mr-1">
                                <i class="fa fa-times cursor"></i>
                              </span> */}
                            </div>
                          ))}
                        </div>

                        {status === "PENDING" && (
                          <Grid className="grid_main_div mt-3 ">
                            <Grid item md={8}></Grid>

                            <Grid item md={4}>
                              <div className="float-right mb-3">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn   mr-2 button_login_decoration "
                                  onClick={() => {
                                    setopendialogConfimation(true);
                                  }}
                                >
                                  Accept
                                </Button>
                              </div>
                              <div className="float-right mb-3">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn   mr-2 button_login_decoration "
                                  onClick={() => {
                                    setopenrejectdialogConfimation(true);
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Are you Sure want to Approve ?</div>
          {blankValidator(subject) && (
            <div className="pl-5 pr-5 mt-4">Leave type : {subject}</div>
          )}

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={approveLeaveApi}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openrejectdialogConfimation}
        onClose={() => {
          setopenrejectdialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenrejectdialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Are you Sure Want to Reject ? </div>
          {leaveType !== undefined &&
            leaveType !== null &&
            leaveType !== "" && (
              <div className="pl-5 pr-5 mt-4">Leave type : {leaveType}</div>
            )}

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={rejectLeaveApi}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenrejectdialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(ReviewMyLeaves)));
