import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ParentMonday from "./ParentMonday.jsx";
import moment from "moment";
import { dateFormatToShow, getFullName } from "../../../utils/Validation";

import Holiday from "../../Common/Holiday/Holiday.jsx";

const ParentStudentTimeTable = ({ location, navigate }) => {
  //-------------------global id------------------------
  const { section } = location.state.row;
  const { name } = location.state.row;
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  //-------------------local state------------------------
  let day = new Date().getDay() - 1;
  if (day < 0) {
    day = 6;
  }
  const [value, setValue] = React.useState(day);
  //-------------------methods------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={location.state.institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3">
                    <div className="Heading_bold">Timetable</div>
                    <div>
                      <span className="Heading_bold">Student Name : </span>
                      {getFullName(name)}{" "}
                    </div>
                    <div>
                      <span className="Heading_bold">Class : </span>
                      <span>
                        {" "}
                        {section.grade?.gradeName} - {section.name}
                      </span>
                    </div>
                  </div>

                  <div className="mr-3 mt-3">
                    {`${dateFormatToShow(startOfWeek)} - ${dateFormatToShow(
                      endOfWeek
                    )}`}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div>
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2 tabs_class_for_Mobile">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="Monday"
                              {...a11yProps(0)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Tuesday"
                              {...a11yProps(1)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Wednesday"
                              {...a11yProps(2)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Thursday"
                              {...a11yProps(3)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Friday"
                              {...a11yProps(4)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Saturday"
                              {...a11yProps(5)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Sunday"
                              {...a11yProps(6)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                          </Tabs>
                        </div>

                        <div className="Pannel_padding_hidden">
                          <TabPanel value={value} index={0}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={1}
                                name={"Monday"}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={1}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={2}
                                name={"Tuesday"}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={2}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={3}
                                name={"Wednesday"}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={3}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={4}
                                name={"Thursday"}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={4}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={5}
                                name={"Friday"}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={5}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={6}
                                name={"Saturday"}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={6}>
                            <div>
                              <ParentMonday
                                sectionId={section.id}
                                day={7}
                                name={"Sunday"}
                              />
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default HOC1(ParentStudentTimeTable);
