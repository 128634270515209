import { useApolloClient } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import {
  blankValidator,
  getFullName,
  getIntValue,
  getValue,
} from "../../../utils/Validation.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import SubText from "../../Common/SubText.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";

const ViewPassengers = (props) => {
  const [search, setSearch] = useState("");

  let data = props.location.state.data;

  const [value, setValue] = useState(-1);
  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [stopid, setstopid] = useState(-1);

  const [endstopid, setendstopid] = useState(-1);
  const classes = useStyles();

  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  let Organization_Name = props.data.institution_name;

  const [DropRouteArr, setDropRouteArr] = useState([]);
  const [StudentPicupRouteArr, setStudentPicupRouteArr] = useState([]);
  const [StudentDropRouteArr, setStudentDropRouteArr] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const client = useApolloClient();
  useEffect(() => {
    getStopName();
    getStudentList();
  }, []);
  const dropRouteFilterArr = DropRouteArr.filter((item) => {
    if (value == -1) {
      return getFullName(item?.studentName)
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
        ? true
        : false;
    }

    if (getValue(data.route_type) === "DROP") {
      return (
        getIntValue(item.transport_stop.id) === value &&
        (getFullName(item?.studentName)
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
          ? true
          : false)
      );
    } else if (getValue(data.route_type) === "PICK") {
      return (
        getIntValue(item.transport_pickup.id) === value &&
        (getFullName(item?.studentName)
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
          ? true
          : false)
      );
    } else {
      return true;
    }
  });

  const getStopName = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Stop_Route_Name,
          variables: {
            transport_route_id: parseInt(data.id),
          },
        })
        .then((response) => {
          let temp = [];
          setisloading(false);
          for (
            let index = 0;
            index < response.data.getTransportStop.length;
            index++
          ) {
            temp.push({
              label: response.data.getTransportStop[index].stop_name,
              value: response.data.getTransportStop[index].id,
            });
          }

          setRouteData(temp);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const getStudentList = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_student_list_transport_route_id,
          variables: {
            transport_route_id: parseInt(data.id),
            status: blankValidator(data?.route_type) ? data?.route_type : "",
          },
        })
        .then((response) => {
          try {
            let tempArr = [];
            let rollNoArray = response.data.getStudentListByTransportRouteId;
            if (rollNoArray) {
              let sortedArr = rollNoArray.sort((a, b) => {
                return parseInt(a.roll_number) - parseInt(b.roll_number);
              });
              sortedArr.map((item) => {
                tempArr.push({
                  isSelected: true,
                  roll_number: item.roll_number,
                  id: item.id,
                  studentName: item?.student_profile?.name,
                  status: item.status,
                  section: item.section,
                  transport_stop: item.transport_stop,
                  transport_pickup: item.transport_pickup,
                });
              });
              setDropRouteArr(tempArr);
            }

            setisloading(false);
          } catch (error) {
            setisloading(false);
            console.log("catch err", error);
          }
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const handleChange = (newValue) => {
    try {
      setValue(parseInt(newValue));
    } catch (error) {
      setValue(-1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="ml-3 mt-3">
                  <div className="d-flex justify-content-between">
                    <div className="Heading_bold">View passengers</div>
                    <div style={{ marginRight: 5, marginTop: 5 }}>
                      <Button
                        variant="contained"
                        className="add_new_query_btn button_login_decoration mr-2"
                        onClick={() =>
                          props.navigate("/assignStudent", {
                            state: {
                              routeInfo: {
                                id: data.id,
                                routeName: data.route_name,
                                rowdata: data,
                              },
                            },
                          })
                        }
                      >
                        Assign Student
                      </Button>
                    </div>
                  </div>
                  {/* -------------------child compo to show subheader value----------- */}
                  {/* --------------subtext aligne center with style----------------- */}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontWeight: "600" }}>Route Name :</p>
                    <p style={{ textTransform: "capitalize" }}>
                      {data && data.route_name}
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontWeight: "600" }}>Route Type :</p>
                    <p style={{ textTransform: "capitalize" }}>
                      {data && data.route_type}
                    </p>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="p-3">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <div className="text_filed_heading">Stop Name</div>
                          <div className="mt-1">
                            <select
                              class="form-control"
                              value={value}
                              onChange={(e) => handleChange(e.target.value)}
                            >
                              <option value={-1}>Select Stop</option>
                              {RouteData.length > 0 &&
                                RouteData.map((item) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <input
                            style={{ marginTop: 25 }}
                            type="text"
                            className="form-control ml-2"
                            autoComplete="off"
                            placeholder="Search Name"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item md={4}></Grid>
                      </Grid>

                      <div className="table_foramtitng mt-3 mb-3">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Roll Number
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Name
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Class
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Section
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Stop Name
                                </StyledTableCell>

                                {/* <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Status
                                </StyledTableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? dropRouteFilterArr.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : dropRouteFilterArr
                              ).map((row) => (
                                <StyledTableRow>
                                  <StyledTableCell align="left">
                                    {!blankValidator(row.roll_number) ? (
                                      ""
                                    ) : (
                                      <span>{row.roll_number}</span>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    <span>{getFullName(row.studentName)}</span>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {!blankValidator(row.section) ? (
                                      ""
                                    ) : (
                                      <span>
                                        {row.section?.grade?.gradeName}
                                      </span>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {!blankValidator(row.section) ? (
                                      ""
                                    ) : (
                                      <span>{row.section.name}</span>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {getValue(data.route_type) === "DROP" &&
                                      row?.transport_stop?.stop_name}
                                    {getValue(data.route_type) === "PICK" &&
                                      row?.transport_pickup?.stop_name}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={dropRouteFilterArr.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ViewPassengers));
