import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Transport.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, routename, stopname, routemap) {
  return { name, routename, stopname, routemap };
}

const rows = [
  createData("Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum", "View"),
  createData("Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum", "View"),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
function TransportDetail(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <div className="main_full_width">
      <div className="main_back_color1">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Transport Details
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div>Pick Up</div>
                        <div className="mt-2 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell className="table_header">
                                    {/* Title */}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Stop Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Map
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : rows
                                ).map((row) => (
                                  <StyledTableRow key={row.title}>
                                    <StyledTableCell padding="checkbox">
                                      <Checkbox
                                      //   checked=""
                                      //   inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.routename}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.stopname}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {row.routemap}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            {/* <TablePagination true
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
                          </TableContainer>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div  mb-3">
                              <Button
                                variant="contained"
                                className="button_width btn_colr_and_width"
                              >
                                Mark Absent
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div>Drop</div>
                        <div className="mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell className="table_header">
                                    {/* Title */}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Stop Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Map
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : rows
                                ).map((row) => (
                                  <StyledTableRow key={row.title}>
                                    <StyledTableCell padding="checkbox">
                                      <Checkbox
                                      //   checked=""
                                      //   inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.routename}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.stopname}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {row.routemap}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            {/* <TablePagination true
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
                          </TableContainer>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div  mb-3">
                              <Button
                                variant="contained"
                                className="button_width btn_colr_and_width"
                              >
                                Mark Absent
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default (HOC1(TransportDetail));
