import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./Leave.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder";
import {
  difference_of_days,
  blankValidator,
  showNotificationMsz,
  serverDateFormat,
  getFullName,
} from "../../../utils/Validation";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ManageLeaveRequest = (props) => {
  let institution_id = parseInt(props.data.institution_id);
  const [Approve_LeaveRequestByApproverIdAndId, {}] = useMutation(
    Service.Approve_LeaveRequestByApproverIdAndId
  );
  const [Reject_Student_Leave, {}] = useMutation(Service.Reject_Student_Leave);

  const [isloading, setisloading] = useState(false);

  const client = useApolloClient();

  const [allLeaveRequests, setallLeaveRequests] = useState([]);

  /// Approve dialof variable
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [approveid, setapproveid] = useState("");
  // const [approvename, setapprovename] = useState("");
  const [approvefromdate, setapprovefromdate] = useState("");
  const [approvetodate, setapprovetodate] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  /// reject dialof variable
  const [
    openrejectdialogConfimation,
    setopenrejectdialogConfimation,
  ] = useState(false);
  const [rejectid, setrejectid] = useState("");
  const [subject, setsubject] = useState("");
  const [rejectfromdate, setrejectfromdate] = useState("");
  const [rejecttodate, setrejecttodate] = useState("");
  // const parse = require("html-react-parser");

  useEffect(() => {
    leaveRequestlist();
  }, []);
  const leaveRequestlist = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Student_Leaves,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          if (response.data.getStudentLeaves.length !== 0) {
            setallLeaveRequests(response.data.getStudentLeaves.reverse());
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const approveLeave = (item) => {
    setapproveid(item.id);
    setsubject(item.subject);
    setapprovefromdate(item.from_date);
    setapprovetodate(item.to_date);
    setopendialogConfimation(true);
  };

  const approveLeaveApi = (ID) => {
    try {
      setisloading(true);
      Approve_LeaveRequestByApproverIdAndId({
        variables: {
          approver_id: parseInt(props.data.userid),
          id: parseInt(approveid),
          institution_id: parseInt(props.data.institution_id),
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.approveLeaveRequestByApproverIdAndId.message,
            "success"
          );

          setopendialogConfimation(false);
          setisloading(false);
          leaveRequestlist();
          setIsUpdated(true);
        })
        .catch((error) => {
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
      setopendialogConfimation(false);
      setisloading(false);
    }
  };

  const rejectLeave = (item) => {
    setrejectid(item.id);
    setsubject(item.subject);
    setrejectfromdate(item.from_date);
    setrejecttodate(item.to_date);
    setopenrejectdialogConfimation(true);
  };

  const rejectLeaveApi = () => {
    try {
      setisloading(true);
      Reject_Student_Leave({
        variables: {
          rejector_id: parseInt(props.data.userid),
          id: parseInt(rejectid),
          institution_id: parseInt(props.data.institution_id),
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.cancelLeaveRequestByRequestId.message,
            "success"
          );

          setopenrejectdialogConfimation(false);
          setisloading(false);
          leaveRequestlist();
          setIsUpdated(true);
        })
        .catch((error) => {
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
      setopenrejectdialogConfimation(false);
      setisloading(false);
    }
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchS, setsearchS] = useState("");
  const [search, setsearch] = useState("");

  const filterLeaves = allLeaveRequests.filter((event) => {
    return (
      getFullName(event?.student?.student_profile?.name)
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 &&
      event.status.toLowerCase().indexOf(searchS.toLowerCase()) !== -1
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Manage Leave Requests
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search By Name"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                  setsearch(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search By Status"
                                autoComplete="off"
                                value={searchS}
                                onChange={(e) => {
                                  setsearchS(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={6}></Grid>
                        </Grid>
                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Student Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class/Section
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End End
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Days
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? filterLeaves.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : filterLeaves
                                ).map((row, index) => {
                                  let days = difference_of_days(
                                    row.from_date,
                                    row.to_date
                                  );
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.student) ? (
                                          ""
                                        ) : (
                                          <span>
                                            {getFullName(
                                              row.student?.student_profile?.name
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(
                                          row.student.section
                                        ) ? (
                                          ""
                                        ) : (
                                          <div>
                                            {!blankValidator(
                                              row.student.section.grade
                                            ) ? (
                                              ""
                                            ) : (
                                              <span>
                                                {!blankValidator(
                                                  row.student.section.grade
                                                    .gradeName
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {
                                                      row.student.section.grade
                                                        .gradeName
                                                    }
                                                    -{row.student.section.name}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {row.from_date === undefined ||
                                        row.from_date === null ? (
                                          ""
                                        ) : (
                                          <span>
                                            {serverDateFormat(row.from_date)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.to_date === undefined ||
                                        row.to_date === null ? (
                                          ""
                                        ) : (
                                          <span>
                                            <span>
                                              {serverDateFormat(row.to_date)}
                                            </span>
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(days) ? (
                                          " "
                                        ) : (
                                          <span>{days}</span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.status}
                                      </StyledTableCell>

                                      <StyledTableCell align="center">
                                        <div className="d-flex justify-content-center">
                                          {row.status === "APPROVED" && (
                                            <div>
                                              <span
                                                className="pr-2 row_details_color"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/reviewMyLeave",
                                                    {
                                                      state: {
                                                        userType: "TEACHER",
                                                        data: row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                            </div>
                                          )}
                                          {row.status === "PENDING" && (
                                            <div>
                                              <span
                                                className="pr-2 row_details_color"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/reviewMyLeave",
                                                    {
                                                      state: {
                                                        data: row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                              |
                                              <span
                                                className="pr-2 pl-2 row_details_color"
                                                onClick={() =>
                                                  approveLeave(row)
                                                }
                                              >
                                                Approve
                                              </span>
                                              |
                                              <span
                                                className="pl-2 row_details_color"
                                                onClick={() => rejectLeave(row)}
                                              >
                                                Reject
                                              </span>
                                            </div>
                                          )}
                                          {row.status === "REJECTED" && (
                                            <div>
                                              <span
                                                className="pr-2 row_details_color"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/reviewMyLeave",
                                                    {
                                                      state: {
                                                        data: row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filterLeaves.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-3">
            Are you sure want to approve leave ?
          </div>
          <div className="pl-5 pr-5 mt-4">
            <p style={{ textTransform: "capitalize" }}>{subject}</p>
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => approveLeaveApi(approveid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openrejectdialogConfimation}
        onClose={() => {
          setopenrejectdialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenrejectdialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-3">
            Are you sure want to cancel your leave ?
          </div>
          <div className="pl-5 pr-5 mt-4">{subject}</div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={rejectLeaveApi}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenrejectdialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageLeaveRequest));
