import React, { useState } from "react";
import "./Attendance.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const AttendanceViewDayAdminTeacher = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [checkvalue, setCheckvalue] = useState(false);

  const HandleCheckbox = (e) => {
    setCheckvalue(e.target.checked);
  };
  const [attendancedata] = useState([
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
    { id: "20", name: "Mark Willy", dayspresent: "1", totaldays: "1" },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Attendance</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={8}>
                            <div className="mt-5">
                              <div>
                                <span>
                                  <CheckBoxComponent
                                    label="Day"
                                    cssClass="e-custom"
                                    checked={checkvalue}
                                    onClick={(e) => HandleCheckbox(e)}
                                  />
                                </span>
                                <span className="ml-4">
                                  <CheckBoxComponent
                                    label="Month"
                                    cssClass="e-custom"
                                  />
                                </span>
                                <span className="ml-4">
                                  <CheckBoxComponent
                                    label="Year"
                                    cssClass="e-custom"
                                  />
                                </span>
                              </div>
                            </div>
                          </Grid>

                          <Grid item md={4}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={4}>
                            <label for="exampleInputEmail1">Date</label>{" "}
                            <input
                              type="date"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Search Events"
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item md={6}></Grid>
                          <Grid item md={2}>
                            <div className="mt-4 mr-4">
                              <div className="pl-4 pr-1 pt-1 pb-1 card_invoicedetails_border">
                                <span className="mr-2">
                                  <i class="fa fa-file-excel-o"></i>
                                </span>
                                <span className="mr-2">
                                  <i class="fa fa-file-pdf-o"></i>
                                </span>
                                <span>
                                  <i class="fa fa-print"></i>
                                </span>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text-right mt-1 mb-1 mr-3">
                          <span className="mr-2">Absent</span>
                          <span>Holidays</span>
                        </div>
                        <div className="mt-3 mb-3 card_invoicedetails_border">
                          <div className="float-right mt-1 mb-1 mr-3">
                            Day - 14/04/2021(Wednesday)
                          </div>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell className="table_header">
                                    Roll Number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Days Present
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Total Days
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    1
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  ></StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  ></StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  ></StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  ></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? attendancedata.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : attendancedata
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.dayspresent}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {row.totaldays}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="attendance_cell_width"
                                    ></StyledTableCell>

                                    <StyledTableCell align="left"></StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={attendancedata.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default (HOC1(AttendanceViewDayAdminTeacher));
