import React from "react";

const ClassOverViewLeft = (props) => {
  return (
    <div>
      {props.classArr &&
        props.classArr.map((row) => <p>{row.gradeName && row.gradeName}</p>)}
    </div>
  );
};

export default ClassOverViewLeft;
