import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  blankValidator,
  CapitalizeFirstFn,
  timeStampToDate,
  timeStampDateToOnlytimewithoutTimeDiff,
} from "../../../utils/Validation";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function AdminExamStudentList(props) {
  const { data, sectionId, gradeId } = props.location.state;

  //name instituition

  const classes = useStyles();
  const client = useApolloClient();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [openRemarkdailog, setOpenRemarkdailog] = useState(false);
  const [examSubjectArr, setexamSubjectArr] = useState([]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let exam_id = 0;

  if (data) {
    exam_id = parseInt(data.id);
  }

  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getSubjectExamTimeTable = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GetExam_Subject_And_Timetable,
            variables: {
              exam_id,
            },
          })
          .then((response) => {
            if (response.data.getExamSubjectandTimetable.length !== 0) {
              setexamSubjectArr(response.data.getExamSubjectandTimetable);
            }
            // setAssignmentarr(response.data.getAssignment);
            setisloading(false);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getSubjectExamTimeTable();
  }, [client, exam_id]);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    {props.location.state.pagetype === "View Result" ? (
                      <span>View Result</span>
                    ) : (
                      <span>Examination Marks</span>
                    )}
                    {props.location.state.data === null ||
                    props.location.state.data === undefined ? (
                      ""
                    ) : (
                      <span>
                        {" "}
                        - {CapitalizeFirstFn(props.location.state.data.name)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Exam Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start Time
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End Time
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Max Marks
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Location
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    {props.location.state.pagetype ===
                                    "View Result" ? (
                                      <span>View Result</span>
                                    ) : (
                                      <span>Enter Marks</span>
                                    )}
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? examSubjectArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : examSubjectArr
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.subject.name)
                                        ? ""
                                        : CapitalizeFirstFn(row.subject.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {timeStampToDate(
                                        row.start_calendar?.datetime
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {timeStampDateToOnlytimewithoutTimeDiff(
                                        row.start_calendar?.datetime
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {timeStampDateToOnlytimewithoutTimeDiff(
                                        row.end_calendar?.datetime
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.marks)
                                        ? ""
                                        : row.marks}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {!blankValidator(row.location)
                                        ? ""
                                        : CapitalizeFirstFn(row.location.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {props.location.state.pagetype ===
                                      "View Result" ? (
                                        <span
                                          role="button"
                                          className="text-info"
                                          onClick={() =>
                                            props.navigate("/examViewResults", {
                                              state: {
                                                data: row,
                                                examName: data.name,
                                                section: sectionId,
                                                gradeId,
                                              },
                                            })
                                          }
                                        >
                                          View Result
                                        </span>
                                      ) : (
                                        <span
                                          role="button"
                                          className="text-info"
                                          onClick={() =>
                                            props.navigate("/examEnterMarks", {
                                              state: {
                                                data: row,
                                                examName: data.name,
                                                section: sectionId,
                                                gradeId: gradeId,
                                              },
                                            })
                                          }
                                        >
                                          Enter Marks
                                        </span>
                                      )}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={examSubjectArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminExamStudentList));
