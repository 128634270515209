import React from 'react'

const CountDisplay = (props) => {
    return (
        <div>
        <label className="count_display_lebel">{props.label}</label>
        <div className="total_count_border_radious">
          <p>{props.count}</p>{" "}
        </div>
      </div>
    )
}

export default CountDisplay
