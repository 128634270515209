import React, { useState, useEffect } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as Service from "../../../services/index";
import Loder from "../../Loder/Loder.jsx";

import TablePagination from "@material-ui/core/TablePagination";
import { useApolloClient } from "@apollo/client";
import userimage from "./userimage.png";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  getFullName,
  isPresent,
  newDateToDate,
} from "../../../utils/Validation.js";
import Checkbox from "@material-ui/core/Checkbox";
import { useMutation } from "@apollo/client";
const TransportDropAttendence = (props) => {
  const today_Date = new Date().toJSON().slice(0, 10);
  const { id, route_type } = props.transportData;

  // let data = props?.location?.state?.data;
  const [Teacher_Mark_Attendace_API, {}] = useMutation(
    Service.Teacher_Mark_Attendace_API
  );

  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);

  const classes = useStyles();

  // institution id for all api globally declared
  // let institution_id = parseInt(props.data.institution_id);

  //name instituition
  // let Organization_Name = props.data.institution_name;
  const [allChecked, setAllChecked] = useState(false);
  // const [DropRouteArr, setDropRouteArr] = useState([]);
  // const [StudentPicupRouteArr, setStudentPicupRouteArr] = useState([]);
  // const [StudentDropRouteArr, setStudentDropRouteArr] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const client = useApolloClient();

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_student_list_transport_route_id,
          variables: {
            transport_route_id: parseInt(id),
            start_date: newDateToDate(new Date()),
            end_date: newDateToDate(new Date()),
            status: route_type,
          },
        })
        .then((response) => {
          try {
            // let temp = [];
            let tempArr = [];
            let rollNoArray = response.data.getStudentListByTransportRouteId;
            if (rollNoArray) {
              let sortedArr = rollNoArray.sort((a, b) => {
                return parseInt(a?.roll_number) - parseInt(b?.roll_number);
              });

              sortedArr.map((item) => {
                let present__status = isPresent(today_Date, item.attendances);

                // let att_type;
                // if (item.attendances[0].attendance_type === "TRANSPORT_DROP") {
                //   att_type = "Present";
                // }

                tempArr.push({
                  isSelected: present__status === "Present" ? true : false,
                  roll_number: item?.roll_number,
                  id: item?.id,
                  studentName: item?.student_profile?.name,
                });
              });
              setRouteData(tempArr);
            }

            setisloading(false);
          } catch (error) {
            setisloading(false);
            console.log("catch err", error);
          }
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, []);
  const markStudentAttendaceHandlerDrop = () => {
    try {
      let temp = [];
      RouteData.map((item) => {
        if (item.isSelected) {
          temp.push({
            user_id: item.id,
            status: "Present",
            user_type: "STUDENT",
          });
        } else {
          temp.push({
            user_id: item.id,
            status: "Absent",
            user_type: "STUDENT",
          });
        }
      });
      if (temp.length === 0) {
        alert("Please select at lease one student");
        return;
      }
      setisloading(true);
      Teacher_Mark_Attendace_API({
        variables: {
          attendance_type:
            route_type === "PICK" ? "TRANSPORT_PICKUP" : "TRANSPORT_DROP",
          date: today_Date,
          user_id: temp,
        },
      })
        .then((response) => {
          setisloading(false);
          //   setIsUpdated(true);
        })
        .catch((error) => {
          setisloading(false);
          console.log("then error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checkBoxHandler = (id) => {
    let temp = [];
    RouteData.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      if (item.isSelected === false) {
        setAllChecked(false);
      }
      temp.push(item);
    });

    setRouteData(temp);
  };
  const allCheckHandler = () => {
    if (setRouteData.length > 0) {
      setAllChecked(!allChecked);
    }

    let temp = [];
    RouteData.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      temp.push(item);
    });
    setRouteData(temp);
  };
  return (
    <div className="p-3">
      <div className="table_foramtitng mt-3 mb-3">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" className="table_header">
                  Roll Number
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Name
                </StyledTableCell>

                <StyledTableCell align="left" className="table_header">
                  <Checkbox checked={allChecked} onChange={allCheckHandler} />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("RouteData", RouteData)}
              {(rowsPerPage > 0
                ? RouteData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : RouteData
              ).map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {!blankValidator(row.roll_number) ? (
                      ""
                    ) : (
                      <span>{row.roll_number}</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="left">
                    <span>
                      <img
                        src={userimage}
                        alt="No-profile"
                        className="img_formatting mr-2"
                      />
                      {getFullName(row.studentName)}
                    </span>
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Checkbox
                      checked={row.isSelected}
                      onChange={() => checkBoxHandler(row.id)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={RouteData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <div className="text-right">
        <Button
          variant="contained"
          className="mark_attence_button"
          onClick={markStudentAttendaceHandlerDrop}
        >
          Mark Attendance
        </Button>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(TransportDropAttendence);
