import React, { useEffect, useRef, useState } from "react";

import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import * as Service from "../../../services/index";
import {
  getFullName,
  options,
  timeStampToDateandTime,
} from "../../../utils/Validation";
import { smart_substr } from "../../../utils/Validation.js";
import CommonExamViewResult from "../../Common/Exam/CommonExamViewResult";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import SubText from "../../Common/SubText";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

const ViewClassPromotionResults = (props) => {
  const { sectionid, id, section, grade, name, institution_name } =
    props?.location?.state;
  const ref = React.createRef();
  const componentRef = useRef();
  const client = useApolloClient();
  const [examinationArr, setExaminationArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStudentDataSectionId();
  }, []);
  const getStudentDataSectionId = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.GetExamByStudentId,
        variables: {
          section_id: +sectionid,
          student_id: +id,
        },
      });
      if (data && data.getExamByStudentId) {
        let temp = [];
        data.getExamByStudentId.map((item) => {
          temp.push({
            name: item.name,
            status: item.status,
            date: timeStampToDateandTime(item?.start_calendar?.datetime),
            id: item.id,
            exam_timetables: item.exam_timetables,
          });
        });
        setExaminationArr(temp);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name && institution_name} />

              <div
                className="enquirydetails_background mb-2"
                ref={ref}
                style={{ padding: 20 }}
              >
                <div ref={componentRef}>
                  <div className="enquiry_heading">
                    <div className="Heading_bold mt-2">Exam Result</div>
                    <div className="mt-3 mr-3">
                      <Card>
                        <div className="pl-2 pr-2 pt-1 pb-1">
                          <span className="mr-2 cursor_for_print">
                            <ReactToPdf
                              targetRef={ref}
                              filename="dayAttendance.pdf"
                              scale={1}
                              options={options}
                            >
                              {({ toPdf }) => (
                                <i class="fa fa-file-pdf-o" onClick={toPdf}></i>
                              )}
                            </ReactToPdf>
                          </span>
                          <span className="cursor_for_print">
                            <ReactToPrint
                              trigger={() => <i class="fa fa-print"></i>}
                              content={() => componentRef.current}
                            />
                          </span>
                        </div>
                      </Card>
                    </div>{" "}
                  </div>

                  <SubText
                    headerTitle="Student name"
                    headerValue={getFullName(name)}
                    subTitle="Class"
                    subTitleValue={grade && grade + "-" + section && section}
                  />
                </div>

                <Card className="bg-light p-2 mt-3">
                  {examinationArr.map((item, index) => (
                    <div>
                      <Grid className="d-flex justify-content-center bold">
                        <span style={{ textTransform: "capitalize" }}>
                          {smart_substr(item.name)}
                        </span>
                      </Grid>

                      <CommonExamViewResult
                        data={item?.exam_timetables}
                      ></CommonExamViewResult>
                    </div>
                  ))}
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};

export default HOC1(ViewClassPromotionResults);
