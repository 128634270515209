import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
//import "./TransportStaff.css";
import HOC1 from "../Hoc1.jsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../Common/Holiday/Holiday.jsx";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(
  routenumber,
  routename,
  starttime,
  endtime,
  startingPoint,
  destinationpoint,

  details
) {
  return {
    routenumber,
    routename,
    starttime,
    endtime,
    startingPoint,
    destinationpoint,
    details,
  };
}

const rows = [
  createData(
    "195",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
  createData(
    "196",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
  createData(
    "198",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
  createData(
    "193",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
  createData(
    "195",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
  createData(
    "195",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
  createData(
    "195",
    "TVMP-XYZ",
    "9:00am",
    "10:00am",
    "Palasia Indore",
    "Vijay Nager,Indore",
    "View"
  ),
];

function createData1(event, organizer, date, time, location, status) {
  return { event, organizer, date, time, location, status };
}

const rows1 = [
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Not Started"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Cancelled"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "In Progress"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Not Started"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Completed"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Completed"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Not Started"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Not Started"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Not Started"
  ),
  createData1(
    "event 11",
    "Mark Smith",
    "09/02/2021",
    "08:00am",
    "lorem Street,CA",
    "Not Started"
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TransportHomePage() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="main_full_width">
      <div className="main_back_color1">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>


<Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Route Listings</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Route Name"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Route Number"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>

                          <Grid item md={6}></Grid>
                        </Grid>

                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    start Time
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End Time
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Starting Point
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Destination Point
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Details
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : rows
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {row.routenumber}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.routename}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.starttime}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.endtime}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.startingPoint}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.destinationpoint}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <p>{row.details}</p>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="enquirydetails_background mb-2 mt-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Upcoming Events</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>

                          <Grid item md={9}></Grid>
                        </Grid>

                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Event
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Organizer
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Time
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Location
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? rows1.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : rows
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {row.event}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.organizer}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.date}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.time}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.location}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {(row.status === "Not Started" ||
                                        row.status === "Cancelled") && (
                                        <Button
                                          className="Not_Started button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                      {row.status === "In Progress" && (
                                        <Button
                                          className="In_Progress button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                      {row.status === "Completed" && (
                                        <Button
                                          className="Completed button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={rows1.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default HOC1(TransportHomePage);
