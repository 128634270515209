import React, { useState, useEffect } from "react";
import "./Notice.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  getcurrentDate,
  timeStampToDatedash,
} from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ParentSchoolNotice(props) {
  //institution ID from studenthomePage Props
  const institution_id = parseInt(props.data.institution_id);

  //Institute name
  let institution_Name = props.data.institution_name;

  const client = useApolloClient();

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const getStudentNoticeBoardData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_School_Notice_data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            let temp = [];

            for (
              let index = 0;
              index < response.data.getNoticeBoardByInstitutionId.length;
              index++
            ) {
              const element =
                response.data.getNoticeBoardByInstitutionId[index];
              // let date1 = new Intl.DateTimeFormat("en-US", {
              //   year: "numeric",
              //   month: "2-digit",
              //   day: "2-digit",
              // }).format(new Date());

              // let date2 = new Intl.DateTimeFormat("en-US", {
              //   year: "numeric",
              //   month: "2-digit",
              //   day: "2-digit",
              // }).format(element.expiry_date);
              // let Publish_Date = new Intl.DateTimeFormat("en-US", {
              //   year: "numeric",
              //   month: "2-digit",
              //   day: "2-digit",
              // }).format(element.publish_date);
              let date1 = new Date();
              let date2 = getcurrentDate(element.expiry_date);
              let Publish_Date = getcurrentDate(element.publish_date);

              if (date1 >= Publish_Date && date1 <= date2) {
                temp.push({
                  id: element.id,
                  title: element.title,
                  subject: element.subject,
                  publish_date: element.publish_date,
                  expiry_date: element.expiry_date,
                  admin: element.admin,
                  status: date1 > date2 ? "EXPIRED" : element.status,
                  content: element.content,
                  documents: element.documents,
                });
              }
            }
            setSchoolnoticedata(temp);
          })
          .catch((err) => {
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        console.log("parent homepage t and c error", error);
      }
    };

    getStudentNoticeBoardData();
  }, [client, institution_id]);

  const filterSchoolNotice = schoolnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="mt-3 ml-3 Heading_bold">School Notice</div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height ">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={5}></Grid>
                        </Grid>
                        <div>
                          <div className="table_foramtitng mt-3">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Title
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Subject
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Publish Date
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Expiry Date
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Publisher Name
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Status
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Action
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? filterSchoolNotice.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterSchoolNotice
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.title) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {row.title.substring(0, 20)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell scope="row" align="left">
                                        {!blankValidator(row.subject) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {row.subject.substring(0, 20)}
                                          </span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {!blankValidator(row.publish_date) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {timeStampToDatedash(
                                              row.publish_date
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.expiry_date) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {timeStampToDatedash(
                                              row.expiry_date
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.admin) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {!blankValidator(row.admin.name) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {!blankValidator(
                                                  row.admin.name.first_name
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {row.admin.name.first_name}
                                                  </span>
                                                )}{" "}
                                                {!blankValidator(
                                                  row.admin.name.middle_name
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {row.admin.name.middle_name}
                                                  </span>
                                                )}{" "}
                                                {!blankValidator(
                                                  row.admin.name.last_name
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {row.admin.name.last_name}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.status) ? (
                                          "No Data"
                                        ) : (
                                          <span>{row.status}</span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="row_details_color"
                                        onClick={() =>
                                          props.navigate("/noticeDetail", {
                                            state: {
                                              item: row,
                                              type: "SCHOOLNOTICE",
                                              institution_Name:
                                                institution_Name,
                                            },
                                          })
                                        }
                                      >
                                        View
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterSchoolNotice.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ParentSchoolNotice));
