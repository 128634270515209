import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./AssignmentAssign.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";

import axios from "axios";
import Button from "@material-ui/core/Button";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import RichTextEditor from "react-rte";
import Expand from "react-expand-animated";
import Dialog from "@material-ui/core/Dialog";
import { baseUrl } from "../../../services/index.js";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Loder from "../../Loder/Loder.jsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  smart_substr,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import AssignmentQuestion from "../../Common/Assigment/AssignmentQuestion.jsx";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const AddNewAssignment = (props) => {
  let pageType = props.location.state.pagetype;
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [DeleteSectionid, setDeleteSectionid] = useState("");
  const [DeleteSectionName, setDeleteSectionName] = useState("");

  const [sectioneditDialog, setSectioneditDialog] = useState(false);
  const [assignmenteditDialog, setassignmenteditDialog] = useState(false);
  const [editSectionid, seteditSectionid] = useState("");
  const [editSectionName, seteditSectionName] = useState("");
  const [editSectionDescription, seteditSectionDescription] = useState("");
  const [description, setdescription] = useState("");
  const [sectionName, setSectionName] = useState("");

  const [addMangeopen, setaddMangeopen] = useState(false);
  const [addMangeQuestionopen, setaddMangeQuestionopen] = useState(false);
  const [editMangeQuestionopen, seteditMangeQuestionopen] = useState(false);

  const [sectionArr, setSectionArr] = useState([]);

  const [questionArr, setQuestionArr] = useState([]);

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [question, setquestion] = useState(RichTextEditor.createEmptyValue());

  let data = props.location.state.data;

  const [Update_Assignment_Name, {}] = useMutation(
    Service.Update_Assignment_Name
  );

  const [Update_Assignment_Question, {}] = useMutation(
    Service.Update_Assignment_Question
  );
  const [Add_Assignment_Question, {}] = useMutation(
    Service.Add_Assignment_Question
  );
  const [Add_Multiple_Assignment_Question, {}] = useMutation(
    Service.Add_Multiple_Assignment_Question
  );
  //------------------- Add Questions variables
  const [chekbox, setchekbox] = useState(1);
  const [editchekbox, seteditchekbox] = useState(1);
  const [sectionId, setSectionId] = useState(-1);

  let questionnumber = (questionArr.length + 1).toString();

  const [marks, setmarks] = useState(-1);

  const [multipleanswerarr, setmultipleanswerarr] = useState([
    {
      choice: "",
      is_answer: 0,
    },
  ]);
  const [editMultipleChoiceArray, seteditMultipleChoiceArray] = useState([
    {
      choice: "",
      is_answer: 0,
    },
  ]);
  ///editquestion

  // const removeTags = (str) => {
  //   if (str === null || str === "") return false;
  //   else str = str.toString();
  //   let a = str.replace(/(<([^>]+)>)/gi, "");
  //   return a.replace(/\s/g, "&nbsp;");

  // };
  const [editquestionnumber, seteditquestionnumber] = useState("");
  const [editmarks, seteditmarks] = useState("");
  const [editAnswer, seteditAnswer] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [editQuestionname, seteditQuestionname] = useState(
    RichTextEditor.createEmptyValue()
  );

  const [editquestionid, seteditquestionid] = useState(0);
  const [editQuestionType, seteditQuestionType] = useState("");
  const [editquestionSectionid, seteditquestionSectionid] = useState(-1);
  const [managesubjects, setmanagesubjects] = useState([]);
  const [subjectid, setsubjectid] = useState("");
  const [editAssignmentname, seteditAssignmentname] = useState("");
  const [editdescription, seteditdescription] = useState("");
  const [editscore, seteditscore] = useState(-1);
  const [charleft, setcharleft] = useState("250");
  const [photo, setphoto] = useState({});

  const [photoPath, setphotoPath] = useState({});
  const parse = require("html-react-parser");

  // ----------------------
  /// addquestion api

  let assignment_id = -1;
  let status = "";

  if (data !== null && data !== undefined) {
    assignment_id = parseInt(data.id);
    status = data.status;
  }

  const [Add_Question_Section, {}] = useMutation(Service.Add_Question_Section);
  const [MAKE_STATUS_PUBLISH, {}] = useMutation(Service.MAKE_STATUS_PUBLISH);

  const [Delete_Assignment_Question, {}] = useMutation(
    Service.Delete_Assignment_Question_ById
  );

  const [Delete_Question_Section, {}] = useMutation(
    Service.Delete_Question_Section
  );

  const [Update_Question_Section, {}] = useMutation(
    Service.Update_Question_Section
  );
  const [Update_Multiple_Assignment_Question, {}] = useMutation(
    Service.Update_Multiple_Assignment_Question
  );

  const classes = useStyles();
  const [isloading, setisloading] = useState(false);

  const [rowsPerPageC, setRowsPerPageC] = React.useState(5);
  const [EditAssignmentId, setEditAssignmentId] = useState("");
  const [checkQuestionpresent, setcheckQuestionpresent] = useState(false);

  const [pageC, setPageC] = React.useState(0);

  const handleChangePageC = (event, newPage) => {
    setPageC(newPage);
  };
  const handleChangeRowsPerPageC = (event) => {
    setRowsPerPageC(parseInt(event.target.value, 10));
    setPageC(0);
  };

  const client = useApolloClient();
  //Assignmnet edit data
  const UpdateAssignmentDetails = (data) => {
    setEditAssignmentId(parseInt(data.id));
    seteditAssignmentname(data.name);
    seteditscore(parseInt(data.total_score));
    seteditdescription(data.description);
    setsubjectid(parseInt(data.subject.id));
    setassignmenteditDialog(true);
  };

  const [AssignementName, setAssignementName] = useState("");
  const [TotalScore, setTotalScore] = useState(-1);
  const [AssignementDecription, setAssignementDecription] = useState("");
  const [SubjectID, setSubjectID] = useState("");

  const [isSectionUpdated, setisSectionUpdated] = useState(false);
  const [isQuestionListUpdated, setisQuestionListUpdated] = useState(false);

  ////////// APi methods
  useEffect(() => {
    window.scrollTo(0, 0);

    if (data !== null && data !== undefined) {
      setAssignementName(data.name);
      setTotalScore(parseInt(data.total_score));
      setAssignementDecription(data.description);
      setSubjectID(parseInt(data.subject.id));
    }

    //to get section data
    const getsectionData = () => {
      try {
        setisloading(true);
        if (assignment_id === -1) {
          return;
        }
        client
          .query({
            query: Service.Get_Question_Section,
            variables: {
              assignment_id: assignment_id,
            },
          })
          .then((response) => {
            setisloading(false);
            if (
              response.data.getQuestionSection !== undefined ||
              response.data.getQuestionSection !== null
            ) {
              setSectionArr(response.data.getQuestionSection);
            }
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err getQuestionSection", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    // to get subject data
    const getSubjectData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id: parseInt(props.data.institution_id),
            },
          })
          .then((response) => {
            setisloading(false);
            setmanagesubjects(response.data.getSubject);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    //question list
    const getQuestionlistByAssignment = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Assignment_Question,
            variables: {
              assignment_id: assignment_id,
            },
          })
          .then((response) => {
            setisloading(false);
            if (
              response.data.getAssignmentQuestion === undefined ||
              response.data.getAssignmentQuestion === null
            ) {
              setQuestionArr([]);
            } else {
              setQuestionArr(response.data.getAssignmentQuestion);
              if (response.data.getAssignmentQuestion.length > 0) {
                setcheckQuestionpresent(true);
              }
            }
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    getSubjectData();
    getsectionData();
    getQuestionlistByAssignment();
  }, [
    client,
    assignment_id,
    isSectionUpdated,
    props.data.institution_id,
    isQuestionListUpdated,
  ]);

  //Error
  const [AssignmentSubjectIdError, setAssignmentSubjectIdError] =
    useState(false);
  const [AssignmentNameEditError, setAssignmentNameEditError] = useState(false);
  const [AssignmentdescriptionError, setAssignmentdescriptionError] =
    useState(false);
  const [AssignmentScoreEditError, setAssignmentScoreEditError] =
    useState(false);
  const UpdateAssignmentName = (ID) => {
    let id = parseInt(ID);
    let flag = false;
    if (!blankValidator(editAssignmentname)) {
      setAssignmentNameEditError(true);
      flag = true;
    }
    if (!blankValidator(editdescription)) {
      setAssignmentdescriptionError(true);
      flag = true;
    }
    if (!blankValidator(editscore) || editscore === -1) {
      setAssignmentScoreEditError(true);
      flag = true;
    }
    if (!blankValidator(subjectid) || subjectid === -1) {
      setAssignmentSubjectIdError(true);
      flag = true;
    }

    if (flag) {
      return;
    }
    try {
      setisloading(true);
      Update_Assignment_Name({
        variables: {
          id,
          subject_id: parseInt(subjectid),
          name: editAssignmentname,
          description: editdescription,
          total_score: parseInt(editscore),
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateAssignment.message,
            "success"
          );
          setAssignementName(editAssignmentname);
          setTotalScore(parseInt(editscore));
          setAssignementDecription(editdescription);
          setSubjectID(parseInt(subjectid));

          setassignmenteditDialog(false);
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const publishAssignment = () => {
    let totallistmarks = 0;
    for (let index = 0; index < questionArr.length; index++) {
      const element = questionArr[index];
      totallistmarks += element.marks;
    }
    if (totallistmarks > TotalScore) {
      alert("Assignmnet TotalMarks is greater than Assignment total Marks ");
    }

    try {
      MAKE_STATUS_PUBLISH({
        variables: {
          id: assignment_id,
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.publishAssignment.message,
            "success"
          );

          props.navigate("/teacherHomePage");
        },
        (err) => console.log("then error", err)
      );
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  //Error
  const [sectionNameError, setsectionNameError] = useState(false);
  const [secDescriptionError, setsecDescriptionError] = useState(false);

  const addsectionName = () => {
    let flag = false;
    if (!blankValidator(sectionName)) {
      setsectionNameError(true);
      flag = true;
    }
    if (!blankValidator(description)) {
      setsecDescriptionError(true);
      flag = true;
    }
    if (flag) {
      return;
    }
    try {
      setisloading(true);
      Add_Question_Section({
        variables: {
          description: description,
          name: sectionName,
          assignment_id: assignment_id,
        },
      }).then(
        (response) => {
          setisloading(false);
          setdescription("");
          setSectionName("");
          showNotificationMsz(
            response.data.addQuestionSection.message,
            "success"
          );
          setaddMangeopen(!addMangeopen);
          setisSectionUpdated(!isSectionUpdated);
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  //Delete Question Variables
  const [opendialogquestionConfimation, setopendialogquestionConfimation] =
    useState(false);

  const [DeleteQuestionid, setDeleteQuestionid] = useState("");
  const [DeleteQuestionName, setDeleteQuestionName] = useState("");
  const DeleteQuestionDailogBox = (data) => {
    setDeleteQuestionid(data.id);
    setDeleteQuestionName(data.description);

    setopendialogquestionConfimation(true);
  };
  const deleteQuestion = (questionId) => {
    let id = parseInt(questionId);

    try {
      setisloading(true);
      Delete_Assignment_Question({
        variables: {
          id,
          assignment_id,
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.deleteAssignmentQuestion.message,
            "success"
          );

          setisloading(false);
          setopendialogquestionConfimation(false);
          setisQuestionListUpdated(!isQuestionListUpdated);
        },
        (err) => {
          setisloading(false);
          console.log("then error question", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const DeleteDailogBox = (data) => {
    setDeleteSectionid(data.id);
    setDeleteSectionName(data.name);

    setopendialogConfimation(true);
  };

  // delete section api
  const deleteSection = (deletesectionid) => {
    let deleteSectionid = parseInt(deletesectionid);
    try {
      setisloading(true);
      Delete_Question_Section({
        variables: {
          id: deleteSectionid,
          assignment_id: assignment_id,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.deleteQuestionSection.message,
            "success"
          );

          setisSectionUpdated(!isSectionUpdated);
          setopendialogConfimation(false);
        },
        (err) => {
          setisloading(false);
          showNotificationMsz("Section Not Deleted", "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      showNotificationMsz("Section Not Deleted", "danger");
    }
  };
  // Update section dialog
  const UpdateSectionDialog = (data) => {
    seteditSectionid(data.id);
    seteditSectionName(data.name);
    seteditSectionDescription(data.description);
    setSectioneditDialog(true);
  };

  //Error
  const [EditSectionNameEdit, setEditSectionNameEdit] = useState(false);
  const [EditSecDescriptionError, setEditSecDescriptionError] = useState(false);
  const updateSection = () => {
    let id = parseInt(editSectionid);

    let flag = false;
    if (!blankValidator(editSectionName)) {
      setEditSectionNameEdit(true);
      flag = true;
    }
    if (!blankValidator(editSectionDescription)) {
      setEditSecDescriptionError(true);
      flag = true;
    }
    if (flag) {
      return;
    }
    try {
      setisloading(true);
      Update_Question_Section({
        variables: {
          id,
          //	assignment_id: assignment_id,
          description: editSectionDescription,
          name: editSectionName,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateQuestionSection.message,
            "success"
          );

          // setsectionUpdateDailog(false);
          setdescription("");
          setSectionName("");
          setisSectionUpdated(!isSectionUpdated);
          setSectioneditDialog(false);
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  //Error
  const [QuesSectionIdError, setQuesSectionIdError] = useState(false);
  const [MarksNumberError, setMarksNumberError] = useState(false);

  const [QuestionTextError, setQuestionTextError] = useState(false);
  const [QuestionAnswerError, setQuestionAnswerError] = useState(false);
  //---------------------------add assignmnet question starts----------

  const submitAnswer = () => {
    let question_type = "Text_Answer";
    let question_section_id = parseInt(sectionId);
    let scroll = false;
    let flag = false;

    if (
      !blankValidator(question_section_id) ||
      question_section_id === isNaN ||
      question_section_id === -1
    ) {
      if (scroll === false) {
        document.getElementById("section").focus();
        setQuesSectionIdError(true);
      }
      scroll = true;
      flag = true;
    }

    if (question.toString("html") === "<p><br></p>") {
      if (scroll === false) {
        document.getElementById("questiontext").focus();
        setQuestionTextError(true);
      }
      scroll = true;
      flag = true;
    }

    if (value.toString("html") === "<p><br></p>" && chekbox == 1) {
      if (scroll === false) {
        document.getElementById("answertext").focus();
        setQuestionAnswerError(true);
      }
      scroll = true;
      flag = true;
    }

    if (flag) {
      return;
    }

    if (chekbox === 2) {
      question_type = "Multple_choice_multiple_answer";
      let answerselected = false;

      for (let index = 0; index < multipleanswerarr.length; index++) {
        const item = multipleanswerarr[index];

        if (item.is_answer === 1) {
          answerselected = true;
          break;
        }
      }
      if (answerselected === false) {
        alert("Please select one answer");
        return;
      }
    }
    if (chekbox === 3) {
      question_type = "Multple_choice_single_answer";
      let answerselected = false;

      for (let index = 0; index < multipleanswerarr.length; index++) {
        const item = multipleanswerarr[index];
        if (item.is_answer === 1) {
          if (answerselected === true) {
            answerselected = false;
            break;
          }
          answerselected = true;
        }
      }
      if (answerselected === false) {
        alert("Please select one answer");
        return;
      }
    }

    try {
      setisloading(true);
      if (chekbox === 1) {
        Add_Assignment_Question({
          variables: {
            assignment_id: assignment_id,
            description: question.toString("html"),
            question_type: question_type,
            answer: value.toString("html"),
            marks: parseInt(marks),
            question_number: questionnumber,
            question_section_id: question_section_id,
            parent_question_id: -1,
            photo: photo === undefined && photo === null ? {} : photo,
          },
        }).then(
          (response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.addAssignmentQuestion.message,
              "success"
            );

            setphoto({});
            setphotoPath({});
            setValue(RichTextEditor.createEmptyValue());
            setquestion(RichTextEditor.createEmptyValue());
            setSectionId(-1);
            setmarks(-1);
            setisQuestionListUpdated(!isQuestionListUpdated);
            setaddMangeQuestionopen(false);
          },
          (err) => {
            setisloading(false);
            console.log("then error", err);
          }
        );
      } else {
        Add_Multiple_Assignment_Question({
          variables: {
            assignment_id: assignment_id,
            description: question.toString("html"),
            question_type: question_type,
            answers: multipleanswerarr,
            marks: parseInt(marks),
            question_number: questionnumber,
            question_section_id: question_section_id,
            parent_question_id: -1,
            photo: photo === undefined && photo === null ? {} : photo,
          },
        }).then(
          (response) => {
            setisloading(false);

            let temp = [{ choice: "", is_answer: 0 }];
            setisQuestionListUpdated(!isQuestionListUpdated);
            setmultipleanswerarr(temp);
            //  setquestionname("");
            setquestion(RichTextEditor.createEmptyValue());
            setphoto({});
            setphotoPath({});
            setSectionId(-1);
            setmarks(-1);
            setaddMangeQuestionopen(false);
            showNotificationMsz(
              response.data.addAssignmentQuestionWithMultipleChoice.message,
              "success"
            );
          },
          (err) => {
            setisloading(false);
            console.log("then error", err);
          }
        );
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  //---------------------------add assignmnet question ends----------

  //----------------------edit assignmnet question starts-------------------

  const [editquestionphoto, seteditquestionphoto] = useState({});
  const handleeditMangeQuestionopen = (data) => {
    seteditquestionphoto(data.document);

    seteditquestionSectionid(parseInt(data.question_section.id));
    seteditquestionid(data.id);
    seteditQuestionType(data.question_type);
    seteditmarks(data.marks);
    seteditquestionnumber(data.question_number.toString());
    let description = RichTextEditor.createValueFromString(
      data.description.toString("html"),
      "html"
    );
    seteditQuestionname(description);

    let answer = "";
    let temp = [];

    if (data.question_type === "Text_Answer") {
      seteditchekbox(1);
      if (data.answer == undefined || data.answer == "") {
        answer = RichTextEditor.createEmptyValue();
      } else {
        answer = RichTextEditor.createValueFromString(
          data.answer.toString("html"),
          "html"
        );
      }
      seteditAnswer(answer);
    }
    if (data.question_type === "Multple_choice_multiple_answer") {
      if (
        data.assignment_choices !== null &&
        data.assignment_choices !== undefined
      ) {
        data.assignment_choices.map((item, index) =>
          temp.push({
            choice: item.choice,
            is_answer: item.is_answer,
          })
        );

        seteditMultipleChoiceArray(temp);
      }
      seteditchekbox(2);
    }

    if (data.question_type === "Multple_choice_single_answer") {
      if (
        data.assignment_choices !== null &&
        data.assignment_choices !== undefined
      ) {
        data.assignment_choices.map((item, index) =>
          temp.push({
            choice: item.choice,
            is_answer: item.is_answer,
          })
        );

        seteditMultipleChoiceArray(temp);
      }

      seteditchekbox(3);
    }

    seteditMangeQuestionopen(true);
  };
  const closeeditMangeQuestionopen = () => {
    seteditMangeQuestionopen(false);
  };

  const editsubmitAnswer = () => {
    try {
      let question_type = "Text_Answer";

      if (editchekbox === 2) {
        question_type = "Multple_choice_multiple_answer";

        let answerselected = false;
        for (let index = 0; index < editMultipleChoiceArray.length; index++) {
          const item = editMultipleChoiceArray[index];
          if (item.is_answer === 1) {
            answerselected = true;
            break;
          }
        }
        if (answerselected === false) {
          alert("Please select one answer");
          return;
        }
      }
      if (editchekbox === 3) {
        question_type = "Multple_choice_single_answer";

        let answerselected = false;
        for (let index = 0; index < editMultipleChoiceArray.length; index++) {
          const item = editMultipleChoiceArray[index];
          if (item.is_answer === 1) {
            if (answerselected === true) {
              answerselected = false;
              break;
            }
            answerselected = true;
          }
        }
        if (answerselected === false) {
          alert("Please select one answer");
          return;
        }
      }

      setisloading(true);
      if (editchekbox === 1) {
        Update_Assignment_Question({
          variables: {
            id: parseInt(editquestionid),
            assignment_id: assignment_id,
            description: editQuestionname.toString("html"),
            question_type,
            answer: editAnswer.toString("html"),
            marks: parseInt(editmarks),
            question_number: editquestionnumber,
            question_section_id: parseInt(editquestionSectionid),
            parent_question_id: -1,
            photo: photo === undefined && photo === null ? {} : photo,
          },
        }).then(
          (response) => {
            setisloading(false);
            seteditMangeQuestionopen(false);
            setphoto({});
            seteditquestionphoto({});
            showNotificationMsz(
              response.data.updateAssignmentQuestion.message,
              "success"
            );

            setValue(RichTextEditor.createEmptyValue());
            setquestion(RichTextEditor.createEmptyValue());
            //    setquestionname("");

            setSectionId(-1);
            setmarks(-1);
            setisQuestionListUpdated(!isQuestionListUpdated);
          },
          (err) => {
            setisloading(false);
            console.log("then error", err);
          }
        );
      } else {
        Update_Multiple_Assignment_Question({
          variables: {
            id: parseInt(editquestionid),
            assignment_id: assignment_id,
            description: editQuestionname.toString("html"),
            question_type,
            answers: editMultipleChoiceArray,
            marks: parseInt(editmarks),
            question_number: editquestionnumber,
            question_section_id: parseInt(editquestionSectionid),
            parent_question_id: -1,
            photo: photo === undefined && photo === null ? {} : photo,
          },
        }).then(
          (response) => {
            setisloading(false);
            setphoto({});
            seteditquestionphoto({});
            setisQuestionListUpdated(!isQuestionListUpdated);
            seteditMangeQuestionopen(false);

            let temp = [{ choice: "", is_answer: 0 }];
            seteditAnswer(RichTextEditor.createEmptyValue());
            seteditquestionnumber("");
            seteditMultipleChoiceArray(temp);
            seteditquestionSectionid(-1);
            seteditQuestionType("");
            seteditquestionid(0);
            seteditQuestionname(RichTextEditor.createEmptyValue());
            seteditmarks("");
            showNotificationMsz(
              response.data.updateAssignmentQuestionWithMultipleChoice.message,
              "success"
            );
          },
          (err) => {
            setisloading(false);
            console.log("then error", err);
          }
        );
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const UploadDocumentHandler = (e) => {
    const file = e.target.files[0];
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);

    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };

          setphoto(obj);
          setphotoPath(obj);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  //---------------------edit assignment questionend-------------------------

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const HandleImageDailogBoxClose = () => {
    setimagepath("");
    setimageType("");
    setOpenImageDailog(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <Grid className="grid_main_div">
                  <Grid item md={10}>
                    <div className="ml-3 mt-2 Heading_bold">{pageType}</div>
                  </Grid>
                  <Grid item md={2}>
                    {blankValidator(status) && (
                      <div className="ml-1 mt-2">
                        {status !== "DRAFT" ? (
                          <div>
                            {/* <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width bg-success"
                              onClick={publishAssignment}
                            >
                              {status}
                            </Button> */}
                          </div>
                        ) : (
                          <div>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={publishAssignment}
                            >
                              Proceed To Publish
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>

                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-1 mb-1">
                          <Grid item md={12}>
                            <div className="Outside_border_updateAssignment p-2">
                              <Grid className="grid_main_div">
                                <Grid item md={4}>
                                  {" "}
                                  <div className="inputMarginright ">
                                    <label for="exampleInputEmail1">
                                      Title
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      autoComplete="off"
                                      value={AssignementName}
                                      placeholder="Title"
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={4}>
                                  <div className="inputMarginright">
                                    <label for="exampleInputEmail1">
                                      Total Score
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      autoComplete="off"
                                      value={TotalScore == -1 ? "" : TotalScore}
                                      placeholder="TotalScore"
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>

                                <Grid item md={4}>
                                  {" "}
                                  <div className="inputMarginright">
                                    <label for="exampleInputEmail1">
                                      Subject
                                    </label>
                                    <select
                                      class="form-control"
                                      disabled={true}
                                      id="exampleFormControlSelect1"
                                      value={SubjectID}
                                    >
                                      <option>Subject</option>
                                      {managesubjects.map((item, index) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid className="grid_main_div">
                                <Grid item md={11}>
                                  <div>
                                    <label for="exampleInputEmail1">
                                      Instructions
                                    </label>
                                    <textarea
                                      class="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      placeholder="Instructions"
                                      autoComplete="off"
                                      value={AssignementDecription}
                                      disabled={true}
                                    ></textarea>
                                  </div>
                                </Grid>
                                <Grid item md={1}>
                                  {" "}
                                  <div className="exampleInputEmail1">
                                    {status === null || status === undefined ? (
                                      ""
                                    ) : status === "DRAFT" ? (
                                      <span>
                                        {" "}
                                        <i
                                          class="fa fa-pencil pl-3 ml-3 pt-3 mt-4  openassignemntcolor"
                                          onClick={() =>
                                            UpdateAssignmentDetails(data)
                                          }
                                        ></i>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-4 mb-2">
                          <Grid item md={12}>
                            <div className="ml-3 mt-3 mb-3 Heading_bold">
                              {status === null || status === undefined
                                ? ""
                                : status === "DRAFT"
                                ? "Create Section"
                                : "Section"}
                            </div>
                            <Card className="bg-light p-2 mb-5">
                              <div className="card_admissiondetails_height">
                                <div>
                                  {!addMangeopen ? (
                                    <div className="d-flex">
                                      {status === null ||
                                      status === undefined ? (
                                        ""
                                      ) : status === "DRAFT" ? (
                                        <div
                                          onClick={() => {
                                            setaddMangeopen(!addMangeopen);
                                          }}
                                        >
                                          <span className="iconstoaddfee">
                                            <i className="fa fa-plus-circle"></i>
                                          </span>
                                          <span className=" ml-2 mt-1 mb-2 addmanageuserfont">
                                            Add New Section
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  ) : (
                                    <Expand open={addMangeopen}>
                                      <Card className="cardvisible_border mb-2 mt-3 pb-2">
                                        <div className="card_admissiondetails_height">
                                          <div className="textfiled_margin">
                                            <div className="card_content_instition">
                                              <Grid className="grid_main_div">
                                                <Grid item md={12}>
                                                  <div
                                                    className="text-right"
                                                    onClick={() => {
                                                      setaddMangeopen(
                                                        !addMangeopen
                                                      );
                                                    }}
                                                  >
                                                    <span className="iconstoaddfee">
                                                      <i className="fa fa-minus-circle"></i>
                                                    </span>
                                                  </div>
                                                  <div className="text_filed_heading">
                                                    Section
                                                  </div>
                                                  <div className=" mt-1">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="exampleFormControlInput1"
                                                      placeholder="Section Name"
                                                      autoComplete="off"
                                                      value={sectionName}
                                                      onChange={(e) => {
                                                        setsectionNameError(
                                                          false
                                                        );
                                                        setSectionName(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    {sectionNameError && (
                                                      <span className="text-danger">
                                                        Enter the Section Name
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="text_filed_heading">
                                                    Instructions
                                                  </div>
                                                  <div className=" mt-1">
                                                    <textarea
                                                      class="form-control"
                                                      id="exampleFormControlTextarea1"
                                                      rows="3"
                                                      placeholder="Instructions"
                                                      autoComplete="off"
                                                      maxlength="250"
                                                      value={description}
                                                      onChange={(e) => {
                                                        let leftcharater =
                                                          250 -
                                                          e.target.value.length;
                                                        setcharleft(
                                                          leftcharater
                                                        );
                                                        setsecDescriptionError(
                                                          false
                                                        );
                                                        setdescription(
                                                          e.target.value
                                                        );
                                                      }}
                                                    ></textarea>
                                                    {secDescriptionError && (
                                                      <span className="text-danger">
                                                        Enter the Description
                                                      </span>
                                                    )}
                                                  </div>
                                                  <span>
                                                    Character limit:{charleft}
                                                  </span>
                                                </Grid>
                                              </Grid>
                                            </div>
                                            <Grid className="grid_main_div">
                                              <Grid item md={10}></Grid>
                                              <Grid item md={2}>
                                                <div className=" mb-1">
                                                  <Button
                                                    variant="contained"
                                                    className="add_new_query_btn  button_login_decoration button_width"
                                                    onClick={addsectionName}
                                                  >
                                                    Create
                                                  </Button>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </div>
                                      </Card>
                                    </Expand>
                                  )}
                                </div>
                              </div>
                              <div className="table_foramtitng">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Instructions
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="center"
                                          className="table_header"
                                        >
                                          {status === null ||
                                          status === undefined
                                            ? ""
                                            : status === "DRAFT"
                                            ? "	Edit"
                                            : ""}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          {status === null ||
                                          status === undefined
                                            ? ""
                                            : status === "DRAFT"
                                            ? "	Delete"
                                            : ""}
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(sectionArr > 0
                                        ? sectionArr.slice(
                                            pageC * rowsPerPageC,
                                            pageC * rowsPerPageC + rowsPerPageC
                                          )
                                        : sectionArr
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.name != null && row.name}
                                          </StyledTableCell>

                                          <StyledTableCell align="left">
                                            {row.description != null &&
                                              row.description}
                                          </StyledTableCell>

                                          <StyledTableCell align="center">
                                            {status === null ||
                                            status === undefined ? (
                                              ""
                                            ) : status === "DRAFT" ? (
                                              <span
                                                onClick={() =>
                                                  UpdateSectionDialog(row)
                                                }
                                              >
                                                {" "}
                                                <i class="fa fa-pencil openassignemntcolor"></i>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {status === null ||
                                            status === undefined ? (
                                              ""
                                            ) : status === "DRAFT" ? (
                                              <span
                                                onClick={() =>
                                                  DeleteDailogBox(row)
                                                }
                                              >
                                                {" "}
                                                <i class="fa fa-trash openassignemntcolor"></i>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={sectionArr.length}
                                    rowsPerPage={rowsPerPageC}
                                    page={pageC}
                                    onChangePage={handleChangePageC}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPageC
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </Card>
                            <div className="ml-3 mt-3 mb-3 Heading_bold">
                              {status === null || status === undefined
                                ? ""
                                : status === "DRAFT"
                                ? "	Create Question"
                                : "Question"}
                            </div>
                            <div>
                              {!addMangeQuestionopen ? (
                                <div className="d-flex">
                                  {status === null || status === undefined ? (
                                    ""
                                  ) : status === "DRAFT" ? (
                                    <div
                                      onClick={() => {
                                        setaddMangeQuestionopen(
                                          !addMangeQuestionopen
                                        );
                                      }}
                                    >
                                      <span className="iconstoaddfee">
                                        <i className="fa fa-plus-circle"></i>
                                      </span>
                                      <span className=" ml-2 mt-1 mb-2 addmanageuserfont">
                                        Add New Question
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <Expand open={addMangeQuestionopen}>
                                  <Card className="cardvisible_border mb-2 mt-3 pb-2">
                                    <div className="card_admissiondetails_height">
                                      <div className="textfiled_margin">
                                        <div className="card_content_instition">
                                          <Grid className="grid_main_div">
                                            <Grid item md={12}>
                                              <div
                                                className="text-right"
                                                onClick={() => {
                                                  setaddMangeQuestionopen(
                                                    !addMangeQuestionopen
                                                  );
                                                }}
                                              >
                                                <span className="iconstoaddfee">
                                                  <i className="fa fa-minus-circle"></i>
                                                </span>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        <Card className="bg-light p-2 mt-4 mb-5">
                                          <Grid className="grid_main_div mt-1 mb-1">
                                            <Grid item md={4}>
                                              <div className="inputMarginright ">
                                                <label for="exampleInputEmail1">
                                                  Select Section
                                                </label>
                                                <select
                                                  class="form-control"
                                                  value={sectionId}
                                                  id="section"
                                                  onChange={(e) => {
                                                    setQuesSectionIdError(
                                                      false
                                                    );
                                                    setSectionId(
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value={-1}>
                                                    Select Section
                                                  </option>
                                                  {sectionArr.length > 0 ? (
                                                    sectionArr.map(
                                                      (item, index) => (
                                                        <option value={item.id}>
                                                          {item.name}
                                                        </option>
                                                      )
                                                    )
                                                  ) : (
                                                    <option value={-1}>
                                                      No Data
                                                    </option>
                                                  )}
                                                </select>
                                                {QuesSectionIdError && (
                                                  <span className="text-danger">
                                                    Select the Section of
                                                    Question
                                                  </span>
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid item md={4}>
                                              <div className="inputMarginright ">
                                                <label for="exampleInputEmail1">
                                                  Marks
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="marks"
                                                  autoComplete="off"
                                                  placeholder="Marks"
                                                  value={
                                                    marks === -1 ? "" : marks
                                                  }
                                                  onChange={(e) => {
                                                    setMarksNumberError(false);

                                                    if (isNaN(e.target.value)) {
                                                      setMarksNumberError(true);
                                                      return;
                                                    }
                                                    setmarks(e.target.value);
                                                  }}
                                                />
                                                {MarksNumberError && (
                                                  <span className="text-danger">
                                                    Enter Marks of Question in
                                                    Integer
                                                  </span>
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid item md={4}>
                                              <div className="inputMarginright ">
                                                <label for="exampleInputEmail1">
                                                  Question Number
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  autoComplete="off"
                                                  value={questionnumber}
                                                  disabled={true}
                                                />
                                              </div>
                                            </Grid>

                                            <div style={{ width: "100%" }}>
                                              <label for="exampleInputEmail1 mt-4 pt-3">
                                                Question Name
                                              </label>
                                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                                <div
                                                  className="mt-1 mb-3"
                                                  id="questiontext"
                                                >
                                                  <RichTextEditor
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    value={question}
                                                    onChange={(e) => {
                                                      setQuestionTextError(
                                                        false
                                                      );
                                                      setquestion(e);
                                                    }}
                                                  />
                                                  {QuestionTextError && (
                                                    <span className="text-danger">
                                                      Enter Question
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="p-2">
                                                  {chekbox == 1 && (
                                                    <Grid className="grid_main_div">
                                                      <Grid item md={3}>
                                                        <input
                                                          className="nofilechoosenhide mb-1"
                                                          type="file"
                                                          id="Photo"
                                                          onChange={(e) =>
                                                            UploadDocumentHandler(
                                                              e
                                                            )
                                                          }
                                                        />
                                                        {blankValidator(
                                                          photo
                                                        ) &&
                                                          blankValidator(
                                                            photo.name
                                                          ) &&
                                                          blankValidator(
                                                            photo
                                                          ) && (
                                                            <Chip
                                                              label={photo.name}
                                                              onClick={() => {
                                                                HandleImageDailogBox(
                                                                  photo
                                                                );
                                                              }}
                                                              color="primary"
                                                              variant="outlined"
                                                            />
                                                          )}
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                  {chekbox == 2 && (
                                                    <Grid className="grid_main_div">
                                                      <Grid item md={3}>
                                                        <input
                                                          className="nofilechoosenhide mb-1"
                                                          type="file"
                                                          id="Photo"
                                                          onChange={(e) =>
                                                            UploadDocumentHandler(
                                                              e
                                                            )
                                                          }
                                                        />
                                                        {blankValidator(
                                                          photo
                                                        ) &&
                                                          blankValidator(
                                                            photo.name
                                                          ) &&
                                                          blankValidator(
                                                            photo
                                                          ) && (
                                                            <Chip
                                                              label={photo.name}
                                                              onClick={() => {
                                                                HandleImageDailogBox(
                                                                  photo
                                                                );
                                                              }}
                                                              color="primary"
                                                              variant="outlined"
                                                            />
                                                          )}
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                  {chekbox == 3 && (
                                                    <Grid className="grid_main_div">
                                                      <Grid item md={3}>
                                                        <input
                                                          className="nofilechoosenhide mb-1"
                                                          type="file"
                                                          id="Photo"
                                                          onChange={(e) =>
                                                            UploadDocumentHandler(
                                                              e
                                                            )
                                                          }
                                                        />{" "}
                                                        {blankValidator(
                                                          photo
                                                        ) &&
                                                          blankValidator(
                                                            photo.name
                                                          ) &&
                                                          blankValidator(
                                                            photo
                                                          ) && (
                                                            <Chip
                                                              label={photo.name}
                                                              onClick={() => {
                                                                HandleImageDailogBox(
                                                                  photo
                                                                );
                                                              }}
                                                              color="primary"
                                                              variant="outlined"
                                                            />
                                                          )}
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                </div>
                                              </div>

                                              <div className="mt-3 mb-1">
                                                <label
                                                  for="exampleInputEmail1"
                                                  className="mb-2"
                                                >
                                                  Type of Questions
                                                </label>
                                              </div>
                                              <div className="mt-1 mb-3 d-flex justify-content-between">
                                                <div class="form-check form-check-inline">
                                                  <CheckBoxComponent
                                                    label="TEXT ANSWER"
                                                    checked={chekbox == 1}
                                                    cssClass="e-custom"
                                                    onChange={(e) => {
                                                      setchekbox(1);
                                                    }}
                                                  />
                                                </div>
                                                <div class="form-check form-check-inline">
                                                  <CheckBoxComponent
                                                    label="MULTIPLE CHOICE WITH MULTIPLE ANSWER"
                                                    checked={chekbox == 2}
                                                    cssClass="e-custom"
                                                    onChange={(e) => {
                                                      setchekbox(2);
                                                    }}
                                                  />
                                                </div>
                                                <div class="form-check form-check-inline">
                                                  <CheckBoxComponent
                                                    label="MULTIPLE CHOICE WITH SINGLE ANSWER"
                                                    checked={chekbox == 3}
                                                    cssClass="e-custom"
                                                    onChange={(e) => {
                                                      setchekbox(3);
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              {chekbox == 1 && (
                                                <div className="mt-3 mb-3 card_invoicedetails_border">
                                                  <div
                                                    className="mt-1 mb-3"
                                                    id="answertext"
                                                  >
                                                    <RichTextEditor
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                      value={value}
                                                      onChange={(e) => {
                                                        setQuestionAnswerError(
                                                          false
                                                        );
                                                        setValue(e);
                                                      }}
                                                    />
                                                    {QuestionAnswerError && (
                                                      <span className="text-danger">
                                                        Enter Answer of Question
                                                      </span>
                                                    )}
                                                  </div>

                                                  <Grid className="grid_main_div mt-2 mb-2">
                                                    <Grid item md={10}></Grid>
                                                    <Grid item md={2}>
                                                      <Button
                                                        onClick={submitAnswer}
                                                        variant="contained"
                                                        className="add_new_query_btn button_login_decoration button_width"
                                                      >
                                                        Save
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              )}
                                              {chekbox == 2 && (
                                                <div className="mt-3 mb-3 card_invoicedetails_border">
                                                  <div className="font-weight-bolder ml-4 mt-4 mb-4 ">
                                                    Select Check Box for right
                                                    answer
                                                  </div>
                                                  {multipleanswerarr.map(
                                                    (item, index) => (
                                                      <div className=" ml-4 mt-2 mb-3">
                                                        <div class="form-check form-check-inline">
                                                          <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            name="checkbox"
                                                            checked={
                                                              multipleanswerarr[
                                                                index
                                                              ].is_answer === 0
                                                                ? false
                                                                : true
                                                            }
                                                            onClick={() => {
                                                              multipleanswerarr[
                                                                index
                                                              ].is_answer =
                                                                multipleanswerarr[
                                                                  index
                                                                ].is_answer ===
                                                                0
                                                                  ? 1
                                                                  : 0;

                                                              setmultipleanswerarr(
                                                                [
                                                                  ...multipleanswerarr,
                                                                ]
                                                              );
                                                            }}
                                                          />
                                                          <input
                                                            class="form-check-input form-control"
                                                            type="text"
                                                            name="one"
                                                            maxLength="200"
                                                            value={item.choice}
                                                            onChange={(e) => {
                                                              multipleanswerarr[
                                                                index
                                                              ].choice =
                                                                e.target.value;
                                                              setmultipleanswerarr(
                                                                [
                                                                  ...multipleanswerarr,
                                                                ]
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                  <Grid className="grid_main_div mt-2 mb-2">
                                                    <Grid item md={2}>
                                                      <div
                                                        className="openassignemntcolor addSectionfont"
                                                        onClick={(e) => {
                                                          multipleanswerarr.push(
                                                            {
                                                              choice: "",
                                                              is_answer: 0,
                                                            }
                                                          );
                                                          let temp = [];
                                                          multipleanswerarr.map(
                                                            (item) => {
                                                              temp.push(item);
                                                            }
                                                          );
                                                          setmultipleanswerarr(
                                                            temp
                                                          );
                                                        }}
                                                      >
                                                        <i class="fa fa-plus"></i>{" "}
                                                        Add new choice
                                                      </div>
                                                    </Grid>

                                                    <Grid item md={10}></Grid>
                                                    <Grid item md={2}>
                                                      <Button
                                                        onClick={submitAnswer}
                                                        variant="contained"
                                                        className="add_new_query_btn button_login_decoration button_width"
                                                      >
                                                        Save
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              )}
                                              {chekbox == 3 && (
                                                <div className="mt-3 mb-3 card_invoicedetails_border">
                                                  <div className="font-weight-bolder ml-4 mt-4 mb-4 ">
                                                    Select Check Box for right
                                                    answer
                                                  </div>
                                                  {multipleanswerarr.map(
                                                    (item, index) => (
                                                      <div className=" ml-4 mt-1 mb-3">
                                                        <div class="form-check form-check-inline">
                                                          <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            name="checkbox"
                                                            checked={
                                                              multipleanswerarr[
                                                                index
                                                              ].is_answer === 0
                                                                ? false
                                                                : true
                                                            }
                                                            onClick={(e) => {
                                                              if (
                                                                e.target
                                                                  .checked ==
                                                                false
                                                              ) {
                                                                multipleanswerarr[
                                                                  index
                                                                ].is_answer = 0;
                                                                setmultipleanswerarr(
                                                                  [
                                                                    ...multipleanswerarr,
                                                                  ]
                                                                );
                                                                return;
                                                              }
                                                              for (
                                                                let i = 0;
                                                                i <
                                                                multipleanswerarr.length;
                                                                i++
                                                              ) {
                                                                const element =
                                                                  multipleanswerarr[
                                                                    i
                                                                  ];
                                                                if (
                                                                  element.is_answer ===
                                                                  1
                                                                ) {
                                                                  alert(
                                                                    "only one answer can be seleted "
                                                                  );
                                                                  return;
                                                                }
                                                              }
                                                              multipleanswerarr[
                                                                index
                                                              ].is_answer =
                                                                e.target
                                                                  .checked ===
                                                                true
                                                                  ? 1
                                                                  : 0;
                                                              setmultipleanswerarr(
                                                                [
                                                                  ...multipleanswerarr,
                                                                ]
                                                              );
                                                            }}
                                                          />
                                                          <input
                                                            class="form-check-input form-control"
                                                            type="text"
                                                            name="one"
                                                            maxLength="200"
                                                            value={item.choice}
                                                            onChange={(e) => {
                                                              multipleanswerarr[
                                                                index
                                                              ].choice =
                                                                e.target.value;
                                                              setmultipleanswerarr(
                                                                [
                                                                  ...multipleanswerarr,
                                                                ]
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                  <Grid className="grid_main_div mt-2 mb-2">
                                                    <Grid item md={2}>
                                                      <div
                                                        className="openassignemntcolor addSectionfont"
                                                        onClick={(e) => {
                                                          multipleanswerarr.push(
                                                            {
                                                              choice: "",
                                                              is_answer: 0,
                                                            }
                                                          );
                                                          let temp = [];
                                                          multipleanswerarr.map(
                                                            (item) => {
                                                              temp.push(item);
                                                            }
                                                          );
                                                          setmultipleanswerarr(
                                                            temp
                                                          );
                                                        }}
                                                      >
                                                        <i class="fa fa-plus"></i>{" "}
                                                        Add new choice
                                                      </div>
                                                    </Grid>

                                                    <Grid item md={10}></Grid>
                                                    <Grid item md={2}>
                                                      <Button
                                                        onClick={submitAnswer}
                                                        variant="contained"
                                                        className="add_new_query_btn button_login_decoration button_width"
                                                      >
                                                        Save
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              )}
                                            </div>
                                          </Grid>
                                        </Card>
                                      </div>
                                    </div>
                                  </Card>
                                </Expand>
                              )}
                            </div>

                            {/* /////////////edit start question section */}

                            <div>
                              <Expand open={editMangeQuestionopen}>
                                <Card className="cardvisible_border mb-2 mt-3 pb-2">
                                  <div className="card_admissiondetails_height">
                                    <div className="textfiled_margin">
                                      <div className="card_content_instition">
                                        <Grid className="grid_main_div">
                                          <Grid item md={10}>
                                            <div className="ml-3 mt-3 mb-3 Heading_bold">
                                              Edit Question
                                            </div>
                                          </Grid>
                                          <Grid item md={2}>
                                            <div
                                              className="text-right"
                                              onClick={
                                                closeeditMangeQuestionopen
                                              }
                                            >
                                              <span className="iconstoaddfee">
                                                <i className="fa fa-minus-circle"></i>
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                      <Card className="bg-light p-2 mt-4 mb-5">
                                        <Grid className="grid_main_div mt-1 mb-1">
                                          <Grid item md={4}>
                                            <div className="inputMarginright ">
                                              <label for="exampleInputEmail1">
                                                Select Section
                                              </label>
                                              <select
                                                class="form-control"
                                                id="exampleFormControlSelect1"
                                                value={editquestionSectionid}
                                                onChange={(e) =>
                                                  seteditquestionSectionid(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value={-1}>
                                                  Select Section
                                                </option>
                                                {sectionArr.map(
                                                  (item, index) => (
                                                    <option value={item.id}>
                                                      {item.name}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </Grid>
                                          <Grid item md={4}>
                                            <div className="inputMarginright ">
                                              <label for="exampleInputEmail1">
                                                Marks
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                autoComplete="off"
                                                placeholder="Marks"
                                                value={
                                                  editmarks === -1
                                                    ? ""
                                                    : editmarks
                                                }
                                                onChange={(e) => {
                                                  seteditmarks(e.target.value);
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item md={4}>
                                            <div className="inputMarginright ">
                                              <label for="exampleInputEmail1">
                                                Question Number
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                autoComplete="off"
                                                placeholder=" Number"
                                                value={editquestionnumber}
                                              />
                                            </div>
                                          </Grid>

                                          <div style={{ width: "100%" }}>
                                            <label for="exampleInputEmail1 mt-4 pt-3">
                                              Question Name
                                            </label>
                                            <div className="mt-3 mb-3 card_invoicedetails_border">
                                              <div className="mt-1 mb-3">
                                                <RichTextEditor
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                  value={editQuestionname}
                                                  onChange={(e) => {
                                                    seteditQuestionname(e);
                                                  }}
                                                />
                                              </div>
                                              <div className="p-2">
                                                {editchekbox === 1 && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={3}>
                                                      <input
                                                        className="nofilechoosenhide mb-1"
                                                        type="file"
                                                        id="Photo"
                                                        onChange={(e) =>
                                                          UploadDocumentHandler(
                                                            e
                                                          )
                                                        }
                                                      />

                                                      {blankValidator(
                                                        editquestionphoto
                                                      ) &&
                                                        blankValidator(
                                                          editquestionphoto.name
                                                        ) && (
                                                          <Chip
                                                            label={
                                                              editquestionphoto.name
                                                            }
                                                            onClick={() => {
                                                              HandleImageDailogBox(
                                                                editquestionphoto
                                                              );
                                                            }}
                                                            // onDelete={() => {
                                                            //   seteditquestionphoto(null);
                                                            // }}
                                                            color="primary"
                                                            variant="outlined"
                                                          />
                                                        )}
                                                    </Grid>
                                                  </Grid>
                                                )}
                                                {editchekbox === 2 && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={3}>
                                                      <input
                                                        className="nofilechoosenhide mb-1"
                                                        type="file"
                                                        id="Photo"
                                                        onChange={(e) =>
                                                          UploadDocumentHandler(
                                                            e
                                                          )
                                                        }
                                                      />

                                                      {blankValidator(
                                                        editquestionphoto
                                                      ) &&
                                                        blankValidator(
                                                          editquestionphoto.name
                                                        ) && (
                                                          <Chip
                                                            label={
                                                              editquestionphoto.name
                                                            }
                                                            onClick={() => {
                                                              HandleImageDailogBox(
                                                                editquestionphoto
                                                              );
                                                            }}
                                                            color="primary"
                                                            variant="outlined"
                                                          />
                                                        )}
                                                    </Grid>
                                                  </Grid>
                                                )}
                                                {editchekbox == 3 && (
                                                  <Grid className="grid_main_div mt-3">
                                                    <Grid item md={3}>
                                                      <input
                                                        className="nofilechoosenhide mb-1"
                                                        type="file"
                                                        id="Photo"
                                                        onChange={(e) =>
                                                          UploadDocumentHandler(
                                                            e
                                                          )
                                                        }
                                                      />

                                                      {blankValidator(
                                                        editquestionphoto
                                                      ) &&
                                                        blankValidator(
                                                          editquestionphoto
                                                        ) && (
                                                          <Chip
                                                            label={
                                                              editquestionphoto.name
                                                            }
                                                            onClick={() => {
                                                              HandleImageDailogBox(
                                                                editquestionphoto
                                                              );
                                                            }}
                                                            color="primary"
                                                            variant="outlined"
                                                          />
                                                        )}
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </div>
                                            </div>

                                            <div className="mt-3 mb-1">
                                              <label
                                                for="exampleInputEmail1"
                                                className="mb-2"
                                              >
                                                Type of Questions
                                              </label>
                                            </div>
                                            <div className="mt-1 mb-3 d-flex justify-content-between">
                                              <div
                                                class="form-check form-check-inline"
                                                onClick={(e) => {
                                                  seteditchekbox(1);
                                                }}
                                              >
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  checked={editchekbox == 1}
                                                  value={1}
                                                  id="inlineRadio1"
                                                />

                                                <label
                                                  class="form-check-label"
                                                  for="inlineRadio1"
                                                >
                                                  TEXT ANSWER
                                                </label>
                                              </div>
                                              <div
                                                class="form-check form-check-inline"
                                                onClick={(e) => {
                                                  seteditchekbox(2);
                                                }}
                                              >
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio2"
                                                  value={2}
                                                  checked={editchekbox == 2}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineRadio2"
                                                >
                                                  MULTIPLE CHOICE WITH MULTIPLE
                                                  ANSWER
                                                </label>
                                              </div>
                                              <div
                                                class="form-check form-check-inline"
                                                onClick={(e) => {
                                                  seteditchekbox(3);
                                                }}
                                              >
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio3"
                                                  value={3}
                                                  checked={editchekbox == 3}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="inlineRadio3"
                                                >
                                                  MULTIPLE CHOICE WITH SINGLE
                                                  ANSWER
                                                </label>
                                              </div>
                                            </div>

                                            {editchekbox === 1 && (
                                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                                <div className="mt-1 mb-3">
                                                  <RichTextEditor
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    value={editAnswer}
                                                    onChange={(e) => {
                                                      seteditAnswer(e);
                                                    }}
                                                  />
                                                </div>

                                                <Grid className="grid_main_div mt-2 mb-2">
                                                  <Grid item md={10}></Grid>
                                                  <Grid item md={2}>
                                                    <Button
                                                      onClick={editsubmitAnswer}
                                                      variant="contained"
                                                      className="add_new_query_btn button_login_decoration button_width"
                                                    >
                                                      Save
                                                    </Button>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            )}
                                            {editchekbox === 2 && (
                                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                                {editMultipleChoiceArray.map(
                                                  (item, index) => (
                                                    <div className="mt-1 mb-3">
                                                      <div class="form-check form-check-inline">
                                                        <input
                                                          class="form-check-input"
                                                          type="checkbox"
                                                          name="checkbox"
                                                          checked={
                                                            editMultipleChoiceArray[
                                                              index
                                                            ].is_answer === 0
                                                              ? false
                                                              : true
                                                          }
                                                          onClick={() => {
                                                            editMultipleChoiceArray[
                                                              index
                                                            ].is_answer =
                                                              editMultipleChoiceArray[
                                                                index
                                                              ].is_answer === 0
                                                                ? 1
                                                                : 0;

                                                            seteditMultipleChoiceArray(
                                                              [
                                                                ...editMultipleChoiceArray,
                                                              ]
                                                            );
                                                          }}
                                                        />
                                                        <input
                                                          class="form-check-input form-control"
                                                          type="text"
                                                          name="one"
                                                          maxLength="200"
                                                          value={item.choice}
                                                          onChange={(e) => {
                                                            editMultipleChoiceArray[
                                                              index
                                                            ].choice =
                                                              e.target.value;
                                                            seteditMultipleChoiceArray(
                                                              [
                                                                ...editMultipleChoiceArray,
                                                              ]
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                                <Grid className="grid_main_div mt-2 mb-2">
                                                  <Grid item md={2}>
                                                    <div
                                                      className="openassignemntcolor addSectionfont"
                                                      onClick={(e) => {
                                                        editMultipleChoiceArray.push(
                                                          {
                                                            choice: "",
                                                            is_answer: 0,
                                                          }
                                                        );
                                                        let temp = [];
                                                        editMultipleChoiceArray.map(
                                                          (item) => {
                                                            temp.push(item);
                                                          }
                                                        );
                                                        seteditMultipleChoiceArray(
                                                          temp
                                                        );
                                                      }}
                                                    >
                                                      <i class="fa fa-plus"></i>{" "}
                                                      Add new choice
                                                    </div>
                                                  </Grid>

                                                  <Grid item md={10}></Grid>
                                                  <Grid item md={2}>
                                                    <Button
                                                      onClick={editsubmitAnswer}
                                                      variant="contained"
                                                      className="add_new_query_btn button_login_decoration button_width"
                                                    >
                                                      Save
                                                    </Button>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            )}
                                            {editchekbox == 3 && (
                                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                                {editMultipleChoiceArray.map(
                                                  (item, index) => (
                                                    <div className="mt-1 mb-3">
                                                      <div class="form-check form-check-inline">
                                                        <input
                                                          class="form-check-input"
                                                          type="checkbox"
                                                          name="checkbox"
                                                          checked={
                                                            editMultipleChoiceArray[
                                                              index
                                                            ].is_answer === 0
                                                              ? false
                                                              : true
                                                          }
                                                          onClick={() => {
                                                            editMultipleChoiceArray[
                                                              index
                                                            ].is_answer =
                                                              editMultipleChoiceArray[
                                                                index
                                                              ].is_answer === 0
                                                                ? 1
                                                                : 0;

                                                            seteditMultipleChoiceArray(
                                                              [
                                                                ...editMultipleChoiceArray,
                                                              ]
                                                            );
                                                          }}
                                                        />
                                                        <input
                                                          class="form-check-input form-control"
                                                          type="text"
                                                          name="one"
                                                          maxLength="200"
                                                          value={item.choice}
                                                          onChange={(e) => {
                                                            editMultipleChoiceArray[
                                                              index
                                                            ].choice =
                                                              e.target.value;
                                                            seteditMultipleChoiceArray(
                                                              [
                                                                ...editMultipleChoiceArray,
                                                              ]
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                                <Grid className="grid_main_div mt-2 mb-2">
                                                  <Grid item md={2}>
                                                    <div
                                                      className="openassignemntcolor addSectionfont"
                                                      onClick={(e) => {
                                                        editMultipleChoiceArray.push(
                                                          {
                                                            choice: "",
                                                            is_answer: 0,
                                                          }
                                                        );
                                                        let temp = [];
                                                        editMultipleChoiceArray.map(
                                                          (item) => {
                                                            temp.push(item);
                                                          }
                                                        );
                                                        seteditMultipleChoiceArray(
                                                          temp
                                                        );
                                                      }}
                                                    >
                                                      <i class="fa fa-plus"></i>{" "}
                                                      Add new choice
                                                    </div>
                                                  </Grid>

                                                  <Grid item md={10}></Grid>
                                                  <Grid item md={2}>
                                                    <Button
                                                      onClick={editsubmitAnswer}
                                                      variant="contained"
                                                      className="add_new_query_btn button_login_decoration button_width"
                                                    >
                                                      Save
                                                    </Button>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            )}
                                          </div>
                                        </Grid>
                                      </Card>
                                    </div>
                                  </div>
                                </Card>
                              </Expand>
                            </div>

                            {/* ----------------------------------------edit end----------------------------------------- */}

                            <Card className="bg-light p-2">
                              {questionArr.map((item, index) => (
                                <Accordion className="assugment_border">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Grid className="grid_main_div">
                                      <Grid
                                        item
                                        md={8}
                                        // title={item.description}
                                      >
                                        {smart_substr(item.description)}
                                      </Grid>

                                      <Grid item md={2}>
                                        {item.question_section.name}
                                      </Grid>

                                      <Grid item md={2}>
                                        <div className="float-right">
                                          {status === null ||
                                          status === undefined ? (
                                            ""
                                          ) : status === "DRAFT" ? (
                                            <span>
                                              <i
                                                class="fa fa-pencil pr-5 mr-1 openassignemntcolor"
                                                onClick={(e) => {
                                                  try {
                                                    e.stopPropagation();
                                                    handleeditMangeQuestionopen(
                                                      item
                                                    );
                                                  } catch (error) {}
                                                }}
                                              ></i>
                                              <i
                                                class="fa fa-trash pr-4 openassignemntcolor"
                                                onClick={() => {
                                                  DeleteQuestionDailogBox(item);
                                                }}
                                              ></i>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AssignmentQuestion
                                    data={item}
                                  ></AssignmentQuestion>
                                </Accordion>
                              ))}
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div></div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Section : {DeleteSectionName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteSection(DeleteSectionid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={opendialogquestionConfimation}
        onClose={() => {
          setopendialogquestionConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogquestionConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Question :{" "}
            {parse.default(DeleteQuestionName)}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteQuestion(DeleteQuestionid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogquestionConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={sectioneditDialog}
        onClose={() => {
          setSectioneditDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setSectioneditDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Update Section : {editSectionName}
          </div>

          <div className="p-4">
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Section Name"
              autoComplete="off"
              value={editSectionName}
              onChange={(e) => {
                setEditSectionNameEdit(false);
                seteditSectionName(e.target.value);
              }}
            />
            {EditSectionNameEdit && (
              <span className="text-danger">Enter Section Name</span>
            )}

            <input
              type="text"
              className="form-control mt-1"
              id="exampleFormControlInput1"
              placeholder="Section Description"
              autoComplete="off"
              value={editSectionDescription}
              onChange={(e) => {
                setEditSecDescriptionError(false);
                seteditSectionDescription(e.target.value);
              }}
            />
            {EditSecDescriptionError && (
              <span className="text-danger">Enter the Description</span>
            )}
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => updateSection()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setSectioneditDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={assignmenteditDialog}
        onClose={() => {
          setassignmenteditDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting update_dailog_border"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setassignmenteditDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            {/* <b>Update Assignment :</b>{" "} */}
            <span className="font-weight-bold">{AssignementName}</span>
          </div>

          <div className="p-4 ">
            <div className="Outside_border_updateAssignment p-4">
              <label className="float-left">Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Assignment Name"
                autoComplete="off"
                value={editAssignmentname}
                onChange={(e) => {
                  setAssignmentNameEditError(false);
                  seteditAssignmentname(e.target.value);
                }}
              />
              {AssignmentNameEditError && (
                <span className="text-danger">Enter Assignment Name</span>
              )}
              <label className="float-left mt-1">Description</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Description"
                autoComplete="off"
                maxlength="250"
                value={editdescription}
                onChange={(e) => {
                  setAssignmentdescriptionError(false);
                  seteditdescription(e.target.value);
                }}
              ></textarea>

              {AssignmentdescriptionError && (
                <span className="text-danger">
                  Enter Assignment Description
                </span>
              )}
              <label className="float-left mt-1">Total Score</label>
              <input
                type="text"
                className="form-control mt-1"
                id="exampleFormControlInput1"
                placeholder="Total Score"
                autoComplete="off"
                value={editscore == -1 ? "" : editscore}
                onChange={(e) => {
                  setAssignmentScoreEditError(false);
                  seteditscore(e.target.value);
                }}
              />
              {AssignmentScoreEditError && (
                <span className="text-danger mt-1">Enter Assignment Score</span>
              )}
              <label className="float-left">Subject</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                value={subjectid}
                onChange={(e) => {
                  setAssignmentSubjectIdError(false);
                  setsubjectid(e.target.value);
                }}
              >
                <option value={-1}>Subject</option>
                {managesubjects.length > 0 ? (
                  managesubjects.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))
                ) : (
                  <option value={-1}>No Data</option>
                )}
              </select>
              {AssignmentSubjectIdError && (
                <span className="text-danger">Select the Subject</span>
              )}
            </div>
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => UpdateAssignmentName(EditAssignmentId)}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setassignmenteditDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />

      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={HandleImageDailogBoxClose}
        className="dailog_formatting"
      >
        <div>
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={HandleImageDailogBoxClose}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <span>
            <img
              src={baseUrl + "/" + imagepath}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </span>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AddNewAssignment));
