import React from "react";
import AssignTimeTabletabs from "./AssignTimeTabletabs";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import HOC1 from "../../Hoc1.jsx";

import { blankValidator } from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
function TimeTablePreviewTemplate(props) {
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 Heading_bold mt-2">
                    Preview TimeTable Template -
                    {blankValidator(props.location.state.data) &&
                      props.location.state.data.TemplateName}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="mt-2">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="table_foramtitng mt-2">
                          <AssignTimeTabletabs
                            // id={props.location.state.data.timetable_template_id}
                            id={
                              blankValidator(props.location.state.data)
                                ? props.location.state.data
                                    .timetable_template_id
                                : -1
                            }
                          ></AssignTimeTabletabs>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default (HOC1(TimeTablePreviewTemplate));
