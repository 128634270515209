import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import APIHooks from "../../../api manager/CommonAPI.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
function ManageSubject({ ADMIN_DATA, navigate }) {
  const subjectHooks = APIHooks();
  // -----------global id------------------------------------
  let institution_id = parseInt(ADMIN_DATA.institution_id);
  // -----------------------local state-------------------------------
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [nameedit, setnameedit] = useState("");
  const [subjectid, setsubjectid] = useState("");
  const [deleteid, setdeleteid] = useState("");
  const [deletesubject, setdeletesubject] = useState("");
  const [opendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [nameerror, setnameerror] = useState(false);
  const [editnameerror, seteditnameerror] = useState(false);
  // -----------------------local state-------------------------------
  // -----------------------methods-------------------------------

  useEffect(() => {
    window.scrollTo(0, 0);
    setisloading(true);
    subjectHooks.getSubjectAPI(institution_id);
    setisloading(false);
  }, []);

  const OpenEditQuery = (data) => {
    setnameedit(data.name);
    setsubjectid(data.id);
    setOpendailogtoEdit(true);
  };

  const DeleteDailogBox = (data) => {
    setdeleteid(data.id);
    setdeletesubject(data.name);
    setopendialogConfimation(true);
  };
  const deleteSubjectHandler = async (subjectId) => {
    setisloading(true);
    await subjectHooks.deleteSubjectAPI(institution_id, parseInt(subjectId));
    setisloading(false);
    setopendialogConfimation(false);
  };
  const CreateManageSubject = async () => {
    if (!subjectName) {
      setnameerror(true);
      return;
    }
    setisloading(true);
    await subjectHooks.createSubjectAPI(institution_id, subjectName);
    setisloading(false);
    setSubjectName("");
    setaddMangeopen(false);
  };

  const UpdateManageSubject = async () => {
    if (!nameedit) {
      seteditnameerror(true);
      return;
    }
    let id = parseInt(subjectid);
    setisloading(true);
    await subjectHooks.updateSubjectAPI(id, institution_id, nameedit);
    setisloading(false);
    setOpendailogtoEdit(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={ADMIN_DATA.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Manage Subject</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {!addMangeopen ? (
                          <div
                            className="d-flex"
                            onClick={() => setaddMangeopen(true)}
                          >
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add New Subject
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="cardvisible_border mb-2">
                              <div
                                className="text-right mr-3"
                                onClick={() => setaddMangeopen(false)}
                              >
                                <span className="iconstoaddfee">
                                  <i class="fa fa-times cursor"></i>
                                </span>
                              </div>
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="text_filed_heading">
                                    Subjects
                                  </div>
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div className="text_filed_heading">
                                          Name
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            autoComplete="off"
                                            value={subjectName}
                                            onChange={(e) => {
                                              setnameerror(false);
                                              setSubjectName(e.target.value);
                                            }}
                                          />
                                          {nameerror && (
                                            <span className="text-danger">
                                              Enter the Subject Name
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className=" mb-1">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={CreateManageSubject}
                                        >
                                          Create
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin cardheight_overflow">
                        {subjectHooks.subjectArr.length !== 0 ? (
                          subjectHooks.subjectArr.map((item, index) => (
                            <Card
                              className="cardvisible_border mb-2"
                              key={index}
                            >
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={2}></Grid>
                                      <Grid item md={6}>
                                        <div className=" mt-1 mb-1">
                                          <input
                                            type="text"
                                            className="form-control input_border_none_Color "
                                            placeholder="Name"
                                            value={item.name}
                                            disabled={true}
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={4}>
                                        {" "}
                                        <div className="d-flex pt-2">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Subject Name</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    autoComplete="off"
                    value={nameedit}
                    onChange={(e) => {
                      seteditnameerror(false);
                      setnameedit(e.target.value);
                    }}
                  />
                  {editnameerror && (
                    <span className="text-danger">Enter the Subject Name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4  mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateManageSubject}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Subject : {deletesubject}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteSubjectHandler(deleteid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageSubject));
