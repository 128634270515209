import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import { useNavigate } from "react-router-dom";

import {
  blankValidator,
  getcurrentDate,
  serverFormatDate,
  holidayStringHandler,
} from "../../../utils/Validation";
import Loder from "../../Loder/Loder";
function Holiday(props) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [academic_session_id, setacademic_session_id] = useState(-1);
  const [holidatCalenderArray, setHolidatCalenderArray] = useState([]);

  const gobackprevoius = () => {
    navigate(-1);
  };

  let institution_id = parseInt(props.data.institution_id);

  useEffect(() => {
    getacademicdata();
  }, []);

  const getacademicdata = () => {
    if (props.data.usertype === "ADMIN") {
      setacademic_session_id(
        blankValidator(parseInt(props?.data?.academic_session_id))
          ? parseInt(props?.data?.academic_session_id)
          : -1
      );
    } else {
      setacademic_session_id(
        blankValidator(parseInt(props?.dataacademic?.id))
          ? parseInt(props?.dataacademic?.id)
          : -1
      );
    }
  };
  useEffect(() => {
    if (academic_session_id == -1) {
      return;
    }
    if (props.data.usertype === "SUPERADMIN") {
      return;
    }
    getSchoolCalenderData();
  }, [academic_session_id]);
  const getSchoolCalenderData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_School_Calendar,
          variables: {
            institution_id,
            date: serverFormatDate(new Date()),
            academic_session_id,
          },
        })
        .then((response) => {
          let element = response?.data?.getSchoolCalendar;
          setHolidatCalenderArray(
            element.filter((item) => {
              return (
                getcurrentDate(item.start_datetime) <= new Date() &&
                getcurrentDate(item.end_datetime) >= new Date()
              );
            })
          );

          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <div className="font_color_holiday">
        {blankValidator(holidatCalenderArray) &&
        holidatCalenderArray.length !== 0
          ? "Holiday : "
          : ""}
        {holidayStringHandler(holidatCalenderArray)}
      </div>
      <div className="card_Show_Insitute">
        <span className="margin_institution_name">
          {props.data.type === "PT" ? (
            ""
          ) : (
            <Card className="institution_Name_font">{props.name}</Card>
          )}
        </span>
        {!props.isRemove && (
          <div>
            {blankValidator(props.mynav) && props.navigate ? (
              <span
                className="gobackbutton manage_edit_cursor"
                onClick={() => {
                  props.navigate(props.mynav);
                }}
              >
                <i className="fa fa-chevron-left"></i> Back
              </span>
            ) : (
              <span
                className="gobackbutton manage_edit_cursor"
                onClick={gobackprevoius}
              >
                <i className="fa fa-chevron-left"></i> Back
              </span>
            )}
          </div>
        )}
      </div>

      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(Holiday);
