import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";

import * as Service from "../../../services/index";
import { blankValidator, getFullName } from "../../../utils/Validation.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder";
import GoogleMapComponent from "../GoogleMapComponent/GoogleMapComponent.jsx";
import image_map from "./image_map.png";
import "./MapView.css";

const GoogleMap = ({ navigate, location, data }) => {
  const { id } = location?.state;
  let institution_Name = data.institution_name;
  const client = useApolloClient();
  const [googleMapData, setGoogleMapData] = useState({});
  const [routeStopArr, setRouteStopArr] = useState([]);
  const [center, setCenter] = useState({
    lat: 28.9845,
    lng: 77.7064,
  });
  const [busLocation, setBusLocation] = useState({
    lat: 28.9845,
    lng: 77.7064,
  });
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    getTransportStop();
    updateTime();
    const interval = setInterval(() => {
      updateTime();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const updateTime = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_tranport_route_by_id,
          variables: {
            id: parseInt(id),
          },
        })
        .then(async (response) => {
          await setGoogleMapData(response.data.getTransportRouteById);

          await setBusLocation({
            lat: parseFloat(
              response.data.getTransportRouteById.transport_tracker
                ?.current_latitude
            ),
            lng: parseFloat(
              response.data.getTransportRouteById.transport_tracker
                ?.current_longitude
            ),
          });

          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const getTransportStop = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Stop_Route_Name,
          variables: {
            transport_route_id: parseInt(id),
          },
        })
        .then(async (response) => {
          if (response?.data?.getTransportStop) {
            const sortedArr = response?.data?.getTransportStop.sort((a, b) => {
              return +a.sequence_number - +b.sequence_number;
            });
            await setRouteStopArr(sortedArr);
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_back_color1">
      <div className="margin_from_both_contact ">
        <Holiday name={institution_Name} />

        <div className="googlemapcard">
          <Grid className="grid_main_div">
            <Grid item md={8}>
              <div className="google_map_main_div">
                <Card>
                  <div className="conatctcardHeight contnent_margin_map">
                    <Grid className="grid_main_div">
                      <Grid item md={2}>
                        <img
                          className="img_formatting imageCursor map_card_image"
                          src={
                            blankValidator(googleMapData.employee)
                              ? `${Service.baseUrl}/${googleMapData?.employee?.photo?.path}`
                              : image_map
                          }
                          alt=""
                        />
                      </Grid>
                      <Grid item md={5}>
                        <div>
                          <span className="content_color_grey">
                            Transport Employee Name
                          </span>
                          <div className="d-flex">
                            <span>
                              <i class="fa fa-user iconcolor"></i>
                            </span>
                            <span className="ml-2 Heading_Google_map_view">
                              {getFullName(googleMapData?.employee?.name)}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5}>
                        <div>
                          <span className="content_color_grey">
                            Driver Contact
                          </span>
                          <div className="d-flex">
                            <span>
                              <i className="fa fa-mobile iconcolor"></i>
                            </span>
                            <span className="ml-2 Heading_Google_map_view">
                              +{googleMapData?.driver_phone_number}
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid className="grid_main_div">
                      <Grid item md={12}>
                        <div>
                          <div className="Heading_Google_map_view">
                            Route Details
                          </div>
                          <div className="content_color_grey">
                            {googleMapData?.route_name}
                          </div>
                        </div>
                        <div>
                          <div className="Heading_Google_map_view">
                            {googleMapData?.start_point} -{" "}
                            {googleMapData?.end_point}
                          </div>
                          {/* <div className="content_color_grey">
                            Lorem ipsum......
                          </div> */}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ height: "60vh", width: "100%" }}>
                    <GoogleMapComponent
                      showRoute={true}
                      showBus={
                        blankValidator(googleMapData.transport_tracker)
                          ? true
                          : false
                      }
                      stopArray={routeStopArr}
                      zoom={8}
                      center={center}
                      busLocation={busLocation}
                    ></GoogleMapComponent>
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="Second_portion_map">
                <div className="card_MARGIN">
                  <div className="conatctcardHeight2">
                    <div className="google_map_main_div cardheightnew_overflow">
                      <Card>
                        <div className="contnent_margin_map">
                          <div className="Heading_Google_map_view">Stops</div>

                          {routeStopArr.map((item, index) => (
                            <div
                              className="d-flex mt-1 border_bottom_map"
                              key={index}
                            >
                              <span>
                                <i class="fa fa-subway"></i>
                              </span>
                              <span className="ml-2 content_color_grey">
                                <div>{item.stop_name}</div>
                                {/* -------------not getting time from server ------------*/}
                                {/* <div>(Bell 8:50-3:00)- 15:35</div> */}
                              </span>
                            </div>
                          ))}
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

export default HOC1(GoogleMap);
