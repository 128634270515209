import React, { useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Holiday from "../../Common/Holiday/Holiday.jsx";

const AdmissionMenu = (props) => {
  console.log("prosp======", props);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data?.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Onboarding</div>
                </div>
                <div className="card_margin_both_side">
                  {props.data.type === "PT" || props.data.type === "ET" ? (
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <p className="Heading_bold mt-2 mb-2">Student</p>
                        <Grid className="grid_main_div" spacing={2}>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() =>
                                props.navigate("/adminstudentlistview")
                              }
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Student Status</p>
                            </Card>
                          </Grid>
                          <Grid item md={3}>
                            {props.data.type === "PT" ||
                            props.data.type === "ET" ? (
                              <Card
                                className="home_menu_card_center"
                                onClick={() => props.navigate("/PtSudentForm")}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-user"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Student Onboarding</p>
                              </Card>
                            ) : (
                              <Card
                                className="home_menu_card_center"
                                onClick={() => props.navigate("/studentform")}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-user"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Student Onboarding</p>
                              </Card>
                            )}
                          </Grid>{" "}
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() => {
                                props.navigate("/assignonboardstudent");
                              }}
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Assign Class</p>
                            </Card>
                          </Grid>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() => {
                                props.navigate("/manageusersetting");
                              }}
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Student Login</p>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  ) : (
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <p className="Heading_bold mt-2 mb-2">Student</p>
                        <Grid className="grid_main_div" spacing={2}>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() =>
                                props.navigate("/admissionIntrest")
                              }
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-user"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Enquiry</p>
                            </Card>
                          </Grid>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() =>
                                props.navigate("/adminstudentlistview")
                              }
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Student Status</p>
                            </Card>
                          </Grid>
                          <Grid item md={3}>
                            {props.data.type === "PT" ||
                            props.data.type === "ET" ? (
                              <Card
                                className="home_menu_card_center"
                                onClick={() => props.navigate("/PtSudentForm")}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-user"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Student Onboarding</p>
                              </Card>
                            ) : (
                              <Card
                                className="home_menu_card_center"
                                onClick={() => props.navigate("/studentform")}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-user"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Student Onboarding</p>
                              </Card>
                            )}
                          </Grid>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() => {
                                props.navigate("/manageRollNo");
                              }}
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Manage Roll Number</p>
                            </Card>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div" spacing={2}>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() => {
                                props.navigate("/assignonboardstudent");
                              }}
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Assign Class</p>
                            </Card>
                          </Grid>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() => {
                                props.navigate("/manageusersetting");
                              }}
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Assign Login</p>
                            </Card>
                          </Grid>
                          <Grid item md={3}>
                            <Card
                              className="home_menu_card_center"
                              onClick={() => {
                                props.navigate("/manageusersetting");
                              }}
                            >
                              <div className="home_menu_size_of_icons">
                                <i class="fa fa-users"></i>
                              </div>
                              <div className="home_menu_whiteLine"></div>
                              <p>Student Login</p>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  )}
                </div>
                {props.data.type === "PT" || props.data.type === "ET" ? (
               <div className="card_margin_both_side">
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <div>
                          <p className="Heading_bold mt-2 mb-2">Staff</p>
                          <Grid className="grid_main_div" spacing={2}>
                            {/* <Grid item md={3}>
                              <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  props.navigate("/employeedetailform");
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-users"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Staff Onboarding</p>
                              </Card>
                            </Grid> */}
                            <Grid item md={3}>
                              <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  props.navigate("/adminemployeelistview");
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-users"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Staff Login</p>
                              </Card>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : (
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <div>
                          <p className="Heading_bold mt-2 mb-2">Staff</p>
                          <Grid className="grid_main_div" spacing={2}>
                            <Grid item md={3}>
                              <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  props.navigate("/employeedetailform");
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-users"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Staff Onboarding</p>
                              </Card>
                            </Grid>
                            <Grid item md={3}>
                              <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  props.navigate("/adminemployeelistview");
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-users"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Staff Login</p>
                              </Card>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
                {props.data.type === "PT" || props.data.type === "ET" ? (
                  ""
                ) : (
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <div>
                          <p className="Heading_bold mt-2 mb-2">
                            Bulk Onboarding
                          </p>
                          <Grid className="grid_main_div" spacing={2}>
                            <Grid item md={3}>
                              <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  props.navigate("/bulkUpload");
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-users"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Bulk Upload</p>
                              </Card>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default HOC1(AdmissionMenu);
