import React, { useState, useEffect } from "react";
import "./StudentTimetable.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// import moment from "../../../../node_modules/moment";
import TableHead from "@material-ui/core/TableHead";
import { changeTimeFormat, getFullName } from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import OnlineLinkLocation from "../../Common/Events/OnlineLinkLocation";

const StudentMonday = (props) => {
  let institution_id = parseInt(props.manageInstitution.institution_id);
  const [mondayArray, setMondayArray] = useState([]);
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var todayDate = Date.now();
  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    const getstudentTimeTableFn = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Class_Time_Table,
            variables: {
              institution_id,
              section_id: parseInt(props.sectionId),
              day: props.day,
              start_date: new Date(
                todayDate + (props.day - new Date().getDay()) * (3600 * 24000)
              )
                .toJSON()
                .slice(0, 10),
              end_date: new Date(
                todayDate + (props.day - new Date().getDay()) * (3600 * 24000)
              )
                .toJSON()
                .slice(0, 10),
            },
          })
          .then((response) => {
            let temp = [];
            response.data.getClassTimetable.map(async (data) => {
              data["show"] = false;
              data["display"] = "";
              temp.push(data);
            });
            const sortedList = temp.sort((a, b) => {
              let st = a.start_time.split(":");
              let stm = parseInt(st[0]);
              let ss = parseInt(st[1]);
              let et = b.start_time.split(":");
              let etm = parseInt(et[0]);
              let es = parseInt(et[1]);
              return stm - etm || ss - es;
            });

            setMondayArray(sortedList);
            setisloading(false);
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent monday  catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent monday  t and c error", error);
      }
    };
    getstudentTimeTableFn();
  }, [institution_id, client, props.sectionId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="Heading_bold mt-2">{props.name}</div>
      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" className="table_header">
                  Period Name
                </StyledTableCell>
                <StyledTableCell className="table_header" align="left">
                  Start Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  End Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Rooms
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Subject
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Teacher
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? mondayArray.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : mondayArray
              ).map((row, index) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {row.period_name && row.period_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.start_time && changeTimeFormat(row.start_time)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.end_time && changeTimeFormat(row.end_time)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <OnlineLinkLocation
                      data={row}
                      day={props.day}
                      fromTimetable={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.subject?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getFullName(row.employee?.name)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={mondayArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>

      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(StudentMonday);
