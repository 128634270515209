import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Onboarding.css";

import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  serverDateFormat,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import { useMutation } from "@apollo/client";
import APIHooks from "../../../api manager/CommonAPI.js";
import { blankValidator } from "../../../utils/Validation";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  religion,
  highestQualificationData,
} from "../../../utils/LocalData.js";

const ViewStudentForm = ({ ADMIN_DATA, location, navigate, userId }) => {
  const studentHook = APIHooks();
  //-----------------------------global id---------------------------
  const [isMotherAccordian, setisMotherAccordian] = useState(false);
  const [isFatherAccordian, setisFatherAccordian] = useState(false);
  const [isGuardianAccordian, setisGuardianAccordian] = useState(false);

  const [academic_session_id, setacademic_session_id] = useState(-1);
  const [institute_name, setinstitute_name] = useState("");
  const [institution_id, setinstitution_id] = useState(-1);

  let staffId = location.state.studentId;

  //-----------------------------api calls---------------------------
  const [Update_StudentForm_API, {}] = useMutation(
    Service.Update_StudentForm_API
  );

  const client = useApolloClient();
  //-----------------------------global id---------------------------
  //-----------------------------local state---------------------------
  const [otherDocsArr, setOtherDocsArr] = useState([]);
  const [studentForm, setStudentForm] = useState({
    transport_pickup_id: -1,
    transport_drop_id: -1,
    status: "",
    RollNo: "",
    display_id: "",
    login_id: "",
    Student_first_name: "",
    Student_middle_name: "",
    Student_last_name: "",
    institution_id: "",
    joining_date: "",
    gender: "",
    birth_date: "",
    email: "",
    Current_line1: "",
    Current_line2: "",
    Current_city: "",
    Current_state: "",
    Current_zip_code: -1,
    Current_country: "",
    Permanent_line1: "",
    Permanent_line2: "",
    Permanent_city: "",
    Permanent_state: "",
    Permanent_zip_code: -1,
    Permanent_country: "",
    blood_group: "",
    S_nationality: "",
    passport_number: "",
    passport_expiry_date: "",
    national_id_number: "",
    primary_contact_id: "",
    religion: "",
    height: -1,
    weight: -1,
    Father_id: "",
    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Father_nationality: "",
    Father_email_address: "",
    father_passport_number: "",
    Father_occupation: "",
    Father_phone_number: "",
    Father_national_id_number: "",
    Father_annual_income: "",
    Father_qualification_id: -1,
    // mother section--------------------------------
    Mother_id: "",
    Mother_first_name: "",
    Mother_middle_name: "",
    Mother_last_name: "",
    Mother_nationality: "",
    Mother_email_address: "",
    mother_passport_number: "",
    Mother_occupation: "",
    Mother_phone_number: "",
    Mother_national_id_number: "",
    Mother_annual_income: "",
    Mother_qualification_id: -1,
    // guardian section--------------------------------
    guardian_id: "",
    guardian_first_name: "",
    guardian_middle_name: "",
    guardian_last_name: "",
    guardian_nationality: "",
    guardian_email_address: "",
    guardian_passport_number: "",
    guardian_occupation: "",
    guardian_phone_number: "",
    guardian_national_id_number: "",
    guardian_annual_income: "",
    guardian_qualification_id: -1,
    // guardian section ends
    academic_session_id: -1,
    grade_id: -1,
    section_id: "",
    roll_number: "",
    sectionName: "",
    gradeName: "",
    education_qualification_id: [],

    photo: { name: "", type: "", path: "", filename: "" },
    birth_cert: { name: "", type: "", path: "", filename: "" },
    national_doc: { name: "", type: "", path: "", filename: "" },
    immune_doc: { name: "", type: "", path: "", filename: "" },
    school_leave_doc: { name: "", type: "", path: "", filename: "" },
    others_doc: [],
  });
  const [studentId, setStudentId] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [selectroute, setselectroute] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [pickupRouteArr, setPickupRouteArr] = useState([]);
  const [pickupStartArr, setPickupStartArr] = useState([]);
  const [pickupRouteName, setPickupRouteName] = useState("");
  const [dropRouteName, setDropRouteName] = useState("");
  const [pickupStopName, setPickupStopName] = useState("");
  const [dropStopName, setDropStopName] = useState("");
  const [dropStopArr, setDropStopArr] = useState([]);
  const [buttonCheck, setbuttonCheck] = useState(false);
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);
  //-----------------------------local state---------------------------

  //----------------------------error-----------------------------------------
  //error decalare
  const [genderError, setGenderError] = useState(false);
  const [StudentFirstNameError, setStudentFirstNameError] = useState(false);
  const [declarationcheckedError, setdeclarationcheckedError] = useState(false);
  const [BirthdateError, setBirthdateError] = useState(false);
  const [NationalityError, setNationalityError] = useState(false);
  const [Caddressline1Error, setCaddressline1Error] = useState(false);
  const [CcountryError, setCcountryError] = useState(false);
  const [CstateError, setCstateError] = useState(false);
  const [CPincodeError, setCPincodeError] = useState(false);
  const [Paddressline1Error, setPaddressline1Error] = useState(false);
  const [PcountryError, setPcountryError] = useState(false);
  const [PstateError, setPstateError] = useState(false);
  const [PPincodeError, setPPincodeError] = useState(false);
  const [MfirstnameError, setMfirstnameError] = useState(false);
  const [MEmailError, setMEmailError] = useState(false);
  const [MPhoneError, setMPhoneError] = useState(false);
  const [FfirstnameError, setFfirstnameError] = useState(false);
  const [FEmailError, setFEmailError] = useState(false);
  const [FPhoneError, setFPhoneError] = useState(false);
  const [photoError, setphotoError] = useState(false);
  const [birth_certificateError, setbirth_certificateError] = useState(false);
  //----------------------------error-----------------------------------------

  const setValues = () => {
    if (
      blankValidator(location.state.type) &&
      location.state.type === "STUDENT"
    ) {
      setacademic_session_id(parseInt(location.state.academic_session_id));
      setinstitute_name(location.state.institution_name);
      setinstitution_id(location.state.institution_id);
      return;
    } else if (
      blankValidator(location.state.type) &&
      location.state.type === "FROMPARENTHOMEPAGE"
    ) {
      setacademic_session_id(parseInt(location.state.academic_session_id));
      setinstitute_name(location.state.institution_name);
      setinstitution_id(location.state.institution_id);
      return;
    } else {
      setacademic_session_id(parseInt(ADMIN_DATA.academic_session_id));
      setinstitute_name(ADMIN_DATA.institution_name);
      setinstitution_id(parseInt(ADMIN_DATA.institution_id));
    }
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    setisloading(true);
    await setValues();

    await studentHook.getClassAPI(academic_session_id);
    try {
      let myUserId;
      if (staffId) {
        myUserId = staffId;
      } else if (userId) {
        myUserId = userId;
      }

      if (myUserId) {
        setStudentId(myUserId);
        const { data } = await client.query({
          query: Service.Get_Student_Details_Data,
          variables: {
            id: parseInt(myUserId),
          },
        });
        if (data && data.getStudentDetailForm) {
          const {
            status,
            roll_number,
            login,
            student_profile,
            section,
            transport_stop,
            transport_pickup,
          } = data.getStudentDetailForm;
          //----------------------------variables---------------------------
          let userStatus = "";
          let userRollNo = "";
          let userLoginObj = {
            user_id: "",
            login_id: "",
            display_id: "",
          };
          let userFirstName = "";
          let userMiddleName = "";
          let userLastName = "";
          let userJoinDate = "";
          let userGender = "";
          let userBirthDate = "";
          let userEmail = "";
          let line1 = "";
          let line2 = "";
          let city = "";
          let state = "";
          let zip_code = -1;
          let country = "";
          let pline1 = "";
          let pline2 = "";
          let pcity = "";
          let pstate = "";
          let pzip_code = -1;
          let pcountry = "";
          let userBloodGroup = "";
          let userNationality = "";
          let userIdentityObj = {
            encrypted_id: "",
            expiry_date: "",
          };
          let nationalIdObj = {
            encrypted_id: "",
          };
          let userPrimaryContactId = "";
          let userReligion = "";
          let userHeight = -1;
          let userWeight = -1;
          let fatherObj = {
            qualification_id: -1,
            id: "",
            nationality: "",
            email_address: "",
            occupation: "",
            phone_number: "",
            annual_income: "",
            name: {
              first_name: "",
              middle_name: "",
              last_name: "",
            },
            user_identity: {
              encrypted_id: "",
              expiry_date: "",
            },
            national_identity: {
              encrypted_id: "",
            },
          };
          let motherObj = {
            qualification_id: -1,
            id: "",
            nationality: "",
            email_address: "",
            occupation: "",
            phone_number: "",
            annual_income: "",
            name: {
              first_name: "",
              middle_name: "",
              last_name: "",
            },
            user_identity: {
              encrypted_id: "",
              expiry_date: "",
            },
            national_identity: {
              encrypted_id: "",
            },
          };
          let guardianObj = {
            qualification_id: -1,
            id: "",
            nationality: "",
            email_address: "",
            occupation: "",
            phone_number: "",
            annual_income: "",
            name: {
              first_name: "",
              middle_name: "",
              last_name: "",
            },
            user_identity: {
              encrypted_id: "",
              expiry_date: "",
            },
            national_identity: {
              encrypted_id: "",
            },
          };

          let sectionName = "";
          let gradeName = "";
          let sectionObj = {
            id: -1,
            name: "",
            grade: {
              id: -1,
              gradeName: "",
            },
          };
          let educationDocArr = [];
          let photoObj = {
            name: "",
            type: "",
            path: "",
            filename: "",
          };
          let birthCertificateObj = {
            name: "",
            type: "",
            path: "",
            filename: "",
          };
          let nationalCertificateObj = {
            name: "",
            type: "",
            path: "",
            filename: "",
          };

          let immunisationCertificateObj = {
            name: "",
            type: "",
            path: "",
            filename: "",
          };
          let schoolleavingCertificateObj = {
            name: "",
            type: "",
            path: "",
            filename: "",
          };

          let othersArr = [];

          //----------------------------variables---------------------------
          //----------------------------validation---------------------------
          if (status) {
            userStatus = status;
          }
          if (transport_stop) {
            if (transport_stop.transport_route) {
              if (transport_stop.transport_route.route_name)
                setDropRouteName(transport_stop.transport_route.route_name);
            }
            if (transport_stop.stop_name)
              setDropStopName(transport_stop.stop_name);
          }
          if (transport_pickup) {
            if (transport_pickup.transport_route) {
              if (transport_pickup.transport_route.route_name)
                setPickupRouteName(transport_pickup.transport_route.route_name);
            }
            if (transport_pickup.stop_name)
              setPickupStopName(transport_pickup.stop_name);
          }
          if (transport_stop || transport_pickup) {
            setbuttonCheck(true);
          }
          if (roll_number) {
            userRollNo = roll_number;
          }
          if (login) {
            if (login.display_id) userLoginObj.display_id = login.display_id;
            if (login.login_id) userLoginObj.login_id = login.login_id;
          }

          if (section !== null && section !== undefined) {
            if (section.id) {
              sectionObj.id = section.id;
              sectionName = section.name;
            }
            if (section.grade) {
              if (section.grade.id) {
                sectionObj.grade.id = section.grade?.id;
                gradeName = section.grade?.gradeName;
              }
            }
          } else {
            sectionObj.grade.id = student_profile?.grade.id;
            gradeName = student_profile.grade?.gradeName;
          }

          if (student_profile) {
            if (student_profile.photo) {
              let sp = student_profile.photo;
              if (sp.path) photoObj.path = sp.path;
              if (sp.filename) photoObj.filename = sp.filename;
              if (sp.type) photoObj.type = sp.type;
              if (sp.name) photoObj.name = sp.name;
            }
            if (student_profile.birth_cert) {
              let spb = student_profile.birth_cert;
              if (spb.path) birthCertificateObj.path = spb.path;
              if (spb.filename) birthCertificateObj.filename = spb.filename;
              if (spb.type) birthCertificateObj.type = spb.type;
              if (spb.name) birthCertificateObj.name = spb.name;
            }

            if (student_profile.national) {
              let spb = student_profile.national;
              if (spb.path) nationalCertificateObj.path = spb.path;
              if (spb.filename) nationalCertificateObj.filename = spb.filename;
              if (spb.type) nationalCertificateObj.type = spb.type;
              if (spb.name) nationalCertificateObj.name = spb.name;
            }
            if (student_profile.leaving) {
              let spb = student_profile.leaving;
              if (spb.path) schoolleavingCertificateObj.path = spb.path;
              if (spb.filename)
                schoolleavingCertificateObj.filename = spb.filename;
              if (spb.type) schoolleavingCertificateObj.type = spb.type;
              if (spb.name) schoolleavingCertificateObj.name = spb.name;
            }

            if (student_profile.immunization) {
              let spb = student_profile.immunization;
              if (spb.path) immunisationCertificateObj.path = spb.path;
              if (spb.filename)
                immunisationCertificateObj.filename = spb.filename;
              if (spb.type) immunisationCertificateObj.type = spb.type;
              if (spb.name) immunisationCertificateObj.name = spb.name;
            }

            if (student_profile.others && student_profile.others.length > 0) {
              for (
                let index = 0;
                index < student_profile.others.length;
                index++
              ) {
                const element = student_profile.others[index];
                othersArr.push({
                  path: element.path,
                  filename: element.filename,
                  type: element.type,
                  name: element.name,
                });
              }
            }

            if (student_profile.father) {
              let spf = student_profile.father;
              if (spf.occupation) fatherObj.occupation = spf.occupation;
              if (spf.national_identity) {
                if (spf.national_identity.encrypted_id)
                  fatherObj.national_identity.encrypted_id =
                    spf.national_identity.encrypted_id;
              }
              if (spf.annual_income)
                fatherObj.annual_income = spf.annual_income;
              if (spf.qualification_id)
                fatherObj.qualification_id = spf.qualification_id;
              if (spf.phone_number) fatherObj.phone_number = spf.phone_number;
              if (spf.id) fatherObj.id = spf.id;
              if (spf.name) {
                if (spf.name.first_name)
                  fatherObj.name.first_name = spf.name.first_name;
                if (spf.name.middle_name)
                  fatherObj.name.middle_name = spf.name.middle_name;
                if (spf.name.last_name)
                  fatherObj.name.last_name = spf.name.last_name;
              }
              if (spf.nationality) fatherObj.nationality = spf.nationality;
              if (spf.email_address)
                fatherObj.email_address = spf.email_address;
              if (spf.user_identity) {
                fatherObj.user_identity.encrypted_id =
                  spf.user_identity.encrypted_id;
                fatherObj.user_identity.expiry_date =
                  spf.user_identity.expiry_date;
              }
            }
            // mother section--------------
            if (student_profile.mother) {
              let spm = student_profile.mother;
              if (spm.occupation) motherObj.occupation = spm.occupation;
              if (spm.national_identity) {
                if (spm.national_identity.encrypted_id)
                  motherObj.national_identity.encrypted_id =
                    spm.national_identity.encrypted_id;
              }

              if (spm.annual_income)
                motherObj.annual_income = spm.annual_income;
              if (spm.qualification_id)
                motherObj.qualification_id = spm.qualification_id;
              if (spm.phone_number) motherObj.phone_number = spm.phone_number;
              if (spm.id) motherObj.id = spm.id;
              if (spm.name) {
                if (spm.name.first_name)
                  motherObj.name.first_name = spm.name.first_name;
                if (spm.name.middle_name)
                  motherObj.name.middle_name = spm.name.middle_name;
                if (spm.name.last_name)
                  motherObj.name.last_name = spm.name.last_name;
              }
              if (spm.nationality) motherObj.nationality = spm.nationality;
              if (spm.email_address)
                motherObj.email_address = spm.email_address;
              if (spm.user_identity) {
                motherObj.user_identity.encrypted_id =
                  spm.user_identity.encrypted_id;
                motherObj.user_identity.expiry_date =
                  spm.user_identity.expiry_date;
              }
            }
            // mother section--------------
            // guardian section--------------
            if (student_profile.guardian) {
              let spg = student_profile.guardian;
              if (spg.occupation) guardianObj.occupation = spg.occupation;
              if (spg.national_identity) {
                if (spg.national_identity.encrypted_id)
                  guardianObj.national_identity.encrypted_id =
                    spg.national_identity.encrypted_id;
              }
              if (spg.annual_income)
                guardianObj.annual_income = spg.annual_income;
              if (spg.qualification_id)
                guardianObj.qualification_id = spg.qualification_id;
              if (spg.phone_number) guardianObj.phone_number = spg.phone_number;
              if (spg.id) guardianObj.id = spg.id;
              if (spg.name) {
                if (spg.name.first_name)
                  guardianObj.name.first_name = spg.name.first_name;
                if (spg.name.middle_name)
                  guardianObj.name.middle_name = spg.name.middle_name;
                if (spg.name.last_name)
                  guardianObj.name.last_name = spg.name.last_name;
              }
              if (spg.nationality) guardianObj.nationality = spg.nationality;
              if (spg.email_address)
                guardianObj.email_address = spg.email_address;
              if (spg.user_identity) {
                guardianObj.user_identity.encrypted_id =
                  spg.user_identity.encrypted_id;
                guardianObj.user_identity.expiry_date =
                  spg.user_identity.expiry_date;
              }
            }
            // guardian section--------------
            if (student_profile.national_identity) {
              if (student_profile.national_identity.encrypted_id)
                nationalIdObj.encrypted_id =
                  student_profile.national_identity.encrypted_id;
            }
            if (student_profile.user_identity) {
              if (student_profile.user_identity.encrypted_id)
                userIdentityObj.encrypted_id =
                  student_profile.user_identity.encrypted_id;
              if (student_profile.user_identity.expiry_date)
                userIdentityObj.expiry_date =
                  student_profile.user_identity.expiry_date;
            }
            if (student_profile.currentAddress) {
              if (student_profile.currentAddress.line1)
                line1 = student_profile.currentAddress.line1;
              if (student_profile.currentAddress.line2)
                line2 = student_profile.currentAddress.line2;
              if (student_profile.currentAddress.city)
                city = student_profile.currentAddress.city;
              if (student_profile.currentAddress.state)
                state = student_profile.currentAddress.state;
              if (student_profile.currentAddress.zip_code)
                zip_code = student_profile.currentAddress.zip_code;
              if (student_profile.currentAddress.country)
                country = student_profile.currentAddress.country;
            }
            if (student_profile.permanentAddress) {
              if (student_profile.permanentAddress.line1)
                pline1 = student_profile.permanentAddress.line1;
              if (student_profile.permanentAddress.line2)
                pline2 = student_profile.permanentAddress.line2;
              if (student_profile.permanentAddress.city)
                pcity = student_profile.permanentAddress.city;
              if (student_profile.permanentAddress.state)
                pstate = student_profile.permanentAddress.state;
              if (student_profile.permanentAddress.zip_code)
                pzip_code = student_profile.permanentAddress.zip_code;
              if (student_profile.permanentAddress.country)
                pcountry = student_profile.permanentAddress.country;
            }
            if (
              student_profile.currentAddress &&
              student_profile.permanentAddress
            ) {
              if (
                student_profile.currentAddress.line1 ===
                  student_profile.permanentAddress.line1 &&
                student_profile.currentAddress.line2 ===
                  student_profile.permanentAddress.line2 &&
                student_profile.currentAddress.city ===
                  student_profile.permanentAddress.city &&
                student_profile.currentAddress.state ===
                  student_profile.permanentAddress.state &&
                student_profile.currentAddress.zip_code ===
                  student_profile.permanentAddress.zip_code &&
                student_profile.currentAddress.country ===
                  student_profile.permanentAddress.country
              ) {
                setCheckbox(true);
              }
            }
            if (student_profile.name) {
              if (student_profile.name.first_name)
                userFirstName = student_profile.name.first_name;
              if (student_profile.name.middle_name)
                userMiddleName = student_profile.name.middle_name;
              if (student_profile.name.last_name)
                userLastName = student_profile.name.last_name;
            }
            if (student_profile.joining_date)
              userJoinDate = student_profile.joining_date;
            if (student_profile.gender) userGender = student_profile.gender;
            if (student_profile.birth_date)
              userBirthDate = student_profile.birth_date;
            if (student_profile.email) userEmail = student_profile.email;
            if (student_profile.blood_group)
              userBloodGroup = student_profile.blood_group;
            if (student_profile.nationality)
              userNationality = student_profile.nationality;
            if (student_profile.primary_contact_id)
              userPrimaryContactId = student_profile.primary_contact_id;
            if (student_profile.religion)
              userReligion = student_profile.religion;
            if (student_profile.height) userHeight = student_profile.height;
            if (student_profile.weight) userWeight = student_profile.weight;
          }

          //------------------------------validation------------------------

          await setStudentForm({
            ...studentForm,
            transport_pickup_id: -1,
            transport_drop_id: -1,
            status: userStatus,
            RollNo: userRollNo,
            display_id: userLoginObj.display_id,
            login_id: userLoginObj.login_id,
            Student_first_name: userFirstName,
            Student_middle_name: userMiddleName,
            Student_last_name: userLastName,
            institution_id,
            joining_date: userJoinDate,
            gender: userGender,
            birth_date: userBirthDate,
            email: userEmail,
            Current_line1: line1,
            Current_line2: line2,
            Current_city: city,
            Current_state: state,
            Current_zip_code: zip_code,
            Current_country: country,
            Permanent_line1: pline1,
            Permanent_line2: pline2,
            Permanent_city: pcity,
            Permanent_state: pstate,
            Permanent_zip_code: pzip_code,
            Permanent_country: pcountry,
            blood_group: userBloodGroup,
            S_nationality: userNationality,
            passport_number: userIdentityObj.encrypted_id,
            passport_expiry_date: userIdentityObj.expiry_date,
            national_id_number: nationalIdObj.encrypted_id,
            primary_contact_id: userPrimaryContactId,
            religion: userReligion,
            height: userHeight,
            weight: userWeight,
            Father_id: fatherObj.id,
            Father_first_name: fatherObj.name.first_name,
            Father_middle_name: fatherObj.name.middle_name,
            Father_last_name: fatherObj.name.last_name,
            Father_nationality: fatherObj.nationality,
            Father_email_address: fatherObj.email_address,
            father_passport_number: fatherObj.user_identity.encrypted_id,
            Father_occupation: fatherObj.occupation,
            Father_phone_number: fatherObj.phone_number,
            Father_national_id_number: fatherObj.national_identity.encrypted_id,
            Father_annual_income: fatherObj.annual_income,
            Father_qualification_id: fatherObj.qualification_id,
            // mother section--------------------------------
            Mother_id: motherObj.id,
            Mother_first_name: motherObj.name.first_name,
            Mother_middle_name: motherObj.name.middle_name,
            Mother_last_name: motherObj.name.last_name,
            Mother_nationality: motherObj.nationality,
            Mother_email_address: motherObj.email_address,
            mother_passport_number: motherObj.user_identity.encrypted_id,
            Mother_occupation: motherObj.occupation,
            Mother_phone_number: motherObj.phone_number,
            Mother_national_id_number: motherObj.national_identity.encrypted_id,
            Mother_annual_income: motherObj.annual_income,
            Mother_qualification_id: motherObj.qualification_id,
            // guardian section--------------------------------
            guardian_id: guardianObj.id,
            guardian_first_name: guardianObj.name.first_name,
            guardian_middle_name: guardianObj.name.middle_name,
            guardian_last_name: guardianObj.name.last_name,
            guardian_nationality: guardianObj.nationality,
            guardian_email_address: guardianObj.email_address,
            guardian_passport_number: guardianObj.user_identity.encrypted_id,
            guardian_occupation: guardianObj.occupation,
            guardian_phone_number: guardianObj.phone_number,
            guardian_national_id_number:
              guardianObj.national_identity.encrypted_id,
            guardian_annual_income: guardianObj.annual_income,
            guardian_qualification_id: guardianObj.qualification_id,
            // guardian section ends
            academic_session_id: parseInt(academic_session_id),
            grade_id: sectionObj.grade.id,
            section_id: sectionObj.id,
            roll_number: userRollNo,
            sectionName,
            gradeName,
            education_qualification_id: educationDocArr,

            photo: photoObj,
            birth_cert: birthCertificateObj,
            national_doc: nationalCertificateObj,
            immune_doc: immunisationCertificateObj,
            school_leave_doc: schoolleavingCertificateObj,
            others_doc: othersArr,
          });
          setOtherDocsArr([]);
          setOtherDocsArr(othersArr);
        }
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
      console.log("t and c error", error);
    }
    setisloading(false);
  }, [client, academic_session_id, institution_id]);

  const selectPickupStartHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setStudentForm({
        ...studentForm,
        transport_drop_id: -1,
      });
      return;
    }
    const newArr = pickupStartArr.filter((item) => {
      if (item.value === id) return item;
    });
    setPickupStopName(newArr[0].label);
    setStudentForm({
      ...studentForm,
      transport_drop_id: e.target.value,
    });
  };

  const selectDropStopHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      return;
    }
    const newArr = dropStopArr.filter((item) => {
      if (item.value === id) return item;
    });
    setDropStopName(newArr[0].label);
  };

  const fillAddress = (e) => {
    setCheckbox(e.target.checked);
    if (e.target.checked === true) {
      setStudentForm({
        ...studentForm,
        Permanent_line1: studentForm.Current_line1,
        Permanent_line2: studentForm.Current_line2,
        Permanent_city: studentForm.Current_city,
        Permanent_state: studentForm.Current_state,
        Permanent_zip_code: parseInt(studentForm.Current_zip_code),
        Permanent_country: studentForm.Current_country,
      });
    } else
      setStudentForm({
        ...studentForm,
        Permanent_line1: "",
        Permanent_line2: "",
        Permanent_city: "",
        Permanent_state: "",
        Permanent_zip_code: "",
        Permanent_country: "",
      });
  };

  const selectTransportRoute = async () => {
    try {
      setPickupRouteName("");
      setPickupStopName("");
      setDropRouteName("");
      setDropStopName("");

      setisloading(true);
      await client
        .query({
          query: Service.GET_Route_List_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          let temp = [];
          setisloading(false);
          const tempArr = response.data.getTransportRoute;
          tempArr.map((item) => {
            temp.push({
              label: item.route_name,
              value: item.id,
            });
          });
          setPickupRouteArr(temp);
          setselectroute(true);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectPickupRouteHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setStudentForm({
        ...studentForm,
        transport_pickup_id: -1,
      });
      return;
    }
    const newArr = pickupRouteArr.filter((item) => {
      if (item.value === id) return item;
    });
    setPickupRouteName(newArr[0].label);
    setStudentForm({
      ...studentForm,
      transport_pickup_id: e.target.value,
    });

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Pickup_Route_Stop,
          variables: {
            transport_route_id: id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          const tempArr = response.data.getTransportStop;
          tempArr.map((item) => {
            temp.push({
              label: item.stop_name,
              value: item.id,
            });
          });

          setPickupStartArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("get transport t and c error", error);
    }
  };

  const selectDropRouteHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      return;
    }
    const newArr = pickupRouteArr.filter((item) => {
      if (item.value === id) return item;
    });
    setDropRouteName(newArr[0].label);
    let transport_route_id = parseInt(e.target.value);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Pickup_Route_Stop,
          variables: {
            transport_route_id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getTransportStop.length;
            index++
          ) {
            temp.push({
              label: response.data.getTransportStop[index].stop_name,
              value: response.data.getTransportStop[index].id,
            });
          }
          setDropStopArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const UploadDocumentHandler = async (e, type) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      await axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };

          if (type === "PHOTO") {
            setStudentForm({
              ...studentForm,
              photo: obj,
            });
            //            setphoto(temp);
          }
          if (type === "BIRTH_CERTIFICATE") {
            setStudentForm({
              ...studentForm,
              birth_cert: obj,
            });
            //setbirthCertificate(temp);
          }
          if (type === "NATIONAL_CARD") {
            setStudentForm({
              ...studentForm,
              national_doc: obj,
            });
            // setaadhar(temp);
          }

          if (type === "IMMUNIZATION") {
            setStudentForm({
              ...studentForm,
              immune_doc: obj,
            });
            // setimmune(temp);
          }
          if (type === "SCHOOL_LEAVE_CERTIFICATE") {
            setStudentForm({
              ...studentForm,
              school_leave_doc: obj,
            });

            //  setschoolLeaveCertificate(temp);
          }
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch error", error);
      showNotificationMsz(error.message, "danger");
    }
  };

  const VerifyStudentData = async (studentFormStatus) => {
    try {
      let formStatus = studentFormStatus;
      if (studentFormStatus == "Form Submission Pending") {
        formStatus = "Verification Pending";
      }

      const {
        Father_nationality,
        Father_email_address,
        father_passport_number,
        Father_occupation,
        Father_phone_number,
        Father_national_id_number,
        Father_annual_income,
        Father_qualification_id,
        Mother_nationality,
        Mother_email_address,
        mother_passport_number,
        Mother_occupation,
        Mother_phone_number,
        Mother_national_id_number,
        Mother_annual_income,
        Mother_qualification_id,
        guardian_nationality,
        guardian_email_address,
        guardian_passport_number,
        guardian_occupation,
        guardian_phone_number,
        guardian_national_id_number,
        guardian_annual_income,
        guardian_qualification_id,
        email,
        grade_id,
        section_id,
        roll_number,
        Student_first_name,
        Student_middle_name,
        Student_last_name,

        joining_date,
        gender,
        birth_date,
        Current_line1,
        Current_line2,
        Current_state,
        Current_zip_code,
        Current_country,
        Permanent_line1,
        Permanent_line2,
        Permanent_state,
        Permanent_zip_code,
        Permanent_country,
        blood_group,
        S_nationality,
        passport_number,
        passport_expiry_date,
        national_id_number,
        religion,
        height,
        weight,
        Father_first_name,
        Father_middle_name,
        Father_last_name,
        Mother_first_name,
        Mother_middle_name,
        Mother_last_name,
        guardian_first_name,
        guardian_middle_name,
        guardian_last_name,
        photo,
        birth_cert,
        national_doc,
      } = studentForm;
      //------------------ validation --------------------------
      let validationDone = false;
      let scroll = false;

      if (!Student_first_name) {
        setStudentFirstNameError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("s_firstNamee").focus();
        }
        scroll = true;
      }
      if (!gender) {
        setGenderError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("gender").focus();
        }
        scroll = true;
      }
      if (!birth_date) {
        setBirthdateError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("s_birthdate").focus();
        }
        scroll = true;
      }
      if (!S_nationality) {
        setNationalityError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("s_nationality").focus();
        }
        scroll = true;
      }
      if (!Current_line1) {
        setCaddressline1Error(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("c_addressline1").focus();
        }
        scroll = true;
      }
      if (!Current_country) {
        setCcountryError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("C_country").focus();
        }
        scroll = true;
      }
      if (!Current_state) {
        setCstateError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("C_state").focus();
        }
        scroll = true;
      }
      if (!Current_zip_code || Current_zip_code == -1) {
        setCPincodeError(true);
        validationDone = true;
        if (scroll === false) {
          document.getElementById("C_zip_code").focus();
        }
        scroll = true;
      }
      if (!Permanent_line1) {
        setPaddressline1Error(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("P_addressline1").focus();
        }
        scroll = true;
      }
      if (!Permanent_country) {
        setPcountryError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("P_Country").focus();
        }
        scroll = true;
      }
      if (!Permanent_state) {
        setPstateError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("P_State").focus();
        }
        scroll = true;
      }
      if (!Permanent_zip_code || Permanent_zip_code == -1) {
        setPPincodeError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("P_zip_code").focus();
        }
        scroll = true;
      }
      if (!Mother_first_name) {
        setMfirstnameError(true);
        setisMotherAccordian(true);
        validationDone = true;
      }
      if (!Mother_email_address) {
        setMEmailError(true);
        validationDone = true;
        setisMotherAccordian(true);
      }
      if (!Mother_phone_number) {
        setMPhoneError(true);
        validationDone = true;
        setisMotherAccordian(true);
      }
      if (!Father_first_name) {
        setFfirstnameError(true);
        validationDone = true;
        setisFatherAccordian(true);
      }
      if (!Father_email_address) {
        setFEmailError(true);
        validationDone = true;
        setisFatherAccordian(true);
      }
      if (!Father_phone_number) {
        setFPhoneError(true);
        validationDone = true;
        setisFatherAccordian(true);
      }

      if (!photo || photo.name === "") {
        setphotoError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("photo").focus();
        }
        scroll = true;
      }

      if (!birth_cert || birth_cert.name === "") {
        setbirth_certificateError(true);
        validationDone = true;
        if (!scroll) {
          document.getElementById("birth").focus();
        }
        scroll = true;
      }

      if (validationDone) {
        return;
      }
      setisloading(true);
      const response = await Update_StudentForm_API({
        variables: {
          id: parseInt(studentId),
          transport_drop_id: parseInt(studentForm.transport_drop_id),
          transport_pickup_id: parseInt(studentForm.transport_pickup_id),
          status: formStatus,
          Father_nationality,
          Father_email_address,
          father_passport_number,
          father_passport_expiry_date: "",
          Father_occupation,
          Father_phone_number,
          Father_national_id_number,
          Father_annual_income,
          Father_qualification_id: parseInt(Father_qualification_id),

          Mother_nationality,
          Mother_email_address,
          mother_passport_number,
          mother_passport_expiry_date: "",
          Mother_occupation,
          Mother_phone_number,
          Mother_national_id_number,
          Mother_annual_income,
          Mother_qualification_id: parseInt(Mother_qualification_id),

          guardian_nationality,
          guardian_email_address,
          guardian_passport_number,
          guardian_passport_expiry_date: "",
          guardian_occupation,
          guardian_phone_number,
          guardian_national_id_number,
          guardian_annual_income,
          guardian_qualification_id: parseInt(guardian_qualification_id),

          academic_session_id,
          email,
          join_grade_id: parseInt(grade_id),
          section_id: parseInt(section_id),
          roll_number,
          Student_first_name,
          Student_middle_name,
          Student_last_name,
          institution_id, // imp
          joining_date: serverDateFormat(joining_date),
          gender,
          birth_date: serverDateFormat(birth_date),
          Current_line1,
          Current_line2,
          Current_city: "",
          Current_state,
          Current_zip_code: parseInt(Current_zip_code),
          Current_country,
          Permanent_line1,
          Permanent_line2,
          Permanent_city: "",
          Permanent_state,
          Permanent_zip_code: parseInt(Permanent_zip_code),
          Permanent_country,
          blood_group,
          S_nationality,
          passport_number,
          passport_expiry_date: serverDateFormat(passport_expiry_date),

          national_id_number,
          religion,
          height: parseInt(height),
          weight: parseInt(weight),
          Father_first_name,
          Father_middle_name,
          Father_last_name,
          Mother_first_name,
          Mother_middle_name,
          Mother_last_name,
          guardian_first_name,
          guardian_middle_name,
          guardian_last_name,

          photo,
          national: national_doc,
          birth_cert: birth_cert,
          immunization_doc: studentForm.immune_doc,
          leaving_cert_doc: studentForm.school_leave_doc,
          other: otherDocsArr,
        },
      });
      setisloading(false);

      showNotificationMsz(response.data.updateStudent.message, "success");
      navigate.goBack();
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };

  const submitRouteHandler = () => {
    setselectroute(false);
  };

  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };

  // upload documents
  const uploadMultipleFiles = async (event) => {
    try {
      if (otherDocsArr.length > 3) {
        showNotificationMsz("You can not upload more than 4 files.", "info");
        return;
      }
      setisloading(true);
      const url = `${Service.baseUrl}/uploadFile/image`;
      const file = event.target.files[0];
      let fileData = new FormData();
      fileData.append("file", file);
      await axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const { path, filename, type, name } = response.data;

          const obj = {
            path,
            filename,
            type,
            name,
          };
          setOtherDocsArr([...otherDocsArr, obj]);
          showNotificationMsz("File uploaded successfully", "success");
          setisloading(false);
        })
        .catch((error) => {
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error.message, "danger");
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institute_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Student Profile</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={2}>
                            <div className="inputMarginright">
                              <img
                                src={`${Service.baseUrl}/${studentForm.photo.path}`}
                                className="student_photo_div"
                                alt="No_profile"
                              />
                            </div>
                          </Grid>
                          <Grid item md={5}>
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                <p>ID : {studentForm.display_id}</p>
                              </Card>
                            </div>
                            <div className="inputMarginright mt-2 card_invoicedetails_border">
                              <Card className="p-1">
                                <p>Status : {studentForm.status}</p>
                              </Card>
                            </div>
                          </Grid>
                          <Grid item md={5}>
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                <p>Roll No : {studentForm.roll_number}</p>
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Student Details</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Class</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={studentForm.gradeName}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Section</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={studentForm.sectionName}
                                disabled={true}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date Of Joining
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={studentForm.joining_date}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    joining_date: e.target.value,
                                  });
                                }}
                                disabled={true}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="text_filed_heading ">Student Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="s_firstNamee"
                                  autoComplete="off"
                                  value={studentForm.Student_first_name}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Student_first_name: e.target.value,
                                    });
                                    setStudentFirstNameError(false);
                                  }}
                                  disabled={true}
                                />
                                {StudentFirstNameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.Student_middle_name}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Student_middle_name: e.target.value,
                                    });
                                  }}
                                  disabled={true}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.Student_last_name}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Student_last_name: e.target.value,
                                    });
                                  }}
                                  disabled={true}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date of Birth
                              <span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="s_birthdate"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.birth_date}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    birth_date: e.target.value,
                                  });
                                  setBirthdateError(false);
                                }}
                              />
                              {BirthdateError && (
                                <span className="text-danger">
                                  Enter Date of Birth
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Gender<span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="gender"
                                value={studentForm.gender}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    gender: e.target.value,
                                  });
                                  setGenderError(false);
                                }}
                                disabled={true}
                              >
                                <option value="">Select </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                              {genderError && (
                                <span className="text-danger">
                                  Select Gender
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Blood Group
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={studentForm.blood_group}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    blood_group: e.target.value,
                                  });
                                }}
                                disabled={true}
                              >
                                <option value="">Blood Group</option>
                                <option value="A+">A+</option>
                                <option value="B+">B+</option>
                                <option value="AB+">AB+</option>
                                <option value="O+">O+</option>
                                <option value="A-">A-</option>
                                <option value="B-">B-</option>
                                <option value="AB-">AB-</option>
                                <option value="O-">O-</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Email</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={studentForm.email}
                                disabled={true}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    email: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.passport_number}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Expiry Date
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.passport_expiry_date}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    passport_expiry_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.national_id_number}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">Religion</div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                disabled={true}
                                value={studentForm.religion}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    religion: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Select </option>
                                {religion.map((religion) => (
                                  <option value={religion.value}>
                                    {religion.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality<span className="text-danger"> *</span>
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="s_nationality"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.S_nationality}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    S_nationality: e.target.value,
                                  });
                                  setNationalityError(false);
                                }}
                              />
                              {NationalityError && (
                                <span className="text-danger">
                                  Enter Nationality
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Height (in cm)
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={
                                  studentForm.height == -1
                                    ? ""
                                    : studentForm.height
                                }
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    height: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Weight (in kg)
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={
                                  studentForm.weight == -1
                                    ? ""
                                    : studentForm.weight
                                }
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    weight: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Address</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Current Address
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="text_filed_heading mt-1">
                              Address Line 1
                              <span className="text-danger"> *</span>
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="c_addressline1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Current_line1}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Current_line1: e.target.value,
                                  });
                                  setCaddressline1Error(false);
                                }}
                              />
                              {Caddressline1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>
                            <div className="text_filed_heading mt-1">
                              Address Line 2
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Current_line2}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Current_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Country</div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                value={studentForm.Current_country}
                                id="C_country"
                                disabled={true}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Current_country: e,
                                  });
                                  setCcountryError(false);
                                }}
                                className="country_drop_height_width"
                              />
                              {CcountryError && (
                                <span className="text-danger">
                                  Select the Country
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              State<span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                id="C_state"
                                country={studentForm.Current_country}
                                value={studentForm.Current_state}
                                disabled={true}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Current_state: e,
                                  });
                                  setCstateError(false);
                                }}
                                className="country_drop_height_width"
                              />
                              {CstateError && (
                                <span className="text-danger">
                                  Select the State
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Pin Code<span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="C_zip_code"
                                autoComplete="off"
                                disabled={true}
                                value={
                                  studentForm.Current_zip_code == -1
                                    ? ""
                                    : studentForm.Current_zip_code
                                }
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Current_zip_code: e.target.value,
                                  });
                                  setCPincodeError(false);
                                }}
                              />
                              {CPincodeError && (
                                <span className="text-danger">
                                  Enter the Pin code
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="d-flex mt-3">
                          <span className="text_filed_heading">
                            Permanent Address
                          </span>
                          <span className="ml-5">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="filladdress"
                                onClick={(e) => fillAddress(e)}
                                checked={checkbox}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckChecked"
                              >
                                Same As Current Address
                              </label>
                            </div>
                          </span>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="text_filed_heading mt-1">
                              Address Line 1
                              <span className="text-danger"> *</span>
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="P_addressline1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Permanent_line1}
                                onChange={(e) => {
                                  setPaddressline1Error(false);
                                  setStudentForm({
                                    ...studentForm,
                                    Permanent_line1: e.target.value,
                                  });
                                }}
                              />
                              {Paddressline1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>

                            <div className="text_filed_heading mt-1">
                              Address Line 2
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Permanent_line2}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Permanent_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Country</div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                id="P_Country"
                                disabled={true}
                                value={studentForm.Permanent_country}
                                onChange={(e) => {
                                  setPcountryError(false);
                                  setStudentForm({
                                    ...studentForm,
                                    Permanent_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {PcountryError && (
                                <span className="text-danger">
                                  Select the Country
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              State<span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                id="P_State"
                                disabled={true}
                                country={studentForm.Permanent_country}
                                value={studentForm.Permanent_state}
                                onChange={(e) => {
                                  setPstateError(false);
                                  setStudentForm({
                                    ...studentForm,
                                    Permanent_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {PstateError && (
                                <span className="text-danger">
                                  Select the State
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Pin Code<span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="P_zip_code"
                                autoComplete="off"
                                disabled={true}
                                value={
                                  studentForm.Permanent_zip_code == -1
                                    ? ""
                                    : studentForm.Permanent_zip_code
                                }
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Permanent_zip_code: e.target.value,
                                  });
                                  setPPincodeError(false);
                                }}
                              />
                              {PPincodeError && (
                                <span className="text-danger">
                                  Enter the Pin code
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">
                    Parent/Legal Guardian's Details
                  </div>
                  <Accordion
                    className="assugment_border"
                    expanded={isMotherAccordian}
                    onChange={() => setisMotherAccordian(!isMotherAccordian)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="text_filed_heading">
                        Mother
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ width: "100%" }}>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="M_name"
                                  autoComplete="off"
                                  disabled={true}
                                  value={studentForm.Mother_first_name}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Mother_first_name: e.target.value,
                                    });
                                    setMfirstnameError(false);
                                  }}
                                />
                                {MfirstnameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled={true}
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.Mother_middle_name}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Mother_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  disabled={true}
                                  autoComplete="off"
                                  value={studentForm.Mother_last_name}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Mother_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Mother_nationality}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_nationality: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address
                              <span className="text-danger"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="M_email"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Mother_email_address}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_email_address: e.target.value,
                                  });
                                  setMEmailError(false);
                                }}
                              />
                              {MEmailError && (
                                <span className="text-danger">
                                  Enter Email Address
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.mother_passport_number}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    mother_passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Occupation</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Mother_occupation}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_occupation: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Mother_national_id_number}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                              <span className="text-danger"> *</span>
                            </div>
                            <div
                              className="inputMarginright mt-1"
                              id="M_mobile"
                            >
                              <PhoneInput
                                countryCodeEditable={false}
                                className="phone_input_width_create"
                                country={"in"}
                                value={studentForm.Mother_phone_number}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_phone_number: e,
                                  });
                                  setMPhoneError(false);
                                }}
                              />
                              {MPhoneError && (
                                <span className="text-danger">
                                  Enter Contact Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Annual Income
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                disabled={true}
                                value={studentForm.Mother_annual_income}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_annual_income: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Highest Education Qualification
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                disabled={true}
                                value={studentForm.Mother_qualification_id}
                                onChange={(e) => {
                                  setStudentForm({
                                    ...studentForm,
                                    Mother_qualification_id: e.target.value,
                                  });
                                }}
                              >
                                <option>Select</option>
                                {highestQualificationData.map((data, index) => (
                                  <option value={data.value}>
                                    {data.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <div className="mt-2">
                    <Accordion
                      className="assugment_border"
                      expanded={isFatherAccordian}
                      onChange={() => setisFatherAccordian(!isFatherAccordian)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="pahelFather"
                      >
                        <Typography className="text_filed_heading">
                          Father
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ width: "100%" }}>
                          <div className="name_outside_border p-2">
                            <Grid className="grid_main_div">
                              <Grid item md={4}>
                                <div className="text_filed_heading">
                                  First Name
                                  <span className="text-danger"> *</span>
                                </div>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="F_name"
                                    disabled={true}
                                    autoComplete="off"
                                    value={studentForm.Father_first_name}
                                    onChange={(e) => {
                                      setStudentForm({
                                        ...studentForm,
                                        Father_first_name: e.target.value,
                                      });
                                      setFfirstnameError(false);
                                    }}
                                  />
                                  {FfirstnameError && (
                                    <span className="text-danger">
                                      Enter First Name
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className="text_filed_heading">
                                  Middle Name
                                </div>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    disabled={true}
                                    autoComplete="off"
                                    value={studentForm.Father_middle_name}
                                    onChange={(e) => {
                                      setStudentForm({
                                        ...studentForm,
                                        Father_middle_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className="text_filed_heading">
                                  Last Name
                                </div>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    disabled={true}
                                    autoComplete="off"
                                    value={studentForm.Father_last_name}
                                    onChange={(e) => {
                                      setStudentForm({
                                        ...studentForm,
                                        Father_last_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <Grid className="grid_main_div gapfromanotherinput">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Nationality
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  disabled={true}
                                  value={studentForm.Father_nationality}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_nationality: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Email Address
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="F_email"
                                  disabled={true}
                                  autoComplete="off"
                                  value={studentForm.Father_email_address}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_email_address: e.target.value,
                                    });
                                    setFEmailError(false);
                                  }}
                                />
                                {FEmailError && (
                                  <span className="text-danger">
                                    Enter the Email Address
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Passport Number
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.father_passport_number}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      father_passport_number: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div gapfromanotherinput">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Occupation
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.Father_occupation}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_occupation: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                National ID
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.Father_national_id_number}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_national_id_number: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Contact Number
                                <span className="text-danger"> *</span>
                              </div>
                              <div
                                className="inputMarginright mt-1"
                                id="F_mobile"
                              >
                                <PhoneInput
                                  countryCodeEditable={false}
                                  className="phone_input_width_create"
                                  country={"in"}
                                  value={studentForm.Father_phone_number}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_phone_number: e,
                                    });
                                    setFPhoneError(false);
                                  }}
                                />
                                {FPhoneError && (
                                  <span className="text-danger">
                                    Enter the Phone Number
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div gapfromanotherinput mb-2">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Annual Income
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.Father_annual_income}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_annual_income: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Highest Education Qualification
                              </div>
                              <div className="inputMarginright mt-1">
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  value={studentForm.Father_qualification_id}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      Father_qualification_id: e.target.value,
                                    });
                                  }}
                                >
                                  <option>Select</option>
                                  {highestQualificationData.map(
                                    (data, index) => (
                                      <option value={data.value}>
                                        {data.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </Grid>
                            <Grid item md={4}></Grid>
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="mt-2">
                    <Accordion className="assugment_border">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panelMother"
                      >
                        <Typography className="text_filed_heading">
                          Legal Guardian's Name
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ width: "100%" }}>
                          <div className="name_outside_border p-2">
                            <Grid className="grid_main_div">
                              <Grid item md={4}>
                                <div className="text_filed_heading">
                                  First Name
                                </div>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    disabled={true}
                                    autoComplete="off"
                                    value={studentForm.guardian_first_name}
                                    onChange={(e) => {
                                      setStudentForm({
                                        ...studentForm,
                                        guardian_first_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className="text_filed_heading">
                                  Middle Name
                                </div>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    id="exampleFormControlInput1"
                                    autoComplete="off"
                                    value={studentForm.guardian_middle_name}
                                    onChange={(e) => {
                                      setStudentForm({
                                        ...studentForm,
                                        guardian_middle_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div className="text_filed_heading">
                                  Last Name
                                </div>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    id="exampleFormControlInput1"
                                    autoComplete="off"
                                    value={studentForm.guardian_last_name}
                                    onChange={(e) => {
                                      setStudentForm({
                                        ...studentForm,
                                        guardian_last_name: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <Grid className="grid_main_div gapfromanotherinput">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Nationality
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.guardian_nationality}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_nationality: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Email Address
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled={true}
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.guardian_email_address}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_email_address: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Passport Number
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.guardian_passport_number}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_passport_number: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div gapfromanotherinput">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Occupation
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.guardian_occupation}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_occupation: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                National ID
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={
                                    studentForm.guardian_national_id_number ===
                                    -1
                                      ? ""
                                      : studentForm.guardian_national_id_number
                                  }
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_national_id_number:
                                        e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Contact Number
                              </div>
                              <div className="inputMarginright mt-1">
                                <PhoneInput
                                  countryCodeEditable={false}
                                  className="phone_input_width_create"
                                  country={"in"}
                                  value={studentForm.guardian_phone_number}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_phone_number: e,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div gapfromanotherinput mb-2">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Annual Income
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={studentForm.guardian_annual_income}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_annual_income: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Highest Education Qualification
                              </div>
                              <div className="inputMarginright mt-1">
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  value={studentForm.guardian_qualification_id}
                                  onChange={(e) => {
                                    setStudentForm({
                                      ...studentForm,
                                      guardian_qualification_id: e.target.value,
                                    });
                                  }}
                                >
                                  <option>Select</option>
                                  {highestQualificationData.map(
                                    (data, index) => (
                                      <option value={data.value}>
                                        {data.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </Grid>
                            <Grid item md={4}></Grid>
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <Card className="mt-2">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3">
                          <div className="text_filed_heading">
                            Primary Contact
                            <span className="text-danger"> *</span>
                          </div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked={
                                studentForm.primary_contact_id ==
                                studentForm.Mother_id
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Mother
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              checked={
                                studentForm.primary_contact_id ==
                                studentForm.Father_id
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Father
                            </label>
                          </div>

                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                              checked={
                                studentForm.primary_contact_id ==
                                studentForm.guardian_id
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault3"
                            >
                              Guardian
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* <div className=" mt-1 Heading_bold">Academic Information</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Previous School Name
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Class</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Year Of Passing
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Board/University Name
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card> */}

                  <div className=" mt-1 Heading_bold">Transport</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={2}>
                            <div className="form-check">
                              {buttonCheck}
                              <input
                                checked={buttonCheck}
                                className="form-check-input"
                                type="checkbox"
                                value={buttonCheck}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    setPickupRouteName("");
                                    setPickupStopName("");
                                    setDropRouteName("");
                                    setDropStopName("");
                                  }

                                  setbuttonCheck(e.target.checked);
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckChecked"
                              >
                                Need Transport
                              </label>
                            </div>
                          </Grid>
                          <Grid item md={7}></Grid>
                          <Grid item md={3}>
                            {buttonCheck ? (
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration "
                                onClick={selectTransportRoute}
                              >
                                Select Transport Route
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                className="student_Form_taransport_able_button button_login_decoration "
                                disabled={true}
                              >
                                Select Transport Route
                              </Button>
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Pick Up</div>
                            <div className=" mt-1">
                              <p>Route Name - {pickupRouteName}</p>
                              <p>Stop Name - {pickupStopName} </p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Drop</div>
                            <div className=" mt-1">
                              <p>Route Name - {dropRouteName}</p>
                              <p>Stop Name - {dropStopName}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Document Upload</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {/* //------- */}
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Photo <span className="text-danger"> *</span>
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                id="photo"
                                type="file"
                                disabled={true}
                                className="nofilechoosenhide"
                                onChange={(e) => {
                                  UploadDocumentHandler(e, "PHOTO");
                                  setphotoError(false);
                                }}
                              />

                              {blankValidator(studentForm.photo) &&
                                blankValidator(studentForm.photo.name) && (
                                  <Chip
                                    label={studentForm.photo.name.substring(
                                      0,
                                      25
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(studentForm.photo);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                            {photoError && (
                              <span className="text-danger">
                                Upload the Profile Photo
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        {/* //------ */}

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Birth Certificate{" "}
                            <span className="text-danger"> *</span>
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                id="birth"
                                disabled={true}
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) => {
                                  UploadDocumentHandler(e, "BIRTH_CERTIFICATE");
                                  setbirth_certificateError(false);
                                }}
                              />

                              {blankValidator(studentForm.birth_cert) &&
                                blankValidator(studentForm.birth_cert.name) && (
                                  <Chip
                                    label={studentForm.birth_cert.name.substring(
                                      0,
                                      25
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(
                                        studentForm.birth_cert
                                      );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                            {birth_certificateError && (
                              <span className="text-danger">
                                Upload the Birth Certificate
                              </span>
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            National Certificate
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                disabled={true}
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "NATIONAL_CARD")
                                }
                              />

                              {blankValidator(studentForm.national_doc) &&
                                blankValidator(
                                  studentForm.national_doc.name
                                ) && (
                                  <Chip
                                    label={studentForm.national_doc.name.substring(
                                      0,
                                      25
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(
                                        studentForm.national_doc
                                      );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Immunization Details
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                id="Photo"
                                disabled={true}
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "IMMUNIZATION")
                                }
                              />

                              {blankValidator(studentForm.immune_doc) &&
                                blankValidator(studentForm.immune_doc.name) && (
                                  <Chip
                                    label={studentForm.immune_doc.name.substring(
                                      0,
                                      25
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(
                                        studentForm.immune_doc
                                      );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            School Leaving Certificate
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                id="Photo"
                                disabled={true}
                                onChange={(e) =>
                                  UploadDocumentHandler(
                                    e,
                                    "SCHOOL_LEAVE_CERTIFICATE"
                                  )
                                }
                              />

                              {blankValidator(studentForm.school_leave_doc) &&
                                blankValidator(
                                  studentForm.school_leave_doc.name
                                ) && (
                                  <Chip
                                    label={studentForm.school_leave_doc.name.substring(
                                      0,
                                      25
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(
                                        studentForm.school_leave_doc
                                      );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Add Any
                          </Grid>
                          <Grid item md={2}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              disabled={true}
                              multiple
                              onChange={(e) => uploadMultipleFiles(e)}
                            />
                          </Grid>

                          <Grid item md={6}>
                            {otherDocsArr.length !== 0 &&
                              otherDocsArr.map((item, index) => (
                                <Chip
                                  className="ml-1"
                                  label={item.name}
                                  color="primary"
                                  variant="outlined"
                                />
                              ))}
                          </Grid>
                        </Grid>
                        <Grid className="mt-3 mb-2"></Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={selectroute}
        onClose={() => {
          setselectroute(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
      >
        <div className="Select_route_transport">
          <DialogTitle>
            Select Route
            <span
              className="float-right"
              onClick={() => {
                setselectroute(false);
              }}
            >
              <i className="fa fa-times cursor" aria-hidden="true"></i>{" "}
            </span>
            <hr />
          </DialogTitle>

          <div className="card_admissiondetails_height mt-2 mb-2">
            <div className="textfiled_margin">
              <div className="text_filed_heading">Transport Route-Pick Up</div>
              <Grid className="grid_main_div">
                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => selectPickupRouteHandler(e)}
                    >
                      <option value={-1}>select Pickup Route</option>
                      {pickupRouteArr.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => selectPickupStartHandler(e)}
                    >
                      <option value={-1}>Select Stop</option>
                      {pickupStartArr.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="card_admissiondetails_height mt-2 mb-2">
            <div className="textfiled_margin">
              <div className="text_filed_heading">Transport Route-Drop</div>
              <Grid className="grid_main_div">
                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => selectDropRouteHandler(e)}
                    >
                      <option value={-1}>select drop Route </option>
                      {pickupRouteArr.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => selectDropStopHandler(e)}
                    >
                      <option value={-1}>Select Stop</option>
                      {dropStopArr.map((item, index) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </Grid>

              <Grid className="grid_main_div mt-2 mb-2">
                <Grid item md={10}></Grid>

                <Grid item md={2}>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration button_width"
                    onClick={submitRouteHandler}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={OpenPhotoImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenPhotoImageDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + impagepath}
              download
              target="_blank"
              className="mr-3"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenPhotoImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + impagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(ViewStudentForm)));
