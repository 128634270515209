import React, { Component } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Report.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import userimage from "../Transport/userimage.png";
import Holiday from "../../Common/Holiday/Holiday.jsx";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, id, designation, gender, joindate, bloodgroup) {
  return { name, id, designation, gender, joindate, bloodgroup };
}

const rows = [
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
  createData("Mark willy", "15230242", "Lorem ipsum", "Male", "12/10/18", "A+"),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
function AllStaffReport(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div className="main_back_color1">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 Heading_bold">Report</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-4 mb-4">
                          <Grid item md={8}>
                            <div className="List_heading_formatting">
                              All Staff - Department/Subject
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="d-flex">
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Staff Name"
                                  autoComplete="off"
                                />
                              </div>
                              <div className="btn_add mt-1 ml-2">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn button_login_decoration button_width"
                                >
                                  Search
                                </Button>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-3 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Profile
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    ID No.
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Designation
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Gender
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Joining Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Blood Group
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : rows
                                ).map((row, index) => (
                                  <StyledTableRow key={row.title}>
                                    <StyledTableCell align="left">
                                      <img
                                        src={userimage}
                                        alt=""
                                        className="img_formatting"
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      id:{row.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.designation}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.gender}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.joindate}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.bloodgroup}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default (HOC1(AllStaffReport));
