import React, { Component } from "react";
import "./SignIn.css";
import HOC from "../../Hoc.jsx";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const config = require("../../../config/default.json");

class ForgotUserId extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userid: "",
      recaptchErr: false,
      recaptchaValue: null,
      userIdErr: false,
    };
  }

  createNotification = (type, message, title) => {
    try {
      switch (type) {
        case "info":
          NotificationManager.info(message);
          break;
        case "success":
          NotificationManager.success(message, title);
          break;
        case "warning":
          NotificationManager.warning(message);
          break;
        case "error":
          NotificationManager.error(message);
          break;
        default:
          return null;
      }
    } catch (error) {
      console.log(error);
    }
  };
  forgetUserIdHandler = () => {
    const { userid ,recaptchaValue} = this.state;
    if (!userid) {
      this.setState({ userIdErr: true });
      return;
    }
    if (!recaptchaValue) {
      this.setState({ recaptchErr: true });
      return;
    }
  };
  callback = () => {
    console.log("Done!!!!");
  };
  render() {
    const { recaptchErr, userIdErr, userid } = this.state;
    return (
      <div>
        <div className="main_back_color">
          <div className="main_div_Sigin">
            <div className="form_width">
              <p className="signupheading">Forgot User id</p>
              <div className="main_padding_top_bottom">
                <div className=" inputfiledformatting">
                  <label
                    for="exampleInputEmail1"
                    className="text_field_heading float-left"
                  >
                    User Id
                  </label>
                  <input
                    type="text"
                    className="form-control input_margin_gap"
                    placeholder="User Id"
                    value={userid}
                    onChange={(e) => {
                      if (userIdErr) this.setState({ userIdErr: false });
                      this.setState({
                        userid: e.target.value,
                      });
                    }}
                  />
                  {userIdErr && <p className="text-danger">Enter userId</p>}
                </div>

                <div>
                  <p className="inputfiledformatting termspoilcy1">
                    If the address matches an account in the system, you will be
                    send an email with your user Id.
                  </p>
                </div>
                <div className=" inputfiledformatting recaptacode">
                  <p>
                    <ReCAPTCHA
                      sitekey={config.config.siteKey}
                      onChange={this.callback}
                      onChange={(code) => {
                        if (recaptchErr) this.setState({ recaptchErr: false });
                        this.setState({ recaptchaValue: code });
                      }}
                    />
                  </p>
                  {recaptchErr && (
                    <p className="text-danger text-left">
                      Please validate recaptcha
                    </p>
                  )}
                </div>
                <div className="inputfiledformatting buttonpadding mt-3">
                  <Button
                    variant="contained"
                    className="login_button button_login_decoration"
                    onClick={this.forgetUserIdHandler}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

export default (HOC(ForgotUserId));
