import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getData } from "../../api";
import Hoc1 from "../Hoc1";
import * as Constants from "../../action/appAction";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Holiday from "../Common/Holiday/Holiday";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loder from "../Loder/Loder";
//redux
import * as Service from "../../services/index";
import { blankValidator } from "../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import Assigned from "./Assignment/Teacherhomepagetab/Assigned";
import Completed from "./Assignment/Teacherhomepagetab/Completed";
import Created from "./Assignment/Teacherhomepagetab/Created";
import Drafted from "./Assignment/Teacherhomepagetab/Drafted";
import RevewPending from "./Assignment/Teacherhomepagetab/RevewPending";
import OnlineClass from "./OnlineClassTeacher.jsx";

const PtTeacherAssignment = (props) => {
  const client = useApolloClient();
  const teacher_id = parseInt(props.data.userid);
  const institution_id = parseInt(props.data.institution_id);
  let academic_session_id = parseInt(props.dataacademic.id);

  const [isloading, setisloading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [gradeid, setgradeid] = useState(-1);
  const [section, setsection] = useState([]);
  const [sectionid, setsectionid] = useState(-1);
  const [managegrade, setmanagegrade] = useState([]);
  const [subjectarr, setsubjectarr] = useState([]);
  const [subjectid, setsubjectid] = useState(-1);
  const [searchbyassignmnetname, setsearchbyassignmnetname] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setmanagegrade(response.data.getGrade);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    const getsubjectlist = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setsubjectarr(response.data.getSubject);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getManageGrades();
    getsubjectlist();
  }, [client, academic_session_id, institution_id]);

  const selectHandler = (id) => {
    let grade_id = parseInt(id);
    setgradeid(grade_id);
    setsection([]);
    setsectionid(-1);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setsection(response.data.getSectionAndGrade);
          // setSectionfromgrade(response.data.Sections)
          //setname("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const searchBysection = (val) => {
    setsectionid(parseInt(val));
  };
  const searchBysubject = async (val) => {
    setsubjectid(val);
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.data.institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="ml-3 mt-3 Heading_bold ">
                  <div>Assignments</div>
                  <div className="ml-2 mr-2 tabs_class_for_Mobile">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                      indicatorColor="primary"
                      textColor="primary"
                      lefted
                    >
                      <Tab
                        label="ReviewPending"
                        {...a11yProps(0)}
                        className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                      />
                      <Tab
                        label="Draft"
                        {...a11yProps(1)}
                        className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                      />
                      <Tab
                        label="Created"
                        {...a11yProps(2)}
                        className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                      />
                      <Tab
                        label="Assigned"
                        {...a11yProps(3)}
                        className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                      />
                      <Tab
                        label="Completed"
                        {...a11yProps(4)}
                        className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                      />
                    </Tabs>
                  </div>
                </div>
                <div className="mr-3 mt-3 ">
                  <span
                    className="mr-3 ml-4 openassignemntcolor mobile_font_size"
                    onClick={() =>
                      props.navigate("/addNewAssignment", {
                        state: {
                          teacher_id: teacher_id,
                        },
                      })
                    }
                  >
                    Create Assignment
                  </span>
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={3}>
                          {value !== 1 && value !== 2 ? (
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={gradeid}
                                onChange={(e) => selectHandler(e.target.value)}
                              >
                                <option value={-1}>Search By Grade</option>

                                {managegrade.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search Assignment"
                                autoComplete="off"
                                value={searchbyassignmnetname}
                                onChange={(e) => {
                                  setsearchbyassignmnetname(e.target.value);
                                }}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item md={3}>
                          {value !== 1 && value !== 2 ? (
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  searchBysection(e.target.value)
                                }
                              >
                                <option value={-1}>Search By Section</option>
                                {section.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  searchBysubject(e.target.value)
                                }
                              >
                                <option value={-1}>Search By Subject</option>
                                {subjectarr.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </Grid>

                        <Grid item md={3}>
                          {value !== 1 && value !== 2 ? (
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  searchBysubject(e.target.value)
                                }
                              >
                                <option value={-1}>Search By Subject</option>
                                {subjectarr.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid item md={3}></Grid>
                      </Grid>

                      <div className="Pannel_padding_hidden mb-2">
                        <TabPanel value={value} index={0}>
                          <div className="mt-3">
                            <RevewPending
                              navigation={props}
                              teacher_id={teacher_id}
                              academic_session_id={academic_session_id}
                              status={"ASSIGNED"}
                              subjectid={subjectid}
                              gradeid={gradeid}
                              sectionid={sectionid}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <div className="mt-3">
                            <Drafted
                              navigation={props}
                              teacher_id={teacher_id}
                              academic_session_id={academic_session_id}
                              status={"DRAFT"}
                              subjectid={subjectid}
                              searchbyassignmnetname={searchbyassignmnetname}
                              gradeid={gradeid}
                              sectionid={sectionid}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <div className="mt-3">
                            <Created
                              navigation={props}
                              teacher_id={teacher_id}
                              status={"CREATED"}
                              academic_session_id={academic_session_id}
                              subjectid={subjectid}
                              searchbyassignmnetname={searchbyassignmnetname}
                              gradeid={gradeid}
                              sectionid={sectionid}
                            />
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                          <div className="mt-3">
                            <Assigned
                              navigation={props}
                              teacher_id={teacher_id}
                              status={"ASSIGNED"}
                              academic_session_id={academic_session_id}
                              subjectid={subjectid}
                              gradeid={gradeid}
                              sectionid={sectionid}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                          <div className="mt-3">
                            <Completed
                              navigation={props}
                              teacher_id={teacher_id}
                              status={"COMPLETED"}
                              academic_session_id={academic_session_id}
                              subjectid={subjectid}
                              gradeid={gradeid}
                              sectionid={sectionid}
                            />
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(Hoc1(PtTeacherAssignment));
