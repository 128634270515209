import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Report.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  ReportDropdownclass,
  StaffViewReportArr,
  studentDropDwonInfoArr,
  AllStaffViewReports,
} from "../../../utils/LocalData";
import APIHooks from "../../../api manager/CommonAPI";
//-----------api calls---------------
import { blankValidator, getFullName } from "../../../utils/Validation";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useApolloClient } from "@apollo/client";
import * as Service from "../../../services/index";

const ReportHome = ({ location, data, navigate }) => {
  //------------------global id---------------------
  const reportHooks = APIHooks();
  let navigation = navigate;
  const nestedData = location.state;

  const [academic_session_id, setAcdmicSession] = useState(
    parseInt(data.academic_session_id)
  );
  let institution_id = parseInt(data.institution_id);
  //--------------------------local state---------------------
  const [classReport, setclassReport] = useState("");
  const [ClassId, setClassId] = useState("");
  const [SectionId, setSectionId] = useState("");
  const [ReportType, setReportType] = useState("");
  const [isloading, setisloading] = useState(false);
  const [studentClassId, setstudentClassId] = useState(null);
  const [studentSectionId, setstudentSectionId] = useState(null);
  const [studentNameId, setstudentNameId] = useState(null);
  const [designationId, setdesignationId] = useState("");
  const [departmntId, setDepartmntId] = useState("");
  const [staffdesignationId, setstaffdesignationId] = useState("");
  const [selectedStaffId, setselectedStaffId] = useState("");
  const [employeeDetails, setemployeeDetails] = useState({});
  const [staffDeptId, setstaffDeptId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [managesessiondata, setmanagesessiondata] = useState([]);
  const client = useApolloClient();
  //errors
  const [class_classIdError, setclass_classIdError] = useState(false);
  const [class_sectionIdError, setclass_sectionIdError] = useState(false);
  const [class_reportTypeError, setclass_reportTypeError] = useState(false);
  const [student_classIdError, setstudent_classIdError] = useState(false);
  const [student_sectionIdError, setstudent_sectionIdError] = useState(false);
  const [student_NameError, setstudent_NameError] = useState(false);
  const [student_reportTypeError, setstudent_reportTypeError] = useState(false);
  const [allStaff_designationIdError, setallStaff_designationError] =
    useState(false);
  const [allStaff_departmentIdError, setallStaff_departmentIdError] =
    useState(false);
  const [allStaff_reportTypeError, setallStaff_reportTypeError] =
    useState(false);
  const [Staff_designationIdError, setStaff_designationIdError] =
    useState(false);
  const [Staff_departmentIdError, setStaff_departmentIdError] = useState(false);
  const [Staff_NameError, setStaff_NameError] = useState(false);
  const [Staff_reportTypeError, setStaff_reportTypeError] = useState(false);

  useEffect(() => {
    if (
      nestedData.name == "Class" ||
      nestedData.name == "Student" ||
      nestedData.name == "All_Staff" ||
      nestedData.name == "Staff"
    ) {
      setclassReport(nestedData.subName);
      selectReportTypeChnage(nestedData.subName);
    }
    setisloading(true);
    reportHooks.getClassAPI(academic_session_id);
    reportHooks.getDesignationAPI(institution_id);
    reportHooks.getDepartmentAPI(institution_id);
    setisloading(false);
  }, [institution_id, academic_session_id]);

  const classHandler = async (classId) => {
    let id = parseInt(classId);
    if (id === -1) {
      reportHooks.arrayCleaner("CLASS");
      return;
    }
    setClassId(id);
    setisloading(true);
    await reportHooks.getSectionAPI(id);
    setisloading(false);
  };

  const selectStudentName = (id) => {
    setstudentNameId(id);
    // const studentObj = reportHooks.studentListArr.find((item) => {
    //   if (item.id === +id) return item;
    // });
    // if (studentObj) {
    //   const { student_profile } = studentObj;

    // }
  };
  const selectDesignationFn = (id) => {
    setdesignationId(id);
  };
  const staffNameHandler = (employeeId) => {
    let id = parseInt(employeeId);
    if (id === -1) return;
    setselectedStaffId(id);
    reportHooks.employeeArr.filter((item) => {
      if (item.id == ClassId) {
        return setemployeeDetails(item);
      }
    });
  };
  const selectDepartmentFn = (id) => {
    setDepartmntId(id);
  };
  const staffDesignationHandler = async (designationId) => {
    let id = parseInt(designationId);
    if (id === -1) {
      reportHooks.arrayCleaner("STAFF");
      return;
    }
    setstaffdesignationId(id);
    if (staffDeptId) {
      setisloading(true);
      await reportHooks.getAllStaffAPI(staffDeptId, id);
      setisloading(false);
    }
  };

  const staffDepartmentHandler = async (departmentId) => {
    let id = parseInt(departmentId);
    if (id === -1) {
      reportHooks.arrayCleaner("STAFF");
      return;
    }
    setstaffDeptId(id);
    if (staffdesignationId) {
      setisloading(true);
      await reportHooks.getAllStaffAPI(id, staffdesignationId);
      setisloading(false);
    }
  };

  const studentSectionHandler = async (sectionId) => {
    try {
      let id = parseInt(sectionId);
      if (id === -1) {
        reportHooks.arrayCleaner("STUDENT_SECTION");
        return;
      }
      setstudentSectionId(id);
      setisloading(true);
      await reportHooks.getStudentListAPI(id);
      setisloading(false);
    } catch (error) {
      console.log(" error", error);
    }
  };

  const studentClassHandler = async (classId) => {
    try {
      let id = parseInt(classId);
      if (id === -1) {
        reportHooks.arrayCleaner("STUDENT_CLASS");
        return;
      }
      setstudentClassId(id);
      setisloading(true);
      await reportHooks.getSectionAPI(id);
      setisloading(false);
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const selectSectionChange = (id) => {
    setSectionId(id);
  };

  const selectReportTypeChnage = (type) => {
    setclassReport(type);
    setReportType(type);
  };

  const ViewReportDetails = (type) => {
    try {
      if (type === "class") {
        if (!blankValidator(ClassId)) {
          setclass_classIdError(true);
          return;
        }
        let class_Name = "";
        reportHooks.classArr.filter((item) => {
          if (item.id == ClassId) {
            return (class_Name = item.gradeName);
          }
        });
        if (!blankValidator(SectionId)) {
          setclass_sectionIdError(true);
          return;
        }
        let section_Name = "";
        reportHooks.sectionArr.filter((item) => {
          if (item.id == SectionId) {
            return (section_Name = item.name);
          }
        });
        if (!blankValidator(ReportType)) {
          setclass_reportTypeError(true);
          return;
        }

        let json = {
          seeker: "CLASSESS",
          ClassId,
          SectionId,
          ReportType,
          class_Name,
          section_Name,
          ReportType,
        };
        if (ReportType == "Examinations") {
          navigation("/manageExamination", {
            state: { json, academic_session_id },
          });
        } else {
          navigation("/reportDetails", { state: { json } });
        }
      } else if (type === "student") {
        if (!blankValidator(studentClassId)) {
          setstudent_classIdError(true);
          return;
        }
        let class_Name = "";
        reportHooks.classArr.filter((item) => {
          if (item.id == studentClassId) {
            return (class_Name = item.gradeName);
          }
        });
        if (!blankValidator(studentSectionId)) {
          setstudent_sectionIdError(true);
          return;
        }
        let section_Name = "";
        reportHooks.studentSectionArr.filter((item) => {
          if (item.id == studentSectionId) {
            return (section_Name = item.name);
          }
        });

        if (!blankValidator(studentNameId)) {
          setstudent_NameError(true);
          return;
        }
        let student_Name = "";
        reportHooks.studentListArr.filter((item) => {
          if (parseInt(item.id) == parseInt(studentNameId)) {
            student_Name = getFullName(item.student_profile.name);
          }
        });

        if (!blankValidator(ReportType)) {
          setstudent_reportTypeError(true);
          return;
        }
        let json = {
          seeker: "CLASSESS",
          userId: studentNameId,
          SectionId: studentSectionId,
          ReportType,
          class_Name,
          section_Name,
          student_Name,
          studentClassId,
          notshowclasspromotionbutton: true,
        };

        if (ReportType == "Examinations") {
          navigation("/classpromotion", {
            state: { json, academic_session_id },
          });
        } else {
          navigation("/reportDetails", { state: { json } });
        }
      } else if (type === "allStaff") {
        if (!blankValidator(designationId)) {
          setallStaff_designationError(true);
          return;
        }
        let designation_Name = "";
        reportHooks.designationArr.filter((item) => {
          if (item.id == designationId) {
            return (designation_Name = item.name);
          }
        });

        if (!blankValidator(departmntId)) {
          setallStaff_departmentIdError(true);
          return;
        }
        let department_Name = "";
        reportHooks.departmentArr.filter((item) => {
          if (item.id == departmntId) {
            return (department_Name = item.name);
          }
        });

        if (!blankValidator(ReportType)) {
          setallStaff_reportTypeError(true);
          return;
        }
        let json = {
          SectionId: null,
          designationId,
          departmntId,
          ReportType,
          designation_Name,
          department_Name,
        };
        navigation("/reportDetails", { state: { json } });
      } else if (type === "staff") {
        if (!blankValidator(staffdesignationId)) {
          setStaff_designationIdError(true);
          return;
        }
        let designation_Name = "";
        reportHooks.staffDesignationArr.filter((item) => {
          if (item.id == staffdesignationId) {
            return (designation_Name = item.name);
          }
        });

        if (!blankValidator(staffDeptId)) {
          setStaff_departmentIdError(true);
          return;
        }
        let department_Name = "";
        reportHooks.staffDepartmentArr.filter((item) => {
          if (item.id == staffdesignationId) {
            return (department_Name = item.name);
          }
        });
        if (!blankValidator(selectedStaffId)) {
          setStaff_NameError(true);
          return;
        }
        // let staff_Name = "";
        let staff_Name = reportHooks.employeeArr.filter((item) => {
          if (item.id == selectedStaffId) {
            return getFullName(item.name);
          }
        });

        if (!blankValidator(ReportType)) {
          setStaff_reportTypeError(true);
          return;
        }

        let json = {
          employeeDetails,
          institution_id,
          SectionId: selectedStaffId,
          seeker: "STAFF",
          ReportType,
          designation_Name,
          department_Name,
          staff_Name,
        };
        navigation("/reportDetails", { state: { json } });
      }
    } catch (error) {
      console.log("try and catch error", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagesessiondata(response.data.getAllAcademicSession);
        })

        .catch((e) => {
          setisloading(false);
          console.log("catch y error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [client, institution_id]);
  const sessionHandler = (e) => {
    setAcdmicSession(parseInt(e.target.value));
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={data.institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold">Report</div>
              </div>
              <div className="card_margin_both_side">
                {/* --------------------------class section------------------------------ */}
                <Card className="cardPadding">
                  <div className="card_admissiondetails_height">
                    <Grid className="grid_main_div">
                      <Grid item md={3}>
                        <div className="pl-3">
                          <select
                            class="form-control"
                            value={academic_session_id}
                            onChange={(e) => {
                              sessionHandler(e);
                            }}
                          >
                            <option value={-1}>Select Academic session</option>
                            {managesessiondata &&
                              managesessiondata.map((item) => (
                                <option value={item.id}>
                                  {item.session_name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <Grid item md={9}></Grid>
                      </Grid>
                    </Grid>
                    <div className="textfiled_margin">
                      <div className="mt-2 mb-2">
                        <Accordion
                          defaultExpanded={
                            nestedData.name === "Class" ? true : false
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Class/Section</Typography>
                          </AccordionSummary>
                          <Grid className="grid_main_div">
                            <Grid item md={3}>
                              <div className="text_filed_heading ml-1">
                                Class
                              </div>
                              <div className="inputMarginright mt-1 mb-2 ml-1">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setclass_classIdError(false);
                                    classHandler(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Select Class</option>
                                  {reportHooks.classArr.length !== 0 &&
                                    reportHooks.classArr.map((item) => (
                                      <option value={item.id}>
                                        {item.gradeName}
                                      </option>
                                    ))}
                                </select>
                                {class_classIdError && (
                                  <span className="text-danger">
                                    Please Select Class
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className="text_filed_heading  ml-1">
                                Section
                              </div>
                              <div className="inputMarginright mt-1 mb-2 ml-1">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setclass_sectionIdError(false);
                                    selectSectionChange(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Select Section</option>
                                  {reportHooks.sectionArr.length !== 0 &&
                                    reportHooks.sectionArr.map((item) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                                {class_sectionIdError && (
                                  <span className="text-danger">
                                    Please Select Section
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className="text_filed_heading  ml-1">
                                Select Report
                              </div>
                              <div className="inputMarginright mt-1 mb-2 ml-1">
                                <select
                                  class="form-control"
                                  value={classReport}
                                  onChange={(e) => {
                                    setclass_reportTypeError(false);
                                    selectReportTypeChnage(e.target.value);
                                  }}
                                >
                                  <option value="">Select Report</option>
                                  {ReportDropdownclass.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                                {class_reportTypeError && (
                                  <span className="text-danger">
                                    Please Select Report
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className="btn_add ml-2 mb-2 onlyMargin">
                                <Button
                                  onClick={() => ViewReportDetails("class")}
                                  variant="contained"
                                  className="add_new_query_btn button_login_decoration button_width"
                                >
                                  View Report
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </Accordion>
                      </div>

                      {/*----------------------------- student section---------------------- */}

                      <div className="mt-2 mb-2">
                        <Accordion
                          defaultExpanded={
                            nestedData.name === "Student" ? true : false
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Student</Typography>
                          </AccordionSummary>
                          <Grid className="grid_main_div">
                            <Grid item md={2}>
                              <div className="text_filed_heading  ml-1">
                                Class
                              </div>
                              <div className="inputMarginright mt-1 mb-2  ml-1">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setstudent_classIdError(false);
                                    studentClassHandler(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Select Class</option>
                                  {reportHooks.classArr.length !== 0 &&
                                    reportHooks.classArr.map((item) => (
                                      <option value={item.id}>
                                        {item.gradeName}
                                      </option>
                                    ))}
                                </select>
                                {student_classIdError && (
                                  <span className="text-danger">
                                    Please select Class
                                  </span>
                                )}
                              </div>
                            </Grid>

                            <Grid item md={2}>
                              <div className="text_filed_heading  ml-1">
                                Section
                              </div>
                              <div className="inputMarginright mt-1 mb-2  ml-1">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setstudent_sectionIdError(false);
                                    studentSectionHandler(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Select Section</option>
                                  {reportHooks.studentSectionArr.length !== 0 &&
                                    reportHooks.studentSectionArr.map(
                                      (item) => (
                                        <option value={item?.id}>
                                          {item?.name}
                                        </option>
                                      )
                                    )}
                                </select>
                                {student_sectionIdError && (
                                  <span className="text-danger">
                                    Please select Section
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className="text_filed_heading ml-1">
                                Name
                              </div>
                              <div className="inputMarginright mt-1 ml-1">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setstudent_NameError(false);
                                    selectStudentName(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Select Student</option>
                                  {reportHooks.studentListArr.length !== 0 &&
                                    reportHooks.studentListArr.map((item) => (
                                      <option value={item?.id}>
                                        {getFullName(
                                          item?.student_profile?.name
                                        )}
                                      </option>
                                    ))}
                                </select>
                                {student_NameError && (
                                  <span className="text-danger">
                                    Please select student
                                  </span>
                                )}
                              </div>
                            </Grid>

                            <Grid item md={2}>
                              <div className="text_filed_heading  ml-1">
                                Select Report
                              </div>
                              <div className="inputMarginright mt-1 mb-2  ml-1">
                                <select
                                  class="form-control"
                                  value={classReport}
                                  onChange={(e) => {
                                    setstudent_reportTypeError(false);
                                    selectReportTypeChnage(e.target.value);
                                  }}
                                >
                                  <option value="">Select Report</option>
                                  {studentDropDwonInfoArr.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                                {student_reportTypeError && (
                                  <span className="text-danger">
                                    Please select Report
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className="btn_add onlyMargin ml-2 mb-2">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn button_login_decoration button_width"
                                  onClick={() => ViewReportDetails("student")}
                                >
                                  View Report
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </Accordion>
                      </div>
                      {data.type === "PT" || data.type === "ET" ? (
                        ""
                      ) : (
                        <div className="mt-2 mb-2">
                          <Accordion
                            defaultExpanded={
                              nestedData.name === "All_Staff" ? true : false
                            }
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>All Staff</Typography>
                            </AccordionSummary>
                            <Grid className="grid_main_div">
                              <Grid item md={3}>
                                <div className="text_filed_heading ml-1">
                                  Designation
                                </div>
                                <div className="inputMarginright mt-1 mb-2 ml-1">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setallStaff_designationError(false);
                                      selectDesignationFn(e.target.value);
                                    }}
                                  >
                                    <option>Designation</option>
                                    {reportHooks.designationArr.map((item) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  {allStaff_designationIdError && (
                                    <span className="text-danger">
                                      Select Designation
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className="text_filed_heading  ml-1">
                                  Department
                                </div>
                                <div className="inputMarginright mt-1 mb-2 ml-1">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setallStaff_departmentIdError(false);
                                      selectDepartmentFn(e.target.value);
                                    }}
                                  >
                                    <option>Department</option>
                                    {reportHooks.departmentArr.map((item) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  {allStaff_departmentIdError && (
                                    <span className="text-danger">
                                      Select Department
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className="text_filed_heading  ml-1">
                                  Select Report
                                </div>
                                <div className="inputMarginright mt-1 mb-2 ml-1">
                                  <select
                                    class="form-control"
                                    value={classReport}
                                    onChange={(e) => {
                                      setallStaff_reportTypeError(false);
                                      selectReportTypeChnage(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Report</option>
                                    {AllStaffViewReports.map((item) => (
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                  {allStaff_reportTypeError && (
                                    <span className="text-danger">
                                      Select Report
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className="btn_add onlyMargin ml-2 mb-2">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn button_login_decoration button_width"
                                    onClick={() =>
                                      ViewReportDetails("allStaff")
                                    }
                                  >
                                    View Report
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Accordion>
                        </div>
                      )}
                      {data.type === "PT" || data.type === "ET" ? (
                        ""
                      ) : (
                        <div className="mt-2 mb-2">
                          <Accordion
                            defaultExpanded={
                              nestedData.name === "Staff" ? true : false
                            }
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Staff</Typography>
                            </AccordionSummary>
                            <Grid className="grid_main_div">
                              <Grid item md={2}>
                                <div className="text_filed_heading  ml-1">
                                  Designation
                                </div>
                                <div className="inputMarginright mt-1 mb-2 ml-1">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setStaff_designationIdError(false);
                                      staffDesignationHandler(e.target.value);
                                    }}
                                  >
                                    <option value={-1}>
                                      Select Designation
                                    </option>
                                    {reportHooks.staffDesignationArr.map(
                                      (item) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {Staff_designationIdError && (
                                    <span className="text-danger">
                                      Please select designation
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={2}>
                                <div className="text_filed_heading ml-1">
                                  Department
                                </div>
                                <div className="inputMarginright mt-1 mb-2 ml-1">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setStaff_departmentIdError(false);
                                      staffDepartmentHandler(e.target.value);
                                    }}
                                  >
                                    <option valur={-1}>
                                      Select Department
                                    </option>
                                    {reportHooks.staffDepartmentArr.map(
                                      (item) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {Staff_departmentIdError && (
                                    <span className="text-danger">
                                      Please select department
                                    </span>
                                  )}
                                </div>
                              </Grid>

                              <Grid item md={2}>
                                <div className="text_filed_heading ml-1">
                                  Name
                                </div>
                                <div className="inputMarginright mt-1 mb-2 ml-1">
                                  <select
                                    class="form-control"
                                    onChange={(e) => {
                                      setStaff_NameError(false);
                                      staffNameHandler(e.target.value);
                                    }}
                                  >
                                    <option value={-1}>Select Employee</option>
                                    {reportHooks.employeeArr.map((item) => (
                                      <option value={item.id}>
                                        {getFullName(item.name)}
                                      </option>
                                    ))}
                                  </select>
                                  {Staff_NameError && (
                                    <span className="text-danger">
                                      Please select employee
                                    </span>
                                  )}
                                </div>
                              </Grid>

                              <Grid item md={3}>
                                <div className="text_filed_heading  ml-1">
                                  Select Report
                                </div>
                                <div className="inputMarginright mt-1 mb-2  ml-1">
                                  <select
                                    class="form-control"
                                    value={classReport}
                                    onChange={(e) => {
                                      setStaff_reportTypeError(false);
                                      selectReportTypeChnage(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Report</option>
                                    {StaffViewReportArr.map((item) => (
                                      <option vlaue={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                  {Staff_reportTypeError && (
                                    <span className="text-danger">
                                      Select Report
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={3}>
                                <div className="btn_add onlyMargin ml-2 mb-2">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn button_login_decoration button_width"
                                    onClick={() => ViewReportDetails("staff")}
                                  >
                                    View Report
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Accordion>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ReportHome));
