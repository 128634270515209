import React, { useState } from "react";
import { connect } from "react-redux";
import { getData } from "../../../api";
import Hoc1 from "../../Hoc1";
import "./GetStarted.css";
import * as Constants from "../../../action/appAction";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Screen8 from "./Images/Screen8.png";
import Screen9 from "./Images/Screen9.png";
import AddClass from "./Images/AddClass.PNG";
import Screen14 from "./Images/Screen14.png";
import Screen15 from "./Images/Screen15.png";
import Screen16 from "./Images/Screen16.png";
import AddSubject from "./Images/AddSubject.PNG";
import Screen17 from "./Images/Screen17.png";
import AssignBtn from "./Images/AssignBtn.PNG";
import Screen18 from "./Images/Screen18.png";
import Screen19 from "./Images/Screen19.png";
import AddTimeTable from "./Images/AddTimeTable.PNG";
import Screen20 from "./Images/Screen20.png";
import Screen21 from "./Images/Screen21.png";
import CreatePeroid from "./Images/CreatePeroid.PNG";
import Screen22 from "./Images/Screen22.png";
import Screen23 from "./Images/Screen23.png";
import Screen24 from "./Images/Screen24.png";
import AssignTime from "./Images/AssignTime.PNG";
import Screen25 from "./Images/Screen25.png";
import Screen26 from "./Images/Screen26.png";
import Screen30 from "./Images/Screen30.png";
import Screen31 from "./Images/Screen31.png";
import AddRoom from "./Images/AddRoom.PNG";
import Screen34 from "./Images/Screen34.png";
import Screen38 from "./Images/Screen38.png";
import Screen39 from "./Images/Screen39.png";
import Screen46 from "./Images/Screen46.png";

const GetStarted = (props) => {
  const { institution_name, academic_session_id, institution_id, type } =
    props.ADMIN_DATA;
  console.log("props.ADMIN_DATA", props.ADMIN_DATA);
  const [activeLink, setActiveLink] = useState(1);

  const scrollpage = (id) => {
    try {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    } catch (error) {}
  };
  return (
    <div className="main_full_width">
      <div className="getStartedmarginPadding">
        <Grid className="Main_Grid_For_MENUS">
          <Grid item md={12}>
            {/* <Holiday name={institution_name} isRemove={true} /> */}
            <Grid className="Main_Grid_For_MENUS">
              <Grid item md={3}>
                <Card className="leftcardPostionfixed">
                  <div className="card_margin_both_side mb-2">
                    <div
                      className={
                        activeLink === 1
                          ? "leftCardTitleActive"
                          : "leftCardTitle"
                      }
                      onClick={() => {
                        setActiveLink(1);
                        scrollpage("Class-and-Section");
                      }}
                    >
                      <p>Class and Section</p>
                    </div>
                    <div
                      className={
                        activeLink === 2
                          ? "leftCardTitleActive"
                          : "leftCardTitle"
                      }
                      onClick={() => {
                        setActiveLink(2);
                        scrollpage("Time-Table-Setup");
                      }}
                    >
                      <p>Time Table Setup</p>
                    </div>
                    <div
                      className={
                        activeLink === 3
                          ? "leftCardTitleActive"
                          : "leftCardTitle"
                      }
                      onClick={() => {
                        setActiveLink(3);
                        scrollpage("Student-Onboarding");
                      }}
                    >
                      <p>Student Onboarding</p>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item md={9}>
                <Card className="cardHeightScroll">
                  <div className="card_margin_both_side mb-2">
                    {/* -----------class & section setup------------  */}
                    <section id="Class-and-Section">
                      <Card className="p-2">
                        <p className="getStartRightHeading">
                          Class and Section
                        </p>
                        {/*  ---------------step 1 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 1 </p>
                          <p className="stepvaluepara">
                            Login using admin account to setup the classes or
                            groups and click on{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("/homemenu");
                              }}
                            >
                              “Manage Institute”
                            </span>
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen8}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 2 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 2 </p>
                          <p className="stepvaluepara">
                            Select{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("/adminmanageinstitution", {
                                  state: {
                                    temp: props.ADMIN_DATA.data,
                                  },
                                });
                              }}
                            >
                              “Manage class”
                            </span>{" "}
                            to start creating classes or group for students.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen9}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 3 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 3 </p>
                          <p className="stepvaluepara">
                            In the{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("addeditclass", {
                                  state: {
                                    academic_session_id:
                                      parseInt(academic_session_id),
                                    institution_name: institution_name,
                                    institution_id: institution_id,
                                  },
                                });
                              }}
                            >
                              Manage class
                            </span>
                            , add the name of the class or group as class name
                            by clicking on{" "}
                            <img
                              className="smallLogo"
                              src={AddClass}
                              alt="No_Image"
                            />
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen14}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">
                            After this, add the sections for that class
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen15}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">
                            Continue adding more sections as per your need.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen16}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-4">
                            Add the subject by clicking on{" "}
                            <img
                              className="smallLogo"
                              src={AddSubject}
                              alt="No_Image"
                            />
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen17}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-4">
                            Click on{" "}
                            <img
                              className="smallLogo"
                              src={AssignBtn}
                              alt="No_Image"
                            />{" "}
                            to assign to your class. You can create multiple
                            subjects and assign.
                          </p>
                          <p className="stepvaluepara pt-4">
                            Congratulations! You have completed your class
                            setup.
                          </p>
                        </div>
                      </Card>
                    </section>
                    {/* --------------time table setup------------ */}
                    <section id="Time-Table-Setup">
                      <Card className="p-2 mt-3">
                        <p className="getStartRightHeading">Time Table Setup</p>
                        {/*  ---------------step 1 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 1 </p>
                          <p className="stepvaluepara">
                            Start with adding a Room using{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("managelocation", {
                                  state: {
                                    academic_session_id:
                                      parseInt(academic_session_id),
                                    institution_name: institution_name,
                                    institution_id: institution_id,
                                  },
                                });
                              }}
                            >
                              “Manage Rooms”
                            </span>{" "}
                            in Manage Institute page.
                          </p>
                          <p className="stepvaluepara">
                            Click on{" "}
                            <img
                              className="smallLogo"
                              src={AddRoom}
                              alt="No_Image"
                            />{" "}
                            to add a new Room.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen30}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 2 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 2 </p>
                          <p className="stepvaluepara">
                            Go to manage Institute and select{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("/adminmanageinstitution", {
                                  state: {
                                    temp: props.ADMIN_DATA.data,
                                  },
                                });
                              }}
                            >
                              “Manage Timetable”
                            </span>
                            as below.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen18}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 3 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 3 </p>
                          <p className="stepvaluepara">
                            Select{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("manageTimeTable", {
                                  state: {
                                    academic_session_id:
                                      parseInt(academic_session_id),
                                    institution_name: institution_name,
                                    institution_id: institution_id,
                                  },
                                });
                              }}
                            >
                              “Manage timetable template”
                            </span>{" "}
                            to create a template for your timetable.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen19}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 4 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 4 </p>
                          <p className="stepvaluepara">
                            Click on{" "}
                            <img
                              className="smallLogo"
                              src={AddTimeTable}
                              alt="No_Image"
                            />{" "}
                            to start entering a name for the template.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen20}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">
                            Enter the template name in below page
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen21}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 5 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 5 </p>
                          <p className="stepvaluepara">
                            Click on{" "}
                            <img
                              className="smallLogo"
                              src={CreatePeroid}
                              alt="No_Image"
                            />{" "}
                            to start adding the period details.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen22}
                            alt="No_Image"
                          />
                          <img
                            className="imagelarge mt-2"
                            src={Screen23}
                            alt="No_Image"
                          />
                          <img
                            className="imagelarge mt-2"
                            src={Screen24}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 6 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 6 </p>
                          <p className="stepvaluepara">
                            Go back to{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("manageTimeTable", {
                                  state: {
                                    academic_session_id:
                                      parseInt(academic_session_id),
                                    institution_name: institution_name,
                                    institution_id: institution_id,
                                  },
                                });
                              }}
                            >
                              “Manage timetable”
                            </span>{" "}
                            page and select{" "}
                            <img
                              className="smallLogo"
                              src={AssignTime}
                              alt="No_Image"
                            />{" "}
                            to assign the timetable to the section you have
                            created.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen19}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 7 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 7 </p>
                          <p className="stepvaluepara">
                            Select the class, section and start and end date of
                            the group and click on “Assign”.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen25}
                            alt="No_Image"
                          />
                          <img
                            className="imagelarge mt-2"
                            src={Screen26}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">
                            You can now click on Edit the details for updating
                            the time, room, Subject and teacher name. You can
                            add as many period or delete any period using “Add
                            new Period” and “Delete all periods”
                          </p>
                          <img
                            className="imagelarge mt-2"
                            src={Screen31}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">
                            Timetable setup is complete, You can try testing the
                            online class by clicking on the Room link.
                          </p>
                          <img
                            className="imagelarge mt-2"
                            src={Screen34}
                            alt="No_Image"
                          />
                        </div>
                      </Card>
                    </section>
                    {/* -----------Student Onboarding setup------------  */}
                    <section id="Student-Onboarding">
                      <Card className="p-2">
                        <p className="getStartRightHeading">
                          Student Onboarding
                        </p>
                        {/*  ---------------step 1 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 1 </p>
                          <p className="stepvaluepara">
                            Login using admin account and select{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("/admissionsMenu");
                              }}
                            >
                              “Onboarding”
                            </span>
                            dashboard. Click on{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.ADMIN_DATA.type === "PT" ||
                                props.ADMIN_DATA.type === "ET"
                                  ? props.navigate("/PtSudentForm")
                                  : props.navigate("/studentform");
                              }}
                            >
                              “Student Onboarding”
                            </span>{" "}
                            to begin student onboarding.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen38}
                            alt="No_Image"
                          />
                        </div>
                        {/*  ---------------step 2 ----------- */}
                        <div className="getStartSection">
                          <p className="stepvalue">Step 2 </p>
                          <p className="stepvaluepara">
                            Fill the student details and submit. This will
                            create student and parent login. Student and parent
                            will receive user id and password for logging to the
                            student portal.
                          </p>
                          <img
                            className="imagelarge"
                            src={Screen39}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">
                            Congratulations! You have added your student. To
                            manage student information, you can go to{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                props.navigate("/admissionsMenu");
                              }}
                            >
                              “Student Status”
                            </span>
                          </p>
                          <img
                            className="imagelarge mt-2"
                            src={Screen38}
                            alt="No_Image"
                          />
                          <p className="stepvaluepara pt-2">Online classes</p>
                          <p className="stepvaluepara pt-2">
                            After onboarding students and setting up the
                            timetable, you can login using teacher login and go
                            to{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                // props.navigate("/admissionsMenu");
                              }}
                            >
                              “Class timetable”
                            </span>{" "}
                            by clicking on the{" "}
                            <span
                              className="getClickColor"
                              onClick={() => {
                                // props.navigate("/admissionsMenu");
                              }}
                            >
                              “Timetable”
                            </span>
                            in the banner.{" "}
                          </p>
                          <p className="stepvaluepara pt-2">
                            Click on the Room link to start online class.{" "}
                          </p>
                          <img
                            className="imagelarge mt-2"
                            src={Screen46}
                            alt="No_Image"
                          />
                        </div>
                      </Card>
                    </section>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(GetStarted));
