import React, { useState } from "react";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import Badge from "@material-ui/core/Badge";
import profile from "./profile.png";
//-------------------redux-------------------
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
import { baseUrl } from "../../services/index";
import IconSchool from "./IconSchool.png";
import { getFullName } from "../../utils/Validation";
const ParentHeader = ({ data, navigate, dataacademic }) => {
  //----------------local state---------------------
  const navigation = navigate;
  // const [invisible] = useState(false);
  const [Sidebar, setSidebar] = useState(false);

  //----------------methods---------------------

  const setLoadingnewside = () => {
    try {
      setSidebar(!Sidebar);

      if (Sidebar) {
        document.getElementById("mySidenav").style.width = "250px";
      } else {
        document.getElementById("mySidenav").style.width = "0px";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader">
          <Toolbar>
            <Grid container spacing={24}>
              <Grid item xs={2} md={3}>
                <div className="logoformatting">
                  <span>
                    <img
                      src={
                        data.path === ""
                          ? IconSchool
                          : baseUrl + "/" + data.path
                      }
                      alt=""
                      className={
                        data.path === ""
                          ? "images_background_logo image_hader"
                          : "image_hader"
                      }
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={10} md={9}>
                <div className="iconsMaindiv2" style={{ paddingRight: "3%" }}>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/parentHomePage")}
                  >
                    Home
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/parentExamination")}
                  >
                    Examinations
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/parentStudentLeave")}
                  >
                    Leaves
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/parentNotice")}
                  >
                    Notice
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/showEvent")}
                  >
                    Event
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/studyMaterial")}
                  >
                    StudyMaterial
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/parentStudentTransport")}
                  >
                    Transport
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() =>
                      navigation("/listofInvoices", {
                        state: {
                          studentData: data.studentData,
                          institution_name: data.institution_name,
                        },
                      })
                    }
                  >
                    Fees
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/attendenceView")}
                    // onClick={() => navigation("/parentAttendence")}
                  >
                    Attendance
                  </span>
                  {/* <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => navigation("/parentStudentTimeTable")}
                  >
                    TimeTable
                  </span> */}

                  <span className="Gap_from_bottom_Menu_Spacing">
                    <i
                      className="fa fa-calendar"
                      onClick={() =>
                        navigation("/commonHolidayCalender", {
                          state: {
                            academic_session_id: parseInt(dataacademic.id),
                          },
                        })
                      }
                    ></i>
                  </span>
                  <span className="Gap_from_bottom_Menu_Spacing">
                    <Badge
                      color="secondary"
                      // variant="dot"
                      // invisible={invisible}
                    >
                      <i
                        className="fa fa-envelope "
                        onClick={() => navigation("/notification")}
                      ></i>
                    </Badge>
                  </span>

                  <div className="d-flex Gap_from_bottom_Menu_Spacing">
                    <img src={profile} alt="No_photo" width="40" height="35" />
                    <span
                      className="dropdown-toggle dropdownbutton button_login_decoration ml-2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {data.first_name !== undefined && data.first_name}{" "}
                      {data.last_name !== undefined && data.last_name}
                    </span>
                    <div className="dropdown-menu arrow_box">
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => navigation("/studentresetpassword")}
                      >
                        Reset Password
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() =>
                          navigate("/studentDetailForm", {
                            state: {
                              studentId: data.studentData.id,
                              institution_name: data.institution_name,
                              academic_session_id: parseInt(
                                data.academic_session_id
                              ),
                              type: "STUDENT",
                              institution_id: data.institution_id,
                            },
                          })
                        }
                      >
                        Student Profile
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => navigation("/")}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mobile_Burger_Menu mr-3 text-right mt-3">
                  <span
                    className="logout_Pointer_cursor"
                    onClick={setLoadingnewside}
                  >
                    <i class="fa fa-bars"></i>
                  </span>

                  <div id="mySidenav" className="sidenav">
                    <div className="cross_icon_style">
                      <i
                        class="fa fa-times cursor"
                        onClick={() => {
                          document.getElementById("mySidenav").style.width =
                            "0px";
                        }}
                      ></i>
                    </div>
                    <div className="text-center">
                      <img
                        src={profile}
                        alt="No_photo"
                        width="60"
                        height="60"
                      />{" "}
                      <div className="userName_mobileView_color">
                        {getFullName(data)}
                      </div>
                    </div>

                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentHomePage")}
                    >
                      Home
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentExamination")}
                    >
                      Examinations
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentStudentLeave")}
                    >
                      Leaves
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentNotice")}
                    >
                      Notice
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/showEvent")}
                    >
                      Event
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/studyMaterial")}
                    >
                      StudyMaterial
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentStudentTransport")}
                    >
                      Transport
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() =>
                        navigation("/listofInvoices", {
                          state: {
                            studentData: data.studentData,
                            institution_name: data.institution_name,
                          },
                        })
                      }
                    >
                      Fees
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentAttendence")}
                    >
                      Attendance
                    </span>

                    {/* <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentStudentTimeTable")}
                    >
                      Time Table
                    </span> */}
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() =>
                        navigation("/commonHolidayCalender", {
                          state: {
                            academic_session_id: parseInt(dataacademic.id),
                          },
                        })
                      }
                    >
                      Calender
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/parentNotifications")}
                    >
                      Notification
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/studentresetpassword")}
                    >
                      Reset Password
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() =>
                        navigate("/studentDetailForm", {
                          state: {
                            studentId: data.studentData.id,
                            institution_name: data.institution_name,
                            academic_session_id: parseInt(
                              data.academic_session_id
                            ),
                            type: "STUDENT",
                            institution_id: data.institution_id,
                          },
                        })
                      }
                    >
                      Student Profile
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/")}
                    >
                      Logout <i class="fa fa-sign-out ml-3"></i>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(ParentHeader);
