import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Transport.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import userimage from "./userimage.png";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loder from "../../Loder/Loder.jsx";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { getFullName } from "../../../utils/Validation.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TransportMarkingAttendance = (props) => {
  //------------------global id---------------------
  let institution_id = parseInt(props.data.institution_id);
  var toadyDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
  //------------------api calls---------------------
  const [Publish_Attendence, {}] = useMutation(Service.Publish_Attendence);
  const client = useApolloClient();
  const classes = useStyles();
  //------------------local state---------------------
  const [dropRouteArr, setdropRouteArr] = useState([]);
  const [dropPickUpArr, setdropPickUpArr] = useState([]);
  const [dropStudentAr, setdropStudentAr] = useState([]);

  const [studentArr, setstudentArr] = useState([]);

  const [routeListArr, setrouteListArr] = useState([]);
  const [pickupStartArr, setPickupStartArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //------------------methods---------------------
  useEffect(() => {
    const selectTransportRoute = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_Route_List_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setrouteListArr(response.data.getTransportRoute);
            setdropRouteArr(response.data.getTransportRoute);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        console.log("t and c error", error);
      }
    };
    selectTransportRoute();
  }, [client, institution_id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const routeDropPickHandler = async (id) => {
    try {
      setisloading(true);

      await client
        .query({
          query: Service.GET_Student_List_Transport_Basis,
          variables: {
            transport_pickup_stop_id: parseInt(id),
            transport_drop_stop_id: -1,
          },
        })
        .then((response) => {
          setisloading(false);

          let temp = [];
          for (
            let index = 0;
            index < response.data.getStudentListByTranportId.length;
            index++
          ) {
            const element = response.data.getStudentListByTranportId[index];
            element["isChecked"] = false;
            temp.push(element);
          }
          setdropStudentAr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      console.log("get transport t and c error", error);
    }
  };
  const routePickHandler = async (id) => {
    try {
      setisloading(true);

      await client
        .query({
          query: Service.GET_Student_List_Transport_Basis,
          variables: {
            transport_pickup_stop_id: parseInt(id),
            transport_drop_stop_id: -1,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getStudentListByTranportId.length;
            index++
          ) {
            const element = response.data.getStudentListByTranportId[index];
            element["isChecked"] = false;
            temp.push(element);
          }
          setstudentArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      console.log("get transport t and c error", error);
    }
  };
  const dropRouteListHandler = async (id) => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_Pickup_Route_Stop,
          variables: {
            transport_route_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setdropPickUpArr(response.data.getTransportStop);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      console.log("get transport t and c error", error);
    }
  };
  const routeListHandler = async (id) => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_Pickup_Route_Stop,
          variables: {
            transport_route_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setPickupStartArr(response.data.getTransportStop);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      console.log("get transport t and c error", error);
    }
  };
  const checkBoxDropHandler = (index) => {
    dropStudentAr[index].isChecked = !dropStudentAr[index].isChecked;
    let temp = [];
    for (let index = 0; index < dropStudentAr.length; index++) {
      const element = dropStudentAr[index];
      element["id"] = dropStudentAr[index].id;
      temp.push(element);
    }

    setdropStudentAr(temp);
  };
  const checkBoxHandler = (index) => {
    studentArr[index].isChecked = !studentArr[index].isChecked;
    let temp = [];
    for (let index = 0; index < studentArr.length; index++) {
      const element = studentArr[index];
      element["id"] = studentArr[index].id;
      temp.push(element);
    }

    setstudentArr(temp);
  };
  const publishAttendance = (type) => {
    let date = toadyDate.replace(/-/g, "/");
    let attendance_type = "TRANSPORT_PICKUP";
    if (type === "DROP") {
      for (let index = 0; index < dropStudentAr.length; index++) {
        if (dropStudentAr[index].isChecked === true) {
          try {
            Publish_Attendence({
              variables: {
                attendance_type: "TRANSPORT_DROP",
                reference_id: 1,
                date,
                user_id: parseInt(dropStudentAr[index].id),
              },
            })
              .then((response) => {
                alert(response.data.addAttendance.message);
              })
              .catch((error) => {
                console.log("catch error", error);
              });
          } catch (error) {
            console.log("t and c error", error);
          }
        } else {
        }
      }
    } else {
      for (let index = 0; index < studentArr.length; index++) {
        if (studentArr[index].isChecked === true) {
          try {
            Publish_Attendence({
              variables: {
                attendance_type: "TRANSPORT_PICKUP",
                reference_id: 1,
                date,
                user_id: parseInt(studentArr[index].id),
              },
            })
              .then((response) => {
                alert(response.data.addAttendance.message);
              })
              .catch((error) => {
                console.log("catch error", error);
              });
          } catch (error) {
            console.log("t and c error", error);
          }
        } else {
        }
      }
    }
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-3 Heading_bold">Attendance Report</div>
                <div className="mr-3 mt-3 Heading_bold">{toadyDate}</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <Grid>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="danger"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      <Tab
                        label="Pickup"
                        {...a11yProps(0)}
                        className="button_login_decoration text_tranformation_exam"
                      />

                      <Tab
                        label="Drop"
                        {...a11yProps(1)}
                        className="button_login_decoration text_tranformation_exam"
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <label className="text_filed_heading">
                            Route Name
                          </label>
                          <select
                            class="form-control"
                            onChange={(e) => routeListHandler(e.target.value)}
                          >
                            <option> Select Route Name</option>
                            {routeListArr.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.route_name}
                              </option>
                            ))}
                          </select>
                        </Grid>
                        <Grid item md={4}>
                          <label className="text_filed_heading">
                            Pickup Name
                          </label>
                          <select
                            class="form-control"
                            onChange={(e) => routePickHandler(e.target.value)}
                          >
                            <option>Select Pickup Name</option>
                            {pickupStartArr.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.stop_name}
                              </option>
                            ))}
                          </select>
                        </Grid>
                        <Grid item md={4}>
                          <label className="text_filed_heading">Date</label>
                          <input
                            disabled={true}
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                            value={toadyDate}
                          />
                        </Grid>
                      </Grid>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <div className="mt-5">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header create_route_listing_padding"
                                    >
                                      ID Number
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header create_route_listing_padding"
                                    >
                                      Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header create_route_listing_padding"
                                    >
                                      Advance Attendance Marking
                                    </StyledTableCell>

                                    <StyledTableCell className="table_header create_route_listing_padding">
                                      {/* <Checkbox /> */}
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? studentArr.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : studentArr
                                  ).map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        align="left"
                                        className="create_route_listing_padding"
                                      >
                                        {row.id}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="create_route_listing_padding"
                                      >
                                        <img
                                          src={userimage}
                                          alt=""
                                          className="img_formatting mr-2"
                                        />
                                        {getFullName(row.student_profile.name)}
                                                                           </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="create_route_listing_padding routeopen_text_color"
                                        onClick={() =>
                                         props.navigate(
                                            "/advanceattendance"
                                          )
                                        }
                                      >
                                        Capture Photo
                                      </StyledTableCell>

                                      <StyledTableCell className="create_route_listing_padding">
                                        <Checkbox
                                          checked={studentArr[index].isChecked}
                                          onChange={() =>
                                            checkBoxHandler(index)
                                          }
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                              <div className="main_button_div  mb-3">
                                <Button
                                  variant="contained"
                                  className="button_width btn_colr_and_width"
                                  onClick={() => publishAttendance("PICKUP")}
                                >
                                  Publish
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {/*---------------- drop tab------------------ */}
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <label className="text_filed_heading">
                            Route Name
                          </label>
                          <select
                            class="form-control"
                            onChange={(e) =>
                              dropRouteListHandler(e.target.value)
                            }
                          >
                            <option> Select Route Name</option>
                            {dropRouteArr.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.route_name}
                              </option>
                            ))}
                          </select>
                        </Grid>
                        <Grid item md={4}>
                          <label className="text_filed_heading">
                            Drop Name
                          </label>
                          <select
                            class="form-control"
                            onChange={(e) =>
                              routeDropPickHandler(e.target.value)
                            }
                          >
                            <option>Select Pickup Name</option>
                            {dropPickUpArr.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.stop_name}
                              </option>
                            ))}
                          </select>
                        </Grid>
                        <Grid item md={4}>
                          <label className="text_filed_heading">Date</label>
                          <input
                            disabled={true}
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                            value={toadyDate}
                          />
                        </Grid>
                      </Grid>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <div className="mt-5">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header create_route_listing_padding"
                                    >
                                      ID Number
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header create_route_listing_padding"
                                    >
                                      Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header create_route_listing_padding"
                                    >
                                      Advance Attendance Marking
                                    </StyledTableCell>

                                    <StyledTableCell className="table_header create_route_listing_padding">
                                      {/* <Checkbox /> */}
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? dropStudentAr.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : dropStudentAr
                                  ).map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        align="left"
                                        className="create_route_listing_padding"
                                      >
                                        {row.id}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="create_route_listing_padding"
                                      >
                                        <img
                                          src={userimage}
                                          alt=""
                                          className="img_formatting mr-2"
                                        />
                                       {
                                         getFullName(row.student_profile.name)
                                       } 
                                                                          </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="create_route_listing_padding routeopen_text_color"
                                        onClick={() =>
                                         props.navigate(
                                            "/advanceattendance"
                                          )
                                        }
                                      >
                                        Capture Photo
                                      </StyledTableCell>

                                      <StyledTableCell className="create_route_listing_padding">
                                        <Checkbox
                                          checked={
                                            dropStudentAr[index].isChecked
                                          }
                                          onChange={() =>
                                            checkBoxDropHandler(index)
                                          }
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                              <div className="main_button_div  mb-3">
                                <Button
                                  variant="contained"
                                  className="button_width btn_colr_and_width"
                                  onClick={() => publishAttendance("DROP")}
                                >
                                  Publish
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </TabPanel>
                  </Grid>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(TransportMarkingAttendance)));
