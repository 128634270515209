import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { getValue } from "../../../utils/Validation";

const StudentTransportDetail = (props) => {
  // student ID
  let id = props.user_info.userid;

  //Institute name
  let institution_Name = props.user_info.institution_name;

  // to get data api
  const client = useApolloClient();
  const classes = useStyles();
  const [pickupRouteName, setPicupRouteName] = useState("");
  const [pikupStopName, setPikupStopName] = useState("");
  const [DropRouteName, setDropRouteName] = useState("");
  const [DropStopName, setDropStopName] = useState("");
  const [pickRouteType, setPickRouteType] = useState("");
  const [stopRouteType, setStopRouteType] = useState("");
  const [transportPickId, setTransportPickId] = useState("");
  const [transportPickData, setTransportPickData] = useState("");
  const [transportDropData, setTransportDropData] = useState("");
  const [transportStopId, setTransportStopId] = useState("");
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Student_Details_Data,
          variables: {
            id,
          },
        })
        .then((response) => {
          const transport_pickup =
            response.data.getStudentDetailForm.transport_pickup;
          setTransportPickData(transport_pickup);
          const transport_stop =
            response.data.getStudentDetailForm.transport_stop;
          setTransportDropData(transport_stop);
          if (transport_pickup) {
            let tr = transport_pickup.transport_route;
            if (tr && tr.route_name) {
              setPicupRouteName(tr.route_name);
            }
          }
          if (transport_pickup) {
            let tr = transport_pickup.transport_route;
            if (tr && tr.id) {
              setTransportPickId(tr.id);
            }
          }
          if (transport_pickup) {
            let tr = transport_pickup.transport_route;
            if (tr && tr.route_type) {
              setPickRouteType(tr.route_type);
            }
          }
          if (transport_pickup) {
            let tr = transport_pickup;
            if (tr && tr.stop_name) {
              setPikupStopName(tr.stop_name);
            }
          }

          if (transport_stop) {
            let tr = transport_stop.transport_route;
            if (tr && tr.route_name) {
              setDropRouteName(tr.route_name);
            }
          }
          if (transport_stop) {
            let tr = transport_stop.transport_route;
            if (tr && tr.id) {
              setTransportStopId(tr.id);
            }
          }
          if (transport_stop) {
            let tr = transport_stop.transport_route;
            if (tr && tr.route_type) {
              setStopRouteType(tr.route_type);
            }
          }

          if (transport_stop) {
            let tr = transport_stop;
            if (tr && tr.stop_name) {
              setDropStopName(tr.stop_name);
            }
          }
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log(" institution arr try and catch", error);
    }
  }, [id, client]);

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_Name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-3 Heading_bold">Transport Details</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="table_foramtitng p-3">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              align="center"
                              className="table_header create_route_listing_padding"
                            >
                              Route Type
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className="table_header create_route_listing_padding"
                            >
                              Route Name
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className="table_header create_route_listing_padding"
                            >
                              Stop Name
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className="table_header create_route_listing_padding"
                            >
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody id="myTable">
                          {transportPickData && (
                            <StyledTableRow id="data">
                              <StyledTableCell scope="row" align="center">
                                {getValue(pickRouteType)}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="center">
                                {" "}
                                {getValue(pickupRouteName)}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="center">
                                {getValue(pikupStopName)}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="center">
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "#0070bb",
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    props.navigate("/googlemapview", {
                                      state: {
                                        id: transportPickId,
                                      },
                                    });
                                  }}
                                >
                                  Track
                                </span>{" "}
                              </StyledTableCell>
                            </StyledTableRow>
                          )}

                          {/* -----stop data row---- */}
                          {transportDropData && (
                            <StyledTableRow id="data">
                              <StyledTableCell scope="row" align="center">
                                {getValue(stopRouteType)}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="center">
                                {getValue(DropRouteName)}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="center">
                                {" "}
                                {getValue(DropStopName)}
                              </StyledTableCell>
                              <StyledTableCell
                                className="cursor"
                                scope="row"
                                align="center"
                              >
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "#0070bb",
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                  }}
                                  onClick={() => {
                                    props.navigate("/googlemapview", {
                                      state: {
                                        id: transportStopId,
                                      },
                                    });
                                  }}
                                >
                                  Track
                                </span>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentTransportDetail));
