import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./Report.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import "react-phone-input-2/lib/style.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//------------------redux-------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { baseUrl } from "../../../services/index.js";
import Dialog from "@material-ui/core/Dialog";
//------------------api calls-------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import Loder from "../../Loder/Loder.jsx";
const EmployeeDetailsFormReport = (props) => {
  //-----------------------------global id--------------------------

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setOpenImageDailog(true);
  };

  let id = parseInt(props.id);
  let Organization_Name = props.data.institution_name;
  //-----------------------------api calls--------------------------
  const client = useApolloClient();
  //-----------------------------local state--------------------------
  const [checkbox, setCheckbox] = useState(true);
  const [documentsArr, setDocumentsArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Employeeformdata, setEmployeeformdata] = useState({
    birth_certificate: {},
    pan_card: {},
    status: "",
    designation_id: "",
    department_id: "",
    email: "",
    Teacher_first_name: "",
    Teacher_middle_name: "",
    Teacher_last_name: "",
    phone: "",
    institution_id: "",
    joining_date: "",
    gender: "",
    birth_date: "",
    Current_line1: "",
    Current_line2: "",
    Current_city: "",
    Current_state: "",
    Current_zip_code: "",
    Current_country: "",
    Permanent_line1: "",
    Permanent_line2: "",
    Permanent_city: "",
    Permanent_state: "",
    Permanent_zip_code: "",
    Permanent_country: "",
    blood_group: "",
    nationality: "",
    tax_id: "",
    marital_status: "",
    passport_id: "",
    national_id: "",
    religion: "",
    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Mother_first_name: "",
    Mother_middle_name: "",
    Mother_last_name: "",
    Spouse_first_name: "",
    Spouse_middle_name: "",
    Spouse_last_name: "",
    education_qualification_id: "",
    passport_number: "",
    passport_expiry_date: "",
    tax_pancard_number: "",
    display_id: "",
    login_id: "",
    education_qualification_arr: [],
    photopath: "",
  });

  //-----------------------------methods--------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.location.state.id) {
      id = parseInt(props.location.state.id);
    }

    getDesignationanddepartment();
  }, [props]);

  const getDesignationanddepartment = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Id_based_Employee_details,
          variables: {
            id,
          },
        })
        .then((response) => {
          const employeeeditdata = response.data.getEmployeeById;

          setEmployeeformdata({
            ...setEmployeeformdata,
            pan_card: employeeeditdata.tax,
            birth_certificate: employeeeditdata.birth_cert,
            education_qualification_arr:
              employeeeditdata.education_qualifications,
            photopath: employeeeditdata.photo.path,
            login_id: employeeeditdata.login.login_id,
            display_id: employeeeditdata.login.display_id,
            status: employeeeditdata.status,
            designation_id: employeeeditdata.designation.name,
            department_id: employeeeditdata.department.name,
            email: employeeeditdata.email,
            Teacher_first_name: employeeeditdata.name.first_name,
            Teacher_middle_name: employeeeditdata.name.middle_name,
            Teacher_last_name: employeeeditdata.name.last_name,
            phone: employeeeditdata.phone,
            joining_date: employeeeditdata.joining_date,
            gender: employeeeditdata.gender,
            birth_date: employeeeditdata.birth_date,
            Current_line1: employeeeditdata.currentAddress.line1,
            Current_line2: employeeeditdata.currentAddress.line2,
            Current_city: employeeeditdata.currentAddress.city,
            Current_state: employeeeditdata.currentAddress.state,
            Current_zip_code: employeeeditdata.currentAddress.zip_code,
            Current_country: employeeeditdata.currentAddress.country,
            Permanent_line1: employeeeditdata.permanentAddress.line1,
            Permanent_line2: employeeeditdata.permanentAddress.line2,
            Permanent_city: employeeeditdata.permanentAddress.city,
            Permanent_state: employeeeditdata.permanentAddress.state,
            Permanent_zip_code: employeeeditdata.permanentAddress.zip_code,
            Permanent_country: employeeeditdata.permanentAddress.country,
            blood_group: employeeeditdata.blood_group,
            nationality: employeeeditdata.nationality,
            tax_id: employeeeditdata.tax_id,
            marital_status: employeeeditdata.marital_status,
            passport_id: employeeeditdata.passport_id,
            national_id: employeeeditdata.national_id,
            religion: employeeeditdata.religion,
            Father_first_name: employeeeditdata.fatherName.first_name,
            Father_middle_name: employeeeditdata.fatherName.middle_name,
            Father_last_name: employeeeditdata.fatherName.last_name,

            Mother_first_name:
              Object.keys(employeeeditdata.motherName).length !== 0
                ? employeeeditdata.motherName.first_name
                : "No Data",
            Mother_middle_name:
              Object.keys(employeeeditdata.motherName).length !== 0
                ? employeeeditdata.motherName.middle_name
                : "",
            Mother_last_name:
              Object.keys(employeeeditdata.motherName).length !== 0
                ? employeeeditdata.motherName.last_name
                : "",
            Spouse_first_name: employeeeditdata.spouseName.first_name,
            Spouse_middle_name: employeeeditdata.spouseName.middle_name,
            Spouse_last_name: employeeeditdata.spouseName.last_name,
            education_qualification_id: "",
            passport_number: employeeeditdata.passport_number,
            passport_expiry_date: employeeeditdata.passport_expiry_date,
            tax_pancard_number: employeeeditdata.tax_pancard_number,
          });
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Employee Onboarding Form
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={2}>
                            <div className="inputMarginright">
                              <img
                                src={`${Service.baseUrl}/${Employeeformdata.photopath}`}
                                alt=""
                                style={{ width: 110, height: 115 }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={5} className="mt-3">
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                Employee ID : {Employeeformdata?.display_id}
                              </Card>
                            </div>
                            <div className="inputMarginright mt-2 card_invoicedetails_border">
                              <Card className="p-1">
                                Login ID : {Employeeformdata.email}
                              </Card>
                            </div>
                          </Grid>
                          <Grid item md={5} className="mt-3">
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                Status : {Employeeformdata.status}
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">Employee Details</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Department</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.department_id}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Designation
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.designation_id}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date Of Joining
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.joining_date}</p>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="text_filed_heading">Employee Name</div>
                        <div className="name_outside_border">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Teacher_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Teacher_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Teacher_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date of Birth
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.birth_date}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Gender</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.gender}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.phone}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Blood Group
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.blood_group}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Email</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.email}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.passport_id}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Passport Expiry Date
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.passport_expiry_date}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              National ID
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.national_id}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Tax Id Pan Card Number
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.tax_id}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">Religion</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.religion}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Marital Status
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.marital_status}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.nationality}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading">Spouse's Name</div>
                        <div className="name_outside_border">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Spouse_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Spouse_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Spouse_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Mother's Name</div>
                        <div className="name_outside_border">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Mother_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Mother_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Mother_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Father's Name</div>
                        <div className="name_outside_border mb-2">
                          <Grid className="grid_main_div mb-2">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Father_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Father_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Father_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Address</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Current Address
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_line1}</p>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_line2}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Country</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_country}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">State</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_state}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_zip_code}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="d-flex mt-3">
                          <span className="text_filed_heading">
                            Permanent Address
                          </span>
                          <span className="ml-5">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="filladdress"
                                // onClick={(e) => fillAddress(e)}
                                checked={checkbox}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Same As Current Address
                              </label>
                            </div>
                          </span>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_line1}</p>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_line2}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Country</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_country}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">State</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_state}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_zip_code}</p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">
                    Educational Qualification
                  </div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {Employeeformdata.education_qualification_arr.length !==
                          0 &&
                          Employeeformdata.education_qualification_arr.map(
                            (item, index) => (
                              <Card
                                className="cardvisible_border mt-2 mb-2"
                                key={index}
                              >
                                <div className="textfiled_margin">
                                  <Grid className="grid_main_div">
                                    <Grid item md={6}>
                                      <div className="text_filed_heading">
                                        Qualification Attained
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.qualification}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="text_filed_heading">
                                        Course Name
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.course_name}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="text_filed_heading">
                                        College/University Name
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.university_name}</p>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className="text_filed_heading gapfromanotherinput">
                                    Peroid Of Education
                                  </div>
                                  <Grid className="grid_main_div  mb-2">
                                    <Grid item md={6}>
                                      <div className="text_filed_heading">
                                        Start Date
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.start_date}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="text_filed_heading">
                                        End Date
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.end_date}</p>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Card>
                            )
                          )}
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Documents</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Birth Certificate
                          </Grid>
                          <Grid item md={6}>
                            <div
                              onClick={() => {
                                HandleImageDailogBox(
                                  Employeeformdata.birth_certificate.path
                                );
                              }}
                            >
                              {Employeeformdata.birth_certificate.filename}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Aadhar Card
                          </Grid>
                          <Grid item md={6}></Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Certificate
                          </Grid>
                          <Grid item md={6}></Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Tax/Pan Card
                          </Grid>
                          <Grid item md={6}>
                            <div
                              onClick={() => {
                                HandleImageDailogBox(
                                  Employeeformdata.pan_card.path
                                );
                              }}
                            >
                              {Employeeformdata.pan_card.filename}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Add Any
                          </Grid>
                          <Grid item md={6}></Grid>

                          <Grid item md={1}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
          setimagepath("");
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
                setimagepath("");
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <span>
            <img
              src={baseUrl + "/" + imagepath}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </span>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(EmployeeDetailsFormReport)));
