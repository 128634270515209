import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Expand from "react-expand-animated";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
const CreateInvoiceTemplate = (props) => {
  //----------------global id----------------
  let institution_id = parseInt(props.manageInstitution.institution_id);
  //--------------------API CALLS-----------------
  const client = useApolloClient();

  const [Create_Edit_invoice_Data, {}] = useMutation(
    Service.Create_Edit_invoice_Data
  );
  const [Add_Invoice_Template_Fee] = useMutation(
    Service.Add_Invoice_Template_Fee
  );

  const [Update_Invoice_Fee, {}] = useMutation(Service.Update_Invoice_Fee);
  const [Delete_Invoice_Fee_Items, {}] = useMutation(
    Service.Delete_Invoice_Fee_Items
  );
  //----------------local state-------------------
  const [showInvoiceTitle, setShowInvoiceTitle] = useState(false);
  const [wholeDataBool, setWholeDataBool] = useState(false);
  const [title, setTitle] = useState("");
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [totalAmountEdit, settotalAmountEdit] = useState("");
  const [tmepId, settmepId] = useState("");
  const [taxEdit, settaxEdit] = useState("");
  const [opencreateeditinvoice, setCreateEditInvoice] = useState(false);
  const [createdInvoiceArr, setCreatedInvoiceArr] = useState([]);
  const [subtypeId, setsubtypeId] = useState(-1);
  const [subtypeError, setsubtypeError] = useState(false);

  const [subtypeEditId, setsubtypeEditId] = useState("");
  const [isloading, setisloading] = useState(false);

  const [feeSubTypeArr, setfeeSubTypeArr] = useState([]);
  const [feeSubTypeEditArr, setfeeSubTypeEditArr] = useState([]);
  const [createeditfeetype, setcreateeditfeetype] = useState(false);
  const [invoiceId, setInvoiceId] = useState(-1);

  //error

  const [subtypeIdError, setsubtypeIdError] = useState(false);
  const [totalAmountError, settotalAmountError] = useState(false);
  const [taxError, settaxError] = useState(false);
  const [titleError, settitleError] = useState(false);
  const [tax, setTax] = useState(0.0);
  const [amount, setAmount] = useState(0.0);

  //----------------methods-------------------
  useEffect(() => {
    try {
      client
        .query({
          query: Service.Get_Fee_Subtype_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setfeeSubTypeArr(response.data.getFeeSubtype);
          setfeeSubTypeEditArr(response.data.getFeeSubtype);
        })
        .catch((err) => console.log("catch err", err));
    } catch (error) {
      console.log("t and c error", error);
    }
  }, [wholeDataBool, institution_id, client]);

  const selectSubtypeFn = (id) => {
    setsubtypeError(false);
    let subtype_ID = parseInt(id);
    if (subtype_ID === -1) {
      setsubtypeId(-1);
      return;
    }

    setsubtypeId(id);
  };
  const selectSubtypeEditFn = (id) => {
    setsubtypeEditId(id);
  };

  const opendailogcreateeditinvoice = (item) => {
    settmepId(item.id);
    settotalAmountEdit(item.amount);
    settaxEdit(item.tax);
    setCreateEditInvoice(true);
  };
  const openDeleteDialog = (params) => {
    setdeleteId(params.id);
    setdeleteName(params.name);
    setDeleteDialog(true);
  };
  const deleteInvoiceWithItemfn = () => {
    let id = parseInt(deleteId);
    try {
      Delete_Invoice_Fee_Items({
        variables: { id },
      })
        .then((response) => {
          setDeleteDialog(false);
          if (
            response.data.deleteInvoiceTemplateFeeItems.message.trim() ===
            "FAIL"
          ) {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(
            response.data.deleteInvoiceTemplateFeeItems.message,
            "success"
          );
        })
        .catch((error) => {
          setDeleteDialog(false);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const getFeeItem = (id) => {
    try {
      client
        .query({
          query: Service.Get_Invoice_Fee_Items,
          variables: {
            invoice_template_id: parseInt(id),
          },
        })
        .then((response) => {
          setCreatedInvoiceArr(response.data.getInvoiceTemplateFeeItems);
        })
        .catch((err) => console.log("catch err", err));
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const createEditInvoiceHandle = () => {
    
    try {
      if (!blankValidator(invoiceId)) {
        return;
      }
      if (!blankValidator(subtypeId)) {
        setsubtypeError(true);
        return;
      }
      setisloading(true);
      Add_Invoice_Template_Fee({
        variables: {
          invoice_template_id: parseInt(invoiceId),
          fee_subtype_id: parseInt(subtypeId),
          amount: amount,
          tax: tax,
        },
      })
        .then((response) => {
          setisloading(false);
          setCreateEditInvoice(false);
          showNotificationMsz(
            response.data.addInvoiceTemplateFeeItems.message,
            "success"
          );

          setsubtypeId(-1);
          getFeeItem(invoiceId);
        })
        .catch((err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const UpdateInvoiceFee = () => {
    try {
      if (!blankValidator(subtypeEditId)) {
        setsubtypeIdError(true);
        return;
      }
      if (!blankValidator(totalAmountEdit)) {
        settotalAmountError(true);
        return;
      }
      if (!blankValidator(taxEdit)) {
        settaxError(true);
        return;
      }

      Update_Invoice_Fee({
        variables: {
          id: parseInt(tmepId),
          tax: taxEdit,
          amount: totalAmountEdit,
          fee_subtype_id: parseInt(subtypeEditId),
        },
      })
        .then((response) => {
          setCreateEditInvoice(false);
          showNotificationMsz(
            response.data.updateInvoiceTemplateFeeItems.message,
            "success"
          );
        })
        .catch((err) => {
          setCreateEditInvoice(false);
          showNotificationMsz(err, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const CreateTemplateTitleFn = () => {
    try {
      if (!blankValidator(title)) {
        settitleError(true);
        return;
      }
      setisloading(true);
      Create_Edit_invoice_Data({
        variables: { institution_id, name: title },
      })
        .then((response) => {
          setisloading(false);
          setInvoiceId(parseInt(response.data.addInvoiceTemplate.id));
          showNotificationMsz(
            response.data.addInvoiceTemplate.message,
            "success"
          );

          setWholeDataBool(true);
          setShowInvoiceTitle(true);
        })
        .catch((err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">
                    Create Invoice Template
                  </div>
                  <div className="mt-3 mr-3 Heading_bold">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration"
                      onClick={() => {
                       props.navigate("createeditinvoice");
                      }}
                    >
                      Add New FeeSubtype
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side ">
                  <Card>
                    <div className=" pb-3">
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={12}>
                              <div className="inputMarginright">
                                <label for="exampleInputEmail1">Title</label>
                                {showInvoiceTitle ? (
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={title}
                                      disabled={true}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Title"
                                      value={title}
                                      onChange={(e) => {
                                        setTitle(e.target.value);
                                      }}
                                    />
                                    {titleError && (
                                      <sapn className="text-danger">
                                        Enter the Title
                                      </sapn>
                                    )}
                                  </div>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                          <div className="btn_add mt-2">
                            {!showInvoiceTitle && (
                              <Button
                                variant="contained"
                                className="addButtonwidth button_login_decoration"
                                onClick={CreateTemplateTitleFn}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>

                      {wholeDataBool && (
                        <div>
                          <Grid item md={4}>
                            <div className="inputMarginright ml-3 mt-5 Heading_bold">
                              <p>Fee Subtype</p>
                            </div>
                          </Grid>
                          {!createeditfeetype ? (
                            <div className="enquiry_heading mt-2">
                              <div className="ml-3 mt-3 Heading_bold">
                                Create Invoice Template
                              </div>
                              <div
                                className=" mt-3 icon_pencil_color d-flex addPointer"
                                onClick={() => setcreateeditfeetype(true)}
                              >
                                <span className="mr-2 mt-1">Add</span>
                                <span className="iconstoaddfee mr-3">
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="card_margin_both_side mt-5">
                              <Expand open={createeditfeetype}>
                                <Card>
                                  <div className="card_admissiondetails_height">
                                    <div className="textfiled_margin">
                                      <Grid className="grid_main_div">
                                        <Grid item md={12}>
                                          <div className="float-right">
                                            <span className="iconstoaddfee">
                                              <i
                                                className="fa fa-minus-circle"
                                                onClick={() =>
                                                  setcreateeditfeetype(false)
                                                }
                                              ></i>
                                            </span>
                                          </div>

                                          <div className=" ">
                                            <label>Subtype</label>
                                            <select
                                              class="form-control"
                                              onChange={(e) =>
                                                selectSubtypeFn(e.target.value)
                                              }
                                            >
                                              <option value={-1}>
                                                Select Subtype
                                              </option>
                                              {feeSubTypeArr.length > 0 &&
                                                feeSubTypeArr.map(
                                                  (item, index) => (
                                                    <option
                                                      value={item.id}
                                                      key={index}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            {subtypeError && (
                                              <p className="text-danger">
                                                Please Enter Subtype
                                              </p>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Grid className="grid_main_div">
                                        <Grid item md={6}>
                                          <label>Amount</label>
                                          <input
                                            className="form-control"
                                            onChange={(e) => {
                                              setAmount(e.target.value);
                                            }}
                                            value={amount}
                                          ></input>
                                        </Grid>
                                        <Grid item md={6}>
                                          <div className="pl-2">
                                            <label>Tax</label>
                                            <input
                                              className="form-control"
                                              onChange={(e) => {
                                                setTax(e.target.value);
                                              }}
                                              value={tax}
                                            ></input>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <div className="btn_add mt-2">
                                        <Button
                                          variant="contained"
                                          className="addButtonwidth button_login_decoration"
                                          onClick={createEditInvoiceHandle}
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Expand>
                            </div>
                          )}
                          <div className="ml-3 mr-3  mt-2 ">
                            <div className="cardheight_overflow">
                              <Grid className="grid_main_div">
                                <Grid item md={1}></Grid>
                              </Grid>
                              {createdInvoiceArr.map((item, index) => (
                                <Card
                                  className="cardvisible_border mb-2"
                                  key={index}
                                >
                                  <div className="card_admissiondetails_height">
                                    <div className="textfiled_margin">
                                      <div className="">
                                        <Grid className="grid_main_div">
                                          <Grid item md={4}>
                                            <div className="inputMarginright mt-1 mb-1">
                                              <p>{item.feesubtype.name}</p>
                                            </div>
                                          </Grid>
                                          <Grid item md={4}>
                                            <div className="inputMarginright mt-1 mb-1">
                                              <p>{item.amount}</p>
                                            </div>
                                          </Grid>
                                          <Grid item md={3}>
                                            <div className="inputMarginright mt-1 mb-1">
                                              <p>{item.tax}</p>
                                            </div>
                                          </Grid>
                                          <Grid item md={1}>
                                            <div className="d-flex pt-2">
                                              <span className="iconstoaddfee mr-2">
                                                <i
                                                  className="fa fa-pencil"
                                                  onClick={() =>
                                                    opendailogcreateeditinvoice(
                                                      item
                                                    )
                                                  }
                                                ></i>
                                              </span>
                                              <span className="iconstoaddfee ml-2">
                                                <i
                                                  className="fa fa-trash"
                                                  onClick={() =>
                                                    openDeleteDialog(item)
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={opencreateeditinvoice}
        onClose={() => setCreateEditInvoice(false)}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => setCreateEditInvoice(false)}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label>Subtype</label>
                <select
                  class="form-control"
                  onChange={(e) => {
                    setsubtypeIdError(false);
                    selectSubtypeEditFn(e.target.value);
                  }}
                >
                  <option>Subtype</option>
                  {feeSubTypeEditArr.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {subtypeIdError && (
                  <span className="text-danger">Select the Subtype</span>
                )}
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div">
            <Grid item md={1}></Grid>
            <Grid item md={10}>
              <label for="exampleInputEmail1">Total Amount</label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={totalAmountEdit}
                  onChange={(e) => {
                    settotalAmountError(false);
                    settotalAmountEdit(e.target.value);
                  }}
                />
                {totalAmountError && (
                  <span className="text-danger">Enter Total Amount</span>
                )}
              </div>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>
          <Grid className="grid_main_div">
            <Grid item md={1}></Grid>
            <Grid item md={10}>
              <label for="exampleInputEmail1">Tax</label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={taxEdit}
                  onChange={(e) => {
                    settaxError(false);
                    settaxEdit(e.target.value);
                  }}
                />
                {taxError && <span className="text-danger">Enter Tax</span>}
              </div>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>
          <Grid className="grid_main_div mt-1  mb-3">
            <Grid item md={1}></Grid>
            <Grid item md={8}>
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateInvoiceFee}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={3}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDeleteDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete : {deleteName}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteInvoiceWithItemfn()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(CreateInvoiceTemplate)));
