import React, { useState, useEffect } from "react";
import { DirectionsRenderer } from "react-google-maps";

const MapDirectionsRenderer = (props) => {
  const [directions, setDirections] = useState(null);
  const [error, seterror] = useState(null);
  useEffect(() => {
    blueLineShow();
  }, [props]);

  const blueLineShow = async () => {
    try {
      const { places, travelMode } = props;

      const waypoints = await places.map((p) => ({
        location: {
          lat: parseFloat(p.stop_latitude),
          lng: parseFloat(p.stop_longitude),
        },
        stopover: true,
      }));

      if (waypoints.length >= 2) {
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;

        const directionsService =
          await new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: travelMode,
            waypoints: waypoints,
          },
          async (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              await setDirections(result);
            } else {
              await seterror(result);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (error) {
    return <h1>{error}</h1>;
  } else {
    return (
      <div>{directions && <DirectionsRenderer directions={directions} />}</div>
    );
  }
};

export default MapDirectionsRenderer;
