import React, { useState, useEffect } from "react";
import "./ManageInstitution.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import AssignTimeTabletabs from "./AssignTimeTabletabs";
import Loder from "../../Loder/Loder.jsx";
//----------redux----------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";

const ClassTimeTableOpenAssignLink = ({ ADMIN_DATA, navigate }) => {
  const { academic_session_id, institution_name } = ADMIN_DATA;
  //--------------- gloabl id ----------------------
  let institution_id = parseInt(ADMIN_DATA.institution_id);
  //--------------- api calls ----------------------
  const client = useApolloClient();
  const [Add_Timetable, {}] = useMutation(Service.Add_Timetable);
  //----------------------local state-----------------
  const [sectionId, setSectionId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [SectionArr, setSectionArr] = useState([]);
  const [templateId, setTemplateId] = useState(-1);
  const [templateArr, setTemplateArr] = useState([]);
  const [gradeArr, setGradeArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [openEdittimeTable, setopenEdittimeTable] = useState(false);
  const [page, setPage] = React.useState(0);
  const [starttime, setstarttime] = useState(new Date());
  const [endtime, setendtime] = useState(new Date());
  //error
  const [templateidError, settemplateidError] = useState(false);
  const [classnameError, setclassnameError] = useState(false);
  const [sectionIdError, setsectionIdError] = useState(false);
  const [startdateError, setstartdateError] = useState(false);
  const [enddateError, setenddateError] = useState(false);

  const handlestarttime = (date) => {
    setstarttime(date);
  };
  const handleendtime = (date) => {
    setendtime(date);
  };
  const handleClose = () => {
    setopenEdittimeTable(false);
  };

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_TimeTable_Template,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setTemplateArr(response.data.getTimeTableTemplate);
        })
        .catch((e) => {
          setisloading(false);
          console.log("getTimeTableTemplate catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("getTimeTableTemplate location arr try and catch", error);
    }
  }, []);
  useEffect(() => {
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_id),
          },
        })
        .then((response) => {
          setGradeArr(response.data.getGrade);
        })
        .catch((e) => {
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  }, []);
  const selectClassHandler = async (classId) => {
    setSectionArr([]);
    if (parseInt(classId) === -1) {
      setSectionArr([]);
      return;
    }
    setGradeId(classId);
    try {
      setisloading(true);
      await client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const AssignTimeTableHandler = () => {
    try {
      if (!blankValidator(templateId) || templateId === -1) {
        settemplateidError(true);
        return;
      }
      if (!blankValidator(gradeId) || gradeId === -1) {
        setclassnameError(true);
        return;
      }
      if (!blankValidator(sectionId) || sectionId === -1) {
        setsectionIdError(true);
        return;
      }
      if (!blankValidator(startDate)) {
        setstartdateError(true);
        return;
      }
      if (!blankValidator(endDate)) {
        setenddateError(true);
        return;
      }
      setisloading(true);

      Add_Timetable({
        variables: {
          institution_id,
          section_id: parseInt(sectionId),
          period_template_id: 1,
          start_date: startDate,
          end_date: endDate,
          timetable_template_id: parseInt(templateId),
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.addTimetable.message, "success");
          navigate("/showAllTimetables", {
            state: {
              classId: gradeId,
              sectionselectedid: sectionId,
            },
          });
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-2 ml-3 Heading_bold">
                    Assign Time Table
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className=" mt-1">Select Time Template</div>
                            <div className="inputMarginright">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  settemplateidError(false);
                                  setTemplateId(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Time Template</option>
                                {templateArr.length > 0 ? (
                                  templateArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                              {templateidError && (
                                <span className="text-danger">
                                  Select the Template
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className=" mt-1">Select Class</div>
                            <div className="inputMarginright">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setclassnameError(false);
                                  selectClassHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Class</option>
                                {gradeArr.length > 0 ? (
                                  gradeArr.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.gradeName}
                                    </option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                              {classnameError && (
                                <span className="text-danger">
                                  Select the Class
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className=" mt-1">Section</div>
                            <div className="inputMarginright">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setsectionIdError(false);
                                  setSectionId(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Section</option>
                                {SectionArr.length > 0 ? (
                                  SectionArr.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                              {sectionIdError && (
                                <span className="text-danger">
                                  Select the Section
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={4}>
                            <div className=" mt-1">Start Date</div>
                            <div className="inputMarginright">
                              <input
                                type="date"
                                class="form-control"
                                onChange={(e) => {
                                  setstartdateError(false);
                                  setStartDate(e.target.value);
                                }}
                              />
                              {startdateError && (
                                <span className="text-danger">
                                  Enter the Start Date
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className=" mt-1">End Date</div>
                            <div className="inputMarginright">
                              <input
                                type="date"
                                class="form-control"
                                onChange={(e) => {
                                  setenddateError(false);
                                  setEndDate(e.target.value);
                                }}
                              />
                              {enddateError && (
                                <span className="text-danger">
                                  Enter the End Date
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={2}></Grid>
                          <Grid item md={2}>
                            <div className=" mr-3 mt-4 ml-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={AssignTimeTableHandler}
                              >
                                Assign
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <Card className="mt-2">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="table_foramtitng mt-2">
                          <AssignTimeTabletabs
                            id={templateId}
                          ></AssignTimeTabletabs>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={openEdittimeTable}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        className=""
      >
        <div className="dailog_ceate_update_timetable">
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={5}>
                <div className="text_filed_heading">Start Time</div>
                <div className="inputMarginright ">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      inputVariant="outlined"
                      variant="outlined"
                      value={starttime}
                      onChange={handlestarttime}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item md={5}>
                <div className="text_filed_heading">End Time</div>
                <div className="">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      inputVariant="outlined"
                      variant="outlined"
                      value={endtime}
                      onChange={handleendtime}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>

          <div className="mt-1 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={5}>
                <div className="inputMarginright mt-1">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option>Location</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </Grid>
              <Grid item md={5}>
                <div className="mt-1">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Teacher"
                    autoComplete="off"
                  />
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>

          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={5}>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Location"
                    autoComplete="off"
                  />
                </div>
              </Grid>
              <Grid item md={5}>
                <div className="inputMarginright">
                  <span>
                    <Checkbox />
                  </span>
                  <span>Online mode</span>
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-1  mb-3">
            <Grid item md={10}></Grid>
            <Grid item md={2}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="button_enquiry_details button_login_decoration mr-2"
                  // onClick={this.openAddmisonIntrest}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});
export default connect(
  mapStateToProps,
  null
)(HOC1(ClassTimeTableOpenAssignLink));
