import React, { useState, useEffect, useRef } from "react";
import "./Fees.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import {
  blankValidator,
  showNotificationMsz,
  timeStampToDate,
} from "../../../utils/Validation";
import ReactToPrint from "react-to-print";

const InvoiceDetailView = ({ location, navigate }) => {
  const componentRef = useRef();
  //-----------------------------global id-------------------------
  const { institution_Name, item, studentId } = location.state;
  //-----------------------------api calls-------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //-----------------------------local state-------------------------
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentFullName, setStudentFullName] = useState("");
  const [parentFullName, setParentFullName] = useState("");
  const [studentRollNo, setStudentRollNo] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const invoiceData = item;
  const [InvoiceSummaryArr, setInvoiceSummaryArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  //--------------------------methods----------------------------
  useEffect(() => {
    setInvoiceSummaryArr(item.invoice.invoice_fee_items);
    getStudentData();
  }, [client, studentId]);
  const getStudentData = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Student_Details_Data,
        variables: {
          id: parseInt(studentId),
        },
      });
      const { student_profile, roll_number, section } =
        data.getStudentDetailForm;
      let add1 = `${student_profile.currentAddress.line1} ${student_profile.currentAddress.line2}, ${student_profile.currentAddress.city}`;
      let add2 = `${student_profile.currentAddress.state}, ${student_profile.currentAddress.country}, ${student_profile.currentAddress.zip_code} `;
      let studentFullName = `${student_profile.name.first_name} ${student_profile.name.middle_name} ${student_profile.name.last_name}`;
      let parentFullName = `${student_profile.father.name.first_name} ${student_profile.father.name.middle_name} ${student_profile.father.name.last_name}`;
      setAddress1(add1);
      setAddress2(add2);
      setPhoneNo(student_profile.father.phone_number);
      setStudentEmail(student_profile.email);
      setStudentFullName(studentFullName);
      setParentFullName(parentFullName);
      setStudentRollNo(roll_number);
      setStudentClass(section.grade.gradeName);
      setStudentSection(section.name);
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };
  // const printfunction = (areaID) => {
  //   //window.print();
  //   // var printContent = document.getElementById(areaID);
  //   // var WinPrint = window.open("", "", "width=900,height=650");
  //   // WinPrint.document.write(printContent.innerHTML);
  //   // WinPrint.document.close();
  //   // WinPrint.focus();
  //   // WinPrint.print();
  //   // //WinPrint.close();
  //   // var printContents = document.getElementById(divName).innerHTML;
  //   // var originalContents = document.body.innerHTML;
  //   // document.body.innerHTML = printContents;
  //   // window.print();
  //   // document.body.innerHTML = originalContents;
  // };

  const SavePdffunction = () => {
    window.print();
  };

  const gobackprevoius = () => {
    navigate.goBack();
  };

  return (
    <div className="main_full_width" id="invoice__print__area">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />
              <div className="pageBackButton">
                <span onClick={gobackprevoius} className="manage_edit_cursor">
                  <i class="fa fa-chevron-left"></i> Back
                </span>
              </div>
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Invoice Summary</div>
                  <div className="mt-3 mr-3">
                    <Card>
                      <div className="pl-2 pr-2 pt-1 pb-1">
                        <span className="mr-2 cursor_for_print">
                          <i
                            class="fa fa-file-pdf-o"
                            onClick={SavePdffunction}
                          ></i>
                        </span>
                        <span className="cursor_for_print">
                          <ReactToPrint
                            trigger={() => (
                              <i
                                class="fa fa-print"
                                // onClick={() =>
                                //   printfunction("invoice__print__area")
                                // }
                              ></i>
                            )}
                            content={() => componentRef.current}
                          />
                        </span>
                      </div>
                    </Card>
                  </div>
                </div>

                <div className="card_margin_both_side" ref={componentRef}>
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-4 inputMarginright">
                              <div className="Heading_bold">
                                {institution_Name}
                              </div>
                              <div className="mt-3">
                                <div className="d-flex">
                                  <div style={{ flex: 4 }}>
                                    <h6>Address</h6>
                                  </div>
                                  <div style={{ flex: 0.3 }}>:</div>
                                  <div style={{ flex: 5.6 }}>
                                    <p>
                                      {address1} <br /> {address2}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div style={{ flex: 4 }}>
                                    <h6>Contact Number</h6>
                                  </div>
                                  <div style={{ flex: 0.3 }}>:</div>
                                  <div style={{ flex: 5.6 }}>
                                    <p>+{phoneNo}</p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div style={{ flex: 4 }}>
                                    <h6>Registration Number</h6>
                                  </div>
                                  <div style={{ flex: 0.3 }}>:</div>
                                  <div style={{ flex: 5.6 }}>
                                    <p>12656445</p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div style={{ flex: 4 }}>
                                    <h6>Email</h6>
                                  </div>
                                  <div style={{ flex: 0.3 }}>:</div>
                                  <div style={{ flex: 5.6 }}>
                                    <p>{studentEmail}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 inputMarginright">
                              <div className="card_invoicedetails_border p-3">
                                <div className="Heading_bold">
                                  Student Details
                                </div>
                                <div className="mt-3">
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Name</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{studentFullName}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Parent Name</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{parentFullName}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Roll Number</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{studentRollNo}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Class</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{studentClass}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Section</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{studentSection}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2 card_invoicedetails_border">
                              <div className="Heading_bold ml-3 mt-2">
                                {invoiceData.invoice?.name}
                              </div>
                              <div className="p-3 ">
                                <table class="table table-striped mt-1 card_invoicedetails_border">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Invoice Number</th>
                                      <td>
                                        {invoiceData.invoice_display_id &&
                                          invoiceData.invoice_display_id}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Invoice Date</th>
                                      <td>
                                        {timeStampToDate(
                                          invoiceData.invoice?.create_date
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Payment Due Date</th>
                                      <td>
                                        {timeStampToDate(
                                          invoiceData.invoice?.due_date
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Invoice Amount</th>
                                      <td>
                                        {invoiceData.invoice?.currency_code}{" "}
                                        {invoiceData.amount &&
                                          invoiceData.amount}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-3 mb-2 card_invoicedetails_border">
                          <div className="Heading_bold ml-3 mt-3">
                            Invoice Summary
                          </div>
                          <div className=" table_foramtitng mt-1 mb-3 p-3">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Description
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Invoice Amount
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Tax
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Total Amount
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {InvoiceSummaryArr.map((item, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">
                                        {invoiceData.invoice?.description}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        
                                        scope="row"
                                        align="left"
                                      >
                                        {invoiceData.invoice?.currency_code}{" "}
                                        {item.amount && item.amount}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {item.tax && item.tax}%
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(invoiceData.invoice)
                                          ? ""
                                          : !blankValidator(
                                              invoiceData.invoice.currency_code
                                            )
                                          ? ""
                                          : invoiceData.invoice
                                              .currency_code}{" "}
                                        {parseInt(item.amount) +
                                          (parseInt(item.amount) *
                                            parseInt(item.tax)) /
                                            100}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
export default (HOC1(InvoiceDetailView));
