import React, { Component } from "react";
//import "./TransportStaff.css";
import HOC1 from "../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="main_back_color">
          <div className="margin_left_right">
            <Grid item xs={6} md={6}>
              <div className="div1">
                <div className="form_changePAssword_width">
                  <p className="signupheading">Change Password</p>
                  <div className="main_padding_top_bottom">
                    <div className=" inputfiledformatting margin_input_filed">
                      <p
                        for="exampleInputEmail1"
                        className="text_field_heading"
                      >
                        Old Password
                      </p>
                      <TextField
                        className="passwordtextbox"
                        id="myInput"
                        variant="outlined"
                        fullWidth
                        type="password"
                        placeholder="Password "
                        autoComplete="current-password"
                      />
                    </div>
                    <div className=" inputfiledformatting margin_input_filed">
                      <p
                        for="exampleInputEmail1"
                        className="text_field_heading"
                      >
                        New Password
                      </p>
                      <TextField
                        className="passwordtextbox"
                        id="myInput"
                        variant="outlined"
                        fullWidth
                        name="password"
                        type="password"
                        placeholder="password"
                        autoComplete="current-password"
                      />
                    </div>

                    <div className=" inputfiledformatting margin_input_filed">
                      <p
                        for="exampleInputEmail1"
                        className="text_field_heading"
                      >
                        Confirm Password
                      </p>
                      <TextField
                        className="passwordtextbox"
                        id="myInput"
                        variant="outlined"
                        fullWidth
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </div>

                    <div className="inputfiledformatting buttonpadding1">
                      <Button
                        variant="contained"
                        className="login_button button_login_decoration"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default HOC1(ChangePassword);
