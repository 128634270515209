import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./Eventone.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getValue,
  blankValidator,
  timeStampToDateandTime,
  getcurrentDate,
  timeStampToDate,
  getSubStringValue,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
//redux
import * as Service from "../../../services/index";

import Dialog from "@material-ui/core/Dialog";

import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import OnlineLinkLocation from "./OnlineLinkLocation.jsx";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageEventForTeacher = (props) => {
  let institution_id = parseInt(props.data.institution_id);

  const [isloading, setisloading] = useState(false);
  let academic_session_id = parseInt(props.location.state.academic_session_id);
  const client = useApolloClient();
  // const [openMeetingDailog, setOpenMeetingDailog] = useState(false);
  // const [displayData, setDisplayData] = useState("");
  const [OnGoingArray, setOnGoingArray] = useState([]);
  const [CompletedArray, setCompletedArray] = useState([]);
  const [NotStartedArray, setNotStartedArray] = useState([]);

  const [value, setValue] = React.useState(0);

  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [eventID, setEventID] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    EventList();
  }, [client, institution_id]);
  const EventList = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_Event_For_UserId,
          variables: {
            user_id: parseInt(props.data.userid),
            user_type: props.data.usertype,
          },
        })
        .then((response) => {
          if (response.data.getEventForUserId.length !== 0) {
            const element = response.data.getEventForUserId;

            setOnGoingArray(
              element.filter((item) => {
                return (
                  getcurrentDate(item.start_date) <= new Date() &&
                  getcurrentDate(item.end_date) >= new Date()
                );
              })
            );
            setNotStartedArray(
              element.filter((item) => {
                return getcurrentDate(item.start_date) > new Date();
              })
            );
            setCompletedArray(
              element.filter((item) => {
                return getcurrentDate(item.end_date) < new Date();
              })
            );
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);

  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(5);
  const [Delete_event, {}] = useMutation(Service.Delete_event);
  //filter ongoing

  const filterOnGoingEvent = OnGoingArray.filter((item) => {
    return getValue(item?.event_name)
      .toLowerCase()
      .indexOf(search.toLowerCase()) !== -1
      ? true
      : false;
  });
  //filter not started or active
  const filterNotStartedEvent = NotStartedArray.filter((item) => {
    return getValue(item?.event_name)
      .toLowerCase()
      .indexOf(search1.toLowerCase()) !== -1
      ? true
      : false;
  });

  //filter completed
  const filterCompletedEvent = CompletedArray.filter((item) => {
    return getValue(item?.event_name)
      .toLowerCase()
      .indexOf(search2.toLowerCase()) !== -1
      ? true
      : false;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const cancelEventHandle = (row) => {
    setEventID(row.id);

    setDeleteDialog(true);
  };
  const deleteEventHandler = () => {
    setisloading(true);
    let id = parseInt(eventID);
    try {
      Delete_event({
        variables: { id },
      })
        .then((response) => {
          const { message } = response.data.deleteEvent;
          setisloading(false);
          setDeleteDialog(false);
          showNotificationMsz(message, "success");
          EventList();
        })
        .catch((error) => {
          setDeleteDialog(false);
          setisloading(false);

          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Manage Event</div>
                  <div
                    className="mr-3 mt-4 Heading_bold date_time_color"
                    role="button"
                    onClick={() =>
                      props.navigate("/createEvent", {
                        state: {
                          academic_session_id,
                        },
                      })
                    }
                  >
                    Create Event
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={8}>
                            <div className=" mt-2 ml-2 mr-2 mybarwidth">
                              <Tabs
                                value={value}
                                onChange={handleChange}
                                //  variant="scrollable"
                                aria-label="simple tabs example"
                                //className="tab_width_exam"
                                indicatorColor="primary"
                                textColor="primary"
                                left
                              >
                                <Tab
                                  label="Ongoing"
                                  {...a11yProps(0)}
                                  className="button_login_decoration text_tranformation_exam"
                                />
                                <Tab
                                  label="Up coming"
                                  {...a11yProps(1)}
                                  className="button_login_decoration text_tranformation_exam"
                                />
                                <Tab
                                  label="Completed"
                                  {...a11yProps(2)}
                                  className="button_login_decoration text_tranformation_exam"
                                />
                              </Tabs>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="Pannel_padding_hidden mt-1 mb-3">
                          <TabPanel value={value} index={0}>
                            <Grid className="grid_main_div mt-1">
                              <Grid item md={3}>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Events"
                                    autoComplete="off"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </Grid>

                              <Grid item md={9}></Grid>
                            </Grid>
                            <div className=" table_foramtitng mt-2">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Description
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Location
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Start Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        End Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Organizer
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publish Date
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? filterOnGoingEvent.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : filterOnGoingEvent
                                    ).map((row, index) => {
                                      return (
                                        <StyledTableRow
                                          key={index}
                                          // style={{ width: "100%" }}
                                        >
                                          <StyledTableCell
                                            align="left"
                                            // style={{ width: "35%" }}
                                          >
                                            <Tooltip
                                              title={row.event_name}
                                              arrow
                                            >
                                              <span>
                                                {getSubStringValue(
                                                  row.event_name
                                                )}
                                              </span>
                                            </Tooltip>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            // style={{ width: "30%" }}

                                            scope="row"
                                            align="left"
                                          >
                                            <Tooltip
                                              title={row.description}
                                              arrow
                                            >
                                              <span>
                                                {getSubStringValue(
                                                  row.description
                                                )}
                                              </span>
                                            </Tooltip>
                                          </StyledTableCell>

                                          <StyledTableCell align="left">
                                            <OnlineLinkLocation
                                              data={row}
                                              userType={props.data.usertype}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.start_date) ? (
                                              "No Data"
                                            ) : (
                                              <div>
                                                <span>
                                                  {timeStampToDateandTime(
                                                    row.start_date
                                                  )}
                                                </span>{" "}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.end_date) ? (
                                              "No Data"
                                            ) : (
                                              <div>
                                                <span>
                                                  {timeStampToDateandTime(
                                                    row.end_date
                                                  )}
                                                </span>{" "}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {getFullName(row.organizer_name)}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            // style={{ width: "10%" }}
                                          >
                                            {timeStampToDate(row.publish_date)}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="center"
                                            // style={{ width: "15%" }}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <span
                                                role="button"
                                                className="text-info pr-1"
                                                onClick={() => {
                                                  if (!blankValidator(row)) {
                                                    alert("Not Open");
                                                    return;
                                                  }

                                                  props.navigate(
                                                    "/eventDetails",
                                                    {
                                                      state: {
                                                        data: row,
                                                        isOngoing: true,
                                                        academic_session_id,
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                Open
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info pl-1 pr-1"
                                                onClick={() => {
                                                  if (!blankValidator(row)) {
                                                    alert("Not Open");
                                                    return;
                                                  }

                                                  props.navigate("/editEvent", {
                                                    state: {
                                                      data: row,
                                                      academic_session_id,
                                                    },
                                                  });
                                                }}
                                              >
                                                Edit
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info pl-1 pr-1"
                                                onClick={() => {
                                                  if (
                                                    !blankValidator(
                                                      row === null
                                                    )
                                                  ) {
                                                    alert("Not Open");
                                                    return;
                                                  }
                                                  props.navigate(
                                                    "/eventInvite",
                                                    {
                                                      state: {
                                                        data: row,
                                                        academic_session_id,
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                Invite
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info pl-1"
                                                onClick={() => {
                                                  cancelEventHandle(row);
                                                }}
                                              >
                                                Cancel
                                              </span>
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={filterOnGoingEvent.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </TabPanel>
                        </div>

                        {/* Not Started */}

                        <div className="Pannel_padding_hidden mt-1 mb-3">
                          <TabPanel value={value} index={1}>
                            <Grid className="grid_main_div mt-2">
                              <Grid item md={3}>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Events"
                                    autoComplete="off"
                                    value={search1}
                                    onChange={(e) => {
                                      setSearch1(e.target.value);
                                    }}
                                  />
                                </div>
                              </Grid>

                              <Grid item md={9}></Grid>
                            </Grid>
                            <div className="table_foramtitng mt-2">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Description
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Location
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Start Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        End Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Organizer
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publish Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage1 > 0
                                      ? filterNotStartedEvent.slice(
                                          page1 * rowsPerPage1,
                                          page1 * rowsPerPage1 + rowsPerPage1
                                        )
                                      : filterNotStartedEvent
                                    ).map((row, index) => {
                                      return (
                                        <StyledTableRow
                                          key={index}
                                          style={{ width: "100%" }}
                                        >
                                          <StyledTableCell
                                            align="left"
                                            // style={{ width: "35%" }}
                                          >
                                            <Tooltip
                                              title={row.event_name}
                                              arrow
                                            >
                                              <span>
                                                {getSubStringValue(
                                                  row.event_name
                                                )}
                                              </span>
                                            </Tooltip>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            // style={{ width: "30%" }}

                                            scope="row"
                                            align="left"
                                          >
                                            <Tooltip
                                              title={row.description}
                                              arrow
                                            >
                                              <span>
                                                {getSubStringValue(
                                                  row.description
                                                )}
                                              </span>
                                            </Tooltip>
                                          </StyledTableCell>

                                          <StyledTableCell align="left">
                                            <OnlineLinkLocation data={row} />
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.start_date) ? (
                                              "No Data"
                                            ) : (
                                              <div>
                                                <span>
                                                  {timeStampToDateandTime(
                                                    row.start_date
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.end_date) ? (
                                              "No Data"
                                            ) : (
                                              <div>
                                                <span>
                                                  {timeStampToDateandTime(
                                                    row.end_date
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {getFullName(row.organizer_name)}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            style={{ width: "10%" }}
                                          >
                                            {timeStampToDate(row.publish_date)}
                                          </StyledTableCell>

                                          {/*
                                              <StyledTableCell
                                            align="center"
                                            // style={{ width: "15%" }}
                                          > <div className="d-flex justify-content-between">
                                              <span
                                                role="button"
                                                className="text-info"
                                                onClick={() => {
                                                  if (!blankValidator(row)) {
                                                    alert("Not Open");
                                                    return;
                                                  }

                                                 props.navigate(
                                                    "/eventDetails",
                                                    {
                                                      data: row,
                                                      isNotStarted: true,
                                                      academic_session_id,
                                                    }
                                                  );
                                                }}
                                              >
                                                Open
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info"
                                                onClick={() => {
                                                  if (!blankValidator(row)) {
                                                    alert("Not Open");
                                                    return;
                                                  }

                                                 props.navigate(
                                                    "/editEvent",
                                                    {
                                                      data: row,
                                                      academic_session_id,
                                                    }
                                                  );
                                                }}
                                              >
                                                Edit
                                              </span>
                                              {row.showInvite && (
                                                <div>
                                                  |
                                                  <span
                                                    role="button"
                                                    className="text-info"
                                                    onClick={() => {
                                                      if (
                                                        !blankValidator(
                                                          row === null
                                                        )
                                                      ) {
                                                        alert("Not Open");
                                                        return;
                                                      }
                                                     props.navigate(
                                                        "/eventInvite",
                                                        {
                                                          data: row,
                                                          academic_session_id,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    Invite
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </StyledTableCell> */}
                                          <StyledTableCell
                                            align="center"
                                            // style={{ width: "15%" }}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <span
                                                role="button"
                                                className="text-info pr-1"
                                                onClick={() => {
                                                  if (!blankValidator(row)) {
                                                    alert("Not Open");
                                                    return;
                                                  }

                                                  props.navigate(
                                                    "/eventDetails",
                                                    {
                                                      state: {
                                                        data: row,
                                                        isNotStarted: true,
                                                        academic_session_id,
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                Open
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info pl-1 pr-1"
                                                onClick={() => {
                                                  if (!blankValidator(row)) {
                                                    alert("Not Open");
                                                    return;
                                                  }

                                                  props.navigate("/editEvent", {
                                                    state: {
                                                      data: row,
                                                      academic_session_id,
                                                    },
                                                  });
                                                }}
                                              >
                                                Edit
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info pl-1 pr-1"
                                                onClick={() => {
                                                  if (
                                                    !blankValidator(
                                                      row === null
                                                    )
                                                  ) {
                                                    alert("Not Open");
                                                    return;
                                                  }
                                                  props.navigate(
                                                    "/eventInvite",
                                                    {
                                                      state: {
                                                        data: row,
                                                        academic_session_id,
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                Invite
                                              </span>
                                              |
                                              <span
                                                role="button"
                                                className="text-info pl-1"
                                                onClick={() => {
                                                  cancelEventHandle(row);
                                                }}
                                              >
                                                Cancel
                                              </span>
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={filterNotStartedEvent.length}
                                  rowsPerPage={rowsPerPage1}
                                  page={page1}
                                  onChangePage={handleChangePage1}
                                  onChangeRowsPerPage={handleChangeRowsPerPage1}
                                />
                              </TableContainer>
                            </div>
                          </TabPanel>
                        </div>

                        {/* Completed */}

                        <div className="Pannel_padding_hidden mt-1 mb-3">
                          <TabPanel value={value} index={2}>
                            <Grid className="grid_main_div mt-1">
                              <Grid item md={3}>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Events"
                                    autoComplete="off"
                                    value={search2}
                                    onChange={(e) => {
                                      setSearch2(e.target.value);
                                    }}
                                  />
                                </div>
                              </Grid>

                              <Grid item md={9}></Grid>
                            </Grid>
                            <div className="table_foramtitng mt-2">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Description
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Location
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Start Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        End Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Organizer
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publish Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage2 > 0
                                      ? filterCompletedEvent.slice(
                                          page2 * rowsPerPage2,
                                          page2 * rowsPerPage2 + rowsPerPage2
                                        )
                                      : filterCompletedEvent
                                    ).map((row, index) => {
                                      return (
                                        <StyledTableRow
                                          key={index}
                                          // style={{ width: "100%" }}
                                        >
                                          <StyledTableCell
                                            align="left"
                                            // style={{ width: "35%" }}
                                          >
                                            <Tooltip
                                              title={row.event_name}
                                              arrow
                                            >
                                              <span>
                                                {getSubStringValue(
                                                  row.event_name
                                                )}
                                              </span>
                                            </Tooltip>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            // style={{ width: "30%" }}

                                            scope="row"
                                            align="left"
                                          >
                                            <Tooltip
                                              title={row.description}
                                              arrow
                                            >
                                              <span>
                                                {getSubStringValue(
                                                  row.description
                                                )}
                                              </span>
                                            </Tooltip>
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            <OnlineLinkLocation
                                              data={row}
                                              userType={props.data.usertype}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.start_date) ? (
                                              "No Data"
                                            ) : (
                                              <div>
                                                <span>
                                                  {timeStampToDateandTime(
                                                    row.start_date
                                                  )}
                                                </span>{" "}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.end_date) ? (
                                              "No Data"
                                            ) : (
                                              <div>
                                                <span>
                                                  {timeStampToDateandTime(
                                                    row.end_date
                                                  )}
                                                </span>{" "}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {getFullName(row.organizer_name)}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            // style={{ width: "10%" }}
                                          >
                                            {timeStampToDate(row.publish_date)}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="center"
                                            // style={{ width: "15%" }}
                                          >
                                            <span
                                              role="button"
                                              className="text-info"
                                              onClick={() => {
                                                if (!blankValidator(row)) {
                                                  alert("Not Open");
                                                  return;
                                                }

                                                props.navigate(
                                                  "/eventDetails",
                                                  {
                                                    state: {
                                                      data: row,
                                                      isCompleted: true,
                                                      academic_session_id,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              Open
                                            </span>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={filterCompletedEvent.length}
                                  rowsPerPage={rowsPerPage2}
                                  page={page2}
                                  onChangePage={handleChangePage2}
                                  onChangeRowsPerPage={handleChangeRowsPerPage2}
                                />
                              </TableContainer>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDeleteDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to cancel event ?
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteEventHandler()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageEventForTeacher));
