import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import Hoc1 from "../../Hoc1";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import * as Service from "../../../services/index";
import { useMutation } from "@apollo/client";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { CountryDropdown } from "react-country-region-selector";
import Loder from "../../Loder/Loder";
import { useApolloClient } from "@apollo/client";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const CurrencySettings = (props) => {
  const { name, id } = props.location.state;
  console.log("props.location.state ", props.location);
  const [value, setValue] = React.useState("female");
  let organization_id = +id;
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencyError, setSelectedCurrencyError] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("");
  const [selectLanguageError, setSelectLanguageError] = useState(false);
  const [isloading, setisloading] = useState(false);
  const client = useApolloClient();
  const [countryValue, setCountryValue] = useState("");
  const [onlineClassValue, setonlineClassValue] = useState("");
  const [onlinePaymentValue, setonlinePaymentValue] = useState("");
  const [countryValueError, setCountryValueError] = useState(false);
  const [currcyArray, setCurrcyArray] = useState([
    {
      currency: "Albania Lek",
      abbreviation: "ALL",
      symbol: "&#76;&#101;&#107;",
    },
    {
      currency: "Afghanistan Afghani",
      abbreviation: "AFN",
      symbol: "&#1547;",
    },
    {
      currency: "Argentina Peso",
      abbreviation: "ARS",
      symbol: "&#36;",
    },
    {
      currency: "Aruba Guilder",
      abbreviation: "AWG",
      symbol: "&#402;",
    },
    {
      currency: "Australia Dollar",
      abbreviation: "AUD",
      symbol: "&#36;",
    },
    {
      currency: "Azerbaijan New Manat",
      abbreviation: "AZN",
      symbol: "&#1084;&#1072;&#1085;",
    },
    {
      currency: "Bahamas Dollar",
      abbreviation: "BSD",
      symbol: "&#36;",
    },
    {
      currency: "Barbados Dollar",
      abbreviation: "BBD",
      symbol: "&#36;",
    },
    {
      currency: "Belarus Ruble",
      abbreviation: "BYR",
      symbol: "&#112;&#46;",
    },
    {
      currency: "Belize Dollar",
      abbreviation: "BZD",
      symbol: "&#66;&#90;&#36;",
    },
    {
      currency: "Bermuda Dollar",
      abbreviation: "BMD",
      symbol: "&#36;",
    },
    {
      currency: "Bolivia Boliviano",
      abbreviation: "BOB",
      symbol: "&#36;&#98;",
    },
    {
      currency: "Bosnia and Herzegovina Convertible Marka",
      abbreviation: "BAM",
      symbol: "&#75;&#77;",
    },
    {
      currency: "Botswana Pula",
      abbreviation: "BWP",
      symbol: "&#80;",
    },
    {
      currency: "Bulgaria Lev",
      abbreviation: "BGN",
      symbol: "&#1083;&#1074;",
    },
    {
      currency: "Brazil Real",
      abbreviation: "BRL",
      symbol: "&#82;&#36;",
    },
    {
      currency: "Brunei Darussalam Dollar",
      abbreviation: "BND",
      symbol: "&#36;",
    },
    {
      currency: "Cambodia Riel",
      abbreviation: "KHR",
      symbol: "&#6107;",
    },
    {
      currency: "Canada Dollar",
      abbreviation: "CAD",
      symbol: "&#36;",
    },
    {
      currency: "Cayman Islands Dollar",
      abbreviation: "KYD",
      symbol: "&#36;",
    },
    {
      currency: "Chile Peso",
      abbreviation: "CLP",
      symbol: "&#36;",
    },
    {
      currency: "China Yuan Renminbi",
      abbreviation: "CNY",
      symbol: "&#165;",
    },
    {
      currency: "Colombia Peso",
      abbreviation: "COP",
      symbol: "&#36;",
    },
    {
      currency: "Costa Rica Colon",
      abbreviation: "CRC",
      symbol: "&#8353;",
    },
    {
      currency: "Croatia Kuna",
      abbreviation: "HRK",
      symbol: "&#107;&#110;",
    },
    {
      currency: "Cuba Peso",
      abbreviation: "CUP",
      symbol: "&#8369;",
    },
    {
      currency: "Czech Republic Koruna",
      abbreviation: "CZK",
      symbol: "&#75;&#269;",
    },
    {
      currency: "Denmark Krone",
      abbreviation: "DKK",
      symbol: "&#107;&#114;",
    },
    {
      currency: "Dominican Republic Peso",
      abbreviation: "DOP",
      symbol: "&#82;&#68;&#36;",
    },
    {
      currency: "East Caribbean Dollar",
      abbreviation: "XCD",
      symbol: "&#36;",
    },
    {
      currency: "Egypt Pound",
      abbreviation: "EGP",
      symbol: "&#163;",
    },
    {
      currency: "El Salvador Colon",
      abbreviation: "SVC",
      symbol: "&#36;",
    },
    {
      currency: "Estonia Kroon",
      abbreviation: "EEK",
      symbol: "&#107;&#114;",
    },
    {
      currency: "Euro Member Countries",
      abbreviation: "EUR",
      symbol: "&#8364;",
    },
    {
      currency: "Falkland Islands (Malvinas) Pound",
      abbreviation: "FKP",
      symbol: "&#163;",
    },
    {
      currency: "Fiji Dollar",
      abbreviation: "FJD",
      symbol: "&#36;",
    },
    {
      currency: "Ghana Cedis",
      abbreviation: "GHC",
      symbol: "&#162;",
    },
    {
      currency: "Gibraltar Pound",
      abbreviation: "GIP",
      symbol: "&#163;",
    },
    {
      currency: "Guatemala Quetzal",
      abbreviation: "GTQ",
      symbol: "&#81;",
    },
    {
      currency: "Guernsey Pound",
      abbreviation: "GGP",
      symbol: "&#163;",
    },
    {
      currency: "Guyana Dollar",
      abbreviation: "GYD",
      symbol: "&#36;",
    },
    {
      currency: "Honduras Lempira",
      abbreviation: "HNL",
      symbol: "&#76;",
    },
    {
      currency: "Hong Kong Dollar",
      abbreviation: "HKD",
      symbol: "&#36;",
    },
    {
      currency: "Hungary Forint",
      abbreviation: "HUF",
      symbol: "&#70;&#116;",
    },
    {
      currency: "Iceland Krona",
      abbreviation: "ISK",
      symbol: "&#107;&#114;",
    },
    {
      currency: "India Rupee",
      abbreviation: "INR",
      symbol: null,
    },
    {
      currency: "Indonesia Rupiah",
      abbreviation: "IDR",
      symbol: "&#82;&#112;",
    },
    {
      currency: "Iran Rial",
      abbreviation: "IRR",
      symbol: "&#65020;",
    },
    {
      currency: "Isle of Man Pound",
      abbreviation: "IMP",
      symbol: "&#163;",
    },
    {
      currency: "Israel Shekel",
      abbreviation: "ILS",
      symbol: "&#8362;",
    },
    {
      currency: "Jamaica Dollar",
      abbreviation: "JMD",
      symbol: "&#74;&#36;",
    },
    {
      currency: "Japan Yen",
      abbreviation: "JPY",
      symbol: "&#165;",
    },
    {
      currency: "Jersey Pound",
      abbreviation: "JEP",
      symbol: "&#163;",
    },
    {
      currency: "Kazakhstan Tenge",
      abbreviation: "KZT",
      symbol: "&#1083;&#1074;",
    },
    {
      currency: "Korea (North) Won",
      abbreviation: "KPW",
      symbol: "&#8361;",
    },
    {
      currency: "Korea (South) Won",
      abbreviation: "KRW",
      symbol: "&#8361;",
    },
    {
      currency: "Kyrgyzstan Som",
      abbreviation: "KGS",
      symbol: "&#1083;&#1074;",
    },
    {
      currency: "Laos Kip",
      abbreviation: "LAK",
      symbol: "&#8365;",
    },
    {
      currency: "Latvia Lat",
      abbreviation: "LVL",
      symbol: "&#76;&#115;",
    },
    {
      currency: "Lebanon Pound",
      abbreviation: "LBP",
      symbol: "&#163;",
    },
    {
      currency: "Liberia Dollar",
      abbreviation: "LRD",
      symbol: "&#36;",
    },
    {
      currency: "Lithuania Litas",
      abbreviation: "LTL",
      symbol: "&#76;&#116;",
    },
    {
      currency: "Macedonia Denar",
      abbreviation: "MKD",
      symbol: "&#1076;&#1077;&#1085;",
    },
    {
      currency: "Malaysia Ringgit",
      abbreviation: "MYR",
      symbol: "&#82;&#77;",
    },
    {
      currency: "Mauritius Rupee",
      abbreviation: "MUR",
      symbol: "&#8360;",
    },
    {
      currency: "Mexico Peso",
      abbreviation: "MXN",
      symbol: "&#36;",
    },
    {
      currency: "Mongolia Tughrik",
      abbreviation: "MNT",
      symbol: "&#8366;",
    },
    {
      currency: "Mozambique Metical",
      abbreviation: "MZN",
      symbol: "&#77;&#84;",
    },
    {
      currency: "Namibia Dollar",
      abbreviation: "NAD",
      symbol: "&#36;",
    },
    {
      currency: "Nepal Rupee",
      abbreviation: "NPR",
      symbol: "&#8360;",
    },
    {
      currency: "Netherlands Antilles Guilder",
      abbreviation: "ANG",
      symbol: "&#402;",
    },
    {
      currency: "New Zealand Dollar",
      abbreviation: "NZD",
      symbol: "&#36;",
    },
    {
      currency: "Nicaragua Cordoba",
      abbreviation: "NIO",
      symbol: "&#67;&#36;",
    },
    {
      currency: "Nigeria Naira",
      abbreviation: "NGN",
      symbol: "&#8358;",
    },
    {
      currency: "Korea (North) Won",
      abbreviation: "KPW",
      symbol: "&#8361;",
    },
    {
      currency: "Norway Krone",
      abbreviation: "NOK",
      symbol: "&#107;&#114;",
    },
    {
      currency: "Oman Rial",
      abbreviation: "OMR",
      symbol: "&#65020;",
    },
    {
      currency: "Pakistan Rupee",
      abbreviation: "PKR",
      symbol: "&#8360;",
    },
    {
      currency: "Panama Balboa",
      abbreviation: "PAB",
      symbol: "&#66;&#47;&#46;",
    },
    {
      currency: "Paraguay Guarani",
      abbreviation: "PYG",
      symbol: "&#71;&#115;",
    },
    {
      currency: "Peru Nuevo Sol",
      abbreviation: "PEN",
      symbol: "&#83;&#47;&#46;",
    },
    {
      currency: "Philippines Peso",
      abbreviation: "PHP",
      symbol: "&#8369;",
    },
    {
      currency: "Poland Zloty",
      abbreviation: "PLN",
      symbol: "&#122;&#322;",
    },
    {
      currency: "Qatar Riyal",
      abbreviation: "QAR",
      symbol: "&#65020;",
    },
    {
      currency: "Romania New Leu",
      abbreviation: "RON",
      symbol: "&#108;&#101;&#105;",
    },
    {
      currency: "Russia Ruble",
      abbreviation: "RUB",
      symbol: "&#1088;&#1091;&#1073;",
    },
    {
      currency: "Saint Helena Pound",
      abbreviation: "SHP",
      symbol: "&#163;",
    },
    {
      currency: "Saudi Arabia Riyal",
      abbreviation: "SAR",
      symbol: "&#65020;",
    },
    {
      currency: "Serbia Dinar",
      abbreviation: "RSD",
      symbol: "&#1044;&#1080;&#1085;&#46;",
    },
    {
      currency: "Seychelles Rupee",
      abbreviation: "SCR",
      symbol: "&#8360;",
    },
    {
      currency: "Singapore Dollar",
      abbreviation: "SGD",
      symbol: "&#36;",
    },
    {
      currency: "Solomon Islands Dollar",
      abbreviation: "SBD",
      symbol: "&#36;",
    },
    {
      currency: "Somalia Shilling",
      abbreviation: "SOS",
      symbol: "&#83;",
    },
    {
      currency: "South Africa Rand",
      abbreviation: "ZAR",
      symbol: "&#82;",
    },
    {
      currency: "Korea (South) Won",
      abbreviation: "KRW",
      symbol: "&#8361;",
    },
    {
      currency: "Sri Lanka Rupee",
      abbreviation: "LKR",
      symbol: "&#8360;",
    },
    {
      currency: "Sweden Krona",
      abbreviation: "SEK",
      symbol: "&#107;&#114;",
    },
    {
      currency: "Switzerland Franc",
      abbreviation: "CHF",
      symbol: "&#67;&#72;&#70;",
    },
    {
      currency: "Suriname Dollar",
      abbreviation: "SRD",
      symbol: "&#36;",
    },
    {
      currency: "Syria Pound",
      abbreviation: "SYP",
      symbol: "&#163;",
    },
    {
      currency: "Taiwan New Dollar",
      abbreviation: "TWD",
      symbol: "&#78;&#84;&#36;",
    },
    {
      currency: "Thailand Baht",
      abbreviation: "THB",
      symbol: "&#3647;",
    },
    {
      currency: "Trinidad and Tobago Dollar",
      abbreviation: "TTD",
      symbol: "&#84;&#84;&#36;",
    },
    {
      currency: "Turkey Lira",
      abbreviation: "TRY",
      symbol: null,
    },
    {
      currency: "Turkey Lira",
      abbreviation: "TRL",
      symbol: "&#8356;",
    },
    {
      currency: "Tuvalu Dollar",
      abbreviation: "TVD",
      symbol: "&#36;",
    },
    {
      currency: "Ukraine Hryvna",
      abbreviation: "UAH",
      symbol: "&#8372;",
    },
    {
      currency: "United Kingdom Pound",
      abbreviation: "GBP",
      symbol: "&#163;",
    },
    {
      currency: "United States Dollar",
      abbreviation: "USD",
      symbol: "&#36;",
    },
    {
      currency: "Uruguay Peso",
      abbreviation: "UYU",
      symbol: "&#36;&#85;",
    },
    {
      currency: "Uzbekistan Som",
      abbreviation: "UZS",
      symbol: "&#1083;&#1074;",
    },
    {
      currency: "Venezuela Bolivar",
      abbreviation: "VEF",
      symbol: "&#66;&#115;",
    },
    {
      currency: "Viet Nam Dong",
      abbreviation: "VND",
      symbol: "&#8363;",
    },
    {
      currency: "Yemen Rial",
      abbreviation: "YER",
      symbol: "&#65020;",
    },
    {
      currency: "Zimbabwe Dollar",
      abbreviation: "ZWD",
      symbol: "&#90;&#36;",
    },
  ]);
  const [prcingvalue, setPrcingvalue] = useState("");
  const [prcingvalueError, setPrcingvalueError] = useState(false);
  const [amountvalue, setAmountvalue] = useState("");
  const [amountvalueError, setAmountvalueError] = useState(false);

  const [instituteValue, setInstituteValue] = useState("");
  const [instituteValueError, setInstituteValueError] = useState(false);
  const [instituteListArray, setInstituteListArray] = useState([]);

  const [add_Properties, {}] = useMutation(Service.add_Properties);
  useEffect(() => {
    getLeaveCategoryConfiguration();
    getOrgationzationData();
  }, []);

  const getOrgationzationData = async () => {
    try {
      const { data } = await client.query({
        query: Service.get_Private_Institute,
      });

      if (data) {
        setInstituteListArray(data.getPrivateInstitute);
      }
    } catch (error) {
      console.log("private teaching error", error);
    }
  };

  const getLeaveCategoryConfiguration = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Properties,
          variables: {
            organization_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setSelectedCurrency(response.data.getProperties?.currency_code);
          setSelectLanguage(response.data.getProperties?.language);
          setCountryValue(response.data.getProperties?.locale);
          setonlineClassValue(response.data.getProperties?.online_class);
          setonlinePaymentValue(response.data.getProperties?.online_payment);
          setPrcingvalue(response.data.getProperties?.price_type);
          setAmountvalue(response.data.getProperties?.amount);
        })
        .catch((err) => {
          setisloading(false);

          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const currenySubmit = () => {
    if (!blankValidator(selectedCurrency)) {
      setSelectedCurrencyError(true);
      return;
    }

    try {
      setisloading(true);
      add_Properties({
        variables: {
          organization_id: +organization_id,
          currency_code: selectedCurrency,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addProperties.message, "success");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const gobackprevoius = () => {
    props.navigate(-1);
  };

  const countryHandle = () => {
    console.log("countryValue", countryValue);
    if (!blankValidator(countryValue)) {
      setCountryValueError(true);
    }
    try {
      setisloading(true);
      add_Properties({
        variables: {
          organization_id: +organization_id,
          locale: countryValue,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addProperties.message, "success");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const handleFeature = () => {
    try {
      setisloading(true);
      add_Properties({
        variables: {
          organization_id: +organization_id,
          online_class: onlineClassValue,
          online_payment: onlinePaymentValue,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addProperties.message, "success");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const pricingSumbitHandle = () => {
    try {
      if (!prcingvalue) {
        setPrcingvalueError(true);
        return;
      }
      if (!amountvalue) {
        setAmountvalueError(true);
        return;
      }
      setisloading(true);
      add_Properties({
        variables: {
          organization_id: +organization_id,
          price_type: prcingvalue,
          amount: amountvalue,
          currency_code: selectedCurrency,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addProperties.message, "success");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {}
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            {/* <div className="font_color_holiday">Holiday(Independence day)</div> */}
            <div className="font_color_holiday">Holiday(Independence day)</div>
            <div className="card_Show_Insitute">
              <span className="margin_institution_name">
                <Card className="institution_Name_font mb-2">{name}</Card>
              </span>
              <span
                className="gobackbutton manage_edit_cursor"
                onClick={gobackprevoius}
              >
                <i className="fa fa-chevron-left"></i> Back
              </span>
            </div>
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-2 Heading_bold">Currency</div>
              </div>
              <div className="card_margin_both_side">
                <Card className="pt-3 pb-4">
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <div className="text_filed_heading">Currency</div>
                          <div className="inputMarginright mt-1" id="mobile">
                            <select
                              class="form-control"
                              id="gender"
                              value={selectedCurrency}
                              onChange={(e) => {
                                setSelectedCurrencyError(false);
                                setSelectedCurrency(e.target.value);
                              }}
                            >
                              <option value={-1}>Select</option>
                              {currcyArray.map((row) => (
                                <option value={row.abbreviation}>
                                  {row.currency}
                                </option>
                              ))}
                            </select>
                            {selectedCurrencyError && (
                              <span className="text-danger">
                                Select Currency
                              </span>
                            )}
                          </div>
                        </Grid>

                        <Grid item md={4}>
                          <div className="pl-3">
                            <div className="text_filed_heading">
                              Currency Symbol{" "}
                            </div>
                            <p className="inputMarginright mt-1">
                              {selectedCurrency}
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder="currency Symbol"
                                autoComplete="off"
                                value={selectedCurrency.toString()}
                              
                              /> */}
                              {/* {clientidError && (
                                <span className="text-danger">
                                  Enter Full Day Hours
                                </span>
                              )} */}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={4}></Grid>
                      </Grid>
                      <Grid className="grid_main_div mt-2 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick={currenySubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-2 Heading_bold">Country</div>
              </div>
              <div className="card_margin_both_side">
                <Card className="pt-3 pb-4">
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <div className="text_filed_heading">Country</div>
                          <CountryDropdown
                            value={countryValue}
                            id="C_country"
                            valueType="short"
                            onChange={(e) => {
                              setCountryValueError(false);
                              setCountryValue(e);
                            }}
                            className="country_drop_height_width"
                          />

                          {countryValueError && (
                            <span className="text-danger">Select Country</span>
                          )}
                        </Grid>
                      </Grid>
                      <Grid className="grid_main_div mt-2 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick={() => {
                              countryHandle();
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-2 Heading_bold">Feature</div>
              </div>
              <div className="card_margin_both_side">
                <Card className="pt-3 pb-4">
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              Online classes
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={onlineClassValue}
                              onChange={(e) =>
                                setonlineClassValue(e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="Enable"
                                control={<Radio />}
                                label="Enable"
                              />
                              <FormControlLabel
                                value="Disable"
                                control={<Radio />}
                                label="Disable"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item md={4}>
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              Online payment
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={onlinePaymentValue}
                              onChange={(e) =>
                                setonlinePaymentValue(e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="Enable"
                                control={<Radio />}
                                label="Enable"
                              />
                              <FormControlLabel
                                value="Disable"
                                control={<Radio />}
                                label="Disable"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid className="grid_main_div mt-2 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick={() => {
                              handleFeature();
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            {/* --------pricing section------- */}
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-2 Heading_bold">Pricing </div>
              </div>
              <div className="card_margin_both_side">
                <Card className="pt-3 pb-4">
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <div className="text_filed_heading">Price</div>
                          <div className="inputMarginright mt-1">
                            <select
                              className="form-control "
                              id="exampleFormControlSelect1"
                              value={prcingvalue}
                              onChange={(e) => {
                                setPrcingvalue(e.target.value);
                              }}
                            >
                              <option>Select Price </option>
                              <option>Student_based </option>
                              <option>Student_negotiated</option>
                              <option>Fixed</option>
                            </select>
                            {prcingvalueError && (
                              <span className="text-danger">
                                Enter Select Price
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">Amount </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Amount"
                              autoComplete="off"
                              value={amountvalue}
                              onChange={(e) => {
                                setAmountvalue(e.target.value);
                              }}
                            />
                            {amountvalueError && (
                              <span className="text-danger">Enter Amount</span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Currency code
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Currency code"
                              autoComplete="off"
                              value={selectedCurrency}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="textfiled_margin mt-2">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Institution list
                          </div>
                          <div className="inputMarginright mt-1">
                            <select
                              className="form-control "
                              id="exampleFormControlSelect1"
                              value={instituteValue}
                              onChange={(e) => {
                                setInstituteValue(e.target.value);
                              }}
                            >
                              <option value={-1}>Select Institution </option>
                              {instituteListArray.map((row, index) => (
                                <option key={index} value={row.id}>
                                  {row.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="textfiled_margin ">
                      <Grid className="grid_main_div mt-2 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick={() => {
                              pricingSumbitHandle();
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(CurrencySettings));
