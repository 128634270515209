import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import "./Onboarding.css";
import Loder from "../../Loder/Loder.jsx";

import Expand from "react-expand-animated";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { baseUrl } from "../../../services/index.js";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  emailValidator,
  showNotificationMsz,
  dateToDatedashtoDDMMYY,
  serverDateFormat,
  dateFormatToShow,
} from "../../../utils/Validation.js";
import { AttainedArr } from "../../../utils/LocalData";
import { useMutation } from "@apollo/client";
import APIHooks from "../../../api manager/CommonAPI";
import Holiday from "../../Common/Holiday/Holiday.jsx";

function AdminEmployeeDetailForm({ ADMIN_DATA, location, empId, navigate }) {
  //----------------------------global id----------------------------------
  const { institution_id, institution_name } = ADMIN_DATA;
  const empDetailHook = APIHooks();
  let staffId = location?.state?.staffId;
  //----------------------------api calls----------------------------------
  const classes = useStyles();
  const client = useApolloClient();
  //----------------------------api calls----------------------------------
  const [othersDocumentsArr, setOthersDocumentsArr] = useState([]);
  const [nationalityError, setnationalityError] = useState(false);
  const [departmentIdError, setdepartmentIdError] = useState(false);
  const [designationIdError, setdesignationIdError] = useState(false);

  const [firstNameError, setfirstNameError] = useState(false);
  const [birthdateError, setbirthdateError] = useState(false);
  const [genderError, setgenderError] = useState(false);
  const [contactnoError, setcontactnoError] = useState(false);

  const [emailCorrectError, setemailCorrectError] = useState(false);
  const [motherFirstNameError, setmotherFirstNameError] = useState(false);
  const [fatherfirstnameError, setfatherfirstnameError] = useState(false);
  const [addressline1Error, setaddressline1Error] = useState(false);
  const [countryError, setcountryError] = useState(false);
  const [stateError, setstateError] = useState(false);
  const [zipcodeError, setzipcodeError] = useState(false);
  const [qualificationError, setqualificationError] = useState(false);
  const [courseNameError, setcourseNameError] = useState(false);
  const [collegeNameError, setcollegeNameError] = useState(false);
  const [starttimeError, setstarttimeError] = useState(false);
  const [endtimeError, setendtimeError] = useState(false);

  const [Update_Employee_Data, {}] = useMutation(Service.Update_Employee_Data);
  const [Employeeformdata, setEmployeeformdata] = useState({
    status: "",
    photoPath: "",
    designation_id: "",
    department_id: "",
    email: "",
    Teacher_first_name: "",
    Teacher_middle_name: "",
    Teacher_last_name: "",
    phone: "",
    institution_id: "",
    joining_date: "",
    gender: "",
    birth_date: "",
    Current_line1: "",
    Current_line2: "",
    Current_city: "",
    Current_state: "",
    Current_zip_code: "",
    Current_country: "",
    Permanent_line1: "",
    Permanent_line2: "",
    Permanent_city: "",
    Permanent_state: "",
    Permanent_zip_code: "",
    Permanent_country: "",
    blood_group: "",
    nationality: "",
    taxvalue: "",
    marital_status: "",
    passport_number: "",
    national_id_number: "",
    religion: "",
    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Mother_first_name: "",
    Mother_middle_name: "",
    Mother_last_name: "",
    Spouse_first_name: "",
    Spouse_middle_name: "",
    Spouse_last_name: "",
    education_qualifications: [],
    passport_path: "",
    certificate_path: "",
    birth_cert_path: "",
    tax_path: "",
    showother: [],
    passport_expiry_date: "",
    display_id: "",
    login_id: "",
    passport: {},
    certificate: {},
    photo: {},
    birth_cert: {},
    tax: {},
    other: [],
    others: [],
  });
  const [qualification, setqualification] = useState("");
  const [course, setcourse] = useState("");
  const [college, setcollege] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [isloading, setisloading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [employeedeail, setemployeedeail] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [EditDailogEducationOpen, setEditDailogEducationOpen] = useState(false);
  const [EditQualificationId, setEditQualificationId] = useState("");
  const [EditQualificationName, setEditQualificationName] = useState("");
  const [EditCourse, setEditCourse] = useState("");
  const [EditCollege, setEditCollege] = useState("");
  const [EditStartDate, setEditStartDate] = useState("");
  const [EditEndDate, setEditEndDate] = useState("");
  const [EditID, setEditID] = useState("");
  const [qualificationAttainedName, setqualificationAttainedName] =
    useState("");
  //Errors
  const [EditqualificationIdError, setEditqualificationIdError] =
    useState(false);
  const [EditcourseNameError, setEditcourseNameError] = useState(false);
  const [EditcollegeNameError, setEditcollegeNameError] = useState(false);
  const [EditstarttimeError, setEditstarttimeError] = useState(false);
  const [EditendtimeError, setEditendtimeError] = useState(false);
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  //-------------------------methods-----------------------
  useEffect(() => {
    onloadData();
  }, []);

  const onloadData = async () => {
    window.scrollTo(0, 0);
    await empDetailHook.getDepartmentAPI(parseInt(institution_id));
    await empDetailHook.getDesignationAPI(parseInt(institution_id));
  };

  useEffect(() => {
    getStaffData();
  }, []);

  const getStaffData = async () => {
    setisloading(true);
    let id;
    if (staffId) {
      id = staffId;
    } else if (empId) {
      id = empId;
    }
    if (id) {
      try {
        const { data } = await client.query({
          query: Service.Get_Id_based_Employee_details,
          variables: {
            id: parseInt(id),
          },
        });
        let mystatus = "";
        let displayId = "";
        let loginId = "";
        let tphotoPath = "";
        let designationId = "";
        let departmentId = "";
        let empEmail = "";
        let tFirstName = "";
        let tMiddleName = "";
        let tLastName = "";
        let tphone = "";
        let tJoiningDate = "";
        let tgender = "";
        let tbirthDate = "";
        let line1 = "";
        let line2 = "";
        let currentCity = "";
        let currentState = "";
        let currentCountry = "";
        let currentPinCode = "";

        let pline1 = "";
        let pline2 = "";
        let pcurrentCity = "";
        let pcurrentState = "";
        let pcurrentCountry = "";
        let pcurrentPinCode = "";
        let tbloodGroup = "";
        let tnationality = "";
        let taxValue = "";
        let tmaritalStatus = "";
        let tnationalIdNumber = "";
        let treligion = "";
        let ffirstName = "";
        let fmiddleName = "";
        let flastName = "";
        let mfirstName = "";
        let mmiddleName = "";
        let mlastName = "";
        let sfirstName = "";
        let smiddleName = "";
        let slastName = "";
        let passportExpDate = "";
        let passportNumber = "";
        const educationArr = [];
        let passportObj = {
          path: "",
          filename: "",
          type: "",
          name: "",
        };
        let certificateObj = {
          path: "",
          filename: "",
          type: "",
          name: "",
        };
        let photoObj = {
          path: "",
          filename: "",
          type: "",
          name: "",
        };
        let birthCertificateObj = {
          path: "",
          filename: "",
          type: "",
          name: "",
        };
        let taxObj = {
          path: "",
          filename: "",
          type: "",
          name: "",
        };
        let taxIdentityObj = {
          encrypted_id: "",
        };
        let othersArr = [];
        let passportPath = "";
        let certificateImagePath = "";
        let birthCertificateImagePath = "";
        let taxImagePath = "";
        const {
          status,
          login,
          tax_identity,
          photo,
          designation,
          email,
          name,
          department,
          phone,
          joining_date,
          gender,
          birth_date,
          currentAddress,
          permanentAddress,
          blood_group,
          nationality,
          tax,
          marital_status,
          national_identity,
          religion,
          fatherName,
          motherName,
          spouseName,
          passport_identity,
          education_qualifications,
          passport,
          certificate,
          birth_cert,
          others,
        } = data.getEmployeeById;
        if (status) mystatus = status;

        if (tax_identity) {
          if (tax_identity.encrypted_id)
            taxIdentityObj.encrypted_id = tax_identity.encrypted_id;
        }
        if (login) {
          loginId = login.login_id;
          displayId = login.display_id;
        }
        if (photo) {
          if (photo.path) {
            tphotoPath = photo.path;
          }
        }
        if (designation) designationId = designation.id;
        if (department) departmentId = department.id;
        if (department) departmentId = department.id;
        if (email) empEmail = email;
        if (name) {
          if (name.first_name) tFirstName = name.first_name;
          if (name.middle_name) tMiddleName = name.middle_name;
          if (name.last_name) tLastName = name.last_name;
        }
        if (phone) tphone = phone;
        if (joining_date) tJoiningDate = joining_date;
        if (gender) tgender = gender;
        if (birth_date) tbirthDate = birth_date;
        if (currentAddress) {
          if (currentAddress.line1) line1 = currentAddress.line1;
          if (currentAddress.line2) line2 = currentAddress.line2;
          if (currentAddress.city) currentCity = currentAddress.city;
          if (currentAddress.state) currentState = currentAddress.state;
          if (currentAddress.country) currentCountry = currentAddress.country;
          if (currentAddress.zip_code) currentPinCode = currentAddress.zip_code;
        }
        if (permanentAddress) {
          if (permanentAddress.line1) pline1 = permanentAddress.line1;
          if (permanentAddress.line2) pline2 = permanentAddress.line2;
          if (permanentAddress.city) pcurrentCity = permanentAddress.city;
          if (permanentAddress.state) pcurrentState = permanentAddress.state;
          if (permanentAddress.country)
            pcurrentCountry = permanentAddress.country;
          if (permanentAddress.zip_code)
            pcurrentPinCode = permanentAddress.zip_code;
        }
        if (blood_group) tbloodGroup = blood_group;
        if (nationality) tnationality = nationality;
        // if (tax) {
        //   if (tax.value) {
        //     taxValue = tax.value;
        //   }
        // }
        if (marital_status) tmaritalStatus = marital_status;
        if (national_identity) {
          if (national_identity.encrypted_id) {
            tnationalIdNumber = national_identity.encrypted_id;
          }
        }
        if (religion) treligion = religion;
        if (fatherName) {
          if (fatherName.first_name) ffirstName = fatherName.first_name;
          if (fatherName.middle_name) fmiddleName = fatherName.middle_name;
          if (fatherName.last_name) flastName = fatherName.last_name;
        }
        if (motherName) {
          if (motherName.first_name) mfirstName = motherName.first_name;
          if (motherName.middle_name) mmiddleName = motherName.middle_name;
          if (motherName.last_name) mlastName = motherName.last_name;
        }
        if (spouseName) {
          if (spouseName.first_name) sfirstName = spouseName.first_name;
          if (spouseName.middle_name) smiddleName = spouseName.middle_name;
          if (spouseName.last_name) slastName = spouseName.last_name;
        }
        if (passport_identity) {
          if (passport_identity.expiry_date)
            passportExpDate = passport_identity.expiry_date;
          if (passport_identity.encrypted_id)
            passportNumber = passport_identity.encrypted_id;
        }
        if (education_qualifications && education_qualifications.length > 0) {
          education_qualifications.map((data) => {
            educationArr.push({
              qualification: data.qualification && data.qualification,
              course_name: data.course_name && data.course_name,
              university_name: data.university_name && data.university_name,
              start_date: serverDateFormat(data.start_date),
              end_date: serverDateFormat(data.end_date),
            });
          });
        }
        if (passport) {
          if (passport.path) passportObj.path = passport.path;
          if (passport.filename) passportObj.filename = passport.filename;
          if (passport.type) passportObj.type = passport.type;
          if (passport.name) passportObj.name = passport.name;
        }
        if (certificate) {
          if (certificate.path) certificateObj.path = certificate.path;
          if (certificate.filename)
            certificateObj.filename = certificate.filename;
          if (certificate.type) certificateObj.type = certificate.type;
          if (certificate.name) certificateObj.name = certificate.name;
        }
        if (photo) {
          if (photo.path) photoObj.path = photo.path;
          if (photo.filename) photoObj.filename = photo.filename;
          if (photo.type) photoObj.type = photo.type;
          if (photo.name) photoObj.name = photo.name;
        }
        if (birth_cert) {
          if (birth_cert.path) birthCertificateObj.path = birth_cert.path;
          if (birth_cert.filename)
            birthCertificateObj.filename = birth_cert.filename;
          if (birth_cert.type) birthCertificateObj.type = birth_cert.type;
          if (birth_cert.name) birthCertificateObj.name = birth_cert.name;
        }
        if (tax) {
          if (tax.path) taxObj.path = tax.path;
          if (tax.filename) taxObj.filename = tax.filename;
          if (tax.type) taxObj.type = tax.type;
          if (tax.name) taxObj.name = tax.name;
        }
        if (others && others.length > 0) {
          others.map((othersdata) =>
            othersArr.push({
              path: othersdata.path,
              filename: othersdata.filename,
              type: othersdata.type,
              name: othersdata.name,
            })
          );
        }

        setEmployeeformdata({
          ...Employeeformdata,
          status: mystatus,
          display_id: displayId,
          login_id: loginId,
          photoPath: tphotoPath,
          designation_id: designationId,
          department_id: departmentId,
          email: empEmail,
          Teacher_first_name: tFirstName,
          Teacher_middle_name: tMiddleName,
          Teacher_last_name: tLastName,
          phone: tphone,
          joining_date: tJoiningDate,
          gender: tgender,
          birth_date: tbirthDate,
          Current_line1: line1,
          Current_line2: line2,
          Current_city: currentCity,
          Current_state: currentState,
          Current_zip_code: currentPinCode,
          Current_country: currentCountry,

          Permanent_line1: pline1,
          Permanent_line2: pline2,
          Permanent_city: pcurrentCity,
          Permanent_state: pcurrentState,
          Permanent_zip_code: pcurrentPinCode,
          Permanent_country: pcurrentCountry,
          blood_group: tbloodGroup,
          nationality: tnationality,

          taxvalue: taxIdentityObj.encrypted_id,
          marital_status: tmaritalStatus,
          national_id_number: tnationalIdNumber,
          religion: treligion,
          Father_first_name: ffirstName,
          Father_middle_name: fmiddleName,
          Father_last_name: flastName,
          Mother_first_name: mfirstName,
          Mother_middle_name: mmiddleName,
          Mother_last_name: mlastName,
          Spouse_first_name: sfirstName,
          Spouse_middle_name: smiddleName,
          Spouse_last_name: slastName,

          passport_expiry_date: passportExpDate,
          passport_number: passportNumber,
          education_qualifications: educationArr,
          passport: passportObj,
          certificate: certificateObj,
          photo: photoObj,
          birth_cert: birthCertificateObj,
          tax: taxObj,
          others: othersArr,
          photo_path: tphotoPath,
          passport_path: passportObj.path,
          certificate_path: certificateObj.path,
          birth_cert_path: birthCertificateObj.path,
          tax_path: taxObj.path,
          showother: othersArr,
        });
      } catch (error) {
        setisloading(false);
        console.log("error", error);
        showNotificationMsz(error, "danger");
      }
    }
    setisloading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const qualificationAttainedDetail = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setqualification("");
      return;
    }

    setqualification(id);
    const newArr = AttainedArr.filter((item) => {
      if (item.value === id) return item;
    });
    setqualificationAttainedName(newArr[0].label);
  };

  const handleEducationUpdate = (data, index) => {
    setEditQualificationName(data.qualificationAttainedName);
    setEditID(parseInt(index));
    setEditQualificationId(parseInt(data.qualification_id));
    setEditCourse(data.course_name);
    setEditCollege(data.university_name);
    setEditStartDate(data.start_date);
    setEditEndDate(data.end_date);
    setEditDailogEducationOpen(!EditDailogEducationOpen);
  };

  const EditqualificationAttainedDetail = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setEditQualificationId("");
      return;
    }
    setEditQualificationId(id);
    const newArr = AttainedArr.filter((item) => {
      if (item.value === id) return item;
    });
    setEditQualificationName(newArr[0].label);
  };

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const HandleImageDailogBoxClose = () => {
    setimagepath("");
    setimageType("");
    setOpenImageDailog(false);
  };

  const HandleEditEducationData = (val) => {
    let index = parseInt(val);
    Employeeformdata.education_qualifications[index].qualification =
      EditQualificationName;
    Employeeformdata.education_qualifications[index].course_name = EditCourse;
    Employeeformdata.education_qualifications[index].university_name =
      EditCollege;
    Employeeformdata.education_qualifications[index].start_date = EditStartDate;
    Employeeformdata.education_qualifications[index].end_date = EditEndDate;

    let temp = [];
    Employeeformdata.education_qualifications.map((data) => {
      temp.push(data);
    });
    setEmployeeformdata({
      ...Employeeformdata,
      education_qualifications: temp,
    });
    setEditDailogEducationOpen(!EditDailogEducationOpen);
  };

  const addEducationData = () => {
    let flag = false;
    let scroll = false;
    if (!blankValidator(qualificationAttainedName)) {
      setqualificationError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("qualificationn").focus();
      }
      scroll = true;
    }
    if (!blankValidator(course)) {
      setcourseNameError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("coursee").focus();
      }
      scroll = true;
    }

    if (!blankValidator(college)) {
      setcollegeNameError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("collegee").focus();
      }
      scroll = true;
    }
    if (!blankValidator(startdate)) {
      setstarttimeError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("startdatee").focus();
      }
      scroll = true;
    }
    if (!blankValidator(enddate)) {
      setendtimeError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("enddatee").focus();
      }
      scroll = true;
    }

    if (flag) {
      return;
    }
    Employeeformdata.education_qualifications.push({
      qualification: qualificationAttainedName,
      course_name: course,
      university_name: college,
      start_date: serverDateFormat(startdate),
      end_date: serverDateFormat(enddate),
    });
    let temp = [];
    Employeeformdata.education_qualifications.map((data) => {
      temp.push(data);
    });
    setEmployeeformdata({
      ...Employeeformdata,
      education_qualifications: temp,
    });

    setqualification("");
    setcourse("");
    setcollege("");
    setstartdate("");
    setenddate("");
    setemployeedeail(false);
  };

  const fillAddress = (e) => {
    setCheckbox(e.target.checked);
    if (e.target.checked === true) {
      setEmployeeformdata({
        ...Employeeformdata,
        Permanent_line1: Employeeformdata.Current_line1,
        Permanent_line2: Employeeformdata.Current_line2,
        Permanent_city: Employeeformdata.Current_city,
        Permanent_state: Employeeformdata.Current_state,
        Permanent_zip_code: parseInt(Employeeformdata.Current_zip_code),
        Permanent_country: Employeeformdata.Current_country,
      });
    } else
      setEmployeeformdata({
        ...Employeeformdata,
        Permanent_line1: "",
        Permanent_line2: "",
        Permanent_city: "",
        Permanent_state: "",
        Permanent_zip_code: "",
        Permanent_country: "",
      });
  };

  const employeeDetaiform = async () => {
    let flag = false;
    let scroll = false;
    if (
      !blankValidator(Employeeformdata.department_id) ||
      Employeeformdata.department_id === -1
    ) {
      setdepartmentIdError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("depart_id").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(Employeeformdata.designation_id) ||
      Employeeformdata.designation_id === -1
    ) {
      setdesignationIdError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("design_id").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.Teacher_first_name)) {
      setfirstNameError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("T_firstname").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.birth_date)) {
      setbirthdateError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("T_birth").focus();
      }
      scroll = true;
    }
    if (!blankValidator(Employeeformdata.gender)) {
      setgenderError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("gender_id").focus();
      }
      scroll = true;
    }
    if (!blankValidator(Employeeformdata.phone)) {
      setcontactnoError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("phone").focus();
      }
      scroll = true;
    }

    if (Employeeformdata.email) {
      if (!emailValidator(Employeeformdata.email)) {
        setemailCorrectError(true);
        flag = true;
        if (scroll === false) {
          document.getElementById("email_id").focus();
        }
        scroll = true;
      }
    }

    if (!blankValidator(Employeeformdata.Mother_first_name)) {
      setmotherFirstNameError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("M_firstname").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.Father_first_name)) {
      setfatherfirstnameError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("F_firstname").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.Current_line1)) {
      setaddressline1Error(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_line1").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.Current_country)) {
      setcountryError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_country").focus();
      }
      scroll = true;
    }
    if (!blankValidator(Employeeformdata.Current_state)) {
      setstateError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_state").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(Employeeformdata.Current_zip_code) ||
      Employeeformdata.Current_zip_code === ""
    ) {
      setzipcodeError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_pincode").focus();
      }
      scroll = true;
    }

    if (flag) {
      return;
    }

    try {
      let updateId;
      if (staffId) {
        updateId = staffId;
      } else if (empId) {
        updateId = empId;
      }
      setisloading(true);
      if (updateId) {
        await Update_Employee_Data({
          variables: {
            id: parseInt(updateId),
            designation_id: parseInt(Employeeformdata.designation_id),
            department_id: parseInt(Employeeformdata.department_id),
            email: Employeeformdata.email,
            Teacher_first_name: Employeeformdata.Teacher_first_name,
            Teacher_middle_name: Employeeformdata.Teacher_middle_name,
            Teacher_last_name: Employeeformdata.Teacher_last_name,
            phone: Employeeformdata.phone,
            institution_id,
            joining_date: Employeeformdata.joining_date,
            gender: Employeeformdata.gender,
            birth_date: Employeeformdata.birth_date,
            Current_line1: Employeeformdata.Current_line1,
            Current_line2: Employeeformdata.Current_line2,
            Current_city: Employeeformdata.Current_city,
            Current_state: Employeeformdata.Current_state,
            Current_zip_code: Employeeformdata.Current_zip_code,
            Current_country: Employeeformdata.Current_country,
            Permanent_line1: Employeeformdata.Permanent_line1,
            Permanent_line2: Employeeformdata.Permanent_line2,
            Permanent_city: Employeeformdata.Permanent_city,
            Permanent_state: Employeeformdata.Permanent_state,
            Permanent_zip_code: Employeeformdata.Permanent_zip_code + "",
            Permanent_country: Employeeformdata.Permanent_country,
            blood_group: Employeeformdata.blood_group,
            nationality: Employeeformdata.nationality,
            tax_id_number: Employeeformdata.taxvalue,
            marital_status: Employeeformdata.marital_status,
            passport_number: Employeeformdata.passport_number,
            passport_expiry_date: Employeeformdata.passport_expiry_date,
            national_id_number: Employeeformdata.national_id_number,
            religion: Employeeformdata.religion,
            Father_first_name: Employeeformdata.Father_first_name,
            Father_middle_name: Employeeformdata.Father_middle_name,
            Father_last_name: Employeeformdata.Father_last_name,
            Mother_first_name: Employeeformdata.Mother_first_name,
            Mother_middle_name: Employeeformdata.Mother_middle_name,
            Mother_last_name: Employeeformdata.Mother_last_name,
            Spouse_first_name: Employeeformdata.Spouse_first_name,
            Spouse_middle_name: Employeeformdata.Spouse_middle_name,
            Spouse_last_name: Employeeformdata.Spouse_last_name,
            education_qualifications: Employeeformdata.education_qualifications,
            //  education_qualification_doc: EducationUploadFileArr,

            passport:
              Employeeformdata.passport === undefined
                ? {}
                : Employeeformdata.passport,
            certificate:
              Employeeformdata.certificate === undefined
                ? {}
                : Employeeformdata.certificate,
            photo:
              Employeeformdata.photo === undefined
                ? {}
                : Employeeformdata.photo,
            birth_cert:
              Employeeformdata.birth_cert === undefined
                ? {}
                : Employeeformdata.birth_cert,
            tax: Employeeformdata.tax === undefined ? {} : Employeeformdata.tax,
            other: Employeeformdata.others,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.updateEmployee.message,
              "success"
            );
            navigate.goBack();
          })
          .catch((error) => {
            setisloading(false);
            console.log("then error", error);
          });
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const UploadDocumentHandler = (e, type) => {
    const file = e.target.files[0];
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);

    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };

          if (type === "PHOTO") {
            setEmployeeformdata({
              ...Employeeformdata,
              photo: obj,
            });
          }
          if (type === "BIRTH_CERTIFICATE") {
            setEmployeeformdata({
              ...Employeeformdata,
              birth_cert: obj,
            });
          }

          if (type === "PASSPORT") {
            setEmployeeformdata({
              ...Employeeformdata,
              passport: obj,
            });
          }
          if (type === "CERTIFICATE") {
            setEmployeeformdata({
              ...Employeeformdata,
              certificate: obj,
            });
          }
          if (type === "TAX_CARD") {
            setEmployeeformdata({
              ...Employeeformdata,
              tax: obj,
            });
          }
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  // upload documents
  const UploadMultipleDocumentHandler = async (e) => {
    if (e.target.files.length > 4) {
      showNotificationMsz(`Cannot Upload More than 4 files together`, "info");
      return;
    }
    if (Employeeformdata.others.length >= 4) {
      showNotificationMsz(`Already Have 4 documents`, "info");
      return;
    }
    try {
      setisloading(true);
      let fileArray = [];
      let file = e.target.files;
      for (let index = 0; index < file.length; index++) {
        const element = file[index];
        const url = `${Service.baseUrl}/uploadFile/image`;
        let fileData = new FormData();
        fileData.append("file", element);
        const { data } = await axios.post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const { path, filename, type, name } = data;
        const obj = {
          path,
          filename,
          type,
          name,
        };

        Employeeformdata.others.push(obj);
        setEmployeeformdata({ ...Employeeformdata });

        setisloading(false);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Employee Profile</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={2}>
                            <div className=" inputMarginright">
                              <img
                                src={`${baseUrl}/${Employeeformdata.photoPath} `}
                                className="student_photo_div"
                                alt="No_profile"
                              />
                            </div>
                          </Grid>
                          <Grid item md={5} className="mt-3">
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                ID : {Employeeformdata.display_id}
                              </Card>
                            </div>
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                Status : {Employeeformdata.status}
                              </Card>
                            </div>
                          </Grid>
                          <Grid item md={5} className="mt-3">
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                Login Id : {Employeeformdata.login_id}
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">Employee Details</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Department <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="depart_id"
                                value={Employeeformdata.department_id}
                                onChange={(e) => {
                                  setdepartmentIdError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    department_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Select Department</option>
                                {empDetailHook.departmentArr.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                              {departmentIdError && (
                                <span className="text-danger">
                                  Select the Department
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Designation<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="design_id"
                                value={Employeeformdata.designation_id}
                                onChange={(e) => {
                                  setdesignationIdError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    designation_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Designation</option>
                                {empDetailHook.designationArr.map(
                                  (item, index) => (
                                    <option value={item.id}>{item.name}</option>
                                  )
                                )}
                              </select>
                              {designationIdError && (
                                <span className="text-danger">
                                  Select the Designation
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date Of Joining{" "}
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="joining_id"
                                autoComplete="off"
                                value={Employeeformdata.joining_date}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    joining_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="text_filed_heading">Employee Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="T_firstname"
                                  autoComplete="off"
                                  value={Employeeformdata.Teacher_first_name}
                                  onChange={(e) => {
                                    setfirstNameError(false);
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Teacher_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {firstNameError && (
                                  <span className="text-danger">
                                    Enter the First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Teacher_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Teacher_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Teacher_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Teacher_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date of Birth{" "}
                              <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="T_birth"
                                autoComplete="off"
                                value={Employeeformdata.birth_date}
                                onChange={(e) => {
                                  setbirthdateError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    birth_date: e.target.value,
                                  });
                                }}
                              />
                              {birthdateError && (
                                <span className="text-danger">
                                  Enter the Date of Birth
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Gender <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gender_id"
                                value={Employeeformdata.gender}
                                onChange={(e) => {
                                  setgenderError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    gender: e.target.value,
                                  });
                                }}
                              >
                                <option>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                              {genderError && (
                                <span className="text-danger">
                                  Select the Gender
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                              <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <PhoneInput
                                className="phone_input_width_create"
                                id="phone"
                                country={"in"}
                                countryCodeEditable={false}
                                value={Employeeformdata.phone}
                                onChange={(e) => {
                                  setcontactnoError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    phone: e,
                                  });
                                }}
                              />
                              {contactnoError && (
                                <span className="text-danger">
                                  Enter the Contact Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Blood Group
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                value={Employeeformdata.blood_group}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    blood_group: e.target.value,
                                  });
                                }}
                              >
                                <option value="">Blood Group</option>
                                <option value="A+">A+</option>
                                <option value="B+">B+</option>
                                <option value="AB+">AB+</option>
                                <option value="O+">O+</option>
                                <option value="A-">A-</option>
                                <option value="B-">B-</option>
                                <option value="AB-">AB-</option>
                                <option value="O-">O-</option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Email</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="email_id"
                                autoComplete="off"
                                value={Employeeformdata.email}
                                onChange={(e) => {
                                  setemailCorrectError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    email: e.target.value,
                                  });
                                }}
                              />

                              {emailCorrectError && (
                                <span className="text-danger">
                                  Enter the Correct Email Address
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="passportnumber"
                                autoComplete="off"
                                value={Employeeformdata.passport_number}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Passport Expiry Date
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="passportexpirydate"
                                autoComplete="off"
                                value={
                                  Employeeformdata.passport_expiry_date === ""
                                    ? ""
                                    : Employeeformdata.passport_expiry_date
                                }
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    passport_expiry_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={Employeeformdata.national_id_number}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Tax Id / Pan Card Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="taxid"
                                autoComplete="off"
                                value={Employeeformdata.taxvalue}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    taxvalue: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">Religion</div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="religion"
                                value={Employeeformdata.religion}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    religion: e.target.value,
                                  });
                                }}
                              >
                                <option>Religion</option>
                                <option>Hindu</option>
                                <option>Islam</option>
                                <option>Christian</option>
                                <option>Sikh</option>
                                <option>Others</option>
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Marital Status
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="maritalstatus"
                                value={Employeeformdata.marital_status}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    marital_status: e.target.value,
                                  });
                                }}
                              >
                                <option>Marital Status</option>
                                <option>Single</option>
                                <option>Married</option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="nationality"
                                autoComplete="off"
                                value={Employeeformdata.nationality}
                                onChange={(e) => {
                                  setnationalityError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    nationality: e.target.value,
                                  });
                                }}
                              />
                              {nationalityError && (
                                <span className="text-danger">
                                  Enter the Nationality
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading">Spouse's Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Spouse_first_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Spouse_first_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Spouse_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Spouse_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Spouse_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Spouse_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Mother's Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="M_firstname"
                                  autoComplete="off"
                                  value={Employeeformdata.Mother_first_name}
                                  onChange={(e) => {
                                    setmotherFirstNameError(false);
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Mother_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {motherFirstNameError && (
                                  <span className="text-danger">
                                    Enter the First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Mother_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Mother_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="M_lastname"
                                  autoComplete="off"
                                  value={Employeeformdata.Mother_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Mother_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Father's Name</div>
                        <div className="name_outside_border mb-2 p-2">
                          <Grid className="grid_main_div mb-2">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Father_first_name}
                                  onChange={(e) => {
                                    setfatherfirstnameError(false);
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Father_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {fatherfirstnameError && (
                                  <span className="text-danger">
                                    Enter the First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Father_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Father_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="F_lastname"
                                  autoComplete="off"
                                  value={Employeeformdata.Father_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Father_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Address</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Current Address
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <label className="text_filed_heading pt-2">
                              {" "}
                              Address Line 1
                            </label>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="C_line1"
                                autoComplete="off"
                                value={Employeeformdata.Current_line1}
                                onChange={(e) => {
                                  setaddressline1Error(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_line1: e.target.value,
                                  });
                                }}
                              />
                              {addressline1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <label className="text_filed_heading">
                              {" "}
                              Address Line 2
                            </label>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="C_line2"
                                autoComplete="off"
                                value={Employeeformdata.Current_line2}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Country</div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                id="C_country"
                                value={Employeeformdata.Current_country}
                                onChange={(e) => {
                                  setcountryError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {countryError && (
                                <span className="text-danger">
                                  Select the Country
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">State</div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                id="C_state"
                                country={Employeeformdata.Current_country}
                                value={Employeeformdata.Current_state}
                                onChange={(e) => {
                                  setstateError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {stateError && (
                                <span className="text-danger">
                                  Select the State
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="C_pincode"
                                autoComplete="off"
                                value={Employeeformdata.Current_zip_code}
                                onChange={(e) => {
                                  if (isNaN(e.target.value)) {
                                    return;
                                  }
                                  setzipcodeError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_zip_code: e.target.value,
                                  });
                                }}
                              />
                              {zipcodeError && (
                                <span className="text-danger">
                                  Enter the Pin Code
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="d-flex  mt-3">
                          <span className="text_filed_heading">
                            Permanent Address
                          </span>
                          <span className="ml-5">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="filladdress"
                                onClick={(e) => fillAddress(e)}
                                checked={checkbox}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Same As Current Address
                              </label>
                            </div>
                          </span>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <label className="text_filed_heading pt-2">
                              {" "}
                              Address Line 1
                            </label>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_line1"
                                autoComplete="off"
                                value={Employeeformdata.Permanent_line1}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_line1: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <label className="text_filed_heading">
                              {" "}
                              Address Line 2
                            </label>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_line2"
                                autoComplete="off"
                                value={Employeeformdata.Permanent_line2}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Country</div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                id="P_country"
                                value={Employeeformdata.Permanent_country}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">State</div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                id="P_state"
                                country={Employeeformdata.Permanent_country}
                                value={Employeeformdata.Permanent_state}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_pincode"
                                autoComplete="off"
                                value={Employeeformdata.Permanent_zip_code}
                                onChange={(e) => {
                                  if (isNaN(e.target.value)) {
                                    return;
                                  }

                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_zip_code: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">
                    Educational Qualification
                  </div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {employeedeail === false ? (
                          <div
                            className="d-flex float-right"
                            onClick={() => {
                              setemployeedeail(true);
                            }}
                          >
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add Additional Qualification
                            </span>
                          </div>
                        ) : (
                          <Expand open={employeedeail}>
                            <Card className="cardvisible_border mt-2 ">
                              <div
                                className="float-right"
                                onClick={() => {
                                  setemployeedeail(false);
                                }}
                              >
                                <span className="iconstoaddfee mr-2">
                                  <i class="fa fa-times cursor"></i>
                                </span>
                              </div>
                              <div className="textfiled_margin">
                                <Grid className="grid_main_div">
                                  <Grid item md={6}>
                                    <div className="text_filed_heading">
                                      Qualification Attained
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <select
                                        class="form-control"
                                        id="qualificationn"
                                        value={qualification}
                                        onChange={(e) => {
                                          setqualificationError(false);
                                          qualificationAttainedDetail(e);
                                        }}
                                      >
                                        {AttainedArr.map((item) => (
                                          <option value={item.value}>
                                            {item.label}
                                          </option>
                                        ))}
                                      </select>
                                      {qualificationError && (
                                        <span className="text-danger">
                                          Select Qualification Attained
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      Course Name
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="coursee"
                                        autoComplete="off"
                                        value={course}
                                        onChange={(e) => {
                                          setcourseNameError(false);
                                          setcourse(e.target.value);
                                        }}
                                      />
                                      {courseNameError && (
                                        <span className="text-danger">
                                          Enter the Course/Class
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      College/University Name
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="collegee"
                                        autoComplete="off"
                                        value={college}
                                        onChange={(e) => {
                                          setcollegeNameError(false);
                                          setcollege(e.target.value);
                                        }}
                                      />
                                      {collegeNameError && (
                                        <span className="text-danger">
                                          Enter the College/University
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="text_filed_heading gapfromanotherinput">
                                  Period of education
                                </div>
                                <Grid className="grid_main_div">
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      Start Date
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="date"
                                        class="form-control"
                                        id="startdatee"
                                        autoComplete="off"
                                        value={startdate}
                                        onChange={(e) => {
                                          setstarttimeError(false);
                                          setstartdate(e.target.value);
                                        }}
                                      />
                                      {starttimeError && (
                                        <span className="text-danger">
                                          Enter the Start Date
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      End Date
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="date"
                                        class="form-control"
                                        id="enddatee"
                                        autoComplete="off"
                                        min={startdate}
                                        value={enddate}
                                        onChange={(e) => {
                                          setendtimeError(false);
                                          setenddate(e.target.value);
                                        }}
                                      />
                                      {endtimeError && (
                                        <span className="text-danger">
                                          Enter the End Date
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={6}></Grid>
                                </Grid>

                                <Grid className="grid_main_div  mb-2">
                                  <Grid item md={9}></Grid>
                                  <Grid item md={3}>
                                    <Button
                                      variant="contained"
                                      className="add_new_query_btn button_login_decoration button_width mt-4"
                                      onClick={() => addEducationData()}
                                    >
                                      Add Education
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                            </Card>
                          </Expand>
                        )}

                        <div className="table_foramtitng mt-1 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                              id="Table_width_Student"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Qualification
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Course
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    College
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    End Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? Employeeformdata.education_qualifications.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : Employeeformdata.education_qualifications
                                ).map((row, index) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.qualification}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.course_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.university_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.start_date}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.end_date}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        <div className="d-flex">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                handleEducationUpdate(
                                                  row,
                                                  index
                                                )
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() => {
                                                Employeeformdata.education_qualifications.splice(
                                                  index,
                                                  1
                                                );
                                                let temp = [];
                                                Employeeformdata.education_qualifications.map(
                                                  (data) => {
                                                    temp.push(data);
                                                  }
                                                );
                                                setEmployeeformdata({
                                                  ...Employeeformdata,
                                                  education_qualifications:
                                                    temp,
                                                });
                                              }}
                                            ></i>
                                          </span>
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={false}
                              component="div"
                              count={
                                Employeeformdata.education_qualifications.length
                              }
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangerowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Document Upload</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Photo
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              id="Photo"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "PHOTO")
                              }
                            />
                          </Grid>

                          <Grid item md={6}>
                            {blankValidator(Employeeformdata.photo.name) && (
                              <Chip
                                label={Employeeformdata.photo.name}
                                onClick={() => {
                                  HandleImageDailogBox(Employeeformdata.photo);
                                }}
                                onDelete={() => {
                                  let blankObj = {};
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    photo: blankObj,
                                  });
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Birth Certificate
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "BIRTH_CERTIFICATE")
                              }
                            />
                          </Grid>

                          <Grid item md={6}>
                            {blankValidator(
                              Employeeformdata.birth_cert?.name
                            ) && (
                              <Chip
                                label={Employeeformdata.birth_cert.name}
                                onClick={() => {
                                  HandleImageDailogBox(
                                    Employeeformdata.birth_cert
                                  );
                                }}
                                onDelete={() => {
                                  let blankObj = {};
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    birth_cert: blankObj,
                                  });
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Passport
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "PASSPORT")
                              }
                            />
                          </Grid>

                          <Grid item md={6}>
                            {blankValidator(
                              Employeeformdata.passport?.name
                            ) && (
                              <Chip
                                label={Employeeformdata.passport?.name}
                                onClick={() => {
                                  HandleImageDailogBox(
                                    Employeeformdata.passport
                                  );
                                }}
                                onDelete={() => {
                                  let blankObj = {};
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    passport: blankObj,
                                  });
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Certificate
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "CERTIFICATE")
                              }
                            />
                          </Grid>

                          <Grid item md={6}>
                            {blankValidator(
                              Employeeformdata.certificate?.name
                            ) && (
                              <Chip
                                label={Employeeformdata.certificate.name}
                                onClick={() => {
                                  HandleImageDailogBox(
                                    Employeeformdata.certificate
                                  );
                                }}
                                onDelete={() => {
                                  let blankObj = {};
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    certificate: blankObj,
                                  });
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Tax/Pan Card
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "TAX_CARD")
                              }
                            />
                          </Grid>

                          <Grid item md={6}>
                            {blankValidator(Employeeformdata.tax?.name) && (
                              <Chip
                                label={Employeeformdata.tax.name}
                                onClick={() => {
                                  HandleImageDailogBox(Employeeformdata.tax);
                                }}
                                onDelete={() => {
                                  let blankObj = {};
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    tax: blankObj,
                                  });
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Add Any
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              multiple
                              onChange={(e) => UploadMultipleDocumentHandler(e)}
                            />
                          </Grid>

                          <Grid item md={1}></Grid>
                        </Grid>
                        <Grid className="mt-3 mb-2 d-flex">
                          {Employeeformdata.others.length !== 0 &&
                            Employeeformdata.others.map((item, index) => (
                              <div key={index}>
                                <Chip
                                  className="ml-1"
                                  label={item.name}
                                  onClick={() => {
                                    HandleImageDailogBox(item);
                                  }}
                                  onDelete={() => {
                                    Employeeformdata.others.splice(index, 1);
                                    let temp = [];
                                    Employeeformdata.others.map((data) => {
                                      temp.push(data);
                                    });
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      others: temp,
                                    });
                                  }}
                                  color="primary"
                                  variant="outlined"
                                />
                              </div>
                            ))}
                          {/* {Employeeformdata.others !== undefined &&
                          Employeeformdata.others !== null ? (
                            <div item md={1} className="div_main_div">
                              {Employeeformdata.showother.map(
                                (item, index) =>
                                  blankValidator(item.name) && (
                                    <Chip
                                      className="ml-1"
                                      label={item.name}
                                      onClick={() => {
                                        HandleImageDailogBox(item);
                                      }}
                                      onDelete={() => {
                                        Employeeformdata.showother.splice(
                                          index,
                                          1
                                        );
                                        let temp = [];
                                        Employeeformdata.showother.map(
                                          (data) => {
                                            temp.push(data);
                                          }
                                        );
                                        setEmployeeformdata({
                                          ...Employeeformdata,
                                          showother: temp,
                                        });
                                      }}
                                      color="primary"
                                      variant="outlined"
                                    />
                                  )
                              )}
                            </div>
                          ) : (
                            ""
                          )} */}
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          {" "}
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick={employeeDetaiform}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={EditDailogEducationOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
      >
        <DialogTitle>
          Edit Education Details
          <span
            className="float-right"
            onClick={() => {
              setEditDailogEducationOpen(!EditDailogEducationOpen);
            }}
          >
            <i class="fa fa-times cursor" aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="textfiled_margin">
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="text_filed_heading">Qualification Attained</div>
                <div className="inputMarginright mt-1">
                  <select
                    class="form-control"
                    id="qualification"
                    value={EditQualificationId}
                    onChange={(e) => {
                      setEditqualificationIdError(false);
                      EditqualificationAttainedDetail(e);
                    }}
                  >
                    {AttainedArr.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {EditqualificationIdError && (
                    <span className="text-danger">
                      Select Qualification Attained
                    </span>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="text_filed_heading">Course Name</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    autoComplete="off"
                    value={EditCourse}
                    onChange={(e) => {
                      setEditcourseNameError(false);
                      setEditCourse(e.target.value);
                    }}
                  />
                  {EditcourseNameError && (
                    <span className="text-danger">Enter the Course/Class</span>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="text_filed_heading">
                  College/University Name
                </div>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    autoComplete="off"
                    value={EditCollege}
                    onChange={(e) => {
                      setEditcollegeNameError(false);
                      setEditCollege(e.target.value);
                    }}
                  />
                  {EditcollegeNameError && (
                    <span className="text-danger">
                      Enter the College/University
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className="text_filed_heading gapfromanotherinput">
              Period of education
            </div>
            <Grid className="grid_main_div">
              <Grid item md={3}>
                <div className="text_filed_heading">Start Date</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="date"
                    class="form-control"
                    id="exampleFormControlInput1"
                    autoComplete="off"
                    value={EditStartDate}
                    onChange={(e) => {
                      setEditstarttimeError(false);
                      setEditStartDate(e.target.value);
                    }}
                  />
                  {EditstarttimeError && (
                    <span className="text-danger">Enter the Start Date</span>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="text_filed_heading">End Date</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="date"
                    class="form-control"
                    id="exampleFormControlInput1"
                    autoComplete="off"
                    value={EditEndDate}
                    onChange={(e) => {
                      setEditendtimeError(false);
                      setEditEndDate(e.target.value);
                    }}
                  />
                  {EditendtimeError && (
                    <span className="text-danger">Enter the End Date</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            f
            className="add_new_query_btn button_login_decoration mr-1"
            onClick={() => {
              setEditDailogEducationOpen(!EditDailogEducationOpen);
            }}
          >
            Cancel
          </Button>
          <Button
            className="add_new_query_btn button_login_decoration"
            onClick={() => HandleEditEducationData(EditID)}
          >
            Update{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={OpenImageDailog}
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={HandleImageDailogBoxClose}
        className="dailog_formatting"
      >
        <div>
          <div className="cut_dailog_icon float-right">
            <a
              href={`${baseUrl}/${imagepath}`}
              download
              target="_blank"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={HandleImageDailogBoxClose}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <span>
            <img
              src={`${baseUrl}/${imagepath}`}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </span>
        </div>
      </Dialog>
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminEmployeeDetailForm));
