import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";

//redux
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { CapitalizeFirstFn } from "../../../utils/Validation";

const InvoiceTemplate = (props) => {
  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  let Organization_Name = props.data.institution_name;

  // to get data
  const client = useApolloClient();
  const [isUpdated, setIsUpdated] = useState(false);
  const [Delete_Invoice_Template_Data, {}] = useMutation(
    Service.Delete_Invoice_Template_Data
  );
  const [Create_Edit_invoice_Data, {}] = useMutation(
    Service.Create_Edit_invoice_Data
  );

  const classes = useStyles();
  const [openeditfeesubtype, setOpeneditfeesubtype] = useState(false);
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ViewInvoiceData, setViewInvoiceData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState("");
  const [deleteTemplateName, setdeleteTemplateName] = useState("");
  const [opendialogConfimation, setopendialogConfimation] = useState(false);

  //error
  const [titleError, settitleError] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // to get data of invoice meta data
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Template_Invoice_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setViewInvoiceData(response.data.getInvoiceTemplate);
        })
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  }, [isUpdated, client, institution_id]);

  const CreateTemplateFn = () => {
    try {
      if (!blankValidator(title)) {
        settitleError(false);
        return;
      }
      Create_Edit_invoice_Data({
        variables: { institution_id, name: title },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.addInvoiceTemplate.message,
            "success"
          );

          props.navigate("/createEditTemplate", {
            invoiceData: { title, id: response.data.addInvoiceTemplate.id },
          });
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const DeleteDailogBox = (data) => {
    setDeleteTemplateId(data.id);
    setdeleteTemplateName(data.name);
    setopendialogConfimation(true);
  };

  // to delete template---------------------------
  const deleteTemplateFn = (ID) => {
    try {
      let id = parseInt(ID);
      setisloading(true);
      Delete_Invoice_Template_Data({
        variables: { id },
      })
        .then((response) => {
          setopendialogConfimation(false);
          setisloading(false);
          if (response.data.deleteInvoiceTemplate.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(
            response.data.deleteInvoiceTemplate.message,
            "success"
          );

          setIsUpdated(true);
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={Organization_Name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading ">
                <div className="mt-3 ml-3 Heading_bold">Invoice Template</div>
                <div className="button_main_flex invoice_btn_width mt-4">
                  <Button
                    variant="contained"
                    className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_size_invoice"
                    onClick={() => props.navigate("/createInvoiceTemplate")}
                  >
                    Create New Template
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_width button_login_decoration btn_font_size_invoice mr-3"
                    onClick={() => props.navigate("/createeditinvoice")}
                  >
                    Create/Edit FeeSubType
                  </Button>
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin mt-3">
                      <Grid className="grid_main_div">
                        <Grid item md={7}></Grid>
                        <Grid item md={5}></Grid>
                      </Grid>
                      <div className="table_foramtitng ">
                        <TableContainer component={Paper}>
                          <div className="tableborder_viewinvoice">
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Title
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Actions
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? ViewInvoiceData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : ViewInvoiceData
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell scope="row" align="left">
                                      {CapitalizeFirstFn(row.name)}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                    >
                                      <span
                                        className="actionStyle"
                                        onClick={() =>
                                          props.navigate(
                                            "/createEditTemplate",
                                            {
                                              state: {
                                                invoiceData: {
                                                  title: row.name,
                                                  id: row.id,
                                                },
                                              },
                                            }
                                          )
                                        }
                                      >
                                        Open
                                      </span>
                                      |
                                      <span
                                        className="actionStyle"
                                        onClick={() => DeleteDailogBox(row)}
                                      >
                                        Delete
                                      </span>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={ViewInvoiceData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>

                      <Grid className="grid_main_div">
                        <Grid item md={8}></Grid>
                        <Grid item md={4}>
                          <div className="main_button_div2 mb-3 mt-4">
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_width button_login_decoration"
                              onClick={() => props.navigate("/createinvoice")}
                            >
                              Create Invoice
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <Loder loading={isloading} />
      <Dialog
        open={openeditfeesubtype}
        onClose={() => setOpeneditfeesubtype(false)}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => setOpeneditfeesubtype(false)}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Title</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <div className="dialogButton">
            <Button
              variant="contained"
              className="button_enquiry_details button_login_decoration ml-2"
              onClick={CreateTemplateFn}
            >
              Create
            </Button>
          </div>
        </div>
      </Dialog>
      {/* ------------delete template dialog-------------- */}
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure, Want to delete the Template : {deleteTemplateName}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteTemplateFn(deleteTemplateId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(InvoiceTemplate));
