import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Transport.css";

import DateFnsUtils from "@date-io/date-fns";
import Chip from "@material-ui/core/Chip";
import { baseUrl } from "../../../services/index.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import * as Service from "../../../services/index";
import {
  blankValidator,
  getFullName,
  getHHMMSStoDate,
  showNotificationMsz,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useApolloClient } from "@apollo/client";

const CreateNewRoute = (props) => {
  //---------------------global id-------------------
  let pageType = props.location.state.pageType;
  let institution_id = parseInt(props.data.institution_id);
  let Organization_Name = props.data.institution_name;
  //---------------------api calls-------------------
  const client = useApolloClient();
  const [Add_TransPort_Route, {}] = useMutation(Service.Add_TransPort_Route);
  const [Update_Transport_Route, {}] = useMutation(
    Service.Update_Transport_Route
  );
  const [driverDetailsError, setDriverDetailsError] = useState(false);
  const [photuploadError, setphotuploadError] = useState(false);
  const [filename, setFileName] = useState("Add Attachment");
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const [selectedDriverId, setSelectedDriverId] = useState(-1);
  const [allDriverArr, setAllDriverArr] = useState([]);

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const HandleImageDailogBoxClose = () => {
    setimagepath("");
    setimageType("");
    setOpenImageDailog(false);
  };
  //---------------------local state-------------------
  const [isloading, setisloading] = useState(false);
  const [editMainId, seteditMainId] = useState("");
  const temp = new Date();
  const [route_name, setRoute_name] = useState("");
  const [start_point, setStart_point] = useState("");
  const [end_point, setEnd_point] = useState("");
  const [driver_phone_number, setDriver_phone_number] = useState("");

  const [vehicle_owner_first_name, setvehicle_owner_first_name] = useState("");
  const [vehicle_owner_middle_name, setvehicle_owner_middle_name] =
    useState("");
  const [vehicle_owner_last_name, setvehicle_owner_last_name] = useState("");
  const [vehicle_type, setVehicle_type] = useState("");
  const [fileUpload, setFileUpload] = useState({});
  const [vehicle_registered_number, setVehicle_registered_number] =
    useState("");
  const [max_capacity, setMax_capacity] = useState("");
  const [route_url, setRoute_url] = useState("");
  const [start_time, setStart_time] = useState(temp);
  const [end_time, setEnd_time] = useState(temp);
  const [routeType, setRouteType] = useState("");
  const [routeTypeError, setRouteTypeError] = useState("");
  const [routeNameError, setrouteNameError] = useState(false);
  const [startpointError, setstartpointError] = useState(false);
  const [destinationpointError, setdestinationpointError] = useState(false);
  const [driverfirstnameError, setdriverfirstnameError] = useState(false);
  const [driverlastnameError, setdriverlastnameError] = useState(false);
  const [drivercontactError, setdrivercontactError] = useState(false);
  const [vehicaltypeError, setvehicaltypeError] = useState(false);
  const [govtregisterError, setgovtregisterError] = useState(false);
  const [ownerfirstnameError, setownerfirstnameError] = useState(false);
  const [ownerlastnameError, setownerlastnameError] = useState(false);
  const [maxcapacityError, setmaxcapacityError] = useState(false);
  const [routeUrlError, setrouteUrlError] = useState(false);
  const [starttimeError, setstarttimeError] = useState(false);
  const [endtimeError, setendtimeError] = useState(false);

  //---------------------methods-------------------
  useEffect(() => {
    getAllDriverData();
  }, []);

  useEffect(() => {
    const fillData = () => {
      if (pageType === "EDIT") {
        setisloading(true);
        const {
          id,
          route_name,
          start_point,
          end_point,
          driver_name,
          driver_phone_number,
          vehicle_type,
          vehicle_registered_number,
          vehicle_owner_name,
          max_capacity,
          route_url,
          route_type,
          start_time,
          end_time,
          employee,
        } = props.location.state.routeData;

        seteditMainId(id);
        setRoute_name(route_name);
        setStart_point(start_point);
        setEnd_point(end_point);

        setDriver_phone_number(driver_phone_number);
        setVehicle_type(vehicle_type);
        setVehicle_registered_number(vehicle_registered_number);
        setvehicle_owner_first_name(vehicle_owner_name.first_name);
        setvehicle_owner_middle_name(vehicle_owner_name.middle_name);
        setvehicle_owner_last_name(vehicle_owner_name.last_name);
        setMax_capacity(max_capacity);
        setRoute_url(route_url);
        setRouteType(route_type);
        setStart_time(getHHMMSStoDate(start_time));
        setEnd_time(getHHMMSStoDate(end_time));
        if (employee?.id)
          setSelectedDriverId(employee ? parseInt(employee.id) : -1);
        setisloading(false);
      }
    };
    fillData();
  }, [pageType]);

  const getAllDriverData = async () => {
    const { data } = await client.query({
      query: Service.Get_Employee_All_data,
      variables: {
        institution_id,
        department_id: -1,
        user_type: "Transport",
      },
    });

    if (data && data.getEmployee) {
      setAllDriverArr(data.getEmployee);
    }
  };
  const handlestarttime = (date) => {
    setstarttimeError(false);
    setStart_time(date);
  };
  const handleendtime = (date) => {
    setendtimeError(false);
    setEnd_time(date);
  };
  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];

      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setphotuploadError(false);
          setFileName(file.name);
          setFileUpload(obj);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const createNewRoot = async () => {
    if (!blankValidator(route_name)) {
      setrouteNameError(true);
      return;
    }
    if (!blankValidator(start_point)) {
      setstartpointError(true);
      return;
    }
    if (!blankValidator(end_point)) {
      setdestinationpointError(true);
      return;
    }
    if (!selectedDriverId) {
      setDriverDetailsError(true);
      return;
    }

    if (!blankValidator(driver_phone_number)) {
      setdrivercontactError(true);
      return;
    }
    if (!blankValidator(vehicle_type)) {
      setvehicaltypeError(true);
      return;
    }
    if (!blankValidator(vehicle_registered_number)) {
      setgovtregisterError(true);
      return;
    }
    if (!blankValidator(vehicle_owner_first_name)) {
      setownerfirstnameError(true);
      return;
    }
    if (!blankValidator(vehicle_owner_last_name)) {
      setownerlastnameError(true);
      return;
    }

    if (!blankValidator(max_capacity)) {
      setmaxcapacityError(true);
      return;
    }
    // if (!blankValidator(route_url)) {
    //   setrouteUrlError(true);
    //   return;
    // }
    if (!blankValidator(start_time)) {
      setstarttimeError(true);
      return;
    }
    if (!blankValidator(end_time)) {
      setendtimeError(true);
      return;
    }
    if (!blankValidator(routeType)) {
      setRouteTypeError(true);
      return;
    }
    const driver_name = {
      first_name: "",
      middle_name: "",
      last_name: "",
    };
    const vehicle_owner_name = {
      first_name: vehicle_owner_first_name,
      middle_name: vehicle_owner_middle_name,
      last_name: vehicle_owner_last_name,
    };
    setisloading(true);
    let maxCap = parseInt(max_capacity);
    if (pageType === "EDIT") {
      try {
        Update_Transport_Route({
          variables: {
            id: parseInt(editMainId),
            route_name,
            start_point,
            end_point,
            driver_name,
            vehicle_owner_name,
            driver_phone_number,
            vehicle_type,
            vehicle_registered_number,
            max_capacity: maxCap,
            route_url,
            route_type: routeType,
            start_time: start_time.toLocaleTimeString().substring(0, 8),
            end_time: end_time.toLocaleTimeString().substring(0, 8),
            driver_id: selectedDriverId,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.updateTransportRoute.message,
              "success"
            );
           props.navigate("routelistings");
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        console.log("t and c error", error);
      }
    } else {
      try {
        const { data } = await Add_TransPort_Route({
          variables: {
            driver_id: selectedDriverId,
            institution_id,
            route_name,
            start_point,
            end_point,
            driver_name,
            vehicle_owner_name,
            driver_phone_number,
            vehicle_type,
            vehicle_registered_number,
            max_capacity: maxCap,
            route_url,
            route_type: routeType,
            start_time: start_time.toLocaleTimeString().substring(0, 8),
            end_time: end_time.toLocaleTimeString().substring(0, 8),
            driver_photo_id: fileUpload[0] === undefined ? {} : fileUpload[0],
            driver_id: selectedDriverId,
          },
        });
        if (data) {
          const { addTransportRoute } = data;
          showNotificationMsz(addTransportRoute.message, "success");
         props.navigate("routelistings");
        }
        setisloading(false);
      } catch (error) {
        setisloading(false);
        showNotificationMsz(error, "danger");
      }
    }
  };
  const driverHandler = (driverId) => {
    let driver_id = parseInt(driverId);
    if (parseInt(driverId) === -1) {
      setSelectedDriverId(driver_id);
      return;
    }
    setSelectedDriverId(driver_id);
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={Organization_Name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold mt-3">
                  {pageType === "EDIT" ? "Route Details" : "New Route"}{" "}
                  {pageType === "EDIT" && `(${route_name})`}
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height p-2">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div">
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Route Name{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Name of the route"
                              value={route_name}
                              onChange={(e) => {
                                setrouteNameError(false);
                                setRoute_name(e.target.value);
                              }}
                            />
                            {routeNameError && (
                              <span className="text-danger">
                                Enter the Route Name
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Starting Point
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Starting Point"
                              value={start_point}
                              onChange={(e) => {
                                setstartpointError(false);
                                setStart_point(e.target.value);
                              }}
                            />
                            {startpointError && (
                              <span className="text-danger">
                                Enter the Starting Point
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Destination Point{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Destination Point"
                              value={end_point}
                              onChange={(e) => {
                                setdestinationpointError(false);
                                setEnd_point(e.target.value);
                              }}
                            />
                            {destinationpointError && (
                              <span className="text-danger">
                                Enter the Destination Point
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item md={4}>
                        <div className="text_filed_heading gapfromanotherinput ">
                          Transport Employee
                        </div>
                        <select
                          class="form-control p-2"
                          value={selectedDriverId}
                          onChange={(e) => {
                            driverHandler(e.target.value);
                            setDriverDetailsError(false);
                          }}
                        >
                          <option value={-1}> Select Driver</option>
                          {allDriverArr &&
                            allDriverArr.map((item) => (
                              <option value={item.id}>
                                {getFullName(item.name)}
                              </option>
                            ))}
                        </select>
                        {driverDetailsError && (
                          <span className="text-danger">
                            Please select driver.
                          </span>
                        )}
                      </Grid>
                      {/* <div className="name_outside_border p-2">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              First Name{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="First Name"
                                value={driver_name_first_name}
                                onChange={(e) => {
                                  setdriverfirstnameError(false);
                                  setdriver_first_name(e.target.value);
                                }}
                              />
                              {driverfirstnameError && (
                                <span className="text-danger">
                                  Enter First Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Middle Name
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Middle Name"
                                value={driver_name_middle_name}
                                onChange={(e) => {
                                  setdriver_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Last Name{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Last Name"
                                value={driver_name_last_name}
                                onChange={(e) => {
                                  setdriverlastnameError(false);
                                  setdriver_last_name(e.target.value);
                                }}
                              />
                              {driverlastnameError && (
                                <span className="text-danger">
                                  Enter Last Name
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div> */}
                      <Grid className="grid_main_div gapfromanotherinput">
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Driver Contact Number{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <PhoneInput
                              className="phone_input_width_create"
                              country={"in"}
                              id="standard-required"
                              value={driver_phone_number}
                              onChange={(e) => {
                                setdrivercontactError(false);

                                setDriver_phone_number(e);
                              }}
                            />
                            {drivercontactError && (
                              <span className="text-danger">
                                Enter the Contact Number
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Type of Vehicle{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Vehicle Type"
                              value={vehicle_type}
                              onChange={(e) => {
                                setvehicaltypeError(false);
                                setVehicle_type(e.target.value);
                              }}
                            />
                            {vehicaltypeError && (
                              <span className="text-danger">
                                Enter the Type of Vehicle
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Vehicle Govt. Registration Number{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              value={vehicle_registered_number}
                              onChange={(e) => {
                                setgovtregisterError(false);
                                setVehicle_registered_number(e.target.value);
                              }}
                            />
                            {govtregisterError && (
                              <span className="text-danger">
                                Enter the Govt. Register Number
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className="text_filed_heading gapfromanotherinput ">
                        Vehicle Owner's Name
                      </div>
                      <div className="name_outside_border p-2">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              First Name{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="First Name"
                                value={vehicle_owner_first_name}
                                onChange={(e) => {
                                  setownerfirstnameError(false);
                                  setvehicle_owner_first_name(e.target.value);
                                }}
                              />
                              {ownerfirstnameError && (
                                <span className="text-danger">
                                  Enter First Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Middle Name
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Middle Name"
                                value={vehicle_owner_middle_name}
                                onChange={(e) => {
                                  setvehicle_owner_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Last Name{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Last Name"
                                value={vehicle_owner_last_name}
                                onChange={(e) => {
                                  setownerlastnameError(false);
                                  setvehicle_owner_last_name(e.target.value);
                                }}
                              />
                              {ownerlastnameError && (
                                <span className="text-danger">
                                  Enter Last Name
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <Grid className="grid_main_div gapfromanotherinput">
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Max Capacity{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              value={max_capacity}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return;
                                }
                                setmaxcapacityError(false);
                                setMax_capacity(e.target.value);
                              }}
                            />
                            {maxcapacityError && (
                              <span className="text-danger">
                                Enter the Max. Capacity of Vehicle
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={8}>
                          {/* <div className="text_filed_heading">
                            Route URL{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="xyz"
                              value={route_url}
                              onChange={(e) => {
                                setrouteUrlError(false);
                                setRoute_url(e.target.value);
                              }}
                            />
                            {routeUrlError && (
                              <span className="text-danger">
                                Enter the Route URL
                              </span>
                            )}
                          </div> */}
                        </Grid>
                      </Grid>

                      <Grid className="grid_main_div gapfromanotherinput">
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            Start Time{" "}
                            <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="inputMarginright ">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                views={["hours", "minutes"]}
                                margin="normal"
                                id="time-picker"
                                inputVariant="outlined"
                                variant="outlined"
                                value={start_time}
                                onChange={handlestarttime}
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            {starttimeError && (
                              <span className="text-danger">
                                Enter the Start Time
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">
                            End Time <span className="text-danger pl-1">*</span>
                          </div>
                          <div className="">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                views={["hours", "minutes"]}
                                margin="normal"
                                id="time-picker"
                                inputVariant="outlined"
                                variant="outlined"
                                value={end_time}
                                onChange={handleendtime}
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            {endtimeError && (
                              <span className="text-danger">
                                Enter the End Time
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="pl-2">
                            <div className="text_filed_heading">
                              Route Type{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <select
                              class="form-control"
                              value={routeType}
                              onChange={(e) => {
                                setRouteType(e.target.value);
                                setRouteTypeError(false);
                              }}
                            >
                              <option value={-1}> Select Route Type</option>

                              <option value="PICK">Pick up</option>
                              <option value="DROP">Drop</option>
                            </select>
                            {routeTypeError && (
                              <span className="text-danger">
                                Enter route type
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className="mt-3 mb-2">
                        <label for="exampleInputEmail1">Attachment</label>
                        <div class="input-group mt-1">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fa fa-paperclip"></i>
                            </span>
                          </div>
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="inputGroupFile01"
                              onChange={ImageUploadHandler}
                            />
                            <label
                              class="custom-file-label"
                              for="inputGroupFile01"
                            >
                              {filename}
                            </label>
                          </div>
                        </div>
                      </div>
                      {Object.keys(fileUpload).length > 0 && (
                        <Chip
                          label={fileUpload.name}
                          onClick={() => {
                            HandleImageDailogBox(fileUpload);
                          }}
                          onDelete={() => {
                            setFileUpload({});
                            setFileName("Add Attachment");
                          }}
                          color="primary"
                          variant="outlined"
                        />
                      )}

                      <Grid className="grid_main_div mt-3">
                        <Grid item md={3}>
                          {photuploadError && (
                            <span className="text-danger">
                              Upload the Profile Photo
                            </span>
                          )}
                        </Grid>
                        <Grid item md={4}></Grid>
                        <Grid item md={2}>
                          {/* {Object.keys(fileUpload).length > 0 && (
                            <Chip
                              label={fileUpload.name}
                              onClick={() => {
                                HandleImageDailogBox(fileUpload);
                              }}
                              onDelete={() => {
                                setFileUpload(null);
                              }}
                              color="primary"
                              variant="outlined"
                            />
                          )} */}
                        </Grid>
                      </Grid>
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <div className="mb-3">
                            <Button
                              variant="contained"
                              className="button_enquiry_details mr-2 button_login_decoration button_width"
                              onClick={createNewRoot}
                            >
                              {pageType === "EDIT" ? "Update" : "Submit"}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={HandleImageDailogBoxClose}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={HandleImageDailogBoxClose}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <span>
            <img
              src={baseUrl + "/" + imagepath}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </span>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(CreateNewRoute)));
