import { useApolloClient, useMutation } from "@apollo/client";
// import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import "../../../components/Admin/Transport/Transport.css";
import * as Service from "../../../services/index";
import {
  blankValidator,
  // changeTimeFormat,
  // getFullName,
} from "../../../utils/Validation.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
// import Loder from "../../Loder/Loder.jsx";
import MyEmployeTransport from "./MyEmployeTransport";

const EmployeeTransport = ({ navigate, ADMIN_DATA }) => {
  let institution_id = parseInt(ADMIN_DATA.institution_id);
  let userID = parseInt(ADMIN_DATA.userid);
  // -------------------api calls----------------------------
  // const [delete_Transport_route, {}] = useMutation(
  //   Service.delete_Transport_route
  // );
  const client = useApolloClient();
  // const classes = useStyles();
  // -------------------local state----------------------------
  const [isUpdated, setIsUpdated] = useState(false);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);
  // const [opendialogConfimation, setopendialogConfimation] = useState(false);
  // const [RouteId, setRouteId] = useState("");
  // const [search, setsearch] = useState("");
  // const [search2, setsearch2] = useState("");
  // const [routeNameDelete, setrouteNameDelete] = useState("");

  // filter method

  // const filterArr = RouteData.filter((event) => {
  //   if (!blankValidator(search)) {
  //     return (
  //       event.start_point.toLowerCase().indexOf(search2.toLowerCase()) !== -1
  //     );
  //   } else {
  //     return (
  //       event.route_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
  //     );
  //   }
  // });

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_Transport_RouteByDriver_Id,
          variables: {
            transport_emp_id: userID,
          },
        })
        .then((response) => {
          setisloading(false);
          if (blankValidator(response.data.getTransportRouteByDriverId))
            setRouteData(response.data.getTransportRouteByDriverId);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch err", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }

    return () => setIsUpdated(false);
  }, [isUpdated, institution_id, client]);

  // const DeleteTransportRoute = (ID) => {
  //   let id = parseInt(ID);
  //   try {
  //     delete_Transport_route({
  //       variables: { id },
  //     })
  //       .then((response) => {
  //         setIsUpdated(true);
  //         setisloading(false);

  //         setopendialogConfimation(false);
  //         alert(response.data.deleteTransportRoute.message);
  //       })
  //       .catch((error) => {
  //         setisloading(false);
  //         console.log("catch error", error);
  //       });
  //   } catch (error) {
  //     console.log("t and c error", error);
  //   }
  // };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={ADMIN_DATA.institution_name} />
            <MyEmployeTransport navigate={navigate}></MyEmployeTransport>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
// });

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(EmployeeTransport)));
