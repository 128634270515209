import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./Exam.css";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Paper from "@material-ui/core/Paper";
import { showNotificationMsz } from "../../../utils/Validation";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  ADD_GRADE_VALUE,
  GET_GRADE_VALUE,
  DELETE_GRADE_VALUE,
  UPDATE_GRADE_VALUE,
} from "../../../services/index";
import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";

function CreateGrading(props) {
  const { institution_name, institution_id } = props.location.state;
  //-------------------API------------------------------
  const client = useApolloClient();
  const [addGradeValueAPI, {}] = useMutation(ADD_GRADE_VALUE);
  const [deleteGradeAPI, {}] = useMutation(DELETE_GRADE_VALUE);
  const [updateGradeAPI, {}] = useMutation(UPDATE_GRADE_VALUE);
  //-------------------API------------------------------
  const classes = useStyles();
  const [uGradeId, setUGradeId] = useState(null);
  const [uMinMarks, setUMinMarks] = useState("");
  const [uMaxMarks, setUMaxMarks] = useState("");
  const [uGrade, setUGrade] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDlg, setShowDeleteDlg] = useState(false);
  const [showUpdateDlg, setShowUpdateDlg] = useState(false);
  const [dMarkGradeValue, setDMarkGradeValue] = useState("");
  const [dGradeValueId, setDGradeValueId] = useState(null);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [gradeValue, setGradeValue] = useState("");
  const [gradeArr, setGradeArr] = useState([]);
  const [gradeTypeValue, setGradeTypeValue] = useState(false);
  const [minMarkErr, setMinMarkErr] = useState(false);
  const [maxMarkErr, setMaxMarkErr] = useState(false);
  const [gradeValueErr, setGradeValueErr] = useState(false);
  const [markGradeArr, setMarkGradeArr] = useState([]);
  const gradeTypeArr = [
    { id: 11, gradeTypeName: "Percentage" },
    { id: 12, gradeTypeName: "Grade Points" },
  ];
  useEffect(() => {
    getMarkValue();
  }, []);

  const getMarkValue = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: GET_GRADE_VALUE,
        variables: {
          institution_id: +institution_id,
        },
      });
      if (data && data.getMarkGrade) {
        setMarkGradeArr(data.getMarkGrade);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const selectGradeTypeHandler = (grade) => {
    if (+grade === 12) {
      setGradeTypeValue(true);
    } else {
      setGradeTypeValue(false);
    }
  };
  const gradeValueHandler = async () => {
    try {
      if (!min || !max || !gradeValue) {
        if (!min) setMinMarkErr(true);
        if (!max) setMaxMarkErr(true);
        if (!gradeValue) setGradeValueErr(true);
        return;
      }

      setIsLoading(true);
      const { data } = await addGradeValueAPI({
        variables: {
          institution_id: +institution_id,
          name: gradeValue,
          start_mark: +min,
          end_mark: +max,
        },
      });
      if (data && data.addMarkGrade) {
        const { message, errorCode } = data.addMarkGrade;
        let mszType = errorCode === 0 ? "success" : "danger";
        showNotificationMsz(message, mszType);

        getMarkValue();
        setGradeArr([]);
      }
      setIsLoading(false);
    } catch (error) {
      showNotificationMsz("Something went wrong", "danger");
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const openConfirmationHandler = (args) => {
    if (args) {
      setDGradeValueId(args.id);
      setDMarkGradeValue(args.name);
      setShowDeleteDlg(true);
    }
  };
  const openEditHandler = (gradeData) => {
    const { name, start_mark, end_mark, id } = gradeData;
    setUGradeId(id);
    setUGrade(name);
    setUMinMarks(start_mark);
    setUMaxMarks(end_mark);
    setShowUpdateDlg(true);
  };
  const deleteGradeValueHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteGradeAPI({
        variables: { id: +dGradeValueId },
      });
      if (data && data.deleteMarkGrade) {
        const { message, errorCode } = data.deleteMarkGrade;
        let mszType = errorCode === 0 ? "success" : "danger";
        showNotificationMsz(message, mszType);
        setShowDeleteDlg(false);
        getMarkValue();
      }
      setIsLoading(false);
    } catch (error) {
      showNotificationMsz("Something went wrong", "danger");
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const updateGradHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await updateGradeAPI({
        variables: {
          id: +1,
          name: uGrade,
          start_mark: +uMinMarks,
          end_mark: +uMaxMarks,
        },
      });
      if (data && data.updateMarkGrade) {
        const { message, errorCode } = data.updateMarkGrade;
        let mszType = errorCode === 0 ? "success" : "danger";
        showNotificationMsz(message, mszType);
        setShowUpdateDlg(false);
        getMarkValue();
      }
      setIsLoading(false);
    } catch (error) {
      showNotificationMsz("Something went wrong", "danger");
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Create Grading</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="">
                              <label>Grade Type</label>
                              <select
                                className="form-control "
                                onChange={(event) => {
                                  selectGradeTypeHandler(event.target.value);
                                }}
                              >
                                {gradeTypeArr.map((grade) => (
                                  <option value={grade.id}>
                                    {grade.gradeTypeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                        {gradeTypeValue && (
                          <>
                            <div className="mt-3">
                              <Grid container spacing={2}>
                                <Grid item md={3}>
                                  <label for="exampleInputEmail1">
                                    Minimum Marks
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Min Marks"
                                    autoComplete="off"
                                    value={min}
                                    onChange={(e) => {
                                      if (minMarkErr) setMinMarkErr(false);
                                      setMin(e.target.value);
                                    }}
                                  />
                                  {minMarkErr && (
                                    <p style={{ color: "red" }}>
                                      Please enter min marks
                                    </p>
                                  )}
                                </Grid>
                                <Grid item md={3}>
                                  <label for="exampleInputEmail1">
                                    Maximum Marks
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Max Marks"
                                    autoComplete="off"
                                    value={max}
                                    onChange={(e) => {
                                      if (maxMarkErr) setMaxMarkErr(false);
                                      setMax(e.target.value);
                                    }}
                                  />
                                  {maxMarkErr && (
                                    <p style={{ color: "red" }}>
                                      Please enter max marks
                                    </p>
                                  )}
                                </Grid>
                                <Grid item md={3}>
                                  <label for="exampleInputEmail1">Grade</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Grade "
                                    autoComplete="off"
                                    value={gradeValue}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      const re = /^[A-Za-z_@./#&+-]+$/;
                                      if (val === "" || re.test(val)) {
                                        if (gradeValueErr)
                                          setGradeValueErr(false);
                                        setGradeValue(val);
                                      }
                                    }}
                                  />
                                  {gradeValueErr && (
                                    <p style={{ color: "red" }}>
                                      Please enter grade value
                                    </p>
                                  )}
                                </Grid>
                                <Grid item md={3}>
                                  <div style={{ marginTop: 30 }}>
                                    <Button
                                      variant="contained"
                                      className="common_button"
                                      onClick={gradeValueHandler}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {gradeTypeValue && (
                      <div
                        style={{
                          marginLeft: "2%",
                          marginRight: "2%",
                          marginTop: "2%",
                        }}
                      >
                        <Card>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Minimun Marks
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Maximum Marks
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Grade
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {markGradeArr.map((mark) => (
                                  <StyledTableRow key={mark.id}>
                                    <StyledTableCell align="center">
                                      {mark.start_mark}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {mark.end_mark}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {mark.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <i
                                        className="fa fa-pencil cursor edit_topic_icon_color"
                                        onClick={() => openEditHandler(mark)}
                                      ></i>{" "}
                                      <i
                                        className="fa fa-trash cursor edit_topic_icon_color"
                                        onClick={() =>
                                          openConfirmationHandler(mark)
                                        }
                                      ></i>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
      <Dialog
        open={showDeleteDlg}
        onClose={() => {
          setShowDeleteDlg(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setShowDeleteDlg(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the mark grade : {dMarkGradeValue}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="common_button"
                  onClick={deleteGradeValueHandler}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="common_button mr-2"
                  onClick={() => {
                    setShowDeleteDlg(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={showUpdateDlg}
        onClose={() => {
          setShowUpdateDlg(false);
        }}
      >
        <div className="dailog_feesubtype_setup">
          <div className="d-flex justify-content-end mr-3 p-3">
            <i
              class="fa fa-times fa-sm cursor"
              onClick={() => {
                setShowUpdateDlg(false);
              }}
            ></i>
          </div>
          <div className="update_mark_container">
            <label>Minimum Marks</label>
            <input
              type="text"
              class="form-control"
              placeholder="Minimum Marks"
              value={uMinMarks}
              onChange={(event) => {
                setUMinMarks(event.target.value);
              }}
            />

            <label>Maximum Marks</label>
            <input
              type="text"
              class="form-control"
              placeholder="Maximum Marks"
              value={uMaxMarks}
              onChange={(event) => {
                setUMaxMarks(event.target.value);
              }}
            />
            <label>Grade</label>
            <input
              type="text"
              class="form-control"
              placeholder="Grade"
              value={uGrade}
              onChange={(event) => {
                setUGrade(event.target.value);
              }}
            />
            <div className="text-center mt-3">
              <Button
                variant="contained"
                className="common_button"
                onClick={updateGradHandler}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default (HOC1(CreateGrading));
