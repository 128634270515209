import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
//import "./Notice.css";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Holiday from "../Holiday/Holiday.jsx";
import { useMutation } from "@apollo/client";
import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import { timeStampToDate, getTime } from "../../../utils/Validation.js";
//------------------------------redux---------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function CommonNotifications(props) {
  const { login_id } = props.data;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [mailNotificationArr, setMailNotificationArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [isUnderLine, setIsUnderLine] = useState(true);
  const [sentIsUnderLine, setSentIsUnderLine] = useState(false);
  const [headerMessage, setHeaderMessage] = useState(false);

  const parse = require("html-react-parser");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [Read_Mail, {}] = useMutation(Service.Read_Mail);

  useEffect(() => {
    getMailIninbox("Inbox");
  }, [props]);

  const getMailIninbox = async (mailType) => {
    try {
      setisloading(true);
      const { data } = await Read_Mail({
        variables: {
          user: login_id,
          mailBox_type: mailType,
        },
      });
      if (data && data.readMail) {
        setMailNotificationArr(data.readMail);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };
  const mailHandler = (mailType) => {
    setIsUnderLine(true);
    setSentIsUnderLine(false);
    getMailIninbox(mailType);
    setHeaderMessage(false);
  };
  const mailHandlerSend = (mailType) => {
    setSentIsUnderLine(true);
    setIsUnderLine(false);
    getMailIninbox(mailType);
    setHeaderMessage(true);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="enquiry_heading">
                          {headerMessage === false ? (
                            <div className=" mt-2 Heading_bold">
                              Email Inbox
                            </div>
                          ) : (
                            <div className=" mt-2 Heading_bold">Email Sent</div>
                          )}

                          <div className="ml-3 mt-2 ">
                            <span
                              className={
                                isUnderLine
                                  ? "common_notifctaion_active openassignemntcolor mr-2"
                                  : "openassignemntcolor mr-2"
                              }
                              onClick={() => {
                                mailHandler("Inbox");
                              }}
                            >
                              Inbox
                            </span>
                            <span
                              className={
                                sentIsUnderLine
                                  ? " common_notifctaion_active openassignemntcolor mr-2"
                                  : "openassignemntcolor mr-2"
                              }
                              onClick={() => {
                                mailHandlerSend("Sent");
                              }}
                            >
                              Sent
                            </span>
                            {/* <span className="openassignemntcolor mr-2">
                              Trash
                            </span> */}
                            <span
                              onClick={() =>
                                props.navigate("/notificationComposed")
                              }
                              className="openassignemntcolor"
                            >
                              Compose
                            </span>
                          </div>
                        </div>

                        <div className="enquiry_heading">
                          <div className="mt-2 Heading_bold">
                            <div>
                              <span>
                                {" "}
                                <Checkbox />
                              </span>
                              <span>
                                <Button
                                  variant="contained"
                                  className="default button_login_decoration notice_text_tranformation"
                                >
                                  Delete
                                </Button>
                              </span>
                            </div>
                          </div>
                          <div className="ml-3 mt-2 d-flex ">
                            <span className="mr-2">
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                              />
                            </span>
                            <span>
                              <Button
                                variant="contained"
                                className="button_login_decoration notice_text_tranformation"
                              >
                                <i class="fa fa-filter"></i>Filter
                              </Button>
                            </span>
                          </div>
                        </div>

                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? mailNotificationArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : mailNotificationArr
                                ).map((row, index) => (
                                  <StyledTableRow
                                    key={index}
                                    className="transition_onHover"
                                  >
                                    <StyledTableCell
                                      align="left"
                                      padding="checkbox"
                                    >
                                      <Checkbox />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "70%",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            props.navigate(
                                              "/notificationOpened",
                                              { state: { emailDetails: row } }
                                            );
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontWeight: !row.isRead
                                                ? "bold"
                                                : "normal",
                                            }}
                                          >
                                            {" "}
                                            {row && row.subject}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginRight: 7,
                                              fontWeight: !row.isRead
                                                ? "bold"
                                                : "normal",
                                            }}
                                          >
                                            {timeStampToDate(row.date)},
                                            {getTime(row.date)}
                                          </p>
                                          <p style={{ marginRight: 7 }}>
                                            <i class="fa fa-tag"></i>
                                          </p>

                                          <p style={{ marginRight: 7 }}>
                                            <i class="fa fa-edit"></i>
                                          </p>
                                          <p style={{ marginRight: 7 }}>
                                            <i class="fa fa-trash"></i>
                                          </p>
                                        </div>
                                      </div>

                                      {/* <div>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                           props.navigate(
                                              "/notificationOpened",
                                              { emailDetails: row }
                                            )
                                          }
                                        >
                                          {row.subject}
                                        </span>

                                        <span className="float-right">
                                          <div className="d-flex ">
                                            <span className="mr-2">
                                              {timeStampToDate(row.date)},
                                              {getTime(row.date)}
                                            </span>
                                            <span className="mr-2">
                                              <i class="fa fa-tag"></i>
                                            </span>
                                            <span className="mr-2">
                                              {" "}
                                              <i class="fa fa-edit"></i>
                                            </span>
                                            <span style={{ cursor: "pointer" }}>
                                              {" "}
                                              <i class="fa fa-trash"></i>
                                            </span>
                                          </div>
                                        </span>
                                      </div> */}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={mailNotificationArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(CommonNotifications));
