import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./AssignmentAssign.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder";
import { store } from "react-notifications-component";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  serverFormatDate,
  currentDayForMinMAx,
  getValue,
  timeStampToDate,
  showNotificationMsz,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const AssignmentAssign = (props) => {
  const [isloading, setisloading] = useState(false);

  const [Assign_Assigment_ToAClassSection, {}] = useMutation(
    Service.Assign_Assigment_ToAClassSection
  );

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listofsection, setlistofsection] = useState([]);
  const client = useApolloClient();

  const [end_date, setend_date] = useState("");
  const [startdate, setstartdate] = useState("");
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [gradeID, setgradeID] = useState(-1);
  const [sectionId, setSectionId] = useState("");
  const [isUpdated, setisUpdated] = useState(false);
  const [gradeIdError, setgradeIdError] = useState(false);
  const [sectionIdError, setsectionIdError] = useState(false);
  const [StartdateError, setStartdateError] = useState(false);
  const [EndDateError, setEndDateError] = useState(false);

  const [totalScore, settotalScore] = useState(0);
  const [name, setname] = useState("");

  const [assignment_id, setassignment_id] = useState(-1);

  useEffect(() => {
    getdata();
    getManageGrades(parseInt(props.dataacademic.id));
    getListOfSectionByMyAssignment(parseInt(props.location.state.item.id));
  }, [props, client, assignment_id, isUpdated]);

  const getdata = async () => {
    if (blankValidator(props.location.state.item)) {
      await settotalScore(parseInt(props.location.state.item.total_score));
      await setname(props.location.state.item.name);
      await setassignment_id(parseInt(props.location.state.item.id));
    }
  };
  const changeSection = (val) => {
    let seciontId = parseInt(val);
    setSectionId(seciontId);
  };

  const assignClassAssignment = () => {
    if (!blankValidator(gradeID) || gradeID === -1) {
      setgradeIdError(true);
      return;
    }
    if (!blankValidator(sectionId) || sectionId === -1) {
      setsectionIdError(true);
      return;
    }

    if (!blankValidator(startdate)) {
      setStartdateError(true);
      return;
    }
    if (!blankValidator(end_date)) {
      setEndDateError(true);
      return;
    }
    let section_id = parseInt(sectionId);
    try {
      setisloading(true);
      Assign_Assigment_ToAClassSection({
        variables: {
          assignment_id,
          section_id: section_id,
          start_date: startdate,
          end_date: end_date,
          total_score: totalScore,
          date: serverFormatDate(new Date()),
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.AssignAssigmentToAClassSection.message,
            "success"
          );
          setisloading(false);

          setgradeID(-1);
          setSectionId(-1);
          setstartdate("");
          setend_date("");
          setSectionArr([]);
          setisUpdated(!isUpdated);
        },
        (err) => {
          console.log("then error", err);
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const getManageGrades = async (id) => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: id,
          },
        })
        .then(async (response) => {
          await setGradeArr(response.data.getGrade);
          setisloading(false);
        })
        .catch((e) => {
          console.log("catch error", e);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const getListOfSectionByMyAssignment = async (id) => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_SectionList_By_Assignment,
          variables: {
            assignment_id: id,
          },
        })
        .then(async (response) => {
          await setlistofsection(response.data.getClassAssignmentbyAssigmentId);
          setisloading(false);
        })
        .catch((e) => {
          console.log("catch error", e);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const changeGrade = (val) => {
    let grade_id = parseInt(val);
    setgradeID(grade_id);
    if (grade_id == -1) {
      setSectionArr([]);
    }

    try {
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then(
          (response) => {
            setSectionArr(response.data.getSectionAndGrade);
          },
          (err) => console.log("then err", err)
        )
        .catch((err) => console.log("catch err", err));
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">{name}</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-3">
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}>
                              <div className="inputMarginright mt-1">
                                <label for="exampleInputEmail1">Class</label>
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  value={gradeID}
                                  onChange={(e) => {
                                    setgradeIdError(false);
                                    changeGrade(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Class</option>

                                  {gradeArr.length > 0 &&
                                    gradeArr.map((item, index) => (
                                      <option value={item.id}>
                                        {item.gradeName}
                                      </option>
                                    ))}
                                </select>
                                {gradeIdError && (
                                  <span className="text-danger">
                                    Select Class Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className=" mt-1">
                                <label for="exampleInputEmail1">Section</label>
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  value={sectionId}
                                  onChange={(e) => {
                                    setsectionIdError(false);
                                    changeSection(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Section</option>

                                  {sectionArr.length > 0 &&
                                    sectionArr.map((item, index) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                                {sectionIdError && (
                                  <span className="text-danger">
                                    Select Section Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid className="grid_main_div mt-1">
                            <Grid item md={6}>
                              <div className="inputMarginright ">
                                <label for="exampleInputEmail1">
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="564946646"
                                  autoComplete="off"
                                  min={currentDayForMinMAx()}
                                  value={startdate}
                                  onChange={(e) => {
                                    setStartdateError(false);
                                    setstartdate(e.target.value);
                                  }}
                                />
                                {StartdateError && (
                                  <span className="text-danger">
                                    Enter Start Date
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <label for="exampleInputEmail1">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="564946646"
                                autoComplete="off"
                                min={startdate}
                                value={end_date}
                                onChange={(e) => {
                                  setEndDateError(false);
                                  setend_date(e.target.value);
                                }}
                              />
                              {EndDateError && (
                                <span className="text-danger">
                                  Enter End Date
                                </span>
                              )}
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div mt-3 mb-2">
                            <Grid item md={10}></Grid>
                            <Grid item md={2}>
                              {" "}
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={assignClassAssignment}
                              >
                                Assign
                              </Button>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End End
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? listofsection.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : listofsection
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {getValue(row.section?.grade?.gradeName)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {getValue(row.section?.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {timeStampToDate(row.start_date)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {timeStampToDate(row.end_date)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {getValue(row.status)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={listofsection.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(AssignmentAssign));
