import React, { useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";
import Holiday from "../../Common/Holiday/Holiday.jsx";

function ManageRole(props) {
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [name, setname] = useState("");
  const [managerole, setmanagerole] = useState([
    { name: "Admin", show: true },
    { name: "Teacher", show: true },
    { name: "Parent", show: true },
    { name: "Student", show: true },
    { name: "Staff", show: true },
    { name: "Visitor", show: true },
  ]);

  const handleaddMangeopen = () => {
    setaddMangeopen(true);
  };
  const closeaddMangeopen = () => {
    setaddMangeopen(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Manage Role</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {addMangeopen === false ? (
                          <div className="d-flex" onClick={handleaddMangeopen}>
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add New Role
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className=" mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div
                                          className="text-right"
                                          onClick={closeaddMangeopen}
                                        >
                                          <span className="iconstoaddfee">
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        </div>
                                        <div className="text_filed_heading">
                                          Role Name
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control "
                                            id="exampleFormControlInput1"
                                            placeholder="Name"
                                            autoComplete="off"
                                            value={name}
                                            onChange={(e) => {
                                              setname(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className="mb-2 ">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={() => {
                                            managerole.push({
                                              name: name,
                                              show: true,
                                            });
                                            setmanagerole([...managerole]);
                                            setname("");
                                          }}
                                        >
                                          Create
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin cardheight_overflow">
                        {/* <div className="ml-3 mt-2 Heading_bold">Role List</div> */}
                        {managerole.map((item, index) => (
                          <Card className="cardvisible_border mb-2">
                            <div className="card_admissiondetails_height">
                              <div className="textfiled_margin">
                                <div className="">
                                  <Grid className="grid_main_div">
                                    <Grid item md={6}>
                                      <div className=" mt-1 mb-1">
                                        <input
                                          type="text"
                                          className="form-control input_border_none department_color_disabled"
                                          id="exampleFormControlInput1"
                                          placeholder="Name"
                                          // autoComplete="off"
                                          value={managerole[index].name}
                                          onChange={(e) => {
                                            managerole[index].name =
                                              e.target.value;
                                            setmanagerole([...managerole]);
                                          }}
                                          disabled={item.show}
                                          onBlur={() => {
                                            managerole[index].show = true;
                                            setmanagerole([...managerole]);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item md={5}></Grid>
                                    <Grid item md={1}>
                                      {" "}
                                      <div className="d-flex">
                                        <span className="iconstoaddfee mr-2 ml-1">
                                          <i
                                            className="fa fa-pencil"
                                            onClick={() => {
                                              managerole[index].show = false;
                                              setmanagerole([...managerole]);
                                            }}
                                          ></i>
                                        </span>
                                        <span className="iconstoaddfee ml-2">
                                          <i
                                            className="fa fa-trash"
                                            onClick={() => {
                                              managerole.splice(index, 1);
                                              setmanagerole([...managerole]);
                                            }}
                                          ></i>
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default (HOC1(ManageRole));
