import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  blankValidator,
  dateFormatToShow,
  showNotificationMsz,
} from "../../../utils/Validation";
import moment from "moment";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Loder from "../../Loder/Loder.jsx";
import Monday from "../Report/calendar/Monday.jsx";

const TimeTable = ({ manageInstitution, navigate, location }) => {
  const { institution_id, academic_session_id, institution_name } =
    manageInstitution;

  const { row, classselectedid, sectionselectedid, classId, secId } =
    location.state;
  const client = useApolloClient();
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  const [isTimeTableShown, setIsTimeTableShown] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [sectionId, setSectionId] = useState(
    sectionselectedid == undefined ? -1 : sectionselectedid
  );
  const [gradeId, setGradeId] = useState(
    classselectedid == undefined ? -1 : classselectedid
  );
  const [managegrade, setmanagegrade] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getManageGrades();
    sectionHandler(sectionId);
  }, [sectionId]);

  const getManageGrades = () => {
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_id),
          },
        })
        .then(async (response) => {
          const classArr = response.data.getGrade;
          await setmanagegrade(classArr);

          if (blankValidator(classId)) classHandler(classId, true);
        })
        .catch((e) => console.log("catch error", e));
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const sectionHandler = async (sectionId) => {
    try {
      if (sectionId === -1) {
        setIsTimeTableShown(false);
        return;
      }
      setSectionId(sectionId);

      var todayDate = new Date().toJSON().slice(0, 10);
      const { data } = await client.query({
        query: Service.Get_Id_For_Teacher,
        variables: {
          institution_id,
          section_id: +sectionId,
          date: todayDate,
        },
      });
      if (data && data.getClassTimetableID && data.getClassTimetableID.id) {
        setIsTimeTableShown(true);
      } else {
        setIsTimeTableShown(false);
      }
    } catch (error) {}
  };

  const classHandler = async (classId, isfirstTime) => {
    try {
      await setGradeId(parseInt(classId));
      if (!isfirstTime) {
        setSectionArr([]);
        setSectionId(-1);
      }
      if (classId === -1) {
        setIsTimeTableShown(false);
        return;
      }
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then(async (response) => {
          await setSectionArr(response.data.getSectionAndGrade);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 Heading_bold mt-2">Time Table</div>
                  <div className="mr-3 mt-3">
                    {`${dateFormatToShow(startOfWeek)}-${dateFormatToShow(
                      endOfWeek
                    )}`}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div
                      className="card_admissiondetails_height"
                      style={{ paddingBottom: 25 }}
                    >
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <label> Class</label>
                              <select
                                value={gradeId}
                                className="form-control "
                                onChange={(e) =>
                                  classHandler(e.target.value, false)
                                }
                              >
                                <option value={-1}>Select Class</option>
                                {managegrade.map((item) => (
                                  <option value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className=" mt-1">
                              <label>Select Section</label>
                              <select
                                value={sectionId}
                                className="form-control "
                                onChange={(e) => setSectionId(e.target.value)}
                              >
                                <option value={-1}>Select section</option>
                                {sectionArr.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <Card className="mt-2 pt-3 pb-3">
                    {isTimeTableShown ? (
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <div className="mt-3 mb-2">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              indicatorColor="primary"
                              textColor="primary"
                              left
                            >
                              <Tab
                                label="Monday"
                                {...a11yProps(0)}
                                className=" text-capitalize  "
                                style={{ minWidth: 130 }}
                              />
                              <Tab
                                label="Tuesday"
                                {...a11yProps(1)}
                                className=" text-capitalize "
                                style={{ minWidth: 130 }}
                              />
                              <Tab
                                label="Wednesday"
                                {...a11yProps(2)}
                                className=" text-capitalize "
                                style={{ minWidth: 130 }}
                              />
                              <Tab
                                label="Thursday"
                                {...a11yProps(3)}
                                className=" text-capitalize "
                                style={{ minWidth: 130 }}
                              />
                              <Tab
                                label="Friday"
                                {...a11yProps(4)}
                                className=" text-capitalize "
                                style={{ minWidth: 130 }}
                              />
                              <Tab
                                label="Saturday"
                                {...a11yProps(5)}
                                className=" text-capitalize "
                                style={{ minWidth: 130 }}
                              />
                              <Tab
                                label="Sunday"
                                {...a11yProps(6)}
                                className=" text-capitalize "
                                style={{ minWidth: 130 }}
                              />
                            </Tabs>
                          </div>

                          <TabPanel value={value} index={0}>
                            <Monday
                              sectionId={sectionId}
                              day={1}
                              name="Monday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={1}>
                            <Monday
                              sectionId={sectionId}
                              day={2}
                              name="Monday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={2}>
                            <Monday
                              sectionId={sectionId}
                              day={3}
                              name="Monday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={3}>
                            <Monday
                              sectionId={sectionId}
                              day={4}
                              name="Monday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={4} classes="">
                            <Monday
                              sectionId={sectionId}
                              day={5}
                              name="Monday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={5}>
                            <Monday
                              sectionId={sectionId}
                              day={6}
                              name="Monday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>
                          <TabPanel value={value} index={6} classes="">
                            <Monday
                              sectionId={sectionId}
                              day={7}
                              name="Sunday"
                              seeker={"CLASSESS"}
                            />
                          </TabPanel>
                        </div>
                      </div>
                    ) : (
                      <div className="float-right pr-2">
                        <Card
                          className="p-2  card_color"
                          onClick={() =>
                            navigate("/classtimetabletpenassignlink")
                          }
                        >
                          Assign TimeTable
                        </Card>
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(TimeTable)));
