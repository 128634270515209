import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  getcurrentDate,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  timeStampToDate,
  timeStampToDatedash,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { set } from "lodash";

const AdminSchoolNotice = (props) => {
  //instituiion id
  const [Delete_Notice_API, {}] = useMutation(Service.Delete_Notice_API);
  let institution_id = parseInt(props.data.institution_id);

  //name instituition

  let institution_Name = props.data.institution_name;

  const client = useApolloClient();

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [noticecancelid, setnoticecancelid] = useState(-1);
  const [noticename, setnoticename] = useState("");

  useEffect(() => {
    getStudentNoticeBoardData();
  }, [client, institution_id]);

  const getStudentNoticeBoardData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_School_Notice_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);

          let temp = [];

          for (
            let index = 0;
            index < response.data.getNoticeBoardByInstitutionId.length;
            index++
          ) {
            const element = response.data.getNoticeBoardByInstitutionId[index];
            // let date1 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(new Date());

            // let date2 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.expiry_date);

            let date1 = new Date();
            let date2 = getcurrentDate(element.expiry_date);
            temp.push({
              id: element.id,
              title: element.title,
              subject: element.subject,
              publish_date: element.publish_date,
              expiry_date: element.expiry_date,
              admin: element.admin,
              status: date1 > date2 ? "EXPIRED" : element.status,
              content: element.content,
              documents: element.documents,
            });
          }
          setSchoolnoticedata(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("parent homepage t and c error", error);
    }
  };

  const [search, setSearch] = useState("");

  const filterSchoolNotice = Schoolnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const OpenCancelDailog = (data) => {
    setnoticename(data.title);
    setnoticecancelid(data.id);
    setopendialogConfimation(true);
  };

  const deleteNotice = async (eventId) => {
    let id = parseInt(eventId);
    setisloading(true);
    await Delete_Notice_API({
      variables: { id },
    }).then((data) => {
      showNotificationMsz(data?.data?.deleteNotice?.message, "success");
    });
    setisloading(false);
    getStudentNoticeBoardData();

    setopendialogConfimation(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">School Notice</div>
                  <div className="mt-3 mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={() =>
                        props.navigate("/createSchoolNotice", {
                          state: {
                            pageType: "Add",
                          },
                        })
                      }
                    >
                      Create Notice
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-3">
                          <Grid className="grid_main_div mt-2 mb-2">
                            <Grid item md={3}>
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Search By Title"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Title
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Publish Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Expiry Date
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Publisher Name
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? filterSchoolNotice.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : filterSchoolNotice
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.title) ? (
                                        "No Data"
                                      ) : (
                                        <span>
                                          {row.title.substring(0, 20)}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.subject) ? (
                                        "No Data"
                                      ) : (
                                        <span>
                                          {row.subject.substring(0, 20)}
                                        </span>
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {!blankValidator(row.publish_date) ? (
                                        "No Data"
                                      ) : (
                                        <span>
                                          {timeStampToDatedashDayStart(
                                            row.publish_date
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.expiry_date) ? (
                                        "No Data"
                                      ) : (
                                        <span>
                                          {timeStampToDatedashDayStart(
                                            row.expiry_date
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {getFullName(row.admin?.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.status) ? (
                                        "No Data"
                                      ) : (
                                        <span>{row.status}</span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <div className="d-flex justify-content-center">
                                        <span
                                          className="row_details_color pr-2"
                                          onClick={() =>
                                            props.navigate("/noticeDetail", {
                                              state: {
                                                item: row,
                                                type: "SCHOOLNOTICE",
                                                institution_Name:
                                                  institution_Name,
                                              },
                                            })
                                          }
                                        >
                                          View
                                        </span>
                                        |
                                        <span
                                          className="row_details_color pl-2 pr-2"
                                          onClick={() =>
                                            props.navigate(
                                              "/createSchoolNotice",
                                              {
                                                state: {
                                                  pageType: "Edit",
                                                  item: row,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          Edit{""}
                                        </span>
                                        {row.status === "EXPIRED" ? (
                                          ""
                                        ) : (
                                          <div>
                                            |{""}
                                            <span
                                              className="row_details_color pl-2"
                                              onClick={() =>
                                                OpenCancelDailog(row)
                                              }
                                            >
                                              Delete
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filterSchoolNotice.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to cancel the Notice : {noticename}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteNotice(noticecancelid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminSchoolNotice));
