import React, { useState, useEffect } from "react";
import "./Onboarding.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import {
  getValue,
  blankValidator,
  showNotificationMsz,
  getFullName,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useMutation } from "@apollo/client";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const AdminStudentListView = (props) => {
  const [WithDraw_Students_API, {}] = useMutation(
    Service.WithDraw_Students_API
  );
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [managegrade, setmanagegrade] = useState([]);
  const [StudentsArr, setStudentsArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  // global academic session id
  let academic_session_id = parseInt(props.data.academic_session_id);

  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  let Organization_Name = props.data.institution_name;

  const [sectionArr, setSectionArr] = useState([]);
  const [UserId, setUserId] = useState(-1);
  const [sectionId, setsectionId] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);
  const [gradeName, setgradeName] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const [deleteStudentName, setDeleteStudentName] = useState("");
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  //assign Section
  const [Assign_Section_Student_API, {}] = useMutation(
    Service.Assign_Section_Student_API
  );

  const client = useApolloClient();
  useEffect(() => {
    window.scrollTo(0, 0);
    const status = [
      { status: "Form Submission Pending" },
      { status: "Verification Pending" },
      { status: "Enrolled" },
      { status: "ACTIVE" },
      { status: "INACTIVE" },
    ];

    const getStudentList = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Assign_Student_List,
            variables: {
              institution_id,
              status: status,
            },
          })
          .then((response) => {
            const arr = response.data.getStudentByStatus;
            if (arr && arr.length > 0) {
              let temp = [];
              arr.map((item) => {
                if (item.status === "INACTIVE") {
                  item.status = "WITHDRAWN";
                }
                temp.push(item);
              });

              setStudentsArr(temp);
            }
            setisloading(false);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }

      try {
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setmanagegrade(response.data.getGrade);
          })
          .catch((e) => console.log("catch error", e));
      } catch (error) {
        console.log("t and c error", error);
      }
    };

    getStudentList();
  }, [client, institution_id, academic_session_id, isUpdated]);

  const [searchname, setsearchname] = useState("");

  const [searchstatus, setsearchstatus] = useState("");

  const [gradeid, setgradeid] = useState(-1);

  const filterByName = StudentsArr.filter((item) => {
    return (
      getFullName(item?.student_profile?.name)
        .toLowerCase()
        .indexOf(searchname.toLowerCase()) !== -1 &&
      item.status.toLowerCase().indexOf(searchstatus.toLowerCase()) !== -1 &&
      (gradeid != -1
        ? blankValidator(item.section) && item.section?.grade?.id == gradeid
        : true)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const OpenAssignSectionDailog = (data, user_id) => {
    if (!blankValidator(data) || !blankValidator(user_id)) {
      alert("Please Contact To Admin");
      return;
    }
    let classId = parseInt(data.id);

    let UserID = parseInt(user_id);

    setUserId(UserID);
    setgradeName(data.gradeName);
    if (classId == -1) {
      setSectionArr([]);
      return;
    }
    setisloading(true);
    client
      .query({
        query: Service.GET_Section_Data,
        variables: {
          grade_id: parseInt(classId),
        },
      })
      .then((response) => {
        const sectionData = response.data.getSectionAndGrade;
        setSectionArr(sectionData);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });
    setOpenDialog(true);
  };

  const selectSectionHandler = (Id) => {
    let id = parseInt(Id);
    if (id === -1) {
      setsectionId(-1);
      return;
    }
    setsectionId(id);
  };
  const AssignSectionToStudent = () => {
    let userIdArr = [];

    userIdArr.push({
      id: parseInt(UserId),
    });

    try {
      Assign_Section_Student_API({
        variables: { id: userIdArr, section_id: parseInt(sectionId) },
      })
        .then((response) => {
          setOpenDialog(false);
          setIsUpdated(!isUpdated);

          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const editSchoolForm = (studentId, status) => {
    // if (status === "Verification Pending") {
    //  props.navigate("/viewstudentform", {
    //     studentId,
    //   });
    // } else {
    props.navigate("/studentDetailForm", {
      state: {
        studentId,
        status,
      },
    });
    // }
  };
  // const classHandler = (className) => {
  //   setStudentsArr(classWiseFilterArr);
  //   setSearchClassname(className);
  //   if (className === "search") {
  //     setStudentsArr(classWiseFilterArr);
  //     return;
  //   } else {
  //     const filtArr = classWiseFilterArr.filter((arr) => {
  //       return arr.section?.grade?.gradeName === className;
  //     });
  //     setStudentsArr(filtArr);
  //   }
  // };
  const withdrawDataHandler = (deleteId, studentName) => {
    setDeleteId(deleteId);
    setDeleteStudentName(studentName);
    setOpenConfirmationBox(true);
  };
  const withdrawStudentHandler = () => {
    try {
      setisloading(true);
      WithDraw_Students_API({
        variables: { id: parseInt(deleteId) },
      })
        .then((response) => {
          setOpenConfirmationBox(false);
          try {
            setisloading(true);
            client
              .query({
                query: Service.GET_Student_form_list,
                variables: {
                  institution_id,
                },
              })
              .then((response) => {
                setStudentsArr(response.data.getStudentList.reverse());
                setisloading(false);
              })
              .catch((err) => {
                setisloading(false);
                console.log("catch err", err);
              });
          } catch (error) {
            setisloading(false);
            console.log("t and c error", error);
          }
          setisloading(false);
          setIsUpdated(true);
          if (response.data.deleteDepartment.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(
            response.data.deleteDepartment.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="enquiry_heading ">
                          <div className="mt-3 Heading_bold">
                            Search Student
                          </div>
                          <div className="mt-3">
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration"
                              onClick={() => {
                                props.navigate("/assignonboardstudent");
                              }}
                            >
                              Bulk class assignment
                            </Button>
                          </div>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search By Name"
                                autoComplete="off"
                                value={searchname}
                                onChange={(e) => {
                                  setsearchname(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright  mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search By Status"
                                autoComplete="off"
                                value={searchstatus}
                                onChange={(e) => {
                                  try {
                                    setsearchstatus(e.target.value);
                                  } catch (error) {
                                    setsearchstatus("");
                                  }
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright  mt-1">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setgradeid(e.target.value);
                                }}
                              >
                                <option value={-1}>Search by class</option>
                                {managegrade.length > 0 ? (
                                  managegrade.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))
                                ) : (
                                  <option>No data</option>
                                )}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                        <div className="mt-4 mb-4 Heading_bold">
                          List Of Students
                        </div>
                        <div className="table_foramtitng">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    ID
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Roll No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Student Name
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Parent Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {(rowsPerPage > 0
                                  ? filterByName.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : filterByName
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell
                                      align="left "
                                      className="adminstudentlistview"
                                    >
                                      {!blankValidator(row.student_profile?.login)
                                        ? ""
                                        : !blankValidator(row.student_profile?.login?.display_id)
                                        ? ""
                                        : row.student_profile?.login?.display_id}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="adminstudentlistview"
                                    >
                                      {row.section == null
                                        ? getValue(
                                            row.student_profile?.grade
                                              ?.gradeName
                                          )
                                        : row.section?.grade?.gradeName}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="adminstudentlistview"
                                    >
                                      {!blankValidator(row.section) ? (
                                        row.status === "Enrolled" && (
                                          <span
                                            className="row_details_color"
                                            onClick={() =>
                                              OpenAssignSectionDailog(
                                                row.student_profile.grade,
                                                row.id
                                              )
                                            }
                                          >
                                            Assign
                                          </span>
                                        )
                                      ) : (
                                        <span>
                                          {!blankValidator(row.section.name) ? (
                                            ""
                                          ) : (
                                            <span>{row.section.name}</span>
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="adminstudentlistview"
                                    >
                                      {getValue(row.roll_number)}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="adminstudentlistview"
                                    >
                                      {getFullName(row.student_profile?.name)}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="adminstudentlistview"
                                    >
                                      {getFullName(
                                        row.student_profile?.parent?.name
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="adminstudentlistview"
                                    >
                                      {row.status && row.status}
                                      {/* {row.status === "INACTIVE"
                                        ? "WITHDRAWN"
                                        : row.status} */}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <div className="d-flex">
                                        <span
                                          className="pr-1 row_details_color adminstudentlistview"
                                          onClick={() =>
                                            editSchoolForm(row.id, row.status)
                                          }
                                        >
                                          {row.status ===
                                          "Form Submission Pending"
                                            ? "Submit Form"
                                            : "Open"}
                                        </span>
                                        {row.status === "WITHDRAWN" ? (
                                          ""
                                        ) : (
                                          <span>|</span>
                                        )}
                                        {row.status === "WITHDRAWN" ? (
                                          ""
                                        ) : (
                                          <>
                                            <span
                                              className="pl-1 pr-1 row_details_color adminstudentlistview"
                                              onClick={async () =>
                                                withdrawDataHandler(
                                                  row.id,
                                                  await getFullName(
                                                    row.student_profile.name
                                                  )
                                                )
                                              }
                                            >
                                              Withdraw
                                            </span>{" "}
                                            |
                                            <span
                                              className="pl-1 row_details_color adminstudentlistview"
                                              onClick={async () =>
                                                props.navigate(
                                                  "/manageRollNo",
                                                  {
                                                    state: {
                                                      row,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Assign Roll number
                                            </span>
                                            |
                                            <span
                                              className="pl-2 row_details_color adminstudentlistview"
                                              onClick={async () =>
                                                props.navigate(
                                                  "/manageusersetting",
                                                  {
                                                    state: {
                                                      row,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Assign Login
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[25]}
                              component="div"
                              count={filterByName.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={openDialog}
        fullWidth="fullWidth"
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <div className="full_Size_Box">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpenDialog(false);
              }}
            ></i>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label htmlFor="">Select Class</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Search By Name"
                  autoComplete="off"
                  value={gradeName}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="">Select Section</label>
                <select
                  class="form-control"
                  onChange={(e) => selectSectionHandler(e.target.value)}
                >
                  <option value={-1}>Select Section</option>
                  {sectionArr.length !== 0 &&
                    sectionArr.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </Grid>
            </Grid>

            <div className="text-center mt-3">
              <Button
                variant="contained"
                className="add_new_query_btn button_login_decoration"
                onClick={AssignSectionToStudent}
              >
                Assign
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={openConfirmationBox} className="dailog_formatting">
        <div className="dailog_width_review_leave">
          <div className="cut_dailog_icon float-right">
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenConfirmationBox(false);
              }}
            ></i>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to withdraw : {deleteStudentName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={withdrawStudentHandler}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setOpenConfirmationBox(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminStudentListView));
