import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./MarkAttendance.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import userimage from "./userimage.png";

import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  isPresent,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation.js";

const MarkAttendanceHome = ({ TEACHER_DATA, navigate, data }) => {
  console.log("TEACHER_DATA", data);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  //----------------------------------global id-----------------------------
  var today_Date = new Date().toJSON().slice(0, 10);
  let academic_session_id = parseInt(TEACHER_DATA.id);
  //-----------------------------------api calls-----------------------------
  const client = useApolloClient();
  const [Teacher_Mark_Attendace_API, {}] = useMutation(
    Service.Teacher_Mark_Attendace_API
  );
  //-----------------------------------local state-----------------------------
  const [allChecked, setAllChecked] = useState(false);
  const [studentArr, setStudentArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [AttendanceHomeData] = useState([{ rollno: "1", name: "Mark Willy" }]);
  const [sectionId, setsectionId] = useState("");

  //-----------------------------------methods-----------------------------
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setGradeArr(response.data.getGrade);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }

    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, client, academic_session_id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const AttendanceView = () => {
    if (!blankValidator(sectionId) || sectionId === -1) {
      store.addNotification({
        title: "",
        message: "Select Section",
        type: "info",
        insert: "top-center",
        container: "top-center",
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      return;
    }
    navigate("/teacherAttendanceView", {
      state: {
        type: "CLASSATTENDENCE",
        id: sectionId,
      },
    });
  };
  const classHandler = (id) => {
    if (parseInt(id) === -1) {
      setAllChecked(false);
      setSectionArr([]);
      setStudentArr([]);
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setAllChecked(false);
          setSectionArr([]);
          setStudentArr([]);
          setSectionArr(response.data.getSectionAndGrade);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const sectionHandler = (id) => {
    try {
      if (id == -1) {
        setsectionId("");
        setStudentArr([]);
        return;
      }
      setsectionId(parseInt(id));
      setisloading(true);
      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: today_Date,
            end_date: today_Date,
            attendance_type: "CLASS",
            section_id: parseInt(id),
          },
        })
        .then((response) => {
          try {
            const studentArr = response.data.getAttendanceBySection;
            let temp = [];
            studentArr.map((item) => {
              let present__status = isPresent(today_Date, item.attendances);
              temp.push({
                isSelected: present__status === "Present" ? true : false,
                rollNo: item.roll_number,
                id: item.id,
                studentName: getFullName(item?.student_profile?.name),
              });
            });

            setStudentArr(temp);
          } catch (error) {
            console.log("Error", error);
          }
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const sortedList = studentArr.sort((a, b) =>
    a.studentName.localeCompare(b.studentName)
  );
  const checkBoxHandler = (id) => {
    let temp = [];
    studentArr.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      if (item.isSelected === false) {
        setAllChecked(false);
      }
      temp.push(item);
    });

    setStudentArr(temp);
  };
  const allCheckHandler = () => {
    if (setStudentArr.length > 0) {
      setAllChecked(!allChecked);
    }

    let temp = [];
    studentArr.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      temp.push(item);
    });
    setStudentArr(temp);
  };

  const markStudentAttendaceHandler = () => {
    try {
      let temp = [];
      studentArr.map((item) => {
        if (item.isSelected) {
          temp.push({
            user_id: item.id,
            status: "Present",
            user_type: "STUDENT",
          });
        } else {
          temp.push({
            user_id: item.id,
            status: "Absent",
            user_type: "STUDENT",
          });
        }
      });
      if (temp.length === 0) {
        alert("Please select at lease one student");
        return;
      }
      setisloading(true);
      Teacher_Mark_Attendace_API({
        variables: {
          attendance_type: "CLASS",
          date: today_Date,
          user_id: temp,
          reference_id: -1,
        },
      })
        .then((response) => {
          showNotificationMsz("Attendance added successfully", "success");
          //  alert(response.data.addAttendance.message);
          setisloading(false);
          setIsUpdated(true);
        })
        .catch((error) => {
          setisloading(false);
          console.log("then error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Attendance - {today_Date}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-1 mb-1">
                          <Grid item md={6}>
                            <div className="inputMarginright ">
                              <label for="exampleInputEmail1">Class</label>
                              <select
                                class="form-control"
                                onChange={(e) => classHandler(e.target.value)}
                              >
                                <option value={-1}>Select Class</option>
                                {gradeArr.length !== 0 &&
                                  gradeArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <label for="exampleInputEmail1">Section</label>
                            <select
                              class="form-control"
                              onChange={(e) => sectionHandler(e.target.value)}
                            >
                              <option value={-1}>Select Section</option>
                              {sectionArr.length !== 0 &&
                                sectionArr.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                            </select>
                          </Grid>
                        </Grid>

                        <div className="mt-1 table_foramtitng">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Roll No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className="table_header"
                                    padding="checkbox"
                                  >
                                    <Checkbox
                                      checked={allChecked}
                                      onChange={allCheckHandler}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? sortedList.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : sortedList
                                ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell scope="row" align="left">
                                      {row.rollNo}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <img
                                        src={userimage}
                                        alt=""
                                        className="img_formatting mr-2"
                                      />
                                      {row.studentName}
                                    </StyledTableCell>

                                    <StyledTableCell padding="checkbox">
                                      <Checkbox
                                        checked={row.isSelected}
                                        onChange={() => checkBoxHandler(row.id)}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={studentArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                                onClick={AttendanceView}
                              >
                                View
                              </Button>
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={markStudentAttendaceHandler}
                              >
                                Publish
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  TEACHER_DATA: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(MarkAttendanceHome));
