import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Hoc1 from "../Hoc1";
import * as Constants from "../../action/appAction";
import { getData } from "../../api";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { useApolloClient } from "@apollo/client";
import * as Service from "../../services/index";

const PrivateTeachingInvoice = ({ navigate }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [institutionArr, setInstitutionArr] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    getOrgationzationData();
  }, []);
  const getOrgationzationData = async () => {
    try {
      const { data } = await client.query({
        query: Service.get_Private_Institute,
      });

      if (data) {
        let temp = [];
        data.getPrivateInstitute.map((item) => {
          temp.push({
            isSelected: false,
            name: item.name,
            id: item.id,
          });
        });
        setInstitutionArr(temp);
      }
    } catch (error) {
      console.log("private teaching error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const allCheckHandler = () => {
    if (institutionArr.length > 0) {
      setAllChecked(!allChecked);
    }

    let temp = [];
    institutionArr.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      // item.isSelected = !item.isSelected;
      temp.push(item);
    });

    setInstitutionArr(temp);
  };
  const checkBoxHandler = (id) => {
    let temp = [];
    let checkAllCheck = true;
    institutionArr.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      temp.push(item);
      if (item.isSelected === false && checkAllCheck == true) {
        checkAllCheck = false;
      }
    });
    setAllChecked(checkAllCheck);
    setInstitutionArr(temp);
  };

  const generateInvoiceHandle = () => {
    let user_data = [];
    institutionArr.map((item) => {
      if (item.isSelected === true) {
        user_data.push({
          id: item.id,
        });
      }
    });

    navigate("/GenrateInvoiceForPrivate", {
      state: {
        selectInst: user_data,
      },
    });
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              {/* <Holiday name={"FIND/ORGANISATION/INSTITUTION"} /> */}
              <div className="font_color_holiday">
                Holiday(Independence day)
              </div>
              <div className="card_Show_Insitute">
                <span className="margin_institution_name">
                  <Card className="institution_Name_font mb-2">
                    FIND/ORGANISATION/INSTITUTION
                  </Card>
                </span>
              </div>
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-4 Heading_bold">
                    Private teaching invoicing
                  </div>
                  <div className="mr-3 mt-4">
                    <Button
                      variant="contained"
                      className="btn_colrWidth mr-2 mt-2 button_login_decoration"
                      onClick={() =>
                        navigate("/OnlineTeachingInvoices", {
                          state: {
                            pagetype: "add",
                          },
                        })
                      }
                    >
                      Invoices
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height p-2">
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell
                                className="table_header"
                                align="left"
                              >
                                <Checkbox
                                  checked={allChecked}
                                  onChange={allCheckHandler}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                className="table_header"
                              >
                                Institution Name
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(rowsPerPage > 0
                              ? institutionArr.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : institutionArr
                            ).map((row, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="left">
                                  <Checkbox
                                    checked={row.isSelected}
                                    onChange={() => checkBoxHandler(row.id)}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <span>{row?.name}</span>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          true
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={institutionArr.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </div>
                    <div className="m-3 text-right ">
                      <Button
                        variant="contained"
                        className="btn_colrWidth mr-2 mt-2 button_login_decoration"
                        onClick={() => generateInvoiceHandle()}
                      >
                        Generate Invoice
                      </Button>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(PrivateTeachingInvoice));
