import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Loder from "../../Loder/Loder";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { showNotificationMsz } from "../../../utils/Validation";
import * as Service from "../../../services/index";
import Topic from "./Topic";

const ViewLeftPenalAccordion = (props) => {
  const {
    type,
    row,
    showChapterDialog,
    updateChapterHandler,
    chapterDialogHandler,
    setUChapterName,
    UChapterName,
    setAChapterName,
    AChapterName,
    addChapterHandler,
    showAddChapterDlg,
    addChapterDlgHandler,
    setStudyMatId,
    showDeleteChapterDlg,
    deleteChapterDlgHandler,
    DChapterName,
    deleteChapterHandler,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [studySubjectId, setStudySubjectId] = useState("");
  // const [studyTopicId, setStudyTopicId] = useState("");
  //const [updateTopicName, setupdateTopicName] = useState("");
  const [UChapterNameErr, setUChapterNameErr] = useState(false);
  const [AChapterNameErr, setAChapterNameErr] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [fileName, setFileName] = useState({});
  const [fileNameError, setFileNameError] = useState(false);
  const [topicLevel, settopicLevel] = useState(false);
  const [topicDeleteId, setTopicDeleteId] = useState("");
  const [deleteTopicName, setDeleteTopicName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [add_Topic, {}] = useMutation(Service.add_Topic);
  const [Update_Topic, {}] = useMutation(Service.Update_Topic);
  const [deleteTopic, {}] = useMutation(Service.deleteTopic);
  // add topic
  const AddDialogOpen = (studyMatId) => {
    addChapterDlgHandler(true);
    setStudyMatId(studyMatId);
    // setStudySubjectId(studyMatId);
    setTopicName("");
  };
  const AddTopicStudy = async () => {
    if (!AChapterName) {
      setAChapterNameErr(true);
      return;
    }
    addChapterHandler(studySubjectId);
  };
  const fileUploaderHandler = (e) => {
    try {
      setFileNameError(false);
      setVideoLink("");
      setIsLoading(true);
      // select file
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      // create form data
      let fileData = new FormData();
      //add file to form data
      fileData.append("file", file);
      // axios api call
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          let fileData = {
            filename: response.data.filename,
            path: response.data.path,
            type: response.data.type,
            name: response.data.name,
          };
          await setFileName(fileData);

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setIsLoading(false);
      console.log("catch and try error", error);
    }
  };
  // update topic
  const UpdateTopicDialogOpen = (item) => {
    chapterDialogHandler(true);
    // setStudyTopicId(item.id);
    // setupdateTopicName(item.name);
  };
  const updateTopicHandler = () => {
    if (!UChapterName) {
      setUChapterNameErr(true);
      return;
    }

    updateChapterHandler();
  };
  // delete topic
  const DeleteTopicDialogOpen = () => {
    deleteChapterDlgHandler(true);
    // setTopicDeleteId(row.id);
    // setDeleteTopicName(row.name);
  };
  const deleteTopicHandler = () => {
    deleteChapterHandler();
  };
  return (
    <div>
      {row && (
        <div className={type === "parent" ? "" : "view_left_subject_add"}>
          <div className="view_left_classbold">
            {type === "parent" && (
              <div>
                <div className="view_left_subject_add">
                  <div
                    onClick={() => {
                      settopicLevel(!topicLevel);
                    }}
                  >
                    <div>
                      {topicLevel ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                      {row?.subject?.name}{" "}
                      <i
                        className="fa fa-plus-circle cursor plus_icon_color"
                        onClick={(event) => {
                          event.stopPropagation();
                          AddDialogOpen(row.id);
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
                {topicLevel && (
                  <ViewLeftPenalAccordion
                    {...props}
                    row={row.topics}
                    className={row.class_name}
                    subjectName={row.subject.name}
                    type="topic"
                  />
                )}
              </div>
            )}

            {type === "topic" &&
              row &&
              row.map((item) => (
                <Topic
                  {...props}
                  item={item}
                  UpdateTopicDialogOpen={UpdateTopicDialogOpen}
                  DeleteTopicDialogOpen={DeleteTopicDialogOpen}
                />
              ))}
          </div>
        </div>
      )}
      {/* add topic dialog  */}
      <div>
        <Dialog
          open={showAddChapterDlg}
          onClose={() => {
            addChapterDlgHandler(false);
          }}
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    addChapterDlgHandler(false);
                  }}
                ></i>
              </div>
            </div>
            <div>
              <div>
                <h3>Add Topics</h3>
              </div>
              <div className=" inputMarginright m-2">
                <lebel className="text_filed_heading float-left">Name</lebel>{" "}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Topic Name"
                  value={AChapterName}
                  onChange={(e) => {
                    if (AChapterNameErr) setAChapterNameErr(false);
                    setAChapterName(e.target.value);
                    // setTopicName(e.target.value);
                  }}
                />
                {AChapterNameErr && (
                  <p className="text-danger">Enter topic name</p>
                )}
              </div>
            </div>
            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={AddTopicStudy}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      addChapterDlgHandler(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
        {/* update topic dailog */}
        <Dialog
          open={showChapterDialog}
          onClose={() => {
            chapterDialogHandler(false);
          }}
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    chapterDialogHandler(false);
                  }}
                ></i>
              </div>
            </div>
            <div>
              <div className="mt-2">
                <h3>Update Topics</h3>
              </div>
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                <lebel className="text_filed_heading float-left">Name</lebel>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Update Topic Name"
                  value={UChapterName}
                  onChange={(e) => {
                    if (UChapterNameErr) setUChapterNameErr(false);
                    //  setupdateTopicName(e.target.value);
                    setUChapterName(e.target.value);
                  }}
                />
                {UChapterNameErr && (
                  <p className="text-danger">Enter topic name</p>
                )}
              </div>
            </div>
            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={updateTopicHandler}
                  >
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      chapterDialogHandler(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
        {/* delete topic dailog */}
        <Dialog
          open={showDeleteChapterDlg}
          onClose={() => {
            deleteChapterDlgHandler(false);
          }}
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    deleteChapterDlgHandler(false);
                  }}
                ></i>
              </div>
            </div>
            <div className="pl-5 pr-5 mt-4">
              Confirm delete of Topic : {DChapterName}
            </div>
            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={deleteTopicHandler}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      deleteChapterDlgHandler(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};

export default ViewLeftPenalAccordion;
