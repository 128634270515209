import React, { useState, useEffect } from "react";
import Hoc6 from "../../Hoc6";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Institution.css";

import {
  blankValidator,
  showNotificationMsz,
  emailValidator,
} from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import {
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
  baseUrl,
} from "../../../services/index";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Hoc1 from "../../Hoc1";

const CreateUpdateInstitutionSuperAdmin = (props) => {
  const [filename, setFileName] = useState("Add Attachment");
  const [Institution_Icon, setInstitution_Icon] = useState({});
  let pageType = props.location.state.pagetype;
  let organization_id = parseInt(props.location.state.organization_id);
  const [updateInstitutionAPI, {}] = useMutation(UPDATE_INSTITUTION);
  const [createInstitutionAPI, {}] = useMutation(CREATE_INSTITUTION);
  const [nameError, setnameError] = useState(false);
  const [line1Error, setline1Error] = useState(false);
  const [line2Error, setline2Error] = useState(false);
  const [zipcodeerror, setzipcodeerror] = useState(false);
  const [stateError, setstateError] = useState(false);
  const [countryError, setcountryError] = useState(false);
  const [phoneNoError, setphoneNoError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [emailcorrectError, setemailcorrectError] = useState(false);
  const [homepageUrlError, sethomepageUrlError] = useState(false);
  const [schoolTimeError, setSchoolTimeError] = useState(false);
  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setFileName(file.name);
          setisloading(false);
          const Institution_Icon = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setInstitution_Icon(Institution_Icon);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      console.log("catch and try error", error);
    }
  };

  const [id, setId] = useState(null);

  const selectCountry = (e) => {
    setCreateInsForm({
      ...createInsForm,
      country: e,
    });
  };

  const selectRegion = (e) => {
    setCreateInsForm({
      ...createInsForm,
      state: e,
    });
  };

  const [createInsForm, setCreateInsForm] = useState({
    name: "",
    line1: "",
    line2: "",
    zip_code: "",
    state: "",
    country: "",
    phone_number: "",
    email_address: "",
    home_url: "",
    icon_id: "",
    city: "",
    schoolTime: "",
  });

  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageType === "edit") {
      const Institutiondata = props.location.state.data;
      let id = parseInt(props.location.state.data.id);

      setId(id);
      setCreateInsForm({
        name: Institutiondata.name,
        line1: Institutiondata.address.line1,
        line2: Institutiondata.address.line2,
        zip_code: Institutiondata.address.zip_code,
        state: Institutiondata.address.state,
        country: Institutiondata.address.country,
        phone_number: Institutiondata.phone_number,
        email_address: Institutiondata.email_address,
        home_url: Institutiondata.home_url,
        icon_doc: Institutiondata.document.filename,
        schoolTime: Institutiondata.office_time,
      });
      setFileName(Institutiondata.document.filename);
    }
  }, [pageType, props.location.state.data]);

  const submitInstitution = async () => {
    let flag = false;
    if (!blankValidator(createInsForm.name)) {
      setnameError(true);
      flag = true;
    }

    if (!blankValidator(createInsForm.line1)) {
      setline1Error(true);
      flag = true;
    }

    // if (!blankValidator(createInsForm.line2)) {
    //   setline2Error(true);
    //   flag = true;
    // }

    if (!blankValidator(createInsForm.zip_code)) {
      setzipcodeerror(true);
      flag = true;
    }

    if (!blankValidator(createInsForm.state)) {
      setstateError(true);
      flag = true;
    }

    if (!blankValidator(createInsForm.country)) {
      setcountryError(true);
      flag = true;
    }

    if (!blankValidator(createInsForm.phone_number)) {
      setphoneNoError(true);
      flag = true;
    }

    if (!emailValidator(createInsForm.email_address)) {
      setemailError(true);
      flag = true;
    }

    if (!blankValidator(createInsForm.home_url)) {
      sethomepageUrlError(true);
      flag = true;
    }
    if (!blankValidator(createInsForm.schoolTime)) {
      setSchoolTimeError(true);
      flag = true;
    }
    if (flag) {
      return;
    }

    if (pageType === "edit") {
      try {
        setisloading(true);
        updateInstitutionAPI({
          variables: {
            id,
            name: createInsForm.name,
            organization_id,
            phone_number: createInsForm.phone_number,
            email_address: createInsForm.email_address,
            home_url: createInsForm.home_url,
            state: createInsForm.state,
            country: createInsForm.country,
            line1: createInsForm.line1,
            line2: createInsForm.line2,
            city: " ",
            zip_code: parseInt(createInsForm.zip_code),
            icon_doc: Institution_Icon,
            office_time: createInsForm.schoolTime,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.updateInstitution.message,
              "success"
            );

            props.navigate.goBack();
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    } else
      try {
        setisloading(true);
        await createInstitutionAPI({
          variables: {
            name: createInsForm.name,
            organization_id,
            phone_number: createInsForm.phone_number,
            email_address: createInsForm.email_address,
            home_url: createInsForm.home_url,
            state: createInsForm.state,
            country: createInsForm.country,
            line1: createInsForm.line1,
            line2: createInsForm.line2,
            city: createInsForm.city,
            zip_code: parseInt(createInsForm.zip_code),
            icon_doc: Institution_Icon,
            office_time: createInsForm.schoolTime,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.addInstitution.message,
              "success"
            );

            props.navigate.goBack();
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.location.state.organization_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    {pageType === "edit" ? (
                      <div>Update Institution</div>
                    ) : (
                      <div>Create Institution</div>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">Name</div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Institution Name"
                                autoComplete="off"
                                value={createInsForm.name}
                                onChange={(e) => {
                                  setnameError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    name: e.target.value,
                                  });
                                }}
                              />
                              {nameError && (
                                <span className="text-danger">
                                  Ente the Name of Institute
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading mt-2">Address</div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Address Line 1"
                                autoComplete="off"
                                value={createInsForm.line1}
                                onChange={(e) => {
                                  setline1Error(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    line1: e.target.value,
                                  });
                                }}
                              />
                              {line1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Address Line 2"
                                autoComplete="off"
                                value={createInsForm.line2}
                                onChange={(e) => {
                                  setline2Error(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    line2: e.target.value,
                                  });
                                }}
                              />
                              {line2Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Pin code"
                                autoComplete="off"
                                value={createInsForm.zip_code}
                                onChange={(e) => {
                                  setzipcodeerror(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    zip_code: e.target.value,
                                  });
                                }}
                              />
                              {zipcodeerror && (
                                <span className="text-danger">
                                  Enter the Zip-code
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-1">
                              <CountryDropdown
                                value={createInsForm.country}
                                onChange={(e) => {
                                  setcountryError(false);
                                  selectCountry(e);
                                }}
                                className="country_drop_height_width"
                              />
                              {countryError && (
                                <span className="text-danger">
                                  Select The Country
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                country={createInsForm.country}
                                value={createInsForm.state}
                                onChange={(e) => {
                                  setstateError(false);
                                  selectRegion(e);
                                }}
                                className="country_drop_height_width"
                              />
                              {stateError && (
                                <span className="text-danger">
                                  Select The State
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>

                        <div className="text_filed_heading mt-2">
                          Phone Number
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                value={createInsForm.phone_number}
                                onChange={(e) => {
                                  setphoneNoError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    phone_number: e,
                                  });
                                }}
                              />
                              {phoneNoError && (
                                <span className="text-danger">
                                  Enter the Phone Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading mt-2">Email</div>
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Email Address"
                                autoComplete="off"
                                value={createInsForm.email_address}
                                onChange={(e) => {
                                  setemailError(false);
                                  setemailcorrectError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    email_address: e.target.value,
                                  });
                                }}
                              />
                              {emailError && (
                                <span className="text-danger">
                                  Enter the Email Address
                                </span>
                              )}
                              {emailcorrectError && (
                                <span className="text-danger">
                                  Enter the Correct Email Address
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading mt-2">
                          Homepage link
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Homepage link"
                                autoComplete="off"
                                value={createInsForm.home_url}
                                onChange={(e) => {
                                  sethomepageUrlError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    home_url: e.target.value,
                                  });
                                }}
                              />
                              {homepageUrlError && (
                                <span className="text-danger">
                                  Enter the Home Page URL
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading mt-2">
                          Institution Icon
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="fa fa-paperclip"></i>
                                  </span>
                                </div>
                                <div class="custom-file">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="inputGroupFile01"
                                    onChange={ImageUploadHandler}
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="inputGroupFile01"
                                  >
                                    {filename}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="text_filed_heading mt-2">
                          Working Hours
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <textarea
                              rows="5"
                              className="form-control"
                              placeholder="Working Hours"
                              autoComplete="off"
                              value={createInsForm.schoolTime}
                              onChange={(e) => {
                                setSchoolTimeError(false);

                                setCreateInsForm({
                                  ...createInsForm,
                                  schoolTime: e.target.value,
                                });
                              }}
                            />
                            <p>
                              e.g. (Monday-Friday 8:00 AM-5:00 PM, Saturday 8:00
                              AM-1:00 PM)
                            </p>
                            {schoolTimeError && (
                              <span className="text-danger">
                                Enter the Shcool Time
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <div className="main_button_div  mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration button_width"
                                onClick={submitInstitution}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((Hoc1(CreateUpdateInstitutionSuperAdmin)));
