import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import RichTextEditor from "react-rte";
import Chip from "@material-ui/core/Chip";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { blankValidator } from "../../../utils/Validation";
import Dialog from "@material-ui/core/Dialog";
import { baseUrl } from "../../../services/index.js";
import * as Service from "../../../services/index";
class AssignmentQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OpenImageDailog: false,
      imagepath: "",
      imageType: "",
    };
  }

  HandleImageDailogBox = (item) => {
    this.setState({
      imagepath: item.path,
      imageType: item.type,
      OpenImageDailog: true,
    });
  };
  HandleImageDailogBoxClose = () => {
    this.setState({
      imagepath: "",
      imageType: "",
      OpenImageDailog: false,
    });
  };
  render() {
    const { data, type } = this.props;
    return (
      <Card className="cardvisible_border mb-2 mt-3 pb-2">
        <div className="card_admissiondetails_height">
          <div className="textfiled_margin">
            <Card className="bg-light p-2 mt-4 mb-5">
              <Grid className="grid_main_div mt-1 mb-1">
                <Grid item md={4}>
                  <div className="inputMarginright ">
                    <label for="exampleInputEmail1">Section Name</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Marks"
                      value={data.question_section.name}
                      disabled={true}
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="inputMarginright ">
                    <label for="exampleInputEmail1">Marks</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Marks"
                      value={data.marks}
                      disabled={true}
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="inputMarginright ">
                    <label for="exampleInputEmail1">Question Number</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      value={data.question_number}
                      disabled={true}
                    />
                  </div>
                </Grid>

                <div style={{ width: "100%" }}>
                  <label for="exampleInputEmail1 mt-4 pt-3">
                    Question Name
                  </label>
                  <div className="mt-3 mb-3 card_invoicedetails_border p-3">
                    <div className="mt-1 mb-3">
                      <RichTextEditor
                        style={{
                          width: "100%",
                        }}
                        value={RichTextEditor.createValueFromString(
                          data.description.toString("html"),
                          "html"
                        )}
                      />
                    </div>
                    <div>
                      {blankValidator(data.document?.path) && (
                        <Grid className="grid_main_div">
                          <Grid item md={2}>
                            <div className=" assignment_result_data_font">
                              Question Image{" "}
                            </div>
                          </Grid>
                          <Grid item md={10}>
                            <Chip
                              label={data?.document?.name?.substring(0, 15)}
                              onClick={() => {
                                this.HandleImageDailogBox(data?.document);
                              }}
                              color="primary"
                              variant="outlined"
                            />
                            {/* <div className="font_color_data_assignment">
                              <img
                                src={
                                  Service.baseUrl +
                                  "/" +
                                  `${data.document?.path}`
                                }
                                className="student_photo_div"
                                alt="profile_img"
                              />
                            </div> */}
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </div>
                  {type != "STUDENT" ? (
                    <TeacherView
                      data={data}
                      HandleImageDailogBox={this.HandleImageDailogBox}
                    ></TeacherView>
                  ) : (
                    <StudentView
                      data={data}
                      HandleImageDailogBox={this.HandleImageDailogBox}
                    ></StudentView>
                  )}
                </div>
              </Grid>
            </Card>
          </div>
        </div>
        <Dialog
          open={this.state.OpenImageDailog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth="fullWidth"
          onClose={this.HandleImageDailogBoxClose}
          className="dailog_formatting"
        >
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <a
                href={baseUrl + "/" + this.state.imagepath}
                download
                target="_blank"
                className="mr-3"
              >
                <i class="fa fa-download"></i>
              </a>
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={this.HandleImageDailogBoxClose}
              ></i>
            </div>
          </div>
          <div className="p-3">
            <span>
              <img
                src={baseUrl + "/" + this.state.imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          </div>
        </Dialog>
      </Card>
    );
  }
}

export default AssignmentQuestion;
const TeacherView = (props) => {
  const { data } = props;
  return (
    <div>
      {/* <div className="mt-3 mb-1">
        <label for="exampleInputEmail1" className="mb-2">
          Type of Questions
        </label>
      </div> */}
      <div className="mt-1 mb-3 d-flex justify-content-between">
        <div className="form-check form-check-inline">
          <CheckBoxComponent
            label="Text Answer"
            checked={data.question_type == "Text_Answer"}
            cssClass="e-custom"
            disabled={true}
          />
        </div>
        <div className="form-check form-check-inline">
          <CheckBoxComponent
            label="Multiple choice with Multiple answer"
            checked={data.question_type == "Multple_choice_multiple_answer"}
            disabled={true}
            cssClass="e-custom"
          />
        </div>
        <div class="form-check form-check-inline">
          <CheckBoxComponent
            label="Multiple choice with Single answer"
            checked={data.question_type == "Multple_choice_single_answer"}
            disabled={true}
            cssClass="e-custom"
          />
        </div>
      </div>
      <div>
        {data.question_type == "Text_Answer" && (
          <div className="mt-3 mb-3 card_invoicedetails_border">
            <div className="mt-1 mb-3">
              <RichTextEditor
                style={{
                  width: "100%",
                }}
                value={RichTextEditor.createValueFromString(
                  data.answer.toString("html"),
                  "html"
                )}
              />
            </div>
          </div>
        )}
        {data.question_type == "Multple_choice_single_answer" && (
          <div className="mt-3 mb-3 card_invoicedetails_border">
            {data.assignment_choices.map((item, index) => (
              <div className=" ml-4 mt-2 mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    checked={item.is_answer === 0 ? false : true}
                  />
                  <input
                    class="form-check-input form-control"
                    type="text"
                    name="one"
                    value={item.choice}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {data.question_type == "Multple_choice_multiple_answer" && (
          <div className="mt-3 mb-3 card_invoicedetails_border">
            {data.assignment_choices.map((item, index) => (
              <div className=" ml-4 mt-1 mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    checked={item.is_answer === 0 ? false : true}
                  />
                  <input
                    class="form-check-input form-control"
                    type="text"
                    name="one"
                    value={item.choice}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Grid item md={2}>
        {blankValidator(data.document) &&
          blankValidator(data.document.name) && (
            <Chip
              label={data.document.name}
              color="primary"
              variant="outlined"
              onClick={() => {
                props.HandleImageDailogBox(data.document);
              }}
            />
          )}
      </Grid>
    </div>
  );
};

const StudentView = (props) => {
  return <div></div>;
};
