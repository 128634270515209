import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import RichTextEditor from "react-rte";

import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------------------redux-----------------------------
import * as Service from "../../../services/index";
import {
  blankValidator,
  timeStampToDatedash,
  showNotificationMsz,
  currentDayForMinMAx,
  getFullName,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const CreateSchoolNotice = (props) => {
  //instituiion id
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  let Organization_Name = props.data.institution_name;

  //user Name

  //UserID
  let user_Id = parseInt(props.LoginDetails.userid);

  //userType
  let userType = props.LoginDetails.usertype;

  //page Type
  let pageType = props.location.state.pageType;
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [filename, setFilename] = useState("Add Attachment");
  const [title, settitle] = useState("");
  const [publishdate, setpublishdate] = useState("");
  const [expirydate, setexpirydate] = useState("");
  const [noticetype] = useState("SCHOOL");
  const [uploadFileArr, setUploadFileArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [EditId, setEditId] = useState("");
  const [subject, setsubject] = useState("");

  //error
  const [titleError, settitleError] = useState(false);
  const [publishdateError, setpublishdateError] = useState(false);
  const [expirydateError, setexpirydateError] = useState(false);
  const [uploadingError, setuploadingError] = useState(false);
  const [ContentError, setContentError] = useState(false);
  const [subjectError, setsubjectError] = useState(false);

  const client = useApolloClient();

  const [Add_School_Notice_Data, {}] = useMutation(
    Service.Add_School_Notice_Data
  );

  //to Delte documnet
  const [Delete_Document, {}] = useMutation(Service.Delete_Document);

  //Update Notice Mutation
  const [Update_Notice_Data, {}] = useMutation(Service.Update_Notice_Data);

  const onChange = (value) => {
    setContentError(false);
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  useEffect(() => {
    if (pageType === "Edit") {
      let NoticeData = props.location.state.item;
      setEditId(NoticeData.id);
      settitle(NoticeData.title);
      setpublishdate(timeStampToDatedash(NoticeData.publish_date));
      setexpirydate(timeStampToDatedash(NoticeData.expiry_date));
      setsubject(NoticeData.subject);
      setUploadFileArr(NoticeData.documents);

      let messagedata = RichTextEditor.createValueFromString(
        NoticeData.content.toString("html"),
        "html"
      );

      setValue(messagedata);
    }
  }, []);

  const deleteDocsHandler = (ID, index) => {
    let id = parseInt(ID);

    try {
      setisloading(true);
      Delete_Document({
        variables: { id },
      }).then(
        (response) => {
          setisloading(false);

          uploadFileArr.splice(index, 1);
          setUploadFileArr([...uploadFileArr]);
          showNotificationMsz(response.data.deleteDocuments.message, "success");
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];

      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();

      fileData.append("file", file);

      setisloading(true);

      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFilename(file.name);
          setUploadFileArr([...uploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const createNoticeHandler = () => {
    let flag = false;
    if (!blankValidator(title)) {
      settitleError(true);
      flag = true;
    }

    if (!blankValidator(publishdate)) {
      setpublishdateError(true);
      flag = true;
    }

    if (!blankValidator(expirydate)) {
      setexpirydateError(true);
      flag = true;
    }

    if (!blankValidator(subject)) {
      setsubjectError(true);
      flag = true;
    }

    if (!blankValidator(uploadFileArr)) {
      setuploadingError(true);
      flag = true;
    }

    if (value.toString("html") === "<p><br></p>") {
      setContentError(true);
      flag = true;
    }
    if (flag) {
      return;
    }

    if (pageType === "Edit") {
      let temp = [];
      uploadFileArr.map((item) => {
        temp.push({
          name: item.name,
          filename: item.filename,
          type: item.type,
          path: item.path,
        });
      });
      try {
        setisloading(true);
        Update_Notice_Data({
          variables: {
            id: EditId,
            institution_id,
            title: title,
            subject: subject,
            notice_type: noticetype,
            section_id: -1, //not need in add school notice so that's why section_id is hardcoded
            publisher_id: user_Id,
            publisher_type: userType,
            publish_date: publishdate,
            expiry_date: expirydate,
            location: "",
            content: value.toString("html"),
            notice_board_doc: temp,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.updateNoticeBoard.message,
              "success"
            );

           props.navigate("/adminSchoolNotice");
          })
          .catch((error) => {
            setisloading(false);
            showNotificationMsz(error, "danger");
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    } else
      try {
        setisloading(true);
        Add_School_Notice_Data({
          variables: {
            institution_id,
            title: title,
            subject: subject,
            notice_type: noticetype,
            section_id: -1, //not need in add school notice so that's why section_id is hardcoded
            publisher_id: user_Id,
            publisher_type: userType,
            publish_date: publishdate,
            expiry_date: expirydate,
            location: "",
            content: value.toString("html"),
            notice_board_doc: uploadFileArr,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.addNoticeBoard.message,
              "success"
            );

           props.navigate("/adminSchoolNotice");
          })
          .catch((error) => {
            setisloading(false);
            showNotificationMsz(error, "danger");
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">
                    {pageType === "Edit" ? (
                      <span>Edit School Notice</span>
                    ) : (
                      <span>Create School Notice</span>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-2 inputMarginright">
                              <label for="exampleInputEmail1">Title</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Title"
                                  autoComplete="off"
                                  value={title}
                                  onChange={(e) => {
                                    settitleError(false);
                                    settitle(e.target.value);
                                  }}
                                />
                                {titleError && (
                                  <span className="text-danger">
                                    Enter Notice Title
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Publish Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  min={currentDayForMinMAx()}
                                  value={publishdate}
                                  onChange={(e) => {
                                    setpublishdateError(false);
                                    setpublishdate(e.target.value);
                                  }}
                                />
                                {publishdateError && (
                                  <span className="text-danger">
                                    Enter Published Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Expiry Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  min={publishdate}
                                  value={expirydate}
                                  onChange={(e) => {
                                    setexpirydateError(false);
                                    setexpirydate(e.target.value);
                                  }}
                                />
                                {expirydateError && (
                                  <span className="text-danger">
                                    Enter Expiry Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">
                                Type of Notice
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                placeholder="Publisher Name"
                                value={noticetype}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-2 inputMarginright">
                              <label for="exampleInputEmail1">Subject</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Subject"
                                  autoComplete="off"
                                  value={subject}
                                  onChange={(e) => {
                                    setsubjectError(false);
                                    setsubject(e.target.value);
                                  }}
                                />
                                {subjectError && (
                                  <span className="text-danger">
                                    Enter Subject of Notice
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Name of Publisher
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  placeholder="Publisher Name"
                                  value={
                                    getFullName(props.LoginDetails)
                                  
                                  }
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="">
                              <label for="exampleInputEmail1">Attachment</label>
                              <div className=" mb-2 d-flex">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                      <i class="fa fa-paperclip"></i>
                                    </span>
                                  </div>
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="inputGroupFile01"
                                      onChange={ImageUploadHandler}
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="inputGroupFile01"
                                    >
                                      {filename}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {uploadingError && (
                                <span className="text-danger">
                                  please Add Attachement
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <label className="mt-2">Attachment Lists</label>
                        <div className=" mb-2 attachList">
                          {uploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span className="pl-2">{item.name}</span>
                              <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() =>
                                    deleteDocsHandler(item.id, index)
                                  }
                                ></i>
                              </span>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2 mb-2">
                          <label for="exampleInputEmail1">Content</label>
                          <div className="mt-1">
                            <RichTextEditor
                              value={value}
                              onChange={onChange}
                              className="editor_height"
                            />
                            {ContentError && (
                              <span className="text-danger">
                                Enter Message of Notice
                              </span>
                            )}
                          </div>
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={createNoticeHandler}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
  LoginDetails: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(CreateSchoolNotice)));
