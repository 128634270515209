import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import "react-phone-input-2/lib/style.css";
import { store } from "react-notifications-component";
import Dialog from "@material-ui/core/Dialog";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

//------------------------table-------------------------
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  getFullName,
  getValue,
  showNotificationMsz,
} from "../../../utils/Validation.js";

const UpdateParent = ({ manageInstitution }) => {
  //-------------------------------global id-------------------------
  let institution_id = parseInt(manageInstitution.institution_id);

  //-------------------------------api calls-------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //-------------------------------local state-------------------------
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [userArr, setUserArr] = useState([]);
  const [parentName, setParentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginId, setLoginId] = useState("");

  const [searchById, setSearchById] = useState("");
  const [searchByLoginId, setSearchByLoginId] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchByName, setSearchByName] = useState("");
  //-------------------------------methods-------------------------
  useEffect(() => {
    const getParentList = () => {
      try {
        setIsLoading(true);
        client
          .query({
            query: Service.GET_PARENT_list,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setIsLoading(false);

            setUserArr(response.data.getParentList);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setIsLoading(false);
        console.log("t and c error", error);
      }
    };
    getParentList();
  }, [client, institution_id]);

  const changePasswordHandler = async (parentArgs) => {
    try {
      if (parentArgs.login) {
        setLoginId(parentArgs.login.login_id);
      } else {
        showNotificationMsz("Need login id to reset password!", "info");
        return;
      }
      let fullName = await getFullName(parentArgs.name);
      setParentName(fullName);
      setConfirmationBox(true);
    } catch (error) {
      console.log("error", error);
    }
  };
  const parentPasswordResetFn = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.Change_User_Password_By_Admin,
        variables: {
          login_id: loginId,
        },
      });
      showNotificationMsz(data.changePasswordByAdmin.message, "success");
      setConfirmationBox(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showNotificationMsz(error, "danger");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterUserArr = userArr.filter((event) => {
    return (
      (getFullName(event.name)
        .toLowerCase()
        .indexOf(searchByName.toLowerCase()) !== -1
        ? true
        : false) &&
      (getValue(event?.email_address)
        .toLowerCase()
        .indexOf(searchEmail.toLowerCase()) !== -1
        ? true
        : false) &&
      (getValue(event?.login?.display_id)
        .toLowerCase()
        .indexOf(searchById.toLowerCase()) !== -1
        ? true
        : false) &&
      (getValue(event?.login?.login_id)
        .toLowerCase()
        .indexOf(searchByLoginId.toLowerCase()) !== -1
        ? true
        : false)
    );
  });

  return (
    <div>
      <div className="mt-2 mb-1 Heading_bold">Search User</div>
      <Grid className="grid_main_div">
        <Grid item md={3}>
          <div className="inputMarginrightside mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="User ID"
              autoComplete="off"
              value={searchById}
              onChange={(e) => {
                setSearchById(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="inputMarginrightside mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Login ID"
              autoComplete="off"
              value={searchByLoginId}
              onChange={(e) => {
                setSearchByLoginId(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="inputMarginrightside mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              autoComplete="off"
              value={searchByName}
              onChange={(e) => {
                setSearchByName(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="inputMarginrightside mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Email ID"
              autoComplete="off"
              value={searchEmail}
              onChange={(e) => {
                setSearchEmail(e.target.value);
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* --------------------list of users-------------- */}
      <div className="mt-4 mb-1 Heading_bold">List Of Users</div>

      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table_header" align="left">
                  ID
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Login ID
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Name
                </StyledTableCell>

                <StyledTableCell align="left" className="table_header">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Phone No
                </StyledTableCell>

                <StyledTableCell align="left" className="table_header">
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filterUserArr.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filterUserArr
              ).map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {row.login?.display_id}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.login?.login_id}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getFullName(row.name)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.email_address && row.email_address}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.phone_number && `+${row.phone_number}`}
                  </StyledTableCell>

                  <StyledTableCell
                    align="left"
                    className="row_details_color"
                    onClick={() => changePasswordHandler(row)}
                  >
                    Reset Password
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterUserArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* -------------------------list of users---------------------- */}

      <Loder loading={isLoading} />
      <Dialog
        open={confirmationBox}
        onClose={() => {
          setConfirmationBox(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div className="cut_dailog_icon float-right">
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setConfirmationBox(false);
              }}
            ></i>
          </div>

          <div className="pl-5 pr-5 mt-4">
            Are you sure want to reset you password : {parentName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => parentPasswordResetFn()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setConfirmationBox(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(UpdateParent);
