import React, { useState, useEffect } from "react";
import "./Notice.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loder from "../../Loder/Loder.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  getcurrentDate,
  getFullName,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TeacherNoticeBoard(props) {
  //institution ID from token
  const institution_id = parseInt(props.data.institution_id);

  //academic session id
  let academic_session_id = parseInt(props.dataacademic.id);

  const client = useApolloClient();

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);
  const [value, setValue] = React.useState(0);
  const [isloading, setisloading] = useState(false);
  const [search, setSearch] = useState("");
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);

  const [sectionId, setSectionId] = useState("");
  const [gradeId, setGradeId] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [classnoticedata, setClassnoticedata] = useState([]);

  useEffect(() => {
    const getTeacherNoticeBoardData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_School_Notice_data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            let temp = [];

            for (
              let index = 0;
              index < response.data.getNoticeBoardByInstitutionId.length;
              index++
            ) {
              const element =
                response.data.getNoticeBoardByInstitutionId[index];
              // let date1 = new Intl.DateTimeFormat("en-US", {
              //   year: "numeric",
              //   month: "2-digit",
              //   day: "2-digit",
              // }).format(new Date());

              // let date2 = new Intl.DateTimeFormat("en-US", {
              //   year: "numeric",
              //   month: "2-digit",
              //   day: "2-digit",
              // }).format(element.expiry_date);

              // let Publish_Date = new Intl.DateTimeFormat("en-US", {
              //   year: "numeric",
              //   month: "2-digit",
              //   day: "2-digit",
              // }).format(element.publish_date);
              let date1 = new Date().getTime();
              let date2 = +element.expiry_date;
              let Publish_Date = +element.publish_date;

              if (date1 >= Publish_Date && date1 <= date2) {
                temp.push({
                  id: element.id,
                  title: element.title,
                  subject: element.subject,
                  publish_date: element.publish_date,
                  expiry_date: element.expiry_date,
                  admin: element.admin,
                  status: date1 > date2 ? "EXPIRED" : element.status,
                  content: element.content,
                  documents: element.documents,
                  section: element.section,
                });
              }
            }
            setSchoolnoticedata(temp);
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent homepage t and c error", error);
      }
    };
    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setisloading(false);
            if (response.data.getGrade.length !== 0) {
              setGradeArr(response.data.getGrade);
            }
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getManageGrades();

    getTeacherNoticeBoardData();
  }, [client, institution_id, academic_session_id]);

  const changeGrade = (val) => {
    let grade_id = parseInt(val);

    setGradeId(grade_id);
    setSectionArr([]);
    setSectionId(-1);

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const getClassNoticeBoardData = (val) => {
    let section_id = parseInt(val);
    setSectionId(section_id);
    if (section_id === -1) {
      setClassnoticedata([]);

      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Class_Notice_data,
          variables: {
            institution_id,
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];

          for (
            let index = 0;
            index < response.data.getNoticeBoardBySectionId.length;
            index++
          ) {
            const element = response.data.getNoticeBoardBySectionId[index];
            // let date1 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(new Date());

            // let date2 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.expiry_date);

            // let Publish_Date = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.publish_date);
            let date1 = new Date();

            let date2 = getcurrentDate(element.expiry_date);
            let Publish_Date = getcurrentDate(element.publish_date);

            if (date1 >= Publish_Date && date1 <= date2) {
              temp.push({
                id: element.id,
                title: element.title,
                subject: element.subject,
                publish_date: element.publish_date,
                expiry_date: element.expiry_date,
                employee: element.employee,
                status: date1 > date2 ? "EXPIRED" : element.status,
                content: element.content,
                documents: element.documents,
                section: element.section,
              });
            }
          }

          setClassnoticedata(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("parent homepage t and c error", error);
    }
  };
  const filterSchoolNotice = schoolnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterClassNotice = classnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">Notice Board</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height ">
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2 mybarwidth">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="School Notice"
                              {...a11yProps(0)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                            {props.data.usertype !== "TEACHER" ||
                            props.data.usertype == "Teacher" ? (
                              ""
                            ) : (
                              <Tab
                                label="Class Notice"
                                {...a11yProps(1)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                            )}
                          </Tabs>
                        </div>
                        <TabPanel value={value} index={0}>
                          <Grid className="grid_main_div gapfromanotherinput mb-3">
                            <Grid item md={6}>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Search"
                                  autoComplete="off"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={3}></Grid>
                            {/* <Grid item md={3}>
                              <div className="mt-1">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn button_width button_login_decoration "
                                  onClick={() =>
                                   props.navigate(
                                      "/noticeManagementSchool"
                                    )
                                  }
                                >
                                  manage School Notice
                                </Button>
                              </div>
                            </Grid> */}
                          </Grid>
                          <div className="">
                            <div className="table_foramtitng mt-3">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Title
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Subject
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publish Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Expiry Date
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publisher Name
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Status
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? filterSchoolNotice.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : filterSchoolNotice
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.title) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.title}</span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {!blankValidator(row.subject) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.subject}</span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {!blankValidator(row.publish_date) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {timeStampToDatedashDayStart(
                                                row.publish_date
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.expiry_date) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {timeStampToDatedashDayStart(
                                                row.expiry_date
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {getFullName(row.admin.name)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.status) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.status}</span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="row_details_color"
                                          onClick={() =>
                                            props.navigate("/noticeDetail", {
                                              state: {
                                                item: row,
                                                type: "SCHOOLNOTICE",
                                                institution_Name:
                                                  props.data.institution_name,
                                              },
                                            })
                                          }
                                        >
                                          View
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={filterSchoolNotice.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                          <div className="enquiry_heading">
                            <div></div>
                            <div>
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration "
                                onClick={() =>
                                  props.navigate("/createNotice", {
                                    state: {
                                      pageType: "Add",
                                      academic_session: parseInt(
                                        props.dataacademic.id
                                      ),
                                    },
                                  })
                                }
                              >
                                Create Notice
                              </Button>
                            </div>
                          </div>
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={3}>
                              <div className="inputMarginright">
                                <label for="exampleInputEmail1">Class</label>
                                <select
                                  className="form-control"
                                  value={gradeId}
                                  onChange={(e) => {
                                    changeGrade(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Search By Class</option>
                                  {gradeArr.length > 0 &&
                                    gradeArr.map((item, index) => (
                                      <option value={item.id}>
                                        {item.gradeName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div className="inputMarginright">
                                <label for="exampleInputEmail1">Section</label>
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  value={sectionId}
                                  onChange={(e) => {
                                    getClassNoticeBoardData(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Search By Section</option>

                                  {sectionArr.length > 0 &&
                                    sectionArr.map((item, index) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <label for="exampleInputEmail1">Title</label>

                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Title"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item md={3}></Grid>
                          </Grid>

                          <div className="">
                            <div className="table_foramtitng mt-3">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Title
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Subject
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publish Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Expiry Date
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publisher Name
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Status
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage1 > 0
                                      ? filterClassNotice.slice(
                                          page1 * rowsPerPage1,
                                          page1 * rowsPerPage1 + rowsPerPage1
                                        )
                                      : filterClassNotice
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.title) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.title}</span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {!blankValidator(row.subject) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.subject}</span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {!blankValidator(row.publish_date) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {timeStampToDatedashDayStart(
                                                row.publish_date
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.expiry_date) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {timeStampToDatedashDayStart(
                                                row.expiry_date
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {getFullName(row?.employee?.name)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.status) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.status}</span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                          <div className="d-flex ">
                                            <span
                                              className="row_details_color pr-2"
                                              onClick={() =>
                                                props.navigate(
                                                  "/noticeDetail",
                                                  {
                                                    state: {
                                                      item: row,
                                                      type: "CLASSNOTICE",
                                                      institution_Name:
                                                        props.data
                                                          .institution_name,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              View
                                            </span>
                                            |
                                            <sapn
                                              className="row_details_color pl-2"
                                              onClick={() =>
                                                props.navigate(
                                                  "/createNotice",
                                                  {
                                                    state: {
                                                      pageType: "Edit",
                                                      item: row,
                                                      academic_session:
                                                        parseInt(
                                                          props.dataacademic.id
                                                        ),
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Edit
                                            </sapn>
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={filterClassNotice.length}
                                  rowsPerPage={rowsPerPage1}
                                  page={page1}
                                  onChangePage={handleChangePage1}
                                  onChangeRowsPerPage={handleChangeRowsPerPage1}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(TeacherNoticeBoard));
