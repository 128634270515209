import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import ClassInvitelist from "./ClassInvitelist";
import SectionInvitelist from "./SectionInvitelist";
const Studentlist = (props) => {
  const [chekbox1, setchekbox1] = useState(true);
  const [chekbox2, setchekbox2] = useState(false);

  return (
    <Grid className="grid_main_div mt-3">
      <Grid item md={8}>
        <div className="mt-2">
          <div className="text_filed_heading">Invitees</div>
          <div className="mt-2 mb-2">
            <span className="ml-4">
              <CheckBoxComponent
                label="Class"
                cssClass="e-custom"
                checked={chekbox1}
                onChange={(e) => {
                  setchekbox1(e.target.checked);
                  setchekbox2(false);
                }}
              />
            </span>
            <span className="ml-4">
              <CheckBoxComponent
                label="Section"
                cssClass="e-custom"
                checked={chekbox2}
                onChange={(e) => {
                  setchekbox2(e.target.checked);
                  setchekbox1(false);
                }}
              />
            </span>
          </div>
          {chekbox1 && <ClassInvitelist {...props} />}
          {chekbox2 && <SectionInvitelist {...props} />}
        </div>
      </Grid>
    </Grid>
  );
};

export default Studentlist;
