import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import Loder from "../../../Loder/Loder.jsx";
import {
  newDateToDate,
  blankValidator,
  changeTimeFormat,
} from "../../../../utils/Validation";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../../api/index";
import * as Constants from "../../../../action/appAction";
import OnlineLinkLocation from "../../../Common/Events/OnlineLinkLocation.jsx";

function StaffTimetableWeek({ employeeId, day, ADMIN_DATA, weekName }) {
  const { institution_id } = ADMIN_DATA;
  var todayDate = new Date().toJSON().slice(0, 10);
  const [mondayArray, setMondayArray] = useState([]);
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  // ----------------METHODS-------------------
  useEffect(() => {
    const getStudentTimetable = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_ClassAssignment_For_Teacher,
            variables: {
              institution_id: parseInt(institution_id),
              userid: parseInt(employeeId),
              day,
              start_date: todayDate,
              end_date: newDateToDate(endOfWeek),
            },
          })
          .then((response) => {
            const timetableArr = response.data.getClassTimetableForTeacher;
            let temp = [];
            if (timetableArr) {
              timetableArr.map((item) => {
                if (item.timetables) {
                  item.timetables.map((timeTableData) => {
                    let subjectName = "";
                    if (timeTableData.subject) {
                      if (timeTableData.subject.name) {
                        subjectName = timeTableData.subject.name;
                      }
                    }
                    temp.push({
                      subject: subjectName,
                      section: item.section.name,
                      gradeName: item.section.grade.gradeName,
                      id: timeTableData.id,
                      period_name: timeTableData.period_name,
                      start_time: timeTableData.start_time,
                      end_time: timeTableData.end_time,
                      location: timeTableData.location,
                      show: false,
                      display: "",
                    });
                  });
                }
              });
            }
            const sortedList = temp.sort((a, b) => {
              let st = a.start_time.split(":");
              let stm = parseInt(st[0]);
              let ss = parseInt(st[1]);
              let et = b.start_time.split(":");
              let etm = parseInt(et[0]);
              let es = parseInt(et[1]);
              return stm - etm || ss - es;
            });
            setMondayArray(sortedList);
            setisloading(false);
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent monday  catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent monday  t and c error", error);
      }
    };
    getStudentTimetable();
  }, [client, institution_id, employeeId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <Grid className="grid_main_div">
        <Grid item md={12}>
          <div className="card_admissiondetails_height">
            <div className="mb-2">
              <div className="Heading_bold mt-2">{weekName}</div>
              <div className="table_foramtitng mt-2">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className="table_header">
                          Period Name
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Start Time
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          End Time
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Class
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Section
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Location
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Subject
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? mondayArray.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : mondayArray
                      ).map((row, index) => (
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            {row.period_name && row.period_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.start_time && changeTimeFormat(row.start_time)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.end_time && changeTimeFormat(row.end_time)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.gradeName && row.gradeName}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.section && row.section}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <OnlineLinkLocation
                              data={row}
                              day={day}
                              fromTimetable={true}
                              userType="Teacher"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.subject}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    true
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={mondayArray.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(StaffTimetableWeek);
