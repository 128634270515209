import React, { useState, useEffect } from "react";
import "./Notice.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  getcurrentDate,
  timeStampToDate,
} from "../../../utils/Validation";
import PtNoticeBoard from "./PtNoticeBoard";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function NoticeBoard(props) {
  const institution_id = parseInt(props.data?.institution_id);
  let section_id = parseInt(props?.user_info?.section?.id);
  let institution_Name = props.data.institution_name;
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);
  const [value, setValue] = React.useState(0);
  const [isloading, setisloading] = useState(false);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [classnoticedata, setClassnoticedata] = useState([]);

  useEffect(() => {
    const getStudentNoticeBoardData = () => {
      try {
        if (!blankValidator(institution_id)) {
          return;
        }
        setisloading(true);
        client
          .query({
            query: Service.Get_School_Notice_data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setSchoolnoticedata(
              response.data.getNoticeBoardByInstitutionId.filter((item) => {
                return (
                  new Date() >= getcurrentDate(item.publish_date) &&
                  new Date() <= getcurrentDate(item.expiry_date)
                );
              })
            );
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent homepage t and c error", error);
      }
    };
    const getClassNoticeData = () => {
      if (!blankValidator(section_id) || !blankValidator(institution_id)) {
        return;
      }
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Class_Notice_data,
            variables: {
              institution_id,
              section_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setClassnoticedata(
              response.data.getNoticeBoardBySectionId.filter((item) => {
                return (
                  new Date() >= getcurrentDate(item.publish_date) &&
                  new Date() <= getcurrentDate(item.expiry_date)
                );
              })
            );
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent homepage t and c error", error);
      }
    };

    getStudentNoticeBoardData();
    getClassNoticeData();
  }, [institution_id, client, section_id]);

  const filterSchoolNotice = schoolnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterClassNotice = classnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search1.toLowerCase()) !== -1;
  });

  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  {props.data.type === "PT" || props.data.type === "ET" ? (
                    <PtNoticeBoard institution_Name={institution_Name} />
                  ) : (
                    <Card>
                      <div className="card_admissiondetails_height ">
                        <div className="textfiled_margin mb-2">
                          <div className="mt-2 mybarwidth">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="simple tabs example"
                              indicatorColor="primary"
                              textColor="primary"
                              left
                            >
                              <Tab
                                label="School Notice"
                                {...a11yProps(0)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                              <Tab
                                label="Class Notice"
                                {...a11yProps(1)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                            </Tabs>
                          </div>
                          <TabPanel value={value} index={0}>
                            <Grid className="grid_main_div gapfromanotherinput mb-3">
                              <Grid item md={6}>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Search"
                                    autoComplete="off"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </Grid>

                              <Grid item md={5}></Grid>
                            </Grid>
                            <div className="">
                              <div className="table_foramtitng mt-3">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Title
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Subject
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Publish Date
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Expiry Date
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Publisher Name
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Status
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Action
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage > 0
                                        ? filterSchoolNotice.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                          )
                                        : filterSchoolNotice
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.title) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {row.title.substring(0, 20)}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {!blankValidator(row.subject) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {row.subject.substring(0, 20)}
                                              </span>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell align="left">
                                            {timeStampToDate(row.publish_date)}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(
                                              row.expiry_date
                                            ) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {timeStampToDate(
                                                  row.expiry_date
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.admin) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {!blankValidator(
                                                  row.admin.name
                                                ) ? (
                                                  "No Data"
                                                ) : (
                                                  <span>
                                                    {!blankValidator(
                                                      row.admin.name.first_name
                                                    ) ? (
                                                      ""
                                                    ) : (
                                                      <span>
                                                        {
                                                          row.admin.name
                                                            .first_name
                                                        }
                                                      </span>
                                                    )}{" "}
                                                    {!blankValidator(
                                                      row.admin.name.middle_name
                                                    ) ? (
                                                      ""
                                                    ) : (
                                                      <span>
                                                        {
                                                          row.admin.name
                                                            .middle_name
                                                        }
                                                      </span>
                                                    )}{" "}
                                                    {!blankValidator(
                                                      row.admin.name.last_name
                                                    ) ? (
                                                      ""
                                                    ) : (
                                                      <span>
                                                        {
                                                          row.admin.name
                                                            .last_name
                                                        }
                                                      </span>
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.status) ? (
                                              "No Data"
                                            ) : (
                                              <span>{row.status}</span>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="row_details_color"
                                            onClick={() =>
                                              props.navigate("/noticeDetail", {
                                                state: {
                                                  item: row,
                                                  type: "SCHOOLNOTICE",
                                                  institution_Name:
                                                    institution_Name,
                                                },
                                              })
                                            }
                                          >
                                            View
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterSchoolNotice.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={1}>
                            <Grid className="grid_main_div gapfromanotherinput mb-3">
                              <Grid item md={6}>
                                <div className="inputMarginright mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Search"
                                    autoComplete="off"
                                    value={search1}
                                    onChange={(e) => {
                                      setSearch1(e.target.value);
                                    }}
                                  />
                                </div>
                              </Grid>

                              {/* <Grid item md={1}>
                              <div className="button_text_formating mt-1">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn button_width button_login_decoration "
                                >
                                  {" "}
                                  Search{" "}
                                </Button>
                              </div>
                            </Grid> */}
                              <Grid item md={5}></Grid>
                            </Grid>
                            <div className="">
                              <div className="table_foramtitng mt-3">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Title
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Subject
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Publish Date
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Expiry Date
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Publisher Name
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Status
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Action
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage1 > 0
                                        ? filterClassNotice.slice(
                                            page1 * rowsPerPage1,
                                            page1 * rowsPerPage1 + rowsPerPage1
                                          )
                                        : filterClassNotice
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.title) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {row.title.substring(0, 20)}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {!blankValidator(row.subject) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {row.subject.substring(0, 20)}
                                              </span>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell align="left">
                                            {timeStampToDate(row.publish_date)}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(
                                              row.expiry_date
                                            ) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {timeStampToDate(
                                                  row.expiry_date
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.employee) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {!blankValidator(
                                                  row.employee.name
                                                ) ? (
                                                  "No Data"
                                                ) : (
                                                  <span>
                                                    {!blankValidator(
                                                      row.employee.name
                                                        .first_name
                                                    ) ? (
                                                      ""
                                                    ) : (
                                                      <span>
                                                        {
                                                          row.employee.name
                                                            .first_name
                                                        }
                                                      </span>
                                                    )}{" "}
                                                    {!blankValidator(
                                                      row.employee.name
                                                        .middle_name
                                                    ) ? (
                                                      ""
                                                    ) : (
                                                      <span>
                                                        {
                                                          row.employee.name
                                                            .middle_name
                                                        }
                                                      </span>
                                                    )}{" "}
                                                    {!blankValidator(
                                                      row.employee.name
                                                        .last_name
                                                    ) ? (
                                                      ""
                                                    ) : (
                                                      <span>
                                                        {
                                                          row.employee.name
                                                            .last_name
                                                        }
                                                      </span>
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {!blankValidator(row.status) ? (
                                              "No Data"
                                            ) : (
                                              <span>{row.status}</span>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="row_details_color"
                                            onClick={() =>
                                              props.navigate("/noticeDetail", {
                                                state: {
                                                  item: row,
                                                  type: "CLASSNOTICE",
                                                  institution_Name:
                                                    institution_Name,
                                                },
                                              })
                                            }
                                          >
                                            View
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterClassNotice.length}
                                    rowsPerPage={rowsPerPage1}
                                    page={page1}
                                    onChangePage={handleChangePage1}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage1
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}
//export default (HOC1());

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
  user_info: getData(state, Constants.ACADEMIC_SESSION_DATA),
});

export default connect(mapStateToProps, null)(HOC1(NoticeBoard));
