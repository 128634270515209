import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import StudentLeaves from "../../Student/Leave/LeaveSummary.jsx";

import Loder from "../../Loder/Loder.jsx";

//-----------------redux----------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------------api calls----------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { getFullName } from "../../../utils/Validation.js";

function ParentStudentLeave(props) {
  const { studentData } = props.data;
  //------------------------global id------------------------
  let father_id = parseInt(props.data.userid);
  let institution_id = parseInt(props.data.institution_id);

  //Institute name
  let institution_Name = props.data.institution_name;

  //------------------------api calls------------------------
  const client = useApolloClient();
  //------------------------local state------------------------
  const [ParentstudentData, setParentStudentData] = useState([]);

  const [isloading, setisloading] = useState(false);

  const [studentId, setStudentId] = useState(-1);
  // ---------------methods-----------------------

  // useEffect(() => {
  //   const getPraentStudentData = () => {
  //     try {
  //       setisloading(true);
  //       client
  //         .query({
  //           query: Service.Get_Parent_student_data,
  //           variables: {
  //             institution_id,
  //             father_id,
  //             status: "ACTIVE",
  //           },
  //         })
  //         .then((response) => {
  //           setisloading(false);
  //           if (response.data.getStudentByParent.length > 0) {
  //             setParentStudentData(response.data.getStudentByParent);
  //           }
  //         })
  //         .catch((err) => {
  //           setisloading(false);
  //           console.log("exam catch err", err);
  //         });
  //     } catch (error) {
  //       setisloading(false);
  //       console.log("exam t and c error", error);
  //     }
  //   };

  //   getPraentStudentData();
  // }, [client, institution_id, father_id]);
  useEffect(() => {
    getStudentExamData();
  }, []);

  const getStudentExamData = () => {
    setStudentId(parseInt(studentData?.id));
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} mynav={"/parentHomePage"} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold leave_content_main">
                    Leave Summary
                  </div>
                  <div className="mt-3 mr-3 d-flex">
                    <div>
                      {studentId != -1 && (
                        <Button
                          variant="contained"
                          className="add_new_query_btn  button_login_decoration mr-2"
                          onClick={() =>
                            props.navigate("/requestleave", {
                              state: {
                                userid: parseInt(studentId),
                              },
                            })
                          }
                        >
                          {" "}
                          Apply
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className=" d-flex mt-2">
                          <div className="text_filed_heading">
                            Student Name :
                          </div>{" "}
                          <div className="">
                            {getFullName(studentData?.name)}
                          </div>
                        </div>
                        <div className=" d-flex mt-2">
                          <div className="text_filed_heading">Class :</div>{" "}
                          <div>
                            {studentData?.section?.grade?.gradeName}
                            {"-"}
                            {studentData?.section?.name}
                          </div>
                        </div>
                        {/* <div className="mt-1 mb-1">
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => {
                              getStudentExamData(e.target.value);
                            }}
                          >
                            <option value={-1}>Student List</option>
                            {ParentstudentData.map((item, index) => (
                              <option value={item.student.id} key={index}>
                                {getFullName(item.name)}
                              </option>
                            ))}
                          </select>
                        </div> */}

                        <div className="table_foramtitng mt-3">
                          <StudentLeaves
                            userid={studentId}
                            navigate={props.navigate}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ParentStudentLeave));
