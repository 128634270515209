import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import StudentLeaves from "./LeaveSummary.jsx";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";

import * as Constants from "../../../action/appAction";
// import { blankValidator } from "../../../utils/Validation";

function Studentleave(props) {
  //----------------global id-------------------------

  //Institute name

  // ---------------api call fn-----------------------
  const client = useApolloClient();
  // ---------------local state-----------------------
  const [studentLeaveArray, setStudentLeaveArray] = useState(["1"]);

  const [isloading, setisloading] = useState(false);

  const [user_id, setuser_id] = useState("");
  const [institution_Name, setinstitution_Name] = useState("");
  const [institution_id, setinstitution_id] = useState("");

  // ---------------methods-----------------------
  useEffect(() => {
    if (props.user_info.usertype === "STUDENT") {
      setuser_id(parseInt(parseInt(props.user_info.userid)));
      getStudentDataHandler(parseInt(props.user_info.userid));
    }
    if (props.user_info.usertype === "PARENT") {
      setuser_id(parseInt(props.userid));
      getStudentDataHandler(parseInt(props.userid));
    }
    setinstitution_Name(props.user_info.institution_name);
    setinstitution_id(parseInt(props.user_info.institution_id));
  }, []);

  const getStudentDataHandler = (id) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_leave_Request_By_userId,
          variables: {
            user_id: parseInt(id),
            user_types: [
              {
                user_type: props.user_info.usertype,
              },
            ],
          },
        })
        .then(
          (response) => {
            setisloading(false);

            setStudentLeaveArray(
              response.data.getLeaveRequestByUserId.reverse()
            );
          },

          (err) => {
            setisloading(false);
            console.log("parent homepage then err", err);
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("parent homepage t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} mynav={"studentHomePage"} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold leave_content_main">
                    Leave Summary
                  </div>
                  <div className="mt-3 mr-3 d-flex">
                    <div>
                      <Button
                        variant="contained"
                        className="add_new_query_btn  button_login_decoration mr-2"
                        onClick={() => props.navigate("/requestleave")}
                      >
                        {" "}
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="card_margin_both_side">
                  <StudentLeaves {...props} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(Studentleave));
