import React, { useState, useEffect } from "react";
// import "./Fees.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { useApolloClient } from "@apollo/client";
import * as Service from "../../../services/index";
import Loder from "../../Loder/Loder";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//-----------------------REDUX------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { getFullName, timeStampToDate } from "../../../utils/Validation";

function StudentPaymentnavigate(props, PARENT_DATA) {
  const { currency_code } = PARENT_DATA;
  //-------------------GLOBAL ID------------------------
  let institution_id = parseInt(props.data.institution_id);
  //-------------------API CALLS------------------------
  const client = useApolloClient();

  let student_id = props.location.state.data.student_id;

  let userName = props.data;

  let institution_Name = props.data.institution_name;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [Paymentnavigate, setPaymentnavigate] = useState([]);

  useEffect(() => {
    getParticularStudentInvoice();
  }, [client, student_id, institution_id]);

  const getParticularStudentInvoice = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.GET_Student_Invoice_Data,
        variables: {
          student_id,
        },
      });

      if (data && data.getInvoiceByStudentId) {
        setPaymentnavigate(
          data.getInvoiceByStudentId.filter((item) => {
            return (
              item.status === "PAID" || item.status === "VERIFICATION_PENDING"
            );
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">Payment History</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mt-3 mb-3">
                        <div className="mt-3 mb-2 Heading_bold">
                          Student Name : {getFullName(userName)}
                          <br />
                          {/* Class : {grade + "-" + section} */}
                        </div>
                        <div className="table_foramtitng ">
                          <TableContainer component={Paper}>
                            <div className="">
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Invoice Title
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Invoice Number
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Invoice Date
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Payment Due Date
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Invoice Amount
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Status
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className="table_header"
                                    >
                                      Details
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? Paymentnavigate.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : Paymentnavigate
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        scope="row"
                                        align="center"
                                      >
                                        <span>{row.invoice?.name}</span>
                                      </StyledTableCell>

                                      <StyledTableCell align="center">
                                        {row.invoice_display_id &&
                                          row.invoice_display_id}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {timeStampToDate(
                                          row.invoice?.create_date
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {timeStampToDate(row.invoice?.due_date)}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {currency_code && currency_code}{" "}
                                        {row.amount && row.amount}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.status && row.status}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="openassignemntcolor"
                                        // onClick={() =>
                                        //  props.navigate(
                                        //     "/invoiceDetailView",
                                        //     {
                                        //       studentdata:
                                        //         props.location.state
                                        //           .data,
                                        //       item: row,
                                        //     }
                                        //   )
                                        // }
                                        onClick={() =>
                                          props.navigate(
                                            "/viewInvoiceSummary",
                                            {
                                              state: {
                                                id: row.id,
                                                pageType: "StudentInvoice",
                                              },
                                            }
                                          )
                                        }
                                      >
                                        Open
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={Paymentnavigate.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentPaymentnavigate));
