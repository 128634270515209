import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { useApolloClient, useMutation } from "@apollo/client";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  CapitalizeFirstFn,
  getcurrentDate,
  getcurrentDatewithoutTimezone,
  showNotificationMsz,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";

function ManageExamination(props) {
  const { academic_session_id, json } = props.location.state;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [Delete_Exam, {}] = useMutation(Service.Delete_Exam);
  const [openRemarkdailog, setOpenRemarkdailog] = useState(false);

  const [openExamdialog, setopenExamdialog] = useState(false);
  const [examid, setexamid] = useState("");
  let firsttimr = true;
  const [examName, setexamName] = useState("");
  const [allSectionIdArr, setAllSectionIdArr] = useState([]);
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [sectionId, setSectionId] = useState(
    blankValidator(json?.SectionId) ? json?.SectionId : -1
  );
  const [gradeId, setGradeId] = useState(
    blankValidator(json?.SectionId) ? json?.ClassId : -1
  );

  const client = useApolloClient();
  const [search, setSearch] = useState("");
  const [ManageExamScheduleData, setManageExamScheduleData] = useState([]);

  const [ManageExamCompleteData, setManageExamCompleteData] = useState([]);

  const [ManageExamCancelledData, setManageExamCancelledData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getManageGrades();
  }, [academic_session_id]);

  useEffect(() => {
    changeGrade(gradeId);
  }, [gradeId]);
  useEffect(() => {
    if (+sectionId === -1) {
      getExamData(allSectionIdArr);
    } else {
      getExamData(sectionId);
    }
  }, [sectionId]);

  const changeSection = (val) => {
    setSectionId(+val);
  };
  const [isloading, setisloading] = useState(false);

  const getManageGrades = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_id),
          },
        })
        .then((response) => {
          if (response.data.getGrade.length !== 0) {
            setGradeArr(response.data.getGrade);
          }
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const getExamData = (arrayOrId) => {
    try {
      if (+arrayOrId === -1) return;
      console.log("arrayOrId", arrayOrId);
      setisloading(true);
      client
        .query({
          query: Service.Get_Exam,
          variables: {
            section_id: arrayOrId,
          },
        })
        .then((response) => {
          const { getExam } = response.data;

          if (getExam) {
            setManageExamScheduleData(
              getExam.filter((item) => {
                if (item?.end_calendar) {
                  return (
                    getcurrentDatewithoutTimezone(
                      item?.end_calendar?.datetime
                    ) >= new Date() && item.status !== "CANCELLED"
                  );
                } else {
                  return false;
                }
              })
            );

            setManageExamCompleteData(
              getExam.filter((item) => {
                if (item?.end_calendar) {
                  return (
                    getcurrentDatewithoutTimezone(
                      item?.end_calendar?.datetime
                    ) < new Date() && item.status !== "CANCELLED"
                  );
                } else {
                  return false;
                }
              })
            );

            setManageExamCancelledData(
              getExam.filter((item) => {
                return item.status === "CANCELLED";
              })
            );
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const changeGrade = (val) => {
    let grade_id = parseInt(val);
    setGradeId(grade_id);
    if (firsttimr != true) {
      setSectionId(-1);
      firsttimr = false;
    }

    setSectionArr([]);
    setManageExamScheduleData([]);
    setManageExamCompleteData([]);
    setManageExamCancelledData([]);
    if (grade_id === -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          let tempArr = [];
          const { getSectionAndGrade } = response.data;
          getSectionAndGrade.map((item) => {
            tempArr.push(+item.id);
          });
          console.log("tempArr", tempArr);
          getExamData(tempArr);
          setAllSectionIdArr(tempArr);
          setisloading(false);
          setSectionArr(getSectionAndGrade);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const deleteExam = (data) => {
    setexamid(data.id);
    setexamName(data.name);
    setopenExamdialog(true);
  };

  const deleteExamWork = () => {
    try {
      setisloading(true);
      Delete_Exam({
        variables: {
          id: parseInt(examid),
        },
      })
        .then((response) => {
          showNotificationMsz(response.data.deleteExam.message, "success");

          // setAssignmentarr(response.data.getAssignment);
          getExamData(sectionId);
          setisloading(false);
          setopenExamdialog(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);

  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(5);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const filterExamSchedule = ManageExamScheduleData.filter((event) => {
    return event.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterExamCompleted = ManageExamCompleteData.filter((event) => {
    return event.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const filterExamCancel = ManageExamCancelledData.filter((event) => {
    return event.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage2 = (event, newPage2) => {
    setPage2(newPage2);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const closeRemarkdailog = () => {
    setOpenRemarkdailog(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    View Examinations
                  </div>
                  <div className="mr-3 mt-2 Heading_bold">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={() =>
                        props.navigate("/adminAddExam", {
                          state: {
                            academic_session_id: parseInt(academic_session_id),
                          },
                        })
                      }
                    >
                      Create Exam
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={gradeId}
                                onChange={(e) => changeGrade(e.target.value)}
                              >
                                <option value={-1}>Search By Class</option>

                                {gradeArr.length > 0 &&
                                  gradeArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                value={sectionId}
                                onChange={(e) => changeSection(e.target.value)}
                              >
                                <option value={-1}>Search By Section</option>

                                {sectionArr.length > 0 &&
                                  sectionArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Name"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={3}></Grid>
                        </Grid>
                        <div>
                          <Grid className="grid_main_div">
                            <Grid item md={8}>
                              <div className=" mt-2 ml-2 mr-2 mybarwidth">
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  //  variant="scrollable"
                                  aria-label="simple tabs example"
                                  //className="tab_width_exam"
                                  indicatorColor="primary"
                                  textColor="primary"
                                  left
                                >
                                  <Tab
                                    label="Scheduled"
                                    {...a11yProps(0)}
                                    className="button_login_decoration text_tranformation_exam"
                                  />
                                  <Tab
                                    label="Completed"
                                    {...a11yProps(1)}
                                    className="button_login_decoration text_tranformation_exam"
                                  />
                                  <Tab
                                    label="Cancelled"
                                    {...a11yProps(2)}
                                    className="button_login_decoration text_tranformation_exam"
                                  />
                                </Tabs>
                              </div>
                            </Grid>
                            <Grid item md={4}></Grid>
                          </Grid>

                          <TabPanel value={value} index={0}>
                            <div>
                              <div className="table_foramtitng mt-3 mb-3">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Class
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Section
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="center"
                                          className="table_header"
                                        >
                                          Action
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage > 0
                                        ? filterExamSchedule.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                          )
                                        : filterExamSchedule
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {row.name === undefined ||
                                            row.name === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(row.name)}
                                              </div>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.section === undefined ||
                                            row.section === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(
                                                  row.section.grade.gradeName
                                                )}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.section === undefined ||
                                            row.section === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(
                                                  row.section.name
                                                )}
                                              </div>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell align="center">
                                            <div>
                                              <span
                                                className="openassignemntcolor mr-2"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/adminUpdateExam",
                                                    {
                                                      state: {
                                                        data: row,
                                                        sectionId: sectionId,
                                                        gradeId: gradeId,
                                                        pagetype:
                                                          "Update Subject Schedule",
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Edit
                                              </span>
                                              |
                                              <span
                                                className="openassignemntcolor ml-2"
                                                onClick={() => {
                                                  deleteExam(row);
                                                }}
                                              >
                                                Cancel
                                              </span>
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterExamSchedule.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <div>
                              <div className="table_foramtitng mt-3 mb-3">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Class
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Section
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Open
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage1 > 0
                                        ? filterExamCompleted.slice(
                                            page1 * rowsPerPage1,
                                            page1 * rowsPerPage1 + rowsPerPage1
                                          )
                                        : filterExamCompleted
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {row.name === undefined ||
                                            row.name === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(row.name)}
                                              </div>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.section === undefined ||
                                            row.section === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(
                                                  row.section.grade.gradeName
                                                )}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.section === undefined ||
                                            row.section === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(
                                                  row.section.name
                                                )}
                                              </div>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="openassignemntcolor"
                                          >
                                            {row.status === "PUBLISHED" ? (
                                              <span
                                                role="button"
                                                className="text-info"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/adminExamStudentList",
                                                    {
                                                      state: {
                                                        data: row,
                                                        sectionId: sectionId,
                                                        gradeId: gradeId,
                                                        pagetype: "View Result",
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Results
                                              </span>
                                            ) : (
                                              <span
                                                role="button"
                                                className="text-info"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/adminExamStudentList",
                                                    {
                                                      state: {
                                                        data: row,
                                                        sectionId,
                                                        gradeId,
                                                        pagetype: "Enter Marks",
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Enter Marks
                                              </span>
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>{" "}
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterExamCompleted.length}
                                    rowsPerPage={rowsPerPage1}
                                    page={page1}
                                    onChangePage={handleChangePage1}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage1
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <div>
                              <div className="table_foramtitng mt-3 mb-3">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Class
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Section
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Action
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage > 0
                                        ? filterExamCancel.slice(
                                            page2 * rowsPerPage2,
                                            page2 * rowsPerPage2 + rowsPerPage2
                                          )
                                        : filterExamCancel
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {row.name === undefined ||
                                            row.name === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(row.name)}
                                              </div>
                                            )}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.section === undefined ||
                                            row.section === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(
                                                  row.section.grade.gradeName
                                                )}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row.section === undefined ||
                                            row.section === null ? (
                                              ""
                                            ) : (
                                              <div>
                                                {CapitalizeFirstFn(
                                                  row.section.name
                                                )}
                                              </div>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <span
                                              className="openassignemntcolor mr-2"
                                              onClick={() =>
                                                props.navigate(
                                                  "/adminUpdateExam",
                                                  {
                                                    state: {
                                                      data: row,
                                                      sectionId: sectionId,
                                                      gradeId: gradeId,
                                                      pagetype: "Cancelled",
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Open
                                            </span>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterExamCancel.length}
                                    rowsPerPage={rowsPerPage2}
                                    page={page2}
                                    onChangePage={handleChangePage2}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage2
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={openRemarkdailog}
        onClose={closeRemarkdailog}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_examcancel">
          <div>
            <div className="float-left ml-3 text_filed_heading">
              Confirmation
            </div>
            <div className="float-right mr-3 cross_font_size pb-4">
              <i class="fa fa-times cursor" onClick={closeRemarkdailog}></i>
            </div>
          </div>
          <hr style={{ marginTop: "30px" }} />
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <p className="text_font_size_toconfirm">
                  Are you sure you want to Cancel the Exam
                </p>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div">
            <Grid item md={3}></Grid>
            <Grid item md={6}>
              <div className="d-flex float-right mt-1">
                <div className="mr-3">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration button_width"
                  >
                    Confirm
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration button_width"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openExamdialog}
        onClose={() => {
          setopenExamdialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenExamdialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            "Are you sure want to delete the Examination : {examName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteExamWork(examid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenExamdialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageExamination));
