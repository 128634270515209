import React, { useState, useEffect } from "react";
import "./AssignmentAssign.css";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder";

//redux
import * as Service from "../../../services/index";
import { CapitalizeFirstFn } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ListOfAssignments = (props) => {
  useEffect(() => {
    if (props.data === null && props.data === undefined) {
     props.navigate("/");
    }
  }, [props]);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const client = useApolloClient();

  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [Deleteassignmentid, setDeleteassignmentid] = useState("");
  const [Deleteassignmentname, setDeleteassignmentname] = useState("");
  const [isloading, setisloading] = useState(false);

  const [subjectarr, setsubjectarr] = useState([]);

  const selectHandler = (val) => {};
  const [MAKE_STATUS_PUBLISH, {}] = useMutation(Service.MAKE_STATUS_PUBLISH);
  const teacher_id = parseInt(props.data.userid);
  const institution_id = parseInt(props.data.institution_id);
  const [DELETE_TEACHER_ASSIGNMENT, {}] = useMutation(
    Service.DELETE_TEACHER_ASSIGNMENT
  );
  const [isUpdated, setisUpdated] = useState(false);
  const [isUpdated2, setisUpdated2] = useState(false);
  useEffect(() => {
    const getsubjectlist = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setsubjectarr(response.data.getSubject);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        console.log("t and c error", error);
      }
      return () => isUpdated(false);
    };

    const getAssignmentdata = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Teacher_Assignment_Data,
            variables: {
              teacher_id,
              academic_session_id: parseInt(props.dataacademic.id),
            },
          })
          .then((response) => {
            setisloading(false);
            if (response.data.getAssignment.length > 0) {
              setAssignmentdata(response.data.getAssignment);
            }
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
      return () => isUpdated2(false);
    };
    getAssignmentdata();
    getsubjectlist();
  }, [
    client,
    isUpdated2,
    isUpdated,
    institution_id,
    teacher_id,
    props.dataacademic.id,
  ]);

  const publishAssignment = (publishid) => {
    setisloading(true);
    try {
      MAKE_STATUS_PUBLISH({
        variables: {
          id: parseInt(publishid),
        },
      }).then(
        (response) => {
          if (
            response.data.publishAssignment !== null ||
            response.data.publishAssignment !== undefined
          ) {
            if (
              response.data.publishAssignment.message !== null ||
              response.data.publishAssignment.message !== undefined
            ) {
              alert(response.data.publishAssignment.message);
            }
            setisloading(false);
            setisUpdated2(true);
            setisUpdated(true);
          }
          setisloading(false);
          setisUpdated2(true);
          setisUpdated(true);
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const assignAssignment = (item) => {
   props.navigate("/assignmentAssign", {
      item: item,
    });
  };

  const DeleteDailogBox = (item) => {
    setDeleteassignmentid(item.id);
    setDeleteassignmentname(item.name);
    setopendialogConfimation(true);
  };

  const cancelAssignment = (ID) => {
    let id = parseInt(ID);
    try {
      DELETE_TEACHER_ASSIGNMENT({
        variables: { id },
      }).then(
        (response) => {
          setopendialogConfimation(false);
          alert(response.deleteAssignment.message);
          setisUpdated2(true);
        },
        (err) => {
          console.log("then error", err);
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const [Assignmentdata, setAssignmentdata] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    List of Assignments
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => selectHandler(e.target.value)}
                              >
                                <option>Search By Subject</option>

                                {subjectarr.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => selectHandler(e.target.value)}
                              >
                                <option>Search By Assignment</option>

                                {Assignmentdata.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Assignment
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Details
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Assign
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Cancel
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? Assignmentdata.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : Assignmentdata
                                ).map((row, index) => (
                                  <StyledTableRow>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.subject === undefined ||
                                      row.subject === null ||
                                      row.subject === "" ? (
                                        ""
                                      ) : (
                                        <div>
                                          {row.subject.name === "" ||
                                          row.subject.name === null ||
                                          row.subject.name === undefined ? (
                                            " "
                                          ) : (
                                            <span>
                                              {CapitalizeFirstFn(
                                                row.subject.name
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.name !== null && row.name}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                      onClick={() =>
                                       props.navigate(
                                          "/updateAssignment",
                                          {
                                            data: row,
                                            pagetype: "Update Assignment",
                                          }
                                        )
                                      }
                                    >
                                      Open
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.status}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.status === "DRAFT" ? (
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            publishAssignment(row.id)
                                          }
                                          className="add_new_query_btn button_login_decoration button_width"
                                        >
                                          Publish
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          onClick={() => assignAssignment(row)}
                                          className="add_new_query_btn button_login_decoration button_width"
                                        >
                                          Assign
                                        </Button>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <Button
                                        variant="contained"
                                        onClick={() => DeleteDailogBox(row)}
                                        className="add_new_query_btn button_login_decoration"
                                      >
                                        Cancel
                                      </Button>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[10, 30, 60, 100]}
                              component="div"
                              count={Assignmentdata.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Assignment : {Deleteassignmentname}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => cancelAssignment(Deleteassignmentid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(ListOfAssignments)));
