import React, { useState, useEffect } from "react";
import "./Fees.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import {
  blankValidator,
  getFullName,
  serverFormatDate,
  showNotificationMsz,
  timeStampToDate,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
const config = require("../../../config/default.json");

const ListOfInvoices = ({ location, navigate, PARENT_DATA }) => {
  //----------------------------------global id----------------------
  const { currency_code, email_address, online_payment, institution_id } =
    PARENT_DATA;
  // deconstruction of object-------------------------
  const { name, section, id } = location?.state?.studentData;
  const institution_name = location?.state?.institution_name;
  //----------------------------------api calls----------------------
  const client = useApolloClient();
  const classes = useStyles();
  //----------------------------------local state----------------------
  let temp = new Date();
  const [due_date, setdue_date] = useState(temp);
  const [funding_instrument_type, setfunding_instrument_type] = useState("");
  const [bank, setbank] = useState("");
  const [invoiceid, setinvoiceid] = useState("");
  const [amount, setamount] = useState("");
  const [amountError, setamountError] = useState(false);
  const [invoiceidError, setinvoiceidError] = useState(false);
  const [bankError, setbankError] = useState(false);
  const [StudentInvoiceid, setStudentInvoiceid] = useState("");
  const [funding_instrument_typeError, setfunding_instrument_typeError] =
    useState(false);
  const [assignmenteditDialog, setassignmenteditDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ListInvoiceData, setListInvoiceData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Add_Payment, {}] = useMutation(Service.Add_Payment);
  //----------------------------------methods----------------------

  useEffect(() => {
    getStudentInvoiceList();
  }, []);

  const getStudentInvoiceList = async () => {
    try {
      setisloading(true);
      const response = await client.query({
        query: Service.GET_Student_Invoice_Data,
        variables: {
          student_id: parseInt(id),
        },
      });

      setListInvoiceData(
        response.data.getInvoiceByStudentId.filter((item) => {
          return item.status != "PAID";
        })
      );
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startPayment = (data) => {};
  const SubmitAlreadyPaid = () => {
    let flag = false;
    if (!blankValidator(funding_instrument_type)) {
      setfunding_instrument_typeError(true);
      flag = true;
    }
    if (!blankValidator(bank)) {
      setbankError(true);
      flag = true;
    }
    if (!blankValidator(amount)) {
      setamountError(true);
      flag = true;
    }
    if (!blankValidator(invoiceid)) {
      setinvoiceidError(true);
      flag = true;
    }

    if (flag) {
      return;
    }
    const obj = {
      path: "",
      filename: "",
      type: "",
      name: "",
    };

    const temp = [];
    temp.push(obj);

    let val = {
      funding_instrument_type,
      payment_date: serverFormatDate(due_date),
      student_invoice_id: parseInt(StudentInvoiceid),
      fi_source: bank,
      external_reference_number: invoiceid,
      amount: amount,
      notes: "",
      payment_doc: temp,
    };
    try {
      setisloading(true);
      Add_Payment({
        variables: {
          funding_instrument_type,
          payment_date: serverFormatDate(due_date),
          student_invoice_id: parseInt(StudentInvoiceid),
          fi_source: bank,
          external_reference_number: invoiceid,
          amount: amount,
          notes: "",
          payment_doc: temp,
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.addPayment.message, "success");
          getStudentInvoiceList();
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }
  const post = (details) => {
    setisloading(true);
    window.localStorage.setItem("refreshKey", PARENT_DATA.tokenid);
    try {
      var config = {
        root: "",
        data: {
          orderId: details.orderId /* update order id */,
          token: details.txnToken /* update token value */,
          tokenType: "TXN_TOKEN",
          amount: "123123" /* update amount */,
        },
        handler: {
          notifyMerchant: function (eventName, data) {},
        },
      };

      let checkout = window.Paytm.CheckoutJS.onLoad(startPayment);

      // initialze configuration using init method
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          setisloading(false);
          // after successfully updating configuration, invoke JS Checkout
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          alert("Please Contact to Admin");
          setisloading(false);
          console.log("error => ", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };
  const paytmHandler = async (userDetails) => {
    try {
      setisloading(true);

      const { amount, invoice_display_id, id } = userDetails;

      const url = Service.payUrl;
      const paymentPayload = {
        email: email_address,
        phone: "", // phone number is not provided for student
        amount,
        order_id: invoice_display_id,
        invoice_id: id,
        gateway_name: config.config.gateWayName,
        institution_id,
      };
      const { data } = await axios.post(url, paymentPayload, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (data) {
        post(data);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
      console.log(error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">Invoices</div>
                  <div className="mt-3 mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={() =>
                        navigate("/paymentnavigate", {
                          state: {
                            data: location.state,
                          },
                        })
                      }
                    >
                      Payment History
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="Heading_bold mt-2">
                          Student Name :{getFullName(name)}
                          <br />
                          Class : {section?.grade?.gradeName} {section?.name}
                        </div>
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header table_Padding_invoice_list"
                                    align="left"
                                  >
                                    Invoice Title
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_Padding_invoice_list"
                                  >
                                    Invoice Number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_Padding_invoice_list"
                                  >
                                    Invoice Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_Padding_invoice_list"
                                  >
                                    Payment Due Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_Padding_invoice_list"
                                  >
                                    Due Amount
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    align="left"
                                    className="table_header table_Padding_invoice_list"
                                  >
                                    Status
                                  </StyledTableCell> */}

                                  <StyledTableCell
                                    align="center"
                                    className="table_header table_Padding_invoice_list"
                                  >
                                    Action
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header table_Padding_invoice_list"
                                  ></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? ListInvoiceData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : ListInvoiceData
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_Padding_invoice_list"
                                    >
                                      {row.invoice?.name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_Padding_invoice_list"
                                    >
                                      {row.invoice_display_id &&
                                        row.invoice_display_id}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_Padding_invoice_list"
                                    >
                                      {timeStampToDate(
                                        row.invoice?.create_date
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_Padding_invoice_list"
                                    >
                                      {timeStampToDate(row.invoice?.due_date)}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_Padding_invoice_list"
                                    >
                                      {currency_code && currency_code}{" "}
                                      {row.due_amount && row.due_amount}
                                    </StyledTableCell>
                                    {/* <StyledTableCell
                                      align="left"
                                      className="table_Padding_invoice_list"
                                    >
                                      {row.status && row.status}
                                    </StyledTableCell> */}

                                    <StyledTableCell
                                      align="center"
                                      className=" "
                                    >
                                      <div className="d-flex justify-content-center">
                                        {row?.status === "DUE" ||
                                        row?.status === "PARTIALLY PAID" ? (
                                          <div>
                                            <span
                                              className="row_details_color pr-2"
                                              onClick={() =>
                                                navigate(
                                                  "/invoiceDetailAlreadyPaid",
                                                  {
                                                    state: {
                                                      institution_Name:
                                                        institution_name,
                                                      rowData: row,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Already Paid
                                            </span>
                                            {online_payment === "Enable" && (
                                              <>
                                                |
                                                <span
                                                  className="row_details_color pl-2 pr-2"
                                                  onClick={() => {
                                                    paytmHandler(row);
                                                  }}
                                                >
                                                  Pay
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <span
                                          className="row_details_color pl-2 pr-2"
                                          onClick={() =>
                                            navigate("/viewInvoiceSummary", {
                                              state: {
                                                id: row.id,
                                                status: row.status,
                                                pageType: "StudentInvoice",
                                              },
                                            })
                                          }
                                        >
                                          Open
                                        </span>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={ListInvoiceData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>

                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="d-flex float-right"></div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={assignmenteditDialog}
        onClose={() => {
          setassignmenteditDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting update_dailog_border"
      >
        <div className="dailog_width_review_leave p-3">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setassignmenteditDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            {/* <b>Update Assignment :</b>{" "} */}
            <span className="font-weight-bold">Pay At</span>
          </div>

          <label className="float-left mt-1">Type</label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            autoComplete="off"
            value={funding_instrument_type}
            onChange={(e) => {
              setfunding_instrument_typeError(false);
              setfunding_instrument_type(e.target.value);
            }}
          />
          {funding_instrument_typeError && (
            <span className="text-danger">Invoive Id</span>
          )}

          <label className="float-left mt-1">Bank</label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            autoComplete="off"
            value={bank}
            onChange={(e) => {
              setbankError(false);
              setbank(e.target.value);
            }}
          />
          {bankError && <span className="text-danger">Enter bank Name</span>}
          <label className="float-left mt-1">Refrence Id</label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            autoComplete="off"
            value={invoiceid}
            onChange={(e) => {
              setinvoiceidError(false);
              setinvoiceid(e.target.value);
            }}
          />
          {invoiceidError && <span className="text-danger">Invoive Id</span>}

          <label className="float-left mt-1">Amount</label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            autoComplete="off"
            value={amount}
            onChange={(e) => {
              setamountError(false);
              setamount(e.target.value);
            }}
          />
          {amountError && <span className="text-danger">Enter Amount</span>}

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={SubmitAlreadyPaid}
                >
                  Pay
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setassignmenteditDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  PARENT_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ListOfInvoices));
