import React, { Fragment, Component } from "react";
import Footer from "./Footer/Footer.jsx";
import ForgotpasswordHeader from "./Header/ForgotpasswordHeader.jsx";

const UpdateComponent = (Wcomponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Fragment>
          <div className="app-container-hoc main-margin">
            <ForgotpasswordHeader {...this.props} />
            <Wcomponent {...this.props} />
            <Footer {...this.props} />
          </div>
        </Fragment>
      );
    }
  };
};

export default UpdateComponent;
