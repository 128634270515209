import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Expand from "react-expand-animated";


import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { CapitalizeFirstFn } from "../../../utils/Validation";

function StudentAssignmentResult(props) {
  //stdent Name
  let userName = props.location.state.studentdata.row.name;

  //section
  let section = props.location.state.studentdata.row.student.section.name;

  //class
  let grade =
    props.location.state.studentdata.row.student.section.grade.gradeName;

  //assignment name
  let assignment = props.location.state.item.assignment.name;

  //status
  let status = props.location.state.item.status;
  //Institute name
  let institution_Name = props.location.state.studentdata.institution_Name;

  const [AssignmnetReview, setAssignmnetReview] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [totalMarks, settotalMarks] = useState("");
  const [obtainedMarks, setobtainedMarks] = useState("");
  const [teacherremarks, setteacherremarks] = useState("");

  const client = useApolloClient();
  const parse = require("html-react-parser");
  // ----------------METHODS-------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    getAssignmentdata();
  }, [props]);
  const getAssignmentdata = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Assignment_Completed_Result,
          variables: {
            assignment_id: parseInt(props.location.state.item.assignment_id),
            student_assignment_id: parseInt(props.location.state.item.id),
          },
        })
        .then(
          (response) => {
            settotalMarks(
              response.data.getAssignmentResult[1].assignment.total_score
            );
            setobtainedMarks(
              response.data.getAssignmentResult[1].student_assignment.score
            );
            setteacherremarks(
              response.data.getAssignmentResult[1].student_assignment.remarks
            );
            let temp = [];
            response.data.getAssignmentResult.map(async (data) => {
              data["show"] = false;
              //   await getuseranswerandrealAnswer(data, data.id);
              temp.push(data);
            });

            if (
              response.data.getAssignmentResult.length !== null ||
              response.data.getAssignmentResult !== undefined ||
              response.data.getAssignmentResult.length > 0
            ) {
              setAssignmnetReview(temp);
            }

            setisloading(false);
            //   setAssignmentarr(response.data.getAssignmentByStatus);
          },
          (err) => {
            console.log("then err", err);
            setisloading(false);
          }
        )
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const gobackprevoius = () => {
    props.navigate.goBack();
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />
              <div className="pageBackButton">
                <span onClick={gobackprevoius} className="manage_edit_cursor">
                  {" "}
                  <i class="fa fa-chevron-left"></i> Back
                </span>
              </div>
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Assignment Result
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="text_filed_heading">
                              Student Name
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Mark Willy"
                                autoComplete="off"
                                value={
                                  CapitalizeFirstFn(userName.first_name) +
                                  " " +
                                  userName.middle_name +
                                  " " +
                                  userName.last_name
                                }
                                disabled={true}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="text_filed_heading">Class</div>
                            <div className="inputMarginright mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="2-A"
                                autoComplete="off"
                                value={grade + "-" + section}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="text_filed_heading">
                              Assignment Name
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Lorem ipsum"
                                autoComplete="off"
                                value={CapitalizeFirstFn(assignment)}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="text_filed_heading">Status</div>
                            <div className="inputMarginright mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="2-A"
                                autoComplete="off"
                                value={status}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="text_filed_heading">Score</div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="40/50"
                                autoComplete="off"
                                disabled={true}
                                value={obtainedMarks + "/" + totalMarks}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="text_filed_heading">Remarks</div>
                            <div className="inputMarginright mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder=""
                                autoComplete="off"
                                disabled={true}
                                value={teacherremarks}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>
                        <hr style={{ marginTop: "4%", marginBottom: "4%" }} />

                        <div className="mt-3">
                          {AssignmnetReview.map((item, index) => (
                            <Card
                              className="pt-2 pb-2 cardvisible_border mt-2"
                              key={index}
                            >
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_assignment_result_data">
                                    <span className="assignmnt_question_width d-flex">
                                      {" "}
                                      Q.
                                      {item.assignment_question === undefined ||
                                      item.assignment_question === null ? (
                                        ""
                                      ) : item.assignment_question
                                          .question_number === undefined ||
                                        item.assignment_question
                                          .question_number === null ? (
                                        ""
                                      ) : (
                                        <span>
                                          {
                                            item.assignment_question
                                              .question_number
                                          }
                                        </span>
                                      )}{" "}
                                      {item.assignment_question === undefined ||
                                      item.assignment_question === null ? (
                                        ""
                                      ) : item.assignment_question
                                          .description === undefined ||
                                        item.assignment_question.description ===
                                          null ? (
                                        ""
                                      ) : (
                                        <span>
                                          {parse.default(
                                            item.assignment_question.description
                                          )}
                                        </span>
                                      )}
                                    </span>

                                    <span className="text-center">
                                      {item.assignment_question === undefined ||
                                      item.assignment_question === null ? (
                                        ""
                                      ) : item.assignment_question
                                          .question_section === undefined ||
                                        item.assignment_question
                                          .question_section === null ? (
                                        ""
                                      ) : (
                                        <span>
                                          {
                                            item.assignment_question
                                              .question_section.name
                                          }
                                        </span>
                                      )}
                                    </span>

                                    <div className="float-right">
                                      <span className="mr-2">
                                        {" "}
                                        {item.teacher_marks === undefined ||
                                        item.teacher_marks === null ? (
                                          ""
                                        ) : (
                                          <span>
                                            {item.teacher_marks} Marks
                                          </span>
                                        )}
                                      </span>
                                      {item.show === false ? (
                                        <span
                                          className="iconstoaddfee"
                                          onClick={() => {
                                            AssignmnetReview[index].show = true;
                                            setAssignmnetReview([
                                              ...AssignmnetReview,
                                            ]);
                                            // setassignmentresult(true)
                                          }}
                                        >
                                          <i className="fa fa-plus-circle"></i>
                                        </span>
                                      ) : (
                                        <span
                                          className="iconstoaddfee"
                                          onClick={() => {
                                            AssignmnetReview[
                                              index
                                            ].show = false;
                                            setAssignmnetReview([
                                              ...AssignmnetReview,
                                            ]);
                                            // setassignmentresult(true)
                                          }}
                                        >
                                          <i className="fa fa-minus-circle"></i>
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <Expand open={item.show}>
                                    <div className="mt-3">
                                      <hr />
                                      <Grid className="grid_main_div">
                                        <Grid item md={2}>
                                          <div className=" assignment_result_data_font">
                                            Submitted Answer:{" "}
                                          </div>
                                        </Grid>
                                        <Grid item md={10}>
                                          <div>
                                            {item &&
                                              item.answer &&
                                              parse.default(item.answer)}
                                            {/* {item.answer === undefined ||
                                            item.answer === null ? (
                                              ""
                                            ) : (
                                              <span>
                                                {parse.default(item.answer)}
                                              </span>
                                            )} */}
                                          </div>
                                        </Grid>
                                      </Grid>

                                      <Grid className="grid_main_div mt-4">
                                        <Grid item md={2}>
                                          <div className="assignment_result_data_font">
                                            Correct Answer:{" "}
                                          </div>
                                        </Grid>
                                        <Grid item md={10}>
                                          <div className="font_color_data_assignment">
                                            {item &&
                                              item.assignment_question &&
                                              parse.default(
                                                item.assignment_question?.answer
                                              )}
                                            {/* {item.assignment_question ===
                                              null ||
                                            item.assignment_question === "" ||
                                            item.assignment_question ===
                                              undefined ? (
                                              ""
                                            ) : (
                                              <span>
                                                {item.assignment_question
                                                  .answer === null ||
                                                item.assignment_question
                                                  .answer === "" ||
                                                item.assignment_question
                                                  .answer === undefined ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {parse.default(
                                                      item.assignment_question
                                                        .answer
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )} */}
                                          </div>
                                        </Grid>
                                      </Grid>

                                      <div className="mt-5">
                                        <hr />
                                      </div>

                                      <div className="mt-3">
                                        <div className="text_filed_heading">
                                          Notes
                                        </div>
                                        <Card className="pt-3 pb-5 cardvisible_border">
                                          <div className="mt-1 ml-2 mr-2">
                                            {item.remarks === "" ||
                                            item.remarks === null ||
                                            item.remarks === undefined ? (
                                              ""
                                            ) : (
                                              <span>{item.remarks} </span>
                                            )}
                                          </div>
                                        </Card>
                                      </div>

                                      <Grid className="grid_main_div mt-2">
                                        <Grid item md={6}>
                                          <div className="text_filed_heading">
                                            Marks(Max.
                                            {item.assignment_question.marks})
                                          </div>
                                          <div className="inputMarginright mt-2">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              placeholder=""
                                              autoComplete="off"
                                              disabled={true}
                                              value={item.teacher_marks}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item md={6}></Grid>
                                      </Grid>
                                    </div>
                                  </Expand>
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default (HOC1(StudentAssignmentResult));
