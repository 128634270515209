import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./AssignmentAssign.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import * as Service from "../../../services/index";
import Loder from "../../Loder/Loder";
import {
  blankValidator,
  CapitalizeFirstFn,
  showNotificationMsz,
  mulitpleAnswerHandler,
  getFullName,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Constants from "../../../action/appAction";
const TeacherReviewOfAnswer = (props) => {
  const [isloading, setisloading] = useState(false);
  const client = useApolloClient();
  const parse = require("html-react-parser");
  const [AssignmnetReview, setAssignmnetReview] = useState([]);
  const [openRemarkdailog, setSpenRemarkdailog] = useState(false);

  const [assignmnetRemark, setassignmnetRemark] = useState("");

  const [mainremarksError, setmainremarksError] = useState(false);
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);
  const [totalMarks, settotalMarks] = useState("");

  const [In_Progress_Assignment, {}] = useMutation(
    Service.In_Progress_Assignment
  );
  const [Update_Assignment_Submission, {}] = useMutation(
    Service.Update_Assignment_Submission
  );
  const [Make_Assignment_Complete_By_Teacher, {}] = useMutation(
    Service.Make_Assignment_Complete_By_Teacher
  );
  let assignmentId = props.location.state.assignmentData.id;

  useEffect(() => {
    getAssignmentdata();
  }, [props]);
  useEffect(() => {
    let marks = 0;
    AssignmnetReview.map((data, index) => {
      if (
        data.assignment_submission != undefined &&
        data.assignment_submission != null
      ) {
        if (/^[0-9]+$/.test(data.assignment_submission.teacher_marks)) {
          marks = marks + data.assignment_submission.teacher_marks;
        }
      }
    });
    settotalMarks(marks);
  }, [AssignmnetReview]);

  // ----------------METHODS-------------------

  const getAssignmentdata = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Assignment_Review_By_Teacher,
          variables: {
            assignment_id: parseInt(props.location.state.assignmentData.id),
            student_assignment_id: parseInt(
              props.location.state.studentData.id
            ),
          },
        })
        .then((response) => {
          try {
            if (
              response.data.studentReviewSubmittedAssignment !== undefined ||
              response.data.studentReviewSubmittedAssignment.length !== null ||
              response.data.studentReviewSubmittedAssignment.length > 0
            ) {
              let temp = [];
              response.data.studentReviewSubmittedAssignment.map(
                async (data) => {
                  data["show"] = false;
                  temp.push(data);
                }
              );
              setAssignmnetReview(temp);
            }
            setisloading(false);
          } catch (error) {
            showNotificationMsz(error, "warning");
          }
        })
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const submitAssignmnetResult = () => {
    try {
      setisloading(true);
      Make_Assignment_Complete_By_Teacher({
        variables: {
          remarks: assignmnetRemark,
          score: totalMarks,
          assignment_id: parseInt(assignmentId),
          student_assignment_id: parseInt(props.location.state.studentData.id),
        },
      })
        .then(
          (response) => {
            showNotificationMsz(
              response.data.completeAssignment.message,
              "success"
            );

            // setAssignmentarr(response.data.getAssignment);
            setisloading(false);
            props.navigate("/teacherHomePage");
          },
          (err) => {
            setisloading(false);
            console.log("then err", err);
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const submitquestionresult = (item, index, type) => {
    let status = "";
    if (type === "CORRECT") {
      status = "CORRECT";
    } else {
      status = "INCORRECT";
    }
    try {
      setisloading(true);
      Update_Assignment_Submission({
        variables: {
          student_assignment_id: parseInt(props.location.state.studentData.id),
          assignment_question_id: parseInt(item.id),
          assignment_id: parseInt(assignmentId),
          teacher_marks: parseInt(item.assignment_submission.teacher_marks),
          remarks: item.assignment_submission.remarks,
          answer_status: status,
        },
      })
        .then(
          (response) => {
            showNotificationMsz(
              response.data.updateAssignmentSubmission.message,
              "success"
            );

            AssignmnetReview[index].show = false;
            setAssignmnetReview([...AssignmnetReview]);
            setisloading(false);
          },
          (err) => {
            AssignmnetReview[index].show = false;
            setAssignmnetReview([...AssignmnetReview]);
            setisloading(false);
            console.log("then err", err);
          }
        )
        .catch((err) => {
          AssignmnetReview[index].show = false;
          setAssignmnetReview([...AssignmnetReview]);
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      AssignmnetReview[index].show = false;
      setAssignmnetReview([...AssignmnetReview]);
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const closeRemarkdailog = () => {
    setSpenRemarkdailog(false);
  };

  const reject = () => {
    try {
      setisloading(true);
      In_Progress_Assignment({
        variables: {
          assignment_id: parseInt(assignmentId),
          user_id: props.location.state.studentData.student.id,
        },
      })
        .then(
          (response) => {
            showNotificationMsz(
              response.data.inProgressAssignment.message,
              "success"
            );
            setisloading(false);
            props.navigate("/teacherHomePage");
          },
          (err) => {
            setisloading(false);
            console.log("then err", err);
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };
  return (
    <>
      <div className="main_full_width">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={props.data.institution_name} />
                <div className="enquirydetails_background mb-2">
                  <div className="enquiry_heading">
                    <div className="ml-3 mt-2 Heading_bold">
                      {props.location.state.assignmentData.name === undefined ||
                      props.location.state.assignmentData.name === null ? (
                        ""
                      ) : (
                        <span className="text-info">
                          {" "}
                          {CapitalizeFirstFn(
                            props.location.state.assignmentData.name
                          )}
                        </span>
                      )}
                      <br />

                      <span className="text-info">
                        {CapitalizeFirstFn(
                          getFullName(
                            props.location.state.studentData.student
                              .student_profile.name
                          )
                        )}
                      </span>

                      <span>
                        (Roll No:{" "}
                        {props.location.state.studentData.student.roll_number})
                      </span>
                      {props.location.state.assignmentData.sectionName ===
                        undefined ||
                      props.location.state.assignmentData.sectionName ===
                        null ? (
                        ""
                      ) : (
                        <span className="text-info">
                          {" "}
                          {" , " +
                            CapitalizeFirstFn(
                              props.location.state.assignmentData.sectionName
                            )}
                        </span>
                      )}
                      {props.location.state.assignmentData.gradeName ===
                        undefined ||
                      props.location.state.assignmentData.gradeName === null ? (
                        ""
                      ) : (
                        <span className="text-info">
                          {" "}
                          {" - " +
                            CapitalizeFirstFn(
                              props.location.state.assignmentData.gradeName
                            )}
                        </span>
                      )}

                      {props.location.state.assignmentData.assignmentName ===
                        undefined ||
                      props.location.state.assignmentData.assignmentName ===
                        null ? (
                        ""
                      ) : (
                        <span className="text-info">
                          {" "}
                          {" , " +
                            CapitalizeFirstFn(
                              props.location.state.assignmentData.assignmentName
                            )}
                        </span>
                      )}
                    </div>
                    <div className="button_main_flex invoice_btn_width mt-4">
                      {/* <Button
											variant="contained"
											className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_teacher_review"
										>
											Save as Draft
										</Button> */}
                      <Button
                        variant="contained"
                        className="add_new_query_btn button_width button_login_decoration btn_font_teacher_review mr-3"
                        onClick={submitAssignmnetResult}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        className="add_new_query_btn button_width button_login_decoration btn_font_teacher_review mr-3"
                        onClick={reject}
                      >
                        Reject
                      </Button>
                    </div>
                  </div>
                  <div className="card_margin_both_side">
                    <Card className="pt-2 pb-4">
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <div className="text-right openassignemntcolor mt-2 mb-2">
                            Total Marks : {totalMarks}
                          </div>

                          <div className="mt-3">
                            {AssignmnetReview.map((item, index) => (
                              <Card
                                className="pt-2 pb-2 cardvisible_border mt-2"
                                key={index}
                                style={{
                                  borderColor:
                                    item["teacher_marks"] !== undefined &&
                                    item["teacher_marks"] !== ""
                                      ? "#0070bb "
                                      : "",
                                }}
                              >
                                <div className="card_admissiondetails_height">
                                  <div className="textfiled_margin">
                                    <Grid className="grid_main_div mt-2 mb-3">
                                      <Grid item md={8}>
                                        {blankValidator(item.description) && (
                                          <span className="d-flex">
                                            <span className="font-weight-bold mr-1">
                                              Q.{item.question_number}
                                            </span>
                                            <span>
                                              {parse.default(item.description)}
                                            </span>
                                          </span>
                                        )}
                                      </Grid>
                                      <Grid item md={2}>
                                        <span>
                                          {item.question_section ===
                                            undefined ||
                                          item.question_section === null ? (
                                            ""
                                          ) : (
                                            <span>
                                              {item.question_section.name}
                                            </span>
                                          )}
                                        </span>
                                      </Grid>
                                      <Grid item md={2}>
                                        <div className="d-flex justify-content-between">
                                          <span className="mr-2">
                                            {item.teacher_marks === undefined ||
                                            item.teacher_marks === null ? (
                                              ""
                                            ) : (
                                              <span className="mr-1">
                                                {item.teacher_marks} Marks
                                              </span>
                                            )}
                                          </span>

                                          {item.show === false ? (
                                            <span
                                              className="iconstoaddfee"
                                              onClick={() => {
                                                AssignmnetReview[
                                                  index
                                                ].show = true;
                                                setAssignmnetReview([
                                                  ...AssignmnetReview,
                                                ]);
                                                // setassignmentresult(true)
                                              }}
                                            >
                                              <i className="fa fa-plus-circle"></i>
                                            </span>
                                          ) : (
                                            <span
                                              className="iconstoaddfee"
                                              onClick={() => {
                                                AssignmnetReview[
                                                  index
                                                ].show = false;
                                                setAssignmnetReview([
                                                  ...AssignmnetReview,
                                                ]);
                                                // setassignmentresult(true)
                                              }}
                                            >
                                              <i className="fa fa-minus-circle"></i>
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>

                                    <Expand open={item.show}>
                                      {/* <Expand open={true}> */}
                                      <div className="mt-3">
                                        <hr />
                                        {blankValidator(
                                          item.assignment_choices
                                        ) &&
                                        item.assignment_choices.length > 0 ? (
                                          <div>
                                            <Grid className="grid_main_div">
                                              <Grid item md={6}>
                                                {blankValidator(
                                                  item.document?.path
                                                ) && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={4}>
                                                      <div className=" assignment_result_data_font">
                                                        Question Image:{" "}
                                                      </div>
                                                    </Grid>
                                                    <Grid item md={8}>
                                                      <Chip
                                                        label={item?.document.name?.substring(
                                                          0,
                                                          15
                                                        )}
                                                        onClick={() => {
                                                          HandleImageDailogBox(
                                                            item?.document
                                                          );
                                                        }}
                                                        color="primary"
                                                        variant="outlined"
                                                      />
                                                      {/* <img
                                                        src={
                                                          Service.baseUrl +
                                                          "/" +
                                                          `${item.document?.path}`
                                                        }
                                                        className="student_photo_div"
                                                        alt="profile_img"
                                                      /> */}
                                                    </Grid>
                                                  </Grid>
                                                )}

                                                <Grid className="grid_main_div">
                                                  <Grid item md={4}>
                                                    <div className="assignment_result_data_font">
                                                      Correct Answer:
                                                    </div>
                                                  </Grid>
                                                  <Grid item md={8}>
                                                    <div className="font_color_data_assignment d-flex">
                                                      {item.assignment_choices.map(
                                                        (val, index1) => (
                                                          <span key={index1}>
                                                            {val.is_answer ==
                                                              1 && val.choice}
                                                            {index != 0 &&
                                                              val.is_answer ==
                                                                1 &&
                                                              ","}
                                                          </span>
                                                        )
                                                      )}
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid item md={6}>
                                                <Grid className="grid_main_div">
                                                  <Grid item md={4}>
                                                    <div className=" assignment_result_data_font">
                                                      Submitted Answer:{" "}
                                                    </div>
                                                  </Grid>
                                                  <Grid item md={8}>
                                                    {item &&
                                                      item.assignment_submission &&
                                                      mulitpleAnswerHandler(
                                                        item
                                                          .assignment_submission
                                                          ?.answer
                                                      )}
                                                  </Grid>
                                                </Grid>
                                                {blankValidator(
                                                  item.assignment_submission
                                                    ?.document?.path
                                                ) && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={4}>
                                                      <div className=" assignment_result_data_font">
                                                        Answer Image:{" "}
                                                      </div>
                                                    </Grid>
                                                    <Grid item md={8}>
                                                      <Chip
                                                        label={item.assignment_submission?.document?.name?.substring(
                                                          0,
                                                          15
                                                        )}
                                                        onClick={() => {
                                                          HandleImageDailogBox(
                                                            item
                                                              .assignment_submission
                                                              ?.document
                                                          );
                                                        }}
                                                        color="primary"
                                                        variant="outlined"
                                                      />
                                                      {/* <img
                                                        src={
                                                          Service.baseUrl +
                                                          "/" +
                                                          `${item.assignment_submission?.document?.path}`
                                                        }
                                                        className="student_photo_div"
                                                        alt="profile_img"
                                                      /> */}
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </Grid>
                                          </div>
                                        ) : (
                                          <div>
                                            <Grid className="grid_main_div">
                                              <Grid item md={6}>
                                                {blankValidator(
                                                  item.document?.path
                                                ) && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={4}>
                                                      <div className=" assignment_result_data_font">
                                                        Question Image:{" "}
                                                      </div>
                                                    </Grid>
                                                    <Grid item md={8}>
                                                      <Chip
                                                        label={item.document?.name?.substring(
                                                          0,
                                                          15
                                                        )}
                                                        onClick={() => {
                                                          HandleImageDailogBox(
                                                            item?.document
                                                          );
                                                        }}
                                                        color="primary"
                                                        variant="outlined"
                                                      />
                                                      {/* <img
                                                        src={
                                                          Service.baseUrl +
                                                          "/" +
                                                          `${item.document?.path}`
                                                        }
                                                        className="student_photo_div"
                                                        alt="profile_img"
                                                      /> */}
                                                    </Grid>
                                                  </Grid>
                                                )}
                                                <Grid className="grid_main_div">
                                                  <Grid item md={4}>
                                                    <div className="assignment_result_data_font">
                                                      Correct Answer:
                                                    </div>
                                                  </Grid>
                                                  <Grid item md={8}>
                                                    <div>
                                                      {item.answer ===
                                                        undefined ||
                                                      item.answer === null ? (
                                                        ""
                                                      ) : (
                                                        <span>
                                                          {parse.default(
                                                            item.answer
                                                          )}
                                                        </span>
                                                      )}
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid item md={6}>
                                                <Grid className="grid_main_div">
                                                  <Grid item md={4}>
                                                    <div className=" assignment_result_data_font">
                                                      Submitted Answer:{" "}
                                                    </div>
                                                  </Grid>
                                                  <Grid item md={8}>
                                                    <div className="font_color_data_assignment">
                                                      {blankValidator(
                                                        item.assignment_submission
                                                      ) &&
                                                        blankValidator(
                                                          item
                                                            .assignment_submission
                                                            .answer
                                                        ) && (
                                                          <span>
                                                            {parse.default(
                                                              item
                                                                .assignment_submission
                                                                .answer
                                                            )}
                                                          </span>
                                                        )}
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                                {blankValidator(
                                                  item.assignment_submission
                                                    ?.document?.path
                                                ) && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={4}>
                                                      <div className=" assignment_result_data_font">
                                                        Answer Image:{" "}
                                                      </div>
                                                    </Grid>
                                                    <Grid item md={8}>
                                                      <Chip
                                                        label={item?.assignment_submission?.document?.name?.substring(
                                                          0,
                                                          15
                                                        )}
                                                        onClick={() => {
                                                          HandleImageDailogBox(
                                                            item
                                                              ?.assignment_submission
                                                              ?.document
                                                          );
                                                        }}
                                                        color="primary"
                                                        variant="outlined"
                                                      />
                                                      {/* <img
                                                        src={
                                                          Service.baseUrl +
                                                          "/" +
                                                          `${item.assignment_submission?.document?.path}`
                                                        }
                                                        className="student_photo_div"
                                                        alt="profile_img"
                                                      /> */}
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </Grid>
                                          </div>
                                        )}

                                        <div className="mt-5">
                                          <hr />
                                        </div>

                                        <div className="mt-3">
                                          <div className="text_filed_heading">
                                            Notes
                                          </div>

                                          <div className="mt-1 ml-2 mr-2">
                                            <div class="form-group">
                                              <textarea
                                                class="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows="4"
                                                value={
                                                  blankValidator(
                                                    item.assignment_submission
                                                  )
                                                    ? item.assignment_submission
                                                        .remarks
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    item[
                                                      "assignment_submission"
                                                    ] == null &&
                                                    item[
                                                      "assignment_submission"
                                                    ] == undefined
                                                  ) {
                                                    item[
                                                      "assignment_submission"
                                                    ] = {};
                                                  }

                                                  item["assignment_submission"][
                                                    "remarks"
                                                  ] = e.target.value;

                                                  let temp = [];

                                                  AssignmnetReview.map(
                                                    (item1) => {
                                                      temp.push(item1);
                                                    }
                                                  );
                                                  setAssignmnetReview(temp);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="text_filed_heading">
                                          Marks(Max:
                                          <span className="ml-1 mr-1">
                                            {blankValidator(item.marks) &&
                                              item.marks}
                                          </span>
                                          )
                                        </div>
                                        <Grid className="grid_main_div mt-2">
                                          <Grid item md={6}>
                                            <div className="inputMarginright mt-2">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder=""
                                                autoComplete="off"
                                                value={
                                                  blankValidator(
                                                    item.assignment_submission
                                                  )
                                                    ? item.assignment_submission
                                                        .teacher_marks
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  try {
                                                    let n1 = parseInt(
                                                      e.target.value
                                                    );

                                                    if (
                                                      item[
                                                        "assignment_submission"
                                                      ] == null &&
                                                      item[
                                                        "assignment_submission"
                                                      ] == undefined
                                                    ) {
                                                      item[
                                                        "assignment_submission"
                                                      ] = {};
                                                    }
                                                    if (
                                                      !/^[0-9]+$/.test(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      item[
                                                        "assignment_submission"
                                                      ]["teacher_marks"] = "";
                                                    } else {
                                                      item[
                                                        "assignment_submission"
                                                      ]["teacher_marks"] = n1;
                                                    }

                                                    let temp = [];
                                                    AssignmnetReview.map(
                                                      (item1) => {
                                                        temp.push(item1);
                                                      }
                                                    );
                                                    setAssignmnetReview(temp);
                                                  } catch (error) {}
                                                }}
                                              />
                                            </div>
                                          </Grid>

                                          {/* <Grid item md={}></Grid> */}
                                          <Grid item md={6}>
                                            <div className="teacher_review_button_flex">
                                              <div className="mr-1">
                                                <Button
                                                  variant="contained"
                                                  className="add_new_query_btn"
                                                  onClick={() => {
                                                    submitquestionresult(
                                                      item,
                                                      index,
                                                      "INCORRECT"
                                                    );
                                                  }}
                                                >
                                                  Incorrect
                                                </Button>
                                              </div>

                                              <div>
                                                <Button
                                                  variant="contained"
                                                  className="add_new_query_btn"
                                                  //disabled={anvalueformarks<= parseInt(item.teacher_marks) ? true : false}
                                                  onClick={() => {
                                                    try {
                                                      if (
                                                        !blankValidator(
                                                          item
                                                            .assignment_submission
                                                            .teacher_marks
                                                        )
                                                      ) {
                                                        showNotificationMsz(
                                                          "Please enter marks",
                                                          "warning"
                                                        );

                                                        return;
                                                      }
                                                      if (
                                                        parseInt(
                                                          item
                                                            .assignment_submission
                                                            .teacher_marks
                                                        ) > item.marks
                                                      ) {
                                                        showNotificationMsz(
                                                          "Given Marks must not be greater than Question Marks",
                                                          "warning"
                                                        );

                                                        return;
                                                      }

                                                      submitquestionresult(
                                                        item,
                                                        index,
                                                        "CORRECT"
                                                      );
                                                    } catch (error) {
                                                      showNotificationMsz(
                                                        error,
                                                        "warning"
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Correct
                                                </Button>
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Expand>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </div>
                        </div>
                      </div>
                      <Grid className="grid_main_div">
                        <Grid item md={12}>
                          <div className="mt-3">
                            <div className="text_filed_heading ml-4">
                              Remarks
                            </div>

                            <div className="mt-1 ml-2 mr-2">
                              <div class="form-group">
                                <textarea
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="4"
                                  value={assignmnetRemark}
                                  onChange={(e) => {
                                    setassignmnetRemark(e.target.value);
                                  }}
                                />

                                {mainremarksError && (
                                  <p className="text-danger">
                                    Please provide remarks{" "}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <Dialog
          open={openRemarkdailog}
          onClose={closeRemarkdailog}
          aria-labelledby="draggable-dialog-title"
          className="dailog_formatting"
        >
          <div className="dailog_feesubtype_setup">
            <div>
              <div className="float-left ml-3">Remarks</div>
              <div className="float-right mr-3 cross_font_size pb-4">
                <i class="fa fa-times cursor" onClick={closeRemarkdailog}></i>
              </div>
            </div>
            <hr style={{ marginTop: "30px" }} />
            <div className="mt-3 ml-2 mr-2">
              <Grid className="grid_main_div">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="Enter your remarks...."
                    ></textarea>
                  </div>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </div>
            <Grid className="grid_main_div">
              <Grid item md={11}>
                <div className="d-flex float-right mt-1">
                  <div className="mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
        </Dialog>
        <Loder loading={isloading} />
      </div>
      <Dialog
        open={OpenPhotoImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenPhotoImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + impagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenPhotoImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + impagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(TeacherReviewOfAnswer));
