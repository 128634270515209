import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import HOC1 from "../../Hoc1.jsx";
import Card from "@material-ui/core/Card";
import Loder from "../../Loder/Loder";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Button from "@material-ui/core/Button";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import GoogleMapComponent from "../../Common/GoogleMapComponent/GoogleMapComponent";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useMutation } from "@apollo/client";
import {
  blankValidator,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import image_map from "../../Common/MapView/image_map.png";

const UpdateEmployeeTransport = ({ navigate, location, data }) => {
  let institution_Name = data.institution_name;
  const { id } = location.state.data;
  const client = useApolloClient();
  const [update_Transport_Tracker, {}] = useMutation(
    Service.update_Transport_Tracker
  );
  const [timeInterval, settimeInterval] = useState(null);
  const [googleMapData, setGoogleMapData] = useState("");
  // const [lititute, setLititute] = useState(28.9845);
  // const [longititute, setLongititute] = useState(77.7064);
  // const [zoom, setzoom] = useState(12);
  const [routeStopArr, setRouteStopArr] = useState([]);
  const [center, setCenter] = useState({
    lat: 28.9845,
    lng: 77.7064,
  });
  const [isloading, setisloading] = useState(false);
  const [busLocation, setBusLocation] = useState({
    lat: 28.9845,
    lng: 77.7064,
  });
  useEffect(() => {
    try {
      getTransportBYID();
      setisloading(true);
      client
        .query({
          query: Service.Get_Stop_Route_Name,
          variables: {
            transport_route_id: parseInt(id),
          },
        })
        .then((response) => {
          if (response?.data?.getTransportStop) {
            const sortedArr = response?.data?.getTransportStop.sort((a, b) => {
              return +a.sequence_number - +b.sequence_number;
            });
            setRouteStopArr(sortedArr);
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, []);

  const getTransportBYID = async () => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.get_tranport_route_by_id,
          variables: {
            id: parseInt(id),
          },
        })
        .then((response) => {
          setGoogleMapData(response.data.getTransportRouteById);
          setBusLocation({
            lat: parseFloat(
              response.data.getTransportRouteById.transport_tracker
                ?.current_latitude
            ),
            lng: parseFloat(
              response.data.getTransportRouteById.transport_tracker
                ?.current_longitude
            ),
          });
          // setlaststop(
          //   response.data.getTransportStop[
          //     response.data.getTransportStop.length - 1
          //   ].stop_name
          // );
          // setRouteStopArr(response.data.getTransportStop);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime("ACTIVE");
    }, 30000);
    settimeInterval(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const updateTime = async (status) => {
    try {
      setisloading(true);
      const location = window.navigator && window.navigator.geolocation;
      if (location) {
        await location.getCurrentPosition(
          async (position) => {
            await update_Transport_Tracker({
              variables: {
                id: parseInt(id),
                current_latitude: position.coords.latitude.toString(),
                current_longitude: position.coords.longitude.toString(),
                status,
              },
            })
              .then((response) => {
                setisloading(false);
                getTransportBYID();
              })
              .catch((error) => {
                setisloading(false);
              });
          },
          (error) => {
            setisloading(false);
            ///need to show notifcation
          }
        );
      } else {
        showNotificationMsz(
          "your device does not support location trackker",
          "info"
        );
      }
    } catch (error) {
      setisloading(false);
      console.log("error---", error);
    }
  };
  const UpdateTransportRoute = () => {
    updateTime("ACTIVE");
  };
  const StartTransportRoute = async () => {
    try {
      await updateTime("ACTIVE");
    } catch (error) {}
  };
  const StopTransportRoute = async () => {
    try {
      clearInterval(timeInterval);
      await updateTime("INACTIVE");
    } catch (error) {}
  };
  return (
    <div className="main_back_color1">
      <div className="margin_from_both_contact ">
        <Holiday name={institution_Name} />

        <div className="googlemapcard">
          <Grid className="grid_main_div">
            <Grid item md={8}>
              <div className="google_map_main_div">
                <Card>
                  <div className="conatctcardHeight p-3">
                    <Grid className="grid_main_div">
                      <Grid item md={2}>
                        <img
                          src={
                            blankValidator(googleMapData.employee)
                              ? `${Service.baseUrl}/${googleMapData?.employee?.photo?.path}`
                              : image_map
                          }
                          alt="no_profile"
                          className="map_card_image"
                        />
                      </Grid>
                      <Grid item md={5}>
                        <div>
                          <span className="content_color_grey">
                            Driver Name
                          </span>
                          <div className="d-flex">
                            <span>
                              <i class="fa fa-user iconcolor"></i>
                            </span>
                            <span className="ml-2 Heading_Google_map_view">
                              {getFullName(googleMapData?.employee?.name)}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5}>
                        <div>
                          <span className="content_color_grey">
                            Driver Contact
                          </span>
                          <div className="d-flex">
                            <span>
                              <i className="fa fa-mobile iconcolor"></i>
                            </span>
                            <span className="ml-2 Heading_Google_map_view">
                              +{googleMapData?.driver_phone_number}
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid className="grid_main_div">
                      <Grid item md={12}>
                        <div>
                          <div className="Heading_Google_map_view">
                            Route Details
                          </div>
                          <div className="content_color_grey">
                            {googleMapData?.route_name}
                          </div>
                        </div>
                        <div>
                          <div className="Heading_Google_map_view">
                            {googleMapData?.start_point} -{" "}
                            {googleMapData?.end_point}
                          </div>
                          <div className="float-right d-flex">
                            {!blankValidator(
                              googleMapData.transport_tracker
                            ) ? (
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration mr-2"
                                onClick={() => StartTransportRoute()}
                              >
                                Start
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration mr-2"
                                onClick={() => StopTransportRoute()}
                              >
                                Stop{" "}
                              </Button>
                            )}

                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration mr-2"
                              onClick={() => UpdateTransportRoute()}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ height: "60vh", width: "100%" }}>
                    <GoogleMapComponent
                      showRoute={true}
                      stopArray={routeStopArr}
                      zoom={11}
                      center={center}
                      showBus={blankValidator(googleMapData.transport_tracker)}
                      busLocation={busLocation}
                    ></GoogleMapComponent>
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="Second_portion_map">
                <div className="card_MARGIN">
                  <div className="conatctcardHeight2">
                    <div className="google_map_main_div cardheightnew_overflow">
                      <Card>
                        <div className="contnent_margin_map">
                          <div className="Heading_Google_map_view">Stops</div>

                          {routeStopArr.map((item, index) => (
                            <div
                              className="d-flex mt-1 border_bottom_map"
                              key={index}
                            >
                              <span>
                                <i class="fa fa-subway"></i>
                              </span>
                              <span className="ml-2 content_color_grey">
                                <div>{item.stop_name}</div>
                                {/* -------------not getting time from server ------------*/}
                                {/* <div>(Bell 8:50-3:00)- 15:35</div> */}
                              </span>
                            </div>
                          ))}
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(UpdateEmployeeTransport));
