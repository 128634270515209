import React from "react";
import Grid from "@material-ui/core/Grid";
const ClassOverViewTotalStudent = () => {
  return (
    <div>
      <div>
        <Grid className="grid_main_div">
          <Grid item md={2}>
            {" "}
            <div>
              <p>Section A</p>
              <p>Section A</p>
              <p>Section A</p>
            </div>
          </Grid>
          <Grid item md={2}>
            {" "}
            <div>
              <p>20</p>
              <p>20</p>
              <p>20</p>
            </div>
          </Grid>
          <Grid item md={2}>
            {" "}
            <div>
              <p>25</p>
              <p>20</p>
              <p>20</p>
            </div>
          </Grid>
          <Grid item md={2}>
            {" "}
            <div>
              <p>25</p>
              <p>20</p>
              <p>20</p>
            </div>
          </Grid>
          <Grid item md={2}>
            {" "}
            <div>
              <p>25</p>
              <p>20</p>
            </div>
          </Grid>
          <Grid item md={2}>
            {" "}
            <div>
              <p>25</p>
              <p>20</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ClassOverViewTotalStudent;
