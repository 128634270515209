import { useApolloClient } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../action/appAction";
import { getData } from "../../api/index";
//redux
import * as Service from "../../services/index";
import { blankValidator } from "../../utils/Validation";
import NoSectionDailog from "../Admin/HomeMenu/NoSectionDailog";
import UpcomingEventsComponent from "../Common/Events/UpcomingEventsComponent";
import Holiday from "../Common/Holiday/Holiday.jsx";
import HOC1 from "../Hoc1.jsx";
import Loder from "../Loder/Loder.jsx";
import Assigned from "./Assignment/Teacherhomepagetab/Assigned";
import Completed from "./Assignment/Teacherhomepagetab/Completed";
import Created from "./Assignment/Teacherhomepagetab/Created";
import Drafted from "./Assignment/Teacherhomepagetab/Drafted";
import RevewPending from "./Assignment/Teacherhomepagetab/RevewPending";
import OnlineClass from "./OnlineClassTeacher.jsx";
import OnlineEventLink from "./OnlineEventLink";
import PrivateStudentNoitce from "./PrivateStudentNoitce";
import PtTeacherTimeTableHome from "./PtTeacherTimeTableHome";
import "./TeacherHomePage.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TeacherHomePage = (props) => {
  const client = useApolloClient();

  let { first_name, middle_name, last_name, type } = props.data;

  let userName = `${first_name + " " + middle_name + " " + last_name}`;
  const [managegrade, setmanagegrade] = useState([]);
  const [gradeid, setgradeid] = useState(-1);

  const [section, setsection] = useState([]);
  const [sectionid, setsectionid] = useState(-1);
  const [subjectarr, setsubjectarr] = useState([]);

  const [subjectid, setsubjectid] = useState(-1);
  const [searchbyassignmnetname, setsearchbyassignmnetname] = useState("");
  const [noSectionData, setNoSectionData] = useState(false);

  const searchBysection = (val) => {
    setsectionid(parseInt(val));
  };
  const searchBysubject = async (val) => {
    setsubjectid(val);
  };

  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    if (!blankValidator(props.data) && !blankValidator(props.dataacademic)) {
      props.navigate("/");
    }
  }, [props]);

  const teacher_id = parseInt(props.data.userid);

  const institution_id = parseInt(props.data.institution_id);
  let academic_session_id = parseInt(props.dataacademic.id);

  useEffect(() => {
    window.scrollTo(0, 0);

    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setmanagegrade(response.data.getGrade);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    const getsubjectlist = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setsubjectarr(response.data.getSubject);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getManageGrades();
    getsubjectlist();
  }, [client, academic_session_id, institution_id]);

  useEffect(() => {
    if (academic_session_id) {
      getAllSectionData();
    }
  }, []);

  const getAllSectionData = async () => {
    try {
      const { data } = await client.query({
        query: Service.getAllSectionForInstitue,
        variables: {
          academic_session_id: academic_session_id,
        },
      });
      if (data) {
        if (!data.getAllSectionForInstitue[0]) {
          setNoSectionData(true);
        } else {
          setNoSectionData(false);
        }
      }
    } catch (error) {}
  };
  const closehandle = () => {
    setNoSectionData(false);
  };

  const selectHandler = (id) => {
    let grade_id = parseInt(id);
    setgradeid(grade_id);
    setsection([]);
    setsectionid(-1);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setsection(response.data.getSectionAndGrade);
          // setSectionfromgrade(response.data.Sections)
          //setname("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} isRemove={true} />
              <OnlineClass
                userName={props.data}
                user_id={parseInt(props.data.userid)}
                institution_id={parseInt(props.data.institution_id)}
                userType="TEACHER"
              />
              <OnlineEventLink
                userType={props.data.usertype}
                user_id={parseInt(props.data.userid)}
                userName={props.data}
              />
              {type === "PT" || type === "ET" ? (
                <div>
                  <PrivateStudentNoitce  {...props} />
                  <PtTeacherTimeTableHome {...props} />
                </div>
              ) : (
                ""
              )}
              {/* ---------assignemnt section------- */}
              <div className="enquirydetails_background mb-2">
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="ml-3 mt-3 Heading_bold ">
                    <div>Assignments</div>
                    <div className="ml-2 mr-2 tabs_class_for_Mobile">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        lefted
                      >
                        <Tab
                          label="ReviewPending"
                          {...a11yProps(0)}
                          className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                        />
                        <Tab
                          label="Draft"
                          {...a11yProps(1)}
                          className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                        />
                        <Tab
                          label="Created"
                          {...a11yProps(2)}
                          className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                        />
                        <Tab
                          label="Assigned"
                          {...a11yProps(3)}
                          className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                        />
                        <Tab
                          label="Completed"
                          {...a11yProps(4)}
                          className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                        />
                      </Tabs>
                    </div>
                  </div>
                  <div className="mr-3 mt-3 ">
                    <span
                      className="mr-3 ml-4 openassignemntcolor mobile_font_size"
                      onClick={() =>
                        props.navigate("/addNewAssignment", {
                          state: {
                            teacher_id: teacher_id,
                          },
                        })
                      }
                    >
                      Create Assignment
                    </span>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            {value !== 1 && value !== 2 ? (
                              <div className="inputMarginright mt-1">
                                <select
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  value={gradeid}
                                  onChange={(e) =>
                                    selectHandler(e.target.value)
                                  }
                                >
                                  <option value={-1}>Search By Grade</option>

                                  {managegrade.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Search Assignment"
                                  autoComplete="off"
                                  value={searchbyassignmnetname}
                                  onChange={(e) => {
                                    setsearchbyassignmnetname(e.target.value);
                                  }}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid item md={3}>
                            {value !== 1 && value !== 2 ? (
                              <div className="inputMarginright mt-1">
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  onChange={(e) =>
                                    searchBysection(e.target.value)
                                  }
                                >
                                  <option value={-1}>Search By Section</option>
                                  {section.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div className="inputMarginright mt-1">
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  onChange={(e) =>
                                    searchBysubject(e.target.value)
                                  }
                                >
                                  <option value={-1}>Search By Subject</option>
                                  {subjectarr.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </Grid>

                          <Grid item md={3}>
                            {value !== 1 && value !== 2 ? (
                              <div className="inputMarginright mt-1">
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  onChange={(e) =>
                                    searchBysubject(e.target.value)
                                  }
                                >
                                  <option value={-1}>Search By Subject</option>
                                  {subjectarr.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>

                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="Pannel_padding_hidden mb-2">
                          <TabPanel value={value} index={0}>
                            <div className="mt-3">
                              <RevewPending
                                navigation={props}
                                teacher_id={teacher_id}
                                academic_session_id={academic_session_id}
                                status={"ASSIGNED"}
                                subjectid={subjectid}
                                gradeid={gradeid}
                                sectionid={sectionid}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <div className="mt-3">
                              <Drafted
                                navigation={props}
                                teacher_id={teacher_id}
                                academic_session_id={academic_session_id}
                                status={"DRAFT"}
                                subjectid={subjectid}
                                searchbyassignmnetname={searchbyassignmnetname}
                                gradeid={gradeid}
                                sectionid={sectionid}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <div className="mt-3">
                              <Created
                                navigation={props}
                                teacher_id={teacher_id}
                                status={"CREATED"}
                                academic_session_id={academic_session_id}
                                subjectid={subjectid}
                                searchbyassignmnetname={searchbyassignmnetname}
                                gradeid={gradeid}
                                sectionid={sectionid}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel value={value} index={3}>
                            <div className="mt-3">
                              <Assigned
                                navigation={props}
                                teacher_id={teacher_id}
                                status={"ASSIGNED"}
                                academic_session_id={academic_session_id}
                                subjectid={subjectid}
                                gradeid={gradeid}
                                sectionid={sectionid}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={4}>
                            <div className="mt-3">
                              <Completed
                                navigation={props}
                                teacher_id={teacher_id}
                                status={"COMPLETED"}
                                academic_session_id={academic_session_id}
                                subjectid={subjectid}
                                gradeid={gradeid}
                                sectionid={sectionid}
                              />
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              {type === "PT" || type === "ET" ? (
                ""
              ) : (
                <UpcomingEventsComponent navigate={props.navigate} />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div>
        {" "}
        <Loder loading={isloading} />
      </div>
      <NoSectionDailog
        open={noSectionData}
        type="Teacher"
        close={closehandle}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(TeacherHomePage));
