import React from "react";
import { Route, Routes } from "react-router-dom";
//Superadmin
import OrganisationSetup from "../components/SuperAdmin/Organisations/OrganisationSetup.jsx";
import SuperAdminHomeMenu from "../components/SuperAdmin/Organisations/SuperAdminHome.jsx";
import CreateUpdateOrganisation from "../components/SuperAdmin/Organisations/CreateUpdateOrganisation.jsx";
import AdminSetup from "../components/SuperAdmin/Admin/AdminSetup.jsx";
import CreateUpdateAdmin from "../components/SuperAdmin/Admin/CreateUpdateAdmin.jsx";

//common
import SignIn from "../components/Common/SignIn/SignIn.jsx";
import Contactus from "../components/Common/Contactus/Contactus.jsx";
import ForgotPassword from "../components/Common/SignIn/ForgotPassword.jsx";
import ResetPassword from "../components/Common/SignIn/ResetPassword.jsx";
import ForgotUserId from "../components/Common/SignIn/ForgotUserId.jsx";
import ViewStudentForm from "../components/Admin/Onboarding/ViewStudentForm";

//admission intrest
import EnquiryDetail from "../components/Admin/AdmissionIntrest/EnquiryDetail.jsx";
import SubmitAdmissionIntrest from "../components/Admin/AdmissionIntrest/SubmitAdmissionIntrest";
import AdmissionIntrest from "../components/Admin/AdmissionIntrest/AdmissionIntrest.jsx";
import AdminStudentAdmission from "../components/Admin/AdmissionIntrest/AdminStudentAdmission";

//Fee Module
import CreateInvoice from "../components/Admin/FeeModule/FeeCreateInvoice.jsx";
import EditInvoice from "../components/Admin/FeeModule/FeeEditInvoice.jsx";
import CreateEditInvoice from "../components/Admin/FeeModule/FeeCreateEditInvoice.jsx";
import InvoiceAssignment from "../components/Admin/FeeModule/FeeInvoiceAssignment.jsx";
import FeeSubtype from "../components/Admin/FeeModule/FeeSubtype.jsx";
import ManageInvoice from "../components/Admin/FeeModule/FeeManageInvoice.jsx";
import InvoiceTemplate from "../components/Admin/FeeModule/FeeViewInvoiceTemplate.jsx";
import StudentInvoice from "../components/Admin/FeeModule/StudentInvoice.jsx";
import FeeAdminView from "../components/Admin/FeeModule/FeeAdminView.jsx";
import InvoiceClass from "../components/Admin/FeeModule/InvoiceClass.jsx";

//institution setup
import CreateUpdateInstitution from "../components/Admin/Institution/CreateUpdateInstitution.jsx";
import InstitutionSetup from "../components/Admin/Institution/InstitutionSetup.jsx";

//manage user Setting
import ManageUserSetting from "../components/Admin/ManageUser/ManageUserSetting.jsx";

//Transport
import CreateNewRoute from "../components/Admin/Transport/CreateNewRoute.jsx";
import AdvanceAttendance from "../components/Admin/Transport/AdvanceAttendance.jsx";
import TransportRouteMap from "../components/Admin/Transport/TransportRouteMap.jsx";
import TransportDetail from "../components/Admin/Transport/TransportDetail.jsx";
import TransportMarkingAttendance from "../components/Admin/Transport/TransportMarkingAttendance.jsx";
import RouteListings from "../components/Admin/Transport/RouteListings.jsx";
import AssignStudent from "../components/Admin/Transport/AssignStudent.jsx";

//Report
import ReportHome from "../components/Admin/Report/ReportHome.jsx";
import AllStaffReport from "../components/Admin/Report/AllStaffReport.jsx";
import ClassStudentList from "../components/Admin/Report/ClassStudentList.jsx";
import ClassTeacherList from "../components/Admin/Report/ClassTeacherList.jsx";
import ReportDetails from "../components/Admin/Report/ReportDetails.jsx";
import ReportExamination from "../components/Admin/Report/ReportExamination.jsx";

//manage Institution
import ManageGrade from "../components/Admin/ManageInstitution/ManageGrade.jsx";
import ManageLocation from "../components/Admin/ManageInstitution/ManageLocation.jsx";
import ManageDesignation from "../components/Admin/ManageInstitution/ManageDesignation.jsx";
import ManageRole from "../components/Admin/ManageInstitution/ManageRole.jsx";
import ManageDepartment from "../components/Admin/ManageInstitution/ManageDepartment.jsx";
import ManageSubject from "../components/Admin/ManageInstitution/ManageSubject.jsx";
import AdminManageInstitution from "../components/Admin/ManageInstitution/AdminManageInstitution.jsx";
import ManageClass from "../components/Admin/ManageInstitution/ManageClass.jsx";
import AddEditClass from "../components/Admin/ManageInstitution/AddEditClass.jsx";
import AssignTeacher from "../components/Admin/ManageInstitution/AssignTeacher.jsx";
import ClassTimeTableOpenAssignLink from "../components/Admin/ManageInstitution/ClassTimeTableOpenAssignLink.jsx";
import CreateUpdateTimeTable from "../components/Admin/ManageInstitution/CreateUpdateTimeTable.jsx";
import TimeTable from "../components/Admin/ManageInstitution/TimeTable.jsx";
import TimeTablePreviewTemplate from "../components/Admin/ManageInstitution/TimeTablePreviewTemplate.jsx";
import ManageSession from "../components/Admin/ManageInstitution/ManageSession.jsx";
import ManageTimeTable from "../components/Admin/ManageInstitution/ManageTimeTable.jsx";
import CreateTimeTableTemplate from "../components/Admin/ManageInstitution/CreateTimeTableTemplate.jsx";

//onBoarding
import ClassPromotion from "../components/Common/ClassPromotion/ClassPromotion";
import ViewClassPromotionResults from "../components/Admin/Onboarding/ViewClassPromotionResults.jsx";
import StudentForm from "../components/Admin/Onboarding/StudentForm.jsx";
import EmployeeDetailForm from "../components/Admin/Onboarding/EmployeeDetailForm.jsx";
import AdminStudentDetailForm from "../components/Admin/Onboarding/AdminStudentDetailForm.jsx";
import AdminEmployeeDetailForm from "../components/Admin/Onboarding/AdminEmployeeDetailForm.jsx";
import AdminEmployeeListView from "../components/Admin/Onboarding/AdminEmployeeListView.jsx";
import AdminStudentListView from "../components/Admin/Onboarding/AdminStudentListView.jsx";
import StudentTransportationPop from "../components/Admin/Onboarding/StudentTransportationPop.jsx";

import GoogleMapView from "../components/Common/MapView/GoogleMap.jsx";

import ChangePassword from "../components/TransportStaff/ChangePassword.jsx";
import ListofAllRoute from "../components/TransportStaff/ListofAllRoute.jsx";
import TransportHomePage from "../components/TransportStaff/TransportHomePage.jsx";

//teacher
import TeacherHomePage from "../components/Teacher/TeacherHomePage.jsx";
import AssignmentAssign from "../components/Teacher/Assignment/AssignmentAssign.jsx";
import AssignmentCancel from "../components/Teacher/Assignment/AssignmentCancel.jsx";
import ListOfAssignments from "../components/Teacher/Assignment/ListOfAssignments.jsx";
import AssignmentView from "../components/Teacher/Assignment/AssignmentView.jsx";
import TeacherReviewOfAnswer from "../components/Teacher/Assignment/TeacherReviewOfAnswer.jsx";

import CreateGrading from "../components/Common/Exam/CreateGrading.jsx";

import ExamEnterMarks from "../components/Common/Exam/ExamEnterMarks.jsx";
import ExamViewResults from "../components/Common/Exam/ExamViewResults.jsx";
import ApplyLeave from "../components/Teacher/Leave/ApplyLeave.jsx";
import EditLeave from "../components/Teacher/Leave/EditLeave.jsx";
import TeacherLeaveSummary from "../components/Teacher/Leave/TeacherLeaveSummary.jsx";
import ManageLeaveRequest from "../components/Teacher/Leave/ManageLeaveRequest.jsx";
import ReviewApproveRejectList from "../components/Teacher/Leave/ReviewApproveRejectList.jsx";
import ReviewMyLeaves from "../components/Teacher/Leave/ReviewMyLeaves.jsx";

import MarkAttendanceHome from "../components/Teacher/MarkAttendance/MarkAttendanceHome.jsx";
import MarkingAttendanceSubmit from "../components/Teacher/MarkAttendance/MarkingAttendanceSubmit.jsx";

import CreateNotice from "../components/Teacher/TeacherNotice/CreateNotice.jsx";
import TeacherNoticeBoard from "../components/Teacher/TeacherNotice/TeacherNoticeBoard.jsx";

import NoticeManagementSubject from "../components/Teacher/TeacherNotice/NoticeManagementSubject.jsx";

import CreateUpdateAssignment from "../components/Teacher/Assignment/CreateUpdateAssignment.jsx";
import CreateAssignment from "../components/Teacher/Assignment/CreateAssignment.jsx";

import TeacherEventpage from "../components/Common/Events/TeacherManageEvent.jsx";
import CommonManageEvent from "../components/Common/Events/ManageEvent.jsx";
import CommonCreateEvent from "../components/Common/Events/CreateEvents.jsx";
import CommonEventInvite from "../components/Common/Events/InviteForm.jsx";
import CommonEventDetails from "../components/Common/Events/EventDetails.jsx";
import CommoneventAttendanceSubmitcamera from "../components/Common/Events/EventAttendanceSubmit.jsx";
import CommonMarkEventAttendance from "../components/Common/Events/MarkEventAttendance.jsx";
import CommoneventAttendance from "../components/Common/Events/EventAttendence.jsx";
import CommonEditEvent from "../components/Common/Events/EditEvent.jsx";

import TeacherTimetable from "../components/Teacher/TimeTable/TeacherTimeTable.jsx";
import MyTeacherTimetable from "../components/Teacher/MyTimetable/MyTeacherTimeTable.jsx";

import CommonNotificationopened from "../components/Common/CommonNotifications/CommonNotificationopened.jsx";
import CommonNotificationCompose from "../components/Common/CommonNotifications/CommonNotificationCompose.jsx";
import CommonNotifications from "../components/Common/CommonNotifications/CommonNotifications.jsx";
import EmployeeProfileView from "../components/Teacher/EmployeeProfileView.jsx";

//Student flow
import StudentHomePage from "../components/Student/StudentHomePage.jsx";

import Calender from "../components/Student/Calender/Calender.jsx";
import AssignmentResult from "../components/Student/Assignment/AssignmentResult.jsx";
import Examination from "../components/Student/Exam/Examination.jsx";
import ExamResult from "../components/Student/Exam/ExamResult.jsx";
import ExamTime from "../components/Student/Exam/ExamTime.jsx";
import RequestLeave from "../components/Student/Leave/RequestLeave.jsx";
import ReviewLeave from "../components/Student/Leave/ReviewLeave.jsx";
import LeaveSummary from "../components/Student/Leave/Studentleave.jsx";
import NoticeBoard from "../components/Student/Notice/NoticeBoard.jsx";
import StudentSyllabus from "../components/Student/Exam/StudentSyllabus";

import StudentTimeTable from "../components/Student/TimeTable/StudentTimeTable.jsx";

import StudentResetPassword from "../components/Student/StudentResetpassword.jsx";
import Assignmentsubmission from "../components/Student/Assignment/AssignmentSubmission.jsx";
import ContinueAssignment from "../components/Student/Assignment/ContinueAssignment";

import NoticeDetail from "../components/Common/NoticeDetail/NoticeDetail.jsx";

import StudentListofInvoice from "../components/Student/Fees/StudentListofInvoice.jsx";
import StudentPaymentnavigate from "../components/Student/Fees/StudentPaymentHistory";
// import StudentInvoiceAlreadyPaid from "../components/Student/Fees/StudentInvoiceAlreadyPaid.jsx";

//parent flow
import ParentHomePage from "../components/Parent/ParentHomePage.jsx";
import ParentExamination from "../components/Parent/Exam/ParentExamination.jsx";
import ParentExamResult from "../components/Parent/Exam/ParentExamResult.jsx";
import ParentExamTimeTable from "../components/Parent/Exam/ParentExamTimeTable.jsx";
import InvoiceDetailAlreadyPaid from "../components/Parent/Fees/InvoiceDetailAlreadyPaid.jsx";
import InvoiceDetailView from "../components/Parent/Fees/InvoiceDetailView.jsx";
import Pay from "../components/Parent/Fees/Pay.jsx";
import ListOfInvoices from "../components/Parent/Fees/ListOfInvoices.jsx";
import Paymentnavigate from "../components/Parent/Fees/PaymentHistory";
import ParentSchoolNotice from "../components/Parent/Notice/ParentSchoolNotice.jsx";

import StudentAssignment from "../components/Parent/Assignment/StudentAssignment.jsx";
import StudentAssignmentResult from "../components/Parent/Assignment/StudentAssignmentResult.jsx";
import ParentStudentEventsDetails from "../components/Common/Events/ParentStudentEventsDetails";
import StudentProfile from "../components/Parent/StudentProfile/StudentProfile";
import ParentNotice from "../components/Parent/ParentNotice/ParentNotice";

import CallBack from "../components/CallBack/CallBack";
//staff
import StaffHomePage from "../components/Staff/StaffHomePage.jsx";

//attendance--(events)

import AttendanceViewDayAdminStudent from "../components/Admin/Attendance/AttendanceViewDayAdminStudent.jsx";
import AttendanceViewDayAdminTeacher from "../components/Admin/Attendance/AttendanceViewDayAdminTeacher.jsx";

import EnqueryDetailsReport from "../components/Admin/Report/EnqueryDetailsReport.jsx";
import EmployeeDetailsFormReport from "../components/Admin/Report/EmployeeDetailsFormReport.jsx";

import CreateEditTemplate from "../components/Admin/FeeModule/CreateEditTemplate.jsx";
import ManageRoutes from "../components/Admin/Transport/ManageRoutes.jsx";
import CreateInvoiceTemplate from "../components/Admin/FeeModule/CreateInvoiceTemplate.jsx";
import EditFeeInvoice from "../components/Admin/FeeModule/EditFeeInvoice.jsx";
import AssignInvoice from "../components/Admin/FeeModule/AssignInvoice.jsx";
import ParentManageEvent from "../components/Common/Events/ParentManageEvent.jsx";
import HomeMenu from "../components/Admin/HomeMenu/HomeMenu.jsx";
import ParentStudentLeave from "../components/Parent/Leave/ParentStudentLeave.jsx";
import StudentTransportDetail from "../components/Student/Transport/StudentTransportDetail.jsx";
import ParentStudentTransport from "../components/Parent/Transport/ParentStudentTransport.jsx";

import FeeMenus from "../components/Admin/FeeModule/FeeMenus.jsx";

import ParentStudentAttendenceView from "../components/Admin/Attendance/ParentStudentAttendenceView.jsx";

import CommonHoliDayCalender from "../components/Common/Holiday/CommonHoliDayCalender";

import StudentAttendance from "../components/Student/Calender/StudentAttendance.jsx";

import ManageExamination from "../components/Common/Exam/ManageExamination.jsx";
import AdminUpdateExam from "../components/Common/Exam/AdminUpdateExam.jsx";
import AdminExamStudentList from "../components/Common/Exam/AdminExamStudentList.jsx";
// import AdminMangeStudentLeave from "../components/Admin/Leave/AdminMangeStudentLeave.jsx";
// import AdminReviewStudentLeave from "../components/Admin/Leave/AdminReviewStudentLeave.jsx";
import AdminClassNotice from "../components/Admin/Notice/AdminClassNotice.jsx";
import AdminManageTeacherLeave from "../components/Admin/Leave/AdminManageTeacherLeave.jsx";
import AdminStudentWithdrawal from "../components/Admin/Onboarding/AdminStudentWithdrawal.jsx";
import AdmissionMenu from "../components/Admin/AdmissionIntrest/AdmissionMenu.jsx";
import AdminSchoolNotice from "../components/Admin/Notice/AdminSchoolNotice.jsx";
import CreateSchoolNotice from "../components/Admin/Notice/CreateSchoolNotice.jsx";

import ClassOverviewandpaymentAttendence from "../components/Admin/ClassOverviewandpayment/ClassOverviewandpaymentAttendence";

import ParentFeeHome from "../components/Parent/Fees/ParentFeeHome.jsx";
import TeacherAttendanceView from "../components/Teacher/MarkAttendance/TeacherAttendanceView.jsx";
import TeacherEventMarkAttendanceView from "../components/Common/Events/EventMarkAttendanceView.jsx";

import AssignOnboardStudent from "../components/Admin/Onboarding/AssignOnboardStudent.jsx";
// import Route from "./Route.js";

//------------redux----------------------
import { connect } from "react-redux";
import * as Constants from "../action/appAction";
import { getData } from "../api/index";
import AssignRollNo from "../components/Admin/Onboarding/AssignRollNo.jsx";
import TeacherTimeTableMenu from "../components/Teacher/TimeTable/TeacherTimeTableMenu.jsx";
import BulkUpload from "../components/Admin/Others/BulkUpload.jsx";
import ManageRollNo from "../components/Admin/Onboarding/ManageRollNo.jsx";
import ParentStudentTimeTable from "../components/Parent/TimeTable/ParentStudentTimeTable.jsx";

import ViewPassengers from "../components/Admin/Transport/ViewPassengers.jsx";
import OpenAssignInvoice from "../components/Admin/FeeModule/OpenAssignInvoice.jsx";
import Setting from "../components/Admin/Settings/Setting.jsx";
import ManageBankAccount from "../components/Admin/ManageInstitution/ManageBankAccount.jsx";
import AdminAddExam from "../components/Common/Exam/AdminAddExam.jsx";
import StudentAttendenceReport from "../components/Admin/Report/StudentAttendenceReport/StudentAttendenceReport";
// holiday calender flow
import HolidayCalender from "../components/Admin/HolidayCalender/HolidayCalender";
import AddHolidayCalender from "../components/Admin/HolidayCalender/AddHolidayCalender";
import UpdateHolidayCalender from "../components/Admin/HolidayCalender/UpdateHolidayCalender";
// employee flow
import EmployeeHomePage from "../components/Employee/EmployeeHomePage/EmployeeHomePage";
import StudentInvoiceSummarybyID from "../components/Student/Fees/StudentInvoiceSummarybyID.jsx";
import PageReloadHandler from "./PagereloadHandler.js";
import EmployeeTransport from "../components/Employee/EmployeeTransport/EmployeeTransport";
import MarkTransportAttendence from "../components/Employee/EmployeeTransport/MarkTransportAttendence";
import UpdateEmployeeTransport from "../components/Employee/EmployeeTransport/UpdateEmployeeTransport";
import CommonAssignmentResultView from "../components/Common/Assigment/CommonAssignmentResultView.jsx";
import ShowAllTimetable from "../components/Admin/ManageInstitution/ShowAllTimetable.jsx";
import VerifyPayment from "../components/Common/VerifyPayment/VerifyPayment.js";
import TeacherHomeEventMain from "../components/Common/Events/TeacherHomeEventMain.jsx/TeacherHomeEventMain.jsx";
import ManageAllTimeTable from "../components/Admin/ManageInstitution/ManageAllTimeTable.jsx";
import CreateUpdateInstitutionSuperAdmin from "../components/Admin/Institution/CreateUpdateInstitutionSuperAdmin.jsx";
import TimeTableUpdateTime from "../components/Admin/ManageInstitution/TimeTableUpdateTime.jsx";
import ManageEventForTeacher from "../components/Common/Events/ManageEventForTeacher.jsx";
import EndUserAggreement from "../components/Common/SignIn/EndUserAggreement.jsx";
import PrivacyPolicy from "../components/Common/SignIn/PrivacyPolicy.jsx";
import StudyMaterial from "../components/Student/StudyMaterial/StudyMaterial.jsx";
import ViewStudyMaterial from "../components/Student/StudyMaterial/ViewStudyMaterial.jsx";
import TermsAndCondition from "../components/Common/SignIn/TermsAndCondition.jsx";
import CurrencySettings from "../components/SuperAdmin/Organisations/CurrencySettings.jsx";
import PtStudentForm from "../components/Admin/Onboarding/PtStudentForm.jsx";
import PtTeacherAssignment from "../components/Teacher/PtTeacherAssignment.jsx";
import PrivateTeachingInvoice from "../components/SuperAdmin/PrivateTeachingInvoice.jsx";
import GenrateInvoiceForPrivate from "../components/SuperAdmin/GenrateInvoiceForPrivate.jsx";
import OnlineTeachingInvoices from "../components/SuperAdmin/OnlineTeachingInvoices.jsx";
import PtAdminInvoiceSummaryPage from "../components/SuperAdmin/PtAdminInvoiceSummaryPage.jsx";
import PtMyInvoice from "../components/Admin/FeeModule/PtMyInvoice.jsx";
import PtPayMentHistory from "../components/Admin/FeeModule/PtPayMentHistory.jsx";
import PtAlreadypaidInvoice from "../components/Admin/FeeModule/PtAlreadypaidInvoice.jsx";
import PtInvoiceSummaryView from "../components/Admin/FeeModule/PtInvoiceSummaryView.jsx";
import GetStarted from "../components/Admin/GetStarted/GetStarted.jsx";
import ManageCredits from "../components/Admin/ManageCredits/ManageCredits.jsx";
import StudentMange from "../components/Student/StudentMange/StudentMange.jsx";
import ParentMange from "../components/Parent/ParentMange/ParentMange.jsx";
import CreateInvoiceForDate from "../components/Admin/FeeModule/CreateInvoiceForDate.jsx";
import PtTeacherFormDetails from "../components/Admin/Onboarding/PtTeacherFormDetails.jsx";

const CustomeRoutes = (props) => {
  return (
    <Routes>
      {/* <PageReloadHandler { ...props }></PageReloadHandler> */}
      {/* ------------------------------------unprotected route------------------------------- */}
      <Route exact path="/signIn" element={<SignIn {...props} />} />
      <Route exact path="/" element={<SignIn {...props} />} />
      <Route
        exact
        path="/forgotPassword"
        element={<ForgotPassword {...props} />}
      />
      <Route exact path="/forgotUserId" element={<ForgotUserId {...props} />} />
      <Route
        exact
        path="/resetPassword"
        element={<ResetPassword {...props} />}
      />

      {/* ------------------------------------protected route------------------------------- */}
      {/* <Route
        exact
        path="/showAllTimetables"
        element={ShowAllTimetable}
       { ...props }
      /> */}
      <Route
        exact
        path="/showAllTimetables"
        element={<ShowAllTimetable {...props} />}
      />
      <Route exact path="/verify" element={VerifyPayment} {...props} />

      <Route
        exact
        path="/classoverviewandpaymentattendence"
        element={ClassOverviewandpaymentAttendence}
        {...props}
      />
      <Route
        exact
        path="/admissionIntrest"
        element={<AdmissionIntrest {...props} />}
      />
      <Route exact {...props} path="/assignrollno" element={AssignRollNo} />
      <Route
        exact
        {...props}
        path="/viewstudentform"
        element={ViewStudentForm}
      />

      <Route
        {...props}
        exact
        path="/contactUs"
        element={<Contactus {...props} />}
      />

      <Route
        {...props}
        exact
        path="/enquiryDetails"
        element={<EnquiryDetail {...props} />}
      />
      <Route
        {...props}
        exact
        path="/submitAdmissionIntrest"
        element={<SubmitAdmissionIntrest {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createinvoice"
        element={<CreateInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/editinvoice"
        element={<EditInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/createeditinvoice"
        element={<CreateEditInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/invoiceassignment"
        element={<InvoiceAssignment {...props} />}
      />
      <Route
        {...props}
        exact
        path="/feesubtypesetup"
        element={<FeeSubtype {...props} />}
      />
      <Route
        {...props}
        exact
        path="/ManageInvoice"
        element={<ManageInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/invoicetemplate"
        element={<InvoiceTemplate {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentinvoice"
        element={<StudentInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/classInvoice"
        element={<InvoiceClass {...props} />}
      />
      <Route
        {...props}
        exact
        path="/feeadminstudentview"
        element={<FeeAdminView {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createUpdateInstitution"
        element={<CreateUpdateInstitution {...props} />}
      />

      <Route
        {...props}
        exact
        path="/InstitutionSetup"
        element={<InstitutionSetup {...props} />}
      />

      <Route
        {...props}
        exact
        path="/manageusersetting"
        element={<ManageUserSetting {...props} />}
      />

      <Route
        {...props}
        exact
        path="/advanceattendance"
        element={<AdvanceAttendance {...props} />}
      />

      <Route
        {...props}
        exact
        path="/transportdetail"
        element={<TransportDetail {...props} />}
      />
      <Route
        {...props}
        exact
        path="/transportmarkingattendance"
        element={<TransportMarkingAttendance {...props} />}
      />

      <Route
        {...props}
        exact
        path="/assignStudent"
        element={<AssignStudent {...props} />}
      />

      <Route
        {...props}
        exact
        path="/report"
        element={<ReportHome {...props} />}
      />
      <Route
        {...props}
        exact
        path="/allstaffreport"
        element={<AllStaffReport {...props} />}
      />
      <Route
        {...props}
        exact
        path="/classstudentlist"
        element={<ClassStudentList {...props} />}
      />
      <Route
        {...props}
        exact
        path="/classteacherlist"
        element={<ClassTeacherList {...props} />}
      />
      <Route
        {...props}
        exact
        path="/reportDetails"
        element={<ReportDetails {...props} />}
      />
      <Route
        {...props}
        exact
        path="/reportExamination"
        element={<ReportExamination {...props} />}
      />

      <Route
        {...props}
        exact
        path="/adminmanageinstitution"
        element={<AdminManageInstitution {...props} />}
      />
      <Route
        {...props}
        exact
        path="/managegrade"
        element={<ManageGrade {...props} />}
      />
      <Route
        {...props}
        exact
        path="/managelocation"
        element={<ManageLocation {...props} />}
      />
      <Route
        {...props}
        exact
        path="/Managerole"
        element={<ManageRole {...props} />}
      />
      <Route
        {...props}
        exact
        path="/managedepartment"
        element={<ManageDepartment {...props} />}
      />
      <Route
        {...props}
        exact
        path="/managesubject"
        element={<ManageSubject {...props} />}
      />
      <Route
        {...props}
        exact
        path="/managedesignation"
        element={<ManageDesignation {...props} />}
      />
      <Route
        {...props}
        exact
        path="/manageclass"
        element={<ManageClass {...props} />}
      />
      <Route
        {...props}
        exact
        path="/addeditclass"
        element={<AddEditClass {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignteacher"
        element={<AssignTeacher {...props} />}
      />
      <Route
        {...props}
        exact
        path="/classtimetabletpenassignlink"
        element={<ClassTimeTableOpenAssignLink {...props} />}
      />
      <Route
        {...props}
        exact
        path="/createupdatetimetable"
        element={<CreateUpdateTimeTable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/timetable"
        element={<TimeTable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/timetablepreviewtemplate"
        element={<TimeTablePreviewTemplate {...props} />}
      />

      <Route
        {...props}
        exact
        path="/attendenceView"
        element={<ParentStudentAttendenceView {...props} />}
      />

      <Route
        {...props}
        exact
        path="/manageSession"
        element={<ManageSession {...props} />}
      />
      <Route
        {...props}
        exact
        path="/manageTimeTable"
        element={<ManageTimeTable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/timeTableTemplate"
        element={<CreateTimeTableTemplate {...props} />}
      />

      <Route
        {...props}
        exact
        path="/studentDetailForm"
        element={<AdminStudentDetailForm {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminemployeedetailform"
        element={<AdminEmployeeDetailForm {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminemployeelistview"
        element={<AdminEmployeeListView {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminstudentlistview"
        element={<AdminStudentListView {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studenttransportationpopup"
        element={<StudentTransportationPop {...props} />}
      />

      <Route
        {...props}
        exact
        path="/googlemapview"
        element={<GoogleMapView {...props} />}
      />

      <Route
        {...props}
        exact
        path="/changepassword"
        element={<ChangePassword {...props} />}
      />

      <Route
        {...props}
        exact
        path="/transporthome"
        element={<TransportHomePage {...props} />}
      />

      <Route
        {...props}
        exact
        path="/homemenu"
        element={<HomeMenu {...props} />}
      />

      <Route {...props} exact path="/fees" element={<FeeMenus {...props} />} />
      <Route
        {...props}
        exact
        path="/commonHolidayCalender"
        element={<CommonHoliDayCalender {...props} />}
      />

      <Route
        {...props}
        exact
        path="/manageExamination"
        element={<ManageExamination {...props} />}
      />

      <Route
        {...props}
        exact
        path="/adminUpdateExam"
        element={<AdminUpdateExam {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminExamStudentList"
        element={<AdminExamStudentList {...props} />}
      />

      <Route
        {...props}
        exact
        path="/adminClassNotice"
        element={<AdminClassNotice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminManageTeacherLeave"
        element={<AdminManageTeacherLeave {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminStudentWithdrawal"
        element={<AdminStudentWithdrawal {...props} />}
      />
      <Route
        {...props}
        exact
        path="/admissionsMenu"
        element={<AdmissionMenu {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminSchoolNotice"
        element={<AdminSchoolNotice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/createSchoolNotice"
        element={<CreateSchoolNotice {...props} />}
      />

      <Route
        {...props}
        exact
        path="/bulkUpload"
        element={<BulkUpload {...props} />}
      />
      <Route
        {...props}
        exact
        path="/manageRollNo"
        element={<ManageRollNo {...props} />}
      />

      <Route
        {...props}
        exact
        path="/viewPassengers"
        element={<ViewPassengers {...props} />}
      />
      <Route
        {...props}
        exact
        path="/Setting"
        element={<Setting {...props} />}
      />
      <Route
        {...props}
        exact
        path="/manageBankAccount"
        element={<ManageBankAccount {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminAddExam"
        element={<AdminAddExam {...props} />}
      />

      {/* TEACHER COMPONENTS */}
      <Route
        {...props}
        exact
        path="/teacherHomePage"
        element={<TeacherHomePage {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignmentAssign"
        element={<AssignmentAssign {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignmentCancel"
        element={<AssignmentCancel {...props} />}
      />
      <Route
        {...props}
        exact
        path="/listOfAssignments"
        element={<ListOfAssignments {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignmentView"
        element={<AssignmentView {...props} />}
      />
      <Route
        {...props}
        exact
        path="/editEvent"
        element={<CommonEditEvent {...props} />}
      />
      <Route
        {...props}
        exact
        path="/updateAssignment"
        element={<CreateUpdateAssignment {...props} />}
      />

      <Route
        {...props}
        exact
        path="/addNewAssignment"
        element={<CreateAssignment {...props} />}
      />

      <Route
        {...props}
        exact
        path="/teacherReviewOfAnswer"
        element={<TeacherReviewOfAnswer {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createGrading"
        element={<CreateGrading {...props} />}
      />

      <Route
        {...props}
        exact
        path="/examEnterMarks"
        element={<ExamEnterMarks {...props} />}
      />
      <Route
        {...props}
        exact
        path="/examViewResults"
        element={<ExamViewResults {...props} />}
      />
      <Route
        {...props}
        exact
        path="/applyLeave"
        element={<ApplyLeave {...props} />}
      />
      <Route
        {...props}
        exact
        path="/editteacherleave"
        element={<EditLeave {...props} />}
      />
      <Route
        {...props}
        exact
        path="/teacherLeaveSummary"
        element={<TeacherLeaveSummary {...props} />}
      />
      <Route
        {...props}
        exact
        path="/manageLeaveRequests"
        element={<ManageLeaveRequest {...props} />}
      />
      <Route
        {...props}
        exact
        path="/reviewApproveRejectList"
        element={<ReviewApproveRejectList {...props} />}
      />
      <Route
        {...props}
        exact
        path="/reviewMyLeave"
        element={<ReviewMyLeaves {...props} />}
      />

      <Route
        {...props}
        exact
        path="/markAttendanceHome"
        element={<MarkAttendanceHome {...props} />}
      />
      <Route
        {...props}
        exact
        path="/markAttendanceSubmit"
        element={<MarkingAttendanceSubmit {...props} />}
      />

      <Route
        {...props}
        exact
        path="/notification"
        element={<CommonNotifications {...props} />}
      />
      <Route
        {...props}
        exact
        path="/notificationOpened"
        element={<CommonNotificationopened {...props} />}
      />
      <Route
        {...props}
        exact
        path="/notificationComposed"
        element={<CommonNotificationCompose {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createNotice"
        element={<CreateNotice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/teacherNoticeBoard"
        element={<TeacherNoticeBoard {...props} />}
      />

      <Route
        {...props}
        exact
        path="/noticeManagementSchool"
        element={<NoticeManagementSubject {...props} />}
      />

      <Route
        {...props}
        exact
        path="/manageEvent"
        element={<CommonManageEvent {...props} />}
      />
      <Route
        {...props}
        exact
        path="/teacherEventPage"
        element={<TeacherEventpage {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createEvent"
        element={<CommonCreateEvent {...props} />}
      />
      <Route
        {...props}
        exact
        path="/eventInvite"
        element={<CommonEventInvite {...props} />}
      />
      <Route
        {...props}
        exact
        path="/eventDetails"
        element={<CommonEventDetails {...props} />}
      />
      <Route
        {...props}
        exact
        path="/attendanceCamera"
        element={<CommoneventAttendanceSubmitcamera {...props} />}
      />
      <Route
        {...props}
        exact
        path="/markEventAttendance"
        element={<CommonMarkEventAttendance {...props} />}
      />
      <Route
        {...props}
        exact
        path="/eventAttendance"
        element={<CommoneventAttendance {...props} />}
      />

      <Route
        {...props}
        exact
        path="/teacherTimetable"
        element={<TeacherTimetable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/teachermytimetable"
        element={<MyTeacherTimetable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/employeeProfileView"
        element={<EmployeeProfileView {...props} />}
      />

      <Route
        {...props}
        exact
        path="/teacherAttendanceView"
        element={<TeacherAttendanceView {...props} />}
      />
      <Route
        {...props}
        exact
        path="/eventMarkAttendanceView"
        element={<TeacherEventMarkAttendanceView {...props} />}
      />

      <Route
        {...props}
        exact
        path="/teacherTimeTableMenu"
        element={<TeacherTimeTableMenu {...props} />}
      />
      <Route
        {...props}
        exact
        path="/openAssignInvoice"
        element={<OpenAssignInvoice {...props} />}
      />

      {/* student follow */}
      <Route
        {...props}
        exact
        path="/studentHomePage"
        element={<StudentHomePage {...props} />}
      />
      <Route
        {...props}
        exact
        path="/calendar"
        element={<Calender {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignmentResult"
        element={<AssignmentResult {...props} />}
      />
      <Route
        {...props}
        exact
        path="/commonAssignmentResultView"
        element={<CommonAssignmentResultView {...props} />}
      />
      <Route
        {...props}
        exact
        path="/examinations"
        element={<Examination {...props} />}
      />
      <Route
        {...props}
        exact
        path="/examResult"
        element={<ExamResult {...props} />}
      />
      <Route
        {...props}
        exact
        path="/examTimeTable"
        element={<ExamTime {...props} />}
      />
      <Route
        {...props}
        exact
        path="/requestLeave"
        element={<RequestLeave {...props} />}
      />
      <Route
        {...props}
        exact
        path="/reviewLeave"
        element={<ReviewLeave {...props} />}
      />
      <Route
        {...props}
        exact
        path="/leaveSummary"
        element={<LeaveSummary {...props} />}
      />

      <Route
        {...props}
        exact
        path="/noticeBoard"
        element={<NoticeBoard {...props} />}
      />

      <Route
        {...props}
        exact
        path="/studentTimeTable"
        element={<StudentTimeTable {...props} />}
      />

      <Route
        {...props}
        exact
        path="/studentresetpassword"
        element={<StudentResetPassword {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignmentsubmission"
        element={<Assignmentsubmission {...props} />}
      />
      <Route
        {...props}
        exact
        path="/continueassignment"
        element={<ContinueAssignment {...props} />}
      />
      <Route
        {...props}
        exact
        path="/noticeDetail"
        element={<NoticeDetail {...props} />}
      />

      {/* <Route
       { ...props }
        exact
        path="/studentProfileView"
        element={ <StudentProfileView { ...props } />}
      /> */}
      <Route
        {...props}
        exact
        path="/studentListOfInvoice"
        element={<StudentListofInvoice {...props} />}
      />
      {/* <Route
       { ...props }
        exact
        path="/studentInvoiceAlreadyPaid"
        element={ <StudentInvoiceAlreadyPaid { ...props } />}
      /> */}

      <Route
        {...props}
        exact
        path="/viewInvoiceSummary"
        element={<StudentInvoiceSummarybyID {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentTransportDetail"
        element={<StudentTransportDetail {...props} />}
      />

      <Route
        {...props}
        exact
        path="/studentAttendance"
        element={<StudentAttendance {...props} />}
      />

      {/* parent flow */}
      <Route
        {...props}
        exact
        path="/parentHomePage"
        element={<ParentHomePage {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentExamination"
        element={<ParentExamination {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentExamResult"
        element={<ParentExamResult {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentExamTimeTable"
        element={<ParentExamTimeTable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/invoiceDetailAlreadyPaid"
        element={<InvoiceDetailAlreadyPaid {...props} />}
      />
      <Route
        {...props}
        exact
        path="/invoiceDetailView"
        element={<InvoiceDetailView {...props} />}
      />
      <Route {...props} exact path="/pay" element={<Pay {...props} />} />
      <Route
        isAuth={true}
        exact
        path="/listofInvoices"
        element={<ListOfInvoices {...props} />}
      />
      <Route
        {...props}
        exact
        path="/paymentnavigate"
        element={<Paymentnavigate {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentSchoolNotice"
        element={<ParentSchoolNotice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentNotice"
        element={<ParentNotice {...props} />}
      />

      <Route
        {...props}
        exact
        path="/studentAssignment"
        element={<StudentAssignment {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentAssignmentResult"
        element={<StudentAssignmentResult {...props} />}
      />
      <Route
        {...props}
        exact
        path="/vieweventDetails"
        element={<ParentStudentEventsDetails {...props} />}
      />

      <Route
        {...props}
        exact
        path="/showEvent"
        element={<ParentManageEvent {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentStudentLeave"
        element={<ParentStudentLeave {...props} />}
      />
      <Route
        isAuth={true}
        exact
        path="/parentfee"
        element={<ParentFeeHome {...props} />}
      />
      <Route
        {...props}
        exact
        path="/parentStudentTransport"
        element={<ParentStudentTransport {...props} />}
      />

      <Route
        {...props}
        exact
        path="/parentStudentTimeTable"
        element={<ParentStudentTimeTable {...props} />}
      />

      {/* staff */}
      <Route
        {...props}
        exact
        path="/staffHomePage"
        element={<StaffHomePage {...props} />}
      />

      <Route
        {...props}
        exact
        path="/attendanceViewDayAdminStudent"
        element={<AttendanceViewDayAdminStudent {...props} />}
      />
      <Route
        {...props}
        exact
        path="/attendanceViewDayAdminTeacher"
        element={<AttendanceViewDayAdminTeacher {...props} />}
      />

      <Route
        {...props}
        exact
        path="/enqueryDetailsReport"
        element={<EnqueryDetailsReport {...props} />}
      />
      <Route
        {...props}
        exact
        path="/details"
        element={<EmployeeDetailsFormReport {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createEditTemplate"
        element={<CreateEditTemplate {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createInvoiceTemplate"
        element={<CreateInvoiceTemplate {...props} />}
      />
      <Route
        {...props}
        exact
        path="/editFeeInvoice"
        element={<EditFeeInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignInvoice"
        element={<AssignInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminStudentAdmission"
        element={<AdminStudentAdmission {...props} />}
      />
      <Route
        {...props}
        exact
        path="/classpromotion"
        element={<ClassPromotion {...props} />}
      />

      <Route
        {...props}
        exact
        path="/viewClassPromotionResults"
        element={<ViewClassPromotionResults {...props} />}
      />
      <Route
        {...props}
        exact
        path="/assignonboardstudent"
        element={<AssignOnboardStudent {...props} />}
      />

      <Route
        {...props}
        exact
        path="/createUpdateOrganisation"
        element={<CreateUpdateOrganisation {...props} />}
      />

      <Route
        {...props}
        exact
        path="/superAdminHomeMenu"
        element={<SuperAdminHomeMenu {...props} />}
      />
      <Route
        {...props}
        exact
        path="/organisationSetup"
        element={<OrganisationSetup {...props} />}
      />
      <Route
        {...props}
        exact
        path="/createUpdateAdmin"
        element={<CreateUpdateAdmin {...props} />}
      />
      <Route
        {...props}
        exact
        path="/adminSetup"
        element={<AdminSetup {...props} />}
      />
      <Route
        {...props}
        exact
        path="/routelistings"
        element={<RouteListings {...props} />}
      />
      <Route
        {...props}
        exact
        path="/transportroutemap"
        element={<TransportRouteMap {...props} />}
      />
      <Route
        {...props}
        exact
        path="/createnewroute"
        element={<CreateNewRoute {...props} />}
      />
      <Route
        exact
        path="/studentform"
        element={<StudentForm {...props} />}
        {...props}
      />
      <Route
        {...props}
        exact
        path="/listofallroute"
        element={<ListofAllRoute {...props} />}
      />
      <Route
        exact
        path="/employeedetailform"
        element={<EmployeeDetailForm {...props} />}
        {...props}
      />
      <Route
        {...props}
        exact
        path="/manageRoute"
        element={<ManageRoutes {...props} />}
      />
      <Route
        {...props}
        exact
        path="/employeeHomePage"
        element={<EmployeeHomePage {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentprofile"
        element={<StudentProfile {...props} />}
      />
      <Route
        {...props}
        exact
        path="/holidaycalender"
        element={<HolidayCalender {...props} />}
      />
      <Route
        {...props}
        exact
        path="/addholidaycalender"
        element={<AddHolidayCalender {...props} />}
      />
      <Route
        {...props}
        exact
        path="/updateholidaycalender"
        element={<UpdateHolidayCalender {...props} />}
      />
      <Route
        {...props}
        exact
        path="/callback"
        element={<CallBack {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentsyllabus"
        element={<StudentSyllabus {...props} />}
      />
      <Route
        {...props}
        exact
        path="/transport"
        element={<EmployeeTransport {...props} />}
      />
      <Route
        {...props}
        exact
        path="/marktransportattendence"
        element={<MarkTransportAttendence {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentpaymentnavigate"
        element={<StudentPaymentnavigate {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studentsttendencereport"
        element={<StudentAttendenceReport {...props} />}
      />
      <Route
        {...props}
        exact
        path="/updateemployeetransport"
        element={<UpdateEmployeeTransport {...props} />}
      />
      <Route
        {...props}
        exact
        path="/TeacherHomeEventMain"
        element={<TeacherHomeEventMain {...props} />}
      />
      <Route
        {...props}
        exact
        path="/ManageAllTimeTable"
        element={<ManageAllTimeTable {...props} />}
      />
      <Route
        {...props}
        exact
        path="/CreateUpdateInstitutionSuperAdmin"
        element={<CreateUpdateInstitutionSuperAdmin {...props} />}
      />
      <Route
        {...props}
        exact
        path="/TimeTableUpdateTime"
        element={<TimeTableUpdateTime {...props} />}
      />
      <Route
        {...props}
        exact
        path="/ManageEventForTeacher"
        element={<ManageEventForTeacher {...props} />}
      />
      <Route
        {...props}
        exact
        path="/studyMaterial"
        element={<StudyMaterial {...props} />}
      />

      <Route
        {...props}
        exact
        path="/viewStudyMaterial"
        element={<ViewStudyMaterial {...props} />}
      />

      <Route
        {...props}
        exact
        path="/currencySettings"
        element={<CurrencySettings {...props} />}
      />
      <Route
        exact
        path="/endUserAggreement"
        element={<EndUserAggreement {...props} />}
      />
      <Route
        exact
        path="/privacyPolicy"
        element={<PrivacyPolicy {...props} />}
      />
      <Route
        exact
        path="/termsOfUse"
        element={<TermsAndCondition {...props} />}
      />
      <Route
        exact
        path="/PtSudentForm"
        element={<PtStudentForm {...props} />}
        {...props}
      />
      <Route
        {...props}
        exact
        path="/PtTeacherAssignment"
        element={<PtTeacherAssignment {...props} />}
      />
      <Route
        {...props}
        exact
        path="/PrivateTeachingInvoice"
        element={<PrivateTeachingInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/GenrateInvoiceForPrivate"
        element={<GenrateInvoiceForPrivate {...props} />}
      />
      <Route
        {...props}
        exact
        path="/OnlineTeachingInvoices"
        element={<OnlineTeachingInvoices {...props} />}
      />
      <Route
        {...props}
        exact
        path="/PtAdminInvoiceSummaryPage"
        element={<PtAdminInvoiceSummaryPage {...props} />}
      />
      <Route
        {...props}
        exact
        path="/ptMyInvoice"
        element={<PtMyInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/PtPayMentHistory"
        element={<PtPayMentHistory {...props} />}
      />
      <Route
        {...props}
        exact
        path="/PtAlreadypaidInvoice"
        element={<PtAlreadypaidInvoice {...props} />}
      />
      <Route
        {...props}
        exact
        path="/PtInvoiceSummaryView"
        element={<PtInvoiceSummaryView {...props} />}
      />
      <Route
        {...props}
        exact
        path="/GetStarted"
        element={<GetStarted {...props} />}
      />
      <Route
        {...props}
        exact
        path="/ManageCredits"
        element={<ManageCredits {...props} />}
      />
      <Route
        {...props}
        exact
        path="/StudentMange"
        element={<StudentMange {...props} />}
      />
      <Route
        {...props}
        exact
        path="/ParentMange"
        element={<ParentMange {...props} />}
      />
      <Route
        {...props}
        exact
        path="/CreateInvoiceForDate"
        element={<CreateInvoiceForDate {...props} />}
      />
      <Route
        {...props}
        exact
        path="/PtTeacherFormDetails"
        element={<PtTeacherFormDetails {...props} />}
      />
      {/* <Route
        exact
        path="/adminManageStudentLeave"
        element={ <AdminMangeStudentLeave { ...props } />}
      />
      <Route
        exact
        path="/adminReviewStudentLeave"
        element={ <AdminReviewStudentLeave { ...props } />}
      /> */}
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  isAuth: getData(state, Constants.AUTHENTICATION),
});
export default connect(mapStateToProps, null)(CustomeRoutes);
