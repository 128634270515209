import { useApolloClient } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

//redux
import * as Service from "../../../services/index";
import { CapitalizeFirstFn, getFullName } from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StudentAssignment = (props) => {
  //user Id
  let user_id = parseInt(props?.location?.state?.row?.id);

  //stdent Name
  let userName = props.location.state?.row?.name;

  //section
  let section = props.location.state?.row?.section?.name;

  //class
  let grade = props.location.state?.row?.section?.grade?.gradeName;

  //Institute name
  let institution_Name = props?.location?.state?.institution_Name;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const client = useApolloClient();
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(false);

  //assignment array
  const [rowsPerPageC, setRowsPerPageC] = React.useState(5);
  const [pageC, setPageC] = React.useState(0);

  const [rowsPerPageP, setRowsPerPageP] = React.useState(5);
  const [pageP, setPageP] = React.useState(0);

  const [rowsPerPageN, setRowsPerPageN] = React.useState(5);
  const [pageN, setPageN] = React.useState(0);

  const [completedArray, setCompletedArray] = useState([]);

  const [inProgressArray, setInProgressArray] = useState([]);
  const [innewArray, setInNewArray] = useState([]);
  const [SubmittedArray, setSubmittedArray] = useState([]);

  useEffect(() => {
    getAssignment();
  }, [user_id, client]);

  const getAssignment = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Student_Assignment,
          variables: {
            user_id,
          },
        })
        .then((response) => {
          setisloading(false);

          if (response.data.getAssignmentForStudent.length > 0) {
            setInProgressArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "INPROGRESS";
              })
            );
            setCompletedArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "COMPLETED";
              })
            );
            setSubmittedArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "SUBMITTED";
              })
            );
            setInNewArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "ASSIGNED" || item.status === "NEW";
              })
            );
          }
        });
    } catch (error) {
      setisloading(false);
      console.log("getAssignment try and catch inprog", error);
    }
  };

  const FilterCompletedArray = completedArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  // const emptyRows =
  //   rowsPerPageC -
  //   Math.min(rowsPerPageC, FilterCompletedArray.length - pageC * rowsPerPageC);

  const handleChangePageC = (event, newPage) => {
    setPageC(newPage);
  };
  const handleChangeRowsPerPageC = (event) => {
    setRowsPerPageC(parseInt(event.target.value, 10));
    setPageC(0);
  };

  const FilterInProgressArray = inProgressArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  // const emptyRows2 =
  //   rowsPerPageP -
  //   Math.min(rowsPerPageP, FilterInProgressArray.length - pageP * rowsPerPageP);

  const handleChangePageP = (event, newPage) => {
    setPageP(newPage);
  };
  const handleChangeRowsPerPageP = (event) => {
    setRowsPerPageP(parseInt(event.target.value, 10));
    setPageP(0);
  };

  const FilterInNewArray = innewArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  // const emptyRows3 =
  //   rowsPerPageN -
  //   Math.min(rowsPerPageN, FilterInNewArray.length - pageN * rowsPerPageN);

  const handleChangePageN = (event, newPage) => {
    setRowsPerPageN(newPage);
  };
  const handleChangeRowsPerPageN = (event) => {
    setRowsPerPageN(parseInt(event.target.value, 10));
    setPageN(0);
  };

  // Submitted Array assignment

  const [rowsPerPageS, setRowsPerPageS] = React.useState(5);

  const [pageS, setPageS] = React.useState(0);

  const filterSubmitted = SubmittedArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  // const emptyRows7 =
  //   rowsPerPageS -
  //   Math.min(rowsPerPageS, filterSubmitted.length - pageS * rowsPerPageS);

  const handleChangePageS = (event, newPage) => {
    setPageS(newPage);
  };
  const handleChangerowsPerPageS = (event) => {
    setRowsPerPageS(parseInt(event.target.value, 10));
    setPageS(0);
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_Name} />

            <div className="enquirydetails_background">
              <div className="ml-4">
                <div className="mt-3  Heading_bold">Assignment</div>
                <div className="mt-1 ">
                  <span className="Heading_bold">Name : </span>{" "}
                  <span> {getFullName(userName)}</span>
                </div>
                <div>
                  <span className="Heading_bold">Class : </span>
                  <span>{grade + "-" + section}</span>
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div">
                        <Grid item md={8}>
                          <div className=" mt-2 ml-2 mr-2 mybarwidth">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              //  variant="scrollable"
                              aria-label="simple tabs example"
                              //className="tab_width_exam"
                              indicatorColor="primary"
                              textColor="primary"
                              left
                            >
                              <Tab
                                label="New"
                                {...a11yProps(0)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                              <Tab
                                label="In Progress"
                                {...a11yProps(1)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                              <Tab
                                label="Submitted"
                                {...a11yProps(2)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                              <Tab
                                label="Completed"
                                {...a11yProps(3)}
                                className="button_login_decoration text_tranformation_exam"
                              />
                            </Tabs>
                          </div>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={3}>
                          <div className="inputMarginright mt-2">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Search"
                              autoComplete="off"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="mt-2 mb-2">
                        <TabPanel value={value} index={0}>
                          <div className="table_foramtitng mt-1 mb-2">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                                id="Table_width_Student"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Subject
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Start Date
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      End Date
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPageN > 0
                                    ? FilterInNewArray.slice(
                                        pageN * rowsPerPageN,
                                        pageN * rowsPerPageN + rowsPerPageN
                                      )
                                    : FilterInNewArray
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        className="fontsizetable table_assignement_Table_padding"
                                        align="left"
                                      >
                                        {row.assignment === "" ||
                                        row.assignment === null ||
                                        row.assignment === undefined ? (
                                          ""
                                        ) : (
                                          <span>
                                            {row.assignment.name === "" ||
                                            row.assignment.name === null ||
                                            row.assignment.name ===
                                              undefined ? (
                                              " "
                                            ) : (
                                              <span>
                                                {CapitalizeFirstFn(
                                                  row.assignment.name
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className="fontsizetable table_assignement_Table_padding"
                                        align="left"
                                      >
                                        {row.class_assignment === "" ||
                                        row.class_assignment === null ||
                                        row.class_assignment === undefined ? (
                                          " "
                                        ) : (
                                          <span>
                                            {row.class_assignment.start_date ===
                                              "" ||
                                            row.class_assignment.start_date ===
                                              null ||
                                            row.class_assignment.start_date ===
                                              undefined ? (
                                              " "
                                            ) : (
                                              <span>
                                                {new Intl.DateTimeFormat(
                                                  "en-US",
                                                  {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                  }
                                                ).format(
                                                  row.class_assignment
                                                    .start_date
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className="fontsizetable table_assignement_Table_padding"
                                        align="left"
                                      >
                                        {row.class_assignment === "" ||
                                        row.class_assignment === null ||
                                        row.class_assignment === undefined ? (
                                          " "
                                        ) : (
                                          <span>
                                            {row.class_assignment.end_date ===
                                              "" ||
                                            row.class_assignment.end_date ===
                                              null ||
                                            row.class_assignment.end_date ===
                                              undefined ? (
                                              " "
                                            ) : (
                                              <span>
                                                {new Intl.DateTimeFormat(
                                                  "en-US",
                                                  {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                  }
                                                ).format(
                                                  row.assignment.end_date
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={FilterInNewArray.length}
                                rowsPerPage={rowsPerPageN}
                                page={pageN}
                                onChangePage={handleChangePageN}
                                onChangeRowsPerPage={handleChangeRowsPerPageN}
                              />
                            </TableContainer>
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                          <div>
                            <div className="table_foramtitng mt-1 mb-2">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                  id="Table_width_Student"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_assignement_Table_padding"
                                      >
                                        Subject
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_assignement_Table_padding"
                                      >
                                        Start Date
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_assignement_Table_padding"
                                      >
                                        End Date
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPageP > 0
                                      ? FilterInProgressArray.slice(
                                          pageP * rowsPerPageP,
                                          pageP * rowsPerPageP + rowsPerPageP
                                        )
                                      : FilterInProgressArray
                                    ).map((row) => {
                                      return (
                                        <StyledTableRow>
                                          <StyledTableCell
                                            className="fontsizetable table_assignement_Table_padding"
                                            align="left"
                                          >
                                            {row.assignment === "" ||
                                            row.assignment === null ||
                                            row.assignment === undefined ? (
                                              ""
                                            ) : (
                                              <span>
                                                {row.assignment.name === "" ||
                                                row.assignment.name === null ||
                                                row.assignment.name ===
                                                  undefined ? (
                                                  " "
                                                ) : (
                                                  <span>
                                                    {CapitalizeFirstFn(
                                                      row.assignment.name
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="fontsizetable table_assignement_Table_padding"
                                            align="left"
                                          >
                                            {row.class_assignment === "" ||
                                            row.class_assignment === null ||
                                            row.class_assignment ===
                                              undefined ? (
                                              " "
                                            ) : (
                                              <span>
                                                {row.class_assignment
                                                  .start_date === "" ||
                                                row.class_assignment
                                                  .start_date === null ||
                                                row.class_assignment
                                                  .start_date === undefined ? (
                                                  " "
                                                ) : (
                                                  <span>
                                                    {new Intl.DateTimeFormat(
                                                      "en-US",
                                                      {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                      }
                                                    ).format(
                                                      row.class_assignment
                                                        .start_date
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="fontsizetable table_assignement_Table_padding"
                                            align="left"
                                          >
                                            {row.class_assignment === "" ||
                                            row.class_assignment === null ||
                                            row.class_assignment ===
                                              undefined ? (
                                              " "
                                            ) : (
                                              <span>
                                                {row.class_assignment
                                                  .end_date === "" ||
                                                row.class_assignment
                                                  .end_date === null ||
                                                row.class_assignment
                                                  .end_date === undefined ? (
                                                  " "
                                                ) : (
                                                  <span>
                                                    {new Intl.DateTimeFormat(
                                                      "en-US",
                                                      {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                      }
                                                    ).format(
                                                      row.assignment.end_date
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 15]}
                                  component="div"
                                  count={FilterInProgressArray.length}
                                  rowsPerPage={rowsPerPageP}
                                  page={pageP}
                                  onChangePage={handleChangePageP}
                                  onChangeRowsPerPage={handleChangeRowsPerPageP}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                          <div className="table_foramtitng mt-1 mb-2">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table fontsizetable"
                                id="Table_width_Student"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Subject
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Start Date
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      End Date
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {(rowsPerPageS > 0
                                    ? filterSubmitted.slice(
                                        pageS * rowsPerPageS,
                                        pageS * rowsPerPageS + rowsPerPageS
                                      )
                                    : filterSubmitted
                                  ).map((row) => {
                                    return (
                                      <StyledTableRow>
                                        <StyledTableCell
                                          className="fontsizetable table_assignement_Table_padding"
                                          align="left"
                                        >
                                          {row.assignment === "" ||
                                          row.assignment === null ||
                                          row.assignment === undefined ? (
                                            ""
                                          ) : (
                                            <span>
                                              {row.assignment.name === "" ||
                                              row.assignment.name === null ||
                                              row.assignment.name ===
                                                undefined ? (
                                                " "
                                              ) : (
                                                <span>
                                                  {CapitalizeFirstFn(
                                                    row.assignment.name
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          className="fontsizetable table_assignement_Table_padding"
                                          align="left"
                                        >
                                          {row.class_assignment === "" ||
                                          row.class_assignment === null ||
                                          row.class_assignment === undefined ? (
                                            " "
                                          ) : (
                                            <span>
                                              {row.class_assignment
                                                .start_date === "" ||
                                              row.class_assignment
                                                .start_date === null ||
                                              row.class_assignment
                                                .start_date === undefined ? (
                                                " "
                                              ) : (
                                                <span>
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                    }
                                                  ).format(
                                                    row.class_assignment
                                                      .start_date
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          className="fontsizetable table_assignement_Table_padding"
                                          align="left"
                                        >
                                          {row.class_assignment === "" ||
                                          row.class_assignment === null ||
                                          row.class_assignment === undefined ? (
                                            " "
                                          ) : (
                                            <span>
                                              {row.class_assignment.end_date ===
                                                "" ||
                                              row.class_assignment.end_date ===
                                                null ||
                                              row.class_assignment.end_date ===
                                                undefined ||
                                              row.class_assignment.end_date ===
                                                "NaN" ? (
                                                " "
                                              ) : (
                                                <span>
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                    }
                                                  ).format(
                                                    row.assignment.end_date
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>

                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterSubmitted.length}
                                rowsPerPage={rowsPerPageS}
                                page={pageS}
                                onChangePage={handleChangePageS}
                                onChangeRowsPerPage={handleChangerowsPerPageS}
                              />
                            </TableContainer>
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                          <div className="table_foramtitng mt-1 mb-2">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table fontsizetable"
                                id="Table_width_Student"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Subject
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Start Date
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      End Date
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header table_assignement_Table_padding"
                                    >
                                      Details
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {(rowsPerPageC > 0
                                    ? FilterCompletedArray.slice(
                                        pageC * rowsPerPageC,
                                        pageC * rowsPerPageC + rowsPerPageC
                                      )
                                    : FilterCompletedArray
                                  ).map((row) => {
                                    return (
                                      <StyledTableRow>
                                        <StyledTableCell
                                          className="fontsizetable table_assignement_Table_padding"
                                          align="left"
                                        >
                                          {row.assignment === "" ||
                                          row.assignment === null ||
                                          row.assignment === undefined ? (
                                            ""
                                          ) : (
                                            <span>
                                              {row.assignment.name === "" ||
                                              row.assignment.name === null ||
                                              row.assignment.name ===
                                                undefined ? (
                                                " "
                                              ) : (
                                                <span>
                                                  {CapitalizeFirstFn(
                                                    row.assignment.name
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          className="fontsizetable table_assignement_Table_padding"
                                          align="left"
                                        >
                                          {row.class_assignment === "" ||
                                          row.class_assignment === null ||
                                          row.class_assignment === undefined ? (
                                            " "
                                          ) : (
                                            <span>
                                              {row.class_assignment
                                                .start_date === "" ||
                                              row.class_assignment
                                                .start_date === null ||
                                              row.class_assignment
                                                .start_date === undefined ? (
                                                " "
                                              ) : (
                                                <span>
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                    }
                                                  ).format(
                                                    row.class_assignment
                                                      .start_date
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          className="fontsizetable table_assignement_Table_padding"
                                          align="left"
                                        >
                                          {row.class_assignment === "" ||
                                          row.class_assignment === null ||
                                          row.class_assignment === undefined ? (
                                            " "
                                          ) : (
                                            <span>
                                              {row.class_assignment.end_date ===
                                                "" ||
                                              row.class_assignment.end_date ===
                                                null ||
                                              row.class_assignment.end_date ===
                                                undefined ? (
                                                " "
                                              ) : (
                                                <span>
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                    }
                                                  ).format(
                                                    row.assignment.end_date
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          className="fontsizetable row_details_color table_assignement_Table_padding"
                                          align="left"
                                          onClick={() =>
                                            props.navigate(
                                              "/studentAssignmentResult",
                                              {
                                                item: row,
                                                studentdata:
                                                  props.location.state,
                                              }
                                            )
                                          }
                                        >
                                          Open
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>

                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={FilterCompletedArray.length}
                                rowsPerPage={rowsPerPageC}
                                page={pageC}
                                onChangePage={handleChangePageC}
                                onChangeRowsPerPage={handleChangeRowsPerPageC}
                              />
                            </TableContainer>
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

export default HOC1(StudentAssignment);
