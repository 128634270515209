import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//---------------redux-------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { CapitalizeFirstFn } from "../../../utils/Validation";

const ParentFeeHome = (props) => {
  const navigate = props.navigate;
  //-----------------------------global id----------------------
  const { institution_name } = props.PARENT_DATA;
  //-----------------------------api calls----------------------
  const client = useApolloClient();
  const classes = useStyles();
  //-----------------------------local state----------------------
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [studentListArr, setStudentListArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  useEffect(() => {
    parentGetData();
  }, [client, props.PARENT_DATA, isUpdated]);

  const parentGetData = async () => {
    try {
      if (
        !props.PARENT_DATA ||
        !props.PARENT_DATA.userid ||
        !props.PARENT_DATA.institution_id
      ) {
        return;
      }
      setisloading(true);
      const response = await client.query({
        query: Service.Get_Parent_student_data,
        variables: {
          institution_id: parseInt(props.PARENT_DATA.institution_id),
          father_id: parseInt(props.PARENT_DATA.userid),
          status: "ACTIVE",
        },
      });

      setStudentListArr(response.data.getStudentByParent);
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold">Fees</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="mt-3  Heading_bold">Student List</div>
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Roll No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Payment
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? studentListArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : studentListArr
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {row.student.roll_number}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left">
                                      {CapitalizeFirstFn(row.name.first_name)}{" "}
                                      {CapitalizeFirstFn(row.name.middle_name)}{" "}
                                      {CapitalizeFirstFn(row.name.last_name)}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      {row.student.section.grade.gradeName}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.student.section.name}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                      onClick={() =>
                                        navigate("/listofInvoices", {
                                          state: {
                                            row,
                                            institution_name: institution_name,
                                          },
                                        })
                                      }
                                    >
                                      View
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={studentListArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangerowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  PARENT_DATA: getData(state, Constants.TOKEN) || {},
});

export default connect(mapStateToProps, null)(HOC1(ParentFeeHome));
