import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function FeeSubtype(props) {
  //--------------- gloabl id ----------------------
  let institution_id = parseInt(props.data.institution_id);
  let Organization_Name = props.data.institution_name;
  //--------------- api calls ----------------------
  const client = useApolloClient();
  const [Delete_FeesubType_Data, {}] = useMutation(
    Service.Delete_FeesubType_Data
  );
  const [Create_FeesubType_Data, {}] = useMutation(
    Service.Create_FeesubType_Data
  );
  const [Edit_FeesubType_Data, {}] = useMutation(Service.Edit_FeesubType_Data);
  //--------------- local data ----------------------
  const [createfeetype, setcreatefeetype] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [subtypeID, setSubtypeID] = useState("");
  const [nameedit, setNameedit] = useState("");
  const [descriptionedit, setDescriptionedit] = useState("");
  const [openeditfeesubtype, setOpeneditfeesubtype] = useState(false);
  const [feesubtypesetup, setfeesubtypesetup] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [DeleteSubtypeName, setDeleteSubtypeName] = useState("");
  const [DeleteSubtypeID, setDeleteSubtypeID] = useState("");

  //errors
  const [nameError, setnameError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [editNameError, seteditNameError] = useState(false);
  const [editdescriptionError, seteditdescriptionError] = useState(false);

  const handlefeeComponent = () => {
    setcreatefeetype(true);
  };

  const closefeeComponent = () => {
    setcreatefeetype(false);
  };

  const opendailogfeesubtype = (item) => {
    setNameedit(item.name);
    setDescriptionedit(item.description);
    setSubtypeID(item.id);
    setOpeneditfeesubtype(true);
  };

  const DeleteDailogBox = (data) => {
    setDeleteSubtypeID(data.id);
    setDeleteSubtypeName(data.name);
    setopendialogConfimation(true);
  };

  const closeFeesubtypedailog = () => {
    setOpeneditfeesubtype(false);
  };
  //get location data

  const getFeesubtypedata = async () => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_Fee_Subtype_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setfeesubtypesetup(response.data.getFeeSubtype.reverse());
          setName("");
          setDescription("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log(" location arr try and catch", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getFeesubtypedata();
  }, [client, institution_id]);

  //create fee subtype
  const createFeeSubtype = () => {
    try {
      if (!blankValidator(name)) {
        setnameError(true);
        return;
      }
      if (!blankValidator(description)) {
        setdescriptionError(true);
        return;
      }
      setisloading(true);
      Create_FeesubType_Data({
        variables: { institution_id, name, description },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.addFeeSubtype.message, "success");

          getFeesubtypedata();
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const deleteFeeSubtype = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_FeesubType_Data({
        variables: { id },
      })
        .then((response) => {
          setisloading(false);
          if (response.data.deleteFeeSubtype.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(
            response.data.deleteFeeSubtype.message,
            "success"
          );

          setopendialogConfimation(false);
          getFeesubtypedata();
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const UpdateFeeSubtype = () => {
    try {
      let id = parseInt(subtypeID);
      if (!blankValidator(nameedit)) {
        seteditNameError(true);
        return;
      }
      if (!blankValidator(descriptionedit)) {
        seteditdescriptionError(true);
        return;
      }
      setisloading(true);
      Edit_FeesubType_Data({
        variables: { id, name: nameedit, description: descriptionedit },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateFeeSubtype.message,
            "success"
          );

          setOpeneditfeesubtype(false);
          getFeesubtypedata();
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background">
                {createfeetype === false ? (
                  <div>
                    <div className="ml-3 Heading_bold">Fee Subtype Setup</div>
                    <div
                      className="icon_pencil_color d-flex addPointer ml-3"
                      onClick={handlefeeComponent}
                    >
                      <span className="mr-2 mt-1">Add new subtype</span>
                      <span className="iconstoaddfee mr-3">
                        <i className="fa fa-plus-circle"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="card_margin_both_side">
                    <Expand open={createfeetype}>
                      <Card>
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <Grid className="grid_main_div">
                              <Grid item md={12}>
                                <div className="icon_pencil_color float-right">
                                  <span
                                    className=" iconstoaddfee  "
                                    onClick={closefeeComponent}
                                  >
                                    <i className="fa fa-minus-circle"></i>
                                  </span>
                                </div>
                                <div className="inputMarginright">
                                  <label for="exampleInputEmail1">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Title"
                                    autoComplete="off"
                                    value={name}
                                    onChange={(e) => {
                                      setnameError(false);
                                      setName(e.target.value);
                                    }}
                                  />
                                  {nameError && (
                                    <span className="text-danger">
                                      Enter the name
                                    </span>
                                  )}
                                </div>
                                <div className="inputMarginright">
                                  <label for="exampleInputEmail1">
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Description"
                                    autoComplete="off"
                                    value={description}
                                    onChange={(e) => {
                                      setdescriptionError(false);
                                      setDescription(e.target.value);
                                    }}
                                  />
                                  {descriptionError && (
                                    <span className="text-danger">
                                      Enter the Description
                                    </span>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                            <div className="btn_add mt-2">
                              <Button
                                variant="contained"
                                className="addButtonwidth button_login_decoration"
                                onClick={createFeeSubtype}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Expand>
                  </div>
                )}

                <div className="card_margin_both_side">
                  <div className="cardheight_overflow">
                    {feesubtypesetup.length ? (
                      feesubtypesetup.map((item, index) => (
                        <Card className="cardvisible_border mb-2">
                          <div className="card_admissiondetails_height">
                            <div className="textfiled_margin float-right">
                              <div>
                                <span className="iconstoaddfee">
                                  <i
                                    class="fa fa-pencil"
                                    onClick={() => opendailogfeesubtype(item)}
                                  ></i>
                                </span>
                                <span className="iconstoaddfee ml-2">
                                  <i
                                    class="fa fa-trash"
                                    onClick={() => DeleteDailogBox(item)}
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div className="textfiled_margin mb-2">
                              <Grid className="grid_main_div">
                                <Grid item md={6} xs={8}>
                                  <div className="text_filed_heading">
                                    Title
                                  </div>
                                  <div className="inputMarginright">
                                    <TextField
                                      id="standard-required"
                                      placeholder=""
                                      autoComplete="off"
                                      className="text_Filed_width"
                                      value={feesubtypesetup[index].name}
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <div className="text_filed_heading mt-2">
                                Description
                              </div>
                              <Grid className="grid_main_div">
                                <Grid item md={10}>
                                  <div className="inputMarginright">
                                    <TextField
                                      id="standard-required"
                                      placeholder="Registration for new admission"
                                      autoComplete="off"
                                      className="text_Filed_width"
                                      value={feesubtypesetup[index].description}
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </Card>
                      ))
                    ) : (
                      <div>No Data</div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={openeditfeesubtype}
        onClose={closeFeesubtypedailog}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i class="fa fa-times cursor" onClick={closeFeesubtypedailog}></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Title</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={nameedit}
                    onChange={(e) => {
                      seteditNameError(false);
                      setNameedit(e.target.value);
                    }}
                  />
                  {editNameError && (
                    <span className="text-danger">Enter the name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
            <Grid className="grid_main_div mt-2">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Description</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={descriptionedit}
                    onChange={(e) => {
                      seteditdescriptionError(false);
                      setDescriptionedit(e.target.value);
                    }}
                  />
                  {editdescriptionError && (
                    <span className="text-danger">Enter the description</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <div className="dialogButton">
            <Button
              variant="contained"
              className="button_enquiry_details button_login_decoration ml-2"
              onClick={UpdateFeeSubtype}
            >
              Update
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Fee SubType : {DeleteSubtypeName}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteFeeSubtype(DeleteSubtypeID)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});
export default connect(mapStateToProps, null)((HOC1(FeeSubtype)));
