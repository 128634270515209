import React from "react";
import "./Footer.css";
const Footer = (props) => {
  return (
    <div>
      <div className="mb-1 Footer_main_div">
        <span
          className="Footer_content_formatting pr-1"
          onClick={() =>props.navigate("/contactUs")}
        >
          Contact us
        </span>{" "}
        |
        <span
          className="Footer_content_formatting pl-1 pr-1"
          onClick={() =>props.navigate("/termsOfUse")}
        >
          Terms of Use{" "}
        </span>{" "}
        |
        <span
          className="Footer_content_formatting pl-1"
          onClick={() =>props.navigate("/privacyPolicy")}
        >
          Privacy policy
        </span>
      </div>
    </div>
  );
};

export default Footer;
