import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./Report.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";
import {
  dateToDateFormat,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation";

//------------api calls-----------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import Holiday from "../../Common/Holiday/Holiday.jsx";

const ClassTeacherList = (props) => {
  let section_id = parseInt(props.sectionId);
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [TeacherData, setTeacherData] = useState([]);
  const classes = useStyles();
  const [search, setSearch] = useState("");
  //-------------------methods----------------------

  useEffect(() => {
    getTeacherListFn();
  }, [props]);
  const getTeacherListFn = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Teacher_list_by_SEctionID,
          variables: {
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);
          const temp = [];
          const teacherListArr = response.data.getTeacherListBySectionId;
          for (let i = 0; i < teacherListArr.length; i++) {
            const item = teacherListArr[i];
            if (!item.employee) {
              continue;
            }
            let flag = false;
            for (let index = 0; index < temp.length; index++) {
              const element = temp[index];
              if (element.employee.id == item.employee.id) {
                flag = true;
                break;
              }
            }
            if (flag == false) {
              temp.push(item);
            }
          }
          if (teacherListArr.length !== 0) {
            setTeacherData(temp);
          }
        })
        .catch((error) => {
          setisloading(false);
          console.log("class teacher list  catch err", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("class teacher list  t and c error", error);
    }
  };

  //Teacher array
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showDetails = (staffId) => {
    if (staffId) {
     props.navigate("/adminemployeedetailform", {
        pagetype: "edit",
        staffId,
      });
    } else {
      showNotificationMsz("Selected id can not be null", "info");
    }
  };
  const filterArr = TeacherData.filter((event) => {
    if (event.employee == null) {
      return false;
    }
    return (
      getFullName(event.employee.name)
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.institution_name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold">Report</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-4 mb-4">
                        <Grid item md={8}>
                          <div className="List_heading_formatting">
                            <h5>
                              Teacher list{" "}
                              {`( ${props.class_Name} ${props.section_Name})`}
                            </h5>
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Teacher Name"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className=" table_foramtitng mt-3 mb-2">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Profile
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Teacher Name
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  ID
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Gender
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Joining Date
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Blood Group
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? filterArr.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : filterArr
                              ).map((row, index) => (
                                <StyledTableRow key={row.title} key={index}>
                                  <StyledTableCell align="left">
                                    <img
                                    // src={`${Service.baseUrl}/${row.photo_path}`}
                                      src={`${Service.baseUrl}/${row.employee?.photo?.path}`}
                                      alt=""
                                      className="img_formatting imageCursor"
                                      onClick={() =>
                                        showDetails(row.employee.id)
                                      }
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {getFullName(row.employee?.name)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    
                                    scope="row"
                                    align="left"
                                  >
                                    {row.employee?.login?.display_id}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {row.employee?.gender}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {dateToDateFormat(
                                      row.employee.joining_date
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {row.employee?.blood_group}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterArr.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangerowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default (HOC1(ClassTeacherList));
