import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./Report.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import Loder from "../../Loder/Loder.jsx";
import { dateToDateFormat, getFullName } from "../../../utils/Validation.js";

const ClassStudentList = ({
  sectionId,
  navigate,
  class_Name,
  section_Name,
  institution_name,
}) => {
  const client = useApolloClient();
  const classes = useStyles();
  const [searchStudent, setSearchStudent] = useState("");
  const [isloading, setisloading] = useState(false);
  const [studentArr, setStudentArr] = useState([]);

  useEffect(() => {
    GetStudentBySectionBasis();
  }, []);

  const GetStudentBySectionBasis = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id: parseInt(sectionId),
          },
        })
        .then((response) => {
          setisloading(false);
          setStudentArr(response.data.getStudentListBySectionId);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  //Student array
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showDetails = (id) => {
    navigate("studentDetails", { studentId: id });
  };
  const studentFilterArr = studentArr.filter((event) => {
    return (
      getFullName(event.student_profile.name)
        .toLowerCase()
        .indexOf(searchStudent.toLowerCase()) !== -1
    );
  });
  const userDetailsHandler = (studentId) => {
    console.log("navigate", navigate, studentId);
    navigate("/studentDetailForm", {
      state: {
        studentId,
      },
    });
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold">Report</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-4 mb-4">
                        <Grid item md={8}>
                          <div className="List_heading_formatting">
                            <h5>
                              Student list {`( ${class_Name} ${section_Name} )`}
                            </h5>
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Student Name"
                              value={searchStudent}
                              onChange={(e) => {
                                setSearchStudent(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="table_foramtitng mt-3 mb-2">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Roll No.
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Profile
                                </StyledTableCell> */}
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Student Name
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  ID
                                </StyledTableCell>

                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Gender
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Joining Date
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Blood Group
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Action
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? studentFilterArr.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : studentFilterArr
                              ).map((row, index) => (
                                <StyledTableRow key={row.title}>
                                  <StyledTableCell align="left">
                                    {row.roll_number && row.roll_number}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="left">
                                    <img
                                      src={`${Service.baseUrl}/${row.student_profile?.photo?.path}`}
                                      alt=""
                                      className="img_formatting imageCursor"
                                      onClick={() => userDetailsHandler(row.id)}
                                    />
                                  </StyledTableCell> */}
                                  <StyledTableCell align="left">
                                    {getFullName(row.student_profile?.name)}
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    {row.login?.display_id}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {row.student_profile?.gender}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {dateToDateFormat(
                                      row.student_profile.joining_date
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {row.student_profile.blood_group}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    <span
                                      className="text-info mouse__pointer"
                                      onClick={() => userDetailsHandler(row.id)}
                                    >
                                      Edit
                                    </span>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={studentFilterArr.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangerowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default HOC1(ClassStudentList);
