import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { store } from "react-notifications-component";
//---------------------redux----------------------
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Loder from "../../Loder/Loder";

const InvoiceAssignment = ({ manageInstitution, navigate }) => {
  //---------------------------------global id--------------------------

  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  let institution_id = parseInt(manageInstitution.institution_id);
  //---------------------------------api calls--------------------------
  const [Assign_Invoice_To_Class, {}] = useMutation(
    Service.Assign_Invoice_To_Class
  );
  const client = useApolloClient();
  //---------------------------------local state------------------------
  const [classId, setClassId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceArr, setInvoiceArr] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [sectionArr, setSectionArr] = useState([]);
  const [isloading, setisloading] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [classArr, setClassArr] = useState([]);

  //error
  const [classIdError, setclassIdError] = useState(false);
  const [sectionidError, setsectionidError] = useState(false);
  const [invoiceidError, setinvoiceidError] = useState(false);

  useEffect(() => {
    setisloading(true);
    client
      .query({
        query: Service.Get_Manage_Grade,
        variables: {
          academic_session_id,
        },
      })
      .then((response) => {
        setClassArr(response.data.getGrade);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });

    // ---------------------to get invoice list-----------------------------------------
    client
      .query({
        query: Service.Get_Invoice_Latest,
        variables: {
          institution_id,
          academic_session_id,
        },
      })
      .then((response) => {
        setInvoiceArr(response.data.getInvoice);
      })
      .catch((e) => {
        console.log("catch error", e);
      });

    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, client, academic_session_id]);

  const classHandler = (id) => {
    if (id === -1) {
      setSectionArr([]);
      return;
    }
    setClassId(id);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setSectionArr(response.data.getSectionAndGrade);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const sectionHandler = (id) => {
    if (id === -1) {
      setSectionId("");
      return;
    }
    setSectionId(id);
  };
  const invoiceHandler = (id) => {
    setInvoiceId(id);
  };

  const AssignInvoiceHandler = async () => {
    try {
      if (!blankValidator(classId)) {
        setclassIdError(true);
        return;
      }
      if (!blankValidator(sectionId)) {
        setsectionidError(true);
        return;
      }
      if (!blankValidator(invoiceId)) {
        setinvoiceidError(true);
        return;
      }
      const arr = invoiceArr.filter((item) => {
        if (item.id == invoiceId) {
          return item;
        }
      });

      let data = await arr[0];

      setisloading(true);
      Assign_Invoice_To_Class({
        variables: {
          invoice_id: parseInt(invoiceId),
          due_date: "2021-05-07",
          amount: data.amount,
          invoice_display_id: 1,
          recurrence: data.recurrence,
          section_id: parseInt(sectionId),
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.assignInvoiceToClass.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Invoice Assignment
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-1 pb-3">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <label>Class</label>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setclassIdError(false);
                                  classHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Class</option>
                                {classArr.length !== 0 &&
                                  classArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                              {classIdError && (
                                <span className="text-danger">
                                  Select the Class
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <label>Section</label>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setsectionidError(false);
                                  sectionHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Section</option>
                                {sectionArr.length !== 0 &&
                                  sectionArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                              {sectionidError && (
                                <span className="text-danger">
                                  Select the Section
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <label>Invoice List</label>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setinvoiceidError(false);
                                  invoiceHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Invoice</option>
                                {invoiceArr.length !== 0 &&
                                  invoiceArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                              {invoiceidError && (
                                <span className="text-danger">
                                  Select the Invoice
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={11}></Grid>
                          <Grid item md={1}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration "
                              onClick={AssignInvoiceHandler}
                            >
                              Assign
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(InvoiceAssignment)));
