import React, { useState, useEffect } from "react";
import Hoc1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder.jsx";
import {
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import { useApolloClient } from "@apollo/client";
import * as Service from "../../../services/index";
import { useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import TotalCountData from "../../Admin/HomeMenu/TotalCountData";

const AdminSetup = (props) => {
  //-----------------------global data--------------------------
  const { pageType, adminData, orgenizationName } = props.location.state;

  //-----------------------api calls--------------------------
  const client = useApolloClient();
  const [Delete_admin, {}] = useMutation(Service.Delete_admin);
  //-------------------------------local state---------------------------
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);
  const [loginidError, setloginidError] = useState(false);
  const [newPasswordError, setnewPasswordError] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);
  const [createInsForm, setCreateInsForm] = useState({
    name: "",
    icon_id: "",
    icon_doc: "",
    adminName: "",
    loginid: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [Organization_Icon, setOrganization_Icon] = useState({});
  const [adminArr, setadminArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [organization_id, setorganization_id] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [Deleteadminname, setDeleteadminname] = useState("");
  const [adcmicSeesionId, setAdcmicSeesionId] = useState("");
  //-------------------------------local state---------------------------

  //-------------------------------methods---------------------------
  useEffect(() => {
    try {
      if (pageType === "ADMIN") {
        getAdminDetails(+adminData);
        setorganization_id(+adminData);
      } else {
        const { id, name, document } = adminData;

        setCreateInsForm({
          name,
          icon_doc: document?.filename,
        });

        if (document) {
          const { path, filename, type } = document;
          const Organization_Icon = {
            path: path,
            filename: filename,
            type: type,
            name: document.name,
          };
          setOrganization_Icon(Organization_Icon);
          setorganization_id(+id);
        }
        getAdminDetails(+id);
      }
    } catch (error) {
      console.log("error ", error);
    }
  }, []);
  useEffect(() => {
    getAcadmicSetionData();
  }, []);
  const getAcadmicSetionData = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Academic_session,
        variables: {
          institution_id: +adminData.id,
        },
      });
      if (data && data.getAcademicSession) {
        setAdcmicSeesionId(data.getAcademicSession.id);
      }
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  const resetPasswordDialog = (item) => {
    setCreateInsForm({
      ...createInsForm,
      loginid: item.login.login_id,
      adminName: item.name.first_name,
    });
    setloginidError();
    setOpenEditDialog(true);
  };

  const getAdminDetails = async (orgId) => {
    console.log("orgId", orgId);
    if (!blankValidator(orgId)) {
      showNotificationMsz("Please contact to super admin", "info");
    } else {
      try {
        setisloading(true);
        const { data } = await client.query({
          query: Service.GET_Admin_Details_list,
          variables: {
            organization_id: +orgId,
          },
        });

        if (data && data.getAdmin) {
          setadminArr(data.getAdmin.reverse());
        }
        setisloading(false);
      } catch (error) {
        setisloading(false);
        console.log("Error", error);
      }
    }
  };

  const DeleteDailogBox = (item) => {
    setAdminId(item.id);
    setDeleteadminname(item.name.first_name);
    setOpenDialog(true);
  };

  const resetPassword = async () => {
    try {
      let flag = false;
      if (!blankValidator(createInsForm.newPassword)) {
        setnewPasswordError(true);
        flag = true;
      }
      if (!blankValidator(createInsForm.confirmPassword)) {
        setconfirmPasswordError(true);
        flag = true;
      }

      if (createInsForm.newPassword !== createInsForm.confirmPassword) {
        setConfirmPassErr(true);
        flag = true;
      }

      if (flag) {
        return;
      }

      setisloading(true);
      const { data } = await client.query({
        query: Service.Change_User_Password,
        variables: {
          loginid: createInsForm.loginid,
          newpassword: createInsForm.newPassword,
        },
      });

      if (data && data.changePassword) {
        const { message } = data.changePassword;
        showNotificationMsz(message, "success");
        setCreateInsForm({
          ...createInsForm,
          loginid: "",
          adminName: "",
          newPassword: "",
          confirmPassword: "",
        });
        setOpenEditDialog(false);
        getAdminDetails(organization_id);
        setOpenDialog(false);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "success");
    }
  };

  const deleteAdminHandler = async (admnId) => {
    try {
      setisloading(true);
      const { data } = await Delete_admin({
        variables: { id: +admnId },
      });
      if (data && data.deleteAdmin) {
        const { message } = data.deleteAdmin;
        showNotificationMsz(message, "success");
        setOpenDialog(false);
        getAdminDetails(organization_id);
      }
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };
  const editInstitutionHandler = (item) => {
    props.navigate("/createUpdateadmin", {
      state: {
        pagetype: "edit",
        organization_id,
        name: pageType === "ADMIN" ? orgenizationName : adminData.name,
        data: adminData,
        editInstitutionData: item,
      },
    });
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday
                name={
                  pageType === "ADMIN" ? orgenizationName : createInsForm?.name
                }
              />

              <div className="enquirydetails_background mb-2">
                <Grid className="Main_Grid_For_MENUS">
                  <Grid item md={6}>
                    <div className="enquiry_heading">
                      <div className="ml-3 mt-2 Heading_bold">Admin</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    {adcmicSeesionId && (
                      <TotalCountData
                        inst_id={adminData.id}
                        acdmin_id={adcmicSeesionId}
                        type="superadmin"
                      />
                    )}
                  </Grid>
                </Grid>
                <div className="enquiry_heading">
                  <div className="ml-3 mt-4 Heading_bold"></div>
                  <div className="mr-3 mt-4 d-flex ">
                    <Button
                      variant="contained"
                      className="btn_colr_and_widthset mr-2"
                      onClick={() =>
                        props.navigate("/createUpdateadmin", {
                          state: {
                            pagetype: "add",
                            name:
                              pageType === "ADMIN"
                                ? orgenizationName
                                : createInsForm?.name,
                            organization_id,
                          },
                        })
                      }
                    >
                      Add New Admin
                    </Button>

                    {pageType === "SUPERADMIN" && (
                      <Button
                        variant="contained"
                        className=" btn_colr_and_widthset  "
                        onClick={() =>
                          props.navigate("/CreateUpdateInstitutionSuperAdmin", {
                            state: {
                              pagetype: "add",
                              organization_id: adminData.id,
                              organization_name: adminData.name,
                            },
                          })
                        }
                      >
                        Add New Institution
                      </Button>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Card className="p-2 mt-3 d-flex">
                          <Grid item md={2}>
                            <h6>Login ID</h6>
                          </Grid>
                          <Grid item md={4}>
                            <h6>Name</h6>
                          </Grid>
                          <Grid item md={3}>
                            <h6>Email Id</h6>
                          </Grid>
                          <Grid item md={3}>
                            <h6>Institution Name</h6>
                          </Grid>
                          <Grid item md={3}>
                            <h6>Actions</h6>
                          </Grid>
                        </Card>
                        {adminArr.length !== 0 &&
                          adminArr.map((item, index) => (
                            <Card className="p-2 mt-3 d-flex" key={index}>
                              <Grid item md={2}>
                                <p style={{ textTransform: "capitalize" }}>
                                  {item.login?.login_id}
                                </p>
                              </Grid>
                              <Grid item md={4}>
                                <p style={{ textTransform: "capitalize" }}>
                                  {item.name?.first_name}{" "}
                                  {item.name?.middle_name}{" "}
                                  {item.name?.last_name}
                                </p>
                              </Grid>
                              <Grid item md={3}>
                                <p>{item.email_address}</p>
                              </Grid>
                              <Grid item md={3}>
                                <p style={{ textTransform: "capitalize" }}>
                                  {item.institution?.name}
                                </p>
                              </Grid>
                              <Grid item md={3}>
                                <div className="d-flex">
                                  <p
                                    className="action__icons"
                                    onClick={() => {
                                      editInstitutionHandler(item);
                                    }}
                                  >
                                    Edit |{" "}
                                  </p>

                                  <p
                                    className="action__icons"
                                    onClick={() => {
                                      DeleteDailogBox(item);
                                    }}
                                  >
                                    {" "}
                                    Delete |
                                  </p>
                                  <p
                                    className="action__icons"
                                    onClick={() => {
                                      resetPasswordDialog(item);
                                    }}
                                  >
                                    {" "}
                                    Reset Password
                                  </p>
                                </div>
                              </Grid>
                            </Card>
                          ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpenDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            <p style={{ textTransform: "capitalize" }}>
              Are you sure want to delete the admin : {Deleteadminname}
            </p>
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteAdminHandler(adminId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="d-flex justify-content-between">
            <div className="Heading_bold">Reset Password</div>
            <div className=" mr-3 cross_font_size">
              <i
                class="fa fa-times cursor"
                onClick={() => {
                  setOpenEditDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <div className="text_filed_heading">Login Id</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Current Login Id"
                    autoComplete="off"
                    value={createInsForm.loginid}
                  />
                </div>
                <div className="text_filed_heading">New Password</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="New Password"
                    autoComplete="off"
                    value={createInsForm.newPassword}
                    onChange={(e) => {
                      setnewPasswordError(false);
                      setCreateInsForm({
                        ...createInsForm,
                        newPassword: e.target.value,
                      });
                    }}
                  />
                  {newPasswordError && (
                    <span className="text-danger">Enter the New Password</span>
                  )}
                </div>

                <div className="text_filed_heading mt-2">Confirm Password</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="New Email Address"
                    autoComplete="off"
                    value={createInsForm.confirmPassword}
                    onChange={(e) => {
                      setconfirmPasswordError(false);
                      setConfirmPassErr(false);

                      setCreateInsForm({
                        ...createInsForm,
                        confirmPassword: e.target.value,
                      });
                    }}
                  />
                  {confirmPasswordError && (
                    <span className="text-danger">
                      Enter the Confirm Password
                    </span>
                  )}
                  {confirmPassErr && (
                    <span className="text-danger">
                      New Password does not Match
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4  mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={5} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={resetPassword}
              >
                Submit
              </Button>
            </Grid>
            <Grid item md={5} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={() => {
                  setCreateInsForm({
                    ...createInsForm,
                    loginid: "",
                    adminName: "",
                    newPassword: "",
                    confirmPassword: "",
                  });
                  setOpenEditDialog(false);
                }}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(AdminSetup));
