import React, { useState, useEffect } from "react";
import "./BulkUpload.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BulkStudentUpload from "./BulkStudentUpload.jsx";
import BulkTeacherUpload from "./BulkTeacherUpload.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const BulkUpload = (props) => {
  //name instituition
  let Organization_Name = props.data.institution_name;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="ml-3 mt-3 Heading_bold">Bulk Upload</div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height ">
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2 mybarwidth">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="Teacher"
                              {...a11yProps(0)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                            <Tab
                              label="Student"
                              {...a11yProps(1)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                          </Tabs>
                        </div>
                        <div className="Pannel_padding_hidden">
                          {" "}
                          <TabPanel value={value} index={0}>
                            <div className="mt-1 ml-1 ">
                              <BulkTeacherUpload />
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <div className="mt-2 ml-1">
                              <BulkStudentUpload />
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(BulkUpload)));
