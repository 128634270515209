import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import ViewLeftPenalSubject from "./ViewLeftPenalSubject";

const ViewStudyLeftPenal = (props) => {
  const { getDataStudyList } = props;
  let key = Object.keys(getDataStudyList);
  const [isAllOpen, setIsAllOpen] = useState(true);

  return (
    <div>
      <Card className="view_study_left_height">
        <p
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: 5,
            fontSize: 16,
            fontWeight: "500",
            cursor: "pointer",
            color: "#0070ba",
          }}
          onClick={() => setIsAllOpen(!isAllOpen)}
        >
          Expand All
        </p>
        <div className="admin_Study_inner_padding">
          {key &&
            key.map((keyValue) => {
              return (
                <ViewLeftPenalSubject
                  row={getDataStudyList[keyValue]}
                  className={keyValue}
                  isAllOpen={isAllOpen}
                  {...props}
                />
              );
            })}
        </div>
      </Card>
    </div>
  );
};

export default ViewStudyLeftPenal;
