import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";
import Dialog from "@material-ui/core/Dialog";

import DateFnsUtils from "@date-io/date-fns";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";

import Loder from "../../Loder/Loder";
import {
  timeStampToDate,
  blankValidator,
  CapitalizeFirstFn,
  showNotificationMsz,
  currentDayForMinMAx,
  timeStampToDatedash,
  // timeStampDateToOnlytime,
  getcurrentDatewithoutTimezone,
  // getcurrentDate,
  timeStampDateToOnlytimewithoutTimeDiff,
} from "../../../utils/Validation";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function AdminUpdateExam(props) {
  //name instituition
  //instuttion id
  let institution_id = parseInt(props.data.institution_id);

  const [Add_Exam_Subject_and_Timetable, {}] = useMutation(
    Service.Add_Exam_Subject_and_Timetable
  );
  const [Delete_Exam_Timetable, {}] = useMutation(
    Service.Delete_Exam_Timetable
  );
  const [Update_Exam_Timetable, {}] = useMutation(
    Service.Update_Exam_Timetable
  );

  const [Update_Exam_Name, {}] = useMutation(Service.Update_Exam_Name);

  const [addMangeopen, setaddMangeopen] = useState(false);

  let pagetype = props.location.state.pagetype;

  const [startDate, setStartDate] = useState("");

  const client = useApolloClient();
  const [examSubjectArr, setexamSubjectArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [syllabusa, setsyllabusa] = useState("");
  const [location_id, setlocation_id] = useState("");
  const [locationarr, setlocationarr] = useState([]);
  const [marksa, setmarksa] = useState(0);

  const [subjectarr, setsubjectarr] = useState([]);
  const temp_start = new Date();
  const temp = new Date();
  let nextHour = temp.setHours(temp.getHours() + 1);
  const [start_time, setStart_time] = useState(temp_start);
  const [end_time, setEnd_time] = useState(nextHour);
  const [subjectid, setsubjectid] = useState("");

  const [editName, setEditName] = useState("");
  let data = props.location.state.data;

  const [sectionName, setsectionName] = useState("");
  const [gradeName, setgradeName] = useState("");

  const [name, setName] = useState("");
  const [exam_id, setexam_id] = useState(-1);
  const [section_id, setsection] = useState(-1);

  const [openExamsubjectDelete, setopenExamsubjectDelete] = useState(false);
  const [examsubjecttimetableId, setexamsubjecttimetableId] = useState("");
  const [examsubjecttimttableName, setexamsubjecttimttableName] = useState("");
  const deltesubjectTimetable = (data) => {
    setexamsubjecttimetableId(data.id);
    setexamsubjecttimttableName(data.subject.name);
    setopenExamsubjectDelete(true);
  };

  const [isUpdated, setisUpdated] = useState(false);
  const getSubjectExamTimeTable = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GetExam_Subject_And_Timetable,
          variables: {
            exam_id,
          },
        })
        .then((response) => {
          let temp = [];
          response.data.getExamSubjectandTimetable.map(async (data) => {
            data["show"] = false;
            temp.push(data);
          });

          setexamSubjectArr(temp);

          // setAssignmentarr(response.data.getAssignment);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (blankValidator(data)) {
      setName(data.name);
      setexam_id(data.id);
      setsection(data.section_id);
    }
    if (blankValidator(props.location.state.data.section)) {
      setgradeName(props.location.state.data.section.grade.gradeName);
      setsectionName(props.location.state.data.section.name);
      setsection(props.location.state.data.section.id);
    }

    if (exam_id === -1) {
      return;
    }

    const getsubjectlist = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setsubjectarr(response.data.getSubject);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        console.log("t and c error", error);
      }
    };

    const LocationData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Location,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            let temp = [];

            for (
              let index = 0;
              index < response.data.getLocation.length;
              index++
            ) {
              temp.push({
                name: response.data.getLocation[index].name,
                mode: response.data.getLocation[index].mode,
                show: true,
                id: response.data.getLocation[index].id,
              });
            }
            setisloading(false);
            setlocationarr(temp);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log(" location arr try and catch", error);
      }
    };

    LocationData();
    getSubjectExamTimeTable();
    getsubjectlist();
  }, [exam_id, client, institution_id, isUpdated]);

  const SelectLocation = (e) => {
    setlocationError(false);
    let id = parseInt(e.target.value);
    if (id === -1) {
      setlocation_id(-1);
      return;
    }
    setlocation_id(id);
  };

  const SelectEditLocation = (e) => {
    setEditlocationError(false);
    let id = parseInt(e.target.value);
    if (id === -1) {
      setExamLoactionidEdit(-1);
      return;
    }
    setExamLoactionidEdit(id);
  };

  const deleteExamsubject = () => {
    try {
      setisloading(true);
      Delete_Exam_Timetable({
        variables: {
          id: parseInt(examsubjecttimetableId),
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.deleteExamTimetable.message,
            "success"
          );

          // setAssignmentarr(response.data.getAssignment);
          setisUpdated(!isUpdated);
          setisloading(false);
          setopenExamsubjectDelete(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const [ExamEditid, setExamEditid] = useState(-1);
  const [ExamDateEdit, setExamDateEdit] = useState("");
  const [StarttimeEdit, setStarttimeEdit] = useState("");
  const [EndtimeEdit, setEndtimeEdit] = useState("");
  const [MaximumMarksEdit, setMaximumMarksEdit] = useState(0);
  const [ExamLoactionidEdit, setExamLoactionidEdit] = useState(-1);
  const [SyllabusEdit, setSyllabusEdit] = useState("");
  const [subjectIdEdit, setsubjectIdEdit] = useState(-1);

  const EditExamData = (data, index) => {
    examSubjectArr[index].show = true;
    setexamSubjectArr([...examSubjectArr]);
    setExamLoactionidEdit(data.location.id);
    setExamEditid(data.id);
    setExamDateEdit(timeStampToDatedash(data.start_calendar.datetime));

    // setStarttimeEdit(getcurrentDate(data.start_calendar.datetime));
    // setEndtimeEdit(getcurrentDate(data.end_calendar.datetime));
    setStarttimeEdit(
      getcurrentDatewithoutTimezone(data.start_calendar.datetime)
    );
    setEndtimeEdit(getcurrentDatewithoutTimezone(data.end_calendar.datetime));

    setsubjectIdEdit(data.subject.id);
    setMaximumMarksEdit(data.marks);

    setSyllabusEdit(data.syllabus);
  };

  //Edit Exam Error
  const [EditExamdateError, setEditExamdateError] = useState(false);
  const [EditMarksError, setEditMarksError] = useState(false);
  const [EditlocationError, setEditlocationError] = useState(false);
  const [EditSyllabusError, setEditSyllabusError] = useState(false);

  const updateExamTimeTable = (ID) => {
    let flag = false;
    if (!blankValidator(ExamDateEdit)) {
      setEditExamdateError(true);
      flag = true;
    }

    if (!blankValidator(MaximumMarksEdit)) {
      setEditMarksError(true);
      flag = true;
    }

    if (!blankValidator(ExamLoactionidEdit) || ExamLoactionidEdit === -1) {
      setEditlocationError(true);
      flag = true;
    }

    if (!blankValidator(SyllabusEdit)) {
      setEditSyllabusError(true);
      flag = true;
    }

    if (flag) {
      return;
    }

    let id = parseInt(ID);

    let start_Data = new Date(
      ExamDateEdit +
        " " +
        StarttimeEdit.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })
    );

    let end_Data = new Date(
      ExamDateEdit +
        " " +
        EndtimeEdit.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })
    );

    try {
      setisloading(true);

      Update_Exam_Timetable({
        variables: {
          id,
          start_datetime: start_Data.toJSON().substring(0, 23),
          end_datetime: end_Data.toJSON().substring(0, 23),
          day: ExamDateEdit,
          name,
          syllabus: SyllabusEdit,
          subject_id: parseInt(subjectIdEdit),
          location_id: parseInt(ExamLoactionidEdit),
          marks: parseInt(MaximumMarksEdit),
        },
      })
        .then((response) => {
          getSubjectExamTimeTable(
            response?.data?.updateExamTimetable?.message,
            "success"
          );
          showNotificationMsz();

          // setAssignmentarr(response.data.getAssignment);
          setisUpdated(!isUpdated);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handlestarttime = (date) => {
    setStart_time(date);
    let nexthour = new Date();
    nexthour.setHours(date.getHours() + 1);

    setEnd_time(nexthour);
  };
  const handleendtime = (date) => {
    setEnd_time(date);
  };
  const handleaddMangeopen = () => {
    setaddMangeopen(true);
  };
  const closeaddMangeopen = () => {
    setaddMangeopen(false);
  };

  const selectSubjectId = (val) => {
    setsubjectIdError(false);
    let id = parseInt(val);
    if (id === -1) {
      setsubjectid(-1);
      return;
    }
    setsubjectid(id);
  };

  //errors
  const [subjectIdError, setsubjectIdError] = useState(false);
  const [ExamdateError, setExamdateError] = useState(false);
  const [totalMarksError, settotalMarksError] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const [syllabusError, setsyllabusError] = useState(false);

  const submit = () => {
    let flag = false;
    if (!blankValidator(subjectid) || subjectid === -1) {
      setsubjectIdError(true);
      flag = true;
    }
    if (!blankValidator(startDate)) {
      setExamdateError(true);
      flag = true;
    }
    if (!blankValidator(marksa) || marksa === -1 || marksa === 0) {
      settotalMarksError(true);
      flag = true;
    }

    if (!blankValidator(location_id) || location_id === -1) {
      setlocationError(true);
      flag = true;
    }
    if (!blankValidator(syllabusa)) {
      setsyllabusError(true);
      flag = true;
    }

    if (flag) {
      return;
    }

    try {
      setisloading(true);

      let start_Data = new Date(
        startDate +
          " " +
          start_time.toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          })
      );

      let end_Data = new Date(
        startDate +
          " " +
          end_time.toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          })
      );

      Add_Exam_Subject_and_Timetable({
        variables: {
          institution_id,
          syllabus: syllabusa,
          subject_id: parseInt(subjectid),
          location_id: parseInt(location_id),
          marks: parseInt(marksa),
          day: startDate,
          start_time: start_Data.toJSON().substring(0, 23),
          end_time: end_Data.toJSON().substring(0, 23),
          exam_id,
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.addExamSubjectandTimetable.message,
            "success"
          );

          // setAssignmentarr(response.data.getAssignment);
          setisUpdated(!isUpdated);
          setisloading(false);
          closeaddMangeopen();
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const [openNameedit, setopenNameedit] = useState(false);
  const opennameedittdialofg = () => {
    setEditName(name);
    setopenNameedit(true);
  };

  const updateExamName = () => {
    try {
      setisloading(true);
      Update_Exam_Name({
        variables: {
          id: parseInt(exam_id),
          name: editName,
          section_id,
        },
      })
        .then((response) => {
          showNotificationMsz(response.data.updateExam.message, "success");

          setName(editName);
          setopenNameedit(false);
          // setAssignmentarr(response.data.getAssignment);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">{pagetype}</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Name{" "}
                                {pagetype == "Cancelled" ? (
                                  ""
                                ) : (
                                  <i
                                    class="fa fa-pencil openassignemntcolor"
                                    onClick={opennameedittdialofg}
                                  ></i>
                                )}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                autoComplete="off"
                                value={name}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Class</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Class"
                                autoComplete="off"
                                value={gradeName}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Section</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Section"
                                autoComplete="off"
                                value={sectionName}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-3">
                          {/* <Card className="pt-2 pb-2 cardvisible_border mt-2"> */}
                          <Card className="bg-light p-2 mb-5">
                            <div className="card_admissiondetails_height">
                              {pagetype == "Cancelled" ? (
                                ""
                              ) : (
                                <div className="textfiled_margin">
                                  {addMangeopen === false ? (
                                    <div
                                      className="d-flex"
                                      onClick={handleaddMangeopen}
                                    >
                                      <span className="iconstoaddfee">
                                        <i className="fa fa-plus-circle"></i>
                                      </span>
                                      <span className=" ml-2 mt-1 mb-2 addmanageuserfont">
                                        Add Subject Schedule
                                      </span>
                                    </div>
                                  ) : (
                                    <Expand open={addMangeopen}>
                                      <div className="mt-3">
                                        <hr />
                                        <Grid className="grid_main_div mt-1">
                                          <Grid item md={12}>
                                            <div
                                              className="text-right"
                                              onClick={closeaddMangeopen}
                                            >
                                              <span role="button">
                                                <i className="fa fa-times cursor"></i>
                                              </span>
                                            </div>
                                          </Grid>

                                          <Grid item md={6}>
                                            <div className="inputMarginright">
                                              <label for="exampleInputEmail1">
                                                Subject
                                              </label>
                                              <select
                                                className="form-control"
                                                id="exampleFormControlSelect1"
                                                onChange={(e) =>
                                                  selectSubjectId(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value={-1}>
                                                  Select the Subject
                                                </option>

                                                {subjectarr.map(
                                                  (item, index) => (
                                                    <option value={item.id}>
                                                      {item.name}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {subjectIdError && (
                                                <span className="text-danger">
                                                  Select the Subject
                                                </span>
                                              )}
                                            </div>
                                          </Grid>

                                          <Grid item md={6}>
                                            <div>
                                              <label for="exampleInputEmail1">
                                                Exam Date
                                              </label>
                                              <input
                                                type="date"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                autoComplete="off"
                                                min={currentDayForMinMAx()}
                                                value={startDate}
                                                onChange={(e) => {
                                                  setExamdateError(false);
                                                  setStartDate(e.target.value);
                                                }}
                                              />
                                              {ExamdateError && (
                                                <span className="text-danger">
                                                  Enter the Exam Date
                                                </span>
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid item md={6}>
                                            <div className="inputMarginright">
                                              <label for="exampleInputEmail1">
                                                Start Time
                                              </label>
                                              <div>
                                                <MuiPickersUtilsProvider
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    inputVariant="outlined"
                                                    variant="outlined"
                                                    value={start_time}
                                                    onChange={handlestarttime}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change time",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider>
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item md={6}>
                                            <div>
                                              <label for="exampleInputEmail1">
                                                End Time
                                              </label>
                                              <div>
                                                <MuiPickersUtilsProvider
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    inputVariant="outlined"
                                                    variant="outlined"
                                                    value={end_time}
                                                    min={start_time}
                                                    onChange={handleendtime}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change time",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider>
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Grid className="grid_main_div mt-1">
                                          <Grid item md={6}>
                                            <div className="inputMarginright">
                                              <label for="exampleInputEmail1">
                                                Max Marks
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Marks"
                                                autoComplete="off"
                                                value={
                                                  marksa === 0 ? "" : marksa
                                                }
                                                onChange={(e) => {
                                                  settotalMarksError(false);
                                                  setmarksa(e.target.value);
                                                }}
                                              />
                                              {totalMarksError && (
                                                <span className="text-danger">
                                                  Enter the Marks
                                                </span>
                                              )}
                                            </div>
                                          </Grid>

                                          <Grid item md={6}>
                                            <div>
                                              <label for="exampleInputEmail1">
                                                Location
                                              </label>
                                              <select
                                                className="form-control"
                                                id="exampleFormControlSelect1"
                                                onChange={(e) =>
                                                  SelectLocation(e)
                                                }
                                              >
                                                <option value={-1}>
                                                  Select the Location
                                                </option>

                                                {locationarr.map(
                                                  (item, index) => (
                                                    <option value={item.id}>
                                                      {item.name}{" "}
                                                      {item.mode === "Online"
                                                        ? " - ( Online ) "
                                                        : ""}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {locationError && (
                                                <span className="text-danger">
                                                  Select the Exam Location
                                                </span>
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Grid className="grid_main_div mt-1">
                                          <Grid item md={12}>
                                            <div class="form-group">
                                              <label for="exampleInputEmail1">
                                                Syllabus
                                              </label>
                                              <textarea
                                                class="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows="4"
                                                placeholder="Syllabus..."
                                                value={syllabusa}
                                                onChange={(e) => {
                                                  setsyllabusError(false);
                                                  setsyllabusa(e.target.value);
                                                }}
                                              ></textarea>
                                              {syllabusError && (
                                                <span className="text-danger">
                                                  Enter the Syllabus
                                                </span>
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Grid className="grid_main_div mt-1 mb-2">
                                          <Grid item md={10}></Grid>
                                          <Grid item md={2}>
                                            <Button
                                              variant="contained"
                                              className="add_new_query_btn button_login_decoration button_width"
                                              onClick={submit}
                                            >
                                              Submit
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Expand>
                                  )}
                                </div>
                              )}
                            </div>
                          </Card>
                        </div>
                        {examSubjectArr.map((item, index) => (
                          <Card className="p-3 mt-2">
                            <div>
                              <Grid className="grid_main_div">
                                {/* {stripHtml(item.bid_proposal).substring(0,50)} */}

                                <Grid item md={2}>
                                  {!blankValidator(item.subject.name) ? (
                                    ""
                                  ) : (
                                    <span className="font-weight-bold">
                                      {CapitalizeFirstFn(item.subject.name)}
                                    </span>
                                  )}
                                </Grid>
                                <Grid item md={2}>
                                  {timeStampToDate(
                                    item.start_calendar.datetime
                                  )}
                                </Grid>

                                <Grid item md={2}>
                                  {timeStampDateToOnlytimewithoutTimeDiff(
                                    item.start_calendar.datetime
                                  )}
                                  {/* {timeStampDateToOnlytime(
                                    item.start_calendar.datetime
                                  )} */}
                                </Grid>
                                <Grid item md={2}>
                                  {timeStampDateToOnlytimewithoutTimeDiff(
                                    item.end_calendar.datetime
                                  )}
                                  {/* {timeStampDateToOnlytime(
                                    item.end_calendar.datetime
                                  )} */}
                                </Grid>
                                <Grid item md={2}>
                                  {!blankValidator(item.location)
                                    ? ""
                                    : CapitalizeFirstFn(item.location.name)}
                                </Grid>
                                <Grid item md={2}>
                                  {pagetype == "Cancelled" ? (
                                    ""
                                  ) : (
                                    <div className="d-flex">
                                      <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                        {item.show === false ? (
                                          <span
                                            className="iconstoaddfee"
                                            onClick={() =>
                                              EditExamData(item, index)
                                            }
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </span>
                                        ) : (
                                          <span
                                            className="iconstoaddfee manage_edit_cursor"
                                            onClick={() => {
                                              examSubjectArr[
                                                index
                                              ].show = false;
                                              setexamSubjectArr([
                                                ...examSubjectArr,
                                              ]);
                                              // setassignmentresult(true)
                                            }}
                                          >
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        )}
                                      </span>
                                      <span className="iconstoaddfee manage_edit_cursor ml-2">
                                        <i
                                          className="fa fa-trash"
                                          onClick={() => {
                                            deltesubjectTimetable(item);
                                          }}
                                        ></i>
                                      </span>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>

                              <Expand open={item.show}>
                                <div className="p-2">
                                  <Grid className="grid_main_div mt-1">
                                    <Grid item md={6}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          Subject
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleFormControlInput1"
                                          autoComplete="off"
                                          value={item.subject.name}
                                          disabled={true}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          Exam Date
                                        </label>
                                        <input
                                          type="date"
                                          className="form-control"
                                          id="exampleFormControlInput1"
                                          autoComplete="off"
                                          value={ExamDateEdit}
                                          onChange={(e) => {
                                            setEditExamdateError(false);
                                            setExamDateEdit(e.target.value);
                                          }}
                                        />
                                        {EditExamdateError && (
                                          <span className="text-danger">
                                            Enter The Exam Date
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          Start Time
                                        </label>
                                        <div>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardTimePicker
                                              margin="normal"
                                              id="time-picker"
                                              inputVariant="outlined"
                                              variant="outlined"
                                              value={StarttimeEdit}
                                              onChange={(date) => {
                                                setStarttimeEdit(date);
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change time",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          End Time
                                        </label>
                                        <div>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardTimePicker
                                              margin="normal"
                                              id="time-picker"
                                              inputVariant="outlined"
                                              variant="outlined"
                                              value={EndtimeEdit}
                                              onChange={(date) => {
                                                setEndtimeEdit(date);
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change time",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>

                                  <Grid className="grid_main_div mt-1">
                                    <Grid item md={6}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          Max Marks
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleFormControlInput1"
                                          autoComplete="off"
                                          placeholder="Marks"
                                          value={
                                            MaximumMarksEdit === 0
                                              ? ""
                                              : MaximumMarksEdit
                                          }
                                          onChange={(e) => {
                                            setEditMarksError(false);
                                            setMaximumMarksEdit(e.target.value);
                                          }}
                                        />
                                        {EditMarksError && (
                                          <span className="text-danger">
                                            Enter The Exam Marks
                                          </span>
                                        )}
                                      </div>
                                    </Grid>

                                    <Grid item md={6}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          Location
                                        </label>
                                        <select
                                          className="form-control"
                                          id="exampleFormControlSelect1"
                                          value={ExamLoactionidEdit}
                                          onChange={(e) =>
                                            SelectEditLocation(e)
                                          }
                                        >
                                          <option value={-1}>
                                            Select the Location
                                          </option>

                                          {locationarr.map((item, index) => (
                                            <option value={item.id}>
                                              {item.name}{" "}
                                              {item.mode === "Online"
                                                ? " - ( Online ) "
                                                : ""}
                                            </option>
                                          ))}
                                        </select>
                                        {EditlocationError && (
                                          <span className="text-danger">
                                            Enter The Exam Location
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid className="grid_main_div mt-1">
                                    <Grid item md={12}>
                                      <div class="form-group">
                                        <label for="exampleInputEmail1">
                                          Syllabus
                                        </label>
                                        <textarea
                                          class="form-control"
                                          id="exampleFormControlTextarea1"
                                          rows="4"
                                          placeholder="Syllabus..."
                                          value={SyllabusEdit}
                                          onChange={(e) => {
                                            setEditSyllabusError(false);
                                            setSyllabusEdit(e.target.value);
                                          }}
                                        ></textarea>
                                        {EditSyllabusError && (
                                          <span className="text-danger">
                                            Enter The Exam Syllabus
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid className="grid_main_div mt-1 mb-2">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <Button
                                        variant="contained"
                                        className="add_new_query_btn button_login_decoration button_width"
                                        onClick={(e) => {
                                          updateExamTimeTable(ExamEditid);
                                        }}
                                      >
                                        Update
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Expand>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={openExamsubjectDelete}
        onClose={() => {
          setopenExamsubjectDelete(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenExamsubjectDelete(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Subject : {examsubjecttimttableName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteExamsubject(examsubjecttimetableId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenExamsubjectDelete(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openNameedit}
        onClose={() => {
          setopenNameedit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenNameedit(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Update Exam Name</div>

          <div className="p-4">
            <label className="float-left">Name</label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Exam Name"
              autoComplete="off"
              value={editName}
              onChange={(e) => {
                setEditName(e.target.value);
              }}
            />
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => updateExamName()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenNameedit(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(AdminUpdateExam)));
