import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  timeStampToDate,
  getFullName,
  getcurrentDate,
} from "../../../utils/Validation";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const ParentNotice = (props) => {
  const { studentData } = props.data;
  //Institute name
  let institution_Name = props.data.institution_name;
  let father_id = parseInt(props.data.userid);
  let institution_id = parseInt(props.data.institution_id);
  const [value, setValue] = React.useState(0);
  const [isloading, setisloading] = useState(false);
  const [ParentstudentData, setParentStudentData] = useState([]);
  const [studentId, setStudentId] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const classes = useStyles();
  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [search, setSearch] = useState("");
  const client = useApolloClient();
  const [classnoticedata, setClassnoticedata] = useState([]);
  //   let section_id = parseInt(props.user_info.section.id);
  const [search1, setSearch1] = useState("");
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);
  const [page1, setPage1] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // useEffect(() => {
  //   const getPraentStudentData = () => {
  //     try {
  //       setisloading(true);
  //       client
  //         .query({
  //           query: Service.Get_Parent_student_data,
  //           variables: {
  //             institution_id,
  //             father_id,
  //             status: "ACTIVE",
  //           },
  //         })
  //         .then((response) => {
  //           setisloading(false);
  //           if (response.data.getStudentByParent.length > 0) {
  //             setParentStudentData(response.data.getStudentByParent);
  //           }
  //         })
  //         .catch((err) => {
  //           setisloading(false);
  //           console.log("exam catch err", err);
  //         });
  //     } catch (error) {
  //       setisloading(false);
  //       console.log("exam t and c error", error);
  //     }
  //   };

  //   getPraentStudentData();
  // }, [client, institution_id, father_id]);
  useEffect(() => {
    getStudentExamData();
  }, []);

  const getStudentExamData = () => {
    let tempid = parseInt(studentData?.section?.id);
    setStudentId(tempid);
    if (tempid == -1) {
      return;
    }
    getSectionNoticeData(tempid);
    getStudentNoticeBoardData();
  };

  const getSectionNoticeData = (id) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Class_Notice_data,
          variables: {
            institution_id,
            section_id: id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];

          for (
            let index = 0;
            index < response.data.getNoticeBoardBySectionId.length;
            index++
          ) {
            const element = response.data.getNoticeBoardBySectionId[index];
            // let date1 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(new Date());

            // let date2 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.expiry_date);

            // let Publish_Date = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.publish_date);
            let date1 = new Date();
            let date2 = getcurrentDate(element.expiry_date);
            let Publish_Date = getcurrentDate(element.publish_date);

            if (date1 >= Publish_Date && date1 <= date2) {
              temp.push({
                id: element.id,
                title: element.title,
                subject: element.subject,
                publish_date: element.publish_date,
                expiry_date: element.expiry_date,
                employee: element.employee,
                status: date1 > date2 ? "EXPIRED" : element.status,
                content: element.content,
                documents: element.documents,
                section: element.section,
              });
            }
          }

          setClassnoticedata(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("parent homepage t and c error", error);
    }
  };
  const getStudentNoticeBoardData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_School_Notice_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];

          for (
            let index = 0;
            index < response.data.getNoticeBoardByInstitutionId.length;
            index++
          ) {
            const element = response.data.getNoticeBoardByInstitutionId[index];

            // let date1 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(new Date());

            // let date2 = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.expiry_date);
            // let Publish_Date = new Intl.DateTimeFormat("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // }).format(element.publish_date);
            let date1 = new Date();
            let date2 = getcurrentDate(element.expiry_date);
            let Publish_Date = getcurrentDate(element.publish_date);

            if (date1 >= Publish_Date && date1 <= date2) {
              temp.push({
                id: element.id,
                title: element.title,
                subject: element.subject,
                publish_date: element.publish_date,
                expiry_date: element.expiry_date,
                admin: element.admin,
                status: date1 > date2 ? "EXPIRED" : element.status,
                content: element.content,
                documents: element.documents,
              });
            }
          }
          setSchoolnoticedata(temp);
        })
        .catch((err) => {
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      console.log("parent homepage t and c error", error);
    }
  };

  const filterSchoolNotice = schoolnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filterClassNotice = classnoticedata.filter((event) => {
    return event.title.toLowerCase().indexOf(search1.toLowerCase()) !== -1;
  });

  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_Name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading ">
                <div className="mt-3 ml-4 Heading_bold leave_content_main">
                  Notice
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin mb-2">
                      <div className="mt-1 mb-1">
                        <div className=" d-flex mt-2">
                          <div className="text_filed_heading">
                            Student Name :{" "}
                          </div>
                          <div>{getFullName(studentData?.name)}</div>
                        </div>
                        <div className=" d-flex mt-2">
                          <div className="text_filed_heading">Class :</div>
                          <div>
                            {studentData?.section?.grade?.gradeName}
                            {"-"}
                            {studentData?.section?.name}
                          </div>
                        </div>

                        {/* <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          onChange={(e) => {
                            getStudentExamData(e.target.value);
                          }}
                        >
                          <option value={-1}>Student List</option>
                          {ParentstudentData.map((item, index) => (
                            <option value={item.student.section.id} key={index}>
                              {getFullName(item.name)}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>
                    {studentId != -1 && (
                      <div>
                        <div className="mt-2 ml-2 mybarwidth">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="School Notice"
                              {...a11yProps(0)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                            <Tab
                              label="Class Notice"
                              {...a11yProps(1)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                          </Tabs>
                        </div>
                        {/* -----school notice section ----- */}
                        <TabPanel value={value} index={0}>
                          <div className="table_foramtitng mt-3">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Title
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Subject
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Publish Date
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Expiry Date
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Publisher Name
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Status
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Action
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? filterSchoolNotice.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterSchoolNotice
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.title) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {row.title.substring(0, 20)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell scope="row" align="left">
                                        {!blankValidator(row.subject) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {row.subject.substring(0, 20)}
                                          </span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {!blankValidator(row.publish_date) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {timeStampToDate(row.publish_date)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.expiry_date) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {timeStampToDate(row.expiry_date)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.admin) ? (
                                          "No Data"
                                        ) : (
                                          <span>
                                            {!blankValidator(row.admin.name) ? (
                                              "No Data"
                                            ) : (
                                              <span>
                                                {!blankValidator(
                                                  row.admin.name.first_name
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {row.admin.name.first_name}
                                                  </span>
                                                )}{" "}
                                                {!blankValidator(
                                                  row.admin.name.middle_name
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {row.admin.name.middle_name}
                                                  </span>
                                                )}{" "}
                                                {!blankValidator(
                                                  row.admin.name.last_name
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <span>
                                                    {row.admin.name.last_name}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.status) ? (
                                          "No Data"
                                        ) : (
                                          <span>{row.status}</span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="row_details_color"
                                        onClick={() =>
                                          props.navigate("/noticeDetail", {
                                            state: {
                                              item: row,
                                              type: "SCHOOLNOTICE",
                                              institution_Name:
                                                institution_Name,
                                            },
                                          })
                                        }
                                      >
                                        View
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterSchoolNotice.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableContainer>
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Grid className="grid_main_div gapfromanotherinput mb-3">
                            <Grid item md={6}>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Search"
                                  autoComplete="off"
                                  value={search1}
                                  onChange={(e) => {
                                    setSearch1(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>

                            {/* <Grid item md={1}>
                              <div className="button_text_formating mt-1">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn button_width button_login_decoration "
                                >
                                  {" "}
                                  Search{" "}
                                </Button>
                              </div>
                            </Grid> */}
                            <Grid item md={5}></Grid>
                          </Grid>
                          <div className="">
                            <div className="table_foramtitng mt-3">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Title
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Subject
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publish Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Expiry Date
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Publisher Name
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Status
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage1 > 0
                                      ? filterClassNotice.slice(
                                          page1 * rowsPerPage1,
                                          page1 * rowsPerPage1 + rowsPerPage1
                                        )
                                      : filterClassNotice
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.title) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {row.title.substring(0, 20)}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {!blankValidator(row.subject) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {row.subject.substring(0, 20)}
                                            </span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {!blankValidator(row.publish_date) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {timeStampToDate(
                                                row.publish_date
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.expiry_date) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {timeStampToDate(row.expiry_date)}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.employee) ? (
                                            "No Data"
                                          ) : (
                                            <span>
                                              {!blankValidator(
                                                row.employee.name
                                              ) ? (
                                                "No Data"
                                              ) : (
                                                <span>
                                                  {!blankValidator(
                                                    row.employee.name.first_name
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <span>
                                                      {
                                                        row.employee.name
                                                          .first_name
                                                      }
                                                    </span>
                                                  )}{" "}
                                                  {!blankValidator(
                                                    row.employee.name
                                                      .middle_name
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <span>
                                                      {
                                                        row.employee.name
                                                          .middle_name
                                                      }
                                                    </span>
                                                  )}{" "}
                                                  {!blankValidator(
                                                    row.employee.name.last_name
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <span>
                                                      {
                                                        row.employee.name
                                                          .last_name
                                                      }
                                                    </span>
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.status) ? (
                                            "No Data"
                                          ) : (
                                            <span>{row.status}</span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="row_details_color"
                                          onClick={() =>
                                            props.navigate("/noticeDetail", {
                                              state: {
                                                item: row,
                                                type: "CLASSNOTICE",
                                                institution_Name:
                                                  institution_Name,
                                              },
                                            })
                                          }
                                        >
                                          View
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={filterClassNotice.length}
                                  rowsPerPage={rowsPerPage1}
                                  page={page1}
                                  onChangePage={handleChangePage1}
                                  onChangeRowsPerPage={handleChangeRowsPerPage1}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ParentNotice));
