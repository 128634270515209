import React, { useState, useEffect } from "react";
import "./Events.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import RichTextEditor from "react-rte";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import { baseUrl } from "../../../services/index.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  currentDayForMinMAx,
  createDateTime,
  getMinAndSecfromDate,
} from "../../../utils/Validation";
import { useMutation, useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

const CreateEvents = (props) => {
  const client = useApolloClient();

  const [eStartDate, setEStartDate] = useState("");
  const [eEndDate, setEEndDate] = useState("");
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [filename, setFileName] = useState("Add Attachment");
  const [uploadFileArr, setUploadFileArr] = useState([]);
  const [locationArr, setlocationArr] = useState([]);
  const [locationname, setlocationname] = useState([]);

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  const [eventName, setEventName] = useState("");

  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [charleft, setcharleft] = useState("250");
  const [endDate, setEndDate] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [sheduleSection, setSheduleSection] = useState(false);
  const [weekDay, setWeekDay] = useState("");
  const [recurrenceValue, setRecurrenceValue] = useState("");
  const [addRecurrenceArray, setAddRecurrenceArray] = useState([]);
  const temp = new Date();
  const [start_time, setStart_time] = useState(temp);
  const [end_time, setEnd_time] = useState(temp);
  const [editSheduleOpen, setEditSheduleOpen] = useState(false);
  const [updateIndex, setUpdateIndex] = useState("");

  //error
  const [eventNameErr, setEventNameErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [startDateErr, setStartDateErr] = useState(false);
  const [endDateErr, setEndDateErr] = useState(false);
  const [publishDateErr, setPublishDateErr] = useState(false);
  const [locationErr, setLocationErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);
  const [attachmentErr, setAttachmentErr] = useState(false);
  const [weekDayError, setWeekDayError] = useState(false);
  const [recurrenceValueError, setRecurrenceValueError] = useState(false);

  const [Create_Event_Teacher, {}] = useMutation(Service.Create_Event_Teacher);
  let academic_session_id = parseInt(props.location.state.academic_session_id);
  let institution_id = parseInt(props.data.institution_id);

  const [daysData] = useState([
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ]);
  const [RecurrenceData] = useState([
    {
      id: "NONE",
      name: "None",
    },
    {
      id: "Daily",
      name: "Daily",
    },
    {
      id: "Weekly",
      name: "Weekly",
    },
    {
      id: "byWeekly",
      name: "Bi-Weekly",
    },
    {
      id: "triWeekly",
      name: "3 weeks",
    },
    {
      id: "Month",
      name: "Monthly",
    },
  ]);

  let user_id = parseInt(props.data.userid);
  useEffect(() => {
    getlocation();
  }, [props]);

  const ImageUploadHandler = async (e) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setIsLoading(true);

      const { data } = await axios.post(url, fileData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data) {
        const { path, filename, type, name } = data;
        const obj = {
          path,
          filename,
          type,
          name,
        };
        setFileName(file.name);
        setAttachmentErr(false);
        setUploadFileArr([...uploadFileArr, obj]);
      }
      setIsLoading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setIsLoading(false);
    }
  };

  const getlocation = async () => {
    try {
      const { data } = await client.query({
        query: Service.Get_Manage_Location,
        variables: {
          institution_id,
        },
      });

      if (data && data.getLocation) {
        setlocationArr(data.getLocation);
        setlocationname("");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const createEventHandler = async () => {
    let flag = false;
    let scroll = false;
    if (!blankValidator(eventName)) {
      setEventNameErr(true);
      flag = true;
      if (!scroll) {
        document.getElementById("name").focus();
      }
      scroll = true;
    }
    if (!blankValidator(description)) {
      setDescriptionErr(true);
      flag = true;
      if (!scroll) {
        document.getElementById("description").focus();
      }
      scroll = true;
    }
    // if (new Date(eStartDate).valueOf() - new Date().valueOf() < 30000) {
    if (!eStartDate) {
      setStartDateErr(true);
      flag = true;
      if (!scroll) {
        document.getElementById("st_date").focus();
      }
      scroll = true;
    }
    // if (new Date(eEndDate).valueOf() - new Date().valueOf() < 30000) {
    if (!eEndDate) {
      setEndDateErr(true);
      flag = true;
      flag = true;
      if (!scroll) {
        document.getElementById("end_date").focus();
      }
      scroll = true;
    }

    if (!blankValidator(publishDate)) {
      setPublishDateErr(true);
      flag = true;
      if (!scroll) {
        document.getElementById("pub_date").focus();
      }
      scroll = true;
    }

    if (!blankValidator(location) || location === -1) {
      setLocationErr(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("location").focus();
      }
      scroll = true;
    }
    if (value.toString("html") === "<p><br></p>") {
      setMessageErr(true);
      flag = true;
    }

    if (flag) {
      console.log("ccccc", flag);
      return;
    }
    try {
      setIsLoading(true);

      await Create_Event_Teacher({
        variables: {
          academic_session_id: academic_session_id,
          institution_id: institution_id,
          event_name: eventName,
          start_date: eStartDate,
          end_date: eEndDate,
          organizer_id: user_id,
          publish_date: publishDate,
          description: description,
          location,
          expiry_date: createDateTime(new Date(eEndDate)),
          user_type: props.data.usertype,
          content: value.toString("html"),
          event_doc: uploadFileArr,
          recursion: addRecurrenceArray,
        },
      }).then(
        (response) => {
          setIsLoading(false);
          showNotificationMsz(response.data.addEvent.message, "success");
          if (props.data.usertype === "TEACHER") {
            props.navigate("/ManageEventForTeacher", {
              state: {
                academic_session_id: props.SESSION_DATA.id,
              },
            });
          } else {
            props.navigate(-1);
          }

          //props.navigate("/manageEvent", { academic_session_id });
        },
        (err) => {
          setIsLoading(false);
          showNotificationMsz("Not Created", "danger");
        }
      );
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showNotificationMsz("Not Created", "danger");
    }
  };
  const handlestarttime = (date) => {
    setStart_time(date);
    let nexthour = new Date();
    nexthour.setHours(date.getHours() + 1);
    setEnd_time(nexthour);
  };
  const handleendtime = (date) => {
    setEnd_time(date);
  };
  const createScheduleHandler = () => {
    if (!weekDay) {
      setWeekDayError(true);
    }
    if (!recurrenceValue) {
      setRecurrenceValueError(true);
    }
    if (!weekDay || !recurrenceValue) {
      return;
    }
    for (let index = 0; index < addRecurrenceArray.length; index++) {
      const element = addRecurrenceArray[index];
      if (element.weekDay === weekDay) {
        showNotificationMsz("Already Add ", "success");
        return;
      }
    }
    addRecurrenceArray.push({
      weekDay: +weekDay,
      start_time: getMinAndSecfromDate(start_time),
      end_time: getMinAndSecfromDate(end_time),
      recurrence: recurrenceValue,
    });
    setAddRecurrenceArray([...addRecurrenceArray]);
    setWeekDay("");
    setRecurrenceValue("");
  };
  const editScheduleDailogHandle = (data, index) => {
    setEditSheduleOpen(true);
    setWeekDay(data.weekDay);
    setStart_time(new Date("2022-2-2 " + data.start_time));
    setEnd_time(new Date("2022-2-2 " + data.end_time));
    setRecurrenceValue(data.recurrence);
    setUpdateIndex(index);
  };
  const updateScheduleHandler = (index) => {
    addRecurrenceArray[index] = {
      weekDay: weekDay,
      start_time: getMinAndSecfromDate(start_time),
      end_time: getMinAndSecfromDate(end_time),
      recurrence: recurrenceValue,
    };
    setAddRecurrenceArray([...addRecurrenceArray]);
    setWeekDay("");
    setRecurrenceValue("");
    setEditSheduleOpen(false);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Create Events</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">Name</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  maxLength="50"
                                  placeholder="Name"
                                  autoComplete="off"
                                  value={eventName}
                                  onChange={(e) => {
                                    setEventNameErr(false);
                                    setEventName(e.target.value);
                                  }}
                                />
                                {eventNameErr && (
                                  <span className="text-danger">
                                    Enter Event Name
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Description
                              </label>
                              <div>
                                <textarea
                                  class="form-control"
                                  rows="3"
                                  placeholder="Description"
                                  autoComplete="off"
                                  id="description"
                                  maxlength="250"
                                  value={description}
                                  onChange={(e) => {
                                    let leftcharater =
                                      250 - e.target.value.length;
                                    setcharleft(leftcharater);
                                    setDescriptionErr(false);
                                    setDescription(e.target.value);
                                  }}
                                />
                                {descriptionErr && (
                                  <span className="text-danger">
                                    Enter Description of Event
                                  </span>
                                )}
                              </div>
                              <span>Character limit:{charleft}</span>
                            </div>
                          </Grid>
                        </Grid>
                        {/* ==========date section=========== */}
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Start Date</label>
                              <div>
                                <input
                                  class="form-control"
                                  id="st_date"
                                  type="date"
                                  value={eStartDate}
                                  onChange={(e) => {
                                    setStartDateErr(false);
                                    setEStartDate(e.target.value);
                                  }}
                                />
                                {/* <DateTimePicker
                                  style={{ width: "100%", height: 20 }}
                                  allowKeyboardControl={true}
                                  disablePast={true}
                                  label="Start Date & Time"
                                  inputVariant="outlined"
                                  value={eStartDate}
                                  onChange={(date) => {
                                    setStartDateErr(false);
                                    setEStartDate(date);
                                  }}
                                /> */}
                              </div>
                            </div>
                            {startDateErr && (
                              <p className="text-danger mt-4">
                                Enter Start Date
                              </p>
                            )}
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">End Date</label>
                              <div>
                                <input
                                  class="form-control"
                                  type="date"
                                  id="end_date"
                                  value={eEndDate}
                                  onChange={(e) => {
                                    setEndDateErr(false);
                                    setEEndDate(e.target.value);
                                  }}
                                />
                                {/* <DateTimePicker
                                  style={{ width: "100%", height: 20 }}
                                  allowKeyboardControl={true}
                                  disablePast={true}
                                  label="End Date & Time"
                                  inputVariant="outlined"
                                  value={eEndDate}
                                  onChange={(date) => {
                                    setEndDateErr(false);
                                    setEEndDate(date);
                                  }}
                                /> */}
                              </div>
                            </div>
                            {endDateErr && (
                              <p className="text-danger mt-4">Enter End Date</p>
                            )}
                          </Grid>
                        </Grid>
                        {/* ==========Shedule section=========== */}
                        <div className="pt-2">
                          <label for="exampleInputEmail1">Schedule</label>
                        </div>
                        <Card className="sheduleCardPAdding">
                          {sheduleSection === false ? (
                            <div className="inputMarginright">
                              <div className="text-left ml-3">
                                <span className="iconstoaddfee">
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                                <span
                                  className=" addmanageuserfont mt-1 ml-1"
                                  onClick={() => {
                                    setSheduleSection(true);
                                  }}
                                >
                                  Add a Schedule
                                </span>
                              </div>
                            </div>
                          ) : (
                            <Expand open={sheduleSection}>
                              <div>
                                <div
                                  className="text-right"
                                  onClick={() => {
                                    setSheduleSection(false);
                                  }}
                                >
                                  <span className="iconstoaddfee">
                                    <i class="fa fa-times cursor"></i>
                                  </span>
                                </div>
                                <Grid className="grid_main_div mt-2">
                                  <Grid item md={6}>
                                    <div className="inputMarginright">
                                      <label for="exampleInputEmail1">
                                        Select Week Day
                                      </label>
                                      <div>
                                        <select
                                          value={weekDay}
                                          class="form-control"
                                          onChange={(e) => {
                                            setWeekDay(e.target.value);
                                            setWeekDayError(false);
                                          }}
                                        >
                                          <option value={-1}>
                                            Select a day
                                          </option>
                                          {daysData.map((item, index) => (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                        {weekDayError && (
                                          <p className="text-danger">
                                            Select a week day
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item md={6}>
                                    <div className="inputMarginright">
                                      <label for="exampleInputEmail1">
                                        Start Time
                                      </label>
                                      <div>
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            inputVariant="outlined"
                                            variant="outlined"
                                            value={
                                              start_time == temp
                                                ? "00:00"
                                                : start_time
                                            }
                                            onChange={handlestarttime}
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                    {/* {startDateErr && (
                                      <p className="text-danger mt-4">
                                        Enter Start Date
                                      </p>
                                    )} */}
                                  </Grid>
                                </Grid>
                                <Grid className="grid_main_div mt-2">
                                  <Grid item md={6}>
                                    <div className="inputMarginright">
                                      <label for="exampleInputEmail1">
                                        End Time
                                      </label>
                                      <div>
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            inputVariant="outlined"
                                            variant="outlined"
                                            value={
                                              end_time == temp
                                                ? "00:00"
                                                : end_time
                                            }
                                            min={start_time}
                                            onChange={handleendtime}
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item md={6}>
                                    <div className="inputMarginright">
                                      <label for="exampleInputEmail1">
                                        Recurrence
                                      </label>
                                      <div>
                                        <select
                                          value={recurrenceValue}
                                          class="form-control"
                                          onChange={(e) => {
                                            setRecurrenceValue(e.target.value);
                                            setRecurrenceValueError(false);
                                          }}
                                        >
                                          <option value={-1}>
                                            Select a Recurrence
                                          </option>
                                          {RecurrenceData.map((item, index) => (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                        {recurrenceValueError && (
                                          <p className="text-danger">
                                            Select a Recurrence
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className=" mt-2 mb-2 text-right">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn  button_login_decoration"
                                    onClick={createScheduleHandler}
                                  >
                                    Add
                                  </Button>
                                </div>
                                {/* -------add data---- */}
                                <Grid className="grid_main_div pt-1">
                                  <Grid item md={3}>
                                    <p className="tableHeadingBold">Week Day</p>
                                  </Grid>
                                  <Grid item md={2}>
                                    <p className="tableHeadingBold">
                                      Start Time
                                    </p>
                                  </Grid>
                                  <Grid item md={2}>
                                    <p className="tableHeadingBold">End Time</p>
                                  </Grid>
                                  <Grid item md={3}>
                                    <p className="tableHeadingBold">
                                      Recurrence
                                    </p>
                                  </Grid>
                                  <Grid item md={2}>
                                    <p className="tableHeadingBold">Action</p>
                                  </Grid>
                                </Grid>
                                <div>
                                  {addRecurrenceArray.map((row, index) => (
                                    <Card
                                      className="cardvisible_border p-2 mt-2"
                                      key={index}
                                    >
                                      <Grid className="grid_main_div pt-1">
                                        <Grid item md={3}>
                                          <p>{daysData[row.weekDay].name}</p>
                                        </Grid>
                                        <Grid item md={2}>
                                          <p>{row.start_time}</p>
                                        </Grid>
                                        <Grid item md={2}>
                                          <p>{row.end_time}</p>
                                        </Grid>
                                        <Grid item md={3}>
                                          <p>{row.recurrence}</p>
                                        </Grid>
                                        <Grid item md={2}>
                                          <div className="d-flex">
                                            <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                              <span
                                                className="iconstoaddfee"
                                                onClick={() =>
                                                  editScheduleDailogHandle(
                                                    row,
                                                    index
                                                  )
                                                }
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </span>
                                            </span>

                                            <span
                                              className="iconstoaddfee manage_edit_cursor ml-2"
                                              onClick={() => {
                                                addRecurrenceArray.splice(
                                                  index,
                                                  1
                                                );
                                                setAddRecurrenceArray([
                                                  ...addRecurrenceArray,
                                                ]);
                                              }}
                                            >
                                              <i className="fa fa-trash"></i>
                                            </span>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  ))}
                                </div>
                              </div>
                            </Expand>
                          )}
                        </Card>

                        <Grid className="grid_main_div mt-4">
                          <Grid item md={6}>
                            <div className="inputMarginright pt-2">
                              <label for="exampleInputEmail1">
                                Publish Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="pub_date"
                                  autoComplete="off"
                                  min={currentDayForMinMAx()}
                                  value={publishDate}
                                  onChange={(e) => {
                                    setPublishDateErr(false);
                                    setPublishDate(e.target.value);
                                  }}
                                />
                                {publishDateErr && (
                                  <span className="text-danger">
                                    Enter published Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid className="grid_main_div mt-4">
                            <Grid item md={6}>
                              <div className="">
                                <label for="exampleInputEmail1">
                                  Select Location
                                </label>
                                <div>
                                  <select
                                    id="location"
                                    class="form-control"
                                    onChange={(e) => {
                                      setLocationErr(false);
                                      setLocation(parseInt(e.target.value));
                                    }}
                                  >
                                    <option value={-1}>Select</option>
                                    {locationArr.length > 0 &&
                                      locationArr.map((item, index) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                  {locationErr && (
                                    <span className="text-danger">
                                      Enter location of event
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div class="form-group">
                              <label for="exampleFormControlTextarea1">
                                Content
                              </label>
                              <RichTextEditor
                                value={value}
                                id="message"
                                onChange={(e) => {
                                  setMessageErr(false);
                                  setValue(e);
                                }}
                                className="editor_height"
                              />
                              {messageErr && (
                                <span className="text-danger">
                                  Enter Message of Event
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="">
                              <label for="exampleInputEmail1">Attachment</label>
                              <div className=" mb-2 d-flex">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                      <i class="fa fa-paperclip"></i>
                                    </span>
                                  </div>
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      id="attachment"
                                      class="custom-file-input"
                                      onChange={ImageUploadHandler}
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="inputGroupFile01"
                                    >
                                      {filename}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {attachmentErr && (
                                <span className="text-danger">
                                  Upload Attachment
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <label className="mt-2">Attachment Lists</label>
                        <div className=" mb-2 attachList">
                          {uploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2"
                              >
                                {item.name}
                              </span>
                              {/* <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() => {
                                    uploadFileArr.splice(index, 1);
                                    setUploadFileArr([...uploadFileArr]);
                                  }}
                                ></i>
                              </span> */}
                            </div>
                          ))}
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              {/* <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                              >
                                Preview
                              </Button> */}
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={createEventHandler}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
      {/* update shcedule dailog */}
      <Dialog
        open={editSheduleOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setEditSheduleOpen(false);
        }}
        className="dailog_formatting"
      >
        <div className="">
          <div className="text-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setEditSheduleOpen(false);
              }}
            ></i>
          </div>
          <div className="p-3">
            <Grid className="grid_main_div mt-2">
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label for="exampleInputEmail1">Select Week Day</label>
                  <div>
                    <select
                      value={weekDay}
                      class="form-control"
                      onChange={(e) => {
                        setWeekDay(e.target.value);
                      }}
                    >
                      <option value={-1}>Select a day</option>
                      {daysData.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label for="exampleInputEmail1">Start Time</label>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        inputVariant="outlined"
                        variant="outlined"
                        value={start_time == temp ? "00:00" : start_time}
                        onChange={handlestarttime}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                {startDateErr && (
                  <p className="text-danger mt-4">Enter Start Date</p>
                )}
              </Grid>
            </Grid>
            <Grid className="grid_main_div mt-2">
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label for="exampleInputEmail1">End Time</label>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        inputVariant="outlined"
                        variant="outlined"
                        value={end_time == temp ? "00:00" : end_time}
                        min={start_time}
                        onChange={handleendtime}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label for="exampleInputEmail1">Recurrence</label>
                  <div>
                    <select
                      value={recurrenceValue}
                      class="form-control"
                      onChange={(e) => {
                        setRecurrenceValue(e.target.value);
                      }}
                    >
                      <option value={-1}>Select a Recurrence</option>
                      {RecurrenceData.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className=" mt-2 mb-2 text-right">
              <Button
                variant="contained"
                className="add_new_query_btn  button_login_decoration"
                onClick={() => {
                  updateScheduleHandler(updateIndex);
                }}
              >
                update
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  SESSION_DATA: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(CreateEvents));
