import React, { Component } from "react";
import "./Header.css";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import logo from "./Skool_First_Logo.png";
class ForgotpasswordHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  openLogin = () => {
   this.props.navigate("/signIn");
  };
  render() {
    return (
      <div>
        <AppBar position="fixed" className="appbarcolor">
          <div className="signupheader">
            <Toolbar>
              <Grid container spacing={24}>
                <Grid item xs={10} md={10}>
                  <div className="logoformatting">
                    <img src={logo} alt="" className="image_hader" />
                  </div>
                </Grid>

                <Grid item xs={2} md={2}>
                  <div className="headerbutton_padding">
                    <Button
                      variant="contained"
                      className="buttonformatting button_login_decoration"
                      onClick={this.openLogin}
                    >
                      Login
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </div>
        </AppBar>
      </div>
    );
  }
}

export default ForgotpasswordHeader;
