import { useApolloClient } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../action/appAction";
import { getData } from "../../api/index";
//redux
import * as Service from "../../services/index";
import {
  blankValidator,
  CapitalizeFirstFn,
  getcurrentDate,
  getValue,
  timeStampToDate,
} from "../../utils/Validation";
import UpcomingEventsComponent from "../Common/Events/UpcomingEventsComponent";
import Holiday from "../Common/Holiday/Holiday.jsx";
import HOC1 from "../Hoc1.jsx";
import Loder from "../Loder/Loder.jsx";
import OnlineClass from "./onlineClass.jsx";
import "./StudentHomePage.css";
import OnlineEventLink from "../Teacher/OnlineEventLink";

const StudentHomePage = (props) => {
  console.log("props", props);
  let SectionData = props?.location?.state?.SectionData;
  let institution_id = parseInt(props.data.institution_id);
  let institution_Name = props.data.institution_name;

  let { first_name, middle_name, last_name } = props.data;

  let userName = `${first_name + " " + middle_name + " " + last_name}`;

  const [sectionid, setSectionid] = useState(-1);
  let userId = parseInt(props.data.userid);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const client = useApolloClient();
  const classes = useStyles();

  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(3);
  const [page3, setPage3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(3);

  const [gradeName, setgradeName] = useState("");
  const [isloading, setisloading] = useState(false);

  const [completedArray, setCompletedArray] = useState([]);
  const [inProgressArray, setInProgressArray] = useState([]);
  const [SubmittedArray, setSubmittedArray] = useState([]);
  const [innewArray, setInNewArray] = useState([]);

  const [Student_Name, setStudent_Name] = useState("");

  const [classnoticedata, setClassnoticedata] = useState([]);

  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  //assignment array
  const [rowsPerPageC, setRowsPerPageC] = React.useState(5);

  const [pageC, setPageC] = React.useState(0);

  const [searchC, setsearchC] = useState("");

  const filterCompleted = completedArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(searchC.toLowerCase()) !== -1
    );
  });

  const handleChangePageC = (event, newPage) => {
    setPageC(newPage);
  };

  const handleChangeRowsPerPageC = (event) => {
    setRowsPerPageC(parseInt(event.target.value, 10));
    setPageC(0);
  };

  //in progress array
  const [rowsPerPageP, setRowsPerPageP] = React.useState(5);

  const [pageP, setPageP] = React.useState(0);

  const [searchP, setsearchP] = useState("");

  const filterInProgess = inProgressArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(searchP.toLowerCase()) !== -1
    );
  });

  const handleChangePageP = (event, newPage) => {
    setPageP(newPage);
  };
  const handleChangerowsPerPageP = (event) => {
    setRowsPerPageP(parseInt(event.target.value, 10));
    setPageP(0);
  };

  //in new array
  const [rowsPerPageN, setRowsPerPageN] = React.useState(5);

  const [pageN, setPageN] = React.useState(0);

  const [searchN, setsearchN] = useState("");

  const filterNew = innewArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(searchN.toLowerCase()) !== -1
    );
  });

  const handleChangePageN = (event, newPage) => {
    setPageN(newPage);
  };
  const handleChangerowsPerPageN = (event) => {
    setRowsPerPageN(parseInt(event.target.value, 10));
    setPageN(0);
  };

  // Submitted Array assignment

  const [rowsPerPageS, setRowsPerPageS] = React.useState(5);

  const [pageS, setPageS] = React.useState(0);

  const [searchS, setsearchS] = useState("");

  const filterSubmitted = SubmittedArray.filter((event) => {
    return (
      event.assignment.name.toLowerCase().indexOf(searchS.toLowerCase()) !== -1
    );
  });

  const handleChangePageS = (event, newPage) => {
    setPageS(newPage);
  };
  const handleChangerowsPerPageS = (event) => {
    setRowsPerPageS(parseInt(event.target.value, 10));
    setPageS(0);
  };

  useEffect(() => {
    const getStudentDataSectionId = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_studentlist_SEction_id,
            variables: {
              id: userId,
            },
          })
          .then((response) => {
            setisloading(false);

            let Student_Name =
              response.data.getStudentDetailForm.student_profile.name;
            let section_id = SectionData.section?.id;
            setSectionid(section_id);
            setStudent_Name(Student_Name);
            try {
              setisloading(true);
              client
                .query({
                  query: Service.Get_Class_Notice_data,
                  variables: {
                    institution_id,
                    section_id,
                  },
                })
                .then((response) => {
                  setisloading(false);

                  setClassnoticedata(
                    response.data.getNoticeBoardBySectionId.filter((item) => {
                      return (
                        new Date() >= getcurrentDate(item.publish_date) &&
                        new Date() <= getcurrentDate(item.expiry_date)
                      );
                    })
                  );
                })
                .catch((err) => {
                  setisloading(false);
                  console.log("parent homepage catch err", err);
                });
            } catch (error) {
              setisloading(false);
              console.log("parent homepage t and c error", error);
            }

            setgradeName(SectionData.grade?.gradeName);

            const { dispatch } = props;
            let temp = {
              section: SectionData.section,
              section_id: SectionData.section.id,
              userName: response.data.getStudentDetailForm.student_profile.name,
            };
            dispatch({
              type: Constants.ACADEMIC_SESSION_INFO,
              state: temp,
              key: Constants.ACADEMIC_SESSION_DATA,
            });
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent homepage t and c error", error);
      }

      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_School_Notice_data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setSchoolnoticedata(
              response.data.getNoticeBoardByInstitutionId.filter((item) => {
                return (
                  new Date() >= getcurrentDate(item.publish_date) &&
                  new Date() <= getcurrentDate(item.expiry_date)
                );
              })
            );
          })
          .catch((err) => {
            setisloading(false);
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("parent homepage t and c error", error);
      }
    };

    getStudentDataSectionId();
    getAssignment();
  }, [client, institution_id, userId]);

  const startAssignment = (data) => {
    props.navigate("/continueassignment", { state: { data: data } });
  };

  const getAssignment = async () => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_Student_Assignment,
          variables: {
            user_id: userId,
          },
        })
        .then((response) => {
          setisloading(false);

          if (response.data.getAssignmentForStudent.length > 0) {
            setInProgressArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "INPROGRESS";
              })
            );
            setCompletedArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "COMPLETED";
              })
            );
            setSubmittedArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "SUBMITTED";
              })
            );
            setInNewArray(
              response.data.getAssignmentForStudent.reverse().filter((item) => {
                return item.status === "ASSIGNED" || item.status === "NEW";
              })
            );
          }
        });
    } catch (error) {
      setisloading(false);
      console.log("getAssignment try and catch inprog", error);
    }
  };

  const handleChangePage2 = (event, newPage2) => {
    setPage2(newPage2);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const handleChangePage3 = (event, newPage3) => {
    setPage3(newPage3);
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
  };

  return (
    <>
      <div className="main_full_width studenthomepage">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={institution_Name} isRemove={true} />
                {sectionid !== -1 && sectionid != null && (
                  <OnlineClass
                    userName={props.data}
                    section_id={sectionid}
                    institution_id={institution_id}
                    userType={props.data.usertype}
                  />
                )}
                <OnlineEventLink
                  userType={props.data.usertype}
                  user_id={parseInt(userId)}
                  userName={props.data}
                />
                <Grid className="grid_main_div studenthome mt-3">
                  <Grid
                    item
                    md={
                      props.data.type === "PT" || props.data.type === "ET"
                        ? 12
                        : 8
                    }
                  >
                    <div className="enquirydetails_background mb-2 assignment_portion_height">
                      <div className="enquiry_heading">
                        <div className="mt-1 ml-3 Heading_bold  flex-wrap">
                          <span> Assignment </span>

                          <span>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="simple tabs example"
                              indicatorColor="primary"
                              textColor="primary"
                              lefted
                            >
                              <Tab
                                label="New"
                                {...a11yProps(0)}
                                className="button_login_decoration text_tranformation_exam button_width_assignmentTab_new"
                              />
                              <Tab
                                label="In Progress"
                                {...a11yProps(1)}
                                className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                              />
                              <Tab
                                label="Submitted"
                                {...a11yProps(2)}
                                className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                              />
                              <Tab
                                label="Completed"
                                {...a11yProps(3)}
                                className="button_login_decoration text_tranformation_exam button_width_assignmentTab"
                              />
                            </Tabs>
                          </span>
                        </div>
                      </div>

                      <div className="card_margin_both_side">
                        <Card>
                          <div className="cardbackground_assignment_table">
                            <TabPanel value={value} index={0}>
                              <div>
                                <Grid className="grid_main_div mt-1 mb-1">
                                  <Grid item md={4}>
                                    <div className="inputMarginright">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Search"
                                        autoComplete="off"
                                        value={searchN}
                                        onChange={(e) => {
                                          setsearchN(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={8}></Grid>
                                </Grid>
                                <div className="table_foramtitng mt-1 mb-2">
                                  <TableContainer component={Paper}>
                                    <Table
                                      className={classes.table}
                                      aria-label="customized table"
                                      id="Table_width_Student"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Assignment Name
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Subject
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Start Date
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            End Date
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Details
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(rowsPerPageN > 0
                                          ? filterNew.slice(
                                              pageN * rowsPerPageN,
                                              pageN * rowsPerPageN +
                                                rowsPerPageN
                                            )
                                          : filterNew
                                        ).map((row) => (
                                          <StyledTableRow>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {getValue(
                                                CapitalizeFirstFn(
                                                  row.assignment.name
                                                )
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {getValue(
                                                row.assignment.subject.name
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {timeStampToDate(
                                                row.class_assignment.start_date
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {timeStampToDate(
                                                row.class_assignment.end_date
                                              )}
                                            </StyledTableCell>

                                            <StyledTableCell
                                              align="left"
                                              className="row_details_color table_assignement_Table_padding"
                                              onClick={() => {
                                                startAssignment(row);
                                              }}
                                            >
                                              Start
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                    <TablePagination
                                      true
                                      rowsPerPageOptions={false}
                                      component="div"
                                      count={filterNew.length}
                                      rowsPerPage={rowsPerPageN}
                                      page={pageN}
                                      onChangePage={handleChangePageN}
                                      onChangeRowsPerPage={
                                        handleChangerowsPerPageN
                                      }
                                    />
                                  </TableContainer>
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                              <div>
                                <Grid className="grid_main_div mt-1 mb-1">
                                  <Grid item md={4}>
                                    <div className="inputMarginright">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Search"
                                        autoComplete="off"
                                        value={searchP}
                                        onChange={(e) => {
                                          setsearchP(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={8}></Grid>
                                </Grid>
                                <div className="table_foramtitng mt-1 mb-2">
                                  <TableContainer component={Paper}>
                                    <Table
                                      className={classes.table}
                                      aria-label="customized table"
                                      id="Table_width_Student"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Assignment Name
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Subject
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Start Date
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            End Date
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="table_header table_assignement_Table_padding"
                                          >
                                            Details
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(rowsPerPageP > 0
                                          ? filterInProgess.slice(
                                              pageP * rowsPerPageP,
                                              pageP * rowsPerPageP +
                                                rowsPerPageP
                                            )
                                          : filterInProgess
                                        ).map((row) => {
                                          return (
                                            <StyledTableRow>
                                              <StyledTableCell
                                                className="fontsizetable table_assignement_Table_padding"
                                                align="left"
                                              >
                                                {getValue(
                                                  CapitalizeFirstFn(
                                                    row.assignment.name
                                                  )
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className="fontsizetable table_assignement_Table_padding"
                                                align="left"
                                              >
                                                {getValue(
                                                  row.assignment.subject.name
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className="fontsizetable table_assignement_Table_padding"
                                                align="left"
                                              >
                                                {timeStampToDate(
                                                  row.class_assignment
                                                    .start_date
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className="fontsizetable table_assignement_Table_padding"
                                                align="left"
                                              >
                                                {timeStampToDate(
                                                  row.class_assignment.end_date
                                                )}
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className="fontsizetable row_details_color table_assignement_Table_padding"
                                                align="left"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/continueassignment",
                                                    { state: { data: row } }
                                                  )
                                                }
                                              >
                                                Continue
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                    <TablePagination
                                      true
                                      rowsPerPageOptions={false}
                                      component="div"
                                      count={filterInProgess.length}
                                      rowsPerPage={rowsPerPageP}
                                      page={pageP}
                                      onChangePage={handleChangePageP}
                                      onChangeRowsPerPage={
                                        handleChangerowsPerPageP
                                      }
                                    />
                                  </TableContainer>
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                              <Grid className="grid_main_div mt-1 mb-1">
                                <Grid item md={4}>
                                  <div className="inputMarginright">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Search"
                                      autoComplete="off"
                                      value={searchS}
                                      onChange={(e) => {
                                        setsearchS(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={8}></Grid>
                              </Grid>
                              <div className="table_foramtitng mt-1 mb-2">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table fontsizetable"
                                    id="Table_width_Student"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Assignment Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Subject
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Start Date
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          End Date
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Details
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {(rowsPerPageS > 0
                                        ? filterSubmitted.slice(
                                            pageS * rowsPerPageS,
                                            pageS * rowsPerPageS + rowsPerPageS
                                          )
                                        : filterSubmitted
                                      ).map((row) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {getValue(
                                                CapitalizeFirstFn(
                                                  row.assignment.name
                                                )
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {getValue(
                                                row.assignment.subject.name
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {timeStampToDate(
                                                row.class_assignment.start_date
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {timeStampToDate(
                                                row.class_assignment.end_date
                                              )}
                                            </StyledTableCell>

                                            <StyledTableCell
                                              className="fontsizetable row_details_color table_assignement_Table_padding"
                                              align="left"
                                              onClick={() =>
                                                props.navigate(
                                                  "/assignmentResult",
                                                  {
                                                    state: {
                                                      data: row,
                                                      name: Student_Name,
                                                      grade: gradeName,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Open
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>

                                  <TablePagination
                                    true
                                    rowsPerPageOptions={false}
                                    component="div"
                                    count={filterSubmitted.length}
                                    rowsPerPage={rowsPerPageS}
                                    page={pageS}
                                    onChangePage={handleChangePageS}
                                    onChangeRowsPerPage={
                                      handleChangerowsPerPageS
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </TabPanel>

                            <TabPanel value={value} index={3}>
                              <Grid className="grid_main_div mt-1 mb-1">
                                <Grid item md={4}>
                                  <div className="inputMarginright">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Search"
                                      autoComplete="off"
                                      value={searchC}
                                      onChange={(e) => {
                                        setsearchC(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={8}></Grid>
                              </Grid>
                              <div className="table_foramtitng mt-1 mb-2">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table fontsizetable"
                                    id="Table_width_Student"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Assignment Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Subject
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Start Date
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          End Date
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_assignement_Table_padding"
                                        >
                                          Details
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {(rowsPerPageC > 0
                                        ? filterCompleted.slice(
                                            pageC * rowsPerPageC,
                                            pageC * rowsPerPageC + rowsPerPageC
                                          )
                                        : filterCompleted
                                      ).map((row) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {getValue(
                                                CapitalizeFirstFn(
                                                  row.assignment.name
                                                )
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {getValue(
                                                row.assignment.subject.name
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {timeStampToDate(
                                                row.class_assignment.start_date
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              className="fontsizetable table_assignement_Table_padding"
                                              align="left"
                                            >
                                              {timeStampToDate(
                                                row.class_assignment.end_date
                                              )}
                                            </StyledTableCell>

                                            <StyledTableCell
                                              className="fontsizetable row_details_color table_assignement_Table_padding"
                                              align="left"
                                              onClick={() =>
                                                props.navigate(
                                                  "/assignmentResult",
                                                  {
                                                    state: {
                                                      data: row,
                                                      name: Student_Name,
                                                      grade: gradeName,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Open
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>

                                  <TablePagination
                                    true
                                    rowsPerPageOptions={false}
                                    component="div"
                                    count={filterCompleted.length}
                                    rowsPerPage={rowsPerPageC}
                                    page={pageC}
                                    onChangePage={handleChangePageC}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPageC
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </TabPanel>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Grid>
                  {props.data.type === "PT" || props.data.type === "ET" ? (
                    ""
                  ) : (
                    <Grid item md={4}>
                      <div className="enquirydetails_background mb-1 ml-2 noticePortion_Height">
                        <div className="enquiry_heading">
                          <div className="mt-1 ml-1 Heading_bold">
                            Class Notice
                          </div>
                          <div
                            className="mt-3 mr-3 openassignemntcolor view_notice_font"
                            onClick={() =>
                              props.navigate("/noticeBoard", {
                                state: {
                                  data: institution_id,
                                },
                              })
                            }
                          >
                            {" "}
                            View All <i class="fa fa-chevron-right"></i>
                          </div>
                        </div>
                        <div className="card_margin_both_side">
                          <Grid className="grid_main_div mb-1">
                            <Grid item md={12}>
                              <Card className="inputMarginright">
                                <div className="card_admissiondetails_height Notice_background_card ">
                                  <div className="textfiled_margin mb-2">
                                    <div className="">
                                      <div className="table_foramtitng mt-3">
                                        <TableContainer component={Paper}>
                                          <Table
                                            className={classes.table}
                                            aria-label="customized table"
                                            id="class_notice_width"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <StyledTableCell
                                                  align="left"
                                                  className="table_header table_header_padding"
                                                >
                                                  Description
                                                </StyledTableCell>

                                                <StyledTableCell
                                                  align="left"
                                                  className="table_header table_header_padding"
                                                >
                                                  Date
                                                </StyledTableCell>
                                                <StyledTableCell
                                                  align="left"
                                                  className="table_header table_header_padding"
                                                >
                                                  Details
                                                </StyledTableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {(rowsPerPage2 > 0
                                                ? classnoticedata.slice(
                                                    page2 * rowsPerPage2,
                                                    page2 * rowsPerPage2 +
                                                      rowsPerPage2
                                                  )
                                                : classnoticedata
                                              ).map((row) => (
                                                <StyledTableRow>
                                                  <StyledTableCell
                                                    align="left"
                                                    className="font_size_class_data"
                                                  >
                                                    {!blankValidator(
                                                      row.title
                                                    ) ? (
                                                      " "
                                                    ) : (
                                                      <span>
                                                        {CapitalizeFirstFn(
                                                          row.title
                                                        )}
                                                      </span>
                                                    )}
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    scope="row"
                                                    align="left"
                                                    className="font_size_class_data"
                                                  >
                                                    {timeStampToDate(
                                                      row.publish_date
                                                    )}
                                                  </StyledTableCell>

                                                  <StyledTableCell
                                                    align="left"
                                                    className="row_details_color font_size_class_data"
                                                    onClick={() =>
                                                      props.navigate(
                                                        "/noticeDetail",
                                                        {
                                                          state: {
                                                            item: row,
                                                            type: "CLASSNOTICE",
                                                            institution_Name:
                                                              institution_Name,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  >
                                                    View
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                          <TablePagination
                                            true
                                            rowsPerPageOptions={false}
                                            component="div"
                                            count={classnoticedata.length}
                                            rowsPerPage={rowsPerPage2}
                                            page={page2}
                                            onChangePage={handleChangePage2}
                                            onChangeRowsPerPage={
                                              handleChangeRowsPerPage2
                                            }
                                          />
                                        </TableContainer>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </Grid>
                          </Grid>
                        </div>
                      </div>

                      <div className="enquirydetails_background mb-1 ml-2 mt-3 noticePortion_Height">
                        <div className="enquiry_heading">
                          <div className="mt-1 ml-1 Heading_bold">
                            School Notice
                          </div>
                          <div
                            className="mt-3 mr-3 openassignemntcolor view_notice_font"
                            onClick={() =>
                              props.navigate("/noticeBoard", {
                                state: {
                                  data: institution_id,
                                },
                              })
                            }
                          >
                            {" "}
                            View All <i class="fa fa-chevron-right"></i>
                          </div>
                        </div>
                        <div className="card_margin_both_side">
                          <Grid className="grid_main_div mb-1">
                            <Grid item md={12}>
                              <Card className="inputMarginright">
                                <div className="card_admissiondetails_height Notice_background_card">
                                  <div className="textfiled_margin mb-2">
                                    <div className="">
                                      <div className="table_foramtitng mt-3">
                                        <TableContainer component={Paper}>
                                          <Table
                                            className={classes.table}
                                            aria-label="customized table"
                                            id="class_notice_width"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <StyledTableCell
                                                  align="left"
                                                  className="table_header table_header_padding"
                                                >
                                                  Description
                                                </StyledTableCell>

                                                <StyledTableCell
                                                  align="left"
                                                  className="table_header table_header_padding"
                                                >
                                                  Date
                                                </StyledTableCell>
                                                <StyledTableCell
                                                  align="left"
                                                  className="table_header table_header_padding"
                                                >
                                                  Details
                                                </StyledTableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {(rowsPerPage3 > 0
                                                ? schoolnoticedata.slice(
                                                    page3 * rowsPerPage3,
                                                    page3 * rowsPerPage3 +
                                                      rowsPerPage3
                                                  )
                                                : schoolnoticedata
                                              ).map((row) => (
                                                <StyledTableRow>
                                                  <StyledTableCell
                                                    align="left"
                                                    className="font_size_class_data"
                                                  >
                                                    {!blankValidator(
                                                      row.title
                                                    ) ? (
                                                      " "
                                                    ) : (
                                                      <span>
                                                        {CapitalizeFirstFn(
                                                          row.title
                                                        )}
                                                      </span>
                                                    )}
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    scope="row"
                                                    align="left"
                                                    className="font_size_class_data"
                                                  >
                                                    {timeStampToDate(
                                                      row.publish_date
                                                    )}
                                                  </StyledTableCell>

                                                  <StyledTableCell
                                                    align="left"
                                                    className="row_details_color font_size_class_data"
                                                    onClick={() =>
                                                      props.navigate(
                                                        "/noticeDetail",
                                                        {
                                                          state: {
                                                            item: row,
                                                            type: "SCHOOLNOTICE",
                                                            institution_Name:
                                                              institution_Name,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  >
                                                    View
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                          <TablePagination
                                            true
                                            rowsPerPageOptions={false}
                                            component="div"
                                            count={schoolnoticedata.length}
                                            rowsPerPage={rowsPerPage3}
                                            page={page3}
                                            onChangePage={handleChangePage3}
                                            onChangeRowsPerPage={
                                              handleChangeRowsPerPage3
                                            }
                                          />
                                        </TableContainer>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>

                <UpcomingEventsComponent navigate={props.navigate} />
              </Grid>
            </Grid>
          </div>
        </div>
        <Loder loading={isloading} />
      </div>
    </>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentHomePage));
