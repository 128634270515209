import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import {
  FETCH_USER_withToken,
  getGatWay,
  Get_Academic_session,
} from "../services";
import { useMutation } from "@apollo/react-hooks";
import * as Constants from "../action/appAction";
import { connect } from "react-redux";

import {
  showNotificationMsz,
  blankValidator,
  loadComponent,
  onScriptLoad,
} from "../utils/Validation";
const config = require("../config/default.json");

const PageReloadHandler = ({ navigate, dispatch }) => {
  const client = useApolloClient();

  const [FETCH_USERHOOK, {}] = useMutation(FETCH_USER_withToken);

  let navigation = navigate?.push;

  const [isloading, setisloading] = useState(false);

  const getrefreshData = async (token) => {
    try {
      window.localStorage.removeItem("refreshKey");
      setisloading(true);
      const response = await FETCH_USERHOOK({
        variables: {
          token,
        },
      });
      const loginResponse = await response.data.loginWithToken;

      const { tokenid, usertype, institution_id, message } =
        await loginResponse;
      await dispatch({
        type: Constants.IS_AUTHENTICATED,
        state: true,
        key: Constants.AUTHENTICATION,
      });
      await dispatch({
        type: Constants.USER_INFO,
        state: loginResponse,
        key: Constants.TOKEN,
      });

      if (tokenid) {
        if (usertype === "SUPERADMIN") {
          navigation("/organisationSetup");
        } else if (usertype === "ADMIN") {
          navigation("/institutionSetup");
        } else if (usertype === "TEACHER" || usertype === "Teacher") {
          await getAcadimSessionDetails(institution_id);
        } else if (usertype === "STUDENT") {
          await getAcadimSessionDetails(institution_id);
          navigation("/studentListOfInvoice");
        } else if (usertype === "PARENT") {
          await getAcadimSessionDetails(institution_id);
          navigation("/parentfee");
        } else {
          await getAcadimSessionDetails(institution_id);
        }
      } else {
        await showNotificationMsz(message, "danger");
      }
      setisloading(false);
    } catch (error) {
      console.log("error ", error);
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  if (window.performance) {
    let token = window.localStorage.getItem("refreshKey");

    if (blankValidator(token)) {
      getrefreshData(token);
    }
  }
  const getGateWayHandler = async (insId) => {
    try {
      const gateWay = await client.query({
        query: getGatWay,
        variables: {
          institution_id: insId,
          gateway_name: config.config.gateWayName,
        },
      });
      if (gateWay.data && gateWay.data.getGatWay) {
        const { getGatWay } = gateWay.data;

        let url = `https://${config.config.paytm_hostname}/merchantpgpui/checkoutjs/merchants/${getGatWay.secret}.js`;
        const loaded = await loadComponent(url);
        if (loaded) onScriptLoad();
      }
    } catch (error) {}
  };
  const getAcadimSessionDetails = async (institution_id) => {
    try {
      setisloading(true);
      getGateWayHandler(institution_id);
      const { data } = await client.query({
        query: Get_Academic_session,
        variables: {
          institution_id,
        },
      });
      if (!data.getAcademicSession.id) {
        alert("There is no active session");
        setisloading(false);
      } else {
        await dispatch({
          type: Constants.ACADAMIC_DATA,
          state: data.getAcademicSession,
          key: Constants.ACADAMIC_DATA,
        });
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
    }
  };
  return <div></div>;
};
export default connect(null, null)(PageReloadHandler);
