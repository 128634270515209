import React, { useState } from "react";
import "./Events.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  CapitalizeFirstFn,
  timeStampToDate,
  timeStampToDateandTime,
  getFullName,
} from "../../../utils/Validation";
import OnlineLinkLocation from "./OnlineLinkLocation";
// import StudentInvite from "./StudentInvite";
// import TeacherInvite from "./TeacherInvite";
import Holiday from "../../Common/Holiday/Holiday.jsx";

import EventGroupInviteesTabbar from "./AddInvitees/EventGroupInviteesTabbar";
const InviteForm = (props) => {
  let data = props.location.state.data;
  let academic_session_id =
    props.data.usertype == "TEACHER"
      ? props.location.state.academic_session_id
      : props.data.academic_session_id;
  // const [openMeetingDailog, setOpenMeetingDailog] = useState(false);
  // const [displayData, setDisplayData] = useState("");

  // const [chekbox1, setchekbox1] = useState(true);
  // const [chekbox2, setchekbox2] = useState(false);
  // const [chekbox3, setchekbox3] = useState(false);
  // const [chekbox4, setchekbox4] = useState(true);
  // const [chekbox5, setchekbox5] = useState(false);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.token.institution_name} />
              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">Invite</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={3}>
                            <div className="mt-2">
                              <div className="text_filed_heading">Name</div>
                              <div>
                                {data.event_name === undefined ||
                                data.event_name === null ? (
                                  ""
                                ) : (
                                  <span className="text-info font-weight-bold">
                                    {" "}
                                    {CapitalizeFirstFn(data.event_name)}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-2">
                              <div className="text_filed_heading ">
                                Start Time
                              </div>
                              <div className="font-weight-bold">
                                {data.end_date &&
                                  timeStampToDateandTime(data.start_date)}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-2">
                              <div className="text_filed_heading">End Time</div>
                              <div className="font-weight-bold">
                                <span className="font-weight-bold">
                                  {data.end_date &&
                                    timeStampToDateandTime(data.end_date)}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          {(props.token.usertype === "ADMIN" ||
                            props.token.usertype === "SUPERADMIN") && (
                            <Grid item md={6} className="grid_main_div">
                              <Grid item md={6}>
                                <div className="mt-2">
                                  <div className="text_filed_heading">
                                    Publish Date
                                  </div>
                                  <div className="font-weight-bold">
                                    {data.publish_date &&
                                      timeStampToDate(data.publish_date)}
                                  </div>
                                </div>
                              </Grid>
                              <Grid item md={6}>
                                <div className="mt-2">
                                  <div className="text_filed_heading">
                                    Expiry Date
                                  </div>
                                  <div className="font-weight-bold">
                                    {data.publish_date &&
                                      timeStampToDate(data.expiry_date)}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item md={3}>
                            <div className="mt-2">
                              <div className="text_filed_heading">Location</div>
                              <OnlineLinkLocation
                                data={data}
                                userType={props.data.usertype}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Organizer
                              </div>
                              <div>{getFullName(data.organizer_name)}</div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <div className="text_filed_heading">Invitees</div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            <EventGroupInviteesTabbar
                              data={data}
                              institution_id={props.data.institution_id}
                              academic_session_id={academic_session_id}
                            ></EventGroupInviteesTabbar>
                          </Grid>
                        </Grid>
                        <div className="card_invoicedetails_border mt-3"></div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  token: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(InviteForm));
