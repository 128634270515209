import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  CapitalizeFirstFn,
  getcurrentDate,
  getFullName,
  getSubStringValue,
  getValue,
  timeStampToDateandTime,
} from "../../../utils/Validation";
import OnlineLinkLocation from "./OnlineLinkLocation.jsx";
import Loder from "../../Loder/Loder.jsx";

function UpcomingEventsComponent(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);

  const [manageEventArr, setManageEventArr] = useState([]);

  //Institute name
  let institution_Name = props.data.institution_name;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const institution_id = parseInt(props.data.institution_id);

  // to get data api
  const client = useApolloClient();

  useEffect(() => {
    const getEventData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_EVENT_BY_USERID,
            variables: {
              user_id: parseInt(props.data.userid),
              user_type:
                props.data.usertype === "TEACHER"
                  ? "TEACHER"
                  : props.data.usertype,
            },
          })
          .then((response) => {
            setisloading(false);

            setManageEventArr(
              response.data.getEventByUserId.reverse().filter((item) => {
                return getcurrentDate(item.event.end_date) >= new Date();
              })
            );
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getEventData();
  }, [client, institution_id]);

  const [search, setSearch] = useState("");

  const filterUpcomingEvent = manageEventArr.filter((item) => {
    return getValue(item?.event?.event_name)
      .toLowerCase()
      .indexOf(search.toLowerCase()) !== -1
      ? true
      : false;
  });
  return (
    <div className="enquirydetails_background">
      <div className="enquiry_heading">
        <div className="ml-3 mt-2 Heading_bold">Events</div>
      </div>
      <div className="card_margin_both_side">
        <Card>
          <div className="card_admissiondetails_height">
            <div className="textfiled_margin">
              <Grid className="grid_main_div mt-2">
                <Grid item md={3}>
                  <div className="inputMarginright mt-1">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Search Events"
                      autoComplete="off"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Grid>

                <Grid item md={9}></Grid>
              </Grid>

              <div className="table_foramtitng mb-2 mt-2">
                <TableContainer
                  className={classes.table}
                  aria-label="customized table"
                >
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className="table_header">
                          Name
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Organizer Name
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Description
                        </StyledTableCell>
                        <StyledTableCell className="table_header" align="left">
                          Start Time
                        </StyledTableCell>
                        <StyledTableCell className="table_header" align="left">
                          Location
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? filterUpcomingEvent.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : filterUpcomingEvent
                      ).map((row, index) => (
                        <StyledTableRow key={row.event.title}>
                          <StyledTableCell
                            align="left"
                            className="row_details_color"
                            onClick={() =>
                              props.navigate("/vieweventDetails", {
                                state: {
                                  institution_Name: institution_Name,
                                  row: row.event,
                                },
                              })
                            }
                          >
                            {!blankValidator(row.event.event_name) ? (
                              " "
                            ) : (
                              <span>
                                {CapitalizeFirstFn(row.event.event_name)}
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {getFullName(row.event.organizer_name)}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Tooltip title={row?.event?.description} arrow>
                              <span>
                                {getSubStringValue(row?.event?.description)}
                              </span>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!blankValidator(row.event.start_date) ? (
                              " "
                            ) : (
                              <span>
                                {timeStampToDateandTime(row.event.start_date)}
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <OnlineLinkLocation
                              data={row.event}
                              userType={props.data.usertype}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    true
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filterUpcomingEvent.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(UpcomingEventsComponent);
