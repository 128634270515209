import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";

import { baseUrl } from "../../../services/index.js";
import { getFullName, timeStampToDateandTime } from "../../../utils/Validation";
import HOC1 from "../../Hoc1.jsx";
import Holiday from "../Holiday/Holiday.jsx";
import OnlineLinkLocation from "./OnlineLinkLocation.jsx";

const ParentStudentEventsDetails = (props) => {
  const { row, institution_Name } = props.location.state;
  let data = row;
  const parse = require("html-react-parser");
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const [OpenImageDailog, setOpenImageDailog] = useState(false);

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name && institution_Name} />
              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold text-info">
                    Event Details - {row?.event_name && row?.event_name}
                  </div>
                  {props?.data?.usertype === "TEACHER" && (
                    <div className="d-flex">
                      <div className="mt-3 ml-3 ">
                        <Button
                          variant="contained"
                          className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_size_invoice"
                          onClick={() => {
                            props.navigate("/eventMarkAttendanceView", {
                              state: {
                                type: "EVENT",
                                data,
                              },
                            });
                          }}
                        >
                          Attendance
                        </Button>{" "}
                      </div>
                      <div className="mt-3 ml-3 mr-2">
                        <Button
                          variant="contained"
                          className="add_new_query_btn button_width button_login_decoration btn_font_size_invoice mr-3"
                          onClick={(e) => {
                            props.navigate("/markEventAttendance", {
                              state: {
                                data,
                              },
                            });
                          }}
                        >
                          Mark Attendance
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Start Time
                              </div>
                              <div className="font-weight-bold">
                                {row?.start_date &&
                                  timeStampToDateandTime(row?.start_date)}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">End Time</div>
                              <div>
                                <div className="font-weight-bold">
                                  {row?.end_date &&
                                    timeStampToDateandTime(row?.end_date)}
                                </div>
                              </div>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">Location</div>
                              <OnlineLinkLocation
                                data={row}
                                userType={props.data.usertype}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Organizer Name
                              </div>
                              <div className="font-weight-bold">
                                {getFullName(row?.organizer_name)}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            {/* <div className="mt-2">
                              <div className="text_filed_heading">Invitees</div>
                              <div>Primary 1 - All Sectiona - All Parents</div>
                            </div> */}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Event Content
                              </div>
                              <div>
                                {row?.content && parse.default(row?.content)}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Attachments
                              </div>
                              {row.documents &&
                                row.documents.map((item, index) => (
                                  <div
                                    className="file_back_color ml-2 d-flex "
                                    onClick={() => HandleImageDailogBox(item)}
                                  >
                                    {item?.name}
                                  </div>
                                ))}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default HOC1(ParentStudentEventsDetails);
