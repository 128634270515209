import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../action/appAction";
import { getData } from "../../api/index";
import * as Service from "../../services/index";
import {
  blankValidator,
  CapitalizeFirstFn,
  getcurrentDate,
  getFullName,
  timeStampToDate,
} from "../../utils/Validation";
import UpcomingEventsComponent from "../Common/Events/UpcomingEventsComponent";
import Holiday from "../Common/Holiday/Holiday.jsx";
import HOC1 from "../Hoc1.jsx";
import Loder from "../Loder/Loder.jsx";
import "./ParentHomePage.css";

const ParentHomePage = ({ data, navigate, dataacademic }) => {
  const { studentData } = data;
  //-------------------GLOBAL ID------------------------
  let navigation = navigate;
  let father_id = parseInt(data.userid);
  let institution_id = parseInt(data.institution_id);
  //Institute name

  let institution_Name = data.institution_name;

  //-------------------API CALLS------------------------
  const client = useApolloClient();
  //-------------------LOCAL STATE------------------------
  const [academicSession, setAcademicSession] = useState("");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page2] = React.useState(0);
  const [rowsPerPage2] = React.useState(10);
  const [ParentHomeData, setParentHomeData] = useState([]);
  const [admissionFormArr, setadmissionFormArr] = useState([]);

  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [isloading, setisloading] = useState(false);
  //-------------------METHODS------------------------
  useEffect(() => {
    client
      .query({
        query: Service.Get_Academic_session,
        variables: {
          institution_id,
        },
      })
      .then((response) => {
        setAcademicSession(response.data.getAcademicSession.session_name);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });

    getStudentAdmissionData();

    if (father_id && institution_id) {
      getPraentStudentData();
      get_SchoolNotice_Data();
    }
  }, [client, institution_id, father_id]);
  const getPraentStudentData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Parent_student_data,
          variables: {
            institution_id,
            father_id,
            status: "ACTIVE",
          },
        })
        .then((response) => {
          if (response.data.getStudentByParent.length > 0) {
            setParentHomeData(response.data.getStudentByParent);
          }

          setisloading(false);
        })
        .catch((error) => {
          console.log("exam catch error", error);
        });
    } catch (error) {
      console.log("exam t and c error", error);
    }
  };
  const get_SchoolNotice_Data = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_School_Notice_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          let temp = [];

          if (
            response.data.getNoticeBoardByInstitutionId !== null &&
            response.data.getNoticeBoardByInstitutionId !== undefined
          ) {
            for (
              let index = 0;
              index < response.data.getNoticeBoardByInstitutionId.length;
              index++
            ) {
              const element =
                response.data.getNoticeBoardByInstitutionId[index];

              if (
                new Date() >= getcurrentDate(element.publish_date) &&
                new Date() <= getcurrentDate(element.expiry_date)
              ) {
                temp.push({
                  id: element.id,
                  title: element.title,
                  subject: element.subject,

                  publish_date: element?.publish_date,
                  expiry_date: element?.expiry_date,
                  admin: element?.admin,
                  // status: "jjjj",
                  status:
                    new Date() > getcurrentDate(element.expiry_date)
                      ? "EXPIRED"
                      : element.status,

                  content: element.content,
                  documents: element.documents,
                });
              }
            }
            setSchoolnoticedata(temp);
          }
          setisloading(false);
        })
        .catch((err) => {
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      console.log("parent homepage t and c error", error);
    }
  };

  const getStudentAdmissionData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Parent_student_data,
          variables: {
            institution_id,
            father_id,
            status: "",
          },
        })
        .then((response) => {
          if (response.data.getStudentByParent.length > 0) {
            setadmissionFormArr(response.data.getStudentByParent);
          }
          setisloading(false);
        })
        .catch((err) => {
          console.log("exam catch err", err);
        });
    } catch (error) {
      console.log("exam t and c error", error);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} isRemove={true} />

              <Grid className="grid_main_div paddding_form_top">
                <Grid item md={8} sm={12}>
                  <div className="enquirydetails_background balanceCard mb-2">
                    <div className="card_margin_both_side">
                      <div className="ml-1 mb-1 Heading_bold">
                        Student Details
                      </div>
                      {admissionFormArr.length !== 0 && (
                        <Card>
                          <div className="card_admissiondetails_height">
                            {/* --------------------------add on ui-------------------------- */}
                            <div
                              style={{
                                fontWeight: "bold",
                                paddingTop: 10,
                                paddingLeft: 10,
                                paddingBottom: 2,
                              }}
                            >
                              <h6>List of Childs for Admission Form</h6>
                            </div>
                            <div className="textfiled_margin">
                              <div className="table_foramtitng mt-2 mb-1">
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                    id="parent_homepage_table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_Parent_home_padding"
                                        >
                                          Name
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header table_Parent_home_padding"
                                        >
                                          Class
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="center"
                                          className="table_header table_Parent_home_padding"
                                        >
                                          Academic Session
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="center"
                                          className="table_header table_Parent_home_padding"
                                        >
                                          Actions
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage > 0
                                        ? admissionFormArr.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                          )
                                        : { admissionFormArr }
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {row?.name?.first_name}{" "}
                                            {row?.name?.middle_name}{" "}
                                            {row?.name?.last_name}
                                          </StyledTableCell>

                                          <StyledTableCell align="left">
                                            {row.grade.gradeName}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {academicSession}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            <div>
                                              <span
                                                className="row_details_color mr-1"
                                                onClick={() =>
                                                  navigate(
                                                    "/studentDetailForm",
                                                    {
                                                      state: {
                                                        studentId:
                                                          row.student.id,
                                                        institution_name:
                                                          institution_Name,
                                                        academic_session_id:
                                                          parseInt(
                                                            dataacademic.id
                                                          ),
                                                        type: "FROMPARENTHOMEPAGE",
                                                        institution_id:
                                                          data.institution_id,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                {row.status}
                                              </span>
                                              |
                                              <span
                                                className="row_details_color ml-1"
                                                onClick={() =>
                                                  navigation(
                                                    "/listofInvoices",
                                                    {
                                                      state: {
                                                        row,
                                                        institution_name:
                                                          institution_Name,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Payment
                                              </span>
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={admissionFormArr.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </div>
                        </Card>
                      )}

                      <Card className="mt-3">
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div className="table_foramtitng mt-2 mb-1">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                  id="parent_homepage_table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Class
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Section
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {/* {(rowsPerPage > 0
                                      ? ParentHomeData.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : ParentHomeData
                                    ).map((row) => ( */}
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {getFullName(studentData?.name)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {studentData?.section?.grade?.gradeName}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <div>{studentData?.section?.name}</div>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <div className="d-flex justify-content-between">
                                          <span
                                            className="row_details_color"
                                            onClick={() =>
                                              navigation("/studentAssignment", {
                                                state: {
                                                  row: studentData,
                                                  institution_Name:
                                                    institution_Name,
                                                },
                                              })
                                            }
                                          >
                                            Assignment
                                          </span>
                                          |
                                          <span
                                            className="row_details_color"
                                            onClick={() =>
                                              navigation("/listofInvoices", {
                                                state: {
                                                  studentData: studentData,
                                                  institution_name:
                                                    data.institution_name,
                                                },
                                              })
                                            }
                                          >
                                            Payment
                                          </span>
                                          |
                                          <span
                                            className="row_details_color"
                                            onClick={() =>
                                              navigation(
                                                "/parentStudentTimeTable",
                                                {
                                                  state: {
                                                    institution_Name:
                                                      institution_Name,
                                                    row: studentData,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            TimeTable
                                          </span>
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* ))} */}
                                  </TableBody>
                                </Table>
                                {/* <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={ParentHomeData.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> */}
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4} sm={12}>
                  <div className="enquirydetails_background mb-1 ml-2 balanceCard">
                    <div className="enquiry_heading">
                      <div className="mt-2 ml-1 Heading_bold">
                        School Notices
                      </div>
                      <div
                        className="mt-3 mr-3 openassignemntcolor view_notice_font"
                        onClick={() => navigation("/parentSchoolNotice")}
                      >
                        {" "}
                        View All <i class="fa fa-chevron-right"></i>
                      </div>
                    </div>
                    <div className="card_margin_both_side">
                      <Grid className="grid_main_div mb-1">
                        <Grid item md={12} className="mt-4 mb-4">
                          <Card className="inputMarginright">
                            <div className="card_admissiondetails_height ">
                              <div className="textfiled_margin mb-2">
                                <div className="mt-2 mb-3">
                                  <div className="table_foramtitng mt-3">
                                    <TableContainer component={Paper}>
                                      <Table
                                        className={classes.table}
                                        aria-label="customized table"
                                        id="class_notice_width"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <StyledTableCell
                                              align="left"
                                              className="table_header table_header__parent_padding"
                                            >
                                              Description
                                            </StyledTableCell>

                                            <StyledTableCell
                                              align="left"
                                              className="table_header table_header__parent_padding"
                                            >
                                              Date
                                            </StyledTableCell>
                                            <StyledTableCell
                                              align="left"
                                              className="table_header table_header__parent_padding"
                                            >
                                              Details
                                            </StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {(rowsPerPage2 > 0
                                            ? schoolnoticedata.slice(
                                                page2 * rowsPerPage2,
                                                page2 * rowsPerPage2 +
                                                  rowsPerPage2
                                              )
                                            : schoolnoticedata
                                          ).map((row) => (
                                            <StyledTableRow>
                                              <StyledTableCell
                                                align="left"
                                                className="font_size_Parent_class_data"
                                              >
                                                {!blankValidator(row.title) ? (
                                                  " "
                                                ) : (
                                                  <span>
                                                    {CapitalizeFirstFn(
                                                      row.title
                                                    )}
                                                  </span>
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                scope="row"
                                                align="left"
                                                className="font_size_Parent_class_data"
                                              >
                                                {!blankValidator(
                                                  row.publish_date
                                                ) ? (
                                                  " "
                                                ) : (
                                                  <span>
                                                    {timeStampToDate(
                                                      row.publish_date
                                                    )}
                                                  </span>
                                                )}
                                              </StyledTableCell>

                                              <StyledTableCell
                                                align="left"
                                                className="row_details_color font_size_Parent_class_data"
                                                onClick={() =>
                                                  navigate("/noticeDetail", {
                                                    state: {
                                                      item: row,
                                                      type: "SCHOOLNOTICE",
                                                      institution_Name:
                                                        institution_Name,
                                                    },
                                                  })
                                                }
                                              >
                                                View
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <UpcomingEventsComponent navigate={navigate} />
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(ParentHomePage));
