import React, { useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";

import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux

import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function ManageTimeTable({ ADMIN_DATA, navigate }) {
  const { institution_name, type } = ADMIN_DATA;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Manage Time Table
                  </div>
                </div>
                <div className="card_margin_both_side">
                  {type === "PT" || type === "ET" ? (
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color inputMarginright"
                              onClick={() => navigate("/timeTableTemplate")}
                            >
                              Manage Time Table Template
                            </Card>
                          </Grid>
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color"
                              onClick={() =>
                                navigate("/classtimetabletpenassignlink")
                              }
                            >
                              Assign TimeTable
                            </Card>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color inputMarginright"
                              onClick={() =>
                                navigate("/timetable", {
                                  state: {
                                    classId: -1,
                                    secId: -1,
                                  },
                                })
                              }
                            >
                              Update TimeTable
                            </Card>
                          </Grid>{" "}
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color "
                              onClick={() => navigate("/showAllTimetables")}
                            >
                              Manage class timetable
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  ) : (
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color inputMarginright"
                              onClick={() => navigate("/timeTableTemplate")}
                            >
                              Manage Time Table Template
                            </Card>
                          </Grid>
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color"
                              onClick={() =>
                                navigate("/classtimetabletpenassignlink")
                              }
                            >
                              Assign TimeTable
                            </Card>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color inputMarginright"
                              onClick={() => navigate("/manageclass")}
                            >
                              Assign Teacher
                            </Card>
                          </Grid>
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color"
                              onClick={() =>
                                navigate("/timetable", {
                                  state: {
                                    classId: -1,
                                    secId: -1,
                                  },
                                })
                              }
                            >
                              Update TimeTable
                            </Card>
                            {/* <Card
                              className="pt-2 pb-2 card_color"
                              onClick={() => navigate("/showAllTimetables")}
                            >
                              Manage class timetable
                            </Card> */}
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <Card
                              className="pt-2 pb-2 card_color inputMarginright"
                              onClick={() => navigate("/showAllTimetables")}
                            >
                              Manage class timetable
                            </Card>
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageTimeTable));
