import React, { useState, useEffect } from "react";
import "./Assignment.css";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import RichTextEditor from "react-rte";
import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";

import axios from "axios";
import Chip from "@material-ui/core/Chip";
//redux
import * as Service from "../../../services/index";
import {
  CapitalizeFirstFn,
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const AssignmentSubmission = (props) => {
  // institution id for all api globally declared
  let userId = parseInt(props.data.userid);

  //Institute name
  let institution_Name = props.data.institution_name;

  //create institution
  const [Student_Question_Submission, {}] = useMutation(
    Service.Student_Question_Submission
  );

  const [Submit_Assignment_Student, {}] = useMutation(
    Service.Submit_Assignment_Student
  );
  const parse = require("html-react-parser");
  // const sectionName = props.location.state.sectionname;
  const assignmentname = props.location.state.data.assignment.name;
  const assignment_id = props.location.state.data.assignment_id;
  const student_assignment_id = props.location.state.data.id;

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [questioarr, setquestioarr] = useState([]);
  const client = useApolloClient();
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [questionindex, setquestionindex] = useState(0);
  const [choiceArray, setChoiceArray] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);
  const [answerimage, setanswerimage] = useState({});
  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };
  const UploadDocumentHandler = (e) => {
    const file = e.target.files[0];
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);
    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setanswerimage(obj);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error.message, "danger");
    }
  };
  const select = (item, index) => {
    try {
      setCurrentQuestion(item);
      if (item.question_type === "Text_Answer") {
        setValue(
          RichTextEditor.createValueFromString(
            blankValidator(item.answer) ? item.answer : "",
            "html"
          )
        );
      } else {
        getOptionForMultplease(item.id, item.answer);
      }

      setquestionindex(index);
      setanswerimage(item.answerdocument);
    } catch (error) {
      console.log("error", error);
    }
  };

  // will submit response for revoew
  const submitAssignmentForReview = () => {
    try {
      setisloading(true);
      Submit_Assignment_Student({
        variables: {
          assignment_id: parseInt(assignment_id),
          user_id: userId,
        },
      })
        .then(
          (response) => {
            showNotificationMsz(
              response.data.submitAssignment.message,
              "success"
            );

            setisloading(false);
            props.navigate("/studentHomePage");
          },
          (err) => {
            setisloading(false);
            console.log("then err", err);
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  useEffect(() => {
    questionlist();
  }, [props]);

  const getOptionForMultplease = (data, answer) => {
    try {
      setisloading(true);

      client
        .query({
          query: Service.Get_Question_choice,
          variables: {
            assignment_question_id: data,
          },
        })
        .then(
          (response) => {
            setisloading(false);

            if (response.data.getAssignmentChoicesByQuestionId !== undefined) {
              let temp = [];
              for (
                let index = 0;
                index < response.data.getAssignmentChoicesByQuestionId.length;
                index++
              ) {
                const element =
                  response.data.getAssignmentChoicesByQuestionId[index];

                let preselectedData = getDataisPreselected(
                  element.choice,
                  answer
                );

                temp.push({
                  choice: element.choice,
                  isselected: preselectedData,
                });
              }

              setChoiceArray(temp);
            } else {
              return;
            }
          },

          (error) => {
            setisloading(false);
          }
        );
    } catch (error) {
      setisloading(false);
    }
  };
  const getDataisPreselected = (data, asnwer) => {
    try {
      if (!blankValidator(asnwer)) {
        return false;
      }

      for (let index = 0; index < asnwer.length; index++) {
        const element = asnwer[index];

        if (element.choice == data) return true;
      }
    } catch (error) {
      console.log("Error", error);
    }
    return false;
  };
  const questionlist = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Assignment_Question,
          variables: {
            assignment_id: assignment_id,
            student_assignment_id: student_assignment_id,
          },
        })
        .then(
          (response) => {
            setisloading(false);
            if (
              response.data.getAssignmentQuestion === undefined ||
              response.data.getAssignmentQuestion.length <= 0
            ) {
              showNotificationMsz(`no question present in assigment`, "danger");
              return;
            }
            let temp = [];
            response.data.getAssignmentQuestion.map((data) => {
              data["answer"] = "";
              data["answerdocument"] = {};
              if (data.question_type === "Text_Answer") {
                if (blankValidator(data.assignment_submission)) {
                  data["answer"] = data.assignment_submission.answer;
                }
              } else {
                if (blankValidator(data.assignment_submission)) {
                  let choiceArray = [];
                  let array = data.assignment_submission.answer.split("&&");
                  array.map((data) => {
                    choiceArray.push({ choice: data });
                  });

                  data["answer"] = choiceArray;
                }
              }
              data["answerdocument"] = data?.assignment_submission?.document;

              temp.push(data);
            });

            setquestioarr(temp);
            if (
              response.data.getAssignmentQuestion !== undefined &&
              response.data.getAssignmentQuestion.length > 0
            ) {
              setCurrentQuestion(temp[0]);
              setquestionindex(0);
              if (temp[0].question_type === "Text_Answer") {
                setValue(
                  RichTextEditor.createValueFromString(
                    blankValidator(temp[0]["answer"]) ? temp[0]["answer"] : "",
                    "html"
                  )
                );
              } else {
                getOptionForMultplease(temp[0].id, temp[0].answer);
              }
              setanswerimage(temp[0]["answerdocument"]);
            }
          },

          (err) => {
            setisloading(false);
            console.log("exam then err", err);
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("exam catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("exam t and c error", error);
    }
  };

  const previous = () => {
    try {
      let temp = questionindex - 1;
      if (temp < 0) {
        return;
      }

      setCurrentQuestion(questioarr[temp]);

      if (questioarr[temp].question_type === "Text_Answer") {
        setValue(
          RichTextEditor.createValueFromString(
            blankValidator(questioarr[temp].answer)
              ? questioarr[temp].answer
              : "",
            "html"
          )
        );
      } else {
        getOptionForMultplease(questioarr[temp].id, questioarr[temp].answer);
      }
      setanswerimage(questioarr[temp].answerdocument);
      setquestionindex(temp);
    } catch (error) {
      console.log("error", error);
    }
  };
  const submitAndNext = async () => {
    try {
      await submitQuestion(questionindex);
      setopendialogConfimation(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const next = async () => {
    try {
      let temp = questionindex + 1;
      submitQuestion(questionindex);
      if (temp >= questioarr.length) {
        showNotificationMsz(
          `All question are attempt , Please submit your assigment for review`,
          "info"
        );

        return;
      }
      setCurrentQuestion(questioarr[temp]);
      if (questioarr[temp].question_type === "Text_Answer") {
        setValue(
          RichTextEditor.createValueFromString(
            blankValidator(questioarr[temp].answer)
              ? questioarr[temp].answer
              : "",
            "html"
          )
        );
      } else {
        getOptionForMultplease(questioarr[temp].id, questioarr[temp].answer);
      }
      setanswerimage(questioarr[temp].answerdocument);
      setquestionindex(temp);
    } catch (error) {
      console.log("error", error);
    }
  };

  const submitQuestion = async (currentIndex) => {
    let currentData = questioarr[currentIndex];

    let qid = parseInt(currentQuestion.id);
    let temp = [];
    if (currentData.question_type === "Text_Answer") {
      let answerforCurrentQuestion = value.toString("html");

      if (
        answerforCurrentQuestion != "<p><br></p>" &&
        answerforCurrentQuestion != ""
      ) {
        questioarr[currentIndex]["answer"] = answerforCurrentQuestion;
        temp.push({
          choice: answerforCurrentQuestion,
        });
      }
    } else {
      for (let index = 0; index < choiceArray.length; index++) {
        const element = choiceArray[index];
        if (element.isselected == true)
          temp.push({
            choice: element.choice,
          });
      }

      if (temp.length > 0) questioarr[currentIndex]["answer"] = temp;
    }

    if (temp.length > 0)
      questioarr[currentIndex]["answerdocument"] = answerimage;

    if (temp.length <= 0) {
      // not need to submit answer on server as data is not avilable
      questioarr[currentIndex]["answer"] = undefined;

      setquestioarr([...questioarr]);
      return;
    }

    setquestioarr([...questioarr]);
    try {
      setisloading(true);
      Student_Question_Submission({
        variables: {
          student_assignment_id,
          assignment_question_id: qid,
          assignment_id: assignment_id,
          user_id: userId,
          answers: temp,
          photo:
            answerimage === undefined && answerimage === null
              ? {}
              : answerimage,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.addStudentSubmission.message,
            "success"
          );
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <Grid className="grid_main_div mt-5 ">
                <Grid item md={8}>
                  <div className="bg-white mb-2">
                    <Grid className="grid_main_div bg-white pt-2 justify-content-between">
                      <Grid item md={5} classname="pl-3">
                        <div className=" font-weight-bold pl-4">
                          Assignment Name{" "}
                          {assignmentname === undefined ||
                          assignmentname === null ? (
                            ""
                          ) : (
                            <div className="text-info">
                              {" "}
                              {CapitalizeFirstFn(assignmentname)}
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
                      >
                        <div className="text-info font-weight-bold">
                          {questioarr.length}
                        </div>
                        <div className=" font-weight-bold assignemt_Portion_font">
                          Total Questions{" "}
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
                      >
                        <div className="text-info font-weight-bold">
                          {
                            questioarr.filter((data) => {
                              return blankValidator(data["answer"]);
                            }).length
                          }
                        </div>
                        <div className=" font-weight-bold assignemt_Portion_font">
                          Question Attempted
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
                      >
                        <div className="text-info font-weight-bold">
                          {questioarr.length -
                            questioarr.filter((data) => {
                              return blankValidator(data["answer"]);
                            }).length}
                        </div>
                        <div className=" font-weight-bold assignemt_Portion_font">
                          Remaining Questions
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        <div className="main_button_div mb-3 heightbtn text-center">
                          <Button
                            variant="contained"
                            className="button_enquiry_details button_login_decoration "
                            onClick={() => {
                              setopendialogConfimation(true);
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>

                    <div className="card_margin_both_side">
                      <Card>
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div className="mt-2 mb-2">
                              <label className="exampleInputEmail1 text-primary font-weight-bold">
                                <span>Section : </span>
                                <span className="text-info ml-1">
                                  {CapitalizeFirstFn(
                                    currentQuestion?.question_section?.name
                                  )}
                                </span>
                              </label>
                              <div className="exampleInputEmail1 text-primary font-weight-bold">
                                <span>Instructions : </span>
                                <span className="text-info ml-1">
                                  {CapitalizeFirstFn(
                                    currentQuestion?.question_section
                                      ?.description
                                  )}
                                </span>
                              </div>
                              {currentQuestion.question_type ===
                              "Text_Answer" ? (
                                <div>
                                  {" "}
                                  <Grid className="grid_main_div mt-2 mb-3">
                                    <Grid item md={10}>
                                      {currentQuestion.description === null ||
                                      currentQuestion.description === "" ||
                                      currentQuestion.description ===
                                        undefined ? (
                                        ""
                                      ) : (
                                        <span className="d-flex">
                                          <span className="font-weight-bold mr-1">
                                            Q.{currentQuestion.question_number})
                                          </span>{" "}
                                          <span className="pl-2">
                                            {parse.default(
                                              currentQuestion.description
                                            )}
                                          </span>
                                        </span>
                                      )}
                                    </Grid>
                                    <Grid item md={2}>
                                      {!blankValidator(currentQuestion.marks) ||
                                      currentQuestion.marks === -1 ? (
                                        ""
                                      ) : (
                                        <p className="text-primary font-weight-bold text-right">
                                          {currentQuestion.marks + " "} Marks{" "}
                                        </p>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {blankValidator(currentQuestion.document) && (
                                    <Grid className="grid_main_div mt-2 mb-3">
                                      <Grid item md={12}>
                                        <div className="inputMarginright">
                                          {blankValidator(
                                            currentQuestion.document.path
                                          ) && (
                                            <div>
                                              <div className="image_download_font">
                                                <a
                                                  href={
                                                    Service.baseUrl +
                                                    "/" +
                                                    currentQuestion.document
                                                      .path
                                                  }
                                                  download
                                                  target="_blank"
                                                  className="mr-3"
                                                  rel="noreferrer"
                                                >
                                                  <i class="fa fa-download"></i>
                                                </a>
                                              </div>
                                              <img
                                                src={
                                                  Service.baseUrl +
                                                  "/" +
                                                  `${currentQuestion.document.path}`
                                                }
                                                className="student_photo_div"
                                                alt="profile_img"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  )}
                                  <div className="mt-1">
                                    <RichTextEditor
                                      value={value}
                                      onChange={(e) => {
                                        setValue(e);
                                      }}
                                    />
                                  </div>
                                  <div className="mt-3 mb-2 d-flex">
                                    <div
                                      class="input-group"
                                      style={{ width: "80%" }}
                                    >
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">
                                          <i class="fa fa-paperclip"></i>
                                        </span>
                                      </div>
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          class="custom-file-input"
                                          id="inputGroupFile01"
                                          onChange={(e) =>
                                            UploadDocumentHandler(e)
                                          }
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="inputGroupFile01"
                                        >
                                          {blankValidator(answerimage) &&
                                            blankValidator(answerimage.name) &&
                                            answerimage.name}
                                        </label>
                                      </div>
                                      {blankValidator(answerimage) &&
                                        blankValidator(answerimage.name) && (
                                          <Chip
                                            label={answerimage.name.substring(
                                              0,
                                              15
                                            )}
                                            onClick={() => {
                                              HandleImageDailogBox(answerimage);
                                            }}
                                            color="primary"
                                            variant="outlined"
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <Grid className="grid_main_div mt-2 mb-3">
                                    <Grid item md={10}>
                                      {currentQuestion.description === null ||
                                      currentQuestion.description === "" ||
                                      currentQuestion.description ===
                                        undefined ? (
                                        ""
                                      ) : (
                                        <span className="d-flex">
                                          <span className="font-weight-bold mr-1">
                                            Q.{currentQuestion.question_number})
                                          </span>
                                          <span className="pl-2">
                                            {parse.default(
                                              currentQuestion.description
                                            )}
                                          </span>
                                        </span>
                                      )}
                                    </Grid>
                                    <Grid item md={2}>
                                      {!blankValidator(currentQuestion.marks) ||
                                      currentQuestion.marks === -1 ? (
                                        ""
                                      ) : (
                                        <p className="text-primary font-weight-bold text-right">
                                          {currentQuestion.marks + " "} Marks{" "}
                                        </p>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {blankValidator(currentQuestion.document) && (
                                    <Grid className="grid_main_div mt-2 mb-3">
                                      <Grid item md={12}>
                                        <div className="inputMarginright">
                                          {blankValidator(
                                            currentQuestion.document.path
                                          ) && (
                                            <div>
                                              <div className="image_download_font">
                                                <a
                                                  href={
                                                    Service.baseUrl +
                                                    "/" +
                                                    currentQuestion.document
                                                      .path
                                                  }
                                                  download
                                                  target="_blank"
                                                  className="mr-3"
                                                  rel="noreferrer"
                                                >
                                                  <i class="fa fa-download"></i>
                                                </a>
                                              </div>
                                              <img
                                                src={
                                                  Service.baseUrl +
                                                  "/" +
                                                  `${currentQuestion.document.path}`
                                                }
                                                className="student_photo_div"
                                                alt="profile_img"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {choiceArray.map((item, index) => (
                                    <Card
                                      className={
                                        item.isselected === true
                                          ? "bg-primary p-1 m-1"
                                          : "bg-light p-1 m-1"
                                      }
                                    >
                                      <div className="d-flex">
                                        <Checkbox
                                          checked={item.isselected}
                                          onClick={(e) => {
                                            try {
                                              if (
                                                currentQuestion.question_type ===
                                                "Multple_choice_single_answer"
                                              ) {
                                                if (e.target.checked == false) {
                                                  choiceArray[index][
                                                    "isselected"
                                                  ] = e.target.checked;
                                                  setChoiceArray([
                                                    ...choiceArray,
                                                  ]);
                                                  return;
                                                }

                                                for (
                                                  let i = 0;
                                                  i < choiceArray.length;
                                                  i++
                                                ) {
                                                  const element =
                                                    choiceArray[i];
                                                  if (
                                                    element.isselected === true
                                                  ) {
                                                    alert(
                                                      "only one answer can be seleted "
                                                    );
                                                    return;
                                                  }
                                                }
                                                choiceArray[index][
                                                  "isselected"
                                                ] = e.target.checked;
                                                setChoiceArray([
                                                  ...choiceArray,
                                                ]);
                                              } else {
                                                choiceArray[index][
                                                  "isselected"
                                                ] = e.target.checked;
                                                let temp = [];
                                                choiceArray.map((item) => {
                                                  temp.push(item);
                                                });
                                                setChoiceArray(temp);
                                              }
                                            } catch (error) {
                                              console.log("error ", error);
                                            }
                                          }}
                                        />
                                        <div className="pt-2">
                                          {item.choice}
                                        </div>
                                      </div>
                                    </Card>
                                  ))}
                                </div>
                              )}

                              <Grid className="grid_main_div mt-2 mb-3">
                                <Grid item md={6}>
                                  <Button
                                    variant="contained"
                                    className="mr-2"
                                    onClick={() => previous()}
                                  >
                                    Reset
                                  </Button>
                                </Grid>
                                <Grid item md={6}>
                                  <div className="main_button_div mb-3">
                                    {questionindex <= 0 ? (
                                      ""
                                    ) : (
                                      <Button
                                        variant="contained"
                                        //className="add_new_query_btn button_login_decoration  mr-2"
                                        className="button_enquiry_details bg-warning button_login_decoration mr-1"
                                        onClick={() => previous()}
                                      >
                                        <i class="fa fa-chevron-left mr-2"></i>{" "}
                                        Previous
                                      </Button>
                                    )}
                                    {questionindex + 1 < questioarr.length ? (
                                      <Button
                                        variant="contained"
                                        className="button_enquiry_details bg-warning button_login_decoration"
                                        onClick={() => {
                                          next();
                                        }}
                                      >
                                        Next
                                        <i class="fa fa-chevron-right ml-2"></i>
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        className="button_enquiry_details bg-warning button_login_decoration"
                                        onClick={() => {
                                          submitAndNext();
                                        }}
                                      >
                                        Submit
                                        <i class="fa fa-chevron-right ml-2"></i>
                                      </Button>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="bg-white mb-1 ml-2">
                    <div className="enquiry_heading">
                      <div className="mt-1 ml-2 Heading_bold">
                        All Questions
                      </div>
                    </div>
                    <div className="card_margin_both_side">
                      <Grid className="grid_main_div mb-1">
                        <Grid item md={12}>
                          {questioarr.map((item, index) => (
                            <Card
                              className="inputMarginright m-2 p-1 onHover_cursor"
                              style={{
                                backgroundColor:
                                  item["answer"] !== undefined &&
                                  item["answer"] !== ""
                                    ? "#0070bb "
                                    : "",
                                color:
                                  item["answer"] !== undefined &&
                                  item["answer"] !== ""
                                    ? "white "
                                    : "",
                              }}
                              id={
                                questionindex === index
                                  ? "Selected_Card_border"
                                  : "Card_border"
                              }
                              onClick={() => {
                                select(item, index);
                              }}
                            >
                              <div className="enquiry_heading">
                                <div>
                                  <div className="font_style_question pt-2">
                                    {parse.default(item.description)}
                                  </div>
                                </div>
                                <div className="text-white icon_fonta">
                                  {item["answer"] !== undefined &&
                                  item["answer"] !== "" ? (
                                    <span>
                                      <i class="fa fa-check-circle"></i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Card>
                          ))}

                          {/* <Card className="inputMarginright bg-primary m-2 p-2">
                            <div className="enquiry_heading">
                              <div className=" text-white">Question</div>
                              <div className="text-white icon_fonta">
                                <i class="fa fa-check-circle"></i>
                              </div>
                            </div>
                          </Card> */}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>

          <Grid className="grid_main_div">
            <Grid
              item
              md={12}
              className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
            >
              <div className="text-info font-weight-bold">
                {questioarr.length -
                  questioarr.filter((data) => {
                    return blankValidator(data["answer"]);
                  }).length}
              </div>
              <div className=" font-weight-bold">Questions is Pending</div>
            </Grid>
          </Grid>

          <div className="pl-5 pr-5 mt-4">
            Are you sure want to Submit the Assignment?
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={submitAssignmentForReview}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={OpenPhotoImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenPhotoImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + impagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenPhotoImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + impagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AssignmentSubmission));
