import React from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Radio from "@material-ui/core/Radio";
import Holiday from "../../Common/Holiday/Holiday.jsx";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(
  invoiceamount,
  discount,
  tax,
  totalamount,
  status,
  details
) {
  return { invoiceamount, discount, tax, totalamount, status, details };
}

const rows = [
  createData("20000", "500", "800", "20800", "Pending", "open"),
  createData("20000", "500", "800", "20800", "Upcoming", "open"),
  createData("20000", "500", "800", "20800", "Already Paid", "open"),
  createData("20000", "500", "800", "20800", "Pending", "open"),
  createData("20000", "500", "800", "20800", "Upcoming", "open"),
  createData("20000", "500", "800", "20800", "Pending", "open"),
  createData("20000", "500", "800", "20800", "Upcoming", "open"),
  createData("20000", "500", "800", "20800", "Pending", "open"),
  createData("20000", "500", "800", "20800", "Already Paid", "open"),
  createData("20000", "500", "800", "20800", "Already Paid", "open"),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
function FeeAdminView(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">Manage Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={3}>
                            <div className="inputMarginright1 mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Student Id"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright1 mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Student Name"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright1 mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Invoice Title</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright1 mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Status</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            <div className="inputMarginright1 mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Start Date"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright1 mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="End Date"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="btn_add mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration"
                              >
                                Search
                              </Button>
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="mt-3 mb-1 Heading_bold">Invoice</div>

                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin mt-2 mb-2">
                            <div className="table_foramtitng">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell className="table_header"></StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Invoice Amount
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Discount
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Tax
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Total Amount
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Status
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Details
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      ></StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? rows.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : rows
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell padding="checkbox">
                                          <Radio
                                          //   checked=""
                                          //   inputProps={{ 'aria-labelledby': labelId }}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          
                                          scope="row"
                                          align="left"
                                        >
                                          {row.invoiceamount}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.discount}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.tax}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.totalamount}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.status === "Pending" && (
                                            <p
                                              className="Pending"
                                              variant="contained"
                                            >
                                              {row.status}
                                            </p>
                                          )}
                                          {row.status === "Upcoming" && (
                                            <p
                                              className="Upcoming"
                                              variant="contained"
                                            >
                                              {row.status}
                                            </p>
                                          )}
                                          {row.status === "Already Paid" && (
                                            <p
                                              className="AlreadyPaid"
                                              variant="contained"
                                            >
                                              {row.status}
                                            </p>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="row_details_color"
                                        >
                                          {row.details}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="row_details_color"
                                        >
                                          <Button
                                            className="add_new_query_btn button_login_decoration"
                                            variant="contained"
                                          >
                                            Save Scheduler
                                          </Button>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={rows.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                                <Button
                                  className="add_new_query_btn button_login_decoration ml-3 mb-1"
                                  variant="contained"
                                >
                                  Edit
                                </Button>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default (HOC1(FeeAdminView));
