import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getData } from "../../../api";
import Holiday from "../../Common/Holiday/Holiday";
import HocS from "../../HocS";
import * as Constants from "../../../action/appAction";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import * as Service from "../../../services/index";
import Loder from "../../Loder/Loder";
import { useApolloClient } from "@apollo/client";
import { getFullName } from "../../../utils/Validation";
import "./ParentMange.css";

const ParentMange = (props) => {
  let institution_Name = props.data.institution_name;
  const client = useApolloClient();
  const { data, navigate } = props;
  const [isloading, setisloading] = useState(false);
  const [ParentHomeData, setParentHomeData] = useState([]);
  useEffect(() => {
    getPraentStudentData();
  }, []);

  const getPraentStudentData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.getStudentListByParentID,
          variables: {
            institution_id: data.institution_id,
            father_id: data.userid,
            status: "ACTIVE",
          },
        })
        .then((response) => {
          if (response.data.getStudentListByParentID.length > 0) {
            let data = response.data.getStudentListByParentID;
            let finalData = [];
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              if (element.student) {
                for (let index = 0; index < element.student.length; index++) {
                  let element1 = element.student[index];
                  element1["name"] = element.name;
                  finalData.push(element1);
                }
              }
            }
            setParentHomeData(finalData);
          }

          setisloading(false);
        })
        .catch((error) => {
          console.log("exam catch error", error);
        });
    } catch (error) {
      console.log("exam t and c error", error);
    }
  };

  const openStudentHandle = (row) => {
    let temp = { studentData: row };
    props.dispatch({
      type: Constants.USER_INFO,
      state: temp,
      key: Constants.TOKEN,
    });
    navigate("parentHomePage");
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_Name} isRemove={true} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-4 Heading_bold">
                  Select student class
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <div className="parentMangetbaleHeader">
                        <div>Student Name</div>
                        <div>Calss</div>
                        <div>Section</div>
                        <div className="d-flex"></div>
                      </div>
                      {/* ==========map section======= */}
                      {ParentHomeData.map((row, index) => (
                        <Card
                          className="cardvisible_border mb-2 mt-3"
                          key={index}
                        >
                          <div className="card_admissiondetails_height">
                            <div className="textfiled_margin">
                              <div className="card_content_instition">
                                <div>{getFullName(row.name)}</div>
                                <div>{row?.section?.grade?.gradeName}</div>
                                <div>{row?.section?.name}</div>
                                <div className="d-flex">
                                  <span
                                    className="mr-3 icon_pencil_color manage_edit_cursor"
                                    onClick={() => openStudentHandle(row)}
                                  >
                                    Open
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HocS(ParentMange));
