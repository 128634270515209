import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./Exam.css";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import {
  getFullName,
  blankValidator,
  CapitalizeFirstFn,
  showNotificationMsz,
  timeStampToDatedashDayStart,
  getValue,
} from "../../../utils/Validation";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
// import Feedback from "react-bootstrap/esm/Feedback";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ExamEnterMarks(props) {
  const { examName, data } = props.location.state;
  //section id
  let section_id = props.location.state.section;

  //time table id
  let exam_timetable_id = props.location.state.data.id;

  const client = useApolloClient();

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [studentListArr, setstudentListArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  // const [ExamMarks, setExamMarks] = useState("");

  // mutation create data api
  const [Add_Student_Exam_Result, {}] = useMutation(
    Service.Add_Student_Exam_Result
  );
  const [Add_Student_Exam_Result_Draft, {}] = useMutation(
    Service.Add_Student_Exam_Result_Draft
  );

  useEffect(() => {
    getstudentList();
  }, [client, section_id]);
  const getstudentList = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Studentlist_For_Exam,
          variables: {
            section_id,
            exam_timetable_id,
          },
        })
        .then((response) => {
          setisloading(false);
          const studentArr = response.data.getStudentlistForExam;

          //   let temp = [];
          for (let index = 0; index < studentArr.length; index++) {
            const element = studentArr[index];
            element["index"] = index;
            if (getValue(element?.exam_result?.status) === "PRESENT") {
              element["isSelected"] = true;
              element["ExamMarks"] = element.exam_result.marks;
              element["feedback"] = element.exam_result.feedback;
            } else {
              element["isSelected"] = false;
              element["ExamMarks"] = 0;
              element["feedback"] = "";
            }
          }
          setstudentListArr(studentArr);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const checkBoxHandler = (val, index) => {
    studentListArr[index].isSelected = !studentListArr[index].isSelected;
    setstudentListArr([...studentListArr]);
  };

  const ExamMArksEnter = (val, index) => {
    studentListArr[index].ExamMarks = val;
    setstudentListArr([...studentListArr]);
  };
  const FeedbackHandler = (val, index) => {
    studentListArr[index].feedback = val;
    setstudentListArr([...studentListArr]);
    // const temp = [];
    // studentListArr.map((item) => {
    //   temp.push(item);
    // });
    //setstudentListArr(temp);
  };
  const PublishMarks = () => {
    let results = [];

    studentListArr.map((item) => {
      if (item.isSelected === true) {
        results.push({
          student_id: item.id,
          marks: parseInt(item.ExamMarks),
          status: "true",
          feedback: item.feedback,
        });
      }
    });
    try {
      setisloading(true);
      Add_Student_Exam_Result({
        variables: {
          exam_timetable_id,
          results,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz("Exam Result Add Successfully", "success");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const SaveDraft = () => {
    let results = [];

    studentListArr.map((item) => {
      if (item.isSelected === true) {
        results.push({
          student_id: item.id,
          marks: parseInt(item.ExamMarks),
          status: "true",
          feedback: item.feedback,
        });
      }
    });
    try {
      setisloading(true);
      Add_Student_Exam_Result_Draft({
        variables: {
          exam_timetable_id,
          results,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz("Exam result drafted successfully", "success");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Publish Marks</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="examentermark_flex">
                          <div>
                            <div>
                              <label className="Heading_bold">
                                Exam Name :{" "}
                              </label>{" "}
                              <span>{CapitalizeFirstFn(examName)}</span>
                            </div>

                            <div>
                              <label className="Heading_bold">Class - </label>{" "}
                              <span>
                                {data?.exam?.section?.grade?.gradeName}
                              </span>{" "}
                              -<span>{data?.exam?.section?.name}</span>
                            </div>
                            <div>
                              <label className="Heading_bold">Subject - </label>{" "}
                              <span>{data?.subject?.name}</span>
                            </div>
                          </div>
                          <div>
                            <label className="Heading_bold">Exam Date : </label>{" "}
                            <span>
                              {timeStampToDatedashDayStart(
                                data?.start_calendar?.datetime
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Roll Number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Exam Attendence Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Comments
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align=""
                                    className="table_header"
                                  >
                                    Marks
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? studentListArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : studentListArr
                                ).map((row, index) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.roll_number) ? (
                                        ""
                                      ) : (
                                        <span>{row.roll_number}</span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left">
                                      {!blankValidator(row.student_profile) ? (
                                        ""
                                      ) : (
                                        <span>
                                          {getFullName(
                                            row.student_profile.name
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <CheckBoxComponent
                                        checked={row.isSelected}
                                        onChange={() =>
                                          checkBoxHandler(row.id, row.index)
                                        }
                                        cssClass="e-custom"
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      style={{ width: "150px" }}
                                    >
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Comment"
                                        autoComplete="off"
                                        value={row.feedback}
                                        onChange={(e) =>
                                          FeedbackHandler(
                                            e.target.value,
                                            row.index
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="right"
                                      style={{ width: "150px" }}
                                    >
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="marks"
                                        autoComplete="off"
                                        value={row.ExamMarks}
                                        onChange={(e) => {
                                          ExamMArksEnter(
                                            e.target.value,
                                            row.index
                                          );
                                        }}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={studentListArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>

                          <Grid className="grid_main_div mt-2">
                            <Grid item md={4}></Grid>

                            <Grid item md={8}>
                              <div className="d-flex float-right">
                                <div className="mr-3">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn button_login_decoration button_width"
                                    onClick={PublishMarks}
                                  >
                                    Publish
                                  </Button>
                                </div>
                                <div className="mr-3">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn button_login_decoration button_width"
                                    onClick={SaveDraft}
                                  >
                                    Save Draft
                                  </Button>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(ExamEnterMarks)));
