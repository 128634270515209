import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import {
  blankValidator,
  changeTimeFormat,
  showNotificationMsz,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function CreateUpdateTimeTable(props) {
  //name instituition
  let Organization_Name = props.data.institution_name;

  //template id
  let timetable_template_id = parseInt(props.location.state.Template_id);

  //institution id
  let institution_id = props.data.institution_id;

  //template name
  let TemplateName = props.location.state.name;
  //add time Table
  const [Add_Period_Template, {}] = useMutation(Service.Add_Period_Template);

  //updata time table api
  const [Edit_Periods_Template, {}] = useMutation(
    Service.Edit_Periods_Template
  );
  //delete time table
  const [Delete_Periods_Template, {}] = useMutation(
    Service.Delete_Periods_Template
  );
  const client = useApolloClient();

  const [addMangeopen, setaddMangeopen] = useState(false);

  const [period, setperiod] = useState("");
  const [starttime, setstarttime] = useState(new Date());
  const [endtime, setendtime] = useState(new Date());

  const [periodnameerror, setperiodnameerror] = useState(false);
  const [starttimeerror, setstarttimeerror] = useState(false);
  const [endtimeerror, setendtimeerror] = useState(false);

  const [openedittimetable, setopenedittimetable] = useState(false);
  const [peroidedit, setPeroidedit] = useState("");
  const [starttimeedit, setStarttimeedit] = useState(new Date());
  const [endtimeedit, setEndtimeedit] = useState(new Date());
  const [timetableID, settimetableID] = useState("");
  const [deleteid, setdeleteid] = useState("");
  const [deletePeriodname, setdeletePeriodname] = useState("");
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [startmatchendError, setstartmatchendError] = useState(false);
  const handlestarttime = (date) => {
    setstarttime(date);
  };
  const handleendtime = (date) => {
    setstartmatchendError(false);
    setendtime(date);
  };
  const [manageperiod, setmanageperiod] = useState([]);

  const createtimetable = () => {
    if (!blankValidator(period)) {
      setperiodnameerror(true);
      return;
    }
    if (
      starttime.toLocaleTimeString("en-GB") ===
      new Date().toLocaleTimeString("en-GB")
    ) {
      setstarttimeerror(true);
      return;
    }
    if (
      endtime.toLocaleTimeString("en-GB") ===
      new Date().toLocaleTimeString("en-GB")
    ) {
      setendtimeerror(true);
      return;
    }
    if (
      starttime.toLocaleTimeString("en-GB") ===
      endtime.toLocaleTimeString("en-GB")
    ) {
      setstartmatchendError(true);
      return;
    }
    setisloading(true);

    try {
      Add_Period_Template({
        variables: {
          timetable_template_id,
          period_name: period,
          start_time: starttime.toLocaleTimeString("en-GB"),
          end_time: endtime.toLocaleTimeString("en-GB"),
        },
      })
        .then((response) => {
          getTimeTableData();
          setisloading(false);
          showNotificationMsz(
            response.data.addPeriodTemplate.message,
            "success"
          );

          setperiod("");
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleaddMangeopen = () => {
    setaddMangeopen(true);
  };
  const closeaddMangeopen = () => {
    setaddMangeopen(false);
  };

  const getTimeTableData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_PeroidTimeTable,
          variables: {
            timetable_template_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setaddMangeopen(false);
          setmanageperiod(response.data.getPeriodTemplate);
        })
        .catch((e) => {
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  useEffect(() => {
    getTimeTableData();
  }, [client, timetable_template_id]);

  const opendailogedittimetable = (item) => {
    setPeroidedit(item.period_name);
    setStarttimeedit(new Date("2022-2-2 " + item.start_time));
    setEndtimeedit(new Date("2022-2-2 " + item.end_time));
    settimetableID(parseInt(item.id));
    setopenedittimetable(true);
  };

  const DeleteDailogBox = (data) => {
    setdeleteid(data.id);
    setdeletePeriodname(data.period_name);
    setopendialogConfimation(true);
  };
  const deletetimeTabletemplate = (ID) => {
    setisloading(true);
    let id = parseInt(ID);
    try {
      Delete_Periods_Template({
        variables: { id },
      }).then(
        (response) => {
          if (response.data.deletePeriodTemplate.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(
            response.data.deletePeriodTemplate.message,
            "success"
          );

          setopendialogConfimation(false);
          getTimeTableData();
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const updateTimeTable = (timetableID) => {
    setisloading(true);
    let id = parseInt(timetableID);
    try {
      Edit_Periods_Template({
        variables: {
          id,
          timetable_template_id,
          institution_id,
          period_name: peroidedit,
          start_time: starttimeedit.toLocaleTimeString("en-GB"),
          end_time: endtimeedit.toLocaleTimeString("en-GB"),
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.updatePeriodTemplate.message,
            "success"
          );

          setisloading(false);
          setopenedittimetable(false);
          getTimeTableData();
        },
        (err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Update TimeTable template
                  </div>
                  {/* <div className="mt-2 mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_width  button_login_decoration"
                      onClick={() =>props.navigate("/manageTimeTable")}
                    >
                      Manage TimeTable
                    </Button>
                  </div> */}
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="text_filed_heading">
                              Timetable template name
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Template Name"
                                autoComplete="off"
                                value={TemplateName}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="card_admissiondetails_height mt-1">
                  <div className="textfiled_margin pb-2">
                    <Card className="">
                      <div className="card_margin_both_side">
                        {/* <div
                          className="d-flex"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div></div>
                          <div className="float-right mt-3 mb-3">
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Create Period
                            </span>
                          </div>
                        </div> */}

                        {addMangeopen === false ? (
                          <div
                            className="mt-1 mb-3"
                            onClick={handleaddMangeopen}
                          >
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Create Period
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="mt-2 mb-2 expand_color_drop cardvisible_border">
                              <div
                                className="text-right mr-3"
                                onClick={closeaddMangeopen}
                              >
                                <span className="iconstoaddfee">
                                  <i class="fa fa-times cursor"></i>
                                </span>
                              </div>
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="text_filed_heading mb-2">
                                    Create Period
                                  </div>
                                  <div>
                                    <Grid className="grid_main_div">
                                      <Grid item md={4}>
                                        <div className="text_filed_heading">
                                          Period Name
                                        </div>
                                        <div className="inputMarginright mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Period Name"
                                            autoComplete="off"
                                            value={period}
                                            onChange={(e) => {
                                              setperiodnameerror(false);
                                              setperiod(e.target.value);
                                            }}
                                          />
                                          {periodnameerror && (
                                            <span className="error_message_color">
                                              Enter the period name
                                            </span>
                                          )}
                                        </div>
                                      </Grid>

                                      <Grid item md={4}>
                                        <div className="text_filed_heading">
                                          Start Time
                                        </div>
                                        <div className="inputMarginright mt-1 ">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardTimePicker
                                              ampm={true}
                                              views={["hours", "minutes"]}
                                              format="HH:mm"
                                              margin="normal"
                                              id="time-picker"
                                              inputVariant="outlined"
                                              variant="outlined"
                                              value={starttime}
                                              onChange={handlestarttime}
                                              KeyboardButtonProps={{
                                                "aria-label": "change time",
                                              }}
                                            />
                                            {starttimeerror && (
                                              <span className="error_message_color">
                                                Enter the Start time
                                              </span>
                                            )}
                                          </MuiPickersUtilsProvider>
                                        </div>
                                      </Grid>
                                      <Grid item md={4}>
                                        <div className="text_filed_heading">
                                          End Time
                                        </div>
                                        <div className="mt-1">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardTimePicker
                                              ampm={true}
                                              views={["hours", "minutes"]}
                                              format="HH:mm"
                                              margin="normal"
                                              id="time-picker"
                                              inputVariant="outlined"
                                              variant="outlined"
                                              value={endtime}
                                              onChange={handleendtime}
                                              KeyboardButtonProps={{
                                                "aria-label": "change time",
                                              }}
                                            />
                                            {endtimeerror && (
                                              <span className="error_message_color">
                                                Enter the End Time
                                              </span>
                                            )}
                                            {startmatchendError && (
                                              <span className="error_message_color">
                                                End Time not be same as Start
                                                Time
                                              </span>
                                            )}
                                          </MuiPickersUtilsProvider>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="mb-1">
                                    <Grid className="grid_main_div">
                                      <Grid item md={10}></Grid>
                                      <Grid item md={2}>
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn button_width  button_login_decoration"
                                          onClick={createtimetable}
                                        >
                                          Create
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}

                        <div className="mt-3 mb-2">
                          <h6>Periods</h6>
                        </div>
                        {manageperiod.map((item, index) => (
                          <Card className="cardvisible_border mb-2" key={index}>
                            <div className="card_admissiondetails_height">
                              <div className="textfiled_margin">
                                <div className="">
                                  <Grid className="grid_main_div">
                                    <Grid item md={3}>
                                      <div className=" mt-1 mb-1">
                                        <p>{item.period_name}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className=" mt-1 mb-1">
                                        <p>
                                          {changeTimeFormat(item.start_time)}
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className=" mt-1 mb-1">
                                        <p>{changeTimeFormat(item.end_time)}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={2}></Grid>
                                    <Grid item md={1}>
                                      <div className="d-flex pt-2">
                                        <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                          <i
                                            className="fa fa-pencil"
                                            onClick={() => {
                                              opendailogedittimetable(item);
                                            }}
                                          ></i>
                                        </span>
                                        <span className="iconstoaddfee manage_edit_cursor ml-2">
                                          <i
                                            className="fa fa-trash"
                                            onClick={() =>
                                              DeleteDailogBox(item)
                                            }
                                          ></i>
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={openedittimetable}
        onClose={() => {
          setopenedittimetable(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
      >
        <div className="personal_dialog_main">
          <DialogTitle>
            Update Time Table
            <span
              className="float-right"
              onClick={() => {
                setopenedittimetable(false);
              }}
            >
              <i className="fa fa-times cursor" aria-hidden="true"></i>{" "}
            </span>
            <hr />
          </DialogTitle>

          <div className="textfiled_margin">
            <Grid className="grid_main_div mt-1">
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label for="exampleInputEmail1">Period Name</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    value={peroidedit}
                    onChange={(e) => {
                      setPeroidedit(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="">
                  <label for="exampleInputEmail1">Start Time</label>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        ampm={false}
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        margin="normal"
                        id="time-picker"
                        inputVariant="outlined"
                        variant="outlined"
                        value={starttimeedit}
                        // value={new Date()}
                        onChange={(date) => {
                          setStarttimeedit(date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid className="grid_main_div mt-1">
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label for="exampleInputEmail1">End Time</label>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        ampm={false}
                        views={["hours", "minutes"]}
                        format="HH:mm"
                        margin="normal"
                        id="time-picker"
                        inputVariant="outlined"
                        variant="outlined"
                        value={endtimeedit}
                        //value={new Date()}
                        onChange={(date) => {
                          setEndtimeedit(date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
              <Grid item md={2}>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration button_width mt-4"
                  onClick={() => updateTimeTable(timetableID)}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Period : {deletePeriodname}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deletetimeTabletemplate(deleteid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(CreateUpdateTimeTable));
