import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
//-----------redux---------------
import { connect } from "react-redux";

import * as Constants from "../../action/appAction";
import { getData } from "../../api/index";
//-----------api calls---------------
import * as Service from "../../services/index";
import { invoiceStatusArr } from "../../utils/LocalData";
import {
  blankValidator,
  CapitalizeFirstFn,
  dateToDatedashtoDDMMYY,
  getFullName,
  getValue,
} from "../../utils/Validation";
import Holiday from "../Common/Holiday/Holiday.jsx";
import HOC1 from "../Hoc1.jsx";
import Loder from "../Loder/Loder.jsx";
import "../Admin/FeeModule/Feemodule.css";

const StudentInvoice = (props) => {
  let classId;
  let sectionId;
  let studentID_temp;
  let invoiceName;

  //   if (props.from === "report") {
  //     classId = props.location.state.json.studentClassId;
  //     sectionId = props.location.state.json.SectionId;
  //     sectionId = props.location.state.json.SectionId;
  //     studentID_temp = props.location.state.json.userId;
  //     invoiceName = props.location.state.json.invoiceName;
  //   } else {
  //     classId = props.location.state.classId;
  //     sectionId = props.location.state.sectionId;
  //     invoiceName = props.location.state.invoiceName;
  //   }

  //------------------global id---------------------
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );
  //--------------------api calls------------------
  const client = useApolloClient();
  const [sectionid, setsectionid] = useState(
    sectionId !== undefined ? sectionId : -1
  );

  const classes = useStyles();
  //--------------------local state------------------
  const [searchInvoice, setSearchInvoice] = useState(
    invoiceName == undefined ? "" : invoiceName
  );
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [studentInvoiceArr, setStudentInvoiceArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [StudentListArr, setStudentListArr] = useState([]);
  const [classID, setClassID] = useState(classId !== undefined ? classId : -1);
  const [studentID, setStudentID] = useState(
    studentID_temp !== undefined ? studentID_temp : -1
  );
  const [status, setstatus] = useState("DUE");

  useEffect(() => {
    statusHandler(status);
  }, [status]);
  useEffect(() => {
    selectSectionHandler(sectionid);
  }, [sectionid]);

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("arr try and catch", error);
    }
    classHandler(classID);
    //to get Class Data
  }, [classID]);

  const statusHandler = (status) => {
    if (status === "") {
      setClassID(-1);
      setsectionid(-1);
      setStudentID(-1);
      setStudentInvoiceArr([]);
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Invoice_For_Institute,
          variables: {
            institution_id,
            academic_session_id,
            status,
          },
        })
        .then(async (response) => {
          const studentInvoiceArrtemp = response.data.getInvoiceForInstitute;
          let temp = [];
          studentInvoiceArrtemp.map((item) => {
            item.student_invoices.map((item1) => {
              item1["name"] = item.name;
              item1["create_date"] = item.create_date;
              temp.push(item1);
            });
          });

          await setStudentInvoiceArr(temp);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("student list  catch err", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("student list  t and c error", error);
    }
  };
  const filterStudentArr = studentInvoiceArr.filter((event, index) => {
    console.log("event", event);
    return (
      (classID != -1 ? event.student.section.grade.id == classID : true) &&
      (sectionid != -1 ? event.student.section.id == sectionid : true) &&
      (studentID != -1 ? event.student_id == studentID : true) &&
      (searchInvoice != ""
        ? getValue(event.name)
            ?.toLowerCase()
            ?.indexOf(searchInvoice?.toLowerCase()) !== -1
        : true)
    );
  });

  const classHandler = (clId) => {
    if (isFirstTime) {
      setsectionid(-1);
      setStudentID(-1);
      setSectionArr([]);
      setStudentListArr([]);
    } else {
      setIsFirstTime(true);
    }

    if (parseInt(clId) == -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(clId),
          },
        })
        .then((response) => {
          setisloading(false);

          setSectionArr(response.data.getSectionAndGrade);
          // setSectionfromgrade(response.data.Sections)
          //setname("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectSectionHandler = (ID) => {
    let section_id = parseInt(ID);
    setStudentListArr([]);
    if (isFirstTime) {
      setStudentID(-1);
    }

    if (section_id === -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setStudentListArr(response.data.getStudentListBySectionId);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const studentHandler = (userId) => {
    try {
      let student_id = parseInt(userId);
      setStudentID(student_id);
    } catch (error) {
      console.log("student list  t and c error", error);
    }
  };
  const invoiceHandler = (invoiceId) => {
    if (invoiceId === -1) {
      // do something
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Teacher Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={2}>
                            <div className="inputMarginright mt-1">
                              {/* <label>Select Status</label> */}
                              <select
                                value={status}
                                className="form-control"
                                onChange={(e) => setstatus(e.target.value)}
                              >
                                <option value="">Select Status</option>
                                {invoiceStatusArr.map((item) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2} className="ml-1">
                            <div className="mt-1">
                              <input
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Seach by Teacher"
                                autoComplete="off"
                                value={searchInvoice}
                                onChange={(e) => {
                                  setSearchInvoice(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-3 mb-2 Heading_bold">Invoice</div>

                        <div className="card_admissiondetails_height">
                          <div className="table_foramtitng mt-2 mb-2">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Teacher Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Phone
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Amount
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Status
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Title
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Due Date
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                      onClick={() =>
                                        props.navigate(
                                          "/PtAdminInvoiceSummaryPage",
                                          {
                                            state: {
                                              id: "",
                                            },
                                          }
                                        )
                                      }
                                    >
                                      Action
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? filterStudentArr.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterStudentArr
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        align="left"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {getFullName(
                                          row?.student?.student_profile?.name
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <span>
                                          {
                                            row?.student?.section?.grade
                                              ?.gradeName
                                          }
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <span>
                                          {row?.student?.section?.name}
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.name) ? (
                                          ""
                                        ) : (
                                          <span>
                                            {CapitalizeFirstFn(row.name)}
                                          </span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {dateToDatedashtoDDMMYY(row.due_date)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.status)
                                          ? ""
                                          : row.status}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <div>
                                          <span
                                            className="row_details_color"
                                            onClick={() =>
                                              props.navigate(
                                                "/PtAdminInvoiceSummaryPage"
                                              )
                                            }
                                          >
                                            Open
                                          </span>
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterStudentArr.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentInvoice));
