import { useApolloClient } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import * as Service from "../../../services/index";
import {
  blankValidator,
  getFullName,
  getValue,
} from "../../../utils/Validation.js";
import Loder from "../../Loder/Loder.jsx";

const AssignedStudentList = (props) => {
  const { routeInfo } = props;
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(-1);
  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [stopid, setstopid] = useState(-1);

  const classes = useStyles();

  const [DropRouteArr, setDropRouteArr] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const client = useApolloClient();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getRouteStudent();
  }, [props]);
  const getRouteStudent = async () => {
    try {
      if (routeInfo) {
        setisloading(true);
        const { data } = await client.query({
          query: Service.Get_student_list_transport_route_id,
          variables: {
            transport_route_id: routeInfo.id,
            status: getValue(routeInfo?.rowdata?.route_type),
          },
        });

        const { getStudentListByTransportRouteId } = data;
        if (getStudentListByTransportRouteId) {
          let sortedArr = getStudentListByTransportRouteId.sort((a, b) => {
            return parseInt(a.roll_number) - parseInt(b.roll_number);
          });
          let tempArr = [];
          sortedArr.map((item) => {
            tempArr.push({
              isSelected: true,
              roll_number: item.roll_number,
              id: item.id,
              studentName: item?.student_profile?.name,
              status: item.status,
              section: item.section,
              transport_stop: item.transport_stop,
              transport_pickup: item.transport_pickup,
            });
          });
          setDropRouteArr(tempArr);
        }
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <Grid className="grid_main_div">
        <Grid item md={12}>
          <div className="enquirydetails_background mb-2">
            <div className="card_margin_both_side">
              <Card>
                <div className="p-3">
                  <div className="table_foramtitng mt-3 mb-3">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              align="left"
                              className="table_header"
                            >
                              Roll Number
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className="table_header"
                            >
                              Name
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className="table_header"
                            >
                              Class
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className="table_header"
                            >
                              Section
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className="table_header"
                            >
                              Stop Name
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? DropRouteArr.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : DropRouteArr
                          ).map((row) => (
                            <StyledTableRow>
                              <StyledTableCell align="left">
                                {!blankValidator(row.roll_number) ? (
                                  ""
                                ) : (
                                  <span>{row.roll_number}</span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="left">
                                <span>{getFullName(row.studentName)}</span>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {!blankValidator(row.section) ? (
                                  ""
                                ) : (
                                  <span>{row.section?.grade?.gradeName}</span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {!blankValidator(row.section) ? (
                                  ""
                                ) : (
                                  <span>{row.section.name}</span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {getValue(routeInfo.rowdata.route_type) ===
                                  "DROP" && row?.transport_stop?.stop_name}
                                {getValue(routeInfo.rowdata.route_type) ===
                                  "PICK" && row?.transport_pickup?.stop_name}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        true
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={DropRouteArr.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>

      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default AssignedStudentList;
