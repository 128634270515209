import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Hoc1 from "../../Hoc1";
import * as Service from "../../../services/index";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api";
import Holiday from "../../Common/Holiday/Holiday";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {
  dayPresentCounter,
  getFullName,
  isPresent,
  getMonthFormat,
  serverFormatDate,
  showNotificationMsz,
  getValue,
  CapitalizeFirstFn,
  dateToDatedashtoDDMMYY,
} from "../../../utils/Validation";
import Loder from "../../Loder/Loder";
import { useApolloClient } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const CreateInvoiceForDate = (props) => {
  console.log("props", props);
  const {
    sectionid,
    classID,
    endDateValue,
    startDateValue,
    classValue,
    sectionValue,
  } = props?.location?.state?.data;
  let Currency_code = props.manageInstitution.currency_code;
  const [totalWorkingDaysArr, setTotalWorkingDaysArr] = useState(0);
  const [day, setDay] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [studentMonthAttenArr, setStudentMonthAttenArr] = useState([]);
  const [searchMonth, setsearchMonth] = useState("");
  const [timeToShow, settimeToShow] = useState("");
  const classes = useStyles();
  const [isloading, setisloading] = useState(false);
  const pad = (n) => {
    return n < 10 ? "0" + n : n;
  };
  const client = useApolloClient();
  const [createInoviceDailog, setCreateInoviceDailog] = useState(false);
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [invoicetitleError, setinvoicetitleError] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [duedateError, setduedateError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setdescriptionError] = useState(false);
  const [totalamount, setTotalamount] = useState(0.0);
  const [totalamountError, settotalamountError] = useState(false);
  const [selectedTemplateId, setselectedTemplateId] = useState(-1);

  useEffect(() => {
    monthHandler();
  }, []);

  const monthHandler = (data) => {
    try {
      //   setPreFillMonth(data);
      setisloading(true);
      //   let newDate = new Date(data);
      //   let lastDayOfMonth = new Date(
      //     newDate.getFullYear(),
      //     newDate.getMonth() + 1,
      //     0
      //   ).getDate();
      //   settimeToShow(getMonthFormat(newDate));
      //   let dayArr = [];
      //   for (let index = 1; index <= lastDayOfMonth; index++) {
      //     dayArr.push({
      //       title: index,
      //       realDate: `${newDate.getFullYear()}-${pad(
      //         newDate.getMonth() + 1
      //       )}-${pad(index)}`,
      //     });
      //   }
      //   setDay(dayArr);
      //   let start_date = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      //   let end_date = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            // start_date: serverFormatDate(start_date),
            // end_date: serverFormatDate(end_date),
            start_date: startDateValue,
            end_date: endDateValue,
            attendance_type: "CLASS",
            section_id: +sectionid,
          },
        })
        .then((response) => {
          const timetableArr = response.data.getAttendanceBySection;
          let workingDays = 0;
          for (let index = 0; index < timetableArr.length; index++) {
            const element = timetableArr[index];
            if (element?.attendances) {
              workingDays = element.attendances.length;
              break;
            }
          }
          setTotalWorkingDaysArr(workingDays);
          setStudentMonthAttenArr(timetableArr);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  const monthFilterArr = studentMonthAttenArr.filter((event) => {
    return (
      getFullName(event.student_profile?.name)
        .toLowerCase()
        .indexOf(searchMonth.toLowerCase()) !== -1
    );
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const createInvoiceHandle = (row) => {
    setCreateInoviceDailog(true);
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.manageInstitution.institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading ">
                <div>
                  <div className="mt-3 ml-3 Heading_bold">Create Invoice</div>
                  <p className="text-info  ml-3">
                    {getValue(CapitalizeFirstFn(classValue)) +
                      " - " +
                      getValue(CapitalizeFirstFn(sectionValue))}
                  </p>
                </div>
                <div>
                  <p className="mt-3 mr-3">
                    {dateToDatedashtoDDMMYY(startDateValue)} -{" "}
                    {dateToDatedashtoDDMMYY(endDateValue)}
                  </p>
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_invoicedetails_border">
                    <div className="d-flex mt-2 mb-2">
                      <div className="col-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                          autoComplete="off"
                          value={searchMonth}
                          onChange={(e) => {
                            setsearchMonth(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-3"></div>
                      <div className="col-5">
                        <div className="d-flex">
                          <div className="col-6">
                            <p>Total Days - {totalWorkingDaysArr}</p>
                          </div>
                          <div className="col-6">
                            <p>{timeToShow}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell className="table_header">
                              Roll No
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className="table_header"
                              padding="none"
                            >
                              Name
                            </StyledTableCell>
                            <StyledTableCell
                              padding="none"
                              align="left"
                              className="table_header"
                            >
                              Days <br /> Present
                            </StyledTableCell>

                            {day.map((item) => (
                              <StyledTableCell
                                align="left"
                                className={
                                  item.title < 10
                                    ? "table_header paddingone"
                                    : "table_header"
                                }
                                padding="none"
                              >
                                {item.title}
                              </StyledTableCell>
                            ))}
                            <StyledTableCell
                              className="table_header"
                              padding="none"
                              align="left"
                            >
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? monthFilterArr.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : monthFilterArr
                          ).map((row) => (
                            <StyledTableRow>
                              <StyledTableCell align="left">
                                {row.roll_number}
                              </StyledTableCell>
                              <StyledTableCell
                                scope="row"
                                align="left"
                                padding="none"
                              >
                                {getFullName(row.student_profile?.name)}
                              </StyledTableCell>
                              <StyledTableCell align="left" padding="none">
                                {dayPresentCounter(row.attendances)}
                              </StyledTableCell>

                              {day.map((item) => {
                                if (
                                  isPresent(item.realDate, row.attendances) ===
                                  "Present"
                                ) {
                                  return (
                                    <StyledTableCell
                                      align="left"
                                      padding="none"
                                    >
                                      <i
                                        class="fa fa-check"
                                        style={{
                                          color: "#50ae34",
                                          fontSize: 15,
                                        }}
                                      ></i>
                                    </StyledTableCell>
                                  );
                                } else if (
                                  isPresent(item.realDate, row.attendances) ===
                                  "Absent"
                                ) {
                                  return (
                                    <StyledTableCell
                                      align="left"
                                      padding="none"
                                    >
                                      <i
                                        class="fa fa-times cursor"
                                        style={{
                                          color: "#ff0000",
                                          fontSize: 15,
                                        }}
                                      ></i>
                                    </StyledTableCell>
                                  );
                                } else {
                                  return (
                                    <StyledTableCell
                                      align="left"
                                      padding="none"
                                    >
                                      <i
                                        class="fa fa-check"
                                        style={{
                                          color: "#50ae34",
                                          fontSize: 15,
                                          visibility: "hidden",
                                        }}
                                      ></i>
                                    </StyledTableCell>
                                  );
                                }
                              })}
                              <StyledTableCell align="left">
                                <span
                                  className="actionStyle row_details_color"
                                  onClick={() => {
                                    createInvoiceHandle(row);
                                  }}
                                >
                                  Create Invoice
                                </span>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        true
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={monthFilterArr.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={createInoviceDailog}
        onClose={() => {
          setCreateInoviceDailog(false);
        }}
        maxWidth="md"
        fullWidth="fullWidth"
        className="dailog_formatting"
      >
        <div className="p-2">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setCreateInoviceDailog(false);
                }}
              ></i>
            </div>
          </div>
          <div>
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label className="text_filed_heading">
                    Title for Invoice
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title for Invoice"
                    value={invoiceTitle}
                    onChange={(event) => {
                      setinvoicetitleError(false);
                      setInvoiceTitle(event.target.value);
                    }}
                  />
                  {invoicetitleError && (
                    <span className="text-danger">Enter the Invoice Title</span>
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label className="text_filed_heading">Due Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="564946646"
                    value={dueDate}
                    onChange={(e) => {
                      setduedateError(false);
                      setDueDate(e.target.value);
                    }}
                  />
                  {duedateError && (
                    <span className="text-danger">Enter the Due Date</span>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid className="grid_main_div mt-2">
              <Grid item md={6}>
                <div className="inputMarginright">
                  <label className="text_filed_heading">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="description"
                    value={description}
                    onChange={(e) => {
                      setdescriptionError(false);
                      setDescription(e.target.value);
                    }}
                  />
                  {descriptionError && (
                    <span className="text-danger">Enter the Description</span>
                  )}
                </div>
              </Grid>
              <Grid item md={6}></Grid>
            </Grid>
            <Grid className="grid_main_div">
              <Grid item md={4}>
                <div className="inputMarginright">
                  <label className="text_filed_heading">
                    Total Amount({Currency_code})
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Total Amount"
                    value={totalamount}
                    disabled={selectedTemplateId === -1 ? true : false}
                  />
                  {totalamountError && (
                    <span className="text-danger">total amount not be 0</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          {/* action section */}
          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => {
                    // submitHandle();
                  }}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setCreateInoviceDailog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    padding: 0,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(CreateInvoiceForDate));
