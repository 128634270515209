import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Holiday from "../../Common/Holiday/Holiday.jsx";

//-------------redux------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { showNotificationMsz } from "../../../utils/Validation.js";

const SuperAdminHome = (props) => {
  const [isPathValid, setisPathValid] = useState(false);

  //name instituition
  let Organization_Name = props.UserInfo.institution_name;
  useEffect(() => {
    window.scrollTo(0, 0);
    const academicHandler = () => {
      if (props.UserInfo.academic_session_id === null) {
        setisPathValid(false);
      } else {
        setisPathValid(true);
      }
    };
    academicHandler();
  }, [props.UserInfo.academic_session_id]);

  const navigationHandler = (path, data) => {
    if (!isPathValid) {
      showNotificationMsz(
        "Currently there is no active session, please manage Institution session first",
        "info"
      );
    } else if (isPathValid === true) {
     props.navigate(path, data);
    }
  };
  const gobackprevoius = () => {
    props.navigate.goBack();
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="Main_Grid_For_MENUS">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="card_content_instition">
                <span className="margin_institution_name">
                  <Card className="institution_Name_font">
                    {Organization_Name}
                  </Card>
                </span>
                <span
                  className="gobackbutton manage_edit_cursor"
                  onClick={gobackprevoius}
                >
                  <i className="fa fa-chevron-left"></i> Back
                </span>
              </div>
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Home</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_margin_both_side mt-2 mb-2">
                      <Grid className="Main_Grid_For_MENUS" spacing={2}>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/manageExamination");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Examinations</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() =>
                             props.navigate("/adminmanageinstitution", {
                                temp: props.UserInfo.data,
                              })
                            }
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-university"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Manage Institute</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            // onClick={() => {
                            //   navigationHandler("/adminManageStudentLeave");
                            // }}
                            onClick={() => {
                              navigationHandler("/manageLeaveRequests");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Student Leaves</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color  mt-1"
                            onClick={() => {
                              navigationHandler("/adminManageTeacherLeave");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Staff Leaves</div>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid className="Main_Grid_For_MENUS mt-2">
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/manageusersetting");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Manage Student</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/employeedetailform");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-user"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Staff Onboarding</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/adminemployeelistview");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Manage Staff</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color mt-1"
                            onClick={() => {
                              navigationHandler("/adminClassNotice");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Class Notice</div>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid className="Main_Grid_For_MENUS mt-2">
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/adminSchoolNotice");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>School Notice</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/manageEvent", {
                                academic_session_id: parseInt(
                                  props.UserInfo.academic_session_id
                                ),
                              });
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Events</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/admin_notification");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i className="fa fa-envelope "></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Email</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color mt-1"
                            onClick={() => {
                              navigationHandler("/report", {
                                name: "",
                                SubName: "",
                              });
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Reports</div>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid className="Main_Grid_For_MENUS mt-2">
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/admissionsMenu");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Admissions</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/routelistings");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-bus"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Transport</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/fees");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Fees</div>
                          </Card>
                        </Grid>

                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color  mt-1"
                            onClick={() => {
                              navigationHandler("/classpromotion");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-book"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Class Promotion</div>
                          </Card>
                        </Grid>
                      </Grid>

                      {/*------------------ last row----------------- */}

                      <Grid className="Main_Grid_For_MENUS mt-2">
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/bulkUpload");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Bulk Upload</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/assignonboardstudent");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Assign Students</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color cards_Home_menu_margin mt-1"
                            onClick={() => {
                              navigationHandler("/manageRollNo");
                            }}
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-users"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Manage Roll Number</div>
                          </Card>
                        </Grid>

                        <Grid item md={3}></Grid>
                      </Grid>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(SuperAdminHome)));
