import React, { useEffect, useState } from "react";
import "./HomeMenu.css";
import Grid from "@material-ui/core/Grid";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import CountDisplay from "../FeeModule/CountDisplay";
import { showNotificationMsz } from "../../../utils/Validation";
import Loder from "../../Loder/Loder";

const TotalCountData = (props) => {
  const { inst_id, acdmin_id, type } = props;
  const [totalstudent, setTotalstudent] = useState("");
  const [totalteacher, setTotalteacher] = useState("");
  const [totalstaff, setTotalstaff] = useState("");
  const [totaladmin, setTotaladmin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  useEffect(() => {
    if (type === "superadmin") {
      getInstitutionDetails();
    } else {
      if (inst_id && acdmin_id) {
        EventList();
      }
    }
  }, [props]);

  // get institutions api
  const getInstitutionDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.GET_Institution_Details,
        variables: {
          organization_id: +inst_id,
        },
      });

      if (data && data.institutions) {
        const { institutions } = data;
        let scount = 0;
        let tcount = 0;
        let sfcount = 0;
        let adcount = 0;
        for (let index = 0; index < institutions.length; index++) {
          const element = institutions[index];
          console.log("element", element);
          try {
            setIsLoading(true);
            const { data } = await client.query({
              query: Service.Home_admin_get_totalcount,
              variables: {
                institution_id: +element.id,
                academic_session_id: +acdmin_id,
              },
            });

            if (data && data.getUserCount) {
              const { student_count, teacher_count, staff_count, admin_count } =
                data.getUserCount;
              scount += student_count;
              tcount += teacher_count;
              sfcount += staff_count;
              adcount += admin_count;
            }

            setIsLoading(false);
          } catch (error) {
            showNotificationMsz("error", "danger");
            setIsLoading(false);
          }
        }
        setTotalstudent(scount);
        setTotalteacher(tcount);
        setTotalstaff(sfcount);
        setTotaladmin(adcount);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error", error);
    }
  };

  const EventList = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.Home_admin_get_totalcount,
        variables: {
          institution_id: +inst_id,
          academic_session_id: +acdmin_id,
        },
      });

      if (data && data.getUserCount) {
        const { student_count, teacher_count, staff_count, admin_count } =
          data.getUserCount;
        setTotalstudent(student_count);
        setTotalteacher(teacher_count);
        setTotalstaff(staff_count);
        setTotaladmin(admin_count);
      }

      setIsLoading(false);
    } catch (error) {
      showNotificationMsz("error", "danger");
      setIsLoading(false);
    }
  };
  return (
    <div style={{ marginTop: 10 }}>
      <Grid className="grid_main_div">
        <Grid item md={12}>
          <div className="total_count_flex">
            <CountDisplay label="Students" count={totalstudent}></CountDisplay>
            <CountDisplay label="Teachers" count={totalteacher}></CountDisplay>
            <CountDisplay label="Staffs" count={totalstaff}></CountDisplay>
            <CountDisplay label="Admins" count={totaladmin}></CountDisplay>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isLoading} />
    </div>
  );
};

export default TotalCountData;
