import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "@material-ui/core";

const NoSectionDailog = (props) => {
  const RedirectHandle = () => {
    props.navigate("addeditclass", {
      state: {
        academic_session_id: parseInt(props.academic_session_id),
        institution_name: props.institution_name,
        institution_id: props.institution_id,
      },
    });
  };

  return (
    <div>
      <Dialog open={props.open} className="dailog_formatting">
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  props.close();
                }}
              ></i>
            </div>
          </div>
          {props.type === "Admin" ? (
            <div className="mt-4">
              <p className="nosectionParaPadding">
                Please complete the setup to start, Click here to begin by
                creating classes or groups.
              </p>
              <div className="pt-4">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    RedirectHandle();
                  }}
                >
                  Click here to Start
                </Button>
              </div>
            </div>
          ) : (
            <div className="mt-4 p-2">
              <p className="nosectionParaPadding">
                Please complete the setup to start by logging in as admin.
              </p>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default NoSectionDailog;
