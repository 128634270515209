import React, { useState } from "react";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import logo from "./Skool_First_Logo.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import Loder from "../Loder/Loder.jsx";

//------------------redux-------------------------------
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";

import * as Service from "../../services/index";
import { blankValidator } from "../../utils/Validation";

const Header7 = ({ props, data, navigate }) => {
  let imagepath;

  //institution logo path
  imagepath = !blankValidator(props?.data)
    ? ""
    : !blankValidator(props?.data?.document)
    ? ""
    : !blankValidator(props?.data?.document?.path)
    ? ""
    : props?.data?.document?.path;

  //-------------------------global id-------------------------
  let navigation = navigate;

  //-------------------------local state-------------------------

  const [OpendailogtoEmailEdit, setOpendailogtoEmailEdit] = useState(false);
  // const [isloading, setisloading] = useState(false);

  //-------------------------methods-------------------------

  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader1">
          <Toolbar>
            <Grid container spacing={24}>
              <Grid item xs={6} md={10}>
                <div className="logoformatting">
                  <span>
                    <img
                      src={
                        imagepath === ""
                          ? logo
                          : Service.baseUrl + "/" + imagepath
                      }
                      alt="No_profile"
                      className="image_hader"
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={6} md={2}>
                <div className="iconsMaindiv2 icon_color">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn  dropdown-toggle dropdownbutton1 button_login_decoration"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {data.first_name !== undefined && data.first_name}{" "}
                      {data.last_name !== undefined && data.last_name}
                    </button>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => navigation("/studentresetpassword")}
                      >
                        Change Password
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => setOpendailogtoEmailEdit(true)}
                      >
                        Change Email
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => navigation("/")}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>

      <Dialog
        open={OpendailogtoEmailEdit}
        onClose={() => {
          setOpendailogtoEmailEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="d-flex justify-content-between">
            <div className="Heading_bold">Change Super admin email</div>
            <div className=" mr-3 cross_font_size">
              <i
                class="fa fa-times cursor"
                onClick={() => {
                  setOpendailogtoEmailEdit(false);
                }}
              ></i>
            </div>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <div className="text_filed_heading">Current Email</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Current Email"
                    autoComplete="off"
                  />
                </div>

                <div className="text_filed_heading mt-2">New Email Address</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="New Email Address"
                    autoComplete="off"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4  mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
              >
                Submit
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      {/* <Loder loading={isloading} /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Header7);
