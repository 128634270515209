import React, { useState, useEffect } from "react";
import Hoc1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

//import "./Institution.css";
import Loder from "../../Loder/Loder.jsx";
import {
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation.js";
// import Holiday from "../../Common/Holiday/Holiday.jsx";
import userimage from "../../Common/Events/userimage.png";
import Avatar from "react-avatar";
//-----------------------redux--------------------------
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//-----------------------api calls--------------------------
import { useApolloClient } from "@apollo/client";
import * as Service from "../../../services/index";
import { useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import { baseUrl } from "../../../services/index.js";
const OrganisationSetup = ({ UserInfo, navigate, dispatch }) => {
  const [isUpdated, setisUpdated] = useState(false);
  // const [organization_id, setorganization_id] = useState(null);
  //-------------------------------global id---------------------------
  // let organization_id = parseInt(UserInfo.organization_id);
  //-------------------------------api calls---------------------------
  const client = useApolloClient();
  //-------------------------------local state---------------------------
  const [organisationArr, setorganisationArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  //-------------------------------methods---------------------------
  useEffect(() => {
    getInstitutionDetails();
  }, [client]);
  const getInstitutionDetails = async () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Organisation_Details,
        })
        .then((response) => {
          if (
            response.data.getOrganization === undefined ||
            response.data.getOrganization.length === 0
          ) {
            showNotificationMsz("There is no active session", "info");
          }
          setisloading(false);
          setorganisationArr(response.data.getOrganization);
        })
        .catch((error) => {
          setisloading(false);
          console.log(" institution arr then error", error);
        });
      //getting organization id from server to fetch the institution list
    } catch (error) {
      setisloading(false);
      console.log(" institution arr try and catch", error);
    }
  };

  //organization id

  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [Deleteorganisationid, setDeleteorganisationid] = useState("");
  const [Deleteorganisationname, setDeleteorganisationname] = useState("");
  const DeleteDailogBox = (item) => {
    setDeleteorganisationid(item.id);
    setDeleteorganisationname(item.name);
    setopendialogConfimation(true);
  };
  const [Delete_Organisation, {}] = useMutation(Service.Delete_Organisation);
  const deleteOrganisation = (id) => {
    try {
      setisloading(true);
      Delete_Organisation({
        variables: { id: parseInt(id) },
      }).then(
        (response) => {
          setopendialogConfimation(false);
          setisloading(false);
          showNotificationMsz(response.data.deleteOrganization.message, "info");

          getInstitutionDetails();
          setisUpdated(true);
        },
        (err) => {
          console.log("then error", err);
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const openEditInstitution = (item) => {
    navigate("/createUpdateOrganisation", {
      state: {
        pagetype: "edit",
        organization_id: item.id,
        data: item,
      },
    });
  };

  const hadleOrganinzation = (item) => {
    navigate("/adminSetup", {
      state: {
        adminData: item,
        pageType: "SUPERADMIN",
      },
    });
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              {/* <Holiday name={"FIND/ORGANISATION/INSTITUTION"} /> */}
              <div className="font_color_holiday">
                Holiday(Independence day)
              </div>
              <div className="card_Show_Insitute">
                <span className="margin_institution_name">
                  <Card className="institution_Name_font mb-2">
                    FIND/ORGANISATION/INSTITUTION
                  </Card>
                </span>
              </div>
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-4 Heading_bold">Organisations</div>
                  <div className="mr-3 mt-4 d-flex ">
                    <Button
                      variant="contained"
                      className="btn_colrWidth mr-2 mt-2 button_login_decoration"
                      onClick={() =>
                        navigate("/PrivateTeachingInvoice", {
                          state: {
                            pagetype: "add",
                          },
                        })
                      }
                    >
                      Private teaching invoicing
                    </Button>
                    <Button
                      variant="contained"
                      className="btn_colrWidth mr-2 mt-2 button_login_decoration"
                      onClick={() =>
                        navigate("/createUpdateOrganisation", {
                          state: {
                            pagetype: "add",
                          },
                        })
                      }
                    >
                      Add New Organisation
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {organisationArr.map((item, index) => (
                          <Card
                            key={index}
                            className="cardvisible_border mb-2 mt-3"
                          >
                            <div className="card_admissiondetails_height">
                              <div className="textfiled_margin">
                                <div className="card_content_instition">
                                  <Grid className="grid_main_div card_admissiondetails_height textfiled_margin">
                                    <Grid item md={2}>
                                      <div>
                                        {item.document !== undefined &&
                                          item.document !== null && (
                                            <div>
                                              {item.document.path !==
                                                undefined &&
                                              item.document.path !== null ? (
                                                <Avatar
                                                  round
                                                  size="40"
                                                  src={`${baseUrl}/${item.document.path}`}
                                                />
                                              ) : (
                                                <Avatar
                                                  round
                                                  size="40"
                                                  src={userimage}
                                                />
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </Grid>
                                    <Grid item md={2}>
                                      <div>
                                        <span>
                                          {!blankValidator(item.name) ? (
                                            ""
                                          ) : (
                                            <span>{item.name}</span>
                                          )}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item md={5}>
                                      <div>
                                        <span>
                                          {item.domainUrl !== undefined &&
                                            item.domainUrl !== null &&
                                            item.domainUrl}
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item md={3}>
                                      <div className="d-flex">
                                        <span
                                          className="icon_pencil_color manage_edit_cursor"
                                          onClick={() => {
                                            openEditInstitution(item);
                                          }}
                                        >
                                          Edit
                                        </span>
                                        <span className="ml-1 mr-1 icon_pencil_color">
                                          |
                                        </span>
                                        <span
                                          className="icon_pencil_color manage_edit_cursor"
                                          onClick={() => {
                                            DeleteDailogBox(item);
                                          }}
                                        >
                                          Delete
                                        </span>
                                        <span className="ml-1 mr-1 icon_pencil_color">
                                          |
                                        </span>
                                        <span
                                          className="icon_pencil_color manage_edit_cursor"
                                          onClick={() =>
                                            hadleOrganinzation(item)
                                          }
                                        >
                                          Manage
                                        </span>
                                        <span className="ml-1 mr-1 icon_pencil_color">
                                          |
                                        </span>
                                        <span
                                          className="icon_pencil_color manage_edit_cursor"
                                          onClick={() =>
                                            navigate("/currencySettings", {
                                              state: item ,
                                            })
                                          }
                                        >
                                          Settings
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Organisation :{" "}
            {Deleteorganisationname}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteOrganisation(Deleteorganisationid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(OrganisationSetup));
