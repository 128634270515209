import React, { useState, useEffect } from "react";
import Loder from "../Loder/Loder.jsx";
import * as Service from "../../services/index";
import { useApolloClient } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  getHHMMSStoDate,
  get_Meeting_join_ling,
  newDateToDate,
  changeTimeFormat,
} from "../../utils/Validation";
export default function OnlineClass(props) {
  const [isloading, setisloading] = useState(false);

  const [meetingURL, setMeetingURL] = useState("");
  const [openJoinDailog, setopenJoinDailog] = useState(false);
  const [classData, setclassData] = useState([]);
  const temp = new Date();

  const [time, settime] = useState(temp);
  const client = useApolloClient();
  let { first_name, middle_name, last_name } = props.userName;
  let name = `${first_name + "_" + middle_name + "_" + last_name}`;
  useEffect(() => {
    const getStudentList = () => {
      try {
        setisloading(true);

        client
          .query({
            query: Service.Get_Current_CLass_By_SectionId,
            variables: {
              section_id: parseInt(props.section_id),
              institution_id: parseInt(props.institution_id),
              dateAndTime: newDateToDate(time),
              day: time.getDay().toString(),
              time: time.toLocaleTimeString("en-GB").substring(0, 8),
            },
          })
          .then((response) => {
            if (
              response?.data?.getActivePeriod !== null &&
              response?.data?.getActivePeriod !== undefined
            ) {
              try {
                let dataForClass = response?.data?.getActivePeriod;
                let temp = [];
                for (let index = 0; index < dataForClass.length; index++) {
                  // let item = dataForClass[index];
                  // let startDate = getHHMMSStoDate(item?.timetable?.start_time);
                  // let endDate = getHHMMSStoDate(item?.timetable?.end_time);
                  // let currentDateandTime = new Date();
                  temp.push(dataForClass[index]);
                  // if (
                  //   startDate <= currentDateandTime &&
                  //   endDate >= currentDateandTime
                  // ) {

                  // }
                }
                setclassData(temp);
              } catch (error) {
                console.log("error ", error);
              }
            }

            setisloading(false);
          })
          .catch((e) => {
            console.log("catch error", e);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getStudentList();
  }, [client, props.section_id]);
  const openDailogToJoin = (timetable) => {
    if (timetable != undefined) {
      let { response } = JSON.parse(timetable.location.meeting_info);
      if (response) {
        let mainurl = get_Meeting_join_ling(
          response.meetingID[0],
          name,
          response.attendeePW[0],
          props.userType,
          response.moderatorPW[0]
        );
        window.open(mainurl);
        //  setMeetingURL(mainurl);
      }

      //setopenJoinDailog(true);
    }
  };

  return (
    <div className="timesession_alignright mr-2">
      {classData.map((item, index) => (
        <div className="text-primary" key={index}>
          <div>
            {item.timetable && (
              <div>
                {changeTimeFormat(item.timetable.start_time)} -{" "}
                {changeTimeFormat(item.timetable.end_time)}
                {item.timetable.subject !== null ? (
                  <span>| {item?.timetable?.subject?.name}</span>
                ) : (
                  ""
                )}
                <span
                  onClick={() => {
                    openDailogToJoin(item.timetable);
                  }}
                >
                  {" "}
                  | Join
                </span>
              </div>
            )}
          </div>
          {/* <span id="circle"></span> */}
        </div>
      ))}
      <Loder loading={isloading} />

      <Dialog
        open={openJoinDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setopenJoinDailog(false);
        }}
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setopenJoinDailog(false);
              }}
            ></i>
          </div>
        </div>
        <DialogContent>
          <iframe
            src={meetingURL}
            title="description"
            className="iframe_width"
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
