import React, { useState } from "react";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import Badge from "@material-ui/core/Badge";
import profile from "./profile.png";
//redux
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
import { baseUrl } from "../../services/index";
import IconSchool from "./IconSchool.png";

const EmployeHeader = (props) => {
  // const [invisible] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  let userInformation = props.data;

  const setLoadingnewside = () => {
    try {
      setSidebar(!Sidebar);

      if (Sidebar) {
        document.getElementById("mySidenav").style.width = "250px";
      } else {
        document.getElementById("mySidenav").style.width = "0px";
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader">
          <Toolbar>
            <Grid container spacing={24}>
              <Grid item xs={2} md={2}>
                <div className="logoformatting">
                  <span>
                    <img
                      src={
                        props.data.path === ""
                          ? IconSchool
                          : baseUrl + "/" + props.data.path
                      }
                      alt=""
                      className={
                        props.data.path === ""
                          ? "images_background_logo image_hader"
                          : "image_hader"
                      }
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={10} md={10}>
                <div className="iconsMaindiv2">
                  <span
                    className="Gap_from_bottom_Menu_Spacing "
                    onClick={() => props.navigate("/employeeHomePage")}
                  >
                    Home
                  </span>

                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => props.navigate("/teacherLeaveSummary")}
                  >
                    Leaves
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => props.navigate("/teacherNoticeBoard")}
                  >
                    Notice
                  </span>
                  <span
                    className="Gap_from_bottom_Menu_Spacing"
                    onClick={() => props.navigate("/showEvent")}
                    // onClick={() =>
                    //  props.navigate("/manageEvent", {
                    //     academic_session_id: parseInt(props.dataacademic.id),
                    //   })
                    // }
                  >
                    Events
                  </span>

                  {props.data.usertype === "TRANSPORT" ? (
                    <span
                      className="Gap_from_bottom_Menu_Spacing"
                      onClick={() => props.navigate("/transport")}
                    >
                      Transport
                    </span>
                  ) : (
                    ""
                  )}

                  <span className="Gap_from_bottom_Menu_Spacing">
                    <i
                      className="fa fa-calendar"
                      onClick={() =>
                        props.navigate("/commonHolidayCalender", {
                          state: {
                            academic_session_id: parseInt(
                              props.dataacademic.id
                            ),
                          },
                        })
                      }
                    ></i>
                  </span>
                  <span className="Gap_from_bottom_Menu_Spacing">
                    <Badge
                      color="secondary"
                      // variant="dot"
                      // invisible={invisible}
                    >
                      <i
                        className="fa fa-envelope "
                        onClick={() => props.navigate("/notification")}
                      ></i>
                    </Badge>
                  </span>

                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn  dropdown-toggle dropdownbutton1 button_login_decoration"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {userInformation.first_name !== undefined &&
                        userInformation.first_name}{" "}
                      {/* {userInformation.middle_name !== undefined &&
                        userInformation.middle_name}{" "} */}
                      {userInformation.last_name !== undefined &&
                        userInformation.last_name}
                    </button>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() =>
                          props.navigate("/employeeProfileView", {
                            state: {
                              userId: props.data.userid,
                              institution_id: props.data.institution_id,
                              loginID: props.data.login_id,
                            },
                          })
                        }
                      >
                        My Profile
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => props.navigate("/studentresetpassword")}
                      >
                        Reset Password
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => props.navigate("/")}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mobile_Burger_Menu mr-3 text-right mt-3">
                  <span
                    className="logout_Pointer_cursor"
                    onClick={setLoadingnewside}
                  >
                    <i class="fa fa-bars"></i>
                  </span>

                  <div id="mySidenav" className="sidenav">
                    <div className="cross_icon_style">
                      <i
                        class="fa fa-times cursor"
                        onClick={() => {
                          document.getElementById("mySidenav").style.width =
                            "0px";
                        }}
                      ></i>
                    </div>
                    <div className="text-center">
                      <img src={profile} alt="xyx" width="60" height="60" />{" "}
                      <div className="userName_mobileView_color">
                        {userInformation.first_name !== undefined &&
                          userInformation.first_name}{" "}
                        {userInformation.last_name !== undefined &&
                          userInformation.last_name}
                      </div>
                    </div>

                    <span
                      className="logout_Pointer_cursor "
                      onClick={() =>
                        props.navigate("/employeeProfileView", {
                          state: {
                            userId: props.data.userid,
                            institution_id: props.data.institution_id,
                            loginID: props.data.login_id,
                          },
                        })
                      }
                    >
                      My Profile
                    </span>
                    <span
                      className="logout_Pointer_cursor "
                      onClick={() => props.navigate("/employeeHomePage")}
                    >
                      Home
                    </span>
                    {/* <span
                     className="Gap_from_bottom_Menu_Spacing"
                      onClick={() =>props.navigate("/manageExamination",{
                        academic_session_id:parseInt(props.dataacademic.id)
                      })}
                    >
                      Examinations
                    </span> */}

                    {/* <span
                      className="logout_Pointer_cursor"
                      onClick={() =>props.navigate("/manageExam")}
                    >
                      Examinations
                    </span> */}
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => props.navigate("/teacherLeaveSummary")}
                    >
                      Leaves
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => props.navigate("/teacherNoticeBoard")}
                    >
                      Notice
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => props.navigate("/showEvent")}
                      // onClick={() =>
                      //  props.navigate("/manageEvent", {
                      //     academic_session_id: parseInt(props.dataacademic.id),
                      //   })
                      // }
                    >
                      Events
                    </span>

                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => alert("Need To Implement")}
                    >
                      Transport
                    </span>

                    <span
                      className="logout_Pointer_cursor"
                      // onClick={() =>props.navigate("/commonHolidayCalender")}
                      onClick={() =>
                        props.navigate("/commonHolidayCalender", {
                          state: {
                            academic_session_id: parseInt(
                              props.dataacademic.id
                            ),
                          },
                        })
                      }
                    >
                      Calender
                    </span>

                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => props.navigate("/notification")}
                    >
                      Notification
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => props.navigate("/studentresetpassword")}
                    >
                      Reset Password
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => props.navigate("/")}
                    >
                      Logout <i class="fa fa-sign-out ml-3"></i>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});
export default connect(mapStateToProps, null)(EmployeHeader);
