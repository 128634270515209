import React from "react";
import "./Fees.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import Holiday from "../../Common/Holiday/Holiday.jsx";
const Pay = (props) => {
  const payment = props.location.state.paymentData;

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Payment</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-2 inputMarginright">
                              <div className="Heading_bold">Payment</div>
                              <div>{payment.name}</div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2">
                              <div className="Heading_bold">Amount</div>
                              <div>
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                }).format(payment.amount)}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="mt-5">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexCheckDisabled"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDisabled"
                            >
                              Net Banking
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexCheckDisabled1"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDisabled1"
                            >
                              Bill Pay
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexCheckDisabled2"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDisabled2"
                            >
                              PayPal
                            </label>
                          </div>
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                            >
                              Pay
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default (HOC1(Pay));
