import React, { useState } from "react";
import { connect } from "react-redux";
import { getData } from "../../../api";
import Hoc1 from "../../Hoc1";
import * as Constants from "../../../action/appAction";
import "./ManageCredits.css";
import Grid from "@material-ui/core/Grid";
import Holiday from "../../Common/Holiday/Holiday";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Loder from "../../Loder/Loder";

const ManageCredits = ({ ADMIN_DATA }) => {
  const { institution_id, institution_name, academic_session_id, usertype } =
    ADMIN_DATA;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} mynav={"/homemenu"} />
              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-2 ml-4 Heading_bold">Manage Credits</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="table_foramtitng mt-5">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Student Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class/Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start Date
                                  </StyledTableCell>

                                  <StyledTableCell className="table_header">
                                    End Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Total credit
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Remaining Credits
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {/* <TableBody>
                                {filterData.length !== 0 ? (
                                  (rowsPerPage > 0
                                    ? filterData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterData
                                  ).map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">
                                        {getFullName(row.fatherName)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {getFullName(row.studentName)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {timeStampToDateandTime(
                                          row.update_time
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        {row.status && row.status.toUpperCase()}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        <div className="d-flex justify-content-center">
                                          {row.status == "ACCEPTED" ? (
                                            <div>
                                              <span
                                                className="row_details_color pr-2"
                                                onClick={() => {
                                                  openeditadmissionIntrest(row);
                                                }}
                                              >
                                                Open
                                              </span>
                                              |
                                              <span
                                                className="pl-2 row_details_color"
                                                onClick={() =>
                                                  navigate(
                                                    "/adminstudentlistview"
                                                  )
                                                }
                                              >
                                                Current status
                                              </span>
                                            </div>
                                          ) : (
                                            <div>
                                              <span
                                                className="row_details_color"
                                                onClick={() => {
                                                  openeditadmissionIntrest(row);
                                                }}
                                              >
                                                Open
                                              </span>{" "}
                                            </div>
                                          )}
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  <div>No data</div>
                                )}
                              </TableBody> */}
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              //   count={filterData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN) || {},
});
export default connect(mapStateToProps, null)(Hoc1(ManageCredits));
