import React, { useState, useEffect } from "react";
import "./ManageInstitution.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------------redux-------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------------api calls------------------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { blankValidator, getFullName, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";

const AssignTeacher = ({ location, manageInstitution, navigate }) => {
  const { state } = location;
  let id = parseInt(state.data.id);
  let class_timetable_id = parseInt(state.id);
  //-----------------------global id----------------
  let todayDate = new Date().toJSON().slice(0, 10);
  let institution_id = parseInt(manageInstitution.institution_id);
  //-----------------------api calls----------------
  const client = useApolloClient();
  const [Assign_Teacher_API, {}] = useMutation(Service.Assign_Teacher_API);
  const classes = useStyles();
  //-----------------------local state----------------
  const [locationId, setLocationId] = useState("");
  const [locationArr, setLocationArr] = useState([]);
  const [periodName, setPeriodName] = useState("");
  const [periodArr, setPeriodArr] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [isClassTeacher, setIsClassTeacher] = useState(false);
  const [managesubjects, setmanagesubjects] = useState([]);
  const [employeeArr, setEmployeeArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [day, setday] = useState("");

  const [dayArr] = useState([
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ]);

  //error
  const [daysError, setdaysError] = useState(false);
  const [periodidError, setperiodidError] = useState(false);
  const [locationidError, setlocationidError] = useState(false);
  const [subjectidError, setsubjectidError] = useState(false);
  const [teacheridError, setteacheridError] = useState(false);

  //-----------------------methods----------------
  useEffect(() => {
    // to get period data---------------------------

    //-----------------------to get location arr----------------------
    client
      .query({
        query: Service.Get_Manage_Location,
        variables: {
          institution_id,
        },
      })
      .then((response) => {
        setLocationArr(response.data.getLocation);
      })
      .catch((error) => {
        console.log("catch error", error);
      });

    // to get period data---------------------------
    window.scrollTo(0, 0);

    const GetSubjectData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setmanagesubjects(response.data.getSubject);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    const GetallEmployeeData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Employee_All_data,
            variables: {
              institution_id,
              department_id: -1,
            },
          })
          .then((response) => {
            if (response.data.getEmployee) {
              setEmployeeArr(response.data.getEmployee);
            }
            setisloading(false);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    GetSubjectData();
    GetallEmployeeData();
  }, [client, institution_id]);

  const GetPeriodsData = (ID) => {
    let day = parseInt(ID);
    setday(day);
    setPeriodArr([]);
    if (day === -1) {
      setPeriodName("");
      setPeriodArr([]);
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Class_Time_Table,
          variables: {
            institution_id,
            section_id: id,
            start_date: todayDate,
            end_date: "",
            day,
          },
        })
        .then((response) => {
          setPeriodArr(response.data.getClassTimetable);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("parent monday  catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const assignTeacherHandler = () => {
    let flag = false;
    if (!blankValidator(day) || day === -1) {
      setdaysError(true);
      flag = true;
    }
    if (!blankValidator(periodName)) {
      setperiodidError(true);
      flag = true;
    }
    if (!blankValidator(subjectId) || subjectId === -1) {
      setsubjectidError(true);
      flag = true;
    }
    if (!blankValidator(locationId) || locationId === -1) {
      setlocationidError(true);
      flag = true;
    }
    if (!blankValidator(employeeId) || employeeId === -1) {
      setteacheridError(true);
      flag = true;
    }
    if (flag) {
      return;
    }

    let is_class_teacher = 0;
    if (isClassTeacher) {
      is_class_teacher = 1;
    }

    try {
      setisloading(true);
      Assign_Teacher_API({
        variables: {
          employee_id: parseInt(employeeId),
          location_id: parseInt(locationId),
          subject_id: parseInt(subjectId),
          is_class_teacher,
          class_mode: "Offline",
          class_timetable_id,
          period_name: periodName,
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.assignTeacherLocationAndSubjectToPeriod.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const periodHandler = (periodName) => {
    setPeriodName(periodName);
  };
  const selectLocationHandler = (locationId) => {
    if (locationId == -1) {
      setLocationId("");
      return;
    }
    setLocationId(locationId);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={manageInstitution.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-32 ml-3 Heading_bold">
                    Assign Teachers ({state.data.grade.gradeName}
                    {","}
                    {state.data.name})
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header assign_t_cell_padding"
                                  >
                                    Day
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header assign_t_cell_padding"
                                  >
                                    Period
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header assign_t_cell_padding"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header assign_t_cell_padding"
                                  >
                                    Location
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header assign_t_cell_padding"
                                  >
                                    Teacher
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="right"
                                    className="table_header assign_t_cell_padding"
                                  >
                                    Class Teacher
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <StyledTableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="assignteacher_data_padding"
                                  >
                                    <div className=" mt-1">
                                      <select
                                        class="form-control"
                                        onChange={(e) => {
                                          GetPeriodsData(e.target.value);
                                          setdaysError(false);
                                        }}
                                      >
                                        <option value={-1}>Select Day</option>
                                        {dayArr.length > 0 ? (
                                          dayArr.map((item) => (
                                            <option value={item.value}>
                                              {item.label}
                                            </option>
                                          ))
                                        ) : (
                                          <option value={-1}>No Data</option>
                                        )}
                                      </select>
                                      {daysError && (
                                        <span className="text-danger">
                                          Select the day
                                        </span>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="assignteacher_data_padding"
                                  >
                                    <div className=" mt-1">
                                      <select
                                        class="form-control"
                                        onChange={(e) => {
                                          setperiodidError(false);
                                          periodHandler(e.target.value);
                                        }}
                                      >
                                        <option value={-1}>
                                          Select Period
                                        </option>
                                        {periodArr.length > 0 ? (
                                          periodArr.map((period) => (
                                            <option value={period.period_name}>
                                              {period.period_name}
                                            </option>
                                          ))
                                        ) : (
                                          <option value={-1}>No Data</option>
                                        )}
                                      </select>
                                      {periodidError && (
                                        <span className="text-danger">
                                          Select the Period
                                        </span>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="assignteacher_data_padding"
                                  >
                                    <div className=" mt-1">
                                      <select
                                        class="form-control"
                                        onChange={(e) => {
                                          setsubjectidError(false);
                                          setSubjectId(e.target.value);
                                        }}
                                      >
                                        <option value={-1}>
                                          Select Subjects
                                        </option>
                                        {managesubjects.length > 0 ? (
                                          managesubjects.map((item, index) => (
                                            <option value={item.id} key={index}>
                                              {item.name}
                                            </option>
                                          ))
                                        ) : (
                                          <option value={-1}>No data</option>
                                        )}
                                      </select>
                                      {subjectidError && (
                                        <span className="text-danger">
                                          Select the Subject
                                        </span>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="assignteacher_data_padding"
                                  >
                                    <div className=" mt-1">
                                      <select
                                        class="form-control"
                                        onChange={(e) => {
                                          selectLocationHandler(e.target.value);
                                          setlocationidError(false);
                                        }}
                                      >
                                        <option value={-1}>
                                          Select Location
                                        </option>
                                        {locationArr.length > 0 ? (
                                          locationArr.map((item) => (
                                            <option value={item.id}>
                                              {item.name}{" "}
                                              {item.mode === "Online"
                                                ? " - ( Online ) "
                                                : ""}
                                            </option>
                                          ))
                                        ) : (
                                          <option value={-1}>No Data</option>
                                        )}
                                      </select>
                                      {locationidError && (
                                        <span className="text-danger">
                                          Select the Location
                                        </span>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="assignteacher_data_padding"
                                  >
                                    <select
                                      class="form-control"
                                      onChange={(e) => {
                                        setteacheridError(false);
                                        setEmployeeId(e.target.value);
                                      }}
                                    >
                                      <option value={-1}>Teachers</option>
                                      {employeeArr.length > 0 ? (
                                        employeeArr.map((item, index) => (
                                          <option value={item.id} key={index}>
                                            {getFullName(item.name)}
                                         
                                          </option>
                                        ))
                                      ) : (
                                        <option value={-1}>No data</option>
                                      )}
                                    </select>
                                    {teacheridError && (
                                      <span className="text-danger">
                                        Select the Teacher
                                      </span>
                                    )}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="assignteacher_data_padding"
                                  >
                                    <Checkbox
                                      checked={isClassTeacher}
                                      onClick={(e) =>
                                        setIsClassTeacher(e.target.checked)
                                      }
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <div className="mt-1 mb-2 ml-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={assignTeacherHandler}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(AssignTeacher)));
