import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import { blankValidator, changeTimeFormat } from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const TimeTableTab = (props) => {
  let timetable_template_id = parseInt(props.id);
  const [mondayArray, setMondayArray] = useState([]);
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    if (!blankValidator(timetable_template_id) && isNaN(timetable_template_id)) {
      setMondayArray([]);
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_PeroidTimeTable,
          variables: {
            timetable_template_id,
          },
        })
        .then((response) => {
          setisloading(false);

          if (response.data.getPeriodTemplate.length > 0) {
            setMondayArray(response.data.getPeriodTemplate);
          }
        })
        .catch((e) => {
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [timetable_template_id, client]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="Heading_bold">{props.name}</div>
      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" className="table_header">
                  Period Name
                </StyledTableCell>
                <StyledTableCell className="table_header" align="left">
                  Start Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  End Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? mondayArray.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : mondayArray
              ).map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">
                    {row.period_name && row.period_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {changeTimeFormat(row.start_time)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {changeTimeFormat(row.end_time)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={mondayArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(TimeTableTab);
