import React, { useState, useEffect } from "react";
import ViewLeftPenalAccordion from "./ViewLeftPenalAccordion";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const ViewLeftPenalSubject = (props) => {
  const { row, isAllOpen } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(!open);
  }, [isAllOpen]);

  return (
    <div>
      <div
        onClick={() => {
          setOpen(!open);
        }}
      >
        {!open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        {props.className}
      </div>

      {!open &&
        row.map((rowData) => (
          <ViewLeftPenalAccordion
            {...props}
            row={rowData}
          ></ViewLeftPenalAccordion>
        ))}
    </div>
  );
};

export default ViewLeftPenalSubject;
