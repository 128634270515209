import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

import {
  dateToDateFormat,
  blankValidator,
  CapitalizeFirstFn,
  showNotificationMsz,
  getFullName,
} from "../../../utils/Validation";

function AdminManageTeacherLeave(props) {
  const parse = require("html-react-parser");
  const [Approve_LeaveRequestByApproverIdAndId, {}] = useMutation(
    Service.Approve_LeaveRequestByApproverIdAndId
  );
  const [Reject_Student_Leave, {}] = useMutation(Service.Reject_Student_Leave);
  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  // ---------------api call fn-----------------------
  const client = useApolloClient();

  const [department_id, setdepartment_id] = useState(-1);
  // ---------------local state-----------------------
  const [teacherLeaveArray, setteacherLeaveArray] = useState([]);
  const [EmployeeDataArr, setEmployeeDataArr] = useState([]);
  const [managedepartment, setmanagedepartment] = useState([]);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);
  const [user_id, setuser_id] = useState(-1);
  //-----------------
  /// Approve dialof variable
  const [opendialogConfimation, setopendialogConfimation] = useState(false);

  const [approveid, setapproveid] = useState("");
  const [approvename, setapprovename] = useState("");
  const [approvefromdate, setapprovefromdate] = useState("");
  const [approvetodate, setapprovetodate] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  /// reject dialof variable
  const [openrejectdialogConfimation, setopenrejectdialogConfimation] =
    useState(false);
  const [rejectid, setrejectid] = useState("");
  const [rejectname, setrejectname] = useState("");
  const [rejectfromdate, setrejectfromdate] = useState("");
  const [rejecttodate, setrejecttodate] = useState("");

  // ---------------methods-----------------------

  useEffect(() => {
    // GetEmployeedata(-1);
    departMentdata();
  }, []);

  const getEmployeeListByDepartment = (id) => {
    setEmployeeDataArr([]);
    setteacherLeaveArray([]);
    setdepartment_id(parseInt(id));
    GetEmployeedata(parseInt(id));
  };

  const GetEmployeedata = (id) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Employee_All_data,
          variables: {
            institution_id,
            department_id: id,
          },
        })
        .then((response) => {
          setisloading(false);

          setEmployeeDataArr(response.data.getEmployee.reverse());
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const departMentdata = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Department_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagedepartment(response.data.getDepartment);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const getEmployeeLeaves = (id) => {
    setuser_id(parseInt(id));
    let userid = parseInt(id);

    if (userid === -1) {
      setteacherLeaveArray([]);
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_leave_Request_By_userId,
          variables: {
            user_id: userid,
            user_types: [
              {
                user_type: "EMPLOYEE",
              },
            ],
          },
        })
        .then((response) => {
          setisloading(false);

          setteacherLeaveArray(response.data.getLeaveRequestByUserId.reverse());
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const approveLeave = (item) => {
    setapproveid(item.id);
    setapprovename(item?.leave_category?.description);
    setapprovefromdate(item.from_date);
    setapprovetodate(item.to_date);
    setopendialogConfimation(true);
  };

  const approveLeaveApi = (ID) => {
    try {
      setisloading(true);
      Approve_LeaveRequestByApproverIdAndId({
        variables: {
          approver_id: parseInt(props.data.userid),
          id: parseInt(approveid),
          institution_id: parseInt(props.data.institution_id),
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.approveLeaveRequestByApproverIdAndId.message,
            "success"
          );

          setopendialogConfimation(false);
          setisloading(false);
          getEmployeeLeaves(user_id);
          setIsUpdated(true);
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
      setopendialogConfimation(false);
      setisloading(false);
    }
  };

  const rejectLeave = (item) => {
    setrejectid(item.id);
    setrejectname(item?.leave_category?.description);
    setrejectfromdate(item.from_date);
    setrejecttodate(item.to_date);
    setopenrejectdialogConfimation(true);
  };

  const rejectLeaveApi = () => {
    try {
      setisloading(true);
      Reject_Student_Leave({
        variables: {
          rejector_id: parseInt(props.data.userid),
          id: parseInt(rejectid),
          institution_id: parseInt(props.data.institution_id),
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.cancelLeaveRequestByRequestId.message,
            "success"
          );

          setopenrejectdialogConfimation(false);
          setisloading(false);
          getEmployeeLeaves(user_id);
          setIsUpdated(true);
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
      setopenrejectdialogConfimation(false);
      setisloading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} mynav={"/homemenu"} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold leave_content_main">
                    Staff Leave Summary
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Department</div>
                            <div className=" mt-1">
                              <select
                                class="form-control"
                                id="design_id"
                                onChange={(e) =>
                                  getEmployeeListByDepartment(e.target.value)
                                }
                              >
                                <option value={-1}>Department</option>
                                {managedepartment.length > 0 &&
                                  managedepartment.map((item, index) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Employee List
                            </div>
                            <div className="inputMarginright mt-1 mb-1 ml-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  getEmployeeLeaves(e.target.value)
                                }
                              >
                                <option value={-1}>Employee List</option>
                                {EmployeeDataArr.length > 0 &&
                                  EmployeeDataArr.map((item) => (
                                    <option value={item.id}>
                                      {getFullName(item.name)}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End End
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Hours
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? teacherLeaveArray.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : teacherLeaveArray
                                ).map((row) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {row && row.subject}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        <span>
                                          {dateToDateFormat(row.from_date)}
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <span>
                                          {dateToDateFormat(row.to_date)}
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.hours === undefined ||
                                        row.hours === null ? (
                                          ""
                                        ) : (
                                          <span>{row.hours}</span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {!blankValidator(row.status) ? (
                                          "No Data"
                                        ) : (
                                          <span>{row.status}</span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="center">
                                        {row.status === "APPROVED" && (
                                          <div className="d-flex justify-content-center">
                                            <span
                                              className="pr-2 row_details_color"
                                              onClick={() =>
                                                props.navigate(
                                                  "/reviewMyLeave",
                                                  {
                                                    state: {
                                                      data: row,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Open
                                            </span>
                                          </div>
                                        )}
                                        {row.status === "PENDING" && (
                                          <div className="d-flex justify-content-center">
                                            <span
                                              className="pr-2 row_details_color"
                                              onClick={() =>
                                                props.navigate(
                                                  "/reviewMyLeave",
                                                  {
                                                    state: {
                                                      data: row,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Open
                                            </span>
                                            |
                                            <span
                                              className="pr-2 pl-2 row_details_color"
                                              onClick={() => approveLeave(row)}
                                            >
                                              Approve
                                            </span>
                                            |
                                            <span
                                              className=" pl-2 row_details_color"
                                              onClick={() => rejectLeave(row)}
                                            >
                                              Reject
                                            </span>
                                          </div>
                                        )}
                                        {row.status === "REJECTED" && (
                                          <div className="d-flex justify-content-center">
                                            <span
                                              className="pr-2 row_details_color"
                                              onClick={() =>
                                                props.navigate(
                                                  "/reviewMyLeave",
                                                  {
                                                    state: {
                                                      data: row,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              Open
                                            </span>
                                          </div>
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={teacherLeaveArray.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Are you Sure want to Approve ?</div>
          <div className="pl-5 pr-5 mt-4">Subject : {approvename}</div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => approveLeaveApi(approveid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={openrejectdialogConfimation}
        onClose={() => {
          setopenrejectdialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenrejectdialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Are you Sure want to Reject ?</div>
          <div className="pl-5 pr-5 mt-4">Subject : {rejectname}</div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={rejectLeaveApi}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenrejectdialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminManageTeacherLeave));
