import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import ReactPlayer from "react-player/youtube";
import * as Service from "../../../services/index";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Loder from "../../Loder/Loder";
import axios from "axios";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  blankValidator,
  LinkValidator,
  showNotificationMsz,
} from "../../../utils/Validation";
import { getTopicLinks } from "../../../services/index";

const ViewStudyRightPenal = (props) => {
  let errorMsz = "Something went wrong, Please try again";
  const { className, subjectName, item } = props.topicID;

  const {
    updateApi,
    setUpdateApi,
    showSMDialog,
    showAddSMDlgHandler,
    getListArr,
  } = props;
  const client = useApolloClient();
  const [topicLinkName, setTopicLinkName] = useState("");
  const [studyTopicId, setStudyTopicId] = useState("");
  const [isZooming, setIsZooming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subTopicName, setSubTopicName] = useState("");
  const [subTopicNameError, setSubTopicNameError] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [videoLinkError, setVideoLinkError] = useState(false);
  const [linkType, setLinkType] = useState("");
  const [linkTypeError, setLinkTypeError] = useState(false);
  const [fileName, setFileName] = useState({});
  const [fileNameError, setFileNameError] = useState(false);
  const [VideoOpen, setVideoOpen] = useState(false);
  const [documentOpen, setdDcumentOpen] = useState(false);
  const [videolinkPlay, setVideolinkPlay] = useState("");
  const [documentImage, setDocumentImage] = useState("");
  const [documentDeleteID, setDocumentDeleteID] = useState("");
  const [deleteTopicName, setDeleteTopicName] = useState("");
  const [deleteDailogOpen, setDeleteDailogOpen] = useState(false);
  const [TopicLink, setTopicLink] = useState([]);
  const [topicUpdateDialogOpen, setTopicUpdateDialogOpen] = useState(false);
  const [addTopicLinks, {}] = useMutation(Service.addTopicLinks);
  const [updateTopicLinks, {}] = useMutation(Service.updateTopicLinks);
  const [Delete_Topic_link, {}] = useMutation(Service.Delete_Topic_link);

  useEffect(() => {
    if (item?.id) {
      getTopivLink();
    }
  }, [props.topicID]);

  const getTopivLink = async () => {
    try {
      const { data } = await client.query({
        query: getTopicLinks,
        variables: {
          topic_id: +item?.id,
        },
      });

      if (data) setTopicLink(data.getTopicLinks);
    } catch (error) {}
  };
  const fileUploaderHandler = (e) => {
    try {
      setFileNameError(false);
      setVideoLink("");
      setIsLoading(true);
      // select file
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      // create form data
      let fileData = new FormData();
      //add file to form data
      fileData.append("file", file);
      // axios api call
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          let fileData = {
            filename: response.data.filename,
            path: response.data.path,
            type: response.data.type,
            name: response.data.name,
          };
          await setFileName(fileData);

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setIsLoading(false);
      console.log("catch and try error", error);
    }
  };

  // update topic dailog
  const UpdateTopicDialogOpen = async (row) => {
    const { id, name, link, type, document } = row;
    setTopicUpdateDialogOpen(true);
    setStudyTopicId(id);
    setTopicLinkName(name);
    setSubTopicName(name);
    setVideoLink(link);
    setLinkType(type);
    // if (type === "Document File") {
    //   await setFileName(document?.fileName);
    // }
  };
  const updateTopicLinkHandler = async () => {
    try {
      if (!topicLinkName) {
        setSubTopicNameError(true);
        return;
      }
      if (!linkType) {
        setLinkTypeError(true);
        return;
      }
      if (linkType === "Link") {
        if (!LinkValidator(videoLink)) {
          setVideoLinkError(true);
          setFileName({});
          return;
        }
      }
      if (linkType === "Document File") {
        if (!blankValidator(fileName)) {
          setFileNameError(true);
          setVideoLink("");
          return;
        }
      }

      setIsLoading(true);
      const { data } = await updateTopicLinks({
        variables: {
          name: subTopicName,
          id: +studyTopicId,
          link: videoLink,
          type: linkType,
          topic_document: fileName,
        },
      });
      if (data && data.updateTopicLinks) {
        showNotificationMsz(data.updateTopicLinks?.message, "success");
        setTopicUpdateDialogOpen(false);
        getTopivLink();
        setUpdateApi(!updateApi);
        setTopicLinkName("");
        // setSubTopicName("");
        setVideoLink("");
        setLinkType("");
        setFileName({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showNotificationMsz(errorMsz, "danger");
    }
  };
  const PlayDialogOpen = (row) => {
    const { id, name, link, type, document } = row;
    if (type === "Link") {
      setVideoOpen(true);
      setVideolinkPlay(link);
    }
    if (type === "Document File") {
      // window.open(Service.baseUrl + "/" + document.path);
      setDocumentImage(document);
      setdDcumentOpen(true);
    }
  };
  const showDeleteDlgHandler = (row) => {
    setDeleteTopicName(row.name);
    setDocumentDeleteID(row.id);
    setDeleteDailogOpen(true);
  };
  const deleteTopicHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await Delete_Topic_link({
        variables: { id: +documentDeleteID },
      });

      if (data && data.deleteTopicLinks) {
        showNotificationMsz(data.deleteTopicLinks.message, "success");
        setUpdateApi(true);
        setDeleteDailogOpen(false);
        getTopivLink();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showNotificationMsz(errorMsz, "danger");
    }
  };
  // add topic dailog
  const AddTopicDialogOpen = (item) => {
    showAddSMDlgHandler(true);
    // setTopicDialogOpen(true);
    setStudyTopicId(item.id);
    setSubTopicName("");
    setVideoLink("");
    setLinkType("");
    setFileName({});
  };

  const addTopicLinkHandler = async () => {
    if (!topicLinkName) {
      setSubTopicNameError(true);
      return;
    }
    if (!linkType) {
      setLinkTypeError(true);
      return;
    }
    if (linkType === "Link") {
      if (!LinkValidator(videoLink)) {
        setVideoLinkError(true);
        setFileName({});
        return;
      }
    }
    if (linkType === "Document File") {
      if (!blankValidator(fileName)) {
        setFileNameError(true);
        setVideoLink("");
        return;
      }
    }

    try {
      setIsLoading(true);
      const { data } = await addTopicLinks({
        variables: {
          name: topicLinkName,
          topic_id: +studyTopicId,
          link: videoLink,
          type: linkType,
          topic_document: fileName,
        },
      });
      if (data && data.addTopicLinks) {
        showNotificationMsz(data.addTopicLinks.message, "success");

        getTopivLink();
        showAddSMDlgHandler(false);
        setTopicLinkName("");
        setVideoLink("");
        setLinkType("");
        setFileName({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showNotificationMsz(errorMsz, "danger");
    }
  };
  const downloadFileHandler = async (url) => {
    if (url) window.open(url);
  };
  return (
    <div>
      {" "}
      <Card className="right_penal_card_height">
        <div className="admin_Study_inner_padding">
          {item && (
            <div>
              <div
                className="text-right"
                onClick={(event) => {
                  event.stopPropagation();
                  AddTopicDialogOpen(item);
                }}
              >
                <i className="fa fa-plus-circle cursor add_plus_color"></i>
                <span className="sub_topic_color ml-1">
                  Add new study material
                </span>
              </div>
              <div className="view_right_tpoic_header">
                <span className="view_right_class_header">{className}</span>
                {" > "}
                <span className="view_right_class_header">{subjectName}</span>
                {" > "}
                <span className="page_header_study_penal">{item.name} </span>
              </div>
            </div>
          )}

          <div>
            <div className="pt-2">
              <ol type="1">
                {TopicLink &&
                  TopicLink.map((row) => (
                    <li>
                      <div className="d-flex pt-2">
                        <div className="pl-3">
                          <span
                            className="sub_topic_color"
                            onClick={() => PlayDialogOpen(row)}
                          >
                            {" "}
                            <p style={{ textTransform: "capitalize" }}>
                              {row.name}
                            </p>
                          </span>
                        </div>
                        <div className="right_penal_flex_icon">
                          <i
                            class="fa fa-pencil cursor"
                            onClick={() => UpdateTopicDialogOpen(row)}
                          ></i>
                          <i
                            class="fa fa-trash cursor"
                            onClick={() => {
                              showDeleteDlgHandler(row);
                            }}
                          ></i>
                        </div>
                      </div>
                    </li>
                  ))}
              </ol>
            </div>
          </div>
        </div>
      </Card>
      {/* update topic dialog  */}
      <Dialog
        open={topicUpdateDialogOpen}
        onClose={() => {
          setTopicUpdateDialogOpen(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setTopicUpdateDialogOpen(false);
                }}
              ></i>
            </div>
          </div>
          <div>
            <div>
              <h3>Update Study Material</h3>
            </div>
            <div className=" inputMarginright ml-4 mr-4 mt-2">
              <lebel className="text_filed_heading float-left">Name</lebel>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={subTopicName}
                onChange={(e) => {
                  if (subTopicNameError) setSubTopicNameError(false);
                  setSubTopicName(e.target.value);
                }}
              />
              {subTopicNameError && (
                <span className="text-danger">Enter Name</span>
              )}
            </div>

            {linkType === "Link" ? (
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                <lebel className="text_filed_heading float-left">Link</lebel>{" "}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Link"
                  value={videoLink}
                  onChange={(e) => {
                    setVideoLinkError(false);
                    setVideoLink(e.target.value);
                    setFileName({});
                  }}
                />
                {videoLinkError && (
                  <span className="text-danger">Enter File</span>
                )}
              </div>
            ) : (
              ""
            )}
            {linkType === "Document File" ? (
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                {" "}
                <lebel className="text_filed_heading float-left">
                  Select File
                </lebel>
                <div class="custom-file">
                  <input
                    type="file"
                    className="form-control"
                    onChange={fileUploaderHandler}
                  />
                </div>
                {fileNameError && (
                  <span className="text-danger">Choose File</span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => {
                    updateTopicLinkHandler();
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setTopicUpdateDialogOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      {/* video dialog  */}
      <Dialog
        open={VideoOpen}
        onClose={() => {
          setVideoOpen(false);
        }}
        className="dailog_formatting"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <span
            className="cut_dailog_icon float-right"
            onClick={() => {
              setVideoOpen(false);
            }}
          >
            <i className="fa fa-times " aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div>
            <ReactPlayer url={videolinkPlay} className="react_video_width" />
          </div>
        </DialogContent>
      </Dialog>
      {/* doucment dailog */}
      <Dialog
        open={documentOpen}
        onClose={() => {
          setdDcumentOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        fullScreen={isZooming ? true : false}
      >
        <DialogTitle>
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + documentImage.path}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-expand ml-3 mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setIsZooming(!isZooming);
              }}
            ></i>
            <i
              className="fa fa-times ml-3 mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setdDcumentOpen(false);
              }}
            ></i>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <iframe
              style={{ width: "100%", height: "60vh" }}
              src={`${Service.baseUrl}/${documentImage.path}`}
              height={window.innerHeight}
              width={window.innerWidth}
            />
          </div>
        </DialogContent>
      </Dialog>
      {/* delete dialog */}
      <Dialog
        open={deleteDailogOpen}
        onClose={() => {
          setDeleteDailogOpen(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDeleteDailogOpen(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Confirm delete of study material : {deleteTopicName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={deleteTopicHandler}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setDeleteDailogOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
        {/* <DialogContent>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Leave Category ?
          </div>
        </DialogContent>
        <Grid className="grid_main_div mt-2  mb-2 text-center">
          <Grid item md={2}></Grid>
          <Grid item md={8}>
            <div className="acceptation_btn">
              <Button
                variant="contained"
                className="add_new_query_btn button_login_decoration mr-2"
                // onClick={() => deleteLeaveHandler(deleteId)}
              >
                Ok
              </Button>
              <Button
                variant="contained"
                className="add_new_query_btn button_login_decoration"
                onClick={() => {
                  setDeleteDailogOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid> */}
      </Dialog>
      {/* add topic dailog */}
      <Dialog
        open={showSMDialog}
        onClose={() => {
          showAddSMDlgHandler(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  showAddSMDlgHandler(false);
                }}
              ></i>
            </div>
          </div>
          <div>
            <div>
              <h3>Add Study Material</h3>
            </div>
            <div className=" inputMarginright ml-4 mr-4 mt-2">
              <lebel className="text_filed_heading float-left">Name</lebel>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={topicLinkName}
                onChange={(e) => {
                  setTopicLinkName(e.target.value);
                  setSubTopicNameError(false);
                  //setSubTopicName(e.target.value);
                }}
              />
              {subTopicNameError && (
                <span className="text-danger">Enter Name</span>
              )}
            </div>
            <div className=" inputMarginright ml-4 mr-4 mt-2">
              <lebel className="text_filed_heading float-left">
                Select Type
              </lebel>
              <select
                className="form-control "
                value={linkType}
                onChange={(e) => {
                  setLinkType(e.target.value);
                  setLinkTypeError(false);
                }}
              >
                <option value={-1}>Select Type</option>
                <option value="Link">Link</option>
                <option value="Document File">Document File</option>
              </select>
              {linkTypeError && (
                <span className="text-danger">Select Type</span>
              )}
            </div>
            {linkType === "Link" ? (
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                {" "}
                <lebel className="text_filed_heading float-left">Link</lebel>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Link"
                  value={videoLink}
                  onChange={(e) => {
                    setVideoLinkError(false);
                    setVideoLink(e.target.value);
                    setFileName({});
                  }}
                />
                {videoLinkError && (
                  <span className="text-danger">Enter File</span>
                )}
              </div>
            ) : (
              ""
            )}
            {linkType === "Document File" ? (
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                {" "}
                <lebel className="text_filed_heading float-left">
                  Select File
                </lebel>
                <div class="custom-file">
                  <input
                    type="file"
                    className="form-control"
                    onChange={fileUploaderHandler}
                  />
                </div>
                {fileNameError && (
                  <span className="text-danger">Choose File</span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => {
                    addTopicLinkHandler();
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    showAddSMDlgHandler(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isLoading} />
    </div>
  );
};

export default ViewStudyRightPenal;
