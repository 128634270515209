import React, { useState, useEffect } from "react";
import "./Assignment.css";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";

import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const ContinueAssignment = (props) => {
  const [isloading, setisloading] = useState(false);
  let userId = parseInt(props.data.userid);
  const parse = require("html-react-parser");
  const [In_Progress_Assignment, {}] = useMutation(
    Service.In_Progress_Assignment
  );
  const [Submit_Assignment_Student, {}] = useMutation(
    Service.Submit_Assignment_Student
  );
  const client = useApolloClient();
  let institution_Name = props.data.institution_name;
  const { data } = props.location.state;
  const assignment_id = data?.assignment_id;
  let AssignmentName = data?.assignment?.name;
  let Instructions = data?.assignment?.description;
  let status = data?.status;
  const [questioarr, setquestioarr] = useState([]);
  const startAssignment = () => {
    try {
      setisloading(true);
      In_Progress_Assignment({
        variables: {
          assignment_id: parseInt(data.assignment_id),
          user_id: userId,
        },
      })
        .then((response) => {
          setisloading(false);
          props.navigate("/assignmentsubmission", { state: { data: data } });
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Assignment_Question,
          variables: {
            assignment_id: assignment_id,
            student_assignment_id: parseInt(data.id),
          },
        })
        .then(
          (response) => {
            setisloading(false);
            if (
              response.data.getAssignmentQuestion === undefined ||
              response.data.getAssignmentQuestion.length <= 0
            ) {
              return;
            }
            let temp = [];
            response.data.getAssignmentQuestion.map((data) => {
              data["answer"] = "";
              temp.push(data);
            });

            setquestioarr(temp);
          },

          (err) => {
            setisloading(false);
            showNotificationMsz(err, "danger");
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("exam catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("exam t and c error", error);
    }
  }, []);
  // will submit response for revoew
  const submitAssignmentForReview = () => {
    try {
      setisloading(true);
      Submit_Assignment_Student({
        variables: {
          assignment_id: parseInt(assignment_id),
          user_id: userId,
        },
      })
        .then(
          (response) => {
            showNotificationMsz(
              response.data.submitAssignment.message,
              "success"
            );

            setisloading(false);
            props.navigate("/studentHomePage");
          },
          (err) => {
            setisloading(false);

            showNotificationMsz(err, "danger");
          }
        )
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />
              <Grid className="grid_main_div">
                <Grid item md={8}>
                  <Card>
                    <div className="p-3">
                      <Grid className="grid_main_div bg-white pt-2 justify-content-between">
                        <Grid item md={5} classname="pl-3">
                          <div className="continueassignment_font">
                            <label>Assignment Name</label>
                            <p className="text-info">{AssignmentName}</p>
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={1}
                          className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
                        >
                          <div className="text-info font-weight-bold">
                            {questioarr.length}
                          </div>
                          <div className=" font-weight-bold assignemt_Portion_font">
                            Total Questions{" "}
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={1}
                          className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
                        >
                          <div className="text-info font-weight-bold">
                            {
                              questioarr.filter((item) => {
                                return blankValidator(data["answer"]);
                              }).length
                            }
                          </div>
                          <div className=" font-weight-bold assignemt_Portion_font">
                            Question Attempted
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={1}
                          className=" rounded-top rounded-bottom rounded-right rounded-left mr-2 text-center"
                        >
                          <div className="text-info font-weight-bold">
                            {questioarr.length -
                              questioarr.filter((data) => {
                                return blankValidator(data["answer"]);
                              }).length}
                          </div>
                          <div className=" font-weight-bold assignemt_Portion_font">
                            Remaining Questions
                          </div>
                        </Grid>
                        <Grid item md={2}></Grid>
                      </Grid>
                      {Instructions === "" ? (
                        ""
                      ) : (
                        <div className="continueassignment_font mt-2">
                          <label className="text-primary">Instructions </label>
                          <p>{Instructions}</p>
                        </div>
                      )}
                      <div className="button_right_shift">
                        {questioarr && questioarr.length !== 0 ? (
                          <div>
                            {status === "INPROGRESS" ? (
                              <Button
                                variant="contained"
                                className="conitnue_button_color"
                                onClick={() => {
                                  props.navigate("/assignmentsubmission", {
                                    state: {
                                      data: data,
                                    },
                                  });
                                }}
                              >
                                Continue
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                className="conitnue_button_color"
                                onClick={startAssignment}
                              >
                                Start
                              </Button>
                            )}
                          </div>
                        ) : (
                          <Button
                            variant="contained"
                            className="conitnue_button_color"
                            onClick={() => {
                              submitAssignmentForReview();
                              //  startAssignmnetandMakeInProgress(row.id);
                            }}
                          >
                            Complete
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <div className="bg-white mb-1 ml-2">
                    <div className="enquiry_heading">
                      <div className="mt-1 ml-2 Heading_bold">
                        All Questions
                      </div>
                    </div>
                    <div className="card_margin_both_side">
                      <Grid className="grid_main_div mb-1">
                        <Grid item md={12}>
                          {questioarr.map((item, index) => (
                            <Card
                              className="inputMarginright m-2 p-1 onHover_cursor"
                              style={{
                                backgroundColor:
                                  item["answer"] !== undefined &&
                                  item["answer"] !== ""
                                    ? "#0070bb "
                                    : "",
                                color:
                                  item["answer"] !== undefined &&
                                  item["answer"] !== ""
                                    ? "white "
                                    : "",
                              }}
                              // id={
                              //   questionindex === index
                              //     ? "Selected_Card_border"
                              //     : "Card_border"
                              // }
                              // onClick={() => {
                              //   select(item, index);
                              // }}
                            >
                              <div className="enquiry_heading">
                                <div>
                                  <div className="font_style_question pt-2">
                                    {parse.default(item.description)}
                                  </div>
                                </div>
                                <div className="text-white icon_fonta">
                                  {item["answer"] !== undefined &&
                                  item["answer"] !== "" ? (
                                    <span>
                                      <i class="fa fa-check-circle"></i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Card>
                          ))}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* --------full page end----- */}
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ContinueAssignment));
