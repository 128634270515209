import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import "react-phone-input-2/lib/style.css";

//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import {
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation.js";

const AssignRollNo = ({ manageInstitution, navigate, classId }) => {
  //-------------------------------global id-------------------------
  let institution_id = parseInt(manageInstitution.institution_id);
  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  //-------------------------------api calls-------------------------
  const client = useApolloClient();

  //-------------------------------local state-------------------------

  //Assign RollNumber TO Student
  const [Assign_Roll_Number_To_Student, {}] = useMutation(
    Service.Assign_Roll_Number_To_Student
  );
  const [academicSessionId, setAcademicSessionId] =
    useState(academic_session_id);
  const [academicSessionArr, setAcademicSessionArr] = useState([]);

  const [gradeID, setgradeID] = useState(classId !== undefined ? classId : -1);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [classArr, setClassArr] = useState([]);

  //error
  const [academicSessionError, setacademicSessionError] = useState(false);
  const [classnameError, setclassnameError] = useState(false);
  //-------------------------------methods-------------------------
  useEffect(() => {
    setIsLoading(true);

    try {
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setAcademicSessionArr(response.data.getAllAcademicSession);
          getclasslistData(academicSessionId);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }

    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, client, academic_session_id]);
  const getclasslistData = (academicSessionId) => {
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academicSessionId),
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  const academicSessionHandler = (academicSessionId) => {
    setacademicSessionError(false);
    setIsLoading(true);
    if (parseInt(academicSessionId) === -1) {
      let temp = [];

      setClassArr(temp);
      setIsLoading(false);
      setAcademicSessionId(academicSessionId);
      return;
    }
    setAcademicSessionId(academicSessionId);
    getclasslistData(academicSessionId);
  };
  const GradeIDset = (val) => {
    setclassnameError(false);
    var grade_id = parseInt(val);

    if (grade_id === -1) {
      setgradeID("");
      return;
    }
    setgradeID(grade_id);
  };

  const AssignRollNumber = (val) => {
    if (!blankValidator(academicSessionId)) {
      setacademicSessionError(true);
      return;
    }
    if (!blankValidator(gradeID)) {
      setclassnameError(true);
      return;
    }
    let grade_id = parseInt(val);
    try {
      setIsLoading(true);
      Assign_Roll_Number_To_Student({
        variables: {
          grade_id,
        },
      }).then(
        (response) => {
          setIsLoading(false);
          showNotificationMsz(
            response.data.assignRollnumberstoAClass.message,
            "success"
          );
        },
        (err) => {
          setIsLoading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div>
      <p className="text-danger">
        Note: This will update the rollnumbers of all students in the class.
      </p>
      <Grid className="grid_main_div">
        <Grid item md={6} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Academic Session</h6>
          <div className=" mt-1">
            <select
              class="form-control"
              value={academicSessionId}
              onChange={(e) => {
                academicSessionHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Academic Session</option>
              {academicSessionArr.length > 0 ? (
                academicSessionArr.map((item) => (
                  <option value={item.id}>{item.session_name}</option>
                ))
              ) : (
                <option value={-1}>No Data</option>
              )}
            </select>
            {academicSessionError && (
              <span className="text-danger">Select the Academic Session</span>
            )}
          </div>
        </Grid>
        <Grid item md={6} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Class</h6>
          <div className=" mt-1">
            <select
              value={gradeID}
              class="form-control"
              onChange={(e) => GradeIDset(e.target.value)}
            >
              <option value={-1}>Select Class</option>
              {classArr.length > 0 ? (
                classArr.map((item) => (
                  <option value={item.id}>{item.gradeName}</option>
                ))
              ) : (
                <option value={-1}>No Data</option>
              )}
            </select>
            {classnameError && (
              <span className="text-danger">Select the Class</span>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid className="grid_main_div  p-3">
        <Grid item md={11}></Grid>
        <Grid item md={1}>
          <Button
            variant="contained"
            className="button_enquiry_details button_login_decoration button_width"
            onClick={() => AssignRollNumber(gradeID)}
          >
            Assign
          </Button>
        </Grid>
      </Grid>

      <Loder loading={isLoading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(AssignRollNo);
