import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useMutation } from "@apollo/client";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  blankValidator,
  showNotificationMsz,
  timeStampToDatedashtoDDMMYY,
} from "../../../utils/Validation";

const ManageInvoice = (props) => {
  //---------------------global id---------------------------------
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );
  let Organization_Name = props.manageInstitution.institution_name;
  //---------------------api calls---------------------------------
  const client = useApolloClient();
  const classes = useStyles();
  const [Delete_Invoice_Data, {}] = useMutation(Service.Delete_Invoice_Data);

  //---------------------local state---------------------------------
  const [page, setPage] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [deleteInvoiceId, setdeleteInvoiceId] = useState("");
  const [DeleteInvoiceName, setDeleteInvoiceName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ManageInvoiveData, setManageInvoiveData] = useState([]);
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [selectedDailog, setSelectedDailog] = useState(false);
  const [classID, setClassID] = useState("");
  const [classIDError, setClassIDError] = useState(false);
  const [sectionid, setsectionid] = useState("");
  const [sectionidError, setsectionidError] = useState(false);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [startDateValue, setStartDateValue] = useState("");
  const [startDateValueError, setStartDateValueError] = useState(false);
  const [endDateValue, setEndDateValue] = useState("");
  const [endDateValueError, setEndDateValueError] = useState(false);
  //---------------------methods---------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterInvoice = ManageInvoiveData.filter((event) => {
    return event.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  // to get data of manage invoice assignment
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Invoice_Latest,
          variables: {
            institution_id,
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          const descendingArr = response.data.getInvoice.sort((a, b) => {
            return b.id - a.id;
          });
          setManageInvoiveData(descendingArr);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  }, [isUpdated, client, institution_id]);
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("arr try and catch", error);
    }
    classHandler(classID);
    //to get Class Data
  }, [classID]);
  const classHandler = (clId) => {
    setsectionid(-1);
    setSectionArr([]);
    if (parseInt(clId) == -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(clId),
          },
        })
        .then((response) => {
          setisloading(false);

          setSectionArr(response.data.getSectionAndGrade);
          // setSectionfromgrade(response.data.Sections)
          //setname("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const DeleteDailogBox = (data) => {
    setdeleteInvoiceId(data.id);
    setDeleteInvoiceName(data.name);
    setopendialogConfimation(true);
  };

  // to delete invoice---------------------------
  const deleteInvoiceFn = (ID) => {
    try {
      let id = parseInt(ID);
      setisloading(true);
      Delete_Invoice_Data({
        variables: { id },
      })
        .then((response) => {
          setopendialogConfimation(false);
          setisloading(false);
          if (response.data.deleteInvoice.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(response.data.deleteInvoice.message, "success");

          setIsUpdated(true);
        })
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const submitHandle = () => {
    if (!classID) {
      setClassIDError(true);
    }
    if (!sectionid || sectionid === -1) {
      setsectionidError(true);
    }
    if (!startDateValue) {
      setStartDateValueError(true);
    }
    if (!endDateValue) {
      setEndDateValueError(true);
    }
    if (!classID || sectionid === -1 || !startDateValue || !endDateValue) {
      return;
    }

    let classValue;
    let sectionValue;
    classArr.map((row) => {
      if (row.id === classID) {
        classValue = row.gradeName;
      }
    });
    sectionArr.map((row) => {
      if (row.id === sectionid) {
        sectionValue = row.name;
      }
    });
    let tempData = {
      classID: classID,
      sectionid: sectionid,
      startDateValue: startDateValue,
      endDateValue: endDateValue,
      classValue: classValue,
      sectionValue: sectionValue,
    };
    props.navigate("/CreateInvoiceForDate", {
      state: {
        data: tempData,
      },
    });
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={Organization_Name} />

            <div className="enquirydetails_background mb-2">
              <div className="d-flex">
                <div className="col-8">
                  <div className="mt-3 ml-3 Heading_bold">Manage Invoice</div>
                </div>
                <div className="col-2" style={{ marginTop: "1.5%" }}>
                  {/* <Button
                    variant="contained"
                    className="addButtonwidth button_login_decoration button_width"
                    onClick={() => {
                      setSelectedDailog(true);
                    }}
                  >
                    Create Invoice For Date
                  </Button> */}
                </div>
                <div className="col-2" style={{ marginTop: "1.5%" }}>
                  <Button
                    variant="contained"
                    className="addButtonwidth button_login_decoration button_width"
                    onClick={() => {
                      props.navigate("/createinvoice", {
                        pageType: "CREATE",
                      });
                    }}
                  >
                    Create Invoice
                  </Button>
                </div>
              </div>

              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mb-4">
                        <Grid item md={4}>
                          <div className="inputMarginright mt-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search By Title"
                              autoComplete="off"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4}>
                          <div className="mt-2">
                            <input
                              type="date"
                              className="form-control"
                              autoComplete="off"
                              // value={search}
                              // onChange={(e) => {
                              //   setSearch(e.target.value);
                              // }}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="table_foramtitng">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Title
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Due Date
                                </StyledTableCell>

                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Recurrence
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Actions
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? filterInvoice.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : filterInvoice
                              ).map((row) => (
                                <StyledTableRow>
                                  <StyledTableCell scope="row" align="left">
                                    {!blankValidator(row.name) ? "" : row.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {timeStampToDatedashtoDDMMYY(row.due_date)}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {!blankValidator(row.recurrence)
                                      ? ""
                                      : row.recurrence}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <span
                                      className="actionStyle row_details_color"
                                      onClick={() =>
                                        props.navigate("/editFeeInvoice", {
                                          state: {
                                            invoiceObj: row,
                                            pageType: "ManageInvoice",
                                          },
                                        })
                                      }
                                    >
                                      Open
                                    </span>
                                    |
                                    <span
                                      className="actionStyle row_details_color"
                                      onClick={() =>
                                        props.navigate("/assignInvoice", {
                                          state: {
                                            invoiceObj: row,
                                          },
                                        })
                                      }
                                    >
                                      Assign
                                    </span>
                                    |
                                    <span
                                      className="actionStyle row_details_color"
                                      onClick={() => DeleteDailogBox(row)}
                                    >
                                      Delete
                                    </span>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterInvoice.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure, Want to delete the Invoice : {DeleteInvoiceName}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteInvoiceFn(deleteInvoiceId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      {/* ------select date dailog------ */}
      <Dialog
        open={selectedDailog}
        onClose={() => {
          setSelectedDailog(false);
        }}
        maxWidth="md"
        fullWidth="fullWidth"
        className="dailog_formatting"
      >
        <div className="p-2">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setSelectedDailog(false);
                }}
              ></i>
            </div>
          </div>
          <div>
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label>Select Class</label>
                  <select
                    className="form-control"
                    value={classID}
                    onChange={(e) => {
                      setClassID(e.target.value);
                      setClassIDError(false);
                    }}
                  >
                    <option value={-1}>Select Class</option>
                    {classArr.length > 0 &&
                      classArr.map((item) => (
                        <option value={item.id}>{item.gradeName}</option>
                      ))}
                  </select>
                  {classIDError && (
                    <span className="text-danger">Enter the class</span>
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label>Select Section</label>
                  <select
                    className="form-control"
                    value={sectionid}
                    onChange={(e) => {
                      setsectionid(e.target.value);
                      setsectionidError(false);
                    }}
                  >
                    <option value={-1}>Select Section</option>
                    {sectionArr.length != 0 &&
                      sectionArr.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                  {sectionidError && (
                    <span className="text-danger">Enter the section</span>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid className="grid_main_div mt-2">
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label>Start Date</label>
                  <input
                    className="form-control"
                    type="date"
                    value={startDateValue}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                      setStartDateValueError(false);
                    }}
                  />
                  {startDateValueError && (
                    <span className="text-danger">Enter the start date</span>
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label>End Date</label>
                  <input
                    className="form-control"
                    type="date"
                    value={endDateValue}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                      setEndDateValueError(false);
                    }}
                  />
                  {endDateValueError && (
                    <span className="text-danger">Enter the end date</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          {/* action section */}
          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => {
                    submitHandle();
                  }}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setSelectedDailog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageInvoice));
