import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import CountDisplay from "./CountDisplay";
import Loder from "../../Loder/Loder";

const TotalCountInvoice = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalInvoices, setTotalInvoices] = useState("");
  const [totalpaidinvoice, setTotalpaidinvoice] = useState("");
  const [totaloverdue, setTotaloverdue] = useState("");
  const client = useApolloClient();

  useEffect(() => {
    EventList();
  }, [props]);

  const EventList = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.Home_admin_get_totalcount,
        variables: {
          institution_id: parseInt(props.inst_id),
          academic_session_id: parseInt(props.acdmin_id),
        },
      });

      if (data && data.getUserCount) {
        const { invoice_count, paid_count, over_due_count } = data.getUserCount;
        setTotalInvoices(invoice_count);
        setTotalpaidinvoice(paid_count);
        setTotaloverdue(over_due_count);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div>
      <Grid className="grid_main_div">
        <Grid item md={12}>
          <div className="total_count_flex">
            <CountDisplay
              label={"Invoices"}
              count={totalInvoices}
            ></CountDisplay>
            <CountDisplay
              label={"Paid Invoices"}
              count={totalpaidinvoice}
            ></CountDisplay>
            <CountDisplay
              label={"Overdue Invoices"}
              count={totaloverdue}
            ></CountDisplay>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isLoading} />
    </div>
  );
};

export default TotalCountInvoice;
