import React, { useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import "./StudentHomePage.css";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  Inject,
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
} from "@syncfusion/ej2-react-schedule";
const Calender = (props) => {
  const [Holidaysdata] = useState([
    {
      date: "jan 20",
      event: "basant punchami",
    },
    {
      date: "jan 20",
      event: "basant punchami",
    },
    {
      date: "jan 20",
      event: "basant punchami",
    },
    {
      date: "jan 20",
      event: "basant punchami",
    },
    {
      date: "jan 20",
      event: "basant punchami",
    },
    {
      date: "jan 20",
      event: "basant punchami",
    },
  ]);
  const [data] = useState([
    {
      Id: 1,
      Subject: "Paris",
      StartTime: new Date(2020, 0, 28, 10, 0),
      EndTime: new Date(2020, 0, 28, 12, 30),
      IsAllDay: false,
    },
  ]);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />

              <Grid className="grid_main_div">
                <Grid item md={8}>
                  <div className="enquirydetails_background mb-2 mr-2">
                    <div className="enquiry_heading">
                      <div className="ml-3 mt-2 Heading_bold">Calendar</div>
                    </div>
                    <div className="card_margin_both_side">
                      <Card className="pt-3 pb-4">
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div>
                              <ScheduleComponent
                                currentView="Month"
                                selectedDate={new Date()}
                                eventSettings={{ dataSource: data }}
                              >
                                <Inject
                                  services={[
                                    Day,
                                    Week,
                                    WorkWeek,
                                    Month,
                                    Agenda,
                                  ]}
                                />
                                {/* <ResourcesDirective>
                                 <ResourceDirective field="RecipientID" title="Resource Name" name="Resources"
                                 textField="Name" idField="Id" colorField="Color" dataSource={this.state.object}></ResourceDirective>
                              </ResourcesDirective> */}
                              </ScheduleComponent>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="enquirydetails_background mb-2">
                    <div className="enquiry_heading">
                      <div className="ml-3 mt-2 Heading_bold">Holidays</div>
                    </div>
                    <div className="card_margin_both_side">
                      <Card className="pt-3 pb-4">
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div className="mt-3 mb-3">
                              {Holidaysdata.map((item, index) => (
                                <Card className="pt-1 pb-1">
                                  <div>
                                    <span>{item.date}</span>
                                    <span className="ml-5">{item.event}</span>
                                  </div>
                                  <hr />
                                </Card>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default (HOC1(Calender));
