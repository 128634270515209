import React, { useState } from "react";
import { useEffect } from "react";
import ViewLeftPenalAccordion from "./ViewLeftPenalAccordion";

const Topic = (props) => {
  const {
    item,
    subjectName,
    className,
    setUChapterId,
    setUChapterName,
    setDChapterId,
    setDChapterName,
    refresh
  } = props;
  const [subtopicLevel, setsubtopicLevel] = useState(false);


  

  return (
    <div
      className="view_left_subject_add"
      onClick={() => {
        setsubtopicLevel(!subtopicLevel);
      }}
    >
      <div>
        <div className="tpoic_add_flex">
          <span
            onClick={(event) => {
              event.stopPropagation();
              props.updateSubTopicData({
                item,
                className,
                subjectName,
              });
            }}
            className="sub_topic_color"
          >
            {item.name}
          </span>
          <span className="pl-4">
            <i
              className="fa fa-pencil cursor edit_topic_icon_color"
              onClick={(event) => {
                event.stopPropagation();
                setUChapterId(item.id);
                setUChapterName(item.name);
                props.UpdateTopicDialogOpen(item);
              }}
            ></i>{" "}
          </span>
          <span className="pl-2">
            <i
              className="fa fa-trash cursor edit_topic_icon_color"
              onClick={(event) => {
                event.stopPropagation();
                setDChapterId(item.id);
                setDChapterName(item.name);
                props.DeleteTopicDialogOpen(item);
              }}
            ></i>
          </span>
        </div>
      </div>
      {subtopicLevel && (
        <div>
          <ViewLeftPenalAccordion
            {...props}
            row={item.topic_links}
            type="subtopic"
          />
        </div>
      )}
    </div>
  );
};

export default Topic;
