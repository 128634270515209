import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import PhoneInput from "react-phone-input-2";
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const config = require("../../../config/default.json");

function Setting({ TOKEN, navigation }) {
  const {
    institution_id,
    organization_id,
    institution_name,
    half_day_hours,
    full_day_hours,
  } = TOKEN;
  //-----------------------api calls------------------------
  const client = useApolloClient();
  const [Add_Or_Update_BankInformation, {}] = useMutation(
    Service.Add_Or_Update_BankInformation
  );
  const [Create_Leave_Category, {}] = useMutation(
    Service.Create_Leave_Category
  );
  const [add_Properties, {}] = useMutation(Service.add_Properties);
  const [Edit_Leave_Category, {}] = useMutation(Service.Edit_Leave_Category);
  const [addGateWay, {}] = useMutation(Service.addGateWay);
  const [Delete_Leave_Category, {}] = useMutation(
    Service.Delete_Leave_Category
  );
  //-----------------------api calls------------------------

  const [half_day, sethalf_day] = useState(-1);
  const [full_day, setfull_day] = useState(-1);
  const [full_day_hoursNumberError, setfull_day_hoursNumberError] =
    useState(false);
  const [half_day_hoursNumberError, sethalf_day_hoursNumberError] =
    useState(false);
  const [totalcountNumberError, settotalcountNumberError] = useState(false);

  //Payment setting variables
  const [gateway, setgateway] = useState("");
  const [gatewayError, setgatewayError] = useState(false);
  const [phone, setPhone] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [leaveCategoryArr, setleaveCategoryArr] = useState([]);
  const [name, setname] = useState("");
  const [hours, sethours] = useState(0);
  const [description, setdescription] = useState("");
  const [isUpdated, setisUpdated] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [OpendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [nameEdit, setnameEdit] = useState("");
  const [hoursEdit, sethoursEdit] = useState(0);
  const [descriptionEdit, setdescriptionEdit] = useState("");
  const [leaveeditid, setleaveeditid] = useState(0);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [deletename, setdeletename] = useState("");
  const [deleteId, setdeleteId] = useState(0);
  //error
  const [nameError, setnameError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [hoursError, sethoursError] = useState(false);
  const [nameEditError, setnameEditError] = useState(false);
  const [descriptionEditError, setdescriptionEditError] = useState(false);
  const [hoursEditError, sethoursEditError] = useState(false);
  const [half_day_hoursError, sethalf_day_hoursError] = useState(false);
  const [full_day_hoursError, setfull_day_hoursError] = useState(false);
  //---------------error------------------------
  const [bankNameErr, setBankNameErr] = useState(false);
  const [bankCodeErr, setBankCodeErr] = useState(false);
  const [bankAccountNumberErr, setBankAccountNumberErr] = useState(false);
  const [bankBenificieryNameErr, setBankBenificieryNameErr] = useState(false);
  //---------------error------------------------
  const [bankname, setbankname] = useState("");
  const [bankcode, setbankcode] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [beneficiaryname, setbeneficiaryname] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientIdError, setClientIdError] = useState(false);
  const [secretId, setSecretId] = useState("");
  const [SecretIdError, setSecretIdError] = useState(false);

  useEffect(() => {
    sethalf_day(half_day_hours);
    setfull_day(full_day_hours);
  }, [TOKEN]);

  useEffect(() => {
    getLeaveCategory();
    getBankInfoData();
    getLeaveCategoryConfiguration();
  }, [isUpdated]);

  useEffect(() => {
    if (gateway == -1) {
      setClientID("");
      setSecretId("");
      return;
    }
    getGateWayData();
  }, [gateway]);

  const getLeaveCategory = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Leave_Category,
        variables: {
          institution_id,
        },
      });
      if (data && data.getLeaveCategory) {
        setleaveCategoryArr(data.getLeaveCategory);
      }

      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const getLeaveCategoryConfiguration = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Properties,
        variables: {
          organization_id,
        },
      });

      if (data && data.getProperties) {
        const { half_day_hours, full_day_hours } = data.getProperties;
        sethalf_day(half_day_hours);
        setfull_day(full_day_hours);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const AddLeaveCategory = async () => {
    try {
      let isflag = false;
      if (!blankValidator(name)) {
        setnameError(true);
        isflag = true;
      }
      if (!blankValidator(description)) {
        setdescriptionError(true);
        isflag = true;
      }
      if (!blankValidator(hours) || hours === 0) {
        sethoursError(true);
        isflag = true;
      }
      if (isflag) {
        return;
      }

      setisloading(true);
      const { data } = await await Create_Leave_Category({
        variables: {
          name,
          hours: parseInt(hours),
          description,
          institution_id,
        },
      });
      if (data && data.addLeaveCategory) {
        const { message } = data.addLeaveCategory;
        showNotificationMsz(message, "success");
        setaddMangeopen(false);
        setname("");
        setdescription("");
        sethours(0);
        setisUpdated(!isUpdated);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const submitSetting = async () => {
    try {
      if (!blankValidator(full_day)) {
        setfull_day_hoursError(true);
        return;
      }
      if (!blankValidator(half_day)) {
        sethalf_day_hoursError(true);
      }

      setisloading(true);
      const { data } = await add_Properties({
        variables: {
          organization_id: organization_id,
          full_day_hours: parseInt(full_day),
          half_day_hours: parseInt(half_day),
        },
      });
      if (data && data.addProperties) {
        const { message } = data.addProperties;
        showNotificationMsz(message, "success");
        getLeaveCategoryConfiguration();
        getLeaveCategory();
        setisUpdated(!isUpdated);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const OpenEditQuery = (data) => {
    setnameEdit(data.name);
    sethoursEdit(parseInt(data.hours));
    setdescriptionEdit(data.description);
    setleaveeditid(parseInt(data.id));
    setOpendailogtoEdit(true);
  };

  const smsnumberSubmit = () => {
    if (!blankValidator(phone)) {
      setphoneError(true);
      return;
    }
  };
  const paymentSubmit = async () => {
    try {
      if (!blankValidator(gateway)) {
        setgatewayError(true);
        return;
      }
      if (!blankValidator(clientID)) {
        setClientIdError(true);
        return;
      }
      if (!blankValidator(secretId)) {
        setSecretIdError(true);
        return;
      }

      setisloading(true);
      const { data } = await addGateWay({
        variables: {
          gateway_name: gateway,
          key: clientID,
          secret: secretId,
          institution_id,
        },
      });
      if (data && data.addGateWay) {
        const { message } = data.addGateWay;
        showNotificationMsz(message, "success");
        setisUpdated(!isUpdated);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };
  const getGateWayData = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.getGatWay,
        variables: {
          institution_id,
          gateway_name: gateway,
        },
      });

      if (data && data.getGatWay) {
        const { getGatWay } = data;
        setClientID(getGatWay.secret);
        setSecretId(getGatWay.key);
      }

      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };
  const UpdateLeaveCategory = async (ID) => {
    let id = parseInt(ID);
    let isflag = false;
    if (!blankValidator(nameEdit)) {
      setnameEditError(true);
      isflag = true;
    }
    if (!blankValidator(descriptionEdit)) {
      setdescriptionEditError(true);
      isflag = true;
    }
    if (!blankValidator(hoursEdit) || hoursEdit === 0) {
      sethoursEditError(true);
      isflag = true;
    }
    if (isflag) {
      return;
    }
    try {
      setisloading(true);
      const { data } = await Edit_Leave_Category({
        variables: {
          id,
          name: nameEdit,
          hours: parseInt(hoursEdit),
          description: descriptionEdit,
          institution_id,
        },
      });

      if (data && data.updateLeaveCategory) {
        showNotificationMsz(data.updateLeaveCategory?.message, "success");
        setOpendailogtoEdit(false);
        setisUpdated(!isUpdated);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const DeleteDailogBox = (data) => {
    setdeletename(data.name);
    setdeleteId(data.id);
    setopendialogConfimation(true);
  };

  const deleteLeaveHandler = async (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      const { data } = await Delete_Leave_Category({
        variables: { id },
      });
      if (data && data.deleteLeaveCategory) {
        showNotificationMsz(data.deleteLeaveCategory?.message, "success");
        setopendialogConfimation(false);
        setisUpdated(!isUpdated);
      }

      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const sortedList = leaveCategoryArr.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const getBankInfoData = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Bank_Information,
        variables: {
          institution_id,
        },
      });
      if (data && data.getBankInfo) {
        const { bank_name, bank_code, account_number, beneficiary_name } =
          data.getBankInfo;
        setbankname(bank_name);
        setbankcode(bank_code);
        setaccountnumber(account_number);
        setbeneficiaryname(beneficiary_name);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
      console.log("error", error);
    }
  };
  const saveBankInformationHandler = async () => {
    try {
      if (!blankValidator(bankname)) {
        setBankNameErr(true);
        return;
      }
      if (!blankValidator(bankcode)) {
        setBankCodeErr(true);
        return;
      }
      if (!blankValidator(accountnumber)) {
        setBankAccountNumberErr(true);
        return;
      }
      if (!blankValidator(beneficiaryname)) {
        setBankBenificieryNameErr(true);
        return;
      }
      setisloading(true);
      const { data } = await Add_Or_Update_BankInformation({
        variables: {
          institution_id: parseInt(institution_id),
          bank_name: bankname,
          bank_code: bankcode,
          beneficiary_name: beneficiaryname,
          account_number: accountnumber,
        },
      });
      if (data && data.addAndUpdateBankInfo) {
        const { message } = data.addAndUpdateBankInfo;
        showNotificationMsz(message, "success");
        getBankInfoData();
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Settings</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {!addMangeopen ? (
                          <div className="d-flex">
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span
                              className=" ml-2 mt-1 addmanageuserfont"
                              onClick={() => setaddMangeopen(true)}
                            >
                              Add new configuration
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div
                                          className="text-right"
                                          onClick={() => setaddMangeopen(false)}
                                        >
                                          <span className="iconstoaddfee">
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        </div>
                                        <div className="text_filed_heading">
                                          Leave category
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Leave category"
                                            autoComplete="off"
                                            value={name}
                                            onChange={(e) => {
                                              setnameError(false);
                                              setname(e.target.value);
                                            }}
                                          />
                                          {nameError && (
                                            <span className="text-danger">
                                              Enter the Leave Category
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Description
                                        </div>
                                        <div className="inputMarginright mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Description"
                                            autoComplete="off"
                                            value={description}
                                            onChange={(e) => {
                                              setdescriptionError(false);
                                              setdescription(e.target.value);
                                            }}
                                          />
                                          {descriptionError && (
                                            <span className="text-danger">
                                              Enter the Description
                                            </span>
                                          )}
                                        </div>
                                      </Grid>

                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Leave count(Hours)
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Leave count(Hours)"
                                            autoComplete="off"
                                            value={hours === 0 ? "" : hours}
                                            onChange={(e) => {
                                              sethoursError(false);
                                              sethours(e.target.value);
                                            }}
                                          />
                                          {hoursError && (
                                            <span className="text-danger">
                                              Enter the Hours
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className=" mb-1">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={AddLeaveCategory}
                                        >
                                          Create
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin">
                        <Card className="cardvisible_border mb-2">
                          <div className="card_admissiondetails_height">
                            <div className="textfiled_margin">
                              <div className="">
                                <Grid className="grid_main_div">
                                  <Grid item md={2}></Grid>
                                  <Grid item md={3}>
                                    Leave category
                                  </Grid>
                                  <Grid item md={3}>
                                    Count(in Hrs)
                                  </Grid>

                                  <Grid item md={4}>
                                    Action
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </Card>
                        {sortedList.length ? (
                          sortedList.map((item, index) => (
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={2}></Grid>
                                      <Grid item md={3}>
                                        <div className=" mt-1 mb-1">
                                          <input
                                            type="text"
                                            className="form-control input_border_none department_color_disabled"
                                            placeholder="Name"
                                            disabled={true}
                                            value={item.name}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={3}>
                                        <div className=" mt-1 mb-1">
                                          <input
                                            type="text"
                                            className="form-control input_border_none department_color_disabled"
                                            placeholder="Name"
                                            disabled={true}
                                            value={item.hours}
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={4}>
                                        {" "}
                                        <div className="d-flex pt-2">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Full Day / Half Day Setting
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="text_filed_heading">
                              Full day(in Hrs)
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=" Full day(in Hrs)"
                                autoComplete="off"
                                value={full_day}
                                onChange={(e) => {
                                  setfull_day_hoursNumberError(false);
                                  setfull_day_hoursError(false);
                                  if (isNaN(e.target.value)) {
                                    setfull_day_hoursNumberError(true);
                                    return;
                                  }
                                  setfull_day(e.target.value);
                                }}
                              />
                              {full_day_hoursError && (
                                <span className="text-danger">
                                  Enter Full Day Hours
                                </span>
                              )}
                              {full_day_hoursNumberError && (
                                <p className="text-danger">
                                  Please Enter Valid Hours
                                </p>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={6}>
                            <div className="text_filed_heading">
                              Half day(in Hrs)
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Half day(in Hrs)"
                                autoComplete="off"
                                value={half_day}
                                onChange={(e) => {
                                  sethalf_day_hoursNumberError(false);
                                  sethalf_day_hoursError(false);
                                  if (isNaN(e.target.value)) {
                                    sethalf_day_hoursNumberError(true);
                                    return;
                                  }
                                  sethalf_day(e.target.value);
                                }}
                              />
                              {half_day_hoursError && (
                                <span className="text-danger">
                                  Enter Half Day Hours
                                </span>
                              )}
                              {half_day_hoursNumberError && (
                                <p className="text-danger">
                                  Please Enter Valid Hours
                                </p>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2 mb-2">
                          <Grid item md={10}></Grid>

                          <Grid item md={2}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={submitSetting}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              {/* <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">SMS Setting</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="text_filed_heading">
                              Phone Number
                            </div>
                            <div className="inputMarginright mt-1" id="mobile">
                              <PhoneInput
                                className="phone_input_width_create"
                                countryCodeEditable={false}
                                country={"in"}
                                value={phone}
                                onChange={(e) => {
                                  setphoneError(false);
                                  setPhone(e);
                                }}
                              />
                              {phoneError && (
                                <span className="text-danger">
                                  Enter Phone Number
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={6}></Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2 mb-2">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={smsnumberSubmit}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div> */}

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Manage Bank Account Setting
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div className="card_content_instition">
                              <Grid className="grid_main_div">
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Bank Name
                                  </div>
                                  <div className="inputMarginright mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Bank Name"
                                      autoComplete="off"
                                      value={bankname}
                                      onChange={(e) => {
                                        setBankNameErr(false);
                                        setbankname(e.target.value);
                                      }}
                                    />

                                    {bankNameErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Bank Code
                                  </div>
                                  <div className=" mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Bank Code"
                                      autoComplete="off"
                                      value={bankcode}
                                      onChange={(e) => {
                                        setBankCodeErr(false);
                                        setbankcode(e.target.value);
                                      }}
                                    />
                                    {bankCodeErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className="card_content_instition">
                              <Grid className="grid_main_div">
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Bank Account Number
                                  </div>
                                  <div className="inputMarginright mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Account Number"
                                      autoComplete="off"
                                      value={accountnumber}
                                      onChange={(e) => {
                                        setBankAccountNumberErr(false);
                                        setaccountnumber(e.target.value);
                                      }}
                                    />
                                    {bankAccountNumberErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Beneficiary Name
                                  </div>
                                  <div className=" mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Beneficiary Name"
                                      autoComplete="off"
                                      value={beneficiaryname}
                                      onChange={(e) => {
                                        setBankBenificieryNameErr(false);
                                        setbeneficiaryname(e.target.value);
                                      }}
                                    />
                                    {bankBenificieryNameErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <Grid className="grid_main_div">
                              <Grid item md={10}></Grid>
                              <Grid item md={2}>
                                <div className="mb-2 ">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn  button_login_decoration button_width"
                                    onClick={saveBankInformationHandler}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Payment Setting</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Select Gateway
                            </div>
                            <div className="inputMarginright mt-1" id="mobile">
                              <select
                                class="form-control"
                                id="gender"
                                value={gateway}
                                onChange={(e) => {
                                  setgatewayError(false);
                                  setgateway(e.target.value);
                                }}
                              >
                                <option value={-1}>Select</option>
                                <option value={config.config.gateWayName}>
                                  Paytm
                                </option>
                              </select>
                              {gatewayError && (
                                <span className="text-danger">
                                  Select Payment Gateway
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Client Id(MID)
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=" Client Id"
                                autoComplete="off"
                                value={clientID}
                                onChange={(e) => {
                                  setClientIdError(false);
                                  setClientID(e.target.value);
                                }}
                              />
                              {clientIdError && (
                                <span className="text-danger">
                                  Enter Client Id
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Client Secret(Key)
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Client Secret"
                                autoComplete="off"
                                value={secretId}
                                onChange={(e) => {
                                  setSecretIdError(false);
                                  setSecretId(e.target.value);
                                }}
                              />
                              {SecretIdError && (
                                <span className="text-danger">
                                  Enter Client Secret
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2 mb-2">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={paymentSubmit}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={OpendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <div className="text_filed_heading">Leave category</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Leave category"
                    autoComplete="off"
                    value={nameEdit}
                    onChange={(e) => {
                      setnameEditError(false);
                      setnameEdit(e.target.value);
                    }}
                  />
                  {nameEditError && (
                    <span className="text-danger">
                      Enter the Leave Category
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid className="grid_main_div mt-1">
              <Grid item md={6}>
                <div className="text_filed_heading">Description</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    autoComplete="off"
                    value={descriptionEdit}
                    onChange={(e) => {
                      setdescriptionEditError(false);
                      setdescriptionEdit(e.target.value);
                    }}
                  />
                  {descriptionEditError && (
                    <span className="text-danger">Enter the Descrition</span>
                  )}
                </div>
              </Grid>

              <Grid item md={6}>
                <div className="text_filed_heading">Leave count(Hours)</div>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Leave count(Hours)"
                    autoComplete="off"
                    value={hoursEdit === 0 ? "" : hoursEdit}
                    onChange={(e) => {
                      settotalcountNumberError(false);
                      sethoursEditError(false);
                      if (isNaN(e.target.value)) {
                        settotalcountNumberError(true);
                        return;
                      }
                      sethoursEdit(e.target.value);
                    }}
                  />
                  {hoursEditError && (
                    <span className="text-danger">Enter the Hours</span>
                  )}
                  {totalcountNumberError && (
                    <p className="text-danger">Please Enter Valid Hours</p>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4  mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={() => UpdateLeaveCategory(leaveeditid)}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Leave Category : {deletename}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteLeaveHandler(deleteId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  TOKEN: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(Setting)));
