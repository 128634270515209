import React, { useState, useEffect } from "react";
import Hoc1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
//import "./Organization.css";

import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";

import { baseUrl } from "../../../services/index.js";
import Dialog from "@material-ui/core/Dialog";
//redux
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const CreateUpdateOrganisation = (props) => {
  const [filename, setFileName] = useState("Add Attachment");
  const [Organization_Icon, setOrganization_Icon] = useState({});
  let pageType = props.location.state.pagetype;

  const [Update_Organization, {}] = useMutation(Service.Update_Organization);
  const [Create_Organization, {}] = useMutation(Service.Create_Organization);
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const [name, setname] = useState("");

  const [createInsForm, setCreateInsForm] = useState({
    name: "",
    icon_id: "",
    url: "",
    icon_doc: "",
  });

  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageType === "edit") {
      const Organizationdata = props.location.state.data;
      let id = parseInt(props.location.state.data.id);

      setId(id);
      setCreateInsForm({
        name: Organizationdata?.name,
        url: Organizationdata?.domain_url,
        icon_doc: Organizationdata?.document?.filename,
      });
      if (blankValidator(Organizationdata.document)) {
        setFileName(Organizationdata.document.filename);
        setimagepath(Organizationdata.document.path);
        setimageType(Organizationdata.document.type);
        setname(Organizationdata.document.name);

        const Organization_Icon = {
          path: Organizationdata.document.path,
          filename: Organizationdata.document.filename,
          type: Organizationdata.document.type,
          name: Organizationdata.document.name,
        };
        //setFileName(response.data.filename);
        setOrganization_Icon(Organization_Icon);
      }
    }
  }, [pageType, props.location.state.data]);

  const HandleImageDailogBox = () => {
    setOpenImageDailog(true);
  };

  // const [Upload_document_file] = useMutation(Service.Upload_document_file, {
  //   onCompleted: (data) => ,
  // });

  //error
  const [nameError, setnameError] = useState(false);

  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);

      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setFileName(file.name);
          setisloading(false);
          if (blankValidator(response.data)) {
            const Organization_Icon = {
              path: response.data.path,
              filename: response.data.filename,
              type: response.data.type,
              name: response.data.name,
            };
            setimagepath(response.data.path);
            setimageType(response.data.type);

            //setFileName(response.data.filename);
            setOrganization_Icon(Organization_Icon);
          }
        })
        .catch((error) => {
          setisloading(false);

          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      console.log("catch and try error", error);
    }
  };

  const [id, setId] = useState(null);
  const [urlError, seturlError] = useState(false);

  const submitOrganization = async () => {
    let flag = false;
    if (!blankValidator(createInsForm.name)) {
      setnameError(true);
      flag = true;
    }
    if (!blankValidator(createInsForm.url)) {
      seturlError(true);
      flag = true;
    }

    if (flag) {
      return;
    }

    if (pageType === "edit") {
      try {
        setisloading(true);
        Update_Organization({
          variables: {
            id: parseInt(id),
            name: createInsForm.name,
            domain_url: createInsForm.url,
            org_icon: Organization_Icon,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.updateOrganization.message,
              "success"
            );

           props.navigate("organisationSetup");
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    } else
      try {
        setisloading(true);
        await Create_Organization({
          variables: {
            name: createInsForm.name,
            domain_url: createInsForm.url,
            org_icon: Organization_Icon,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.addOrganization.message,
              "success"
            );

           props.navigate("organisationSetup");
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    {pageType === "edit" ? (
                      <div>Update Organisation</div>
                    ) : (
                      <div>Create Organisation</div>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">Name</div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Organisation Name"
                                autoComplete="off"
                                value={createInsForm.name}
                                onChange={(e) => {
                                  setnameError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    name: e.target.value,
                                  });
                                }}
                              />
                              {nameError && (
                                <span className="text-danger">
                                  Enter the Name of Organisation
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading mt-1">Domin URL</div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="URL"
                                autoComplete="off"
                                value={createInsForm.url}
                                onChange={(e) => {
                                  seturlError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    url: e.target.value,
                                  });
                                }}
                              />
                              {urlError && (
                                <span className="text-danger">
                                  Enter the Organisation URL
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading mt-2">
                          Organization Icon
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="fa fa-paperclip"></i>
                                  </span>
                                </div>
                                <div class="custom-file">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="inputGroupFile01"
                                    onChange={ImageUploadHandler}
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="inputGroupFile01"
                                  >
                                    {filename}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={12}>
                            <span
                              className="pl-2 pr-2 file_back_color cursor"
                              onClick={HandleImageDailogBox}
                            >
                              {filename}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <div className="main_button_div  mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration button_width"
                                onClick={submitOrganization}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <span>
            <img
              src={baseUrl + "/" + imagepath}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </span>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((Hoc1(CreateUpdateOrganisation)));
