import React, { useState, useEffect, useRef } from "react";
import HOC1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import YearPicker from "react-year-picker";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder";
import { store } from "react-notifications-component";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
//----------------------api calls--------------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import {
  getMonthFormat,
  isPresent,
  serverFormatDate,
  timeWithDateFormat,
  yearAttenMethod,
  dayPresentCounter,
  options,
  showNotificationMsz,
  getFullName,
} from "../../../utils/Validation";
import "./ClassOverview.css";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import ClassOverViewTotalStudent from "./ClassOverViewTotalStudent";
import ClassOverViewLeft from "./ClassOverViewLeft";
const ClassOverviewandpaymentAttendence = ({ location, navigate, data }) => {
  let academic_session_id = parseInt(data.academic_session_id);
  const componentRef = useRef();
  const ref = React.createRef();

  const [section_id, setsection_id] = useState(-1);
  const [event_id, setevent_id] = useState(-1);
  let today_Date = new Date().toJSON().slice(0, 10);
  //--------------------------api calls------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //--------------------------local state------------------------

  const pad = (n) => {
    return n < 10 ? "0" + n : n;
  };
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  let prefilledDate = `${currentYear}-${pad(currentMonth)}`;
  const [totalWorkingDaysArr, setTotalWorkingDaysArr] = useState(0);
  const [preFillMonth, setPreFillMonth] = useState(prefilledDate);
  const [yearAttenArr, setYearAttenArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [studentDayAttenArr, setStudentDayAttenArr] = useState([]);
  const [studentMonthAttenArr, setStudentMonthAttenArr] = useState([]);
  const [studentYearAttenArr, setStudentYearAttenArr] = useState([]);
  const [yearToShow, setYearToShow] = useState("");
  const [dateToShow, setdateToShow] = useState(today_Date);
  const [timeToShow, settimeToShow] = useState("");

  const [isUpdated, setIsUpdated] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [chekbox, setchekbox] = useState(0);
  const [day, setDay] = useState([]);
  const [classArr, setClassArr] = useState([]);
  const [yearArr, setYearArr] = useState([
    { title: "Jan" },
    { title: "Feb" },
    { title: "Mar" },
    { title: "Apr" },
    { title: "May" },
    { title: "Jun" },
    { title: "Jul" },
    { title: "Aug" },
    { title: "Sep" },
    { title: "Oct" },
    { title: "Nov" },
    { title: "Dec" },
  ]);

  useEffect(() => {
    setsection_id();
    getAttendenceBysection();
  }, []);

  useEffect(() => {
    monthHandler(preFillMonth);
    yearHandler(currentYear);
  }, []);

  const getAttendenceBysection = (section_id) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: today_Date,
            end_date: today_Date,
            attendance_type: "CLASS",
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setStudentDayAttenArr(response.data.getAttendanceBySection);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log(" location arr try and catch", error);
    }
  };

  // const getAttendenceByeventid = (event_id) => {
  //   try {
  //     setisloading(true);
  //     client
  //       .query({
  //         query: Service.Get_Event_Participants,
  //         variables: {
  //           event_id,
  //           date: today_Date,
  //         },
  //       })
  //       .then((response) => {
  //         setisloading(false);
  //         setStudentDayAttenArr(response.data.getEventParticipants);
  //       })
  //       .catch((err) => {
  //         setisloading(false);

  //       });
  //   } catch (error) {
  //     setisloading(false);

  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const yearHandler = async (year) => {
    try {
      setisloading(true);
      let newDate = new Date(year, 0);
      let yearAr = [];

      for (let index = 1; index <= 12; index++) {
        yearAr.push({
          month: newDate.getFullYear() + "-" + pad(index),
        });
      }
      setYearAttenArr(yearAr);

      setYearToShow(year);
      // year data procedure-------
      let yearStartingDate = await new Date(year, 0, 1);
      let yearEndDate = await new Date(year, 11, 31);

      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: serverFormatDate(yearStartingDate),
            end_date: serverFormatDate(yearEndDate),
            attendance_type: "CLASS",
            section_id,
          },
        })
        .then((response) => {
          const timetableArr = response.data.getAttendanceBySection;
          let workingDays;
          timetableArr.map((item) => {
            if (item.attendances) {
              return (workingDays = item.attendances.length);
            }
          });
          setTotalWorkingDaysArr(workingDays);
          setStudentYearAttenArr(timetableArr);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("year handler t and c error", error);
    }
  };
  const monthHandler = async (data) => {
    try {
      setPreFillMonth(data);
      setisloading(true);
      let newDate = new Date(data);
      let lastDayOfMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
      ).getDate();
      setTotalDays(lastDayOfMonth);
      settimeToShow(getMonthFormat(newDate));
      let dayArr = [];

      for (let index = 1; index <= lastDayOfMonth; index++) {
        dayArr.push({
          title: index,
          realDate: `${newDate.getFullYear()}-${pad(
            newDate.getMonth() + 1
          )}-${pad(index)}`,
        });
      }
      setDay(dayArr);
      let start_date = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      let end_date = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
      await client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: serverFormatDate(start_date),
            end_date: serverFormatDate(end_date),
            attendance_type: "CLASS",
            section_id,
          },
        })
        .then((response) => {
          const timetableArr = response.data.getAttendanceBySection;
          let workingDays;
          timetableArr.map((item) => {
            if (item.attendances) {
              return (workingDays = item.attendances.length);
            }
          });
          setTotalWorkingDaysArr(workingDays);
          setStudentMonthAttenArr(timetableArr);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log(" location arr try and catch", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dayWiseAttendanceHandler = (date) => {
    setdateToShow(date);
    setisloading(true);
    client
      .query({
        query: Service.Get_Attendance_By_SectionId,
        variables: {
          start_date: date,
          end_date: date,
          attendance_type: "CLASS",
          section_id,
        },
      })
      .then((response) => {
        setStudentDayAttenArr(response.data.getAttendanceBySection);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });

    return () => {
      setIsUpdated(false);
    };
  };
  useEffect(() => {
    getClassData();
  }, []);
  const getClassData = () => {
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_id),
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);

          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={data.institution_name} />
              <div
                className="enquirydetails_background mb-2"
                ref={componentRef}
              >
                <div ref={ref}>
                  <div className="enquiry_heading">
                    <div className="mt-3 ml-3 Heading_bold">
                      Class overview Attendence and Payment
                    </div>
                  </div>
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <div>
                            <Grid className="grid_main_div">
                              <Grid item md={2}>
                                <div>
                                  <p className="class_attendence_Heading">
                                    Class Name
                                  </p>
                                </div>
                              </Grid>
                              <Grid item md={10}>
                                <div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={2}>
                                      <p className="class_attendence_Heading">
                                        Section
                                      </p>
                                    </Grid>
                                    <Grid item md={2}>
                                      <p className="class_attendence_Heading">
                                        Total Students
                                      </p>
                                    </Grid>
                                    <Grid item md={2}>
                                      {" "}
                                      <p className="class_attendence_Heading">
                                        Attendance
                                      </p>
                                    </Grid>
                                    <Grid item md={2}>
                                      {" "}
                                      <p className="class_attendence_Heading">
                                        Unpaid Invoices
                                      </p>
                                    </Grid>
                                    <Grid item md={2}>
                                      {" "}
                                      <p className="class_attendence_Heading">
                                        OverDues Invoices
                                      </p>
                                    </Grid>
                                    <Grid item md={2}>
                                      <p className="class_attendence_Heading">
                                        Paid Invoices
                                      </p>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                            <div>
                              <Grid className="grid_main_div">
                                <Grid item md={2}>
                                  <ClassOverViewLeft classArr={classArr} />
                                </Grid>
                                <Grid item md={10}>
                                  <div>
                                    <ClassOverViewTotalStudent />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  TEACHER_DATA: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(ClassOverviewandpaymentAttendence)));
