import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { baseUrl } from "../../../services/index.js";
import IconSchool from "../../Header/IconSchool.png";

const AdminManageInstitution = ({ ADMIN_DATA, navigate, location }) => {
  const {
    institution_name,
    organization_id,
    academic_session_id,
    institution_id,
    type,
  } = ADMIN_DATA;
  const [manageInstitution, setManageInstitution] = useState([
    { item: "Manage Department", link: "managedepartment", display: "" },
    { item: "Manage Class", link: "addeditclass" },
    { item: "Manage Grade", link: "createGrading" },
    { item: "Manage Subject", link: "managesubject" },
    { item: "Manage Timetable", link: "manageTimeTable" },
    { item: "Manage Designation", link: "managedesignation" },
    { item: "Manage Rooms", link: "managelocation" },
    { item: "Settings", link: "Setting" },
    { item: "Manage Academic Session", link: "manageSession" },
    { item: "School Calendar", link: "holidaycalender" },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!academic_session_id) {
      let temp = [{ item: "Manage Academic Session", link: "manageSession" }];
      setManageInstitution(temp);
    } else if (type === "PT" || type === "ET") {
      let temp = [
        { item: "Manage Class", link: "addeditclass" },
        { item: "Manage Subject", link: "managesubject" },
        { item: "Manage Timetable", link: "manageTimeTable" },
        { item: "Manage Rooms", link: "managelocation" },
        { item: "Settings", link: "Setting" },
        { item: "Manage Academic Session", link: "manageSession" },
        { item: "School Calendar", link: "holidaycalender" },
        { item: "Manage Grade", link: "createGrading" },
      ];
      setManageInstitution(temp);
    } else {
      let temp = [
        { item: "Manage Department", link: "managedepartment" },
        { item: "Manage Class", link: "addeditclass" },
        { item: "Manage Subject", link: "managesubject" },
        { item: "Manage Timetable", link: "manageTimeTable" },
        { item: "Manage Designation", link: "managedesignation" },
        { item: "Manage Rooms", link: "managelocation" },
        { item: "Settings", link: "Setting" },
        { item: "Manage Academic Session", link: "manageSession" },
        { item: "School Calendar", link: "holidaycalender" },
        { item: "Manage Grade", link: "createGrading" },
      ];
      setManageInstitution(temp);
    }
  }, [academic_session_id]);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 Heading_bold mt-2">
                    Manage Institution
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-4 mb-4">
                          <Grid item md={1}>
                            <div className="img_main_div_inatituition">
                              <img
                                src={
                                  location.state.temp.document === null
                                    ? IconSchool
                                    : baseUrl +
                                      "/" +
                                      location.state.temp.document.path
                                }
                                alt="No_profile"
                                className="img_formatting1"
                              />
                              <p
                                className="icon_cursor_to_edit"
                                onClick={() =>
                                  navigate("/createUpdateInstitution", {
                                    state: {
                                      pagetype: "edit",
                                      organization_id,
                                      data: location.state.temp,
                                    },
                                  })
                                }
                              >
                                Edit
                              </p>
                              {/* <i
                                className="fa fa-pencil icon_cursor_to_edit"
                                onClick={() =>
                                  navigate("/createUpdateInstitution", {
                                    pagetype: "edit",
                                    organization_id,
                                    data: location.state.temp,
                                  })
                                }
                              ></i> */}
                            </div>
                          </Grid>
                          <Grid item md={11} xs={11}>
                            <div className="List_heading_formatting ml-4 mt-3 img_main_div_inatituition">
                              {/* {institution_name} */}
                            </div>
                          </Grid>
                        </Grid>
                        {manageInstitution.map((data) => (
                          <div className="mt-3 mb-2 displayNonePT">
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_admin_institutiomanage">
                                    <div>{data.item}</div>
                                    <div className="">
                                      <span
                                        className="institutionadminmange manageheading_padding"
                                        onClick={() => {
                                          navigate(`${data.link}`, {
                                            state: {
                                              academic_session_id:
                                                parseInt(academic_session_id),
                                              institution_name,
                                              institution_id,
                                            },
                                          });
                                        }}
                                      >
                                        Manage
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminManageInstitution));
