import Grid from "@material-ui/core/Grid";
import React from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import UpcomingEventsComponent from "../../Common/Events/UpcomingEventsComponent";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1";
import MyEmployeTransport from "../EmployeeTransport/MyEmployeTransport";
import "./EmployeeHomePage.css";
import EmployeeNotification from "./EmployeeNotification";

const EmployeeHomePage = (props) => {
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} isRemove={true} />

              {props.data.usertype === "TRANSPORT" ? (
                <MyEmployeTransport navigate={props.navigate} />
              ) : (
                <EmployeeNotification />
              )}

              <UpcomingEventsComponent navigate={props.navigate} />
            </Grid>
          </Grid>
        </div>
      </div>
      <div></div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(EmployeeHomePage)));
