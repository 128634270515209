import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import GoogleMapReact from "google-map-react";
import "./Transport.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";



const TransportRouteMap = (props) => {
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };
  const handleMap = ({ x, y, lat, lng, event }) => {};
  return (
    <div className="main_full_width">
      <div className="main_back_color1">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Route Map</div>
                </div>
                <div className="card_margin_both_side ">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div
                          style={{ height: "63vh", width: "100%" }}
                          className="mt-2 mb-2 margin_map"
                        >
                          <GoogleMapReact
                            onClick={handleMap}
                            onGoogleApiLoaded={({ map, maps }) =>
                              handleApiLoaded(map, maps)
                            }
                            bootstrapURLKeys={{
                              key: "AIzaSyC6teBAs0VTYf_3jk8HnmmJP99hc3fd8Rw",
                            }}
                            defaultCenter={{
                              lat: 28.509279769540377,
                              lng: 77.23611497754148,
                            }}
                            defaultZoom={11}
                          ></GoogleMapReact>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default (HOC1(TransportRouteMap));
