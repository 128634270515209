import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getData } from "../../../api";
import Holiday from "../../Common/Holiday/Holiday";
import HocS from "../../HocS";
import * as Constants from "../../../action/appAction";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

const StudentMange = (props) => {
  let institution_Name = props.data.institution_name;
  const { data, navigate } = props;
  useEffect(() => {
    if (data.userData.length === 1) {
      let loginResponse = data;
      loginResponse["userid"] = data.userData[0].user_id;

      props.dispatch({
        type: Constants.USER_INFO,
        state: loginResponse,
        key: Constants.TOKEN,
      });
      navigate("studentHomePage", {
        state: {
          SectionData: data.userData[0],
        },
      });
    }
  }, []);

  const openStudentHandle = (row) => {
    let loginResponse = data;
    loginResponse["userid"] = row.user_id;
    props.dispatch({
      type: Constants.USER_INFO,
      state: loginResponse,
      key: Constants.TOKEN,
    });
    navigate("studentHomePage", {
      state: {
        SectionData: row,
      },
    });
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_Name} isRemove={true} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-4 Heading_bold">Select your class</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      {/* ==========map section======= */}
                      {data?.userData.map((row, index) => (
                        <Card
                          className="cardvisible_border mb-2 mt-3"
                          key={index}
                        >
                          <div className="card_admissiondetails_height">
                            <div className="textfiled_margin">
                              <div className="card_content_instition">
                                <div>{row?.section?.grade?.gradeName}</div>
                                <div>{row?.section?.name}</div>
                                <div className="d-flex">
                                  <span
                                    className="mr-3 icon_pencil_color manage_edit_cursor"
                                    onClick={() => openStudentHandle(row)}
                                  >
                                    Open
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HocS(StudentMange));
