import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

const BulkTeacherUpload = ({ manageInstitution, navigate }) => {
  //-------------------------------global id-------------------------
  let institution_id = parseInt(manageInstitution.institution_id);
  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  //-------------------------------api calls-------------------------
  const client = useApolloClient();
  const [Bulk_Upload_Employee_API, {}] = useMutation(
    Service.Bulk_Upload_Employee_API
  );
  const [empoyeeBulkUploadForExcel, {}] = useMutation(
    Service.empoyeeBulkUploadForExcel
  );
  //-------------------------------local state-------------------------

  const [isLoading, setIsLoading] = useState(false);

  const [filename, setFileName] = useState("Add Attachment");
  const [filePath, setfilePath] = useState("");
  const [fileFilename, setfileFilename] = useState("");
  const [filetype, setfiletype] = useState("");
  const [filename1, setfilename1] = useState("");
  const [BulkuploadError, setBulkuploadError] = useState([]);

  const [uploadfileError, setuploadfileError] = useState(false);

  const fileUploaderHandler = (e) => {
    setuploadfileError(false);
    try {
      setIsLoading(true);
      // select file
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      // create form data
      let fileData = new FormData();
      //add file to form data
      fileData.append("file", file);
      // axios api call
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setfilePath(response.data.path);
          setfileFilename(response.data.filename);
          setfiletype(response.data.type);
          setfilename1(response.data.name);
          setFileName(file.name);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setIsLoading(false);
      console.log("catch and try error", error);
    }
  };

  const submitHandler = () => {
    if (!blankValidator(filePath)) {
      setuploadfileError(true);
      return;
    }
    // do some upload stuff
    try {
      setIsLoading(true);
      // Bulk_Upload_Employee_API({
      //   variables: {
      //     institution_id,
      //     path: filePath,
      //     filename: fileFilename,
      //     type: filetype,
      //     name: filename1,
      //   },
      // })
      empoyeeBulkUploadForExcel({
        variables: {
          institution_id,
          path: filePath,
          filename: fileFilename,
          type: filetype,
          name: filename1,
        },
      })
        .then((response) => {
          setIsLoading(false);
          let temp = [];
          response.data.empoyeeBulkUploadForExcel.map((data) => {
            if (data.errorCode === 1) {
              temp.push(data);
            }
          });
          if (temp.length == 0) {
            showNotificationMsz("Employee Added Successfully", "success");
          } else {
            showNotificationMsz("Employeee Added With Below Information", "info");
          }
          setBulkuploadError(temp);
        })
        .catch((error) => {
          setIsLoading(false);

          console.log("catch error", error);
        });
    } catch (error) {
      setIsLoading(false);

      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <Grid className="grid_main_div p-3">
        <Grid item md={12}>
          <div className="bulk_upload_flex_data">
            <h6 className="text_filed_heading">Select File</h6>
            <a
              href={Service.baseUrl + "/upload/EmployeeBulkUploadFormat.xlsx"}
              target="_blank"
            >
              Download Templete
            </a>
          </div>
          <div class="custom-file">
            <input
              accept=".csv, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              className="form-control"
              onChange={fileUploaderHandler}
            />
          </div>
          <label class="custom-file-label">{filename}</label>

          {uploadfileError && (
            <span className="text-danger">Upload a File</span>
          )}
        </Grid>
      </Grid>
      <div className="bulk_button_right">
        <Button
          variant="contained"
          className="button_enquiry_details_upload mr-1"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </div>
      {BulkuploadError.length > 0 && (
        <span>
          <Grid className="grid_main_div">
            <Grid item md={1}>
              <div className="text-center">Row Id</div>
            </Grid>
            <Grid item md={10}>
              <div className="text-bold">Error Message</div>
            </Grid>
          </Grid>
        </span>
      )}
      {BulkuploadError.map((item, index) => (
        <div>
          {item.errorCode === 1 ? (
            <span>
              <Grid className="grid_main_div">
                <Grid item md={1}>
                  <div className="text-center text-danger"> {item.row_id}</div>
                </Grid>
                <Grid item md={10}>
                  <div className="text-danger"> {item.message}</div>
                </Grid>
              </Grid>
            </span>
          ) : (
            ""
          )}
        </div>
      ))}

      <Loder loading={isLoading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(BulkTeacherUpload);
