import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const TeacherTimeTableMenu = (props) => {
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Time Table</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_margin_both_side mt-2 mb-2">
                      <Grid className="grid_main_div">
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color inputMarginright mt-1"
                            onClick={() =>
                             props.navigate("/teachermytimetable")
                            }
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>My Time Table</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}>
                          <Card
                            className="pt-2 pb-2 card_color mt-1"
                            onClick={() =>
                             props.navigate("/teacherTimetable")
                            }
                          >
                            <div className="font_size_of_icons">
                              <i class="fa fa-file"></i>
                            </div>
                            <div className="whiteLine"></div>
                            <div>Class Time Table</div>
                          </Card>
                        </Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={3}></Grid>
                      </Grid>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(TeacherTimeTableMenu)));
