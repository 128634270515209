import React, { useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./Notice.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../../Common/Holiday/Holiday.jsx";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const NoticeManagementSubject = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [NoticeDataClass] = useState([
    {
      title: "lorem ipsum",
      subject: "Hindi",
      class: "2-A",
      publishdate: "02/04/2021",
      epirydate: "09/04/2021",
      publishername: "Mark Willy",
      status: "Expired",
    },
    {
      title: "lorem ipsum",
      subject: "Hindi",
      class: "2-A",
      publishdate: "02/04/2021",
      epirydate: "09/04/2021",
      publishername: "Mark Willy",
      status: "Active",
    },
  ]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Manage School Notice
                  </div>
                  <div className="mt-3 mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={() =>props.navigate("/createNotice", {pageType:""})}
                    >
                      Create Notice
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-3">
                          <Grid className="grid_main_div mt-1">
                            <Grid item md={6}>
                              <div className="inputMarginright ">
                                <label for="exampleInputEmail1">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  placeholder="title"
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <label for="exampleInputEmail1">
                                Published Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                              />
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}>
                              <div className="inputMarginright mt-1">
                                <label for="exampleInputEmail1">
                                  Publisher Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  placeholder="Publisher Name"
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className=" mt-1">
                                <label for="exampleInputEmail1">Status</label>
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Status</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                </select>
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div mt-3 mb-2">
                            <Grid item md={10}></Grid>
                            <Grid item md={2}>
                              {" "}
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                              >
                                Search
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Title
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Publish Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Expiry Date
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Publisher Name
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  ></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? NoticeDataClass.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : NoticeDataClass
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {row.title}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.subject}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {row.publishdate}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.epirydate}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.publishername}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {(row.status === "Expired" ||
                                        row.status === "Cancelled") && (
                                        <Button
                                          className="not_started_cancel button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                      {row.status === "Active" && (
                                        <Button
                                          className="Complete_color_teacher button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="openassignemntcolor"
                                    >
                                      View/Edit
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={NoticeDataClass.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default (HOC1(NoticeManagementSubject));
