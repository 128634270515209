import React, { useState, useEffect } from "react";
import "./Onboarding.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { blankValidator, CapitalizeFirstFn } from "../../../utils/Validation";
import Loder from "../../Loder/Loder";

const StudentTransportationPop = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [transportStop, setTransportStop] = React.useState("");
  const [transportPick, setTransportPick] = React.useState("");
  const [routeSelect, setRouteSelect] = React.useState("");
  const client = useApolloClient();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);

  let institution_name = props.data.institution_name;
  useEffect(() => {
    getStudentExamData();
  }, []);

  const getStudentExamData = () => {
    let id = parseInt(props.id);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Student_Details_Data,
          variables: {
            id,
          },
        })
        .then((response) => {
          setTransportPick(response.data.getStudentDetailForm.transport_pickup);
          setTransportStop(response.data.getStudentDetailForm.transport_stop);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const PrimaryContact = (e) => {
    setRouteSelect(e.target.value);
  };
  const submitbutton = () => {
    if (!blankValidator(routeSelect)) {
      alert("Choose Route type");
      return;
    }
   props.navigate("/studentsttendencereport", {
      transport_data: {
        transport: routeSelect == "PICK" ? transportPick : transportStop,
        class_Name: props.class_Name,
        section_Name: props.section_Name,
        id: props.id,
        student_Name: props.student_Name,
        institution_name: props.institution_name,
      },
    });
  };
  return (
    <div className="main_full_width">
      <div className="main_back_color1">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="enquiry_heading ">
                          <div className="mt-3 Heading_bold">
                            Select Route{" "}
                            {`( ${props.class_Name} ${props.section_Name} )`}
                          </div>
                        </div>
                        {/* <div className="mt-1 mb-1 Heading_bold">Pickup</div> */}
                        <div className="table_foramtitng">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  ></StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Route type
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Stops
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Boarding Point
                                  </StyledTableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <StyledTableRow>
                                  <StyledTableCell align="left">
                                    <input
                                      type="radio"
                                      name="radio"
                                      value={"PICK"}
                                      onChange={(e) => PrimaryContact(e)}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    {transportPick?.transport_route?.route_name}
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    {transportPick?.transport_route?.route_type}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {transportPick &&
                                      transportPick.stop_name &&
                                      transportPick.stop_name}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="left">
                                      <div>
                                        <select
                                          className="form-control"
                                          id="exampleFormControlSelect1"
                                        >
                                          <option>Boarding Point</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                        </select>
                                      </div>
                                    </StyledTableCell> */}
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell align="left">
                                    <input
                                      type="radio"
                                      name="radio"
                                      value={"STOP"}
                                      onChange={(e) => PrimaryContact(e)}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    {transportStop?.transport_route?.route_name}
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    {transportStop?.transport_route?.route_type}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {transportStop &&
                                      transportStop.stop_name &&
                                      transportStop.stop_name}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="left">
                                      <div>
                                        <select
                                          className="form-control"
                                          id="exampleFormControlSelect1"
                                        >
                                          <option>Drop Point</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                        </select>
                                      </div>
                                    </StyledTableCell> */}
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>

                        <Grid className="grid_main_div gapfromanotherinput  mb-3">
                          <Grid item md={10}></Grid>

                          <Grid item md={2}>
                            <div className=" mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration "
                                onClick={() => {
                                  submitbutton();
                                  //props.navigate(
                                  //   "/studentsttendencereport"
                                  // );
                                }}
                              >
                                View Attendance
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(routename, stops) {
  return { routename, stops };
}

const rows = [createData("TVM-XYZ", "Stop10>Stop11>Stop9>stop>5")];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});
export default connect(
  mapStateToProps,
  null
)((HOC1(StudentTransportationPop)));
