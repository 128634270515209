import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Holiday from "../../Common/Holiday/Holiday.jsx";

import Loder from "../../Loder/Loder.jsx";
// table data------------
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useMutation } from "@apollo/client";
import {
  blankValidator,
  getFullName,
  showNotificationMsz,
  timeStampToDatedash,
} from "../../../utils/Validation.js";
import APIHooks from "../../../api manager/CommonAPI";
import Autocomplete from "../../Common/CommonNotifications/Autocomplete.js";
import Multiselect from "multiselect-react-dropdown";

const AssignInvoice = (props) => {
  console.log("prosp=------------", props);
  const assignInvoiceHook = APIHooks();
  //-----------------global id-------------------------

  let academic_session_id = parseInt(props.data.academic_session_id);
  let institution_id = parseInt(props.data.institution_id);
  const invoiceData = props.location.state.invoiceObj;

  const [Assign_Invoice_To_Class, {}] = useMutation(
    Service.Assign_Invoice_To_Class
  );
  const [Assign_Invoice_To_Student, {}] = useMutation(
    Service.Assign_Invoice_To_Student
  );

  const classes = useStyles();

  const [selectedvalueOption, setSelectedvalueOption] = useState([]);
  const [selectedvalue, setSelectedvalue] = useState([]);
  const [page, setPage] = useState(0);
  const [ViewInvoiceData, setViewInvoiceData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const client = useApolloClient();
  const [managegrade, setmanagegrade] = useState([]);
  const [section, setsection] = useState([]);
  const [gradeId, setGradeId] = useState("");
  const [section_id, setsection_id] = useState("");
  const [isloading, setisloading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [studentListArr, setStudentListArr] = useState([]);
  //errors
  const [gradeIdError, setgradeIdError] = useState(false);
  const [sectionIdError, setsectionIdError] = useState(false);
  const [selectedStudent, setSelectedStudnet] = useState(-1);
  useEffect(() => {
    window.scrollTo(0, 0);
    assignInvoiceHook.getClassAPI(academic_session_id);
    // getManageGrades();
  }, []);
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Assigned_Invoice_list,
          variables: {
            invoice_id: parseInt(invoiceData.id),
          },
        })
        .then((response) => {
          setisloading(false);
          setViewInvoiceData(response.data.getAssignInvoice);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("get transport t and c error", error);
    }
    setIsUpdated(false);
  }, [isUpdated, institution_id, client]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classHandler = async (classId) => {
    try {
      let gradeId = parseInt(classId);
      setsection([]);
      if (gradeId === -1) {
        setsection([]);
        setGradeId("");
        return;
      }
      setisloading(true);
      setGradeId(gradeId);
      await assignInvoiceHook.getSectionAPI(gradeId);
      setisloading(false);
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const assignInvoiceHandler = async () => {
    try {
      if (!blankValidator(gradeId)) {
        setgradeIdError(true);
        return;
      }
      if (!blankValidator(section_id)) {
        setsectionIdError(true);
        return;
      }

      console.log(selectedStudent);
      if (selectedvalue.length <= 0) {
        setisloading(true);
        Assign_Invoice_To_Class({
          variables: {
            invoice_id: parseInt(invoiceData.id),
            due_date: timeStampToDatedash(invoiceData.due_date),
            amount: invoiceData.amount,
            recurrence: invoiceData.recurrence,
            section_id: parseInt(section_id),
            currency_code: props.data.currency_code,
          },
        })
          .then((response) => {
            setIsUpdated(true);
            setisloading(false);

            showNotificationMsz(
              response.data.assignInvoiceToClass.message,
              "success"
            );
          })
          .catch((error) => {
            console.log("catch err", error);
            setisloading(false);
          });
      } else {
        let studentArr = [];

        selectedvalue.map((item) => studentArr.push(item.id));

        setisloading(true);
        Assign_Invoice_To_Student({
          variables: {
            invoice_id: parseInt(invoiceData.id),
            due_date: timeStampToDatedash(invoiceData.due_date),
            amount: invoiceData.amount,
            recurrence: invoiceData.recurrence,
            student_id: studentArr,
            currency_code: props.data.currency_code,
          },
        })
          .then((response) => {
            setIsUpdated(true);
            setisloading(false);
            showNotificationMsz(
              response.data.assignInvoiceToStudent.message,
              "success"
            );
            props.navigate("/studentinvoice", {
              state: {
                classId: gradeId,
                sectionId: section_id,
                invoiceName: invoiceData.name,
                json: {
                  userId: selectedStudent,
                },
              },
            });
          })
          .catch((error) => {
            console.log("catch err", error);
            setisloading(false);
          });
      }
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const sectionHandler = async (sectionId) => {
    try {
      let section_id = parseInt(sectionId);
      setSelectedStudnet(-1);
      setsection_id(sectionId);
      if (section_id === -1) {
        setSelectedvalueOption([]);
        return;
      }
      setisloading(true);

      const { data } = await client.query({
        query: Service.GET_StudentList_by_SectionID,
        variables: {
          section_id,
        },
      });
      setSelectedvalueOption([]);
      let slicedArr = data.getStudentListBySectionId.slice();
      let studentTempArr = [];
      slicedArr.map((item) => {
        studentTempArr.push({
          name: getFullName(item.student_profile?.name),
          id: item?.id,
        });
      });

      setSelectedvalueOption(studentTempArr);
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };
  const studentHandler = async (userId) => {};

  const onSelect = (selectedList, selectedItem) => {
    setSelectedvalue([...selectedvalue, selectedItem]);
  };

  const onRemove = (selectedList, removedItem) => {
    const newTodos = selectedvalue.filter((item) => item.id !== removedItem.id);
    setSelectedvalue(newTodos);
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.data.institution_name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold mt-3">
                  Assign Invoice {`(${invoiceData.name})`}
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div gapfromanotherinput mt-2 mb-2">
                        <Grid item md={3}>
                          <div className="text_filed_heading">Class</div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) => classHandler(e.target.value)}
                            >
                              <option value={-1}>Select Class</option>
                              {assignInvoiceHook.classArr.length > 0 &&
                                assignInvoiceHook.classArr.map(
                                  (item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.gradeName}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                        </Grid>
                        <Grid item md={3}>
                          <div className="text_filed_heading">Section</div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) => sectionHandler(e.target.value)}
                            >
                              <option value={-1}>Select Section</option>
                              {assignInvoiceHook.sectionArr.length > 0 &&
                                assignInvoiceHook.sectionArr.map(
                                  (item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                        </Grid>
                        <Grid item md={3}>
                          <div className="text_filed_heading">Student</div>
                          <div className="inputMarginright mt-1">
                            <Multiselect
                              options={selectedvalueOption} // Options to display in the dropdown
                              selectedValues={selectedvalue} // Preselected value to persist in dropdown
                              onSelect={onSelect} // Function will trigger on select event
                              onRemove={onRemove} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                            />
                            {/* <Autocomplete
                              suggestions={selectedvalueOption}
                              selectedvalue={selectedvalue}
                              setSelectedvalue={setSelectedvalue}
                            ></Autocomplete> */}
                            {/* <select
                              class="form-control"
                              onChange={(e) =>
                                setSelectedStudnet(e.target.value)
                              }
                            >
                              <option value={-1}>Select Student</option>
                              <option value={-1}>All</option>

                              {studentListArr.length > 0 &&
                                studentListArr.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {getFullName(item.student_profile?.name)}
                                  </option>
                                ))}
                            </select> */}
                          </div>
                        </Grid>
                        <Grid item md={3}>
                          <div className="text_filed_heading"></div>
                          <div className="inputMarginright marginTop">
                            <Button
                              variant="contained"
                              className="button_enquiry_details mr-2 button_login_decoration button_width"
                              onClick={assignInvoiceHandler}
                            >
                              Assign
                            </Button>
                          </div>
                          <div style={{ marginTop: 15 }}></div>
                        </Grid>
                      </Grid>
                      {/* for transport route */}
                      <div className="table_foramtitng ">
                        <TableContainer component={Paper}>
                          <div className="tableborder_viewinvoice">
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Actions
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? ViewInvoiceData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : ViewInvoiceData
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell scope="row" align="left">
                                      {row.section.grade.gradeName}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left">
                                      {row.section.name}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <span
                                        className="row_details_color"
                                        onClick={() =>
                                          props.navigate("/studentinvoice", {
                                            state: {
                                              classId: row?.section?.grade?.id,
                                              sectionId: row?.section?.id,
                                              invoiceName: invoiceData.name,
                                            },
                                          })
                                        }
                                      >
                                        Open
                                      </span>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={ViewInvoiceData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AssignInvoice));
