import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { getFullName, showNotificationMsz } from "../../../../utils/Validation";
import { useMutation } from "@apollo/client";
import Loder from "../../../Loder/Loder.jsx";
import * as Service from "../../../../services/index";
import StaffNewInviteesListTable from "../GetList/StaffNewInviteesList";
import { useApolloClient } from "@apollo/client";

const StaffNewInviteesList = (props) => {
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [staffValue, setStaffValue] = useState("");
  const [techerValue, setTecherValue] = useState("");
  const [teacherListArray, setTeacherListArray] = useState([]);

  const [Update, setUpdate] = useState(false);

  const [add_event_participant_for_staff, {}] = useMutation(
    Service.add_event_participant_for_staff
  );
  const [InviteParticipantAPI, {}] = useMutation(
    Service.Add_Event_Participants
  );
  const selectStaff = (value) => {
    setStaffValue(value);
    getTecherListData();
  };

  const getTecherListData = async () => {
    try {
      const teacherArr = await client.query({
        query: Service.Get_Employee_All_data,
        variables: {
          institution_id: props.institution_id,
          department_id: -1,
        },
      });

      setTeacherListArray(teacherArr?.data?.getEmployee);
    } catch (error) {
      setisloading(false);
    }
  };
  const selectTecharHandle = (value) => {
    setTecherValue(value);
  };

  const InviteStaffHandle = () => {
    try {
      setisloading(true);
      let StudentInviteArray = [];

      if (+techerValue === 0) {
        fliterArr.map((data) => {
          StudentInviteArray.push({
            user_id: +data.id,
          });
        });
      } else {
        StudentInviteArray = [
          { user_id: +techerValue },
        ];
      }
      add_event_participant_for_staff({
        variables: {
          event_id: props.eventid,
          institution_id: props.institution_id,
          user_type: staffValue,
          userData: StudentInviteArray,
        },
      })
        .then((response) => {
          setisloading(false);
          setUpdate(!Update);
          showNotificationMsz(
            response.data.addEventParticipantsForInstituteEmployee.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {}
  };
  // const InviteStaffHandle = () => {
  //   try {
  //     let StudentInviteArray = [];

  //     if (+techerValue === 0) {
  //       fliterArr.map((data) => {
  //         StudentInviteArray.push({
  //           user_id: +data.id,
  //           email: " ",

  //         });
  //       });
  //     } else {
  //       StudentInviteArray = [
  //         { user_id: +techerValue, email: " ", user_type: staffValue },
  //       ];
  //     }
  //     setisloading(true);
  //     InviteParticipantAPI({
  //       variables: {
  //         event_id: +props.eventid,
  //         event_invite_type: staffValue,
  //         participants: StudentInviteArray,
  //       },
  //     })
  //       .then((response) => {
  //         console.log("response", response);
  //         setisloading(false);
  //         setUpdate(!Update);
  //         showNotificationMsz("Invite sent Successfully", "success");
  //       })
  //       .catch((error) => {
  //         setisloading(false);
  //         console.log("catch error", error);
  //       });
  //   } catch (error) {}
  // };

  const fliterArr = teacherListArray.filter((event, index) => {
    return event != ""
      ? staffValue === "TEACHER"
        ? event.user_type === "TEACHER" || event.user_type === "Teacher"
        : staffValue === "Non-teaching staff"
        ? event.user_type !== "TEACHER" && event.user_type !== "Teacher"
        : true
      : [];
  });
  return (
    <div>
      <Grid className="grid_main_div mt-3">
        <Grid item md={12}>
          <div className="mt-2">
            <div>
              <Grid className="grid_main_div mt-2">
                <Grid item md={4}>
                  <div className="inputMarginright">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={staffValue}
                      onChange={(e) => selectStaff(e.target.value)}
                    >
                      <option value="-1">Search By Staff</option>
                      <option value="All">All</option>
                      <option value="TEACHER">Teachers</option>
                      <option value="Non-teaching staff">
                        Non-teaching staff
                      </option>
                    </select>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="inputMarginright">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={techerValue}
                      onChange={(e) => selectTecharHandle(e.target.value)}
                    >
                      <option value="-1">Search By Teacher</option>
                      <option value={0}>All</option>
                      {fliterArr.length > 0 &&
                        fliterArr.map((item) => (
                          <option value={item.id}>
                            {getFullName(item?.name)}
                            {item.user_id}
                          </option>
                        ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="pl-1">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={InviteStaffHandle}
                    >
                      Invite
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <StaffNewInviteesListTable
                {...props}
                update={Update}
                staffValue={staffValue}
              ></StaffNewInviteesListTable>
            </div>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isloading} />
    </div>
  );
};

export default StaffNewInviteesList;
