import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Invitteeslist from "./Invitteeslist";
import StaffInviteesList from "./StaffInviteesList";
import StaffNewInviteesList from "./StaffNewInviteesList";
import StudentInviteesList from "./StudentInviteesList";
import ParentInviteList from "./ParentInviteList";

const ShowEventInviteesTabbar = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        //  variant="scrollable"
        aria-label="simple tabs example"
        //className="tab_width_exam"
        indicatorColor="primary"
        textColor="primary"
        left
      >
        <Tab
          label="Class"
          {...a11yProps(0)}
          className="button_login_decoration text_tranformation_exam"
        />
        <Tab
          label="Department"
          {...a11yProps(1)}
          className="button_login_decoration text_tranformation_exam"
        />
        <Tab
          label="Students"
          {...a11yProps(2)}
          className="button_login_decoration text_tranformation_exam"
        />
        <Tab
          label="Staff"
          {...a11yProps(3)}
          className="button_login_decoration text_tranformation_exam"
        />
        <Tab
          label="Parent"
          {...a11yProps(4)}
          className="button_login_decoration text_tranformation_exam"
        />
      </Tabs>

      <div className="Pannel_padding_hidden mt-1 mb-3">
        <TabPanel value={value} index={0}>
          <Invitteeslist {...props}></Invitteeslist>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StaffInviteesList {...props}></StaffInviteesList>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <StudentInviteesList {...props}></StudentInviteesList>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <StaffNewInviteesList {...props}></StaffNewInviteesList>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ParentInviteList {...props}></ParentInviteList>
        </TabPanel>
      </div>
    </div>
  );
};

export default ShowEventInviteesTabbar;
