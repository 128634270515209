import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Onboarding.css";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  serverDateFormat,
  showNotificationMsz,
  currentDayForMinMAx,
} from "../../../utils/Validation.js";
import { highestQualificationData } from "../../../utils/LocalData.js";

const StudentForm = ({ ADMIN_DATA, navigate }) => {
  const { locale, academic_session_id, institution_id, institution_name } =
    ADMIN_DATA;

  const [Add_Student_Admission_Data, {}] = useMutation(
    Service.Add_Student_Admission_Data
  );

  const client = useApolloClient();

  const [photo, setphoto] = useState({});
  const [birthCertificate, setbirthCertificate] = useState({});
  const [nation, setnation] = useState({});
  const [immuneCertificate, setimmuneCertificate] = useState({});
  const [schoolLeaveCertificate, setschoolLeaveCertificate] = useState({});
  const [othersarr, setothersarr] = useState([]);

  const [isloading, setisloading] = useState(false);

  const [gradeId, setGradeId] = useState(null);
  const [managegrade, setmanagegrade] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [section, setsection] = useState([]);
  const [selectroute, setselectroute] = useState(false);
  const [buttonCheck, setbuttonCheck] = useState(false);
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [pickupRouteArr, setPickupRouteArr] = useState([]);
  const [dropRouteArr, setDropRouteArr] = useState([]);
  const [pickupStartArr, setPickupStartArr] = useState([]);
  const [pickupRouteName, setPickupRouteName] = useState("");
  const [dropRouteName, setDropRouteName] = useState("");
  const [pickupStopName, setPickupStopName] = useState("");
  const [dropStopName, setDropStopName] = useState("");
  const [dropStopArr, setDropStopArr] = useState([]);
  const [sessionArr, setSessionArr] = useState([]);
  const [academic_session, setacademic_session] = useState(-1);
  const [sessionIdError, setsessionIdError] = useState(false);

  const selectPickupStartHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setStudentformdata({
        ...studentformdata,
        transport_drop_id: null,
      });
      return;
    }
    const newArr = pickupStartArr.filter((item) => {
      if (item.value === id) return item;
    });
    setPickupStopName(newArr[0].label);
    setStudentformdata({
      ...studentformdata,
      transport_drop_id: e.target.value,
    });
  };

  const selectDropStopHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      return;
    }
    const newArr = dropStopArr.filter((item) => {
      if (item.value === id) return item;
    });
    setDropStopName(newArr[0].label);
  };

  const [studentformdata, setStudentformdata] = useState({
    transport_pickup_id: -1,
    transport_drop_id: -1,
    primary_contact: "FATHER",
    Student_first_name: "",
    Student_middle_name: "",
    Student_last_name: "",
    institution_id: "",
    joining_date: "",
    gender: "",
    birth_date: "",
    Current_line1: "",
    Current_line2: "",
    Current_city: "",
    Current_state: "",
    Current_zip_code: -1,
    Current_country: "",
    Permanent_line1: "",
    Permanent_line2: "",
    Permanent_city: "",
    Permanent_state: "",
    Permanent_zip_code: -1,
    Permanent_country: "",
    blood_group: "",
    S_nationality: "",
    passport_number: "",
    passport_expiry_date: "",
    national_id_number: "",
    religion: "",
    height: -1,
    weight: -1,
    email: "",
    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Father_nationality: "",
    Father_email_address: "",
    father_passport_number: "",
    father_passport_expiry_date: "",
    Father_occupation: "",
    Father_phone_number: "",
    Father_national_id_number: "",
    Father_annual_income: "",
    Father_qualification_id: -1,

    Mother_first_name: "",
    Mother_middle_name: "",
    Mother_last_name: "",
    Mother_nationality: "",
    Mother_email_address: "",
    mother_passport_number: "",
    mother_passport_expiry_date: "",
    Mother_occupation: "",
    Mother_phone_number: "",
    Mother_national_id_number: "",
    Mother_annual_income: "",
    Mother_qualification_id: -1,

    guardian_first_name: "",
    guardian_middle_name: "",
    guardian_last_name: "",
    guardian_nationality: "",
    guardian_email_address: "",
    guardian_passport_number: "",
    guardian_passport_expiry_date: "",
    guardian_occupation: "",
    guardian_phone_number: "",
    guardian_national_id_number: "",
    guardian_annual_income: "",
    guardian_qualification_id: -1,

    academic_session_id: "",
    grade_id: "",
    section_id: -1,
    roll_number: "",
    declarationCheck: false,
    category: "",
    bank_name: "",
    bank_code: "",
    bank_account_number: "",
    beneficiary_name: "",
  });

  const fillAddress = (e) => {
    setCheckbox(e.target.checked);
    if (e.target.checked === true) {
      setPaddressline1Error(false);
      setPcountryError(false);
      setPstateError(false);
      setPPincodeError(false);
      setStudentformdata({
        ...studentformdata,
        Permanent_line1: studentformdata.Current_line1,
        Permanent_line2: studentformdata.Current_line2,
        Permanent_city: studentformdata.Current_city,
        Permanent_state: studentformdata.Current_state,
        Permanent_zip_code: parseInt(studentformdata.Current_zip_code),
        Permanent_country: studentformdata.Current_country,
      });
    } else
      setStudentformdata({
        ...studentformdata,
        Permanent_line1: "",
        Permanent_line2: "",
        Permanent_city: "",
        Permanent_state: "",
        Permanent_zip_code: "",
        Permanent_country: "",
      });
  };
  useEffect(() => {
    getacademicSession();
  }, []);

  const getacademicSession = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setSessionArr(response.data.getAllAcademicSession);
        })

        .catch((e) => {
          setisloading(false);
          console.log("catch y error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectSessionHandler = (id) => {
    setsessionIdError(false);
    setacademic_session(id);
    setmanagegrade([]);
    setStudentformdata({
      ...studentformdata,
      grade_id: "",
    });
    setGradeId(-1);
    if (id === -1) {
      setsessionIdError(true);
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagegrade(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectClassHandler = (ID) => {
    let ClassId = parseInt(ID);
    if (ClassId === -1) {
      setStudentformdata({
        ...studentformdata,
        grade_id: "",
      });
      return;
    }
    setgradeIdError(false);
    setGradeId(ClassId);
    setStudentformdata({
      ...studentformdata,
      grade_id: ClassId,
    });
  };

  const PrimaryContact = (e) => {
    setStudentformdata({
      ...studentformdata,
      primary_contact: e.target.value,
    });
  };
  const UploadDocumentHandler = (e, type) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);
    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          // const temp = [];
          // temp.push(obj);
          if (type === "PHOTO") {
            setphoto(obj);
          }
          if (type === "BIRTH_CERTIFICATE") {
            setbirthCertificate(obj);
          }
          if (type === "NATIONAL_CARD") {
            setnation(obj);
          }

          if (type === "IMMUNIZATION") {
            setimmuneCertificate(obj);
          }
          if (type === "SCHOOL_LEAVE_CERTIFICATE") {
            setschoolLeaveCertificate(obj);
          }
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error.message, "danger");
    }
  };

  //error decalare
  const [gradeIdError, setgradeIdError] = useState(false);
  const [StudentFirstNameError, setStudentFirstNameError] = useState(false);
  const [declarationcheckedError, setdeclarationcheckedError] = useState(false);
  const [BirthdateError, setBirthdateError] = useState(false);
  const [NationalityError, setNationalityError] = useState(false);
  const [Caddressline1Error, setCaddressline1Error] = useState(false);
  const [CcountryError, setCcountryError] = useState(false);
  const [CstateError, setCstateError] = useState(false);
  const [CPincodeError, setCPincodeError] = useState(false);
  const [Paddressline1Error, setPaddressline1Error] = useState(false);
  const [PcountryError, setPcountryError] = useState(false);
  const [PstateError, setPstateError] = useState(false);
  const [PPincodeError, setPPincodeError] = useState(false);
  const [MfirstnameError, setMfirstnameError] = useState(false);

  const [MPhoneError, setMPhoneError] = useState(false);
  const [FfirstnameError, setFfirstnameError] = useState(false);

  const [FPhoneError, setFPhoneError] = useState(false);

  const studentDetaiform = async () => {
    let validationDone = false;
    let scroll = false;
    if (
      !blankValidator(studentformdata.grade_id) ||
      studentformdata.grade_id === -1
    ) {
      setgradeIdError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("gradeID").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.Student_first_name)) {
      setStudentFirstNameError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("s_firstNamee").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.gender)) {
      setGenderError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("gender").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.birth_date)) {
      setBirthdateError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("s_birthdate").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.S_nationality)) {
      setNationalityError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("s_nationality").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.Current_line1)) {
      setCaddressline1Error(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("c_addressline1").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.Current_country)) {
      setCcountryError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("C_country").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.Current_state)) {
      setCstateError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("C_state").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(studentformdata.Current_zip_code) ||
      studentformdata.Current_zip_code === -1
    ) {
      setCPincodeError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("C_zip_code").focus();
      }
      scroll = true;
    }
    // if (!blankValidator(studentformdata.Permanent_line1)) {
    //   setPaddressline1Error(true);
    //   validationDone = true;
    //   if (scroll === false) {
    //     document.getElementById("P_addressline1").focus();
    //   }
    //   scroll = true;
    // }
    // if (!blankValidator(studentformdata.Permanent_country)) {
    //   setPcountryError(true);
    //   validationDone = true;
    //   if (scroll === false) {
    //     document.getElementById("P_Country").focus();
    //   }
    //   scroll = true;
    // }
    // if (!blankValidator(studentformdata.Permanent_state)) {
    //   setPstateError(true);
    //   validationDone = true;
    //   if (scroll === false) {
    //     document.getElementById("P_State").focus();
    //   }
    //   scroll = true;
    // }
    // if (
    //   !blankValidator(studentformdata.Permanent_zip_code) ||
    //   studentformdata.Permanent_zip_code === -1
    // ) {
    //   setPPincodeError(true);
    //   validationDone = true;
    //   if (scroll === false) {
    //     document.getElementById("P_zip_code").focus();
    //   }
    //   scroll = true;
    // }
    if (!blankValidator(studentformdata.Mother_first_name)) {
      setMfirstnameError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("M_name").focus();
      }
      scroll = true;
    }

    if (!blankValidator(studentformdata.Mother_phone_number)) {
      setMPhoneError(true);
      validationDone = true;
    }
    if (!blankValidator(studentformdata.Father_first_name)) {
      setFfirstnameError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("F_name").focus();
      }
      scroll = true;
    }

    if (!blankValidator(studentformdata.Father_phone_number)) {
      setFPhoneError(true);
      validationDone = true;
    }

    if (validationDone) {
      return;
    }
    console.log(
      "category: studentformdata.category,",
      studentformdata.category
    );

    try {
      setisloading(true);
      await Add_Student_Admission_Data({
        variables: {
          transport_pickup_id: parseInt(studentformdata.transport_pickup_id),
          transport_drop_id: parseInt(studentformdata.transport_drop_id),
          primary_contact: studentformdata.primary_contact,
          Student_first_name: studentformdata.Student_first_name,
          Student_middle_name: studentformdata.Student_middle_name,
          Student_last_name: studentformdata.Student_last_name,
          institution_id,
          joining_date: serverDateFormat(studentformdata.joining_date),
          gender: studentformdata.gender,
          birth_date: serverDateFormat(studentformdata.birth_date),
          Current_line1: studentformdata.Current_line1,
          Current_line2: studentformdata.Current_line2,
          Current_state: studentformdata.Current_state,
          Current_zip_code: parseInt(studentformdata.Current_zip_code),
          Current_country: studentformdata.Current_country,
          Permanent_line1: studentformdata.Permanent_line1,
          Permanent_line2: studentformdata.Permanent_line2,
          Permanent_state: studentformdata.Permanent_state,
          Permanent_zip_code: parseInt(studentformdata.Permanent_zip_code),
          Permanent_country: studentformdata.Permanent_country,
          blood_group: studentformdata.blood_group,
          S_nationality: studentformdata.S_nationality,
          passport_number: studentformdata.passport_number,
          passport_expiry_date: serverDateFormat(
            studentformdata.passport_expiry_date
          ),
          national_id_number: studentformdata.national_id_number,
          religion: studentformdata.religion,
          height: parseInt(studentformdata.height), // done int type
          weight: parseInt(studentformdata.weight), // done int type

          email: studentformdata.email,

          Father_first_name: studentformdata.Father_first_name,
          Father_middle_name: studentformdata.Father_middle_name,
          Father_last_name: studentformdata.Father_last_name,
          Father_nationality: studentformdata.Father_nationality,
          Father_email_address: studentformdata.Father_email_address,
          father_passport_number: studentformdata.father_passport_number,
          father_passport_expiry_date: "12/10/2024",
          Father_occupation: studentformdata.Father_occupation,
          Father_phone_number: studentformdata.Father_phone_number,
          Father_national_id_number: studentformdata.Father_national_id_number,
          Father_annual_income: studentformdata.Father_annual_income,
          Father_qualification_id: parseInt(
            studentformdata.Father_qualification_id
          ),

          Mother_first_name: studentformdata.Mother_first_name,
          Mother_middle_name: studentformdata.Mother_middle_name,
          Mother_last_name: studentformdata.Mother_last_name,
          Mother_nationality: studentformdata.Mother_nationality,
          Mother_email_address: studentformdata.Mother_email_address,
          mother_passport_number: studentformdata.mother_passport_number,
          mother_passport_expiry_date: "12/10/2024",
          Mother_occupation: studentformdata.Mother_occupation,
          Mother_phone_number: studentformdata.Mother_phone_number,
          Mother_national_id_number: studentformdata.Mother_national_id_number,
          Mother_annual_income: studentformdata.Mother_annual_income,
          Mother_qualification_id: parseInt(
            studentformdata.Mother_qualification_id
          ),

          guardian_first_name: studentformdata.guardian_first_name,
          guardian_middle_name: studentformdata.guardian_middle_name,
          guardian_last_name: studentformdata.guardian_last_name,
          guardian_nationality: studentformdata.guardian_nationality,
          guardian_email_address: studentformdata.guardian_email_address,
          guardian_passport_number: studentformdata.guardian_passport_number,
          guardian_passport_expiry_date: "12/10/2024",
          guardian_occupation: studentformdata.guardian_occupation,
          guardian_phone_number: studentformdata.guardian_phone_number,
          guardian_national_id_number:
            studentformdata.guardian_national_id_number,
          guardian_annual_income: studentformdata.guardian_annual_income,
          guardian_qualification_id: parseInt(
            studentformdata.guardian_qualification_id
          ),

          academic_session_id,
          join_grade_id: parseInt(studentformdata.grade_id),
          section_id: parseInt(studentformdata.section_id),
          roll_number: "-1",

          photo: photo === undefined && photo === null ? {} : photo,
          national: nation === undefined && nation === null ? {} : nation,
          birth_cert:
            birthCertificate === undefined && birthCertificate === null
              ? {}
              : birthCertificate,
          immunization_doc:
            immuneCertificate === undefined && immuneCertificate === null
              ? {}
              : immuneCertificate,
          leaving_cert_doc:
            schoolLeaveCertificate === undefined &&
            schoolLeaveCertificate === null
              ? {}
              : schoolLeaveCertificate,
          other: othersarr,
          category: studentformdata.category,
          bank_name: studentformdata.bank_name,
          bank_code: studentformdata.bank_code,
          bank_account_number: studentformdata.bank_account_number,
          beneficiary_name: studentformdata.beneficiary_name,
          category: studentformdata.category,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addStudent.message, "success");

          // navigate("/adminstudentlistview");
          navigate("/assignonboardstudent");
        },
        (error) => {
          setisloading(false);
          console.log("then error", error);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  // upload documents
  const UploadMultipleDocumentHandler = async (e) => {
    if (e.target.files.length > 4) {
      showNotificationMsz(`Cannot Upload More than 4 files`, "info");
      return;
    }

    const files = e.target.files;

    let fileresponse = [];
    try {
      setisloading(true);
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        const url = `${Service.baseUrl}/uploadFile/image`;
        let fileData = new FormData();
        fileData.append("file", element);

        await axios
          .post(url, fileData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setisloading(false);
            const obj = {
              path: response.data.path,
              filename: response.data.filename,
              type: response.data.type,
              name: response.data.name,
            };
            fileresponse.push(obj);

            //temp.push(obj);
          })
          .catch((error) => {
            console.log("catch error", error);
            showNotificationMsz(error.message, "danger");
          });

        setothersarr([...fileresponse]);
        // setStudentformdata({ ...studentformdata, others_doc: fileresponse });
      }
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }

    setisloading(false);
  };

  const selectTransportRoute = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.GET_Route_List_Data,
        variables: {
          institution_id,
        },
      });

      if (data && data.getTransportRoute) {
        let pickupArr = [];
        let dropArr = [];
        data.getTransportRoute.map((item) => {
          const { route_type, route_name, id } = item;
          if (route_type === "PICK") {
            pickupArr.push({
              label: route_name,
              value: id,
            });
          } else if (route_type === "DROP") {
            dropArr.push({
              label: route_name,
              value: id,
            });
          }
        });
        setPickupRouteArr(pickupArr);

        setDropRouteArr(dropArr);
      }

      setisloading(false);
      setselectroute(true);

      // .then((response) => {
      //   let temp = [];
      //   setisloading(false);
      //   for (
      //     let index = 0;
      //     index < response.data.getTransportRoute.length;
      //     index++
      //   ) {
      //     temp.push({
      //       label: response.data.getTransportRoute[index].route_name,
      //       value: response.data.getTransportRoute[index].id,
      //     });
      //   }
      //   setPickupRouteArr(temp);
      //   setselectroute(true);
      // })
      // .catch((err) => {
      //   setisloading(false);

      // });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectPickupRouteHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setStudentformdata({
        ...studentformdata,
        transport_pickup_id: null,
      });
      return;
    }
    const newArr = pickupRouteArr.filter((item) => {
      if (item.value === id) return item;
    });
    setPickupRouteName(newArr[0].label);
    setStudentformdata({
      ...studentformdata,
      transport_pickup_id: e.target.value,
    });

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Pickup_Route_Stop,
          variables: {
            transport_route_id: id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getTransportStop.length;
            index++
          ) {
            temp.push({
              label: response.data.getTransportStop[index].stop_name,
              value: response.data.getTransportStop[index].id,
            });
          }
          setPickupStartArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("get transport t and c error", error);
    }
  };

  const selectDropRouteHandler = async (routeDropId) => {
    if (+routeDropId === -1) {
      return;
    }
    const newArr = dropRouteArr.filter((item) => {
      if (item.value === +routeDropId) return item;
    });
    setDropRouteName(newArr[0].label);

    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Pickup_Route_Stop,
        variables: {
          transport_route_id: +routeDropId,
        },
      });
      if (data && data.getTransportStop) {
        let dropStopArr = [];
        data.getTransportStop.map((item) => {
          dropStopArr.push({
            label: item.stop_name,
            value: item.id,
          });
        });
        setDropStopArr(dropStopArr);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  // all the logic down there

  const submitRouteHandler = () => {
    setselectroute(false);
  };
  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name && institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Student Admission Form
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <div className=" mt-1 Heading_bold">Student Details</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Academic Session
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gradeID"
                                value={academic_session}
                                onChange={(e) =>
                                  selectSessionHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Session</option>
                                {sessionArr.map((item) => (
                                  <option value={item.id}>
                                    {item.session_name}
                                  </option>
                                ))}
                              </select>
                              {sessionIdError && (
                                <span className="text-danger">
                                  Select Academic Session
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Class<span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gradeID"
                                value={studentformdata.grade_id}
                                onChange={(e) =>
                                  selectClassHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Class</option>
                                {managegrade.length > 0 &&
                                  managegrade.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                              {gradeIdError && (
                                <span className="text-danger">
                                  Select Class
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date Of Joining
                            </div>
                            <div className=" mt-1">
                              <input
                                type="date"
                                class="form-control"
                                min={currentDayForMinMAx()}
                                value={studentformdata.joining_date}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    joining_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            {/* <div className="text_filed_heading">Section</div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                value={studentformdata.section_id}
                                onChange={(e) => {
                                  setsectionIdError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    section_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Select Section</option>
                                {section.length > 0 &&
                                  section.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                              {sectionIdError && (
                                <span className="text-danger">
                                  Select Section
                                </span>
                              )}
                            </div> */}
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading ">Student Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger pl-1">*</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="s_firstNamee"
                                  value={studentformdata.Student_first_name}
                                  onChange={(e) => {
                                    setStudentFirstNameError(false);

                                    setStudentformdata({
                                      ...studentformdata,
                                      Student_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {StudentFirstNameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Student_middle_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Student_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Student_last_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Student_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date of Birth
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="s_birthdate"
                                value={studentformdata.birth_date}
                                onChange={(e) => {
                                  setBirthdateError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    birth_date: e.target.value,
                                  });
                                }}
                              />
                              {BirthdateError && (
                                <span className="text-danger">
                                  Enter Date of Birth
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Gender <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gender"
                                value={studentformdata.gender}
                                onChange={(e) => {
                                  setGenderError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    gender: e.target.value,
                                  });
                                }}
                              >
                                <option>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                              {genderError && (
                                <span className="text-danger">
                                  Select Gender
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Blood Group
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                value={studentformdata.blood_group}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    blood_group: e.target.value,
                                  });
                                }}
                              >
                                <option>Blood Group</option>
                                <option>A+</option>
                                <option>B+</option>
                                <option>AB+</option>
                                <option>O+</option>
                                <option>A-</option>
                                <option>B-</option>
                                <option>AB-</option>
                                <option>O-</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Email</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.email}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    email: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.passport_number}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Expiry Date
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                min={currentDayForMinMAx()}
                                value={studentformdata.passport_expiry_date}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    passport_expiry_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.national_id_number}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">Religion</div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                value={studentformdata.religion}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    religion: e.target.value,
                                  });
                                }}
                              >
                                <option>Religion</option>
                                <option>Hindu</option>
                                <option>Islam</option>
                                <option>Christian</option>
                                <option>Sikh</option>
                                <option>Others</option>
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="s_nationality"
                                value={studentformdata.S_nationality}
                                onChange={(e) => {
                                  setNationalityError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    S_nationality: e.target.value,
                                  });
                                }}
                              />
                              {NationalityError && (
                                <span className="text-danger">
                                  Enter Nationality
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Height(in cm)
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={
                                  studentformdata.height === -1
                                    ? ""
                                    : studentformdata.height
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    height: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Weight(in Kg)
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={
                                  studentformdata.weight === -1
                                    ? ""
                                    : studentformdata.weight
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    weight: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          {studentformdata.Current_country == "India" ? (
                            <Grid item md={4}>
                              {" "}
                              <div className="text_filed_heading pl-2">
                                Category
                              </div>
                              <div className="mt-1 pl-2">
                                <select
                                  class="form-control"
                                  value={studentformdata.category}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      category: e.target.value,
                                    });
                                  }}
                                >
                                  <option>Select Category</option>
                                  <option>Scheduled Caste</option>
                                  <option>Scheduled Tribe</option>
                                  <option>Other Backward Classes</option>
                                  <option>General</option>
                                </select>
                              </div>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Address</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Current Address
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="text_filed_heading mt-1">
                              Address Line 1{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="c_addressline1"
                                value={studentformdata.Current_line1}
                                onChange={(e) => {
                                  setCaddressline1Error(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Current_line1: e.target.value,
                                  });
                                }}
                              />
                              {Caddressline1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>

                            <div className="text_filed_heading mt-1">
                              Address Line 2{" "}
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Current_line2}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Current_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Country<span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                value={studentformdata.Current_country}
                                id="C_country"
                                onChange={(e) => {
                                  setCcountryError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Current_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {CcountryError && (
                                <span className="text-danger">
                                  Select the Country
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              State<span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                country={studentformdata.Current_country}
                                value={studentformdata.Current_state}
                                id="C_state"
                                onChange={(e) => {
                                  setCstateError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Current_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {CstateError && (
                                <span className="text-danger">
                                  Select the State
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Pin Code
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="C_zip_code"
                                value={
                                  studentformdata.Current_zip_code === -1
                                    ? ""
                                    : studentformdata.Current_zip_code
                                }
                                onChange={(e) => {
                                  setCPincodeError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Current_zip_code: e.target.value,
                                  });
                                }}
                              />
                              {CPincodeError && (
                                <span className="text-danger">
                                  Enter the Pin code
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="d-flex mt-3">
                          <span className="text_filed_heading">
                            Permanent Address
                          </span>
                          <span className="ml-5">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="filladdress"
                                onClick={(e) => fillAddress(e)}
                                checked={checkbox}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Same As Current Address
                              </label>
                            </div>
                          </span>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="text_filed_heading mt-1">
                              Address Line 1{" "}
                              {/* <span className="text-danger pl-1">*</span> */}
                            </div>
                            <div className="mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_addressline1"
                                value={studentformdata.Permanent_line1}
                                onChange={(e) => {
                                  // setPaddressline1Error(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Permanent_line1: e.target.value,
                                  });
                                }}
                              />
                              {/* {Paddressline1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )} */}
                            </div>
                            <div className="text_filed_heading mt-1">
                              Address Line 2{" "}
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Permanent_line2}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Permanent_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Country
                              {/* <span className="text-danger pl-1">*</span> */}
                            </div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                value={studentformdata.Permanent_country}
                                id="P_Country"
                                onChange={(e) => {
                                  setPcountryError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Permanent_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {/* {PcountryError && (
                                <span className="text-danger">
                                  Select the Country
                                </span>
                              )} */}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              State
                              {/* <span className="text-danger pl-1">*</span> */}
                            </div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                country={studentformdata.Permanent_country}
                                value={studentformdata.Permanent_state}
                                id="P_State"
                                onChange={(e) => {
                                  setPstateError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Permanent_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {/* {PstateError && (
                                <span className="text-danger">
                                  Select the State
                                </span>
                              )} */}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Pin Code
                              {/* <span className="text-danger pl-1">*</span> */}
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_zip_code"
                                value={
                                  studentformdata.Permanent_zip_code === -1
                                    ? ""
                                    : studentformdata.Permanent_zip_code
                                }
                                onChange={(e) => {
                                  setPPincodeError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Permanent_zip_code: e.target.value,
                                  });
                                }}
                              />
                              {/* {PPincodeError && (
                                <span className="text-danger">
                                  Enter the Pin code
                                </span>
                              )} */}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">
                    Parent/Legal Guardian's Details
                  </div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">Mother Details</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger pl-1">*</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="M_name"
                                  value={studentformdata.Mother_first_name}
                                  onChange={(e) => {
                                    setMfirstnameError(false);
                                    setStudentformdata({
                                      ...studentformdata,
                                      Mother_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {MfirstnameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Mother_middle_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Mother_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Mother_last_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Mother_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Mother_nationality}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_nationality: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="M_email"
                                value={studentformdata.Mother_email_address}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_email_address: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.mother_passport_number}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    mother_passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Occupation</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Mother_occupation}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_occupation: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={
                                  studentformdata.Mother_national_id_number
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div
                              className="inputMarginright mt-1"
                              id="M_mobile"
                            >
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                countryCodeEditable={false}
                                value={studentformdata.Mother_phone_number}
                                onChange={(e) => {
                                  setMPhoneError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_phone_number: e,
                                  });
                                }}
                              />
                              {MPhoneError && (
                                <span className="text-danger">
                                  Enter Contact Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Annual Income
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Mother_annual_income}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_annual_income: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Select Higher Qualification
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                value={
                                  studentformdata.Mother_qualification_id === -1
                                    ? ""
                                    : studentformdata.Mother_qualification_id
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Mother_qualification_id: e.target.value,
                                  });
                                }}
                              >
                                <option>Select</option>
                                {highestQualificationData.map((data, index) => (
                                  <option value={data.value}>
                                    {data.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <hr style={{ width: "80%" }} />
                        <div className="text_filed_heading">Father Details</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger pl-1"> *</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="F_name"
                                  value={studentformdata.Father_first_name}
                                  onChange={(e) => {
                                    setFfirstnameError(false);
                                    setStudentformdata({
                                      ...studentformdata,
                                      Father_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {FfirstnameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Father_middle_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Father_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Father_last_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Father_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Father_nationality}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_nationality: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="F_email"
                                value={studentformdata.Father_email_address}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_email_address: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.father_passport_number}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    father_passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Occupation</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Father_occupation}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_occupation: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={
                                  studentformdata.Father_national_id_number
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                              <span className="text-danger pl-1"> *</span>
                            </div>
                            <div
                              className="inputMarginright mt-1"
                              id="F_mobile"
                            >
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                countryCodeEditable={false}
                                value={studentformdata.Father_phone_number}
                                onChange={(e) => {
                                  setFPhoneError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_phone_number: e,
                                  });
                                }}
                              />
                              {FPhoneError && (
                                <span className="text-danger">
                                  Enter the Phone Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Annual Income
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.Father_annual_income}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_annual_income: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Select Higher Qualification
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                value={
                                  studentformdata.Father_qualification_id === -1
                                    ? ""
                                    : studentformdata.Father_qualification_id
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_qualification_id: e.target.value,
                                  });
                                }}
                              >
                                <option>Select Higher Qualification</option>
                                {highestQualificationData.map((data, index) => (
                                  <option value={data.value}>
                                    {data.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <hr style={{ width: "80%" }} />
                        <div className="text_filed_heading">
                          Legal Guardian's Name
                        </div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.guardian_first_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      guardian_first_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.guardian_middle_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      guardian_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.guardian_last_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      guardian_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.guardian_nationality}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_nationality: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.guardian_email_address}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_email_address: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.guardian_passport_number}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_passport_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Occupation</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.guardian_occupation}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_occupation: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={
                                  studentformdata.guardian_national_id_number
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_national_id_number: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                countryCodeEditable={false}
                                value={studentformdata.guardian_phone_number}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_phone_number: e,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Annual Income
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                value={studentformdata.guardian_annual_income}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_annual_income: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Select</div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                value={
                                  studentformdata.guardian_qualification_id ===
                                  -1
                                    ? ""
                                    : studentformdata.guardian_qualification_id
                                }
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    guardian_qualification_id: e.target.value,
                                  });
                                }}
                              >
                                <option>Select</option>
                                {highestQualificationData.map((data, index) => (
                                  <option value={data.value}>
                                    {data.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="mt-3">
                          <div className="text_filed_heading">
                            Primary Contact
                            <span className="text-danger pl-1"> *</span>
                          </div>
                          <div class="form-check mt-2">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              value={"MOTHER"}
                              onChange={(e) => PrimaryContact(e)}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Mother
                            </label>
                          </div>

                          <div class="form-check mt-2">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              value={"FATHER"}
                              onChange={(e) => PrimaryContact(e)}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Father
                            </label>
                          </div>

                          <div class="form-check mt-2 mb-2">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                              value={"GUARDIAN"}
                              onChange={(e) => PrimaryContact(e)}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault3"
                            >
                              Guardian
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div>
                    <div className="enquirydetails_background mb-2">
                      <div className="enquiry_heading">
                        <div className="mt-2 Heading_bold">
                          Bank Account Details
                        </div>
                      </div>
                      <div>
                        <Card className="pt-3 pb-4">
                          <div className="card_admissiondetails_height">
                            <div className="textfiled_margin">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Bank Name
                                        </div>
                                        <div className="inputMarginright mt-1">
                                          <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Bank Name"
                                            autoComplete="off"
                                            value={studentformdata.bank_name}
                                            onChange={(e) => {
                                              setStudentformdata({
                                                ...studentformdata,
                                                bank_name: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Bank Code
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Bank Code"
                                            autoComplete="off"
                                            value={studentformdata.bank_code}
                                            onChange={(e) => {
                                              setStudentformdata({
                                                ...studentformdata,
                                                bank_code: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Bank Account Number
                                        </div>
                                        <div className="inputMarginright mt-1">
                                          <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Account Number"
                                            autoComplete="off"
                                            value={
                                              studentformdata.bank_account_number
                                            }
                                            onChange={(e) => {
                                              setStudentformdata({
                                                ...studentformdata,
                                                bank_account_number:
                                                  e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Beneficiary Name
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Beneficiary Name"
                                            autoComplete="off"
                                            value={
                                              studentformdata.beneficiary_name
                                            }
                                            onChange={(e) => {
                                              setStudentformdata({
                                                ...studentformdata,
                                                beneficiary_name:
                                                  e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>

                  {/* <div className=" mt-1 Heading_bold">Academic Information</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Previous School Name
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className=" mt-1">
                              <input type="text" class="form-control" />
                            </div>
                          </Grid>
                        </Grid>




                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Class</div>
                            <div className="inputMarginright mt-1">
                              <input type="text" class="form-control" />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Year Of Passing
                            </div>
                            <div className="inputMarginright mt-1">
                              <input type="text" class="form-control" />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Board/University Name
                            </div>
                            <div className="inputMarginright mt-1">
                              <input type="text" class="form-control" />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card> */}

                  <div className=" mt-1 Heading_bold">Transport</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={2}>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value={buttonCheck}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    setPickupRouteName("");
                                    setPickupStopName("");
                                    setDropRouteName("");
                                    setDropStopName("");
                                  }

                                  setbuttonCheck(e.target.checked);
                                }}
                                id="flexCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Need Transport
                              </label>
                            </div>
                          </Grid>
                          <Grid item md={7}></Grid>
                          <Grid item md={3}>
                            {buttonCheck ? (
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration "
                                onClick={selectTransportRoute}
                              >
                                Select Transport Route
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                className="student_Form_taransport_able_button button_login_decoration "
                                disabled={true}
                              >
                                Select Transport Route
                              </Button>
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Pick Up</div>
                            <div className=" mt-1">
                              <p>Route Name - {pickupRouteName}</p>
                              <p>Stop Name - {pickupStopName} </p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Drop</div>
                            <div className=" mt-1">
                              <p>Route Name - {dropRouteName}</p>
                              <p>Stop Name - {dropStopName}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Document Upload</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Photo
                          </Grid>
                          <Grid item md={6} className="d-flex flex-column">
                            <div className="d-flex flex-row">
                              <input
                                className="nofilechoosenhide"
                                id="photo"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "PHOTO")
                                }
                              />
                              {blankValidator(photo) &&
                                blankValidator(photo.name) && (
                                  <Chip
                                    label={photo.name.substring(0, 15)}
                                    onClick={() => {
                                      HandleImageDailogBox(photo);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Birth Certificate
                          </Grid>
                          <Grid item md={6} className="d-flex flex-column">
                            <div className="d-flex flex-row">
                              <input
                                className="nofilechoosenhide"
                                id="birth"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "BIRTH_CERTIFICATE")
                                }
                              />
                              {blankValidator(birthCertificate) &&
                                blankValidator(birthCertificate.name) && (
                                  <Chip
                                    label={birthCertificate.name.substring(
                                      0,
                                      15
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(birthCertificate);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            National ID
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex flex-row">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "NATIONAL_CARD")
                                }
                              />

                              {blankValidator(nation) &&
                                blankValidator(nation.name) && (
                                  <Chip
                                    label={nation.name.substring(0, 15)}
                                    onClick={() => {
                                      HandleImageDailogBox(nation);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Immunization Details
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "IMMUNIZATION")
                                }
                              />

                              {blankValidator(immuneCertificate) &&
                                blankValidator(immuneCertificate.name) && (
                                  <Chip
                                    label={immuneCertificate.name.substring(
                                      0,
                                      15
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(immuneCertificate);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            School Leaving Certificate
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(
                                    e,
                                    "SCHOOL_LEAVE_CERTIFICATE"
                                  )
                                }
                              />

                              {blankValidator(schoolLeaveCertificate) &&
                                blankValidator(schoolLeaveCertificate.name) && (
                                  <Chip
                                    label={schoolLeaveCertificate.name.substring(
                                      0,
                                      15
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(
                                        schoolLeaveCertificate
                                      );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Add Any
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              multiple
                              onChange={(e) => UploadMultipleDocumentHandler(e)}
                            />
                          </Grid>

                          <Grid item md={6}>
                            {othersarr.length !== 0 &&
                              othersarr.map(
                                (item, index) =>
                                  blankValidator(item) &&
                                  blankValidator(item.name) && (
                                    <Chip
                                      key={index}
                                      className="ml-1"
                                      label={item.name.substring(0, 15)}
                                      color="primary"
                                      variant="outlined"
                                    />
                                  )
                              )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-5 Heading_bold">
                    Declaration<span className="text-danger pl-1"> *</span>
                  </div>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3">
                        <Grid item md={12}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="declare"
                              value={studentformdata.declarationCheck}
                              onChange={(e) => {
                                setdeclarationcheckedError(false);
                                setStudentformdata({
                                  ...studentformdata,
                                  declarationCheck: e.target.checked,
                                });
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              I hereby declare that the information given in
                              this application is true and correct to the best
                              of my knowledge and belief.
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            className={
                              studentformdata.declarationCheck === true
                                ? "student_Form_taransport_able_button_blue button_login_decoration button_width"
                                : "student_Form_taransport_able_button button_login_decoration button_width"
                            }
                            disabled={
                              studentformdata.declarationCheck === true
                                ? false
                                : true
                            }
                            onClick={studentDetaiform}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={selectroute}
        onClose={() => {
          setselectroute(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
      >
        <div className="Select_route_transport">
          <DialogTitle>
            Select Route
            <span
              className="float-right"
              onClick={() => {
                setselectroute(false);
              }}
            >
              <i className="fa fa-times cursor" aria-hidden="true"></i>{" "}
            </span>
            <hr />
          </DialogTitle>
          <div className="card_admissiondetails_height">
            <div className="textfiled_margin">
              <div className="text_filed_heading">Transport Route-Pick Up</div>
              <Grid className="grid_main_div">
                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      class="form-control"
                      onChange={(e) => selectPickupRouteHandler(e)}
                    >
                      <option value={-1}>select Pickup Route</option>
                      {pickupRouteArr.length > 0 ? (
                        pickupRouteArr.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                      ) : (
                        <option value={-1}>No Data</option>
                      )}
                    </select>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="mt-1">
                    <select
                      class="form-control"
                      onChange={(e) => selectPickupStartHandler(e)}
                    >
                      <option value={-1}>PickUp</option>
                      {pickupStartArr.length > 0 ? (
                        pickupStartArr.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                      ) : (
                        <option value={-1}>No Data</option>
                      )}
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="card_admissiondetails_height">
            <div className="textfiled_margin">
              <div className="text_filed_heading">Transport Route-Drop</div>
              <Grid className="grid_main_div">
                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      class="form-control"
                      onChange={(event) =>
                        selectDropRouteHandler(event.target.value)
                      }
                    >
                      <option value={-1}>select drop Route </option>
                      {dropRouteArr.length > 0 &&
                        dropRouteArr.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="mt-1">
                    <select
                      class="form-control"
                      onChange={(e) => selectDropStopHandler(e)}
                    >
                      <option value={-1}>select Route</option>
                      {dropStopArr.length > 0 &&
                        dropStopArr.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                  </div>
                </Grid>
              </Grid>

              <Grid className="grid_main_div mt-2 mb-2">
                <Grid item md={10}></Grid>

                <Grid item md={2}>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration button_width"
                    onClick={submitRouteHandler}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={OpenPhotoImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenPhotoImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + impagepath}
              download
              target="_blank"
              className="mr-3"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenPhotoImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + impagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentForm));
