import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import "react-phone-input-2/lib/style.css";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

import Checkbox from "@material-ui/core/Checkbox";
//------------------------table-------------------------
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { getFullName, showNotificationMsz } from "../../../utils/Validation.js";

const GenerateUser = ({ manageInstitution, ids, academicId }) => {
  //-------------------------------global id-------------------------
  let organization_id = parseInt(manageInstitution.organization_id);
  let institution_id = parseInt(manageInstitution.institution_id);
  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  const [allChecked, setAllChecked] = useState(false);
  //-------------------------------api calls-------------------------
  const client = useApolloClient();
  const [Create_User_Login_Data, {}] = useMutation(
    Service.Create_User_Login_Data
  );
  const classes = useStyles();
  //-------------------------------local state-------------------------
  const [selectedSection, setSelectedSection] = useState(-1);
  const [acadId, setAcademicId] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [userArr, setUserArr] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [academicSessionId, setAcademicSessionId] = useState("");
  const [academicSessionArr, setAcademicSessionArr] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [BulkuploadError, setBulkuploadError] = useState([]);
  const [searchByName, setSearchByName] = useState("");

  //error
  const [academicsessionError, setacademicsessionError] = useState(false);
  const [classIdError, setclassIdError] = useState(false);
  const [sectionidError, setsectionidError] = useState(false);

  //-------------------------------methods-------------------------
  useEffect(() => {
    setIsLoading(true);
    getStudentListData();
    // try {
    //   getStudentListData();
    //   client
    //     .query({
    //       query: Service.GET_ALL_ACADEMIC_SESSION,
    //       variables: {
    //         institution_id,
    //       },
    //     })
    //     .then((response) => {
    //       setAcademicSessionArr(response.data.getAllAcademicSession);
    //       if (academicId) {
    //         setAcademicId(academicId);
    //         academicSessionHandler(academicId);
    //       }
    //       setIsLoading(false);
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //       console.log("catch error", e);
    //     });
    // } catch (error) {
    //   setIsLoading(false);
    //   console.log("t and c error", error);
    // }

    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, client, academic_session_id]);
  const getStudentListData = async () => {
    try {
      setIsLoading(true);
      const response = await client.query({
        query: Service.studentProfile,
        variables: {
          institution_id: parseInt(institution_id),
        },
      });
      const filterArr = await response.data.studentProfile.filter((item) => {
        return !item.login;
      });
      const temp = [];
      await filterArr.map((item) => {
        temp.push({
          isSelected: false,
          id: item?.id,
          email: item?.email,
          studentName: getFullName(item?.name),
        });
      });
      setUserArr(temp);
      setIsLoading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const academicSessionHandler = async (academicSessionId) => {
    setIsLoading(true);
    if (parseInt(academicSessionId) === -1) {
      let temp = [];
      setUserArr([]);
      setSectionArr(temp);
      setClassArr(temp);
      setIsLoading(false);
      return;
    }
    setAcademicSessionId(academicSessionId);
    try {
      await client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academicSessionId),
          },
        })
        .then((response) => {
          setUserArr([]);
          setSectionArr([]);
          setClassArr([]);
          setClassArr(response.data.getGrade);
          if (ids) {
            const { grade } = ids;
            if (grade) {
              setSelectedClass(grade.id);
              classHandler(grade.id);
            }
          }

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classHandler = (classId) => {
    if (parseInt(classId) === -1) {
      setUserArr([]);
      setSectionArr([]);
      return;
    }
    try {
      setIsLoading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          setUserArr([]);
          setSectionArr([]);
          setSectionArr(response.data.getSectionAndGrade);
          if (ids && ids.id) {
            setSelectedSection(ids.id);
            sectionHandler(ids.id);
          }

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  const sectionHandler = async (sectionId) => {
    try {
      setSectionId(sectionId);
      if (parseInt(sectionId) === -1) {
        setUserArr([]);
        return;
      }
      setIsLoading(true);
      const response = await client.query({
        query: Service.GET_StudentList_by_SectionID,
        variables: {
          section_id: parseInt(sectionId),
        },
      });
      const filterArr = await response.data.getStudentListBySectionId.filter(
        (item) => {
          return !item.login;
        }
      );
      const temp = [];
      await filterArr.map((item) => {
        temp.push({
          isSelected: false,
          id: item?.student_profile?.id,
          email: item?.student_profile?.email,
          studentName: getFullName(item?.student_profile?.name),
        });
      });
      setUserArr(temp);
      setIsLoading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setIsLoading(true);
      console.log("error", error);
    }
  };

  const allCheckHandler = () => {
    if (userArr.length > 0) {
      setAllChecked(!allChecked);
    }

    let temp = [];
    userArr.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      // item.isSelected = !item.isSelected;
      temp.push(item);
    });
    setUserArr(temp);
  };

  const checkBoxHandler = (id) => {
    let temp = [];
    let checkAllCheck = true;
    userArr.map((item) => {
      console.log("item.id ", item.id, "  id= ", id);
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      temp.push(item);
      if (item.isSelected === false && checkAllCheck == true) {
        checkAllCheck = false;
      }
    });
    setAllChecked(checkAllCheck);
    setUserArr(temp);
  };

  const generateUserHandler = () => {
    try {
      setIsLoading(true);
      let user_data = [];
      setBulkuploadError([]);
      userArr.map((item) => {
        if (item.isSelected === true) {
          user_data.push({
            user_id: item.id,
            user_type: "STUDENT",
            login_id: item.email,
            email_address: item.email,
          });
        }
      });

      Create_User_Login_Data({
        variables: {
          institution_id,
          user_data: user_data,
          organization_id,
        },
      })
        .then(async (response) => {
          // await sectionHandler(sectionId);
          setIsUpdated(true);
          setIsLoading(false);

          showNotificationMsz("Login generated successfully", "success");

          setBulkuploadError(response.data.createLoginDetailsForUser);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  const filterStudentArr = userArr.filter((event) => {
    return (
      event.studentName.toLowerCase().indexOf(searchByName.toLowerCase()) !== -1
    );
  });
  return (
    <div>
      <Grid className="grid_main_div  p-3">
        {/* <Grid item md={3} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Academic Session</h6>
          <div className=" mt-1">
            <select
              value={acadId}
              class="form-control"
              onChange={(e) => {
                setacademicsessionError(false);
                academicSessionHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Academic Session</option>
              {academicSessionArr.length !== 0 &&
                academicSessionArr.map((item) => (
                  <option value={item.id}>{item.session_name}</option>
                ))}
            </select>
            {academicsessionError && (
              <span className="text-danger">Select the Academic Session</span>
            )}
          </div>
        </Grid>
        <Grid item md={3} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Class</h6>
          <div className=" mt-1">
            <select
              value={selectedClass}
              class="form-control"
              onChange={(e) => {
                setSelectedClass(e.target.value);
                setclassIdError(false);
                classHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Class</option>
              {classArr.length !== 0 &&
                classArr.map((item) => (
                  <option value={item.id}>{item.gradeName}</option>
                ))}
            </select>
            {classIdError && (
              <span className="text-danger">Select the Class</span>
            )}
          </div>
        </Grid>
        <Grid item md={3} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Section</h6>
          <select
            value={selectedSection}
            class="form-control"
            onChange={(e) => {
              setsectionidError(false);
              sectionHandler(e.target.value);
              setSelectedSection(e.target.value);
            }}
          >
            <option value={-1}>Select Section</option>
            {sectionArr.length !== 0 &&
              sectionArr.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
          </select>
          {sectionidError && (
            <span className="text-danger">Select the Section</span>
          )}
        </Grid> */}
        <Grid item md={3} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Name</h6>
          <div className="mr-1 mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Search name"
              autoComplete="off"
              value={searchByName}
              onChange={(e) => {
                setSearchByName(e.target.value);
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* --------------------list of users-------------- */}
      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" className="table_header mr-3">
                  <Checkbox checked={allChecked} onChange={allCheckHandler} />
                </StyledTableCell>
                {/* <StyledTableCell className="table_header " align="left">
                  ID
                </StyledTableCell> */}

                <StyledTableCell align="left" className="table_header">
                  Name
                </StyledTableCell>

                <StyledTableCell align="left" className="table_header">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Phone number
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filterStudentArr.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filterStudentArr
              ).map((row) => (
                <StyledTableRow>
                  <StyledTableCell
                    className="mr-3"
                    padding="checkbox"
                    align="center"
                  >
                    <Checkbox
                      checked={row.isSelected}
                      onChange={() => checkBoxHandler(row.id)}
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell align="left">
                   
                  </StyledTableCell> */}

                  <StyledTableCell align="left">
                    {row.studentName}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.email && row.email}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {/* {row.email && row.email} */}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterStudentArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* -------------------------list of users---------------------- */}

      <Grid className="grid_main_div d-flex p-3">
        <Grid item md={10}></Grid>
        <Grid item md={2}>
          <Button
            variant="contained"
            className="button_enquiry_details button_login_decoration button_width"
            onClick={generateUserHandler}
          >
            Generate Login
          </Button>
        </Grid>
      </Grid>

      <Loder loading={isLoading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(GenerateUser);
