import React, { useState } from "react";
import HOC1 from "../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Holiday from "../Common/Holiday/Holiday.jsx";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function StaffHomePage(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [MessageInbox] = useState([
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
    {
      message:
        "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form...",
    },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="enquiry_heading">
                          <div className=" mt-2 Heading_bold">Email Inbox</div>
                          <div className="ml-3 mt-2 ">
                            <span className="openassignemntcolor mr-2">
                              Inbox
                            </span>
                            <span className="openassignemntcolor mr-2">
                              Sent
                            </span>
                            <span className="openassignemntcolor mr-2">
                              Trash
                            </span>
                            <span className="openassignemntcolor">Compose</span>
                          </div>
                        </div>

                        <div className="enquiry_heading">
                          <div className="mt-2 Heading_bold">
                            <div>
                              <span>
                                {" "}
                                <Checkbox />
                              </span>
                              <span>
                                <Button
                                  variant="contained"
                                  className="default button_login_decoration notice_text_tranformation"
                                >
                                  Delete
                                </Button>
                              </span>
                            </div>
                          </div>
                          <div className="ml-3 mt-2 d-flex ">
                            <span className="mr-2">
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                              />
                            </span>
                            <span>
                              <Button
                                variant="contained"
                                className="button_login_decoration notice_text_tranformation"
                              >
                                <i class="fa fa-filter"></i>Filter
                              </Button>
                            </span>
                          </div>
                        </div>

                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? MessageInbox.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : MessageInbox
                                ).map((row) => (
                                  <StyledTableRow className="transition_onHover">
                                    <StyledTableCell
                                      align="left"
                                      padding="checkbox"
                                    >
                                      <Checkbox />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <div>
                                        <span>{row.message}</span>

                                        <span className="float-right">
                                          <div className="d-flex ">
                                            <span className="mr-2">
                                              {" "}
                                              <i class="fa fa-tag"></i>
                                            </span>
                                            <span className="mr-2">
                                              {" "}
                                              <i class="fa fa-edit"></i>
                                            </span>
                                            <span>
                                              {" "}
                                              <i class="fa fa-trash"></i>
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={MessageInbox.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default (HOC1(StaffHomePage));
