import React from "react";
import "./Exam.css";
import HOC1 from "../../Hoc1.jsx";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import { timeStampToDate } from "../../../utils/Validation";
//redux
// import * as Service from "../../../services/index";
// import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const StudentSyllabus = (props) => {
  console.log("props.location?.state?.row", props.location?.state?.row);
  const { end_calendar, start_calendar, syllabus, subject, marks, location } =
    props.location?.state?.row;
  let institution_Name = props.user_info.institution_name;
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />
              <Card>
                <div className="p-3">
                  <Grid className="grid_main_div mt-1">
                    <Grid item md={6}>
                      <div className="inputMarginright">
                        <label for="exampleInputEmail1">Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          autoComplete="off"
                          value={subject?.name}
                          disabled={true}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6}>
                      <div className="inputMarginright">
                        <label for="exampleInputEmail1">Exam Date</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          autoComplete="off"
                          value={timeStampToDate(start_calendar?.datetime)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid className="grid_main_div mt-1">
                    <Grid item md={6}>
                      <div className="inputMarginright">
                        <label for="exampleInputEmail1">Start Time</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            autoComplete="off"
                            value={new Intl.DateTimeFormat("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              // second: "2-digit",
                            }).format(start_calendar?.datetime)}
                          />
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              inputVariant="outlined"
                              variant="outlined"
                              //   value={StarttimeEdit}
                            />
                          </MuiPickersUtilsProvider> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={6}>
                      <div className="inputMarginright">
                        <label for="exampleInputEmail1">End Time</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            autoComplete="off"
                            value={new Intl.DateTimeFormat("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              // second: "2-digit",
                            }).format(end_calendar?.datetime)}
                          />
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              inputVariant="outlined"
                              variant="outlined"
                              //   value={EndtimeEdit}
                            />
                          </MuiPickersUtilsProvider> */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid className="grid_main_div mt-1">
                    <Grid item md={6}>
                      <div className="inputMarginright">
                        <label for="exampleInputEmail1">Max Marks</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          autoComplete="off"
                          placeholder="Marks"
                          value={marks && marks}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6}>
                      <div className="inputMarginright">
                        <label for="exampleInputEmail1">Location</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlSelect1"
                          value={location.name}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid className="grid_main_div mt-1">
                    <Grid item md={12}>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Syllabus</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="4"
                          placeholder="Syllabus..."
                          value={syllabus}
                        ></textarea>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentSyllabus));
