import { useApolloClient } from "@apollo/client";
import {
  CREATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  EDIT_DEPARTMENT,
  Create_Manage_Subject,
  Delete_Manage_Subject,
  Edit_Subject_Data,
  Get_Department_Data,
  Get_Manage_Grade,
  GET_Section_Data,
  GET_StudentList_by_SectionID,
  Get_Designation_Data,
  Get_All_Staff_Details,
  Get_Id_based_Employee_details,
  Get_Manage_Subject_Data,
} from "../services/index";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { showNotificationMsz } from "../utils/Validation";

const APIHooks = () => {
  //---------------------------------global data---------------------
  //---------------------------------api calls---------------------

  const client = useApolloClient();
  //----------------------- department storage------------------------
  const [departmentArr, setDepartmentArr] = useState([]);
  const [staffDepartmentArr, setStaffDepartmentArr] = useState([]);
  const [createDepartmentAPI, {}] = useMutation(CREATE_DEPARTMENT);
  const [deleteDepartment, {}] = useMutation(DELETE_DEPARTMENT);
  const [updateDepartment, {}] = useMutation(EDIT_DEPARTMENT);
  //----------------------- class storage------------------------
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [studentSectionArr, setStudentSectionArr] = useState([]);
  const [studentListArr, setStudentListArr] = useState([]);
  //----------------------- designation storage------------------------
  const [designationArr, setDesignationArr] = useState([]);
  const [staffDesignationArr, setStaffDesignationArr] = useState([]);
  //-----------------------get department data------------------------
  //-----------------------get employee data------------------------
  const [employeeArr, setEmployeeArr] = useState([]);
  const [singleEmpData, setSingleEmpData] = useState([]);
  const [createSubject, {}] = useMutation(Create_Manage_Subject);
  const [deleteSubject, {}] = useMutation(Delete_Manage_Subject);
  const [updateSubject, {}] = useMutation(Edit_Subject_Data);
  //-----------------------get employee data------------------------
  //-----------------------get subject data------------------------
  const [subjectArr, setSubjectArr] = useState([]);

  //-----------------------get subject data------------------------
  const getDepartmentAPI = async (institution_id) => {
    try {
      const { data } = await client.query({
        query: Get_Department_Data,
        variables: {
          institution_id,
        },
      });
      if (data.getDepartment) {
        await setDepartmentArr(data.getDepartment);
        await setStaffDepartmentArr(data.getDepartment);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get department data------------------------
  //-----------------------create department data------------------------
  const CreateDepartmentAPI = async (institution_id, name) => {
    try {
      const { data } = await createDepartmentAPI({
        variables: { institution_id, name },
      });
      showNotificationMsz(data.addDepartment.message, "success");
      getDepartmentAPI(institution_id);
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------create department data------------------------
  //-----------------------delete department data------------------------
  const DeleteDepartmentAPI = async (institution_id, id) => {
    try {
      const { data } = await deleteDepartment({
        variables: { id },
      });
      showNotificationMsz(data.deleteDepartment.message, "success");
      getDepartmentAPI(institution_id);
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------delete department data------------------------
  //-----------------------update department data------------------------
  const UpdateDepartmentAPI = async (institution_id, id, name) => {
    try {
      const { data } = await updateDepartment({
        variables: { id, name },
      });
      showNotificationMsz(data.updateDepartment.message, "success");
      getDepartmentAPI(institution_id);
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------update department data------------------------
  //-----------------------get class data------------------------
  const getClassAPI = async (academic_session_id) => {
    try {
      const { data } = await client.query({
        query: Get_Manage_Grade,
        variables: {
          academic_session_id,
        },
      });
      if (data.getGrade) {
        await setClassArr(data.getGrade);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get class data------------------------
  //-----------------------get section data------------------------
  const getSectionAPI = async (grade_id) => {
    try {
      setSectionArr([]);
      setStudentSectionArr([]);
      setStudentListArr([]);
      const { data } = await client.query({
        query: GET_Section_Data,
        variables: {
          grade_id,
        },
      });
      if (data.getSectionAndGrade) {
        setStudentSectionArr(data.getSectionAndGrade);
        setSectionArr(data.getSectionAndGrade);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get section data------------------------
  //-----------------------to clean arr------------------------
  const arrayCleaner = (key) => {
    try {
      switch (key) {
        case "CLASS":
          setSectionArr([]);
        case "STUDENT_CLASS":
          setStudentSectionArr([]);
          setStudentListArr([]);
        case "STUDENT_SECTION":
          setStudentListArr([]);
        case "STAFF":
          setEmployeeArr([]);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------to clean arr------------------------
  //-----------------------get section data------------------------
  const getStudentListAPI = async (section_id) => {
    try {
      const { data } = await client.query({
        query: GET_StudentList_by_SectionID,
        variables: {
          section_id,
        },
      });
      if (data.getStudentListBySectionId) {
        setStudentListArr(data.getStudentListBySectionId);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get section data------------------------
  //-----------------------get desingation data------------------------
  const getDesignationAPI = async (institution_id) => {
    try {
      const { data } = await client.query({
        query: Get_Designation_Data,
        variables: {
          institution_id,
        },
      });
      if (data.getDesignation) {
        await setStaffDesignationArr(data.getDesignation);
        await setDesignationArr(data.getDesignation);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get desingation data------------------------
  //-----------------------get all staff data------------------------
  const getAllStaffAPI = async (department_id, designation_id) => {
    try {
      if (department_id && designation_id) {
        const { data } = await client.query({
          query: Get_All_Staff_Details,
          variables: {
            department_id,
            designation_id,
          },
        });
        if (
          data.getEmployeeByDepartmentAndDesignationId &&
          data.getEmployeeByDepartmentAndDesignationId.length > 0
        ) {
          setEmployeeArr(data.getEmployeeByDepartmentAndDesignationId);
        }
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get all staff data------------------------
  //-----------------------get single staff data------------------------
  const getSingleStaffAPI = async (id) => {
    try {
      const { data } = await client.query({
        query: Get_Id_based_Employee_details,
        variables: {
          id,
        },
      });
      if (data.getEmployeeById) {
        setSingleEmpData(data.getEmployeeById);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get single staff data------------------------
  //-----------------------get subject data------------------------
  const getSubjectAPI = async (institution_id) => {
    try {
      const { data } = await client.query({
        query: Get_Manage_Subject_Data,
        variables: {
          institution_id,
        },
      });
      if (data.getSubject) {
        await setSubjectArr(data.getSubject);
      }
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------get subject data------------------------
  //-----------------------create department data------------------------
  const createSubjectAPI = async (institution_id, name) => {
    try {
      const { data } = await createSubject({
        variables: { institution_id, name },
      });

      showNotificationMsz(data.addSubject.message, "success");
      getSubjectAPI(institution_id);
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------create subject data------------------------
  //-----------------------delete subject data------------------------
  const deleteSubjectAPI = async (institution_id, id) => {
    try {
      const { data } = await deleteSubject({
        variables: { id },
      });
      showNotificationMsz(data.deleteSubject.message, "success");
      getSubjectAPI(institution_id);
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------delete subject data------------------------
  //-----------------------update department data------------------------
  const updateSubjectAPI = async (id, institution_id, name) => {
    try {
      const { data } = await updateSubject({
        variables: { id, institution_id, name },
      });
      showNotificationMsz(data.updateSubject.message, "success");
      getSubjectAPI(institution_id);
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
  };
  //-----------------------update department data------------------------

  return {
    sectionArr,
    classArr,
    departmentArr,
    studentSectionArr,
    studentListArr,
    designationArr,
    staffDesignationArr,
    staffDepartmentArr,
    employeeArr,
    subjectArr,
    singleEmpData,
    getDepartmentAPI,
    getClassAPI,
    getSectionAPI,
    arrayCleaner,
    getStudentListAPI,
    getDesignationAPI,
    CreateDepartmentAPI,
    DeleteDepartmentAPI,
    UpdateDepartmentAPI,
    getAllStaffAPI,
    getSingleStaffAPI,
    getSubjectAPI,
    createSubjectAPI,
    deleteSubjectAPI,
    updateSubjectAPI,
  };
};

export default APIHooks;
