import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function ManageGrade(props) {
  let academic_session_id = parseInt(
    props.academic_session_id.academic_session_id
  );

  //name instituition
  let Organization_Name = props.academic_session_id.institution_name;
  // mutation create data api
  const [Add_Grade, {}] = useMutation(Service.Add_Grade);
  // mutation delet data api
  const [Delete_Grade, {}] = useMutation(Service.Delete_Grade);
  //updata manage role api
  const [Edit_Manage_Grade, {}] = useMutation(Service.Edit_Manage_Grade);
  const client = useApolloClient();

  const [addMangeopen, setaddMangeopen] = useState(false);
  const [opendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [nameedit, setNameedit] = useState("");
  const [deleteid, setDeleteid] = useState("");
  const [gradeid, setGradeid] = useState("");
  const [name, setname] = useState("");
  const [gradenamedelete, setgradenamedelete] = useState("");
  const [managegrade, setmanagegrade] = useState([]);
  const [isloading, setisloading] = useState(false);

  //error
  const [nameerror, setnameerror] = useState(false);
  const [editnameerror, seteditnameerror] = useState(false);

  const handleaddMangeopen = () => {
    setaddMangeopen(true);
  };
  const closeaddMangeopen = () => {
    setaddMangeopen(false);
  };

  const OpenEditQuery = (data) => {
    setNameedit(data.gradeName);
    setGradeid(data.id);
    setOpendailogtoEdit(true);
  };

  const DeleteDailogBox = (data) => {
    setDeleteid(data.id);
    setgradenamedelete(data.gradeName);
    setopendialogConfimation(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagegrade(response.data.getGrade);
          setname("");
          setaddMangeopen(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [client, academic_session_id, isUpdated]);

  const deleteGradeHandler = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_Grade({
        variables: { id },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.deleteGrade.message, "success");

          setopendialogConfimation(false);
          setIsUpdated(!isUpdated);
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const AddGrade = () => {
    if (!blankValidator(name)) {
      setnameerror(true);
      return;
    }
    try {
      setisloading(true);
      Add_Grade({
        variables: {
          gradeName: name,
          academic_session_id,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addGrade.message, "success");

          setIsUpdated(!isUpdated);
        },
        (err) => {
          setisloading(false);

          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const UpdateGradeHandler = () => {
    let id = parseInt(gradeid);
    if (!blankValidator(nameedit)) {
      seteditnameerror(true);
      return;
    }
    try {
      setisloading(true);
      Edit_Manage_Grade({
        variables: { id, gradeName: nameedit },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.updateGrade.message, "success");
          setOpendailogtoEdit(false);
          setIsUpdated(!isUpdated);
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const sortedList = managegrade.sort((a, b) =>
    a.gradeName.localeCompare(b.gradeName)
  );
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Manage Class</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {addMangeopen === false ? (
                          <div className="d-flex" onClick={handleaddMangeopen}>
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add New Class
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div
                                          className="text-right"
                                          onClick={closeaddMangeopen}
                                        >
                                          <span className="iconstoaddfee">
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        </div>
                                        <div className="text_filed_heading">
                                          Class
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Class Name"
                                            autoComplete="off"
                                            value={name}
                                            onChange={(e) => {
                                              setnameerror(false);
                                              setname(e.target.value);
                                            }}
                                          />
                                          {nameerror && (
                                            <span className="text-danger">
                                              Enter the Class Name
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className=" mb-1">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={AddGrade}
                                        >
                                          Create
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin cardheight_overflow">
                        {/* <div className="ml-3 mt-2 Heading_bold">Role List</div> */}
                        {sortedList.length ? (
                          sortedList.map((item, index) => (
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={2}></Grid>
                                      <Grid item md={6}>
                                        <div className=" mt-1 mb-1">
                                          <input
                                            type="text"
                                            className="form-control input_border_none department_color_disabled"
                                            id="exampleFormControlInput1"
                                            placeholder="Name"
                                            // autoComplete="off"
                                            value={item.gradeName}
                                            disabled={true}
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={4}>
                                        {" "}
                                        <div className="d-flex pt-2">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Class Name</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={nameedit}
                    onChange={(e) => {
                      seteditnameerror(false);
                      setNameedit(e.target.value);
                    }}
                  />
                  {editnameerror && (
                    <span className="text-danger">Enter the Class Name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4 mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateGradeHandler}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Class : {gradenamedelete}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteGradeHandler(deleteid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  academic_session_id: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(ManageGrade)));
