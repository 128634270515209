import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import {
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//--------------redux-----------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//--------------api calls-----------------------------
import { useApolloClient } from "@apollo/client";
import * as Service from "../../../services/index";
import { useMutation } from "@apollo/client";

const ManageBankAccount = ({ ADMIN_DATA, navigate }) => {
  const { institution_id, institution_name } = ADMIN_DATA;
  //-----------------------api calls------------------------
  const [Add_Or_Update_BankInformation, {}] = useMutation(
    Service.Add_Or_Update_BankInformation
  );
  const client = useApolloClient();
  //-----------------------local state------------------------
  const [isloading, setisloading] = useState(false);
  //---------------error------------------------
  const [bankNameErr, setBankNameErr] = useState(false);
  const [bankCodeErr, setBankCodeErr] = useState(false);
  const [bankAccountNumberErr, setBankAccountNumberErr] = useState(false);
  const [bankBenificieryNameErr, setBankBenificieryNameErr] = useState(false);
  //---------------error------------------------
  const [bankname, setbankname] = useState("");
  const [bankcode, setbankcode] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [beneficiaryname, setbeneficiaryname] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getBankInfoData();
  }, []);

  const getBankInfoData = async () => {
    try {
      setisloading(true);
      const response = await client.query({
        query: Service.Get_Bank_Information,
        variables: {
          institution_id,
        },
      });
      if (response.data.getBankInfo) {
        const { bank_name, bank_code, account_number, beneficiary_name } =
          response.data.getBankInfo;
        setbankname(bank_name);
        setbankcode(bank_code);
        setaccountnumber(account_number);
        setbeneficiaryname(beneficiary_name);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };
  const saveBankInformationHandler = async () => {
    try {
      if (!blankValidator(bankname)) {
        setBankNameErr(true);
        return;
      }
      if (!blankValidator(bankcode)) {
        setBankCodeErr(true);
        return;
      }
      if (!blankValidator(accountnumber)) {
        setBankAccountNumberErr(true);
        return;
      }
      if (!blankValidator(beneficiaryname)) {
        setBankBenificieryNameErr(true);
        return;
      }
      setisloading(true);
      const response = await Add_Or_Update_BankInformation({
        variables: {
          institution_id: parseInt(institution_id),
          bank_name: bankname,
          bank_code: bankcode,
          beneficiary_name: beneficiaryname,
          account_number: accountnumber,
        },
      });
      showNotificationMsz(
        response.data.addAndUpdateBankInfo.message,
        "success"
      );
      getBankInfoData();
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Manage Bank Account
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div className="card_content_instition">
                              <Grid className="grid_main_div">
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Bank Name
                                  </div>
                                  <div className="inputMarginright mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Bank Name"
                                      autoComplete="off"
                                      value={bankname}
                                      onChange={(e) => {
                                        setBankNameErr(false);
                                        setbankname(e.target.value);
                                      }}
                                    />

                                    {bankNameErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Bank Code
                                  </div>
                                  <div className=" mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Bank Code"
                                      autoComplete="off"
                                      value={bankcode}
                                      onChange={(e) => {
                                        setBankCodeErr(false);
                                        setbankcode(e.target.value);
                                      }}
                                    />
                                    {bankCodeErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className="card_content_instition">
                              <Grid className="grid_main_div">
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Bank Account Number
                                  </div>
                                  <div className="inputMarginright mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Account Number"
                                      autoComplete="off"
                                      value={accountnumber}
                                      onChange={(e) => {
                                        setBankAccountNumberErr(false);
                                        setaccountnumber(e.target.value);
                                      }}
                                    />
                                    {bankAccountNumberErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item md={6}>
                                  <div className="text_filed_heading">
                                    Beneficiary Name
                                  </div>
                                  <div className=" mt-1">
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Beneficiary Name"
                                      autoComplete="off"
                                      value={beneficiaryname}
                                      onChange={(e) => {
                                        setBankBenificieryNameErr(false);
                                        setbeneficiaryname(e.target.value);
                                      }}
                                    />
                                    {bankBenificieryNameErr && (
                                      <div className="text-danger">
                                        Please enter bank name
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <Grid className="grid_main_div">
                              <Grid item md={10}></Grid>
                              <Grid item md={2}>
                                <div className="mb-2 ">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn  button_login_decoration button_width"
                                    onClick={saveBankInformationHandler}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(ManageBankAccount)));
