import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Holiday from "../Holiday/Holiday.jsx";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { regex } from "../../../utils/Validation.js";

function CommonNotificationopened(props) {
  const { emailDetails } = props.location.state;
  console.log("email details ", JSON.stringify(emailDetails));
  const replyBack = () => {};

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="d-flex justify-content-end p-2">
                  <p
                    style={{
                      color: "#0070ba",
                      textDecoration: "underline",
                      marginRight: 7,
                      cursor: "pointer",
                    }}
                    onClick={() =>props.navigate("/notification")}
                  >
                    Inbox
                  </p>
                  <p
                    style={{
                      color: "#0070ba",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>props.navigate("/notificationComposed")}
                  >
                    Compose
                  </p>
                </div>
                <div className="card_margin_both_side">
                  <Grid className="grid_main_div mb-3">
                    <Grid item md={12}>
                      <Card>
                        <div style={{ display: "flex", padding: 15 }}>
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png"
                            height={50}
                            width={60}
                          />
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ display: "flex", marginLeft: 15 }}>
                              <p>From</p>
                              <p style={{ color: "#00ACFF", marginLeft: 8 }}>
                                {emailDetails && emailDetails.from?.text}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* docs attached */}
                        <div className="email_attachement">
                          <p className="email_attachment_text">
                            schoolManagementSystem.pdf{" "}
                          </p>
                        </div>
                        <div className="email_attachement">
                          <p style={{ padding: 15, marginTop: 10 }}>
                            {emailDetails &&
                              emailDetails.text?.replace(regex, "")}
                          </p>
                        </div>

                        {/* <div className="card_admissiondetails_height ">
                          <div className="textfiled_margin mb-2">
                            <div
                              className="mt-3 ml-1 Heading_bold d-flex "
                              style={{ alignItems: "baseline" }}
                            >
                              {emailDetails && emailDetails.subject}{" "}
                              <p
                                style={{
                                  fontSize: 12,
                                  color: "#4c4c4c",
                                  marginLeft: 15,
                                }}
                              >
                                From:
                                {emailDetails && emailDetails.from?.text}
                              </p>
                            </div>
                            <Grid className="grid_main_div mt-3 mb-3">
                              <Grid item md={3}>
                                <div className="d-flex">
                                  <div className="ml-4">
                                    <span>
                                      <i
                                        class="fa fa-reply"
                                        onClick={replyBack}
                                      ></i>
                                    </span>
                                    <span className="pl-2">
                                      <i class="fa fa-reply-all"></i>
                                    </span>
                                    <span className="pl-2">
                                      <i class="fa fa-arrow-circle-right"></i>
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item md={2}></Grid>
                              <Grid item md={5}>
                                <div className="d-flex">
                                  <div className="d-flex">
                                    <span>
                                      <i class="fa fa-inbox"></i>
                                    </span>
                                    <span className="pl-2">Archive</span>
                                  </div>
                                  <div className="ml-3 d-flex">
                                    <span>
                                      <i class="fa fa-folder-open"></i>
                                    </span>
                                    <span className="pl-2">Move</span>
                                  </div>
                                  <div className="ml-3 d-flex">
                                    <span>
                                      <i class="fa fa-trash"></i>
                                    </span>
                                    <span className="pl-2">Delete</span>
                                  </div>
                                  <div className="ml-3 d-flex">
                                    <span></span>
                                    <span className="pl-2">Spam</span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <hr />

                            <div className="mt-2">
                              <Grid className="grid_main_div mt-3 mb-5">
                                <Grid item md={12}>
                                  <div className="mt-2 content_justfiy"></div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div> */}
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(CommonNotificationopened)));
