import React from "react";
import { dateFormatToShow } from "../../utils/Validation";
import MyTeacherMonday from "./MyTimetable/MyTeacherMonday";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import moment from "moment";

const PtTeacherTimeTableHome = (props) => {
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  let  day = new Date().getDay() - 1;
if(day < 0 ){
  day = 6
}
  const [value, setValue] = React.useState(day);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="enquirydetails_background mb-2">
        <div className="enquiry_heading">
          <div className="ml-3 mt-3 Heading_bold">My Time Table</div>
          <div className="mr-3 mt-3">
            {`${dateFormatToShow(startOfWeek)}-${dateFormatToShow(endOfWeek)} `}
          </div>
        </div>
        <div className="card_margin_both_side">
          <Card>
            <div>
              <div className="textfiled_margin mb-2">
                <div className="mt-2 tabs_class_for_Mobile">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                    left
                  >
                    <Tab
                      label="Monday"
                      {...a11yProps(0)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                    <Tab
                      label="Tuesday"
                      {...a11yProps(1)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                    <Tab
                      label="Wednesday"
                      {...a11yProps(2)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                    <Tab
                      label="Thursday"
                      {...a11yProps(3)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                    <Tab
                      label="Friday"
                      {...a11yProps(4)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                    <Tab
                      label="Saturday"
                      {...a11yProps(5)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                    <Tab
                      label="Sunday"
                      {...a11yProps(6)}
                      className="button_login_decoration text_tranformation_exam"
                      style={{ minWidth: 130 }}
                    />
                  </Tabs>
                </div>

                <div className="Pannel_padding_hidden">
                  <TabPanel value={value} index={0}>
                    <MyTeacherMonday day={1} name="Monday" />
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <MyTeacherMonday day={2} name="Tuesday" />
                  </TabPanel>

                  <TabPanel value={value} index={2}>
                    <MyTeacherMonday day={3} name="Wednesday" />
                  </TabPanel>

                  <TabPanel value={value} index={3}>
                    <MyTeacherMonday day={4} name="Thrusday" />
                  </TabPanel>

                  <TabPanel value={value} index={4}>
                    <MyTeacherMonday day={5} name="Friday" />
                  </TabPanel>

                  <TabPanel value={value} index={5}>
                    <MyTeacherMonday day={6} name="Saturday" />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <MyTeacherMonday day={7} name="Sunday" />
                  </TabPanel>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default PtTeacherTimeTableHome;
