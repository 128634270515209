import React, { useState, useEffect } from "react";
import Loder from "../Loder/Loder.jsx";
import * as Service from "../../services/index";
import { useApolloClient } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  get_Meeting_join_ling,
  newDateToDate,
  changeTimeFormat,
  getHHMMSStoDate,
} from "../../utils/Validation";
export default function OnlineClassTeacher(props) {
  const [isloading, setisloading] = useState(false);
  const [meetingURL, setMeetingURL] = useState("");

  const [openJoinDailog, setopenJoinDailog] = useState(false);
  const [classData, setclassData] = useState([]);
  const temp = new Date();

  const [time, settime] = useState(temp);
  const client = useApolloClient();
  let { first_name, middle_name, last_name } = props.userName;
  let name = `${first_name + "_" + middle_name + "_" + last_name}`;
  useEffect(() => {
    const getStudentList = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Current_CLass_For_Teacher,
            variables: {
              user_id: parseInt(props.user_id),
              institution_id: parseInt(props.institution_id),
              dateAndTime: newDateToDate(time),
              day: time.getDay().toString(),
              time: time.toLocaleTimeString("en-GB").substring(0, 8),
              //  time: "10:30:30"
            },
          })
          .then((response) => {
            if (
              response.data.getActivePeriodForTeacher !== null &&
              response.data.getActivePeriodForTeacher !== undefined
            ) {
              let dataForClass = response?.data?.getActivePeriodForTeacher;
              let temp = [];
              for (let index = 0; index < dataForClass.length; index++) {
                let item = dataForClass[index];
                let startDate = getHHMMSStoDate(item?.timetable?.start_time);
                let endDate = getHHMMSStoDate(item?.timetable?.end_time);
                let currentDateandTime = new Date();

                if (
                  startDate <= currentDateandTime &&
                  endDate >= currentDateandTime
                ) {
                  temp.push(item);
                }
              }
              setclassData(temp);
            }

            setisloading(false);
          })
          .catch((e) => {
            console.log("catch error", e);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getStudentList();
  }, [client, props.section_id]);
  const openDailogToJoin = (timetable) => {
    let temp = JSON.parse(timetable.location.meeting_info);
    if (temp) {
      let mainurl = get_Meeting_join_ling(
        temp.response.meetingID[0],
        name,
        temp.response.attendeePW[0],
        props.userType,
        temp.response.moderatorPW[0],
      );
      window.open(mainurl);
   //   setMeetingURL(mainurl);
    }

    //setopenJoinDailog(true);
  };

  return (
    <div className="timesession_alignright mr-2">
      {classData.map((item, index) => (
        <div className="text-primary" key={index}>
          {/* <span id="circle"></span> */}
          <div>
            {item.timetable && (
              <div style={{ fontSize: 15 }}>
                <span style={{ paddingRight: 7 }}>
                  {changeTimeFormat(item.timetable?.start_time)} -{" "}
                  {changeTimeFormat(item.timetable?.end_time)}
                </span>
                |
                {item.timetable?.subject !== null ? (
                  <span style={{ paddingRight: 7, paddingLeft: 7 }}>
                    {item?.timetable?.subject?.name}
                  </span>
                ) : (
                  ""
                )}
                |
                <span
                  style={{ paddingLeft: 7 }}
                  onClick={() => {
                    openDailogToJoin(item.timetable);
                  }}
                >
                  Join
                </span>
              </div>
            )}
          </div>
        </div>
      ))}

      <Loder loading={isloading} />

      <Dialog
        open={openJoinDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setopenJoinDailog(false);
        }}
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setopenJoinDailog(false);
              }}
            ></i>
          </div>
        </div>
        <DialogContent>
          <iframe
            src={meetingURL}
            title="description"
            className="iframe_width"
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
