export const ReportDropdownclass = [
  { label: "Timetable", value: "Timetable" },
  { label: "Teacher List", value: "TeacherList" },
  { label: "Examinations", value: "Examinations" },
  { label: "Attendance", value: "Attendance" },
  { label: "Student List", value: "StudentList" },
  { label: "Event Attendance", value: "AllStaffAttendance" },
];
export const StaffViewReportArr = [
  { label: "Event Attendance", value: "Event Attendance" },
  { label: "Staff Detail", value: "Staff Details" },
  // { label: "Attendance", value: "Attendance" },
  { label: "Staff Timetable", value: "Staff Timetable" },
];
export const studentDropDwonInfoArr = [
  { label: "Student Details", value: "Student_Details" },
  { label: "Class Attendance", value: "Class_Attendance" },
  { label: "Transport Attendance", value: "Transport_Attendance" },
  { label: "Fees", value: "Fees" },
  { label: "Event attendance", value: "Event attendance" },
  { label: "TimeTable", value: "Timetable" },
  { label: "Examinations", value: "Examinations" },
];
export const AllStaffViewReports = [
  { label: "Event Attendance", value: "AllStaffAttendance" },
  { label: "Staff Details", value: "StaffDetails" },
  // { label: "Attendance", value: "Attendance" },
];
export const AttainedArr = [
  { label: " -Select-", value: -1 },
  { label: " Bachelor's Degree", value: 1 },
  { label: " B.Tech/BE", value: 2 },
  { label: "Post Graduation", value: 3 },
  { label: "Ph.D", value: 4 },
  { label: "Diploma", value: 5 },
  { label: "Others", value: 6 },
];
export const religion = [
  { label: "Hindu", value: "Hindu" },
  { label: "Islam", value: "Islam" },
  { label: "Christian", value: "Christian" },
  { label: "Sikh", value: "Sikh" },
  { label: "Others", value: "Others" },
];
export const invoiceStatusArr = [
  { label: "DUE", value: "DUE" },
  { label: "VERIFICATION PENDING", value: "VERIFICATION_PENDING" },
  { label: "PAID", value: "PAID" },
  { label: "PARTIALLY PAID", value: "PARTIALLY PAID" },
  { label: "OVER DUE", value: "OVER DUE" },
];
export const highestQualificationData = [
  { label: "Bachelor's degree", value: 1 },
  { label: "B.Tech", value: 2 },
  { label: "Diploma", value: 3 },
  { label: "MCA", value: 4 },
  { label: "M.Phil", value: 5 },
  { label: "Post Graduate", value: 6 },
  { label: "Ph.D", value: 7 },
  { label: "Others", value: 8 },
];

export const relationData = [
  { label: "FATHER", value: 1 },
  { label: "MOTHER", value: 2 },
  { label: "GUARDIAN", value: 3 },
];
