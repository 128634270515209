import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import RichTextEditor from "react-rte";
import Loder from "../../Loder/Loder.jsx";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import axios from "axios";
import {
  blankValidator,
  showNotificationMsz,
  currentDayForMinMAx,
} from "../../../utils/Validation";

import { baseUrl } from "../../../services/index.js";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";

const ReviewLeave = (props) => {
  //institution id
  let institution_id = parseInt(props.user_info.institution_id);

  const [Update_Leave_Request, {}] = useMutation(Service.Update_Leave_Request);

  //Institute name

  let institution_Name = props.user_info.institution_name;
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const [hours, sethours] = useState("");

  // ---------------api call fn-----------------------
  const client = useApolloClient();
  const [filename, setFileName] = useState("Add Attachment");
  const [UploadFileArr, setUploadFileArr] = useState([]);
  //to cancel leave request

  const [Cancel_student_leave, {}] = useMutation(Service.Cancel_student_leave);

  const [status, setstatus] = useState("");
  const [subject, setSubject] = useState("");
  const [startDate, setStartDate] = useState("Start Date");
  const [endDate, setEndDate] = useState("End Date");
  const [leaveid, setleaveid] = useState("");
  const [cancelleaveid, setcancelleaveid] = useState("");
  const [opendialog, setOpendialog] = useState(false);
  const [Days, setDays] = useState("");
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [isloading, setisloading] = useState(false);

  const onChange = (value) => {
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  const opencancelleave = (leaveid) => {
    setcancelleaveid(leaveid);
    setOpendialog(true);
  };

  useEffect(() => {
    let leavesummarydata = props.location.state.data;
    setstatus(leavesummarydata.status);
    setStartDate(leavesummarydata.from_date);
    setEndDate(leavesummarydata.to_date);
    setSubject(leavesummarydata.subject);
    sethours(leavesummarydata.hours);
    setUploadFileArr(leavesummarydata.documents);
    let messagedata = RichTextEditor.createValueFromString(
      leavesummarydata.notes.toString("html"),
      "html"
    );
    setValue(messagedata);
    setleaveid(leavesummarydata.id);

    setDays(props.location.state.Days);
  }, [institution_id, client, props.location.state.data]);

  const CancelLeaveRequest = (ID) => {
    let id = parseInt(ID);

    try {
      setisloading(true);
      Cancel_student_leave({
        variables: { id, institution_id },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.cancelLeaveRequestByRequestId.message,
            "success"
          );

          setisloading(false);
          if (props.user_info.usertype === "STUDENT") {
           props.navigate("/leaveSummary");
          } else {
           props.navigate("/parentStudentLeave");
          }
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const ImageUploadHandler = (e) => {
    try {
      setisloading(true);
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);

      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFileName(file.name);
          setUploadFileArr([...UploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const submit = () => {
    if (
      !blankValidator(props.user_info?.full_day_hours) &&
      props.user_info.usertype !== "PARENT" &&
      props.user_info.usertype !== "STUDENT"
    ) {
      showNotificationMsz("Please Contact to Admin", "Success");
      return;
    }

    let temp = [];

    UploadFileArr.map((item) => {
      temp.push({
        name: item.name,
        filename: item.filename,
        type: item.type,
        path: item.path,
      });
    });
    try {
      setisloading(true);
      Update_Leave_Request({
        variables: {
          id: parseInt(leaveid),
          user_id: parseInt(props.user_info.userid),
          leave_category_id: -1,
          subject: subject,
          leave_type: "",
          from_date: startDate,
          to_date: endDate,
          hours: 8,
          notes: value.toString("html"),
          leave_document: temp,
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.updateLeaveRequest.message,
            "success"
          );

          setisloading(false);
          props.navigate.goBack();
          // if (props.user_info.usertype === "STUDENT") {
          //  props.navigate("/leaveSummary");
          // } else {
          //  props.navigate("/parentStudentLeave");
          // }
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Review Leave</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="">
                              <label for="exampleInputEmail1">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={subject}
                                onChange={(e) => {
                                  setSubject(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1 ">
                          <Grid item md={6} className="pr-1">
                            <div className="">
                              <label for="exampleInputEmail1">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                min={currentDayForMinMAx()}
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                min={startDate}
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <label for="exampleInputEmail1">Message</label>
                          <div className="mt-1">
                            <RichTextEditor
                              value={value}
                              onChange={onChange}
                              className="editor_height"
                            />
                          </div>
                        </div>
                        <div className="mt-3 mb-2 d-flex">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile01"
                                onChange={ImageUploadHandler}
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                              >
                                {filename}
                              </label>
                            </div>
                          </div>
                        </div>
                        <label className="mt-2">Attachment Lists</label>
                        <div className="mb-2 attachList ml-2">
                          {UploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2 pr-2"
                              >
                                {item.name}
                              </span>
                              {/* <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() => {
                                    UploadFileArr.splice(index, 1);
                                    setUploadFileArr([...UploadFileArr]);
                                  }}
                                ></i>
                              </span> */}
                            </div>
                          ))}
                        </div>
                        <Grid className="grid_main_div mt-2 ">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="d-flex float-right mb-3">
                              {(status === "PENDING" ||
                                status === "APPROVED") && (
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn   mr-2 button_login_decoration "
                                  onClick={() => opencancelleave(leaveid)}
                                >
                                  Cancel Leave
                                </Button>
                              )}
                              {status === "PENDING" && (
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn  button_login_decoration "
                                  onClick={() => submit()}
                                >
                                  Update Changes
                                </Button>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendialog}
        onClose={() => {
          setOpendialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div className="data_content">
            <div className="dailog_heading1 pl-2 text-center">Confirmation</div>
            <div className="cut_dailog_icon">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpendialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-3">
            Are you sure want to cancel your leave ?
          </div>

          <Grid className="grid_main_div mt-2  mb-4 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => CancelLeaveRequest(cancelleaveid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => setOpendialog(false)}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            {/* <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i> */}
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="profile_img"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(ReviewLeave)));
