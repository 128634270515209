//redux
import * as Service from "../../../services/index";
import HOC1 from "../../Hoc1.jsx";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import Grid from "@material-ui/core/Grid";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Card from "@material-ui/core/Card";
import { useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import Loder from "../../Loder/Loder";
import StudentStudyLeft from "./StudentStudyLeft";
import StudentStudyRight from "./StudentStudyRight";

const StudyMaterial = (props) => {
  let institution_Name = props.data.institution_name;
  const [isloading, setisloading] = useState(false);
  let institution_id = props.data.institution_id;
  const client = useApolloClient();
  const [getDataStudyList, setGetDataStudyList] = useState({});
  const [updateApi, setUpdateApi] = useState(true);
  const [topicID, setTopicID] = useState(-1);

  useEffect(() => {
    getlistArrayData();
  }, [props, updateApi]);
  const getlistArrayData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.AllGetStudyMaterial,
          variables: {
            institution_id: institution_id,
          },
        })
        .then((response) => {
          console.log(
            "response.data.getStudyMaterial ",
            response.data.getStudyMaterial
          );
          let object = {};
          for (
            let index = 0;
            index < response.data?.getStudyMaterial?.length;
            index++
          ) {
            const element = response.data?.getStudyMaterial[index];
            console.log("element ", element);
            if (object[element.class_name]) {
              object[element.class_name]?.push(element)
            } else {
              let array = [];
              array.push(element);
              object[element.class_name] = array;
            }
          }
          setGetDataStudyList(object);
          setisloading(false);
        })
        .catch((e) => console.log("catch error", e));
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const updateSubTopicData = (topic_id) => {
    setTopicID(topic_id);
  };
  return (
    <div>
      <div className="main_full_width">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={institution_Name} />

                <div>
                  <Card>
                    <div className="m-3">
                      <h3>Manage Study Materials</h3>
                    </div>
                    <Grid className="grid_main_div">
                      <Grid item md={4}>
                        <StudentStudyLeft
                          getDataStudyList={getDataStudyList}
                          setUpdateApi={setUpdateApi}
                          updateApi={updateApi}
                          type="parent"
                          updateSubTopicData={updateSubTopicData}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <StudentStudyRight
                          {...props}
                          topicID={topicID}
                          setUpdateApi={setUpdateApi}
                          updateApi={updateApi}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(StudyMaterial)));
