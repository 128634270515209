import React, { useState, useEffect } from "react";
import "./Assignment.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import Loder from "../../../Loder/Loder.jsx";
import {
  blankValidator,
  CapitalizeFirstFn,
  getValue,
  timeStampToDatedashDayStart,
} from "../../../../utils/Validation";
//redux
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../../api/index";
import * as Constants from "../../../../action/appAction";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function Completed(props) {
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);
  const [Assignmentdata, setAssignmentdata] = useState([]);

  const filterAssignmentdata = Assignmentdata.filter((event) => {
    if (blankValidator(event.assignment)) {
      return (
        (props.subjectid != -1
          ? blankValidator(event.assignment) &&
            event.assignment.subject.id == props.subjectid
          : true) &&
        (props.gradeid != -1
          ? blankValidator(event.section) &&
            event.section.grade.id == props.gradeid
          : true) &&
        (props.sectionid != -1
          ? blankValidator(event.section) && event.section.id == props.sectionid
          : true)
      );
    }
  });

  // const filterAssignmentdata = Assignmentdata.filter((event) => {
  //   if (event.assignment !== undefined && event.assignment !== null) {
  //     return (
  //       event.assignment.subject.name
  //         .toLowerCase()
  //         .indexOf(props.subjectname.toLowerCase()) !== -1 &&
  //       event.section.name
  //         .toLowerCase()
  //         .indexOf(props.sectionname.toLowerCase()) !== -1
  //     );
  //   }
  // });
  // ----------------METHODS-------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    const getAssignmentdata = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Class_Assignment_Data,
            variables: {
              teacher_id: parseInt(props.teacher_id),
              status: props.status,
              academic_session_id: parseInt(props.academic_session_id),
            },
          })
          .then(
            (response) => {
              setAssignmentdata(response.data.getClassAssignment.reverse());

              setisloading(false);
              //   setAssignmentarr(response.data.getAssignmentByStatus);
            },
            (err) => {
              console.log("then err", err);
              setisloading(false);
            }
          )
          .catch((err) => {
            console.log("catch err", err);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getAssignmentdata();
  }, [client, props.status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="table_foramtitng mt-1 mb-2">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="table_header">
                Class
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Section{" "}
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Subject
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Assignment
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Start Date
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                End Date
              </StyledTableCell>

              <StyledTableCell align="left" className="table_header">
                Details
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filterAssignmentdata.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filterAssignmentdata
            ).map((row) => (
              <StyledTableRow>
                <StyledTableCell align="left">
                  {getValue(row.section.grade.gradeName)}
                </StyledTableCell>

                <StyledTableCell scope="row" align="left">
                  {getValue(row.section.name)}
                </StyledTableCell>

                <StyledTableCell scope="row" align="left">
                  {getValue(CapitalizeFirstFn(row.assignment.subject.name))}
                </StyledTableCell>

                <StyledTableCell align="left">
                  {getValue(CapitalizeFirstFn(row.assignment.name))}
                </StyledTableCell>

                <StyledTableCell align="left">
                  {timeStampToDatedashDayStart(row.start_date)}
                </StyledTableCell>

                <StyledTableCell align="left">
                  {timeStampToDatedashDayStart(row.end_date)}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className="openassignemntcolor"
                  onClick={() => {
                    props.navigation.navigate("/assignmentView", {
                      state: {
                        row: row,
                      },
                    });
                  }}
                >
                  Open
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          true
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filterAssignmentdata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Completed);
