import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import React, { useEffect, useRef, useState } from "react";
import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
//-----------------redux----------------------------
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//-----------------api calls----------------------------
import * as Service from "../../../services/index";

import {
  blankValidator,
  getFullName,
  getFullNamefromValue,
  options,
} from "../../../utils/Validation.js";
// import ParentAttendence from "./ParentAttendence.jsx";
import Attendence from "./StudentAttendance";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import ParentAttendenceShow from "./ParentAttendenceShow";

function ParentStudentAttendenceView(props) {
  const { studentData } = props.data;
  const componentRef = useRef();
  const ref = React.createRef();
  //------------------------global id------------------------
  let father_id = parseInt(props.data.userid);
  let institution_id = parseInt(props.data.institution_id);

  //Institute name
  let institution_Name = props.data.institution_name;

  //------------------------api calls------------------------
  const client = useApolloClient();
  //------------------------local state------------------------
  const [ParentstudentData, setParentStudentData] = useState([]);

  const [isloading, setisloading] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState(-1);
  // ---------------methods-----------------------

  useEffect(() => {
    if (props.data.usertype === "STUDENT") {
      setStudentId(props.data.userid);
      let { first_name, middle_name, last_name } = props.data;
      let name = getFullNamefromValue(first_name, middle_name, last_name);
      setStudentName(name);
      return;
    }
    getStudentExamData();
    // const getPraentStudentData = () => {
    //   try {
    //     setisloading(true);
    //     client
    //       .query({
    //         query: Service.Get_Parent_student_data,
    //         variables: {
    //           institution_id,
    //           father_id,
    //           status: "ACTIVE",
    //         },
    //       })
    //       .then((response) => {
    //         setisloading(false);
    //         if (response.data.getStudentByParent.length > 0) {
    //           setParentStudentData(response.data.getStudentByParent);
    //         }
    //       })
    //       .catch((err) => {
    //         setisloading(false);
    //         console.log("exam catch err", err);
    //       });
    //   } catch (error) {
    //     setisloading(false);
    //     console.log("exam t and c error", error);
    //   }
    // };

    // getPraentStudentData();
  }, [client, institution_id, father_id]);

  const getStudentExamData = () => {
    // if (index == -1) {
    //   setStudentId(-1);
    //   return;
    // }
    setStudentName(getFullName(studentData?.name));

    setStudentId(studentData?.id);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} mynav={"/parentHomePage"} />

              <div
                className="enquirydetails_background mb-2"
                ref={componentRef}
              >
                <div className="enquiry_heading " ref={ref}>
                  <Grid className="grid_main_div mt-1">
                    <Grid item md={5}>
                      <div className="mt-3 ml-4 Heading_bold leave_content_main">
                        Attendence
                      </div>
                    </Grid>
                    <Grid item md={6}></Grid>
                    <Grid item md={1}>
                      <div className="d-flex card_invoicedetails_border p-1 pr-2 mr-2emplo mt-4">
                        <div className="col-6 mouse__pointer">
                          <ReactToPdf
                            targetRef={ref}
                            filename="dayAttendance.pdf"
                            scale={1}
                            options={options}
                          >
                            {({ toPdf }) => (
                              <i class="fa fa-file-pdf-o" onClick={toPdf}></i>
                            )}
                          </ReactToPdf>
                        </div>
                        <div className="col-6 mouse__pointer">
                          <ReactToPrint
                            trigger={() => <i class="fa fa-print"></i>}
                            content={() => componentRef.current}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        {/* <div className="text_filed_heading">Student List</div> */}
                        {/* {props.data.usertype === "PARENT" && (
                          <div className="mt-1 mb-1">
                            <select
                              class="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) => {
                                getStudentExamData(e.target.value);
                              }}
                            >
                              <option value={-1}>Student List</option>

                              {ParentstudentData.map((item, index) => (
                                <option value={index} key={index}>
                                  {getFullName(item.name)}
                                </option>
                              ))}
                            </select>
                          </div>
                        )} */}

                        <div>
                          {blankValidator(studentId) && (
                            <ParentAttendenceShow
                              studentId={studentId}
                              student_Name={studentName}
                              navigate={props.navigate}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)(HOC1(ParentStudentAttendenceView));
