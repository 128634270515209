import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import RichTextEditor from "react-rte";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { baseUrl } from "../../../services/index.js";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  getNumberOfHoursByday,
  showNotificationMsz,
  currentDayForMinMAx,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const EditLeave = (props) => {
  const [leave_type, setleavetype] = useState("");
  const [subject, setSubject] = useState("");
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const [days, setDays] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [isloading, setisloading] = useState(false);
  const [filename, setFileName] = useState("Add Attachment");
  const [uploadFileArr, setUploadFileArr] = useState([]);
  const [checkoption, setcheckoption] = useState("");

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [datediffrenceEror, setdatediffrenceEror] = useState(false);
  const [leaveSubjectError, setleaveSubjectError] = useState(false);
  const [startdateError, setstartdateError] = useState(false);
  const [enddaterror, setenddaterror] = useState(false);
  const [messageEror, setmessageEror] = useState(false);
  const [leave_categoryname, setleave_categoryname] = useState(false);
  const [Teacher_Delete_His_LeaveRequest, {}] = useMutation(
    Service.Teacher_Delete_His_LeaveRequest
  );
  const [leaveid, setleaveid] = useState("");
  const [status, setstatus] = useState("");
  const [leave_category_id, setleave_category_id] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    let leavesummarydata = props.location.state.row;
    setleaveid(leavesummarydata.id);
    setleave_category_id(parseInt(leavesummarydata.leave_category.id));
    setleave_categoryname(leavesummarydata.leave_category.name);
    setstatus(leavesummarydata.status);
    setleavetype(leavesummarydata.leave_type);
    setSubject(leavesummarydata.subject);
    setStartDate(leavesummarydata.from_date);
    setEndDate(leavesummarydata.to_date);
    setUploadFileArr(leavesummarydata.documents);
    setValue(
      RichTextEditor.createValueFromString(leavesummarydata.notes, "html")
    );

    setDays(leavesummarydata.days);
    if (leavesummarydata.leave_type == "HALF") {
      setcheckoption("HALF");
    }
    if (leavesummarydata.leave_type == "FULL") {
      setcheckoption("FULL");
    }
  }, [props]);
  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFileName(file.name);
          setUploadFileArr([...uploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  const [Update_Leave_Request, {}] = useMutation(Service.Update_Leave_Request);

  const [opendialogquestionConfimation, setopendialogquestionConfimation] =
    useState(false);

  const deleteLeave = () => {
    let id = parseInt(leaveid);

    setopendialogquestionConfimation(false);
    try {
      setisloading(true);
      Teacher_Delete_His_LeaveRequest({
        variables: {
          id: id,
        },
      }).then((response) => {
        setisloading(false);
        showNotificationMsz(
          response.data.deleteLeaveRequestByEmployeeId.message,
          "success"
        );

        setopendialogquestionConfimation(false);
       props.navigate("/teacherLeaveSummary");
      });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  // const dateDiffInDays = (a, b) => {
  //   let diff = a.substring(8) - b.substring(8);
  //   return diff;
  // };

  const submit = () => {
    let flag = false;
    let scroll = false;
    if (!blankValidator(subject)) {
      setleaveSubjectError(true);

      if (scroll === false) {
        document.getElementById("subjectt").focus();
      }
      scroll = true;
      flag = true;
    }
    if (!blankValidator(startDate)) {
      setstartdateError(true);
      if (scroll === false) {
        document.getElementById("startdate").focus();
      }
      scroll = true;
      flag = true;
    }
    if (!blankValidator(endDate)) {
      setenddaterror(true);
      if (scroll === false) {
        document.getElementById("enddate").focus();
      }
      scroll = true;
      flag = true;
    }

    if (value.toString("html") === "<p><br></p>") {
      setmessageEror(true);
      if (scroll === false) {
        document.getElementById("messageid").focus();
      }
      scroll = true;
      flag = true;
    }
    if (flag) {
      return;
    }
    let temp = [];
    uploadFileArr.map((item) => {
      temp.push({
        name: item.name,
        filename: item.filename,
        type: item.type,
        path: item.path,
      });
    });

    let id = parseInt(leaveid);
    try {
      setisloading(true);
      let hours;
      let leave_typee;
      if (checkoption === "HALF") {
        let halfhours = parseInt(props.data.half_day_hours);
        hours = getNumberOfHoursByday(startDate, endDate, halfhours);
        leave_typee = "HALF";
      } else {
        let fullhours = parseInt(props.data.full_day_hours);
        hours = getNumberOfHoursByday(startDate, endDate, fullhours);
        leave_typee = "FULL";
      }

      Update_Leave_Request({
        variables: {
          id: id,
          leave_category_id,
          user_id: parseInt(props.data.userid),
          leave_type: leave_typee,
          subject,
          from_date: startDate,
          to_date: endDate,
          hours,
          notes: value.toString("html"),
          leave_document: temp,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateLeaveRequest.message,
            "success"
          );
         props.navigate("teacherLeaveSummary");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">
                    Edit Leave Request
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="mt-1 mb-3">
                              <CheckBoxComponent
                                label="Half Day"
                                cssClass="e-custom mr-3"
                                value="Half"
                                checked={checkoption === "HALF"}
                                onChange={(e) => {
                                  setcheckoption("HALF");
                                }}
                              />
                              <CheckBoxComponent
                                label="Full Day"
                                cssClass="e-custom"
                                value="Full"
                                checked={checkoption === "FULL"}
                                onChange={(e) => {
                                  setcheckoption("FULL");
                                }}
                              />
                            </div>
                            <div className="">
                              <label for="exampleInputEmail1">
                                Leave Category Type
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Demo"
                                autoComplete="off"
                                value={leave_categoryname}
                              />
                            </div>
                            <div className="">
                              <label for="exampleInputEmail1">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="subjectt"
                                placeholder="Subject"
                                autoComplete="off"
                                value={subject}
                                onChange={(e) => {
                                  setleaveSubjectError(false);
                                  setSubject(e.target.value);
                                }}
                              />
                              {leaveSubjectError && (
                                <span className="text-danger">
                                  Enter Subject of leave
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="startdate"
                                autoComplete="off"
                                min={currentDayForMinMAx()}
                                max={endDate}
                                value={startDate}
                                onChange={(e) => {
                                  setstartdateError(false);
                                  setStartDate(e.target.value);
                                }}
                              />
                              {startdateError && (
                                <span className="text-danger">
                                  Enter Start Date
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="enddate"
                                autoComplete="off"
                                min={startDate}
                                value={endDate}
                                onChange={(e) => {
                                  setenddaterror(false);
                                  setdatediffrenceEror(false);
                                  setEndDate(e.target.value);
                                }}
                              />
                              {enddaterror && (
                                <span className="text-danger">
                                  Enter End Date
                                </span>
                              )}
                              {datediffrenceEror && (
                                <span className="text-danger">
                                  Maximum 5 days can be selected
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <label for="exampleInputEmail1">Message</label>
                          <div className="mt-3 mb-3" id="messageid">
                            <RichTextEditor
                              style={{ width: "100%" }}
                              value={value}
                              onChange={(e) => {
                                setmessageEror(false);
                                setValue(e);
                              }}
                            />
                            {messageEror && (
                              <span className="text-danger">
                                Enter The Message
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-3 mb-2">
                          <label for="exampleInputEmail1">Attachment</label>
                          <div class="input-group mt-1">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile01"
                                onChange={ImageUploadHandler}
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                              >
                                {filename}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 mb-2 attachList ml-2">
                          {uploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2 pr-2"
                              >
                                {item.name}
                              </span>
                              <span className="pl-3 mr-1">
                                {/* <i
                                  class="fa fa-times cursor"
                                  onClick={() => {
                                    uploadFileArr.splice(index, 1);
                                    setUploadFileArr([...uploadFileArr]);
                                  }}
                                ></i> */}
                              </span>
                            </div>
                          ))}
                        </div>
                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              {(status === "PENDING" ||
                                status === "APPROVED") && (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setopendialogquestionConfimation(true);
                                  }}
                                  className="button_enquiry_details button_login_decoration mr-2"
                                >
                                  Cancel
                                </Button>
                              )}
                              {status === "PENDING" && (
                                <Button
                                  variant="contained"
                                  className="button_enquiry_details button_login_decoration"
                                  onClick={submit}
                                >
                                  Update
                                </Button>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer" 
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        open={opendialogquestionConfimation}
        onClose={() => {
          setopendialogquestionConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogquestionConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete Leave ?
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={deleteLeave}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogquestionConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(EditLeave)));
