import React, { useState } from "react";
// import "./Notice.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {
  CapitalizeFirstFn,
  getFullName,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";

import Dialog from "@material-ui/core/Dialog";
import { baseUrl } from "../../../services/index.js";
import Holiday from "../Holiday/Holiday.jsx";

const NoticeDetail = (props) => {
  const parse = require("html-react-parser");
  //Institute name
  let institution_Name = props.location.state.institution_Name;

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">
                    {props.location.state.item.title === undefined ||
                    props.location.state.item.title === null ? (
                      ""
                    ) : (
                      <span>
                        {" "}
                        Notice Detail -
                        {CapitalizeFirstFn(props.location.state.item.title)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          {props?.location?.state?.type === "CLASSNOTICE" ? (
                            <Grid item md={4}>
                              <div className="mt-2">
                                <div className="text_filed_heading">
                                  Class/Section
                                </div>
                                <span className="iconscontent">
                                  <i className="fa fa-graduation-cap iconcolor"></i>
                                </span>
                                <span className="font-weight-bold ml-1">
                                  {
                                    props?.location?.state?.item?.section?.grade
                                      ?.gradeName
                                  }
                                  /
                                </span>
                                {props?.location?.state?.item?.section?.name}
                              </div>
                            </Grid>
                          ) : (
                            <Grid item md={4}>
                              <div className="mt-2">
                                <div className="text_filed_heading">
                                  Published by
                                </div>
                                <span className="iconscontent">
                                  <i class="fa fa-user iconcolor"></i>
                                </span>

                                <span className="font-weight-bold ml-2">
                                  {getFullName(
                                    props.location.state?.item?.admin?.name
                                  )}
                                </span>
                              </div>
                            </Grid>
                          )}
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">Subject</div>
                              <span className="iconscontent">
                                <i className="fa fa-book iconcolor"></i>
                              </span>

                              <span className="font-weight-bold ml-2">
                                {props?.location?.state?.item?.subject}
                              </span>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Published Date{" "}
                              </div>
                              <span className="iconscontent">
                                <i className="fa fa-calendar iconcolor"></i>
                              </span>
                              {props.location.state.item.publish_date ===
                                undefined ||
                              props.location.state.item.publish_date ===
                                null ? (
                                ""
                              ) : (
                                <span className="font-weight-bold ml-2">
                                  {timeStampToDatedashDayStart(
                                    props.location.state.item.publish_date
                                  )}
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">Content </div>
                              <div className="outside_border_for_content p-2">
                                {parse.default(
                                  props.location.state.item.content
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Attachments
                              </div>
                              <div className="mt-2 attachList ml-2">
                                {props.location.state.item.documents.map(
                                  (item, index) => (
                                    <div
                                      className="file_back_color ml-2 docimage"
                                      key={index}
                                    >
                                      <span
                                        className="pl-2 pr-2"
                                        onClick={() =>
                                          HandleImageDailogBox(item)
                                        }
                                      >
                                        {item.name}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="profile_img"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default HOC1(NoticeDetail);
