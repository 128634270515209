import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function AddEditClass(props) {
  const { type } = props.academic_session_id;
  let academic_session_id = parseInt(
    props.academic_session_id.academic_session_id
  );

  let institution_id = parseInt(props.academic_session_id.institution_id);

  //name instituition
  let Organization_Name = props.academic_session_id.institution_name;
  const client = useApolloClient();

  const [addsection, setaddsection] = useState(false);
  const [sectionname, setsectionname] = useState("");
  const [opendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [isloading, setisloading] = useState("");
  const [GradeName, setGradeName] = useState("");
  const [opendialogConfimationSubject, setopendialogConfimationSubject] =
    useState(false);

  const [nameedit, setNameedit] = useState("");
  const [deleteSectionid, setdeleteSectionid] = useState("");
  const [sectionid, setSectionid] = useState("");
  const [HandleClassDailog, setHandleClassDailog] = useState(false);
  const [addClassDailog, setAddClassDailog] = useState(false);
  const [gradeId, setGradeId] = useState(null);
  const [managegrade, setmanagegrade] = useState([]);
  const [classCreditCount, setClassCreditCount] = useState("");
  const [sectionStartDate, setSectionStartDate] = useState("");
  const [sectionEndDate, setsectionEndDate] = useState("");
  //updata manage subject api
  const [subjectID, setSubjectID] = useState("");
  const [deleteSubjectid, setdeleteSubjectid] = useState("");
  const [sectionArr, setSectionArr] = useState([]);
  const [managesubject, setmanagesubject] = useState([]);
  const [sectionnamedelete, setsectionnamedelete] = useState("");
  const [subjectnamedelete, setsubjectnamedelete] = useState("");
  const [selectedSubjectArr, setSelectedSubjectArr] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [ManageSubjectDaliog, setManageSubjectDaliog] = useState(false);
  const [SubjectName, setSubjectName] = useState("");
  const [IsSubjectUpdated, setIsSubjectUpdated] = useState(false);
  const [sectionDailog, setSectionDailog] = useState(false);
  const [assignSubjectDialog, setAssignSubjectDialog] = useState(false);

  //error
  const [GradeIdError, setGradeIdError] = useState(false);
  const [sectionError, setsectionError] = useState(false);
  const [sectionEditError, setsectionEditError] = useState(false);
  const [assigngradeIdError, setassigngradeIdError] = useState(false);
  const [subjectIDError, setsubjectIDError] = useState(false);
  const [gradenameError, setgradenameError] = useState(false);
  const [subjectnameError, setsubjectnameError] = useState(false);
  const [class_name, setClass_name] = useState("");
  const [editGradeId, setEditGradeId] = useState("");
  //to create section
  const [Create_Section_Data, {}] = useMutation(Service.Create_Section_Data);

  //to delete section
  const [Delete_Section_Data, {}] = useMutation(Service.Delete_Section_Data);

  //to edit section
  const [Edit_Section_Data, {}] = useMutation(Service.Edit_Section_Data);

  // to assign subject
  const [Assign_Subject_Data, {}] = useMutation(Service.Assign_Subject_Data);

  // mutation create Subject  api
  const [Create_Manage_Subject, {}] = useMutation(
    Service.Create_Manage_Subject
  );

  // to delete associated subject
  const [Delete_Subject_Associated, {}] = useMutation(
    Service.Delete_Subject_Associated
  );

  // mutation create grade api
  const [Add_Grade, {}] = useMutation(Service.Add_Grade);

  const OpenEditQuery = (sectionData) => {
    setNameedit(sectionData.name);
    setEditGradeId(sectionData.grade.id);
    setSectionid(sectionData.id);
    setOpendailogtoEdit(true);
  };

  const DeleteDailogBox = (data) => {
    setdeleteSectionid(data.id);
    setsectionnamedelete(data.name);
    setopendialogConfimation(true);
  };

  const deleteSubjectdailogbox = (data) => {
    setdeleteSubjectid(data.id);
    setsubjectnamedelete(data.subject.full_name);
    setopendialogConfimationSubject(true);
  };

  const deleteSubject = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_Subject_Associated({
        variables: { id },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.deleteClassSubject.message,
            "success"
          );

          setopendialogConfimationSubject(false);
          // to get assign subject list to particular class/grade

          client
            .query({
              query: Service.Get_Grade_Subject_Date,
              variables: {
                grade_id: gradeId,
              },
            })
            .then(async (response) => {
              const subjectList = response.data.getClassSubject;
              const sortedSectionArr = subjectList.sort((a, b) => {
                return a.subject.name.localeCompare(b.subject.name);
              });
              await setSelectedSubjectArr(sortedSectionArr);
            })
            .catch((err) => {
              setisloading(false);
              console.log("catch err", err);
              showNotificationMsz(err, "danger");
            });
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getManageGrades();
    getSubjectList();
  }, [
    client,
    institution_id,
    academic_session_id,
    isUpdated,
    IsSubjectUpdated,
  ]);
  const getManageGrades = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];

          for (let index = 0; index < response.data.getGrade.length; index++) {
            temp.push({
              gradeName: response.data.getGrade[index].gradeName,
              show: true,
              id: response.data.getGrade[index].id,
            });
          }
          if (temp.length === 0) {
            setHandleClassDailog(true);
          }
          setmanagegrade(temp);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  };
  const getSubjectList = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Subject_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagesubject(response.data.getSubject);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const sortedList = managegrade.sort((a, b) =>
    a.gradeName.localeCompare(b.gradeName)
  );
  const selectSubjectHandler = (ID) => {
    setSubjectID(ID);
  };

  const createSectionHandler = () => {
    if (gradeId === null) {
      setGradeIdError(true);
      return;
    }
    if (!blankValidator(sectionname)) {
      setsectionError(true);
      return;
    }

    try {
      setisloading(true);
      Create_Section_Data({
        variables: { grade_id: gradeId, name: sectionname },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.addSection.message, "success");

          setsectionname("");
          setaddsection(false);
          client
            .query({
              query: Service.GET_Section_Data,
              variables: {
                grade_id: gradeId,
              },
            })
            .then(async (response) => {
              const sectionData = response.data.getSectionAndGrade;
              const sortedSectionArr = sectionData.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
              await setSectionArr(sortedSectionArr);
            })
            .catch((e) => {
              setisloading(false);
              console.log("catch error", e);
              showNotificationMsz(e, "danger");
            });
        })
        .catch((e) => {
          setisloading(false);
          showNotificationMsz(e, "danger");
        });
      if (type === "PT" || type === "ET") {
        setAssignSubjectDialog(true);
        setSectionDailog(false);
        return;
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const UpdateSection = () => {
    try {
      let id = parseInt(sectionid);
      if (!blankValidator(nameedit)) {
        setsectionEditError(true);
        return;
      }
      setisloading(true);
      Edit_Section_Data({
        variables: { id, name: nameedit, grade_id: +editGradeId },
      }).then((response) => {
        const { message } = response.data.updateSection;
        showNotificationMsz(message, "success");
        setOpendailogtoEdit(false);
        setisloading(false);
        if (gradeId) {
          client
            .query({
              query: Service.GET_Section_Data,
              variables: {
                grade_id: gradeId,
              },
            })
            .then(async (response) => {
              const sectionData = response.data.getSectionAndGrade;
              const sortedSectionArr = sectionData.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
              await setSectionArr(sortedSectionArr);
            })
            .catch((e) => {
              setisloading(false);
              console.log("catch error", e);
            });
        }
      });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };

  const deleteSectionHandler = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_Section_Data({
        variables: { id },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.deleteSection.message, "success");
          setopendialogConfimation(false);
          try {
            setisloading(true);
            client
              .query({
                query: Service.GET_Section_Data,
                variables: {
                  grade_id: gradeId,
                },
              })
              .then(async (response) => {
                setisloading(false);
                const sectionData = response.data.getSectionAndGrade;
                const sortedSectionArr = sectionData.sort((a, b) => {
                  return a.name.localeCompare(b.name);
                });
                await setSectionArr(sortedSectionArr);
              })
              .catch((e) => {
                setisloading(false);

                showNotificationMsz(e, "danger");
              });
          } catch (error) {
            setisloading(false);
            showNotificationMsz(error, "danger");
          }
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const classHandler = async (classId) => {
    let id = parseInt(classId);
    try {
      const classWithName = managegrade.find((item) => +item.id === id);
      if (classWithName) {
        setClass_name(classWithName.gradeName);
      }

      setisloading(true);
      setGradeId(id);
      await client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: id,
          },
        })
        .then((response) => {
          setSubjectID("");
          const sectionData = response.data.getSectionAndGrade;
          const sortedSectionArr = sectionData.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          setSectionArr(sortedSectionArr);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }

    // to get associated subject list
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Grade_Subject_Date,
          variables: {
            grade_id: id,
          },
        })
        .then(async (response) => {
          setisloading(false);
          const subjectList = response.data.getClassSubject;
          const sortedSectionArr = subjectList.sort((a, b) => {
            return a.subject.name.localeCompare(b.subject.name);
          });
          await setSelectedSubjectArr(sortedSectionArr);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const assignSubjectHandler = () => {
    if (!blankValidator(gradeId)) {
      setassigngradeIdError(true);
      return;
    }
    if (!blankValidator(subjectID)) {
      setsubjectIDError(true);
      return;
    }
    let grade_id = parseInt(gradeId);
    let subject_id = parseInt(subjectID);

    try {
      setisloading(true);
      Assign_Subject_Data({
        variables: { grade_id, subject_id, institution_id, class_name },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.assingSubject.message, "success");

          setSubjectID("");
          // to get assign subject list to particular class/grade
          try {
            setisloading(true);
            client
              .query({
                query: Service.Get_Grade_Subject_Date,
                variables: {
                  grade_id,
                },
              })
              .then(async (response) => {
                const subjectList = response.data.getClassSubject;
                const sortedSectionArr = subjectList.sort((a, b) => {
                  return a.subject.name.localeCompare(b.subject.name);
                });
                await setSelectedSubjectArr(sortedSectionArr);
                setisloading(false);
              })
              .catch((err) => {
                setisloading(false);
                console.log("catch err", err);
                showNotificationMsz(err, "danger");
              });
          } catch (error) {
            setisloading(false);
            showNotificationMsz(error, "danger");
          }
        })
        .catch((error) => {
          console.log("catch error", error);
        });
      if (type === "PT" || type === "ET") {
        setHandleClassDailog(false);
        showNotificationMsz("You have successfully created a class", "success");
      }
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const AddNewClass = () => {
    try {
      if (!blankValidator(GradeName)) {
        setgradenameError(true);
        return;
      }
      setisloading(true);
      Add_Grade({
        variables: {
          gradeName: GradeName,
          academic_session_id,
        },
      }).then((response) => {
        const { message, id } = response.data.addGrade;
        // setGradeId(-1);
        setSectionArr([]);
        setClass_name(GradeName);
        setSelectedSubjectArr([]);
        setSubjectID("");
        setisloading(false);
        showNotificationMsz(message, "success");
        setGradeName("");

        setGradeId(id);
        if ((type === "PT" || type === "ET") && managegrade.length === 0) {
          getManageGrades();
          setSectionDailog(true);
          return;
        }

        setIsUpdated(!isUpdated);
        setAddClassDailog(false);
      });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  const AddNewSubject = () => {
    if (!blankValidator(SubjectName)) {
      setsubjectnameError(true);
      return;
    }

    try {
      setisloading(true);
      Create_Manage_Subject({
        variables: {
          institution_id,
          name: SubjectName,
        },
      }).then((response) => {
        setisloading(false);
        showNotificationMsz(response.data.addSubject.message, "success");

        setSubjectName("");

        setManageSubjectDaliog(false);
        if ((type === "PT" || type === "ET") && managegrade.length === 0) {
          getSubjectList();
          return;
        }
        setIsSubjectUpdated(!IsSubjectUpdated);
      });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };
  const handleaddsection = () => {
    setaddsection(true);
  };

  const closeaddsection = () => {
    setaddsection(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="edit_className_flex">
                          <div className="text-left">
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span
                              className=" addmanageuserfont mt-1 ml-1"
                              onClick={() => {
                                setAddClassDailog(true);
                              }}
                            >
                              Add Class
                            </span>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="add_new_query_btn  button_login_decoration"
                              onClick={() => {
                                props.navigate("/managegrade");
                              }}
                            >
                              Edit Class Name
                            </Button>
                          </div>
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className=" mt-3">
                              <select
                                value={gradeId}
                                className="form-control"
                                onChange={(e) => {
                                  setGradeIdError(false);
                                  classHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Class</option>
                                {sortedList.map((item, index) => (
                                  <option value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                              {GradeIdError && (
                                <span className="text-danger">
                                  Select the Class Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className="">
                    <div className="">Section</div>
                    <Card className=" mb-2">
                      {addsection === false ? (
                        <div className="inputMarginright">
                          <div className="text-left ml-3">
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span
                              className=" addmanageuserfont mt-1 ml-1"
                              onClick={handleaddsection}
                            >
                              Add New Section
                            </span>
                          </div>
                        </div>
                      ) : (
                        <Expand open={addsection}>
                          <div className="card_margin_both_side">
                            <Card className=" mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div
                                          className="text-right"
                                          onClick={closeaddsection}
                                        >
                                          <span className="iconstoaddfee">
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        </div>

                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Section Name"
                                            //autoComplete="off"
                                            value={sectionname}
                                            onChange={(e) => {
                                              setsectionError(false);
                                              setsectionname(e.target.value);
                                            }}
                                          />
                                          {sectionError && (
                                            <span className="text-danger">
                                              Enter the Section Name
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={6}>
                                        <div className=" mt-1 pr-1">
                                          <label for="exampleInputEmail1">
                                            Start date
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            placeholder="Start date"
                                            //autoComplete="off"
                                            value={sectionStartDate}
                                            onChange={(e) => {
                                              setSectionStartDate(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          {/* {sectionError && (
                                            <span className="text-danger">
                                              Enter the Section Name
                                            </span>
                                          )} */}
                                        </div>
                                      </Grid>
                                      <Grid item md={6}>
                                        <div className=" mt-1 pl-1">
                                          <label for="exampleInputEmail1">
                                            End date
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            placeholder="End date"
                                            //autoComplete="off"
                                            value={sectionEndDate}
                                            onChange={(e) => {
                                              setsectionEndDate(e.target.value);
                                            }}
                                          />
                                          {/* {sectionError && (
                                            <span className="text-danger">
                                              Enter the Section Name
                                            </span>
                                          )} */}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className=" mt-1 mb-5">
                                    <Button
                                      variant="contained"
                                      className="add_new_query_btn  button_login_decoration float-right"
                                      onClick={createSectionHandler}
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Expand>
                      )}

                      <div className="card_margin_both_side">
                        {sectionArr.length ? (
                          sectionArr.map((item, index) => (
                            <Card
                              className="mb-2 cardvisible_border"
                              key={index}
                            >
                              <div className="card_admissiondetails_height mt-2">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={11}>
                                        <div className="">
                                          <input
                                            type="text"
                                            className="form-control input_border_none"
                                            placeholder={item.name}
                                            // autoComplete="off"
                                            value={item.name}
                                            disabled={true}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={1}>
                                        {" "}
                                        <div className="d-flex">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </Card>
                  </div>

                  <div className="mt-4">Assign Subject</div>
                  <Card className="pt-3 pb-4 mt-2">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="enquiry_heading">
                          <div className="text-left">
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" addmanageuserfont mt-1 ml-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration"
                                onClick={() => {
                                  setManageSubjectDaliog(true);
                                }}
                              >
                                Add New Subject
                              </Button>
                            </span>
                          </div>
                        </div>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                value={subjectID}
                                onChange={(e) => {
                                  setsubjectIDError(false);
                                  selectSubjectHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Subject</option>
                                {managesubject.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                              {subjectIDError && (
                                <span className="text-danger">
                                  Select the Subject
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={2}>
                            {" "}
                            <div className=" mr-3 mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={assignSubjectHandler}
                              >
                                Assign
                              </Button>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2">Subject List</div>
                        <div className="mt-2">
                          {selectedSubjectArr.length ? (
                            selectedSubjectArr.map((item, index) => (
                              <Card className="mb-2 cardvisible_border">
                                <div className="card_admissiondetails_height ">
                                  <div className="">
                                    <div className="">
                                      <Grid className="grid_main_div">
                                        <Grid item md={11}>
                                          <div className=" mt-1 mb-1">
                                            <input
                                              type="text"
                                              className="form-control input_border_none"
                                              placeholder="Name"
                                              value={item.subject.name}
                                              disabled={true}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item md={1}>
                                          {" "}
                                          <div className="d-flex pt-2">
                                            {/* <span className="iconstoaddfee mr-2 ml-1">
                                        <i
                                          className="fa fa-pencil"
                                          onClick={() => {
                                            managesubject[index].show = false;
                                            setmanagesubject([
                                              ...managesubject,
                                            ]);
                                          }}
                                        ></i>
                                      </span> */}
                                            <span className="iconstoaddfee manage_edit_cursor ml-2">
                                              <i
                                                className="fa fa-trash"
                                                onClick={() =>
                                                  deleteSubjectdailogbox(item)
                                                }
                                              ></i>
                                            </span>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            ))
                          ) : (
                            <div>No Data</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Section Name</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    autoComplete="off"
                    value={nameedit}
                    onChange={(e) => {
                      setsectionEditError(false);
                      setNameedit(e.target.value);
                    }}
                  />
                  {sectionEditError && (
                    <span className="text-danger">Enter the Section Name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4 mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateSection}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Section : {sectionnamedelete}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteSectionHandler(deleteSectionid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimationSubject}
        onClose={() => {
          setopendialogConfimationSubject(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimationSubject(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Subject : {subjectnamedelete}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteSubject(deleteSubjectid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimationSubject(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={HandleClassDailog}
        onClose={() => {
          setHandleClassDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setHandleClassDailog(false);
              }}
            ></i>
          </div>
          <>
            {assignSubjectDialog ? (
              <div className="p-3">
                <div className="card_admissiondetails_height">
                  <div className="textfiled_margin">
                    <div className="enquiry_heading">
                      <div className="text-left">
                        <span className="iconstoaddfee">
                          <i className="fa fa-plus-circle"></i>
                        </span>
                        <span className=" addmanageuserfont mt-1 ml-1">
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration"
                            onClick={() => {
                              setManageSubjectDaliog(true);
                            }}
                          >
                            Add New Subject
                          </Button>
                        </span>
                      </div>
                    </div>

                    <Grid className="grid_main_div mt-2">
                      <Grid item md={6}>
                        <div className="inputMarginright mt-1">
                          <label for="exampleInputEmail1">Select Subject</label>
                          <select
                            className="form-control"
                            value={subjectID}
                            onChange={(e) => {
                              setsubjectIDError(false);
                              selectSubjectHandler(e.target.value);
                            }}
                          >
                            <option value={-1}>Select Subject</option>
                            {managesubject.map((item, index) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>
                          {subjectIDError && (
                            <span className="text-danger">
                              Select the Subject
                            </span>
                          )}
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        {" "}
                        <div className=" mr-3 mt-1">
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick={assignSubjectHandler}
                          >
                            Assign
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            ) : sectionDailog ? (
              <div className="p-3">
                <label for="exampleInputEmail1">Section Name</label>
                <div className=" mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Section Name"
                    //autoComplete="off"
                    value={sectionname}
                    onChange={(e) => {
                      setsectionError(false);
                      setsectionname(e.target.value);
                    }}
                  />
                  {sectionError && (
                    <span className="text-danger">Enter the Section Name</span>
                  )}
                </div>
                <div>
                  <label for="exampleInputEmail1">Start date</label>
                  <div className=" mt-1">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start date"
                      //autoComplete="off"
                      value={sectionStartDate}
                      onChange={(e) => {
                        setSectionStartDate(e.target.value);
                      }}
                    />
                    {/* {sectionError && (
                      <span className="text-danger">
                        Enter the Section Name
                      </span>
                    )} */}
                  </div>
                </div>
                <div>
                  <label for="exampleInputEmail1">End date</label>
                  <div className=" mt-1">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="End date"
                      //autoComplete="off"
                      value={sectionEndDate}
                      onChange={(e) => {
                        setsectionEndDate(e.target.value);
                      }}
                    />
                    {/* {sectionError && (
                        <span className="text-danger">
                          Enter the Section Name
                        </span>
                      )} */}
                  </div>
                </div>
                <Grid className="grid_main_div mt-4 mb-2">
                  <Grid item md={1}></Grid>
                  <Grid item md={10} className="text-center">
                    <Button
                      variant="contained"
                      className="button_enquiry_details button_login_decoration ml-2"
                      onClick={createSectionHandler}
                    >
                      Next
                    </Button>
                  </Grid>

                  <Grid item md={1}></Grid>
                </Grid>
              </div>
            ) : (
              <>
                <div className="mt-3 ml-2 mr-2">
                  <Grid className="grid_main_div">
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                      <label for="exampleInputEmail1">Class Name</label>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          autoComplete="off"
                          value={GradeName}
                          onChange={(e) => {
                            setgradenameError(false);
                            setGradeName(e.target.value);
                          }}
                        />
                        {gradenameError && (
                          <span className="text-danger">
                            Enter the Class Name
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={1}></Grid>
                  </Grid>
                  {/* <Grid className="grid_main_div mt-2">
                      <Grid item md={1}></Grid>
                      <Grid item md={10}>
                        <label for="exampleInputEmail1">
                          Class credit count (Optional)
                        </label>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            autoComplete="off"
                            value={classCreditCount}
                            onChange={(e) => {
                              if (isNaN(e.target.value)) return;

                              setClassCreditCount(e.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md={1}></Grid>
                    </Grid> */}
                </div>
                <Grid className="grid_main_div mt-4 mb-2">
                  <Grid item md={1}></Grid>
                  <Grid item md={10} className="text-center">
                    <Button
                      variant="contained"
                      className="button_enquiry_details button_login_decoration ml-2"
                      onClick={AddNewClass}
                    >
                      Next
                    </Button>
                  </Grid>

                  <Grid item md={1}></Grid>
                </Grid>
              </>
            )}
          </>
        </div>
      </Dialog>
      {/* add class dailog */}
      <Dialog
        open={addClassDailog}
        onClose={() => {
          setAddClassDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setAddClassDailog(false);
              }}
            ></i>
          </div>

          <>
            <div className="mt-3 ml-2 mr-2">
              <Grid className="grid_main_div">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  <label for="exampleInputEmail1">Class Name</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      autoComplete="off"
                      value={GradeName}
                      onChange={(e) => {
                        setgradenameError(false);
                        setGradeName(e.target.value);
                      }}
                    />
                    {gradenameError && (
                      <span className="text-danger">Enter the Class Name</span>
                    )}
                  </div>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
              {/* <Grid className="grid_main_div mt-2">
                  <Grid item md={1}></Grid>
                  <Grid item md={10}>
                    <label for="exampleInputEmail1">
                      Class credit count (Optional)
                    </label>
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        autoComplete="off"
                        value={classCreditCount}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) return;

                          setClassCreditCount(e.target.value);
                        }}
                      />
                     >
                 
                    </div>
                  </Grid>
                  <Grid item md={1}></Grid>
                </Grid> */}
            </div>
            <Grid className="grid_main_div mt-4 mb-2">
              <Grid item md={1}></Grid>
              <Grid item md={10} className="text-center">
                <Button
                  variant="contained"
                  className="button_enquiry_details button_login_decoration ml-2"
                  onClick={AddNewClass}
                >
                  Submit
                </Button>
              </Grid>

              <Grid item md={1}></Grid>
            </Grid>
          </>
        </div>
      </Dialog>

      <Dialog
        open={ManageSubjectDaliog}
        onClose={() => {
          setManageSubjectDaliog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setManageSubjectDaliog(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Subject Name</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    autoComplete="off"
                    value={SubjectName}
                    onChange={(e) => {
                      setsubjectnameError(false);
                      setSubjectName(e.target.value);
                    }}
                  />
                  {subjectnameError && (
                    <span className="text-danger">Enter the Subject Name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4 mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={AddNewSubject}
              >
                Submit
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  academic_session_id: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AddEditClass));
