import React, { useState, useEffect } from "react";
import "./Assignment.css";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Loder from "../../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import { store } from "react-notifications-component";
//redux
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../../api/index";
import * as Constants from "../../../../action/appAction";
import { useMutation } from "@apollo/client";
import { getSubStringValue, getValue } from "../../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#97bf47",
  },
  tooltip: {
    backgroundColor: "#97bf47",
  },
}));

function Created(props) {
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);
  const [Assignmentdata, setAssignmentdata] = useState([]);
  const [isUpdated, setisUpdated] = useState(false);

  // ----------------METHODS-------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    const getAssignmentdata = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Assignment_Created_Status,
            variables: {
              teacher_id: parseInt(props.teacher_id),
            },
          })
          .then(
            (response) => {
              setAssignmentdata(
                response.data.getAssignmentByStatusCreated.reverse()
              );
              setisloading(false);
              //   setAssignmentarr(response.data.getAssignmentByStatus);
            },
            (err) => {
              console.log("then err", err);
              setisloading(false);
            }
          )
          .catch((err) => {
            console.log("catch err", err);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
      return () => setisUpdated(false);
    };
    getAssignmentdata();
  }, [client, props.teacher_id, isUpdated]);

  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [Deleteassignmentid, setDeleteassignmentid] = useState("");
  const [Deleteassignmentname, setDeleteassignmentname] = useState("");
  const DeleteDailogBox = (item) => {
    setDeleteassignmentid(item.id);
    setDeleteassignmentname(item.name);
    setopendialogConfimation(true);
  };
  const [DELETE_TEACHER_ASSIGNMENT, {}] = useMutation(
    Service.DELETE_TEACHER_ASSIGNMENT
  );
  const cancelAssignment = (id) => {
    try {
      setisloading(true);
      DELETE_TEACHER_ASSIGNMENT({
        variables: { id },
      }).then(
        (response) => {
          setopendialogConfimation(false);
          setisloading(false);
          store.addNotification({
            title: "",
            message: `${response.data.deleteAssignment.message}`,
            type: "success",
            insert: "top-center",
            container: "top-center",
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
          setisUpdated(true);
        },
        (err) => {
          console.log("then error", err);
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const assignAssignment = (item) => {
    props.navigation.navigate("/assignmentAssign", {
      state: {
        item: item,
      },
    });
  };
  const filterStudentArr = Assignmentdata.filter((event) => {
    return (
      event.name
        .toString()
        .toLowerCase()
        .indexOf(props.searchbyassignmnetname.toLowerCase()) !== -1 &&
      (props.subjectid != -1 ? event.subject.id == props.subjectid : true)
    );
  });
  // const filterAssignmentdata = Assignmentdata.filter((event) => {
  //   if (event.assignment !== undefined && event.assignment !== null) {
  //     return (
  //       event.assignment.subject.name
  //         .toLowerCase()
  //         .indexOf(props.subjectname.toLowerCase()) !== -1 &&
  //       event.section.name
  //         .toLowerCase()
  //         .indexOf(props.sectionname.toLowerCase()) !== -1
  //     );
  //   }
  // });
  return (
    <div className="table_foramtitng mt-1 mb-2">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="table_header">
                Assignment
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Subject
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Description
              </StyledTableCell>
              <StyledTableCell align="left" className="table_header">
                Total Score
              </StyledTableCell>

              <StyledTableCell align="center" className="table_header">
                Details
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filterStudentArr.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filterStudentArr
            ).map((row) => (
              <StyledTableRow>
                <StyledTableCell align="left">
                  {getValue(row.name)}
                </StyledTableCell>
                <StyledTableCell scope="row" align="left">
                  {getValue(row.subject.name)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Tooltip title={row.description} arrow>
                    <span>{getSubStringValue(row.description)}</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {getValue(row.total_score)}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <div className="d-flex justify-content-between">
                    <span
                      role="button"
                      className="text-info pr-1"
                      onClick={() =>
                        props.navigation.navigate("/updateAssignment", {
                          state: {
                            data: row,
                            pagetype: "Assignment",
                          },
                        })
                      }
                    >
                      Open
                    </span>
                    |
                    <span
                      role="button"
                      className="text-info pl-1 pr-1"
                      onClick={() => DeleteDailogBox(row)}
                    >
                      Delete
                    </span>
                    |
                    <span
                      role="button"
                      className="text-info pl-1 pr-1"
                      onClick={() => assignAssignment(row)}
                    >
                      Assign
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          true
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filterStudentArr.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Loder loading={isloading} />
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Assignment : {Deleteassignmentname}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => cancelAssignment(Deleteassignmentid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Created);
