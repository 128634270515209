import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//redux
import * as Service from "../../../services/index";
import {
  serverFormatDate,
  blankValidator,
  currentDayForMinMAx,
  showNotificationMsz,
  timeStampToDate,
  timeStampToDatedash,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder";
import "./HolidayCalender.css";
const AddHolidayCalender = (props) => {
  let { data, academic_session_id } = props?.location?.state;
  let institution_id = parseInt(props.data.institution_id);

  const [update_holiday_data, {}] = useMutation(Service.update_holiday_data);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [isloading, setisloading] = useState(false);
  //error
  const [nameerror, setnameerror] = useState(false);
  const [startdateerror, setstartdateerror] = useState(false);
  const [enddateerror, setenddateerror] = useState(false);

  useEffect(() => {
    setName(data.name);
    setStartDate(timeStampToDatedash(data.start_datetime));
    setendDate(timeStampToDatedash(data.end_datetime));
  }, [props]);

  const UpdateCalender = () => {
    try {
      let flag = false;
      let scroll = false;
      if (!blankValidator(name)) {
        setnameerror(true);
        flag = true;
        if (scroll === false) {
          document.getElementById("name").focus();
        }
        scroll = true;
      }
      if (!blankValidator(startDate)) {
        setstartdateerror(true);
        flag = true;
        if (scroll === false) {
          document.getElementById("st_date").focus();
        }
        scroll = true;
      }
      if (!blankValidator(endDate)) {
        setstartdateerror(true);
        flag = true;
        if (scroll === false) {
          document.getElementById("end_date").focus();
        }
        scroll = true;
      }
      if (flag) {
        return;
      }
      setisloading(true);
      update_holiday_data({
        variables: {
          id: parseInt(data.id),
          name,
          start_datetime: serverFormatDate(new Date(startDate)),
          end_datetime: serverFormatDate(new Date(endDate)),
          institution_id,
          academic_session_id,
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateSchoolCalendar.message,
            "success"
          );

          props.navigate.goBack();
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz("Not Created", "danger");
          console.log("t and c error", error);
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz("Not Created", "danger");
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.data.institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="mt-3 ml-3 Heading_bold">Create Holiday</div>
              </div>
              {/* -----------------form section------------ */}
              <Card>
                <div className="card_admissiondetails_height">
                  <div className="textfiled_margin">
                    <Grid className="grid_main_div">
                      <Grid item md={4}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1">Name</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              autoComplete="off"
                              value={name}
                              onChange={(e) => {
                                setnameerror(false);
                                setName(e.target.value);
                              }}
                            />
                            {nameerror && (
                              <span className="text-danger">
                                Enter Event Name
                              </span>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1">Start Date</label>
                          <div>
                            <input
                              type="date"
                              className="form-control"
                              id="st_date"
                              autoComplete="off"
                              min={currentDayForMinMAx()}
                              value={startDate}
                              onChange={(e) => {
                                setstartdateerror(false);
                                setStartDate(e.target.value);
                              }}
                            />
                            {startdateerror && (
                              <span className="text-danger">Enter Date</span>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1">End Date</label>
                          <div>
                            <input
                              type="date"
                              className="form-control"
                              id="end_date"
                              autoComplete="off"
                              min={startDate}
                              value={endDate}
                              onChange={(e) => {
                                setenddateerror(false);
                                setendDate(e.target.value);
                              }}
                            />
                            {enddateerror && (
                              <span className="text-danger">Enter Date</span>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <div className="submit_button_right_set">
                      <Button
                        variant="contained"
                        className=" "
                        onClick={UpdateCalender}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

export default connect(null, null)((HOC1(AddHolidayCalender)));
