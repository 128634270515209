import React, { useEffect } from "react";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import logo from "./Skool_First_Logo.png";

//redux
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
import * as Service from "../../services/index";
import { getFullName } from "../../utils/Validation";

const AdminInstitutionHeader = (props) => {
  const { LoadImage } = props;
  let userInformation = props.data;
  useEffect(() => {}, [props]);
  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader1">
          <Toolbar>
            <Grid container spacing={10}>
              <Grid item xs={6} md={9}>
                <div className="logoformatting">
                  <span>
                    {LoadImage?.document && LoadImage?.document?.path ? (
                      <img
                        src={`${Service.baseUrl}/${LoadImage?.document?.path}`}
                        alt=""
                        className="image_hader"
                      />
                    ) : (
                      <img src={logo} alt="" className="image_hader" />
                    )}
                  </span>
                </div>
              </Grid>

              <Grid item xs={6} md={3}>
                <div className="text-right mt-2 amininstitution_padding">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn  dropdown-toggle dropdownbutton1 button_login_decoration"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ textTransform: "capitalize" }}
                    >
                      {getFullName(userInformation)}
                      {/* {`${userInformation && userInformation.first_name} ${
                        userInformation && userInformation?.last_name
                      }`} */}
                    </button>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => props.navigate("/studentresetpassword")}
                      >
                        Reset Password
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => props.navigate("/")}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  LoadImage: getData(state, Constants.LOADIMAGE),
});

export default connect(mapStateToProps, null)(AdminInstitutionHeader);
