import React, { useState, useEffect } from "react";
import "./Events.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import RichTextEditor from "react-rte";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../Holiday/Holiday.jsx";
import { baseUrl } from "../../../services/index.js";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  timeStampToDatedash,
  timeStampToDateandTimewithT,
  currentDayForMinMAx,
} from "../../../utils/Validation";
import { useMutation, useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const EditEvent = (props) => {
  console.log("props==========", props);
  //to update Event
  const client = useApolloClient();
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const [Update_Event, {}] = useMutation(Service.Update_Event);

  const [locationid, setlocationid] = useState("");
  const [locationArr, setlocationArr] = useState([]);

  //to Delte documnet

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  //const [filename, setFilename] = useState("Add Attachment");
  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  const [name, setName] = useState("");
  const [charleft, setcharleft] = useState("250");
  const [description, setDescription] = useState("");

  const [filename, setFileName] = useState("Add Attachment");
  const [uploadFileArr, setUploadFileArr] = useState([]);
  // let academic_session_id = parseInt(props.location.state.academic_session_id);
  //const [message, setMessage] = useState("");

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [publishDate, setPublishDate] = useState("");

  //error
  const [nameerror, setnameerror] = useState(false);
  const [descriptionerror, setdescriptionerror] = useState(false);
  const [startdateerror, setstartdateerror] = useState(false);
  const [enddateerror, setenddateerror] = useState(false);
  const [publishdateerror, setpublishdateerror] = useState(false);
  const [locationerror, setlocationerror] = useState(false);
  const [messageerror, setmessageerror] = useState(false);

  const [isloading, setisloading] = useState(false);

  // const user_id = parseInt(props.data.userid);

  //const [leaveid, setleaveid] = useState("");

  useEffect(() => {
    getlocation();
    geValues();
  }, [props]);

  const geValues = async () => {
    if (props.location.state.data.event_name != null) {
      await setName(props.location.state.data.event_name);
    }

    if (
      props.location.state.data.start_date != null &&
      props.location.state.data.start_date !== undefined
    ) {
      setStartDate(
        timeStampToDateandTimewithT(props.location.state.data.start_date)
      );
    }
    if (
      props.location.state.data.end_date != null &&
      props.location.state.data.end_date !== undefined
    ) {
      await setEndDate(
        timeStampToDateandTimewithT(props.location.state.data.end_date)
      );
    }

    if (
      props.location.state.data.publish_date != null &&
      props.location.state.data.publish_date !== undefined
    ) {
      await setPublishDate(
        timeStampToDatedash(props.location.state.data.publish_date)
      );
    }

    if (
      props.location.state.data.location != null &&
      props.location.state.data.location !== undefined
    ) {
      await setlocationid(parseInt(props.location.state.data.location.id));
    }
    if (
      props.location.state.data.description != null &&
      props.location.state.data.description !== undefined
    ) {
      await setDescription(props.location.state.data.description);
    }
    if (
      props.location.state.data.content != null &&
      props.location.state.data.content !== undefined
    ) {
      await setValue(
        RichTextEditor.createValueFromString(
          props.location.state.data.content,
          "html"
        )
      );
    }
    if (
      props.location.state.data.documents != null &&
      props.location.state.data.documents !== undefined
    ) {
      await setUploadFileArr(props.location.state.data.documents);
    }
  };

  const getlocation = () => {
    try {
      client
        .query({
          query: Service.Get_Manage_Location,
          variables: {
            institution_id: parseInt(props.data.institution_id),
          },
        })
        .then((response) => {
          setisloading(false);
          setlocationArr(response.data.getLocation);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log(" location arr try and catch", error);
    }
  };

  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFileName(file.name);
          setUploadFileArr([...uploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const submit = () => {
    if (!blankValidator(name)) {
      setnameerror(true);
      return;
    }
    if (!blankValidator(description)) {
      setdescriptionerror(true);
      return;
    }

    if (!blankValidator(startDate)) {
      setstartdateerror(true);
      return;
    }
    if (!blankValidator(endDate)) {
      setenddateerror(true);
      return;
    }
    if (!blankValidator(publishDate)) {
      setpublishdateerror(true);
      return;
    }

    if (!blankValidator(locationid) || locationid === -1) {
      setlocationerror(true);
      return;
    }
    if (value.toString("html") === "<p><br></p>") {
      setmessageerror(true);
      return;
    }

    try {
      setisloading(true);
      let temp = [];
      uploadFileArr.map((item) => {
        temp.push({
          name: item.name,
          filename: item.filename,
          type: item.type,
          path: item.path,
        });
      });

      Update_Event({
        variables: {
          id: parseInt(props.location.state.data.id),
          event_name: name,
          start_date: startDate,
          end_date: endDate,
          organizer_id: parseInt(props.data.userid),
          publish_date: publishDate,
          description: description,
          location: parseInt(locationid),
          expiry_date: endDate,
          content: value.toString("html"),
          event_doc: temp,
          user_type: props?.data?.usertype,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.updateEvent.message, "success");
          props.navigate.goBack();
          //props.navigate("/manageEvent", { academic_session_id });
        },
        (err) => {
          showNotificationMsz("Not Updated", "danger");

          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      showNotificationMsz("Not Updated", "danger");
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Edit Event</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">Name</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Name"
                                  autoComplete="off"
                                  value={name}
                                  onChange={(e) => {
                                    setnameerror(false);
                                    setName(e.target.value);
                                  }}
                                />
                                {nameerror && (
                                  <span className="text-danger">
                                    Enter Event Name
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Description
                              </label>
                              <div>
                                <textarea
                                  class="form-control"
                                  rows="3"
                                  placeholder="Description"
                                  autoComplete="off"
                                  maxlength="250"
                                  value={description}
                                  onChange={(e) => {
                                    let leftcharater =
                                      250 - e.target.value.length;
                                    setcharleft(leftcharater);
                                    setdescriptionerror(false);
                                    setDescription(e.target.value);
                                  }}
                                />
                                {descriptionerror && (
                                  <span className="text-danger">
                                    Enter Description of Event
                                  </span>
                                )}
                              </div>
                              <span>Character limit:{charleft}</span>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Start Date</label>
                              <div>
                                <input
                                  type="datetime-local"
                                  //  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  min={currentDayForMinMAx()}
                                  value={startDate}
                                  onChange={(e) => {
                                    setstartdateerror(false);
                                    setStartDate(e.target.value);
                                  }}
                                />
                                {startdateerror && (
                                  <span className="text-danger">
                                    Enter Start Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">End Date</label>
                              <div>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={endDate}
                                  min={startDate}
                                  onChange={(e) => {
                                    setenddateerror(false);
                                    setEndDate(e.target.value);
                                  }}
                                />
                                {enddateerror && (
                                  <span className="text-danger">
                                    Enter End Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Publish Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  min={startDate}
                                  value={publishDate}
                                  onChange={(e) => {
                                    setpublishdateerror(false);
                                    setPublishDate(e.target.value);
                                  }}
                                />
                                {publishdateerror && (
                                  <span className="text-danger">
                                    Enter published Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">Location</label>
                              <div>
                                <select
                                  id="location"
                                  class="form-control"
                                  value={locationid}
                                  onChange={(e) => {
                                    setlocationid(parseInt(e.target.value));
                                  }}
                                >
                                  <option value={-1}>Select</option>
                                  {locationArr.length > 0 &&
                                    locationArr.map((item, index) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                                {locationerror && (
                                  <span className="text-danger">
                                    Enter Location of Event
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div class="form-group">
                              <label for="exampleFormControlTextarea1">
                                Content
                              </label>
                              <RichTextEditor
                                value={value}
                                onChange={(e) => {
                                  setmessageerror(false);
                                  setValue(e);
                                }}
                                className="editor_height"
                              />
                              {messageerror && (
                                <span className="text-danger">
                                  Enter Message of Event
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="">
                              <label for="exampleInputEmail1">Attachment</label>
                              <div className=" mb-2 d-flex">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                      <i class="fa fa-paperclip"></i>
                                    </span>
                                  </div>
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      id="attachment"
                                      class="custom-file-input"
                                      onChange={ImageUploadHandler}
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="inputGroupFile01"
                                    >
                                      {filename}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <label className="mt-2">Attachment Lists</label>
                        <div className=" mb-2 attachList">
                          {uploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2"
                              >
                                {item.name}
                              </span>
                              {/* <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() => {
                                    uploadFileArr.splice(index, 1);
                                    setUploadFileArr([...uploadFileArr]);
                                  }}
                                ></i>
                              </span> */}
                            </div>
                          ))}
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={11}></Grid>
                          <Grid item md={1}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration"
                                onClick={submit}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(EditEvent));
