import React, { useState } from "react";
import ViewLeftPenalAccordion from "./ViewLeftPenalAccordion";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import StudentLeftExpand from "./StudentLeftExpand";

const ViewLeftPenalSubjectforStudent = (props) => {
  const { type, setUpdateApi, updateApi, row } = props;
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        onClick={() => {
          setOpen(!open);
        }}
      >
     {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        {props.className}
      </div>

      {open && row.map((rowData) => (
        <StudentLeftExpand
          {...props}
          row={rowData}
        ></StudentLeftExpand>
      ))}
    </div>
  );
};

export default ViewLeftPenalSubjectforStudent;
