import React, { useState, useEffect } from "react";
import "./AssignmentAssign.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
//redux
import * as Constants from "../../../action/appAction";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import Loder from "../../Loder/Loder";
import {
  getFullName,
  blankValidator,
  CapitalizeFirstFn,
  getValue,
  timeStampToDatedashDayStart,
  getSubStringValue,
} from "../../../utils/Validation";
import Tooltip from "@material-ui/core/Tooltip";
import { useApolloClient, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
function AssignmentView(props) {
  const [isloading, setisloading] = useState(false);
  const [studentlist, setStudentlist] = useState([]);
  const [DELETE_TEACHER_ASSIGNMENT_FROM_REVIEWPENDING, {}] = useMutation(
    Service.DELETE_TEACHER_ASSIGNMENT_FROM_REVIEWPENDING
  );

  const [opendialogConfimation, setopendialogConfimation] = useState(false);

  const client = useApolloClient();
  const [search, setSearch] = useState("");

  let status = props.location.state.row.status;

  useEffect(() => {
    window.scrollTo(0, 0);
    const getStudentList = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_StudentList_By_AssignmentId,
            variables: {
              assignment_id: parseInt(props.location.state.row.assignment.id),
            },
          })
          .then((response) => {
            if (response.data.getStudentListByAssignmentId.length !== 0) {
              setStudentlist(response.data.getStudentListByAssignmentId);
            }
            setisloading(false);
          })
          .catch((e) => {
            console.log("catch error", e);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getStudentList();
  }, [client, props.location.state.row.assignment.id]);
  const cancelAssignment = () => {
    try {
      setisloading(true);
      DELETE_TEACHER_ASSIGNMENT_FROM_REVIEWPENDING({
        variables: {
          assignment_id: parseInt(props.location.state.row.assignment.id),
          section_id: parseInt(props.location.state.row.section.id),
        },
      }).then(
        (response) => {
          setopendialogConfimation(false);
          setisloading(false);
          if (
            response.data.deleteClassAssignment.message !== null ||
            response.data.deleteClassAssignment.message !== undefined
          ) {
            setopendialogConfimation(false);
            props.navigate("/teacherHomePage");
          }
        },
        (err) => {
          console.log("then error", err);
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const classes = useStyles();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const emptyRows1 =
  //   rowsPerPage1 -
  //   Math.min(rowsPerPage1, studentlist.length - page1 * rowsPerPage1);

  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const filterStudentlist = studentlist.filter((event) => {
    if (blankValidator(event.student.student_profile)) {
      return (
        event.student.student_profile.name.first_name
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    }
  });
  return (
    <>
      <div className="main_full_width">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={props.data.institution_name} />
                <div className="enquirydetails_background mb-2">
                  <div className="enquiry_heading">
                    <p className="mt-3 ml-3 Heading_bold">
                      <p>
                        <span className="text-info ">Assignment Review</span>
                      </p>
                      <p className="text-info">
                        {getValue(
                          CapitalizeFirstFn(
                            props.location.state.row.section.grade.gradeName
                          )
                        ) +
                          " - " +
                          getValue(
                            CapitalizeFirstFn(
                              props.location.state.row.section.name
                            )
                          )}
                      </p>
                    </p>

                    <span className="mt-3 mr-3">
                      <Button
                        variant="contained "
                        className=" add_new_query_btn button_login_decoration"
                        onClick={() => {
                          setopendialogConfimation(true);
                        }}
                      >
                        cancel Assignment
                      </Button>
                    </span>
                  </div>

                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin mt-3 mb-3">
                          <div className="table_foramtitng ">
                            <TableContainer component={Paper}>
                              <div className="">
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Assignment Name
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Start Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        End Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Completed
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        In Progress
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Not Started
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Review Pending
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Summary
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header assignment_view_padding_head"
                                      >
                                        Status
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <StyledTableRow>
                                      <StyledTableCell
                                        scope="row"
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {getValue(
                                          CapitalizeFirstFn(
                                            props.location.state.row.assignment
                                              ?.name
                                          )
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {timeStampToDatedashDayStart(
                                          props.location.state.row.start_date
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {timeStampToDatedashDayStart(
                                          props.location.state.row.end_date
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {studentlist && (
                                          <span>
                                            {
                                              studentlist.filter((item) => {
                                                return (
                                                  item.status === "COMPLETED"
                                                );
                                              }).length
                                            }{" "}
                                            /{studentlist.length}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {" "}
                                        {studentlist && (
                                          <span>
                                            {
                                              studentlist.filter((item) => {
                                                return (
                                                  item.status === "INPROGRESS"
                                                );
                                              }).length
                                            }{" "}
                                            /{studentlist.length}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {studentlist && (
                                          <span>
                                            {
                                              studentlist.filter((item) => {
                                                return item.status === "NEW";
                                              }).length
                                            }{" "}
                                            /{studentlist.length}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {studentlist && (
                                          <span>
                                            {
                                              studentlist.filter((item) => {
                                                return (
                                                  item.status === "SUBMITTED"
                                                );
                                              }).length
                                            }{" "}
                                            /{studentlist.length}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        <Tooltip
                                          title={
                                            props.location.state.row.description
                                          }
                                          arrow
                                        >
                                          <span>
                                            {getSubStringValue(
                                              props.location.state.row
                                                .description
                                            )}
                                          </span>
                                        </Tooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="assignment_view_padding_head"
                                      >
                                        {" "}
                                        {getValue(status)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                              </div>
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>

                <div className="enquirydetails_background mb-2">
                  <div className="enquiry_heading ">
                    <div className="mt-3 ml-3 Heading_bold">Student List</div>
                  </div>

                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin mt-3 mb-3">
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={3}>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Search by Name"
                                  autoComplete="off"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={3}></Grid>

                            <Grid item md={6}></Grid>
                          </Grid>

                          <div className="table_foramtitng mt-3">
                            <TableContainer component={Paper}>
                              <div className="">
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Roll Number
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Score
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header"
                                      >
                                        Status
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        View
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage1 > 0
                                      ? filterStudentlist.slice(
                                          page1 * rowsPerPage1,
                                          page1 * rowsPerPage1 + rowsPerPage1
                                        )
                                      : filterStudentlist
                                    ).map((row, index) => (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {getValue(row.student.roll_number)}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {getFullName(
                                            row?.student?.student_profile?.name
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {getValue(row.score)}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {(row.status === "NEW" ||
                                            row.status === "INPROGRESS") && (
                                            <div className="text-warning">
                                              {row.status}
                                            </div>
                                          )}
                                          {row.status === "COMPLETED" && (
                                            <div className="text-danger">
                                              {row.status}
                                            </div>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="openassignemntcolor"
                                        >
                                          {row.status === "SUBMITTED" ? (
                                            <span
                                              onClick={() => {
                                                props.navigate(
                                                  "/teacherReviewOfAnswer",
                                                  {
                                                    state: {
                                                      studentData: row,
                                                      assignmentData:
                                                        props.location.state.row
                                                          .assignment,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              Review
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {row.status === "COMPLETED" ? (
                                            <span
                                              onClick={() =>
                                                props.navigate(
                                                  "/commonAssignmentResultView",
                                                  {
                                                    state: {
                                                      studentData: row,
                                                      assignmentData:
                                                        props.location.state.row
                                                          .assignment,
                                                      gradeName:
                                                        props.location.state.row
                                                          .section.grade
                                                          .gradeName,
                                                    },
                                                  }
                                                )
                                              }

                                              // onClick={() =>
                                              //  props.navigate(
                                              //     "/commonAssignmentResultView",
                                              //     {
                                              //       studentData: row,
                                              //       assignmentData:props.location.state.row.assignment,
                                              //       gradeName:props.location.state.row.section.grade.gradeName

                                              //     }
                                              //   )
                                              // }
                                            >
                                              Open
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </div>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterStudentlist.length}
                                rowsPerPage={rowsPerPage1}
                                page={page1}
                                onChangePage={handleChangePage1}
                                onChangeRowsPerPage={handleChangeRowsPerPage1}
                              />
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Loder loading={isloading} />

        <Dialog
          open={opendialogConfimation}
          onClose={() => {
            setopendialogConfimation(false);
          }}
          aria-labelledby="draggable-dialog-title"
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                ></i>
              </div>
            </div>
            <div className="pl-5 pr-5 mt-4">
              Are you sure want to delete the Assignment :{" "}
              {getValue(
                CapitalizeFirstFn(props.location.state.row.assignment.name)
              )}
            </div>

            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={cancelAssignment}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      setopendialogConfimation(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AssignmentView));
