import React, { useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./AssignmentAssign.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const AssignmentCancel = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [Assignmentdata] = useState([
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
    {
      class: "1",
      section: "A",
      startdate: "12/01/2021",
      enddate: "13/01-2021",
      status: "Assigned",
    },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Assignments- Hindi(Grammer)
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="checkbox"
                                    className="table_header"
                                  >
                                    <Checkbox />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End End
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? Assignmentdata.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : Assignmentdata
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell padding="checkbox">
                                      <Checkbox />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.class}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.section}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {row.startdate}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.enddate}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <Button
                                        className="Complete_color_teacher button_login_decoration"
                                        variant="contained"
                                      >
                                        {row.status}
                                      </Button>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={Assignmentdata.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>

                        <Grid className="grid_main_div mt-4 mb-4">
                          <Grid item md={9}></Grid>
                          <Grid item md={3}>
                            {" "}
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                            >
                              Cancel Assignment
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default (HOC1(AssignmentCancel));
