import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./AdmissionIntrest.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder.jsx";
import { relationData } from "../../../utils/LocalData.js";
import * as Service from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  emailValidator,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const SubmitAdmissionIntrest = (props) => {
  let institution_id = parseInt(props.data.institution_id);
  let Organization_Name = props.data.institution_name;
  let academic_session_id = parseInt(props.data.academic_session_id);
  const [Add_Enquery_Data, {}] = useMutation(Service.Add_Enquery_Data);
  const [isloading, setisloading] = useState(false);
  const client = useApolloClient();
  let pageType = props.location.state.pagetype;
  const [Parent_first_name, setParent_first_name] = useState("");
  const [Parent_middle_name, setParent_middle_name] = useState("");
  const [Parent_last_name, setParent_last_name] = useState("");
  const [Student_first_name, setStudent_first_name] = useState("");
  const [Student_middle_name, setStudent_middle_name] = useState("");
  const [Student_last_name, setStudent_last_name] = useState("");
  const [student_birth_date, setstudent_birth_date] = useState("");
  const [academic_session, setacademic_session] = useState("");
  const [grade, setgrade] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [email, setemail] = useState("");
  const [referee, setreferee] = useState("");
  const [sibling_first_name, setsibling_first_name] = useState("");
  const [sibling_middle_name, setsibling_middle_name] = useState("");
  const [sibling_last_name, setsibling_last_name] = useState("");
  const [sibling_class, setsibling_class] = useState("");
  const [comment, setcomment] = useState("");

  const [Parent_first_nameError, setParent_first_nameError] = useState(false);
  const [Parent_middle_nameError, setParent_middle_nameError] = useState(false);
  const [Parent_last_nameError, setParent_last_nameError] = useState(false);
  const [Student_first_nameError, setStudent_first_nameError] = useState(false);
  const [Student_middle_nameError, setStudent_middle_nameError] =
    useState(false);
  const [Student_last_nameError, setStudent_last_nameError] = useState(false);
  const [student_birth_dateError, setstudent_birth_dateError] = useState(false);
  const [academic_sessionError, setacademic_sessionError] = useState(false);
  const [gradeError, setgradeError] = useState(false);
  const [contact_numberError, setcontact_numberError] = useState(false);
  const [emailError, setemailError] = useState(false);

  const [relation, setrelation] = useState(false);
  const [relationError, setrelationError] = useState(false);
  const [commentError, setcommentError] = useState(false);

  const [classArr, setClassArr] = useState([]);
  const [academicSessionArr, setAcademicSessionArr] = useState([]);

  // to get data of manage role
  useEffect(() => {
    window.scrollTo(0, 0);

    const getSessionandClass = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_ALL_ACADEMIC_SESSION,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            const { getAllAcademicSession } = response.data;
            if (getAllAcademicSession) {
              setAcademicSessionArr(getAllAcademicSession);
              const currentSession = getAllAcademicSession.find((element) => {
                if (element.is_current === 1) {
                  return element.id;
                }
              });
              if (currentSession) {
                setacademic_session(currentSession.id + "");
              }
            }
            setisloading(false);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getSessionandClass();
  }, [
    client,
    pageType,
    academic_session_id,
    institution_id,
    props.location.state.data,
  ]);

  useEffect(() => {
    academicSessionHandler(academic_session);
  }, [academic_session]);

  const GradeHandler = (GradeID) => {
    setgradeError(false);
    let grade_id = parseInt(GradeID);
    if (grade_id === -1) {
      setgrade("");
      return;
    }
    setgrade(grade_id);
  };
  const relationHandler = (relationName) => {
    if (!relationName) return;
    setrelation(relationName);
    setrelationError(false);
  };
  const academicSessionHandler = (academicSessionId) => {
    setacademic_sessionError(false);
    if (academicSessionId === -1) {
      setacademic_session("");
      setClassArr([]);
      return;
    }
    setacademic_session(academicSessionId + "");
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academicSessionId),
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const SubmitAdmissionIntrest = () => {
    let flag = false;
    if (!blankValidator(Parent_first_name)) {
      setParent_first_nameError(true);
      flag = true;
    }

    if (!blankValidator(Parent_last_name)) {
      setParent_last_nameError(true);
      flag = true;
    }
    if (!blankValidator(Student_first_name)) {
      setStudent_first_nameError(true);
      flag = true;
    }

    if (!blankValidator(Student_last_name)) {
      setStudent_last_nameError(true);
      flag = true;
    }

    if (!blankValidator(student_birth_date)) {
      setstudent_birth_dateError(true);
      flag = true;
    }

    if (!blankValidator(academic_session) || academic_session === -1) {
      setacademic_sessionError(true);
      flag = true;
    }
    if (!blankValidator(grade) || grade === -1) {
      setgradeError(true);
      flag = true;
    }
    if (!blankValidator(contact_number)) {
      setcontact_numberError(true);
      flag = true;
    }
    if (!emailValidator(email)) {
      setemailError(true);
      flag = true;
    }

    if (!relation) {
      setrelationError(true);
      flag = true;
    }
    if (!blankValidator(comment)) {
      setcommentError(true);
      flag = true;
    }
    if (flag) {
      return;
    }

    try {
      setisloading(true);
      Add_Enquery_Data({
        variables: {
          institution_id,
          Parent_first_name,
          Parent_middle_name,
          Parent_last_name,
          Student_first_name,
          Student_middle_name,
          Student_last_name,
          student_birth_date,
          academic_session,
          grade: parseInt(grade),
          contact_number,
          email,
          referee,
          sibling_first_name,
          sibling_middle_name,
          sibling_last_name,
          sibling_class,
          parent_type: relation,
          comment,
        },
      })
        .then((response) => {
          const { admissionEnquiry } = response.data;
          if (admissionEnquiry && admissionEnquiry.message) {
            showNotificationMsz(
              response.data.admissionEnquiry.message,
              "success"
            );
           props.navigate("admissionIntrest");
          } else {
            showNotificationMsz("Something went wrong!", "success");
          }
          setisloading(false);
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
          setisloading(false);
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-4 Heading_bold">
                    Admission Interest
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <p className="font-weight-bold mt-2">
                          Parent Name{" "}
                          <span className="text-danger pl-1">*</span>
                        </p>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                autoComplete="off"
                                value={Parent_first_name}
                                onChange={(e) => {
                                  setParent_first_nameError(false);
                                  setParent_first_name(e.target.value);
                                }}
                              />
                              {Parent_first_nameError && (
                                <span className="text-danger">
                                  Enter the Parent First Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle Name"
                                autoComplete="off"
                                value={Parent_middle_name}
                                onChange={(e) => {
                                  setParent_middle_nameError(false);
                                  setParent_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                autoComplete="off"
                                value={Parent_last_name}
                                onChange={(e) => {
                                  setParent_last_nameError(false);
                                  setParent_last_name(e.target.value);
                                }}
                              />
                              {Parent_last_nameError && (
                                <span className="text-danger">
                                  Enter the Parent Last Name
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <p className="font-weight-bold mt-2">
                          Student Name{" "}
                          <span className="text-danger pl-1">*</span>
                        </p>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                autoComplete="off"
                                value={Student_first_name}
                                onChange={(e) => {
                                  setStudent_first_nameError(false);
                                  setStudent_first_name(e.target.value);
                                }}
                              />
                              {Student_first_nameError && (
                                <span className="text-danger">
                                  Enter the Student First Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle Name"
                                autoComplete="off"
                                value={Student_middle_name}
                                onChange={(e) => {
                                  setStudent_middle_nameError(false);
                                  setStudent_middle_name(e.target.value);
                                }}
                              />
                              {Student_middle_nameError && (
                                <span className="text-danger">
                                  Enter the Student Middle Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                autoComplete="off"
                                value={Student_last_name}
                                onChange={(e) => {
                                  setStudent_last_nameError(false);
                                  setStudent_last_name(e.target.value);
                                }}
                              />
                              {Student_last_nameError && (
                                <span className="text-danger">
                                  Enter the Student Last Name
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Relationship with parent
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  relationHandler(e.target.value);
                                }}
                              >
                                <option value="">Select Relation</option>
                                {relationData.length !== 0 &&
                                  relationData.map((item, index) => (
                                    <option key={index} value={item.label}>
                                      {item.label}
                                    </option>
                                  ))}
                              </select>
                              {relationError && (
                                <span className="text-danger">
                                  Select the Relation
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Academic Session Applied{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                value={academic_session}
                                onChange={(e) => {
                                  setacademic_session(e.target.value);
                                }}
                              >
                                <option value={-1}>
                                  Select Academic Session
                                </option>
                                {academicSessionArr.length !== 0 &&
                                  academicSessionArr.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.session_name}
                                    </option>
                                  ))}
                              </select>
                              {academic_sessionError && (
                                <span className="text-danger">
                                  Select the Academic Session
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Class <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                value={grade}
                                onChange={(e) => {
                                  GradeHandler(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Class</option>
                                {classArr.length !== 0 &&
                                  classArr.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                              {gradeError && (
                                <span className="text-danger">
                                  Select the Class/Grade
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                value={contact_number}
                                onChange={(e) => {
                                  setcontact_numberError(false);
                                  setcontact_number(e);
                                }}
                              />
                              {contact_numberError && (
                                <span className="text-danger">
                                  Enter the Phone Number
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email Address"
                                autoComplete="off"
                                value={email}
                                onChange={(e) => {
                                  setemailError(false);
                                  setemail(e.target.value);
                                }}
                              />
                              {emailError && (
                                <span className="text-danger">
                                  Enter the Email Address
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Student Date of Birth{" "}
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                value={student_birth_date}
                                onChange={(e) => {
                                  setstudent_birth_dateError(false);
                                  setstudent_birth_date(e.target.value);
                                }}
                              />
                              {student_birth_dateError && (
                                <span className="text-danger">
                                  Enter the Date Of Birth
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Sibling Details (if Present)
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                autoComplete="off"
                                value={sibling_first_name}
                                onChange={(e) => {
                                  setsibling_first_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle Name"
                                autoComplete="off"
                                value={sibling_middle_name}
                                onChange={(e) => {
                                  setsibling_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                autoComplete="off"
                                value={sibling_last_name}
                                onChange={(e) => {
                                  setsibling_last_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Sibling Class/Section
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Class-Section"
                                autoComplete="off"
                                value={sibling_class}
                                onChange={(e) => {
                                  setsibling_class(e.target.value);
                                }}
                              />
                              {/* {sibling_classError && (
                                <span className="text-danger">
                                  Enter the Sibling Class Name
                                </span>
                              )} */}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Referred by whom
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                autoComplete="off"
                                value={referee}
                                onChange={(e) => {
                                  setreferee(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Comments <span className="text-danger pl-1">*</span>
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="">
                              <textarea
                                className="form-control  textareaformatting"
                                id="exampleFormControlTextarea1"
                                placeholder="Your comments here......"
                                rows="3"
                                type="text"
                                value={comment}
                                onChange={(e) => {
                                  setcommentError(false);
                                  setcomment(e.target.value);
                                }}
                              />
                              {commentError && (
                                <span className="text-danger">
                                  Enter the Comment/Message
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={11}></Grid>
                          <Grid item md={1}>
                            <div className=" mb-3">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration"
                                onClick={SubmitAdmissionIntrest}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(SubmitAdmissionIntrest)));
