import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import TransportDropAttendence from "./TransportDropAttendence";
import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { isPresent, newDateToDate } from "../../../utils/Validation.js";
// import { useMutation } from "@apollo/client";

const MarkTransportAttendence = (props) => {
  const today_Date = new Date().toJSON().slice(0, 10);

  let data = props?.location?.state?.data;
  // const [Teacher_Mark_Attendace_API, {}] = useMutation(
  //   Service.Teacher_Mark_Attendace_API
  // );

  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);

  //name instituition
  let Organization_Name = props?.data?.institution_name;
  // const [allChecked, setAllChecked] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_student_list_transport_route_id,
          variables: {
            transport_route_id: parseInt(data.id),
            start_date: newDateToDate(new Date()),
            end_date: newDateToDate(new Date()),
            status: "PICK",
          },
        })
        .then((response) => {
          try {
            let tempArr = [];
            let rollNoArray = response.data.getStudentListByTransportRouteId;
            if (rollNoArray) {
              let sortedArr = rollNoArray.sort((a, b) => {
                return parseInt(a.roll_number) - parseInt(b.roll_number);
              });
              sortedArr.map((item) => {
                let present__status = isPresent(today_Date, item.attendances);

                tempArr.push({
                  isSelected: present__status === "Present" ? true : false,
                  roll_number: item.roll_number,
                  id: item.id,
                  studentName: item?.student_profile?.name,
                });
              });
              setRouteData(tempArr);
            }

            setisloading(false);
          } catch (error) {
            setisloading(false);
            console.log("catch err", error);
          }
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  }, []);

  // const allCheckHandler = () => {
  //   if (setRouteData.length > 0) {
  //     setAllChecked(!allChecked);
  //   }

  //   let temp = [];
  //   RouteData.map((item) => {
  //     if (!allChecked) {
  //       item.isSelected = true;
  //     } else {
  //       item.isSelected = false;
  //     }
  //     temp.push(item);
  //   });
  //   setRouteData(temp);
  // };
  // const markStudentAttendaceHandler = () => {
  //   try {
  //     let temp = [];
  //     RouteData.map((item) => {
  //       if (item.isSelected) {
  //         temp.push({
  //           user_id: item.id,
  //           status: "Present",
  //           user_type: "STUDENT",
  //         });
  //       } else {
  //         temp.push({
  //           user_id: item.id,
  //           status: "Absent",
  //           user_type: "STUDENT",
  //         });
  //       }
  //     });
  //     if (temp.length === 0) {
  //       alert("Please select at lease one student");
  //       return;
  //     }
  //     setisloading(true);
  //     Teacher_Mark_Attendace_API({
  //       variables: {
  //         attendance_type: "TRANSPORT_PICKUP",
  //         date: today_Date,
  //         user_id: temp,
  //       },
  //     })
  //       .then((response) => {
  //         setisloading(false);
  //         //   setIsUpdated(true);
  //       })
  //       .catch((error) => {
  //         setisloading(false);
  //         console.log("then error", error);
  //       });
  //   } catch (error) {
  //     setisloading(false);
  //     console.log("t and c error", error);
  //   }
  // };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    Route Details ({data?.route_name})
                  </div>

                  <div className="inputMarginright mt-3">
                    <input
                      type="date"
                      className="form-control"
                      id="exampleFormControlInput1"
                      autoComplete="off"
                      value={newDateToDate(new Date())}
                    />
                    {/* {StartdateError && (
                        <span className="text-danger">Enter Start Date</span>
                      )} */}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <TransportDropAttendence transportData={data} />
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(MarkTransportAttendence));
