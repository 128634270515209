import React, { useState, useEffect } from "react";
import "./Exam.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  getTimeDIff,
  getValue,
  timeStampDateToOnlytimewithoutTimeDiff,
  timeStampToDate,
} from "../../../utils/Validation";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { getTime } from "date-fns";
import OnlineLinkLocation from "../../Common/Events/OnlineLinkLocation";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ExamTime(props) {
  //exam name
  let ExamTitle = props.location.state.item.name;

  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ExamtimeData, setExamtimeData] = useState([]);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const getStudentDataSectionId = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_EXAM_TimeTable_DATA_Student,
            variables: {
              exam_id: parseInt(props.location.state.item.id),
            },
          })
          .then(
            (response) => {
              setisloading(false);

              if (response.data.getExamTimetable.length > 0) {
                setExamtimeData(response.data.getExamTimetable);
              }
            },

            (err) => {
              setisloading(false);
              console.log("exam then err", err);
            }
          )
          .catch((err) => {
            setisloading(false);
            console.log("exam catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("exam t and c error", error);
      }
    };
    getStudentDataSectionId();
  }, [client]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={getValue(props.user_info?.institution_name)} />

              <div className="enquirydetails_background mb-2">
                <div>
                  <div className="mt-3 ml-4 ">
                    <div className="Heading_bold mt-2">Exam Time Table</div>
                    <div>
                      <span className="Heading_bold">
                        Exam name : {getValue(props.location.state.item?.name)}
                      </span>
                    </div>
                    <div>
                      <span className="Heading_bold">Class : </span>
                      <span>
                        <span>
                          {getValue(
                            props.location.state.item?.section_data?.section
                              ?.grade?.gradeName
                          )}
                        </span>
                        {" - "}
                        <span>
                          {getValue(
                            props.location.state.item?.section_data?.section
                              ?.name
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Date
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Start Time
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End Time
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Duration
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Location
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Actions
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? ExamtimeData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : ExamtimeData
                                ).map((row) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {getValue(row.subject?.name)}
                                      </StyledTableCell>
                                      <StyledTableCell scope="row" align="left">
                                        {timeStampToDate(
                                          row.start_calendar?.datetime
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {timeStampDateToOnlytimewithoutTimeDiff(
                                          row.start_calendar?.datetime
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {timeStampDateToOnlytimewithoutTimeDiff(
                                          row.end_calendar?.datetime
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {getTimeDIff(
                                          row.end_calendar.datetime,
                                          row.start_calendar.datetime
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <OnlineLinkLocation data={row} />
                                        {/* {row.location.name === "" ||
                                        row.location.name === null ||
                                        row.location.name === undefined ? (
                                          " "
                                        ) : (
                                          <span>{row.location.name}</span>
                                        )} */}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <span
                                          className="openassignemntcolor"
                                          onClick={() => {
                                            props.navigate("/studentsyllabus", {
                                              state: { row },
                                            });
                                          }}
                                        >
                                          Open
                                        </span>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={ExamtimeData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}
const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ExamTime));
