import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Holiday from "../../Common/Holiday/Holiday.jsx";

function ManageLocation(props) {
  // gloabl institution id
  let institution_id = parseInt(props.data.institution_id);
  const [checkvalue, setCheckvalue] = useState(true);

  // const HandleCheckbox = (e) => {
  //   setCheckvalue(e.target.checked);

  // };
  //name instituition
  let Organization_Name = props.data.institution_name;

  const client = useApolloClient();
  // create location api
  const [Create_Location, {}] = useMutation(Service.Create_Location);
  // delete location api
  const [Delete_Location, {}] = useMutation(Service.Delete_Location);
  // edit location api
  const [Edit_Location, {}] = useMutation(Service.Edit_Location);
  const [isUpdated, setIsUpdated] = useState(false);
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [opendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);

  const [nameedit, setNameedit] = useState("");
  const [deleteid, setDeleteid] = useState("");
  const [locationid, setlocationid] = useState("");
  const [name, setname] = useState("");
  const [deletelocation, setdeletelocation] = useState("");
  const [managelocation, setmanagelocation] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [editCheckvalue, setEditCheckvalue] = useState(false);
  //error
  const [nameerror, setnameerror] = useState(false);
  const [editnameerror, seteditnameerror] = useState(false);

  const handleaddMangeopen = () => {
    setaddMangeopen(true);
  };
  const closeaddMangeopen = () => {
    setaddMangeopen(false);
  };

  const OpenEditQuery = (data) => {
    setNameedit(data.name);
    setlocationid(data.id);
    setOpendailogtoEdit(true);
    setEditCheckvalue(data.mode === "Online" ? true : false);
  };

  const DeleteDailogBox = (data) => {
    setDeleteid(data.id);
    setdeletelocation(data.name);
    setopendialogConfimation(true);
  };

  //get location data
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Location,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          let temp = [];

          for (
            let index = 0;
            index < response.data.getLocation.length;
            index++
          ) {
            temp.push({
              name: response.data.getLocation[index].name,
              mode: response.data.getLocation[index].mode,
              show: true,
              id: response.data.getLocation[index].id,
            });
          }
          setisloading(false);
          setmanagelocation(temp);
          setname("");
          setaddMangeopen(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log(" location arr try and catch", error);
    }
    return () => setIsUpdated(false);
  }, [client, isUpdated, institution_id]);

  // create location
  const createLocation = () => {
    if (!blankValidator(name)) {
      setnameerror(true);
      return;
    }

    let mode;
    if (checkvalue) {
      mode = "Online";
    } else {
      mode = "Offline";
    }

    try {
      setisloading(true);
      Create_Location({
        variables: { institution_id, name, mode },
      }).then(
        (response) => {
          showNotificationMsz(response.data.addLocation.message, "success");
          setCheckvalue(false);
          setname("");
          setisloading(false);
          setIsUpdated(!isUpdated);
        },
        (error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  //delete location
  const deletelocationHandler = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_Location({
        variables: { id },
      }).then(
        (response) => {
          if (response.data.deleteLocation.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(response.data.deleteLocation.message, "success");

          setopendialogConfimation(false);
          setisloading(false);
          setIsUpdated(!isUpdated);
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  //updata location
  const UpdateManageLocation = () => {
    let id = parseInt(locationid);
    if (!blankValidator(nameedit)) {
      seteditnameerror(true);
      return;
    }
    let mode;
    if (editCheckvalue) {
      mode = "Online";
    } else {
      mode = "Offline";
    }
    try {
      setisloading(true);
      Edit_Location({
        variables: { id, name: nameedit, mode: mode },
      }).then(
        (response) => {
          showNotificationMsz(response.data.updateLocation.message, "success");

          setOpendailogtoEdit(false);
          setisloading(false);
          setIsUpdated(!isUpdated);
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const sortedList = managelocation.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Manage Rooms</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {addMangeopen === false ? (
                          <div className="d-flex" onClick={handleaddMangeopen}>
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add New Room
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div
                                          className="text-right"
                                          onClick={closeaddMangeopen}
                                        >
                                          <span className="iconstoaddfee">
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        </div>

                                        <Grid
                                          item
                                          md={12}
                                          className="grid_main_div"
                                        >
                                          <Grid item md={6}>
                                            <div className="text_filed_heading">
                                              Room
                                            </div>
                                            <div className=" mt-1">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Room Name"
                                                value={name}
                                                onChange={(e) => {
                                                  setnameerror(false);
                                                  setname(e.target.value);
                                                }}
                                              />
                                              {nameerror && (
                                                <span className="text-danger">
                                                  Enter the Room Name
                                                </span>
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            md={6}
                                            style={{ marginTop: 34 }}
                                          >
                                            {props.data.online_class ===
                                              "Enable" && (
                                              <div className="d-flex ml-3">
                                                <CheckBoxComponent
                                                  cssClass="e-custom"
                                                  checked={checkvalue}
                                                  onChange={(e) => {
                                                    setCheckvalue(
                                                      e.target.checked
                                                    );
                                                  }}
                                                />
                                                <p style={{ marginLeft: 7 }}>
                                                  Online
                                                </p>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className="mb-2 ">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={createLocation}
                                        >
                                          Create
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin cardheight_overflow">
                        {/* <div className="ml-3 mt-2 Heading_bold">Role List</div> */}

                        <Card className="cardvisible_border mb-2">
                          <div className="card_admissiondetails_height">
                            <div className="textfiled_margin">
                              <div className="">
                                <Grid className="grid_main_div">
                                  <Grid item md={5}>
                                    <div className=" mt-1 mb-1">
                                      <p>Room Name</p>
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className=" mt-1 mb-1">Mode</div>
                                  </Grid>

                                  <Grid item md={4}>
                                    <p>Action</p>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </Card>
                        {sortedList.length ? (
                          sortedList.map((item, index) => (
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={5}>
                                        <div className=" mt-1 mb-1">
                                          <input
                                            type="text"
                                            className="form-control input_border_none_Color"
                                            placeholder="Name"
                                            // autoComplete="off"
                                            value={item.name}
                                            disabled={true}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={3}>
                                        <div className=" mt-1 mb-1">
                                          {item.mode === "Online" ? (
                                            <i className="fa fa-globe iconstoaddfee "></i>
                                          ) : (
                                            <i
                                              className="fa fa-globe"
                                              style={{ fontSize: 20 }}
                                            ></i>
                                          )}
                                          {/* {(item.mode !== null && item.mode !== undefined) && item.mode} */}
                                        </div>
                                      </Grid>

                                      <Grid item md={4}>
                                        <div className="d-flex text-align-center">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1 justify-content-end"></span>
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Location Name</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    autoComplete="off"
                    value={nameedit}
                    onChange={(e) => {
                      seteditnameerror(false);
                      setNameedit(e.target.value);
                    }}
                  />
                  {editnameerror && (
                    <span className="text-danger">Enter the Location Name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
            <div className="">
              <Grid className="grid_main_div">
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                  {props.data.online_class === "Enable" && (
                    <div className="d-flex mt-2">
                      <CheckBoxComponent
                        cssClass="e-custom"
                        checked={editCheckvalue}
                        onChange={(e) => {
                          setEditCheckvalue(e.target.checked);
                        }}
                      />
                      <p style={{ marginLeft: 7 }}>Online</p>
                    </div>
                  )}
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </div>
          </div>
          <Grid className="grid_main_div mt-4  mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateManageLocation}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Location : {deletelocation}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deletelocationHandler(deleteid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageLocation));
