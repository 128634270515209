import React from "react";
import "./Report.css";

import ClassTeacherList from "./ClassTeacherList.jsx";
import ClassStudentList from "./ClassStudentList.jsx";
import ReportExamination from "./ReportExamination";
import TimeTable from "./TimeTable.jsx";
import AdmissionInterestReport from "./AdmissionInterestReport";
import EmployeeFormReport from "./EmployeeFormReport";

import StudentInvoice from "../../Admin/FeeModule/StudentInvoice";
import StudentInvoiceReport from "./StudentInvoiceReport";
import AttendanceViewDayAdminStudent from "../Attendance/AttendanceViewDayAdminStudent";
import StudentTransportationPop from "../Onboarding/StudentTransportationPop";
import AdminEmployeeDetailForm from "../Onboarding/AdminEmployeeDetailForm";
import StudentAttendance from "../Attendance/StudentAttendance";
import StaffTimetable from "./StaffTimetable";
import AdminStudentDetailForm from "../../Admin/Onboarding/AdminStudentDetailForm";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import StaffAttendance from "../Attendance/StaffAttendance";
import { getFullName } from "../../../utils/Validation";
const ReportDetails = (props) => {
  console.log("props=======", props);
  const { institution_name } = props.ADMIN_DATA;

  const reportData = props.location.state.json;

  switch (reportData.ReportType) {
    case "Timetable":
      return (
        <TimeTable
          {...props}
          sectionId={reportData.SectionId}
          seeker={reportData.seeker}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          institution_name={institution_name}
        />
      );
    case "Staff Timetable":
      return (
        <StaffTimetable
          {...props}
          employeeId={reportData.SectionId}
          navigate={props.navigate}
        />
      );

    case "TeacherList":
      return (
        <ClassTeacherList
          {...props}
          sectionId={reportData.SectionId}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          institution_name={institution_name}
        />
      );
    case "Examinations":
      return (
        <ReportExamination
          {...props}
          data={reportData}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          institution_name={institution_name}
          studentClassId={reportData.studentClassId}
          studentSectionId={reportData.SectionId}
        />
      );
    case "StudentList": // for all student list
      return (
        <ClassStudentList
          {...props}
          sectionId={reportData.SectionId}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          institution_name={institution_name}
          navigate={props.navigate}
        />
      );
    case "StaffDetails": // for all staff
      return (
        <EmployeeFormReport
          {...props}
          designationId={reportData.designationId}
          departmntId={reportData.departmntId}
          designation_Name={reportData.designation_Name}
          department_Name={reportData.department_Name}
          institution_name={institution_name}
          navigate={props.navigate}
        />
      );
    case "Staff Detail": // for single staff
      return (
        <AdminEmployeeDetailForm
          {...props}
          empId={reportData.SectionId}
          navigate={props.navigate}
          institution_name={institution_name}
        />
      );
    case "Student_Details": // for single student details
      return (
        <AdminStudentDetailForm
          {...props}
          userId={reportData.userId}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          student_Name={reportData.student_Name}
          institution_name={institution_name}
        />
      );
    case "AdmissionEnquery":
      return <AdmissionInterestReport {...props} />;
    case "Fees":
      return (
        <StudentInvoice {...props} from={"report"} />
        // <StudentInvoiceReport
        //   id={reportData.userId}
        //   class_Name={reportData.class_Name}
        //   section_Name={reportData.section_Name}
        //   student_Name={reportData.student_Name}
        //   institution_name={institution_name}
        // />
      );
    case "Attendance":
      return (
        <AttendanceViewDayAdminStudent
          {...props}
          id={reportData.SectionId}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          student_Name={reportData.student_Name}
          institution_name={institution_name}
        />
      );
    case "Class_Attendance":
      return (
        <StudentAttendance
          {...props}
          studentId={reportData.userId}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          student_Name={reportData.student_Name}
          institution_name={institution_name}
        />
      );
    case "Transport_Attendance":
      return (
        <StudentTransportationPop
          {...props}
          id={reportData.userId}
          class_Name={reportData.class_Name}
          section_Name={reportData.section_Name}
          student_Name={reportData.student_Name}
          institution_name={institution_name}
        />
      );
    case "Event Attendance": //for staff
      return (
        <StaffAttendance
          {...props}
          student_Name={getFullName(reportData?.staff_Name[0]?.name)}
          institution_name={institution_name}
          studentId={reportData?.staff_Name[0]?.id}
          reportData={reportData}
          userType={reportData.staff_Name[0].user_type}
        />
      );
    case "Event attendance": //for student
      return (
        <StaffAttendance
          {...props}
          student_Name={reportData?.student_Name}
          institution_name={institution_name}
          studentId={reportData?.userId}
          userType={"STUDENT"}
        />
      );
    case "AllStaffAttendance": //for all staff and all section student
      return (
        <StaffAttendance
          {...props}
          student_Name={reportData?.student_Name}
          institution_name={institution_name}
          studentId={reportData?.studentClassId}
          userType={"STUDENT"}
        />
      );
    default:
      return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(ReportDetails);
