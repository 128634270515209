import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Report.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";

//------------api calls-----------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { getFullName } from "../../../utils/Validation.js";

const EmployeeFormReport = ({
  designationId,
  departmntId,
  department_Name,
  institution_name,
  navigate,
}) => {
  //-------------------global id----------------------
  let designation_id = parseInt(designationId);
  let department_id = parseInt(departmntId);
  //-------------------global id----------------------
  const client = useApolloClient();
  const classes = useStyles();
  //-------------------local state----------------------
  const [search, setsearch] = useState("");
  const [staffDataArr, setStaffDataArr] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isloading, setisloading] = useState(false);
  //-------------------methods----------------------
  useEffect(() => {
    getAllStaffDetails();
  }, []);

  const filterArr = staffDataArr.filter((event) => {
    return (
      getFullName(event.name).toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });
  const getAllStaffDetails = async () => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.Get_All_Staff_Details,
          variables: {
            department_id,
            designation_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setStaffDataArr(
            response.data.getEmployeeByDepartmentAndDesignationId
          );
        })
        .catch((error) => {
          setisloading(false);
          console.log(" staff details catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("staff details  arr try and catch", error);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showDetails = (staffId) => {
    navigate("/adminemployeedetailform", {
      state: {
        pagetype: "edit",
        staffId,
      },
    });
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold">Report</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-4 mb-4">
                        <Grid item md={8}>
                          <div className="List_heading_formatting">
                            All Staff - {department_Name}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="d-flex">
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                  setsearch(e.target.value);
                                }}
                              />
                            </div>
                            <div className="btn_add mt-1 ml-2">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <div className=" table_foramtitng mt-3 mb-2">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Profile
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Teacher Name
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  ID
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Gender
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Joining Date
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header"
                                >
                                  Blood Group
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? filterArr.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : filterArr
                              ).map((row, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell align="left">
                                    <img
                                      src={`${Service.baseUrl}/${row.photo?.path}`}
                                      alt=""
                                      className="img_formatting imageCursor"
                                      onClick={() => showDetails(row.id)}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {getFullName(row.name)}
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="left">
                                    {row.login?.display_id}
                                  </StyledTableCell>

                                  <StyledTableCell align="left">
                                    {row.gender && row.gender}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {row.joining_date && row.joining_date}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {row.blood_group && row.blood_group}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterArr.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default HOC1(EmployeeFormReport);
