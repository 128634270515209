import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import "./Onboarding.css";
import Loder from "../../Loder/Loder.jsx";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { baseUrl } from "../../../services/index.js";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  emailValidator,
  showNotificationMsz,
  dateToDatedashtoDDMMYY,
  serverDateFormat,
  dateFormatToShow,
  getMinAndSecfromDate,
} from "../../../utils/Validation.js";
import { AttainedArr } from "../../../utils/LocalData";
import { useMutation } from "@apollo/client";
import APIHooks from "../../../api manager/CommonAPI";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Multiselect from "multiselect-react-dropdown";
import Expand from "react-expand-animated";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ReCAPTCHA from "react-google-recaptcha";

const config = require("../../../config/default.json");

function PtTeacherFormDetails({ ADMIN_DATA, location, empId, navigate }) {
  //----------------------------global id----------------------------------
  const { institution_id, institution_name, locale, currency_code } =
    ADMIN_DATA;
  const empDetailHook = APIHooks();
  let staffId = location?.state?.staffId;
  //----------------------------api calls----------------------------------
  const classes = useStyles();
  const client = useApolloClient();
  //----------------------------api calls----------------------------------

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [qualificationArray, setQualificationArray] = useState([
    { value: "1", name: "2nd PUC" },
    { value: "2", name: "Bachelor of Architecture (B.Arch.)" },
    { value: "3", name: "Bachelor of Arts (B.A.)" },
    { value: "4", name: "Bachelor of Ayurvedic Medicine & Surgery (B.A.M.S.)" },
    { value: "5", name: "Bachelor of Business Administration (B.B.A.)" },
    { value: "6", name: "Bachelor of Commerce (B.Com.)" },
    { value: "7", name: "Bachelor of Computer Applications (B.C.A.)" },
    {
      value: "8",
      name: "Bachelor of Computer Science (B.Sc. (Computer Science))",
    },
    { value: "9", name: "Bachelor of Dental Surgery (B.D.S.)" },
    { value: "10", name: "Bachelor of Design (B.Des. - B.D.)" },
    { value: "11", name: "Bachelor of Education (B.Ed.)" },
    { value: "12", name: "Bachelor of Engineering (B.E.)" },
    { value: "13", name: "Bachelor of Technology (B.Tech.)" },
    { value: "14", name: "Bachelor of Fine Arts (BFA - BVA)" },
    {
      value: "15",
      name: "Bachelor of Fisheries Science (B.F.Sc. - B.Sc. (Fisheries))",
    },
    {
      value: "16",
      name: "Bachelor of Home Science (B.A. - B.Sc. (Home Science))",
    },
    {
      value: "17",
      name: "Bachelor of Homeopathic Medicine and Surgery (B.H.M.S.)",
    },
    { value: "18", name: "Bachelor of Laws (L.L.B.)" },
    { value: "19", name: "Bachelor of Library Science (B.Lib. - B.Lib.Sc.)" },
    { value: "20", name: "Bachelor of Mass Communications (B.M.C. - B.M.M.)" },
    {
      value: "21",
      name: "Bachelor of Medicine and Bachelor of Surgery (M.B.B.S.)",
    },
    { value: "22", name: "Bachelor of Nursing (B.Sc. (Nursing))" },
    { value: "23", name: "Bachelor of Pharmacy (B.Pharm.)" },
    { value: "24", name: "Bachelor of Physical Education (B.P.Ed.)" },
    { value: "25", name: "Bachelor of Physiotherapy (B.P.T.)" },
    { value: "26", name: "Bachelor of Science (B.Sc.)" },
    { value: "27", name: "Bachelor of Social Work (BSW or B.A. (SW))" },
    {
      value: "28",
      name: "Bachelor of Veterinary Science & Animal Husbandry (B.V.Sc.)",
    },
    { value: "29", name: "Doctor of Medicine (M.D.)" },
    {
      value: "30",
      name: "Doctor of Medicine in Homoeopathy (M.D. (Homoeopathy))",
    },
    {
      value: "31",
      name: "Master in Home Science (M.A. - M.Sc. (Home Science))",
    },
    { value: "32", name: "Master of Architecture (M.Arch.)" },
    { value: "33", name: "Master of Arts (M.A.)" },
    { value: "34", name: "Master of Business Administration (M.B.A.)" },
    { value: "35", name: "Master of Chirurgiae (M.Ch.)" },
    { value: "36", name: "Master of Commerce (M.Com.)" },
    { value: "37", name: "Master of Computer Applications (M.C.A.)" },
    { value: "38", name: "Master of Dental Surgery (M.D.S.)" },
    { value: "39", name: "Master of Design (M.Des. - M.Design.)" },
    { value: "40", name: "Master of Education (M.Ed.)" },
    {
      value: "41",
      name: "Master of Engineering - Master of Technology (M.E./M.Tech.)",
    },
    { value: "42", name: "Master of Fine Arts (MFA - MVA)" },
    {
      value: "43",
      name: "Master of Fishery Science (M.F.Sc. - M.Sc. (Fisheries))",
    },
    { value: "44", name: "Master of Laws (L.L.M.)" },
    { value: "45", name: "Master of Library Science (M.Lib. - M.Lib.Sc.)" },
    { value: "46", name: "Master of Mass Communications (M.M.C - M.M.M.)" },
    { value: "47", name: "Master of Pharmacy (M.Pharm)" },
    { value: "48", name: "Master of Philosophy (M.Phil.)" },
    { value: "49", name: "Master of Physical Education (M.P.Ed. - M.P.E.)" },
    { value: "50", name: "Master of Physiotherapy (M.P.T.)" },
    { value: "51", name: "Master of Science (M.Sc.)" },
    {
      value: "52",
      name: "Master of Science in Agriculture (M.Sc. (Agriculture))",
    },
    { value: "53", name: "Master of Social Work (M.S.W. or M.A. (SW))" },
    { value: "54", name: "Master of Surgery (M.S.)" },
    { value: "55", name: "Master of Veterinary Science (M.V.Sc.)" },
    { value: "56", name: "Doctor of Pharmacy (Pharm.D)" },
    { value: "57", name: "Doctor of Philosophy (Ph.D.)" },
    { value: "58", name: "Doctorate of Medicine (D.M.)" },
    { value: "59", name: "Master's in Environmental Pollution" },
    { value: "60", name: "BPT - Bachelors in Physiothreapist" },
    { value: "61", name: "Masters in Physiotherapist" },
    { value: "62", name: "Master in Food & Nutrition" },
    { value: "63", name: "MSc in Nutrition & Dietetics" },
    { value: "64", name: "BNYS - Bachelor of Naturopathy and Yogic Sciences" },
    { value: "65", name: "BHMS - Bachelors of Homeopathic Medicine & Surgery" },
    { value: "66", name: "M Phil in Food Service Management & Dietitian" },
    { value: "67", name: "BAMS - Bachelor in Ayurveda, Medicine & Surgery" },
    {
      value: "68",
      name: "Post Graduation Diploma in Dietetics, Health & Nutrition",
    },
    { value: "69", name: "Masters in Psychology" },
    { value: "70", name: "PhD in Psychology" },
    { value: "71", name: "Diploma in Psychological Medicine" },
    { value: "72", name: "M Phil in Clinical Psychology" },
    { value: "73", name: "Post Graduation in Psychology" },
    { value: "74", name: "MPC - Masters in Psychotherapy & Counselling" },
  ]);
  const [qualificationValue, setQualificationValue] = useState("");
  const [uploadCertificate, setUploadCertificate] = useState("Add Attachment");
  const [teachingExpArray, setTeachingExpArray] = useState([
    {
      value: "Primary ( class 1 - 6)",
      name: "Primary ( class 1 - 6)",
    },
    {
      value: "Secondary (Class 7 - 10)",
      name: "Secondary (Class 7 - 10)",
    },
    {
      value: "Higher Secondary (Class 11 - 12)",
      name: "Higher Secondary (Class 11 - 12)",
    },
    {
      value: "Graduation",
      name: "Graduation",
    },
    {
      value: "Post Graduation",
      name: "Post Graduation",
    },
  ]);
  const [teachExpValue, setTeachExpValue] = useState("");
  const [teachExpValueError, setTeachExpValueError] = useState(false);
  const [LanguageSpeak, setLanguageSpeak] = useState([]);
  const [LanguageSpeakError, setLanguageSpeakError] = useState(false);
  const boardsListArray = [
    {
      id: 1,
      name: "Central Board of Secondary Education (CBSE)",
    },
    {
      id: 2,
      name: "Council for The Indian School Certificate Examinations (ICSE)",
    },
    {
      id: 3,
      name: "National Institute of Open Schooling (NIOS)",
    },
    {
      id: 4,
      name: "Aligarh Muslim University, Aligarh",
    },
    {
      id: 5,
      name: "Andhra Pradesh Open School Society",
    },
    {
      id: 6,
      name: "Assam Sanskrit Board",
    },
    {
      id: 7,
      name: "Banasthali Vidyapith, Rajasthan",
    },

    {
      id: 8,
      name: "Bihar Board of Open Schooling And Examination",
    },
    {
      id: 9,
      name: "Bihar Sanskrit Shiksha Board",
    },
    {
      id: 10,
      name: "Bihar School Examination Board",
    },
    {
      id: 11,
      name: "Bihar State Madrasa Education Board",
    },
    {
      id: 12,
      name: "Board of High School And Intermediate Education Uttar Pradesh",
    },
    {
      id: 13,
      name: "Board of School Education Haryana",
    },
    {
      id: 14,
      name: "Board of Secondary Education Andhra Pradesh",
    },
    {
      id: 15,
      name: "Board of Secondary Education Assam",
    },
    {
      id: 16,
      name: "Board of Secondary Education, Odisha",
    },
    {
      id: 17,
      name: "Board of Secondary Education, Rajasthan",
    },
    {
      id: 18,
      name: "Chhatisgarh State Open School",
    },
    {
      id: 19,
      name: "Chhattisgarh Board of Secondary Education",
    },
    {
      id: 20,
      name: "Chhattisgarh Sanskrit Board",
    },
    {
      id: 21,
      name: "Directorate of Army Education, New Delhi",
    },
    {
      id: 22,
      name: "Directorate of Government Examinations, Tamil Nadu",
    },
    {
      id: 23,
      name: "Goa Board of Secondary And Higher Secondary Education",
    },
    {
      id: 24,
      name: "Gujarat Secondary And Higher Secondary Education Board",
    },
    {
      id: 25,
      name: "Gurukul Kangri Vishwavidyalaya, Haridwar",
    },
    {
      id: 26,
      name: "Haryana Open School",
    },
    {
      id: 27,
      name: "Himachal Pradesh Board of School Education",
    },
    {
      id: 28,
      name: "Himachal Pradesh State Open School",
    },
    {
      id: 29,
      name: "International Baccalaureate (IB)",
    },
    {
      id: 30,
      name: "IGCSE",
    },
    {
      id: 31,
      name: "Jamia Miliya Hamdard University",
    },
    {
      id: 32,
      name: "Jamia Miliya Islamia, New Delhi",
    },
    {
      id: 33,
      name: "Jammu and Kashmir State Board of School Education",
    },
    {
      id: 34,
      name: "Jammu and Kashmir State Open School",
    },
    {
      id: 35,
      name: "Jharkhand Academic Council Ranchi",
    },
    {
      id: 36,
      name: "Karnataka Secondary Education Examination Board",
    },
    {
      id: 37,
      name: "Kerala Board of Public Examinations",
    },
    {
      id: 38,
      name: "Madhya Pradesh Board of Secondary Education",
    },
    {
      id: 39,
      name: "Madhya Pradesh State Open School",
    },
    {
      id: 40,
      name: "Maharashtra State Board of Secondary And Higher Secondary Education",
    },
    {
      id: 41,
      name: "Manipur Board of Secondary Education",
    },
    {
      id: 42,
      name: "Meghalaya Board of School Education",
    },
    {
      id: 43,
      name: "Mizoram Board of School Education",
    },
    {
      id: 44,
      name: "Nagaland Board of School Education",
    },
    { id: 45, name: "Punjab School Education Board" },
    { id: 46, name: "Rajasthan State Open School" },
    { id: 47, name: "Rashtriya Sanskrit Sansthan, New Delhi" },
    {
      id: 48,
      name: "State Council for Open and Lifelong Education - Kerala",
    },
    { id: 49, name: "State Madrassa Education Board Assam" },
    { id: 50, name: "Tamil Nadu Board of Secondary Education" },
    { id: 51, name: "Tamilnadu Council for Open And Distance Learning" },
    { id: 52, name: "Tripura Board of Secondary Education" },
    { id: 53, name: "Uttarakhand Board of School Education" },
    { id: 54, name: "Uttrakhand Vidyalayi Shiksha Parishad" },
    { id: 55, name: "Uttranchal Shiksha Avam Pariksha Parishad" },
    { id: 56, name: "West Bengal Board of Madrasa Education" },
    { id: 57, name: "West Bengal Board of Secondary Education" },
    { id: 58, name: "The West Bengal Council of Rabindra Open Schooling " },
    { id: 59, name: "International Board" },
    { id: 60, name: "Board of Secondary Education Telangana" },
    { id: 61, name: "UP Board of Madrasa Education" },
    { id: 62, name: "Maharshi Patanjali Sanskrit Sansthan Bhopal" },
    { id: 63, name: "Visva Bharati University" },
    { id: 64, name: "Dayalbagh Education Institute" },
    { id: 65, name: "Gurukul Mahavidyalaya Jwalapur Haridwar" },
    { id: 66, name: "Uttar Pradesh Board of Secondary Sanskrit Education" },
    { id: 67, name: "Jharkhand Secondary Examination Board" },
    { id: 68, name: "Sampoornanand Sanskrit Vishwavidyalaya" },
    { id: 69, name: "Telangana Open School Society" },
    { id: 70, name: "Uttarakhand Sanskrit Shiksha Board" },
  ];
  const [educationBoard, setEducationBoard] = useState([]);
  const [educationBoardError, setEducationBoardError] = useState(false);
  const [subjectListArray, setSubjectListArray] = useState([
    { name: "Accountancy", id: 1 },
    { name: "Arabic", id: 2 },
    { name: "Assamese", id: 3 },
    { name: "Bengali", id: 4 },
    { name: "Bharatiya Darshan", id: 5 },
    { name: "Biology", id: 6 },
    { name: "Business Studies", id: 7 },
    { name: "Chemistry", id: 8 },
    { name: "Computer Science", id: 9 },
    { name: "Data Entry Operations", id: 10 },
    { name: "Economics", id: 11 },
    { name: "English", id: 12 },
    { name: "Environmental Science", id: 13 },
    { name: "Gujarati", id: 14 },
    { name: "Hindi", id: 15 },
    { name: "History", id: 16 },
    { name: "Home Science", id: 17 },
    { name: "Indian Culture & Heritage", id: 18 },
    { name: "Introduction to Law", id: 19 },
    { name: "Kannada", id: 20 },
    { name: "Library and Information Science", id: 21 },
    { name: "Malayalam", id: 22 },
    { name: "Marathi", id: 23 },
    { name: "Mass Communication", id: 24 },
    { name: "Mathematics", id: 25 },
    { name: "Nepali", id: 26 },
    { name: "Odia", id: 27 },
    { name: "Painting", id: 28 },
    { name: "Persian", id: 29 },
    { name: "Physics", id: 30 },
    { name: "Political Science", id: 31 },
    { name: "Psychology", id: 32 },
    { name: "Punjabi", id: 33 },
    { name: "Sanskrit", id: 34 },
    { name: "Sanskrit Sahitya", id: 35 },
    { name: "Sanskrit Vyakaran", id: 36 },
    { name: "Science and Technology", id: 37 },
    { name: "Social Science", id: 38 },
    { name: "Sociology", id: 39 },
    { name: "Tamil", id: 40 },
    { name: "Telugu", id: 41 },
    { name: "Tourism", id: 42 },
    { name: "Urdu", id: 43 },
    { name: "Veda Adhyan", id: 44 },
  ]);
  const [subjectValue, setSubjectValue] = useState([]);
  const [subjectValueError, setSubjectValueError] = useState(false);
  const [sessionRate, setSessionRate] = useState("");
  const [sessionRateError, setSessionRateError] = useState(false);
  const [timeSlotArray, setTimeSlotArray] = useState([
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ]);
  const [timeSlotValue, setTimeSlotValue] = useState("");
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isLoaing, setIsLoaing] = useState(false);
  const [expProfileSummary, setExpProfileSummary] = useState("");
  const [expProfileSummaryError, setExpProfileSummaryError] = useState(false);
  const [filename, setFileName] = useState("Add Attachment");
  const [filenameError, setFileNameError] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [dialogOpen, setdialogOpen] = useState(false);
  const [shortCode, setshortCode] = useState("");
  const [languagesArray, setLanguagesArray] = useState([
    { name: "Assamese", id: 1 },
    { name: "Bengali", id: 2 },
    { name: "Bodo", id: 3 },
    { name: "Dogri", id: 4 },
    { name: "English", id: 5 },
    { name: "Gujarati", id: 6 },
    { name: "Hindi", id: 7 },
    { name: "Kannada", id: 8 },
    { name: "Kashmiri", id: 9 },
    { name: "Konkani", id: 10 },
    { name: "Malayalam", id: 11 },
    { name: "Manipuri", id: 12 },
    { name: "Marathi", id: 13 },
    { name: "Maithili", id: 14 },
    { name: "Nepali", id: 15 },
    { name: "Oriya", id: 16 },
    { name: "Punjabi", id: 17 },
    { name: "Sanskrit", id: 18 },
    { name: "Santhali", id: 19 },
    { name: "Sindhi", id: 20 },
    { name: "Tamil", id: 21 },
    { name: "Telugu", id: 22 },
    { name: "Urdu", id: 23 },
  ]);
  const [formDeclare, setFormDeclare] = useState(false);
  const [timeSlotExpand, setTimeSlotExpand] = useState(false);
  const temp = new Date();
  const [start_time, setStart_time] = useState(temp);
  const [end_time, setEnd_time] = useState(temp);
  const [weekDay, setWeekDay] = useState("");
  const [addRecurrenceArray, setAddRecurrenceArray] = useState([]);
  const [weekDayError, setWeekDayError] = useState(false);
  const [editSheduleOpen, setEditSheduleOpen] = useState(false);
  const [updateIndex, setUpdateIndex] = useState("");
  const [startDateErr, setStartDateErr] = useState(false);
  const [totalExperience, setTotalExperience] = useState("");
  const [totalExperienceError, setTotalExperienceError] = useState(false);
  const [currencyCode, setcurrencyCode] = useState("");
  const [curencyCodeValue, setCurencyCodeValue] = useState("");
  const [phoneWithOutDialCode, setPhoneWithOutDialCode] = useState("");
  const [getPhoto, setGetPhoto] = useState("");
  const [getCertificate, setGetCertificate] = useState("");
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);

  //-------------------------methods-----------------------
  //   useEffect(() => {
  //     onloadData();
  //   }, []);

  //   const onloadData = async () => {
  //     window.scrollTo(0, 0);
  //     await empDetailHook.getDepartmentAPI(parseInt(institution_id));
  //     await empDetailHook.getDesignationAPI(parseInt(institution_id));
  //   };

  useEffect(() => {
    getStaffData();
    setcurrencyCode(locale.toLowerCase());
    setCurencyCodeValue(currency_code);
  }, []);

  const getStaffData = async () => {
    setIsLoaing(true);
    let id;
    if (staffId) {
      id = staffId;
    } else if (empId) {
      id = empId;
    }
    if (id) {
      try {
        const { data } = await client.query({
          query: Service.Get_Id_based_Employee_details,
          variables: {
            id: parseInt(id),
          },
        });

        if (data.getEmployeeById) {
          const {
            status,
            login,
            tax_identity,
            photo,
            designation,
            email,
            name,
            department,
            phone,
            joining_date,
            gender,
            birth_date,
            currentAddress,
            permanentAddress,
            blood_group,
            nationality,
            tax,
            marital_status,
            national_identity,
            religion,
            fatherName,
            motherName,
            spouseName,
            passport_identity,
            education_qualifications,
            passport,
            certificate,
            birth_cert,
            others,
            experience_level,
            boards,
            total_experience,
            background,
            languages,
            teacher_pricings,
            teacher_availabilities,
          } = data.getEmployeeById;
          setFirstName(name?.first_name);
          setLastName(name?.middle_name);
          setPhoneNo(phone);
          setEmailId(email);
          setTotalExperience(total_experience);
          setTeachExpValue(experience_level);
          setEducationBoard(boards);
          setExpProfileSummary(background);
          setLanguageSpeak(languages);
          setSessionRate(teacher_pricings[0]?.amount);
          setGetPhoto(photo);
          setGetCertificate(certificate);
          setQualificationValue(education_qualifications[0]?.qualification);
          if (teacher_availabilities) {
            setTimeSlotExpand(true);
            let tempValue = [];
            teacher_availabilities.map((row) => {
              tempValue.push({
                week_day: +row.day,
                start_time: row.start_time,
                end_time: row.end_time,
              });
            });
            setAddRecurrenceArray(tempValue);
          }
        }
      } catch (error) {
        setIsLoaing(false);
        console.log("error", error);
        showNotificationMsz(error, "danger");
      }
    }
    setIsLoaing(false);
  };
  // select language
  const onSelect = (selectedList, selectedItem) => {
    setLanguageSpeak([...LanguageSpeak, selectedItem]);
    setLanguageSpeakError(false);
  };

  const onRemove = (selectedList, removedItem) => {
    const newTodos = LanguageSpeak.filter((item) => item.id !== removedItem.id);
    setLanguageSpeak(newTodos);
  };
  // select Board
  const onSelectBoard = (selectedList, selectedItem) => {
    setEducationBoard([...educationBoard, selectedItem]);
    setEducationBoardError(false);
  };
  const onRemoveBoard = (selectedList, removedItem) => {
    const newTodos = educationBoard.filter(
      (item) => item.id !== removedItem.id
    );
    setEducationBoard(newTodos);
  };
  // select subject
  const onSelectSubject = (selectedList, selectedItem) => {
    setSubjectValue([...subjectValue, selectedItem]);
    setSubjectValueError(false);
  };
  const onRemoveSubject = (selectedList, removedItem) => {
    const newTodos = subjectValue.filter((item) => item.id !== removedItem.id);
    setSubjectValue(newTodos);
  };
  const certificateUploadHandler = async (e, type) => {
    const file = e.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    setIsLoaing(true);
    setFileNameError(false);

    try {
      let response = await fetch(`${baseUrl}/uploadFile/image`, {
        method: "POST",
        body: formData,
      });
      if (response.status === 200) {
        let data = await response.text();
        console.log("data ", JSON.parse(data));
        const obj = {
          path: JSON.parse(data).path,
          filename: JSON.parse(data).filename,
          type: JSON.parse(data).type,
          name: JSON.parse(data).name,
        };
        console.log("obj", obj);
        setUploadCertificate(obj);
        // handle data
      }
      setIsLoaing(false);
      // fetch(`${baseUrl}/uploadFile/image`, { method: "POST", body: formData })
      //   .then((data) => {
      //     console.log("response======", data);
      //     setIsLoaing(false);
      //     const obj = {
      //       path: response.data.path,
      //       filename: response.data.filename,
      //       type: response.data.type,
      //       name: response.data.name,
      //     };
      //     console.log("obj", obj);
      //     setUploadCertificate(obj);
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //     setIsLoaing(false);
      //     // showNotificationMsz(error.message, "danger");
      //   });
    } catch (error) {
      setIsLoaing(false);
      console.log("catch and try error", error);
    }
  };

  const updateTeacherProfileHandle = async () => {
    if (!firstName) {
      setFirstNameError(true);
    }
    if (!lastName) {
      setLastNameError(true);
    }
    const emailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailId.match(emailReg)) {
      setEmailIdError(true);
    }
    if (!teachExpValue || teachExpValue == -1) {
      setTeachExpValueError(true);
    }
    if (LanguageSpeak.length == 0) {
      setLanguageSpeakError(true);
    }
    if (educationBoard.length == 0) {
      setEducationBoardError(true);
    }
    if (!totalExperience) {
      setTotalExperienceError(true);
    }
    if (subjectValue.length == 0) {
      setSubjectValueError(true);
    }
    if (!sessionRate) {
      setSessionRateError(true);
    }
    if (!expProfileSummary) {
      setExpProfileSummaryError(true);
    }
    // if (!filename || filename === "Add Attachment") {
    //   setFileNameError(true);
    // }
    if (
      !firstName ||
      !lastName ||
      !emailId.match(emailReg) ||
      teachExpValue === -1 ||
      LanguageSpeak.length == 0 ||
      educationBoard.length == 0 ||
      !totalExperience ||
      subjectValue.length == 0 ||
      !sessionRate ||
      !expProfileSummary
      // !filename ||
      // filename === "Add Attachment"
    ) {
      return;
    }
    console.log(" update payload", {
      firstName: firstName,
      lastName: lastName,
      email: emailId,
      phoneNumber: phoneWithOutDialCode,
      currency_code: currency_code,
      country_code: locale,
      experience_level: teachExpValue,
      total_experience: totalExperience,
      background: expProfileSummary,
      languages: LanguageSpeak,
      boards: educationBoard,
      subjects: subjectValue,
      teacherPricingAmount: sessionRate,
      teacherPricingCurrencyCode: curencyCodeValue,
      availability: addRecurrenceArray,
    });
    return;
    setIsLoaing(true);
    try {
      // const { data } = await Signup_For_PrivateTeacher({
      //   variables: {
      //     firstName: firstName,
      //     lastName: lastName,
      //     email: emailId,
      //     // phoneNumber: phoneWithOutDialCode,
      //     currency_code: curencyCodeValue,
      //     // country_code: countrySymbal,
      //     experience_level: teachExpValue,
      //     total_experience: totalExperience,
      //     background: expProfileSummary,
      //     languages: LanguageSpeak,
      //     boards: educationBoard,
      //     subjects: subjectValue,
      //     teacherPricingAmount: sessionRate,
      //     teacherPricingCurrencyCode: curencyCodeValue,
      //     availability: addRecurrenceArray,
      //   },
      // });
      // if (data) {
      //   setIsLoaing(false);
      //   if (data.signupForPrivateTeacher.errorCode === 1) {
      //     Swal.fire({
      //       title: data.signupForPrivateTeacher.message,
      //     });
      //   } else {
      //     finishHandle();
      //   }
      // }
    } catch (error) {
      setIsLoaing(false);
    }
  };
  const handlestarttime = (date) => {
    setStart_time(date);
    let nexthour = new Date();
    nexthour.setHours(date.getHours() + 1);
    setEnd_time(nexthour);
  };
  const handleendtime = (date) => {
    setEnd_time(date);
  };
  const createScheduleHandler = () => {
    if (!weekDay) {
      setWeekDayError(true);
    }

    if (!weekDay) {
      return;
    }
    for (let index = 0; index < addRecurrenceArray.length; index++) {
      const element = addRecurrenceArray[index];
    }
    addRecurrenceArray.push({
      week_day: +weekDay,
      start_time: getMinAndSecfromDate(start_time),
      end_time: getMinAndSecfromDate(end_time),
    });
    setAddRecurrenceArray([...addRecurrenceArray]);
    setWeekDay("");
  };
  const editScheduleDailogHandle = (data, index) => {
    setEditSheduleOpen(true);
    setWeekDay(data.week_day);
    setStart_time(new Date("2022-2-2 " + data.start_time));
    setEnd_time(new Date("2022-2-2 " + data.end_time));
    setUpdateIndex(index);
  };
  const updateScheduleHandler = (index) => {
    addRecurrenceArray[index] = {
      week_day: weekDay,
      start_time: getMinAndSecfromDate(start_time),
      end_time: getMinAndSecfromDate(end_time),
    };
    setAddRecurrenceArray([...addRecurrenceArray]);
    setWeekDay("");
    setEditSheduleOpen(false);
  };
  const ImageUploadHandler = async (e, type) => {
    const file = e.target.files[0];
    console.log("file", file);
    const url = `${baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);
    setIsLoaing(true);
    setFileNameError(false);
    try {
      let response = await fetch(`${baseUrl}/uploadFile/image`, {
        method: "POST",
        body: fileData,
      });
      if (response.status === 200) {
        let data = await response.text();
        console.log("data ", JSON.parse(data));
        const obj = {
          path: JSON.parse(data).path,
          filename: JSON.parse(data).filename,
          type: JSON.parse(data).type,
          name: JSON.parse(data).name,
        };
        console.log("obj", obj);
        setFileName(obj);
        // handle data
      }
      setIsLoaing(false);
      // fetch(`${baseUrl}/uploadFile/image`, { method: "POST", body: fileData })
      //   .then((response) => {
      //     console.log("respsss", response);
      //     setIsLoaing(false);
      //     const obj = {
      //       path: response.data.path,
      //       filename: response.data.filename,
      //       type: response.data.type,
      //       name: response.data.name,
      //     };

      //     setFileName(obj);
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //     setIsLoaing(false);
      //     // showNotificationMsz(error.message, "danger");
      //   });
    } catch (error) {
      setIsLoaing(false);
      console.log("catch and try error", error);
    }
  };
  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Employee Profile</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="p-2">
                      <h5 className="Heading_bold">Teacher Name</h5>
                      <div className="ptFomrBorder">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="EmailPadding">
                              <div className="ptFormlabel">First name</div>
                              <input
                                type="text"
                                placeholder="First name"
                                className="form-control"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                  setFirstNameError(false);
                                }}
                              />
                              {firstNameError && (
                                <span className="text-danger">
                                  Enter First Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="EmailPadding">
                              <div className="ptFormlabel">Last name</div>
                              <input
                                type="text"
                                placeholder="Last name"
                                className="form-control"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setLastNameError(false);
                                }}
                              />
                              {lastNameError && (
                                <span className="text-danger">
                                  Enter Last Name
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">Phone</div>
                            <PhoneInput
                              className="phoneInputheight"
                              country={currencyCode}
                              countryCodeEditable={false}
                              value={phoneNo}
                              disableDropdown={true}
                              onChange={(phone, formattedValue) => {
                                // setFormatCount(
                                //   formattedValue.format.length -
                                //     formattedValue.format.replaceAll(".", "")
                                //       .length
                                // );
                                setPhoneWithOutDialCode(
                                  phone.slice(formattedValue.dialCode.length)
                                );
                                // setPhoneValueError(false);
                                // setPhoneValue(phone);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">Email</div>
                            <input
                              type="text"
                              placeholder="Email"
                              className="form-control"
                              value={emailId}
                              onChange={(e) => {
                                setEmailId(e.target.value);
                                setEmailIdError(false);
                              }}
                            />
                            {emailIdError && (
                              <span className="text-danger">
                                Enter Email Id
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className="pt-3">
                        <h5>Educational Qualification</h5>
                        <div className="ptFomrBorder">
                          <Grid className="grid_main_div">
                            <Grid item md={6}>
                              <div className="EmailPadding">
                                <div className="ptFormlabel">
                                  Highest Qualification
                                </div>
                                <select
                                  className="selectOption"
                                  value={qualificationValue}
                                  onChange={(e) => {
                                    setQualificationValue(e.target.value);
                                  }}
                                >
                                  <option value={-1}>
                                    Select Qualification{" "}
                                  </option>
                                  {qualificationArray.map((item, index) => (
                                    <option value={item.name} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">
                              Teaching experience level
                            </div>
                            <select
                              className="selectOption"
                              value={teachExpValue}
                              onChange={(e) => {
                                setTeachExpValue(e.target.value);
                                setTeachExpValueError(false);
                              }}
                            >
                              <option value={-1}>
                                Teaching experience level{" "}
                              </option>
                              {teachingExpArray.map((item, index) => (
                                <option value={item.name} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {teachExpValueError && (
                              <span className="text-danger">
                                Enter Teaching experience
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">
                              Languages you speak
                            </div>
                            <Multiselect
                              options={languagesArray} // Options to display in the dropdown
                              selectedValues={LanguageSpeak} // Preselected value to persist in dropdown
                              onSelect={onSelect} // Function will trigger on select event
                              onRemove={onRemove} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              avoidHighlightFirstOption={true}
                            />
                            {LanguageSpeakError && (
                              <span className="text-danger">
                                Enter Languages
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">
                              Board of education
                            </div>
                            <Multiselect
                              options={boardsListArray} // Options to display in the dropdown
                              selectedValues={educationBoard} // Preselected value to persist in dropdown
                              onSelect={onSelectBoard} // Function will trigger on select event
                              onRemove={onRemoveBoard} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              avoidHighlightFirstOption={true}
                            />
                            {educationBoardError && (
                              <span className="text-danger">Enter Boards</span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className="common_main_grid">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">
                              Total teaching experience
                            </div>
                            <input
                              type="text"
                              placeholder="Enter teaching experience"
                              className="form-control"
                              value={totalExperience}
                              onChange={(e) => {
                                setTotalExperience(e.target.value);
                                setTotalExperienceError(false);
                              }}
                            />
                            {totalExperienceError && (
                              <span className="text-danger">
                                Enter teaching experience
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      {/* <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">Subjects</div>
                            <Multiselect
                              options={subjectListArray} // Options to display in the dropdown
                              selectedValues={subjectValue} // Preselected value to persist in dropdown
                              onSelect={onSelectSubject} // Function will trigger on select event
                              onRemove={onRemoveSubject} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              avoidHighlightFirstOption={true}
                            />
                            {subjectValueError && (
                              <span className="text-danger">
                                Enter subjects
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid> */}
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">
                              Session Rate ({curencyCodeValue}/Hr)
                            </div>
                            <input
                              type="text"
                              placeholder="Session Rate"
                              className="form-control"
                              value={sessionRate}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return;
                                }
                                setSessionRate(e.target.value);
                                setSessionRateError(false);
                              }}
                            />
                            {sessionRateError && (
                              <span className="text-danger">
                                Enter session rate
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      {/* time slot section */}
                      <div className="pt-3">
                        <div className="ptFomrBorder p-2">
                          {timeSlotExpand === false ? (
                            <div className="text-left ml-3">
                              <span className="iconstoaddfee">
                                <i className="fa fa-plus-circle"></i>
                              </span>
                              <span
                                className=" addmanageuserfont mt-1 ml-1"
                                onClick={() => {
                                  setTimeSlotExpand(true);
                                }}
                              >
                                Available time slots
                              </span>
                            </div>
                          ) : (
                            <Expand open={timeSlotExpand}>
                              <div>
                                <div
                                  className="text-right"
                                  onClick={() => {
                                    setTimeSlotExpand(false);
                                  }}
                                >
                                  <span className="iconstoaddfee">
                                    <i class="fa fa-times cursor"></i>
                                  </span>
                                </div>
                                <Grid className="grid_main_div">
                                  <Grid item md={4}>
                                    <div className="EmailPadding">
                                      <div className="ptFormlabel">
                                        Available Days
                                      </div>
                                      <select
                                        value={weekDay}
                                        class="form-control"
                                        onChange={(e) => {
                                          setWeekDay(e.target.value);
                                          setWeekDayError(false);
                                        }}
                                      >
                                        <option value={-1}>Select a day</option>
                                        {timeSlotArray.map((item, index) => (
                                          <option value={item.id}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                      {weekDayError && (
                                        <p className="text-danger">
                                          Select a week day
                                        </p>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={4}>
                                    <div className="EmailPadding">
                                      <label
                                        className="ptFormlabel"
                                        style={{ marginBottom: "0px" }}
                                      >
                                        Start Time
                                      </label>
                                      <div className="timeInputPadding">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            inputVariant="outlined"
                                            variant="outlined"
                                            value={
                                              start_time == temp
                                                ? "00:00"
                                                : start_time
                                            }
                                            onChange={handlestarttime}
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item md={4}>
                                    <div className="EmailPadding">
                                      <label
                                        className="ptFormlabel"
                                        style={{ marginBottom: "0px" }}
                                      >
                                        End Time
                                      </label>
                                      <div className="timeInputPadding">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            inputVariant="outlined"
                                            variant="outlined"
                                            value={
                                              end_time == temp
                                                ? "00:00"
                                                : end_time
                                            }
                                            min={start_time}
                                            onChange={handleendtime}
                                            KeyboardButtonProps={{
                                              "aria-label": "change time",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className=" mt-2 mb-2 text-right">
                                  <Button
                                    variant="contained"
                                    className="add_new_query_btn button_login_decoration"
                                    onClick={createScheduleHandler}
                                  >
                                    Add
                                  </Button>
                                </div>
                                <Grid className="grid_main_div pt-1">
                                  <Grid item md={3}>
                                    <p className="tableHeadingBold">Week Day</p>
                                  </Grid>
                                  <Grid item md={3}>
                                    <p className="tableHeadingBold">
                                      Start Time
                                    </p>
                                  </Grid>
                                  <Grid item md={3}>
                                    <p className="tableHeadingBold">End Time</p>
                                  </Grid>
                                  <Grid item md={3}>
                                    <p className="tableHeadingBold">Action</p>
                                  </Grid>
                                </Grid>
                                <div>
                                  {addRecurrenceArray.map((row, index) => (
                                    <Card
                                      className="cardvisible_border p-2 mt-2"
                                      key={index}
                                    >
                                      <Grid className="grid_main_div pt-1">
                                        <Grid item md={3}>
                                          <p className="paraMarginRemove">
                                            {timeSlotArray[row.week_day].name}
                                          </p>
                                        </Grid>
                                        <Grid item md={3}>
                                          <p className="paraMarginRemove">
                                            {row.start_time}
                                          </p>
                                        </Grid>
                                        <Grid item md={3}>
                                          <p className="paraMarginRemove">
                                            {row.end_time}
                                          </p>
                                        </Grid>

                                        <Grid item md={3}>
                                          <div className="d-flex">
                                            <span
                                              className="iconstoaddfee manage_edit_cursor"
                                              onClick={() =>
                                                editScheduleDailogHandle(
                                                  row,
                                                  index
                                                )
                                              }
                                            >
                                              <i className="fa fa-pencil"></i>
                                            </span>

                                            <span
                                              className="iconstoaddfee manage_edit_cursor ml-2"
                                              onClick={() => {
                                                addRecurrenceArray.splice(
                                                  index,
                                                  1
                                                );
                                                setAddRecurrenceArray([
                                                  ...addRecurrenceArray,
                                                ]);
                                              }}
                                            >
                                              <i className="fa fa-trash"></i>
                                            </span>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  ))}
                                </div>
                              </div>
                            </Expand>
                          )}
                        </div>
                      </div>
                      <Grid className="grid_main_div">
                        <Grid item md={6}>
                          <div className="EmailPadding">
                            <div className="ptFormlabel">Profile summary</div>
                            <textarea
                              className="form-control"
                              rows="4"
                              value={expProfileSummary}
                              maxLength={500}
                              onChange={(e) => {
                                setExpProfileSummary(e.target.value);
                                setExpProfileSummaryError(false);
                              }}
                            ></textarea>
                            {expProfileSummaryError && (
                              <span className="text-danger">
                                Enter Profile summary
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Photo
                          </Grid>
                          <Grid item md={6}>
                            {console.log("getPhoto", getPhoto)}
                            <div className="d-flex">
                              <input
                                type="file"
                                id="Photo"
                                onChange={(e) => ImageUploadHandler(e, "PHOTO")}
                              />
                              {blankValidator(getPhoto) &&
                                blankValidator(getPhoto?.name) && (
                                  <Chip
                                    label={getPhoto?.name.substring(0, 25)}
                                    onClick={() => {
                                      HandleImageDailogBox(getPhoto);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Upload Certificate
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                type="file"
                                id="Photo"
                                onChange={(e) =>
                                  certificateUploadHandler(e, "PHOTO")
                                }
                              />
                              {blankValidator(getCertificate) &&
                                blankValidator(getCertificate?.name) && (
                                  <Chip
                                    label={getCertificate?.name.substring(
                                      0,
                                      25
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(getCertificate);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                      </div>

                      {/* <Grid className="grid_main_div mt-3 mb-3">
                  <Grid item md={6}>
                    <span
                      className="pl-2 pr-2 file_back_color cursor"
                      // onClick={HandleImageDailogBox}
                    >
                      {filename}
                    </span>
                  </Grid>
                </Grid> */}
                      {/* <div className="pt-3">
                  <h5>Bank Account Details</h5>
                  <div className="ptFomrBorder">
                    <Grid className="grid_main_div">
                      <Grid item md={6}>
                        <div className="EmailPadding">
                          <div className="ptFormlabel">Bank Name</div>
                          <input
                            type="text"
                            placeholder="Enter Bank Name"
                            className="form-control"
                            value={bankName}
                            onChange={(e) => {
                              setBankName(e.target.value);
                              // setPhoneNoError(false);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div className="EmailPadding">
                          <div className="ptFormlabel"> Bank Code</div>
                          <input
                            type="text"
                            placeholder="Enter Bank Code"
                            className="form-control"
                            value={bankCode}
                            onChange={(e) => {
                              setBankCode(e.target.value);
                              // setPhoneNoError(false);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="grid_main_div">
                      <Grid item md={6}>
                        <div className="EmailPadding">
                          <div className="ptFormlabel">Bank Account Number</div>
                          <input
                            type="text"
                            placeholder="Enter Bank Account Number"
                            className="form-control"
                            value={bankAccountNumber}
                            onChange={(e) => {
                              setBankAccountNumber(e.target.value);
                              // setPhoneNoError(false);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div className="EmailPadding">
                          <div className="ptFormlabel">Beneficiary Name</div>
                          <input
                            type="text"
                            placeholder="Enter Beneficiary Name"
                            className="form-control"
                            value={beneficiaryName}
                            onChange={(e) => {
                              setBeneficiaryName(e.target.value);
                              // setPhoneNoError(false);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div> */}
                      {/* <div className="recaptchaPadding mt-2">
                        {" "}
                        <ReCAPTCHA
                          sitekey={config.config.siteKey}
                          // onChange={callback}
                          onChange={(code) => {
                            if (recaptchErr) setRecaptchaErr(false);
                            setRecaptchaValue(code);
                          }}
                        />
                      </div> */}
                      <div className="pr-4 pt-3">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            {" "}
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={updateTeacherProfileHandle}
                            >
                              Update
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* update time slot dailog */}
      <Dialog
        open={editSheduleOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setEditSheduleOpen(false);
        }}
        className="dailog_formatting"
      >
        <div className="">
          <div className="text-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setEditSheduleOpen(false);
              }}
            ></i>
          </div>
          <div className="p-3">
            <Grid className="grid_main_div mt-2">
              <Grid item md={6}>
                <div className="EmailPadding">
                  <label className="ptFormlabel">Select Week Day</label>
                  <div>
                    <select
                      value={weekDay}
                      class="form-control"
                      style={{ height: "45px" }}
                      onChange={(e) => {
                        setWeekDay(e.target.value);
                      }}
                    >
                      <option value={-1}>Select a day</option>
                      {timeSlotArray.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="EmailPadding">
                  <label
                    className="ptFormlabel"
                    style={{ marginBottom: "5px" }}
                  >
                    Start Time
                  </label>
                  <div className="timeInputPadding">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        inputVariant="outlined"
                        variant="outlined"
                        value={start_time == temp ? "00:00" : start_time}
                        onChange={handlestarttime}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                {startDateErr && (
                  <p className="text-danger mt-4">Enter Start Date</p>
                )}
              </Grid>
            </Grid>
            <Grid className="grid_main_div mt-2">
              <Grid item md={6}>
                <div className="EmailPadding">
                  <label
                    className="ptFormlabel"
                    style={{ marginBottom: "5px" }}
                  >
                    End Time
                  </label>
                  <div className="timeInputPadding">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        inputVariant="outlined"
                        variant="outlined"
                        value={end_time == temp ? "00:00" : end_time}
                        min={start_time}
                        onChange={handleendtime}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className=" mt-2 mb-2 text-right">
              <Button
                variant="contained"
                className="add_new_query_btn button_login_decoration "
                onClick={() => {
                  updateScheduleHandler(updateIndex);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={OpenPhotoImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenPhotoImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + impagepath}
              download
              target="_blank"
              className="mr-3"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenPhotoImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + impagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>
      <Loder loading={isLoaing} />
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(PtTeacherFormDetails));
