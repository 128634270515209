import React, { useState, useEffect } from "react";

import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import DateFnsUtils from "@date-io/date-fns";
import Expand from "react-expand-animated";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  getMinAndSecfromDate,
  showNotificationMsz,
  currentDayForMinMAx,
  serverDateFormat,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function AdminAddExam(props) {
  //name instituition

  //academic session

  let academic_session_id = parseInt(props.location.state.academic_session_id);

  //instuttion id
  let institution_id = parseInt(props.data.institution_id);

  const [Create_Exam, {}] = useMutation(Service.Create_Exam);

  const [name, setname] = useState("");
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);

  const [GradeId, setGradeId] = useState(-1);
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [AddedExamDetailArr, setAddedExamDetailArr] = useState([]);
  const [ExamSubjectArr, setExamSubjectArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [locationarr, setlocationarr] = useState([]);
  const [AllSectionArr, setAllSectionArr] = useState([]);
  const [SelectedSectionIdArr, setSelectedSectionIdArr] = useState([]);
  const [selectedsectioncheck, setselectedsectioncheck] = useState(false);

  const [ExamLocationNameEdit, setExamLocationNameEdit] = useState("");
  const [subjectNameEdit, setsubjectNameEdit] = useState("");

  const [subjectarr, setsubjectarr] = useState([]);
  const [locationname, setlocationname] = useState("");
  const [subjectid, setsubjectid] = useState(-1);
  const [SubjectName, setSubjectName] = useState("");
  const [ExamDate, setExamDate] = useState("");
  const temp = new Date();
  const [start_time, setStart_time] = useState(temp);
  const [end_time, setEnd_time] = useState(temp);
  const [Marks, setMarks] = useState(0);
  const [syllabus, setsyllabus] = useState("");
  const [location_id, setlocation_id] = useState(-1);
  const [locationindex_id, setlocationindex_id] = useState(-1);
  const [subjectindex_id, setsubjectindex_id] = useState(-1);

  //edit values for subject schedule variables
  const [ExamEditid, setExamEditid] = useState(-1);
  const [ExamDateEdit, setExamDateEdit] = useState("");
  const [StarttimeEdit, setStarttimeEdit] = useState("");
  const [EndtimeEdit, setEndtimeEdit] = useState("");
  const [MaximumMarksEdit, setMaximumMarksEdit] = useState(0);
  const [ExamLoactionidEdit, setExamLoactionidEdit] = useState(-1);
  const [SyllabusEdit, setSyllabusEdit] = useState("");
  const [subjectIdEdit, setsubjectIdEdit] = useState(-1);

  //error
  const [nameError, setNameError] = useState(false);

  const [GradeIdError, setGradeIdError] = useState(false);

  // subject info errors
  const [subjectIdError, setsubjectIdError] = useState(false);
  const [examDateError, setexamDateError] = useState(false);
  const [totalMarksError, settotalMarksError] = useState(false);

  const [locationError, setlocationError] = useState(false);
  const [syllabusError, setsyllabusError] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setisloading(false);
            if (response.data.getGrade.length !== 0) {
              setGradeArr(response.data.getGrade);
            }
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    const getsubjectlist = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            let temp = [];
            setisloading(false);
            for (
              let index = 0;
              index < response.data.getSubject.length;
              index++
            ) {
              temp.push({
                label: response.data.getSubject[index].name,
                value: response.data.getSubject[index].id,
              });
            }
            setsubjectarr(temp);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        console.log("t and c error", error);
      }
    };
    const LocationData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Location,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            let temp = [];

            for (
              let index = 0;
              index < response.data.getLocation.length;
              index++
            ) {
              temp.push({
                name: response.data.getLocation[index].name,
                mode: response.data.getLocation[index].mode,
                show: true,
                id: response.data.getLocation[index].id,
              });
            }
            setisloading(false);
            setlocationarr(temp);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log(" location arr try and catch", error);
      }
    };

    LocationData();
    getManageGrades();
    getsubjectlist();
  }, [client, academic_session_id, institution_id]);

  const changeGrade = (val) => {
    let grade_id = parseInt(val);
    setSectionArr([]);
    if (grade_id === -1) {
      setGradeId(-1);
      return;
    }
    setGradeId(grade_id);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getSectionAndGrade.length;
            index++
          ) {
            const element = response.data.getSectionAndGrade[index];
            temp.push({
              section_id: parseInt(element.id),
            });
          }
          setAllSectionArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handlestarttime = (date) => {
    setStart_time(date);

    let nexthour = new Date();
    nexthour.setHours(date.getHours() + 1);

    setEnd_time(nexthour);
  };
  const handleendtime = (date) => {
    setEnd_time(date);
  };

  const changeSection = (val) => {
    let seciontId = parseInt(val);
    setselectedsectioncheck(true);
    if (seciontId === -1) {
      setselectedsectioncheck(false);
      return;
    }
    let temp = [];
    temp.push({
      section_id: seciontId,
    });

    setSelectedSectionIdArr(temp);
  };

  const selectSubjectId = async (val) => {
    setsubjectindex_id(val);
    if (!blankValidator(val) || val === -1) {
      setsubjectid(-1);
      return;
    }

    const { value, label } = subjectarr[val];

    setsubjectIdError(false);

    await setsubjectid(parseInt(value));
    await setsubjectindex_id(val);
    await setSubjectName(label);
  };

  const SelectLocation = async (val) => {
    setlocationError(false);
    setlocationindex_id(val);
    if (!blankValidator(val) || val === -1) {
      setlocation_id(-1);
      return;
    }
    const { id, name } = locationarr[val];
    setlocationError(false);

    await setlocation_id(parseInt(id));
    await setlocationindex_id(val);
    await setlocationname(name);
  };

  const selectSubjectIdEdit = (val) => {
    if (!blankValidator(val) || val === -1) {
      setsubjectIdEdit(-1);
      return;
    }

    const { value } = subjectarr[val];

    if (value === "" || value === -1) {
      setsubjectIdEdit(-1);
      return;
    }

    setsubjectIdEdit(parseInt(val));
    setsubjectNameEdit(name);
  };

  const SelectLocationEdit = (val) => {
    if (!blankValidator(val) || val === -1) {
      setExamLoactionidEdit(-1);
      return;
    }
    const { name } = locationarr[val];
    setExamLoactionidEdit(val);
    setExamLocationNameEdit(name);
  };

  const AddExam = () => {
    try {
      let flag = false;
      if (!blankValidator(subjectid) || subjectid === -1) {
        setsubjectIdError(true);
        flag = true;
      }
      if (!blankValidator(ExamDate)) {
        setexamDateError(true);
        flag = true;
      }
      if (!blankValidator(Marks) || Marks === -1 || Marks === 0) {
        settotalMarksError(true);
        flag = true;
      }

      if (!blankValidator(location_id) || location_id === -1) {
        setlocationError(true);
        flag = true;
      }
      if (!blankValidator(syllabus)) {
        setsyllabusError(true);
        flag = true;
      }
      if (flag) {
        return;
      }

      let startExamTime = new Date(
        ExamDate + " " + getMinAndSecfromDate(start_time)
      );
      let endExamTime = new Date(
        ExamDate + " " + getMinAndSecfromDate(end_time)
      );

      setisloading(true);
      //to Send the Data
      AddedExamDetailArr.push({
        syllabus: syllabus,
        subject_id: parseInt(subjectid),
        location_id: parseInt(location_id),
        marks: parseInt(Marks),
        day: ExamDate,
        start_time: startExamTime.toJSON().substring(0, 23),
        end_time: endExamTime.toJSON().substring(0, 23),
      });
      setAddedExamDetailArr([...AddedExamDetailArr]);

      //to Show the Data

      ExamSubjectArr.push({
        syllabus: syllabus,
        subject_id: parseInt(subjectid),
        SubjectName: SubjectName,
        locationname: locationname,
        location_id: parseInt(location_id),
        marks: parseInt(Marks),
        day: ExamDate,
        start_time: getMinAndSecfromDate(start_time),
        end_time: getMinAndSecfromDate(end_time),
        show: false,
      });
      setExamSubjectArr([...ExamSubjectArr]);

      setsyllabus("");
      setsubjectid(-1);
      setSubjectName("");
      setExamDate("");
      setStart_time(temp);
      setEnd_time(temp);
      setMarks(0);
      setsyllabus("");
      setlocation_id(-1);
      setlocationname("");
      setlocationindex_id(-1);
      setsubjectindex_id(-1);
      setisloading(false);
    } catch (error) {
      console.log("Error", error);
      setisloading(false);
    }
  };

  const CreateExamination = () => {
    let flag = false;
    if (!blankValidator(name)) {
      setNameError(true);
      flag = true;
    }
    if (!blankValidator(GradeId) || GradeId === -1) {
      setGradeIdError(true);
      flag = true;
    }
    if (flag) {
      return;
    }
    try {
      setisloading(true);
      Create_Exam({
        variables: {
          name,
          section_id: selectedsectioncheck
            ? SelectedSectionIdArr
            : AllSectionArr,
          institution_id,
          subjectandtimetable: AddedExamDetailArr,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addExam.message, "success");
          props.navigate.goBack();
        },
        (err) => {
          console.log("then error", err);
          showNotificationMsz("Add subject schedule first", "info");

          setisloading(false);
        }
      );
    } catch (error) {
      console.log("t and c error", error);
      showNotificationMsz("Assignment not Created", "info");

      setisloading(false);
    }
  };

  const updateSubjectSchedule = () => {
    try {
      setisloading(true);

      AddedExamDetailArr[ExamEditid] = {
        syllabus: SyllabusEdit,
        subject_id: parseInt(subjectIdEdit),
        location_id: parseInt(ExamLoactionidEdit),
        marks: parseInt(MaximumMarksEdit),
        day: ExamDateEdit,
        start_time: StarttimeEdit.toJSON().substring(0, 23),
        end_time: EndtimeEdit.toJSON().substring(0, 23),
      };
      setAddedExamDetailArr([...AddedExamDetailArr]);

      //to Show the Data

      ExamSubjectArr[ExamEditid] = {
        syllabus: SyllabusEdit,
        subject_id: parseInt(subjectIdEdit),
        SubjectName: subjectNameEdit,
        locationname: ExamLocationNameEdit,
        location_id: parseInt(ExamLoactionidEdit),
        marks: parseInt(MaximumMarksEdit),
        day: ExamDateEdit,
        start_time: getMinAndSecfromDate(StarttimeEdit),
        end_time: getMinAndSecfromDate(EndtimeEdit),
      };
      setExamSubjectArr([...ExamSubjectArr]);

      ExamSubjectArr[ExamEditid].show = false;

      setExamEditid("");
      setExamDateEdit("");
      setStarttimeEdit(temp);
      setEndtimeEdit(temp);
      setsubjectIdEdit(-1);
      setsubjectNameEdit("");
      setMaximumMarksEdit(0);
      setExamLoactionidEdit(-1);
      setExamLocationNameEdit("");
      setSyllabusEdit("");

      setisloading(false);
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
  };

  const editSubjectSchedule = (data, index) => {
    try {
      ExamSubjectArr[index].show = true;
      setExamSubjectArr([...ExamSubjectArr]);

      setExamEditid(index);
      setExamDateEdit(data.day);
      setStarttimeEdit(new Date("2022-2-2 " + data.start_time));
      setEndtimeEdit(new Date("2022-2-2 " + data.end_time));
      setsubjectIdEdit(data.subject_id);
      setsubjectNameEdit(data.SubjectName);
      setMaximumMarksEdit(data.marks);
      setExamLoactionidEdit(data.location_id);
      setExamLocationNameEdit(data.locationname);
      setSyllabusEdit(data.syllabus);
    } catch (error) {
      console.log("error", error);
    }
  };

  //Edit Exam Error
  const [EditExamdateError, setEditExamdateError] = useState(false);
  const [EditMarksError, setEditMarksError] = useState(false);
  const [EditlocationError, setEditlocationError] = useState(false);
  const [EditSyllabusError, setEditSyllabusError] = useState(false);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Create Exam</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1"> Exam Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Enter Name"
                                autoComplete="off"
                                value={name}
                                onChange={(e) => {
                                  setNameError(false);
                                  setname(e.target.value);
                                }}
                              />
                              {nameError && (
                                <p className="text-danger">Enter Exam Name</p>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Class</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => {
                                  setGradeIdError(false);
                                  changeGrade(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Class</option>

                                {gradeArr.length > 0 &&
                                  gradeArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                              {GradeIdError && (
                                <p className="text-danger">Select Class Name</p>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="">
                              <label for="exampleInputEmail1">Section</label>
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => {
                                  changeSection(e.target.value);
                                }}
                              >
                                <option value={-1}>Select Section</option>

                                {sectionArr.length > 0 &&
                                  sectionArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="mt-3">
                          {/* <Card className="pt-2 pb-2 cardvisible_border mt-2"> */}
                          <Card className="p-2 mb-5">
                            <div className="card_admissiondetails_height">
                              <div className="textfiled_margin">
                                <div className="d-flex">
                                  <span className="iconstoaddfee">
                                    <i className="fa fa-plus-circle"></i>
                                  </span>
                                  <span
                                    className=" ml-2 mt-1 mb-2 addmanageuserfont"
                                    onClick={() =>
                                      setaddMangeopen(!addMangeopen)
                                    }
                                  >
                                    Add Subject Schedule
                                  </span>
                                </div>
                                {addMangeopen && (
                                  <Expand open={addMangeopen}>
                                    <div className="mt-3">
                                      <Grid className="grid_main_div mt-1">
                                        <Grid item md={12}>
                                          <div className="text-right">
                                            <span
                                              className="iconstoaddfee"
                                              onClick={() =>
                                                setaddMangeopen(!addMangeopen)
                                              }
                                            >
                                              <i class="fa fa-times cursor"></i>
                                            </span>
                                          </div>
                                        </Grid>

                                        <Grid item md={6}>
                                          <div className="inputMarginright">
                                            <label for="exampleInputEmail1">
                                              Subject
                                            </label>
                                            <select
                                              className="form-control"
                                              id="exampleFormControlSelect1"
                                              value={subjectindex_id}
                                              onChange={(e) => {
                                                selectSubjectId(e.target.value);
                                              }}
                                            >
                                              <option value={-1} name={""}>
                                                Select the Subject
                                              </option>

                                              {subjectarr.map((item, index) => (
                                                <option value={index}>
                                                  {item.label}
                                                </option>
                                              ))}
                                            </select>
                                            {subjectIdError && (
                                              <span className="text-danger">
                                                Select the Subject
                                              </span>
                                            )}
                                          </div>
                                        </Grid>

                                        <Grid item md={6}>
                                          <div>
                                            <label for="exampleInputEmail1">
                                              Exam Date
                                            </label>
                                            <input
                                              type="date"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              autoComplete="off"
                                              min={currentDayForMinMAx()}
                                              value={ExamDate}
                                              onChange={(e) => {
                                                setexamDateError(false);
                                                setExamDate(e.target.value);
                                              }}
                                            />
                                            {examDateError && (
                                              <span className="text-danger">
                                                Enter Exam Date
                                              </span>
                                            )}
                                          </div>
                                        </Grid>
                                        <Grid item md={6}>
                                          <div className="inputMarginright">
                                            <label for="exampleInputEmail1">
                                              Start Time
                                            </label>
                                            <div>
                                              <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                              >
                                                <KeyboardTimePicker
                                                  margin="normal"
                                                  id="time-picker"
                                                  inputVariant="outlined"
                                                  variant="outlined"
                                                  value={
                                                    start_time == temp
                                                      ? "00:00"
                                                      : start_time
                                                  }
                                                  onChange={handlestarttime}
                                                  KeyboardButtonProps={{
                                                    "aria-label": "change time",
                                                  }}
                                                />
                                              </MuiPickersUtilsProvider>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item md={6}>
                                          <div>
                                            <label for="exampleInputEmail1">
                                              End Time
                                            </label>
                                            <div>
                                              <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                              >
                                                <KeyboardTimePicker
                                                  margin="normal"
                                                  id="time-picker"
                                                  inputVariant="outlined"
                                                  variant="outlined"
                                                  value={
                                                    end_time == temp
                                                      ? "00:00"
                                                      : end_time
                                                  }
                                                  min={start_time}
                                                  onChange={handleendtime}
                                                  KeyboardButtonProps={{
                                                    "aria-label": "change time",
                                                  }}
                                                />
                                              </MuiPickersUtilsProvider>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Grid className="grid_main_div mt-1">
                                        <Grid item md={6}>
                                          <div className="inputMarginright">
                                            <label for="exampleInputEmail1">
                                              Max Marks
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              autoComplete="off"
                                              placeholder="Marks"
                                              value={Marks === 0 ? "" : Marks}
                                              onChange={(e) => {
                                                settotalMarksError(false);
                                                setMarks(e.target.value);
                                              }}
                                            />
                                            {totalMarksError && (
                                              <span className="text-danger">
                                                Enter the Marks
                                              </span>
                                            )}
                                          </div>
                                        </Grid>

                                        <Grid item md={6}>
                                          <div>
                                            <label for="exampleInputEmail1">
                                              Location
                                            </label>
                                            <select
                                              className="form-control"
                                              value={locationindex_id}
                                              onChange={(e) =>
                                                SelectLocation(e.target.value)
                                              }
                                            >
                                              <option value={-1}>
                                                Select the Location
                                              </option>

                                              {locationarr.map(
                                                (item, index) => (
                                                  <option value={index}>
                                                    {item.name}{" "}
                                                    {item.mode === "Online"
                                                      ? " - ( Online ) "
                                                      : ""}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            {locationError && (
                                              <span className="text-danger">
                                                Select the Exam Location
                                              </span>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Grid className="grid_main_div mt-1">
                                        <Grid item md={12}>
                                          <div class="form-group">
                                            <label for="exampleInputEmail1">
                                              Syllabus
                                            </label>
                                            <textarea
                                              class="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows="4"
                                              placeholder="Syllabus..."
                                              value={syllabus}
                                              onChange={(e) => {
                                                setsyllabusError(false);
                                                setsyllabus(e.target.value);
                                              }}
                                            ></textarea>
                                            {syllabusError && (
                                              <span className="text-danger">
                                                Enter Exam Syllabus
                                              </span>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Grid className="grid_main_div mt-1 mb-2">
                                        <Grid item md={10}></Grid>
                                        <Grid item md={2}>
                                          <Button
                                            variant="contained"
                                            className="add_new_query_btn button_login_decoration button_width"
                                            onClick={AddExam}
                                          >
                                            Add
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Expand>
                                )}
                              </div>
                            </div>
                          </Card>
                        </div>

                        <div className="mt-3">
                          {ExamSubjectArr.map((item, index) => (
                            <Card
                              className="pt-2 pb-2 cardvisible_border mt-2"
                              key={index}
                            >
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <Grid className="grid_main_div pt-1">
                                    <Grid item md={2}>
                                      {!blankValidator(item.SubjectName)
                                        ? ""
                                        : item.SubjectName}
                                    </Grid>

                                    <Grid item md={2}>
                                      {serverDateFormat(item.day)}
                                    </Grid>

                                    <Grid item md={2}>
                                      {" "}
                                      {!blankValidator(item.start_time)
                                        ? ""
                                        : item.start_time}
                                    </Grid>
                                    <Grid item md={2}>
                                      {" "}
                                      {!blankValidator(item.end_time)
                                        ? ""
                                        : item.end_time}
                                    </Grid>
                                    <Grid item md={2}>
                                      {!blankValidator(item.locationname)
                                        ? ""
                                        : item.locationname}
                                    </Grid>
                                    <Grid item md={2}>
                                      <div className="d-flex">
                                        <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                          {item.show === false ? (
                                            <span
                                              className="iconstoaddfee"
                                              onClick={() =>
                                                editSubjectSchedule(item, index)
                                              }
                                            >
                                              <i className="fa fa-pencil"></i>
                                            </span>
                                          ) : (
                                            <span
                                              className="iconstoaddfee manage_edit_cursor"
                                              onClick={() => {
                                                ExamSubjectArr[
                                                  index
                                                ].show = false;
                                                setExamSubjectArr([
                                                  ...ExamSubjectArr,
                                                ]);
                                                // setassignmentresult(true)
                                              }}
                                            >
                                              <i class="fa fa-times cursor"></i>
                                            </span>
                                          )}
                                        </span>

                                        <span
                                          className="iconstoaddfee manage_edit_cursor ml-2"
                                          onClick={() => {
                                            AddedExamDetailArr.splice(index, 1);
                                            setAddedExamDetailArr([
                                              ...AddedExamDetailArr,
                                            ]);
                                            ExamSubjectArr.splice(index, 1);
                                            setExamSubjectArr([
                                              ...ExamSubjectArr,
                                            ]);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                {/*------------------------------------------ subject schedule edit start----------------------- */}

                                <Expand open={item.show}>
                                  <div className="p-2">
                                    <Grid className="grid_main_div mt-1">
                                      <Grid item md={6}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            Subject
                                          </label>
                                          <select
                                            className="form-control"
                                            value={subjectIdEdit}
                                            onChange={(e) =>
                                              selectSubjectIdEdit(e)
                                            }
                                          >
                                            <option value={-1}>
                                              Select the Subject
                                            </option>

                                            {subjectarr.map((item, index) => (
                                              <option
                                                value={index}
                                                name={item.label}
                                              >
                                                {item.label}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </Grid>
                                      <Grid item md={6}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            Exam Date
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            autoComplete="off"
                                            value={ExamDateEdit}
                                            onChange={(e) => {
                                              setEditExamdateError(false);
                                              setExamDateEdit(e.target.value);
                                            }}
                                          />
                                          {EditExamdateError && (
                                            <span className="text-danger">
                                              Enter The Exam Date
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item md={6}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            Start Time
                                          </label>
                                          <div>
                                            <MuiPickersUtilsProvider
                                              utils={DateFnsUtils}
                                            >
                                              <KeyboardTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                value={StarttimeEdit}
                                                onChange={(date) => {
                                                  setStarttimeEdit(date);
                                                }}
                                                KeyboardButtonProps={{
                                                  "aria-label": "change time",
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item md={6}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            End Time
                                          </label>
                                          <div>
                                            <MuiPickersUtilsProvider
                                              utils={DateFnsUtils}
                                            >
                                              <KeyboardTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                value={EndtimeEdit}
                                                onChange={(date) => {
                                                  setEndtimeEdit(date);
                                                }}
                                                KeyboardButtonProps={{
                                                  "aria-label": "change time",
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>

                                    <Grid className="grid_main_div mt-1">
                                      <Grid item md={6}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            Max Marks
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            autoComplete="off"
                                            placeholder="Marks"
                                            value={
                                              MaximumMarksEdit === 0
                                                ? ""
                                                : MaximumMarksEdit
                                            }
                                            onChange={(e) => {
                                              setEditMarksError(false);
                                              setMaximumMarksEdit(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          {EditMarksError && (
                                            <span className="text-danger">
                                              Enter The Exam Marks
                                            </span>
                                          )}
                                        </div>
                                      </Grid>

                                      <Grid item md={6}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            Location
                                          </label>
                                          <select
                                            className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={ExamLoactionidEdit}
                                            onChange={(e) =>
                                              SelectLocationEdit(e.target.value)
                                            }
                                          >
                                            <option value={-1}>
                                              Select the Location
                                            </option>

                                            {locationarr.map((item, index) => (
                                              <option value={index}>
                                                {item.name}{" "}
                                                {item.mode === "Online"
                                                  ? " - ( Online ) "
                                                  : ""}
                                              </option>
                                            ))}
                                          </select>
                                          {EditlocationError && (
                                            <span className="text-danger">
                                              Enter The Exam Location
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Grid className="grid_main_div mt-1">
                                      <Grid item md={12}>
                                        <div class="form-group">
                                          <label for="exampleInputEmail1">
                                            Syllabus
                                          </label>
                                          <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="4"
                                            placeholder="Syllabus..."
                                            value={SyllabusEdit}
                                            onChange={(e) => {
                                              setEditSyllabusError(false);
                                              setSyllabusEdit(e.target.value);
                                            }}
                                          ></textarea>
                                          {EditSyllabusError && (
                                            <span className="text-danger">
                                              Enter The Exam Syllabus
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Grid className="grid_main_div mt-1 mb-2">
                                      <Grid item md={10}></Grid>
                                      <Grid item md={2}>
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn button_login_decoration button_width"
                                          onClick={updateSubjectSchedule}
                                        >
                                          Update
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Expand>
                                {/*------------------------------------------ subject schedule edit end----------------------- */}
                              </div>
                            </Card>
                          ))}
                        </div>

                        <Grid className="grid_main_div mt-4 mb-2">
                          <Grid item md={10}></Grid>

                          <Grid item md={2}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={CreateExamination}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div>
        {" "}
        <Loder loading={isloading} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(AdminAddExam)));
