import React, { useState, useEffect } from "react";
import HOC1 from "../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import "react-phone-input-2/lib/style.css";
import Loder from "../Loder/Loder.jsx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//redux
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import { blankValidator } from "../../utils/Validation";
import Holiday from "../Common/Holiday/Holiday.jsx";
import * as Service from "../../services/index";
import { useApolloClient } from "@apollo/client";

import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
function EmployeeProfileView(props) {
  //user ID
  let id = parseInt(props.location.state.userId);

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const client = useApolloClient();
  const [checkbox, setCheckbox] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [Employeeformdata, setEmployeeformdata] = useState({
    display_id: "",
    login_id: "",
    status: "",
    photoPath: "",
    designation_id: "",
    department_id: "",
    email: "",
    Teacher_first_name: "",
    Teacher_middle_name: "",
    Teacher_last_name: "",
    phone: "",
    institution_id: "",
    joining_date: "",
    gender: "",
    birth_date: "",
    passport_expiry_date: "",
    Current_line1: "",
    Current_line2: "",
    Current_city: "",
    Current_state: "",
    Current_zip_code: "",
    Current_country: "",
    Permanent_line1: "",
    Permanent_line2: "",
    Permanent_city: "",
    Permanent_state: "",
    Permanent_zip_code: "",
    Permanent_country: "",
    blood_group: "",
    nationality: "",
    tax_id: "",
    marital_status: "",
    passport_id: "",
    national_id: "",
    religion: "",
    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Mother_first_name: "",
    Mother_middle_name: "",
    Mother_last_name: "",
    Spouse_first_name: "",
    Spouse_middle_name: "",
    Spouse_last_name: "",
    education_qualification_id: "",
    documents: [],
    education_qualification_arr: [],
    certificate: {},
    photo: {},
    birth_cert: {},
    tax: {},
    passport: {},
    others: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const getDesignationanddepartment = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Id_based_Employee_details,
            variables: {
              id,
            },
          })
          .then((response) => {
            setisloading(false);
            const employeeeditdata = response.data.getEmployeeById;
            const { login } = employeeeditdata;

            setEmployeeformdata({
              ...setEmployeeformdata,
              status: employeeeditdata.status,
              display_id: login.display_id,
              login_id: login.login_id,
              education_qualification_arr:
                employeeeditdata.education_qualifications,
              photoPath: employeeeditdata?.photo?.path,
              designation_id: employeeeditdata?.designation?.name,
              department_id: employeeeditdata?.department?.name,
              email: employeeeditdata.email,
              Teacher_first_name: employeeeditdata?.name?.first_name,
              Teacher_middle_name: employeeeditdata?.name?.middle_name,
              Teacher_last_name: employeeeditdata?.name?.last_name,
              phone: employeeeditdata.phone,
              joining_date: employeeeditdata?.joining_date,
              gender: employeeeditdata.gender,
              birth_date: employeeeditdata.birth_date,
              Current_line1: employeeeditdata.currentAddress?.line1 || "",
              Current_line2: employeeeditdata.currentAddress?.line2 || "",
              Current_city: employeeeditdata.currentAddress?.city || "",
              Current_state: employeeeditdata.currentAddress?.state || "",
              Current_zip_code: employeeeditdata.currentAddress?.zip_code || "",
              Current_country: employeeeditdata.currentAddress?.country || "",
              Permanent_line1: employeeeditdata.permanentAddress?.line1 || "",
              Permanent_line2: employeeeditdata.permanentAddress?.line2 || "",
              Permanent_city: employeeeditdata.permanentAddress?.city || "",
              Permanent_state: employeeeditdata.permanentAddress?.state || "",
              passport_expiry_date: employeeeditdata.passport_expiry_date,
              Permanent_zip_code:
                employeeeditdata?.permanentAddress?.zip_code || "",
              Permanent_country:
                employeeeditdata.permanentAddress?.country || "",
              blood_group: employeeeditdata.blood_group,
              nationality: employeeeditdata.nationality,
              tax_id: employeeeditdata.tax_id,
              marital_status: employeeeditdata.marital_status,
              passport_id: employeeeditdata.passport_id,
              national_id: employeeeditdata.national_id,
              religion: employeeeditdata.religion,
              Father_first_name:
                employeeeditdata?.fatherName?.first_name || "No Data",
              Father_middle_name:
                employeeeditdata?.fatherName?.middle_name || "No Data",
              Father_last_name:
                employeeeditdata?.fatherName?.last_name || "No Data",
              Mother_first_name:
                employeeeditdata.motherName?.first_name || "No Data",
              Mother_middle_name:
                employeeeditdata.motherName?.middle_name || "No Data",
              Mother_last_name:
                employeeeditdata.motherName?.last_name || "No Data",
              Spouse_first_name:
                employeeeditdata?.spouseName?.first_name || "No Data",
              Spouse_middle_name:
                employeeeditdata?.spouseName?.middle_name || "No Data",
              Spouse_last_name:
                employeeeditdata?.spouseName?.last_name || "No Data",
              education_qualification_id: "null from backend",

              photo: employeeeditdata.photo ? employeeeditdata.photo : {},
              birth_cert: employeeeditdata.birth_cert
                ? employeeeditdata.birth_cert
                : {},
              tax: employeeeditdata.tax ? employeeeditdata.tax : {},
              certificate: employeeeditdata.certificate
                ? employeeeditdata.certificate
                : {},
              passport: employeeeditdata.passport
                ? employeeeditdata.passport
                : {},
              others: employeeeditdata.others ? employeeeditdata.others : [],
            });
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    getDesignationanddepartment();
  }, [client, id]);
  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const HandleImageDailogBoxClose = () => {
    setimagepath("");
    setimageType("");
    setOpenImageDailog(false);
  };
  return (
    <div className="main_full_width">
      {console.log("Employeeformdata", Employeeformdata)}
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Employee Profile</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={2}>
                            <div className=" inputMarginright">
                              <img
                                src={`${Service.baseUrl}/${Employeeformdata.photoPath}`}
                                alt=""
                                style={{ width: 110, height: 115 }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={5} className="mt-3">
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                Employee ID :{" "}
                                {Employeeformdata.display_id &&
                                  Employeeformdata.display_id}
                              </Card>
                            </div>
                            <div className="inputMarginright mt-2 card_invoicedetails_border">
                              <Card className="p-1">
                                Login ID : {Employeeformdata.login_id}
                              </Card>
                            </div>
                          </Grid>
                          <Grid item md={5} className="mt-3">
                            <div className="inputMarginright mt-1 card_invoicedetails_border">
                              <Card className="p-1">
                                Status : {Employeeformdata.status}
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">Employee Details</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Department</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.department_id}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Designation
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.designation_id}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date Of Joining
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.joining_date}</p>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="text_filed_heading">Employee Name</div>
                        <div className="name_outside_border">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Teacher_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Teacher_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Teacher_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date of Birth
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.birth_date}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Gender</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.gender}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                            </div>

                            <PhoneInput
                              countryCodeEditable={false}
                              disabled={true}
                              className="phone_input_width_create mt-2 "
                              id="phone"
                              country={"in"}
                              value={Employeeformdata.phone}
                            />
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Blood Group
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.blood_group}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Email</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.email}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.passport_id}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Passport Expiry Date
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.passport_expiry_date}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              National ID
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.national_id}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Tax Id Pan Card Number
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.tax_id}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">Religion</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.religion}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Marital Status
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.marital_status}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality
                            </div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.nationality}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading">Spouse's Name</div>
                        <div className="name_outside_border">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Spouse_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Spouse_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Spouse_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Mother's Name</div>
                        <div className="name_outside_border">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Mother_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Mother_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Mother_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Father's Name</div>
                        <div className="name_outside_border mb-2">
                          <Grid className="grid_main_div mb-2">
                            <Grid item md={4}>
                              <div className="text_filed_heading pl-1">
                                First Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Father_first_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Father_middle_name}</p>
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="textWrapper">
                                <p>{Employeeformdata.Father_last_name}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Address</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Current Address
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_line1}</p>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_line2}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Country</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_country}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">State</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_state}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Current_zip_code}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="d-flex mt-3">
                          <span className="text_filed_heading">
                            Permanent Address
                          </span>
                          <span className="ml-5">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="filladdress"
                                // onClick={(e) => fillAddress(e)}
                                checked={checkbox}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Same As Current Address
                              </label>
                            </div>
                          </span>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_line1}</p>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_line2}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Country</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_country}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">State</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_state}</p>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="textWrapper">
                              <p>{Employeeformdata.Permanent_zip_code}</p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">
                    Educational Qualification
                  </div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {Employeeformdata.education_qualification_arr.length !==
                          0 &&
                          Employeeformdata.education_qualification_arr.map(
                            (item, index) => (
                              <Card
                                className="cardvisible_border mt-2 mb-2"
                                key={index}
                              >
                                <div className="textfiled_margin">
                                  <Grid className="grid_main_div">
                                    <Grid item md={6}>
                                      <div className="text_filed_heading">
                                        Qualification Attained
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.qualification}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="text_filed_heading">
                                        Course Name
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.course_name}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="text_filed_heading">
                                        College/University Name
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.university_name}</p>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className="text_filed_heading gapfromanotherinput">
                                    Peroid Of Education
                                  </div>
                                  <Grid className="grid_main_div  mb-2">
                                    <Grid item md={6}>
                                      <div className="text_filed_heading">
                                        Start Date
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.start_date}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="text_filed_heading">
                                        End Date
                                      </div>
                                      <div className="textWrapper">
                                        <p>{item && item.end_date}</p>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Card>
                            )
                          )}
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Documents</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={6}>
                            Photo
                          </Grid>

                          <Grid item md={2}>
                            {blankValidator(Employeeformdata.photo.name) && (
                              <Chip
                                label={Employeeformdata.photo.name}
                                onClick={() => {
                                  HandleImageDailogBox(Employeeformdata.photo);
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={6}>
                            Birth Certificate
                          </Grid>

                          <Grid item md={2}>
                            {blankValidator(
                              Employeeformdata.birth_cert.name
                            ) && (
                              <Chip
                                label={Employeeformdata.birth_cert.name}
                                onClick={() => {
                                  HandleImageDailogBox(
                                    Employeeformdata.birth_cert
                                  );
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={6}>
                            Passport
                          </Grid>

                          <Grid item md={2}>
                            {blankValidator(Employeeformdata.passport.name) && (
                              <Chip
                                label={Employeeformdata.passport.name}
                                onClick={() => {
                                  HandleImageDailogBox(
                                    Employeeformdata.passport
                                  );
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={6}>
                            Certificate
                          </Grid>

                          <Grid item md={2}>
                            {blankValidator(
                              Employeeformdata.certificate.name
                            ) && (
                              <Chip
                                label={Employeeformdata.certificate.name}
                                onClick={() => {
                                  HandleImageDailogBox(
                                    Employeeformdata.certificate
                                  );
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={6}>
                            Tax / Pan
                          </Grid>

                          <Grid item md={2}>
                            {blankValidator(Employeeformdata.tax.name) && (
                              <Chip
                                label={Employeeformdata.tax.name}
                                onClick={() => {
                                  HandleImageDailogBox(Employeeformdata.tax);
                                }}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Other Documents
                          </Grid>
                        </Grid>
                        <Grid className="mt-3 mb-2">
                          {Employeeformdata.others !== undefined &&
                          Employeeformdata.others !== null ? (
                            <div item md={1} className="div_main_div">
                              {Employeeformdata.others.map(
                                (item, index) =>
                                  blankValidator(item) &&
                                  blankValidator(item.name) && (
                                    <Chip
                                      className="ml-1"
                                      label={item.name.substring(0, 25)}
                                      onClick={() => {
                                        HandleImageDailogBox(item);
                                      }}
                                      color="primary"
                                      variant="outlined"
                                    />
                                  )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={HandleImageDailogBoxClose}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={HandleImageDailogBoxClose}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + imagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(EmployeeProfileView));
