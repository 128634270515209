import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TablePagination from "@material-ui/core/TablePagination";
//--------------------api calls-----------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
//--------------------redux-----------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Loder from "../../Loder/Loder";
import { blankValidator, timeStampToDate } from "../../../utils/Validation";

const InvoiceClass = ({ manageInstitution, navigate }) => {
  //--------------------------------global id--------------------------------
  let institution_id = parseInt(manageInstitution.institution_id);
  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  //--------------------------------api calls--------------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //--------------------------------local state--------------------------------
  const [classInvoiceArr, setClassInvoiceArr] = useState([]);
  const [invoiceArr, setInvoiceArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [isupdated, setIsupdated] = useState(false);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //--------------------------------methods--------------------------------
  useEffect(() => {
    setisloading(true);
    client
      .query({
        query: Service.Get_Manage_Grade,
        variables: {
          academic_session_id,
        },
      })
      .then((response) => {
        setClassArr(response.data.getGrade);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.log("catch error", error);
      });
    return () => {
      setIsupdated(false);
    };
  }, [client, academic_session_id, isupdated]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const selectClassHandler = (classId) => {
    if (parseInt(classId) === -1) {
      setSectionArr([]);
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectSectionHandler = (sectionId) => {
    if (parseInt(sectionId) === -1) {
      setClassInvoiceArr([]);
      setInvoiceArr([]);
      return;
    }

    client
      .query({
        query: Service.Get_Class_Invoice_API,
        variables: {
          section_id: parseInt(sectionId),
        },
      })
      .then((response) => {
        setClassInvoiceArr(response.data.getClassInvoice);
        setInvoiceArr(response.data.getClassInvoice);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.log("catch error", error);
      });
  };
  const selectInvoiceHandler = (invoiceId) => {
    if (parseInt(invoiceId) === -1) return;
    const filtArr = invoiceArr.filter((item) => {
      return parseInt(item.invoice.id) === parseInt(invoiceId);
    });
    setClassInvoiceArr(filtArr);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>

            <Holiday name={manageInstitution.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Class Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  selectClassHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Class</option>
                                {classArr.length > 0 ? (
                                  classArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))
                                ) : (
                                  <option vlaue={-1}>No Data</option>
                                )}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  selectSectionHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Section</option>
                                {sectionArr.length > 0 ? (
                                  sectionArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  selectInvoiceHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Invoice</option>
                                {invoiceArr.length > 0 ? (
                                  invoiceArr.map((item) => (
                                    <option value={item.invoice.id}>
                                      {item.invoice.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value={-1}>No data</option>
                                )}
                              </select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Start Date"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="End Date"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="btn_add mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration"
                              >
                                Search
                              </Button>
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="mt-3 mb-1 Heading_bold">Invoice</div>

                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin mt-2 mb-2">
                            <div className="table_foramtitng">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Invoice Title
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Invoice Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Due Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Status
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? classInvoiceArr.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : classInvoiceArr
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.invoice)
                                            ? ""
                                            : !blankValidator(row.invoice.name)
                                            ? ""
                                            : row.invoice.name}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {!blankValidator(row.invoice)
                                            ? ""
                                            : !blankValidator(
                                                row.invoice.create_date
                                              )
                                            ? ""
                                            : timeStampToDate(
                                                row.invoice.create_date
                                              )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.invoice)
                                            ? ""
                                            : !blankValidator(
                                                row.invoice.due_date
                                              )
                                            ? ""
                                            : timeStampToDate(
                                                row.invoice.due_date
                                              )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(row.status)
                                            ? ""
                                            : row.status}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          <span
                                            className="row_details_color"
                                            onClick={() =>
                                              navigate("/editFeeInvoice", {
                                                invoiceObj: row,
                                                pageType: "ClassInvoice",
                                              })
                                            }
                                          >
                                            Open
                                          </span>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={classInvoiceArr.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),

});

export default connect(mapStateToProps, null)(HOC1(InvoiceClass));
