import React, { useState, useEffect } from "react";
// import "./Notice.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
// import Chip from "@material-ui/core/Chip";
// import TextField from "@material-ui/core/TextField";
import RichTextEditor from "react-rte";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder.jsx";
import { useApolloClient } from "@apollo/client";

//----------api calls------------------
import * as Service from "../../../services/index";
import axios from "axios";
import {
  blankValidator,
  emailValidator,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";

//------------------------------redux---------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { te } from "date-fns/locale";
import Autocomplete from "./Autocomplete.js";
const CommonNotificationCompose = (props) => {
  let userMailID = props.UserInfo.login_id;
  const [sendInternalEmail, {}] = useMutation(Service.sendInternalEmail);
  let academic_session_id = parseInt(props.dataacademic?.id);
  if (props.UserInfo.usertype === "ADMIN") {
    academic_session_id = parseInt(props.UserInfo?.academic_session_id);
  }
  let institution_id = parseInt(props.manageInstitution.institution_id);
  //Institute name

  const [filename, setFileName] = useState("Add Attachment");
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [subject, setsubject] = useState("");
  const [selectedvalue, setSelectedvalue] = useState([]);
  const [options] = useState([{ name: "" }]);
  const [isloading, setisloading] = useState(false);
  const [uploadFileArr, setUploadFileArr] = useState([]);
  const [selectedvalueOption, setSelectedvalueOption] = useState([]);
  const client = useApolloClient();
  //error
  const [emailToError, setemailToError] = useState(false);
  const [subjectError, setsubjectError] = useState(false);
  const [messageerror, setmessageerror] = useState(false);
  const [userTypeValue, setUserTypeValue] = useState("Teacher");
  const [userTypeTeacher, setUserTypeTeacher] = useState("Teacher");
  const [gradeId, setGradeId] = useState(-1);
  const [sectionArr, setSectionArr] = useState([]);
  const [studentID, setStudentID] = useState(-1);
  const [sectionId, setSectionId] = useState(-1);
  const [gradeArr, setGradeArr] = useState([]);
  const [StudentListArr, setStudentListArr] = useState([]);
  const [studentList, setstudentList] = useState([]);
  const [selectParentId, setSelectParentId] = useState(-1);

  const [departmentID, setDepartmentID] = useState(-1);

  const [userTypeAdmin, setUserTypeAdmin] = useState("Teacher");
  const [userTypeEmployer, setUserTypeEmployer] = useState("Teacher");

  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);

      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          let docimagepath = response.data.path.replace("\\", "/");
          const obj = {
            path: `${Service.baseUrl}/${docimagepath}`,
            filename: response.data.filename + response.data.type,
          };
          setFileName(file.name);
          setUploadFileArr([...uploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const EmailMessage = (value) => {
    setmessageerror(false);
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  const sendEmailHandler = async () => {
    if (!blankValidator(subject)) {
      setsubjectError(true);
      return;
    }
    if (value.toString("html") === "<p><br></p>") {
      setmessageerror(true);
      return;
    }
    let temp = [];
    selectedvalue.map((item) => {
      temp.push({
        to: item.emailId,
      });
    });

    try {
      setisloading(true);
      const { data } = await sendInternalEmail({
        variables: {
          from: userMailID,
          to: temp,
          subject: subject,
          Text: value.toString("html"),
          attachments: uploadFileArr,
        },
      });
      if (data && data.sendInternalEmail) {
        const { sendInternalEmail } = data;
        showNotificationMsz(
          sendInternalEmail.message,
          sendInternalEmail.errorCode === 0 ? "danger" : "success"
        );
        props.navigate("/notification");
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log(" error", error);
    }
  };

  const deleteDocsHandler = (index) => {
    uploadFileArr.splice(index, 1);
    setUploadFileArr([...uploadFileArr]);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setGradeArr(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  }, [client, academic_session_id]);
  useEffect(() => {
    if (props.UserInfo.usertype == "STUDENT") {
      getTeacherList(-1, "Teacher");
    }
  }, [props.UserInfo]);

  useEffect(() => {
    getTeacherList(departmentID, "Teacher");
  }, [departmentID]);

  const getTeacherList = (deparmentID, UserType) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Employee_All_data,
          variables: {
            institution_id,
            department_id: deparmentID,
          },
        })
        .then(async (response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getEmployee.length;
            index++
          ) {
            let element = response?.data?.getEmployee[index];
            if (UserType !== "Teacher") {
              if (element?.designation?.name !== "Teacher") {
                temp.push({
                  name:
                    getFullName(element?.name) +
                    " - " +
                    element?.designation?.name,
                  id: element?.id,
                  emailId: element?.login?.login_id,
                });
              }
            } else {
              if (element?.designation?.name === "Teacher") {
                temp.push({
                  name:
                    getFullName(element?.name) +
                    " - " +
                    element?.designation?.name,
                  id: element?.id,
                  emailId: element?.login?.login_id,
                });
              }
            }
          }

          await setSelectedvalueOption(temp);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch err", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const radioHandler = (event) => {
    setUserTypeValue(event.target.value);
    if (event.target.value === "Staff") {
      getTeacherList(departmentID, "Staff");
    } else {
      getTeacherList(departmentID, "Teacher");
    }
  };
  const radioHandlerTeacher = (event) => {
    if (event.target.value === "Teacher") {
      getTeacherList(departmentID, "Teacher");
      setSelectedvalue([]);
    } else {
      setSelectedvalueOption([]);
    }
    setUserTypeTeacher(event.target.value);
  };
  const selectGrade = (id) => {
    setGradeId(parseInt(id));
    setSectionArr([]);
    setstudentList([]);
    setStudentID(-1);
    setSectionId(-1);
    if (id == -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectSection = (id) => {
    try {
      let section_id = parseInt(id);
      setSectionId(section_id);
      setStudentListArr([]);

      if (section_id == -1) {
        return;
      }

      setisloading(true);
      client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id: parseInt(id),
          },
        })
        .then((response) => {
          const filterArr = response.data.getStudentListBySectionId;

          let option = [];
          for (let index = 0; index < filterArr.length; index++) {
            const element = filterArr[index];

            option.push({
              name: getFullName(element?.student_profile?.name),
              id: element?.id,
              emailId: element?.login?.login_id,
            });
          }

          setSelectedvalueOption(option);

          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  // admin flow
  const radioHandlerAdmin = (event) => {
    if (event.target.value === "Teacher") {
      getTeacherList(departmentID, "Teacher");
    } else if (event.target.value === "Staff") {
      getTeacherList(departmentID, "Staff");
    } else {
      setSelectedvalueOption([]);
    }
    setUserTypeAdmin(event.target.value);
  };
  const radioHandlerEmployer = (event) => {
    if (event.target.value === "Teacher") {
      getTeacherList(departmentID, "Teacher");
    } else if (event.target.value === "Staff") {
      getTeacherList(departmentID, "Staff");
    } else {
      setSelectedvalueOption([]);
    }
    setUserTypeEmployer(event.target.value);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div
                  className="enquiry_heading"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="mt-3 ml-3 Heading_bold">Compose</div>
                  <div className="mt-3 ml-3">
                    <span
                      className="openassignemntcolor mr-2"
                      onClick={() => props.navigate("/notification")}
                    >
                      Inbox
                    </span>
                    <span
                      className="openassignemntcolor mr-2"
                      onClick={() => props.navigate("/notification")}
                    >
                      Sent
                    </span>
                    {/* <span
                      className="openassignemntcolor mr-2"
                      onClick={() =>props.navigate("/notification")}
                    >
                      Trash
                    </span> */}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="">
                              <label for="exampleInputEmail1">To</label>

                              {props.data.usertype === "PARENT" && (
                                <div className="compose_flex_end mt-2">
                                  <div className="form-check ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={userTypeValue === "Teacher"}
                                      value="Teacher"
                                      onChange={(e) => radioHandler(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      Teacher
                                    </label>
                                  </div>
                                  <div className="form-check ml-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault3"
                                      checked={userTypeValue === "Staff"}
                                      value="Staff"
                                      onChange={(e) => radioHandler(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault3"
                                    >
                                      Staff
                                    </label>
                                  </div>
                                </div>
                              )}
                              {props.data.usertype === "TEACHER" && (
                                <div className="compose_flex_end mt-2">
                                  <div className="form-check ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={userTypeTeacher === "Student"}
                                      value="Student"
                                      onChange={(e) => radioHandlerTeacher(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      Student
                                    </label>
                                  </div>
                                  <div className="form-check ml-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault3"
                                      checked={userTypeTeacher === "Parent"}
                                      value="Parent"
                                      onChange={(e) => radioHandlerTeacher(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault3"
                                    >
                                      Parent
                                    </label>
                                  </div>
                                  <div className="form-check ml-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault4"
                                      checked={userTypeTeacher === "Teacher"}
                                      value="Teacher"
                                      onChange={(e) => radioHandlerTeacher(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault4"
                                    >
                                      Teacher
                                    </label>
                                  </div>
                                </div>
                              )}
                              {props.data.usertype === "ADMIN" && (
                                <div className="compose_flex_end mt-2">
                                  <div className="form-check ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={userTypeAdmin === "Student"}
                                      value="Student"
                                      onChange={(e) => radioHandlerAdmin(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      Student
                                    </label>
                                  </div>
                                  <div className="form-check ml-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault3"
                                      checked={userTypeAdmin === "Parent"}
                                      value="Parent"
                                      onChange={(e) => radioHandlerAdmin(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault3"
                                    >
                                      Parent
                                    </label>
                                  </div>
                                  {props.data.type === "PT" ||
                                  props.data.type === "ET" ? (
                                    ""
                                  ) : (
                                    <>
                                      <div className="form-check ml-2">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault4"
                                          checked={userTypeAdmin === "Teacher"}
                                          value="Teacher"
                                          onChange={(e) => radioHandlerAdmin(e)}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexRadioDefault4"
                                        >
                                          Teacher
                                        </label>
                                      </div>
                                      <div className="form-check ml-2">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault5"
                                          checked={userTypeAdmin === "Staff"}
                                          value="Staff"
                                          onChange={(e) => radioHandlerAdmin(e)}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexRadioDefault4"
                                        >
                                          Staff
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                              {props.data.usertype !== "PARENT" &&
                                props.data.usertype !== "TEACHER" &&
                                props.data.usertype !== "ADMIN" &&
                                props.data.usertype !== "STUDENT" && (
                                  <div className="compose_flex_end mt-2">
                                    <div className="form-check ml-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault3"
                                        checked={userTypeEmployer === "Parent"}
                                        value="Parent"
                                        onChange={(e) =>
                                          radioHandlerEmployer(e)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault3"
                                      >
                                        Parent
                                      </label>
                                    </div>

                                    <div className="form-check ml-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault4"
                                        checked={userTypeEmployer === "Teacher"}
                                        value="Teacher"
                                        onChange={(e) =>
                                          radioHandlerEmployer(e)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault4"
                                      >
                                        Teacher
                                      </label>
                                    </div>
                                    <div className="form-check ml-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        checked={userTypeEmployer === "Staff"}
                                        value="Staff"
                                        onChange={(e) =>
                                          radioHandlerEmployer(e)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault2"
                                      >
                                        Staff
                                      </label>
                                    </div>
                                  </div>
                                )}
                              {(userTypeTeacher === "Student" ||
                                userTypeTeacher == "Parent" ||
                                userTypeAdmin === "Student" ||
                                userTypeAdmin === "Parent" ||
                                userTypeEmployer === "Parent") && (
                                <div>
                                  {" "}
                                  <Grid className="grid_main_div mt-2 mb-1">
                                    <Grid item md={3}>
                                      <div className="inputMarginright">
                                        <select
                                          className="form-control"
                                          id="exampleFormControlSelect1"
                                          value={gradeId}
                                          onChange={(e) =>
                                            selectGrade(e.target.value)
                                          }
                                        >
                                          <option value={-1}>
                                            Search By Class
                                          </option>

                                          {gradeArr.map((item, index) => (
                                            <option value={item.id} key={index}>
                                              {item.gradeName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div>
                                        <select
                                          className="form-control"
                                          id="exampleFormControlSelect1"
                                          value={sectionId}
                                          onChange={(e) =>
                                            selectSection(e.target.value)
                                          }
                                        >
                                          <option value={-1}>
                                            Search By Section
                                          </option>
                                          {sectionArr.map((item, index) => (
                                            <option value={item.id} key={index}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </Grid>
                                    <Grid item md={6}></Grid>
                                  </Grid>
                                </div>
                              )}
                              <div>
                                {/* <Autocomplete
                                  value={selectedvalue}
                                  multiple
                                  freeSolo
                                  options={selectedvalueOption}
                                  onSearch={handleOnSearch}
                                  renderGroup={(param) => param}
                                  
                                  onChange={(e) => {
                                    if (e.target.value === undefined) {
                                      selectedvalue.length = 0;
                                      setSelectedvalue(selectedvalue);
                                      return;
                                    }
                                    setemailToError(false);
                                    console.log(
                                      "e.target.innerHTML",
                                      e.target.innerHTML
                                    );
                                    if (e.target.value === 0) {
                                      if (!blankValidator(e.target.innerHTML)) {
                                        return;
                                      }
                                      selectedvalue.push(e.target.innerHTML);
                                    } else {
                                      // if (!blankValidator(e.target.value)) {
                                      //   return;
                                      // }
                                      // selectedvalue.push(e.target.value);
                                    }
                                    setSelectedvalue(selectedvalue);
                                  }}
                                  freeSolo
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        variant="outlined"
                                        label={option}
                                        {...getTagProps({ index })}
                                        onDelete={() => {
                                          let tempIndex =
                                            selectedvalue.indexOf(option);
                                          if (tempIndex >= 0) {
                                            selectedvalue.splice(tempIndex, 1);
                                            setSelectedvalue([
                                              ...selectedvalue,
                                            ]);
                                          }
                                        }}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => {
                                    console.log("params amit ", params);
                                    return (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder=""
                                      />
                                    );
                                  }}
                                /> */}
                                {emailToError && (
                                  <span className="text-danger">
                                    Enter Valid Email Address
                                  </span>
                                )}
                              </div>

                              <div>
                                <Autocomplete
                                  suggestions={selectedvalueOption}
                                  selectedvalue={selectedvalue}
                                  setSelectedvalue={setSelectedvalue}
                                ></Autocomplete>
                              </div>
                            </div>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">Subject</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={subject}
                                  onChange={(e) => {
                                    setsubjectError(false);
                                    setsubject(e.target.value);
                                  }}
                                />
                                {subjectError && (
                                  <span className="text-danger">
                                    Enter Subject of Email
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <label for="exampleInputEmail1">Message</label>
                          <div className="mt-1">
                            <RichTextEditor
                              value={value}
                              onChange={EmailMessage}
                              className="editor_height"
                            />
                            {messageerror && (
                              <span className="text-danger">
                                Enter Message of Email
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="mt-3 mb-2 d-flex">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile01"
                                onChange={ImageUploadHandler}
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                              >
                                {filename}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>Attachment</div>
                        <div className="mt-2 attachList ml-2">
                          {uploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 docimage"
                              key={index}
                            >
                              <span className="pl-2 pr-2">{item.filename}</span>
                              <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() => deleteDocsHandler(index)}
                                ></i>
                              </span>
                            </div>
                          ))}
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={sendEmailHandler}
                              >
                                Send
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(CommonNotificationCompose));
