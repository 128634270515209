import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";

import Loder from "../../../Loder/Loder";
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
const SectionInvitelist = (props) => {
  let event_id = props.id;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isloading, setisloading] = useState(false);
  const [classlistArray, setClasslistArray] = useState([]);
  const client = useApolloClient();
  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_Event_Participants_Group,
          variables: {
            event_id,
            group_type: "SECTION",
            user_type: props.user_type,
          },
        })
        .then((response) => {
          if (response.data.getEventParticipantsGroup) {
            setClasslistArray(response.data.getEventParticipantsGroup);
          }

          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [props]);
  return (
    <div>
      <div className="font-weight-bold">
        <div className="table_foramtitng mt-3 mb-2">
          <TableContainer
            className="myinvitee"
            component={Paper}
            style={{ marginTop: 10 }}
          >
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" className="table_header">
                    Type
                  </StyledTableCell>
                  <StyledTableCell align="left" className="table_header">
                    Class
                  </StyledTableCell>
                  <StyledTableCell align="left" className="table_header">
                    Section
                  </StyledTableCell>

                  <StyledTableCell align="left" className="table_header">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? classlistArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : classlistArray
                ).map((row, index) => (
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <span>{row.group_type && row.group_type}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span>{row.section?.grade?.gradeName}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span>{row.section?.name}</span>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <div className="d-flex ">
                        <span
                          role="button"
                          className="text-info pl-1 pr-1"
                          // onClick={() => {}}
                        >
                          Remove
                        </span>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              true
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={classlistArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangerowsPerPage}
            />
          </TableContainer>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});
export default SectionInvitelist;
