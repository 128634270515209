import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";

//-----------api calls---------------
import * as Service from "../../../services/index";
import { showNotificationMsz } from "../../../utils/Validation";
import { useMutation } from "@apollo/client";

function PublishMarkAttendence({ data, date, setIsUpdated, userId, event_id }) {
  var today_Date = new Date().toJSON().slice(0, 10);

  // if (date != undefined) {
  //   today_Date = date;
  // }
  const [isloading, setisloading] = useState(false);
  const [Teacher_Mark_Attendace_API, {}] = useMutation(
    Service.Teacher_Mark_Attendace_API
  );

  const MarkAttendance = () => {
    try {
      let temp = [];
      data.map((item) => {
        if (item.isSelected) {
          temp.push({
            user_id: item.user_id,
            status: "Present",
            user_type: item.user_type,
          });
        } else {
          temp.push({
            user_id: item.user_id,
            status: "Absent",
            user_type: item.user_type,
          });
        }
      });
      setisloading(true);
      Teacher_Mark_Attendace_API({
        variables: {
          attendance_type: "EVENT",
          date: today_Date,
          user_id: temp,
          reference_id: +event_id,
        },
      })
        .then((response) => {
          //  alert(response.data.addAttendance.message);
          showNotificationMsz("Attendance marked successfully", "success");
          setisloading(false);
          setIsUpdated(true);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz("Error in marking attendance", "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    // techer event attendence mark
    try {
      let temp = [];
      temp.push({
        user_id: +userId,
        status: "Present",
        user_type: "Teacher",
      });
      setisloading(true);
      Teacher_Mark_Attendace_API({
        variables: {
          attendance_type: "EVENT",
          date: today_Date,
          user_id: temp,
          reference_id: +event_id,
        },
      })
        .then((response) => {
          setisloading(false);
          setIsUpdated(true);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz("Error in marking attendance", "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div>
      <Grid className="grid_main_div mt-2">
        <Grid item md={10}></Grid>
        <Grid item md={2}>
          <div className="main_button_div  mb-3">
            <Button
              onClick={MarkAttendance}
              variant="contained"
              disabled={today_Date > date || today_Date < date ? true : false}
              className="add_new_query_btn button_width button_login_decoration"
            >
              Publish
            </Button>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isloading} />
    </div>
  );
}

export default PublishMarkAttendence;
