import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import { useApolloClient } from "@apollo/client";

//redux
import * as Service from "../../../services/index";
import Loder from "../../Loder/Loder.jsx";
import MangeAllTimeData from "./MangeAllTimeData.jsx";
const ManageAllTimeTable = (props) => {
  //name instituition
  let Organization_Name = props.manageInstitution.institution_name;
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let section_id = parseInt(props.location.state);
  const [isloading, setisloading] = useState(false);
  const [allTimeTableData, setAllTimeTableData] = useState([]);
  const client = useApolloClient();
  useEffect(() => {
    AllTimeTableGet();
  }, []);
  const AllTimeTableGet = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_All_Timetable_Data,
          variables: {
            institution_id,
            section_id,
            day: 1,
          },
        })
        .then((response) => {
          setAllTimeTableData(response.data.getAllClassTimeTable);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {}
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">All Time Table</div>
                </div>
                <div className="p-2">
                  {allTimeTableData.map((row) => (
                    <div>
                      <MangeAllTimeData
                        data={row}
                        section_id={section_id}
                        institution_id={institution_id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(ManageAllTimeTable)));
