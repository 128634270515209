import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import CustomeRoutes from "./routes/index";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import store from "./redux/store";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { baseUrl } from "./services";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useNavigate, useLocation } from "react-router-dom";
// --------to update data with change--------
const defaultOptions = {
  watchQuery: { fetchPolicy: "no-cache", errorPolicy: "ignore" },
  query: { fetchPolicy: "no-cache", errorPolicy: "all" },
};
// --------to update data with change--------
// --------to connect with backend--------
console.log("baseUrl", baseUrl);
const client = new ApolloClient({
  uri: `${baseUrl}/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
// --------to connect with backend --------

window.onload = () => {
  if (window.location.pathname != "/") {
    window.location = "/";
  }
};
const App = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ReactNotifications />

        {/* <PagereloadHandler navigate={navigate} client={client} /> */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CustomeRoutes navigate={navigate} location={location} />
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
