import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loder from "../../../Loder/Loder.jsx";

import { getFullName, showNotificationMsz } from "../../../../utils/Validation";
import { connect } from "react-redux";
import { getData } from "../../../../api/index";
import * as Constants from "../../../../action/appAction";
import * as Service from "../../../../services/index";

import StudentInviteesListTable from "../GetList/StudentInviteesList";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";

const StudentInviteesList = (props) => {
  const [InviteParticipantAPI, {}] = useMutation(
    Service.Add_Event_Participants
  );

  // let classId;
  // // let sectionId;
  // let studentID_temp;
  // if (props.from === "report") {
  //   classId = props.location.state.json.studentClassId;
  //   // sectionId = props.location.state.json.SectionId;
  //   studentID_temp = props.location.state.json.userId;
  // } else {
  //   classId = props.location.state.classId;
  //   // sectionId = props.location.state.sectionId;
  // }

  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [sectionId, setSectionId] = useState(-1);
  const [studentList, setstudentList] = useState([]);
  const [gradeId, setGradeId] = useState(-1);
  const [classID, setClassID] = useState(-1);
  const [StudentListArr, setStudentListArr] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [studentID, setStudentID] = useState(-1);
  let academic_session_id = parseInt(props.academic_session_id);
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setGradeArr(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  }, [client, academic_session_id]);

  const selectGrade = (id) => {
    setGradeId(parseInt(id));
    setSectionArr([]);
    setstudentList([]);
    setStudentID(-1);
    setSectionId(-1);
    if (id == -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const InviteUser = async () => {
    if (studentID === -1) {
      alert("Please select Student to invite");
    }

    let StudentInviteArray = [];
    if (studentID === 0) {
      StudentListArr.map((student_data) => {
        StudentInviteArray.push({
          user_id: student_data.user_id,
          email: " ",
          user_type: "STUDENT",
        });
      });
    } else {
      StudentInviteArray = [
        { user_id: studentID, email: " ", user_type: "STUDENT" },
      ];
    }

    setisloading(true);
    await InviteParticipantAPI({
      variables: {
        event_id: +props.eventid,
        event_invite_type: "STUDENT",
        participants: StudentInviteArray,
      },
    })
      .then((response) => {
        setIsUpdated(!isUpdated);
        setisloading(false);
        showNotificationMsz("Invite sent Successfully", "success");
      })
      .catch((error) => {
        setisloading(false);
      });
  };

  const selectSection = (id) => {
    try {
      let section_id = parseInt(id);
      setSectionId(section_id);
      setStudentListArr([]);
      if (section_id == -1) {
        return;
      }

      setisloading(true);
      client
        .query({
          query: Service.Get_student_list_on_event_by_SectionID,
          variables: {
            section_id: parseInt(id),
            event_id: parseInt(props.eventid),
            user_type: "STUDENT",
          },
        })
        .then((response) => {
          const filterArr = response.data.getStudentListForEvent;
          const filtArr = filterArr.filter((arr) => {
            if (arr.status === "ACTIVE") {
              return arr;
            }
          });

          let temp = [];
          for (let index = 0; index < filtArr.length; index++) {
            const element = filtArr[index];
            temp.push({
              user_id: element.id,
              name: element?.student_profile?.name,
            });
          }

          setStudentListArr(temp);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const classHandler = (clId) => {
    if (isFirstTime) {
      setSectionId(-1);
      setStudentID(-1);
      setSectionArr([]);
      setStudentListArr([]);
    } else {
      setIsFirstTime(true);
    }

    if (parseInt(clId) == -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(clId),
          },
        })
        .then((response) => {
          setisloading(false);

          setSectionArr(response.data.getSectionAndGrade);
          // setSectionfromgrade(response.data.Sections)
          //setname("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <Grid className="grid_main_div mt-3">
        <Grid item md={12}>
          <div className="mt-2">
            <Grid className="grid_main_div mt-2">
              <Grid item md={3}>
                <div className="inputMarginright">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={gradeId}
                    onChange={(e) => selectGrade(e.target.value)}
                  >
                    <option value={-1}>Search By Class</option>

                    {gradeArr.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.gradeName}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item md={3}>
                <div>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={sectionId}
                    onChange={(e) => selectSection(e.target.value)}
                  >
                    <option value={-1}>Search By Section</option>
                    {sectionArr.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="pl-1">
                  <select
                    className="form-control"
                    onChange={(e) => setStudentID(parseInt(e.target.value))}
                    value={studentID}
                  >
                    <option value={-1}>Select Student</option>
                    {StudentListArr.length > 0 && (
                      <option value={0}>All</option>
                    )}
                    {StudentListArr.length > 0 &&
                      StudentListArr.map((item) => (
                        <option value={item.user_id}>
                          {getFullName(item?.name)}
                          {item.user_id}
                        </option>
                      ))}
                  </select>
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="pl-1">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration button_width"
                    onClick={InviteUser}
                  >
                    Invite
                  </Button>
                </div>
              </Grid>
            </Grid>
            <div>
              <StudentInviteesListTable
                {...props}
                updated={isUpdated}
              ></StudentInviteesListTable>
            </div>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  token: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(StudentInviteesList);
