import React, { useState, useEffect } from "react";
import Hoc1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import {
  blankValidator,
  showNotificationMsz,
  emailValidator,
} from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import {
  Update_Admin,
  Create_Admin,
  GET_Institution_Details,
} from "../../../services/index";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { useMutation, useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const CreateUpdateAdmin = (props) => {
  const { name, pagetype, organization_id, editInstitutionData } =
    props.location.state;
  console.log("prosp-s-----", props);
  //---------------------api calls-------------------------
  const client = useApolloClient();
  const [updateAdminAPI, {}] = useMutation(Update_Admin);
  const [createAdminAPI, {}] = useMutation(Create_Admin);
  //---------------------local state-------------------------
  const [institutionarr, setInstitutionarr] = useState([]);
  const [first_nameError, setfirst_nameError] = useState(false);
  const [last_nameError, setlast_nameError] = useState(false);
  const [instituution_idError, setinstituution_idError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [emailcorrectError, setemailcorrectError] = useState(false);
  const [createInsForm, setCreateInsForm] = useState({
    organisationname: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    access_level: "",
    institution_id: -1,
    display_id: "",
    password_salt: "",
    email_address: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  //---------------------local state-------------------------

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pagetype === "edit") {
      const { login, name, id, email_address } = editInstitutionData;
      setCreateInsForm({
        first_name: name.first_name,
        middle_name: name.middle_name,
        last_name: name.last_name,
        institution_id: +id,
        email_address,
      });
    }

    getInstitutionDetails();
  }, [pagetype]);

  const getInstitutionDetails = async () => {
    if (!blankValidator(organization_id)) {
      showNotificationMsz("Please contact to admin", "info");
    } else {
      try {
        setIsLoading(true);
        const { data } = await client.query({
          query: GET_Institution_Details,
          variables: {
            organization_id: +organization_id,
          },
        });

        if (data && data.institutions) {
          setInstitutionarr(data.institutions.reverse());
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("Error", error);
      }
    }
  };

  const submitAdmin = async () => {
    if (pagetype === "edit") {
      try {
        let flag = false;
        if (!blankValidator(createInsForm.first_name)) {
          setfirst_nameError(true);
          flag = true;
        }

        if (!blankValidator(createInsForm.last_name)) {
          setlast_nameError(true);
          flag = true;
        }
        if (!emailValidator(createInsForm.email_address)) {
          setemailError(true);
          flag = true;
        }
        if (flag) {
          return;
        }

        setIsLoading(true);
        const { data } = await updateAdminAPI({
          variables: {
            id: createInsForm.institution_id,
            first_name: createInsForm.first_name,
            middle_name: createInsForm.middle_name,
            last_name: createInsForm.last_name,
            email_address: createInsForm.email_address,
          },
        });
        if (data && data.updateAdmin) {
          const { message } = data.updateAdmin;
          showNotificationMsz(message, "success");
          props.navigate.goBack();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("Error", error);
      }
    } else
      try {
        let flag = false;
        if (!blankValidator(createInsForm.first_name)) {
          setfirst_nameError(true);
          flag = true;
        }

        if (!blankValidator(createInsForm.last_name)) {
          setlast_nameError(true);
          flag = true;
        }

        if (!emailValidator(createInsForm.email_address)) {
          setemailError(true);
          flag = true;
        }
        if (!blankValidator(createInsForm.institution_id)) {
          setinstituution_idError(true);
          flag = true;
        }

        if (flag) {
          return;
        }

        setIsLoading(true);
        const { data } = await createAdminAPI({
          variables: {
            organization_id: +organization_id,
            first_name: createInsForm.first_name,
            middle_name: createInsForm.middle_name,
            last_name: createInsForm.last_name,
            institution_id: createInsForm.institution_id,
            email_address: createInsForm.email_address,
          },
        });

        if (data && data.addAdmin) {
          const { message } = data.addAdmin;
          showNotificationMsz(message, "success");
          props.navigate.goBack();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("Error", error);
      }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    {pagetype === "edit" ? (
                      <div>Update Admin</div>
                    ) : (
                      <div>Create Admin for organization ({name && name})</div>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">Name</div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className=" mt-1 mr-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="First Name"
                                autoComplete="off"
                                value={createInsForm.first_name}
                                onChange={(e) => {
                                  setfirst_nameError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    first_name: e.target.value,
                                  });
                                }}
                              />
                              {first_nameError && (
                                <span className="text-danger">
                                  Ente the First Name
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className=" mt-1 mr-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Middle Name"
                                autoComplete="off"
                                value={createInsForm.middle_name}
                                onChange={(e) => {
                                  setCreateInsForm({
                                    ...createInsForm,
                                    middle_name: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className=" mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Last Name"
                                autoComplete="off"
                                value={createInsForm.last_name}
                                onChange={(e) => {
                                  setlast_nameError(false);
                                  setCreateInsForm({
                                    ...createInsForm,
                                    last_name: e.target.value,
                                  });
                                }}
                              />
                              {last_nameError && (
                                <span className="text-danger">
                                  Ente the Last Name
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        {pagetype === "edit" && (
                          <div>
                            <Grid className="grid_main_div">
                              <Grid item md={6}>
                                <div className="text_filed_heading mt-2">
                                  Email
                                </div>
                                <div className=" mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Email Address"
                                    autoComplete="off"
                                    value={createInsForm.email_address}
                                    onChange={(e) => {
                                      setemailError(false);
                                      setemailcorrectError(false);
                                      setCreateInsForm({
                                        ...createInsForm,
                                        email_address: e.target.value,
                                      });
                                    }}
                                  />
                                  {emailError && (
                                    <span className="text-danger">
                                      Enter the Email Address
                                    </span>
                                  )}
                                  {emailcorrectError && (
                                    <span className="text-danger">
                                      Enter the Correct Email Address
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item md={6}></Grid>
                            </Grid>
                          </div>
                        )}
                        {pagetype !== "edit" && (
                          <div>
                            <Grid className="grid_main_div">
                              <Grid item md={6}>
                                <div className="text_filed_heading mt-2">
                                  {" "}
                                  Institution
                                </div>
                                <div className="inputMarginright mt-1">
                                  <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                    value={createInsForm.institution_id}
                                    onChange={(e) => {
                                      setinstituution_idError(false);
                                      setCreateInsForm({
                                        ...createInsForm,
                                        institution_id: parseInt(
                                          e.target.value
                                        ),
                                      });
                                    }}
                                  >
                                    <option value={-1}>Institution</option>

                                    {institutionarr.length > 0 &&
                                      institutionarr.map((item, index) => (
                                        <option value={item.id} key={index}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Grid>
                              <Grid item md={6}>
                                <div className="text_filed_heading mt-2">
                                  Email
                                </div>
                                <div className=" mt-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Email Address"
                                    autoComplete="off"
                                    value={createInsForm.email_address}
                                    onChange={(e) => {
                                      setemailError(false);
                                      setemailcorrectError(false);
                                      setCreateInsForm({
                                        ...createInsForm,
                                        email_address: e.target.value,
                                      });
                                    }}
                                  />
                                  {emailError && (
                                    <span className="text-danger">
                                      Enter the Email Address
                                    </span>
                                  )}
                                  {emailcorrectError && (
                                    <span className="text-danger">
                                      Enter the Correct Email Address
                                    </span>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <div className="main_button_div  mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration button_width"
                                onClick={submitAdmin}
                              >
                                {pagetype === "edit" ? "Update" : "Submit"}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc1(CreateUpdateAdmin));
