import React, { useState, useEffect } from "react";
import "./Onboarding.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import {
  blankValidator,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation";
import Checkbox from "@material-ui/core/Checkbox";
//-----------------redux-------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------------api calls----------------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

const AssignOnboardStudent = ({ manageInstitution, navigate }) => {
  //-----------------------------global id----------------------
  const { academic_session_id, institution_id, institution_name } =
    manageInstitution;
  let institution_Id = parseInt(institution_id);
  let academic_session_ID = parseInt(academic_session_id);

  //-----------------------------api calls----------------------
  const classes = useStyles();
  const client = useApolloClient();
  const [Assign_Section_Student_API, {}] = useMutation(
    Service.Assign_Section_Student_API
  );
  //-----------------------------local state----------------------
  const [allChecked, setAllChecked] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [sectionArr, setSectionArr] = useState([]);
  const [assignId, setAssignId] = useState("");
  const [classArr, setClassArr] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [StudentsArr, setStudentsArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [searchname, setsearchname] = useState("");
  const [searchStudentName, setSearchStudentName] = useState("");
  //-----------------------------methods----------------------

  useEffect(() => {
    window.scrollTo(0, 0);
    const status = [
      { status: "Form Submission Pending" },
      { status: "Verification Pending" },
      { status: "Enrolled" },
      { status: "ACTIVE" },
      { status: "ACCEPTED" },
      { status: "PROMOTED" },
    ];
    setisloading(true);
    client
      .query({
        query: Service.studentProfile,
        variables: {
          institution_id: institution_Id,
        },
      })
      .then((response) => {
        const myArr = response.data.studentProfile;
        const temp = [];
        myArr.map((item) => {
          if (item.name != null) {
            temp.push({
              isSelected: false,
              item,
            });
          }
        });
        setStudentsArr(temp);
        setisloading(false);
      })
      .catch((error) => {
        showNotificationMsz(error, "danger");
        setisloading(false);
      });

    return () => {
      setIsUpdated(false);
    };
  }, [client, institution_Id, isUpdated]);

  const filterByName = StudentsArr.filter((event) => {
    // if (event.item.section === null) {
    return (
      // event?.item?.student_profile?.grade?.gradeName
      //   .toLowerCase()
      //   .indexOf(searchname.toLowerCase()) !== -1 &&
      getFullName(event.item?.name)
        .toLowerCase()
        .indexOf(searchStudentName.toLowerCase()) !== -1
    );
    // } else {
    //   return (
    //     event.item.section.grade.gradeName
    //       .toLowerCase()
    //       .indexOf(searchname.toLowerCase()) !== -1 &&
    //     getFullName(event.item?.student_profile?.name)
    //       .toLowerCase()
    //       .indexOf(searchStudentName.toLowerCase()) !== -1
    //   );
    // }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const AssignDialogHandler = () => {
    const newArr = StudentsArr.filter((item) => {
      if (item.isSelected) {
        return item;
      }
    });

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_ID),
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);
          setOpenDialog(true);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectSectionHandler = (sectionId) => {
    if (sectionId === -1) return;
    setSectionId(sectionId);
  };
  const selectClassHandler = (classId) => {
    if (classId == -1) {
      setSectionArr([]);
      return;
    }
    setisloading(true);
    client
      .query({
        query: Service.GET_Section_Data,
        variables: {
          grade_id: parseInt(classId),
        },
      })
      .then((response) => {
        const sectionData = response.data.getSectionAndGrade;
        setSectionArr(sectionData);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });
  };

  const [BulkuploadError, setBulkuploadError] = useState([]);

  const AssignSectionToStudent = () => {
    let userIdArr = [];
    StudentsArr.map((item) => {
      if (item.isSelected) {
        userIdArr.push({
          id: parseInt(item.item.id),
        });
      }
    });
    try {
      Assign_Section_Student_API({
        variables: { id: userIdArr, section_id: parseInt(sectionId) },
      })
        .then((response) => {
          setOpenDialog(false);
          setIsUpdated(true);
          showNotificationMsz("Student assigned succesfully", "success");

          setBulkuploadError(response.data.assignSectionToStudent);
          setisloading(false);
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const checkBoxHandler = (id) => {
    let temp = [];
    StudentsArr.map((item) => {
      if (item.item.id === id) {
        item.isSelected = !item.isSelected;
      }
      temp.push(item);
    });

    setStudentsArr(temp);
  };
  const allCheckHandler = () => {
    if (setStudentsArr.length > 0) {
      setAllChecked(!allChecked);
    }

    let temp = [];
    StudentsArr.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      // item.isSelected = !item.isSelected;
      temp.push(item);
    });
    setStudentsArr(temp);
  };
  console.log("filterByName", filterByName);
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="enquiry_heading ">
                          <div className="mt-3 Heading_bold">
                            Assign Student
                          </div>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Class"
                                autoComplete="off"
                                value={searchname}
                                onChange={(e) => {
                                  setsearchname(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by student name"
                                autoComplete="off"
                                value={searchStudentName}
                                onChange={(e) => {
                                  setSearchStudentName(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="mt-4 mb-4 Heading_bold">
                          List Of Students
                        </div>
                        <div className="table_foramtitng">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    <Checkbox
                                      checked={allChecked}
                                      onChange={allCheckHandler}
                                    />
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell> */}
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Actions
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? filterByName.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : filterByName
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      <Checkbox
                                        checked={row.isSelected}
                                        onChange={() =>
                                          checkBoxHandler(row.item.id)
                                        }
                                      />
                                    </StyledTableCell>
                                    {/* <StyledTableCell scope="row" align="left">
                                      {!blankValidator(row.item.section) ? (
                                        <span>
                                          {`${row?.item?.student_profile?.grade?.gradeName}`}
                                        </span>
                                      ) : (
                                        <span>
                                          {`${row.item.section.grade.gradeName}`}
                                        </span>
                                      )}

                                      {BulkuploadError.map((item, index) => (
                                        <div className="text-danger">
                                          {row.item.id === item.id ? (
                                            <span>
                                              {item.errorCode === 1 ? (
                                                <span>{item.message}</span>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ))}
                                    </StyledTableCell> */}
                                    <StyledTableCell align="left">
                                      {getFullName(row.item?.name)}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                    >
                                      {/* <span
                                        style={{
                                          fontSize: 12,
                                          color: "#0070bb",
                                        }}
                                        onClick={() =>
                                          AssignDialogHandler(row.id)
                                        }
                                      >
                                        Assign
                                      </span>{" "}
                                      |{" "} */}
                                      <span
                                        style={{
                                          color: "#0070bb",
                                        }}
                                        onClick={() =>
                                          navigate("/adminstudentlistview")
                                        }
                                      >
                                        View
                                      </span>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filterByName.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={AssignDialogHandler}
                              >
                                Assign
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={openDialog}
        fullWidth="fullWidth"
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <div className="full_Size_Box">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpenDialog(false);
              }}
            ></i>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label htmlFor="">Select Class</label>
                <select
                  class="form-control"
                  onChange={(e) => selectClassHandler(e.target.value)}
                >
                  <option value={-1}>Select Class</option>
                  {classArr.length !== 0 &&
                    classArr.map((item) => (
                      <option value={item.id}>{item.gradeName}</option>
                    ))}
                </select>
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="">Select Section</label>
                <select
                  class="form-control"
                  onChange={(e) => selectSectionHandler(e.target.value)}
                >
                  <option value={-1}>Select Section</option>
                  {sectionArr.length !== 0 &&
                    sectionArr.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </Grid>
            </Grid>

            <div className="text-center mt-3">
              <Button
                variant="contained"
                className="add_new_query_btn button_login_decoration"
                onClick={AssignSectionToStudent}
              >
                Assign
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AssignOnboardStudent));
