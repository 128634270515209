import React from "react";
import Card from "@material-ui/core/Card";
import StudentLeftExpand from "./StudentLeftExpand";
import ViewLeftPenalSubjectforStudent from "./ViewLeftPenalSubjectforStudent";
const StudentStudyLeft = (props) => {
  const { getDataStudyList } = props;
  let key = Object.keys(getDataStudyList);
  return (
    <div>
       <Card className="view_study_left_height">
        <div className="admin_Study_inner_padding">
          {key &&
            key.map((keyValue) => {
              return (
                <ViewLeftPenalSubjectforStudent
                  row={getDataStudyList[keyValue]}
                  className={keyValue}
                  {...props}
                />
              );
            })}
        </div>
      </Card>
    </div>
  );
};

export default StudentStudyLeft;
