import React from "react";
import HOC from "../../Hoc.jsx";

import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
const EndUserAggreement = () => {
  return (
    <div className="main_back_color1">
      <div className="terms_padding_top">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <div className="enquirydetails_background p-3">
                <div className="text-center">
                  <h2>END USER LICENSE AGREEMENT</h2>
                </div>
                <div className="terms_padding_both_card mt-2">
                  <div>
                    <p className="terms_para_font">
                      {" "}
                      This End User License Agreement (“EULA”) is an agreement
                      between EPAL Software Pvt Ltd (“Licensor”, “We”, “us” or
                      “our”) and the customers (“Licensee”, “you”, or “your”) of
                      the Company who have purchased a License to the Software
                      or who have accessed the software for demo purposes and
                      effective from the date of purchase of a license.
                    </p>
                    <p className="terms_para_font pt-2">
                      {" "}
                      PLEASE READ THIS EULA CAREFULLY. THIS EULA CONTAINS
                      IMPORTANT TERMS AND CONDITIONS WHICH GOVERN YOUR USE OF
                      THIS SOFTWARE. THIS EULA CONSTITUTES A BINDING AGREEMENT
                      BETWEEN YOU AND THE LICENSOR.. YOU DON’T NEED TO
                      PHYSICALLY SIGN A COPY OF THIS AGREEMENT. THIS EULA IS
                      AVAILABLE ON OUR WEBSITE . YOU ARE FURTHER HELD TO HAVE
                      ACCEPTED AND BOUND BY THIS AGREEMENT BY USING THE SOFTWARE
                      BEFORE . YOU MAY NEED TO CHECK A BOX, CONFIRMING THAT YOU
                      HAVE AGREED TO THIS EULA BEFORE BEGINNING TO USE THE
                      SOFTWARE.
                    </p>
                  </div>
                  {/* point sections starts */}
                  <div className="terms_of_heading_data">
                    <h3>1) Definitions</h3>
                    <ul>
                      <li>
                        “Intellectual property” shall refer to all intellectual
                        property including but not limited to copyright,
                        trademark, proprietary rights, moral rights, patents or
                        any other form of intellectual property existing in the
                        Software.
                      </li>
                      <li>
                        “EULA” shall refer to this End User License Agreement.
                      </li>
                      <li>
                        “Customer” shall refer to any person who has purchased
                        the Software by paying the license or subscription fee.
                      </li>
                      <li>
                        “Licensor” means the Company specified above, which is
                        granting the license specified in this EULA subject to
                        the terms and conditions specified in this Agreement.
                      </li>
                      <li>
                        “License” means authorization to use the software on
                        SAAS basis and shall refer to the authorization provided
                        by the End User License Agreement subject to terms and
                        conditions specified there.
                      </li>
                      <li>
                        “Licensee” means any person who uses the software by
                        purchase of license subject to the terms and conditions
                        of all applicable agreements.
                      </li>
                      <li>
                        “Services” shall refer to Software as a Service
                        (“SAAS”), offered by the Company to its Users in
                        relation to its software.
                      </li>
                      <li>
                        “Software” shall refer to the School management software
                        designed, developed and owned by the Company and as
                        described in the Site intended to provide School
                        administration and management solutions to
                        organizations.
                      </li>
                    </ul>
                  </div>

                  <div className="terms_of_heading_data">
                    <h3>2) Ownership</h3>
                    <ul>
                      <li>
                        This “Software” is owned and operated by EPAL Software
                        Private Limited. Your use of this Software is governed
                        by these terms and conditions specified in this EULA.
                        This EULA constitutes a binding Agreement between the
                        Users and the Company. Accessing or using the software,
                        shall imply that you have read and accepted this EULA
                        before such access or use. All Users are advised to read
                        this EULA before using this software. A User should
                        cease and desist from accessing or using the Software or
                        using any of its associated services in case, the User
                        does not agree to the terms of this EULA. A breach of
                        the terms of this EULA shall imply a breach of a binding
                        contract between the Licensor and Licensee.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>3) License</h3>
                    <ul>
                      <li>
                        SUBJECT TO THE TERMS AND CONDITIONS OF THIS AGREEMENT
                        AND DURING THE TERM OF THIS AGREEMENT, THE COMPANY
                        HEREBY GRANTS YOU A NON-EXCLUSIVE , NON-SUB LICENSABLE ,
                        NON TRANSFERABLE LICENSE TO USE, ACCESS, A VERSION OF
                        THE SOFTWARE IN OBJECT CODE FORM, BY USING INTERNET
                        CONNECTIVITY AND CLOUD SERVICES, ON A SINGLE COMPUTING
                        DEVICE NETWORK SERVER(FOR THE SINGLE USER LICENSE OF THE
                        SOFTWARE) OR MULTIPLE COMPUTING DEVICE (FOR THE MULTI
                        USER LICENSE OF THE SOFTWARE) ON A LOCAL AREA NETWORK
                        (LAN) SERVER, AS PERMITTED BY THE PURCHASED LICENSE ,
                        SOLELY TO USE THE SERVICES MADE AVAILABLE BY THE COMPANY
                        (THE “COMPANY SERVICES”) FOR YOUR OWN PERSONAL OR
                        INTERNAL BUSINESS USE (THE “PERMITTED PURPOSE”). Any
                        Software that updates, supplements or replaces the
                        original Software is governed by this Agreement unless
                        separate license terms accompany such update, supplement
                        or replacement, in which case such separate terms will
                        govern in the event of a conflict with this Agreement or
                        as otherwise provided in such separate terms. The
                        Software can be accessed by using different devices or
                        on multiple devices at the same time, subject to the
                        license purchased (Single user or multiuser license) and
                        may be accessed by the licensee itself or their
                        authorized agent, personnel, and employees of the
                        licensee, in case the licensee is an institution or
                        organization. The Licensee cannot rent or lease the
                        Software to anyone or use the software for the benefit
                        of anyone other than the licensee. You must immediately
                        cease and desist from using the software and delete any
                        copies you have in your device, in case you do not have
                        a valid license to the software or your license has
                        expired.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>4) Term</h3>
                    <ul>
                      <li>
                        The period specified in the service agreement, for
                        which, the Licensee has purchased a License, shall be
                        held as term of this License. All the terms and
                        conditions specified in this License shall be fully
                        effective and applicable during the term of this License
                        Agreement. Some clauses may be effective even after the
                        terms of this License Agreement ends as specified in
                        clause-14.3 of this EULA. The Licensee must comply with
                        all such terms and conditions before and after the term
                        of this License Agreement ends.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>5) Indemnification</h3>
                    <ul>
                      <li>
                        You will indemnify and hold the Company, its employees,
                        representatives, independent contractors, service
                        providers, suppliers, partners, resellers, distributors,
                        authorized agents, harmless from and against any, i) use
                        you make of the software in breach of the terms of this
                        Agreement. ii) from your breach of any other terms of
                        this EULA iii) from any liability to third parties which
                        may arise due to your use of the Software.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>6) Warranty Disclaimer</h3>
                    <ul>
                      <li>
                        THE “SOFTWARE” IS PROVIDED ON AN “AS IS” BASIS. THE
                        COMPANY PROVIDES NO WARRANTY THAT IT WILL BE FIT FOR A
                        PARTICULAR PURPOSE OR THAT THE PRODUCT WILL BE WORKING
                        AND FUNCTIONAL IN ALL CIRCUMSTANCES. USERS MUST VERIFY
                        WHETHER THE SOFTWARE MEETS THEIR NEEDS AND REQUIREMENTS.
                        THE “SOFTWARE” IS A STANDARD PRODUCT, BUT IT MAY NOT BE
                        COMPLETELY FAULTLESS IN ALL SITUATIONS AND COMBINATIONS.
                        THE “SOFTWARE” WILL HAVE THE GENERAL FUNCTIONALITY AND
                        USABILITY, AS APPEARING ON THE DATE OF PURCHASE AND AS
                        PROVIDED IN THE DEMONSTRATION TO THE LICENSEE BEFORE
                        SUCH PURCHASE. LICENSEE IS SOLELY RESPONSIBLE TO VERIFY
                        BEFORE PURCHASE WHETHER THE SOFTWARE IS SUITABLE TO MEET
                        THEIR NEEDS AND REQUIREMENTS BEFORE PURCHASE. SERVICES
                        MAY BE SOMETIMES UNAVAILABLE DURING MAINTENANCE TIME
                        WITH PRIOR NOTICE OR DOWNTIMES OCCURING DUE TO CAUSES
                        BEYOND CONTROL OF THE LICENSOR.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>7) Limitation of Liability</h3>
                    <ul>
                      <li>
                        IN NO EVENT, SHALL THE LICENSOR, ITS ASSOCIATES,
                        REPRESENTATIVES, ASSOCIATES, SUBSIDIARY COMPANIES,
                        PERSONNEL, EMPLOYEES, OFFICERS, MANAGERIAL PERSONNEL,
                        AGENTS, SHALL BE LIABLE FOR ANY, DIRECT, INDIRECT OR
                        CONSEQUENTIAL , GENERAL OR SPECIAL DAMAGES, OR ANY OTHER
                        DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO,
                        DAMAGES FOR ANY LOSS OF DATA, LOSS OF PROFITS, LOSS OF
                        INCOME, WHETHER IN AN ACTION IN CONTRACT, TORT
                        (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR IN ANY
                        OTHER FORM OF LEGAL ACTION, OR OTHERWISE, ARISING OUT OF
                        OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO
                        USE THE SOFTWARE.
                        <br />
                        LICENSOR FURTHER EXPRESSLY DISCLAIMS ALL LIABILITY,
                        WHETHER EXPRESS OR IMPLIED FROM THE FOLLOWING: I) FROM
                        ANY DEFECTS OR ERRORS WHICH ARE ASSOCIATED WITH THE
                        SOFTWARE BUT WHICH ARE ARISING DUE TO OTHER EXTERNAL
                        FACTORS INCLUDING OTHER SOFTWARE PRODUCTS OF THE
                        LICENSEE, VIRUS ATTACK, ABSENCE OF ANY EQUIPMENT
                        REQUIRED FOR PROPER FUNCTIONING OF THE SOFTWARE, ETC.
                        II) DEFECTS OR PROBLEMS ARISING IN THE SOFTWARE, OR
                        DEFICIENCY IN SERVICES, DUE TO ANY DEFECTS IN ANY THIRD
                        PARTY SOFTWARE, WHICH HAS BEEN DELIVERED TO THE LICENSEE
                        FOR PROPER FUNCTIONING OF THE SOFTWARE, OR IN ORDER TO
                        PROVIDE SERVICES TO THE LICENSEE III) DUE TO ANY DATA
                        BREACH OCCURING DUE TO THIRD PARTY CYBER ATTACKS OR DATA
                        BREACHES BY EMPLOYEES WITHIN YOUR ORGANIZATION
                      </li>
                      <li>
                        IN CASE ANY DEFECT IS DISCOVERED, THE LICENSEE MUST
                        PROVIDE NOTICE TO THE LICENSEE ABOUT SUCH DEFECT, AND
                        GIVE REASONABLE TIME TO THE LICENSOR TO MAKE
                        RECTIFICATIONS WITHIN THE SOFTWARE AND REMOVE SUCH
                        DEFECT. THE LICENSEE SHALL NOT FILE ANY SUIT UNDER ANY
                        LAW FOR THE TIME BEING IN FORCE, FOR ANY PROBLEMS FACED
                        FOR SUCH DEFECT OR DAMAGES SUFFERED, BEFORE SERVING SUCH
                        NOTICE AND PROVIDING REASONABLE TIME TO THE LICENSOR TO
                        CURE SUCH DEFECT AND SHALL NOT TAKE ANY LEGAL ACTION IN
                        ANY FORUM, WHEN SUCH NOTICE IS SERVED AND THE DEFECT IS
                        CURED BY THE LICENSOR.
                      </li>
                      <li>
                        THE LIABILITY FOR ANY HARM SUFFERED BY THE LICENSEE DUE
                        TO ANY DEFECT IN THE SOFTWARE OR DEFICIENCY IN THE
                        SERVICES, CANNOT UNDER ANY CIRCUMSTANCES EXCEED THE
                        SUBSCRIPTION/LICENSE FEE PAID BY THE LICENSEE TO THE
                        LICENSOR, FOR THE SUBSCRIPTION PERIOD DURING WHICH SUCH
                        DEFECT HAS OCCURRED AND DAMAGES SUFFERED.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>8) Intellectual Property</h3>
                    <ul>
                      <li>
                        The Software and all accompanying documents are
                        protected by copyright. You shall not copy, transfer or
                        make derivative work associated with the software,
                        except as authorized by this EULA. The Licensor solely
                        and exclusively owns the software and has right, title
                        and interest in it. No right to ownership in the
                        Software is transferred either expressly or impliedly by
                        means of this License agreement or any other agreement,
                        unless an express agreement for sale of the software is
                        entered between the parties. All intellectual property
                        rights in the software, including but not limited to
                        copyright, patent, trademark, service mark, proprietary
                        rights, moral rights, and right, title interest, shall
                        solely and all times belong to the Licensor.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>9) Prohibited activities</h3>
                    <ul>
                      <li>
                        The Licensee shall not publish the software or any
                        documents delivered to the Licensee along with software,
                        except with express permission from the Licensor. The
                        Licensee shall not copy the software or sell the
                        software to third parties. The Licensee shall not make
                        any modified versions of the software or sell such
                        modified versions of the software. The Licensee is only
                        entitled to make use of the software after purchase of a
                        valid license and shall not permit third parties to make
                        unauthorized use of the software by sharing its
                        credentials or license key. The Licensee and authorized
                        employees and personnel within its organization shall
                        only be entitled to use the Software. The licensee shall
                        not make any pirated versions of the software or promote
                        making of such versions either directly or indirectly.
                        The licensee shall not decompile, reverse engineer, or
                        disassemble the software. Licensee shall under no
                        circumstances, try to claim ownership to the software or
                        challenge the ownership right, title, interest of the
                        Licensor by any means or any right to any intellectual
                        property associated with the software.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>10) Compatibility and Functionality</h3>
                    <ul>
                      <li>
                        The Licensor shall be responsible for keeping the
                        software compatible and functional at all times,
                        considering the system requirements, third party
                        software, system configuration, device, hardware
                        requirements, for which the software was originally
                        meant to be used at the time of purchasing the
                        subscription plan from the Licensor. The software may
                        not be functional unless the system requirements are
                        met.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>11) Updates</h3>
                    <ul>
                      <li>
                        The Licensor shall make updates to the software from
                        time to time, to improve its functionality, utility and
                        make it compatible with any updated versions of any
                        third party software which may be used in conjunction
                        with the Software, at its sole discretion. Such updates
                        may be provided either free of charge or may be charged
                        at the prevalent rates. In case the update is
                        chargeable, the Licensee shall have the option to opt
                        out from making such update. Licensee shall be
                        responsible for updating the software with any new
                        versions that may be released taking into consideration
                        security concerns.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>12) Support</h3>
                    <ul>
                      <li>
                        The Licensor shall provide ongoing support for the
                        software during the normal business hours and business
                        days. Such support shall be limited to rectification of
                        any defects or malfunctioning of the software or
                        resolution of any technical problems in the software or
                        answering queries associated with how the software
                        works. All support provided for the software shall be
                        limited to online support only.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>13) License Fee</h3>
                    <ul>
                      <li>
                        The Subscription fee paid by the Licensee as per the
                        service agreement executed at the time of purchasing the
                        software shall be held as license fee for purchasing
                        this license. The License fee shall be paid by the
                        Licensee subject to the terms and conditions of the
                        Service agreement, as per the rates applicable, at the
                        time of purchasing the software.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>14) Termination</h3>
                    <ul>
                      <li>
                        The Licensor may terminate the license, in case the
                        Licensee carries out any prohibited activities, as
                        provided in clause-9.1 of this EULA or in case the
                        licensee is found to be in breach of any other terms, of
                        this Agreement by serving a termination notice,
                        effective from the date of receipt. In case, the
                        Agreement is terminated, the Licensee shall immediately
                        stop using the software, delete all copies of software
                        in the licensee’s device, after receipt of such notice,
                        and stop any prohibited activity which it may have been
                        carrying out or carried out from the date of receipt of
                        such notice. On termination, by the Licensor, due to
                        carrying out any prohibited activity or breach by the
                        Licensee of any other terms of this EULA, no refund of
                        the subscription fee shall be available. The Licensee
                        may further be liable to make compensation for any
                        losses which may have been sustained by the Licensor due
                        to such prohibited activity being carried out by the
                        Licensee.{" "}
                      </li>
                      <li>
                        The Licensor may terminate this Agreement without any
                        cause by service of 7 days written notice. In such a
                        Case the License fee shall be repaid on a prorated basis
                        taking into consideration the period for which the
                        software has been used and any remaining time for which
                        the software could have been used.
                      </li>
                      <li>
                        This EULA shall terminate automatically in case your
                        subscription plan as per the service agreement ends.
                      </li>
                      <li>
                        In the event of the termination of this Agreement for
                        any reason: (a) the license granted to you in this
                        Agreement will terminate; (b) you must immediately cease
                        all use of the Software and destroy or erase all copies
                        of the Software in your possession or control; and (c)
                        Sections: (2) (Ownership), (6) (Warranty Disclaimers),
                        (5) (Indemnification), (7) (Limitation of Liability),
                        (14) (Termination), and (15) (Governing Law;
                        Jurisdiction) will survive any such termination.
                        Further, in the event the agreement governing your use
                        of the Company Services with which the Software is used
                        is terminated for any reason, this Agreement will
                        terminate automatically without notice to you.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>15) Feedback</h3>
                    <ul>
                      <li>
                        The Licensee shall be responsible at their own expense
                        to make reasonable efforts to inform the Licensor
                        (including but not limited to about any, questions,
                        comments, queries, or feedback), regarding the
                        experience of the Licensee in using the product,
                        identifying areas where development may be made,
                        identifying any technical problems in using the
                        software, defects in the software, post questions
                        associated with functionality, features, performance of
                        the software. The Licensee shall devote their time
                        properly in this matter and make themselves available to
                        the Licensor for this purpose. The Licensee shall not
                        provide any feedback to third parties regarding this
                        matter. The Licensee further gives the Licensor, a
                        worldwide, exclusive, permanent, royalty free,
                        irrevocable license to publish, distribute, create
                        derivative works, disclose, use, reproduce, the feedback
                        so received from the Users. Further, we will be free to
                        use any ideas, concepts, know-how or techniques
                        contained in such Feedback for any purpose whatsoever,
                        including, without limitation, developing, making,
                        marketing, distributing and selling products and
                        services incorporating such Feedback. We will have no
                        obligation to consider, use, return or preserve any
                        Feedback you provide to us. Any Feedback you provide to
                        us may or may not be treated confidentially by us, and
                        we will have no obligation or liability to you for the
                        use or disclosure of any Feedback. You should not expect
                        any compensation of any kind from us with respect to
                        your Feedback.
                      </li>
                    </ul>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>16) Governing Law and Dispute Resolution</h3>
                    <ul>
                      <li>
                        This Agreement shall be governed by and construed in
                        accordance with the laws of India.
                      </li>
                      <li>
                        Any dispute arising out of this Agreement between the
                        parties to these presents, the same shall be first
                        settled by discussion and negotiation by mutual consent
                        of both the parties through an official belonging to the
                        highest management of both the parties. In case no
                        settlement is reached by & between the parties, then the
                        matter shall be referred to Arbitration. The Parties
                        shall mutually appoint a sole arbitrator to resolve the
                        dispute. Either party is entitled to make reference to
                        arbitration. The proceedings shall be governed by the
                        provisions of the Arbitration and Conciliation Act,
                        1996. The Arbitration shall be in English language and
                        the venue shall be Bangalore, India. For matters, that
                        cannot be resolved through arbitration, the Parties
                        shall submit to the exclusive jurisdiction of courts at
                        Bangalore.
                      </li>
                    </ul>
                  </div>
                  {/* point sections end */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default connect(null, null)((HOC(EndUserAggreement)));
