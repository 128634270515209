import React, { useState } from "react";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import profile from "./profile.png";
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
import { baseUrl } from "../../services/index";
import IconSchool from "./IconSchool.png";

const StudentHeader = (props) => {
  const [sidebar, setSidebar] = useState(false);
  let userInformation = props.data;
  const { data } = props;

  const setLoadingnewside = () => {
    try {
      setSidebar(!sidebar);
      if (sidebar) {
        document.getElementById("mySidenav").style.width = "250px";
      } else {
        document.getElementById("mySidenav").style.width = "0px";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader">
          <Toolbar>
            <Grid container spacing={24}>
              <Grid item xs={10} md={10}>
                <div className="logoformatting">
                  <span>
                    <img
                      src={
                        data.path === ""
                          ? IconSchool
                          : baseUrl + "/" + data.path
                      }
                      alt=""
                      className={
                        data.path === ""
                          ? "images_background_logo image_hader"
                          : "image_hader"
                      }
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={2} md={2}>
                <div className="iconsMaindiv2" style={{ paddingRight: "3%" }}>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn  dropdown-toggle dropdownbutton1 button_login_decoration"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {`${userInformation && userInformation.first_name} ${
                        userInformation && userInformation.last_name
                      }`}
                    </button>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() =>
                          props.navigate("/studentDetailForm", {
                            state: {
                              studentId: props.data.userid,
                              institution_name:
                                userInformation.institution_name,
                              academic_session_id: parseInt(
                                props.dataacademic.id
                              ),
                              type: "STUDENT",
                            },
                          })
                        }
                      >
                        My Profile
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => props.navigate("/studentresetpassword")}
                      >
                        Reset Password
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => props.navigate("/")}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(StudentHeader);
