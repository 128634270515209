import React, { useState, useEffect } from "react";
import "./Report.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import "react-phone-input-2/lib/style.css";
//---------------redux-------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Holiday from "../../Common/Holiday/Holiday.jsx";

const EnqueryDetailsReport = (props) => {
  //name instituition
  let Organization_Name = props.data.institution_name;
  // to get data
  let pageType = props.location.state.pagetype;
  const [Parent_first_name, setParent_first_name] = useState("");
  const [Parent_middle_name, setParent_middle_name] = useState("");
  const [Parent_last_name, setParent_last_name] = useState("");
  const [Student_first_name, setStudent_first_name] = useState("");
  const [Student_middle_name, setStudent_middle_name] = useState("");
  const [Student_last_name, setStudent_last_name] = useState("");
  const [student_birth_date, setstudent_birth_date] = useState("");
  const [academic_session, setacademic_session] = useState("");
  const [grade, setgrade] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [email, setemail] = useState("");
  const [referee, setreferee] = useState("");
  const [sibling_first_name, setsibling_first_name] = useState("");
  const [sibling_middle_name, setsibling_middle_name] = useState("");
  const [sibling_last_name, setsibling_last_name] = useState("");
  const [sibling_class, setsibling_class] = useState("");
  const [comment, setcomment] = useState("");
  const [UpdateId, setUpdateId] = useState("");
  // to get data of manage role
  useEffect(() => {
    window.scrollTo(0, 0);
    fillInterestDataFn();
  }, [pageType]);
  const fillInterestDataFn = () => {
    const submitadmissionintrestdata = props.location.state.data;
    setUpdateId(submitadmissionintrestdata.id);
    setParent_first_name(submitadmissionintrestdata.fatherName.first_name);
    setParent_middle_name(submitadmissionintrestdata.fatherName.middle_name);
    setParent_last_name(submitadmissionintrestdata.fatherName.last_name);
    setStudent_first_name(submitadmissionintrestdata.studentName.first_name);
    setStudent_middle_name(submitadmissionintrestdata.studentName.middle_name);
    setStudent_last_name(submitadmissionintrestdata.studentName.last_name);
    setstudent_birth_date(submitadmissionintrestdata.student_birth_date);
    setacademic_session(submitadmissionintrestdata.academic_session);
    setgrade(submitadmissionintrestdata.grade);
    setcontact_number(submitadmissionintrestdata.contact_number);
    setemail(submitadmissionintrestdata.email);
    setreferee(submitadmissionintrestdata.referee);
    setsibling_first_name(submitadmissionintrestdata.siblingName.first_name);
    setsibling_middle_name(submitadmissionintrestdata.siblingName.middle_name);
    setsibling_last_name(submitadmissionintrestdata.siblingName.last_name);
    setsibling_class(submitadmissionintrestdata.sibling_class);
    setcomment(submitadmissionintrestdata.comment);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background  mb-2">
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="paddingContainer">Parent Name</div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <p className="pText">Parent First Name</p>
                            <div className="textWrapper">
                              <p>{Parent_first_name}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Parent Middle Name</p>
                            <div className="textWrapper">
                              <p>{Parent_middle_name}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Parent Last Name</p>
                            <div className="textWrapper">
                              <p>{Parent_last_name}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Student Name
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <p className="pText">First Name</p>
                            <div className="textWrapper">
                              <p>{Student_first_name}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Middle Name</p>
                            <div className="textWrapper">
                              <p>{Student_middle_name}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Last Name</p>
                            <div className="textWrapper">
                              <p>{Student_last_name}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <p className="pText">Student Date of Birth</p>
                            <div className="textWrapper">
                              <p>{student_birth_date}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="pText">
                              Academic Session Applied
                            </div>
                            <div className="textWrapper">
                              <p>{academic_session}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Class</p>
                            <div className="textWrapper">
                              <p>{grade}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <p className="pText">Contact Number</p>
                            <div className="textWrapper">
                              <p>{contact_number}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Email Address</p>
                            <div className="textWrapper">
                              <p>{email}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Referred by whom</p>
                            <div className="textWrapper">
                              <p>{referee}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Sibling Details (if Present)
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <p className="pText">First Name</p>
                            <div className="textWrapper">
                              <p>{sibling_first_name}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Middle Name</p>
                            <div className="textWrapper">
                              <p>{sibling_middle_name}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <p className="pText">Last Name</p>
                            <div className="textWrapper">
                              <p>{sibling_last_name}</p>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Sibling Class/Section
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="textWrapper">
                              <p>{sibling_class}</p>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Comments
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="textWrapper box">
                              <p>{comment}</p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(EnqueryDetailsReport)));
