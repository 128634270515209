import React, { useState, useEffect } from "react";
import "./AdmissionIntrest.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------------redux-----------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------------api calls-----------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { showNotificationMsz } from "../../../utils/Validation";

const EnquiryDetail = (props) => {
  //-----------------global id-----------------------------
  const submitadmissionintrestdata = props.location.state.data;
  let pageType = props.location.state.pagetype;
  let institution_id = parseInt(props.data.institution_id);
  let Organization_Name = props.data.institution_name;
  let academic_session_id = parseInt(props.data.academic_session_id);
  //-----------------api calls-----------------------------
  const [Update_status_admissionintrest, {}] = useMutation(
    Service.Update_status_admissionintrest
  );
  const client = useApolloClient();
  //-----------------local state-----------------------------
  const [isloading, setisloading] = useState(false);
  const [Parent_first_name, setParent_first_name] = useState("");
  const [Parent_middle_name, setParent_middle_name] = useState("");
  const [Parent_last_name, setParent_last_name] = useState("");
  const [Student_first_name, setStudent_first_name] = useState("");
  const [Student_middle_name, setStudent_middle_name] = useState("");
  const [Student_last_name, setStudent_last_name] = useState("");
  const [student_birth_date, setstudent_birth_date] = useState("");
  const [academic_session, setacademic_session] = useState("");
  const [grade, setgrade] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [email, setemail] = useState("");
  const [referee, setreferee] = useState("");
  const [sibling_first_name, setsibling_first_name] = useState("");
  const [sibling_middle_name, setsibling_middle_name] = useState("");
  const [sibling_last_name, setsibling_last_name] = useState("");
  const [sibling_class, setsibling_class] = useState("");
  const [comment, setcomment] = useState("");
  const [managegrade, setmanagegrade] = useState([]);
  const [academicSession, setAcademicSession] = useState([]);
  const [openAcceptDailog, setopenAcceptDailog] = useState(false);
  const [UpdateId, setUpdateId] = useState("");
  const [openRejectDailog, setopenRejectDailog] = useState(false);
  const [relationType, setRelationType] = useState("");
  //-----------------methods-----------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageType === "edit") {
      const {
        fatherName,
        studentName,
        student_birth_date,
        academic_session,
        grade,
        contact_number,
        email,
        referee,
        comment,
        siblingName,
        sibling_class,
        parent_type,
        id,
      } = props.location.state.data;
      setUpdateId(id);
      if (fatherName) {
        if (fatherName.first_name) setParent_first_name(fatherName.first_name);
        if (fatherName.middle_name)
          setParent_middle_name(fatherName.middle_name);
        if (fatherName.last_name) setParent_last_name(fatherName.last_name);
      }

      if (studentName) {
        if (studentName.first_name)
          setStudent_first_name(studentName.first_name);
        if (studentName.middle_name)
          setStudent_middle_name(studentName.middle_name);
        if (studentName.last_name) setStudent_last_name(studentName.last_name);
      }
      if (student_birth_date) setstudent_birth_date(student_birth_date);
      if (academic_session) setacademic_session(academic_session);
      if (grade) setgrade(grade);
      if (contact_number) setcontact_number(contact_number);
      if (email) setemail(email);
      if (referee) setreferee(referee);
      if (siblingName) {
        if (siblingName.first_name)
          setsibling_first_name(siblingName.first_name);
        if (siblingName.middle_name)
          setsibling_middle_name(siblingName.middle_name);
        if (siblingName.last_name) setsibling_last_name(siblingName.last_name);
      }
      if (sibling_class) setsibling_class(sibling_class);
      if (parent_type) setRelationType(parent_type);
      if (comment) setcomment(comment);
    }
    const getSessionandClass = async () => {
      try {
        setisloading(true);
        const { data } = await client.query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        });

        if (data && data.getAllAcademicSession) {
          setAcademicSession(data.getAllAcademicSession);
        }
        setisloading(false);
      } catch (error) {
        setisloading(false);
        console.log("error", error);
      }

      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setisloading(false);
            if (response.data.getGrade.length > 0) {
              setmanagegrade(response.data.getGrade);
            }
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    getSessionandClass();
  }, [
    client,
    institution_id,
    academic_session_id,
    props.location.state.data,
    pageType,
  ]);

  const RejectedStatusUpdate = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Update_status_admissionintrest({
        variables: { id, status: "REJECTED" },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateAdmissionEnquiry.message,
            "success"
          );
         props.navigate("/admissionIntrest");
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const AcceptedStatusUpdate = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Update_status_admissionintrest({
        variables: { id, status: "ACCEPTED" },
      })
        .then((response) => {
          setisloading(false);
          setopenAcceptDailog(false);
          showNotificationMsz(
            response.data.updateAdmissionEnquiry.message,
            "success"
          );

         props.navigate("/admissionIntrest");
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />
              <div className="enquirydetails_background  mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-4 Heading_bold">Enquiry Detail</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">Parent Name</div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <TextField
                                id="standard-required"
                                placeholder="First name"
                                autoComplete="off"
                                className="text_Filed_width"
                                value={Parent_first_name}
                                onChange={(e) => {
                                  setParent_first_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={Parent_middle_name}
                                onChange={(e) => {
                                  setParent_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <TextField
                                id="standard-required"
                                placeholder="Last Name"
                                autoComplete="off"
                                className="text_Filed_width"
                                value={Parent_last_name}
                                onChange={(e) => {
                                  setParent_last_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Student Name
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <TextField
                                id="standard-required"
                                placeholder="First Name"
                                autoComplete="off"
                                className="text_Filed_width"
                                value={Student_first_name}
                                onChange={(e) => {
                                  setStudent_first_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={Student_middle_name}
                                onChange={(e) => {
                                  setStudent_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <TextField
                                id="standard-required"
                                placeholder="Last Name"
                                autoComplete="off"
                                className="text_Filed_width"
                                value={Student_last_name}
                                onChange={(e) => {
                                  setStudent_last_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Student Date of Birth
                            </div>
                            <div className="inputMarginright">
                              <TextField
                                id="date"
                                type="date"
                                defaultValue="2017-05-24"
                                className="text_Filed_width"
                                value={student_birth_date}
                                onChange={(e) => {
                                  setstudent_birth_date(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Academic Session Applied
                            </div>
                            <div className="inputMarginright">
                              <Select
                                value={academic_session}
                                onChange={(e) => {
                                  setacademic_session(e.target.value);
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                                className="text_Filed_width"
                              >
                                <MenuItem value="">
                                  <em>Academic Session</em>
                                </MenuItem>
                                {academicSession.map((item, index) => (
                                  <MenuItem value={item.id} key={index}>
                                    {item.session_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Class</div>
                            <div className="inputMarginright">
                              <Select
                                value={grade}
                                onChange={(e) => {
                                  setgrade(e.target.value);
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                                className="text_Filed_width"
                              >
                                <MenuItem value="">
                                  <em>Class</em>
                                </MenuItem>
                                {managegrade.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.gradeName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                            </div>
                            <div className="inputMarginright">
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                id="standard-required"
                                value={contact_number}
                                onChange={(e) => {
                                  setcontact_number(e);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address
                            </div>
                            <div className="inputMarginright">
                              <TextField
                                id="standard-required"
                                placeholder="Email Address"
                                autoComplete="off"
                                className="text_Filed_width"
                                value={email}
                                onChange={(e) => {
                                  setemail(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Referred by whom
                            </div>
                            <div className="inputMarginright">
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={referee}
                                onChange={(e) => {
                                  setreferee(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Sibling Details (if Present)
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={sibling_first_name}
                                onChange={(e) => {
                                  setsibling_first_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={sibling_middle_name}
                                onChange={(e) => {
                                  setsibling_middle_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={sibling_last_name}
                                onChange={(e) => {
                                  setsibling_last_name(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <div className="text_filed_heading gapfromanotherinput">
                                Sibling Class/Section
                              </div>
                              <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={sibling_class}
                                onChange={(e) => {
                                  setsibling_class(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div>
                              <div className="text_filed_heading gapfromanotherinput">
                                Relation Type{" "}
                              </div>
                              <select
                                class="form-control pt-2"
                                value={relationType}
                              >
                                <option value={-1}>Select Route Type</option>
                                <option value="FATHER">FATHER</option>
                                <option value="MOTHER">MOTHER</option>
                                <option value="GUARDIAN">GUARDIAN</option>
                              </select>

                              {/* <TextField
                                autoComplete="off"
                                className="text_Filed_width"
                                value={relationType}
                                onChange={(e) => {
                                  setRelationType(e.target.value);
                                }}
                              /> */}
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="text_filed_heading gapfromanotherinput">
                          Comments
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="">
                              <textarea
                                className="form-control  textareaformatting"
                                id="exampleFormControlTextarea1"
                                placeholder="Lorem ipsum......"
                                rows="3"
                                type="text"
                                value={comment}
                                onChange={(e) => {
                                  setcomment(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-5 mb-5">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            {submitadmissionintrestdata.status === "REJECTED" ||
                            submitadmissionintrestdata.status === "ACCEPTED" ? (
                              ""
                            ) : (
                              <div className="main_button_div mb-3">
                                <Button
                                  variant="contained"
                                  className="button_enquiry_details button_login_decoration mr-2"
                                  onClick={() => {
                                    setopenAcceptDailog(true);
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  variant="contained"
                                  className="button_enquiry_details button_login_decoration"
                                  onClick={() => {
                                    setopenRejectDailog(true);
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>

          <Dialog
            open={openAcceptDailog}
            onClose={() => {
              setopenAcceptDailog(false);
            }}
            className="dailog_formatting"
          >
            <div className="dailog_width_review_leave">
              <div>
                <div className="cut_dailog_icon float-right">
                  <i
                    className="fa fa-times mr-3 cursor"
                    aria-hidden="true"
                    onClick={() => {
                      setopenAcceptDailog(false);
                    }}
                  ></i>
                </div>
              </div>
              <div className="pl-5 pr-5 mt-4">
                Confirm student can be accepted?
              </div>

              <Grid className="grid_main_div mt-2  mb-2 text-center">
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <div className="acceptation_btn">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration mr-2"
                      onClick={() => AcceptedStatusUpdate(UpdateId)}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration"
                      onClick={() => {
                        setopenAcceptDailog(false);
                      }}
                    >
                      No
                    </Button>
                  </div>
                </Grid>
                <Grid item md={2}></Grid>
              </Grid>
            </div>
          </Dialog>

          <Dialog
            open={openRejectDailog}
            onClose={() => {
              setopenRejectDailog(false);
            }}
            className="dailog_formatting"
          >
            <div className="dailog_width_review_leave">
              <div>
                <div className="cut_dailog_icon float-right">
                  <i
                    className="fa fa-times mr-3 cursor"
                    aria-hidden="true"
                    onClick={() => {
                      setopenRejectDailog(false);
                    }}
                  ></i>
                </div>
              </div>
              <div className="pl-5 pr-5 mt-4">Are you sure want to Reject?</div>

              <Grid className="grid_main_div mt-2  mb-2 text-center">
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <div className="acceptation_btn">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration mr-2"
                      onClick={() => RejectedStatusUpdate(UpdateId)}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration"
                      onClick={() => {
                        setopenRejectDailog(false);
                      }}
                    >
                      No
                    </Button>
                  </div>
                </Grid>
                <Grid item md={2}></Grid>
              </Grid>
            </div>
          </Dialog>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(EnquiryDetail)));
