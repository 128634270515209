import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./MarkAttendance.css";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//-----------api calls---------------
// import * as Service from "../../../services/index";
// import { useMutation } from "@apollo/client";
// import { useApolloClient } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const MarkingAttendanceSubmit = (props) => {
  const handleTakePhoto = () => {};

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Mark Willy</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-2">
                          <Camera
                            onTakePhoto={(dataUri) => {
                              handleTakePhoto(dataUri);
                            }}
                          />
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div  mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details mr-2 button_login_decoration"
                              >
                                Sumbit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  TEACHER_DATA: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(MarkingAttendanceSubmit)));
