import React, { useState, useEffect } from "react";
import "./Exam.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  getFullName,
  timeStampToDate,
} from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ParentExamination(props) {
  const { studentData } = props?.data;

  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //Institute name
  let institution_Name = props.data.institution_name;

  //father id
  let father_id = parseInt(props.data.userid);

  const client = useApolloClient();
  const classes = useStyles();
  const [ExaminationData, setExaminationData] = useState([]);
  const [ParentstudentData, setParentStudentData] = useState([]);
  const [isloading, setisloading] = useState(false);

  // useEffect(() => {
  //   const getPraentStudentData = () => {
  //     try {
  //       setisloading(true);
  //       client
  //         .query({
  //           query: Service.Get_Parent_student_data,
  //           variables: {
  //             institution_id,
  //             father_id,
  //             status: "ACTIVE",
  //           },
  //         })
  //         .then((response) => {
  //           setisloading(false);

  //           if (response.data.getStudentByParent.length > 0) {
  //             setParentStudentData(response.data.getStudentByParent);
  //           }
  //         })
  //         .catch((err) => {
  //           setisloading(false);
  //           console.log("exam catch err", err);
  //         });
  //     } catch (error) {
  //       setisloading(false);
  //       console.log("exam t and c error", error);
  //     }
  //   };

  //   getPraentStudentData();
  // }, [client, institution_id, father_id]);
  useEffect(() => {
    getStudentExamData();
  }, []);

  const getStudentExamData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GetExamByStudentId,
          variables: {
            section_id: studentData?.section?.id,
            student_id: studentData?.id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data?.getExamByStudentId?.length;
            index++
          ) {
            const element = response.data.getExamByStudentId[index];

            temp.push({
              name: element.name,
              status: element.status,
              date: timeStampToDate(element?.start_calendar?.datetime),
              id: element.id,
              section_data: element,
            });
          }
          setExaminationData(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("exam catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  // const getExaminationData = () => {
  //   let section_ID = parseInt(section_id);
  // };

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold">Exams</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className=" d-flex mt-2">
                          <div className="text_filed_heading">
                            Student Name :
                          </div>
                          <div className="">
                            {getFullName(studentData?.name)}
                            {/* <select
                              class="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) =>
                                getStudentExamData(e.target.value)
                              }
                            >
                              <option value={-1}>Student List</option>
                              {ParentstudentData.map((item, index) => (
                                <option value={index} key={index}>
                                  {getFullName(item.name)}
                                </option>
                              ))}
                            </select> */}
                          </div>
                        </div>
                        <div className=" d-flex mt-2">
                          <div className="text_filed_heading">Class :</div>
                          <div className="">
                            {studentData?.section?.grade?.gradeName}
                            {"-"}
                            {studentData?.section?.name}
                          </div>
                        </div>
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Title
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Time Table
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Result
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? ExaminationData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : ExaminationData
                                ).map((row) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.name)
                                          ? ""
                                          : row.name}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.date)
                                          ? ""
                                          : row.date}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {!blankValidator(row.status)
                                          ? ""
                                          : row.status}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="openassignemntcolor"
                                        onClick={() =>
                                          props.navigate("/examTimeTable", {
                                            state: {
                                              item: row,
                                            },
                                          })
                                        }
                                      >
                                        Time Table
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {row.status === "SCHEDULED" && (
                                          <span>-</span>
                                        )}
                                        {row.status === "COMPLETED" && (
                                          <span
                                            className="openassignemntcolor"
                                            onClick={() =>
                                              props.navigate("/examResult", {
                                                state: {
                                                  item: row,
                                                },
                                              })
                                            }
                                          >
                                            View Result
                                          </span>
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={ExaminationData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ParentExamination));
