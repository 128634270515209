//---------------------------api calss--------------------------
import { useApolloClient, useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
//------------------------------redux---------------------------
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import {
  getFullName,
  showNotificationMsz,
  blankValidator,
  getValue,
} from "../../../utils/Validation.js";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";

const ClassPromotion = (props) => {
  //---------------------------global id--------------------------
  const { academic_session_id, json } = props.location.state;
  let institution_id = parseInt(props.manageInstitution.institution_id);
  // let academic_session_id = parseInt(props.location.state.academic_session_id);

  //---------------------------api calss--------------------------
  const [Promote_Student_API, {}] = useMutation(Service.Promote_Student_API);
  const client = useApolloClient();
  const classes = useStyles();
  //---------------------------local state--------------------------
  const [currentAcademicSession, setCurrentAcademicSession] = useState("");
  const [search, setSearch] = useState(
    blankValidator(json?.student_Name) ? json?.student_Name : ""
  );

  const [dialogLoader, setDialogLoader] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [academicSessionArr, setAcademicSessionArr] = useState([]);
  const [managegrade, setmanagegrade] = useState([]);
  const [section, setsection] = useState([]);
  const [Sectionfromgrade, setSectionfromgrade] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dialogGradeArr, setDialogGradeArr] = useState([]);
  const [dialogSectionArr, setDialogSectionArr] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [studentPromoteId, setStudentPromoteId] = useState("");
  const [promotedSectionId, setPromotedSectionId] = useState("");
  const [allChecked, setAllChecked] = useState(false);

  const [sectionid, setsectionid] = useState(
    blankValidator(json?.SectionId) ? json?.SectionId : -1
  );
  const [gradeid, setgradeid] = useState(
    blankValidator(json?.studentClassId) ? json?.studentClassId : -1
  );

  let firsttimr = true;

  useEffect(() => {
    window.scrollTo(0, 0);
    getManageGrades();
  }, [academic_session_id]);

  useEffect(() => {
    selectHandler(gradeid);
  }, [gradeid]);
  useEffect(() => {
    getStudentList(sectionid);
  }, [sectionid]);
  const changeSection = (val) => {
    let secid = parseInt(val);
    setsectionid(secid);
    if (secid === -1) {
      setSectionfromgrade([]);
      return;
    }
    getStudentList(secid);
  };
  //---------------------------methods--------------------------

  const getManageGrades = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_id),
          },
        })
        .then((response) => {
          setisloading(false);
          if (response.data.getGrade.length !== 0) {
            setmanagegrade(response.data.getGrade);
          }
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const PromoteToNextSection = (status) => {
    setisloading(true);
    let studentID = [];
    for (let index = 0; index < Sectionfromgrade.length; index++) {
      if (Sectionfromgrade[index].isSelected === true) {
        studentID.push({ id: Sectionfromgrade[index].id });
      }
    }
    try {
      Promote_Student_API({
        variables: {
          studentID,
          status,
          section_id: parseInt(promotedSectionId),
        },
      })
        .then((response) => {
          // setIsUpdated(!isUpdated);
          setOpenDialog(false);
          setisloading(false);
          getStudentList(sectionid);
          showNotificationMsz("Student parmoted successfully", "success");
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz("Failed to parmoted Student", "danger");
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const getStudentList = (id) => {
    try {
      let secid = parseInt(id);
      setsectionid(secid);

      if (secid === -1) return;
      setisloading(true);
      client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id: secid,
          },
        })
        .then((response) => {
          const filterArr = response.data.getStudentListBySectionId.slice();

          const filtArr = filterArr.filter((arr) => {
            if (arr.status === "ACTIVE" || arr.status == "Enrolled") {
              return arr;
            }
          });
          let temp = [];
          for (let index = 0; index < filtArr.length; index++) {
            const element = filtArr[index];
            temp.push({
              isSelected: false,
              id: element.id,
              display_id: element.login?.display_id,
              roll_number: element.roll_number,
              name: element?.student_profile?.name,
              grade: element.section.grade.gradeName,
              section: element.section.name,
            });
          }

          setSectionfromgrade(temp);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectSectionHandler = (id) => {
    setPromotedSectionId(id);
  };

  const selectHandler = (id) => {
    try {
      let grade_id = parseInt(id);
      setgradeid(grade_id);

      if (firsttimr != true) {
        setsectionid(-1);
        firsttimr = false;
      } else {
        setsection([]);
        setSectionfromgrade([]);
      }
      if (grade_id === -1) {
        return;
      }

      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setsection(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectAcademicHandler = async (currentSessionId) => {
    try {
      if (+currentSessionId === -1) return;
      setCurrentAcademicSession(currentSessionId);
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Manage_Grade,
        variables: {
          academic_session_id: +currentSessionId,
        },
      });
      if (data && data.getGrade) {
        setDialogGradeArr(data.getGrade);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectGradeHandler = (id) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setDialogSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const promoteHandler = async () => {
    let answerselected = false;

    for (let index = 0; index < Sectionfromgrade.length; index++) {
      if (Sectionfromgrade[index].isSelected === true) {
        answerselected = true;
        break;
      }
    }
    if (answerselected === false) {
      alert("Please select one answer");
      return;
    }

    setOpenDialog(true);

    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.GET_ALL_ACADEMIC_SESSION,
        variables: {
          institution_id,
        },
      });
      if (data && data.getAllAcademicSession) {
        const { getAllAcademicSession } = data;
        setAcademicSessionArr(getAllAcademicSession);
        const currentSession = getAllAcademicSession.find(
          (item) => item.is_current === 1
        );
        if (currentSession) {
          setCurrentAcademicSession(currentSession.id);
          selectAcademicHandler(currentSession.id);
        }
      }
      setisloading(false);
      setOpenDialog(true);
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const checkBoxHandler = (id) => {
    let temp = [];
    Sectionfromgrade.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      if (item.isSelected === false) {
        setAllChecked(false);
      }
      temp.push(item);
    });

    setSectionfromgrade(temp);
  };
  const allCheckHandler = () => {
    if (setSectionfromgrade.length > 0) {
      setAllChecked(!allChecked);
    }

    let temp = [];
    Sectionfromgrade.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }

      temp.push(item);
    });
    setSectionfromgrade(temp);
  };

  const filterStudentList = Sectionfromgrade.filter((event) => {
    return (
      getFullName(event.name).toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Class Promotion</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={gradeid}
                                onChange={(e) => selectHandler(e.target.value)}
                              >
                                <option value={-1}>Search By Class</option>

                                {managegrade.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={sectionid}
                                onChange={(e) => changeSection(e.target.value)}
                              >
                                <option value={-1}>Search By Section</option>
                                {section.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="mt-1 ml-2">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Name"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    <Checkbox
                                      checked={allChecked}
                                      onChange={allCheckHandler}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    ID
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Roll number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Results
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? filterStudentList.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : filterStudentList
                                ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">
                                      <Checkbox
                                        checked={row.isSelected}
                                        onChange={() => checkBoxHandler(row.id)}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.display_id}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.roll_number}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {getFullName(row.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.grade}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.section}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <span
                                        className="content_color manage_edit_cursor"
                                        onClick={() =>
                                          props.navigate(
                                            "/viewClassPromotionResults",
                                            {
                                              state: {
                                                ...row,
                                                sectionid,
                                                institution_name:
                                                  props.manageInstitution
                                                    .institution_name,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        Results
                                      </span>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filterStudentList.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                      {!(
                        getValue(json?.notshowclasspromotionbutton) === true
                      ) && (
                        <Grid className="grid_main_div mt-2 mb-1 justify-content-between">
                          <Grid item md={9}></Grid>
                          <Grid item md={3}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width pr-1"
                              // style={{ marginTop: 9 }}
                              onClick={promoteHandler}
                            >
                              Promote / Fail
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpenDialog(false);
                }}
              ></i>
            </div>

            <h6>Class Promotion</h6>
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
              <Grid item xs={4}>
                <label>Academic Session</label>
                <select
                  value={currentAcademicSession}
                  className="form-control "
                  onChange={(e) => selectAcademicHandler(e.target.value)}
                >
                  <option value={-1}>Select Academic Session</option>
                  {academicSessionArr.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.session_name}
                    </option>
                  ))}
                </select>
                {dialogLoader && <p className="text-center">Loading...</p>}
              </Grid>

              <Grid item xs={4}>
                <label>Class</label>
                <select
                  className="form-control "
                  onChange={(e) => selectGradeHandler(e.target.value)}
                >
                  <option>Select Class</option>
                  {dialogGradeArr.length !== 0 ? (
                    dialogGradeArr.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.gradeName}
                      </option>
                    ))
                  ) : (
                    <option>No data</option>
                  )}
                </select>
              </Grid>

              <Grid item xs={4}>
                <label>Section</label>
                <select
                  className="form-control "
                  onChange={(e) => selectSectionHandler(e.target.value)}
                >
                  <option>Select Section</option>
                  {dialogSectionArr.length !== 0 ? (
                    dialogSectionArr.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option>No data</option>
                  )}
                </select>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ flexGrow: 1 }}
              spacing={2}
              className="justify-content-center"
            >
              <Grid item xs={4} className="mt-4">
                <label></label>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration button_width"
                  style={{ marginTop: 9 }}
                  onClick={() => PromoteToNextSection("PROMOTED")}
                >
                  Promote
                </Button>
              </Grid>
              <Grid item xs={4} className="mt-4">
                <label></label>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration button_width"
                  style={{ marginTop: 9 }}
                  onClick={() => PromoteToNextSection("FAILED")}
                >
                  Failed
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ClassPromotion));
