import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import DateFnsUtils from "@date-io/date-fns";
import {
  blankValidator,
  changeTimeFormat,
  showNotificationMsz,
  dateObjectMaker,
  getFullName,
} from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
//--------------------redux--------------------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//--------------------api calls----------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import OnlineLinkLocation from "../../Common/Events/OnlineLinkLocation";

const AllMonday = ({
  timetables,
  day,
  institution_id,
  AllTimeTableGet,
  sectionId,
}) => {
  let section_id = +sectionId;

  //----------------------------global id-----------------------
  var todayDate = new Date().toJSON().slice(0, 10);

  institution_id = parseInt(institution_id);
  //----------------------------api calls-----------------------
  const client = useApolloClient();
  const classes = useStyles();
  const [Assign_Subject_To_Teacher, {}] = useMutation(
    Service.Assign_Subject_To_Teacher
  );
  const [Add_Timetable_For_A_Day, {}] = useMutation(
    Service.Add_Timetable_For_A_Day
  );
  const [Delete_Timetable_From_Day, {}] = useMutation(
    Service.Delete_Timetable_From_Day
  );
  const [delete_Class_TimeTable, {}] = useMutation(
    Service.delete_Class_TimeTable
  );
  //----------------------------local state-----------------------
  let dateObj = new Date();
  const [stValidation, setstValidation] = useState(false);
  const [etValidation, setetValidation] = useState(false);
  const [showAddAndDeleteSec, setShowAddAndDeleteSec] = useState(false);
  const [SubmissionType, setSubmissionType] = useState(false);
  const [periodName, setPeriodName] = useState("");
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [startmatchendError, setstartmatchendError] = useState(false);
  const [endtimeerror, setendtimeerror] = useState(false);
  const [starttimeerror, setstarttimeerror] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationArr, setLocationArr] = useState([]);
  const [rowId, setRowId] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectArr, setSubjectArr] = useState([]);
  const [isClassTeacher, setIsClassTeacher] = useState(false);
  const [editTeacherArr, setEditTeacherArr] = useState([]);
  const [teacher, setTeacher] = useState("");
  const [location, setLocation] = useState("");
  const [start_Time, setStart_Time] = useState(dateObj);
  const [end_Time, setEnd_Time] = useState(dateObj);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [mondayArray, setMondayArray] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [isUpdated, setisUpdated] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [period_Name, setperiod_Name] = useState("");
  //error
  const [periodNameError, setPeriodNameError] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const [teacherError, setteacherError] = useState(false);
  const [subjectError, setsubjectError] = useState(false);

  //----------------------------local state-----------------------

  useEffect(() => {
    let filter = [];
    if (timetables) {
      filter = timetables.filter((data) => {
        return data.day == day;
      });
      setMondayArray(filter);
    }
  }, [timetables]);

  const selectSubjectHandler = (subjectId) => {
    setSubject(subjectId);
  };
  const selectLocationHandler = (locationId) => {
    if (locationId == -1) {
      setLocationName("");
      setLocationId("");
      return;
    }
    setlocationError(false);
    setLocationId(locationId);
    setLocationName(locationId);
  };
  const selectTeacherHandler = (teacherId) => {
    if (teacherId == -1) return;
    setteacherError(false);
    setTeacherId(teacherId);
  };
  // const getStaffTimeTable = () => {
  //   try {
  //     setisloading(true);
  //     client
  //       .query({
  //         query: Service.Get_Staff_TimeTable,
  //         variables: {
  //           employee_id: section_id,
  //           day: parseInt(day),
  //         },
  //       })
  //       .then((response) => {
  //         const timetableArr = response.data.getTimetableBYEmployeeId;
  //         setMondayArray(timetableArr);
  //         setisloading(false);
  //       })
  //       .catch((err) => {
  //         setisloading(false);
  //       });
  //   } catch (error) {
  //     setisloading(false);
  //   }
  // };
  const handleEditData = async (data) => {
    try {
      console.log("datataa ", data);
      setPeriodName("");
      setstValidation(false);
      setetValidation(false);
      setisloading(true);

      if (!data) setSubmissionType(true);
      if (data) {
        const { location, subject, employee } = data;
        setPeriodName(data.period_name);
        setRowId(data.id);
        dateObjectMaker(data);
        setStart_Time(dateObjectMaker(data).st);
        setEnd_Time(dateObjectMaker(data).et);
        if (subject) {
          setSubject(subject.id);
        }
        setstValidation(true);
        setetValidation(true);

        if (location) {
          setLocation(location.name);
        }
        if (subject) {
          setSubject(subject.name);
        }
        if (employee) {
          setTeacher(getFullName(data.employee.name));
          setTeacherId(employee.id);
        }
      }
      //-------------------------api calls------------------------

      //-----------------------to get employee arr----------------------

      const teacherArr = await client.query({
        query: Service.Get_Employee_All_data,
        variables: {
          institution_id,
          department_id: -1,
        },
      });
      if (teacherArr.data.getEmployee) {
        setEditTeacherArr(teacherArr.data.getEmployee);
      }
      if (data.employee) {
        setTeacherId(data.employee.id);
      }
    } catch (error) {
      setisloading(false);
    }
    //-----------------------to get location arr----------------------
    try {
      const locationRes = await client.query({
        query: Service.Get_Manage_Location,
        variables: {
          institution_id,
        },
      });
      setLocationArr(locationRes.data.getLocation);
      if (data.location) {
        setLocationId(data.location.id);
      }
    } catch (error) {
      setisloading(false);
    }
    //-------------to get subject arr----------------
    try {
      const subjectArr = await client.query({
        query: Service.Get_Manage_Subject_Data,
        variables: {
          institution_id,
        },
      });
      if (subjectArr.data.getSubject) {
        setSubjectArr(subjectArr.data.getSubject);
      }
      if (data.subject) {
        setSubject(data.subject.id);
      }
    } catch (error) {}

    setisloading(false);
    setOpenDialog(true);
  };

  const updateTimetableHandler = async () => {
    try {
      if (!blankValidator(periodName)) {
        setPeriodNameError(true);
        return;
      }
      if (!stValidation) {
        setstarttimeerror(true);
        return;
      }
      if (!etValidation) {
        setendtimeerror(true);
        return;
      }

      if (!blankValidator(locationId)) {
        setlocationError(true);
        return;
      }
      if (!blankValidator(teacherId)) {
        setteacherError(true);
        return;
      }
      if (!blankValidator(subject)) {
        setsubjectError(true);
        return;
      }
      setisloading(true);
      let is_class_teacher = 0;
      if (isClassTeacher) is_class_teacher = 1;
      if (SubmissionType) {
        try {
          const { data } = await client.query({
            query: Service.Get_Id_For_Teacher,
            variables: {
              institution_id,
              section_id,
              date: todayDate,
            },
          });
          const addPeriodRes = await Add_Timetable_For_A_Day({
            variables: {
              class_timetable_id: parseInt(data.getClassTimetableID.id),
              day: day.toString(),
              employee_id: parseInt(teacherId),
              location_id: parseInt(locationId),
              class_mode: "offline",
              subject_id: parseInt(subject),
              is_class_teacher,
              period_name: periodName,
              start_time: start_Time.toLocaleTimeString("en-GB"),
              end_time: end_Time.toLocaleTimeString("en-GB"),
            },
          });
          setOpenDialog(false);
          setisUpdated(!isUpdated);
          setisloading(false);
          setLocationId("");
          setTeacherId("");
          setSubject("");
          setSubmissionType(false);
          showNotificationMsz(
            addPeriodRes.data.addATimetable.message,
            "success"
          );
          AllTimeTableGet();
        } catch (error) {
          setisloading(false);
          showNotificationMsz(error, "danger");
        }
        return;
      } else {
        Assign_Subject_To_Teacher({
          variables: {
            id: parseInt(rowId),
            employee_id: parseInt(teacherId),
            location_id: parseInt(locationId),
            subject_id: parseInt(subject),
            is_class_teacher,
            period_name: periodName,
            start_time: start_Time.toLocaleTimeString("en-GB"),
            end_time: end_Time.toLocaleTimeString("en-GB"),
          },
        })
          .then((response) => {
            AllTimeTableGet();
            setOpenDialog(false);
            setisUpdated(!isUpdated);
            setisloading(false);
            setLocationId("");
            setTeacherId("");
            setSubject("");
            showNotificationMsz(
              response.data.assignSubjectToTeacher.message,
              "success"
            );
          })
          .catch((error) => {
            showNotificationMsz(error, "danger");
            setisloading(false);
          });
      }
    } catch (error) {
      setisloading(false);
    }
  };

  const DeleteDailogBox = (data) => {
    setDeleteid(data.id);
    setperiod_Name(data.period_name);
    setopendialogConfimation(true);
  };

  const deleteGradeHandler = (ID) => {
    let id = parseInt(ID);

    try {
      setisloading(true);
      delete_Class_TimeTable({
        variables: { id },
      }).then((response) => {
        setisloading(false);
        showNotificationMsz(response.data.deleteTimetable.message, "success");
        AllTimeTableGet();
        setopendialogConfimation(false);
        setisUpdated(!isUpdated);
      });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const deleteAllPeriod = async () => {
    try {
      setisloading(true);
      const idResponse = await client.query({
        query: Service.Get_Id_For_Teacher,
        variables: {
          institution_id,
          section_id,
          date: todayDate,
        },
      });
      const response = await Delete_Timetable_From_Day({
        variables: {
          day,
          class_timetable_id: parseInt(idResponse.data.getClassTimetableID.id),
        },
      });
      AllTimeTableGet();
      setisUpdated(!isUpdated);
      setDeleteAllDialog(false);
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  return (
    <div className="main_full_width">
      <div className="table_foramtitng mt-2">
        {/* {showAddAndDeleteSec ? ( */}
        <div className="d-flex">
          <div style={{ flex: 0.7 }}></div>
          <div style={{ flex: 0.3 }}>
            <div className="d-flex">
              <div style={{ flex: 0.5 }}>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_width  button_login_decoration"
                  onClick={() => handleEditData("")}
                >
                  Add new period
                </Button>
              </div>
              <div style={{ flex: 0.5, marginLeft: 3 }}>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_width  button_login_decoration"
                  onClick={() => setDeleteAllDialog(true)}
                >
                  Delete all periods
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* ) : (
          <div className="d-flex">
            <div style={{ flex: 0.7 }}></div>
            <div style={{ flex: 0.3 }}>
              <div className="d-flex">
                <div style={{ flex: 0.5 }}>
                  <Button
                    variant="contained"
                    className=" button_width  button_login_decoration"
                    style={{ textTransform: "capitalize", color: "white" }}
                    disable={true}
                  >
                    Add new period
                  </Button>
                </div>
                <div style={{ flex: 0.5, marginLeft: 3 }}>
                  <Button
                    variant="contained"
                    className=" button_width  button_login_decoration"
                    style={{ textTransform: "capitalize", color: "white" }}
                    disable={true}
                  >
                    Delete all periods
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )} */}

        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table_header" align="left">
                  Period Name
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Start Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  End Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Rooms
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Subject
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Teacher
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("mondayArray", mondayArray)}
              {(rowsPerPage > 0
                ? mondayArray.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : mondayArray
              ).map((row, index) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {row.period_name && row.period_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {changeTimeFormat(row.start_time)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {changeTimeFormat(row.end_time)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <OnlineLinkLocation
                      data={row}
                      day={day}
                      fromTimetable={true}
                      userType="Teacher"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.subject?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getFullName(row?.employee?.name)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span
                      className="tab__link"
                      onClick={() => handleEditData(row)}
                    >
                      Edit
                    </span>{" "}
                    |{" "}
                    <span
                      className="tab__link"
                      onClick={() => DeleteDailogBox(row)}
                    >
                      Delete
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={mondayArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangerowsPerPage}
          />
        </TableContainer>
      </div>

      <Loder loading={isloading} />
      <Dialog
        open={openDialog}
        fullWidth="fullWidth"
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <div className="full_Size_Box">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setLocationId("");
                setTeacherId("");
                setSubject("");
                setStart_Time(dateObj);
                setEnd_Time(dateObj);
                setSubmissionType(false);
                setOpenDialog(false);
              }}
            ></i>
          </div>
          <div>
            <Grid item xs={6}>
              <label htmlFor="">Period Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Period Name"
                autoComplete="off"
                value={periodName}
                onChange={(e) => {
                  setPeriodNameError(false);
                  setPeriodName(e.target.value);
                }}
              />
              {periodNameError && (
                <span className="text-danger">Please enter period name.</span>
              )}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label htmlFor="">Start Time</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {stValidation ? (
                    <KeyboardTimePicker
                      ampm={true}
                      views={["hours", "minutes"]}
                      format="HH:mm"
                      margin="normal"
                      id="time-picker"
                      inputVariant="outlined"
                      variant="outlined"
                      value={start_Time}
                      onChange={(event) => {
                        setstValidation(true);
                        setstarttimeerror(false);
                        setStart_Time(event);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  ) : (
                    <KeyboardTimePicker
                      ampm={true}
                      views={["hours", "minutes"]}
                      format="HH:mm"
                      margin="normal"
                      id="time-picker"
                      inputVariant="outlined"
                      variant="outlined"
                      value={start_Time}
                      onChange={(event) => {
                        setstValidation(true);
                        setstarttimeerror(false);
                        setStart_Time(event);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  )}
                </MuiPickersUtilsProvider>
                {starttimeerror && (
                  <span className="text-danger">Enter the Start time</span>
                )}
                {/* <p className="form-control">{start_Time}</p> */}
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="">End Time</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {etValidation ? (
                    <KeyboardTimePicker
                      ampm={true}
                      views={["hours", "minutes"]}
                      format="HH:mm"
                      margin="normal"
                      id="time-picker"
                      inputVariant="outlined"
                      variant="outlined"
                      value={end_Time}
                      onChange={(event) => {
                        setetValidation(true);
                        setendtimeerror(false);
                        setEnd_Time(event);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  ) : (
                    <KeyboardTimePicker
                      ampm={true}
                      views={["hours", "minutes"]}
                      format="HH:mm"
                      margin="normal"
                      id="time-picker"
                      inputVariant="outlined"
                      variant="outlined"
                      value={end_Time}
                      onChange={(event) => {
                        setetValidation(true);
                        setendtimeerror(false);
                        setEnd_Time(event);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  )}
                </MuiPickersUtilsProvider>
                {endtimeerror && (
                  <span className="text-danger">Enter the End Time</span>
                )}
                {startmatchendError && (
                  <span className="text-danger">
                    End Time must not be same as Start Time
                  </span>
                )}
                {/* <p className="form-control">{end_Time}</p> */}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label htmlFor="">Location</label>
                <select
                  value={locationId}
                  class="form-control"
                  onChange={(e) => {
                    selectLocationHandler(e.target.value);
                  }}
                >
                  <option value={-1}>Select Location</option>
                  {locationArr.length !== 0 &&
                    locationArr.map((item) => (
                      <option value={item.id}>
                        {item.name}{" "}
                        {item.mode === "Online" ? " - ( Online ) " : ""}
                      </option>
                    ))}
                </select>

                {locationError && (
                  <span className="text-danger">Select the Location</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="">Teacher</label>
                <select
                  value={teacherId}
                  class="form-control"
                  onChange={(e) => {
                    selectTeacherHandler(e.target.value);
                  }}
                >
                  <option value={-1}>Select Teacher</option>
                  {editTeacherArr.length !== 0 &&
                    editTeacherArr.map((item) => (
                      <option value={item.id}>{getFullName(item.name)}</option>
                    ))}
                </select>
                {teacherError && (
                  <span className="text-danger">Select the Teacher</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label htmlFor="">Subject</label>
                <select
                  class="form-control"
                  value={subject}
                  onChange={(e) => {
                    setsubjectError(false);
                    selectSubjectHandler(e.target.value);
                  }}
                >
                  <option value="">Select Subject</option>
                  {subjectArr.length !== 0 &&
                    subjectArr.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
                {subjectError && (
                  <span className="text-danger">Select the Subject</span>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                className="d-flex"
                style={{ alignItems: "end" }}
              >
                <Checkbox
                  checked={isClassTeacher}
                  onClick={(e) => setIsClassTeacher(e.target.checked)}
                />
                <span style={{ padding: 9 }}>Is Class Teacher</span>
              </Grid>
            </Grid>
            <div className="text-center mt-3">
              <Button
                variant="contained"
                className="add_new_query_btn button_login_decoration"
                onClick={updateTimetableHandler}
              >
                {SubmissionType ? "Add" : "Update"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Period : {period_Name}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteGradeHandler(deleteid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={deleteAllDialog}
        onClose={() => {
          setDeleteAllDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDeleteAllDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete all the periods?
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={deleteAllPeriod}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setDeleteAllDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default AllMonday;
