import React from "react";
import { googleMapKey } from "../../../config/config";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MapDirectionsRenderer from "./app_map_directions_render";

const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        defaultCenter={props.showBus ? props.busLocation : props.defaultCenter}
        defaultZoom={props.defaultZoom}
      >
        {props.showRoute &&
          props.places.map((marker, index) => {
            const position = {
              lat: parseFloat(marker.stop_latitude),
              lng: parseFloat(marker.stop_longitude),
            };
            return (
              <Marker
                key={index}
                position={position}
                label={marker.stop_name}
                title={"amitrai"}
                place={"hhh"}
              />
            );
          })}
        {props.showRoute && (
          <MapDirectionsRenderer
            places={props.places}
            travelMode={window.google.maps.TravelMode.DRIVING}
          />
        )}
        {props.showRoute == false && (
          <Marker
            position={props.defaultCenter}
            draggable={true}
            onDragEnd={(e) => props.onDragMarker(e)}
          />
        )}
        {props.showBus && (
          <Marker
            position={props.busLocation}
            icon={"https://www.geocodezip.net/mapIcons/bus_blue.png"}
          />
        )}
      </GoogleMap>
    );
  })
);

const AppMap = (props) => {
  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultZoom,
    showBus,
    busLocation,
  } = props;

  return (
    <Map
      googleMapURL={
        "https://maps.googleapis.com/maps/api/js?key=" +
        googleMapKey +
        "&v=3.exp&libraries=geometry,drawing,places"
      }
      places={props.stopArray}
      showRoute={props.showRoute}
      showBus={showBus}
      onDragMarker={props.onDragMarker}
      loadingElement={loadingElement || <div style={{ height: `100%` }} />}
      containerElement={containerElement || <div style={{ height: "80vh" }} />}
      mapElement={mapElement || <div style={{ height: `100%` }} />}
      defaultCenter={props.center || { lat: 25.798939, lng: -80.291409 }}
      defaultZoom={props.zoom || 8}
      busLocation={busLocation || { lat: 25.798939, lng: -80.291409 }}
    />
  );
};

export default AppMap;
