import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Expand from "react-expand-animated";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";

const CreateEditTemplate = (props) => {
  const { currency_code } = props.manageInstitution;
  console.log("manageInstitution", JSON.stringify(props.manageInstitution));
  //----------------global id----------------
  let invoiceTitle = props.location.state.invoiceData.title;
  let invoiceId = parseInt(props.location.state.invoiceData.id);
  let institution_id = parseInt(props.manageInstitution.institution_id);
  //--------------------API CALLS-----------------
  const client = useApolloClient();

  const [Add_Invoice_Template_Fee, {}] = useMutation(
    Service.Add_Invoice_Template_Fee
  );
  const [Update_Invoice_Fee, {}] = useMutation(Service.Update_Invoice_Fee);
  const [Delete_Invoice_Fee_Items, {}] = useMutation(
    Service.Delete_Invoice_Fee_Items
  );
  //----------------local state-------------------
  const [amount, setAmount] = useState("");
  const [taxValue, setTaxValue] = useState("");
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [totalAmountEdit, settotalAmountEdit] = useState("");
  const [tmepId, settmepId] = useState("");
  const [taxEdit, settaxEdit] = useState("");
  const [opencreateeditinvoice, setCreateEditInvoice] = useState(false);
  const [createdInvoiceArr, setCreatedInvoiceArr] = useState([]);
  const [subtypeId, setsubtypeId] = useState("");
  const [subtypeEditId, setsubtypeEditId] = useState("");
  const [isloading, setisloading] = useState(false);

  const [feeSubTypeArr, setfeeSubTypeArr] = useState([]);
  const [feeSubTypeEditArr, setfeeSubTypeEditArr] = useState([]);
  const [createeditfeetype, setcreateeditfeetype] = useState(false);

  //error
  const [subtypeError, setsubtypeError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [taxValueError, setTaxValueError] = useState(false);
  const [subtypeIdError, setsubtypeIdError] = useState(false);
  const [totalAmountError, settotalAmountError] = useState(false);
  const [taxError, settaxError] = useState(false);

  //----------------methods-------------------
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Fee_Subtype_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setfeeSubTypeArr(response.data.getFeeSubtype);
          setfeeSubTypeEditArr(response.data.getFeeSubtype);
          setisloading(false);
        })
        .catch((error) => {
          console.log("catch error", error);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    getFeeRelatedData();
  }, [institution_id, client]);
  const getFeeRelatedData = () => {
    try {
      client
        .query({
          query: Service.Get_Invoice_Fee_Items,
          variables: {
            invoice_template_id: invoiceId,
          },
        })
        .then((response) => {
          setCreatedInvoiceArr(response.data.getInvoiceTemplateFeeItems);
        })
        .catch((err) => console.log("catch err", err));
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const selectSubtypeFn = (id) => {
    setsubtypeId(id);
  };
  const selectSubtypeEditFn = (id) => {
    setsubtypeEditId(id);
  };

  const opendailogcreateeditinvoice = (item) => {
    setsubtypeEditId(item.feesubtype.id);
    console.log("selectSubtypeEditFn", item);
    settmepId(item.id);
    settotalAmountEdit(item.amount);
    settaxEdit(item.tax);
    setCreateEditInvoice(true);
  };
  const openDeleteDialog = (params) => {
    setdeleteId(params.id);
    setdeleteName(params.name);
    setDeleteDialog(true);
  };
  const deleteInvoiceWithItemfn = () => {
    let id = parseInt(deleteId);
    try {
      Delete_Invoice_Fee_Items({
        variables: { id },
      })
        .then((response) => {
          const { message } = response.data.deleteInvoiceTemplateFeeItems;
          getFeeRelatedData();
          setDeleteDialog(false);
          showNotificationMsz(message, "success");

          if (message.trim() === "FAIL") {
            showNotificationMsz("Data already deleted", "info");
          }
        })
        .catch((error) => {
          setDeleteDialog(false);

          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const createEditInvoice = async () => {
    try {
      if (!subtypeId) {
        setsubtypeError(true);
        return;
      }
      if (!amount) {
        setAmountError(true);
        return;
      }
      if (!taxValue) {
        setTaxValueError(true);
        return;
      }

      setisloading(true);

      const { data } = await Add_Invoice_Template_Fee({
        variables: {
          invoice_template_id: invoiceId,
          fee_subtype_id: parseInt(subtypeId),
          amount,
          tax: taxValue,
        },
      });
      if (data && data.addInvoiceTemplateFeeItems) {
        const { message } = data.addInvoiceTemplateFeeItems;
        showNotificationMsz(message, "success");
        getFeeRelatedData();
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log(error);
    }
  };
  const UpdateInvoiceFee = async () => {
    try {
      if (!subtypeEditId) {
        setsubtypeIdError(true);
        return;
      }
      if (!totalAmountEdit) {
        settotalAmountError(true);
        return;
      }
      if (!blankValidator(taxEdit)) {
        settaxError(true);
        return;
      }

      const { data } = await Update_Invoice_Fee({
        variables: {
          id: parseInt(tmepId),
          tax: taxEdit,
          amount: totalAmountEdit,
          fee_subtype_id: parseInt(subtypeEditId),
        },
      });

      if (data && data.updateInvoiceTemplateFeeItems) {
        setCreateEditInvoice(false);
        getFeeRelatedData();
        showNotificationMsz(
          data.updateInvoiceTemplateFeeItems.message,
          "success"
        );
      }
    } catch (error) {
      setCreateEditInvoice(false);
      showNotificationMsz(error, "danger");
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="mt-3 ml-3 Heading_bold">
                  Update Invoice Template
                  <h5 className="text-capitalize mt-2">{invoiceTitle}</h5>
                </div>

                <div className="card_margin_both_side">
                  <Card>
                    <div className=" pb-3">
                      {!createeditfeetype ? (
                        <div className="enquiry_heading">
                          <div className="ml-3 mt-3 Heading_bold"></div>
                          <div
                            className=" mt-3 icon_pencil_color d-flex addPointer"
                            onClick={() => setcreateeditfeetype(true)}
                          >
                            <span className="mr-2 mt-1">Add</span>
                            <span className="iconstoaddfee mr-3">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="card_margin_both_side">
                          <Expand open={createeditfeetype}>
                            <Card>
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <Grid className="grid_main_div">
                                    <Grid item md={12}>
                                      <div className="float-right">
                                        <span className="iconstoaddfee">
                                          <i
                                            className="fa fa-minus-circle"
                                            onClick={() =>
                                              setcreateeditfeetype(false)
                                            }
                                          ></i>
                                        </span>
                                      </div>

                                      <div className="inputMarginright ">
                                        <label>Subtype</label>
                                        <select
                                          class="form-control"
                                          onChange={(e) => {
                                            setsubtypeError(false);
                                            selectSubtypeFn(e.target.value);
                                          }}
                                        >
                                          <option>Subtype</option>
                                          {feeSubTypeArr.map((item, index) => (
                                            <option value={item.id} key={index}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                        {subtypeError && (
                                          <span className="text-danger">
                                            Select Subtype
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="inputMarginright ">
                                        <label>Amount</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Amount"
                                          value={amount}
                                          onChange={(event) => {
                                            if (isNaN(event.target.value))
                                              return;
                                            setAmountError(false);
                                            setAmount(event.target.value);
                                          }}
                                        />
                                        {amountError && (
                                          <span className="text-danger">
                                            Enter amount
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid item md={6}>
                                      <div className="inputMarginright ">
                                        <label>Tax (%)</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Tax"
                                          value={taxValue}
                                          onChange={(event) => {
                                            if (isNaN(event.target.value))
                                              return;
                                            setTaxValueError(false);
                                            setTaxValue(event.target.value);
                                          }}
                                        />
                                        {taxValueError && (
                                          <span className="text-danger">
                                            Enter tax
                                          </span>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className="btn_add mt-2">
                                    <Button
                                      variant="contained"
                                      className="addButtonwidth button_login_decoration"
                                      onClick={createEditInvoice}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        </div>
                      )}
                    </div>
                    <div className="ml-3 mr-3  mt-2 ">
                      <div className="cardheight_overflow">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1 mb-1">
                              <p>Fee Subtype</p>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1 mb-1">
                              <p>Total Amount{` (${currency_code})`}</p>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1 mb-1">
                              <p>Tax {`(%)`}</p>
                            </div>
                          </Grid>
                          <Grid item md={1}></Grid>
                        </Grid>
                        {createdInvoiceArr.map((item, index) => (
                          <Card className="cardvisible_border mb-2 mt-2" key={index}>
                            <div className="card_admissiondetails_height">
                              <div className="textfiled_margin">
                                <div className="">
                                  <Grid className="grid_main_div">
                                    <Grid item md={4}>
                                      <div className="inputMarginright mt-1 mb-1">
                                        <p>{item.feesubtype.name}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={4}>
                                      <div className="inputMarginright mt-1 mb-1">
                                        <p>{item.amount}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright mt-1 mb-1">
                                        <p>{item.tax}</p>
                                      </div>
                                    </Grid>
                                    <Grid item md={1}>
                                      <div className="d-flex pt-2">
                                        <span className="iconstoaddfee mr-2">
                                          <i
                                            className="fa fa-pencil"
                                            onClick={() =>
                                              opendailogcreateeditinvoice(item)
                                            }
                                          ></i>
                                        </span>
                                        <span className="iconstoaddfee ml-2">
                                          <i
                                            className="fa fa-trash"
                                            onClick={() =>
                                              openDeleteDialog(item)
                                            }
                                          ></i>
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={opencreateeditinvoice}
        onClose={() => setCreateEditInvoice(false)}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => setCreateEditInvoice(false)}
            ></i>
          </div>

          <Grid className="grid_main_div">
            <Grid item md={1}></Grid>
            <Grid item md={10}>
              <label>Subtype</label>
              <select
                class="form-control"
                value={subtypeEditId}
                onChange={(e) => {
                  setsubtypeIdError(false);
                  selectSubtypeEditFn(e.target.value);
                }}
              >
                <option>Subtype</option>
                {feeSubTypeEditArr.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
              {subtypeIdError && (
                <span className="text-danger">Select the Subtype</span>
              )}
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>

          <Grid className="grid_main_div">
            <Grid item md={1}></Grid>
            <Grid item md={10}>

              <label for="exampleInputEmail1" className="mt-2">Total Amount{` (${currency_code})`}</label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={totalAmountEdit}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) return;
                    settotalAmountError(false);
                    settotalAmountEdit(event.target.value);
                  }}
                />
                {totalAmountError && (
                  <span className="text-danger">Enter total amount</span>
                )}
              </div>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>
          <Grid className="grid_main_div">
            <Grid item md={1}></Grid>
            <Grid item md={10}>
              <label for="exampleInputEmail1"  className="mt-2">Tax (%)</label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={taxEdit}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) return;
                    settaxError(false);
                    settaxEdit(event.target.value);
                  }}
                />
                {taxError && <span className="text-danger">Enter tax</span>}
              </div>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>
          <div className="text-center mt-2">
            <Button
              variant="contained"
              className="button_enquiry_details button_login_decoration ml-2"
              onClick={UpdateInvoiceFee}
            >
              Update
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDeleteDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete : {deleteName}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteInvoiceWithItemfn()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(CreateEditTemplate)));
