import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import ReactPlayer from "react-player/youtube";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Service from "../../../services/index";
const StudentStudyRight = (props) => {
  const { className, subjectName, item } = props.topicID;
  const [VideoOpen, setVideoOpen] = useState(false);
  const [documentOpen, setdDcumentOpen] = useState(false);
  const [videolinkPlay, setVideolinkPlay] = useState("");
  const [documentImage, setDocumentImage] = useState("");
  const PlayDialogOpen = (row) => {
    const { id, name, link, type, document } = row;
    console.log("type", type);
    if (type === "Link") {
      setVideoOpen(true);
      setVideolinkPlay(link);
    }
    if (type === "Document File") {
      setdDcumentOpen(true);
      setDocumentImage(document);
    }
  };
  return (
    <div>
      <Card className="right_penal_card_height">
        <div className="admin_Study_inner_padding">
          {item && (
            <div className="right_penal_flex">
              <div className="view_right_tpoic_header">
                <span className="view_right_class_header">{className}</span>
                {" > "}
                <span className="view_right_class_header">{subjectName}</span>
                {" > "}
                <span className="page_header_study_penal">{item.name} </span>
              </div>
            </div>
          )}

          <div>
            <ol>
              {item &&
                item.topic_links &&
                item.topic_links.map((row) => (
                  <li>
                    <div className="pt-2">
                      <div className="pl-3">
                        <span
                          className="sub_topic_color font-weight-bold"
                          onClick={() => PlayDialogOpen(row)}
                        >
                          {" "}
                          {row.name}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
            </ol>
          </div>
        </div>
      </Card>
      {/* video dialog  */}
      <Dialog
        open={VideoOpen}
        onClose={() => {
          setVideoOpen(false);
        }}
        className="dailog_formatting"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <span
            className="cut_dailog_icon float-right"
            onClick={() => {
              setVideoOpen(false);
            }}
          >
            <i className="fa fa-times " aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div>
            <ReactPlayer url={videolinkPlay} className="react_video_width" />
          </div>
        </DialogContent>
      </Dialog>
      {/* doucment dailog */}
      <Dialog
        open={documentOpen}
        onClose={() => {
          setdDcumentOpen(false);
        }}
        className="dailog_formatting"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <span
            className="cut_dailog_icon float-right"
            onClick={() => {
              setdDcumentOpen(false);
            }}
          >
            <a
              href={Service.baseUrl + "/" + documentImage.path}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i className="fa fa-times ml-3 " aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div>
            <iframe
              style={{ width: "100%", height: "60vh" }}
              src={`${Service.baseUrl}/${documentImage.path}`}
              alt="No_image"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StudentStudyRight;
