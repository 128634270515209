import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  showNotificationMsz,
  serverDateFormat,
} from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const TeacherLeaveSummary = (props) => {
  // const parse = require("html-react-parser");
  const classes = useStyles();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);
  const [LeaveResquests, setLeaveRequests] = useState([]);
  const [myLeaveArr, setMyLeaveArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const client = useApolloClient();

  const [Teacher_Delete_His_LeaveRequest, {}] = useMutation(
    Service.Teacher_Delete_His_LeaveRequest
  );
  let institution_id = parseInt(props.data.institution_id);
  let user_id = parseInt(props.data.userid);
  useEffect(() => {
    getleaveBalance();
    leaveRequestlist();
  }, [client, institution_id, isUpdated]);
  const getleaveBalance = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Leave_Category_By_EmployeeId,
          variables: {
            institution_id,
            employee_id: user_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setMyLeaveArr(response.data.getLeaveCategoryByEmployeeId);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const leaveRequestlist = () => {
    try {
      setLeaveRequests([]);

      setisloading(true);

      let searchdata = {
        user_id: parseInt(user_id),
        user_types: [
          {
            user_type: "EMPLOYEE",
          },
        ],
      };

      if (startDate != "") {
        searchdata["from_date"] = startDate;
      }

      if (endDate != "") {
        searchdata["to_date"] = endDate;
      }

      client
        .query({
          query: Service.Get_leave_Request_By_userId,
          variables: searchdata,
        })
        .then((response) => {
          setisloading(false);
          setLeaveRequests(response.data.getLeaveRequestByUserId.reverse());
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  };

  const [leaveid, setleaveid] = useState("");
  const [deleteleaveName, setdeleteleaveName] = useState("");
  const [opendialogquestionConfimation, setopendialogquestionConfimation] =
    useState(false);
  const DeleteDailogBox = (data) => {
    setleaveid(data.id);

    setdeleteleaveName(data.subject);

    setopendialogquestionConfimation(true);
  };
  const deleteLeave = () => {
    let id = parseInt(leaveid);

    setopendialogquestionConfimation(false);
    try {
      setisloading(true);
      Teacher_Delete_His_LeaveRequest({
        variables: {
          id: id,
        },
      }).then((response) => {
        setisloading(false);
        showNotificationMsz(
          response.data.deleteLeaveRequestByEmployeeId.message,
          "success"
        );

        getleaveBalance();
        leaveRequestlist();
        setopendialogquestionConfimation(false);
      });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSecondPagination = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangeSecondRowsPerPage = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday
                name={props.data.institution_name}
                mynav={"/teacherHomePage"}
              />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Leave Summary</div>
                  <div className="mr-3 mt-3">
                    {props.data.usertype === "TRANSPORT" ? (
                      ""
                    ) : (
                      <Button
                        variant="contained"
                        className="add_new_query_btn  button_login_decoration mr-3"
                        onClick={() => props.navigate("/manageLeaveRequests")}
                      >
                        Approve/Reject Leaves
                      </Button>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Leave Type
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Balance
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Consumed
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  ></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? myLeaveArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : myLeaveArr
                                ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">
                                      {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left">
                                      {row.remainLeave}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.applyLeave}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <Button
                                        className="add_new_query_btn button_login_decoration leave_apply_button_width"
                                        variant="contained"
                                        onClick={() =>
                                          props.navigate("/applyLeave", {
                                            state: {
                                              item: row,
                                            },
                                          })
                                        }
                                      >
                                        Apply
                                      </Button>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={myLeaveArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="enquirydetails_background mb-2 mt-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    My Leave Requests
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <label>Start Date</label>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <label>End Data</label>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <label className="mt-5"></label>
                            <Button
                              className="add_new_query_btn button_login_decoration leave_apply_button_width"
                              variant="contained"
                              onClick={leaveRequestlist}
                            >
                              Search
                            </Button>
                          </Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Leave Category
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Hours
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell> */}

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage1 > 0
                                  ? LeaveResquests.slice(
                                      page1 * rowsPerPage1,
                                      page1 * rowsPerPage1 + rowsPerPage1
                                    )
                                  : LeaveResquests
                                ).map((row, index) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">
                                        {blankValidator(row.leave_category) &&
                                          row.leave_category.name}
                                      </StyledTableCell>
                                      <StyledTableCell scope="row" align="left">
                                        {row.from_date === undefined ||
                                        row.from_date === null ? (
                                          ""
                                        ) : (
                                          <span>
                                            {serverDateFormat(row.from_date)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.from_date === undefined ||
                                        row.from_date === null ? (
                                          ""
                                        ) : (
                                          <span>
                                            {serverDateFormat(row.to_date)}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.hours === undefined ||
                                        row.hours === null ? (
                                          ""
                                        ) : (
                                          <div>{row.hours}</div>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {row.status}
                                      </StyledTableCell>
                                      <StyledTableCell className="row_details_color">
                                        <div className="d-flex justify-content-center">
                                          {row.status === "APPROVED" && (
                                            <div>
                                              <span
                                                onClick={() =>
                                                  props.navigate(
                                                    "/editteacherleave",
                                                    {
                                                      state: {
                                                        row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                              <span className="ml-1 mr-1">
                                                |
                                              </span>
                                              <span
                                                onClick={() =>
                                                  DeleteDailogBox(row)
                                                }
                                              >
                                                Cancel
                                              </span>
                                            </div>
                                          )}

                                          {row.status === "REJECTED" && (
                                            <div>
                                              <span
                                                onClick={() =>
                                                  props.navigate(
                                                    "/editteacherleave",
                                                    {
                                                      state: {
                                                        row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                            </div>
                                          )}

                                          {row.status === "PENDING" && (
                                            <div className="d-flex justify-content-center">
                                              <span
                                                className
                                                onClick={() =>
                                                  props.navigate(
                                                    "/editteacherleave",
                                                    {
                                                      state: {
                                                        row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                              <span className="ml-1 mr-1">
                                                |
                                              </span>
                                              <span
                                                onClick={() =>
                                                  props.navigate(
                                                    "/editteacherleave",
                                                    {
                                                      state: {
                                                        row,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Edit
                                              </span>
                                              <span className="ml-1 mr-1">
                                                |
                                              </span>
                                              <span
                                                onClick={() =>
                                                  DeleteDailogBox(row)
                                                }
                                              >
                                                Cancel
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </StyledTableCell>

                                      {/* <StyledTableCell align="center">

                                        <i
                                          class="fa fa-pencil openassignemntcolor"
                                          onClick={() =>
                                           props.navigate(
                                              "/editteacherleave",
                                              {
                                                row: row,
                                                days: days,
                                              }
                                            )
                                          }
                                       

                                        ></i>{" "}
                                        <i
                                          class="fa fa-trash mr-4 ml-4 openassignemntcolor"

                                          onClick={() => DeleteDailogBox(row)}
                                        ></i>
                                      </StyledTableCell> */}
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={LeaveResquests.length}
                              rowsPerPage={rowsPerPage1}
                              page={page1}
                              onChangePage={handleChangeSecondPagination}
                              onChangeRowsPerPage={
                                handleChangeSecondRowsPerPage
                              }
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
      <Dialog
        open={opendialogquestionConfimation}
        onClose={() => {
          setopendialogquestionConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogquestionConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Leave : {deleteleaveName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={deleteLeave}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogquestionConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(TeacherLeaveSummary));
