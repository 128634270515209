import React, { useState, useEffect } from "react";
import "./Exam.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//---------------------REDUX-----------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { CapitalizeFirstFn } from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ParentExamTimeTable(props) {
  let exam_id = parseInt(props.location.state.item.id);
  //section name
  let section = props.location.state.section;
  //class name
  let grade = props.location.state.grade;
  let examname = props.location.state.item.name;

  //Institute name
  let institution_Name = props.location.state.institution_Name;
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ExamtimeData, setExamtimeData] = useState([]);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const getStudentDataSectionId = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_EXAM_TimeTable_DATA_Student,
            variables: {
              exam_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setExamtimeData(response.data.getExamTimetable);
          })
          .catch((err) => {
            console.log("exam catch err", err);
          });
      } catch (error) {
        console.log("exam t and c error", error);
      }
    };

    getStudentDataSectionId();
  }, [client, exam_id]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold">Exam Time Table</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="Heading_bold mt-2">
                          {CapitalizeFirstFn(examname)}({grade + "-" + section})
                        </div>
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Time
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Duration
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Location
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {(rowsPerPage > 0
                                  ? ExamtimeData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : ExamtimeData
                                ).map((row) => {
                                  let time1, time2;

                                  time1 = new Intl.DateTimeFormat("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }).format(row.start_calendar.datetime);

                                  time2 = new Intl.DateTimeFormat("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }).format(row.end_calendar.datetime);

                                  let t1, t2;
                                  t1 = time1.substring(0, 5);
                                  t2 = time2.substring(0, 5);

                                  let timesplit = t1.split(":");
                                  let timesplit2 = t2.split(":");

                                  var hours =
                                    parseInt(timesplit2) - parseInt(timesplit);

                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {row.start_calendar.datetime ===
                                          undefined ||
                                        row.start_calendar.datetime === null ||
                                        row.start_calendar.datetime === "" ? (
                                          " "
                                        ) : (
                                          <span>
                                            {" "}
                                            {new Intl.DateTimeFormat("en-US", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                            }).format(
                                              row.start_calendar.datetime
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        
                                        scope="row"
                                        align="left"
                                      >
                                        {row.subject.name === "" ||
                                        row.subject.name === null ||
                                        row.subject.name === undefined ? (
                                          " "
                                        ) : (
                                          <span>{row.subject.name}</span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.start_calendar.datetime === "" ||
                                        row.start_calendar.datetime === null ||
                                        row.start_calendar.datetime ===
                                          undefined ? (
                                          ""
                                        ) : (
                                          <span>
                                            {new Intl.DateTimeFormat("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              // second: "2-digit",
                                            }).format(
                                              row.start_calendar.datetime
                                            )}
                                          </span>
                                        )}
                                        {"-"}
                                        {row.end_calendar.datetime === "" ||
                                        row.end_calendar.datetime === null ||
                                        row.end_calendar.datetime ===
                                          undefined ? (
                                          ""
                                        ) : (
                                          <span>
                                            {new Intl.DateTimeFormat("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              // second: "2-digit",
                                            }).format(
                                              row.end_calendar.datetime
                                            )}
                                          </span>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {hours === undefined ||
                                        hours === null ? (
                                          ""
                                        ) : (
                                          <div>{hours}h</div>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.location.name === "" ||
                                        row.location.name === null ||
                                        row.location.name === undefined ? (
                                          " "
                                        ) : (
                                          <span>{row.location.name}</span>
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={ExamtimeData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}
export default (HOC1(ParentExamTimeTable));
