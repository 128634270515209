import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import ClassGroupGradeInvite from "./ClassGroupGradeInvite";
import ClassGroupSectionStudentInvite from "./ClassGroupSectionInvite";
import { connect } from "react-redux";
import { getData } from "../../../../api/index";
import * as Constants from "../../../../action/appAction";

const ClassGroupInvitation = (props) => {
  let academic_session_id = props.academic_session_id;

  const [chekbox1, setchekbox1] = useState(true);
  const [chekbox2, setchekbox2] = useState(false);

  return (
    <Grid className="grid_main_div ">
      <Grid item md={12}>
        <div className="mt-2 mb-2">
          <span className="ml-4">
            <CheckBoxComponent
              label="Class"
              cssClass="e-custom"
              checked={chekbox1}
              onChange={(e) => {
                setchekbox1(e.target.checked);

                setchekbox2(false);
              }}
            />
          </span>
          <span className="ml-4">
            <CheckBoxComponent
              label="Section"
              cssClass="e-custom"
              checked={chekbox2}
              onChange={(e) => {
                setchekbox2(e.target.checked);
                setchekbox1(false);
              }}
            />
          </span>
        </div>

        {chekbox1 && (
          <ClassGroupGradeInvite
            {...props.data}
            user_type={"STUDENT"}
            userid={parseInt(props.token.userid)}
            eventid={parseInt(props.data.id)}
            academic_session_id={parseInt(academic_session_id)}
          />
        )}
        {chekbox2 && (
          <ClassGroupSectionStudentInvite
            user_type={"PARENT"}
            {...props.data}
            userid={parseInt(props.token.userid)}
            academic_session_id={parseInt(academic_session_id)}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => ({
  token: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(ClassGroupInvitation);
