import React, { useState, useEffect, useRef } from "react";
import HOC1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
//----------------------api calls--------------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import {
  blankValidator,
  CapitalizeFirstFn,
  timeStampToDatedash,
  options,
  getFullName,
  timeWithDateFormat,
  timeStampToDateandTime,
} from "../../../utils/Validation";

//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const EventMarkAttendanceView = (props) => {
  let data = props.location.state.data;
  const componentRef = useRef();
  const ref = React.createRef();

  let today_Date = new Date().toJSON().slice(0, 10);
  // const [dateToShow, setdateToShow] = useState(today_Date);
  const [startdate, setstartdate] = useState(
    timeStampToDatedash(data.start_date)
  );
  useEffect(() => {
    getinviteesList(props.location.state.data.id, startdate);
  }, [props, startdate]);
  //--------------------------api calls------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //--------------------------local state------------------------
  const [InviteesArr, setInviteesArr] = useState([]);

  const [isloading, setisloading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [chekbox, setchekbox] = useState(0);

  const getinviteesList = (id, date) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Event_Participants,
          variables: {
            event_id: parseInt(id),
            date: date,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getEventParticipants.length;
            index++
          ) {
            let element = response.data.getEventParticipants[index];

            if (
              element.user_type === "STUDENT" &&
              element.student.attendances !== null &&
              element.student.attendances !== undefined
            ) {
              element["isSelected"] =
                element.student.attendances.length > 0
                  ? element.student.attendances[0].status === "Present"
                  : false;
              element["display_id"] =
                element.student?.student_profile?.login?.display_id;
              element["name"] = getFullName(
                element.student?.student_profile?.name
              );

              element["photo_path"] =
                element.student?.student_profile?.photo?.path;
              element["gradeName"] = element.student?.section?.grade?.gradeName;
              element["section"] = element.student?.section?.name;
              element["roll_number"] = element.student?.roll_number;
            }
            if (
              element.user_type === "EMPLOYEE" &&
              element.employee.attendances !== null &&
              element.employee.attendances !== undefined
            ) {
              element["isSelected"] =
                element.employee.attendances.length > 0
                  ? element.employee.attendances[0].status === "Present"
                  : false;
              element["display_id"] = element.employee?.login?.display_id;
              element["name"] = getFullName(element.employee?.name);
              element["photo_path"] = element.employee?.photo?.path;
            }
            if (
              element.user_type === "ADMIN" &&
              element.admin.attendances !== null &&
              element.admin.attendances !== undefined
            ) {
              element["isSelected"] =
                element.admin.attendances.length > 0
                  ? element.admin.attendances[0].status === "Present"
                  : false;
              element["display_id"] = element.admin?.login?.display_id;
              element["name"] = getFullName(element.employee?.name);
              element["photo_path"] = element.admin?.photo?.path;
            }
            if (
              element.user_type === "PARENT" &&
              element.parent.attendances !== null &&
              element.parent.attendances !== undefined
            ) {
              element["isSelected"] =
                element.parent.attendances.length > 0
                  ? element.parent.attendances[0].status === "Present"
                  : false;
              element["display_id"] = element.parent?.login?.display_id;
              element["name"] = getFullName(element.parent?.name);
              element["photo_path"] = element.parent?.photo?.path;
            }
            if (
              element?.employee?.attendances !== null &&
              element?.employee?.attendances !== undefined
            ) {
              element["isSelected"] =
                element.employee.attendances.length > 0
                  ? element.employee.attendances[0].status === "Present"
                  : false;
              element["display_id"] = element.employee?.login?.display_id;
              element["name"] = getFullName(element.employee?.name);
              element["photo_path"] = element.employee?.photo?.path;
            }
            temp.push(element);
          }
          setInviteesArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.token.institution_name} />
              <div
                className="enquirydetails_background mb-2"
                ref={componentRef}
              >
                <div ref={ref}>
                  <div className="enquiry_heading p-2">
                    <div>
                      <h4> Event Attendance</h4>
                    </div>
                    <div>
                      {data.event_name === undefined ||
                      data.event_name === null ? (
                        ""
                      ) : (
                        <span className="text-info d-flex">
                          <h6 style={{ margin: 0 }}> Event Name </h6> -
                          <span> {CapitalizeFirstFn(data.event_name)}</span>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex pl-2">
                    <span className="text_filed_heading">Start Time : </span>
                    {data.start_date && timeStampToDateandTime(data.start_date)}
                    <span className="ml-5 text_filed_heading">End Time : </span>
                    {data.start_date && timeStampToDateandTime(data.end_date)}
                  </div>
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={8}>
                              <div className="mt-5">
                                <div>
                                  <span>
                                    <CheckBoxComponent
                                      label="Day"
                                      checked={chekbox == 0}
                                      value={0}
                                      cssClass="e-custom"
                                      onChange={(e) => {
                                        setchekbox(e.target.value);
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          {chekbox == 0 && (
                            <div>
                              <Grid className="grid_main_div mt-1">
                                <Grid item md={5}>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="564946646"
                                    autoComplete="off"
                                    min={timeStampToDatedash(data.start_date)}
                                    max={timeStampToDatedash(data.end_date)}
                                    value={startdate}
                                    onChange={(e) => {
                                      setstartdate(e.target.value);
                                    }}
                                  />

                                  {/* <input
                                    min={timeStampToDatedash(
                                      dateObjData.start_date
                                    )}
                                    max={timeStampToDatedash(
                                      dateObjData.end_date
                                    )}
                                    type="date"
                                    class="form-control"
                                    value={dateToShow}
                                    onChange={(e) => {
                                   
                                      let eventId = parseInt(
                                        props.location.state.data.id
                                      );
                                      setdateToShow(e.target.value)
                                      getAttendenceByeventid(
                                        eventId,
                                        e.target.value
                                      );
                                    }}
                                  /> */}
                                </Grid>
                                <Grid item md={6}></Grid>
                                <Grid item md={1}>
                                  <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPdf
                                        targetRef={ref}
                                        filename="dayAttendance.pdf"
                                        scale={1}
                                        options={options}
                                      >
                                        {({ toPdf }) => (
                                          <i
                                            class="fa fa-file-pdf-o"
                                            onClick={toPdf}
                                          ></i>
                                        )}
                                      </ReactToPdf>
                                    </div>
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPrint
                                        trigger={() => (
                                          <i class="fa fa-print"></i>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid className="grid_main_div mt-1">
                                <Grid item md={10}></Grid>
                                <Grid item md={2} className="d-flex">
                                  <Grid item md={6} className="d-flex">
                                    <p className="absentCirlce"></p>
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Absent
                                    </span>
                                  </Grid>
                                  <Grid item md={6} className="d-flex">
                                    <p className="presentCircle"></p>
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Present
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                <div className="float-right mt-1 mb-1 mr-3">
                                  Day -{timeWithDateFormat(startdate)}
                                </div>
                                <div className="table_foramtitng mt-3 mb-3">
                                  <TableContainer component={Paper}>
                                    <Table
                                      className={classes.table}
                                      aria-label="customized table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header"
                                          >
                                            ID
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header"
                                          >
                                            Name
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align="left"
                                            className="table_header"
                                          >
                                            Present
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(rowsPerPage > 0
                                          ? InviteesArr.slice(
                                              page * rowsPerPage,
                                              page * rowsPerPage + rowsPerPage
                                            )
                                          : InviteesArr
                                        ).map((row, index) => {
                                          return (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell
                                                scope="row"
                                                align="left"
                                              >
                                                {blankValidator(
                                                  row.display_id
                                                ) && row.display_id}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                scope="row"
                                                align="left"
                                              >
                                                <div>
                                                  <img
                                                    src={`${Service.baseUrl}/${row.photo_path}`}
                                                    alt=""
                                                    className="img_formatting imageCursor"
                                                  />
                                                  {row.name}
                                                </div>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                scope="row"
                                                align="left"
                                              >
                                                {row.isSelected ? (
                                                  <i
                                                    class="fa fa-check"
                                                    style={{
                                                      color: "#50ae34",
                                                      fontSize: 20,
                                                    }}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    class="fa fa-times cursor"
                                                    style={{
                                                      color: "#ae3450",
                                                      fontSize: 20,
                                                    }}
                                                  ></i>
                                                )}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                    <TablePagination
                                      true
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={InviteesArr.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  </TableContainer>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  token: getData(state, Constants.TOKEN),
  TEACHER_DATA: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(EventMarkAttendanceView));
