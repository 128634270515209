import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------------redux--------------------------

import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { getFullName } from "../../../utils/Validation.js";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const AdmissionInterestReport = (props) => {
  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);
  //name instituition
  let Organization_Name = props.data.institution_name;
  let academic_session_id = parseInt(props.data.academic_session_id);
  // to get data api
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [admissionInterestArr, setAdmissionInterestArr] = useState([]);
  const [managegrade, setmanagegrade] = useState([]);
  const [academicSession, setAcademicSession] = useState([]);
  const [isloading, setisloading] = useState(false);
  // to get data of manage role
  useEffect(() => {
    window.scrollTo(0, 0);
    getAdmissionInterestData();
  }, [props.data.institution_id]);
  const getAdmissionInterestData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Admission_Interest_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setAdmissionInterestArr(response.data.getAddmissionEnquiry);
        })
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
    try {
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setAcademicSession(response.data.getAllAcademicSession);
        })
        .catch((e) => console.log("catch error", e));
    } catch (error) {
      console.log("t and c error", error);
    }
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setmanagegrade(response.data.getGrade);
        })
        .catch((e) => console.log("catch error", e));
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openeditadmissionIntrest = (item) => {
   props.navigate("/enqueryDetailsReport", {
      pagetype: "edit",
      data: item,
    });
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-2 ml-4 Heading_bold">
                    Admission Interest
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control "
                                id="exampleFormControlSelect1"
                              >
                                <option>Academic Session</option>
                                {academicSession.map((item, index) => (
                                  <option value={item.id}>
                                    {item.start_day}
                                    {"---"}
                                    {item.end_day}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright  mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Class</option>
                                {managegrade.map((item, index) => (
                                  <option value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="button_text_formating mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn search_btn_width button_login_decoration "
                              >
                                {" "}
                                Search{" "}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="table_foramtitng mt-5">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Parent Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Child Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Sibling Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Enquiry Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Details
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? admissionInterestArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : admissionInterestArr
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {getFullName(row.fatherName)}
                                     
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    {getFullName(row.studentName)}
                                    
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    {getFullName(row.siblingName)}
                                     
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.student_birth_date}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.status === "Waiting" && (
                                        <Button
                                          className="Waiting button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                      {row.status === "Accepted" && (
                                        <Button
                                          className="Accepted button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                      {row.status === "Rejected" && (
                                        <Button
                                          className="Rejected button_login_decoration"
                                          variant="contained"
                                        >
                                          {row.status}
                                        </Button>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                      onClick={() => {
                                        openeditadmissionIntrest(row);
                                      }}
                                    >
                                      Open
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={admissionInterestArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(AdmissionInterestReport)));
