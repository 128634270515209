import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Transport.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  changeTimeFormat,
  getFullName,
} from "../../../utils/Validation.js";

const RouteListings = ({ navigate, ADMIN_DATA }) => {
  let institution_id = parseInt(ADMIN_DATA.institution_id);
  // -------------------api calls----------------------------
  const [delete_Transport_route, {}] = useMutation(
    Service.delete_Transport_route
  );
  const client = useApolloClient();
  const classes = useStyles();
  // -------------------local state----------------------------
  const [isUpdated, setIsUpdated] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [RouteId, setRouteId] = useState("");
  const [search, setsearch] = useState("");
  const [search2, setsearch2] = useState("");
  const [routeNameDelete, setrouteNameDelete] = useState("");

  // filter method

  const filterArr = RouteData.filter((event) => {
    if (!blankValidator(search)) {
      return (
        event.start_point.toLowerCase().indexOf(search2.toLowerCase()) !== -1
      );
    } else {
      return (
        event.route_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }
  });

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Route_List_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          if (blankValidator(response.data.getTransportRoute)) {
            setRouteData(response.data.getTransportRoute);
          }
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch err", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }

    return () => setIsUpdated(false);
  }, [isUpdated, institution_id, client]);

  const DeleteTransportRoute = (ID) => {
    let id = parseInt(ID);
    try {
      delete_Transport_route({
        variables: { id },
      })
        .then((response) => {
          setIsUpdated(true);
          setisloading(false);

          setopendialogConfimation(false);
          alert(response.data.deleteTransportRoute.message);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={ADMIN_DATA.institution_name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-3 Heading_bold">Route Listings</div>
                <div className="main_button_div mr-3 mt-3">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() =>
                      navigate("/createnewroute", {
                        state: {
                          pageType: "CREATE",
                        },
                      })
                    }
                  >
                    Create New Route
                  </Button>
                </div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={3}>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search by route name"
                              value={search}
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4}></Grid>
                      </Grid>

                      <div className="table_foramtitng mt-3">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="center"
                                  className="table_header create_route_listing_padding"
                                >
                                  Route Name
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  className="table_header create_route_listing_padding"
                                >
                                  Route Type
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Time
                                </StyledTableCell> */}
                                <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Start
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Destination
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Reg. No
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Driver Name
                                </StyledTableCell> */}
                                <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Driver Contact No.
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding"
                                >
                                  Details
                                </StyledTableCell> */}
                                <StyledTableCell
                                  align="left"
                                  className="table_header create_route_listing_padding text-center"
                                >
                                  Actions
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody id="myTable">
                              {(rowsPerPage > 0
                                ? filterArr.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : filterArr
                              ).map((row, index) => (
                                <StyledTableRow id="data" key={index}>
                                  <StyledTableCell
                                    scope="row"
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {row.route_name}
                                  </StyledTableCell>
                                  <StyledTableCell scope="row" align="center">
                                    {row.route_type == "PICK"
                                      ? "PICK UP"
                                      : row.route_type}
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    align="left"
                                    className="create_route_listing_padding"
                                  >
                                    {changeTimeFormat(row.start_time)}-
                                    {changeTimeFormat(row.end_time)}
                                  </StyledTableCell> */}
                                  <StyledTableCell
                                    align="left"
                                    className="create_route_listing_padding"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {row.start_point}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="create_route_listing_padding"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {row.end_point}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="create_route_listing_padding"
                                  >
                                    {row.vehicle_registered_number}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="create_route_listing_padding"
                                  >
                                    {row.driver_phone_number}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="routeopen_text_color create_route_listing_padding"
                                  >
                                    <span
                                      style={{
                                        color: "#0070bb",
                                        paddingRight: 4,
                                      }}
                                      onClick={() =>
                                        navigate("/createnewroute", {
                                          state: {
                                            pageType: "EDIT",
                                            routeData: row,
                                          },
                                        })
                                      }
                                    >
                                      Edit
                                    </span>
                                    {/* |
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "#0070bb",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                      }}
                                      onClick={() => {
                                        setrouteNameDelete(row.route_name);
                                        setRouteId(row.id);
                                        setopendialogConfimation(true);
                                      }}
                                    >
                                      Cancel
                                    </span>
                                    |
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "#0070bb",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                      }}
                                      onClick={() =>
                                        navigate("/assignStudent", {
                                          routeInfo: {
                                            id: row.id,
                                            routeName: row.route_name,
                                            rowdata: row,
                                          },
                                        })
                                      }
                                    >
                                      Assign
                                    </span> */}
                                    |
                                    <span
                                      style={{
                                        color: "#0070bb",
                                        paddingLeft: 4,
                                      }}
                                      onClick={() =>
                                        navigate("/manageRoute", {
                                          state: {
                                            routeData: row,
                                          },
                                        })
                                      }
                                    >
                                      Manage Route{" "}
                                    </span>
                                    |
                                    <span
                                      style={{
                                        color: "#0070bb",
                                        paddingLeft: 4,
                                      }}
                                      onClick={() =>
                                        navigate("/viewPassengers", {
                                          state: {
                                            data: row,
                                          },
                                        })
                                      }
                                    >
                                      View Passengers{" "}
                                    </span>
                                    |
                                    <span
                                      style={{
                                        color: "#0070bb",
                                        paddingLeft: 4,
                                      }}
                                      onClick={() =>
                                        navigate("/googlemapview", {
                                          state: {
                                            id: row.id,
                                          },
                                        })
                                      }
                                    >
                                      Track
                                    </span>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterArr.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={7}></Grid>
                        <Grid item md={5}>
                          <div className="button_main_flex mb-3 mt-4"></div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure, want to Cancel the Route : {routeNameDelete}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => DeleteTransportRoute(RouteId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(RouteListings));
