import React, { Fragment, Component } from "react";
import Footer from "./Footer/Footer.jsx";
import SHeaderSection from "./Header/SHeaderSection.jsx";
const HocS = (Wcomponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Fragment>
          <div className="app-container-hoc main-margin">
            <SHeaderSection {...this.props} />
            <div className="main_back_color1">
              <Wcomponent {...this.props} />
            </div>
            <Footer {...this.props} />
          </div>
        </Fragment>
      );
    }
  };
};

export default HocS;
