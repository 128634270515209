import React, { useState } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Expand from "react-expand-animated";
import Button from "@material-ui/core/Button";

import Holiday from "../../Common/Holiday/Holiday.jsx";
function EditInvoice(props) {
  const [createfeetype, setcreatefeetype] = useState(false);
  const [feesubtype, setfeesubtype] = useState("");
  const [editinvoice, seteditinvoice] = useState([
    { feesubtype: "2000", show: true },
    { feesubtype: "2000", show: true },
  ]);
  // const deleteGradeHandler = (args) => {
  //   editinvoice.splice(args, 1);
  //   seteditinvoice([...editinvoice]);
  // };

  const handlefeeComponent = () => {
    setcreatefeetype(true);
  };

  const closefeeComponent = () => {
    setcreatefeetype(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Edit Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-3">Invoice Number:21545245</div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Mark Willy"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Roll Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="564946646"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Class</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Class</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Title for Invoice
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Title for Invoice"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Due Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="564946646"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Recurrence</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Recurrence</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                        {createfeetype === false ? (
                          <div className="inputMarginright createinvoiceflex mt-3">
                            <div>Fee Components</div>
                            <div
                              className="d-flex"
                              onClick={handlefeeComponent}
                            >
                              <span className="iconstoaddfee">
                                <i className="fa fa-plus-circle"></i>
                              </span>
                              <span className=" addmanageuserfont mt-1 ml-2">
                                Add Fee Subtype
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3 mt-3">
                            <Expand open={createfeetype}>
                              <Card className="cardvisible_border mb-2 ">
                                <div className="card_admissiondetails_height">
                                  <div className="textfiled_margin float-right">
                                    <div>
                                      <span className="iconstoaddfee">
                                        <i
                                          className="fa fa-minus-circle"
                                          onClick={closefeeComponent}
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="textfiled_margin mb-2">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div className="inputMarginright">
                                          <label for="exampleInputEmail1">
                                            Fee SubType
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Fee SubType"
                                            autoComplete="off"
                                            value={feesubtype}
                                            onChange={(e) => {
                                              setfeesubtype(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="btn_add">
                                    <Button
                                      variant="contained"
                                      className="button_login_decoration addButtonwidth"
                                      onClick={() => {
                                        editinvoice.push({
                                          feesubtype: feesubtype,
                                          show: true,
                                        });
                                        seteditinvoice([...editinvoice]);
                                        setfeesubtype("");
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            </Expand>
                          </div>
                        )}

                        <div className="mb-3">
                          {editinvoice.map((item, index) => (
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin float-right">
                                  <div>
                                    <span className="iconstoaddfee">
                                      <i
                                        class="fa fa-pencil"
                                        onClick={() => {
                                          editinvoice[index].show = false;
                                          seteditinvoice([...editinvoice]);
                                        }}
                                      ></i>
                                    </span>
                                    <span className="iconstoaddfee ml-2">
                                      <i
                                        class="fa fa-trash"
                                        onClick={() => {
                                          editinvoice.splice(index, 1);
                                          seteditinvoice([...editinvoice]);
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="textfiled_margin mb-2">
                                  <Grid className="grid_main_div">
                                    <Grid item md={12}>
                                      <div className="inputMarginright">
                                        <label for="exampleInputEmail1">
                                          Fee SubType
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleFormControlInput1"
                                          value={editinvoice[index].feesubtype}
                                          onChange={(e) => {
                                            editinvoice[index].feesubtype =
                                              e.target.value;
                                            seteditinvoice([...editinvoice]);
                                          }}
                                          disabled={item.show}
                                          onBlur={() => {
                                            editinvoice[index].show = true;
                                            seteditinvoice([...editinvoice]);
                                          }}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Total Amount
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Total Amount"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                          <Grid item md={4}>
                            <div className="main_button_div2 mb-3 mt-4">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                              >
                                Preview
                              </Button>
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default (HOC1(EditInvoice));
