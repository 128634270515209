import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import {
  getFullName,
  blankValidator,
  showNotificationMsz,
} from "../../../../utils/Validation";

import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import Loder from "../../../Loder/Loder";
import { useMutation } from "@apollo/client";
import ParentInviteList from "../GetList/ParentInviteList";
const ParentGroupInvitation = (props) => {
  const [Update, setUpdate] = useState(false);
  const [InviteParticipantAPI, {}] = useMutation(
    Service.Add_Event_Participants
  );
  let institution_id = props.institution_id;
  let academic_session_id = parseInt(props.academic_session_id);
  const [getParentList, setGetParentList] = useState([]);
  const [selectParentId, setSelectParentId] = useState(-1);
  const client = useApolloClient();
  const [gradeArr, setGradeArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gradeId, setGradeId] = useState(-1);
  const [sectionArr, setSectionArr] = useState([]);
  const [studentID, setStudentID] = useState(-1);
  const [studentList, setstudentList] = useState([]);
  const [StudentListArr, setStudentListArr] = useState([]);
  const [sectionId, setSectionId] = useState(-1);
  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    // getParentListData();
  }, [props, Update]);
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setGradeArr(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  }, [client, academic_session_id]);

  const selectGrade = (id) => {
    setGradeId(parseInt(id));
    setSectionArr([]);
    setstudentList([]);
    setStudentID(-1);
    setSectionId(-1);
    if (id == -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectSection = (id) => {
    try {
      let section_id = parseInt(id);
      setSectionId(section_id);
      setStudentListArr([]);
      if (section_id == -1) {
        return;
      }

      setisloading(true);
      client
        .query({
          query: Service.Get_student_list_on_event_by_SectionID,
          variables: {
            section_id: parseInt(id),
            event_id: parseInt(props.eventid),
            user_type: "STUDENT",
          },
        })
        .then((response) => {
          const filterArr = response.data.getStudentListForEvent;
          const filtArr = filterArr.filter((arr) => {
            if (arr.status === "ACTIVE") {
              return arr;
            }
          });

          let temp = [];
          for (let index = 0; index < filtArr.length; index++) {
            const element = filtArr[index];
            temp.push({
              user_id: element.id,
              name: element?.student_profile?.name,
              primary_contact_id: element?.student_profile?.primary_contact_id,
            });
          }

          setStudentListArr(temp);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const getParentListData = () => {
    try {
      setIsLoading(true);
      client
        .query({
          query: Service.GET_PARENT_list,
          variables: {
            institution_id,
          },
        })
        .then(async (response) => {
          setIsLoading(false);
          const element = response.data.getParentList;
          // if (element) {
          //   const sortedList = element.sort((a, b) => {
          //     if (blankValidator(a.name) && blankValidator(b.name) )
          //       getFullName(a.name)
          //         .toLowerCase()
          //         .localeCompare(getFullName(b.name).toLowerCase());
          //     else {
          //       return false;
          //     }
          //   });
          //   setGetParentList(sortedList);
          // }
          if (element) {
            let temp = [];
            for (
              let index = 0;
              index < response.data.getParentList.length;
              index++
            ) {
              const element = response.data.getParentList[index];
              if (getFullName(element.name) !== "") {
                await temp.push(element);
              }
            }

            const sortedList = await temp.sort((a, b) => {
              if (blankValidator(a.name) && blankValidator(a.name))
                getFullName(a.name)
                  .toLowerCase()
                  .localeCompare(getFullName(b.name).toLowerCase());
            });

            await setGetParentList(sortedList);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };

  const InviteUser = async () => {
    if (selectParentId === -1) {
      alert("Please select Student to invite");
    }

    let StudentInviteArray = [];
    if (selectParentId === 0) {
      StudentListArr.map((student_data) => {
        StudentInviteArray.push({
          user_id: student_data.primary_contact_id,
          email: " ",
          user_type: "PARENT",
        });
      });
    } else {
      StudentInviteArray = [
        { user_id: selectParentId, email: " ", user_type: "PARENT" },
      ];
    }

    setIsLoading(true);
    await InviteParticipantAPI({
      variables: {
        event_id: +props.eventid,
        event_invite_type: "PARENT",
        participants: StudentInviteArray,
      },
    })
      .then((response) => {
        setUpdate(!Update);
        setIsLoading(false);
        showNotificationMsz("Event Add successfully.", "success");
        // getParentListData();
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const selectParentValue = (id) => {
    try {
      setSelectParentId(parseInt(id));
    } catch (error) {}
  };
  const selectStudentID = (id) => {
    try {
      setSelectParentId(parseInt(id));
    } catch (error) {}
  };
  return (
    <div>
      <Grid className="grid_main_div mt-3">
        <Grid item md={12}>
          <div className="mt-2">
            <div>
              <Grid className="grid_main_div mt-2">
                <Grid item md={3}>
                  <div className="inputMarginright">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={gradeId}
                      onChange={(e) => selectGrade(e.target.value)}
                    >
                      <option value={-1}>Search By Class</option>

                      {gradeArr.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.gradeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={sectionId}
                      onChange={(e) => selectSection(e.target.value)}
                    >
                      <option value={-1}>Search By Section</option>
                      {sectionArr.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className="pl-1">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        selectStudentID(parseInt(e.target.value))
                      }
                      value={selectParentId}
                    >
                      <option value={-1}>Select Student</option>
                      {StudentListArr.length > 0 && (
                        <option value={0}>All</option>
                      )}
                      {StudentListArr.length > 0 &&
                        StudentListArr.map((item) => (
                          <option value={item.primary_contact_id}>
                            {getFullName(item?.name)}
                            {item.user_id}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* <div className="inputMarginright">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={selectParentId}
                      onChange={(e) => selectParentValue(e.target.value)}
                    >
                      <option value={-1}>Search By Parent</option>
                      {getParentList.length > 0 && (
                        <option value={0}>All</option>
                      )}
                      {getParentList.length > 0 &&
                        getParentList.map((item) => (
                          <option value={item.id}>
                            {getFullName(item?.name)}
                          </option>
                        ))}
                    </select>
                  </div> */}
                </Grid>

                <Grid item md={3}>
                  <div className="pl-1">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={InviteUser}
                    >
                      Invite
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <ParentInviteList {...props}></ParentInviteList>
            </div>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isLoading} />
    </div>
  );
};

export default ParentGroupInvitation;
