import { useApolloClient } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import {
  showNotificationMsz,
  serverDateFormat,
  blankValidator,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import { useMutation } from "@apollo/client";
import "./ManageInstitution.css";
import { de } from "date-fns/locale";
const ShowAllTimetable = (props) => {
  const { classId, sectionselectedid } = props.location.state || "";
  const [sectionId, setSectionId] = useState(-1);
  var today_date = new Date().toJSON().slice(0, 10);
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );
  //name instituition
  let Organization_Name = props.manageInstitution.institution_name;

  const client = useApolloClient();
  const [gradeId, setGradeId] = useState(null);
  const [managegrade, setmanagegrade] = useState([]);
  const [section, setsection] = useState([]);
  const [Sectionfromgrade, setSectionfromgrade] = useState([]);
  const [isloading, setisloading] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [allTimeTableData, setAllTimeTableData] = useState([]);
  const [editDailogOpen, setEditDailogOpen] = useState(false);
  const [updateStartDate, setUpdateStartDate] = useState("");
  const [updateEndDate, setUpdateEndDate] = useState("");
  const [updateStartDateError, setUpdateStartDateError] = useState(false);
  const [updateEndDateError, setUpdateEndDateError] = useState(false);
  const [updateTimeId, setUpdateTimeId] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState("");

  const [deleteClassTimetable, {}] = useMutation(Service.deleteClassTimetable);
  const [updateclasstimetable, {}] = useMutation(Service.updateclasstimetable);
  const assignTeacher = (row) => {
    setisloading(true);
    client
      .query({
        query: Service.Get_Id_For_Teacher,
        variables: {
          institution_id,
          section_id: parseInt(row.id),
          date: today_date,
        },
      })
      .then((response) => {
        if (response.data.getClassTimetableID) {
          props.navigate("/assignteacher", {
            data: row,
            id: response.data.getClassTimetableID.id,
          });
        } else {
          showNotificationMsz(
            "Please assign timetable to particular class",
            "success"
          );
        }

        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        showNotificationMsz(e, "danger");
      });
  };
  const timetable = (row) => {
    props.navigate("/timetable", {
      data: row,
      classId: row.grade.id,
      secId: row.name,
      classselectedid: gradeId,
      sectionselectedid: row.id,
    });
  };
  const changeSectionHandler = (id) => {
    if (sectionId === -1) {
      setSectionfromgrade([]);
      return;
    }
    setSectionId(id);

    const filtArr = section.filter((arr) => {
      return parseInt(arr.id) === parseInt(id);
    });
    setSectionfromgrade(filtArr);
    AllTimeTableGet(id);
  };
  useEffect(() => {
    // window.scrollTo(0, 0);

    const getManageGrades = async () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then(async (response) => {
            setisloading(false);
            await setmanagegrade(response.data.getGrade);
            if (blankValidator(classId)) selectClassHandler(classId, true);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getManageGrades();
  }, [client, academic_session_id]);

  const selectClassHandler = (classId, isfirstTime) => {
    setGradeId(classId);
    if (classId === -1) {
      setsection([]);
      setSectionId(-1);
      return;
    }
    setSectionId(-1);
    setGradeId(classId);
    setAllTimeTableData([]);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          const sectionData = response.data.getSectionAndGrade;
          setSectionId(sectionselectedid || undefined);
          setsection(sectionData);
          setSectionfromgrade(sectionData);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const assignSearch = () => {
    setSectionfromgrade(section);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openaddeditclass = () => {
    props.navigate("/addeditclass");
  };
  useEffect(() => {
    AllTimeTableGet(sectionselectedid || -1);
  }, []);
  const AllTimeTableGet = (section_id) => {
    try {
      if (section_id === -1) {
        return;
      }
      setSectionId(section_id);
      setisloading(true);
      client
        .query({
          query: Service.get_All_Timetable_Data,
          variables: {
            institution_id,
            section_id: parseInt(section_id),
            day: 1,
          },
        })
        .then((response) => {
          setAllTimeTableData(response.data.getAllClassTimeTable);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {}
  };

  const handleEditData = (row) => {
    props.navigate("/TimeTableUpdateTime", {
      state: {
        row: row,
        sectionId: sectionId,
      },
    });
  };
  const handleDailogOpen = (row) => {
    const { start_date, end_date, id } = row;
    setUpdateEndDate(end_date);
    setUpdateStartDate(start_date);
    setUpdateTimeId(id);
    setEditDailogOpen(true);
  };
  const handleUpdateTime = () => {
    if (!blankValidator(updateStartDate)) {
      setUpdateStartDateError(true);
      return;
    }
    if (!blankValidator(updateEndDate)) {
      setUpdateEndDateError(true);
      return;
    }
    try {
      setisloading(true);
      updateclasstimetable({
        variables: {
          id: +updateTimeId,
          start_date: updateStartDate,
          end_date: updateEndDate,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateclasstimetable.message,
            "success"
          );
          AllTimeTableGet(sectionId);
          setEditDailogOpen(false);
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const DeleteDailogBox = (row) => {
    const { id } = row;
    setDeleteRowId(id);
    setDeleteDialogOpen(true);
  };
  const deleteTimeTableHandler = () => {
    try {
      setisloading(true);
      deleteClassTimetable({
        variables: { id: +deleteRowId },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.deleteClassTimetable.message,
            "success"
          );

          setDeleteDialogOpen(false);
          AllTimeTableGet(sectionId);
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Show Timetables List
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                value={gradeId}
                                onChange={(e) =>
                                  selectClassHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Class</option>

                                {managegrade &&
                                  managegrade.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-1">
                              <select
                                className="form-control"
                                value={sectionId}
                                onChange={(e) =>
                                  changeSectionHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Section</option>
                                {section.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2}></Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Grade
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Start date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? allTimeTableData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : allTimeTableData
                                ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">
                                      {row.section?.grade?.gradeName}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.section?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className=" ">
                                      {serverDateFormat(row.start_date)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className=" ">
                                      {serverDateFormat(row.end_date)}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className="content_color manage_edit_cursor"
                                    >
                                      Active
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="content_color manage_edit_cursor"
                                    >
                                      {" "}
                                      <span
                                        className="tab__link"
                                        onClick={() => handleDailogOpen(row)}
                                      >
                                        Edit
                                      </span>{" "}
                                      |{" "}
                                      <span
                                        className="tab__link"
                                        onClick={() => handleEditData(row)}
                                      >
                                        Open
                                      </span>{" "}
                                      |{" "}
                                      <span
                                        className="tab__link"
                                        onClick={() => DeleteDailogBox(row)}
                                      >
                                        Delete
                                      </span>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={allTimeTableData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div>
        <Dialog
          open={editDailogOpen}
          onClose={() => {
            setEditDailogOpen(false);
          }}
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    setEditDailogOpen(false);
                  }}
                ></i>
              </div>
            </div>
            <div>
              <div>
                <h3>Update Time</h3>
              </div>
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                {" "}
                <lebel className="text_filed_heading float-left">
                  Start Date
                </lebel>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Name"
                  value={updateStartDate}
                  onChange={(e) => {
                    setUpdateStartDateError(false);
                    setUpdateStartDate(e.target.value);
                  }}
                />
                {updateStartDateError && (
                  <span className="text-danger">Enter Start Date</span>
                )}
              </div>
              <div className=" inputMarginright ml-4 mr-4 mt-2">
                {" "}
                <lebel className="text_filed_heading float-left">
                  End Date
                </lebel>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Name"
                  value={updateEndDate}
                  onChange={(e) => {
                    setUpdateEndDateError(false);
                    setUpdateEndDate(e.target.value);
                  }}
                />
                {updateEndDateError && (
                  <span className="text-danger">Enter End Date</span>
                )}
              </div>
            </div>
            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={() => {
                      handleUpdateTime();
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      setEditDailogOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    setDeleteDialogOpen(false);
                  }}
                ></i>
              </div>
            </div>
            <div className="pl-5 pr-5 mt-4">Confirm delete of TimeTable ?</div>

            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={() => deleteTimeTableHandler()}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      setDeleteDialogOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ShowAllTimetable));
