import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  blankValidator,
  getcurrentDatewithoutTimezone,
  serverFormatDate,
} from "../../../utils/Validation";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  // Readonly,
} from "@syncfusion/ej2-react-schedule";
//redux
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";

import { getData } from "../../../api/index";
// import Loder from "../../Loder/Loder";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
const CommonHoliDayCalender = (props) => {
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [eventdata, setEvent] = useState([]);
  let institution_id = parseInt(props.data.institution_id);
  let academic_session_id = blankValidator(
    parseInt(props.location?.state?.academic_session_id)
  )
    ? parseInt(props.location.state.academic_session_id)
    : -1;

  useEffect(() => {
    getSchoolCalenderData();
  }, [props]);
  const getSchoolCalenderData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_School_Calendar,
          variables: {
            institution_id,
            date: serverFormatDate(new Date()),
            academic_session_id,
          },
        })
        .then((response) => {
          let array = [];
          let calenderAraray = response.data.getSchoolCalendar;
          for (let index = 0; index < calenderAraray.length; index++) {
            const element = calenderAraray[index];
            array.push({
              Id: index,
              Subject: element.name,
              StartTime: getcurrentDatewithoutTimezone(element.start_datetime),
              EndTime: getcurrentDatewithoutTimezone(element.end_datetime),
              IsAllDay: true,
            });
            setEvent(array);
          }

          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-4 Heading_bold">Calendar</div>

                  <div
                    className="mr-3 mt-4 Heading_bold date_time_color"
                    role="button"
                    onClick={() => {
                      props.navigate("/holidaycalender", {
                        state: {
                          academic_session_id,
                        },
                      });
                    }}
                  >
                    Show All Holiday
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-3">
                          {" "}
                          <ScheduleComponent
                            currentView="Month"
                            showHeaderBar={true}
                            selectedDate={new Date()}
                            eventSettings={{ dataSource: eventdata }}
                            cellClick={true}
                            cellDoubleClick={true}
                          >
                            <Inject
                              services={[Day, Week, WorkWeek, Month, Agenda]}
                            />
                          </ScheduleComponent>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(CommonHoliDayCalender));
