import { useApolloClient, useMutation } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

//-----------redux---------------
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { invoiceStatusArr } from "../../../utils/LocalData";
import {
  blankValidator,
  CapitalizeFirstFn,
  dateToDatedashtoDDMMYY,
  getFullName,
  getValue,
  showNotificationMsz,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import "./Feemodule.css";
import { Button } from "@material-ui/core";

const StudentInvoice = (props) => {
  const [Delete_Invoice_Data, {}] = useMutation(Service.Delete_Invoice_Data_Student);
  let classId;
  let sectionId;
  let studentID_temp;
  let invoiceName;

  if (props.from === "report") {
    classId = props.location.state.json.studentClassId;
    sectionId = props.location.state.json.SectionId;
    sectionId = props.location.state.json.SectionId;
    studentID_temp = props.location.state.json.userId;
    invoiceName = props.location.state.json.invoiceName;
  } else {
    classId = props.location.state.classId;
    sectionId = props.location.state.sectionId;
    invoiceName = props.location.state.invoiceName;
  }

  //------------------global id---------------------
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );
  //--------------------api calls------------------
  const client = useApolloClient();
  const [sectionid, setsectionid] = useState(
    sectionId !== undefined ? sectionId : -1
  );

  const classes = useStyles();
  //--------------------local state------------------
  const [searchInvoice, setSearchInvoice] = useState(
    invoiceName == undefined ? "" : invoiceName
  );
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [studentInvoiceArr, setStudentInvoiceArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [StudentListArr, setStudentListArr] = useState([]);
  const [classID, setClassID] = useState(classId !== undefined ? classId : -1);
  const [studentID, setStudentID] = useState(
    studentID_temp !== undefined ? studentID_temp : -1
  );
  const [status, setstatus] = useState("DUE");
  const [deleteInvoiceId, setdeleteInvoiceId] = useState("");
  const [DeleteInvoiceName, setDeleteInvoiceName] = useState("");
  const [opendialogConfimation, setopendialogConfimation] = useState(false);

  useEffect(() => {
    statusHandler(status);
  }, [status]);
  useEffect(() => {
    selectSectionHandler(sectionid);
  }, [sectionid]);

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("arr try and catch", error);
    }
    classHandler(classID);
    //to get Class Data
  }, [classID]);

  const statusHandler = (status) => {
    if (status === "") {
      setClassID(-1);
      setsectionid(-1);
      setStudentID(-1);
      setStudentInvoiceArr([]);
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Invoice_For_Institute,
          variables: {
            institution_id,
            academic_session_id,
            status,
          },
        })
        .then(async (response) => {
          const studentInvoiceArrtemp = response.data.getInvoiceForInstitute;
          let temp = [];
          studentInvoiceArrtemp.map((item) => {
            item.student_invoices.map((item1) => {
              item1["name"] = item.name;
              item1["create_date"] = item.create_date;
              temp.push(item1);
            });
          });

          await setStudentInvoiceArr(temp);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("student list  catch err", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("student list  t and c error", error);
    }
  };
  const filterStudentArr = studentInvoiceArr.filter((event, index) => {
    console.log("event", event);
    return (
      (classID != -1 ? event.student.section.grade.id == classID : true) &&
      (sectionid != -1 ? event.student.section.id == sectionid : true) &&
      (studentID != -1 ? event.student_id == studentID : true) &&
      (searchInvoice != ""
        ? getValue(event.name)
            ?.toLowerCase()
            ?.indexOf(searchInvoice?.toLowerCase()) !== -1
        : true)
    );
  });

  const classHandler = (clId) => {
    if (isFirstTime) {
      setsectionid(-1);
      setStudentID(-1);
      setSectionArr([]);
      setStudentListArr([]);
    } else {
      setIsFirstTime(true);
    }

    if (parseInt(clId) == -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(clId),
          },
        })
        .then((response) => {
          setisloading(false);

          setSectionArr(response.data.getSectionAndGrade);
          // setSectionfromgrade(response.data.Sections)
          //setname("");
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectSectionHandler = (ID) => {
    let section_id = parseInt(ID);
    setStudentListArr([]);
    if (isFirstTime) {
      setStudentID(-1);
    }

    if (section_id === -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setStudentListArr(response.data.getStudentListBySectionId);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const studentHandler = (userId) => {
    try {
      let student_id = parseInt(userId);
      setStudentID(student_id);
    } catch (error) {
      console.log("student list  t and c error", error);
    }
  };
  const invoiceHandler = (invoiceId) => {
    if (invoiceId === -1) {
      // do something
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // delete  invoice
  const DeleteDailogBox = (data) => {
    setdeleteInvoiceId(data.id);
    setDeleteInvoiceName(data.name);
    setopendialogConfimation(true);
  };
  // to delete invoice---------------------------
  const deleteInvoiceFn = (ID) => {
    try {
      let id = parseInt(ID);
      setisloading(true);
      Delete_Invoice_Data({
        variables: { id },
      })
        .then((response) => {
          setopendialogConfimation(false);
          setisloading(false);
          if (response.data.deleteStudentInvoice.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(response.data.deleteStudentInvoice.message, "success");
          statusHandler(status)
        })
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Student Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={2}>
                            <div className="inputMarginright mt-1">
                              {/* <label>Select Status</label> */}
                              <select
                                value={status}
                                className="form-control"
                                onChange={(e) => setstatus(e.target.value)}
                              >
                                <option value="">Select Status</option>
                                {invoiceStatusArr.map((item) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2}>
                            <div className="inputMarginright mt-1">
                              {/* <label>Select Class</label> */}
                              <select
                                className="form-control"
                                value={classID}
                                onChange={(e) => setClassID(e.target.value)}
                                disabled={status == ""}
                              >
                                <option value={-1}>Select Class</option>
                                {classArr.length > 0 &&
                                  classArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2}>
                            <div className="inputMarginright mt-1">
                              {/* <label>Select Section</label> */}
                              <select
                                className="form-control"
                                value={sectionid}
                                disabled={classID == -1}
                                onChange={(e) => setsectionid(e.target.value)}
                              >
                                <option value={-1}>Select Section</option>
                                {sectionArr.length != 0 &&
                                  sectionArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2}>
                            <div className="mt-1">
                              {/* <label>Select Student</label> */}
                              <select
                                className="form-control"
                                onChange={(e) => studentHandler(e.target.value)}
                                value={studentID}
                                disabled={sectionid == -1}
                              >
                                <option value={-1}>Select Student</option>
                                {StudentListArr.length > 0 &&
                                  StudentListArr.map((item) => (
                                    <option
                                      value={item.id}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {getFullName(item.student_profile?.name)}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2} className="ml-1">
                            <div className="mt-1">
                              <input
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Invoice title"
                                autoComplete="off"
                                value={searchInvoice}
                                onChange={(e) => {
                                  setSearchInvoice(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        {/* <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <label>Select Invoice</label>
                              <select
                                className="form-control"
                                onChange={(e) => invoiceHandler(e.target.value)}
                              >
                                <option value={-1}>Select Invoice </option>
                                {invoiceArr.length !== 0 &&
                                  invoiceArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <label>Select Invoice Date</label>
                              <input
                                type="date"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                                value={invoiceDate}
                                onChange={(e) => {
                                  setInvoiceDate(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <label>Select Due Date</label>
                              <input
                                type="date"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search"
                                autoComplete="off"
                                value={dueDate}
                                onChange={(e) => {
                                  setDueDate(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="button_text_formating mt-4">
                            <label></label>
                              <Button
                                onClick={() => {
                                  sortInvoice(status);
                                }}
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration "
                              >
                                Search
                              </Button>
                            </div>
                          </Grid>
                        </Grid> */}

                        <div className="mt-3 mb-2 Heading_bold">Invoice</div>

                        <div className="card_admissiondetails_height">
                          <div className="table_foramtitng mt-2 mb-2">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Student Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Class Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Section Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Invoice Title
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Due Date
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Status
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Action
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(rowsPerPage > 0
                                    ? filterStudentArr.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterStudentArr
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        align="left"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {getFullName(
                                          row?.student?.student_profile?.name
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <span>
                                          {
                                            row?.student?.section?.grade
                                              ?.gradeName
                                          }
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <span>
                                          {row?.student?.section?.name}
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.name) ? (
                                          ""
                                        ) : (
                                          <span>
                                            {CapitalizeFirstFn(row.name)}
                                          </span>
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {dateToDatedashtoDDMMYY(row.due_date)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {!blankValidator(row.status)
                                          ? ""
                                          : row.status}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <div className="d-flex">
                                          <div>
                                            {row.status ===
                                            "VERIFICATION_PENDING" ? (
                                              <span
                                                className="row_details_color pr-2"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/viewInvoiceSummary",
                                                    {
                                                      state: {
                                                        id: row.id,
                                                        status: row.status,
                                                        pageType:
                                                          "StudentInvoice",
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                VERIFY
                                              </span>
                                            ) : (
                                              <span
                                                className="row_details_color pr-2"
                                                onClick={() =>
                                                  props.navigate(
                                                    "/viewInvoiceSummary",
                                                    {
                                                      state: {
                                                        id: row.id,
                                                        status: row.status,
                                                        pageType:
                                                          "StudentInvoice",
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                Open
                                              </span>
                                            )}
                                          </div>

                                          {row.status === "DUE" && (
                                            <div>
                                              {" "}
                                              |
                                              <span
                                                className="actionStyle row_details_color"
                                                onClick={() =>
                                                  DeleteDailogBox(row)
                                                }
                                              >
                                                Delete
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterStudentArr.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure, Want to delete the Invoice : {DeleteInvoiceName}
          </div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteInvoiceFn(deleteInvoiceId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentInvoice));
