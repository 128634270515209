import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Loder from "../../Loder/Loder.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  getValue,
  getFullName,
} from "../../../utils/Validation";

import Holiday from "../../Common/Holiday/Holiday.jsx";

const ParentStudentTransport = (props) => {
  console.log("transppoert props", props);
  const { studentData } = props.data;
  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //father id
  let father_id = parseInt(props.data.userid);

  //Institute name
  let institution_Name = props.data.institution_name;

  const [RouteId, setRouteId] = useState(-1);
  // to get data api
  const client = useApolloClient();
  const classes = useStyles();
  const [ParentStudentData, setParentStudentData] = useState([]);
  const [pickupRouteName, setPicupRouteName] = useState("");
  const [pikupStopName, setPikupStopName] = useState("");
  const [DropRouteName, setDropRouteName] = useState("");
  const [DropStopName, setDropStopName] = useState("");
  const [isloading, setisloading] = useState(false);
  const [transportStopId, setTransportStopId] = useState("");
  const [pickRouteType, setPickRouteType] = useState("");
  const [stopRouteType, setStopRouteType] = useState("");
  const [transportPickData, setTransportPickData] = useState("");
  const [transportDropData, setTransportDropData] = useState("");
  const [studentName, setStudentName] = useState("");

  // useEffect(() => {
  //   try {
  //     setisloading(true);
  //     client
  //       .query({
  //         query: Service.Get_Parent_student_data,
  //         variables: {
  //           institution_id,
  //           father_id,
  //           status: "ACTIVE",
  //         },
  //       })
  //       .then((response) => {
  //         setisloading(false);

  //         if (response.data.getStudentByParent.length > 0) {
  //           setParentStudentData(response.data.getStudentByParent);
  //         }
  //       })
  //       .catch((err) => {
  //         setisloading(false);
  //         console.log("exam catch err", err);
  //       });
  //   } catch (error) {
  //     setisloading(false);
  //     console.log("exam t and c error", error);
  //   }
  // }, [institution_id, father_id, client]);

  useEffect(() => {
    getStudentRouteData();
  }, []);

  const getStudentRouteData = () => {
    if (studentData.id === -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Student_Details_Data,
          variables: {
            id: parseInt(studentData.id),
          },
        })
        .then((response) => {
          let transportData = response.data.getStudentDetailForm;
          if (transportData) {
            setStudentName(transportData?.student_profile.name);
            setTransportPickData(transportData?.transport_pickup);
            setRouteId(transportData?.transport_pickup?.transport_route?.id);
            setPicupRouteName(
              transportData?.transport_pickup?.transport_route?.route_name
            );
            setPickRouteType(
              transportData?.transport_pickup?.transport_route?.route_type
            );
            setPikupStopName(transportData?.transport_pickup?.stop_name);
            setDropRouteName(
              transportData?.transport_stop?.transport_route?.route_name
            );
            setStopRouteType(
              transportData?.transport_stop?.transport_route?.route_type
            );
            setTransportDropData(transportData?.transport_stop);
            setDropStopName(transportData?.transport_stop?.stop_name);
            setTransportStopId(
              transportData?.transport_stop?.transport_route?.id
            );
          }
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log(" catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log(" institution arr try and catch", error);
    }
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_Name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-3 Heading_bold">Transport Details</div>
              </div>
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <div className=" d-flex mt-2">
                        <div className="text_filed_heading">
                          Student Name :{" "}
                        </div>
                        <div>{getFullName(studentData?.name)}</div>
                      </div>
                      <div className=" d-flex mt-2">
                        <div className="text_filed_heading">Class :</div>
                        <div>
                          {studentData?.section?.grade?.gradeName}
                          {"-"}
                          {studentData?.section?.name}
                        </div>
                      </div>
                      {/* <div className="mt-1 mb-2">
                        <select
                          class="form-control"
                          onChange={(e) => {
                            getStudentRouteData(e.target.value);
                          }}
                        >
                          <option value={-1}>Student List</option>
                          {ParentStudentData.map((item, index) => (
                            <option value={item?.student?.id} key={index}>
                              {getFullName(item?.name)}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      {!blankValidator(pickupRouteName) ? (
                        ""
                      ) : (
                        <div className="table_foramtitng pb-2 pt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header create_route_listing_padding"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header create_route_listing_padding"
                                  >
                                    Route Type
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header create_route_listing_padding"
                                  >
                                    Route Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header create_route_listing_padding"
                                  >
                                    Stop Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header create_route_listing_padding"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody id="myTable">
                                {transportPickData && (
                                  <StyledTableRow id="data">
                                    <StyledTableCell scope="row" align="center">
                                      {getFullName(studentName)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {getValue(pickRouteType)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {" "}
                                      {getValue(pickupRouteName)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {getValue(pikupStopName)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      <span
                                        className="cursor"
                                        style={{
                                          fontSize: 12,
                                          color: "#0070bb",
                                          paddingLeft: 4,
                                          paddingRight: 4,
                                        }}
                                        onClick={() => {
                                          props.navigate("/googlemapview", {
                                            state: {
                                              id: RouteId,
                                            },
                                          });
                                        }}
                                      >
                                        Track
                                      </span>{" "}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )}

                                {/* -----stop data row---- */}
                                {transportDropData && (
                                  <StyledTableRow id="data">
                                    <StyledTableCell scope="row" align="center">
                                      {getFullName(studentName)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {getValue(stopRouteType)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {getValue(DropRouteName)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {" "}
                                      {getValue(DropStopName)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      {" "}
                                      <span
                                        style={{
                                          fontSize: 12,
                                          color: "#0070bb",
                                          paddingLeft: 4,
                                          paddingRight: 4,
                                        }}
                                        onClick={() => {
                                          props.navigate("/googlemapview", {
                                            id: RouteId,
                                          });
                                        }}
                                      >
                                        Track
                                      </span>{" "}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ParentStudentTransport));
