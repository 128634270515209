import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Holiday from "../../Common/Holiday/Holiday.jsx";
function CreateTimeTableTemplate({ ADMIN_DATA, navigate }) {
  //-----------------------------global id-------------------------
  const { institution_name } = ADMIN_DATA;
  let institution_id = ADMIN_DATA.institution_id;
  //-----------------------------api calls-------------------------
  const [Add_TimeTable_Template, {}] = useMutation(
    Service.Add_TimeTable_Template
  );
  const [Delete_timeTable_template, {}] = useMutation(
    Service.Delete_timeTable_template
  );
  const [Update_timeTableTemplate, {}] = useMutation(
    Service.Update_timeTableTemplate
  );
  const client = useApolloClient();
  //-----------------------------local state-------------------------
  const [isUpdated, setIsUpdated] = useState(false);
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [name, setName] = useState("");
  const [isloading, setisloading] = useState(false);
  const [MangeTemplate, setMangeTemplate] = useState([]);
  const [nameerror, setnameerror] = useState(false);
  const [opendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [nameedit, setNameedit] = useState("");
  const [templateid, settemplateid] = useState("");
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [Deleteid, setDeleteid] = useState("");
  const [templatenamedelete, settemplatenamedelete] = useState("");
  const [nameeitError, setnameeitError] = useState(false);
  //-----------------------------methods-------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_TimeTable_Template,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setMangeTemplate(response.data.getTimeTableTemplate);
          setaddMangeopen(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    // getManageTemplate();
    return () => setIsUpdated(false);
  }, [isUpdated, client, institution_id]);

  const OpenEditQuery = (data) => {
    setNameedit(data.name);
    settemplateid(data.id);
    setOpendailogtoEdit(true);
  };

  const DeleteDailogBox = (data) => {
    setDeleteid(data.id);
    settemplatenamedelete(data.name);
    setopendialogConfimation(true);
  };

  const createtemplate = () => {
    if (!blankValidator(name)) {
      setnameerror(true);
      return;
    }

    try {
      setisloading(true);
      Add_TimeTable_Template({
        variables: { institution_id, name },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.addTimeTableTemplate.message,
            "success"
          );
          navigate("/createupdatetimetable", {
            state: {
              Template_id: response.data.addTimeTableTemplate.id,
              name: name,
            },
          });

          setIsUpdated(true);
        },
        (error) => {
          showNotificationMsz(error, "danger");
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const UpdateTemplateHandler = () => {
    let id = parseInt(templateid);
    if (!blankValidator(nameedit)) {
      setnameeitError(true);
      return;
    }
    try {
      setisloading(true);
      Update_timeTableTemplate({
        variables: {
          id,
          institution_id,
          name: nameedit,
        },
      })
        .then((response) => {
          setIsUpdated(true);
          setisloading(false);
          setOpendailogtoEdit(false);
          showNotificationMsz(
            response.data.updateTimeTableTemplate.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);

          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const deleteGradeHandler = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_timeTable_template({
        variables: { id },
      })
        .then((response) => {
          setIsUpdated(true);
          setisloading(false);
          showNotificationMsz(
            response.data.deleteTimeTableTemplate.message,
            "success"
          );

          setopendialogConfimation(false);
        })
        .catch((error) => {
          setisloading(false);

          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Manage TimeTable Template
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {!addMangeopen ? (
                          <div
                            className="d-flex"
                            onClick={() => {
                              setaddMangeopen(true);
                            }}
                          >
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add New TimeTable Template
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div
                                          className="text-right"
                                          onClick={() => {
                                            setaddMangeopen(false);
                                          }}
                                        >
                                          <span className="iconstoaddfee">
                                            <i class="fa fa-times cursor"></i>
                                          </span>
                                        </div>
                                        <div className="text_filed_heading">
                                          Template Name
                                        </div>
                                        <div className=" mt-1">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Template Name"
                                            autoComplete="off"
                                            value={name}
                                            onChange={(e) => {
                                              setnameerror(false);
                                              setName(e.target.value);
                                            }}
                                          />
                                          {nameerror && (
                                            <span className="error_message_color">
                                              Enter the template name
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className=" mb-1">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={createtemplate}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin cardheight_overflow">
                        <div className="text_filed_heading">Template List</div>
                        {MangeTemplate.length ? (
                          MangeTemplate.map((item, index) => (
                            <Card className="cardvisible_border mb-2 mt-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={2}></Grid>
                                      <Grid item md={6}>
                                        <div className=" mt-1 mb-1">
                                          <input
                                            type="text"
                                            className="form-control input_border_none department_color_disabled"
                                            id="exampleFormControlInput1"
                                            placeholder="Name"
                                            value={item.name}
                                            disabled={true}
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={4}>
                                        <div className="d-flex pt-2">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>

                                          <span
                                            className="icon_pencil_color manage_edit_cursor ml-4"
                                            onClick={() =>
                                              navigate(
                                                "/createupdatetimetable",
                                                {
                                                  state: {
                                                    Template_id: item.id,
                                                    name: item.name,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            Manage
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label for="exampleInputEmail1">Template Name</label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={nameedit}
                    onChange={(e) => {
                      setNameedit(e.target.value);
                    }}
                  />
                  {nameeitError && (
                    <span className="text-danger">Enter the Template Name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4 mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateTemplateHandler}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Template : {templatenamedelete}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteGradeHandler(Deleteid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(CreateTimeTableTemplate));
