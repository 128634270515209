import React, { useEffect, useRef, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";

import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import { baseUrl } from "../../../services/index.js";
import {
  getFullAddress,
  getFullName,
  options,
  showNotificationMsz,
  timeStampToDate,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";

const StudentInvoiceSummaryById = (props) => {
  //------------------------------global id-------------------------
  const {
    institution_id,
    institution_name,
    currency_code,
    address,
    institutePhone,
    instituteEmail,
    userid,
    usertype
  } = props.STUDENT_DATA;
  console.log("props.STUDENT_DATA", props.STUDENT_DATA);
  const ref = React.createRef();
  const componentRef = useRef();
  const [invoiceData, setInvoiceData] = useState({});
  const parse = require("html-react-parser");
  const { id } = props.location.state;

  //------------------------------api calls-------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //------------------------------local state-------------------------
  const [BankDetailsObj, setBankDetailsObj] = useState({
    bank_name: "",
    bank_code: "",
    beneficiary_name: "",
    account_number: "",
  });
  const [address1, setAddress1] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [instEmail, setInstEmail] = useState("");
  const [parentName, setParentName] = useState("");
  const [studentFullName, setStudentFullName] = useState("");
  const [parentFullName, setParentFullName] = useState("");
  const [studentRollNo, setStudentRollNo] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const [InvoiceSummaryArr, setInvoiceSummaryArr] = useState([]);
  const [payment, setPayment] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [openVerifedDialog, setOpenVerifedDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [verify_Invoice_Status, {}] = useMutation(
    Service.verify_Invoice_Status
  );
  //------------------------------methods-------------------------

  useEffect(() => {
    getStudentInvoiceInfo(id);
    getBankInfo();
    //getParentDetails();
    // getInstituteData();
  }, []);
  const getParentDetails = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.GET_STUDENT_DETAILS,
        variables: {
          id: +userid,
        },
      });
      if (data && data.getStudentDetailForm) {
        const { student_profile } = data.getStudentDetailForm;
        if (student_profile) {
          const { father, mother, guardian, primary_contact_id } =
            student_profile;

          if (father) {
            if (+primary_contact_id === +father.id) setParentName(father);
          }
          if (guardian) {
            if (+primary_contact_id === +guardian.id) setParentName(guardian);
          }
          if (mother) {
            if (+primary_contact_id === +mother.id) setParentName(mother);
          }
        }
      }

      setisloading(false);
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };

  const getStudentInvoiceInfo = async (id) => {
    setisloading(true);
    try {
      const { data } = await client.query({
        query: Service.GetStudentInvoiceById,
        variables: {
          id,
        },
      });
      setisloading(false);
      const { invoice, invoice_display_id, payments } =
        await data?.getStudentInvoiceById;
      if (invoice) {
        invoice["invoice_display_id"] = invoice_display_id;
        await setInvoiceSummaryArr(invoice?.invoice_fee_items);
        await setInvoiceData(invoice);
      }

      await setPayment(payments);

      const { student_profile, roll_number, section } =
        data.getStudentInvoiceById.student;

      if (usertype === "ADMIN") {
	      await setAddress1(getFullAddress(props.STUDENT_DATA.data?.address));
	      await setPhoneNo(props.STUDENT_DATA.data?.phone_number);
	      await setInstEmail(props.STUDENT_DATA.data?.email_address);
      } else {   
	      await setAddress1(getFullAddress(address));
	      await setPhoneNo(props.STUDENT_DATA?.institutePhone);
	      await setInstEmail(props.STUDENT_DATA?.instituteEmail);
      }

	    console.log("\ninvoice inst address", address1);
	    console.log("\ninvoice inst phone", phoneNo);
	    console.log("\ninvoice inst Email", instEmail);

      if (student_profile?.father) {
	      if (+student_profile?.primary_contact_id === +student_profile?.father.id) setParentName(student_profile?.father);
      }
      if (student_profile?.guardian) {
	      if (+student_profile?.primary_contact_id === +student_profile?.guardian.id) setParentName(student_profile?.guardian);
      }
      if (student_profile?.mother) {
	      if (+student_profile?.primary_contact_id === +student_profile?.mother.id) setParentName(student_profile?.mother);
      }

      await setStudentFullName(getFullName(student_profile?.name));
      //await setParentFullName(getFullName(student_profile?.father?.name));
      await setStudentRollNo(roll_number);
      await setStudentClass(section?.grade?.gradeName);
      await setStudentSection(section?.name);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getBankInfo = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Bank_Information,
        variables: {
          institution_id: parseInt(institution_id),
        },
      });
      const { bank_name, bank_code, account_number, beneficiary_name } =
        data.getBankInfo;
      setBankDetailsObj({
        ...BankDetailsObj,
        bank_name,
        bank_code,
        account_number,
        beneficiary_name,
      });
      setisloading(false);
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
      console.log("error", error);
    }
  };

  const verfiedHandler = () => {
    setOpenVerifedDialog(true);
  };
  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const paymentPaidSet = (status) => {
    try {
      setisloading(true);
      verify_Invoice_Status({
        variables: { id, status },
      }).then(
        (response) => {
          props.navigate("/studentinvoice", {
            state: {
              classId: undefined,
              sectionId: undefined,
            },
          });
          // showNotificationMsz(error, "success");
          setOpenVerifedDialog(false);
          setisloading(false);
        },
        (err) => {
          console.log("then error", err);
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2" ref={ref}>
                <div ref={componentRef}>
                  <div className="enquiry_heading">
                    <div className="mt-3 ml-3 Heading_bold">
                      Invoice Summary
                    </div>

                    <div className="mt-3 mr-3 d-flex">
                      <div className="pr-2 ">
                        {props.location.state?.status ===
                        "VERIFICATION_PENDING" ? (
                          <Button
                            onClick={() => {
                              verfiedHandler();
                            }}
                            variant="contained"
                            className="add_new_query_btn  button_login_decoration "
                          >
                            Verified
                          </Button>
                        ) : (
                          ""
                        )}
                        {props.location.state?.status === "DUE" ? (
                          <Button
                            onClick={() =>
                              props.navigate("/invoiceDetailAlreadyPaid", {
                                state: {
                                  institution_Name: institution_name,
                                  student_invoice_id: id,
                                  BankDetailsObj,
                                  invoiceData,
                                },
                              })
                            }
                            variant="contained"
                            className="add_new_query_btn  button_login_decoration "
                          >
                            PAY
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                      <Card>
                        <div className="pl-2 pr-2 pt-1 pb-1">
                          <span className="mr-2 cursor_for_print">
                            <ReactToPdf
                              targetRef={ref}
                              filename="dayAttendance.pdf"
                              scale={1}
                              options={options}
                            >
                              {({ toPdf }) => (
                                <i class="fa fa-file-pdf-o" onClick={toPdf}></i>
                              )}
                            </ReactToPdf>
                          </span>
                          <span className="cursor_for_print">
                            <ReactToPrint
                              trigger={() => <i class="fa fa-print"></i>}
                              content={() => componentRef.current}
                            />
                          </span>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={6}>
                              <div className=" inputMarginright">
                                <div className="Heading_bold">
                                  <p>{institution_name}</p>
                                </div>
                                <div className="mt-3">
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Address</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{address1}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Contact Number</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>
                                        {phoneNo && (
                                          <span>+{phoneNo}</span>
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex">
                                    <div style={{ flex: 4 }}>
                                      <h6>Email</h6>
                                    </div>
                                    <div style={{ flex: 0.3 }}>:</div>
                                    <div style={{ flex: 5.6 }}>
                                      <p>{instEmail}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mt-5 inputMarginright"
                                style={{ marginTop: "27%" }}
                              >
                                <div className="card_invoicedetails_border p-3">
                                  <div className="Heading_bold">
                                    Student Details
                                  </div>
                                  <div className="mt-3">
                                    <div className="d-flex">
                                      <div style={{ flex: 4 }}>
                                        <h6>Name</h6>
                                      </div>
                                      <div style={{ flex: 0.3 }}>:</div>
                                      <div style={{ flex: 5.6 }}>
                                        <p>{studentFullName}</p>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ flex: 4 }}>
                                        <h6>Parent Name</h6>
                                      </div>
                                      <div style={{ flex: 0.3 }}>:</div>
                                      <div style={{ flex: 5.6 }}>
                                        <p>
                                          {Object.keys(parentName).length > 0 &&
                                            `${parentName?.name?.first_name} ${parentName?.name?.middle_name} ${parentName?.name?.last_name}`}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ flex: 4 }}>
                                        <h6>Roll Number</h6>
                                      </div>
                                      <div style={{ flex: 0.3 }}>:</div>
                                      <div style={{ flex: 5.6 }}>
                                        <p>{studentRollNo}</p>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ flex: 4 }}>
                                        <h6>Class</h6>
                                      </div>
                                      <div style={{ flex: 0.3 }}>:</div>
                                      <div style={{ flex: 5.6 }}>
                                        <p>{studentClass}</p>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ flex: 4 }}>
                                        <h6>Section</h6>
                                      </div>
                                      <div style={{ flex: 0.3 }}>:</div>
                                      <div style={{ flex: 5.6 }}>
                                        <p>{studentSection}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className="mt-2 card_invoicedetails_border">
                                <div className="Heading_bold ml-3 mt-2">
                                  {invoiceData?.name}
                                </div>
                                <div className="p-3 ">
                                  <table class="table table-striped mt-1 card_invoicedetails_border">
                                    <tbody>
                                      <tr>
                                        <th scope="row">Invoice Number</th>
                                        <td>
                                          {invoiceData.invoice_display_id &&
                                            invoiceData.invoice_display_id}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Invoice Date</th>
                                        <td>
                                          {timeStampToDate(
                                            invoiceData?.create_date
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Payment Due Date</th>
                                        <td>
                                          {timeStampToDate(
                                            invoiceData?.due_date
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">Invoice Amount</th>
                                        <td>
                                          {currency_code && currency_code}{" "}
                                          {invoiceData.amount &&
                                            invoiceData.amount}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <th scope="row">Recurrence</th>
                                        <td>{invoiceData?.recurrence}</td>
                                      </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          <div className="mt-3 mb-2 card_invoicedetails_border">
                            <div className="Heading_bold ml-3 mt-3">
                              Invoice Summary
                            </div>
                            <div className=" table_foramtitng mt-1 mb-3 p-3">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Fee Subtype Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Invoice Amount
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Tax
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Total Amount
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {InvoiceSummaryArr.map((item, index) => (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell align="left">
                                          {item.feesubtype?.name}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {currency_code && currency_code}{" "}
                                          {item.amount && item.amount}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {item.tax && item.tax}%
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {currency_code && currency_code}{" "}
                                          {parseFloat(
                                            parseFloat(item.amount) +
                                              (parseFloat(item.amount) *
                                                parseFloat(item.tax)) /
                                                100
                                          ).toFixed(2)}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>

                          {/* --------payment detail section start--------- */}
                          <div className="mt-3 mb-2 card_invoicedetails_border">
                            <div className="Heading_bold ml-3 mt-3">
                              Payment Details
                            </div>
                            <div className=" table_foramtitng mt-1 mb-3 p-3">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Type
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Bank Name
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Reference Number
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Amount
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Payment Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Notes
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Attachments
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? payment.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : payment
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {row?.funding_instrument_type}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {row.fi_name}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {row?.external_reference_number}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row?.amount}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {timeStampToDatedashDayStart(
                                            row.payment_date
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row?.notes &&
                                            parse.default(row?.notes)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          <div
                                            className="invoice_color_attch"
                                            onClick={() =>
                                              HandleImageDailogBox(row.document)
                                            }
                                          >
                                            {row?.document?.name}
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={payment.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                          {/* ----------payment detail section end */}

                          {/*-------Bank Details---start*/}

                          <div className="mt-3 mb-2 card_invoicedetails_border">
                            <Grid className="grid_main_div">
                              <Grid item md={6}>
                                <div
                                  className="inputMarginright p-3"
                                  // style={{ marginTop: "27%" }}
                                >
                                  <div className="Heading_bold">
                                    Bank Transfer
                                  </div>
                                  <div className="">
                                    <div>
                                      <div className="d-flex">
                                        Beneficiary name :
                                        <p className="pl-1">
                                          {BankDetailsObj.beneficiary_name}
                                        </p>
                                      </div>
                                      <div className="d-flex">
                                        IFSC Code :
                                        <p className="pl-1">
                                          {BankDetailsObj.bank_code}
                                        </p>
                                      </div>
                                      <div className="d-flex">
                                        Account Number :
                                        <p className="pl-1">
                                          {" "}
                                          {BankDetailsObj.account_number}
                                        </p>
                                      </div>
                                      <div className="d-flex">
                                        Bank Name :
                                        <p className="pl-1">
                                          {" "}
                                          {BankDetailsObj.bank_name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item md={6}></Grid>
                            </Grid>
                            <div className="pl-3">
                              NOTE : While doing Online Bank Transfer, request
                              you to mention StudentName, ID or Invoice No in
                              the Remarks/Comments field
                            </div>
                          </div>
                        </div>

                        {/*-------Bank Details---start*/}
                        {/* --------payment detail section start--------- */}
                      </div>

                      {/* ----------  Bank details section end  -------- */}
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={openVerifedDialog}
        onClose={() => {
          setOpenVerifedDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpenVerifedDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Do you want to confirm verification of the payment for Invoice?{" "}
            {invoiceData?.name}{" "}
          </div>

          <div className="acceptation_btn">
            <Button
              variant="contained"
              className="add_new_query_btn button_login_decoration mr-2"
              onClick={() => paymentPaidSet("PAID")}
            >
              PAID
            </Button>
            <Button
              variant="contained"
              className="add_new_query_btn button_login_decoration"
              onClick={() => {
                paymentPaidSet("PARTIALLY PAID");
              }}
            >
              PARTIALLY PAID
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  STUDENT_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StudentInvoiceSummaryById));
