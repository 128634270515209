import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./HomeMenu.css";
import TotalCountData from "./TotalCountData";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { showNotificationMsz, blankValidator } from "../../../utils/Validation";
//-------------redux------------------
import { connect } from "react-redux";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import NoSectionDailog from "./NoSectionDailog.jsx";
import { useApolloClient } from "@apollo/client";
import { getAllSectionForInstitue } from "../../../services/index.js";

const ManageTimeTable = ({ ADMIN_DATA, navigate }) => {
  //----------------------object decons---------------------------------------
  const { institution_name, academic_session_id, institution_id, type } =
    ADMIN_DATA;
  console.log("ADMIN_DATA======", ADMIN_DATA);
  const client = useApolloClient();
  const [noSectionData, setNoSectionData] = useState(false);
  //----------------------local state---------------------------------------
  const [isPathValid, setisPathValid] = useState(false);
  //----------------------methods---------------------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    const academicHandler = () => {
      if (!academic_session_id) {
        setisPathValid(false);
      } else {
        setisPathValid(true);
      }
    };
    academicHandler();
  }, []);

  useEffect(() => {
    if (academic_session_id) {
      getAllSectionData();
    }
  }, []);

  const getAllSectionData = async () => {
    try {
      const { data } = await client.query({
        query: getAllSectionForInstitue,
        variables: {
          academic_session_id: academic_session_id,
        },
      });
      if (data) {
        if (!data.getAllSectionForInstitue[0]) {
          setNoSectionData(true);
        } else {
          setNoSectionData(false);
        }
      }
    } catch (error) {}
  };
  const closehandle = () => {
    setNoSectionData(false);
  };

  const navigationHandler = (path, data) => {
    if (!isPathValid) {
      showNotificationMsz(
        "Currently there is no active session, please manage Institution session first",
        "info"
      );
    } else if (isPathValid) {
      navigate(path, data);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="Main_Grid_For_MENUS">
            <Grid item md={12}>
              <Holiday name={institution_name} isRemove={true} />

              <div className="enquirydetails_background mb-2">
                <Grid className="Main_Grid_For_MENUS">
                  <Grid item md={6}>
                    <div className="enquiry_heading">
                      <div className="ml-3 mt-2 Heading_bold">Home</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <TotalCountData
                      inst_id={institution_id}
                      acdmin_id={academic_session_id}
                    />
                  </Grid>
                </Grid>

                <div className="card_margin_both_side">
                  {type === "PT" || type === "ET" ? (
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <Grid className="mangeinsitiute_main_grid" spacing={2}>
                          <Grid item md={2}>
                            <div>
                              <Card
                                className="home_menu_card_center"
                                onClick={() =>
                                  navigate("/adminmanageinstitution", {
                                    state: {
                                      temp: ADMIN_DATA.data,
                                    },
                                  })
                                }
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-university"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Manage Institute</p>
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                        {blankValidator(academic_session_id) && (
                          <div>
                            <Grid className="Main_Grid_For_MENUS mt-2">
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/admissionsMenu");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-users"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Onboarding</p>
                                </Card>
                              </Grid>

                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/adminClassNotice");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Class Notice</p>
                                </Card>
                              </Grid>

                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/notification");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i className="fa fa-envelope "></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Email</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageEvent", {
                                      state: {
                                        academic_session_id,
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-calendar"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Events</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageExamination", {
                                      state: {
                                        academic_session_id,
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Examinations</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/fees");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Student Fees</p>
                                </Card>
                              </Grid>
                            </Grid>
                            <Grid className="Main_Grid_For_MENUS mt-3">
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/report", {
                                      state: {
                                        name: "",
                                        SubName: "",
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Reports</p>
                                </Card>
                              </Grid>

                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/adminSchoolNotice");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>School Notice</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageLeaveRequests");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Student Leaves</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/viewStudyMaterial");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p> Study Material</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/ptMyInvoice");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>My invoices</p>
                                </Card>
                              </Grid>
                            </Grid>

                            {/*------------------ last row----------------- */}
                          </div>
                        )}
                      </div>
                    </Card>
                  ) : (
                    <Card>
                      <div className="card_margin_both_side mt-2 mb-2">
                        <Grid className="mangeinsitiute_main_grid" spacing={2}>
                          <Grid item md={2}>
                            <div>
                              <Card
                                className="home_menu_card_center"
                                onClick={() =>
                                  navigate("/adminmanageinstitution", {
                                    state: {
                                      temp: ADMIN_DATA.data,
                                    },
                                  })
                                }
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-university"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Manage Institute</p>
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                        {blankValidator(academic_session_id) && (
                          <div>
                            <Grid className="Main_Grid_For_MENUS mt-2">
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/admissionsMenu");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-users"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Onboarding</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                {/* <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  navigationHandler(
                                    "/classoverviewandpaymentattendence"
                                  );
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-file"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Class overview/Payment</p>
                              </Card> */}
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/routelistings");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-bus"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Transport</p>
                                </Card>
                              </Grid>

                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/adminClassNotice");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Class Notice</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/classpromotion", {
                                      state: {
                                        academic_session_id,
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-book"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Class Promotion</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/notification");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i className="fa fa-envelope "></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Email</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageEvent", {
                                      state: {
                                        academic_session_id,
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-calendar"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Events</p>
                                </Card>
                              </Grid>
                            </Grid>
                            <Grid className="Main_Grid_For_MENUS mt-3">
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageExamination", {
                                      state: {
                                        academic_session_id,
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Examinations</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/fees");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Fees</p>
                                </Card>
                              </Grid>

                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/report", {
                                      state: {
                                        name: "",
                                        SubName: "",
                                      },
                                    });
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Reports</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler(
                                      "/adminManageTeacherLeave"
                                    );
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-users"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Staff Leaves</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/adminSchoolNotice");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>School Notice</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageLeaveRequests");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p>Student Leaves</p>
                                </Card>
                              </Grid>
                            </Grid>

                            <Grid className="Main_Grid_For_MENUS mt-3">
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/viewStudyMaterial");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p> Study Material</p>
                                </Card>
                              </Grid>
                              <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/manageTimeTable");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p> Timetable</p>
                                </Card>
                              </Grid>
                              {/* <Grid item md={2}>
                                <Card
                                  className="home_menu_card_center"
                                  onClick={() => {
                                    navigationHandler("/ManageCredits");
                                  }}
                                >
                                  <div className="home_menu_size_of_icons">
                                    <i class="fa fa-file"></i>
                                  </div>
                                  <div className="home_menu_whiteLine"></div>
                                  <p> Manage credits</p>
                                </Card>
                              </Grid> */}
                              {/* <Grid item md={2}>
                              <Card
                                className="home_menu_card_center"
                                onClick={() => {
                                  navigationHandler("/routelistings");
                                }}
                              >
                                <div className="home_menu_size_of_icons">
                                  <i class="fa fa-bus"></i>
                                </div>
                                <div className="home_menu_whiteLine"></div>
                                <p>Transport</p>
                              </Card>
                            </Grid>
                            <Grid item md={6}></Grid> */}
                            </Grid>
                            {/*------------------ last row----------------- */}
                          </div>
                        )}
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <NoSectionDailog
        open={noSectionData}
        close={closehandle}
        navigate={navigate}
        institution_name={institution_name}
        academic_session_id={academic_session_id}
        institution_id={institution_id}
        type="Admin"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageTimeTable));
