import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Transport.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import GoogleMapComponent from "../../Common/GoogleMapComponent/GoogleMapComponent.jsx";

const ManageRoutes = (props) => {
  //------------------------------local state---------------------------
  const [editDialog, setEditDialog] = useState(false);
  const [stopNameEdit, setStopNameEdit] = useState("");
  const [stopId, setStopId] = useState("");
  //------------------------------local state---------------------------
  //------------------------------local state---------------------------
  const routeInfo = props.location.state.routeData;
  let Organization_Name = props.manageInstitution.institution_name;
  const [Delete_Tranport_Stop_Name, {}] = useMutation(
    Service.Delete_Tranport_Stop_Name
  );
  const [Update_Transport_Stop_API, {}] = useMutation(
    Service.Update_Transport_Stop_API
  );

  const [deleteStopName, setdeleteStopName] = useState("");
  const [deleteStopId, setdeleteStopId] = useState("");
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [delete_Transport_route, {}] = useMutation(
    Service.delete_Transport_route
  );
  const [RouteId, setRouteId] = useState("");
  // to get data api
  const client = useApolloClient();
  const classes = useStyles();
  const [createDialog, setcreateDialog] = useState(false);
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);
  const [stopName, setstopName] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [RouteData, setRouteData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [stopNameValue, setStopNameValue] = useState("");
  const [stopNumber, setStopNumber] = useState("");
  const [stopNumberEdit, setStopNumberEdit] = useState("");

  const [search, setsearch] = useState("");
  const [center, setCenter] = useState({
    lat: 28.9845,
    lng: 77.7064,
  });
  const [zoom, setZoom] = useState(11);
  const filterArr = RouteData.filter((event) => {
    return event.stop_name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const onDragMarker = (coord) => {
    if (coord) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      setCenter({ lat, lng });
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        setCenter({
          ...center,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Stop_Route_Name,
          variables: {
            transport_route_id: parseInt(routeInfo.id),
          },
        })
        .then((response) => {
          const { getTransportStop } = response.data;
          if (getTransportStop) {
            const sortedArr = getTransportStop.sort((a, b) => {
              return +a.sequence_number - +b.sequence_number;
            });
            setRouteData(sortedArr);
          }

          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  }, [isUpdated, routeInfo.id, client]);

  const deleteStopageName = () => {
    let id = parseInt(deleteStopId);
    try {
      Delete_Tranport_Stop_Name({
        variables: { id },
      })
        .then((response) => {
          setIsUpdated(true);

          setopenDeleteDialog(false);
        })
        .catch((error) => {
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const UpdateStopNameHandler = () => {
    try {
      setisloading(true);
      Update_Transport_Stop_API({
        variables: {
          id: parseInt(stopId),
          stop_name: stopNameEdit,
          stop_latitude: center.lat + "",
          stop_longitude: center.lng + "",
          sequence_number: parseInt(stopNumberEdit),
          transport_route_id: parseInt(routeInfo.id),
        },
      })
        .then((response) => {
          showNotificationMsz(
            response.data.updateTransportStop.message,
            "success"
          );
          setIsUpdated(true);
          setisloading(false);
          setEditDialog(false);
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
      setisloading(false);
    }
  };
  const CreateStopName = () => {
    if (!blankValidator(stopNameValue)) {
      alert("Please enter stop name.");
      return;
    }
    if (!blankValidator(stopNumber)) {
      alert("Please enter stop Number.");
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Create_Stop_Route,
          variables: {
            transport_route_id: parseInt(routeInfo.id),
            stop_name: stopNameValue,
            stop_latitude: center.lat + "",
            stop_longitude: center.lng + "",
            sequence_number: parseInt(stopNumber),
          },
        })
        .then((response) => {
          showNotificationMsz(
            response.data.addTransportStop.message,
            "success"
          );
          setStopNameValue("");
          setStopNumber("");
          setIsUpdated(true);

          setcreateDialog(false);
          setisloading(false);
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const DeleteTransportRoute = (ID) => {
    let id = parseInt(ID);
    try {
      delete_Transport_route({
        variables: { id },
      })
        .then((response) => {
          setIsUpdated(true);
          setisloading(false);

          setopendialogConfimation(false);
          alert(response.data.deleteTransportRoute.message);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={Organization_Name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 mt-3">
                  <div className="Heading_bold">Manage Route</div>
                </div>
                <div className=" mr-3">
                  <div>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration mr-2 mt-2"
                      onClick={() =>
                        props.navigate("/assignStudent", {
                          state: {
                            routeInfo: {
                              id: routeInfo.id,
                              routeName: routeInfo.route_name,
                              rowdata: routeInfo,
                            },
                          },
                        })
                      }
                    >
                      Assign Students
                    </Button>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration mr-2 mt-2"
                      onClick={() => {
                        // setrouteNameDelete(row.route_name);
                        setRouteId(routeInfo.id);
                        setopendialogConfimation(true);
                      }}
                    >
                      Delete Route
                    </Button>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration mt-2"
                      onClick={() => setcreateDialog(true)}
                    >
                      Add Stop
                    </Button>
                  </div>
                </div>
              </div>
              {/* --------------subtext aligne center with style----------------- */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontWeight: "600" }}>Route Name :</p>
                <p style={{ textTransform: "capitalize" }}>
                  {routeInfo.route_name}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontWeight: "600" }}>Route Type :</p>
                <p style={{ textTransform: "capitalize" }}>
                  {routeInfo.route_type}
                </p>
              </div>
              {/* --------------subtext aligne center with style----------------- */}

              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={5}>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by Name"
                              value={search}
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={2}>
                          <div className="btn_add mt-1 ml-2">
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                            >
                              Search
                            </Button>
                          </div>
                        </Grid>
                        <Grid item md={4}></Grid>
                      </Grid>

                      <div className="table_foramtitng mt-3">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="center"
                                  className="table_header create_route_listing_padding"
                                >
                                  Sequence Number
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  className="table_header create_route_listing_padding"
                                >
                                  Stop Name
                                </StyledTableCell>

                                <StyledTableCell
                                  align="center"
                                  className="table_header create_route_listing_padding"
                                >
                                  Actions
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody id="myTable">
                              {(rowsPerPage > 0
                                ? filterArr.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : filterArr
                              ).map((row, index) => (
                                <StyledTableRow id="data" key={index}>
                                  <StyledTableCell scope="row" align="center">
                                    {row.sequence_number}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="create_route_listing_padding"
                                  >
                                    {row.stop_name}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="center"
                                    className="routeopen_text_color create_route_listing_padding"
                                  >
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "#0070bb",
                                      }}
                                      onClick={() => {
                                        setStopNameEdit(row.stop_name);
                                        setStopNumberEdit(row.sequence_number);
                                        setStopId(row.id);
                                        setEditDialog(true);
                                        setCenter({
                                          lat: parseFloat(row?.stop_latitude),
                                          lng: parseFloat(row?.stop_longitude),
                                        });
                                      }}
                                    >
                                      Edit
                                    </span>{" "}
                                    |{" "}
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "#0070bb",
                                      }}
                                      onClick={() => {
                                        setdeleteStopName(row.stop_name);
                                        setdeleteStopId(row.id);
                                        setopenDeleteDialog(true);
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterArr.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={7}></Grid>
                        <Grid item md={5}>
                          <div className="button_main_flex mb-3 mt-4"></div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setopenDeleteDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopenDeleteDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure, want to Delete the Route : {deleteStopName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={deleteStopageName}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopenDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        open={createDialog}
        onClose={() => setcreateDialog(false)}
        className="dailog_formatting"
        maxWidth="lg"
        fullWidth="fullWidth"
      >
        <DialogTitle>
          <div>
            <Grid className="grid_main_div mt-2">
              <Grid item md={10}>
                <div className="manage_route_flex">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Stop Name"
                      value={stopNameValue}
                      onChange={(e) => {
                        setStopNameValue(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Stop Number"
                      value={stopNumber}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        setStopNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={center.lat}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={center.lng}
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration"
                      onClick={() => CreateStopName()}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className="float-right mr-3 cross_font_size">
                  <i
                    className="fa fa-times cursor"
                    onClick={() => setcreateDialog(false)}
                  ></i>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <GoogleMapComponent
              center={center}
              zoom={zoom}
              onDragMarker={onDragMarker}
              showRoute={false}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={editDialog}
        onClose={() => setEditDialog(false)}
        className="dailog_formatting"
        maxWidth="lg"
        fullWidth="fullWidth"
      >
        <DialogTitle>
          <div>
            <Grid className="grid_main_div mt-2">
              <Grid item md={10}>
                <div className="manage_route_flex">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Stop Name"
                      value={stopNameEdit}
                      onChange={(e) => {
                        setStopNameEdit(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Stop Number"
                      value={stopNumberEdit}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        setStopNumberEdit(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={center.lat}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={center.lng}
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration"
                      onClick={() => UpdateStopNameHandler()}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className="float-right mr-3 cross_font_size">
                  <i
                    className="fa fa-times cursor"
                    onClick={() => setEditDialog(false)}
                  ></i>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogTitle>
        <DialogContent>
          <GoogleMapComponent
            center={center}
            zoom={zoom}
            onDragMarker={onDragMarker}
            showRoute={false}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure, want to Delete the Route
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => DeleteTransportRoute(RouteId)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageRoutes));
