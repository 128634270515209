import React, { useState } from "react";
import "./Contactus.css";
import Grid from "@material-ui/core/Grid";
import Loder from "../../Loder/Loder.jsx";
import HOC1 from "../../Hoc1.jsx";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import GoogleMapReact from "google-map-react";

import * as Service from "../../../services/index";
import {
  blankValidator,
  emailValidator,
  getFullAddress,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/react-hooks";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const defaultProps = {
  center: { lat: 28.7041, lng: 77.1025 },
  zoom: 12,
};

const Contactus = (props) => {
  const { email_address, phone_number, address, office_time } = props.data.data;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [isloading, setisloading] = useState(false);

  // Add Contact us Data

  const [Contact_Us_Data, {}] = useMutation(Service.Contact_Us_Data);

  const Contactus = () => {
    if (!blankValidator(firstName)) {
      setFirstNameError(true);
      return;
    }
    if (!blankValidator(lastName)) {
      setLastNameError(true);
      return;
    }
    if (!blankValidator(email)) {
      setEmailError(true);
      return;
    }
    if (!emailValidator(email)) {
      alert("Email is Not Correct");
      return false;
    }
    if (!blankValidator(phone)) {
      setPhoneError(true);
      return;
    }
    if (!blankValidator(message)) {
      setMessageError(true);
      return;
    }

    try {
      setisloading(true);
      Contact_Us_Data({
        variables: {
          institution_id: 1,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          message: message,
        },
      }).then(
        (response) => {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setisloading(false);
          alert(response.data.contactUs.message);
        },
        (err) => console.log("then error", err)
      );
    } catch (error) {
      console.log("t and c error", error);
      setisloading(false);
    }
  };

  return (
    <div className="main_back_color">
      <div className="margin_both_side_main_conatat_page ">
        <Holiday name={props.data.institution_name} />
        <Grid className="grid_main_div">
          <Grid item md={9}>
            <div className="contact_div">
              <div className="div1_contact">
                <div className="card_MARGIN">
                  <Card>
                    <div className="conatctcardHeight">
                      <div className="schoolinfomargin">
                        <p className="heading_for_contactus">
                          School Contact Information
                        </p>
                        <div>
                          <div className="displayinline">
                            <span>Email Address</span>
                            <span className="iconscontent">
                              <i className="fa fa-envelope iconcolor"></i>{" "}
                              {email_address}
                            </span>
                          </div>

                          <div className="displayinline ">
                            <span>Phone Number</span>
                            <span className="iconscontent">
                              <i className="fa fa-mobile iconcolor"></i>{" "}
                              {phone_number}
                            </span>
                          </div>

                          <div className="displayinline">
                            <span>Working Hours</span>
                            <span className="iconscontent">
                              <i className="fa fa-navigate iconcolor"></i>{" "}
                              {office_time}
                            </span>
                          </div>

                          <div className="displayinline">
                            <span>Address</span>
                            <span className="iconscontent d-flex">
                              <i className="fa fa-map-marker iconcolor"></i>

                              <div className="pl-1">
                                <p>
                                  {address && address.line1 && address.line1}
                                </p>
                                <p>
                                  {address && address.line2 && address.line2}
                                </p>
                                <p>{address && address.city && address.city}</p>
                                <p>
                                  {address && address.state && address.state}
                                </p>

                                <p>
                                  {address &&
                                    address.country &&
                                    address.country}{" "}
                                  {address &&
                                    address.zip_code &&
                                    address.zip_code}
                                </p>
                              </div>
                              {/* {getFullAddress(address)} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              {/* <div className="div2_contact">
                <div className="card_MARGIN">
                  <div className="conatctcardHeight2">
                    <div style={{ height: "30vh", width: "100%" }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyC6teBAs0VTYf_3jk8HnmmJP99hc3fd8Rw",
                        }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                      ></GoogleMapReact>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Grid>
          <Grid item md={3}>
            {/* <div className="contact_div">
              <div className="div3_contact">
                {" "}
                <div className="card_MARGIN">
                  <Card>
                    <div className="conatctcardHeight1">
                      <p className="heading_for_contactus">Contact Us</p>
                      <div className=" inputfiledformatting margin_input_filed">
                        <p
                          for="exampleInputEmail1"
                          className="text_field_heading"
                        >
                          First Name
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          id="userid"
                          aria-describedby="emailHelp"
                          placeholder="Jhon"
                          autoComplete="off"
                          value={firstName}
                          onChange={(e) => {
                            setFirstNameError(false);
                            setFirstName(e.target.value);
                          }}
                        />
                        {firstNameError && (
                          <p className="error_message_color">
                            Enter the First Name
                          </p>
                        )}
                      </div>

                      <div className=" inputfiledformatting margin_input_filed">
                        <p
                          for="exampleInputEmail1"
                          className="text_field_heading"
                        >
                          Last Name
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          id="userid"
                          aria-describedby="emailHelp"
                          placeholder="Doe"
                          autoComplete="off"
                          value={lastName}
                          onChange={(e) => {
                            setLastNameError(false);
                            setLastName(e.target.value);
                          }}
                        />
                        {lastNameError && (
                          <span className="error_message_color">
                            Enter the Last Name
                          </span>
                        )}
                      </div>

                      <div className=" inputfiledformatting margin_input_filed">
                        <p
                          for="exampleInputEmail1"
                          className="text_field_heading"
                        >
                          Email Address
                        </p>
                        <input
                          type="text"
                          className="form-control text_input"
                          id="userid"
                          aria-describedby="emailHelp"
                          placeholder="jhon.joe@gmail.com"
                          autoComplete="off"
                          value={email}
                          onChange={(e) => {
                            setEmailError(false);
                            setEmail(e.target.value);
                          }}
                        />
                        {emailError && (
                          <span className="error_message_color">
                            Enter the Email Address
                          </span>
                        )}
                      </div>

                      <div className=" inputfiledformatting margin_input_filed">
                        <p
                          for="exampleInputEmail1"
                          className="text_field_heading"
                        >
                          Phone Number
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          id="userid"
                          aria-describedby="emailHelp"
                          placeholder="+91 626-072-8873"
                          autoComplete="off"
                          value={phone}
                          onChange={(e) => {
                            setPhoneError(false);
                            setPhone(e.target.value);
                          }}
                        />
                        {phoneError && (
                          <span className="error_message_color">
                            Enter the Phone Number
                          </span>
                        )}
                      </div>

                      <div className="inputfiledformatting margin_input_filed">
                        <label
                          for="exampleFormControlTextarea1"
                          className="text_field_heading"
                        >
                          Message
                        </label>
                        <textarea
                          className="form-control  textareaformatting"
                          id="exampleFormControlTextarea1"
                          placeholder="Lorem ipsum......"
                          rows="3"
                          type="text"
                          autoComplete="off"
                          value={message}
                          onChange={(e) => {
                            setMessageError(false);
                            setMessage(e.target.value);
                          }}
                        />
                        {messageError && (
                          <span className="error_message_color">
                            Enter the Message
                          </span>
                        )}
                      </div>

                      <div className="inputfiledformatting buttonpadding margin_button_filed">
                        <Button
                          variant="contained"
                          className="login_button button_login_decoration"
                          onClick={Contactus}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div> */}
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(Contactus)));
