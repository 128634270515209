import React, { useState, useEffect } from "react";
import "./Onboarding.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import {
  CapitalizeFirstFn,
  blankValidator,
  showNotificationMsz,
  getFullName,
} from "../../../utils/Validation";
//--------------------redux-----------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//----------------api calls----------------------
import * as Service from "../../../services/index";
import { useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";

const AdminStudentWithdrawal = (props) => {
  //------------------------------api calls---------------------
  const [WithDraw_Students_API, {}] = useMutation(
    Service.WithDraw_Students_API
  );
  //------------------------------local state---------------------
  const [isUpdated, setIsUpdated] = useState(false);
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteStudentName, setDeleteStudentName] = useState("");

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [managegrade, setmanagegrade] = useState([]);
  const [StudentsArr, setStudentsArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  // global academic session id
  let academic_session_id = parseInt(props.data.academic_session_id);

  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  let Organization_Name = props.data.institution_name;

  const client = useApolloClient();
  useEffect(() => {
    window.scrollTo(0, 0);

    const getStudentList = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_Student_form_list,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);

            if (response.data.getStudentList.length > 0) {
              setStudentsArr(response.data.getStudentList.reverse());
            }
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }

      try {
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setmanagegrade(response.data.getGrade);
          })
          .catch((e) => console.log("catch error", e));
      } catch (error) {
        console.log("t and c error", error);
      }
    };

    getStudentList();
  }, [client, institution_id, academic_session_id, isUpdated]);

  const [searchname, setsearchname] = useState("");

  const [searchid, setsearchid] = useState("");

  const filterByName = StudentsArr.filter((event) => {
    try {
      if (searchid == "" && searchname == "") {
        return true;
      } else {
        let id = "";
        if (blankValidator(event.login)) {
          id = event.login.display_id;
        }

        if (searchid == "") {
          if (event.student_profile.name == null) {
            return false;
          } else {
            getFullName(event.student_profile.name)
              .toLowerCase()
              .includes(searchname.toLowerCase());
          }
        } else {
          if (event.student_profile.name == null) {
            return false;
          } else {
            return (
              getFullName(event.student_profile.name)
                .toLowerCase()
                .includes(searchname.toLowerCase()) &&
              id.toLowerCase().includes(searchid.toLowerCase())
            );
          }
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  });

 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const withdrawDataHandler = (deleteId, studentName) => {
    setDeleteId(deleteId);
    setDeleteStudentName(studentName);
    setOpenConfirmationBox(true);
  };
  const withdrawStudentHandler = () => {
    try {
      setisloading(true);
      WithDraw_Students_API({
        variables: { id: parseInt(deleteId) },
      })
        .then((response) => {
          setOpenConfirmationBox(false);
          try {
            setisloading(true);
            client
              .query({
                query: Service.GET_Student_form_list,
                variables: {
                  institution_id,
                },
              })
              .then((response) => {
                setStudentsArr(response.data.getStudentList.reverse());
                setisloading(false);
              })
              .catch((err) => {
                setisloading(false);
                console.log("catch err", err);
              });
          } catch (error) {
            setisloading(false);
            console.log("t and c error", error);
          }
          setisloading(false);
          setIsUpdated(true);
          if (response.data.deleteDepartment.message.trim() === "FAIL") {
            showNotificationMsz("Data is already deleted", "info");
          }
          showNotificationMsz(
            response.data.deleteDepartment.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="enquiry_heading ">
                          <div className="mt-3 Heading_bold">
                            Search Student
                          </div>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search By Name"
                                autoComplete="off"
                                value={searchname}
                                onChange={(e) => {
                                  setsearchname(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright  mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search By Display ID"
                                autoComplete="off"
                                value={searchid}
                                onChange={(e) => {
                                  setsearchid(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright  mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Class/Section</option>
                                {managegrade.map((item, index) => (
                                  <option value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                        <div className="mt-4 mb-4 Heading_bold">
                          List Of Students
                        </div>
                        <div className="table_foramtitng">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Display ID
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Roll Number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? filterByName.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : filterByName
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.login)
                                        ? ""
                                        : !blankValidator(row.login.display_id)
                                        ? ""
                                        : row.login.display_id}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.roll_number) ? (
                                        ""
                                      ) : (
                                        <span>{row.roll_number}</span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {getFullName(row.student_profile.name)}
                                    
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.section) ? (
                                        ""
                                      ) : (
                                        <span>
                                          {!blankValidator(
                                            row.section.grade
                                          ) ? (
                                            ""
                                          ) : (
                                            <span>
                                              {!blankValidator(
                                                row.section.grade.gradeName
                                              ) ? (
                                                ""
                                              ) : (
                                                <span>
                                                  {row.section.grade.gradeName}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.section) ? (
                                        ""
                                      ) : (
                                        <span>
                                          {!blankValidator(row.section.name) ? (
                                            ""
                                          ) : (
                                            <span>{row.section.name}</span>
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {/* {row.status === "Withdrawn" && (
                                        <Button className="Withdrawn button_login_decoration">
                                          {row.status}
                                        </Button>
                                      )}
                                      {row.status === "Studying" && (
                                        <Button className="Studying button_login_decoration">
                                          {row.status}
                                        </Button>
                                      )} */}
                                      {!blankValidator(row.status) ? (
                                        "No Data"
                                      ) : (
                                        <span>{row.status}</span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                      onClick={() =>
                                        withdrawDataHandler(
                                          row.id,
                                          getFullName(row.student_profile.name)
                                        )
                                      }
                                    >
                                      Withdraw
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filterByName.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog open={openConfirmationBox} className="dailog_formatting">
        <div className="dailog_width_review_leave">
          <div className="cut_dailog_icon float-right">
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenConfirmationBox(false);
              }}
            ></i>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to withdraw : {deleteStudentName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={withdrawStudentHandler}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setOpenConfirmationBox(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(AdminStudentWithdrawal)));
