import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import {
  blankValidator,
  getcurrentDatewithoutTimezone,
  getHHMMSStoDate,
  get_Meeting_join_ling,
  showNotificationMsz,
} from "../../../utils/Validation";

const OnlineLinkLocation = (props) => {
  const [isZooming, setIsZooming] = useState(false);
  const [openMeetingDailog, setOpenMeetingDailog] = useState(false);
  const [displayData, setDisplayData] = useState("");
  const data = props.data;

  let currentDay = new Date().getDay();
  if (currentDay == 0) {
    currentDay = 7;
  }

  let { first_name, middle_name, last_name } = props.userInfo;

  let name = `${first_name + "_" + middle_name + "_" + last_name}`;
  return (
    <div>
      {props.fromTimetable === true ? (
        <div>
          {!blankValidator(data.location) ||
          !blankValidator(data.location?.mode) ||
          !blankValidator(props.day) ? (
            ""
          ) : (
            <div>
              {data.location.mode === "Online" && currentDay == props.day ? (
                <div>
                  {getHHMMSStoDate(data.start_time) <= new Date().setHours(23, 59, 59, 999) &&
                  getHHMMSStoDate(data.end_time) >= new Date().setHours(0, 0, 0, 0) ? (
                    <span
                      className="row_details_color"
                      onClick={() => {
                        if (!data.location.meeting_info) {
                          showNotificationMsz(
                            "No active Meeting available",
                            "info"
                          );
                          return;
                        }
                        let { response } = JSON.parse(
                          data.location.meeting_info
                        );
                        let mainurl = get_Meeting_join_ling(
                          response.meetingID[0],
                          name,
                          response.attendeePW[0],
                          props?.userType,
                          response.moderatorPW[0]
                        );
                        window.open(mainurl);
                        //  setDisplayData(mainurl);
                        //  setOpenMeetingDailog(true);
                      }}
                    >
                      {data.location?.name}
                    </span>
                  ) : (
                    <span>{data.location?.name}</span>
                  )}
                </div>
              ) : (
                <span>{data.location?.name}</span>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {!blankValidator(data.location) ||
          !blankValidator(data.location?.mode) ? (
            ""
          ) : (
            <div>
              {data.location.mode === "Online" ? (
                <div>
                  {new Date(
                    data.start_date == undefined
                      ? data.start_calendar?.datetime
                      : +data.start_date +
                        new Date().getTimezoneOffset() * 60000
                  ).getTime() <= new Date().setHours(23, 59, 59, 999) &&
                  new Date(
                    data.end_date == undefined
                      ? data.end_calendar?.datetime
                      : +data.end_date + new Date().getTimezoneOffset() * 60000
                  ).getTime() >= new Date().setHours(0, 0, 0, 0) ? (
                    <span
                      className="row_details_color"
                      onClick={() => {
                        if (!data.location.meeting_info) {
                          showNotificationMsz(
                            "No active Meeting available",
                            "info"
                          );
                          return;
                        }
                        let temp = JSON.parse(data.location.meeting_info);
                        let mainurl = get_Meeting_join_ling(
                          temp.response.meetingID[0],
                          name,
                          temp.response.attendeePW[0],
                          props.userType,
                          temp.response.moderatorPW[0]
                        );
                        window.open(mainurl);
                        // setDisplayData(mainurl);
                        // setOpenMeetingDailog(true);
                      }}
                    >
                      {data.location?.name}
                    </span>
                  ) : (
                    <span>{data.location?.name}</span>
                  )}
                </div>
              ) : (
                <span>{data.location?.name}</span>
              )}
            </div>
          )}
        </div>
      )}

      <div>
        <Dialog
          open={openMeetingDailog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth="fullWidth"
          fullScreen={isZooming ? true : false}
          onClose={() => {
            setOpenMeetingDailog(false);
          }}
        >
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-expand mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setIsZooming(!isZooming);
                }}
              ></i>
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpenMeetingDailog(false);
                }}
              ></i>
            </div>
          </div>
          <DialogContent>
            <iframe
              allow="camera;microphone;display-capture"
              allowfullscreen={true}
              src={displayData}
              title="description"
              className="iframe_width"
            ></iframe>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(OnlineLinkLocation);
