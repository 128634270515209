import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import "react-phone-input-2/lib/style.css";

//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { showNotificationMsz } from "../../../utils/Validation.js";

const UpdateRollNo = ({
  manageInstitution,
  navigate,
  classId,
  secttionId,
  acadSessId,
}) => {
  //-------------------------------global id-------------------------
  let institution_id = parseInt(manageInstitution.institution_id);
  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  //-------------------------------api calls-------------------------
  const client = useApolloClient();
  const [Assign_RollNoTo_Students, {}] = useMutation(
    Service.Assign_RollNoTo_Students
  );
  //-------------------------------local state-------------------------

  const [studentArr, setStudentArr] = useState([]);
  const [academicSessionId, setAcademicSessionId] = useState(-1);
  const [academicSessionArr, setAcademicSessionArr] = useState([]);
  const [sectionId, setSectionId] = useState(-1);

  const [ClassId, setClassId] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  //error
  const [academicsessionError, setacademicsessionError] = useState(false);
  const [classIdError, setclassIdError] = useState(false);
  const [sectionidError, setsectionidError] = useState(false);

  //-------------------------------methods-------------------------
  // useEffect(() => {
  //   sectionHandler(sectionId);
  // }, [sectionId]);
  useEffect(() => {
    if (secttionId) {
      setSectionId(secttionId);
      sectionHandler(secttionId);
    }
    if (classId) {
      setClassId(classId);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    try {
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          let sessionArr = response.data.getAllAcademicSession.slice();
          if (sessionArr) {
            let sortedArr = sessionArr.sort((a, b) => {
              return parseInt(a.roll_number) - parseInt(b.roll_number);
            });

            setAcademicSessionArr(sortedArr);
            if (acadSessId) {
              setAcademicSessionId(acadSessId);
              academicSessionHandler(acadSessId);
            }
            getClassData(acadSessId);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }

    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, client]);
  const getClassData = (acaId) => {
    const temp = [];
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: +acaId,
          },
        })
        .then((response) => {
          setClassArr(temp);
          setSectionArr(temp);
          setStudentArr(temp);
          setClassArr(response.data.getGrade);
          sectionGetData(classId);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  const academicSessionHandler = (acaId) => {
    setIsLoading(true);
    const temp = [];
    if (parseInt(acaId) === -1) {
      setSectionArr(temp);
      setClassArr(temp);
      setStudentArr(temp);
      setIsLoading(false);
      setAcademicSessionId(acaId);
      return;
    }
    setAcademicSessionId(acaId);
    getClassData(acaId);
  };

  const classHandler = (classId) => {
    if (classId === -1) {
      setStudentArr([]);
      setSectionArr([]);
      return;
    }
    if (classId) setClassId(classId);
    sectionGetData(classId);
  };
  const sectionGetData = (classId) => {
    try {
      setIsLoading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          setSectionArr([]);
          setStudentArr([]);
          setSectionArr(response.data.getSectionAndGrade);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const sectionHandler = (sectionId) => {
    if (parseInt(sectionId) === -1) {
      return;
    }

    setIsLoading(true);
    client
      .query({
        query: Service.GET_StudentList_by_SectionID,
        variables: {
          section_id: parseInt(sectionId),
        },
      })
      .then((response) => {
        setStudentArr(response.data.getStudentListBySectionId);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("catch error", e);
      });
  };

  const rollNoInputHandler = (value, index) => {
    studentArr[index].roll_number = value;
    let temp = [];
    studentArr.map((item) => {
      temp.push(item);
    });
    setStudentArr(temp);
  };
  const publishRollNoHandler = () => {
    const rollNoArr = [];
    let temp = false;
    let studentName = "";
    let tempIndex = -1;
    studentArr.map((item, index) => {
      rollNoArr.push({
        id: parseInt(item.id),
        roll_number: parseInt(item.roll_number),
      });
    });

    if (temp) {
      alert(
        "duplicate record found for " +
          studentName +
          " at the row no  " +
          (tempIndex + 1)
      );
      return;
    }
    try {
      setIsLoading(true);
      Assign_RollNoTo_Students({
        variables: { roll_numbers: rollNoArr },
      })
        .then((response) => {
          setIsLoading(false);

          showNotificationMsz(
            response.data.assignRollNumber.message,
            "success"
          );
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };
  const myData = studentArr.sort(function (a, b) {
    try {
  
    if (
      a.student_profile?.name?.first_name.toLowerCase() <
      b.student_profile?.name?.first_name.toLowerCase()
    )
      return -1;
    if (
      a.student_profile?.name?.first_name.toLowerCase() >
      b.student_profile?.name?.first_name.toLowerCase()
    )
      return 1;
    return 0;
        
  } catch (error) {
      console.log(error)
  }
  });
  return (
    <div>
      <Grid className="grid_main_div  p-3">
        <Grid item md={4} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Academic Session</h6>
          <div className=" mt-1">
            <select
              class="form-control"
              value={academicSessionId}
              onChange={(e) => {
                setacademicsessionError(false);
                academicSessionHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Academic Session</option>
              {academicSessionArr.length !== 0 &&
                academicSessionArr.map((item) => (
                  <option value={item.id}>{item.session_name}</option>
                ))}
            </select>
            {academicsessionError && (
              <span className="text-danger">Select the Academic Session</span>
            )}
          </div>
        </Grid>
        <Grid item md={4} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Class</h6>
          <div className=" mt-1">
            <select
              class="form-control"
              value={ClassId}
              onChange={(e) => {
                setclassIdError(false);
                classHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Class</option>
              {classArr.length !== 0 &&
                classArr.map((item) => (
                  <option value={item.id}>{item.gradeName}</option>
                ))}
            </select>
            {classIdError && (
              <span className="text-danger">Select the Class</span>
            )}
          </div>
        </Grid>
        <Grid item md={4} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Section</h6>
          <select
            class="form-control"
            value={sectionId}
            onChange={(e) => {
              setsectionidError(false);
              setSectionId(e.target.value);
              sectionHandler(e.target.value);
            }}
          >
            <option value={-1}>Select Section</option>
            {sectionArr.length !== 0 &&
              sectionArr.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
          </select>
          {sectionidError && (
            <span className="text-danger">Select the Section</span>
          )}
        </Grid>
      </Grid>
      <div
        className={
          studentArr.length === 0
            ? "card_margin_both_side"
            : "card_margin_both_side card__height"
        }
      >
        <Grid className="grid_main_div d-flex">
          <Grid item md={6} style={{ padding: 3 }}>
            <h6 className="text_filed_heading">Student Name</h6>
          </Grid>
          <Grid item md={6} style={{ padding: 3 }}>
            <h6 className="text_filed_heading">Roll No</h6>
          </Grid>
        </Grid>
        <div>
          {console.log("myData", myData)}
          {myData.length !== 0 ? (
            myData.map((item, index) => (
              <div key={index} className="update_rollno_border">
                <Grid className="grid_main_div d-flex p-3">
                  <Grid item md={6} style={{ padding: 3 }}>
                    {item.student_profile?.name?.first_name}{" "}
                    {item.student_profile?.name?.middle_name}{" "}
                    {item.student_profile?.name?.last_name}
                  </Grid>
                  <Grid item md={6} style={{ padding: 3 }}>
                    <input
                      value={item.roll_number}
                      type="text"
                      placeholder="Roll No"
                      className="form-control"
                      onChange={(e) =>
                        rollNoInputHandler(e.target.value, index)
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            ))
          ) : (
            <Card>No Data</Card>
          )}
        </div>
      </div>

      <Grid className="grid_main_div d-flex p-3">
        <Grid item md={10}></Grid>
        <Grid item md={2}>
          <Button
            variant="contained"
            className="button_enquiry_details button_login_decoration button_width"
            onClick={publishRollNoHandler}
          >
            Publish
          </Button>
        </Grid>
      </Grid>

      <Loder loading={isLoading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(UpdateRollNo);
