import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import AssignRollNo from "./AssignRollNo.jsx";
import UpdateRollNo from "./UpdateRollNo.jsx";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const ManageRollNo = ({ ADMIN_DATA, navigate, location }) => {
  let classId;
  let secttionId;
  if (location.state) {
    classId = location.state?.row?.section?.grade?.id;
    secttionId = location.state?.row?.section?.id;
  }

  //--------------------------global id----------------------
  const { institution_name, academic_session_id } = ADMIN_DATA;
  //--------------------------local state----------------------
  const [value, setValue] = React.useState(0);
  //--------------------------methods----------------------

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="ml-3 mt-3 Heading_bold">Manage Roll Number</div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height ">
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2 mybarwidth">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                            fullWidth={true}
                          >
                            <Tab
                              label="Update Roll Number"
                              {...a11yProps(0)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                            <Tab
                              label="Assign Roll Number"
                              {...a11yProps(1)}
                              className="button_login_decoration text_tranformation_exam"
                            />
                          </Tabs>
                        </div>
                        <div className="Pannel_padding_hidden">
                          <TabPanel value={value} index={0}>
                            <div className="mt-1 ml-1 ">
                              <UpdateRollNo
                                classId={classId}
                                secttionId={secttionId}
                                acadSessId={academic_session_id}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <div className="mt-2 ml-1">
                              <AssignRollNo
                                classId={classId}
                                secttionId={secttionId}
                              />
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state, ownProps) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageRollNo));
