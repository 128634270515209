import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  blankValidator,
  dateFormatToShow,
  showNotificationMsz,
} from "../../../utils/Validation";
import moment from "moment";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Loder from "../../Loder/Loder.jsx";
import Monday from "../Report/calendar/Monday.jsx";
import AllMonday from "./AllMonday.jsx";

const TimeTable = ({ manageInstitution, navigate, location }) => {
  const { institution_id, academic_session_id, institution_name } =
    manageInstitution;

  const { row, sectionId } = location.state;

  const client = useApolloClient();
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  const [isTimeTableShown, setIsTimeTableShown] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [allTimeTableData, setAllTimeTableData] = useState([]);

  useEffect(() => {
    AllTimeTableGet();
  }, []);

  const AllTimeTableGet = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.getClasstimeTableforSelectPeriod,
          variables: {
            class_timetable_id: row.id,
          },
        })
        .then((response) => {
          setAllTimeTableData(response.data.getClasstimeTableforSelectPeriod);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {}
  };
  const [managegrade, setmanagegrade] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  let day = new Date().getDay() - 1;
  if (day < 0) {
    day = 6;
  }
  const [value, setValue] = React.useState(day);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log("allTimeTableData", allTimeTableData);
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 Heading_bold mt-2">Time Table</div>
                  <div className="mr-3 mt-3">
                    {`${dateFormatToShow(startOfWeek)}-${dateFormatToShow(
                      endOfWeek
                    )}`}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="mt-2 pt-3 pb-3">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-3 mb-2">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="Monday"
                              {...a11yProps(0)}
                              className=" text-capitalize  "
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Tuesday"
                              {...a11yProps(1)}
                              className=" text-capitalize "
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Wednesday"
                              {...a11yProps(2)}
                              className=" text-capitalize "
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Thursday"
                              {...a11yProps(3)}
                              className=" text-capitalize "
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Friday"
                              {...a11yProps(4)}
                              className=" text-capitalize "
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Saturday"
                              {...a11yProps(5)}
                              className=" text-capitalize "
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Sunday"
                              {...a11yProps(6)}
                              className=" text-capitalize "
                              style={{ minWidth: 130 }}
                            />
                          </Tabs>
                        </div>

                        <TabPanel value={value} index={0}>
                          <AllMonday
                            timetables={allTimeTableData}
                            day={1}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                          <AllMonday
                            timetables={allTimeTableData}
                            day={2}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                          <AllMonday
                            timetables={allTimeTableData}
                            day={3}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                          <AllMonday
                            timetables={allTimeTableData}
                            day={4}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={4} classes="">
                          <AllMonday
                            timetables={allTimeTableData}
                            day={5}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={5}>
                          <AllMonday
                            timetables={allTimeTableData}
                            day={6}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={6} classes="">
                          <AllMonday
                            timetables={allTimeTableData}
                            day={7}
                            institution_id={institution_id}
                            AllTimeTableGet={AllTimeTableGet}
                            sectionId={sectionId}
                          />
                        </TabPanel>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(TimeTable));
