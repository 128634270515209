import React, { useState, useEffect } from "react";
import "./Study.css";
import {
  AllGetStudyMaterial,
  Update_Topic,
  add_Topic,
  deleteTopic,
} from "../../../services/index";
import HOC1 from "../../Hoc1.jsx";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import Grid from "@material-ui/core/Grid";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { useApolloClient, useMutation } from "@apollo/client";
import Card from "@material-ui/core/Card";
import ViewStudyLeftPenal from "./ViewStudyLeftPenal";
import ViewStudyRightPenal from "./ViewStudyRightPenal";
import Loder from "../../Loder/Loder";
import { showNotificationMsz } from "../../../utils/Validation";

const ViewStudyMaterial = (props) => {
  // --------------global variables----------------------------
  const { institution_name, institution_id } = props.User_Info;
  // --------------api calls----------------------------
  const client = useApolloClient();
  const [updateChapterAPI, {}] = useMutation(Update_Topic);
  const [addChapterAPI, {}] = useMutation(add_Topic);
  const [deleteChapterAPI, {}] = useMutation(deleteTopic);
  // --------------api calls----------------------------
  // --------------local states----------------------------
  const [SMName, setSMName] = useState("");
  const [showSMDialog, setShowSMDialog] = useState(false);
  const [showChapterDialog, setShowChapterDialog] = useState(false);
  const [showAddChapterDlg, setShowAddChapterDlg] = useState(false);
  const [showDeleteChapterDlg, setShowDeleteChapterDlg] = useState(false);
  const [UChapterId, setUChapterId] = useState(null);
  const [UChapterName, setUChapterName] = useState("");
  const [DChapterId, setDChapterId] = useState(null);
  const [DChapterName, setDChapterName] = useState("");
  const [studyMatId, setStudyMatId] = useState(null);
  const [AChapterName, setAChapterName] = useState("");

  // --------------local states----------------------------

  const [getDataStudyList, setGetDataStudyList] = useState({});
  const [updateApi, setUpdateApi] = useState(true);
  const [topicID, setTopicID] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const updateSubTopicData = (topic_id) => {
    setTopicID(topic_id);
  };

  useEffect(() => {
    getListArr();
  }, [updateApi, isUpdated]);

  const getListArr = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: AllGetStudyMaterial,
        variables: {
          institution_id: +institution_id,
        },
      });
      if (data && data.getStudyMaterial) {
        const { getStudyMaterial } = data;
        let sortArr = getStudyMaterial.sort((a, b) =>
          a.class_name.localeCompare(b.class_name)
        );
        let object = {};
        for (let index = 0; index < sortArr.length; index++) {
          const element = sortArr[index];

          if (object[element.class_name]) {
            object[element.class_name]?.push(element);
          } else {
            let array = [];
            array.push(element);
            object[element.class_name] = array;
          }
        }
        setGetDataStudyList(object);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const chapterDialogHandler = (args) => {
    setShowChapterDialog(args);
  };

  const addChapterDlgHandler = (args) => {
    setShowAddChapterDlg(args);
  };
  const deleteChapterDlgHandler = (args) => {
    setShowDeleteChapterDlg(args);
  };
  //update chapter method
  const updateChapterHandler = async () => {
    try {
      if (UChapterName && UChapterId) {
        setIsLoading(true);
        const { data } = await updateChapterAPI({
          variables: {
            name: UChapterName,
            id: +UChapterId,
          },
        });
        if (data && data.updateTopic) {
          const { message } = data.updateTopic;
          showNotificationMsz(message, "success");
          setIsUpdated(!isUpdated);
          setShowChapterDialog(false);
          setUChapterName("");
          setUChapterId(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  //delete chapter method
  const deleteChapterHandler = async () => {
    try {
      if (DChapterId) {
        setIsLoading(true);
        const { data } = await deleteChapterAPI({
          variables: { id: +DChapterId },
        });
        if (data && data.deleteTopic) {
          const { message } = data.deleteTopic;
          showNotificationMsz(message, "success");
          setShowDeleteChapterDlg(false);
          setDChapterId(null);
          setIsUpdated(!isUpdated);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  //add chapter method
  const addChapterHandler = async () => {
    try {
      if (AChapterName && studyMatId) {
        setIsLoading(true);
        const { data } = await addChapterAPI({
          variables: {
            name: AChapterName,
            study_material_id: +studyMatId,
          },
        });
        if (data && data.addTopic) {
          showNotificationMsz(data.addTopic.message, "success");
          setIsUpdated(!isUpdated);
          setShowAddChapterDlg(false);

          setStudyMatId(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const showAddSMDlgHandler = (args) => {
    setShowSMDialog(args);
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={institution_name && institution_name} />
            <div>
              <Card>
                <div className="m-3">
                  <h3>Manage Study Materials</h3>
                </div>
                <Grid className="grid_main_div">
                  <Grid item md={4}>
                    <ViewStudyLeftPenal
                      getDataStudyList={getDataStudyList}
                      setUpdateApi={setUpdateApi}
                      updateApi={updateApi}
                      type="parent"
                      updateSubTopicData={updateSubTopicData}
                      showChapterDialog={showChapterDialog}
                      updateChapterHandler={updateChapterHandler}
                      chapterDialogHandler={chapterDialogHandler}
                      setUChapterName={setUChapterName}
                      setUChapterId={setUChapterId}
                      UChapterName={UChapterName}
                      AChapterName={AChapterName}
                      setAChapterName={setAChapterName}
                      addChapterHandler={addChapterHandler}
                      showAddChapterDlg={showAddChapterDlg}
                      addChapterDlgHandler={addChapterDlgHandler}
                      setStudyMatId={setStudyMatId}
                      setDChapterId={setDChapterId}
                      DChapterName={DChapterName}
                      setDChapterName={setDChapterName}
                      showDeleteChapterDlg={showDeleteChapterDlg}
                      deleteChapterDlgHandler={deleteChapterDlgHandler}
                      deleteChapterHandler={deleteChapterHandler}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <ViewStudyRightPenal
                      {...props}
                      topicID={topicID}
                      setUpdateApi={setUpdateApi}
                      updateApi={updateApi}
                      showSMDialog={showSMDialog}
                      showAddSMDlgHandler={showAddSMDlgHandler}
                      setSMName={setSMName}
                      SMName={SMName}
                      getListArr={getListArr}
                    />
                  </Grid>
                </Grid>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  User_Info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ViewStudyMaterial));
