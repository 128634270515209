import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TimeTableTab from "./TimeTableTab.jsx";

const StudentTimeTable = (props) => {
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="textfiled_margin mb-2">
        <div className="mt-2">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            left
          >
            <Tab
              label="Monday"
              {...a11yProps(0)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
            <Tab
              label="Tuesday"
              {...a11yProps(1)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
            <Tab
              label="Wednesday"
              {...a11yProps(2)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
            <Tab
              label="Thursday"
              {...a11yProps(3)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
            <Tab
              label="Friday"
              {...a11yProps(4)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
            <Tab
              label="Saturday"
              {...a11yProps(5)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
            <Tab
              label="Sunday"
              {...a11yProps(6)}
              className="button_login_decoration text_tranformation_exam"
              style={{ minWidth: 130 }}
            />
          </Tabs>
        </div>

        <TabPanel value={value} index={0}>
          <TimeTableTab name="Monday" id={props.id} day={1} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div>
            <TimeTableTab name="Tuesday" id={props.id} day={2} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div>
            <TimeTableTab name="Wednesday" id={props.id} day={3} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <div>
            <TimeTableTab name="Thursday" id={props.id} day={4} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <div>
            <TimeTableTab name="Friday" id={props.id} day={5} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <div>
            <TimeTableTab name="Saturday" id={props.id} day={6} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div>
            <TimeTableTab name="Sunday" id={props.id} day={7} />
          </div>
        </TabPanel>
      </div>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default StudentTimeTable;
