import React, { useState } from "react";
// import "./Leave.css";
import HOC1 from "../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Button from "@material-ui/core/Button";

import Loder from "../Loder/Loder.jsx";
import Holiday from "../Common/Holiday/Holiday.jsx";
import { store } from "react-notifications-component";

//redux
import * as Service from "../../services/index";
import { blankValidator } from "../../utils/Validation";
import { useApolloClient } from "@apollo/client";

import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";

const StudentResetpassword = (props) => {
  // to create data api

  // ---------------------creat data variables--------------

  const client = useApolloClient();
  // user id used as father id
  let login_id = props.data.login_id;

  //Institute name
  let institution_Name = props.data.institution_name;

  // -------------input valudes data-----------------

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassworderror, setOldPassworderror] = useState(false);
  const [newPassworderror, setNewPassworderror] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPassworderrror, setConfirmPassworderror] = useState(false);
  const [equalitycheckerror, setequalitycheckerror] = useState(false);
  const [isloading, setisloading] = useState(false);

  // ---------------------creat data variables--------------

  const changePasswordHandler = () => {
    if (!blankValidator(oldPassword)) {
      setOldPassworderror(true);
      return;
    }
    if (!blankValidator(newPassword)) {
      setNewPassworderror(true);
      return;
    }
    if (!blankValidator(confirmPassword)) {
      setConfirmPassworderror(true);
      return;
    }
    if (confirmPassword !== newPassword) {
      setequalitycheckerror(true);
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.Reset_Password_By_User,
          variables: {
            login_id: login_id,
            old_password: oldPassword,
            new_password: newPassword,
          },
        })
        .then(
          (response) => {
            setisloading(false);
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            if (response.data.resetPasswordByUser.errorCode === 0) {
              store.addNotification({
                title: "",
                message: `${response.data.resetPasswordByUser.message}`,
                type: "success",
                insert: "top-center",
                container: "top-center",
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
             props.navigate("/");
            } else {
              store.addNotification({
                title: "",
                message: `${response.data.resetPasswordByUser.message}`,
                type: "danger",
                insert: "top-center",
                container: "top-center",
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
             props.navigate("/studentresetpassword");
            }
          },
          (err) => {
            setisloading(false);

            console.log("then error", err);
          }
        );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Reset Password</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div"></Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={12}>
                            <div>
                              <label for="exampleInputEmail1">
                                Old Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={oldPassword}
                                onChange={(e) => {
                                  setOldPassworderror(false);
                                  setOldPassword(e.target.value);
                                }}
                              />
                              {oldPassworderror && (
                                <span className="text-danger">
                                  Enter the Old Password
                                </span>
                              )}
                            </div>

                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                New Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={newPassword}
                                onChange={(e) => {
                                  setNewPassworderror(false);
                                  setNewPassword(e.target.value);
                                }}
                              />
                              {newPassworderror && (
                                <span className="text-danger">
                                  Enter the New Password
                                </span>
                              )}
                            </div>

                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={confirmPassword}
                                onChange={(e) => {
                                  setConfirmPassworderror(false);
                                  setequalitycheckerror(false);
                                  setConfirmPassword(e.target.value);
                                }}
                              />
                              {confirmPassworderrror && (
                                <span className="text-danger">
                                  Enter the Confirm Password
                                </span>
                              )}
                              {equalitycheckerror && (
                                <span className="text-danger">
                                  Enter the Correct Password
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={changePasswordHandler}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(StudentResetpassword)));
