import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  CapitalizeFirstFn,
  getFullName,
  newDateToDate,
  timeStampToDateandTime,
  timeStampToDatedash,
} from "../../../utils/Validation.js";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../Holiday/Holiday.jsx";
import "./Events.css";
import PublishMarkAttendence from "./PublishMarkAttendence.jsx";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function MarkEventAttendance(props) {
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isUpdated, setIsUpdated] = useState(false);

  // const [StartdateError, setStartdateError] = useState(false);
  const [InviteesArr, setInviteesArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  let data = props.location.state.data;
  const [startdate, setstartdate] = useState(
    timeStampToDatedash(data.start_date)
  );

  // let tempdate = new Date();

  useEffect(() => {
    getinviteesList(props.location.state.data.id, startdate);
  }, [props, startdate]);

  const getinviteesList = (id, date) => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Event_Participants,
          variables: {
            event_id: parseInt(id),
            date: date,
          },
        })
        .then((response) => {
          setisloading(false);
          try {
            let temp = [];
            for (
              let index = 0;
              index < response.data.getEventParticipants.length;
              index++
            ) {
              let element = response.data.getEventParticipants[index];

              if (
                element.user_type === "STUDENT" &&
                element.student.attendances !== null &&
                element.student.attendances !== undefined
              ) {
                element["isSelected"] =
                  element.student.attendances.length > 0
                    ? element.student.attendances[0].status === "Present"
                    : false;
                element["display_id"] =
                  element.student?.student_profile?.login?.display_id;
                element["name"] = getFullName(
                  element.student?.student_profile?.name
                );

                element["photo_path"] =
                  element.student?.student_profile?.photo?.path;
                element["gradeName"] =
                  element.student?.section?.grade?.gradeName;
                element["section"] = element.student?.section?.name;
                element["roll_number"] = element.student?.roll_number;
              }
              if (
                element.user_type === "EMPLOYEE" &&
                element.employee.attendances !== null &&
                element.employee.attendances !== undefined
              ) {
                element["isSelected"] =
                  element.employee.attendances.length > 0
                    ? element.employee.attendances[0].status === "Present"
                    : false;
                element["display_id"] = element.employee?.login?.display_id;
                element["name"] = getFullName(element.employee?.name);
                element["photo_path"] = element.employee?.photo?.path;
              }
              if (
                element.user_type === "ADMIN" &&
                element.admin.attendances !== null &&
                element.admin.attendances !== undefined
              ) {
                element["isSelected"] =
                  element.admin.attendances.length > 0
                    ? element.admin.attendances[0].status === "Present"
                    : false;
                element["display_id"] = element.admin?.login?.display_id;
                element["name"] = getFullName(element.employee?.name);
                element["photo_path"] = element.admin?.photo?.path;
              }
              if (
                element.user_type === "PARENT" &&
                element.parent.attendances !== null &&
                element.parent.attendances !== undefined
              ) {
                element["isSelected"] =
                  element.parent.attendances.length > 0
                    ? element.parent.attendances[0].status === "Present"
                    : false;
                element["display_id"] = element.parent?.login?.display_id;
                element["name"] = getFullName(element.parent?.name);
                element["photo_path"] = element.parent?.photo?.path;
              }
              if (
                element?.employee?.attendances !== null &&
                element?.employee?.attendances !== undefined
              ) {
                element["isSelected"] =
                  element.employee.attendances.length > 0
                    ? element.employee.attendances[0].status === "Present"
                    : false;
                element["display_id"] = element.employee?.login?.display_id;
                element["name"] = getFullName(element.employee?.name);
                element["photo_path"] = element.employee?.photo?.path;
              }
              temp.push(element);
            }
            setInviteesArr(temp);
          } catch (error) {
            console.log("error", error);
          }
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const allCheckHandler = () => {
    setAllChecked(!allChecked);
    let temp = [];
    InviteesArr.map((item) => {
      item.isSelected = !allChecked;
      temp.push(item);
    });
    setInviteesArr(temp);
  };

  const checkBoxHandler = (id) => {
    let temp = [];
    InviteesArr.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      if (item.isSelected === false) {
        setAllChecked(false);
      }
      temp.push(item);
    });

    setInviteesArr(temp);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />

              <div className="enquirydetails_background">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2">
                    <div className="Heading_bold">Mark Attendence</div>
                    <div>
                      {blankValidator(data.event_name) &&
                        CapitalizeFirstFn(data.event_name)}
                    </div>
                    <div className="d-flex mt-3">
                      <div className="text_filed_heading">Start Time : </div>

                      {data.start_date &&
                        timeStampToDateandTime(data.start_date)}
                      <div className="ml-5 text_filed_heading">End Time : </div>
                      {data.end_date && timeStampToDateandTime(data.end_date)}
                    </div>
                  </div>

                  <div className="inputMarginright mt-3">
                    <input
                      type="date"
                      className="form-control"
                      id="exampleFormControlInput1"
                      disabled={true}
                      autoComplete="off"
                      value={newDateToDate(new Date())}
                    />
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-2">
                          <TableContainer
                            component={Paper}
                            className="table_box_shadow"
                          >
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_cell_padding pl-2"
                                  >
                                    ID
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_cell_padding pl-2"
                                  >
                                    Roll No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_cell_padding pl-2"
                                  >
                                    Class
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_cell_padding pl-2"
                                  >
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header table_cell_padding"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell className="table_header table_cell_padding">
                                    <Checkbox
                                      checked={allChecked}
                                      onChange={allCheckHandler}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? InviteesArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : InviteesArr
                                ).map((row, index) => (
                                  <StyledTableRow key={row.index}>
                                    <StyledTableCell className="table_cell_padding pl-2">
                                      <div>
                                        {blankValidator(row.display_id) &&
                                          row.display_id}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell className="table_cell_padding pl-2">
                                      <div>
                                        {blankValidator(row.roll_number) &&
                                          row.roll_number}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell className="table_cell_padding pl-2">
                                      <div>
                                        {blankValidator(row.gradeName) &&
                                          row.gradeName}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell className="table_cell_padding pl-2">
                                      <div>
                                        {blankValidator(row.section) &&
                                          row.section}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_cell_padding"
                                    >
                                      <div>
                                        <div>
                                          {" "}
                                          <img
                                            src={`${Service.baseUrl}/${row.photo_path}`}
                                            alt=""
                                            className="img_formatting imageCursor"
                                          />
                                          {row.name && row.name}
                                        </div>
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell className="table_cell_padding">
                                      <Checkbox
                                        checked={row.isSelected}
                                        onChange={() => checkBoxHandler(row.id)}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={InviteesArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                        <PublishMarkAttendence
                          date={startdate}
                          data={InviteesArr}
                          event_id={parseInt(props.location.state.data.id)}
                          setIsUpdated={setIsUpdated}
                          userId={props?.data?.userid}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(MarkEventAttendance));
