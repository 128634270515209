import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Loder from "../../../Loder/Loder.jsx";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { useMutation } from "@apollo/client";
import { showNotificationMsz } from "../../../../utils/Validation";
//------------------------------redux---------------------------
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import { Button } from "@material-ui/core";
import ClassInvitelist from "../GetList/ClassInvitelist";
const ClassGroupInvitation = (props) => {
  const [InviteParticipantAPI, {}] = useMutation(
    Service.INVITE_PARTICIPANT_FOR_CLASS
  );
  let academic_session_id = parseInt(props.academic_session_id);

  //---------------------------api calss--------------------------
  const client = useApolloClient();

  //---------------------------local state--------------------------
  const [allGradeArr, setAllGradeArr] = useState([]);
  const [gradeArr, setGradeArr] = useState([]);

  const [gradeId, setGradeId] = useState(-1);
  const [isloading, setisloading] = useState(false);

  const [IsUpdated, setIsUpdated] = useState(false);
  const [chekbox1, setchekbox1] = useState(true);
  const [chekbox2, setchekbox2] = useState(false);
  const [checkValue, setCheckValue] = useState("STUDENT");

  //---------------------------methods--------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setGradeArr(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  }, [client, academic_session_id]);

  const selectGrade = async (id) => {
    try {
      setGradeId(parseInt(id));
      if (+id === 0) {
        let temp = [];
        gradeArr.map((item) => {
          temp.push({
            id: +item.id,
          });
        });

        setAllGradeArr(temp);
      }
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const InviteUser = async () => {
    try {
      if (gradeId === -1) {
        alert("PLease selecte Grade to send invide ");
        return;
      }
      let dataNeedToSend = [];
      if (gradeId === 0) {
        dataNeedToSend = allGradeArr;
      } else {
        dataNeedToSend = [{ id: gradeId }];
      }

      setisloading(true);
      const { data } = await InviteParticipantAPI({
        variables: {
          event_id: +props.id,
          grades: dataNeedToSend,
          user_type: checkValue,
        },
      })
        .then((data) => {
          setisloading(false);
          setIsUpdated(!IsUpdated);
          showNotificationMsz(
            data.addEventParticipantsForClass.message,
            "success"
          );
          // alert(data?.addEventParticipantsForClass?.message);
        })
        .catch((error) => {
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <Grid className="grid_main_div">
        <Grid item md={12}>
          <div className="mb-2">
            <div className="card_margin_both_side">
              <Card>
                <div className="card_admissiondetails_height">
                  <div className="textfiled_margin">
                    <Grid className="grid_main_div mt-2">
                      <Grid item md={5}>
                        <div className="inputMarginright mt-1">
                          <select
                            className="form-control"
                            value={gradeId}
                            onChange={(e) => selectGrade(e.target.value)}
                          >
                            <option value={-1}>Search by class</option>
                            <option value={0}>Select All</option>
                            {gradeArr.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.gradeName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Grid>
                      <Grid item md={5}>
                        <div>
                          <div className="mt-2 mb-2">
                            <span className="ml-4">
                              <CheckBoxComponent
                                label="Student Only"
                                cssClass="e-custom"
                                checked={chekbox1}
                                value={"STUDENT"}
                                onChange={(e) => {
                                  setCheckValue(e.target.value);
                                  setchekbox1(e.target.checked);

                                  setchekbox2(false);
                                }}
                              />
                            </span>
                            <span className="ml-4">
                              <CheckBoxComponent
                                label="Parent Only"
                                cssClass="e-custom"
                                checked={chekbox2}
                                value={"PARENT"}
                                onChange={(e) => {
                                  setCheckValue(e.target.value);
                                  setchekbox2(e.target.checked);
                                  setchekbox1(false);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Grid>

                      <Grid item md={2}>
                        <Button
                          variant="contained"
                          className="add_new_query_btn button_login_decoration button_width"
                          onClick={InviteUser}
                        >
                          Invite
                        </Button>
                      </Grid>
                    </Grid>

                    <div className="table_foramtitng mt-3 mb-2">
                      <ClassInvitelist
                        {...props}
                        updated={IsUpdated}
                        user_type={checkValue}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>

      <Loder loading={isloading} />
    </div>
  );
};

export default ClassGroupInvitation;
