import React, { useState } from "react";
import "./Onboarding.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//redux
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import GenerateStaff from "./GenerateStaff";
import UpdateStaff from "./UpdateStaff";

const AdminEmployeeListView = (props) => {
  //----------------generate id card var---------------------

  //----------------generate id card var---------------------
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let Organization_Name = props.data.institution_name;

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Staff Login</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="mt-2">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        left
                      >
                        <Tab
                          label="Update Staff"
                          {...a11yProps(0)}
                          className="button_login_decoration text_tranformation_exam"
                        />
                        <Tab
                          label="Generate Login"
                          {...a11yProps(1)}
                          className="button_login_decoration text_tranformation_exam"
                        />
                      </Tabs>
                    </div>
                    <div>
                      <TabPanel value={value} index={0}>
                        <UpdateStaff navigate={props.navigate} />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <GenerateStaff navigate={props.navigate} />
                      </TabPanel>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default connect(
  mapStateToProps,
  null
)((HOC1(AdminEmployeeListView)));
