import React, { useState } from "react";
import StudentLeftExpand from "./StudentLeftExpand";

const SudentSubTopic = (props) => {
  const { item, subjectName, className } = props;
  const [subtopicLevel, setsubtopicLevel] = useState(false);
  return (
    <div>
      <div
        className="view_left_subject_add"
        onClick={() => {
          setsubtopicLevel(!subtopicLevel);
        }}
      >
        <span
          onClick={(event) => {
            event.stopPropagation();
            props.updateSubTopicData({
              item,
              className,
              subjectName,
            });
          }}
          className="sub_topic_color"
        >
          {item.name}
        </span>

        {subtopicLevel && (
          <div>
            <StudentLeftExpand
              {...props}
              row={item.topic_links}
              type="subtopic"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SudentSubTopic;
