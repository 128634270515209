import { useApolloClient } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//--------------------redux---------------------------
import * as Service from "../../../services/index";
import { dateFormatToShow } from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import TeacherMonday from "./TeacherMonday.jsx";

const TeacherTimeTable = (props) => {
  const client = useApolloClient();
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  const [managegrade, setmanagegrade] = useState([]);
  const [section, setsection] = useState([]);
  const [section_id, setSection_id] = useState("");
  const [isloading, setisloading] = useState(false);

  const sectionselectHandler = (val) => {
    let section_ID = parseInt(val);
    if (section_ID === -1) {
      setSection_id("");
      return;
    }
    setSection_id(section_ID);
  };
  let academic_session_id = parseInt(props.dataacademic.id);
  useEffect(() => {
    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setmanagegrade(response.data.getGrade);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getManageGrades();
  }, [academic_session_id, client]);

  const selectHandler = (id) => {
    let grade_id = parseInt(id);
    setsection([]);
    if (grade_id === -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setsection(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  let  day = new Date().getDay() - 1;
if(day < 0 ){
  day = 6
}
  const [value, setValue] = React.useState(day);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Class Time Table</div>
                  <div className="mr-3 mt-3">
                    {`${dateFormatToShow(startOfWeek)}-${dateFormatToShow(
                      endOfWeek
                    )}`}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-2">
                        <Grid item md={5}>
                          <div className="inputMarginright mt-1">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) =>
                                selectHandler(e.target.value, e.target.label)
                              }
                            >
                              <option value={-1}>Select Class</option>

                              {managegrade.length > 0 ? (
                                managegrade.map((item, index) => (
                                  <option value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))
                              ) : (
                                <option value={-1}>No Data</option>
                              )}
                            </select>
                          </div>
                        </Grid>
                        <Grid item md={5}>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) =>
                                sectionselectHandler(e.target.value)
                              }
                            >
                              <option value={-1}>Select Section</option>
                              {section.length > 0 ? (
                                section.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))
                              ) : (
                                <option value={-1}>No Data</option>
                              )}
                            </select>
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2 tabs_class_for_Mobile">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="Monday"
                              {...a11yProps(0)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Tuesday"
                              {...a11yProps(1)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Wednesday"
                              {...a11yProps(2)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Thursday"
                              {...a11yProps(3)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Friday"
                              {...a11yProps(4)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Saturday"
                              {...a11yProps(5)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Sunday"
                              {...a11yProps(6)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                          </Tabs>
                        </div>
                        <div className="Pannel_padding_hidden">
                          <TabPanel value={value} index={0}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={1}
                              name={"Monday"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={1}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={2}
                              name={"Tuesday"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={2}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={3}
                              name={"Wednesday"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={3}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={4}
                              name={"Thrusday"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={4}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={5}
                              name={"Friday"}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={5}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={6}
                              name={"Saturday"}
                            />
                          </TabPanel>
                          <TabPanel value={value} index={6}>
                            <TeacherMonday
                              sectionId={section_id}
                              day={7}
                              name={"Sunday"}
                            />
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => ({
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(TeacherTimeTable));
