import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {
  getFullName,
  getWeekDay,
  showNotificationMsz,
} from "../../utils/Validation";
import { Button, Dialog } from "@material-ui/core";
//redux
import * as Service from "../../services/index";
import { useApolloClient, useMutation } from "@apollo/client";
import Loder from "../Loder/Loder";

const PrivateStudentNoitce = (props) => {
  const { institution_id } = props.data;
  const [delete_PrivateStudent_FromTeacher, {}] = useMutation(
    Service.deletePrivateStudentFromTeacher
  );
  const [Assign_Section_Student_API, {}] = useMutation(
    Service.Assign_Section_Student_API
  );
  const [update_Student_Request, {}] = useMutation(
    Service.updateStudentRequest
  );
  const client = useApolloClient();
  const [profileData, setProfileData] = useState([]);
  const [acceptDailogOpen, setAcceptDailogOpen] = useState(false);
  const [rejectDailogOpen, setRejectDailogOpen] = useState(false);
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [gradeId, setGradeId] = useState(-1);
  const [gradeIdError, setGradeIdError] = useState(false);
  const [sectionId, setSectionId] = useState(-1);
  const [sectionIdError, setSectionIdError] = useState(false);
  const [userID, setUserID] = useState("");
  const [studentName, setStudentName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [board, setBoard] = useState("");
  const [subject, setSubject] = useState("");
  const [requestID, setRequestID] = useState("");

  useEffect(() => {
    getManageGrades();
    getPrivateStudentList();
  }, []);

  const getManageGrades = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(props?.dataacademic?.id),
          },
        })
        .then((response) => {
          if (response.data.getGrade.length !== 0) {
            setGradeArr(response.data.getGrade);
          }
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const getPrivateStudentList = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.getStudentForPrivateInstitute,
          variables: {
            institution_id: parseInt(institution_id),
          },
        })
        .then((response) => {
          let data = response.data.getStudentForPrivateInstitute;
          if (data !== 0) {
            let temp = [];
            data.map((row) => {
              if (
                row.student_request?.status === "ACTIVE" ||
                row.student_request?.status === "ACCEPTED"
              ) {
                temp.push({
                  name: row.name,
                  id: row.id,
                  student_request: row.student_request,
                });
              }
            });
            setProfileData(temp);
          }
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const changeGrade = (val) => {
    let grade_id = parseInt(val);
    setGradeId(grade_id);

    setSectionArr([]);

    if (grade_id === -1) {
      return;
    }

    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          let tempArr = [];
          const { getSectionAndGrade } = response.data;
          getSectionAndGrade.map((item) => {
            tempArr.push(+item.id);
          });
          setisloading(false);
          setSectionArr(getSectionAndGrade);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const changeSection = (val) => {
    setSectionId(+val);
  };
  // accept button function
  const AcceptHnadleDailog = (row) => {
    setAcceptDailogOpen(true);
    setUserID(row.id);
    setStudentName(getFullName(row.name));
    setStartDate(row?.student_request?.start_date);
    setBoard(row?.student_request?.board);
    setSubject(row?.student_request?.subject);
    setRequestID(row?.student_request?.id);
  };
  const requestAcceptHandle = () => {
    if (!gradeId || gradeId === -1) {
      setGradeIdError(true);
    }
    if (!sectionId || sectionId === -1) {
      setSectionIdError(true);
    }
    if (!gradeId || !sectionId) {
      return;
    }
    let userIdArr = [];
    userIdArr.push({
      id: parseInt(userID),
    });
    try {
      Assign_Section_Student_API({
        variables: { id: userIdArr, section_id: parseInt(sectionId) },
      })
        .then((response) => {
          setAcceptDailogOpen(false);
          showNotificationMsz("Student assigned succesfully", "success");
          setisloading(false);
          statusChangeData();
          // getPrivateStudentList();
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
          setisloading(false);
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };
  const statusChangeData = () => {
    try {
      update_Student_Request({
        variables: { id: requestID, status: "ACCEPTED" },
      })
        .then((response) => {
          getPrivateStudentList();
        })
        .catch((error) => {
          console.log("errro", error);
          showNotificationMsz(error, "danger");
          setisloading(false);
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };
  // reject function
  const RejectHandleDailog = (row) => {
    setUserID(row.id);
    setRejectDailogOpen(true);
  };
  const deleteHandle = async () => {
    try {
      setisloading(true);
      const { data } = await delete_PrivateStudent_FromTeacher({
        variables: { id: +userID },
      });

      if (data && data.deletePrivateStudentFromTeacher) {
        const { message } = data.deletePrivateStudentFromTeacher;
        showNotificationMsz(message, "success");
        setRejectDailogOpen(false);
        getPrivateStudentList();
      }
      setisloading(false);
    } catch (error) {
      showNotificationMsz("Something went wrong", "danger");
      setisloading(false);
      console.log("error", error);
    }
  };
  return (
    <div>
      <div className="enquirydetails_background mb-2">
        <div className="enquiry_heading">
          <div className="ml-3 mt-3 Heading_bold">
            New Student Requests ({profileData.length})
          </div>
          {/* <div className="mr-3 mt-3">New Requests (15)</div> */}
        </div>
        <div className="card_margin_both_side">
          <div className="privateStudentMainCard">
            {/* mam section */}
            {profileData.map((row, index) => (
              <Card className="findTutorsMarginSapce" key={index}>
                <Grid className="grid_main_div">
                  <Grid item md={7}>
                    <Grid className="grid_main_div">
                      {/* <Grid item md={3}>
                    <div className="fintTutorsPadding imageCenter">
                      <img
                        src={row?.image}
                        alt="no_image"
                        width={160}
                        height={160}
                      />
                    </div>
                  </Grid> */}
                      <Grid item md={12}>
                        <div className="fintTutorsPadding">
                          <div className="tutorsInfo">
                            <p>
                              <b>Name :</b>{" "}
                              <span>{getFullName(row?.name)}</span>
                            </p>
                            <p>
                              <b>Start Date :</b>{" "}
                              <span>{row?.student_request?.start_date}</span>
                            </p>
                            {/* <p>
                              <b>Time Slot :</b>{" "}
                            </p>
                            <div className="avaliblityFlex">
                              {row?.student_request?.day &&
                                row?.student_request?.day
                                  .split(",")
                                  .map((avb, index3) => (
                                    <div
                                      style={{
                                        width: "33%",
                                        display: "flex",
                                      }}
                                      key={index3}
                                    >
                                      <p style={{ width: "80%" }}>{avb}</p>
                                    </div>
                                  ))}
                            </div> */}

                            <p>
                              <b>Language Spoken : </b>{" "}
                              {row?.student_request?.language}
                            </p>
                            <p>
                              <b>Subjects :</b> {row?.student_request?.subject}
                            </p>
                            <p>
                              <b>Boards :</b> {row?.student_request?.board}
                            </p>
                            <p>
                              <b>Class Type :</b>{" "}
                              <span> {row?.student_request?.class_type}</span>
                            </p>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4}>
                    {row?.student_request?.status !== "ACCEPTED" && (
                      <div className="buttonCenter">
                        <Button
                          variant="contained"
                          className="add_new_query_btn search_btn_width button_login_decoration mr-2 "
                          onClick={() => {
                            AcceptHnadleDailog(row);
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          className="add_new_query_btn search_btn_width button_login_decoration ml-2 "
                          onClick={() => {
                            RejectHandleDailog(row);
                          }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Card>
            ))}
            {profileData.length == 0 && (
              <div className="noDataFoundCenter">
                <p>No Data</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* accept student dailog */}
      <Dialog
        open={acceptDailogOpen}
        onClose={() => {
          setAcceptDailogOpen(false);
        }}
        fullWidth="fullWidth"
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        className="dailog_formatting"
      >
        <div>
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setAcceptDailogOpen(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-3 pr-3">
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label for="exampleInputEmail1">class </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={gradeId}
                    onChange={(e) => {
                      changeGrade(e.target.value);
                      setGradeIdError(false);
                    }}
                  >
                    <option value={-1}>Search By Class</option>

                    {gradeArr.length > 0 &&
                      gradeArr.map((item) => (
                        <option value={item.id}>{item.gradeName}</option>
                      ))}
                  </select>
                  {gradeIdError && (
                    <span className="text-danger">Enter class name</span>
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label for="exampleInputEmail1">Section </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    value={sectionId}
                    onChange={(e) => {
                      changeSection(e.target.value);
                      setSectionIdError(false);
                    }}
                  >
                    <option value={-1}>Search By Section</option>

                    {sectionArr.length > 0 &&
                      sectionArr.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                  {sectionIdError && (
                    <span className="text-danger">Enter section name</span>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label for="exampleInputEmail1">Student Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Student Name"
                    autoComplete="off"
                    disabled={true}
                    value={studentName}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label for="exampleInputEmail1">Start date </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Start date"
                    autoComplete="off"
                    disabled={true}
                    value={startDate}
                    // onChange={(e) => {
                    //   setname(e.target.value);
                    // }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label for="exampleInputEmail1">board </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="board"
                    autoComplete="off"
                    disabled={true}
                    value={board}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="inputMarginright mt-1">
                  <label for="exampleInputEmail1">Subject </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    autoComplete="off"
                    disabled={true}
                    value={subject}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => {
                    requestAcceptHandle();
                  }}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setAcceptDailogOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      {/* reject student dailog */}
      <Dialog
        open={rejectDailogOpen}
        onClose={() => {
          setRejectDailogOpen(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setRejectDailogOpen(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Please confirm rejection</div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => {
                    deleteHandle();
                  }}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setRejectDailogOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isloading} />
    </div>
  );
};

export default PrivateStudentNoitce;
