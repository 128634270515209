import React, { Component } from "react";
import "./SignIn.css";
import HOC from "../../Hoc.jsx";
import Button from "@material-ui/core/Button";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TextField, InputAdornment } from "@material-ui/core";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmpassword: "",
    };
  }

  createNotification = (type, message, title) => {
    try {
      switch (type) {
        case "info":
          NotificationManager.info(message);
          break;
        case "success":
          NotificationManager.success(message, title);
          break;
        case "warning":
          NotificationManager.warning(message);
          break;
        case "error":
          NotificationManager.error(message);
          break;
        default:
          return null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  submitresetPassword = () => {
    if (this.state.password === "") {
      this.createNotification("error", "Enter Password", null);
      return;
    }
    if (this.state.password.length < 8) {
      this.createNotification(
        "warning",
        "Password must be 8 characters long",
        null
      );
      return;
    }
    if (this.state.confirmpassword === "") {
      this.createNotification("error", "Enter Confirm Password", null);
      return;
    }
    if (this.state.confirmpassword !== this.state.password) {
      this.createNotification("warning", "Enter Correct Password", null);
      return;
    }
  };

  myFunction = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  render() {
    return (
      <div>
        <div className="main_back_color">
          <div className="main_div_Sigin">
            <div className="form_width">
              <p className="signupheading">Reset Password</p>
              <div className="main_padding_top_bottom">
                <div className=" inputfiledformatting margin_input_filed">
                  <p for="exampleInputEmail1" className="text_field_heading">
                    Password
                  </p>
                  <TextField
                    className="passwordtextbox inputwidth"
                    id="myInput"
                    variant="outlined"
                    fullWidth
                    name="password"
                    type="password"
                    placeholder="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityIcon
                            style={{ cursor: "pointer" }}
                            onClick={this.myFunction}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className=" inputfiledformatting margin_input_filed">
                  <p for="exampleInputEmail1" className="text_field_heading">
                    Confirm Password
                  </p>
                  <TextField
                    className="passwordtextbox inputwidth"
                    id="myInput"
                    variant="outlined"
                    fullWidth
                    name="password"
                    type="password"
                    placeholder="confirm password"
                    autoComplete="current-password"
                    value={this.state.confirmpassword}
                    onChange={(e) =>
                      this.setState({ confirmpassword: e.target.value })
                    }
                  />
                </div>

                <div className="inputfiledformatting buttonpadding1">
                  <Button
                    variant="contained"
                    className="login_button button_login_decoration"
                    onClick={this.submitresetPassword}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

export default (HOC(ResetPassword));
