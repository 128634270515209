import { useApolloClient } from "@apollo/client";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//------------------table portion---------------
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import React, { useEffect, useRef, useState } from "react";
import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import YearPicker from "react-year-picker";
import * as Service from "../../../services/index";
import {
  dayPresentCounter,
  getFullName,
  getMonthFormat,
  isPresent,
  monthPresentCalculator,
  options,
  pad,
  serverFormatDate,
  showNotificationMsz,
  timeWithDateFormat,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday";
import Hoc1 from "../../Hoc1";
import Loder from "../../Loder/Loder";

const ParentAttendenceShow = ({ navigate, studentId, student_Name, data }) => {
  const componentRef = useRef();
  const ref = React.createRef();
  let today_Date = new Date().toJSON().slice(0, 10);
  //------------------------------api calls----------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //------------------------------local state----------------------------
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  let prefilledDate = `${currentYear}-${pad(currentMonth)}`;
  const [preFillMonth, setPreFillMonth] = useState(prefilledDate);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [yearArr, setYearArr] = useState([
    { title: "Jan" },
    { title: "Feb" },
    { title: "Mar" },
    { title: "Apr" },
    { title: "May" },
    { title: "Jun" },
    { title: "Jul" },
    { title: "Aug" },
    { title: "Sep" },
    { title: "Oct" },
    { title: "Nov" },
    { title: "Dec" },
  ]);

  const [studentMonthAttenArr, setStudentMonthAttenArr] = useState([]);
  const [day, setDay] = useState([]);
  const [page, setPage] = React.useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [studentDayAttenArr, setStudentDayAttenArr] = useState([]);
  const [dateToShow, setdateToShow] = useState(today_Date);
  const [chekbox, setchekbox] = useState(0);
  const [timeToShow, settimeToShow] = useState("");
  const [isloading, setisloading] = useState(false);
  const [yearToShow, setYearToShow] = useState("");
  const [studentYearAttenArr, setStudentYearAttenArr] = useState([]);
  //------------------------------methods----------------------------
  useEffect(() => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_student_Attendance_By_ID,
          variables: {
            user_id: parseInt(studentId),
            start_date: today_Date,
            end_date: today_Date,
            attendance_type: "CLASS",
          },
        })
        .then((response) => {
          setStudentDayAttenArr(response.data.getStudentAttendanceById);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log(" location arr try and catch", error);
    }
  }, [studentId]);
  useEffect(() => {
    monthHandler(preFillMonth);
    yearHandler(currentYear);
  }, [studentId]);

  const yearHandler = async (year) => {
    try {
      setisloading(true);

      setYearToShow(year);
      // year data procedure-------
      let yearStartingDate = await new Date(year, 0, 1);
      let yearEndDate = await new Date(year, 11, 31);

      client
        .query({
          query: Service.get_student_Attendance_By_ID,
          variables: {
            user_id: parseInt(studentId),
            start_date: serverFormatDate(yearStartingDate),
            end_date: serverFormatDate(yearEndDate),
            attendance_type: "CLASS",
          },
        })
        .then((response) => {
          setStudentYearAttenArr(response.data.getStudentAttendanceById);

          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("year handler t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const dayWiseAttendanceHandler = (date) => {
    setdateToShow(date);
    setisloading(true);
    client
      .query({
        query: Service.get_student_Attendance_By_ID,
        variables: {
          user_id: parseInt(studentId),
          start_date: date,
          end_date: date,
          attendance_type: "CLASS",
        },
      })
      .then((response) => {
        setStudentDayAttenArr(response.data.getStudentAttendanceById);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });

    return () => {
      setIsUpdated(false);
    };
  };

  const monthHandler = (data) => {
    try {
      setisloading(true);
      let newDate = new Date(data);
      let lastDayOfMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
      ).getDate();
      settimeToShow(getMonthFormat(newDate));
      let dayArr = [];

      for (let index = 1; index <= lastDayOfMonth; index++) {
        dayArr.push({
          title: index,
          realDate: `${newDate.getFullYear()}-${pad(
            newDate.getMonth() + 1
          )}-${pad(index)}`,
        });
      }
      setDay(dayArr);
      let start_date = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      let end_date = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
      client
        .query({
          query: Service.get_student_Attendance_By_ID,
          variables: {
            user_id: parseInt(studentId),
            start_date: serverFormatDate(start_date),
            end_date: serverFormatDate(end_date),
            attendance_type: "CLASS",
          },
        })
        .then((response) => {
          setStudentMonthAttenArr(response.data.getStudentAttendanceById);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log(" location arr try and catch", error);
    }
  };
  return (
    <div>
      <div>
        <div>
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <div>
                <div>
                  <div className="ml-3">
                    <div className="mt-3 Heading_bold">Attendance</div>
                    <div>
                      <span className="Heading_bold">Student Name : </span>
                      <span>{student_Name && student_Name}</span>
                    </div>
                  </div>
                  <div>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={8}>
                            <div className="mt-5">
                              <div>
                                <span>
                                  <CheckBoxComponent
                                    label="Day"
                                    checked={chekbox == 0}
                                    value={0}
                                    cssClass="e-custom"
                                    onChange={(e) => {
                                      if (e.target.value == 1) {
                                        showNotificationMsz(
                                          "Need to calculate day of month",
                                          "info"
                                        );
                                      }
                                      setchekbox(e.target.value);
                                    }}
                                  />
                                </span>
                                <span className="ml-4">
                                  <CheckBoxComponent
                                    label="Month"
                                    cssClass="e-custom"
                                    checked={chekbox == 1}
                                    value={1}
                                    onChange={(e) => {
                                      setchekbox(e.target.value);
                                    }}
                                  />
                                </span>
                                <span className="ml-4">
                                  <CheckBoxComponent
                                    label="Year"
                                    cssClass="e-custom"
                                    checked={chekbox == 2}
                                    value={2}
                                    onChange={(e) => {
                                      setchekbox(e.target.value);
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        {chekbox == 0 && (
                          <div>
                            <Grid className="grid_main_div mt-1">
                              <Grid item md={5}>
                                <label for="exampleInputEmail1">Day</label>
                                <input
                                  max={new Date()}
                                  type="date"
                                  class="form-control"
                                  value={dateToShow}
                                  onChange={(e) => {
                                    dayWiseAttendanceHandler(e.target.value);
                                  }}
                                />
                              </Grid>
                              {/* <Grid item md={6}></Grid>
                                <Grid item md={1}>
                                  <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPdf
                                        targetRef={ref}
                                        filename="dayAttendance.pdf"
                                        scale={1}
                                        options={options}
                                      >
                                        {({ toPdf }) => (
                                          <i
                                            class="fa fa-file-pdf-o"
                                            onClick={toPdf}
                                          ></i>
                                        )}
                                      </ReactToPdf>
                                    </div>
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPrint
                                        trigger={() => (
                                          <i class="fa fa-print"></i>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </div>
                                  </div>
                                </Grid> */}
                            </Grid>
                            <Grid className="grid_main_div mt-1">
                              <Grid item md={10}></Grid>
                              <Grid item md={2} className="d-flex">
                                <Grid item md={6} className="d-flex">
                                  <p className="absentCirlce"></p>
                                  <span
                                    style={{
                                      paddingLeft: 10,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Absent
                                  </span>
                                </Grid>
                                <Grid item md={6} className="d-flex">
                                  <p className="presentCircle"></p>
                                  <span
                                    style={{
                                      paddingLeft: 10,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Present
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <div className="mt-3 mb-3 card_invoicedetails_border">
                              <div className="float-right mt-1 mb-1 mr-3">
                                Day - {timeWithDateFormat(dateToShow)}
                              </div>
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Roll No
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Present
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {studentDayAttenArr &&
                                      studentDayAttenArr
                                        .slice(0, 1)
                                        .map((item) => (
                                          <StyledTableRow>
                                            <StyledTableCell
                                              scope="row"
                                              align="left"
                                            >
                                              {item.student?.roll_number}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              scope="row"
                                              align="left"
                                            >
                                              {getFullName(
                                                item.student?.student_profile
                                                  ?.name
                                              )}
                                            </StyledTableCell>

                                            {item.status === "Present" ? (
                                              <StyledTableCell align="left">
                                                <i
                                                  class="fa fa-check"
                                                  style={{
                                                    color: "#50ae34",
                                                    fontSize: 15,
                                                  }}
                                                ></i>
                                              </StyledTableCell>
                                            ) : (
                                              <StyledTableCell align="left">
                                                <i
                                                  class="fa fa-times cursor"
                                                  style={{
                                                    color: "#ae3450",
                                                    fontSize: 15,
                                                  }}
                                                ></i>
                                              </StyledTableCell>
                                            )}
                                          </StyledTableRow>
                                        ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={studentDayAttenArr.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        )}

                        {chekbox == 1 && (
                          <div>
                            <Grid className="grid_main_div mt-1 d-flex">
                              <Grid item md={5}>
                                <div>Select Month</div>
                                <input
                                  type="month"
                                  class="form-control"
                                  value={preFillMonth}
                                  onChange={(e) => {
                                    setPreFillMonth(e.target.value);
                                    monthHandler(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item md={6}></Grid>
                              {/* <Grid item md={1}>
                                  <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPdf
                                        targetRef={ref}
                                        filename="monthAttendance.pdf"
                                        scale={1}
                                        options={options}
                                      >
                                        {({ toPdf }) => (
                                          <i
                                            class="fa fa-file-pdf-o"
                                            onClick={toPdf}
                                          ></i>
                                        )}
                                      </ReactToPdf>
                                    </div>
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPrint
                                        trigger={() => (
                                          <i class="fa fa-print"></i>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </div>
                                  </div>
                                </Grid> */}
                            </Grid>
                            <Grid className="grid_main_div mt-1">
                              <Grid item md={10}></Grid>
                              <Grid item md={2} className="d-flex">
                                <Grid item md={6} className="d-flex">
                                  <p className="absentCirlce"></p>
                                  <span
                                    style={{
                                      paddingLeft: 10,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Absent
                                  </span>
                                </Grid>
                                <Grid item md={6} className="d-flex">
                                  <p className="presentCircle"></p>
                                  <span
                                    style={{
                                      paddingLeft: 10,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Present
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <div className="mt-3 mb-3 card_invoicedetails_border">
                              <div className="d-flex mt-2 mb-2">
                                <div className="col-7"></div>
                                <div className="col-5">
                                  <div className="d-flex">
                                    <div className="col-6">
                                      <p>
                                        Total Days -{" "}
                                        {dayPresentCounter(
                                          studentMonthAttenArr
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p>{timeToShow}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                        padding="none"
                                      >
                                        Days <br /> Present
                                      </StyledTableCell>

                                      {day.map((item) => (
                                        <StyledTableCell
                                          align="left"
                                          className={
                                            item.title < 10
                                              ? "table_header paddingone"
                                              : "table_header"
                                          }
                                          padding="none"
                                        >
                                          {item.title}
                                        </StyledTableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <StyledTableRow>
                                      <StyledTableCell scope="row" align="left">
                                        {student_Name}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {dayPresentCounter(
                                          studentMonthAttenArr
                                        )}
                                      </StyledTableCell>

                                      {day.map((item) => {
                                        if (
                                          isPresent(
                                            item.realDate,
                                            studentMonthAttenArr
                                          ) === "Present"
                                        ) {
                                          return (
                                            <StyledTableCell
                                              align="left"
                                              padding="none"
                                            >
                                              <i
                                                class="fa fa-check"
                                                style={{
                                                  color: "#50ae34",
                                                  fontSize: 15,
                                                }}
                                              ></i>
                                            </StyledTableCell>
                                          );
                                        } else if (
                                          isPresent(
                                            item.realDate,
                                            studentMonthAttenArr
                                          ) === "Absent"
                                        ) {
                                          return (
                                            <StyledTableCell
                                              align="left"
                                              padding="none"
                                            >
                                              <i
                                                class="fa fa-times cursor"
                                                style={{
                                                  color: "#ff0000",
                                                  fontSize: 15,
                                                }}
                                              ></i>
                                            </StyledTableCell>
                                          );
                                        } else {
                                          return (
                                            <StyledTableCell
                                              align="left"
                                              padding="none"
                                            >
                                              <i
                                                class="fa fa-check"
                                                style={{
                                                  color: "#50ae34",
                                                  fontSize: 15,
                                                  visibility: "hidden",
                                                }}
                                              ></i>
                                            </StyledTableCell>
                                          );
                                        }
                                      })}
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={studentMonthAttenArr.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        )}
                        {chekbox == 2 && (
                          <div>
                            <div>
                              <Grid className="grid_main_div mt-1 d-flex">
                                <Grid item md={5}>
                                  <label style={{ marginBottom: 0 }}>
                                    Select Year
                                  </label>
                                  <YearPicker onChange={yearHandler} />
                                </Grid>
                                <Grid item md={6}></Grid>
                                <Grid item md={1}>
                                  <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPdf
                                        targetRef={ref}
                                        filename="yearAttendance.pdf"
                                        scale={1}
                                        options={options}
                                      >
                                        {({ toPdf }) => (
                                          <i
                                            class="fa fa-file-pdf-o"
                                            onClick={toPdf}
                                          ></i>
                                        )}
                                      </ReactToPdf>
                                    </div>
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPrint
                                        trigger={() => (
                                          <i class="fa fa-print"></i>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                <div className="d-flex mt-2 mb-2">
                                  <div className="col-7"></div>
                                  <div className="col-5">
                                    <div className="d-flex">
                                      <div className="col-6">
                                        <p>
                                          Total Days -{" "}
                                          {dayPresentCounter(
                                            studentYearAttenArr
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <p>Year - {yearToShow}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Days <br /> Present
                                        </StyledTableCell>

                                        {yearArr.map((item) => (
                                          <StyledTableCell
                                            align="left"
                                            className="table_header"
                                          >
                                            {item.title}
                                          </StyledTableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <StyledTableRow>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {student_Name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {dayPresentCounter(
                                            studentYearAttenArr
                                          )}
                                        </StyledTableCell>
                                        {yearArr.map((item, index) => {
                                          if (
                                            monthPresentCalculator(
                                              yearToShow,
                                              index,
                                              studentYearAttenArr
                                            ) === 0
                                          ) {
                                            return (
                                              <StyledTableCell
                                                align="left"
                                                key={index}
                                              ></StyledTableCell>
                                            );
                                          } else {
                                            return (
                                              <StyledTableCell
                                                align="left"
                                                key={index}
                                              >
                                                {monthPresentCalculator(
                                                  yearToShow,
                                                  index,
                                                  studentYearAttenArr
                                                )}
                                              </StyledTableCell>
                                            );
                                          }
                                        })}
                                      </StyledTableRow>
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={studentYearAttenArr.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default ParentAttendenceShow;
