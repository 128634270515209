import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import StudentSubTopic from "./SudentSubTopic";
const StudentLeftExpand = (props) => {
  const { row, type, setUpdateApi, updateApi } = props;
  const [parentLevel, setparentLevel] = useState(false);
  const [topicLevel, settopicLevel] = useState(false);
  return (
    <div>
      {row && (
        <div className={type === "parent" ? "" : "view_left_subject_add"}>
          <div className="view_left_classbold">
            {type === "parent" && (
              <div>
                <div className="view_left_subject_add">
                  <div
                    onClick={() => {
                      settopicLevel(!topicLevel);
                    }}
                  >
                    {topicLevel ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    {row.subject.name}{" "}
                  </div>
                </div>
                {topicLevel && (
                  <StudentLeftExpand
                    {...props}
                    row={row.topics}
                    className={row.class_name}
                    subjectName={row.subject.name}
                    type="topic"
                  />
                )}
              </div>
            )}

            {type === "topic" &&
              row &&
              row.map((item) => (
                <StudentSubTopic {...props} item={item}></StudentSubTopic>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentLeftExpand;
