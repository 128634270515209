import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getFullName, showNotificationMsz } from "../../../utils/Validation";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Dialog from "@material-ui/core/Dialog";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Button from "@material-ui/core/Button";
import * as Service from "../../../services/index";
import { useApolloClient, useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  timeStampToDatedash,
  timeStampToDatedashtoDDMMYY,
  getcurrentDate,
} from "../../../utils/Validation";

const AdminClassNotice = ({ manageInstitution, navigate }) => {
  //-----------------------global id---------------------------
  const [Delete_Notice_API, {}] = useMutation(Service.Delete_Notice_API);
  const { institution_id, institution_name, academic_session_id } =
    manageInstitution;
  //-----------------------api calls---------------------------
  const client = useApolloClient();
  const classes = useStyles();
  let institution_Name = manageInstitution.institution_name;
  //-----------------------local state---------------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [classNoticeArr, setClassNoticeArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [search, setSearch] = useState("");
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [noticecancelid, setnoticecancelid] = useState(-1);
  const [noticename, setnoticename] = useState("");
  //-----------------------methods---------------------------
  useEffect(() => {
    getManageGrades();
  }, [academic_session_id]);
  const getManageGrades = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academic_session_id),
          },
        })
        .then((response) => {
          setisloading(false);
          setClassArr(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const OpenCancelDailog = (data) => {
    setnoticename(data.title);
    setnoticecancelid(data.id);
    setopendialogConfimation(true);
  };

  const classHandler = (classId) => {
    try {
      setSectionArr([]);
      if (+classId === -1) {
        setClassNoticeArr([]);
        classNoticeArr([]);
        return;
      }
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: +classId,
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectSectionHandler = (val) => {
    let section_id = parseInt(val);
    if (section_id === -1) {
      setClassNoticeArr([]);
      return;
    }
    setSectionId(section_id);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Class_Notice_data,
          variables: {
            institution_id: parseInt(institution_id),
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);

          let temp = [];

          for (
            let index = 0;
            index < response.data.getNoticeBoardBySectionId.length;
            index++
          ) {
            const element = response.data.getNoticeBoardBySectionId[index];
            let date1 = new Date();

            let date2 = getcurrentDate(element.expiry_date);

            temp.push({
              id: element.id,
              title: element.title,
              subject: element.subject,
              publish_date: element.publish_date,
              expiry_date: element.expiry_date,
              employee: element.employee,
              status: date1 > date2 ? "EXPIRED" : element.status,
              content: element.content,
              documents: element.documents,
              section: element.section,
            });
          }

          setClassNoticeArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("parent homepage catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("parent homepage t and c error", error);
    }
  };

  const classNoticeFilterArr = classNoticeArr.filter((event) => {
    return event.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteNotice = async (eventId) => {
    let id = parseInt(eventId);
    setisloading(true);
    await Delete_Notice_API({
      variables: { id },
    }).then((data) => {
      showNotificationMsz(data?.data?.deleteNotice?.message, "success");
    });
    setisloading(false);

    setopendialogConfimation(false);
    selectSectionHandler(sectionId);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-1">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Class Notice</div>
                  <div className="mt-3 mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      onClick={() =>
                        navigate("/createNotice", {
                          state: {
                            pageType: "Add",
                            academic_session: parseInt(academic_session_id),
                          },
                        })
                      }
                    >
                      Create Notice
                    </Button>
                  </div>
                </div>

                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height ">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Class</div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(event) =>
                                  classHandler(event.target.value)
                                }
                              >
                                <option value={-1}>Select Class</option>
                                {classArr.length !== 0 &&
                                  classArr.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">Section</div>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  selectSectionHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Section</option>
                                {sectionArr.length !== 0 &&
                                  sectionArr.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="text_filed_heading">Title</div>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Search by Title"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Title
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Subject
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Publish Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Expiry Date
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Publisher Name
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? classNoticeFilterArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : classNoticeFilterArr
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.title) ? (
                                        "No Data"
                                      ) : (
                                        <span>
                                          {row.title.substring(0, 20)}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="left">
                                      {!blankValidator(row.subject) ? (
                                        "No Data"
                                      ) : (
                                        <span>
                                          {row.subject.substring(0, 20)}
                                        </span>
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {row.publish_date &&
                                        timeStampToDatedashtoDDMMYY(
                                          row.publish_date
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.expiry_date &&
                                        timeStampToDatedashtoDDMMYY(
                                          row.expiry_date
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {getFullName(row.employee?.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.status && row.status}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <div className="d-flex justify-content-center">
                                        <span
                                          className="row_details_color pr-2"
                                          onClick={() =>
                                            navigate("/noticeDetail", {
                                              state: {
                                                item: row,
                                                type: "CLASSNOTICE",
                                                institution_Name:
                                                  institution_Name,
                                              },
                                            })
                                          }
                                        >
                                          View
                                        </span>
                                        |
                                        <span
                                          className="row_details_color pl-2 pr-2"
                                          onClick={() =>
                                            navigate("/createNotice", {
                                              state: {
                                                pageType: "Edit",
                                                item: row,
                                                academic_session:
                                                  parseInt(academic_session_id),
                                              },
                                            })
                                          }
                                        >
                                          Edit
                                        </span>
                                        {row.status === "EXPIRED" ? (
                                          ""
                                        ) : (
                                          <div>
                                            |{""}
                                            <span
                                              className="row_details_color pl-2"
                                              onClick={() =>
                                                OpenCancelDailog(row)
                                              }
                                            >
                                              Delete
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={classNoticeFilterArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to cancel the Notice : {noticename}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteNotice(noticecancelid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(AdminClassNotice));
