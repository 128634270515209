import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./ManageInstitution.css";
import Button from "@material-ui/core/Button";
import Expand from "react-expand-animated";

import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  dateToDatedashtoDDMMYY,
} from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const ManageSession = (props) => {
  let institution_id = parseInt(props.data.institution_id);

  //name instituition
  let Organization_Name = props.data.institution_name;

  const client = useApolloClient();

  // create APi
  const [CREATE_ACADEMIC_SESSION, {}] = useMutation(
    Service.CREATE_ACADEMIC_SESSION
  );
  // delete api
  const [Delete_Academic_Session, {}] = useMutation(
    Service.Delete_Academic_Session
  );

  //edit api
  //edit manage subject
  const [Edit_Academic_sesssion, {}] = useMutation(
    Service.Edit_Academic_sesssion
  );
  const [sessionStatus, setsessionStatus] = useState("Inactive");
  const [sessionEditStatus, setsessionEditStatus] = useState("Inactive");
  const [isSessionStatus, setisSessionStatus] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const [isSessionChecked, setisSessionChecked] = useState(false);
  const [name, setname] = useState("");
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [addMangeopen, setaddMangeopen] = useState(false);
  const [managesessiondata, setmanagesessiondata] = useState([]);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [deletesessionName, setdeletesessionName] = useState("");
  const [deletesessionid, setdeletesessionid] = useState("");
  const [nameedit, setnameedit] = useState("");
  const [start_dateedit, setstart_dateedit] = useState("");
  const [end_dateedit, setend_dateedit] = useState("");
  const [sessionid, setsessionid] = useState("");
  const [opendailogtoEdit, setOpendailogtoEdit] = useState(false);
  const [isloading, setisloading] = useState(false);

  //error
  const [sessionnameError, setsessionnameError] = useState(false);
  const [startdateError, setstartdateError] = useState(false);
  const [enddateError, setenddateError] = useState(false);
  const [editsessionnameError, seteditsessionnameError] = useState(false);
  const [editstartdateError, seteditstartdateError] = useState(false);
  const [editenddateError, seteditenddateError] = useState(false);
  const [warningDialog, setWarningDialog] = useState(false);

  const OpenEditQuery = (data) => {
    setnameedit(data.session_name);
    if (data.is_current === 0) {
      setisSessionChecked(false);
      setsessionEditStatus("Inactive");
    } else {
      setisSessionChecked(true);
      setsessionEditStatus("Active");
    }
    setstart_dateedit(data.start_day);
    setend_dateedit(data.end_day);
    setsessionid(data.id);
    setOpendailogtoEdit(true);
  };

  const DeleteDailogBox = (data) => {
    setdeletesessionName(data.session_name);
    setdeletesessionid(data.id);
    setopendialogConfimation(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagesessiondata(response.data.getAllAcademicSession);
        })

        .catch((e) => {
          setisloading(false);
          console.log("catch y error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [client, isUpdated, institution_id]);

  const Create_academic_session = () => {
    if (!blankValidator(name)) {
      setsessionnameError(true);
      return;
    }
    if (!blankValidator(start_date)) {
      setstartdateError(true);
      return;
    }
    if (!blankValidator(end_date)) {
      setenddateError(true);
      return;
    }
    let is_current = 0;
    if (isSessionStatus === true) {
      is_current = 1;
    }
    try {
      setisloading(true);
      CREATE_ACADEMIC_SESSION({
        variables: {
          institution_id,
          start_day: start_date,
          end_day: end_date,
          is_current,
          session_name: name,
        },
      }).then((response) => {
        setisloading(false);
        showNotificationMsz(
          response.data.addAcademicSession.message,
          "success"
        );

        setaddMangeopen(false);
        setname("");
        setstart_date("");
        setend_date("");
        setIsUpdated(!isUpdated);
        //props.navigate("/InstitutionSetup");
      });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };

  const deleteSessionHandler = (ID) => {
    let id = parseInt(ID);
    try {
      setisloading(true);
      Delete_Academic_Session({
        variables: { id },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.deleteAcademicSession.message,
            "success"
          );

          setopendialogConfimation(false);
          setIsUpdated(!isUpdated);
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const UpdateacademicSession = () => {
    // let id = parseInt(sessionid);
    // let is_current = 0;
    // if (isSessionChecked === true) {
    //   is_current = 1;
    // }
    if (!blankValidator(nameedit)) {
      seteditsessionnameError(true);
      return;
    }
    if (!blankValidator(start_dateedit)) {
      seteditstartdateError(true);
      return;
    }
    if (!blankValidator(end_dateedit)) {
      seteditenddateError(true);
      return;
    }
    setWarningDialog(true);
  };
  const submitacademicHandler = () => {
    let id = parseInt(sessionid);
    let is_current = 0;
    if (isSessionChecked === true) {
      is_current = 1;
    }
    try {
      setisloading(true);
      Edit_Academic_sesssion({
        variables: {
          id,
          start_day: start_dateedit,
          end_day: end_dateedit,
          session_name: nameedit,
          is_current,
          institution_id,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.updateAcademicSession.message,
            "success"
          );
          setOpendailogtoEdit(false);
          setWarningDialog(false);
          setIsUpdated(!isUpdated);
         props.navigate("/");
          //props.navigate("/InstitutionSetup");
        },
        (err) => {
          setisloading(false);
          showNotificationMsz(err, "danger");
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const SessionCheckBoxHandle = (index) => {
    let temp = false;
    for (let index1 = 0; index1 < managesessiondata.length; index1++) {
      const element = managesessiondata[index1].is_current;
      if (element === true && index !== index1) {
        temp = true;
      }
    }
    if (temp === true) {
      showNotificationMsz("Please unselect data", "info");
      return;
    }
    managesessiondata[index].is_current = !managesessiondata[index].is_current;
    setmanagesessiondata([...managesessiondata]);
  };

  const handleaddMangeopen = () => {
    setaddMangeopen(true);
  };
  const closeaddMangeopen = () => {
    setaddMangeopen(false);
  };

  const sortedList = managesessiondata.sort((a, b) =>
    a.session_name.localeCompare(b.session_name)
  );

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">
                    Manage Academic Session
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-3 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {addMangeopen === false ? (
                          <div className="d-flex" onClick={handleaddMangeopen}>
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add New Academic Session
                            </span>
                          </div>
                        ) : (
                          <Expand open={addMangeopen}>
                            <Card className="cardvisible_border mb-2">
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div
                                    className="text-right"
                                    onClick={closeaddMangeopen}
                                  >
                                    <span className="iconstoaddfee">
                                      <i class="fa fa-times cursor"></i>
                                    </span>
                                  </div>
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div">
                                      <Grid item md={10}>
                                        <div>
                                          <div className="text_filed_heading">
                                            Name
                                          </div>
                                          <div className="inputMarginright mt-1">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              placeholder="Name"
                                              autoComplete="off"
                                              value={name}
                                              onChange={(e) => {
                                                setsessionnameError(false);
                                                setname(e.target.value);
                                              }}
                                            />
                                            {sessionnameError && (
                                              <span className="text-danger">
                                                Enter the Session Name
                                              </span>
                                            )}
                                          </div>
                                          <div className="current_acdimic_se">
                                            <div>
                                              <Checkbox
                                                checked={isSessionStatus}
                                                onClick={() => {
                                                  setisSessionStatus(
                                                    !isSessionStatus
                                                  );
                                                  if (
                                                    isSessionStatus === false
                                                  ) {
                                                    setsessionStatus("Active");
                                                  } else if (
                                                    isSessionStatus === true
                                                  ) {
                                                    setsessionStatus(
                                                      "Inactive"
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="text_filed_heading">
                                              Make Current Academic Session
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>

                                      <Grid item md={2}></Grid>
                                    </Grid>
                                  </div>
                                  <div className="card_content_instition">
                                    <Grid className="grid_main_div mt-1">
                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          Start Date
                                        </div>
                                        <div className="inputMarginright mt-1">
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={start_date}
                                            onChange={(e) => {
                                              setstartdateError(false);
                                              setstart_date(e.target.value);
                                            }}
                                          />
                                          {startdateError && (
                                            <span className="text-danger">
                                              Enter the Start Date
                                            </span>
                                          )}
                                        </div>
                                      </Grid>

                                      <Grid item md={6}>
                                        <div className="text_filed_heading">
                                          End Date
                                        </div>
                                        <div className="mt-1">
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={end_date}
                                            onChange={(e) => {
                                              setenddateError(false);
                                              setend_date(e.target.value);
                                            }}
                                          />
                                          {enddateError && (
                                            <span className="text-danger">
                                              Enter the End Date
                                            </span>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid className="grid_main_div">
                                    <Grid item md={10}></Grid>
                                    <Grid item md={2}>
                                      <div className=" mb-1">
                                        <Button
                                          variant="contained"
                                          className="add_new_query_btn  button_login_decoration button_width"
                                          onClick={Create_academic_session}
                                        >
                                          Create
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-4">
                      <div className="textfiled_margin cardheight_overflow">
                        {sortedList.length ? (
                          sortedList.map((item, index) => (
                            <Card
                              className={` mb-2 ${
                                sortedList[index].is_current
                                  ? "Card_Active_Border"
                                  : "Card_Border_None"
                              }`}
                            >
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="">
                                    <Grid className="grid_main_div">
                                      <Grid item md={1}></Grid>
                                      <Grid item md={6}>
                                        <div className=" mt-1 d-flex">
                                          <span>
                                            {" "}
                                            <b> Session Name: </b>{" "}
                                            {sortedList[index].session_name}
                                          </span>
                                        </div>
                                        <div className="mt-2">
                                          <span>
                                            <b>Start Date: </b>{" "}
                                            {dateToDatedashtoDDMMYY(
                                              sortedList[index].start_day
                                            )}
                                          </span>
                                          <span className="ml-5">
                                            <b>End Date: </b>{" "}
                                            {dateToDatedashtoDDMMYY(
                                              sortedList[index].end_day
                                            )}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item md={5}>
                                        {" "}
                                        <div className="d-flex pt-2">
                                          <span className="ml-4">
                                            {sortedList[index].is_current ? (
                                              <Card className="Current_Session_card">
                                                Current Academic Session
                                              </Card>
                                            ) : (
                                              <span>{""}</span>
                                            )}
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                OpenEditQuery(item)
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                DeleteDailogBox(item)
                                              }
                                            ></i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))
                        ) : (
                          <div>No Data</div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>

      <Dialog
        open={opendailogtoEdit}
        onClose={() => {
          setOpendailogtoEdit(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="p-2">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setOpendailogtoEdit(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <div>
                  <label for="exampleInputEmail1">Session Name</label>
                  <div className="inputMarginright">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="active or inactive"
                      autoComplete="off"
                      value={nameedit}
                      onChange={(e) => {
                        seteditsessionnameError(false);
                        setnameedit(e.target.value);
                      }}
                    />
                    {editsessionnameError && (
                      <span className="text-danger">
                        Enter the Session Name
                      </span>
                    )}
                  </div>
                  <div className="current_acdimic_session_flex">
                    <div>
                      <Checkbox
                        checked={isSessionChecked}
                        onClick={() => {
                          setisSessionChecked(!isSessionChecked);
                          if (!isSessionChecked) {
                            setsessionEditStatus("Active");
                          } else {
                            setsessionEditStatus("Inactive");
                          }
                        }}
                      />
                    </div>
                    <div for="exampleInputEmail1">
                      Make Current Academic Session
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item md={1}></Grid>
            </Grid>

            <Grid className="grid_main_div mt-1">
              <Grid item md={1}></Grid>
              <Grid item md={5}>
                <label for="exampleInputEmail1">Start Date</label>
                <div className="inputMarginright">
                  <input
                    type="date"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={start_dateedit}
                    onChange={(e) => {
                      seteditstartdateError(false);
                      setstart_dateedit(e.target.value);
                    }}
                  />
                  {editstartdateError && (
                    <span className="text-danger">Enter the Start Date</span>
                  )}
                </div>
              </Grid>
              <Grid item md={5}>
                <label for="exampleInputEmail1">End Date</label>
                <div>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    autoComplete="off"
                    value={end_dateedit}
                    onChange={(e) => {
                      seteditenddateError(false);
                      setend_dateedit(e.target.value);
                    }}
                  />
                  {editenddateError && (
                    <span className="text-danger">Enter the End Date</span>
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4  mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={UpdateacademicSession}
              >
                Update
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete the Session : {deletesessionName}
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteSessionHandler(deletesessionid)}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      {/* warning dailog box */}
      <Dialog
        open={warningDialog}
        onClose={() => {
          setWarningDialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setWarningDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            "Warning! This will change institution's current academic session
            for all users"
          </div>
          <div className="pl-5 pr-5 mt-4">Do you want to continue?</div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => submitacademicHandler()}
                >
                  submit
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setWarningDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(ManageSession)));
