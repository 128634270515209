import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { showNotificationMsz } from "../../../../utils/Validation";
import * as Service from "../../../../services/index";
import Button from "@material-ui/core/Button";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import Loder from "../../../Loder/Loder.jsx";
import StaffInviteesList from "../GetList/StaffInviteesList";
const StaffGroupInvitation = (props) => {
  let institution_id = parseInt(props.institution_id);
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [IsUpdated, setIsUpdated] = useState(false);
  const [managedepartment, setmanagedepartment] = useState([]);
  const [departmentID, setDepartmentID] = useState(-1);
  const [Invitaion_Department, {}] = useMutation(Service.Invitaion_Department);
  useEffect(() => {
    departMentdata();
  }, []);
  const departMentdata = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Department_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagedepartment(response.data.getDepartment);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectDepartment = (ID) => {
    try {
      setDepartmentID(parseInt(ID));
    } catch (error) {}
  };
  const InviteDepartmentHandle = () => {
    try {
      if (departmentID === -1) {
        alert("PLease selecte Grade to send invide ");
        return;
      }
      let dataNeedToSend = [];
      if (departmentID === 0) {
        managedepartment.map((item) => {
          dataNeedToSend.push({
            id: +item.id,
          });
        });
      } else {
        dataNeedToSend = [{ id: departmentID }];
      }
      setisloading(true);
      Invitaion_Department({
        variables: {
          event_id: props.eventid,
          department: dataNeedToSend,
        },
      })
        .then((response) => {
          setisloading(false);
          setIsUpdated(!IsUpdated);
          showNotificationMsz(
            response.data.addEventParticipantsForDepartment.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {}
  };
  return (
    <div>
      <Grid className="grid_main_div mt-3">
        <Grid item md={12}>
          <div className="mt-2">
            <div>
              <Grid className="grid_main_div mt-2">
                <Grid item md={5}>
                  <div className="inputMarginright">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={departmentID}
                      onChange={(e) => selectDepartment(e.target.value)}
                    >
                      <option value={-1}>Search By Department</option>
                      <option value={0}>Select All</option>
                      {managedepartment.length > 0 &&
                        managedepartment.map((item, index) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={3}>
                  <div className="pl-1">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration button_width"
                      style={{ marginTop: 9 }}
                      onClick={InviteDepartmentHandle}
                    >
                      Invite
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <StaffInviteesList {...props} updated={IsUpdated}></StaffInviteesList>
            <div></div>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isloading} />
    </div>
  );
};

export default StaffGroupInvitation;
