import { useApolloClient } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import { changeTimeFormat, getFullName } from "../../../utils/Validation";
import OnlineLinkLocation from "../../Common/Events/OnlineLinkLocation";
import Loder from "../../Loder/Loder.jsx";

const ParentMonday = ({ PARENT_DATA, sectionId, day, name }) => {
  //----------------------global id---------------------------
  let institution_id = parseInt(PARENT_DATA.institution_id);
  let TodayDate = Date.now();
  //----------------------api calls---------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //----------------------local state---------------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mondayArray, setMondayArray] = useState([]);
  const [isloading, setisloading] = useState(false);
  //----------------------methods---------------------------
  useEffect(() => {
    parentMondayGetData();
  }, [institution_id, client, sectionId]);

  const parentMondayGetData = async () => {
    try {
      if (parseInt(sectionId) === -1) {
        setMondayArray([]);
        return;
      }
      setisloading(true);
      const response = await client.query({
        query: Service.Get_Class_Time_Table,
        variables: {
          institution_id,
          section_id: parseInt(sectionId),
          day,
          start_date: new Date(
            TodayDate + (day - new Date().getDay()) * (3600 * 24000)
          )
            .toJSON()
            .slice(0, 10),
          end_date: "",
        },
      });
      const temp = [];
      await response.data.getClassTimetable.map((item) => {
        item["show"] = false;
        item["display"] = "";
        temp.push(item);
      });
      const sortedList = temp.sort((a, b) => {
        let st = a.start_time.split(":");
        let stm = parseInt(st[0]);
        let ss = parseInt(st[1]);
        let et = b.start_time.split(":");
        let etm = parseInt(et[0]);
        let es = parseInt(et[1]);
        return stm - etm || ss - es;
      });
      setMondayArray(sortedList);
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="Heading_bold mt-2">{name}</div>
      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table_header" align="left">
                  Period Name
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Start Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  End Time
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Rooms
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Subject
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Teacher Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? mondayArray.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : mondayArray
              ).map((row, index) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {row.period_name && row.period_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.start_time && changeTimeFormat(row.start_time)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.end_time && changeTimeFormat(row.end_time)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <OnlineLinkLocation data={row} />
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.subject?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getFullName(row.employee?.name)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={mondayArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>

      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  PARENT_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(ParentMonday);
