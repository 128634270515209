import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loder from "../../Loder/Loder.jsx";
import "react-phone-input-2/lib/style.css";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
//------------------------table-------------------------
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  CapitalizeFirstFn,
  blankValidator,
  showNotificationMsz,
  getFullName,
} from "../../../utils/Validation";

const UpdateStaff = ({ manageInstitution, UserInfo, navigate }) => {
  console.log("manageInstitution", manageInstitution);
  //-------------------------------global id-------------------------
  let institution_id = parseInt(manageInstitution.institution_id);
  const [isloading, setisloading] = useState(false);
  const classes = useStyles();
  const [employeeArr, setEmployeeArr] = useState([]);
  const [managedepartment, setmanagedepartment] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchname, setsearchname] = useState("");
  const [searchID, setsearchID] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const client = useApolloClient();
  useEffect(() => {
    window.scrollTo(0, 0);

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Employee_All_data,
          variables: {
            institution_id,
            department_id: -1,
          },
        })
        .then((response) => {
          setEmployeeArr(response.data.getEmployee.reverse());
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Department_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setmanagedepartment(response.data.getDepartment);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [client, institution_id]);

  const getEmployeeList = (ID) => {
    let department_id = parseInt(ID);
    setEmployeeArr([]);
    if (department_id === -1) {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Employee_All_data,
            variables: {
              institution_id,
              department_id: -1,
            },
          })
          .then((response) => {
            setisloading(false);

            setEmployeeArr(response.data.getEmployee.reverse());
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Employee_All_data,
          variables: {
            institution_id,
            department_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setEmployeeArr(response.data.getEmployee.reverse());
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const filterByName = employeeArr.filter((item) => {
    try {
      if (searchID == "" && searchname == "") {
        return true;
      } else {
        let id = "";
        if (item.login) {
          id = item.login.display_id;
        }
        if (searchID && searchname === "") {
          return (
            item.login.display_id
              .toLowerCase()
              .indexOf(searchID.toLowerCase()) !== -1
          );
        } else if (searchID && searchname) {
          if (searchID) {
            return (
              item.login.display_id
                .toLowerCase()
                .indexOf(searchID.toLowerCase()) !== -1 &&
              getFullName(item.name)
                .toLowerCase()
                .indexOf(searchname.toLowerCase()) !== -1
            );
          } else if (searchname) {
            return (
              getFullName(item.name)
                .toLowerCase()
                .indexOf(searchname.toLowerCase()) !== -1 &&
              item.login.display_id
                .toLowerCase()
                .indexOf(searchID.toLowerCase()) !== -1
            );
          }
        } else if (searchname && searchID === "") {
          return (
            getFullName(item.name)
              .toLowerCase()
              .indexOf(searchname.toLowerCase()) !== -1
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  });

  const changePasswordHandler = async (loginid) => {
    try {
      if (!loginid) {
        showNotificationMsz("Login id is required to reset password", "info");
        return;
      }

      setisloading(true);
      const { data } = await client.query({
        query: Service.Change_User_Password_By_Admin,
        variables: {
          login_id: loginid,
        },
      });

      if (data && data.changePasswordByAdmin) {
        showNotificationMsz(data.changePasswordByAdmin.message, "success");
      }
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  const OpenEditEmployeeData = (staffId) => {
    if (manageInstitution.type === "PT") {
      navigate("/PtTeacherFormDetails", {
        state: {
          pagetype: "edit",
          staffId,
        },
      });
    } else {
      navigate("/adminemployeedetailform", {
        state: {
          pagetype: "edit",
          staffId,
        },
      });
    }
  };

  return (
    <div>
      <Grid className="grid_main_div  p-3">
        <Grid item md={4}>
          <div className="inputMarginright mt-1">
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Search By ID Number"
              autoComplete="off"
              value={searchID}
              onChange={(e) => {
                setsearchID(e.target.value);
              }}
            />
          </div>
        </Grid>

        <Grid item md={4}>
          <div className="inputMarginright mt-1">
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Search By Name"
              autoComplete="off"
              value={searchname}
              onChange={(e) => {
                setsearchname(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="mt-1">
            <select
              className="form-control"
              onChange={(e) => getEmployeeList(e.target.value)}
            >
              <option value={-1}> Select Department</option>
              {managedepartment.length > 0 ? (
                managedepartment.map((item, index) => (
                  <option value={item.id}>{item.name}</option>
                ))
              ) : (
                <option value={-1}>No Data</option>
              )}
            </select>
          </div>
        </Grid>
      </Grid>

      {/* --------------------list of users-------------- */}
      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table_header" align="left">
                  ID
                </StyledTableCell>
                <StyledTableCell className="table_header" align="left">
                  Login ID
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Name
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Phone No
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Department
                </StyledTableCell>

                <StyledTableCell align="left" className="table_header">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filterByName.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filterByName
              ).map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="left">
                    {!blankValidator(row.login) ? (
                      ""
                    ) : (
                      <span>{row.login.display_id}</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {!blankValidator(row.login) ? (
                      ""
                    ) : (
                      <span>{row.login.login_id}</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getFullName(row.name)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.email && row.email}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.phone && `+${row.phone}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.department?.name}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <div className="d-flex ">
                      <span
                        className="row_details_color mr-1"
                        onClick={() => OpenEditEmployeeData(row.id)}
                      >
                        Open
                      </span>
                      |
                      <span
                        className="row_details_color ml-1"
                        onClick={() =>
                          changePasswordHandler(row.login?.login_id)
                        }
                      >
                        Reset Password
                      </span>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filterByName.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* -------------------------list of users---------------------- */}

      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(UpdateStaff);
