import React from "react";
import HOC from "../../Hoc.jsx";

import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
const TermsAndCondition = () => {
  return (
    <div className="main_back_color1">
      <div className="terms_padding_top">
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <div className="enquirydetails_background p-3">
                <div className="text-center">
                  <h2>TERMS AND CONDITIONS</h2>
                </div>
                <div className="terms_padding_both_card mt-2">
                  <div>
                    <p className="terms_para_font">
                      This Website “Site” is owned and operated by EPAL Software
                      Pvt Ltd (referred as “Company” hereafter). Your use of
                      this Site is governed by these terms and conditions
                      (referred as “Terms” hereafter). These Terms constitute a
                      binding Agreement between the Users and the Company.
                      Accessing this Site or using any of the services offered
                      by this Site shall imply that you have read and accepted
                      these Terms before using or accessing this Site or any of
                      the offered services. All Users are advised to read these
                      Terms before accessing the Site or using its services. A
                      User should cease and desist from accessing this Site or
                      using any its services in case, the User does not agree to
                      these terms. Any person interested to access or use this
                      Site or its services, must have devices or equipment
                      necessary to make such access or use such services, like,
                      computer and modem. Users also need access to the world
                      wide web to access the Site or subscribe to the Services.
                      Users may need to pay service fees which may be necessary
                      for making access to the Site its content and services,
                      like internet charges and also pay the cost of any
                      equipment or devices required accessing the Site or using
                      the services.
                    </p>
                  </div>
                  {/* point be start */}
                  <div className="terms_of_heading_data">
                    <h3>1) Definitions</h3>
                    <p className="pt-1">
                      The following words used in these Terms, shall have the
                      meaning provided in this section:
                    </p>
                    <p className="pt-1">
                      “Delivery” means the digital download of the software
                      whose license has been purchased by a User.
                    </p>
                    <p className="pt-1">
                      “Intellectual property” shall refer to all intellectual
                      property including but not limited to copyright,
                      trademark, proprietary rights, moral rights, patents or
                      any other form on intellectual property existing in the
                      Site content.
                    </p>
                    <p className="pt-1">
                      “License” means authorization to use the software and
                      includes any legal documents to be executed or accepted
                      for having such license like the License agreement or
                      service agreement.
                    </p>
                    <p className="pt-1">
                      “Site Content” shall refer to all content provided in the
                      Site and components associated with it, including but not
                      limited to images, text, source code, graphics, display,
                      source code, object code, logos, trademarks, service
                      marks, sound recording, videos, etc. provided in the Site.
                    </p>
                    <p className="pt-1">
                      “Services” shall refer to Software as a Service (“SAAS”) ,
                      offered by the Company to its Users in relation to its
                      software.
                    </p>
                    <p className="pt-1">
                      “Software” shall refer to the School management software
                      designed, developed and owned by the Company and as
                      described in the Site intended to provide School
                      administration and management solutions to organizations.
                    </p>
                    <p className="pt-1">
                      “User” shall refer to any person who accesses this Site or
                      uses any of its services or subscribes or purchases any
                      software license.
                    </p>
                    <p className="pt-1">
                      {" "}
                      “Website” shall refer to [Provide your website's URL].
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>2) Customized software</h3>
                    <p>
                      The Company ordinarily provides a general version of the
                      software which is meant for generic use of all Users. The
                      Company may be able to provide customized versions of the
                      software, designed specifically considering the unique
                      needs of a particular User. The User interested in having
                      customized versions of software can use the form provided
                      in the website and send a query regarding availability of
                      such customized versions. Special rates of
                      subscription/license fee may apply for purchasing
                      subscription plans of such customized versions of
                      software.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>3) Subscription Plan</h3>
                    <p>
                      The Company has different, subscription plans, which can
                      be purchased by a User, considering their own needs and
                      requirements, should they decide to use the software
                      offered by the Company. The currently effective general
                      subscription plans offered by the Company are provided in
                      the Website. Customer can have customized subscription
                      plans as well, by sending their queries using the form
                      given in the Website. The Company can provide customized
                      subscription plans to which special rates of subscription
                      fee apply depending on the specific needs and requirements
                      of the Company. The Company has the right to amend the
                      currently effective subscription plans and rates of fee
                      applicable for purchasing such plans any time without
                      prior notice. The Software can be used only by paying
                      subscription/license fee for purchasing the license to use
                      the Software. The Company can at its sole option provide a
                      free demo to the Customers for using the software.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>4) Services</h3>
                    <p>
                      The services described in the home page of the Site are
                      currently offered at the moment to interested Users. The
                      Company is free to add or remove services or functionality
                      associated with the offered services, from time to time,
                      at its sole discretion. The Site is for information and
                      marketing purposes only providing interested entities,
                      description about the software, which is provided as a
                      Software as a Service (“SAAS”) to intending purchasers and
                      other products of the Company.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>5) Who can use the services</h3>
                    <p>
                      The use of this Site and the services offered through this
                      Site is currently restricted to residents of India only. A
                      user can purchase a license or subscribe to the services
                      by making payment only when they are competent to contract
                      as per laws of India.. Users who are less than 18 years of
                      age or persons of unsound mind or any disability, can use
                      the services of the website provided they have consent
                      from their legal guardian. For corporate entities,
                      authorized signatory of the Company are allowed to enter
                      into agreement on behalf of the Company.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>6) Authorization</h3>
                    <p>
                      Users are hereby authorized and allowed to access the Site
                      content provided through the Site subject to the Terms and
                      conditions provided in these Terms.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>7) Prohibited Activities</h3>
                    <p>
                      No user shall carry out the following activities, while
                      accessing this Site or using any of its services:
                    </p>
                    <ol type="a">
                      <li>
                        Copying the Site Content and using in any matter
                        whatsoever, except without the prior permission of the
                        Company;{" "}
                      </li>
                      <li>
                        Infecting the Site content, databases, computer, servers
                        of the Company with any virus or doing any action which
                        prevent the site to function properly, damages the site
                        content in any manner whatsoever;
                      </li>
                      <li>
                        Carry out any intellectual property infringements by
                        accessing the Site or infringe the proprietary rights of
                        the Company;
                      </li>
                      <li>Defame the Company or its services wrongfully;</li>
                      <li>
                        Tamper with the source code or object code associated
                        with the site or causing any service disruptions
                        associated with Site;
                      </li>
                      <li>
                        Steal any information from the database or servers
                        associated with the Site;
                      </li>
                      <li>
                        {" "}
                        Reproduce, republish, copy or exploit material,
                        duplicate, rent, sub-license, sell, redistribute, edit
                        or modify Site content;
                      </li>
                      <li>
                        Upload or send any content or post, which is
                        pornographic, inaccurate, defamatory, violent, racist,
                        harassing, obscene or harmful;
                      </li>
                      <li>
                        Misrepresent connecting to any entity to which the User
                        is not connected;{" "}
                      </li>
                      <li>
                        Carry out any cyber crime or cyber attack by involving
                        the Site or its components;
                      </li>
                      <li>
                        Provide false information or commit identity theft or
                        financial fraud or any other cyber crime or criminal
                        activity, while accessing, using, providing information
                        to the Site or its services;
                      </li>
                      <li>Carrying out any illegal activity via the Site.</li>
                    </ol>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>8) User Information</h3>
                    <p>
                      All user shall provide accurate and correct information
                      when they are submitting any information through the Site.
                      User shall remain solely responsible for the consequences
                      of wrong information provided through the Site.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>
                      9) Online video classes and video communication services
                    </h3>
                    <p>
                      The site may have video communication services for the
                      purpose of facilitating online video classes. All users
                      who attend online video classes by using the video
                      communication services must maintain decorum, have decent
                      behavior and should not engage in any activity which has
                      the effect of intimidating any other user or cause
                      disturbance or annoyance to any other user, including but
                      not limited to promoting nuidity, displaying pornographic
                      content, carry out verbal abuse, insult or discriminate
                      against any race or religion, or community. The video
                      communication services shall be used for educational
                      purposes only. Users shall not carry out any intellectual
                      property infringement by using the video communication
                      service. Company shall not be held liable for any
                      violations made by any user of the terms provided in this
                      clause. Company may at its sole discretion suspend the
                      access of any user to the services of the site, in case a
                      User breaches the conditions provided in this clause.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>10) Suspension and Termination</h3>
                    <p>
                      In case a User carries out any prohibited activity through
                      the Site or carries out any other activity in breach of
                      these terms, the Company shall have the sole discretion to
                      suspend access to the Site content or suspend or terminate
                      any active contract of service with the concerned User,
                      either temporarily or permanently. The Company may ask for
                      explanation or ask the User to carry out any activity in
                      order to mitigate the consequences of any illegal
                      activity. In case the Company terminates an active
                      contract, the Company shall duly notify the User by
                      sending a notice, as per the contact information,
                      submitted by the User at the time of purchase of license.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>11) Intellectual Property ownership</h3>
                    <p>
                      The ownership all intellectual property in the Site
                      content shall be solely and exclusively that of the
                      Company. The Company does not in any way transfer any
                      rights associated with the intellectual property in the
                      Site Content by allowing Users to access the Site content
                      via the Site.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>12) Purchase policy</h3>
                    <p>
                      No User can directly purchase a license to the software,
                      from the Site itself. All purchases has to be carried out
                      by making payment through the link or payment option
                      provided by the authorized representative of the Company.
                      User interested to purchase a license should contact the
                      authorized representative of the Company by using the form
                      provided in the Site. Users may be required to execute or
                      agree to additional agreements like service agreements and
                      License agreements, in order to purchase a license for
                      using specific services offered through this Site. User
                      shall be required to comply with the terms associated with
                      such additional agreements when a User makes purchase of a
                      license. User shall be liable to pay applicable taxes
                      including GST applicable on the purchase of software
                      provided by the Company.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>13) Delivery Policy</h3>
                    <p>
                      The Users will not be able to download any software or
                      apps from the Site itself. All downloads shall be made
                      available through personal links provided by the Company
                      and sent to the User, after a User makes payment of the
                      license Fee in advance. Users shall install the software
                      in their computer after successful download from the
                      provided links. No physical delivery of software to
                      components like CD-ROM or pen drive is currently
                      available.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>14) Warranty Disclaimer</h3>
                    <p>
                      THE SITE CONTENT IS PROVIDED ON AS IS BASIS AND PROVIDED
                      FOR INFORMATION PURPOSES ONLY. THE COMPANY DOES NOT
                      PROVIDE ANY WARRANTY OR REPRESENTATION, WHETHER IMPLIEDLY
                      OR EXPRESSLY THAT THE SERVICES PROVIDED THROUGH THE
                      WEBSITE SHALL BE AVAILABLE AT ALL TIMES OR THE DESCRIPTION
                      PROVIDED IN THE WEBSITE OF THE SERVICES OR ANY OTHER
                      MATTER, IS ACCURATE OR FREE FROM DEFECTS AND ERRORS OR IS
                      UPDATED. USER WHO INTEND TO SUBSCRIBE TO THE SERVICES
                      OFFERED, SHOULD READ ADDITIONAL DOCUMENTS LIKE SERVICE
                      AGREEMENTS AND LICENSE AGREEMENTS, CAREFULLY TO UNDERSTAND
                      THEIR RIGHTS, LIABILITIES AND IMPLICATIONS, ASSOCIATED
                      WITH USE OF THE SERVICES DESCRIBED IN THE SITE. THE SITE
                      MAY NOT BE FREE FROM TECHNICAL AND/OR TYPOGRAPHICAL
                      ERRORS. NONE OF THE INFORMATION IN THE SITE, INCLUDING
                      THIRD PARTY CONTENT, HAS BEEN VERIFIED OR AUTHENTICATED.
                      ANY ERRORS IN INFORMATION PRESENT IN THE SITE MAY NOT BE
                      CORRECTED. YOU MAY USE THE SITE AT YOUR SOLE RISK.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>15) Limitation of Liability</h3>
                    <p>
                      COMPANY PROVIDES THE ACCESS TO THE SITE AND SITE CONTENT
                      OF THE WEBSITE FREE OF CHARGE AND WITHOUT CONSIDERATION
                      SOLELY FOR MARKETING AND INFORMATION PURPOSES. COMPANY IS
                      NOT LIABLE TO THE USER FOR ANY DEFICIENCY OR DISCREPANCY
                      ASSOCIATED WITH THE SITE OR SITE CONTENT OR IN CASE THE
                      CONTENT HARMS THE USER IN ANYWAY. USER HAS SOLE DISCRETION
                      WHETHER TO ACCESS THE SITE OR ITS CONTENT CAN REFRAIN FROM
                      USING OR ACCESSING THE SITE AT THEIR OWN DISCRETION.
                      COMPANY IS NOT LIABLE FOR ANY ILLEGAL ACTIVITY CARRIED OUT
                      BY ANY THIRD PARTY THROUGH THE SITE AND LOSSES ACCRUING TO
                      ANY USER AS A CONSEQUENCE THEREOF, INCLUDING BUT NOT
                      LIMITED TO, LOSSES DUE TO, DATA THEFT, VIRUS, DAMAGE TO
                      SOFTWARE OR HARDWARE COMPONENT OF ANY USER, INTELLECTUAL
                      PROPERTY INFRINGEMENTS, LOSS OR MONEY, FINANCIAL OR OTHER
                      LOSSES, ETC.
                    </p>
                  </div>

                  <div className="terms_of_heading_data">
                    <h3>16) Availability of the Site</h3>
                    <p>
                      The Company shall make best endeavours to make the
                      services associated with this Site available at all times,
                      however the Site may not available in case there is any
                      planned downtime due to maintenance or unavailability of
                      this Site, due the circumstances beyond control of the
                      Company, including but not limited to failure of internet
                      service provider, attacks to bring the service down or
                      technical errors or act of god. User hereby consents to
                      the same and has no objection in case such instances take
                      place. The User shall not hold the company liable for such
                      instances or consequences therof.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>17) Consent to be Contacted and informed</h3>
                    <p>
                      Users who submit their contact details through the Site,
                      hereby consent that they may be contacted on behalf of the
                      Company regarding any services offered by the Company or
                      any new additions or alteration in the software or service
                      provided by the Company from time to time. Users who have
                      purchased a license associated with the software also
                      consent to be informed of any developments associated with
                      the Services or software offered through the Site,
                      including modifications in the same and terms and
                      conditions associated with such services/software. The
                      Company may also contact Users who have actually used the
                      software or services of the Company, for any survey or
                      feedback to improve the services and software offered by
                      the Company. User who do not wish to be contacted may opt
                      out of the same by using email opt out option or sending
                      an email to the email id contact@epalsoftware.com.
                    </p>
                  </div>

                  <div className="terms_of_heading_data">
                    <h3>18) Confidential information</h3>
                    <p>
                      The Company does not wish to accept confidential
                      information through the Site. Any information which is
                      provided through the Site and delivered to the authorized
                      representative of the Company, shall be held as
                      non-confidential.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>19) Third Party services </h3>
                    <p>
                      There may be third party service providers, which
                      facilitate and support, the services offered through the
                      Site, which does not have any connection with the Company
                      and links to such third party services providers may be
                      given in the site or by authorized representatives of
                      Company. The Company does not have any control over such
                      Sites or service providers. Users are requested to
                      properly inspect such third party sites/service providers
                      on their own and verify them before using their services.
                      The Company shall not be held liable for any harm or
                      damages which may be incurred to the User while using the
                      services of such third party sites and service providers.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>20) Privacy Policy</h3>
                    <p>
                      The use of any information or data provided by the Users
                      to the Company shall be governed by the privacy policy
                      which shall also be held as a binding agreement between
                      the User and the Company.{" "}
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>21) Amendment</h3>
                    <p>
                      These Terms can be amended or changed, by giving notice to
                      the Users. Changing the Terms and putting the amended
                      Terms in the website shall be considered sufficient notice
                      to the Users. The Users are advised to visit this page
                      from time to time to stay updated and notified regarding
                      any changes to the Terms. The Company reserves the right
                      to amend the Terms at any time. The Company may at its
                      discretion inform the Users by email of any changes made
                      to this terms or provide a notification about the same at
                      any conspicuous place in the website. Your use of this
                      website or the software or any other products provided by
                      the Company, after any change or amendment to this Terms,
                      shall mean that you have accepted such change
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>22) Notice</h3>
                    <p>
                      For any complaint associated with the use of the Site, or
                      its associated services, or any legal query, Users can
                      send the notice to the following email id -
                      contact@epalsoftware.com For hard copy correspondence the
                      User can send the correspondence at the following Address:
                    </p>
                    <p>
                      No. 334, 4th Floor, 27th Main Road, 2nd Sector, HSR
                      Layout, Bangalore Karnataka 560102
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>23) Dispute Resolution</h3>
                    <p>
                      The access and use of this Site shall be governed by the
                      laws of India. Any dispute arising out of these terms
                      shall be decided through negotiation and amicable
                      settlement. In case the dispute cannot be resolved through
                      negotiation and amicable settlement, the dispute shall be
                      resolved through sole arbitrator mutually appointed by the
                      concerned User and the Company. The place of arbitration
                      shall be Bangalore India and the user exclusively agrees
                      to submit to such jurisdiction and place of arbitration.
                      This arbitration agreement does not take away the right of
                      the Company to pursue any action at any other forum
                      including court of law, in matters which cannot be
                      resolved through an arbitral tribunal or where additional
                      assistance is required apart from assistance provided by
                      the arbitral tribunal, which cannot otherwise be provided
                      by the arbitral tribunal.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>24) General Terms</h3>
                    <p>
                      The User shall comply with all applicable laws,
                      regulations of India at all times, so far relevant in
                      accessing the Site and using its services.
                    </p>
                    <p>
                      A failure or delay in exercising any right, power or
                      privilege in respect of this Agreement will not be
                      presumed to operate as a waiver If any provision or
                      provisions of these Terms shall be held unenforceable for
                      any reason, then all remaining provisions of this Terms
                      shall remain in full force and effect for the duration of
                      this Agreement.
                    </p>
                    <p>
                      The terms and conditions specified in these Terms shall
                      not prevent the Company to comply with any requests from
                      governmental authorities, which may be in relations to any
                      User or his activity while using this Site or to comply
                      with any applicable laws.
                    </p>
                  </div>
                  <div className="terms_of_heading_data">
                    <h3>25) Contact Us</h3>
                    <p>
                      Users may contact us at contact@epalsoftware.com for any
                      general query associated with the Site.{" "}
                    </p>
                  </div>

                  {/* point be end */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default connect(null, null)((HOC(TermsAndCondition)));
