import { Store } from "react-notifications-component";
const parse = require("html-react-parser");
// if the input is blank this fn returns true or false
export const blankValidator = (validators, key) => {
  if (
    validators === undefined ||
    validators === "" ||
    validators === null ||
    validators == {} ||
    validators === [] ||
    validators == -1 ||
    validators == NaN
  ) {
    return false;
  }
  return true;
};
export const LinkValidator = (validators) => {
  let LinkRegEx =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  if (!LinkRegEx.test(validators)) {
    return false;
  }
  return true;
};

// if the email is not valid  this fn returns  false
export const emailValidator = (email, warning) => {
  let emailRegEx =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegEx.test(email)) {
    return false;
  }
  return true;
};

export const pad = (n) => {
  return n < 10 ? "0" + n : n;
};
export const newDateToDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let actualDate = `${year}-${pad(month)}-${pad(day)}`;
  return actualDate;
};
export const dateFormatToShow = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let actualDate = `${pad(day)}/${pad(month)}/${year}`;
  return actualDate;
};

// to make first letter capital
export const CapitalizeFirstFn = (string) => {
  if (string == null || string == "") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// to make first letter capital
export const getFullName = (nameObj) => {
  if (!blankValidator(nameObj)) {
    return "";
  }
  let name = "";
  if (blankValidator(nameObj.first_name)) {
    name = nameObj.first_name;
  }
  if (blankValidator(nameObj.middle_name)) {
    name = name + " " + nameObj.middle_name;
  }
  if (blankValidator(nameObj.last_name)) {
    name = name + " " + nameObj.last_name;
  }
  return name;
};
export const getFullAddress = (addressObj) => {
  if (!blankValidator(addressObj)) {
    return "";
  }
  let address = "";
  if (blankValidator(addressObj.line1)) {
    address = addressObj.line1;
  }
  if (blankValidator(addressObj.line2)) {
    address = address + " " + addressObj.line2;
  }
  if (blankValidator(addressObj.city)) {
    address = address + " " + addressObj.city;
  }
  if (blankValidator(addressObj.state)) {
    address = address + " " + addressObj.state;
  }
  if (blankValidator(addressObj.country)) {
    address = address + " " + addressObj.country;
  }
  if (blankValidator(addressObj.zip_code)) {
    address = address + " " + addressObj.zip_code;
  }
  return address;
};
// to make first letter capital
export const getValue = (email) => {
  if (!blankValidator(email)) {
    return "";
  }
  return email;
};
export const getIntValue = (value) => {
  if (!blankValidator(value)) {
    return -1;
  }
  let temp = parseInt(value);
  if (isNaN(temp)) {
    return -1;
  }
  return temp;
};

// timestamp to date convertor---------------------
export const timeStampToDate = (date) => {
  if (!blankValidator(date)) return "";
  let parseDate = parseInt(date);
  let dateInTimeStamp = new Date(parseDate);
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  let actualDate = `${pad(day)}/${pad(month)}/${year}`;
  return actualDate;
};

// timestamp to date convertor---------------------
export const timeStampToDatedash = (date) => {
  let parseDate = parseInt(date);
  let dateInTimeStamp = new Date(parseDate);
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  let actualDate = `${year}-${pad(month)}-${pad(day)}`;
  return actualDate;
};
// timestamp to date convertor---------------------
export const timeStampToDatedashtoDDMMYY = (date) => {
  if (!blankValidator(date)) {
    return "";
  }
  let parseDate = parseInt(date);
  let dateInTimeStamp = new Date(parseDate);
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  let actualDate = `${day}/${pad(month)}/${pad(year)}`;
  return actualDate;
};

// timestamp to date convertor---------------------
export const dateToDatedashtoDDMMYY = (date) => {
  if (!blankValidator(date)) return "";
  let dateInTimeStamp = new Date(date);
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  let actualDate = `${day}/${pad(month)}/${pad(year)}`;
  return actualDate;
};
// timestamp to date convertor---------------------
export const timeStampToDatedashDayStart = (date) => {
  if (!blankValidator(date)) return "";
  let parseDate = parseInt(date);
  let dateInTimeStamp = new Date(parseDate);
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  let actualDate = `${pad(day)}/${pad(month)}/${year}`;
  return actualDate;
};

export const currentDayForMinMAx = () => {
  let d = new Date();
  let day = d.getDate();
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  if (day < 10) {
    day = "0" + day.toString();
  }
  if (month < 10) {
    month = "0" + month.toString();
  }
  let dfulldateyesterday = year + "-" + month + "-" + day;
  return dfulldateyesterday;
};

export const currentdateForValidation = (d) => {
  let day = d.getDate();
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  if (day < 10) {
    day = "0" + day.toString();
  }
  if (month < 10) {
    month = "0" + month.toString();
  }
  let hours = pad(d.getHours());
  let min = pad(d.getMinutes());

  let dfulldateyesterday =
    year + "-" + month + "-" + day + "T" + hours + ":" + min;
  return dfulldateyesterday;
};
// to send date format on server
export const serverFormatDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let actualDate = `${year}-${pad(month)}-${pad(day)}`;
  return actualDate;
};
// to send date format on server
export const serverDateFormat = (date) => {
  if (!blankValidator(date)) {
    return "";
  } else {
    let dateObj = new Date(date);
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let year = dateObj.getFullYear();
    let actualDate = `${pad(day)}/${pad(month)}/${year}`;
    return actualDate;
  }
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const getMonthFormat = (date) => {
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();
  return month + " " + year;
};
const weekArr = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const timeWithDateFormat = (date) => {
  // let timeStamp = parseInt(date)
  if (!blankValidator) return "";
  let dateInTimeStamp = new Date(date);
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  let actualDate = `${pad(day)}/${pad(month)}/${year} (${
    weekArr[dateInTimeStamp.getDay()]
  })`;
  return actualDate;
};

export const GetAMPM = (val) => {
  console.log("val ", val);
  let hours = val.substring(0, 2);
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  console.log(hours);
  hours = hours ? hours : 12; // the hour '0' should be '12'
  var c = `${val} ${ampm}`;
  return c;
};

export const isPresent = (currentDate, AttendanceArr) => {
  if (!blankValidator(AttendanceArr)) {
    return false;
  }
  let isAvailable = "";
  if (AttendanceArr || AttendanceArr.length > 0) {
    AttendanceArr.map((item) => {
      if (item.date === currentDate) {
        return (isAvailable = item.status);
      }
    });
  }

  return isAvailable;
};
export const yearAttenMethod = (month, AttendanceArr) => {
  let isAvailable = false;
  AttendanceArr.map((item) => {
    let dateMonthWise = item.date.substring(0, 7);
    if (dateMonthWise === month) {
      if (item.status === "Present") return (isAvailable = true);
    }
  });

  return isAvailable;
};

export const get_Meeting_join_ling = (
  mettingID,
  user_Name,
  password,
  userType,
  moderatePaasword
) => {
  console.log("userType", userType);
  let view = "VIEWER";
  let userPassword = password;
  if (userType === "ADMIN" || userType === "TEACHER" || userType === "Admin" || userType === "Teacher") {
    view = "MODERATOR";
    userPassword = moderatePaasword;
  }
  let secret_key = "eyMvm822VdW6uRt5dDbzK5JFxrYGydW7n8Y9FNvZXY";
  let url =
    "fullName=" +
    user_Name.toLowerCase().replace(/ +/g, "_") +
    "&meetingID=" +
    mettingID +
    "&password=" +
    userPassword +
    "&role=" +
    view;
  console.log("url", url);
  var sha1 = require("sha1");
  let checkSum = sha1("join" + url + secret_key);
  let urldone =
    "https://bb.epalsoftware.in/bigbluebutton/api/join?" +
    url +
    "&checksum=" +
    checkSum;
  return urldone;
};

export const monthPresentCalculator = (year, month, arr) => {
  if (arr == undefined) return;
  let date = new Date(year, month);
  let fullYear = date.getFullYear();
  let fullMonth = date.getMonth() + 1;
  let dateToCompare = `${fullYear}-${pad(fullMonth)}`;
  let count = 0;
  arr.map((item) => {
    let dateMonthWise = item.date.substring(0, 7);
    if (dateMonthWise === dateToCompare) {
      if (item.status === "Present") {
        count++;
      }
    }
  });
  return count;
};
// show notification msz
export const showNotificationMsz = (message, messageType) => {
  Store.addNotification({
    title: "",
    message: `${message}`,
    type: `${messageType}`,
    insert: "top-center",
    container: "top-center",
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};
export const subStringTime = (time) => {
  let modifiedSubString = time.substring(0, 5);
  return modifiedSubString;
};
// change time to AM /PM
export const changeTimeFormat = (time) => {
  try {
    let modTime = time.substring(0, 5);
    let timeArr = modTime.split(":");
    let hour = timeArr[0];
    let minute = timeArr[1];
    let hours = parseInt(hour);
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var formattedTime = `${pad(hours)}: ${minute} ${ampm}`;
    return formattedTime;
  } catch (error) {
    return time;
  }
};

export const dayPresentCounter = (arr) => {
  let count = 0;
  arr.filter((item) => {
    if (item.status === "Present") {
      return count++;
    }
  });
  return count;
};
export const dayPresentCounterForReport = (arr) => {
  let count = 0;
  arr.filter((item) => {
    if (item?.attendance?.status === "Present") {
      return count++;
    }
  });
  return count;
};

export const dayPresentCounterMonthWise = (arr, month) => {
  console.log("arr ", arr);
  console.log("month ", month);

  let count = 0;
  for (let index = 0; index < arr.length; index++) {
    const item = arr[index];
    let dateMonthWise = item.date.substring(0, 7);
    if (item.status === "Present" && dateMonthWise === month) {
      count++;
    }
  }

  return count;
};

export const options = {
  unit: "in",
  format: [20, 10],
};
export const dateObjectMaker = (date) => {
  try {
    let startTime = date.start_time.substring(0, 5);
    let endTime = date.end_time.substring(0, 5);
    let st = new Date(`Sep 16, 08 ${startTime}`);
    let et = new Date(`Sep 16, 08 ${endTime}`);
    return { st, et };
  } catch (error) {
    return {};
  }
};

export const dateToDateFormat = (date) => {
  let dateWithCommonFormat = "";
  if (date) {
    let dateArr = date.split("-");
    return (dateWithCommonFormat = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
  } else {
    return dateWithCommonFormat;
  }
};

export const getNumberOfHoursByday = (start, end, hours) => {
  let date1 = new Date(start);
  let date2 = new Date(end);
  // One day in milliseconds
  let oneDay = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  let diffInTime = date2.getTime() - date1.getTime();
  // Calculating the no. of days between two dates
  let diffInDays = (Math.round(diffInTime / oneDay) + 1) * hours;
  return diffInDays;
};
export const difference_of_days = (start, end) => {
  let date1 = new Date(start);
  let date2 = new Date(end);
  // One day in milliseconds
  let oneDay = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  let diffInTime = date2.getTime() - date1.getTime();
  // Calculating the no. of days between two dates
  let diffInDays = Math.round(diffInTime / oneDay) + 1;
  return diffInDays;
};

export const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;

  return [hours, minutes]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const getTimeDIff = (endDate, startDate) => {
  try {
    let d1 = new Date(parseInt(endDate));
    let d3 = new Date(parseInt(startDate));

    let d2 = d1 - d3;

    return toHHMMSS(d2 / 1000);
  } catch (error) {
    return "00:00";
  }
};

export const getimeFormatForEmail = (date) => {
  try {
    return timeStampToDateandTime(date);
  } catch (error) {
    console.log(error);
  }
};
// timestamp to date convertor---------------------
export const timeStampToDateandTime = (date) => {
  if (!blankValidator(date)) return "";
  let parseDate = parseInt(date);
  let starttimeset = new Date();
  let dateInTimeStamp = new Date(
    parseDate + starttimeset.getTimezoneOffset() * 60000
  );
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  var hours = dateInTimeStamp.getHours();
  var minutes = dateInTimeStamp.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  let actualDate = `${pad(day)}/${pad(month)}/${year} ${strTime}`;
  return actualDate;
};
// timestamp to time convertor---------------------
export const timeStampToTimeConvertor = (date) => {
  if (!blankValidator(date)) return "";
  let parseDate = parseInt(date);
  let starttimeset = new Date();
  let dateInTimeStamp = new Date(
    parseDate + starttimeset.getTimezoneOffset() * 60000
  );
  var hours = dateInTimeStamp.getHours();
  var minutes = dateInTimeStamp.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  let actualDate = ` ${strTime}`;
  return actualDate;
};
export const timeStampDateToOnlytime = (date) => {
  if (!blankValidator(date)) return "";
  let parseDate = parseInt(date);
  let starttimeset = new Date();
  let dateInTimeStamp = new Date(
    parseDate + starttimeset.getTimezoneOffset() * 60000
  );

  var hours = dateInTimeStamp.getHours();
  var minutes = dateInTimeStamp.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  let actualTime = `${strTime}`;
  return actualTime;
};

export const timeStampDateToOnlytimewithoutTimeDiff = (date) => {
  if (!blankValidator(date)) return "";
  let parseDate = parseInt(date);

  let dateInTimeStamp = new Date(parseDate);

  var hours = dateInTimeStamp.getHours();
  var minutes = dateInTimeStamp.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  let actualTime = `${strTime}`;
  return actualTime;
};
export const getcurrentDate = (date) => {
  let parseDate = parseInt(date);
  let starttimeset = new Date();
  return new Date(parseDate + starttimeset.getTimezoneOffset() * 60000);
};

export const getcurrentDatewithoutTimezone = (date) => {
  let parseDate = parseInt(date);
  return new Date(parseDate);
};
export const getcurrentDatewithDate = (date) => {
  if (date) {
    let parseDate = date;
    let starttimeset = new Date();
    return new Date(parseDate + starttimeset.getTimezoneOffset() * 60000);
  }
  {
    return new Date();
  }
};

export const timeStampToDateandTimewithT = (date) => {
  let parseDate = parseInt(date);
  let starttimeset = new Date();

  let dateInTimeStamp = new Date(
    parseDate + starttimeset.getTimezoneOffset() * 60000
  );
  let day = dateInTimeStamp.getDate();
  let month = dateInTimeStamp.getMonth() + 1;
  let year = dateInTimeStamp.getFullYear();
  var hours = dateInTimeStamp.getHours();
  var minutes = dateInTimeStamp.getMinutes();
  var strTime = pad(hours) + ":" + pad(minutes);
  let actualDate = `${year}-${pad(month)}-${pad(day)}T${strTime}`;
  return actualDate;
};

export const getHHMMSStoDate = (timeString) => {
  try {
    let d = new Date(); // Creates a Date Object using the clients current time

    let [hours, minutes, seconds] = timeString.split(":"); // Using ES6 destructuring
    // var time = "18:19:02".split(':'); // "Old" ES5 version

    d.setHours(+hours); // Set the hours, using implicit type coercion
    d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
    d.setSeconds(seconds);
    return d;
  } catch (error) {
    return new Date();
  }
};

export const mulitpleAnswerHandler = (str) => {
  if (!str) {
    return "";
  }
  let mstr = str.split("&&");
  if (mstr) {
    return mstr.join();
  } else {
    return "";
  }
};

export const mulitpleAnswerHandlerforCorrectAnswer = (answerAray) => {
  if (!answerAray) {
    return "";
  }
  let temp = [];
  answerAray.map((item) => {
    if (item.is_answer == 1) {
      temp.push(item.choice);
    }
  });

  if (temp) {
    return temp.join();
  } else {
    return "";
  }
};

export const getMinAndSecfromDate = (date) => {
  let mydate = new Date(date);
  if (date) {
    var hours = mydate.getHours();
    var minutes = mydate.getMinutes();
    return pad(hours) + ":" + pad(minutes);
  } else {
    return "00:00";
  }
};
export const getSubStringValue = (val) => {
  if (blankValidator(val)) {
    if (val.length > 30) {
      return val.substring(0, 30) + "...";
    } else {
      return val;
    }
  } else {
    return "";
  }
};

export const smart_substr = (str, len) => {
  let val = parse.default(str);
  return val;
};
export const holidayStringHandler = (arr) => {
  if (!blankValidator(arr) || arr.length === 0) {
    return "";
  }
  let temp = [];
  arr.map((item) => {
    temp.push(item?.name);
  });
  if (temp) {
    return temp.join(" , ");
  } else {
    return "";
  }
};

export const getFullNamefromValue = (first_name, middle_name, last_name) => {
  let fullName = "";
  try {
    if (first_name) {
      fullName = first_name;
    }
    if (middle_name) {
      fullName = fullName + " " + middle_name;
    }
    if (last_name) {
      fullName = fullName + " " + last_name;
    }
  } catch (error) {
    console.log("Error in getting full name");
  }
  return fullName;
};

export const loadComponent = (src) => {
  try {
    if (src) {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.type = "application/javascript";
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
        console.log("ssss", script);
        return script;
      });
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const onScriptLoad = () => {
  try {
    var config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId: "" /* update order id */,
        token: "" /* update token value */,
        tokenType: "TXN_TOKEN",
        amount: "" /* update amount */,
      },
      handler: {
        notifyMerchant: function (eventName, data) {
          console.log("notifyMerchant handler function called");
          console.log("eventName => ", eventName);
          console.log("data => ", data);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            // after successfully updating configuration, invoke JS Checkout
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            console.log("error => ", error);
          });
      });
    }
  } catch (error) {
    throw error;
  }
};
export const getTime = (dateTime) => {
  if (!dateTime) {
    return " - ";
  }
  const time = new Date(+dateTime);
  //method to convert as am/pm as per visuals
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const regex = /(<([^>]+)>)/gi;

export const createDateTime = (date) => {
  if (date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let min = date.getMinutes();
    return `${year}-${pad(month)}-${pad(day)}T${pad(hours)}:${pad(min)}`;
  }
};
export const getWeekDay = (day, time) => {
  let dayTime = "";
  if (day === "0") {
    dayTime = "Sunday" + " " + time;
  }
  if (day === "1") {
    dayTime = "Monday" + " " + time;
  }
  if (day === "2") {
    dayTime = "Tuesday" + " " + time;
  }
  if (day === "3") {
    dayTime = "Wednesday" + " " + time;
  }

  if (day === "4") {
    dayTime = "Thursday" + " " + time;
  }
  if (day === "5") {
    dayTime = "Friday" + " " + time;
  }
  if (day === "6") {
    dayTime = "Saturday" + " " + time;
  }
  return dayTime;
};
