import React, { useState, useEffect } from "react";
// import "./ParentHomePage.css";
import HOC1 from "../../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { CapitalizeFirstFn, blankValidator } from "../../../utils/Validation";

const StudentProfile = ({ data, navigate, dataacademic }) => {
  //-------------------GLOBAL ID------------------------

  let father_id = parseInt(data.userid);
  let institution_id = parseInt(data.institution_id);
  //Institute name
  let institution_Name = data.institution_name;
  //-------------------API CALLS------------------------
  const client = useApolloClient();
  //-------------------LOCAL STATE------------------------
  const [academicSession, setAcademicSession] = useState("");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [ParentHomeData, setParentHomeData] = useState([]);
  const [admissionFormArr, setadmissionFormArr] = useState([]);
  const [UpcomingEvents, setUpcomingEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [schoolnoticedata, setSchoolnoticedata] = useState([]);
  const [isloading, setisloading] = useState(false);
  //-------------------METHODS------------------------
  useEffect(() => {
    client
      .query({
        query: Service.Get_Academic_session,
        variables: {
          institution_id,
        },
      })
      .then((response) => {
        setAcademicSession(response.data.getAcademicSession.session_name);
        setisloading(false);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });

    getStudentAdmissionData();
    const getPraentStudentData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Parent_student_data,
            variables: {
              institution_id,
              father_id,
              status: "ACTIVE",
            },
          })
          .then((response) => {
            if (response.data.getStudentByParent.length > 0) {
              setParentHomeData(response.data.getStudentByParent);
            }

            setisloading(false);
          })
          .catch((error) => {
            console.log("exam catch error", error);
          });
      } catch (error) {
        console.log("exam t and c error", error);
      }
    };

    const get_SchoolNotice_Data = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_School_Notice_data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            let temp = [];

            for (
              let index = 0;
              index < response.data.getNoticeBoardByInstitutionId.length;
              index++
            ) {
              const element =
                response.data.getNoticeBoardByInstitutionId[index];
              let date1 = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date());

              let date2 = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(element.expiry_date);

              let Publish_Date = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(element.publish_date);

              if (date1 >= Publish_Date) {
                temp.push({
                  id: element.id,
                  title: element.title,
                  subject: element.subject,
                  publish_date: element.publish_date,
                  expiry_date: element.expiry_date,
                  admin: element.admin,
                  status: date1 > date2 ? "EXPIRED" : element.status,
                  content: element.content,
                  documents: element.documents,
                });
              }
            }
            setSchoolnoticedata(temp);
            setisloading(false);
          })
          .catch((err) => {
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        console.log("parent homepage t and c error", error);
      }
    };

    const UpCpming_Event_Data = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Upcoming_Events,
            variables: {
              institution_id,
              user_id: parseInt(data.userid),
              user_type: data.usertype,
              academic_session_id: parseInt(dataacademic.id),
            },
          })
          .then((response) => {
            setisloading(false);
            setUpcomingEvents(response.data.getEvent);
          })
          .catch((err) => {
            console.log("parent homepage catch err", err);
          });
      } catch (error) {
        console.log("parent homepage t and c error", error);
      }
    };
    getPraentStudentData();
    get_SchoolNotice_Data();
    UpCpming_Event_Data();
  }, [client, institution_id, father_id]);

  const getStudentAdmissionData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Parent_student_data,
          variables: {
            institution_id,
            father_id,
            status: "",
          },
        })
        .then((response) => {
          if (response.data.getStudentByParent.length > 0) {
            setadmissionFormArr(response.data.getStudentByParent);
          }
          setisloading(false);
        })
        .catch((err) => {
          console.log("exam catch err", err);
        });
    } catch (error) {
      console.log("exam t and c error", error);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const filterUpcomingEvent = UpcomingEvents.filter((event) => {
  //   if (event === null) {
  //     return false;
  //   }
  //   return event.event_name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  // });

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <Grid className="grid_main_div paddding_form_top">
                <Grid item md={12} sm={12}>
                  <div className="enquirydetails_background  mb-2">
                    <div className="card_margin_both_side">
                      <Card className="mt-3">
                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin">
                            <div className="table_foramtitng mt-2 mb-1">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                  id="parent_homepage_table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Roll
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Class
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Section
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="center"
                                        className="table_header table_Parent_home_padding"
                                      >
                                        Action
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? ParentHomeData.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : ParentHomeData
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell
                                          scope="row"
                                          align="left"
                                        >
                                          {!blankValidator(
                                            row.student.roll_number
                                          ) ? (
                                            " "
                                          ) : (
                                            <span>
                                              {row.student.roll_number}
                                            </span>
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {!blankValidator(
                                            row.name.first_name
                                          ) ? (
                                            " "
                                          ) : (
                                            <span>
                                              {CapitalizeFirstFn(
                                                row.name.first_name
                                              )}
                                            </span>
                                          )}{" "}
                                          {!blankValidator(
                                            row.name.middle_name
                                          ) ? (
                                            " "
                                          ) : (
                                            <span>
                                              {CapitalizeFirstFn(
                                                row.name.middle_name
                                              )}
                                            </span>
                                          )}{" "}
                                          {!blankValidator(
                                            row.name.last_name
                                          ) ? (
                                            " "
                                          ) : (
                                            <span>
                                              {CapitalizeFirstFn(
                                                row.name.last_name
                                              )}
                                            </span>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(
                                            row.student.section
                                          ) ? (
                                            ""
                                          ) : (
                                            <div>
                                              {!blankValidator(
                                                row.student.section.grade
                                              ) ? (
                                                ""
                                              ) : (
                                                <span>
                                                  {!blankValidator(
                                                    row.student.section.grade
                                                      .gradeName
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <span>
                                                      {
                                                        row.student.section
                                                          .grade.gradeName
                                                      }
                                                    </span>
                                                  )}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {!blankValidator(
                                            row.student.section
                                          ) ? (
                                            ""
                                          ) : (
                                            <div>
                                              {row.student.section.name}
                                            </div>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          <div className="d-flex justify-content-between">
                                            <span
                                              className="row_details_color"
                                              onClick={() =>
                                                navigate("/studentDetailForm", {
                                                  state: {
                                                    studentId: row.student.id,
                                                    institution_name:
                                                      data.institution_name,
                                                    academic_session_id:
                                                      parseInt(dataacademic.id),
                                                    type: "STUDENT",
                                                    institution_id:
                                                      data.institution_id,
                                                  },
                                                })
                                              }
                                            >
                                              View Profile
                                            </span>
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={ParentHomeData.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(StudentProfile));
