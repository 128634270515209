import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import {
  getFullName,
  showNotificationMsz,
  blankValidator,
} from "../../../../utils/Validation";
import Loder from "../../../Loder/Loder";
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { useMutation } from "@apollo/client";
const ParentInviteList = (props) => {
  let event_id = props.id;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [classlistArray, setClasslistArray] = useState([]);

  useEffect(() => {
    getEventData();
  }, [props]);
  const getEventData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Event_Participants,
          variables: {
            event_id,
            user_type: "PARENT",
          },
        })
        .then((response) => {
          if (blankValidator(response.data.getEventParticipants)) {
            {
              const sortedList = response.data.getEventParticipants.sort(
                (a, b) => {
                  if (
                    blankValidator(a.parent?.name) &&
                    blankValidator(b.parent?.name)
                  )
                    return getFullName(a.parent?.name)
                      .toLowerCase()
                      .localeCompare(getFullName(b.parent?.name).toLowerCase());
                }
              );

              setClasslistArray(sortedList);
            }
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const [eventID, setEventID] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [delete_Event_Participants, {}] = useMutation(
    Service.delete_Event_Participants
  );
  const deleteEventDialogOpen = (row) => {
    setEventID(row.id);
    setDeleteDialog(true);
  };
  const deleteEventRow = () => {
    try {
      delete_Event_Participants({
        variables: { id: parseInt(eventID) },
      })
        .then((response) => {
          const { message } = response.data.deleteEventParticipants;
          setDeleteDialog(false);
          showNotificationMsz(message, "success");
          getEventData();
        })
        .catch((error) => {
          setDeleteDialog(false);

          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  return (
    <div>
      <Grid className="grid_main_div mt-3">
        <Grid item md={8}>
          <div className="mt-2">
            <div className="text_filed_heading">Invitees</div>
            <div className="font-weight-bold">
              <div className="table_foramtitng mt-3 mb-2">
                <TableContainer
                  className="myinvitee"
                  component={Paper}
                  style={{ marginTop: 10 }}
                >
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className="table_header">
                          Name
                        </StyledTableCell>

                        <StyledTableCell align="left" className="table_header">
                          Remove
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? classlistArray.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : classlistArray
                      ).map((row, index) => (
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <span>{getFullName(row.parent?.name)}</span>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <div className="d-flex ">
                              <span
                                role="button"
                                className="text-info pl-1 pr-1"
                                onClick={() => {
                                  deleteEventDialogOpen(row);
                                }}
                              >
                                Remove
                              </span>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    true
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={classlistArray.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangerowsPerPage}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Loder loading={isloading} />
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDeleteDialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">Are you sure want to delete ?</div>

          <Grid className="grid_main_div mt-2 mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => deleteEventRow()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});
export default ParentInviteList;
