import React, { useState, useEffect, useRef } from "react";
import "./Attendance.css";
import HOC1 from "../../Hoc1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import YearPicker from "react-year-picker";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder";
import {
  getMonthFormat,
  isPresent,
  serverFormatDate,
  showNotificationMsz,
  timeWithDateFormat,
  yearAttenMethod,
  dayPresentCounter,
  options,
  getFullName,
} from "../../../utils/Validation";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
//----------------------api calls--------------------------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { Checkbox } from "@material-ui/core";

const AttendanceViewDayAdminStudent = ({
  id,
  navigate,
  class_Name,
  section_Name,
  institution_name,
}) => {
  //--------------------------global id------------------------
  const ref = React.createRef();
  const componentRef = useRef();
  let section_id = parseInt(id);
  let today_Date = new Date().toJSON().slice(0, 10);

  //--------------------------api calls------------------------
  const client = useApolloClient();
  const classes = useStyles();
  //--------------------------local state------------------------
  const pad = (n) => {
    return n < 10 ? "0" + n : n;
  };
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  let prefilledDate = `${currentYear}-${pad(currentMonth)}`;
  const [preFillMonth, setPreFillMonth] = useState(prefilledDate);
  const [totalWorkingDaysArr, setTotalWorkingDaysArr] = useState(0);
  const [yearAttenArr, setYearAttenArr] = useState([]);
  const [search, setSearch] = useState("");
  const [searchMonth, setsearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [isloading, setisloading] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [studentDayAttenArr, setStudentDayAttenArr] = useState([]);
  const [studentMonthAttenArr, setStudentMonthAttenArr] = useState([]);
  const [studentYearAttenArr, setStudentYearAttenArr] = useState([]);
  const [yearToShow, setYearToShow] = useState("");
  const [dateToShow, setdateToShow] = useState(today_Date);
  const [timeToShow, settimeToShow] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [checkbox, setcheckbox] = useState(0);
  const [day, setDay] = useState([]);
  const [yearArr, setYearArr] = useState([
    { title: "Jan" },
    { title: "Feb" },
    { title: "Mar" },
    { title: "Apr" },
    { title: "May" },
    { title: "Jun" },
    { title: "Jul" },
    { title: "Aug" },
    { title: "Sep" },
    { title: "Oct" },
    { title: "Nov" },
    { title: "Dec" },
  ]);

  useEffect(() => {
    if (checkbox === 1) {
      monthHandler(preFillMonth);
    } else if (checkbox === 2) {
      yearHandler(currentYear);
    }
  }, [checkbox]);
  useEffect(() => {
    try {
      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: today_Date,
            end_date: today_Date,
            attendance_type: "CLASS",
            section_id,
          },
        })
        .then((response) => {
          setStudentDayAttenArr(response.data.getAttendanceBySection);
        })
        .catch((error) => {
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
    }
    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, client]);
  useEffect(() => {
    monthHandler(preFillMonth);
    yearHandler(currentYear);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const yearHandler = async (year) => {
    try {
      setisloading(true);
      let newDate = new Date(year, 0);
      let yearAr = [];

      for (let index = 1; index <= 12; index++) {
        yearAr.push({
          month: newDate.getFullYear() + "-" + pad(index),
        });
      }
      setYearAttenArr(yearAr);

      setYearToShow(year);
      // year data procedure-------
      let yearStartingDate = await new Date(year, 0, 1);
      let yearEndDate = await new Date(year, 11, 31);

      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: serverFormatDate(yearStartingDate),
            end_date: serverFormatDate(yearEndDate),
            attendance_type: "CLASS",
            section_id,
          },
        })
        .then((response) => {
          const timetableArr = response.data.getAttendanceBySection;
          let workingDays = 0;
          for (let index = 0; index < timetableArr.length; index++) {
            const element = timetableArr[index];
            if (element?.attendances) {
              workingDays = element.attendances.length;
              break;
            }
          }

          setTotalWorkingDaysArr(workingDays);
          setStudentYearAttenArr(timetableArr);

          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
    }
  };
  const monthHandler = (data) => {
    try {
      setPreFillMonth(data);
      setisloading(true);
      let newDate = new Date(data);
      let lastDayOfMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
      ).getDate();
      setTotalDays(lastDayOfMonth);
      settimeToShow(getMonthFormat(newDate));
      let dayArr = [];
      for (let index = 1; index <= lastDayOfMonth; index++) {
        dayArr.push({
          title: index,
          realDate: `${newDate.getFullYear()}-${pad(
            newDate.getMonth() + 1
          )}-${pad(index)}`,
        });
      }
      setDay(dayArr);
      let start_date = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      let end_date = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
      client
        .query({
          query: Service.Get_Attendance_By_SectionId,
          variables: {
            start_date: serverFormatDate(start_date),
            end_date: serverFormatDate(end_date),
            attendance_type: "CLASS",
            section_id,
          },
        })
        .then((response) => {
          const timetableArr = response.data.getAttendanceBySection;
          let workingDays = 0;
          for (let index = 0; index < timetableArr.length; index++) {
            const element = timetableArr[index];
            if (element?.attendances) {
              workingDays = element.attendances.length;
              break;
            }
          }
          setTotalWorkingDaysArr(workingDays);
          setStudentMonthAttenArr(timetableArr);
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error, "danger");
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterArr = studentDayAttenArr.filter((event) => {
    return (
      getFullName(event.student_profile?.name)
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });
  const monthFilterArr = studentMonthAttenArr.filter((event) => {
    return (
      getFullName(event.student_profile?.name)
        .toLowerCase()
        .indexOf(searchMonth.toLowerCase()) !== -1
    );
  });
  const yearFilterArr = studentYearAttenArr.filter((event) => {
    return (
      getFullName(event.student_profile?.name)
        .toLowerCase()
        .indexOf(searchYear.toLowerCase()) !== -1
    );
  });

  const dayWiseAttendanceHandler = (date) => {
    setdateToShow(date);
    setisloading(true);
    client
      .query({
        query: Service.Get_Attendance_By_SectionId,
        variables: {
          start_date: date,
          end_date: date,
          attendance_type: "CLASS",
          section_id,
        },
      })
      .then((response) => {
        setStudentDayAttenArr(response.data.getAttendanceBySection);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        showNotificationMsz(error, "danger");
      });

    return () => {
      setIsUpdated(false);
    };
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div
                className="enquirydetails_background mb-2"
                ref={componentRef}
              >
                <div ref={ref}>
                  <div className="enquiry_heading">
                    <div className="mt-3 ml-3 Heading_bold">
                      Student Attendance {`( ${class_Name} ${section_Name} )`}
                    </div>
                  </div>
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={8}>
                              <div className="mt-5">
                                <div>
                                  <span>
                                    <CheckBoxComponent
                                      label="Day"
                                      checked={checkbox == 0}
                                      value={0}
                                      cssClass="e-custom"
                                      onChange={(e) => {
                                        if (e.target.value == 1) {
                                          showNotificationMsz(
                                            "Need to calculate day of month",
                                            "info"
                                          );
                                        }
                                        setcheckbox(e.target.value);
                                      }}
                                    />
                                  </span>
                                  <span className="ml-4">
                                    <CheckBoxComponent
                                      label="Month"
                                      cssClass="e-custom"
                                      checked={checkbox == 1}
                                      value={1}
                                      onChange={(e) => {
                                        setcheckbox(e.target.value);
                                      }}
                                    />
                                  </span>
                                  <span className="ml-4">
                                    <CheckBoxComponent
                                      label="Year"
                                      cssClass="e-custom"
                                      checked={checkbox == 2}
                                      value={2}
                                      onChange={(e) => {
                                        setcheckbox(e.target.value);
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          {checkbox == 0 && (
                            <div>
                              <Grid className="grid_main_div mt-1">
                                <Grid item md={5}>
                                  <label for="exampleInputEmail1">Day</label>
                                  <input
                                    max={new Date()}
                                    type="date"
                                    class="form-control"
                                    value={dateToShow}
                                    onChange={(e) => {
                                      dayWiseAttendanceHandler(e.target.value);
                                    }}
                                  />
                                </Grid>
                                <Grid item md={5}>
                                  <div className="inputMarginright ml-1">
                                    <label for="exampleInputEmail1">
                                      Search
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Search"
                                      autoComplete="off"
                                      value={search}
                                      onChange={(e) => {
                                        setSearch(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={1} className="mt-2">
                                  <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPdf
                                        targetRef={ref}
                                        filename="dayAttendance.pdf"
                                        scale={1}
                                        options={options}
                                      >
                                        {({ toPdf }) => (
                                          <i
                                            class="fa fa-file-pdf-o"
                                            onClick={toPdf}
                                          ></i>
                                        )}
                                      </ReactToPdf>
                                    </div>
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPrint
                                        trigger={() => (
                                          <i class="fa fa-print"></i>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid className="grid_main_div mt-1">
                                <Grid item md={10}></Grid>
                                <Grid item md={2} className="d-flex">
                                  <Grid item md={6} className="d-flex">
                                    <p className="absentCirlce"></p>
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Absent
                                    </span>
                                  </Grid>
                                  <Grid item md={6} className="d-flex">
                                    <p className="presentCircle"></p>
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Present
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                <div className="float-right mt-1 mb-1 mr-3">
                                  Day - {timeWithDateFormat(dateToShow)}
                                </div>
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell className="table_header">
                                          Roll Number
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Name
                                        </StyledTableCell>

                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        >
                                          Present
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                        ></StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage > 0
                                        ? filterArr.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                          )
                                        : filterArr
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {row.roll_number}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                          >
                                            {getFullName(
                                              row.student_profile?.name
                                            )}
                                          </StyledTableCell>

                                          {row.attendances.length !== 0 &&
                                            row.attendances[0].status ===
                                              "Present" && (
                                              <StyledTableCell align="left">
                                                <i
                                                  class="fa fa-check"
                                                  style={{
                                                    color: "#50ae34",
                                                    fontSize: 20,
                                                  }}
                                                ></i>
                                              </StyledTableCell>
                                            )}
                                          {row.attendances.length !== 0 &&
                                            row.attendances[0].status ===
                                              "Absent" && (
                                              <StyledTableCell align="left">
                                                <i
                                                  class="fa fa-times cursor"
                                                  style={{
                                                    color: "#ae3450",
                                                    fontSize: 20,
                                                  }}
                                                ></i>
                                              </StyledTableCell>
                                            )}

                                          <StyledTableCell align="left"></StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filterArr.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          )}

                          {checkbox == 1 && (
                            <div>
                              <Grid className="grid_main_div mt-1 d-flex">
                                <Grid item md={5}>
                                  <label for="exampleInputEmail1">
                                    Select Month
                                  </label>
                                  <input
                                    type="month"
                                    class="form-control"
                                    value={preFillMonth}
                                    onChange={(e) =>
                                      monthHandler(e.target.value)
                                    }
                                  />
                                </Grid>
                                <Grid item md={5}>
                                  <div className="inputMarginright ml-1">
                                    <label>Search</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Search"
                                      autoComplete="off"
                                      value={searchMonth}
                                      onChange={(e) => {
                                        setsearchMonth(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={1} className="mt-2">
                                  <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPdf
                                        targetRef={ref}
                                        filename="dayAttendance.pdf"
                                        scale={1}
                                        options={options}
                                      >
                                        {({ toPdf }) => (
                                          <i
                                            class="fa fa-file-pdf-o"
                                            onClick={toPdf}
                                          ></i>
                                        )}
                                      </ReactToPdf>
                                    </div>
                                    <div className="col-6 mouse__pointer">
                                      <ReactToPrint
                                        trigger={() => (
                                          <i class="fa fa-print"></i>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid className="grid_main_div mt-1">
                                <Grid item md={10}></Grid>
                                <Grid item md={2} className="d-flex">
                                  <Grid item md={6} className="d-flex">
                                    <p className="absentCirlce"></p>
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Absent
                                    </span>
                                  </Grid>
                                  <Grid item md={6} className="d-flex">
                                    <p className="presentCircle"></p>
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Present
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <div className="mt-3 mb-3 card_invoicedetails_border">
                                <div className="d-flex mt-2 mb-2">
                                  <div className="col-7"></div>
                                  <div className="col-5">
                                    <div className="d-flex">
                                      <div className="col-6">
                                        <p>
                                          Total Days - {totalWorkingDaysArr}
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <p>{timeToShow}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell className="table_header">
                                          Roll No
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          className="table_header"
                                          padding="none"
                                        >
                                          Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="none"
                                          align="left"
                                          className="table_header"
                                        >
                                          Days <br /> Present
                                        </StyledTableCell>

                                        {day.map((item) => (
                                          <StyledTableCell
                                            align="left"
                                            className={
                                              item.title < 10
                                                ? "table_header paddingone"
                                                : "table_header"
                                            }
                                            padding="none"
                                          >
                                            {item.title}
                                          </StyledTableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(rowsPerPage > 0
                                        ? monthFilterArr.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                          )
                                        : monthFilterArr
                                      ).map((row) => (
                                        <StyledTableRow>
                                          <StyledTableCell align="left">
                                            {row.roll_number}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            scope="row"
                                            align="left"
                                            padding="none"
                                          >
                                            {getFullName(
                                              row.student_profile?.name
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            padding="none"
                                          >
                                            {dayPresentCounter(row.attendances)}
                                          </StyledTableCell>

                                          {day.map((item) => {
                                            if (
                                              isPresent(
                                                item.realDate,
                                                row.attendances
                                              ) === "Present"
                                            ) {
                                              return (
                                                <StyledTableCell
                                                  align="left"
                                                  padding="none"
                                                >
                                                  <i
                                                    class="fa fa-check"
                                                    style={{
                                                      color: "#50ae34",
                                                      fontSize: 15,
                                                    }}
                                                  ></i>
                                                </StyledTableCell>
                                              );
                                            } else if (
                                              isPresent(
                                                item.realDate,
                                                row.attendances
                                              ) === "Absent"
                                            ) {
                                              return (
                                                <StyledTableCell
                                                  align="left"
                                                  padding="none"
                                                >
                                                  <i
                                                    class="fa fa-times cursor"
                                                    style={{
                                                      color: "#ff0000",
                                                      fontSize: 15,
                                                    }}
                                                  ></i>
                                                </StyledTableCell>
                                              );
                                            } else {
                                              return (
                                                <StyledTableCell
                                                  align="left"
                                                  padding="none"
                                                >
                                                  <i
                                                    class="fa fa-check"
                                                    style={{
                                                      color: "#50ae34",
                                                      fontSize: 15,
                                                      visibility: "hidden",
                                                    }}
                                                  ></i>
                                                </StyledTableCell>
                                              );
                                            }
                                          })}
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <TablePagination
                                    true
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={monthFilterArr.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </TableContainer>
                              </div>
                            </div>
                          )}
                          {checkbox == 2 && (
                            <div>
                              <div>
                                <Grid className="grid_main_div mt-1 d-flex">
                                  <Grid item md={5}>
                                    <label for="exampleInputEmail1">
                                      Select Year
                                    </label>

                                    <YearPicker onChange={yearHandler} />
                                  </Grid>
                                  <Grid item md={5}>
                                    <div className="inputMarginright ml-1">
                                      <label>Search</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search"
                                        autoComplete="off"
                                        value={searchYear}
                                        onChange={(e) => {
                                          setSearchYear(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={1} className="mt-2">
                                    <div className="d-flex card_invoicedetails_border p-1 mt-4">
                                      <div className="col-6 mouse__pointer">
                                        <ReactToPdf
                                          targetRef={ref}
                                          filename="dayAttendance.pdf"
                                          scale={1}
                                          options={options}
                                        >
                                          {({ toPdf }) => (
                                            <i
                                              class="fa fa-file-pdf-o"
                                              onClick={toPdf}
                                            ></i>
                                          )}
                                        </ReactToPdf>
                                      </div>
                                      <div className="col-6 mouse__pointer">
                                        <ReactToPrint
                                          trigger={() => (
                                            <i class="fa fa-print"></i>
                                          )}
                                          content={() => componentRef.current}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="mt-3 mb-3 card_invoicedetails_border">
                                  <div className="d-flex mt-2 mb-2">
                                    <div className="col-7"></div>
                                    <div className="col-5">
                                      <div className="d-flex">
                                        <div className="col-6">
                                          <p>
                                            Total Days - {totalWorkingDaysArr}
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p>Year - {yearToShow}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <TableContainer component={Paper}>
                                    <Table
                                      className={classes.table}
                                      aria-label="customized table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell className="table_header">
                                            Roll No
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header"
                                          >
                                            Name
                                          </StyledTableCell>
                                          <StyledTableCell
                                            align="left"
                                            className="table_header"
                                          >
                                            Days <br /> Present
                                          </StyledTableCell>

                                          {yearArr.map((item) => (
                                            <StyledTableCell
                                              align="left"
                                              className="table_header"
                                            >
                                              {item.title}
                                            </StyledTableCell>
                                          ))}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(rowsPerPage > 0
                                          ? yearFilterArr.slice(
                                              page * rowsPerPage,
                                              page * rowsPerPage + rowsPerPage
                                            )
                                          : yearFilterArr
                                        ).map((row) => (
                                          <StyledTableRow>
                                            <StyledTableCell align="left">
                                              {row.roll_number}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              scope="row"
                                              align="left"
                                            >
                                              {getFullName(
                                                row.student_profile?.name
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              {dayPresentCounter(
                                                row.attendances
                                              )}
                                            </StyledTableCell>

                                            {yearAttenArr.map((item) =>
                                              yearAttenMethod(
                                                item.month,
                                                row.attendances
                                              ) ? (
                                                <StyledTableCell align="center">
                                                  {dayPresentCounter(
                                                    row.attendances
                                                  )}
                                                </StyledTableCell>
                                              ) : (
                                                <StyledTableCell align="center">
                                                  {""}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </StyledTableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                    <TablePagination
                                      true
                                      rowsPerPageOptions={[5, 10, 25]}
                                      component="div"
                                      count={yearFilterArr.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  </TableContainer>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    padding: 0,
  },
});
export default (HOC1(AttendanceViewDayAdminStudent));
