import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { getFullName, showNotificationMsz } from "../../../utils/Validation";

const GenerateStaff = ({ manageInstitution, UserInfo, navigate }) => {
  const { organization_id } = UserInfo;
  const { institution_id, type } = manageInstitution;
  const [Create_User_Login_Data, {}] = useMutation(
    Service.Create_User_Login_Data
  );
  //-------------------------------global id-------------------------
  const client = useApolloClient();
  //-------------------------------api calls-------------------------
  //-------------------------------location state-------------------------
  const [arrWithErrorCode, setArrWithErrorCode] = useState([]);
  const [isloading, setisloading] = useState(false);
  const classes = useStyles();
  const [employeeArr, setEmployeeArr] = useState([]);
  const [searchname, setsearchname] = useState("");
  const [managedepartment, setmanagedepartment] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //name instituition

  useEffect(() => {
    window.scrollTo(0, 0);
    getEmployeeList(-1);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Department_Data,
          variables: {
            institution_id: +institution_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setmanagedepartment(response.data.getDepartment);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  }, [client, institution_id]);

  const getEmployeeList = async (departmentId) => {
    try {
      setisloading(true);
      const response = await client.query({
        query: Service.Get_Employee_All_data,
        variables: {
          institution_id: +institution_id,
          department_id: +departmentId,
        },
      });
      const filterArr = await response.data.getEmployee.filter((item) => {
        return !item.login;
      });
      const temp = [];
      await filterArr.map((item) => {
        if (item.name || item.designation || item.designation) {
          temp.push({
            isSelected: false,
            id: item.id,
            email: item.email,
            designation: item.user_type,
            name: item.name,
            phone: item.phone,
          });
        }
      });
      setEmployeeArr(temp);
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const filterByName = employeeArr.filter((event) => {
    if (searchname == "") return true;
    if (event.name.first_name !== undefined) {
      return (
        event.name.first_name
          .toLowerCase()
          .indexOf(searchname.toLowerCase()) !== -1
      );
    }
  });

  const allCheckHandler = () => {
    if (employeeArr.length > 0) {
      setAllChecked(!allChecked);
    }
    let temp = [];
    employeeArr.map((item) => {
      if (!allChecked) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      temp.push(item);
    });
    setEmployeeArr(temp);
  };

  const checkBoxHandler = (id) => {
    let temp = [];
    employeeArr.map((item) => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }
      temp.push(item);
    });

    setEmployeeArr(temp);
  };

  const generateUserHandler = async () => {
    try {
      setisloading(true);
      setArrWithErrorCode([]);
      const user_data = [];
      employeeArr.map((item) => {
        if (item.isSelected) {
          user_data.push({
            first_name: item.name.first_name,
            user_id: item.id,
            user_type: item.designation,
            login_id: item.email,
            email_address: item.email,
          });
        }
      });
      const response = await Create_User_Login_Data({
        variables: {
          institution_id: +institution_id,
          user_data: user_data,
          organization_id: +organization_id,
        },
      });
      const arrWithErrorCode =
        await response.data.createLoginDetailsForUser.filter((item) => {
          if (item.errorCode === 1) return item;
        });
      setArrWithErrorCode(arrWithErrorCode);
      await getEmployeeList(-1);
      showNotificationMsz("User Login Generated Successfully", "success");
      setisloading(false);
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
      console.log("error", error);
    }
  };
  const OpenEditEmployeeData = (staffId) => {
    if (type === "PT") {
      navigate("/PtTeacherFormDetails", {
        state: {
          pagetype: "edit",
          staffId,
        },
      });
    } else {
      navigate("/adminemployeedetailform", {
        state: {
          pagetype: "edit",
          staffId,
        },
      });
    }
  };
  return (
    <div>
      <Grid className="grid_main_div  p-3">
        <Grid item md={4}>
          <div className="inputMarginright  mt-1">
            <select
              className="form-control"
              onChange={(e) => getEmployeeList(e.target.value)}
            >
              <option value={-1}> Select Department</option>
              {managedepartment.length > 0 ? (
                managedepartment.map((item, index) => (
                  <option value={item.id}>{item.name}</option>
                ))
              ) : (
                <option value={-1}>No Data</option>
              )}
            </select>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="inputMarginright mt-1">
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Search By Name"
              autoComplete="off"
              value={searchname}
              onChange={(e) => {
                setsearchname(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>

      {/* --------------------list of users-------------- */}
      <div className="table_foramtitng mt-2">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" className="table_header mr-3">
                  <Checkbox checked={allChecked} onChange={allCheckHandler} />
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Name
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left" className="table_header">
                  Phone No
                </StyledTableCell>

                <StyledTableCell align="left" className="table_header">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filterByName.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filterByName
              ).map((row) => (
                <StyledTableRow>
                  <StyledTableCell
                    className="mr-3"
                    padding="checkbox"
                    align="center"
                  >
                    <Checkbox
                      checked={row.isSelected}
                      onChange={() => checkBoxHandler(row.id)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {getFullName(row.name)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.email && row.email}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.phone && `+${row.phone}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span
                      className="row_details_color mr-1"
                      onClick={() => OpenEditEmployeeData(row.id)}
                    >
                      Open
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            true
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filterByName.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* -------------------------list of users---------------------- */}

      <Grid className="grid_main_div d-flex p-3">
        <Grid item md={10}>
          {arrWithErrorCode.length !== 0 &&
            arrWithErrorCode.map((item) => (
              <p className="text-danger">
                Create user failed for {item.message}
              </p>
            ))}
        </Grid>
        <Grid item md={2}>
          <Button
            variant="contained"
            className="button_enquiry_details button_login_decoration button_width"
            onClick={generateUserHandler}
          >
            Generate
          </Button>
        </Grid>
      </Grid>

      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(GenerateStaff);
