import React, { useState, useEffect } from "react";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import profile from "./profile.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
//------------------redux-------------------------------
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
import * as Service from "../../services/index";
import {
  blankValidator,
  getFullName,
  showNotificationMsz,
} from "../../utils/Validation";
import IconSchool from "./IconSchool.png";

const PtAdminMainHeader = (props) => {
  //-------------------------global id-------------------------
  let navigation = props.navigate;
  //-------------------------local state-------------------------
  const [isPathValid, setisPathValid] = useState(false);
  // const [invisible] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  //-------------------------methods-------------------------

  //institution logo path
  let imagepath = !blankValidator(props.data.data)
    ? ""
    : !blankValidator(props.data.data.document)
    ? ""
    : !blankValidator(props.data.data.document.path)
    ? ""
    : props.data.data.document.path;
  useEffect(() => {
    const academicHandler = () => {
      if (!props.data.academic_session_id) {
        setisPathValid(false);
      } else {
        setisPathValid(true);
      }
    };
    academicHandler();
  }, [props.data.academic_session_id]);

  const setLoadingnewside = () => {
    try {
      setSidebar(!Sidebar);
      if (Sidebar) {
        document.getElementById("mySidenav").style.width = "250px";
      } else {
        document.getElementById("mySidenav").style.width = "0px";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigationHandler = (path, data) => {
    if (!isPathValid) {
      showNotificationMsz(
        "Currently there is no active session, please manage Institution session first",
        "info"
      );
    } else if (isPathValid) {
      navigation(path, data);
    }
  };

  return (
    <div>
      <AppBar position="fixed" className="appbarcolor">
        <div className="signupheader1">
          <Toolbar>
            <Grid container spacing={24}>
              <Grid item xs={3} md={3}>
                <div className="logoformatting">
                  <span>
                    <img
                      src={
                        imagepath === ""
                          ? IconSchool
                          : Service.baseUrl + "/" + imagepath
                      }
                      alt="No_profile"
                      className={
                        imagepath === ""
                          ? "images_background_logo image_hader"
                          : "image_hader"
                      }
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={9} md={9}>
                <div
                  className="iconsMaindiv2 icon_color"
                  style={{ paddingRight: "3%" }}
                >
                  <span
                    className="insideicondiv margin"
                    onClick={() => navigation("/homemenu")}
                  >
                    Home
                  </span>

                  <div className="insideicondiv">
                    <p
                      className="dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Notices
                    </p>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          navigationHandler("/adminClassNotice");
                        }}
                      >
                        Class Notices
                      </span>

                      <span
                        className="dropdown-item"
                        onClick={() => {
                          navigationHandler("/adminSchoolNotice");
                        }}
                      >
                        School Notices
                      </span>
                    </div>
                  </div>
                  <span
                    className="insideicondiv margin"
                    onClick={() => {
                      navigationHandler("/manageEvent", {
                        state: {
                          academic_session_id: parseInt(
                            props.data.academic_session_id
                          ),
                        },
                      });
                    }}
                  >
                    Events
                  </span>

                  <div className="insideicondiv">
                    <p
                      className="dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Report
                    </p>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          navigationHandler("/report", {
                            state: {
                              name: "",
                              subName: "",
                            },
                          });
                        }}
                      >
                        Report
                      </span>
                      {/* ------------------class for header-----------------  */}
                      <div className="dropdown dropdown-item">
                        <span>
                          Class
                          <i className="fa fa-angle-right float-right mt-1"></i>
                        </span>
                        <div className="dropdown-content">
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Class",
                                  subName: "Attendance",
                                },
                              })
                            }
                          >
                            Attendance
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Class",
                                  subName: "Examinations",
                                },
                              })
                            }
                          >
                            Examinations
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Class",
                                  subName: "StudentList",
                                },
                              })
                            }
                          >
                            Student List
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Class",
                                  subName: "TeacherList",
                                },
                              })
                            }
                          >
                            Teacher List
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Class",
                                  subName: "Timetable",
                                },
                              })
                            }
                          >
                            Timetable
                          </span>
                        </div>
                      </div>
                      <div className="dropdown dropdown-item">
                        <span>
                          Student
                          <i className="fa fa-angle-right float-right mt-1"></i>
                        </span>
                        <div className="dropdown-content">
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Student",
                                  subName: "Class_Attendance",
                                },
                              })
                            }
                          >
                            Class Attendance
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Student",
                                  subName: "Examinations",
                                },
                              })
                            }
                          >
                            Examinations
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Student",
                                  subName: "Fees",
                                },
                              })
                            }
                          >
                            Fees
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Student",
                                  subName: "Student_Details",
                                },
                              })
                            }
                          >
                            Student Details
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Student",
                                  subName: "Timetable",
                                },
                              })
                            }
                          >
                            Timetable
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              navigationHandler("/report", {
                                state: {
                                  name: "Student",
                                  subName: "Transport_Attendance",
                                },
                              })
                            }
                          >
                            Transport Attendance
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span
                    className="insideicondiv margin"
                    onClick={() => {
                      navigationHandler("/admissionsMenu");
                    }}
                  >
                    Onboarding
                  </span>

                  {/* <span
                    className="insideicondiv margin"
                    onClick={() => navigationHandler("/routelistings")}
                  >
                    Transport
                  </span> */}

                  <span
                    className="insideicondiv margin"
                    onClick={() => {
                      navigationHandler("/fees");
                    }}
                  >
                    Fees
                  </span>
                  <span
                    className="insideicondiv margin"
                    onClick={() => {
                      navigationHandler("/GetStarted");
                    }}
                  >
                    Get Started
                  </span>

                  <span className="insideicondiv margin">
                    <i
                      className="fa fa-calendar"
                      // onClick={() => navigationHandler("/commonHolidayCalender")}
                      onClick={() => {
                        navigationHandler("/commonHolidayCalender", {
                          state: {
                            academic_session_id: parseInt(
                              props.data.academic_session_id
                            ),
                          },
                        });
                      }}
                    ></i>
                  </span>

                  <span
                    className="insideicondiv margin"
                    onClick={() => navigationHandler("/notification")}
                  >
                    <Badge
                      color="secondary"
                      // variant="dot"
                      // invisible={invisible}
                    >
                      <i className="fa fa-envelope "></i>
                    </Badge>
                  </span>

                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn  dropdown-toggle dropdownbutton1 button_login_decoration"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ textTransform: "capitalize" }}
                    >
                      {getFullName(props.data)}
                    </button>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => navigation("/studentresetpassword")}
                      >
                        Reset Password
                      </span>
                      <span
                        className="dropdown-item logout_Pointer_cursor"
                        onClick={() => navigation("/")}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mobile_Burger_Menu mr-3 text-right mt-3">
                  <span
                    className="logout_Pointer_cursor"
                    onClick={setLoadingnewside}
                  >
                    <i className="fa fa-bars"></i>
                  </span>

                  <div id="mySidenav" className="sidenav">
                    <div className="cross_icon_style">
                      <i
                        className="fa fa-times cursor"
                        onClick={() => {
                          document.getElementById("mySidenav").style.width =
                            "0px";
                        }}
                      ></i>
                    </div>
                    <div className="text-center">
                      <img src={profile} alt="xyx" width="60" height="60" />{" "}
                      <div className="userName_mobileView_color">
                        {props.data.first_name !== undefined &&
                          props.data.first_name}{" "}
                        {props.data.last_name !== undefined &&
                          props.data.last_name}
                      </div>
                    </div>

                    <span
                      className="logout_Pointer_cursor "
                      onClick={() => navigation("/homemenu")}
                    >
                      Home
                    </span>
                    <div className="logout_Pointer_cursor ml-4 pl-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            Notices <i className="fa fa-angle-right ml-3"></i>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <span
                              className="logout_Pointer_cursor p-1"
                              onClick={() => {
                                navigationHandler("/adminClassNotice");
                              }}
                            >
                              Class Notices
                            </span>

                            <span
                              className="logout_Pointer_cursor p-1"
                              onClick={() => {
                                navigationHandler("/adminSchoolNotice");
                              }}
                            >
                              School Notices
                            </span>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => {
                        navigationHandler("/manageEvent", {
                          academic_session_id: parseInt(
                            props.data.academic_session_id
                          ),
                        });
                      }}
                    >
                      Events
                    </span>

                    <div className="logout_Pointer_cursor ml-4 pl-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            Report <i className="fa fa-angle-right ml-3"></i>{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <span
                              className="logout_Pointer_cursor padding_report_admission"
                              onClick={() => {
                                navigationHandler("/report", {
                                  name: "",
                                  subName: "",
                                });
                              }}
                            >
                              Report{" "}
                            </span>
                            <div className="logout_Pointer_cursor">
                              <Accordion>
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    Class{" "}
                                    <i className="fa fa-angle-right ml-3"></i>{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Class",
                                          subName: "Attendance",
                                        })
                                      }
                                    >
                                      Attendance
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Class",
                                          subName: "Examinations",
                                        })
                                      }
                                    >
                                      Examinations
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Class",
                                          subName: "StudentList",
                                        })
                                      }
                                    >
                                      Student List
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Class",
                                          subName: "TeacherList",
                                        })
                                      }
                                    >
                                      Teacher List
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Class",
                                          subName: "Timetable",
                                        })
                                      }
                                    >
                                      Timetable
                                    </span>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                            <div className="logout_Pointer_cursor">
                              <Accordion>
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    Students{" "}
                                    <i className="fa fa-angle-right ml-3"></i>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Student",
                                          subName: "Class_Attendance",
                                        })
                                      }
                                    >
                                      Class Attendance
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Student",
                                          subName: "Examinations",
                                        })
                                      }
                                    >
                                      Examinations
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Student",
                                          subName: "Fees",
                                        })
                                      }
                                    >
                                      Fees
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Student",
                                          subName: "Student_Details",
                                        })
                                      }
                                    >
                                      Student Details
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Student",
                                          subName: "Timetable",
                                        })
                                      }
                                    >
                                      Timetable
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Student",
                                          subName: "Transport_Attendance",
                                        })
                                      }
                                    >
                                      Transport Attendance
                                    </span>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                            <div className="logout_Pointer_cursor">
                              <Accordion>
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    All Staffs
                                    <i className="fa fa-angle-right ml-3"></i>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "All_Staff",
                                          subName: "StaffDetails",
                                        })
                                      }
                                    >
                                      Staff Details
                                    </span>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                            <div className="logout_Pointer_cursor">
                              <Accordion>
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    Staffs{" "}
                                    <i className="fa fa-angle-right ml-3"></i>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Staff",
                                          subName: "StaffDetailSingle",
                                        })
                                      }
                                    >
                                      Staff Details
                                    </span>
                                    <span
                                      className="logout_Pointer_cursor p-1"
                                      onClick={() =>
                                        navigationHandler("/report", {
                                          name: "Staff",
                                          subName: "Timetable",
                                        })
                                      }
                                    >
                                      Timetable
                                    </span>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>

                            {/* <span
                              className="logout_Pointer_cursor padding_report_admission"
                              onClick={() =>
                                navigationHandler("/report", {
                                  name: "Admissions",
                                  subName: "AdmissionEnquery",
                                })
                              }
                            >
                              Admissions
                            </span> */}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    {/* <div className="logout_Pointer_cursor ml-4 pl-2">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            Admissions{" "}
                            <i className="fa fa-angle-right ml-3"></i>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <span
                              className="logout_Pointer_cursor p-1"
                              onClick={() => {
                                navigationHandler("/admissionIntrest");
                              }}
                            >
                              Enquiry
                            </span>
                            <span
                              className="logout_Pointer_cursor p-1"
                              onClick={() => alert("Need to Implement")}
                            >
                              Enrollment
                            </span>
                            <span
                              className="logout_Pointer_cursor p-1"
                              onClick={() => {
                                navigationHandler("/studentform");
                              }}
                            >
                              Student Onboarding
                            </span>
                            <span
                              className="logout_Pointer_cursor p-1"
                              onClick={() => alert("Need to Implement")}
                            >
                              Student withdrawal
                            </span>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div> */}

                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/routelistings")}
                    >
                      Transport
                    </span>

                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => {
                        navigationHandler("/fees");
                      }}
                    >
                      Fees
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigationHandler("/GetStarted")}
                    >
                      Get Started
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      // onClick={() => navigationHandler("/commonHolidayCalender")}
                      onClick={() => {
                        navigationHandler("/commonHolidayCalender", {
                          academic_session_id: parseInt(
                            props.data.academic_session_id
                          ),
                        });
                      }}
                    >
                      Calender
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => alert("need to implement")}
                    >
                      Notification
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/studentresetpassword")}
                    >
                      Reset Password
                    </span>
                    <span
                      className="logout_Pointer_cursor"
                      onClick={() => navigation("/")}
                    >
                      Logout <i className="fa fa-sign-out ml-3"></i>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(PtAdminMainHeader);
