import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
//redux
import { connect } from "react-redux";

import RichTextEditor from "react-rte";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import * as Service from "../../../services/index";
import {
  blankValidator,
  dateFormatToShow,
  showNotificationMsz,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder";
import "./Fees.css";
import { useNavigate } from "react-router-dom";

const InvoiceDetailAlreadyPaid = (props) => {
  let { rowData, invoiceData, student_invoice_id } = props.location.state;
  const navigate = useNavigate();
  const [filename, setFilename] = useState("Add Attachment");
  const [institution_Name, setinstitution_Name] = useState("");

  let temp = new Date();

  const [due_date, setdue_date] = useState(dateFormatToShow(temp));
  const [isloading, setisloading] = useState(false);
  const [notes, setnotes] = useState(RichTextEditor.createEmptyValue());
  const [funding_instrument_type, setfunding_instrument_type] = useState("");
  const [bank, setbank] = useState("");
  const [refrenceid, setrefrenceid] = useState("");
  const [showrefrenceid, setshowrefrenceid] = useState("");

  const [invoiceid, setinvoiceid] = useState("");

  const [due_amount, setdue_amount] = useState("");

  const [amount, setamount] = useState("");
  const [uploadFileArr, setUploadFileArr] = useState([]);
  // const [uploadingError, setuploadingError] = useState(true);
  const [notesError, setnotesError] = useState(false);
  const [duedateError, setduedateError] = useState(false);
  const [amountError, setamountError] = useState(false);
  const [refrenceidError, setrefrenceidError] = useState(false);
  const [bankError, setbankError] = useState(false);
  const [funding_instrument_typeError, setfunding_instrument_typeError] =
    useState(false);

  const [Add_Payment, {}] = useMutation(Service.Add_Payment);

  const onChange = (value) => {
    setnotesError(false);
    setnotes(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (blankValidator(props.user_info.institution_name)) {
      setinstitution_Name(props.user_info.institution_name);
    }

    if (blankValidator(rowData)) {
      setdue_amount(rowData.due_amount);
      setamount(rowData.due_amount);
      setinvoiceid(rowData.id);
      setrefrenceid(rowData.invoice_display_id);
      return;
    } else if (
      blankValidator(invoiceData) &&
      blankValidator(student_invoice_id)
    ) {
      setdue_amount(invoiceData.amount);
      setamount(invoiceData.amount);
      setinvoiceid(student_invoice_id);
      setrefrenceid(invoiceData.invoice_display_id);
    }
  };

  const UploadDocumentHandler = (e, type) => {
    const file = e.target.files[0];
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);

    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFilename(response.data.filename);
          const temp = [];
          temp.push(obj);
          setUploadFileArr(temp);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch error", error);
      showNotificationMsz(error.message, "danger");
    }
  };

  const SubmitAlreadyPaid = () => {
    try {
      let flag = false;
      if (funding_instrument_type === "Bank_Transfer") {
        if (!blankValidator(funding_instrument_type)) {
          setfunding_instrument_typeError(true);
          flag = true;
        }
      }

      // if (!blankValidator(bank)) {
      //   setbankError(true);
      //   flag = true;
      // }
      if (!blankValidator(amount)) {
        setamountError(true);
        flag = true;
      }

      if (!blankValidator(rowData) && !blankValidator(invoiceData)) {
        alert("Please Contact to Admin");
        flag = true;
      }

      if (!blankValidator(parseFloat(due_amount))) {
        alert("Please Contact to Admin");
        flag = true;
      }
      if (parseFloat(due_amount) < parseFloat(amount)) {
        alert("Amount can not be greater than the due amount.");
        flag = true;
      }

      if (!blankValidator(showrefrenceid)) {
        setrefrenceidError(true);
        flag = true;
      }

      if (!blankValidator(due_date)) {
        setduedateError(true);
        flag = true;
      }

      if (notes.toString("html") === "<p><br></p>") {
        setnotesError(true);
        flag = true;
      }
      if (flag) {
        return;
      }

      setisloading(true);
      Add_Payment({
        variables: {
          funding_instrument_type,
          payment_date: due_date,
          invoice_id: parseInt(invoiceid),
          fi_source: bank,
          external_reference_number: showrefrenceid,
          amount: amount,
          notes: notes.toString("html"),
          payment_doc: uploadFileArr[0] === undefined ? {} : uploadFileArr[0],
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.addPayment.message, "success");
          navigate(-1);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const setPaymentval = async (value) => {
    setfunding_instrument_type(value);
    if (!blankValidator(value)) {
      await setshowrefrenceid("");
      // await setdue_date("");
      return;
    }

    if (value == "Cash_Payment") {
      await setshowrefrenceid(refrenceid);
      // await setdue_date(dateFormatToShow(temp));
      return;
    } else if (value == "Bank_Transfer") {
      await setshowrefrenceid("");
      // await setdue_date("");
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">
                    My Payment Details
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-2 inputMarginright">
                              <label for="exampleInputEmail1">
                                Payment Mode
                              </label>
                              <div>
                                <select
                                  className="form-control "
                                  value={funding_instrument_type}
                                  onChange={(event) =>
                                    // setfunding_instrument_type(event.target.value)
                                    setPaymentval(event.target.value)
                                  }
                                >
                                  <option value="">Select Payment Mode</option>
                                  <option value="Bank_Transfer">
                                    Bank Transfer
                                  </option>
                                  <option value="Cash_Payment">
                                    Cash Payment
                                  </option>
                                </select>

                                {funding_instrument_typeError && (
                                  <span className="text-danger">
                                    Select payment mode
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">Bank Name</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={bank}
                                  onChange={(e) => {
                                    setbankError(false);
                                    setbank(e.target.value);
                                  }}
                                />
                                {bankError && (
                                  <span className="text-danger">
                                    Enter bank Name
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Reference Number
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={showrefrenceid}
                                  onChange={(e) => {
                                    setrefrenceidError(false);
                                    setshowrefrenceid(e.target.value);
                                  }}
                                />
                                {refrenceidError && (
                                  <span className="text-danger">
                                    Invoive Id
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">Amount</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={amount}
                                  onChange={(e) => {
                                    setamountError(false);
                                    setamount(e.target.value);
                                  }}
                                />
                                {amountError && (
                                  <span className="text-danger">
                                    Enter Amount
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Payment Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={due_date}
                                  onChange={(e) => {
                                    setduedateError(false);
                                    setdue_date(e.target.value);
                                  }}
                                />
                                {duedateError && (
                                  <span className="text-danger">
                                    Enter Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">Attachment</label>
                              <div className=" mb-2 d-flex">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                      <i class="fa fa-paperclip"></i>
                                    </span>
                                  </div>
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="inputGroupFile01"
                                      onChange={(e) =>
                                        UploadDocumentHandler(e, "Document")
                                      }
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="inputGroupFile01"
                                    >
                                      {filename}
                                    </label>
                                    {/* <div className=" mb-2 attachList">
                                      dfsdf
                                    </div> */}
                                    {/* {uploadingError && (
                                      <span className="text-danger">
                                        please Add Attachement
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <div className="mt-1">
                            <div class="form-group">
                              <label for="exampleFormControlTextarea1">
                                Notes
                              </label>

                              <RichTextEditor
                                value={notes}
                                onChange={onChange}
                                className="editor_height"
                              />
                              {notesError && (
                                <span className="text-danger">Enter Notes</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={10}></Grid>
                          <Grid item md={2}>
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={SubmitAlreadyPaid}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  Session: getData(state, Constants.ACADEMIC_SESSION_DATA),
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(InvoiceDetailAlreadyPaid));
