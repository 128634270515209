import React from "react";
import HOC1 from "../Hoc1.jsx";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Holiday from "../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../services/index";
import {
  CapitalizeFirstFn,
  blankValidator,
  showNotificationMsz,
} from "../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../api/index";
import * as Constants from "../../action/appAction";
const CallBack = (props) => {
  let institution_Name = props.data.institution_name;
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />
              <Card></Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(CallBack)));
