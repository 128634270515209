import React, { useState } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Button from "@material-ui/core/Button";
import Holiday from "../../Common/Holiday/Holiday.jsx";

const ReviewApproveRejectList = (props) => {
  const [filegroup] = useState([
    { name: "abc.doc" },
    { name: "xyz.doc" },
    { name: "aaa.doc" },
    { name: "ddd.doc" },
    { name: "xxx.doc" },
  ]);

  //   removefile = () => {
  //     this.state.filegroup.pop({
  //       name: this.state.filename,
  //     });

  //     this.setState({
  //       filegroup: this.state.filegroup,
  //     });
  //   };
  //   opencancelleave = () => {
  //     this.setState({
  //       opendialog: true,
  //     });
  //   };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">
                    Approve/Reject Leave - Mark Willy{" "}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Demo"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">Days</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                placeholder="5"
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <div class="form-group">
                            <label for="exampleFormControlTextarea1">
                              Message
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="lorem ipsum...."
                            ></textarea>
                          </div>
                        </div>

                        <div>Attachment</div>
                        <div className="mt-2 mb-2 attachList ml-2">
                          {filegroup.map((item, index) => (
                            <div className="file_back_color ml-2 d-flex">
                              <span className="pl-2">{item.name}</span>
                              <span
                                className="pl-5"
                                //   onClick={removefile}
                              >
                                <i class="fa fa-times cursor"></i>
                              </span>
                            </div>
                          ))}
                        </div>
                        <Grid className="grid_main_div mt-3 ">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="d-flex float-right mb-3">
                              <Button
                                variant="contained"
                                className="add_new_query_btn   mr-2 button_login_decoration "
                              >
                                Reject Leave
                              </Button>
                              <Button
                                variant="contained"
                                className="add_new_query_btn  button_login_decoration "
                              >
                                Accept Leave
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default (HOC1(ReviewApproveRejectList));
