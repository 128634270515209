import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Expand from "react-expand-animated";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import {
  blankValidator,
  timeStampToDate,
  timeStampToDatedash,
} from "../../../utils/Validation";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
const EditFeeInvoice = (props) => {
  const invoiceObj = props.location.state.invoiceObj;
  let pageType = props.location.state.pageType;

  //----------------global id----------------
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );
  //--------------------API CALLS-----------------
  const [Add_Invoice_Template_Fee, {}] = useMutation(
    Service.Add_Invoice_Template_Fee
  );
  console.log("invoiceObj", JSON.stringify(invoiceObj));

  const client = useApolloClient();

  //----------------local state-------------------
  const [recurrence, setRecurrence] = useState("");
  const [idtopass, setidtopass] = useState("");
  const [selectedTemplateId, setselectedTemplateId] = useState(-1);
  const [gradeArr, setgradeArr] = useState([]);
  const [feeSubtypeArr, setfeeSubtypeArr] = useState([]);
  const [title, settitle] = useState("");
  const [description, setDescription] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [templateArr, settemplateArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [createfeetype, setcreatefeetype] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [template, settemplate] = useState("");
  const [createinvoice, setcreateinvoice] = useState([]);
  const [totalamount, setTotalamount] = useState(0);
  const [invoiceId, setinvoiceId] = useState("");
  const [currencyCode, setcurrencyCode] = useState("");

  //error
  const [templateIdError, settemplateIdError] = useState(false);
  const [invoicetitleError, setinvoicetitleError] = useState(false);
  const [recurrenceError, setrecurrenceError] = useState(false);
  const [duedateError, setduedateError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);

  const handlefeeComponent = () => {
    setcreatefeetype(true);
  };

  const closefeeComponent = () => {
    setcreatefeetype(false);
  };
  useEffect(() => {
    if (pageType === "ManageInvoice") {
      setInvoiceTitle(invoiceObj.name);
      setDueDate(timeStampToDatedash(invoiceObj.due_date));
      setRecurrence(invoiceObj.recurrence);
      setDescription(invoiceObj.description);
      setcreateinvoice(invoiceObj.invoice_fee_items);
      setTotalamount(invoiceObj.amount);
      setcurrencyCode(invoiceObj.currency_code);
    }
    if (pageType === "StudentInvoice") {
      setInvoiceTitle(invoiceObj.invoice.name);
      setDueDate(timeStampToDatedash(invoiceObj.invoice.due_date));
      setRecurrence(invoiceObj.recurrence);
      setDescription(invoiceObj.invoice.description);
      setcreateinvoice(invoiceObj.invoice.invoice_fee_items);
      setTotalamount(invoiceObj.amount);
      setcurrencyCode(invoiceObj.invoice.currency_code);
      setinvoiceId(invoiceObj.invoice_display_id);
    }
    if (pageType === "ClassInvoice") {
      setInvoiceTitle(invoiceObj.invoice.name);
      setDueDate(timeStampToDatedash(invoiceObj.invoice.due_date));
      setRecurrence(invoiceObj.invoice.recurrence);
      setDescription(invoiceObj.invoice.description);
      setcreateinvoice(invoiceObj.invoice.invoice_fee_items);
      setTotalamount(invoiceObj.invoice.amount);
      setcurrencyCode(invoiceObj.invoice.currency_code);
    }
  }, []);
  useEffect(() => {
    // to get feesubtype on template select
    try {
      client
        .query({
          query: Service.Get_Fee_Subtype_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setfeeSubtypeArr(response.data.getFeeSubtype);
        })
        .catch((err) => console.log("catch err Get_Fee_Subtype_data", err));
    } catch (error) {
      console.log("t and c error Get_Fee_Subtype_data", error);
    }
    // to get feesubtype on template select
  }, [client, institution_id]);
  // to get template invoice

  useEffect(() => {
    window.scrollTo(0, 0);
    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setgradeArr(response.data.getGrade);
            setisloading(false);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error Get_Manage_Grade", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error Get_Manage_Grade", error);
      }
    };
    getManageGrades();
  }, [client, academic_session_id]);

  const AddFeeSubTypeFn = () => {
    try {
      setisloading(true);
      Add_Invoice_Template_Fee({
        variables: {
          invoice_template_id: parseInt(selectedTemplateId),
          fee_subtype_id: parseInt(idtopass),
          amount: "20",
          tax: "20",
        },
      })
        .then((response) => {
          let temp = {
            feesubtype: {
              name: title,
              id: parseInt(idtopass),
            },
            amount: 0,
            tax: 0,
            id: response.data.addInvoiceTemplateFeeItems.id,
          };
          setcreatefeetype(false);
          createinvoice.push(temp);
          let Datatemp = [];
          createinvoice.map((item) => {
            return Datatemp.push(item);
          });
          setcreateinvoice(Datatemp);
          settitle("");
          setisloading(false);
        })
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const handleFeeSubType = (id) => {
    const arrWithName = feeSubtypeArr.filter((item) => {
      if (item.id === parseInt(id)) {
        return item.name;
      }
    });
    let firstArr = arrWithName[0];
    setidtopass(firstArr.id);
    settitle(firstArr.name);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">View Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height pb-2">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label className="text_filed_heading">
                                Title for Invoice
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title for Invoice"
                                disabled={true}
                                value={invoiceTitle}
                                onChange={(event) => {
                                  setinvoicetitleError(false);
                                  setInvoiceTitle(event.target.value);
                                }}
                              />
                              {invoicetitleError && (
                                <span className="text-danger">
                                  Enter the Invoice Title
                                </span>
                              )}
                            </div>
                          </Grid>
                          {/* <Grid item md={4}>
                            <div className="inputMarginright">
                              <label className="text_filed_heading">
                                Recurrence
                              </label>
                              <select
                                className="form-control"
                                value={recurrence}
                                disabled={true}
                                onChange={(e) => {
                                  setrecurrenceError(false);
                                  setRecurrence(e.target.value);
                                }}
                              >
                                <option>Recurrence</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Half Yearly">Half Yearly</option>
                                <option value="Yearly">Yearly</option>
                              </select>
                              {recurrenceError && (
                                <span className="text-danger">
                                  Select the recurrence
                                </span>
                              )}
                            </div>
                          </Grid> */}
                          <Grid item md={4}>
                            {" "}
                            <div className="inputMarginright">
                              <label className="text_filed_heading">
                                Due Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                value={dueDate}
                                disabled={true}
                                onChange={(e) => {
                                  setduedateError(false);
                                  setDueDate(e.target.value);
                                }}
                              />
                              {duedateError && (
                                <span className="text-danger">
                                  Enter the Due Date
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label className="text_filed_heading">
                                Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Description"
                                disabled={true}
                                value={description}
                                onChange={(e) => {
                                  setdescriptionError(false);
                                  setDescription(e.target.value);
                                }}
                              />
                              {descriptionError && (
                                <span className="text-danger">
                                  Enter the Description
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={4}>
                            {pageType === "ManageInvoice" ||
                            pageType === "ClassInvoice" ? (
                              ""
                            ) : (
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Invoice ID
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Invoice ID"
                                  disabled={true}
                                  value={invoiceId}
                                  onChange={(e) => {
                                    setinvoiceId(e.target.value);
                                  }}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>
                        {!createfeetype ? (
                          <div className="inputMarginright createinvoiceflex mt-3">
                            <div className="text_filed_heading">
                              Fee Components
                            </div>
                            {selectedTemplateId >= 0 && (
                              <div
                                className="d-flex"
                                onClick={handlefeeComponent}
                              >
                                <span className="iconstoaddfee">
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                                <span className=" addmanageuserfont mt-1 ml-2">
                                  Add Fee Subtype
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="mt-3">
                            <Expand open={createfeetype}>
                              <Card className="cardvisible_border mb-2">
                                <div className="card_admissiondetails_height">
                                  <div className="textfiled_margin float-right">
                                    <div>
                                      <span className="iconstoaddfee">
                                        <i
                                          className="fa fa-minus-circle"
                                          onClick={closefeeComponent}
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="textfiled_margin mb-2">
                                    <Grid className="grid_main_div">
                                      <Grid item md={12}>
                                        <div className="inputMarginright">
                                          <label className="text_filed_heading">
                                            Fee Subtype
                                          </label>
                                          <select
                                            className="form-control"
                                            onChange={(e) =>
                                              handleFeeSubType(e.target.value)
                                            }
                                          >
                                            <option value={-1}>
                                              Select Template
                                            </option>
                                            {feeSubtypeArr.map(
                                              (item, index) => (
                                                <option
                                                  value={item.id}
                                                  key={index}
                                                >
                                                  {item.name}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="btn_add">
                                    <Button
                                      variant="contained"
                                      className="button_login_decoration addButtonwidth"
                                      onClick={AddFeeSubTypeFn}
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            </Expand>
                          </div>
                        )}

                        <div className="mb-3">
                          {createinvoice.length > 0 &&
                            createinvoice.map((item, index) => (
                              <Card
                                className="cardvisible_border mb-2"
                                key={index}
                              >
                                <Grid className="grid_main_div">
                                  <Grid item md={3}>
                                    <div className="inputMarginright d-flex m-3">
                                      <h6
                                        style={{
                                          fontSize: 15,
                                          paddingTop: 10,
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {item.feesubtype?.name}
                                      </h6>
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="inputMarginright d-flex m-3">
                                      <label className="text_filed_heading pr-2">
                                        Amount({currencyCode})
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Amount"
                                        value={item.amount}
                                        disabled={true}
                                        onChange={(e) => {
                                          createinvoice[index]["amount"] =
                                            e.target.value;
                                          let temp = [];
                                          let totalamount = 0;
                                          createinvoice.map((item) => {
                                            (totalamount =
                                              totalamount +
                                              parseFloat(item.amount) +
                                              (parseFloat(item.amount) *
                                                parseFloat(item.tax)) /
                                                100).toFixed(2);
                                            temp.push(item);
                                          });
                                          // setTotalamount(totalamount);
                                          setcreateinvoice(temp);
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="inputMarginright d-flex m-3">
                                      <label className="text_filed_heading pr-2">
                                        Tax(%)
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Amount"
                                        value={item.tax}
                                        disabled={true}
                                        onChange={(e) => {
                                          createinvoice[index]["tax"] =
                                            e.target.value;
                                          let temp = [];
                                          let totalamount = 0;
                                          createinvoice.map((item) => {
                                            temp.push(item);
                                            (totalamount =
                                              totalamount +
                                              parseFloat(item.amount) +
                                              (parseFloat(item.amount) *
                                                parseFloat(item.tax)) /
                                                100).toFixed(2);
                                          });
                                          // setTotalamount(totalamount);
                                          setcreateinvoice(temp);
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="inputMarginright d-flex m-3">
                                      <label className="text_filed_heading pr-2">
                                        Total Amount({currencyCode})
                                      </label>
                                      <input
                                        disabled={true}
                                        type="text"
                                        className="form-control"
                                        placeholder="Total Amount"
                                        value={(
                                          parseFloat(item.amount) +
                                          (parseFloat(item.amount) *
                                            parseFloat(item.tax)) /
                                            100
                                        ).toFixed(2)}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Card>
                            ))}
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="inputMarginright">
                              <label className="text_filed_heading">
                                Total Amount({currencyCode})
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled={true}
                                placeholder="Total Amount"
                                value={totalamount}
                              />
                            </div>
                          </Grid>
                          <Grid item md={8}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(EditFeeInvoice));
