import React, { Fragment, Component } from "react";
import AdminMainHeader from "./Header/AdminMainHeader";
import TeacherHeader from "./Header/TeacherHeader";
import StudentHeader from "./Header/StudentHeader";
import ParentHeader from "./Header/ParentHeader";
import HeaderSuperAdmin from "./Header/HeaderSuperAdmin";
import EmployeHeader from "./Header/EmployeHeader";
import Footer from "./Footer/Footer.jsx";
import { connect } from "react-redux";
import { getData } from "../api/index";
import * as Constants from "../action/appAction";
import { blankValidator } from "../utils/Validation";
import PtAdminMainHeader from "./Header/PtAdminMainHeader";
import PtTeacherHeader from "./Header/PtTeacherHeader";
import PtStudentHeader from "./Header/PtStudentHeader";

export default (Wcomponent) => {
  class Header extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isloading: false,
      };
    }
    setLoading = () => {
      try {
        this.setState({
          isloading: !this.state.isloading,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getHeader = (data) => {
      let usertype = data.usertype;
      let type = data.type;
      if (usertype == "SUPERADMIN" || usertype == "Superadmin") {
        return (
          <HeaderSuperAdmin setLoading={this.setLoading} {...this.props} />
        );
      } else if (usertype == "ADMIN" || usertype == "Admin") {
        if (type === "PT" || type === "ET") {
          return (
            <PtAdminMainHeader setLoading={this.setLoading} {...this.props} />
          );
        } else {
          return (
            <AdminMainHeader setLoading={this.setLoading} {...this.props} />
          );
        }
      } else if (usertype == "TEACHER" || usertype == "Teacher") {
        if (type === "PT" || type === "ET") {
          return (
            <PtTeacherHeader setLoading={this.setLoading} {...this.props} />
          );
        } else {
          return <TeacherHeader setLoading={this.setLoading} {...this.props} />;
        }
      } else if (usertype == "STUDENT" || usertype == "Student") {
        if (type === "PT" || type === "ET") {
          return (
            <PtStudentHeader setLoading={this.setLoading} {...this.props} />
          );
        } else {
          return <StudentHeader setLoading={this.setLoading} {...this.props} />;
        }
      } else if (usertype == "PARENT" || usertype == "Parent") {
        return <ParentHeader setLoading={this.setLoading} {...this.props} />;
      } else {
        return <EmployeHeader setLoading={this.setLoading} {...this.props} />;
      }
    };

    render() {
      return (
        <Fragment>
          <div className="app-container-hoc main-margin">
            {blankValidator(this.props?.data) &&
              this.getHeader(this.props?.data)}

            {/* {(this.props.data.usertype == "EMPLOYE" ||
              this.props.data.usertype == "Employe") && (
              <EmployeHeader setLoading={this.setLoading} {...this.props} />
            )} */}
            {/* {(this.props.data.usertype == "EMPLOYE" ||
              this.props.data.usertype == "Employe") && (
              <TransportHeader setLoading={this.setLoading} {...this.props} />
            )} */}

            <div className="main_back_color1">
              <Wcomponent {...this.props} />
            </div>
            <Footer {...this.props} />
          </div>
        </Fragment>
      );
    }
  }
  const mapStateToProps = (state) => ({
    data: getData(state, Constants.TOKEN),
  });
  return connect(mapStateToProps, null)(Header);
};
