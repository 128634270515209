import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import {
  blankValidator,
  timeStampToDatedashtoDDMMYY,
} from "../../../utils/Validation";

function CommonExamViewResult(props) {
  const classes = useStyles();

  // ----------------METHODS-------------------

  let exam_result = [];

  if (blankValidator(props.data)) {
    exam_result = props.data;
  }

  return (
    <div className="main_full_width">
      <Grid className="grid_main_div">
        <Grid item md={12}>
          <div className="card_admissiondetails_height">
            <div className="mb-2">
              <div className="table_foramtitng mt-2">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="table_header" align="left">
                          Subject
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Exam Date
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Max. Marks
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Marks
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Result
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          Exam Mode
                        </StyledTableCell>
                        <StyledTableCell align="left" className="table_header">
                          FeedBack
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {blankValidator(exam_result) ? (
                        exam_result.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {!blankValidator(row.subject.name)
                                ? ""
                                : row?.subject?.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {timeStampToDatedashtoDDMMYY(
                                row?.start_calendar?.datetime
                              )}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row?.marks}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.exam_result?.marks}
                            </StyledTableCell>
                            <StyledTableCell align="left">A+</StyledTableCell>

                            <StyledTableCell align="left">
                              {row?.location?.mode}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              FeedBack
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <StyledTableRow>No data</StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default CommonExamViewResult;
