import React, { useState, useEffect } from "react";
import "./AdmissionIntrest.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { blankValidator, getFullName } from "../../../utils/Validation";
import Dialog from "@material-ui/core/Dialog";
//---------------api calls-------------------
import {
  GET_ALL_ACADEMIC_SESSION,
  Get_Admission_Interest_Data,
  Get_Manage_Grade,
} from "../../../services/index";
import { useApolloClient } from "@apollo/client";
//---------------redux-------------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import { TOKEN } from "../../../action/appAction";

const AdminStudentAdmission = (props) => {
  //---------------global id-------------------
  const { institution_id, institution_name, academic_session_id } =
    props.USER_DATA;
  //---------------api calls-------------------
  const client = useApolloClient();
  const classes = useStyles();
  //---------------local state-------------------
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clasName, setClasName] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [admissionInterestArr, setAdmissionInterestArr] = useState([]);
  const [gradeArr, setGradeArr] = useState([]);
  const [academicSessionArr, setAcademicSessionArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //---------------methods-------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    getAdminIntData();
    getAllAcademicData();
    getGradeData();
  }, []);

  const getAllAcademicData = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: GET_ALL_ACADEMIC_SESSION,
        variables: {
          institution_id: +institution_id,
        },
      });

      if (data && data.getAllAcademicSession) {
        setAcademicSessionArr(data.getAllAcademicSession);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const getAdminIntData = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Get_Admission_Interest_Data,
        variables: {
          institution_id: +institution_id,
        },
      });

      if (data && data.getAddmissionEnquiry) {
        const { getAddmissionEnquiry } = data;
        if (getAddmissionEnquiry.length > 0) {
          const acceptedArr = getAddmissionEnquiry.filter((item) => {
            return item.status === "Accepted";
          });
          setAdmissionInterestArr(acceptedArr);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const getGradeData = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Get_Manage_Grade,
        variables: {
          academic_session_id: +academic_session_id,
        },
      });

      if (data && data.getGrade) {
        setGradeArr(data.getGrade);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //-------------handle assign dialog----------
  const assignDialogHandler = (ClassName) => {
    setClasName(ClassName);
    setDialogOpen(true);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-2 ml-4 Heading_bold">
                    Admin Student Admission
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={4}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control "
                                id="exampleFormControlSelect1"
                              >
                                <option>Academic Session</option>
                                {academicSessionArr.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.session_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="inputMarginright  mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Class</option>
                                {gradeArr.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="button_text_formating mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn search_btn_width button_login_decoration "
                              >
                                Search
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="table_foramtitng mt-5">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Student Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Class
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Parent Name
                                  </StyledTableCell>
                                  <StyledTableCell className="table_header">
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Actions
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? admissionInterestArr.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : admissionInterestArr
                                ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">
                                      {getFullName(row.studentName)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {!blankValidator(row.grade) ? (
                                        "No Data"
                                      ) : (
                                        <span>{row.grade}</span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                      onClick={() =>
                                        assignDialogHandler(row.grade)
                                      }
                                    >
                                      Assign
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {getFullName(row.fatherName)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {!blankValidator(row.status) ? (
                                        "No Data"
                                      ) : (
                                        <span>{row.status.toUpperCase()}</span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="row_details_color"
                                      onClick={() => {
                                       props.navigate(
                                          "/studentDetailForm",
                                          { state:{
                                            pagetype: "edit",
                                            data: {
                                              student_profile: {
                                                id: row.id,
                                              },
                                            },
                                          }}
                                        );
                                      }}
                                    >
                                      Open
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={admissionInterestArr.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setDialogOpen(false);
                }}
              ></i>
            </div>
          </div>
          <div className="mt-4">
            <p>{clasName}</p>
          </div>
          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
      <Loder loading={isLoading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  USER_DATA: getData(state, TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(AdminStudentAdmission)));
