import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import "./Feemodule.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TotalCountInvoice from "./TotalCountInvoice";

const FeeMenus = (props) => {
  const { institution_id, academic_session_id, institution_name, type } =
    props.USER_DATA;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />

              <div className="enquirydetails_background mb-2">
                <Grid className="grid_main_div">
                  <Grid item md={6}>
                    <div className="enquiry_heading">
                      <div className="ml-3 mt-2 Heading_bold">Fees</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <TotalCountInvoice
                      id={institution_id}
                      inst_id={institution_id}
                      acdmin_id={academic_session_id}
                    />
                  </Grid>
                </Grid>

                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_margin_both_side mt-2 mb-2">
                      <div>
                        <Grid className="grid_main_div">
                          <Grid item md={3}>
                            {" "}
                            <div
                              className="pt-2 pb-2 inputMarginright_set"
                              onClick={() =>
                                props.navigate("/studentinvoice", {
                                  state: {
                                    classId: undefined,
                                    sectionId: undefined,
                                  },
                                })
                              }
                            >
                              <div className="font_size_of_icons">
                                <i class="fa fa-user"></i>
                              </div>
                              <div className="blueline_under"></div>

                              <div>Student Invoice</div>
                            </div>
                          </Grid>{" "}
                          {type === "PT" || type === "ET" ? (
                            ""
                          ) : (
                            <Grid item md={3}>
                              <div
                                className="pt-2 pb-2 inputMarginright_set"
                                onClick={() =>
                                  props.navigate("/feesubtypesetup")
                                }
                              >
                                <div className="font_size_of_icons">
                                  <i class="fa fa-file"></i>
                                </div>
                                <div className="blueline_under"></div>

                                <div>Manage Fee Subtype</div>
                              </div>
                            </Grid>
                          )}
                          <Grid item md={3}>
                            <div
                              className="pt-2 pb-2 inputMarginright_set"
                              onClick={() => props.navigate("/manageinvoice")}
                            >
                              <div className="font_size_of_icons">
                                <i class="fa fa-file"></i>
                              </div>
                              <div className="blueline_under"></div>

                              <div>Manage Invoice</div>
                            </div>
                          </Grid>
                          {type === "PT" || type === "ET" ? (
                            ""
                          ) : (
                            <Grid item md={3}>
                              <div
                                className="pt-2 pb-2 inputMarginright_set"
                                onClick={() =>
                                  props.navigate("/invoicetemplate")
                                }
                              >
                                <div className="font_size_of_icons">
                                  <i class="fa fa-file"></i>
                                </div>
                                <div className="blueline_under"></div>

                                <div>Manage Invoice Template</div>
                              </div>
                            </Grid>
                          )}
                        </Grid>

                        {/* <div
                          className="pt-2 pb-2 inputMarginright_set"
                          onClick={() =>props.navigate("/classInvoice")}
                        >
                          <div className="font_size_of_icons">
                            <i class="fa fa-users"></i>
                          </div>
                          <div className="blueline_under"></div>

                          <div>Class Invoice</div>
                        </div> */}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <NotificationContainer/> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  USER_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(FeeMenus));
