import React, { useState, useEffect } from "react";
import "./Notice.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import RichTextEditor from "react-rte";

import {
  baseUrl,
  Add_School_Notice_Data,
  Update_Notice_Data,
  Get_Manage_Grade,
  GET_Section_Data,
} from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  timeStampToDatedash,
  currentDayForMinMAx,
  getFullName,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Dialog from "@material-ui/core/Dialog";
const CreateNotice = (props) => {
  const { item } = props.location.state;

  //instituiion id

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  let academic_session_id = parseInt(props.location.state.academic_session);
  let institution_id = parseInt(props.data.institution_id);
  let user_Id = parseInt(props.data.userid);
  let userName = getFullName(props.data);

  //userType
  let userType = props.data.usertype;

  //page Type
  let pageType = props.location.state.pageType;
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [filename, setFilename] = useState("Add Attachment");
  const [title, settitle] = useState("");
  const [publishdate, setpublishdate] = useState("");
  const [expirydate, setexpirydate] = useState("");
  const [noticetype] = useState("CLASS");
  const [uploadFileArr, setUploadFileArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [GradeId, setGradeId] = useState("");
  const [subject, setsubject] = useState("");
  const [EditId, setEditId] = useState("");

  //error
  const [titleError, settitleError] = useState(false);
  const [publishdateError, setpublishdateError] = useState(false);
  const [expirydateError, setexpirydateError] = useState(false);

  const [publisherNameError, setpublisherNameError] = useState(false);

  const [ContentError, setContentError] = useState(false);
  const [sectionError, setsectionError] = useState(false);
  const [GradeIdError, setGradeIdError] = useState(false);
  const [subjectError, setsubjectError] = useState(false);

  const client = useApolloClient();

  //add Notice Mutation
  const [addSchoolNoticeAPI, {}] = useMutation(Add_School_Notice_Data);

  //Update Notice Mutation
  const [updateNoticeAPI, {}] = useMutation(Update_Notice_Data);

  const onChange = (value) => {
    setContentError(false);
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  useEffect(() => {
    if (pageType === "Edit") {
      let NoticeData = props.location.state.item;
      setEditId(NoticeData.id);
      settitle(NoticeData.title);
      setpublishdate(timeStampToDatedash(NoticeData.publish_date));
      setexpirydate(timeStampToDatedash(NoticeData.expiry_date));

      setsubject(NoticeData.subject);
      setUploadFileArr(NoticeData.documents);

      let messagedata = RichTextEditor.createValueFromString(
        NoticeData.content.toString("html"),
        "html"
      );

      setValue(messagedata);
    }

    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            const { getGrade } = response.data;
            if (getGrade && getGrade.length !== 0) {
              setGradeArr(response.data.getGrade);

              if (item && item.section) {
                const { grade } = item.section;
                setGradeId(grade.id);
                changeGrade(grade.id);
              }
            }
            setisloading(false);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getManageGrades();
  }, [client, academic_session_id]);

  const changeGrade = (val) => {
    let grade_id = parseInt(val);
    setGradeId(grade_id);
    if (grade_id === -1) {
      setSectionArr([]);
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          const { getSectionAndGrade } = response.data;
          if (getSectionAndGrade && getSectionAndGrade.length > 0) {
            setSectionArr(response.data.getSectionAndGrade);
            if (item && item.section) {
              setSectionId(item.section.id);
            }
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const changeSection = (val) => {
    let seciontId = parseInt(val);
    setSectionId(seciontId);
  };

  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFilename(file.name);
          setUploadFileArr([...uploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const createNoticeHandler = () => {
    let flag = false;
    if (!blankValidator(title)) {
      settitleError(true);
      flag = true;
    }
    if (!blankValidator(publishdate)) {
      setpublishdateError(true);
      flag = true;
    }
    if (!blankValidator(expirydate)) {
      setexpirydateError(true);
      flag = true;
    }
    if (!blankValidator(GradeId) || GradeId === -1) {
      setGradeIdError(true);
      flag = true;
    }
    if (!blankValidator(sectionId) || sectionId === -1) {
      setsectionError(true);
      flag = true;
    }
    if (!blankValidator(subject)) {
      setsubjectError(true);
      flag = true;
    }

    if (value.toString("html") === "<p><br></p>") {
      setContentError(true);
      flag = true;
    }
    if (flag) {
      return;
    }

    if (pageType === "Edit") {
      let temp = [];
      uploadFileArr.map((item) => {
        temp.push({
          name: item.name,
          filename: item.filename,
          type: item.type,
          path: item.path,
        });
      });
      try {
        setisloading(true);
        updateNoticeAPI({
          variables: {
            id: EditId,
            institution_id,
            title: title,
            subject: subject,
            notice_type: noticetype,
            section_id: sectionId,
            publisher_id: user_Id,
            publisher_type: userType,
            publish_date: publishdate,
            expiry_date: expirydate,
            location: "",
            content: value.toString("html"),
            notice_board_doc: temp,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.updateNoticeBoard.message,
              "success"
            );

            props.navigate.goBack();
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    } else
      try {
        setisloading(true);
        addSchoolNoticeAPI({
          variables: {
            institution_id,
            title: title,
            subject: subject,
            notice_type: noticetype,
            section_id: sectionId,
            publisher_id: user_Id,
            publisher_type: userType,
            publish_date: publishdate,
            expiry_date: expirydate,
            location: "",
            content: value.toString("html"),
            notice_board_doc: uploadFileArr,
          },
        })
          .then((response) => {
            setisloading(false);
            showNotificationMsz(
              response.data.addNoticeBoard.message,
              "success"
            );

            props.navigate.goBack();
          })
          .catch((error) => {
            setisloading(false);
            console.log("catch error", error);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">
                    {pageType === "Edit" ? (
                      <span>Edit Class Notice</span>
                    ) : (
                      <span>Create Class Notice</span>
                    )}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-2 inputMarginright">
                              <label for="exampleInputEmail1">Title</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Title"
                                  autoComplete="off"
                                  value={title}
                                  onChange={(e) => {
                                    settitleError(false);
                                    settitle(e.target.value);
                                  }}
                                />
                                {titleError && (
                                  <span className="text-danger">
                                    Enter Notice Title
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Publish Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  min={currentDayForMinMAx()}
                                  value={publishdate}
                                  onChange={(e) => {
                                    setpublishdateError(false);
                                    setpublishdate(e.target.value);
                                  }}
                                />
                                {publishdateError && (
                                  <span className="text-danger">
                                    Enter Published Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">
                                Expiry Date
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  min={publishdate}
                                  value={expirydate}
                                  onChange={(e) => {
                                    setexpirydateError(false);
                                    setexpirydate(e.target.value);
                                  }}
                                />
                                {expirydateError && (
                                  <span className="text-danger">
                                    Enter Expiry Date
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">
                                Type of Notice
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                placeholder="Publisher Name"
                                value={noticetype}
                                disabled={true}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Class</label>
                              <select
                                className="form-control"
                                value={GradeId}
                                onChange={(e) => {
                                  setGradeIdError(false);
                                  changeGrade(e.target.value);
                                }}
                              >
                                <option value={-1}>Search By Class</option>

                                {gradeArr.length > 0 ? (
                                  gradeArr.map((item, index) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                              {GradeIdError && (
                                <p className="text-danger">Select Class Name</p>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">Section</label>
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                value={sectionId}
                                onChange={(e) => {
                                  setsectionError(false);
                                  changeSection(e.target.value);
                                }}
                              >
                                <option value={-1}>Search By Section</option>

                                {sectionArr.length > 0 ? (
                                  sectionArr.map((item, index) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                            </div>
                            {sectionError && (
                              <p className="text-danger">Select Section Name</p>
                            )}
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div">
                          <Grid item md={6}>
                            <div className="mt-2 inputMarginright">
                              <label for="exampleInputEmail1">Subject</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Subject"
                                  autoComplete="off"
                                  value={subject}
                                  onChange={(e) => {
                                    setsubjectError(false);
                                    setsubject(e.target.value);
                                  }}
                                />
                                {subjectError && (
                                  <span className="text-danger">
                                    Enter Subject of Notice
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="mt-2">
                              <label for="exampleInputEmail1">
                                Name of Publisher
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  placeholder="Publisher Name"
                                  value={userName}
                                  disabled={true}
                                />
                                {publisherNameError && (
                                  <span className="text-danger">
                                    Enter Publisher Name
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="">
                              <label for="exampleInputEmail1">Attachment</label>
                              <div className=" mb-2 d-flex">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                      <i class="fa fa-paperclip"></i>
                                    </span>
                                  </div>
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="inputGroupFile01"
                                      onChange={ImageUploadHandler}
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="inputGroupFile01"
                                    >
                                      {filename}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <label className="mt-2">Attachment Lists</label>
                        <div className=" mb-2 attachList">
                          {uploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2"
                              >
                                {item.name}
                              </span>
                              <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() => {
                                    uploadFileArr.splice(index, 1);
                                    setUploadFileArr([...uploadFileArr]);
                                  }}
                                ></i>
                              </span>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2 mb-2">
                          <label for="exampleInputEmail1">Content</label>
                          <div className="mt-1">
                            <RichTextEditor
                              value={value}
                              onChange={onChange}
                              className="editor_height"
                            />
                            {ContentError && (
                              <span className="text-danger">
                                Enter Message of Notice
                              </span>
                            )}
                          </div>
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={createNoticeHandler}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)((HOC1(CreateNotice)));
