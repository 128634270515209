import React from "react";

const SubText = ({ headerTitle, headerValue, subTitle, subTitleValue }) => {
  return (
    <>
      <div>
        <span className="page_header">{headerTitle && headerTitle} : </span>
        <span> {headerValue && headerValue} </span>
      </div>
      <div>
        <span className="page_header">{subTitle && subTitle} : </span>
        <span> {subTitleValue && subTitleValue} </span>
      </div>
    </>
  );
};

export default SubText;
