import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Onboarding.css";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import Loder from "../../Loder/Loder.jsx";
import { store } from "react-notifications-component";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  serverDateFormat,
  showNotificationMsz,
  currentDayForMinMAx,
  emailValidator,
} from "../../../utils/Validation.js";
import { highestQualificationData } from "../../../utils/LocalData.js";

const PtStudentForm = ({ ADMIN_DATA, navigate }) => {
  const {
    locale,
    academic_session_id,
    institution_id,
    institution_name,
    organization_id,
  } = ADMIN_DATA;

  console.log("ADMIN_DATA ", ADMIN_DATA);
  const [Add_Student_Admission_Data, {}] = useMutation(
    Service.Add_Student_Admission_Data_Private
  );

  const client = useApolloClient();

  const [photo, setphoto] = useState({});
  const [birthCertificate, setbirthCertificate] = useState({});
  const [nation, setnation] = useState({});
  const [immuneCertificate, setimmuneCertificate] = useState({});
  const [schoolLeaveCertificate, setschoolLeaveCertificate] = useState({});
  const [othersarr, setothersarr] = useState([]);

  const [isloading, setisloading] = useState(false);

  const [gradeId, setGradeId] = useState(null);
  const [managegrade, setmanagegrade] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [section, setsection] = useState([]);
  const [selectroute, setselectroute] = useState(false);
  const [buttonCheck, setbuttonCheck] = useState(false);
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [pickupRouteArr, setPickupRouteArr] = useState([]);
  const [dropRouteArr, setDropRouteArr] = useState([]);
  const [pickupStartArr, setPickupStartArr] = useState([]);
  const [pickupRouteName, setPickupRouteName] = useState("");
  const [dropRouteName, setDropRouteName] = useState("");
  const [pickupStopName, setPickupStopName] = useState("");
  const [dropStopName, setDropStopName] = useState("");
  const [dropStopArr, setDropStopArr] = useState([]);
  const [sessionArr, setSessionArr] = useState([]);
  const [academic_session, setacademic_session] = useState(-1);
  const [sessionIdError, setsessionIdError] = useState(false);
  const [sectionArr, setSectionArr] = useState([]);
  const [joinDateError, setJoinDateError] = useState(false);

  const selectPickupStartHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setStudentformdata({
        ...studentformdata,
        transport_drop_id: null,
      });
      return;
    }
    const newArr = pickupStartArr.filter((item) => {
      if (item.value === id) return item;
    });
    setPickupStopName(newArr[0].label);
    setStudentformdata({
      ...studentformdata,
      transport_drop_id: e.target.value,
    });
  };

  const selectDropStopHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      return;
    }
    const newArr = dropStopArr.filter((item) => {
      if (item.value === id) return item;
    });
    setDropStopName(newArr[0].label);
  };

  const [studentformdata, setStudentformdata] = useState({
    primary_contact: "FATHER",
    Student_first_name: "",
    Student_middle_name: "",
    Student_last_name: "",
    institution_id: "",
    joining_date: "",

    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Father_email_address: "",
    Father_phone_number: "",

    academic_session_id: "",
    grade_id: "",
    section_id: -1,
    declarationCheck: false,
  });

  const fillAddress = (e) => {
    setCheckbox(e.target.checked);
    if (e.target.checked === true) {
      setPaddressline1Error(false);
      setPcountryError(false);
      setPstateError(false);
      setPPincodeError(false);
      setStudentformdata({
        ...studentformdata,
        Permanent_line1: studentformdata.Current_line1,
        Permanent_line2: studentformdata.Current_line2,
        Permanent_city: studentformdata.Current_city,
        Permanent_state: studentformdata.Current_state,
        Permanent_zip_code: parseInt(studentformdata.Current_zip_code),
        Permanent_country: studentformdata.Current_country,
      });
    } else
      setStudentformdata({
        ...studentformdata,
        Permanent_line1: "",
        Permanent_line2: "",
        Permanent_city: "",
        Permanent_state: "",
        Permanent_zip_code: "",
        Permanent_country: "",
      });
  };
  useEffect(() => {
    getacademicSession();
  }, []);

  const getacademicSession = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_ALL_ACADEMIC_SESSION,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setSessionArr(response.data.getAllAcademicSession);
        })

        .catch((e) => {
          setisloading(false);
          console.log("catch y error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectSessionHandler = (id) => {
    setisloading(true);
    setsessionIdError(false);
    setacademic_session(id);
    setmanagegrade([]);
    setStudentformdata({
      ...studentformdata,
      grade_id: "",
    });
    setGradeId(-1);
    if (id === -1) {
      setsessionIdError(true);
      return;
    }

    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setmanagegrade(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const selectClassHandler = (ID) => {
    let ClassId = parseInt(ID);
    if (ClassId === -1) {
      setStudentformdata({
        ...studentformdata,
        grade_id: "",
      });
      return;
    }
    setgradeIdError(false);
    setGradeId(ClassId);
    setStudentformdata({
      ...studentformdata,
      grade_id: ClassId,
    });
    client
      .query({
        query: Service.GET_Section_Data,
        variables: {
          grade_id: +ID,
        },
      })
      .then((response) => {
        setisloading(false);
        setsection(response.data.getSectionAndGrade);
      })
      .catch((e) => {
        setisloading(false);
        console.log("catch error", e);
      });
  };

  const PrimaryContact = (e) => {
    setStudentformdata({
      ...studentformdata,
      primary_contact: e.target.value,
    });
  };
  const UploadDocumentHandler = (e, type) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);
    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          // const temp = [];
          // temp.push(obj);
          if (type === "PHOTO") {
            setphoto(obj);
          }
          if (type === "BIRTH_CERTIFICATE") {
            setbirthCertificate(obj);
          }
          if (type === "NATIONAL_CARD") {
            setnation(obj);
          }

          if (type === "IMMUNIZATION") {
            setimmuneCertificate(obj);
          }
          if (type === "SCHOOL_LEAVE_CERTIFICATE") {
            setschoolLeaveCertificate(obj);
          }
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error.message, "danger");
    }
  };

  //error decalare
  const [gradeIdError, setgradeIdError] = useState(false);
  const [StudentFirstNameError, setStudentFirstNameError] = useState(false);
  const [declarationcheckedError, setdeclarationcheckedError] = useState(false);
  const [BirthdateError, setBirthdateError] = useState(false);
  const [NationalityError, setNationalityError] = useState(false);
  const [Caddressline1Error, setCaddressline1Error] = useState(false);
  const [CcountryError, setCcountryError] = useState(false);
  const [CstateError, setCstateError] = useState(false);
  const [CPincodeError, setCPincodeError] = useState(false);
  const [Paddressline1Error, setPaddressline1Error] = useState(false);
  const [PcountryError, setPcountryError] = useState(false);
  const [PstateError, setPstateError] = useState(false);
  const [PPincodeError, setPPincodeError] = useState(false);
  const [MfirstnameError, setMfirstnameError] = useState(false);

  const [MPhoneError, setMPhoneError] = useState(false);
  const [FfirstnameError, setFfirstnameError] = useState(false);

  const [FPhoneError, setFPhoneError] = useState(false);
  const [sectionIdError, setsectionIdError] = useState(false);
  const [fEmailIdError, setFEmailIdError] = useState(false);

  const studentDetaiform = async () => {
    let validationDone = false;
    let scroll = false;
    if (
      !blankValidator(studentformdata.grade_id) ||
      studentformdata.grade_id === -1
    ) {
      setgradeIdError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("gradeID").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(studentformdata.section_id) ||
      studentformdata.section_id === -1
    ) {
      setsectionIdError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("section").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.joining_date)) {
      setJoinDateError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("joinID").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.Student_first_name)) {
      setStudentFirstNameError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("s_firstNamee").focus();
      }
      scroll = true;
    }

    if (!blankValidator(studentformdata.Father_first_name)) {
      setFfirstnameError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("F_name").focus();
      }
      scroll = true;
    }
    if (!emailValidator(studentformdata.Father_email_address)) {
      setFEmailIdError(true);
      validationDone = true;
      if (scroll === false) {
        document.getElementById("F_email").focus();
      }
      scroll = true;
    }
    if (!blankValidator(studentformdata.Father_phone_number)) {
      setFPhoneError(true);
      validationDone = true;
    }

    if (validationDone) {
      return;
    }
    console.log("paylod---", {
      primary_contact: studentformdata.primary_contact,
      Student_first_name: studentformdata.Student_first_name,
      Student_middle_name: studentformdata.Student_middle_name,
      Student_last_name: studentformdata.Student_last_name,
      institution_id,
      joining_date: serverDateFormat(studentformdata.joining_date),
      section_id: parseInt(studentformdata.section_id),

      Father_first_name: studentformdata.Father_first_name,
      Father_middle_name: studentformdata.Father_middle_name,
      Father_last_name: studentformdata.Father_last_name,
      Father_email_address: studentformdata.Father_email_address,
      Father_phone_number: studentformdata.Father_phone_number,
      organization_id,
      academic_session_id,
    });
    try {
      setisloading(true);
      await Add_Student_Admission_Data({
        variables: {
          primary_contact: studentformdata.primary_contact,
          Student_first_name: studentformdata.Student_first_name,
          Student_middle_name: studentformdata.Student_middle_name,
          Student_last_name: studentformdata.Student_last_name,
          institution_id,
          joining_date: serverDateFormat(studentformdata.joining_date),
          Father_first_name: studentformdata.Father_first_name,
          Father_middle_name: studentformdata.Father_middle_name,
          Father_last_name: studentformdata.Father_last_name,
          Father_email_address: studentformdata.Father_email_address,
          Father_phone_number: studentformdata.Father_phone_number,
          academic_session_id,
          join_grade_id: +studentformdata.grade_id,
          section_id: parseInt(studentformdata.section_id),
          photo: photo === undefined && photo === null ? {} : photo,
          birth_cert:
            birthCertificate === undefined && birthCertificate === null
              ? {}
              : birthCertificate,
          immunization_doc:
            immuneCertificate === undefined && immuneCertificate === null
              ? {}
              : immuneCertificate,
          leaving_cert_doc:
            schoolLeaveCertificate === undefined &&
            schoolLeaveCertificate === null
              ? {}
              : schoolLeaveCertificate,
          other: othersarr,
          organization: +organization_id,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response?.data?.addPrivateStudent?.message,
            "success"
          );

          navigate("/adminstudentlistview");
        },
        (error) => {
          setisloading(false);
          console.log("then error", error);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  // upload documents
  const UploadMultipleDocumentHandler = async (e) => {
    if (e.target.files.length > 4) {
      showNotificationMsz(`Cannot Upload More than 4 files`, "info");
      return;
    }

    const files = e.target.files;

    let fileresponse = [];
    try {
      setisloading(true);
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        const url = `${Service.baseUrl}/uploadFile/image`;
        let fileData = new FormData();
        fileData.append("file", element);

        await axios
          .post(url, fileData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setisloading(false);
            const obj = {
              path: response.data.path,
              filename: response.data.filename,
              type: response.data.type,
              name: response.data.name,
            };
            fileresponse.push(obj);

            //temp.push(obj);
          })
          .catch((error) => {
            console.log("catch error", error);
            showNotificationMsz(error.message, "danger");
          });

        setothersarr([...fileresponse]);
        // setStudentformdata({ ...studentformdata, others_doc: fileresponse });
      }
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }

    setisloading(false);
  };

  const selectTransportRoute = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.GET_Route_List_Data,
        variables: {
          institution_id,
        },
      });

      if (data && data.getTransportRoute) {
        let pickupArr = [];
        let dropArr = [];
        data.getTransportRoute.map((item) => {
          const { route_type, route_name, id } = item;
          if (route_type === "PICK") {
            pickupArr.push({
              label: route_name,
              value: id,
            });
          } else if (route_type === "DROP") {
            dropArr.push({
              label: route_name,
              value: id,
            });
          }
        });
        setPickupRouteArr(pickupArr);

        setDropRouteArr(dropArr);
      }

      setisloading(false);
      setselectroute(true);

      // .then((response) => {
      //   let temp = [];
      //   setisloading(false);
      //   for (
      //     let index = 0;
      //     index < response.data.getTransportRoute.length;
      //     index++
      //   ) {
      //     temp.push({
      //       label: response.data.getTransportRoute[index].route_name,
      //       value: response.data.getTransportRoute[index].id,
      //     });
      //   }
      //   setPickupRouteArr(temp);
      //   setselectroute(true);
      // })
      // .catch((err) => {
      //   setisloading(false);

      // });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectPickupRouteHandler = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setStudentformdata({
        ...studentformdata,
        transport_pickup_id: null,
      });
      return;
    }
    const newArr = pickupRouteArr.filter((item) => {
      if (item.value === id) return item;
    });
    setPickupRouteName(newArr[0].label);
    setStudentformdata({
      ...studentformdata,
      transport_pickup_id: e.target.value,
    });

    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Pickup_Route_Stop,
          variables: {
            transport_route_id: id,
          },
        })
        .then((response) => {
          setisloading(false);
          let temp = [];
          for (
            let index = 0;
            index < response.data.getTransportStop.length;
            index++
          ) {
            temp.push({
              label: response.data.getTransportStop[index].stop_name,
              value: response.data.getTransportStop[index].id,
            });
          }
          setPickupStartArr(temp);
        })
        .catch((err) => {
          setisloading(false);
          console.log("get transport catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("get transport t and c error", error);
    }
  };

  const selectDropRouteHandler = async (routeDropId) => {
    if (+routeDropId === -1) {
      return;
    }
    const newArr = dropRouteArr.filter((item) => {
      if (item.value === +routeDropId) return item;
    });
    setDropRouteName(newArr[0].label);

    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Pickup_Route_Stop,
        variables: {
          transport_route_id: +routeDropId,
        },
      });
      if (data && data.getTransportStop) {
        let dropStopArr = [];
        data.getTransportStop.map((item) => {
          dropStopArr.push({
            label: item.stop_name,
            value: item.id,
          });
        });
        setDropStopArr(dropStopArr);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  // all the logic down there

  const submitRouteHandler = () => {
    setselectroute(false);
  };
  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name && institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">Student account</div>
                </div>
                <div className="card_margin_both_side">
                  <div className=" mt-1 Heading_bold">Student Details</div>
                  <Card className="mt-1 pt-2 pb-2">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Academic Session
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gradeID"
                                value={academic_session}
                                onChange={(e) =>
                                  selectSessionHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Session</option>
                                {sessionArr.map((item) => (
                                  <option value={item.id}>
                                    {item.session_name}
                                  </option>
                                ))}
                              </select>
                              {sessionIdError && (
                                <span className="text-danger">
                                  Select Academic Session
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Class<span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gradeID"
                                value={studentformdata.grade_id}
                                onChange={(e) =>
                                  selectClassHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Class</option>
                                {managegrade.length > 0 &&
                                  managegrade.map((item) => (
                                    <option value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                              </select>
                              {gradeIdError && (
                                <span className="text-danger">
                                  Select Class
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Section<span className="text-danger pl-1">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="section"
                                value={studentformdata.section_id}
                                onChange={(e) => {
                                  setsectionIdError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    section_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Select Section</option>
                                {section.length > 0 &&
                                  section.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>
                              {sectionIdError && (
                                <span className="text-danger">
                                  Select Section
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Date Of Joining
                              <span className="text-danger pl-1">*</span>
                            </div>
                            <div className=" mt-1">
                              <input
                                type="date"
                                id="joinId"
                                class="form-control"
                                min={currentDayForMinMAx()}
                                value={studentformdata.joining_date}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    joining_date: e.target.value,
                                  });
                                  setJoinDateError(false);
                                }}
                              />
                              {joinDateError && (
                                <span className="text-danger">
                                  Enter Date Of Joining
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading ">Student Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger pl-1">*</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="s_firstNamee"
                                  value={studentformdata.Student_first_name}
                                  onChange={(e) => {
                                    setStudentFirstNameError(false);

                                    setStudentformdata({
                                      ...studentformdata,
                                      Student_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {StudentFirstNameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Student_middle_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Student_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Student_last_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Student_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold ">Parent/ Details</div>
                  <Card className="mt-1 pt-2 pb-2">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                                <span className="text-danger pl-1"> *</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="F_name"
                                  value={studentformdata.Father_first_name}
                                  onChange={(e) => {
                                    setFfirstnameError(false);
                                    setStudentformdata({
                                      ...studentformdata,
                                      Father_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {FfirstnameError && (
                                  <span className="text-danger">
                                    Enter First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Father_middle_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Father_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={studentformdata.Father_last_name}
                                  onChange={(e) => {
                                    setStudentformdata({
                                      ...studentformdata,
                                      Father_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Email Address
                              <span className="text-danger pl-1"> *</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="F_email"
                                value={studentformdata.Father_email_address}
                                onChange={(e) => {
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_email_address: e.target.value,
                                  });
                                  setFEmailIdError(false);
                                }}
                              />
                              {fEmailIdError && (
                                <span className="text-danger">
                                  Enter the Email ID
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                              <span className="text-danger pl-1"> *</span>
                            </div>
                            <div
                              className="inputMarginright mt-1"
                              id="F_mobile"
                            >
                              <PhoneInput
                                className="phone_input_width_create"
                                country={"in"}
                                countryCodeEditable={false}
                                value={studentformdata.Father_phone_number}
                                onChange={(e) => {
                                  setFPhoneError(false);
                                  setStudentformdata({
                                    ...studentformdata,
                                    Father_phone_number: e,
                                  });
                                }}
                              />
                              {FPhoneError && (
                                <span className="text-danger">
                                  Enter the Phone Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                  <div className=" mt-1 Heading_bold">Document Upload</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Photo
                          </Grid>
                          <Grid item md={6} className="d-flex flex-column">
                            <div className="d-flex flex-row">
                              <input
                                className="nofilechoosenhide"
                                id="photo"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "PHOTO")
                                }
                              />
                              {blankValidator(photo) &&
                                blankValidator(photo.name) && (
                                  <Chip
                                    label={photo.name.substring(0, 15)}
                                    onClick={() => {
                                      HandleImageDailogBox(photo);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Birth Certificate
                          </Grid>
                          <Grid item md={6} className="d-flex flex-column">
                            <div className="d-flex flex-row">
                              <input
                                className="nofilechoosenhide"
                                id="birth"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "BIRTH_CERTIFICATE")
                                }
                              />
                              {blankValidator(birthCertificate) &&
                                blankValidator(birthCertificate.name) && (
                                  <Chip
                                    label={birthCertificate.name.substring(
                                      0,
                                      15
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(birthCertificate);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            National ID
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex flex-row">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "NATIONAL_CARD")
                                }
                              />

                              {blankValidator(nation) &&
                                blankValidator(nation.name) && (
                                  <Chip
                                    label={nation.name.substring(0, 15)}
                                    onClick={() => {
                                      HandleImageDailogBox(nation);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Immunization Details
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(e, "IMMUNIZATION")
                                }
                              />

                              {blankValidator(immuneCertificate) &&
                                blankValidator(immuneCertificate.name) && (
                                  <Chip
                                    label={immuneCertificate.name.substring(
                                      0,
                                      15
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(immuneCertificate);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            School Leaving Certificate
                          </Grid>
                          <Grid item md={6}>
                            <div className="d-flex">
                              <input
                                className="nofilechoosenhide"
                                type="file"
                                onChange={(e) =>
                                  UploadDocumentHandler(
                                    e,
                                    "SCHOOL_LEAVE_CERTIFICATE"
                                  )
                                }
                              />

                              {blankValidator(schoolLeaveCertificate) &&
                                blankValidator(schoolLeaveCertificate.name) && (
                                  <Chip
                                    label={schoolLeaveCertificate.name.substring(
                                      0,
                                      15
                                    )}
                                    onClick={() => {
                                      HandleImageDailogBox(
                                        schoolLeaveCertificate
                                      );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  />
                                )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Add Any
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              multiple
                              onChange={(e) => UploadMultipleDocumentHandler(e)}
                            />
                          </Grid>

                          <Grid item md={6}>
                            {othersarr.length !== 0 &&
                              othersarr.map(
                                (item, index) =>
                                  blankValidator(item) &&
                                  blankValidator(item.name) && (
                                    <Chip
                                      key={index}
                                      className="ml-1"
                                      label={item.name.substring(0, 15)}
                                      color="primary"
                                      variant="outlined"
                                    />
                                  )
                              )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-5 Heading_bold">
                    Declaration<span className="text-danger pl-1"> *</span>
                  </div>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3">
                        <Grid item md={12}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="declare"
                              value={studentformdata.declarationCheck}
                              onChange={(e) => {
                                setdeclarationcheckedError(false);
                                setStudentformdata({
                                  ...studentformdata,
                                  declarationCheck: e.target.checked,
                                });
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              I hereby declare that the information given in
                              this application is true and correct to the best
                              of my knowledge and belief.
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="p-3">
                        <b>Note: </b> This will create accounts for student and
                        parent to access the platform and login details will be
                        sent to them via email/SMS
                      </div>
                    </div>
                  </div>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          <Button
                            variant="contained"
                            className={
                              studentformdata.declarationCheck === true
                                ? "student_Form_taransport_able_button_blue button_login_decoration button_width"
                                : "student_Form_taransport_able_button button_login_decoration button_width"
                            }
                            disabled={
                              studentformdata.declarationCheck === true
                                ? false
                                : true
                            }
                            onClick={studentDetaiform}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={selectroute}
        onClose={() => {
          setselectroute(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
      >
        <div className="Select_route_transport">
          <DialogTitle>
            Select Route
            <span
              className="float-right"
              onClick={() => {
                setselectroute(false);
              }}
            >
              <i className="fa fa-times cursor" aria-hidden="true"></i>{" "}
            </span>
            <hr />
          </DialogTitle>
          <div className="card_admissiondetails_height">
            <div className="textfiled_margin">
              <div className="text_filed_heading">Transport Route-Pick Up</div>
              <Grid className="grid_main_div">
                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      class="form-control"
                      onChange={(e) => selectPickupRouteHandler(e)}
                    >
                      <option value={-1}>select Pickup Route</option>
                      {pickupRouteArr.length > 0 ? (
                        pickupRouteArr.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                      ) : (
                        <option value={-1}>No Data</option>
                      )}
                    </select>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="mt-1">
                    <select
                      class="form-control"
                      onChange={(e) => selectPickupStartHandler(e)}
                    >
                      <option value={-1}>PickUp</option>
                      {pickupStartArr.length > 0 ? (
                        pickupStartArr.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                      ) : (
                        <option value={-1}>No Data</option>
                      )}
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="card_admissiondetails_height">
            <div className="textfiled_margin">
              <div className="text_filed_heading">Transport Route-Drop</div>
              <Grid className="grid_main_div">
                <Grid item md={6}>
                  <div className="inputMarginright mt-1">
                    <select
                      class="form-control"
                      onChange={(event) =>
                        selectDropRouteHandler(event.target.value)
                      }
                    >
                      <option value={-1}>select drop Route </option>
                      {dropRouteArr.length > 0 &&
                        dropRouteArr.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="mt-1">
                    <select
                      class="form-control"
                      onChange={(e) => selectDropStopHandler(e)}
                    >
                      <option value={-1}>select Route</option>
                      {dropStopArr.length > 0 &&
                        dropStopArr.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                  </div>
                </Grid>
              </Grid>

              <Grid className="grid_main_div mt-2 mb-2">
                <Grid item md={10}></Grid>

                <Grid item md={2}>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration button_width"
                    onClick={submitRouteHandler}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={OpenPhotoImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenPhotoImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={Service.baseUrl + "/" + impagepath}
              download
              target="_blank"
              className="mr-3"
            >
              <i className="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenPhotoImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <img
            src={Service.baseUrl + "/" + impagepath}
            className="UploadDocImagewidth"
            alt="No_profile"
          />
        </div>
      </Dialog>

      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(PtStudentForm));
