import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Dialog from "@material-ui/core/Dialog";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Loder from "../../Loder/Loder.jsx";
// import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import { useMutation } from "@apollo/client";
import * as Constants from "../../../action/appAction";
import {
  CapitalizeFirstFn,
  difference_of_days,
  blankValidator,
  showNotificationMsz,
  serverDateFormat,
} from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function LeaveSummary(props) {
  //----------------global id-------------------------

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  //Institute name

  const [leaveSubject, setleaveSubject] = useState("");
  const [cancelleaveid, setcancelleaveid] = useState("");
  const [opendialog, setOpendialog] = useState(false);
  const [search, setsearch] = useState("");
  // ---------------api call fn-----------------------
  const client = useApolloClient();
  // ---------------local state-----------------------
  const [studentLeaveArray, setStudentLeaveArray] = useState([]);

  const [Cancel_student_leave, {}] = useMutation(Service.Cancel_student_leave);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isloading, setisloading] = useState(false);

  const [user_id, setuser_id] = useState("");

  const [institution_id, setinstitution_id] = useState(-1);

  const navigation = props.navigate;
  // ---------------methods-----------------------
  useEffect(() => {
    getLeaveSummaryData();
  }, [props]);

  const getLeaveSummaryData = async () => {
    if (props.user_info.usertype === "STUDENT") {
      await setuser_id(parseInt(props.user_info.userid));
      getStudentDataHandler(parseInt(props.user_info.userid));
    }
    if (props.user_info.usertype === "PARENT") {
      await setuser_id(parseInt(props.userid));
      getStudentDataHandler(parseInt(props.userid));
    }

    setinstitution_id(parseInt(props.user_info.institution_id));
  };

  const getStudentDataHandler = async (id) => {
    try {
      if (id == -1) {
        setStudentLeaveArray([]);
        return;
      }
      setisloading(true);
      let searchdata = {
        user_id: parseInt(id),
        user_types: [
          {
            user_type: "PARENT",
          },
          {
            user_type: "STUDENT",
          },
        ],
      };

      if (startDate != "") {
        searchdata["from_date"] = startDate;
      }

      if (endDate != "") {
        searchdata["to_date"] = endDate;
      }

      await client
        .query({
          query: Service.Get_leave_Request_By_userId,
          variables: searchdata,
        })
        .then((response) => {
          setisloading(false);
          setStudentLeaveArray(response.data.getLeaveRequestByUserId.reverse());
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const opencancelleave = (row) => {
    setcancelleaveid(row.id);
    setleaveSubject(row.leave_type);
    setOpendialog(true);
  };

  const CancelLeaveRequest = () => {
    let id = parseInt(cancelleaveid);

    try {
      setisloading(true);
      Cancel_student_leave({
        variables: { id, institution_id },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.cancelLeaveRequestByRequestId.message,
            "success"
          );

          setisloading(false);
          setOpendialog(false);
          getStudentDataHandler(user_id);
        },
        (err) => {
          setisloading(false);
          setOpendialog(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterLeaves = studentLeaveArray.filter((event) => {
    if (blankValidator(event.subject)) {
      return event.subject.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
  });

  return (
    <div>
      <div>
        <div>
          <Card>
            <div className="card_admissiondetails_height">
              <div className="textfiled_margin mb-2">
                <Grid className="grid_main_div gapfromanotherinput mb-3">
                  <Grid item md={3}>
                    {/* <div className="text_filed_heading">Search By Subject</div> */}
                    <div className="inputMarginright  mt-1">
                      <TextField
                        id="standard-basic"
                        label="Search By Subject"
                        variant="standard"
                        autoComplete="off"
                        value={search}
                        onChange={(e) => {
                          setsearch(e.target.value);
                        }}
                      />
                      {/* <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Search By Subject"
                        autoComplete="off"
                        value={search}
                        onChange={(e) => {
                          setsearch(e.target.value);
                        }}
                      /> */}
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className="inputMarginright mt-1">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          openTo="year"
                          format="dd/MM/yyyy"
                          label="Start Date"
                          views={["year", "month", "date"]}
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {/* <input
                        type="date"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="DD/MM/YYYY"
                        datatype="dd/mm/yy"
                        autoComplete="off"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      /> */}
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className="inputMarginright  mt-1">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          openTo="year"
                          format="dd/MM/yyyy"
                          label="End Date"
                          views={["year", "month", "date"]}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {/* <input
                        type="date"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Demo"
                        autoComplete="off"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      /> */}
                    </div>
                  </Grid>

                  <Grid item md={3}>
                    <div className="button_text_formating mt-4">
                      <Button
                        onClick={() => {
                          getStudentDataHandler(user_id);
                        }}
                        variant="contained"
                        className="add_new_query_btn button_width button_login_decoration "
                      >
                        Search
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <div className="table_foramtitng mt-3">
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            className="table_header"
                            align="left"
                          >
                            Subject
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className="table_header"
                          >
                            Start Date
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className="table_header"
                          >
                            End Date
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className="table_header"
                          >
                            Days
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className="table_header"
                          >
                            Status
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className="table_header"
                          >
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? filterLeaves.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : filterLeaves
                        ).map((row, index) => {
                          //calculate days difference by dividing total milliseconds in a day
                          let days = difference_of_days(
                            row.from_date,
                            row.to_date
                          );

                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="left">
                                {!blankValidator(row.subject) ? (
                                  " "
                                ) : (
                                  <span>
                                    {CapitalizeFirstFn(
                                      row.subject.substring(0, 20)
                                    )}
                                  </span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="left">
                                {!blankValidator(row.from_date) ? (
                                  " "
                                ) : (
                                  <span>{serverDateFormat(row.from_date)}</span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {!blankValidator(row.to_date) ? (
                                  " "
                                ) : (
                                  <span>{serverDateFormat(row.to_date)}</span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {!blankValidator(days) ? (
                                  " "
                                ) : (
                                  <span>{days}</span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {!blankValidator(row.status) ? (
                                  ""
                                ) : (
                                  <span>{row.status}</span>
                                )}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <div className="d-flex justify-content-center">
                                  {row.status === "APPROVED" && (
                                    <div>
                                      <span
                                        className="pr-2 row_details_color"
                                        onClick={() =>
                                          navigation("/reviewLeave", {
                                            state: {
                                              data: row,
                                              Days: days,
                                            },
                                          })
                                        }
                                      >
                                        Open
                                      </span>
                                      |
                                      <span
                                        className="pr-2 pl-2 row_details_color"
                                        onClick={() => opencancelleave(row)}
                                      >
                                        Cancel
                                      </span>
                                    </div>
                                  )}

                                  {row.status === "REJECTED" && (
                                    <div className="d-flex">
                                      <span
                                        className="pr-2 row_details_color"
                                        onClick={() =>
                                          navigation("/reviewLeave", {
                                            state: {
                                              data: row,
                                              Days: days,
                                            },
                                          })
                                        }
                                      >
                                        Open
                                      </span>
                                    </div>
                                  )}

                                  {row.status === "PENDING" && (
                                    <div>
                                      <span
                                        className="pr-2 row_details_color"
                                        onClick={() =>
                                          navigation("/reviewLeave", {
                                            state: {
                                              data: row,
                                              Days: days,
                                            },
                                          })
                                        }
                                      >
                                        Open
                                      </span>
                                      |
                                      <span
                                        className="pr-2 pl-2 row_details_color"
                                        onClick={() =>
                                          navigation("/reviewLeave", {
                                            state: {
                                              data: row,
                                              Days: days,
                                            },
                                          })
                                        }
                                      >
                                        Edit
                                      </span>
                                      |
                                      <span
                                        className="pr-2 pl-2 row_details_color"
                                        onClick={() => opencancelleave(row)}
                                      >
                                        Cancel
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      true
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={filterLeaves.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Loder loading={isloading} />

      <Dialog
        open={opendialog}
        onClose={() => {
          setOpendialog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div className="data_content">
            <div className="dailog_heading1 pl-2">Confirmation</div>
            <div className="cut_dailog_icon">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpendialog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-3">
            Are you sure want to cancel your leave
          </div>

          <Grid className="grid_main_div mt-2  mb-4 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={() => CancelLeaveRequest()}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => setOpendialog(false)}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(LeaveSummary);
