import React from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Holiday from "../../Common/Holiday/Holiday.jsx";
import { dateFormatToShow } from "../../../utils/Validation";
import moment from "moment";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import StaffTimetableWeek from "./calendar/StaffTimetableWeek.jsx";

function StaffTimetable({ navigate, employeeId, data }) {
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  let day = new Date().getDay() - 1;
  if (day < 0) {
    day = 6;
  }
  const [value, setValue] = React.useState(day);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">My Time Table</div>
                  <div className="mr-3 mt-3">
                    <h6>{`${dateFormatToShow(startOfWeek)}-${dateFormatToShow(
                      endOfWeek
                    )} `}</h6>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div>
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2 tabs_class_for_Mobile">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="Monday"
                              {...a11yProps(0)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Tuesday"
                              {...a11yProps(1)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Wednesday"
                              {...a11yProps(2)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Thursday"
                              {...a11yProps(3)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Friday"
                              {...a11yProps(4)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Saturday"
                              {...a11yProps(5)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Sunday"
                              {...a11yProps(6)}
                              className="button_login_decoration text_tranformation_exam"
                              style={{ minWidth: 130 }}
                            />
                          </Tabs>
                        </div>

                        <div className="Pannel_padding_hidden">
                          <TabPanel value={value} index={0}>
                            <StaffTimetableWeek
                              day={1}
                              weekName="Monday"
                              employeeId={employeeId}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={1}>
                            <StaffTimetableWeek
                              day={2}
                              weekName="Tuesday"
                              employeeId={employeeId}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={2}>
                            <StaffTimetableWeek
                              day={3}
                              weekName="Wednesday"
                              employeeId={employeeId}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={3}>
                            <StaffTimetableWeek
                              day={4}
                              weekName="Thrusday"
                              employeeId={employeeId}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={4}>
                            <StaffTimetableWeek
                              day={5}
                              weekName="Friday"
                              employeeId={employeeId}
                            />
                          </TabPanel>

                          <TabPanel value={value} index={5}>
                            <StaffTimetableWeek
                              day={6}
                              weekName="Saturday"
                              employeeId={employeeId}
                            />
                          </TabPanel>
                          <TabPanel value={value} index={6}>
                            <StaffTimetableWeek
                              day={7}
                              weekName="Sunday"
                              employeeId={employeeId}
                            />
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(StaffTimetable));
