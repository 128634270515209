import React, { Component, Fragment } from "react";
import "./styles.css";
import Chip from "@material-ui/core/Chip";
class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
    };
  }

  onChange = (e) => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter((suggestion) => {
      if (userInput == "") {
        return true;
      }
      return (
        suggestion?.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
    });

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (data) => {
    this.props.selectedvalue.push(data);

    this.props.setSelectedvalue([...this.props.selectedvalue]);
    this.setState({
      userInput: "",
    });
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li
                  className={className}
                  key={index}
                  onClick={() => onClick(suggestion)}
                  onKeyDown={(e) => onKeyDown(e, suggestion)}
                >
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions available.</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <div
          className="autocomplete_border"
          onClick={() => {
            document.getElementById("email_input").click();
          }}
        >
          {this.props.selectedvalue.map((data, tempIndex) => {
            return (
              <Chip
                key={tempIndex}
                variant="outlined"
                label={data.name}
                onDelete={() => {
                  if (tempIndex >= 0) {
                    this.props.selectedvalue.splice(tempIndex, 1);
                    this.props.setSelectedvalue([...this.props.selectedvalue]);
                  }
                }}
              />
            );
          })}
          <div className="auto_flex_input">
            <input
              type="text"
              id="email_input"
              className="form-control"
              onChange={onChange}
              // onKeyDown={onKeyDown}
              value={userInput}
            />
          </div>
        </div>
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
