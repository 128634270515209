import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AllMonday from "./AllMonday.jsx";
import { serverDateFormat } from "../../../utils/Validation.js";
const MangeAllTimeData = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Card className="mt-2 pt-3 pb-3">
        <div className="card_admissiondetails_height">
          <div className="pl-4">
            <p className="Heading_bold">{props.data?.name}</p>
            <p className="Heading_bold">
              {serverDateFormat(props.data.start_date)} -{" "}
              {serverDateFormat(props.data.end_date)}
            </p>
          </div>
          <div className="textfiled_margin">
            <div className="mt-3 mb-2">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="primary"
                left
              >
                <Tab
                  label="Monday"
                  {...a11yProps(0)}
                  className=" text-capitalize  "
                  style={{ minWidth: 130 }}
                />
                <Tab
                  label="Tuesday"
                  {...a11yProps(1)}
                  className=" text-capitalize "
                  style={{ minWidth: 130 }}
                />
                <Tab
                  label="Wednesday"
                  {...a11yProps(2)}
                  className=" text-capitalize "
                  style={{ minWidth: 130 }}
                />
                <Tab
                  label="Thursday"
                  {...a11yProps(3)}
                  className=" text-capitalize "
                  style={{ minWidth: 130 }}
                />
                <Tab
                  label="Friday"
                  {...a11yProps(4)}
                  className=" text-capitalize "
                  style={{ minWidth: 130 }}
                />
                <Tab
                  label="Saturday"
                  {...a11yProps(5)}
                  className=" text-capitalize "
                  style={{ minWidth: 130 }}
                />
                <Tab
                  label="Sunday"
                  {...a11yProps(6)}
                  className=" text-capitalize "
                  style={{ minWidth: 130 }}
                />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <AllMonday
                day={1}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllMonday
                day={2}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AllMonday
                day={3}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AllMonday
                day={4}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AllMonday
                day={5}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AllMonday
                day={6}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <AllMonday
                day={7}
                name="Monday"
                timetables={props.data.timetables}
                section_id={props.section_id}
                institution_id={props.institution_id}
              />
            </TabPanel>
          </div>
        </div>
      </Card>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default MangeAllTimeData;
