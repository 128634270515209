import React, { useState, useEffect } from "react";
import "./NewAssignment.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

function CreateAssignment(props) {
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [totalscore, settotalscore] = useState(-1);
  const [managesubjects, setmanagesubjects] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [subjectid, setsubjectid] = useState("");

  //error
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [subjectError, setsubjectError] = useState(false);
  const [totalscoreNumberError, settotalscoreNumberError] = useState(false);
  const [charleft, setcharleft] = useState("250");
  const [Create_Assignment_Teacher, {}] = useMutation(
    Service.Create_Assignment_Teacher
  );

  let teacher_id = parseInt(props.location.state.teacher_id);

  const client = useApolloClient();

  let institution_id = parseInt(props.data.institution_id);

  useEffect(() => {
    window.scrollTo(0, 0);
    const GetSubjectData = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Subject_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setmanagesubjects(response.data.getSubject);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    GetSubjectData();
  }, [client, institution_id]);

  const gobackprevoius = () => {
    props.navigate.goBack();
  };
  const submit = () => {
    let flag = false;

    if (!blankValidator(name)) {
      setNameError(true);
      flag = true;
    }
    if (!blankValidator(subjectid) || subjectid === -1) {
      setsubjectError(true);
      flag = true;
    }

    if (!blankValidator(description)) {
      setdescriptionError(true);
      flag = true;
    }

    if (flag) {
      return;
    }
    try {
      setisloading(true);
      Create_Assignment_Teacher({
        variables: {
          teacher_id: teacher_id,
          name: name,
          description: description,
          total_score: parseInt(totalscore),
          subject_id: parseInt(subjectid),
          academic_session_id: parseInt(props.dataacademic.id),
        },
      })
        .then((response) => {
          setisloading(false);

          if (
            response.data.addAssignmentForTeacher.message ===
              "assignment name already exist" ||
            response.data.addAssignmentForTeacher.id == null
          ) {
            showNotificationMsz(
              response.data.addAssignmentForTeacher.message,
              "info"
            );
          } else {
            showNotificationMsz(
              response.data.addAssignmentForTeacher.message,
              "success"
            );

            props.navigate("/updateAssignment", {
              state: {
                data: response.data.addAssignmentForTeacher,
                pagetype: "Create Assignment",
              },
            });
          }
        })
        .catch((err) => {
          console.log("then error", err);
          showNotificationMsz("Assignment not Created", "danger");

          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
      showNotificationMsz("Assignment not Created", "danger");

      setisloading(false);
    }
  };
  return (
    <>
      <div className="main_full_width">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={props.data.institution_name} />
                <div className="enquirydetails_background mb-2">
                  <div className="enquiry_heading">
                    <div className="mt-3 ml-3 Heading_bold">
                      Create Assignment
                    </div>
                  </div>
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label for="exampleInputEmail1">
                                  {" "}
                                  Assignment Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter Name"
                                  autoComplete="off"
                                  value={name}
                                  onChange={(e) => {
                                    setNameError(false);
                                    setname(e.target.value);
                                  }}
                                />
                                {nameError && (
                                  <p className="text-danger">
                                    Please Enter Assignment Name
                                  </p>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label for="exampleInputEmail1">Subject</label>
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  value={subjectid}
                                  onChange={(e) => {
                                    setsubjectError(false);
                                    setsubjectid(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Subject</option>
                                  {managesubjects.length > 0 ? (
                                    managesubjects.map((item, index) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value={-1}>No Data</option>
                                  )}
                                </select>
                                {subjectError && (
                                  <p className="text-danger">
                                    Please Enter Subjects
                                  </p>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div>
                                <label for="exampleInputEmail1">
                                  Total Score
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Total Score"
                                  autoComplete="off"
                                  value={totalscore == -1 ? "" : totalscore}
                                  onChange={(e) => {
                                    settotalscoreNumberError(false);

                                    if (isNaN(e.target.value)) {
                                      settotalscoreNumberError(true);
                                      return;
                                    }
                                    settotalscore(e.target.value);
                                  }}
                                />

                                {totalscoreNumberError && (
                                  <p className="text-danger">
                                    Please Enter Valid Total Score
                                  </p>
                                )}
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div mt-1 mb-5">
                            <Grid item md={12}>
                              <div>
                                <label for="exampleInputEmail1">
                                  Instructions
                                </label>
                                <textarea
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  placeholder="Instructions"
                                  autoComplete="off"
                                  maxlength="250"
                                  value={description}
                                  onChange={(e) => {
                                    let leftcharater =
                                      250 - e.target.value.length;
                                    setcharleft(leftcharater);
                                    setdescriptionError(false);
                                    setdescription(e.target.value);
                                  }}
                                ></textarea>
                                {descriptionError && (
                                  <p className="text-danger">
                                    Please Enter Description
                                  </p>
                                )}
                              </div>
                              <span>Character limit:{charleft}</span>
                            </Grid>
                          </Grid>

                          {/* <Grid className="grid_main_div mt-2 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          {" "}
                          <Button
                            variant="contained"
                            className="add_new_query_btn button_login_decoration button_width"
                            onClick = {(e) => {props.navigate("/teacherHomePage")}}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid> */}

                          <Grid className="grid_main_div mt-2 mb-2">
                            <Grid item md={8}></Grid>
                            <Grid item md={2} className="pr-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={gobackprevoius}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item md={2}>
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={submit}
                              >
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div>
          {" "}
          <Loder loading={isloading} />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
  dataacademic: getData(state, Constants.ACADAMIC_DATA),
});

export default connect(mapStateToProps, null)(HOC1(CreateAssignment));
