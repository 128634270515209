import React, { useState, useEffect } from "react";
import "./Feemodule.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Expand from "react-expand-animated";
import Button from "@material-ui/core/Button";
import Loder from "../../Loder/Loder.jsx";

import {
  blankValidator,
  getValue,
  showNotificationMsz,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { Dialog } from "@material-ui/core";

const CreateInvoice = (props) => {
  //----------------global id----------------
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );

  let Currency_code = props.manageInstitution.currency_code;
  //--------------------API CALLS-----------------
  const [Add_Invoice_Template_Fee, {}] = useMutation(
    Service.Add_Invoice_Template_Fee
  );
  const [Create_FeesubType_Data, {}] = useMutation(
    Service.Create_FeesubType_Data
  );
  const client = useApolloClient();
  const [Add_Invoice, {}] = useMutation(Service.Add_Invoice);
  //----------------local state-------------------
  const [recurrence, setRecurrence] = useState("");
  const [idtopass, setidtopass] = useState("");
  const [selectedTemplateId, setselectedTemplateId] = useState(-1);
  const [gradeArr, setgradeArr] = useState([]);
  const [feeSubtypeArr, setfeeSubtypeArr] = useState([]);
  const [title, settitle] = useState("");
  const [description, setDescription] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [templateArr, settemplateArr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [createfeetype, setcreatefeetype] = useState(false);
  const [subtypeval, setsubtypeval] = useState(null);
  const [subtypevalError, setsubtypevalError] = useState("");
  const [dueDate, setDueDate] = useState("");

  const [createinvoice, setcreateinvoice] = useState([]);
  const [totalamount, setTotalamount] = useState(0.0);
  const [feeSubTypeDailogOpen, setFeeSubTypeDailogOpen] = useState(false);
  const [feeSubSelectValue, setFeeSubSelectValue] = useState("");
  const [dataAddFeeType, setDataAddFeeType] = useState({});

  //error
  const [templateIdError, settemplateIdError] = useState(false);
  const [invoicetitleError, setinvoicetitleError] = useState(false);
  const [recurrenceError, setrecurrenceError] = useState(false);
  const [duedateError, setduedateError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [totalamountError, settotalamountError] = useState(false);
  const [subtypeArrError, setsubtypeArrError] = useState(false);
  const handlefeeComponent = () => {
    setcreatefeetype(true);
  };

  const closefeeComponent = () => {
    setcreatefeetype(false);
  };
  useEffect(() => {
    // to get feesubtype on template select
    getFeeSubTypeData();
    // to get feesubtype on template select
  }, [client, institution_id]);

  const getFeeSubTypeData = () => {
    try {
      client
        .query({
          query: Service.Get_Fee_Subtype_data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          setfeeSubtypeArr(response.data.getFeeSubtype);
        })
        .catch((err) => console.log("catch err Get_Fee_Subtype_data", err));
    } catch (error) {
      console.log("t and c error Get_Fee_Subtype_data", error);
    }
  };
  // to get template invoice
  useEffect(() => {
    try {
      client
        .query({
          query: Service.Get_Template_Invoice_Data,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          settemplateArr(response.data.getInvoiceTemplate);
        })
        .catch((err) => {
          console.log("catch err Get_Template_Invoice_Data", err);
        });
    } catch (error) {
      console.log("t and c error Get_Template_Invoice_Data", error);
    }
  }, [client, institution_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getManageGrades = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Manage_Grade,
            variables: {
              academic_session_id,
            },
          })
          .then((response) => {
            setgradeArr(response.data.getGrade);
            setisloading(false);
          })
          .catch((e) => {
            setisloading(false);
            console.log("catch error Get_Manage_Grade", e);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error Get_Manage_Grade", error);
      }
    };
    getManageGrades();
  }, [client, academic_session_id]);

  const createInvoiceFn = () => {
    try {
      if (!blankValidator(invoiceTitle)) {
        setinvoicetitleError(true);
        return;
      }
      // if (!blankValidator(recurrence)) {
      //   setrecurrenceError(true);
      //   return;
      // }
      if (!blankValidator(dueDate)) {
        setduedateError(true);
        return;
      }
      if (!blankValidator(description)) {
        setdescriptionError(true);
        return;
      }
      if (createinvoice.length == undefined || createinvoice.length == 0) {
        alert("Please Enter Fees subtype for amount ");
        return;
      }
      if (totalamount <= 0) {
        settotalamountError(true);
        return;
      }

      setisloading(true);
      let invoice_feeitem = [];
      for (let index = 0; index < createinvoice.length; index++) {
        const element = createinvoice[index];
        invoice_feeitem.push({
          fee_subtype_id: parseInt(element.feesubtype.id),
          amount: element.amount.toString(),
          tax: element.tax.toString(),
          total: totalamount.toString(),
        });
      }

      Add_Invoice({
        variables: {
          academic_session_id,
          institution_id,
          due_date: dueDate,
          invoice_feeitem: invoice_feeitem,
          amount: totalamount.toString(),
          name: invoiceTitle,
          recurrence: recurrence,
          description,
          currency_code: Currency_code,
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(response.data.addInvoice.message, "success");

          props.navigate("/manageinvoice");
        })
        .catch((err) => {
          showNotificationMsz(err, "danger");

          setisloading(false);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const handleTemplate = async (id) => {
    try {
      setisloading(true);
      if (id == -1) {
        let temp = [];
        setcreateinvoice(temp);
        setisloading(false);
        setselectedTemplateId(-1);
        return;
      }
      setselectedTemplateId(id);

      const { data } = await client.query({
        query: Service.Get_Invoice_Fee_Items,
        variables: {
          invoice_template_id: parseInt(id),
        },
      });
      if (data && data.getInvoiceTemplateFeeItems) {
        const { getInvoiceTemplateFeeItems } = data;

        let softArr = [];
        let totalamount = 0.0;
        getInvoiceTemplateFeeItems.map((item) => {
          softArr.push({
            ...item,
            amount: (+item.amount).toFixed(2),
            tax: (+item.tax).toFixed(2),
          });

          totalamount =
            totalamount +
            parseFloat(item.amount) +
            (parseFloat(item.amount) * parseFloat(item.tax)) / 100;
        });
        setTotalamount(parseFloat(totalamount).toFixed(2));
        setcreateinvoice(softArr);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const AddFeeSubTypeFn = async (subtypeval) => {
    try {
      setsubtypeArrError(false);
      if (!subtypeval) {
        setsubtypevalError(true);
        return;
      }
      setisloading(true);
      const { data } = await Create_FeesubType_Data({
        variables: { institution_id, name: subtypeval, description: "" },
      });

      if (data && data.addFeeSubtype) {
        const { addFeeSubtype } = data;

        showNotificationMsz(addFeeSubtype.message, "success");
        setFeeSubTypeDailogOpen(false);
        setDataAddFeeType(addFeeSubtype);
        setFeeSubSelectValue(addFeeSubtype.id.toString());
        // if (addFeeSubtype.id <= 0) {
        //   setisloading(false);
        //   // 14-11-change for bug no. 82
        //   setsubtypeval("");
        //   setcreatefeetype(false);
        //   return;
        // }
        // let temp = {
        //   feesubtype: {
        //     name: subtypeval,
        //     id: +addFeeSubtype.id,
        //   },
        //   amount: (0).toFixed(2),
        //   tax: (0).toFixed(2),
        //   id: (0).toFixed(2),
        // };
        // createinvoice.push(temp);
        // let Datatemp = [];
        // createinvoice.map((item) => {
        //   return Datatemp.push(item);
        // });
        // setcreateinvoice(Datatemp);
      }
      setisloading(false);
      // 14-11-change for bug no. 82
      setsubtypeval("");
      setcreatefeetype(false);
      getFeeSubTypeData();
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
      console.log(" error", error);
    }
  };
  const handleFeeSubType = (value) => {
    setsubtypeval(value);
  };
  // local data add ui
  const AddFeeSubTypeFnLocal = async () => {
    try {
      setsubtypeArrError(false);
      if (!feeSubSelectValue) {
        setsubtypevalError(true);
        return;
      }

      if (dataAddFeeType.id <= 0) {
        setisloading(false);
        // 14-11-change for bug no. 82
        setsubtypeval("");
        setcreatefeetype(false);
        return;
      }
      console.log("createinvoice", createinvoice);
      for (let index = 0; index < createinvoice.length; index++) {
        const element = createinvoice[index];
        if (element.feesubtype.id === +JSON.parse(feeSubSelectValue).id) {
          showNotificationMsz("Already Add subtype.", "success");
          return;
        }
      }

      let temp = {
        feesubtype: {
          name: JSON.parse(feeSubSelectValue).name,
          id: +JSON.parse(feeSubSelectValue).id,
        },
        amount: (0).toFixed(2),
        tax: (0).toFixed(2),
        id: (0).toFixed(2),
      };
      createinvoice.push(temp);
      let Datatemp = [];
      createinvoice.map((item) => {
        return Datatemp.push(item);
      });
      setcreateinvoice(Datatemp);

      setsubtypeval("");
      setcreatefeetype(false);
      getFeeSubTypeData();
    } catch (error) {
      setisloading(false);
      showNotificationMsz(error, "danger");
      console.log(" error", error);
    }
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.manageInstitution.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-3 Heading_bold">Create Invoice</div>
                </div>
                <div className="card_margin_both_side">
                  {props.data.type === "PT" || props.data.type === "ET" ? (
                    <Card>
                      <div className="card_admissiondetails_height p-2">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Title for Invoice
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title for Invoice"
                                  value={invoiceTitle}
                                  onChange={(event) => {
                                    setinvoicetitleError(false);
                                    setInvoiceTitle(event.target.value);
                                  }}
                                />
                                {invoicetitleError && (
                                  <span className="text-danger">
                                    Enter the Invoice Title
                                  </span>
                                )}
                              </div>
                            </Grid>
                            {/* <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Recurrence
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setrecurrenceError(false);
                                    setRecurrence(e.target.value);
                                  }}
                                >
                                  <option value="">Recurrence</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Quarterly">Quarterly</option>
                                  <option value="Half Yearly">
                                    Half Yearly
                                  </option>
                                  <option value="Yearly">Yearly</option>
                                  <option value="One-Time">One-Time</option>
                                </select>
                                {recurrenceError && (
                                  <span className="text-danger">
                                    Select the recurrence
                                  </span>
                                )}
                              </div>
                            </Grid> */}
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Due Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="564946646"
                                  value={dueDate}
                                  onChange={(e) => {
                                    setduedateError(false);
                                    setDueDate(e.target.value);
                                  }}
                                />
                                {duedateError && (
                                  <span className="text-danger">
                                    Enter the Due Date
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Description
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="description"
                                  value={description}
                                  onChange={(e) => {
                                    setdescriptionError(false);
                                    setDescription(e.target.value);
                                  }}
                                />
                                {descriptionError && (
                                  <span className="text-danger">
                                    Enter the Description
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                          {/* <Grid className="grid_main_div mt-3">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Description
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="description"
                                  value={description}
                                  onChange={(e) => {
                                    setdescriptionError(false);
                                    setDescription(e.target.value);
                                  }}
                                />
                                {descriptionError && (
                                  <span className="text-danger">
                                    Enter the Description
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid> */}
                          <div className="inputMarginright d-flex mt-3">
                            <div className="text_filed_heading">
                              Fee Components
                            </div>

                            <div
                              className="d-flex pl-3"
                              onClick={() => setFeeSubTypeDailogOpen(true)}
                            >
                              <span className="iconstoaddfee">
                                <i className="fa fa-plus-circle"></i>
                              </span>
                              <span className=" addmanageuserfont mt-1 ml-2">
                                Add Fee Subtype
                              </span>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="card_admissiondetails_height">
                              <div className=" mb-2">
                                <Grid className="grid_main_div">
                                  <Grid item md={4}>
                                    <div className="inputMarginright">
                                      <select
                                        className="form-control"
                                        value={feeSubSelectValue}
                                        onChange={(e) => {
                                          setFeeSubSelectValue(e.target.value);
                                          setsubtypevalError(false);
                                        }}
                                      >
                                        <option value={-1}>
                                          Select Fee Type
                                        </option>
                                        {feeSubtypeArr.map((item, index) => (
                                          <option
                                            value={JSON.stringify(item)}
                                            key={index}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                      {subtypevalError && (
                                        <span className="text-danger">
                                          Select Subtype
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={2}>
                                    {" "}
                                    <Button
                                      variant="contained"
                                      className="button_login_decoration addButtonwidth button_width"
                                      onClick={() => {
                                        AddFeeSubTypeFn(feeSubSelectValue);
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            {createinvoice.length !== 0 &&
                              createinvoice.map((item, index) => (
                                <Card
                                  className="cardvisible_border mb-2 pb-3 pl-2"
                                  key={index}
                                >
                                  <Grid className="grid_main_div">
                                    <Grid item md={2}>
                                      <label className="text_filed_heading  mt-3 mb-3">
                                        {item.feesubtype.name}
                                      </label>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright d-flex mt-3">
                                        <label className="text_filed_heading pr-2">
                                          Amount({Currency_code})
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Amount"
                                          value={item.amount}
                                          onChange={(e) => {
                                            createinvoice[index]["amount"] =
                                              e.target.value;
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                          onBlur={(e) => {
                                            let tempvalue = parseFloat(
                                              e.target.value
                                            ).toFixed(2);

                                            if (isNaN(tempvalue)) {
                                              tempvalue = 0.0;
                                            }

                                            createinvoice[index]["amount"] =
                                              tempvalue;

                                            let totalamount = 0;
                                            createinvoice.map((item) => {
                                              totalamount =
                                                totalamount +
                                                parseFloat(item.amount) +
                                                (parseFloat(item.amount) *
                                                  parseFloat(item.tax)) /
                                                  100;
                                            });
                                            setTotalamount(
                                              parseFloat(totalamount).toFixed(2)
                                            );
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright d-flex mt-3">
                                        <label className="text_filed_heading pr-2">
                                          Tax(%)
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="0.00"
                                          value={item.tax}
                                          onChange={(e) => {
                                            createinvoice[index]["tax"] =
                                              e.target.value;
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                          onBlur={(e) => {
                                            let tempvalue = parseFloat(
                                              e.target.value
                                            ).toFixed(2);

                                            if (isNaN(tempvalue)) {
                                              tempvalue = 0.0;
                                            }
                                            createinvoice[index]["tax"] =
                                              tempvalue;

                                            let totalamount = 0;
                                            createinvoice.map((item) => {
                                              (totalamount =
                                                totalamount +
                                                parseFloat(item.amount) +
                                                (parseFloat(item.amount) *
                                                  parseFloat(item.tax)) /
                                                  100).toFixed(2);
                                            });
                                            setTotalamount(
                                              parseFloat(totalamount).toFixed(2)
                                            );
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright d-flex mt-3">
                                        <label className="text_filed_heading pr-2">
                                          Total({Currency_code})
                                        </label>
                                        <input
                                          disabled={true}
                                          type="text"
                                          className="form-control"
                                          placeholder="Total Amount"
                                          value={parseFloat(
                                            parseFloat(item.amount) +
                                              (parseFloat(item.amount) *
                                                parseFloat(item.tax)) /
                                                100
                                          ).toFixed(2)}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid md={1}>
                                      <span className="iconstoaddfee m-3">
                                        <i
                                          class="fa fa-trash pt-3"
                                          onClick={() => {
                                            let temp = [];
                                            createinvoice.splice(index, 1);
                                            createinvoice.map((item) => {
                                              temp.push(item);
                                            });

                                            setcreateinvoice(temp);
                                          }}
                                        ></i>
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Card>
                              ))}

                            {subtypeArrError && (
                              <span className="text-danger">
                                Enter Fees Subtype
                              </span>
                            )}
                          </div>
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Total Amount({Currency_code})
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Total Amount"
                                  value={totalamount}
                                  disabled={
                                    selectedTemplateId === -1 ? true : false
                                  }
                                />
                                {totalamountError && (
                                  <span className="text-danger">
                                    total amount not be 0
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}></Grid>
                            <Grid item md={4}>
                              <div className="main_button_div2 mb-3 mt-4">
                                <Button
                                  variant="contained"
                                  className="button_enquiry_details button_login_decoration"
                                  onClick={createInvoiceFn}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card>
                      <div className="card_admissiondetails_height p-2">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Template (Optional)
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    settemplateIdError(false);
                                    handleTemplate(e.target.value);
                                  }}
                                >
                                  <option value={-1}>Select Template</option>
                                  {templateArr.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {templateIdError && (
                                  <span className="text-danger">
                                    Select the Template
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Title for Invoice
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title for Invoice"
                                  value={invoiceTitle}
                                  onChange={(event) => {
                                    setinvoicetitleError(false);
                                    setInvoiceTitle(event.target.value);
                                  }}
                                />
                                {invoicetitleError && (
                                  <span className="text-danger">
                                    Enter the Invoice Title
                                  </span>
                                )}
                              </div>
                            </Grid>
                            {/* <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Recurrence
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setrecurrenceError(false);
                                    setRecurrence(e.target.value);
                                  }}
                                >
                                  <option value="">Recurrence</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Quarterly">Quarterly</option>
                                  <option value="Half Yearly">
                                    Half Yearly
                                  </option>
                                  <option value="Yearly">Yearly</option>
                                  <option value="One-Time">One-Time</option>
                                </select>
                                {recurrenceError && (
                                  <span className="text-danger">
                                    Select the recurrence
                                  </span>
                                )}
                              </div>
                            </Grid> */}
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Due Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="564946646"
                                  value={dueDate}
                                  onChange={(e) => {
                                    setduedateError(false);
                                    setDueDate(e.target.value);
                                  }}
                                />
                                {duedateError && (
                                  <span className="text-danger">
                                    Enter the Due Date
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div mt-3">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Description
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="description"
                                  value={description}
                                  onChange={(e) => {
                                    setdescriptionError(false);
                                    setDescription(e.target.value);
                                  }}
                                />
                                {descriptionError && (
                                  <span className="text-danger">
                                    Enter the Description
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid>

                          <div className="inputMarginright d-flex mt-3">
                            <div className="text_filed_heading">
                              Fee Components
                            </div>

                            <div
                              className="d-flex pl-3"
                              // onClick={handlefeeComponent}
                              onClick={() => {
                                setFeeSubTypeDailogOpen(true);
                              }}
                            >
                              <span className="iconstoaddfee">
                                <i className="fa fa-plus-circle"></i>
                              </span>
                              <span className=" addmanageuserfont mt-1 ml-2">
                                Add Fee Subtype
                              </span>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="card_admissiondetails_height">
                              <div className="mb-2">
                                <Grid className="grid_main_div">
                                  <Grid item md={4}>
                                    <div className="inputMarginright">
                                      <select
                                        className="form-control "
                                        value={feeSubSelectValue}
                                        onChange={(e) => {
                                          setFeeSubSelectValue(e.target.value);
                                          setsubtypevalError(false);
                                        }}
                                      >
                                        <option value={-1}>
                                          Select Fee Type
                                        </option>
                                        {feeSubtypeArr.map((item, index) => (
                                          <option
                                            value={JSON.stringify(item)}
                                            key={index}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                      {subtypevalError && (
                                        <span className="text-danger">
                                          Select Subtype
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={2}>
                                    <Button
                                      variant="contained"
                                      className="button_login_decoration addButtonwidth button_width"
                                      onClick={() => {
                                        AddFeeSubTypeFnLocal();
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            {createinvoice.length !== 0 &&
                              createinvoice.map((item, index) => (
                                <Card
                                  className="cardvisible_border mb-2 pb-3 pl-2"
                                  key={index}
                                >
                                  <Grid className="grid_main_div">
                                    <Grid item md={2}>
                                      <label className="text_filed_heading  mt-3 mb-3">
                                        {item.feesubtype.name}
                                      </label>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright d-flex mt-3">
                                        <label className="text_filed_heading pr-2">
                                          Amount({Currency_code})
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Amount"
                                          value={item.amount}
                                          onChange={(e) => {
                                            createinvoice[index]["amount"] =
                                              e.target.value;
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                          onBlur={(e) => {
                                            let tempvalue = parseFloat(
                                              e.target.value
                                            ).toFixed(2);

                                            if (isNaN(tempvalue)) {
                                              tempvalue = 0.0;
                                            }

                                            createinvoice[index]["amount"] =
                                              tempvalue;

                                            let totalamount = 0;
                                            createinvoice.map((item) => {
                                              totalamount =
                                                totalamount +
                                                parseFloat(item.amount) +
                                                (parseFloat(item.amount) *
                                                  parseFloat(item.tax)) /
                                                  100;
                                            });
                                            setTotalamount(
                                              parseFloat(totalamount).toFixed(2)
                                            );
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright d-flex mt-3">
                                        <label className="text_filed_heading pr-2">
                                          Tax(%)
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="0.00"
                                          value={item.tax}
                                          onChange={(e) => {
                                            createinvoice[index]["tax"] =
                                              e.target.value;
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                          onBlur={(e) => {
                                            let tempvalue = parseFloat(
                                              e.target.value
                                            ).toFixed(2);

                                            if (isNaN(tempvalue)) {
                                              tempvalue = 0.0;
                                            }
                                            createinvoice[index]["tax"] =
                                              tempvalue;

                                            let totalamount = 0;
                                            createinvoice.map((item) => {
                                              (totalamount =
                                                totalamount +
                                                parseFloat(item.amount) +
                                                (parseFloat(item.amount) *
                                                  parseFloat(item.tax)) /
                                                  100).toFixed(2);
                                            });
                                            setTotalamount(
                                              parseFloat(totalamount).toFixed(2)
                                            );
                                            setcreateinvoice([
                                              ...createinvoice,
                                            ]);
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item md={3}>
                                      <div className="inputMarginright d-flex mt-3">
                                        <label className="text_filed_heading pr-2">
                                          Total({Currency_code})
                                        </label>
                                        <input
                                          disabled={true}
                                          type="text"
                                          className="form-control"
                                          placeholder="Total Amount"
                                          value={parseFloat(
                                            parseFloat(item.amount) +
                                              (parseFloat(item.amount) *
                                                parseFloat(item.tax)) /
                                                100
                                          ).toFixed(2)}
                                        />
                                      </div>
                                    </Grid>
                                    <Grid md={1}>
                                      <span className="iconstoaddfee m-3">
                                        <i
                                          class="fa fa-trash pt-3"
                                          onClick={() => {
                                            let temp = [];
                                            createinvoice.splice(index, 1);
                                            createinvoice.map((item) => {
                                              temp.push(item);
                                            });

                                            setcreateinvoice(temp);
                                          }}
                                        ></i>
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Card>
                              ))}

                            {subtypeArrError && (
                              <span className="text-danger">
                                Enter Fees Subtype
                              </span>
                            )}
                          </div>
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="inputMarginright">
                                <label className="text_filed_heading">
                                  Total Amount({Currency_code})
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Total Amount"
                                  value={totalamount}
                                  disabled={
                                    selectedTemplateId === -1 ? true : false
                                  }
                                />
                                {totalamountError && (
                                  <span className="text-danger">
                                    total amount not be 0
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}></Grid>
                            <Grid item md={4}>
                              <div className="main_button_div2 mb-3 mt-4">
                                <Button
                                  variant="contained"
                                  className="button_enquiry_details button_login_decoration"
                                  onClick={createInvoiceFn}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />

      {/* -------add fee subtype dailog--------- */}
      <Dialog
        open={feeSubTypeDailogOpen}
        onClose={() => {
          setFeeSubTypeDailogOpen(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_feesubtype_setup">
          <div className="float-right mr-3 cross_font_size">
            <i
              class="fa fa-times cursor"
              onClick={() => {
                setFeeSubTypeDailogOpen(false);
              }}
            ></i>
          </div>
          <div className="mt-3 ml-2 mr-2">
            <Grid className="grid_main_div">
              <Grid item md={1}></Grid>
              <Grid item md={10}>
                <label className="text_filed_heading">Fee Subtype</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Fees Subtype"
                  value={subtypeval}
                  onChange={(e) => {
                    setsubtypevalError(false);
                    setsubtypeval(e.target.value);
                  }}
                />
                {subtypevalError && (
                  <span className="text-danger">Enter Subtype</span>
                )}
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </div>
          <Grid className="grid_main_div mt-4 mb-2">
            <Grid item md={1}></Grid>
            <Grid item md={10} className="text-center">
              <Button
                variant="contained"
                className="button_enquiry_details button_login_decoration ml-2"
                onClick={() => {
                  AddFeeSubTypeFn(subtypeval);
                }}
              >
                Add
              </Button>
            </Grid>

            <Grid item md={1}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(CreateInvoice));
