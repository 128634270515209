import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import Loder from "../../../Loder/Loder.jsx";
//------------------------------redux---------------------------
import * as Service from "../../../../services/index";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { showNotificationMsz } from "../../../../utils/Validation.js";
import SectionInvitelist from "../GetList/SectionInvitelist";

const ClassGroupSectionInvite = (props) => {
  let academic_session_id = parseInt(props.academic_session_id);
  const [InviteParticipantAPI, {}] = useMutation(
    Service.INVITE_PARTICIPANT_FOR_Section
  );

  //---------------------------api calss--------------------------
  const client = useApolloClient();

  //---------------------------local state--------------------------
  const [gradeArr, setGradeArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);

  const [sectionId, setSectionId] = useState(-1);
  const [gradeId, setGradeId] = useState(-1);
  const [isloading, setisloading] = useState(false);
  const [chekbox1, setchekbox1] = useState(true);
  const [chekbox2, setchekbox2] = useState(false);
  const [IsUpdated, setIsUpdated] = useState(false);
  const [checkValue, setCheckValue] = useState("STUDENT");
  //---------------------------methods--------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);
          setGradeArr(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
    return () => setIsUpdated(false);
  }, [IsUpdated, client, academic_session_id]);

  const selectGrade = (id) => {
    setGradeId(parseInt(id));
    setSectionArr([]);
    setSectionId(-1);
    if (id == -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(id),
          },
        })
        .then((response) => {
          setisloading(false);
          setSectionArr(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectSection = (id) => {
    try {
      setSectionId(parseInt(id));
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const InviteUser = async () => {
    try {
      if (sectionId === -1) {
        alert("PLease selecte Grade to send invide ");
        return;
      }
      let dataNeedToSend = [];
      if (sectionId === 0) {
        sectionArr.map((data) => {
          dataNeedToSend.push({ id: +data.id });
        });
      } else {
        dataNeedToSend = [{ id: +sectionId }];
      }

      setisloading(true);
      await InviteParticipantAPI({
        variables: {
          event_id: +props.id,
          section: dataNeedToSend,
          user_type: checkValue,
        },
      })
        .then((response) => {
          setisloading(false);
          setIsUpdated(!IsUpdated);
          showNotificationMsz(
            response.data?.addEventParticipantsForSection?.message,
            "success"
          );
        })
        .catch((error) => {
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <div>
        <div>
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <div className="mb-2">
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={5}>
                            <div className="inputMarginright">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={gradeId}
                                onChange={(e) => selectGrade(e.target.value)}
                              >
                                <option>Search By Class</option>

                                {gradeArr.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={sectionId}
                                onChange={(e) => selectSection(e.target.value)}
                              >
                                <option value={-1}>Search By Section</option>
                                {sectionArr && sectionArr.length > 0 && (
                                  <option value={0}>All</option>
                                )}
                                {sectionArr.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={3}></Grid>
                        </Grid>
                        <div>
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={5}>
                              <div className="mt-2 mb-2">
                                <span className="ml-4">
                                  <CheckBoxComponent
                                    label="Student Only"
                                    cssClass="e-custom"
                                    checked={chekbox1}
                                    value={"STUDENT"}
                                    onChange={(e) => {
                                      setCheckValue(e.target.value);
                                      setchekbox1(e.target.checked);

                                      setchekbox2(false);
                                    }}
                                  />
                                </span>
                                <span className="ml-4">
                                  <CheckBoxComponent
                                    label="Parent Only"
                                    cssClass="e-custom"
                                    checked={chekbox2}
                                    value={"PARENT"}
                                    onChange={(e) => {
                                      setCheckValue(e.target.value);
                                      setchekbox2(e.target.checked);
                                      setchekbox1(false);
                                    }}
                                  />
                                </span>
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className="">
                                <Button
                                  variant="contained"
                                  className="add_new_query_btn button_login_decoration button_width"
                                  // style={{ marginTop: 9 }}
                                  onClick={InviteUser}
                                >
                                  {" "}
                                  Invite
                                </Button>
                              </div>
                            </Grid>
                            <Grid item md={5}></Grid>
                          </Grid>
                        </div>
                        <div className="table_foramtitng mt-3 mb-2">
                          <SectionInvitelist
                            {...props}
                            updated={IsUpdated}
                            user_type={checkValue}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Loder loading={isloading} />
    </div>
  );
};

export default ClassGroupSectionInvite;
