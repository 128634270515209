import { useApolloClient } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import { showNotificationMsz } from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import "./ManageInstitution.css";
const ManageClass = (props) => {
  const [sectionId, setSectionId] = useState(-1);
  var today_date = new Date().toJSON().slice(0, 10);
  let institution_id = parseInt(props.manageInstitution.institution_id);
  let academic_session_id = parseInt(
    props.manageInstitution.academic_session_id
  );
  //name instituition
  let Organization_Name = props.manageInstitution.institution_name;

  const client = useApolloClient();
  const [gradeId, setGradeId] = useState(null);
  const [managegrade, setmanagegrade] = useState([]);
  const [section, setsection] = useState([]);
  const [Sectionfromgrade, setSectionfromgrade] = useState([]);
  const [isloading, setisloading] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const assignTeacher = (row) => {
    props.navigate("/timetable", {
      state: {
        classId: gradeId,
        sectionselectedid: sectionId,
      },
    });

    // setisloading(true);
    // client
    //   .query({
    //     query: Service.Get_Id_For_Teacher,
    //     variables: {
    //       institution_id,
    //       section_id: parseInt(row.id),
    //       date: today_date,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.getClassTimetableID) {
    //      props.navigate("/assignteacher", {
    //         data: row,
    //         id: response.data.getClassTimetableID.id,
    //       });
    //     } else {
    //       showNotificationMsz(
    //         "Please assign timetable to particular class",
    //         "success"
    //       );
    //     }

    //     setisloading(false);
    //   })
    //   .catch((e) => {
    //     setisloading(false);
    //     showNotificationMsz(e, "danger");
    //   });
  };
  const timetable = (row) => {
    props.navigate("/timetable", {
      state: {
        data: row,
        classId: row.grade.id,
        secId: row.name,
        classselectedid: gradeId,
        sectionselectedid: row.id,
      },
    });
  };
  const changeSectionHandler = (id) => {
    setSectionId(id);
    const filtArr = section.filter((arr) => {
      return parseInt(arr.id) === parseInt(id);
    });
    setSectionfromgrade(filtArr);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getManageGrades();
  }, []);

  const getManageGrades = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Manage_Grade,
        variables: {
          academic_session_id,
        },
      });
      if (data && data.getGrade) {
        const { getGrade } = data;
        const sortedArr = getGrade.sort((a, b) => {
          return a.gradeName.localeCompare(b.gradeName);
        });
        setmanagegrade(sortedArr);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };

  const selectClassHandler = (classId) => {
    if (classId === -1) {
      setSectionfromgrade([]);
      return;
    }
    setGradeId(classId);
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          const sectionData = response.data.getSectionAndGrade;
          setSectionId(sectionData.id);
          setSectionfromgrade(sectionData);
          const sortedSectionArr = sectionData.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          setsection(sortedSectionArr);

          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const assignSearch = () => {
    setSectionfromgrade(section);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openaddeditclass = () => {
    props.navigate("/addeditclass");
  };
  const AllTimeTableHandle = () => {
    props.navigate("/ManageAllTimeTable", sectionId);
  };
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">Class List</div>
                  {/* <div className="main_button_div mr-3 mt-2">
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration mr-2"
                      onClick={AllTimeTableHandle}
                    >
                      All TimeTable
                    </Button>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_login_decoration"
                      onClick={openaddeditclass}
                    >
                      Manage Classes
                    </Button>
                  </div> */}
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  selectClassHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Class</option>

                                {managegrade.map((item) => (
                                  <option value={item.id}>
                                    {item.gradeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="mt-1">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  changeSectionHandler(e.target.value)
                                }
                              >
                                <option value={-1}>Select Section</option>
                                {section.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={2}>
                            {/* <div className="btn_add mt-1 ml-2">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_login_decoration button_width"
                                onClick={assignSearch}
                              >
                                Search
                              </Button>
                            </div> */}
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <div className="table_foramtitng mt-3 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Grade
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Section
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Timetable
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Assign Teacher
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? Sectionfromgrade.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : Sectionfromgrade
                                ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">
                                      {row.grade.gradeName}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="content_color manage_edit_cursor"
                                      onClick={() => timetable(row)}
                                    >
                                      Manage
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="content_color manage_edit_cursor"
                                      onClick={() => assignTeacher(row)}
                                    >
                                      Assign
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={Sectionfromgrade.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(ManageClass));
