import React, { useEffect, useState } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import RichTextEditor from "react-rte";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import { baseUrl } from "../../../services/index.js";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  showNotificationMsz,
  currentDayForMinMAx,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const RequestLeave = (props) => {
  // let userid = parseInt(props.user_info.userid);

  // to create data api
  const [CREATE_STUDENT_LEAVE_REQUEST, {}] = useMutation(
    Service.CREATE_STUDENT_LEAVE_REQUEST
  );
  const [user_id, setuser_id] = useState(-1);
  const [institution_Name, setinstitution_Name] = useState("");
  const [institution_id, setinstitution_id] = useState(-1);
  useEffect(() => {
    if (props.user_info.usertype === "STUDENT") {
      setuser_id(parseInt(props.user_info.userid));
    }
    if (props.user_info.usertype === "PARENT") {
      setuser_id(parseInt(props.location.state.userid));
    }
    setinstitution_Name(props.user_info.institution_name);
    setinstitution_id(parseInt(props.user_info.institution_id));
  }, [props]);

  // ---------------api call fn-----------------------
  // const client = useApolloClient();

  // ---------------------creat data variables--------------
  // const [leavetype, setLeaveType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subject, setsubject] = useState("");

  // ---------------------creat data variables--------------

  const [filename, setFileName] = useState("Add Attachment");
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [startDateError, setstartDateError] = useState(false);
  const [enddateError, setenddateError] = useState(false);

  const [isloading, setisloading] = useState(false);
  const [subjectError, setsubjectError] = useState(false);
  const [messageEror, setmessageEror] = useState(false);

  const [UploadFileArr, setUploadFileArr] = useState([]);

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const onChange = (value) => {
    setmessageEror(false);
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  const ImageUploadHandler = (e) => {
    try {
      setisloading(true);
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);

      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFileName(file.name);
          setUploadFileArr([...UploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const leaveRequest = () => {
    let flag = false;

    if (!blankValidator(subject)) {
      setsubjectError(true);
      flag = true;
    }
    if (!blankValidator(startDate)) {
      setstartDateError(true);
      flag = true;
    }
    if (!blankValidator(endDate)) {
      setenddateError(true);
      flag = true;
    }
    if (value.toString("html") === "<p><br></p>") {
      setmessageEror(true);
      flag = true;
    }
    if (flag) {
      return;
    }

    let hourss = 8; // not needed just given the value for api compatibily;
    try {
      setisloading(true);
      CREATE_STUDENT_LEAVE_REQUEST({
        variables: {
          institution_id,
          subject,
          from_date: startDate,
          user_type: props.user_info.usertype,
          to_date: endDate,
          user_id,
          leave_category_id: -1,
          notes: value.toString("html"),
          hours: hourss,
          leave_document: UploadFileArr,
        },
      }).then(
        (response) => {
          setisloading(false);
          if (response.data.applyLeaveByStudent.errorCode === 0) {
            showNotificationMsz(
              response.data.applyLeaveByStudent.message,
              "success"
            );
          }
          if (response.data.applyLeaveByStudent.errorCode === 2) {
            showNotificationMsz(
              response.data.applyLeaveByStudent.message,
              "warning"
            );
          }

          props.navigate.goBack();

          //  props.navigate("/leaveSummary");
        },
        (err) => {
          showNotificationMsz(err, "danger");
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Leave Request</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <label
                                for="text_filed_heading"
                                className="text_filed_heading"
                              >
                                Subject
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                placeholder="Subject"
                                value={subject}
                                onChange={(e) => {
                                  setsubjectError(false);
                                  setsubject(e.target.value);
                                }}
                              />
                              {subjectError && (
                                <span className="text-danger">
                                  Enter the subject
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label
                                for="text_filed_heading"
                                className="text_filed_heading"
                              >
                                Start Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                min={currentDayForMinMAx()}
                                max={endDate}
                                value={startDate}
                                onChange={(e) => {
                                  setstartDateError(false);
                                  setStartDate(e.target.value);
                                }}
                              />
                              {startDateError && (
                                <span className="text-danger">
                                  Enter the start date
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label
                                for="text_filed_heading"
                                className="text_filed_heading"
                              >
                                End Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                min={startDate}
                                value={endDate}
                                onChange={(e) => {
                                  setenddateError(false);
                                  setEndDate(e.target.value);
                                }}
                              />
                              {enddateError && (
                                <span className="text-danger">
                                  Enter the End Date
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <label
                            for="text_filed_heading"
                            className="text_filed_heading"
                          >
                            Message
                          </label>
                          <div className="mt-1">
                            <RichTextEditor
                              value={value}
                              onChange={onChange}
                              className="editor_height"
                            />
                            {messageEror && (
                              <span className="text-danger">
                                Enter the Message
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="mt-3 mb-2 d-flex">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile01"
                                onChange={ImageUploadHandler}
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                              >
                                {filename}
                              </label>
                            </div>
                          </div>
                        </div>
                        <label className="mt-2">Attachment Lists</label>
                        <div className="mb-2 attachList ml-2">
                          {UploadFileArr.map((item, index) => (
                            <div
                              className="file_back_color ml-2 d-flex"
                              key={index}
                            >
                              <span
                                onClick={() => HandleImageDailogBox(item)}
                                className="pl-2 pr-2"
                              >
                                {item.name}
                              </span>
                              <span className="pl-3 mr-1">
                                <i
                                  class="fa fa-times cursor"
                                  onClick={() => {
                                    UploadFileArr.splice(index, 1);
                                    setUploadFileArr([...UploadFileArr]);
                                  }}
                                ></i>
                              </span>
                            </div>
                          ))}
                        </div>

                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={leaveRequest}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div>
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="profile_img"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_info: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(RequestLeave)));
