import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { blankValidator, showNotificationMsz } from "../../../utils/Validation";
import Loder from "../../Loder/Loder.jsx";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
//---------------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
//-----------api calls---------------
import { useMutation } from "@apollo/client";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

const BulkStudentUpload = ({ manageInstitution, navigate }) => {
  //-------------------------------global id-------------------------
  let institution_id = parseInt(manageInstitution.institution_id);
  let academic_session_id = parseInt(manageInstitution.academic_session_id);
  //-------------------------------api calls-------------------------
  const client = useApolloClient();
  const [Bulk_Upload_Student_API, {}] = useMutation(
    Service.Bulk_Upload_Student_API
  );
  const [Bulk_Upload_Student_API_Excel, {}] = useMutation(
    Service.Bulk_Upload_Student_API_Excel
  );
  //-------------------------------local state-------------------------
  const [academicSessionId, setAcademicSessionId] = useState("");
  const [academicSessionArr, setAcademicSessionArr] = useState([]);
  const [filePath, setfilePath] = useState("");
  const [fileFilename, setfileFilename] = useState("");
  const [filetype, setfiletype] = useState("");
  const [filename1, setfilename1] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [ClassId, setClassId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [classArr, setClassArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [filename, setFileName] = useState("Add Attachment");
  const [BulkuploadError, setBulkuploadError] = useState([]);

  //error
  const [academicsessionError, setacademicsessionError] = useState(false);
  const [classIdError, setclassIdError] = useState(false);
  const [sectionidError, setsectionidError] = useState(false);
  const [uploadfileError, setuploadfileError] = useState(false);

  //-------------------------------methods-------------------------
  useEffect(() => {
    setIsLoading(true);
    getAllAcademicSession();
  }, [isUpdated, client, academic_session_id]);

  const getAllAcademicSession = async () => {
    try {
      const { data } = await client.query({
        query: Service.GET_ALL_ACADEMIC_SESSION,
        variables: {
          institution_id,
        },
      });
      if (data && data.getAllAcademicSession) {
        setAcademicSessionArr(data.getAllAcademicSession);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const academicSessionHandler = (academicSessionId) => {
    setIsLoading(true);
    if (academicSessionId === -1) {
      setClassArr([]);
      setIsLoading(false);
      return;
    }
    setAcademicSessionId(academicSessionId);
    try {
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id: parseInt(academicSessionId),
          },
        })
        .then((response) => {
          setClassArr(response.data.getGrade);

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };

  const classHandler = (classId) => {
    if (classId === -1) {
      setSectionArr([]);
      return;
    }
    setClassId(classId);
    try {
      setIsLoading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: parseInt(classId),
          },
        })
        .then((response) => {
          setSectionArr(response.data.getSectionAndGrade);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const sectionHandler = (sectionId) => {
    setSectionId(sectionId);
  };
  const fileUploaderHandler = (e) => {
    setuploadfileError(false);
    try {
      setIsLoading(true);
      // select file
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      // create form data
      let fileData = new FormData();
      //add file to form data
      fileData.append("file", file);
      // axios api call
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setfilePath(response.data.path);
          setfileFilename(response.data.filename);
          setfiletype(response.data.type);
          setfilename1(response.data.name);
          setFileName(file.name);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("catch error", error);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setIsLoading(false);
      console.log("catch and try error", error);
    }
  };

  const submitHandler = async () => {
    if (!blankValidator(academicSessionId)) {
      setacademicsessionError(true);
      return;
    }
    // if (!blankValidator(ClassId)) {
    //   setclassIdError(true);
    //   return;
    // }
    // if (!blankValidator(sectionId)) {
    //   setsectionidError(true);
    //   return;
    // }
    if (!blankValidator(filePath)) {
      setuploadfileError(true);
      return;
    }
    // do some upload stuff
    try {
      setIsLoading(true);
      // Bulk_Upload_Student_API({
      //   variables: {
      //     institution_id,
      //     section_id: parseInt(sectionId),
      //     path: filePath,
      //     filename: fileFilename,
      //     type: filetype,
      //     name: filename1,
      //   },
      // })
      const { data } = await Bulk_Upload_Student_API_Excel({
        variables: {
          institution_id,
          path: filePath,
          filename: fileFilename,
          type: filetype,
          name: filename1,
          academic_session_id: +academicSessionId,
        },
      });
      if (data && data.studentBulkUploadByExcel) {
        let temp = [];
        data.studentBulkUploadByExcel.map((data) => {
          if (data.errorCode === 1) {
            temp.push(data);
          }
        });
        if (temp.length == 0) {
          showNotificationMsz("Student Added Successfully", "success");
        } else {
          showNotificationMsz("Student Added With Below Error", "info");
        }
        setBulkuploadError(temp);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div>
      <Grid className="grid_main_div p-3">
        <Grid item md={4} style={{ padding: 3 }}>
          <h6 className="text_filed_heading">Select Academic Session</h6>
          <div className=" mt-1">
            <select
              class="form-control"
              onChange={(e) => {
                setacademicsessionError(false);
                academicSessionHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Academic Session</option>
              {academicSessionArr.length !== 0 &&
                academicSessionArr.map((item) => (
                  <option value={item.id}>{item.session_name}</option>
                ))}
            </select>
            {academicsessionError && (
              <span className="text-danger">Select the Academic Session</span>
            )}
          </div>
        </Grid>
        <Grid item md={4} style={{ padding: 3 }}>
          {/* <h6 className="text_filed_heading">Select Class</h6>
          <div className=" mt-1">
            <select
              class="form-control"
              onChange={(e) => {
                setclassIdError(false);
                classHandler(e.target.value);
              }}
            >
              <option value={-1}>Select Class</option>
              {classArr.length !== 0 &&
                classArr.map((item) => (
                  <option value={item.id}>{item.gradeName}</option>
                ))}
            </select>
            {classIdError && (
              <span className="text-danger">Select the Class</span>
            )}
          </div> */}
        </Grid>
        <Grid item md={4} style={{ padding: 3 }}>
          {/* <h6 className="text_filed_heading">Select Section</h6>
          <select
            class="form-control"
            onChange={(e) => {
              setsectionidError(false);
              sectionHandler(e.target.value);
            }}
          >
            <option value={-1}>Select Section</option>
            {sectionArr.length !== 0 &&
              sectionArr.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
          </select>
          {sectionidError && (
            <span className="text-danger">Select the Section</span>
          )} */}
        </Grid>
      </Grid>
      <Grid className="grid_main_div p-3">
        <Grid item md={6} style={{ padding: 3 }}>
          <div className="bulk_upload_flex_data">
            <h6 className="text_filed_heading">Select File</h6>
            <a
              href={Service.baseUrl + "/upload/StudentBulkUploadFormat.xlsx"}
              target="_blank"
            >
              Download Templete
            </a>
          </div>

          <div class="custom-file">
            <input
              accept=".csv, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              className="form-control"
              onChange={fileUploaderHandler}
            />
          </div>
          <label class="custom-file-label">{filename}</label>

          {uploadfileError && (
            <span className="text-danger">Upload the file</span>
          )}
        </Grid>
        <Grid item md={6} style={{ padding: 3, marginTop: 25 }}>
          <Button
            variant="contained"
            className="button_enquiry_details_upload "
            onClick={submitHandler}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {BulkuploadError.length > 0 && (
        <span>
          <Grid className="grid_main_div">
            <Grid item md={1}>
              <div className="text-center">Row Id</div>
            </Grid>
            <Grid item md={10}>
              <div>Error Message</div>
            </Grid>
          </Grid>
        </span>
      )}
      {BulkuploadError.map((item, index) => (
        <div>
          {item.errorCode === 1 ? (
            <span>
              <Grid className="grid_main_div">
                <Grid item md={1}>
                  <div className="text-center text-danger"> {item.row_id}</div>
                </Grid>
                <Grid item md={10}>
                  <div className="text-danger"> {item.message}</div>
                </Grid>
              </Grid>
            </span>
          ) : (
            ""
          )}
        </div>
      ))}
      <Loder loading={isLoading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(BulkStudentUpload);
