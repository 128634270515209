import React, { Fragment, Component } from "react";
import AdminInstitutionHeader from "./Header/AdminInstitutionHeader.jsx";
import Footer from "./Footer/Footer.jsx";

const Hoc6 = (Wcomponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isloading: false,
      };
    }
    setLoading = () => {
      try {
        this.setState({
          isloading: !this.state.isloading,
        });
      } catch (error) {
        console.log(error);
      }
    };

    render() {
      return (
        <Fragment>
          <div className="app-container-hoc main-margin">
            <AdminInstitutionHeader setLoading={this.setLoading} {...this.props} />
            <div className="main_back_color1">
              <Wcomponent {...this.props} />
            </div>
            <Footer {...this.props} />
          </div>
        </Fragment>
      );
    }
  };
};

export default Hoc6;
