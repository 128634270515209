import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import "./Assignment.css";
import Loder from "../../Loder/Loder.jsx";
import Expand from "react-expand-animated";
import Chip from "@material-ui/core/Chip";
import Holiday from "../Holiday/Holiday.jsx";
import AssignmentQuestion from "./AssignmentQuestion.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import {
  blankValidator,
  CapitalizeFirstFn,
  getFullName,
  getValue,
  mulitpleAnswerHandler,
} from "../../../utils/Validation";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Dialog from "@material-ui/core/Dialog";

function CommonAssignmentResultView(props) {
  const [AssignmnetReview, setAssignmnetReview] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [impagepath, setimpagepath] = useState("");
  const [OpenPhotoImageDailog, setOpenPhotoImageDailog] = useState(false);

  let score = blankValidator(props?.location?.state?.studentData?.score)
    ? props?.location?.state?.studentData?.score
    : "";
  let total_score = blankValidator(
    props?.location?.state?.assignmentData?.total_score
  )
    ? props?.location?.state?.assignmentData?.total_score
    : "";

  let showval = score + " / " + total_score;

  const parse = require("html-react-parser");
  const client = useApolloClient();
  // ----------------METHODS-------------------
  useEffect(() => {
    getAssignmentdata();
  }, [props]);
  const getAssignmentdata = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Assignment_Completed_Result,
          variables: {
            assignment_id: parseInt(props.location.state.assignmentData.id),
            student_assignment_id: parseInt(
              props.location.state.studentData.id
            ),
          },
        })
        .then(
          (response) => {
            let temp = [];
            response.data.studentReviewSubmittedAssignment.map(async (data) => {
              data["show"] = false;
              //   await getuseranswerandrealAnswer(data, data.id);
              temp.push(data);
            });

            setAssignmnetReview(temp);

            setisloading(false);
            //   setAssignmentarr(response.data.getAssignmentByStatus);
          },
          (err) => {
            console.log("then err", err);
            setisloading(false);
          }
        )
        .catch((err) => {
          console.log("catch err", err);
          setisloading(false);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const HandleImageDailogBox = (data) => {
    setimpagepath(data.path);
    setOpenPhotoImageDailog(true);
  };
  return (
    <>
      <div className="main_full_width">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={getValue(props.data.institution_name)} />

                <div className="enquirydetails_background mb-2">
                  <div className="enquiry_heading">
                    <div className="ml-3 mt-2 Heading_bold">
                      Assignment Result
                    </div>
                  </div>
                  <div className="card_margin_both_side">
                    <Card className="pt-3 pb-4">
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          <Grid className="grid_main_div">
                            <Grid item md={12}>
                              <div className="text_filed_heading">
                                Student Name
                              </div>
                              <div className=" mt-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="name"
                                  autoComplete="off"
                                  value={getFullName(
                                    props?.location?.state?.studentData?.student
                                      ?.student_profile?.name
                                  )}
                                  disabled={true}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}>
                              <div className="text_filed_heading">Class</div>
                              <div className="inputMarginright mt-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="class"
                                  autoComplete="off"
                                  value={getValue(
                                    props.location.state?.gradeName
                                  )}
                                  disabled={true}
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div className="text_filed_heading">
                                Assignment Name
                              </div>
                              <div className=" mt-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="assignment name"
                                  autoComplete="off"
                                  value={getValue(
                                    CapitalizeFirstFn(
                                      props.location.state.assignmentData.name
                                    )
                                  )}
                                  disabled={true}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}>
                              <div className="text_filed_heading">Status</div>
                              <div className="inputMarginright mt-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="2-A"
                                  autoComplete="off"
                                  value={getValue(
                                    props.location.state.studentData.status
                                  )}
                                  disabled={true}
                                />
                              </div>
                            </Grid>

                            <Grid item md={6}>
                              <div className="text_filed_heading">Score</div>
                              <div className=" mt-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="score"
                                  autoComplete="off"
                                  disabled={true}
                                  value={showval}
                                />
                              </div>
                            </Grid>
                          </Grid>

                          <Grid className="grid_main_div mt-2">
                            <Grid item md={6}>
                              <div className="text_filed_heading">Remarks</div>
                              <div className="inputMarginright mt-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder=""
                                  autoComplete="off"
                                  disabled={true}
                                  value={getValue(
                                    props.location.state.studentData.remarks
                                  )}
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}></Grid>
                          </Grid>

                          <hr style={{ marginTop: "4%", marginBottom: "4%" }} />

                          <div className="mt-3">
                            {AssignmnetReview.map((item, index) => {
                              return (
                                <Card
                                  className="pt-2 pb-2 cardvisible_border mt-2"
                                  key={index}
                                >
                                  <div className="card_admissiondetails_height">
                                    <div className="textfiled_margin">
                                      <Grid className="grid_main_div mt-2 mb-3">
                                        <Grid item md={8}>
                                          {item.description === null ||
                                          item.description === "" ||
                                          item.description === undefined ? (
                                            ""
                                          ) : (
                                            <span className="d-flex">
                                              <span className="font-weight-bold mr-1">
                                                Q.{item.question_number}
                                              </span>
                                              <span>
                                                {parse.default(
                                                  item.description
                                                )}
                                              </span>
                                            </span>
                                          )}
                                        </Grid>
                                        <Grid item md={2}>
                                          <span>
                                            {item.question_section ===
                                              undefined ||
                                            item.question_section === null ? (
                                              ""
                                            ) : (
                                              <span>
                                                {item.question_section.name}
                                              </span>
                                            )}
                                          </span>
                                        </Grid>
                                        <Grid item md={2}>
                                          <div className="d-flex justify-content-between">
                                            <span className="mr-2">
                                              {item.teacher_marks ===
                                                undefined ||
                                              item.teacher_marks === null ? (
                                                ""
                                              ) : (
                                                <span className="mr-1">
                                                  {item.teacher_marks} Marks
                                                </span>
                                              )}
                                            </span>

                                            {item.show === false ? (
                                              <span
                                                className="iconstoaddfee"
                                                onClick={() => {
                                                  AssignmnetReview[
                                                    index
                                                  ].show = true;
                                                  setAssignmnetReview([
                                                    ...AssignmnetReview,
                                                  ]);
                                                  // setassignmentresult(true)
                                                }}
                                              >
                                                <i className="fa fa-plus-circle"></i>
                                              </span>
                                            ) : (
                                              <span
                                                className="iconstoaddfee"
                                                onClick={() => {
                                                  AssignmnetReview[
                                                    index
                                                  ].show = false;
                                                  setAssignmnetReview([
                                                    ...AssignmnetReview,
                                                  ]);
                                                  // setassignmentresult(true)
                                                }}
                                              >
                                                <i className="fa fa-minus-circle"></i>
                                              </span>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>

                                      <Expand open={item.show}>
                                        <div className="mt-3">
                                          <AssignmentQuestion
                                            type="STUDENT"
                                            data={item}
                                          ></AssignmentQuestion>
                                          {blankValidator(
                                            item.assignment_choices
                                          ) &&
                                          item.assignment_choices.length > 0 ? (
                                            <div>
                                              <Grid className="grid_main_div">
                                                <Grid item md={2}>
                                                  <div className=" assignment_result_data_font">
                                                    Submitted Answer{" "}
                                                    {item.question_number}:{" "}
                                                  </div>
                                                </Grid>
                                                <Grid item md={10}>
                                                  {item &&
                                                    item.assignment_submission &&
                                                    mulitpleAnswerHandler(
                                                      item.assignment_submission
                                                        ?.answer
                                                    )}
                                                </Grid>
                                              </Grid>

                                              <div>
                                                {blankValidator(
                                                  item.assignment_submission
                                                    ?.document?.path
                                                ) && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={2}>
                                                      <div className=" assignment_result_data_font">
                                                        Answer Image{" "}
                                                      </div>
                                                    </Grid>
                                                    <Grid item md={10}>
                                                      <div className="font_color_data_assignment">
                                                        <Chip
                                                          label={item.assignment_submission?.document?.name?.substring(
                                                            0,
                                                            15
                                                          )}
                                                          onClick={() => {
                                                            HandleImageDailogBox(
                                                              item?.document
                                                            );
                                                          }}
                                                          color="primary"
                                                          variant="outlined"
                                                        />
                                                        {/* <img
                                                          src={
                                                            Service.baseUrl +
                                                            "/" +
                                                            `${item?.document?.path}`
                                                          }
                                                          className="student_photo_div"
                                                          alt="profile_img"
                                                        /> */}
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              <Grid className="grid_main_div">
                                                <Grid item md={2}>
                                                  <div className=" assignment_result_data_font">
                                                    Submitted Answer{" "}
                                                    {item.question_number} :{" "}
                                                  </div>
                                                </Grid>
                                                <Grid item md={10}>
                                                  <div className="font_color_data_assignment">
                                                    {blankValidator(
                                                      item.assignment_submission
                                                    ) &&
                                                      blankValidator(
                                                        item
                                                          .assignment_submission
                                                          .answer
                                                      ) && (
                                                        <span>
                                                          {parse.default(
                                                            item
                                                              .assignment_submission
                                                              .answer
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                </Grid>
                                              </Grid>
                                              <div>
                                                {blankValidator(
                                                  item.assignment_submission
                                                    ?.document?.path
                                                ) && (
                                                  <Grid className="grid_main_div">
                                                    <Grid item md={2}>
                                                      <div className=" assignment_result_data_font">
                                                        Answer Image{" "}
                                                      </div>
                                                    </Grid>
                                                    <Grid item md={10}>
                                                      <div className="font_color_data_assignment">
                                                        <Chip
                                                          label={item.assignment_submission?.document?.name?.substring(
                                                            0,
                                                            15
                                                          )}
                                                          onClick={() => {
                                                            HandleImageDailogBox(
                                                              item?.document
                                                            );
                                                          }}
                                                          color="primary"
                                                          variant="outlined"
                                                        />
                                                        {/* <img
                                                          src={
                                                            Service.baseUrl +
                                                            "/" +
                                                            `${item.assignment_submission?.document?.path}`
                                                          }
                                                          className="student_photo_div"
                                                          alt="profile_img"
                                                        /> */}
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </div>
                                            </div>
                                          )}

                                          <span>
                                            {blankValidator(
                                              item.assignment_choices
                                            ) &&
                                            item.assignment_choices.length >
                                              0 ? (
                                              <div>
                                                <Grid className="grid_main_div mt-4">
                                                  <Grid item md={2}>
                                                    <div className="assignment_result_data_font">
                                                      Correct Answer:
                                                    </div>
                                                  </Grid>
                                                  <Grid item md={10}>
                                                    <div className="font_color_data_assignment d-flex">
                                                      {item.assignment_choices.map(
                                                        (val, index1) => (
                                                          <span key={index1}>
                                                            {val.is_answer ==
                                                              1 && val.choice}
                                                            {index != 0 &&
                                                              val.is_answer ==
                                                                1 &&
                                                              ","}
                                                          </span>
                                                        )
                                                      )}
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            ) : (
                                              <div>
                                                <Grid className="grid_main_div mt-4">
                                                  <Grid item md={2}>
                                                    <div className="assignment_result_data_font">
                                                      Correct Answer:
                                                    </div>
                                                  </Grid>
                                                  <Grid item md={10}>
                                                    <div>
                                                      {item.answer ===
                                                        undefined ||
                                                      item.answer === null ? (
                                                        ""
                                                      ) : (
                                                        <span>
                                                          {parse.default(
                                                            item.answer
                                                          )}
                                                        </span>
                                                      )}
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            )}

                                            <div className="mt-5">
                                              <hr />
                                            </div>

                                            <div className="mt-3">
                                              <div className="text_filed_heading">
                                                Notes
                                              </div>
                                              <Card className="pt-3 pb-5 cardvisible_border">
                                                <div className="mt-1 ml-2 mr-2">
                                                  {!blankValidator(
                                                    item.assignment_submission
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <span>
                                                      {
                                                        item
                                                          .assignment_submission
                                                          .remarks
                                                      }{" "}
                                                    </span>
                                                  )}
                                                </div>
                                              </Card>
                                            </div>

                                            <Grid className="grid_main_div mt-2">
                                              <Grid item md={6}>
                                                <div className="text_filed_heading">
                                                  Marks(Max.
                                                  {item.marks})
                                                </div>
                                                <div className="inputMarginright mt-2">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleFormControlInput1"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    disabled={true}
                                                    value={
                                                      blankValidator(
                                                        item.assignment_submission
                                                      ) &&
                                                      item.assignment_submission
                                                        .teacher_marks
                                                    }
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item md={6}></Grid>
                                            </Grid>
                                          </span>
                                        </div>
                                      </Expand>
                                    </div>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>{" "}
        <Loder loading={isloading} />
        <Dialog
          open={OpenPhotoImageDailog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth="fullWidth"
          onClose={() => {
            setOpenPhotoImageDailog(false);
          }}
          className="dailog_formatting"
        >
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <a
                href={Service.baseUrl + "/" + impagepath}
                download
                target="_blank"
                className="mr-3"
                rel="noreferrer"
              >
                <i className="fa fa-download"></i>
              </a>
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setOpenPhotoImageDailog(false);
                }}
              ></i>
            </div>
          </div>
          <div className="p-3">
            <img
              src={Service.baseUrl + "/" + impagepath}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </div>
        </Dialog>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(CommonAssignmentResultView));
