import React, { useState } from "react";
import "./SignIn.css";
import HOC from "../../Hoc.jsx";
import Button from "@material-ui/core/Button";

import { blankValidator, emailValidator } from "../../../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
const config = require("../../../config/default.json");

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setemailError] = useState(false);
  const [emailMatchError, setemailMatchError] = useState(false);
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const forgetPasswordHandler = () => {
    
    if (!blankValidator(email)) {
      setemailError(true);
      return;
    }
    if (!emailValidator(email)) {
      setemailMatchError(true);
      return;
    }
    if (!recaptchaValue) {
      setRecaptchaErr(true);
      return;
    }
  };
  var callback = function () {
    console.log("Done!!!!");
  };
  return (
    <div>
      <div className="main_back_color">
        <div className="main_div_Sigin">
          <div className="form_width">
            <p className="signupheading">Forgot Password</p>
            <div className="main_padding_top_bottom">
              <div className=" inputfiledformatting">
                <label
                  for="exampleInputEmail1"
                  className="text_field_heading float-left"
                >
                  Email Id
                </label>
                <input
                  type="text"
                  className="form-control input_margin_gap"
                  id="Emailid"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => {
                    setemailError(false);
                    setemailMatchError(false);
                    setEmail(e.target.value);
                  }}
                />
                {emailError && (
                  <p className="text-danger">Enter the Email Address</p>
                )}
                {emailMatchError && (
                  <p className="text-danger">Enter the correct Email Address</p>
                )}
              </div>

              <div>
                <p className="inputfiledformatting termspoilcy1">
                  If the address matches an account in the system, you will be
                  send an email with your user Id.
                </p>
              </div>
              <div className="inputfiledformatting recaptacode mt-3">
                <p>
                  <ReCAPTCHA
                    sitekey={config.config.siteKey}
                    onChange={callback}
                    onChange={(code) => {
                      if (recaptchErr) setRecaptchaErr(false);
                      setRecaptchaValue(code);
                    }}
                  />
                </p>
                {recaptchErr && (
                  <p className="text-danger text-left">
                    Please validate recaptcha
                  </p>
                )}
              </div>
              <div className="inputfiledformatting buttonpadding  mt-3">
                <Button
                  variant="contained"
                  className="login_button button_login_decoration"
                  onClick={forgetPasswordHandler}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (HOC(ForgotPassword));
