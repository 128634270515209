import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";
//-----------api calls---------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
//-----------redux---------------
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { timeStampToDate } from "../../../utils/Validation.js";

const StudentInvoiceReport = ({
  id,
  manageInstitution,
  navigate,
  class_Name,
  section_Name,
  student_Name,
}) => {
  //-----------------global id-----------------
  let student_id = parseInt(id);
  let institution_id = parseInt(manageInstitution.institution_id);
  //--------------api calls----------------
  const client = useApolloClient();
  const classes = useStyles();
  //---------------------local storage------------------
  const [isloading, setisloading] = useState(false);
  const [invoiceArr, setInvoiceArr] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, invoiceArr.length - page * rowsPerPage);

  //--------------methods-----------------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getStudentInvoice();
  }, []);
  const getStudentInvoice = () => {
    try {
      setisloading(true);

      client
        .query({
          query: Service.GET_Student_Invoice_Data,
          variables: {
            student_id,
            institution_id,
          },
        })
        .then((response) => {
          setisloading(false);
          const studentInvoiceArr = response.data.getInvoiceByStudentId;
          if (studentInvoiceArr.length !== 0) {
            setInvoiceArr(studentInvoiceArr);
          }
        })
        .catch((error) => {
          setisloading(false);
          console.log("student list  catch err", error);
        });
    } catch (error) {
      console.log("student list  t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={"FIND/ORGANISATION/INSTITUTION"} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading admission_intrrest">
                  <div className="mt-3 Heading_bold">
                    Student Invoice {`( ${class_Name} ${section_Name} )`}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mt-2 mb-2">
                        <Grid className="grid_main_div">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Student Id"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Student Name"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Invoice Title</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Status</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Start Date"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="End Date"
                                autoComplete="off"
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="btn_add mt-1">
                              <Button
                                variant="contained"
                                className="add_new_query_btn button_width button_login_decoration"
                              >
                                Search
                              </Button>
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="mt-3 mb-1 Heading_bold">Invoice</div>

                        <div className="card_admissiondetails_height">
                          <div className="textfiled_margin mt-2 mb-2">
                            <div className="table_foramtitng">
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        className="table_header"
                                        align="left"
                                      >
                                        Student Name
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Class/Section
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Invoice Title
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Invoice Number
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="table_header"
                                      >
                                        Invoice Date
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        className="table_header"
                                      >
                                        Due Date
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(rowsPerPage > 0
                                      ? invoiceArr.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : invoiceArr
                                    ).map((row) => (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {row.studentname}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          
                                          scope="row"
                                          align="left"
                                        ></StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.invoice.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left"></StyledTableCell>
                                        <StyledTableCell align="left">
                                          {timeStampToDate(
                                            timeStampToDate(
                                              row.invoice.create_date
                                            )
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {timeStampToDate(row.due_date)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left"></StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <TablePagination
                                  true
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={invoiceArr.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const mapStateToProps = (state) => ({
  manageInstitution: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(StudentInvoiceReport)));
