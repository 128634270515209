import React, { useEffect, useState } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Monday from "./calendar/Monday.jsx";
import Loder from "../../Loder/Loder.jsx";
import moment from "moment";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { dateFormatToShow } from "../../../utils/Validation";
//------------api calls-----------------
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";

const TimeTable = ({
  sectionId,
  seeker,
  navigate,
  class_Name,
  section_Name,
  institution_name,
}) => {
  //------------------------------global id-------------------------
  let section_id = parseInt(sectionId);
  let startOfWeek = moment().startOf("isoweek").toDate();
  let endOfWeek = moment().endOf("isoweek").toDate();
  //------------------------------api calls-------------------------
  const client = useApolloClient();
  //------------------------------local state-------------------------
  const [isloading, setisloading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  let day = new Date().getDay() - 1;
  if (day < 0) {
    day = 6;
  }
  const [value, setValue] = useState(day);
  //------------------------------methods-------------------------
  useEffect(() => {
    try {
      setisloading(true);

      client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.log("student list  catch err", error);
        });
    } catch (error) {
      setisloading(false);
      console.log("student list  t and c error", error);
    }
    return () => {
      setIsUpdated(false);
    };
  }, [isUpdated, section_id, client]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-3 Heading_bold">
                    {class_Name} - {section_Name}
                  </div>
                  <div className="mr-3 mt-3">
                    <h6>{`${dateFormatToShow(startOfWeek)}-${dateFormatToShow(
                      endOfWeek
                    )}`}</h6>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div>
                      <div className="textfiled_margin mb-2">
                        <div className="mt-2">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            left
                          >
                            <Tab
                              label="Monday"
                              {...a11yProps(0)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Tuesday"
                              {...a11yProps(1)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Wednesday"
                              {...a11yProps(2)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Thursday"
                              {...a11yProps(3)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Friday"
                              {...a11yProps(4)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Saturday"
                              {...a11yProps(5)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                            <Tab
                              label="Sunday"
                              {...a11yProps(6)}
                              className="text-capitalize"
                              style={{ minWidth: 130 }}
                            />
                          </Tabs>
                        </div>

                        <TabPanel value={value} index={0}>
                          <Monday
                            sectionId={section_id}
                            day={1}
                            name="Monday"
                            seeker={seeker}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                          <div>
                            <Monday
                              sectionId={section_id}
                              day={2}
                              name="Tuesday"
                              seeker={seeker}
                            />
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                          <div>
                            <Monday
                              sectionId={section_id}
                              day={3}
                              name="Wednesday"
                              seeker={seeker}
                            />
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                          <div>
                            <Monday
                              sectionId={section_id}
                              day={4}
                              name="Thursday"
                              seeker={seeker}
                            />
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={4}>
                          <div>
                            <Monday
                              sectionId={section_id}
                              day={5}
                              name="Friday"
                              seeker={seeker}
                            />
                          </div>
                        </TabPanel>

                        <TabPanel value={value} index={5}>
                          <div>
                            <Monday
                              sectionId={section_id}
                              day={6}
                              name="Saturday"
                              seeker={seeker}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                          <div>
                            <Monday
                              sectionId={section_id}
                              day={6}
                              name="Sunday"
                              seeker={seeker}
                            />
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default HOC1(TimeTable);
