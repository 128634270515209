import { useApolloClient, useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import {
  getcurrentDate,
  blankValidator,
  getValue,
  serverFormatDate,
  showNotificationMsz,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder";
import "./HolidayCalender.css";

const HolidayCalender = (props) => {
  const [search, setSearch] = useState("");
  const classes = useStyles();
  const client = useApolloClient();
  const [isloading, setisloading] = useState(false);
  const [holidatCalenderArray, setHolidatCalenderArray] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState("");
  let institution_id = parseInt(props.data.institution_id);

  let academic_session_id = blankValidator(
    parseInt(props.location?.state?.academic_session_id)
  )
    ? parseInt(props.location.state.academic_session_id)
    : -1;

  useEffect(() => {
    getSchoolCalenderData();
  }, []);
  const getSchoolCalenderData = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.get_School_Calendar,
          variables: {
            institution_id,
            date: serverFormatDate(new Date()),
            academic_session_id,
          },
        })
        .then((response) => {
          if (blankValidator(response?.data?.getSchoolCalendar)) {
            const sortedArr = response?.data?.getSchoolCalendar.sort((a, b) => {
              return +a.start_datetime - +b.start_datetime;
            });

            setHolidatCalenderArray(sortedArr);
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const holiDayDelete = (row) => {
    setopendialogConfimation(true);
    setDeleteRowID(row.id);
  };
  const [delete_School_Calendar, {}] = useMutation(
    Service.delete_School_Calendar
  );
  const DeleteHandle = () => {
    try {
      setisloading(true);
      delete_School_Calendar({
        variables: {
          id: parseInt(deleteRowID),
        },
      }).then(
        (response) => {
          showNotificationMsz(
            response.data.deleteSchoolCalendar.message,
            "success"
          );
          setopendialogConfimation(false);
          getSchoolCalenderData();
          setisloading(false);
        },
        (err) => {
          console.log("then error", err);
          showNotificationMsz(err, "danger");
          setisloading(false);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const filterUpcomingEvent = holidatCalenderArray.filter((item) => {
    return getValue(item?.name).toLowerCase().indexOf(search.toLowerCase()) !==
      -1
      ? true
      : false;
  });

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.data.institution_name} />
            <div className="enquirydetails_background mb-2">
              {/* -------holiday top-------- */}
              <div className="enquiry_heading">
                <div className="ml-3 mt-3 Heading_bold">Holiday </div>
                {(props.data.usertype === "ADMIN" ||
                  props.data.usertype === "SUPERADMIN") && (
                  <div
                    className="mr-3 mt-4 Heading_bold date_time_color"
                    role="button"
                    onClick={() =>
                      props.navigate("/addholidaycalender", {
                        state: {
                          academic_session_id,
                        },
                      })
                    }
                  >
                    Add Holiday
                  </div>
                )}
              </div>
              {/* ----------table section-------- */}
              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-1">
                        <Grid item md={3}>
                          <div className="inputMarginright mt-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search Holidays"
                              autoComplete="off"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={9}></Grid>
                      </Grid>
                      <div className=" table_foramtitng mt-2">
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  align="center"
                                  className="table_header"
                                >
                                  Name
                                </StyledTableCell>

                                <StyledTableCell
                                  align="center"
                                  className="table_header"
                                >
                                  Start Date
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  className="table_header"
                                >
                                  End Date
                                </StyledTableCell>

                                {(props.data.usertype === "ADMIN" ||
                                  props.data.usertype === "SUPERADMIN") && (
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Action
                                  </StyledTableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? filterUpcomingEvent.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : filterUpcomingEvent
                              ).map((row, index) => {
                                return (
                                  <StyledTableRow
                                    key={index}
                                    style={{ width: "100%" }}
                                  >
                                    <StyledTableCell align="center">
                                      <span>{row.name && row.name}</span>
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      <span>
                                        {timeStampToDatedashDayStart(
                                          row.start_datetime
                                        )}
                                      </span>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <span>
                                        {timeStampToDatedashDayStart(
                                          row.end_datetime
                                        )}
                                      </span>
                                    </StyledTableCell>
                                    {(props.data.usertype === "ADMIN" ||
                                      props.data.usertype === "SUPERADMIN") && (
                                      <StyledTableCell align="center">
                                        <div className=" ">
                                          {" "}
                                          <span
                                            role="button"
                                            className="text-info pr-1"
                                            onClick={() => {
                                              props.navigate(
                                                "/updateholidaycalender",
                                                {
                                                  state: {
                                                    data: row,
                                                    academic_session_id,
                                                  },
                                                }
                                              );
                                            }}
                                          >
                                            Edit
                                          </span>
                                          |
                                          <span
                                            role="button"
                                            className="text-info pl-1 pr-1"
                                            onClick={() => {
                                              holiDayDelete(row);
                                            }}
                                          >
                                            Delete
                                          </span>
                                        </div>
                                      </StyledTableCell>
                                    )}
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                          <TablePagination
                            true
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filterUpcomingEvent.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
      <Dialog
        open={opendialogConfimation}
        onClose={() => {
          setopendialogConfimation(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="dailog_formatting"
      >
        <div className="dailog_width_review_leave">
          <div>
            {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
            <div className="cut_dailog_icon float-right">
              <i
                className="fa fa-times mr-3 cursor"
                aria-hidden="true"
                onClick={() => {
                  setopendialogConfimation(false);
                }}
              ></i>
            </div>
          </div>
          <div className="pl-5 pr-5 mt-4">
            Are you sure want to delete Holiday
          </div>

          <Grid className="grid_main_div mt-2  mb-2 text-center">
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <div className="acceptation_btn">
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration mr-2"
                  onClick={DeleteHandle}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="add_new_query_btn button_login_decoration"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(HolidayCalender));
