import { useApolloClient } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
//---------------redux-------------------
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//---------------api calls-------------------
import * as Service from "../../../services/index";
import {
  blankValidator,
  getFullName,
  timeStampToDateandTime,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder.jsx";
import "./AdmissionIntrest.css";

const AdmissionIntrest = ({ ADMIN_DATA, navigate }) => {
  //---------------global id-------------------
  const { institution_id, institution_name, academic_session_id, usertype } =
    ADMIN_DATA;

  //---------------api calls-------------------
  const client = useApolloClient();
  const classes = useStyles();
  //---------------local state-------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [admissionInterestArr, setAdmissionInterestArr] = useState([]);
  const [academicSessionArr, setAcademicSessionArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setstatus] = useState("");
  const [name, setname] = useState("");

  const [session, setSession] = useState("");

  //---------------methods-------------------
  useEffect(() => {
    // window.scrollTo(0, 0);
    getAllAcademicSession();
  }, [client, institution_id]);

  const getAllAcademicSession = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.GET_ALL_ACADEMIC_SESSION,
        variables: {
          institution_id: parseInt(institution_id),
        },
      });
      if (data.getAllAcademicSession && data.getAllAcademicSession.length > 0) {
        setAcademicSessionArr(data.getAllAcademicSession);
      }
      await getAdmissionInterestData(academic_session_id.toString());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openeditadmissionIntrest = (item) => {
    navigate("/enquiryDetails", {
      state: {
        pagetype: "edit",
        data: item,
      },
    });
  };

  const getAdmissionInterestData = async (sessionId) => {
    try {
      let stringId = sessionId;
      setAdmissionInterestArr([]);
      if (parseInt(sessionId) === -1 || sessionId === "") {
        return;
      }
      setSession(stringId);
      setIsLoading(true);
      const { data } = await client.query({
        query: Service.Get_Admission_Interest_Data,
        variables: {
          institution_id: parseInt(institution_id),
          academic_session: stringId,
        },
      });
      if (data && data.getAddmissionEnquiry) {
        const { getAddmissionEnquiry } = data;
        let sortedArr = getAddmissionEnquiry.sort((a, b) => {
          return 1;
        });
        setAdmissionInterestArr(sortedArr);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("t and c error", error);
    }
  };

  const filterData = admissionInterestArr.filter((event) => {
    if (
      !blankValidator(getFullName(event.fatherName)) ||
      !blankValidator(event.status)
    ) {
      return false;
    }
    return (
      getFullName(event.fatherName)
        .toLowerCase()
        .indexOf(name.toLowerCase()) !== -1 &&
      event.status.toLowerCase().indexOf(status.toLowerCase()) !== -1
    );
  });

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_name} mynav={"/admissionsMenu"} />
              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <div className="mt-2 ml-4 Heading_bold">
                    Admission Interest
                  </div>
                  <div className="mt-3 mr-3">
                    <Button
                      variant="contained"
                      className="add_new_query_btn  button_login_decoration mr-2"
                      onClick={() =>
                        navigate("/submitAdmissionIntrest", {
                          state: {
                            pagetype: "add",
                          },
                        })
                      }
                    >
                      Add New Query
                    </Button>
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <Grid className="grid_main_div gapfromanotherinput mb-3">
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <select
                                className="form-control "
                                value={session}
                                onChange={(e) =>
                                  getAdmissionInterestData(e.target.value)
                                }
                              >
                                <option value={-1}>
                                  Select Academic Session
                                </option>
                                {academicSessionArr.length !== 0 ? (
                                  academicSessionArr.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.session_name}
                                    </option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Parent Name"
                                autoComplete="off"
                                value={name}
                                onChange={(e) => {
                                  setname(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Status"
                                autoComplete="off"
                                value={status}
                                onChange={(e) => {
                                  setstatus(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                        <div className="table_foramtitng mt-5">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Parent Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Child Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Enquiry date/time
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Time of Enquiry
                                  </StyledTableCell> */}

                                  <StyledTableCell className="table_header">
                                    Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Actions
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filterData.length !== 0 ? (
                                  (rowsPerPage > 0
                                    ? filterData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterData
                                  ).map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="left">
                                        {getFullName(row.fatherName)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {getFullName(row.studentName)}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {timeStampToDateandTime(
                                          row.update_time
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        {row.status && row.status.toUpperCase()}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        <div className="d-flex justify-content-center">
                                          {row.status == "ACCEPTED" ? (
                                            <div>
                                              <span
                                                className="row_details_color pr-2"
                                                onClick={() => {
                                                  openeditadmissionIntrest(row);
                                                }}
                                              >
                                                Open
                                              </span>
                                              |
                                              <span
                                                className="pl-2 row_details_color"
                                                onClick={() =>
                                                  navigate(
                                                    "/adminstudentlistview"
                                                  )
                                                }
                                              >
                                                Current status
                                              </span>
                                            </div>
                                          ) : (
                                            <div>
                                              <span
                                                className="row_details_color"
                                                onClick={() => {
                                                  openeditadmissionIntrest(row);
                                                }}
                                              >
                                                Open
                                              </span>{" "}
                                            </div>
                                          )}
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  <div>No data</div>
                                )}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filterData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  ADMIN_DATA: getData(state, Constants.TOKEN) || {},
});

export default connect(mapStateToProps, null)(HOC1(AdmissionIntrest));
