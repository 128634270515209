import React from "react";
import HOC1 from "../../Hoc1";
import Loder from "../../Loder/Loder";

const VerifyPayment = () => {
  return (
    <>
      <div>Your payment has been completed, redirecting to fees summary</div>
      <Loder loading={true} />
    </>
  );
};

export default HOC1(VerifyPayment);
