import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "./Exam.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
// import Checkbox from '@material-ui/core/Checkbox';
// import Dialog from '@material-ui/core/Dialog';
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import Loder from "../../Loder/Loder";
import {
  CapitalizeFirstFn,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";
//redux
import * as Service from "../../../services/index";

import { useApolloClient } from "@apollo/client";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ExamViewResults(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [openRemarkdailog, setOpenRemarkdailog] = useState(false);
  const client = useApolloClient();
  const [examSubjectResult, setexamSubjectResult] = useState([]);

  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const getSubjectExamTimeTable = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_ExamResult_BySubjectAndExamId,
            variables: {
              exam_id: 1,
              subject_id: 1,
            },
          })
          .then(
            (response) => {
              if (response.data.getExamResultBySubjectAndExamId.length !== 0) {
                setexamSubjectResult(
                  response.data.getExamResultBySubjectAndExamId
                );
              }
              // setAssignmentarr(response.data.getAssignment);
              setisloading(false);
            },
            (err) => {
              setisloading(false);
              console.log("then err", err);
            }
          )
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getSubjectExamTimeTable();
  }, [client]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="ml-3 mt-2 Heading_bold">View Results</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="pt-2 pb-4">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="mt-2 mb-3">
                          <div className="float-left Heading_bold">
                            {props.location.state.data === null ||
                            props.location.state.data === undefined ? (
                              ""
                            ) : (
                              <span>
                                {" "}
                                {CapitalizeFirstFn(
                                  props.location.state.examName
                                )}
                              </span>
                            )}{" "}
                            - Section Name -{" "}
                            {props.location.state.data.subject === null ||
                            props.location.state.data.subject === undefined ? (
                              ""
                            ) : (
                              <span>
                                {props.location.state.data.subject.name}
                              </span>
                            )}
                          </div>
                          <div className="float-right Heading_bold">
                            {" "}
                            Exam Date :
                            {props.location.state.data.start_calendar
                              .datetime === undefined ||
                            props.location.state.data.start_calendar
                              .datetime === null ? (
                              ""
                            ) : (
                              <span>
                                {timeStampToDatedashDayStart(
                                  props.location.state.data.start_calendar
                                    .datetime
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-3 mb-3">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Roll Number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Name
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Exam Attendence Status
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    className="table_header"
                                  >
                                    Marks
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? examSubjectResult.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : examSubjectResult
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {row.exam_result === undefined ||
                                      row.exam_result === null ? (
                                        ""
                                      ) : (
                                        <span>
                                          {row.exam_result.student ===
                                            undefined ||
                                          row.exam_result.student === null ? (
                                            ""
                                          ) : (
                                            <span>
                                              {
                                                row.exam_result.student
                                                  .roll_number
                                              }
                                            </span>
                                          )}
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      
                                      scope="row"
                                      align="left"
                                    >
                                      {row.exam_result === undefined ||
                                      row.exam_result === null ? (
                                        ""
                                      ) : (
                                        <span>
                                          {row.exam_result.student ===
                                            undefined ||
                                          row.exam_result.student === null ? (
                                            ""
                                          ) : (
                                            <span>
                                              {row.exam_result.student ===
                                                undefined ||
                                              row.exam_result.student ===
                                                null ? (
                                                ""
                                              ) : (
                                                <span>
                                                  {row.exam_result.student
                                                    .student_profile ===
                                                    undefined ||
                                                  row.exam_result.student
                                                    .student_profile ===
                                                    null ? (
                                                    ""
                                                  ) : (
                                                    <span>
                                                      {
                                                        row.exam_result.student
                                                          .student_profile.name
                                                          .first_name
                                                      }
                                                    </span>
                                                  )}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </span>
                                      )}{" "}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <CheckBoxComponent
                                        checked={true}
                                        cssClass="e-custom"
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.marks === undefined ||
                                      row.marks === null ? (
                                        ""
                                      ) : (
                                        <span>{row.marks}</span>
                                      )}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={examSubjectResult.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(ExamViewResults);
