import React, { useState, useEffect } from "react";
import "./Leave.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import RichTextEditor from "react-rte";
import Button from "@material-ui/core/Button";

import Loder from "../../Loder/Loder.jsx";
import Chip from "@material-ui/core/Chip";
import { baseUrl } from "../../../services/index.js";
import axios from "axios";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import Dialog from "@material-ui/core/Dialog";
import Holiday from "../../Common/Holiday/Holiday.jsx";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  getNumberOfHoursByday,
  showNotificationMsz,
  currentDayForMinMAx,
} from "../../../utils/Validation";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

const ApplyLeave = (props) => {
  //leave type

  let leave_category_name = props.location.state.item.name;
  let leave_category_id = parseInt(props.location.state.item.id);
  //instituiion id
  let institution_id = parseInt(props.data.institution_id);

  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };
  const HandleImageDailogBoxClose = () => {
    setimagepath("");
    setimageType("");
    setOpenImageDailog(false);
  };

  const [filename, setFileName] = useState("Add Attachment");
  const user_id = parseInt(props.data.userid);
  const [subject, setSubject] = useState("");
  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [checkoption, setcheckoption] = useState("FULL");
  const [datediffrenceEror, setdatediffrenceEror] = useState(false);
  const [leaveSubjectError, setleaveSubjectError] = useState(false);
  const [startdateError, setstartdateError] = useState(false);
  const [enddaterror, setenddaterror] = useState(false);
  const [messageEror, setmessageEror] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [uploadFileArr, setUploadFileArr] = useState([]);

  const [CREATE_TEACHER_LEAVE_REQUEST, {}] = useMutation(
    Service.CREATE_TEACHER_LEAVE_REQUEST
  );

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const submit = () => {
    let scroll = false;
    let flag = false;
    try {
      if (!blankValidator(subject)) {
        setleaveSubjectError(true);

        if (scroll === false) {
          document.getElementById("subjectt").focus();
        }
        scroll = true;
        flag = true;
      }
      if (!blankValidator(startDate)) {
        setstartdateError(true);
        if (scroll === false) {
          document.getElementById("startdate").focus();
        }
        scroll = true;
        flag = true;
      }
      if (!blankValidator(endDate)) {
        setenddaterror(true);
        if (scroll === false) {
          document.getElementById("enddate").focus();
        }
        scroll = true;
        flag = true;
      }

      if (value.toString("html") === "<p><br></p>") {
        setmessageEror(true);
        if (scroll === false) {
          document.getElementById("message").focus();
        }
        scroll = true;
        flag = true;
      }
      let hours;
      if (
        !blankValidator(props.data.half_day_hours) ||
        !blankValidator(props.data.full_day_hours)
      ) {
        showNotificationMsz("Please Contact to Admin", "success");

        return;
      }
      if (flag) {
        return;
      }

      let leave_type;
      if (checkoption === "HALF") {
        let halfhours = parseInt(props.data.half_day_hours);
        hours = getNumberOfHoursByday(startDate, endDate, halfhours);
        leave_type = "HALF";
      } else {
        let fullhours = parseInt(props.data.full_day_hours);
        hours = getNumberOfHoursByday(startDate, endDate, fullhours);
        leave_type = "FULL";
      }

      setisloading(true);
      CREATE_TEACHER_LEAVE_REQUEST({
        variables: {
          institution_id,
          subject: subject,
          from_date: startDate,
          to_date: endDate,
          user_id: user_id,
          leave_category_id,
          leave_type: leave_type,
          notes: value.toString("html"),
          hours,
          leave_document: uploadFileArr,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.applyLeaveByEmployee.message,
            "success"
          );

         props.navigate("teacherLeaveSummary");
        },
        (err) => {
          setisloading(false);
          console.log("then error", err);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const ImageUploadHandler = (e) => {
    try {
      const file = e.target.files[0];
      const url = `${Service.baseUrl}/uploadFile/image`;
      let fileData = new FormData();
      fileData.append("file", file);
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          setFileName(file.name);
          setUploadFileArr([...uploadFileArr, obj]);
        })
        .catch((error) => {
          setisloading(false);
          console.log("catch error", error);
          alert(error.message);
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  const onChange = (value) => {
    setmessageEror(false);
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  <div className="mt-3 ml-3 Heading_bold">Leave Request</div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="mt-1 mb-3">
                              <CheckBoxComponent
                                label="Half Day"
                                cssClass="e-custom mr-3"
                                value="HALF"
                                checked={checkoption === "HALF"}
                                onChange={(e) => {
                                  setcheckoption("HALF");
                                }}
                              />
                              <CheckBoxComponent
                                label="Full Day"
                                cssClass="e-custom"
                                value="FULL"
                                checked={checkoption === "FULL"}
                                onChange={(e) => {
                                  setcheckoption("FULL");
                                }}
                              />
                            </div>
                            <div className="">
                              <label for="exampleInputEmail1">Leave Type</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Demo"
                                autoComplete="off"
                                value={leave_category_name}
                                disabled={true}
                              />
                            </div>
                            <div className="">
                              <label for="exampleInputEmail1">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="subjectt"
                                placeholder="Subject"
                                autoComplete="off"
                                value={subject}
                                onChange={(e) => {
                                  setleaveSubjectError(false);
                                  setSubject(e.target.value);
                                }}
                              />
                              {leaveSubjectError && (
                                <span className="text-danger">
                                  Enter Subject of leave
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-1">
                          <Grid item md={6}>
                            <div className="inputMarginright">
                              <label for="exampleInputEmail1">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="startdate"
                                autoComplete="off"
                                min={currentDayForMinMAx()}
                                max={endDate}
                                value={startDate}
                                onChange={(e) => {
                                  setstartdateError(false);

                                  setStartDate(e.target.value);
                                }}
                              />
                              {startdateError && (
                                <span className="text-danger">
                                  Enter Start Date
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={6}>
                            <div className="">
                              <label for="exampleInputEmail1">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="enddate"
                                autoComplete="off"
                                min={startDate}
                                value={endDate}
                                onChange={(e) => {
                                  setenddaterror(false);
                                  setdatediffrenceEror(false);
                                  setEndDate(e.target.value);
                                }}
                              />
                              {enddaterror && (
                                <span className="text-danger">
                                  Enter End Date
                                </span>
                              )}
                              {datediffrenceEror && (
                                <span className="text-danger">
                                  Maximum 5 days can be selected
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2 mb-2">
                          <label for="exampleInputEmail1">Message</label>
                          <div className="mt-3 mb-3">
                            <RichTextEditor
                              id="message"
                              value={value}
                              onChange={onChange}
                              className="editor_height"
                            />
                            {messageEror && (
                              <span className="text-danger">
                                Enter The Message
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-3 mb-2">
                          <label for="exampleInputEmail1">Attachment</label>
                          <div class="input-group mt-1">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fa fa-paperclip"></i>
                              </span>
                            </div>
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile01"
                                onChange={ImageUploadHandler}
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                              >
                                {filename}
                              </label>
                            </div>
                          </div>
                        </div>
                        <label className="mt-2">Attachment Lists</label>
                        <div className="mb-2 attachList ml-2">
                          {uploadFileArr.map((item, index) => (
                            <Chip
                              label={item.name}
                              onClick={() => {
                                HandleImageDailogBox(item);
                              }}
                              // onDelete={() => {
                              //   uploadFileArr.splice(index, 1);
                              //   setUploadFileArr([...uploadFileArr]);
                              // }}
                              color="primary"
                              variant="outlined"
                            />
                          ))}
                        </div>
                        <Grid className="grid_main_div mt-2 mb-3">
                          <Grid item md={6}></Grid>
                          <Grid item md={6}>
                            <div className="main_button_div mb-3">
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration mr-2"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                className="button_enquiry_details button_login_decoration"
                                onClick={submit}
                              >
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={HandleImageDailogBoxClose}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={HandleImageDailogBoxClose}
            ></i>
          </div>
        </div>
        <div className="p-3">
          <span>
            <img
              src={baseUrl + "/" + imagepath}
              className="UploadDocImagewidth"
              alt="No_profile"
            />
          </span>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(ApplyLeave)));
