import Grid from "@material-ui/core/Grid";
import React from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
import { getValue } from "../../../utils/Validation";
import UpcomingEventsComponent from "./UpcomingEventsComponent";
import Holiday from "../Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";

function ParentManageEvent(props) {
  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={getValue(props.data.institution_name)} />
              <UpcomingEventsComponent navigate={props.navigate} />
            </Grid>
          </Grid>
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(ParentManageEvent)));
