import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import "./Transport.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";

import Loder from "../../Loder/Loder.jsx";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import { useMutation } from "@apollo/client";
import {
  blankValidator,
  getFullName,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import AssignedStudentList from "./AssignedStudentList.jsx";

const AssignStudent = (props) => {
  const [Assign_Student_Transport, {}] = useMutation(
    Service.Assign_Student_Transport
  );
  const routeInfo = props.location.state.routeInfo;
  let Organization_Name = props.data.institution_name;
  let academic_session_id = props.data.academic_session_id;
  let institution_id = parseInt(props.data.institution_id);
  const client = useApolloClient();
  const [studentArr, setStudentArr] = useState([]);
  const [managegrade, setmanagegrade] = useState([]);
  const [section, setsection] = useState([]);
  const [gradeId, setGradeId] = useState("");
  const [section_id, setsection_id] = useState("");
  const [isloading, setisloading] = useState(false);
  const [routeListArr, setrouteListArr] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [pickUpId, setpickUpId] = useState("");
  const [dropId, setdropId] = useState("");
  const [studentId, setstudentId] = useState("");

  //error
  const [gradeIdError, setgradeIdError] = useState(false);
  const [sectionIdError, setsectionIdError] = useState(false);
  const [studentError, setstudentError] = useState(false);
  const [pickupError, setpickupError] = useState(false);
  const [dropError, setdropError] = useState(false);

  useEffect(() => {
    getPickUpRouteDetails();
  }, [institution_id, client, isUpdated]);

  const getPickUpRouteDetails = async () => {
    try {
      setisloading(true);
      const { data } = await client.query({
        query: Service.Get_Pickup_Route_Stop,
        variables: {
          transport_route_id: +routeInfo.id,
        },
      });

      if (data && data.getTransportStop) {
        setrouteListArr(data.getTransportStop);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false);
      console.log("get transport error", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getManageGrades();
  }, [props]);
  const getManageGrades = async () => {
    try {
      await client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setmanagegrade(response.data.getGrade);
        })
        .catch((e) => console.log("catch error", e));
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const selectHandler = async (classId) => {
    if (+classId === -1) {
      setsection([]);
      setStudentArr([]);
      return;
    }
    setgradeIdError(false);
    setGradeId(classId);
    setisloading(true);
    try {
      await client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id: +classId,
          },
        })
        .then((response) => {
          setsection([]);
          setStudentArr([]);
          setisloading(false);
          setsection(response.data.getSectionAndGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      console.log("t and c error", error);
    }
  };
  const DropHandler = (id) => {
    setdropError(false);
    setdropId(id);
  };
  const AssignStudentHandler = async () => {
    try {
      if (!blankValidator(gradeId)) {
        setgradeIdError(true);
        return;
      }
      if (!blankValidator(section_id)) {
        setsectionIdError(true);
        return;
      }
      if (!blankValidator(studentId)) {
        setstudentError(true);
        return;
      }
      if (!blankValidator(pickUpId)) {
        setpickupError(true);
        return;
      }

      setisloading(true);
      const { data } = await Assign_Student_Transport({
        variables: {
          id: +studentId,
          transport_pickup_stop_id:
            routeInfo?.rowdata?.route_type === "PICK" ? +pickUpId : -1,
          transport_drop_stop_id:
            routeInfo?.rowdata?.route_type === "DROP" ? +pickUpId : -1,
        },
      });

      if (data && data.assignStudentTransport) {
        showNotificationMsz(data.assignStudentTransport.message, "success");
        setIsUpdated(true);
      }
      setisloading(false);
    } catch (error) {
      console.log("t and c error", error);
    }
  };

  const PickupHandler = async (id) => {
    setpickupError(false);
    setpickUpId(id);
  };
  const selectStudentHandler = (id) => {
    setstudentError(false);
    setstudentId(id);
  };
  const Get_Student_By_sectionId = async (e) => {
    if (e.target.value === "") {
      setsection_id(e.target.value);
      return;
    }
    setsectionIdError(false);
    let section_id = parseInt(e.target.value);
    setsection_id(section_id);
    setisloading(true);
    try {
      await client
        .query({
          query: Service.GET_StudentList_by_SectionID,
          variables: {
            section_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setStudentArr(response.data.getStudentListBySectionId);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={Organization_Name} />

            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="ml-3 Heading_bold mt-3">Assign Student</div>
              </div>
              {/* --------------subtext aligne center with style----------------- */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontWeight: "600" }}>Route Name :</p>
                <p style={{ textTransform: "capitalize" }}>
                  {routeInfo && routeInfo.routeName && routeInfo.routeName}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontWeight: "600" }}>Route Type :</p>
                <p style={{ textTransform: "capitalize" }}>
                  {routeInfo?.rowdata?.route_type}
                </p>
              </div>
              {/* --------------subtext aligne center with style----------------- */}

              <div className="card_margin_both_side">
                <Card>
                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div gapfromanotherinput mt-2 mb-2">
                        <Grid item md={4}>
                          <div className="text_filed_heading">Class</div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) => selectHandler(e.target.value)}
                            >
                              <option value="">Select Class</option>
                              {managegrade.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.gradeName}
                                </option>
                              ))}
                            </select>
                            {gradeIdError && (
                              <span className="text-danger">
                                Select the Class
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">Section</div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) => Get_Student_By_sectionId(e)}
                            >
                              <option value="">Select Section</option>
                              {section.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {sectionIdError && (
                              <span className="text-danger">
                                Select the Section
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div className="text_filed_heading">Student Name</div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) =>
                                selectStudentHandler(e.target.value)
                              }
                            >
                              <option value="">Select Student</option>
                              {studentArr.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {getFullName(item?.student_profile?.name)}
                                </option>
                              ))}
                            </select>
                            {studentError && (
                              <span className="text-danger">
                                Select the Student
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      {/* for transport route */}

                      <Grid className="grid_main_div gapfromanotherinput mt-2 mb-2">
                        <Grid item md={4}>
                          <div className="text_filed_heading">Stop Name</div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) => PickupHandler(e.target.value)}
                            >
                              <option value=""> Select Stop Name</option>
                              {routeListArr.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.stop_name}
                                </option>
                              ))}
                            </select>
                            {pickupError && (
                              <span className="text-danger">
                                Select the Pickup Stop Name
                              </span>
                            )}
                          </div>
                        </Grid>
                        {/* <Grid item md={4}>
                          <div className="text_filed_heading">
                            Drop Stop Name
                          </div>
                          <div className="inputMarginright mt-1">
                            <select
                              class="form-control"
                              onChange={(e) => DropHandler(e.target.value)}
                            >
                              <option value=""> Select Stop Name</option>
                              {routeListArr.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.stop_name}
                                </option>

                              ))}
                            </select>
                            {dropError && (
                              <span className="text-danger">
                                Select the Drop Stop Name
                              </span>
                            )}
                          </div>
                        </Grid> */}

                        <Grid item md={4} className="radioContainer"></Grid>
                      </Grid>
                      <Grid item md={4}>
                        <div className="text_filed_heading"></div>
                        <div className="inputMarginright marginTop">
                          <Button
                            variant="contained"
                            className="button_enquiry_details mr-2 button_login_decoration button_width"
                            onClick={AssignStudentHandler}
                          >
                            Assign
                          </Button>
                        </div>

                        <div style={{ marginTop: 15 }}></div>
                      </Grid>
                    </div>
                  </div>
                  <AssignedStudentList routeInfo={routeInfo} {...props} />
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)((HOC1(AssignStudent)));
