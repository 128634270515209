import React, { useState } from "react";
import "./Exam.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Loder from "../../Loder/Loder.jsx";
import TablePagination from "@material-ui/core/TablePagination";
import Holiday from "../../Common/Holiday/Holiday.jsx";

//redux

import {
  getValue,
  timeStampDateToOnlytimewithoutTimeDiff,
  timeStampToDate,
} from "../../../utils/Validation";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ParentExamResult(props) {
  //section name
  let section = props.location.state.section;

  //exam name
  let examname = props.location.state.item.name;

  //class name
  let grade = props.location.state.grade;

  //student Name
  let name = props.location.state.name;

  //Institute name
  let institution_Name = props.location.state.institution_Name;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [isloading, setisloading] = useState(false);

  let exam_result = props.location.state.item.section_data.exam_timetables;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={institution_Name} />
              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading ">
                  <div className="mt-3 ml-4 Heading_bold">
                    {name && name}
                    <br />
                    {grade + "-" + section}
                  </div>
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin mb-2">
                        <div className="Heading_bold mt-2">{examname}</div>
                        <div className="table_foramtitng mt-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className="table_header"
                                    align="left"
                                  >
                                    Subject
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Exam Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="table_header"
                                  >
                                    Start Time
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    End Time
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Max. Marks
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Result
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Exam Mode
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header"
                                  >
                                    Comments
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? exam_result.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : exam_result
                                ).map((row) => (
                                  <StyledTableRow>
                                    <StyledTableCell align="left">
                                      {getValue(row.subject.name)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {timeStampToDate(
                                        row.start_calendar?.datetime
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      {timeStampDateToOnlytimewithoutTimeDiff(
                                        row.start_calendar?.datetime
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {timeStampDateToOnlytimewithoutTimeDiff(
                                        row.end_calendar?.datetime
                                      )}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {getValue(row?.marks)}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {getValue(row?.exam_result?.marks)}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      {getValue(row?.location?.mode)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {getValue(row?.exam_result?.feedback)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={exam_result.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />
    </div>
  );
}
export default (HOC1(ParentExamResult));
