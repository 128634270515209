import React, { useState, useEffect } from "react";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import "./Onboarding.css";
import Expand from "react-expand-animated";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import Loder from "../../Loder/Loder.jsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import axios from "axios";
import { store } from "react-notifications-component";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

//redux
import * as Service from "../../../services/index";

import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import {
  blankValidator,
  emailValidator,
  serverDateFormat,
  showNotificationMsz,
  currentDayForMinMAx,
} from "../../../utils/Validation.js";

const EmployeeDetailForm = (props) => {
  const classes = useStyles();
  const [employeedeail, setemployeedeail] = useState(false);
  const [qualification, setqualification] = useState("");
  const [course, setcourse] = useState("");
  const [college, setcollege] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [isloading, setisloading] = useState(false);

  const [EducationDataWithAttainedName, setEducationDataWithAttainedName] =
    useState([]);

  // institution id for all api globally declared
  let institution_id = parseInt(props.data.institution_id);
  //input date min date
  //input date min date

  //name instituition
  let Organization_Name = props.data.institution_name;

  //add student
  const [Add_Employee_Data, {}] = useMutation(Service.Add_Employee_Data);

  const client = useApolloClient();
  const [photo, setphoto] = useState([]);
  const [birth_cert, setbirth_cert] = useState([]);
  const [certificateUpload, setcertificateUpload] = useState([]);
  const [addharUpload, setaddharUpload] = useState([]);
  const [passportUpload, setpassportUpload] = useState([]);
  const [tax, settax] = useState([]);
  const [otherUpload, setotherUpload] = useState([]);
  const [managedesignation, setmanagedesignation] = useState([]);
  const [managedepartment, setmanagedepartment] = useState([]);

  const [checkbox, setCheckbox] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [AttainedArr, setAttainedArr] = useState([
    { label: "-Select-", value: 1 },
    { label: "MCA", value: 2 },
    { label: "M.Tech", value: 3 },
    { label: "MBA", value: 4 },
    { label: "Post Graduation", value: 5 },
    { label: "Ph.D", value: 6 },
    { label: "Diploma", value: 7 },
    { label: "Bachelor's Degree", value: 8 },
    { label: "B.Tech/BE", value: 9 },
  ]);

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangerowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [Employeeformdata, setEmployeeformdata] = useState({
    designation_id: -1,
    department_id: -1,
    email: "",
    Teacher_first_name: "",
    Teacher_middle_name: "",
    Teacher_last_name: "",
    phone: "",
    institution_id: "",
    joining_date: "",
    gender: "",
    birth_date: "",
    Current_line1: "",
    Current_line2: "",
    Current_city: "",
    Current_state: "",
    Current_zip_code: "",
    Current_country: "",
    Permanent_line1: "",
    Permanent_line2: "",
    Permanent_city: "",
    Permanent_state: "",
    Permanent_zip_code: "",
    Permanent_country: "",
    blood_group: "",
    nationality: "",
    tax_id: "",
    marital_status: "",
    passport_id: "",
    national_id: "",
    religion: "",
    Father_first_name: "",
    Father_middle_name: "",
    Father_last_name: "",
    Mother_first_name: "",
    Mother_middle_name: "",
    Mother_last_name: "",
    Spouse_first_name: "",
    Spouse_middle_name: "",
    Spouse_last_name: "",
    userType_id: "",
    education_qualifications: [],
    passport_expiry_date: "",
    declarationCheck: false,
    photo: {},
    birth_cert: {},
    tax: {},
    passport: {},
    certificate: {},
    other: [],
  });

  //error
  const [pandCardError, setPandCardError] = useState(false);
  const [passport_expiry_dateError, setpassport_expiry_dateError] =
    useState(false);
  const [departmentIdError, setdepartmentIdError] = useState(false);
  const [designationIdError, setdesignationIdError] = useState(false);
  const [userTypeIdError, setUserTypeIdError] = useState(false);

  const [firstNameError, setfirstNameError] = useState(false);
  const [lastnameError, setlastnameError] = useState(false);
  const [birthdateError, setbirthdateError] = useState(false);
  const [genderError, setgenderError] = useState(false);
  const [contactnoError, setcontactnoError] = useState(false);

  const [emailCorrectError, setemailCorrectError] = useState(false);
  const [passportError, setpassportError] = useState(false);
  const [nationalidError, setnationalidError] = useState(false);
  const [taxIdError, settaxIdError] = useState(false);
  const [religionError, setreligionError] = useState(false);
  const [maritalStatusError, setmaritalStatusError] = useState(false);
  const [nationalityError, setnationalityError] = useState(false);
  const [motherFirstNameError, setmotherFirstNameError] = useState(false);
  const [motherlastnameError, setmotherlastnameError] = useState(false);
  const [fatherfirstnameError, setfatherfirstnameError] = useState(false);
  const [fatherlastnameError, setfatherlastnameError] = useState(false);
  const [addressline1Error, setaddressline1Error] = useState(false);
  const [addressline2Error, setaddressline2Error] = useState(false);
  const [countryError, setcountryError] = useState(false);
  const [stateError, setstateError] = useState(false);
  const [zipcodeError, setzipcodeError] = useState(false);

  const [qualificationIdError, setqualificationIdError] = useState(false);
  const [courseNameError, setcourseNameError] = useState(false);
  const [collegeNameError, setcollegeNameError] = useState(false);
  const [starttimeError, setstarttimeError] = useState(false);
  const [endtimeError, setendtimeError] = useState(false);

  const [declarationcheckedError, setdeclarationcheckedError] = useState(false);

  const fillAddress = (e) => {
    setCheckbox(e.target.checked);
    if (e.target.checked === true) {
      setEmployeeformdata({
        ...Employeeformdata,
        Permanent_line1: Employeeformdata.Current_line1,
        Permanent_line2: Employeeformdata.Current_line2,
        Permanent_city: Employeeformdata.Current_city,
        Permanent_state: Employeeformdata.Current_state,
        Permanent_zip_code: Employeeformdata.Current_zip_code,
        Permanent_country: Employeeformdata.Current_country,
      });
    } else
      setEmployeeformdata({
        ...Employeeformdata,
        Permanent_line1: "",
        Permanent_line2: "",
        Permanent_city: "",
        Permanent_state: "",
        Permanent_zip_code: "",
        Permanent_country: "",
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const getDesignationanddepartment = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Designation_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);

            setmanagedesignation(response.data.getDesignation);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }

      try {
        setisloading(true);
        client
          .query({
            query: Service.Get_Department_Data,
            variables: {
              institution_id,
            },
          })
          .then((response) => {
            setisloading(false);
            setmanagedepartment(response.data.getDepartment);
          })
          .catch((err) => {
            setisloading(false);
            console.log("catch err", err);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };

    getDesignationanddepartment();
  }, [institution_id, client]);

  // upload documents
  const UploadDocumentHandler = (e, type) => {
    const file = e.target.files[0];
    const url = `${Service.baseUrl}/uploadFile/image`;
    let fileData = new FormData();
    fileData.append("file", file);

    try {
      setisloading(true);
      axios
        .post(url, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setisloading(false);
          const obj = {
            path: response.data.path,
            filename: response.data.filename,
            type: response.data.type,
            name: response.data.name,
          };
          const temp = [];
          temp.push(obj);
          if (type === "PHOTO") {
            setphoto(temp);
          }
          if (type === "BIRTH_CERTIFICATE") {
            setbirth_cert(temp);
          }

          if (type === "PASSPORT") {
            setpassportUpload(temp);
          }
          if (type === "CERTIFICATE") {
            setcertificateUpload(temp);
          }
          if (type === "TAX_CARD") {
            settax(temp);
          }
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz(error.message, "danger");
        });
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }
  };

  // upload documents
  const UploadMultipleDocumentHandler = async (e) => {
    if (e.target.files.length > 4) {
      showNotificationMsz(`Cannot Upload More than 4 files`, "info");
      return;
    }
    if (otherUpload.length >= 4) {
      showNotificationMsz(`Already Have 4 documents`, "info");
      return;
    }
    const files = e.target.files;

    try {
      setisloading(true);
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        const url = `${Service.baseUrl}/uploadFile/image`;
        let fileData = new FormData();
        fileData.append("file", element);

        await axios
          .post(url, fileData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setisloading(false);
            const obj = {
              path: response.data.path,
              filename: response.data.filename,
              type: response.data.type,
              name: response.data.name,
            };
            otherUpload.push(obj);

            setotherUpload([...otherUpload]);

            //temp.push(obj);
          })
          .catch((error) => {
            showNotificationMsz(error, "danger");
          });
      }
    } catch (error) {
      setisloading(false);
      console.log("catch and try error", error);
    }

    setisloading(false);
  };

  const [qualificationAttainedName, setqualificationAttainedName] =
    useState("");

  const qualificationAttainedDetail = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      setqualification("");
      return;
    }

    setqualification(id);
    const newArr = AttainedArr.filter((item) => {
      if (item.value === id) return item;
    });
    setqualificationAttainedName(newArr[0].label);
  };

  const [EditDailogEducationOpen, setEditDailogEducationOpen] = useState(false);
  const [EditQualificationId, setEditQualificationId] = useState("");
  const [EditQualificationName, setEditQualificationName] = useState("");
  const [EditCourse, setEditCourse] = useState("");
  const [EditCollege, setEditCollege] = useState("");
  const [EditStartDate, setEditStartDate] = useState("");
  const [EditEndDate, setEditEndDate] = useState("");
  const [EditID, setEditID] = useState("");
  const [EditqualificationAttainedName, setEditqualificationAttainedName] =
    useState("");

  const handleEducationUpdate = (data, index) => {
    // setEditqualificationAttainedName(data.qualificationAttainedName);
    setEditQualificationName(data.qualificationAttainedName);
    setEditID(parseInt(index));
    setEditQualificationId(parseInt(data.qualification_id));
    setEditCourse(data.course_name);
    setEditCollege(data.university_name);
    setEditStartDate(data.start_date);
    setEditEndDate(data.end_date);
    setEditDailogEducationOpen(!EditDailogEducationOpen);
  };

  const EditqualificationAttainedDetail = (e) => {
    let id = parseInt(e.target.value);
    if (id === -1) {
      //   setEditQualificationName(EditqualificationAttainedName);
      setEditQualificationId("");
      return;
    }
    setEditQualificationId(id);
    const newArr = AttainedArr.filter((item) => {
      if (item.value === id) return item;
    });
    setEditQualificationName(newArr[0].label);
  };

  //Errors
  const [EditqualificationIdError, setEditqualificationIdError] =
    useState(false);
  const [EditcourseNameError, setEditcourseNameError] = useState(false);
  const [EditcollegeNameError, setEditcollegeNameError] = useState(false);
  const [EditstarttimeError, setEditstarttimeError] = useState(false);
  const [EditendtimeError, setEditendtimeError] = useState(false);

  const HandleEditEducationData = (val) => {
    let index = parseInt(val);
    EducationDataWithAttainedName[index].qualification = EditQualificationName;
    EducationDataWithAttainedName[index].course_name = EditCourse;
    EducationDataWithAttainedName[index].university_name = EditCollege;
    EducationDataWithAttainedName[index].start_date = EditStartDate;
    EducationDataWithAttainedName[index].end_date = EditEndDate;
    setEducationDataWithAttainedName([...EducationDataWithAttainedName]);

    setEditDailogEducationOpen(!EditDailogEducationOpen);
  };

  const HandleEducationData = () => {
    //to Show the Data
    EducationDataWithAttainedName.push({
      qualification: qualificationAttainedName,
      course_name: course,
      university_name: college,
      start_date: serverDateFormat(startdate),
      end_date: serverDateFormat(enddate),
    });
    setEducationDataWithAttainedName([...EducationDataWithAttainedName]);
    setqualification("");
    setcourse("");
    setcollege("");
    setstartdate("");
    setenddate("");
    setemployeedeail(false);
  };

  const employeeDetaiform = async () => {
    let flag = false;
    let scroll = false;
    if (
      !blankValidator(Employeeformdata.department_id) ||
      Employeeformdata.department_id === -1
    ) {
      setdepartmentIdError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("depart_id").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(Employeeformdata.designation_id) ||
      Employeeformdata.designation_id === -1
    ) {
      setdesignationIdError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("design_id").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(Employeeformdata.userType_id) ||
      Employeeformdata.userType_id === ""
    ) {
      setUserTypeIdError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("usertype_id").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.Teacher_first_name)) {
      setfirstNameError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("T_firstname").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.birth_date)) {
      setbirthdateError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("T_birth").focus();
      }
      scroll = true;
    }
    if (!blankValidator(Employeeformdata.gender)) {
      setgenderError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("gender_id").focus();
      }
      scroll = true;
    }
    if (!blankValidator(Employeeformdata.phone)) {
      setcontactnoError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("E_mobile").focus();
      }
      scroll = true;
    }
    if (Employeeformdata.email) {
      if (!emailValidator(Employeeformdata.email)) {
        setemailCorrectError(true);
        flag = true;
        if (scroll === false) {
          document.getElementById("email_id").focus();
        }
        scroll = true;
      }
    }

    if (!blankValidator(Employeeformdata.nationality)) {
      setnationalityError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("nationality").focus();
      }
      scroll = true;
    }

    // if (!blankValidator(Employeeformdata.Mother_first_name)) {
    //   setmotherFirstNameError(true);
    //   flag = true;
    //   if (scroll === false) {
    //     document.getElementById("M_firstname").focus();
    //   }
    //   scroll = true;
    // }

    // if (!blankValidator(Employeeformdata.Father_first_name)) {
    //   setfatherfirstnameError(true);
    //   flag = true;
    //   if (scroll === false) {
    //     document.getElementById("F_firstname").focus();
    //   }
    //   scroll = true;
    // }

    if (!blankValidator(Employeeformdata.Current_line1)) {
      setaddressline1Error(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_line1").focus();
      }
      scroll = true;
    }

    if (!blankValidator(Employeeformdata.Current_country)) {
      setcountryError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_country").focus();
      }
      scroll = true;
    }
    if (!blankValidator(Employeeformdata.Current_state)) {
      setstateError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_state").focus();
      }
      scroll = true;
    }
    if (
      !blankValidator(Employeeformdata.Current_zip_code) ||
      Employeeformdata.Current_zip_code === ""
    ) {
      setzipcodeError(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("C_pincode").focus();
      }
      scroll = true;
    }

    if (flag) {
      return;
    }

    try {
      setisloading(true);
      await Add_Employee_Data({
        variables: {
          designation_id: parseInt(Employeeformdata.designation_id),
          department_id: parseInt(Employeeformdata.department_id),
          email: Employeeformdata.email,
          Teacher_first_name: Employeeformdata.Teacher_first_name,
          Teacher_middle_name: Employeeformdata.Teacher_middle_name,
          Teacher_last_name: Employeeformdata.Teacher_last_name,
          phone: Employeeformdata.phone,
          institution_id,
          joining_date: serverDateFormat(Employeeformdata.joining_date),
          gender: Employeeformdata.gender,
          birth_date: serverDateFormat(Employeeformdata.birth_date),
          Current_line1: Employeeformdata.Current_line1,
          Current_line2: Employeeformdata.Current_line2,
          Current_city: Employeeformdata.Current_city,
          Current_state: Employeeformdata.Current_state,
          Current_zip_code: Employeeformdata.Current_zip_code,
          Current_country: Employeeformdata.Current_country,
          Permanent_line1: Employeeformdata.Permanent_line1,
          Permanent_line2: Employeeformdata.Permanent_line2,
          Permanent_city: Employeeformdata.Permanent_city,
          Permanent_state: Employeeformdata.Permanent_state,
          Permanent_zip_code: Employeeformdata.Permanent_zip_code,
          Permanent_country: Employeeformdata.Permanent_country,
          blood_group: Employeeformdata.blood_group,
          nationality: Employeeformdata.nationality,
          tax_id_number: Employeeformdata.tax_id,
          marital_status: Employeeformdata.marital_status,
          passport_number: Employeeformdata.passport_id,
          passport_expiry_date: serverDateFormat(
            Employeeformdata.passport_expiry_date
          ),
          national_id_number: Employeeformdata.national_id,
          religion: Employeeformdata.religion,
          Father_first_name: Employeeformdata.Father_first_name,
          Father_middle_name: Employeeformdata.Father_middle_name,
          Father_last_name: Employeeformdata.Father_last_name,
          Mother_first_name: Employeeformdata.Mother_first_name,
          Mother_middle_name: Employeeformdata.Mother_middle_name,
          Mother_last_name: Employeeformdata.Mother_last_name,
          Spouse_first_name: Employeeformdata.Spouse_first_name,
          Spouse_middle_name: Employeeformdata.Spouse_middle_name,
          Spouse_last_name: Employeeformdata.Spouse_last_name,
          education_qualifications: EducationDataWithAttainedName,
          user_type: Employeeformdata.userType_id,
          //  education_qualification_doc: EducationUploadFileArr,

          passport: passportUpload[0] === undefined ? {} : passportUpload[0],
          certificate:
            certificateUpload[0] === undefined ? {} : certificateUpload[0],
          photo: photo[0] === undefined ? {} : photo[0],
          birth_cert: birth_cert[0] === undefined ? {} : birth_cert[0],
          tax: tax[0] === undefined ? {} : tax[0],
          other: otherUpload,
        },
      }).then(
        (response) => {
          setisloading(false);
          showNotificationMsz(response.data.addEmployee.message, "success");
         props.navigate("/adminemployeelistview");
        },
        (error) => {
          setisloading(false);
          console.log("then error", error);
        }
      );
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const handleEducationData = () => {
    setemployeedeail(true);
  };

  const closeEducationData = () => {
    setemployeedeail(false);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={Organization_Name} />

              <div className="enquirydetails_background mb-2">
                <div className="enquiry_heading">
                  {/* <div className="ml-3 mt-3 Heading_bold">
                    Employee Onboarding Form
                  </div> */}
                  <div className="ml-3 mt-3 Heading_bold">Employee Details</div>
                </div>
                <div className="card_margin_both_side">
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {/* <Grid className="grid_main_div gapfromanotherinput ">
                          <Grid item md={4}>
                            <div className="text_filed_heading">Role</div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Role</option>
                                {managedesignation.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                          <Grid item md={4}></Grid>
                        </Grid> */}

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Department <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="depart_id"
                                value={Employeeformdata.department_id}
                                onChange={(e) => {
                                  setdepartmentIdError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    department_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Department</option>
                                {managedepartment.length > 0 ? (
                                  managedepartment.map((item, index) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))
                                ) : (
                                  <option value={-1}>No data</option>
                                )}
                              </select>
                              {departmentIdError && (
                                <span className="text-danger">
                                  Select the Department
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Designation<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="design_id"
                                value={Employeeformdata.designation_id}
                                onChange={(e) => {
                                  setdesignationIdError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    designation_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={-1}>Designation</option>
                                {managedesignation.length > 0 ? (
                                  managedesignation.map((item, index) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )}
                              </select>
                              {designationIdError && (
                                <span className="text-danger">
                                  Select the Designation
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              User Type<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="usertype_id"
                                value={Employeeformdata.userType_id}
                                onChange={(e) => {
                                  setUserTypeIdError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    userType_id: e.target.value,
                                  });
                                }}
                              >
                                <option value={""}>User Type</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Non_Teacher">Non Teacher</option>
                                <option value="Transport">Transport</option>
                                {/* {managedesignation.length > 0 ? (
                                  managedesignation.map((item, index) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))
                                ) : (
                                  <option value={-1}>No Data</option>
                                )} */}
                              </select>
                              {userTypeIdError && (
                                <span className="text-danger">
                                  Select the user Type
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Date Of Joining
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="joining_id"
                                autoComplete="off"
                                value={Employeeformdata.joining_date}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    joining_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="text_filed_heading">Employee Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name<span className="text-danger">*</span>
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="T_firstname"
                                  autoComplete="off"
                                  value={Employeeformdata.Teacher_first_name}
                                  onChange={(e) => {
                                    setfirstNameError(false);
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Teacher_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {firstNameError && (
                                  <span className="text-danger">
                                    Enter the First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Teacher_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Teacher_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Teacher_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Teacher_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Date of Birth
                              <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="T_birth"
                                autoComplete="off"
                                value={Employeeformdata.birth_date}
                                onChange={(e) => {
                                  setbirthdateError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    birth_date: e.target.value,
                                  });
                                }}
                              />
                              {birthdateError && (
                                <span className="text-danger">
                                  Enter the Date of Birth
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Gender<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="gender_id"
                                value={Employeeformdata.gender}
                                onChange={(e) => {
                                  setgenderError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    gender: e.target.value,
                                  });
                                }}
                              >
                                <option>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                              {genderError && (
                                <span className="text-danger">
                                  Select the Gender
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Contact Number
                              <span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <PhoneInput
                                className="phone_input_width_create"
                                id="E_mobile"
                                country={"in"}
                                value={Employeeformdata.phone}
                                onChange={(e) => {
                                  setcontactnoError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    phone: e,
                                  });
                                }}
                              />
                              {contactnoError && (
                                <span className="text-danger">
                                  Enter the Contact Number
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Blood Group
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                value={Employeeformdata.blood_group}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    blood_group: e.target.value,
                                  });
                                }}
                              >
                                <option>Blood Group</option>
                                <option>A+</option>
                                <option>B+</option>
                                <option>AB+</option>
                                <option>O+</option>
                                <option>A-</option>
                                <option>B-</option>
                                <option>AB-</option>
                                <option>O-</option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">Email</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="email_id"
                                autoComplete="off"
                                value={Employeeformdata.email}
                                onChange={(e) => {
                                  setemailCorrectError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    email: e.target.value,
                                  });
                                }}
                              />

                              {emailCorrectError && (
                                <span className="text-danger">
                                  Enter the Correct Email Address
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Passport Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="passportnumber"
                                autoComplete="off"
                                value={
                                  Employeeformdata.passport_id === ""
                                    ? ""
                                    : Employeeformdata.passport_id
                                }
                                onChange={(e) => {
                                  // setpassportError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    passport_id: e.target.value,
                                  });
                                }}
                              />
                              {/* {passportError && (
                                <span className="text-danger">
                                  Enter the Passport ID
                                </span>
                              )} */}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Passport Expiry Date
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="date"
                                class="form-control"
                                id="passportexpirydate"
                                value={Employeeformdata.passport_expiry_date}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    passport_expiry_date: e.target.value,
                                  });
                                  // setpassport_expiry_dateError(false);
                                }}
                              />
                              {/* {passport_expiry_dateError && (
                                <span className="text-danger">
                                  Select passport expiry date.
                                </span>
                              )} */}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              National ID
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="nationality"
                                autoComplete="off"
                                value={
                                  Employeeformdata.national_id === ""
                                    ? ""
                                    : Employeeformdata.national_id
                                }
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    national_id: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Tax Id/Pan Card Number
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="tax"
                                autoComplete="off"
                                value={Employeeformdata.tax_id}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    tax_id: e.target.value,
                                  });
                                  // setPandCardError(false);
                                }}
                              />
                              {/* {pandCardError && (
                                <span className="text-danger">
                                  Enter pan card no.
                                </span>
                              )} */}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div gapfromanotherinput mb-2">
                          <Grid item md={4}>
                            <div className="text_filed_heading ">Religion</div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                value={Employeeformdata.religion}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    religion: e.target.value,
                                  });
                                }}
                              >
                                <option>Religion</option>
                                <option>Hindu</option>
                                <option>Islam</option>
                                <option>Christian</option>
                                <option>Sikh</option>
                                <option>Others</option>
                              </select>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            <div className="text_filed_heading ">
                              Marital Status
                            </div>
                            <div className="inputMarginright mt-1">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                value={Employeeformdata.marital_status}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    marital_status: e.target.value,
                                  });
                                }}
                              >
                                <option>Marital Status</option>
                                <option>Single</option>
                                <option>Married</option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="text_filed_heading">
                              Nationality<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="nationality"
                                autoComplete="off"
                                value={Employeeformdata.nationality}
                                onChange={(e) => {
                                  setnationalityError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    nationality: e.target.value,
                                  });
                                }}
                              />
                              {nationalityError && (
                                <span className="text-danger">
                                  Enter the Nationality
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="text_filed_heading">Spouse's Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Spouse_first_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Spouse_first_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Spouse_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Spouse_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Spouse_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Spouse_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Mother's Name</div>
                        <div className="name_outside_border p-2">
                          <Grid className="grid_main_div">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="M_firstname"
                                  autoComplete="off"
                                  value={Employeeformdata.Mother_first_name}
                                  onChange={(e) => {
                                    setmotherFirstNameError(false);
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Mother_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {motherFirstNameError && (
                                  <span className="text-danger">
                                    Enter the First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Mother_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Mother_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Mother_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Mother_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="text_filed_heading">Father's Name</div>
                        <div className="name_outside_border p-2 mb-2">
                          <Grid className="grid_main_div mb-2">
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                First Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="F_firstname"
                                  autoComplete="off"
                                  value={Employeeformdata.Father_first_name}
                                  onChange={(e) => {
                                    setfatherfirstnameError(false);
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Father_first_name: e.target.value,
                                    });
                                  }}
                                />
                                {fatherfirstnameError && (
                                  <span className="text-danger">
                                    Enter the First Name
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Middle Name
                              </div>
                              <div className="inputMarginright mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Father_middle_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Father_middle_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={4}>
                              <div className="text_filed_heading">
                                Last Name
                              </div>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  autoComplete="off"
                                  value={Employeeformdata.Father_last_name}
                                  onChange={(e) => {
                                    setEmployeeformdata({
                                      ...Employeeformdata,
                                      Father_last_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Address</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <div className="text_filed_heading">
                          Current Address
                        </div>
                        <Grid className="grid_main_div">
                          <Grid item md={12}>
                            <div className="text_filed_heading mt-1">
                              Address Line 1
                              <span className="text-danger">*</span>
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="C_line1"
                                autoComplete="off"
                                value={Employeeformdata.Current_line1}
                                onChange={(e) => {
                                  setaddressline1Error(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_line1: e.target.value,
                                  });
                                }}
                              />
                              {addressline1Error && (
                                <span className="text-danger">
                                  Enter the Address
                                </span>
                              )}
                            </div>

                            <div className="text_filed_heading mt-1">
                              Address Line 2
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={Employeeformdata.Current_line2}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Country</div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                value={Employeeformdata.Current_country}
                                id="C_country"
                                onChange={(e) => {
                                  setcountryError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {countryError && (
                                <span className="text-danger">
                                  Select the Country
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              State<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                country={Employeeformdata.Current_country}
                                value={Employeeformdata.Current_state}
                                id="C_state"
                                onChange={(e) => {
                                  setstateError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                              {stateError && (
                                <span className="text-danger">
                                  Select the State
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">
                              Pin Code<span className="text-danger">*</span>
                            </div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="C_pincode"
                                autoComplete="off"
                                value={
                                  Employeeformdata.Current_zip_code === ""
                                    ? ""
                                    : Employeeformdata.Current_zip_code
                                }
                                onChange={(e) => {
                                  if (isNaN(e.target.value)) {
                                    return;
                                  }
                                  setzipcodeError(false);
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Current_zip_code: e.target.value,
                                  });
                                }}
                              />
                              {zipcodeError && (
                                <span className="text-danger">
                                  Enter the Pin Code
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <div className="d-flex mt-3">
                          <span className="text_filed_heading">
                            Permanent Address
                          </span>
                          <span className="ml-5">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="filladdress"
                                onClick={(e) => fillAddress(e)}
                                checked={checkbox}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Same As Current Address
                              </label>
                            </div>
                          </span>
                        </div>
                        <Grid className="grid_main_div mt-2">
                          <Grid item md={12}>
                            <div className="text_filed_heading mt-1">
                              Address Line 1
                            </div>
                            <div className=" mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_line1"
                                autoComplete="off"
                                value={Employeeformdata.Permanent_line1}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_line1: e.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="text_filed_heading mt-1">
                              Address Line 2
                            </div>
                            <div className=" mt-2">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                value={Employeeformdata.Permanent_line2}
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_line2: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-2 mb-2">
                          <Grid item md={3}>
                            <div className="text_filed_heading">Country</div>
                            <div className="inputMarginright mt-1">
                              <CountryDropdown
                                value={Employeeformdata.Permanent_country}
                                id="P_Country"
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_country: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">State</div>
                            <div className="inputMarginright mt-1">
                              <RegionDropdown
                                country={Employeeformdata.Permanent_country}
                                value={Employeeformdata.Permanent_state}
                                id="P_state"
                                onChange={(e) => {
                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_state: e,
                                  });
                                }}
                                className="country_drop_height_width"
                              />
                            </div>
                          </Grid>

                          <Grid item md={3}>
                            <div className="text_filed_heading">Pin Code</div>
                            <div className="inputMarginright mt-1">
                              <input
                                type="text"
                                class="form-control"
                                id="P_pincode"
                                autoComplete="off"
                                value={
                                  Employeeformdata.Permanent_zip_code === ""
                                    ? ""
                                    : Employeeformdata.Permanent_zip_code
                                }
                                onChange={(e) => {
                                  if (isNaN(e.target.value)) {
                                    return;
                                  }

                                  setEmployeeformdata({
                                    ...Employeeformdata,
                                    Permanent_zip_code: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">
                    Educational Qualification
                  </div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        {employeedeail === false ? (
                          <div
                            className="d-flex float-right"
                            onClick={handleEducationData}
                          >
                            <span className="iconstoaddfee">
                              <i className="fa fa-plus-circle"></i>
                            </span>
                            <span className=" ml-2 mt-1 addmanageuserfont">
                              Add Additional Qualification
                            </span>
                          </div>
                        ) : (
                          <Expand open={employeedeail}>
                            <Card className="cardvisible_border mt-2 ">
                              <div
                                className="float-right"
                                onClick={closeEducationData}
                              >
                                <span className="iconstoaddfee mr-2">
                                  <i class="fa fa-times cursor"></i>
                                </span>
                              </div>
                              <div className="textfiled_margin">
                                <Grid className="grid_main_div">
                                  <Grid item md={6}>
                                    <div className="text_filed_heading">
                                      Qualification Attained
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <select
                                        class="form-control"
                                        id="exampleFormControlSelect1"
                                        value={qualification}
                                        onChange={(e) => {
                                          setqualificationIdError(false);

                                          qualificationAttainedDetail(e);
                                        }}
                                      >
                                        <option value={-1}>Select</option>
                                        {AttainedArr.map((item) => (
                                          <option value={item.value}>
                                            {item.label}
                                          </option>
                                        ))}
                                      </select>
                                      {qualificationIdError && (
                                        <span className="text-danger">
                                          Select Qualification Attained
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      Course Name
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="exampleFormControlInput1"
                                        autoComplete="off"
                                        value={course}
                                        onChange={(e) => {
                                          setcourseNameError(false);
                                          setcourse(e.target.value);
                                        }}
                                      />
                                      {courseNameError && (
                                        <span className="text-danger">
                                          Enter the Course/Class
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      College/University Name
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="exampleFormControlInput1"
                                        autoComplete="off"
                                        value={college}
                                        onChange={(e) => {
                                          setcollegeNameError(false);
                                          setcollege(e.target.value);
                                        }}
                                      />
                                      {collegeNameError && (
                                        <span className="text-danger">
                                          Enter the College/University
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="text_filed_heading gapfromanotherinput">
                                  Period of education
                                </div>
                                <Grid className="grid_main_div mb-1">
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      Start Date
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="date"
                                        class="form-control"
                                        id="date_birth"
                                        autoComplete="off"
                                        min={"1901-01-01"}
                                        max={currentDayForMinMAx()}
                                        value={startdate}
                                        onChange={(e) => {
                                          setstarttimeError(false);
                                          setstartdate(e.target.value);
                                        }}
                                      />
                                      {starttimeError && (
                                        <span className="text-danger">
                                          Enter the Start Date
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}>
                                    <div className="text_filed_heading">
                                      End Date
                                    </div>
                                    <div className="inputMarginright mt-1">
                                      <input
                                        type="date"
                                        class="form-control"
                                        id="date_birth"
                                        autoComplete="off"
                                        min={"1901-01-01"}
                                        max={currentDayForMinMAx()}
                                        value={enddate}
                                        onChange={(e) => {
                                          setendtimeError(false);
                                          setenddate(e.target.value);
                                        }}
                                      />
                                      {endtimeError && (
                                        <span className="text-danger">
                                          Enter the End Date
                                        </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item md={3}></Grid>
                                  <Grid item md={3}>
                                    <div className="mt-5">
                                      <Button
                                        className="add_new_query_btn button_login_decoration "
                                        onClick={() => HandleEducationData()}
                                      >
                                        Add Education{" "}
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </Card>
                          </Expand>
                        )}
                      </div>
                    </div>

                    <div className="card_admissiondetails_height mt-5">
                      <div className="textfiled_margin">
                        <div className="table_foramtitng mt-1 mb-2">
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                              id="Table_width_Student"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Qualification
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Course
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    College
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Start Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    End Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="table_header "
                                  >
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(rowsPerPage > 0
                                  ? EducationDataWithAttainedName.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : EducationDataWithAttainedName
                                ).map((row, index) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.qualification}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.course_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.university_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.start_date}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className=" "
                                        align="left"
                                      >
                                        {row.end_date}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        <div className="d-flex">
                                          <span className="iconstoaddfee manage_edit_cursor mr-2 ml-1">
                                            <i
                                              className="fa fa-pencil"
                                              onClick={() =>
                                                handleEducationUpdate(
                                                  row,
                                                  index
                                                )
                                              }
                                            ></i>
                                          </span>
                                          <span className="iconstoaddfee manage_edit_cursor ml-2">
                                            <i
                                              className="fa fa-trash"
                                              onClick={() => {
                                                EducationDataWithAttainedName.splice(
                                                  index,
                                                  1
                                                );
                                                setEducationDataWithAttainedName(
                                                  [
                                                    ...EducationDataWithAttainedName,
                                                  ]
                                                );
                                              }}
                                            ></i>
                                          </span>
                                        </div>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <TablePagination
                              true
                              rowsPerPageOptions={false}
                              component="div"
                              count={EducationDataWithAttainedName.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangerowsPerPage}
                            />
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-1 Heading_bold">Document Upload</div>
                  <Card className="mt-1">
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Photo
                          </Grid>
                          <Grid item md={6}>
                            <input
                              type="file"
                              id="Photo"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "PHOTO")
                              }
                            />
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Birth Certificate
                          </Grid>
                          <Grid item md={6}>
                            <input
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "BIRTH_CERTIFICATE")
                              }
                            />
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        {/* <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            National ID
                          </Grid>
                          <Grid item md={6}>
                            <input
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "NATIONAL")
                              }
                            />
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid> */}

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Passport
                          </Grid>
                          <Grid item md={6}>
                            <input
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "PASSPORT")
                              }
                            />
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Certificate
                          </Grid>
                          <Grid item md={6}>
                            <input
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "CERTIFICATE")
                              }
                            />
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={3}>
                            Tax/Pan Card
                          </Grid>
                          <Grid item md={6}>
                            <input
                              type="file"
                              onChange={(e) =>
                                UploadDocumentHandler(e, "TAX_CARD")
                              }
                            />
                          </Grid>
                          <Grid item md={3}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-2">
                          <Grid item md={3}>
                            Add Any
                          </Grid>
                          <Grid item md={3}>
                            <input
                              className="nofilechoosenhide"
                              type="file"
                              multiple
                              onChange={(e) => UploadMultipleDocumentHandler(e)}
                            />
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3 mb-2">
                          {otherUpload.length !== 0 &&
                            otherUpload.map((item, index) => (
                              <div key={index}>
                                <Chip
                                  className="ml-1"
                                  label={item.name}
                                  // onClick={() => {
                                  //   HandleImageDailogBox(item);
                                  // }}
                                  onDelete={() => {
                                    otherUpload.splice(index, 1);
                                    let temp = [];
                                    otherUpload.map((data) => {
                                      temp.push(data);
                                    });
                                    setotherUpload([...temp]);
                                  }}
                                  color="primary"
                                  variant="outlined"
                                />
                              </div>
                            ))}
                        </Grid>
                      </div>
                    </div>
                  </Card>

                  <div className=" mt-5 Heading_bold">
                    Declaration<span className="text-danger">*</span>
                  </div>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3">
                        <Grid item md={12}>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={Employeeformdata.declarationCheck}
                              onChange={(e) => {
                                setdeclarationcheckedError(false);
                                setEmployeeformdata({
                                  ...Employeeformdata,
                                  declarationCheck: e.target.checked,
                                });
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              I hereby declare that the information given in
                              this application is true and correct to the best
                              of my knowledge and belief.
                            </label>
                          </div>
                          {declarationcheckedError && (
                            <span className="text-danger">
                              Select the declaration
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div className="card_admissiondetails_height">
                    <div className="textfiled_margin">
                      <Grid className="grid_main_div mt-3 mb-2">
                        <Grid item md={10}></Grid>
                        <Grid item md={2}>
                          {Employeeformdata.declarationCheck ? (
                            <Button
                              variant="contained"
                              className="add_new_query_btn button_login_decoration button_width"
                              onClick={employeeDetaiform}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              className="student_Form_taransport_able_button button_login_decoration button_width"
                              disabled={true}
                            >
                              Submit
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Loder loading={isloading} />

      <Dialog
        open={EditDailogEducationOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
      >
        <DialogTitle>
          Edit Education Details
          <span
            className="float-right"
            onClick={() => {
              setEditDailogEducationOpen(!EditDailogEducationOpen);
            }}
          >
            <i class="fa fa-times cursor" aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="textfiled_margin">
            <Grid className="grid_main_div">
              <Grid item md={6}>
                <div className="text_filed_heading">Qualification Attained</div>
                <div className="inputMarginright mt-1">
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    value={EditQualificationId}
                    onChange={(e) => {
                      setEditqualificationIdError(false);
                      EditqualificationAttainedDetail(e);
                    }}
                  >
                    <option value={-1}>Select</option>
                    {AttainedArr.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {EditqualificationIdError && (
                    <span className="text-danger">
                      Select Qualification Attained
                    </span>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="text_filed_heading">Course Name</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    autoComplete="off"
                    value={EditCourse}
                    onChange={(e) => {
                      setEditcourseNameError(false);
                      setEditCourse(e.target.value);
                    }}
                  />
                  {EditcourseNameError && (
                    <span className="text-danger">Enter the Course/Class</span>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="text_filed_heading">
                  College/University Name
                </div>
                <div className="inputMarginright mt-1">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    autoComplete="off"
                    value={EditCollege}
                    onChange={(e) => {
                      setEditcollegeNameError(false);
                      setEditCollege(e.target.value);
                    }}
                  />
                  {EditcollegeNameError && (
                    <span className="text-danger">
                      Enter the College/University
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className="text_filed_heading gapfromanotherinput">
              Period of education
            </div>
            <Grid className="grid_main_div">
              <Grid item md={3}>
                <div className="text_filed_heading">Start Date</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="date"
                    class="form-control"
                    id="date_birth"
                    autoComplete="off"
                    min={"1901-01-01"}
                    max={currentDayForMinMAx()}
                    value={EditStartDate}
                    onChange={(e) => {
                      setEditstarttimeError(false);
                      setEditStartDate(e.target.value);
                    }}
                  />
                  {EditstarttimeError && (
                    <span className="text-danger">Enter the Start Date</span>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <div className="text_filed_heading">End Date</div>
                <div className="inputMarginright mt-1">
                  <input
                    type="date"
                    class="form-control"
                    id="date_birth"
                    autoComplete="off"
                    min={"1901-01-01"}
                    max={currentDayForMinMAx()}
                    value={EditEndDate}
                    onChange={(e) => {
                      setEditendtimeError(false);
                      setEditEndDate(e.target.value);
                    }}
                  />
                  {EditendtimeError && (
                    <span className="text-danger">Enter the End Date</span>
                  )}
                </div>
              </Grid>
              <Grid item md={6}></Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="add_new_query_btn button_login_decoration mr-1"
            onClick={() => {
              setEditDailogEducationOpen(!EditDailogEducationOpen);
            }}
          >
            Cancel
          </Button>
          <Button
            className="add_new_query_btn button_login_decoration"
            onClick={() => HandleEditEducationData(EditID)}
          >
            Update{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(
  mapStateToProps,
  null
)((HOC1(EmployeeDetailForm)));
