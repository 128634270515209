import React, { Fragment, Component } from "react";
import SignupHeader from "./Header/SignupHeader.jsx";
import Footer from "./Footer/Footer.jsx";
const UpdateComponent = (Wcomponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Fragment>
          <div className="app-container-hoc main-margin">
            <SignupHeader {...this.props} />
            <Wcomponent {...this.props} />
            <Footer {...this.props} />
          </div>
        </Fragment>
      );
    }
  };
};

export default UpdateComponent;
