import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//redux
import * as Service from "../../../services/index";
import {
  blankValidator,
  currentDayForMinMAx,
  showNotificationMsz,
  timeStampToDatedash,
  dateToDatedashtoDDMMYY,
  timeStampToDatedashDayStart,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import HOC1 from "../../Hoc1.jsx";
import Loder from "../../Loder/Loder";
import "./HolidayCalender.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
const AddHolidayCalender = (props) => {
  console.log("props----", props);
  let { data, academic_session_id } = props?.location?.state;
  let institution_id = parseInt(props.data.institution_id);
  const [add_holiday_data, {}] = useMutation(Service.add_holiday_data);
  const [update_holiday_data, {}] = useMutation(Service.update_holiday_data);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [isloading, setisloading] = useState(false);
  //error
  const [nameerror, setnameerror] = useState(false);
  const [startdateerror, setstartdateerror] = useState(false);
  const [enddateerror, setenddateerror] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [localArray, setLocalArray] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addinLocalArray = () => {
    let flag = false;
    let scroll = false;
    if (!blankValidator(name)) {
      setnameerror(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("name").focus();
      }
      scroll = true;
    }
    if (!blankValidator(startDate)) {
      setstartdateerror(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("st_date").focus();
      }
      scroll = true;
    }
    if (!blankValidator(endDate)) {
      setstartdateerror(true);
      flag = true;
      if (scroll === false) {
        document.getElementById("end_date").focus();
      }
      scroll = true;
    }
    if (flag) {
      return;
    }

    localArray.push({
      name: name,
      start_datetime: startDate,
      end_datetime: endDate,
    });

    setLocalArray([...localArray]);

    setName("");
    setStartDate("");
    setendDate("");
  };
  const submit = () => {
    if (!blankValidator(localArray) || localArray.length === 0) {
      showNotificationMsz("Please first add holiday to submit", "info");
      return;
    }
    try {
      setisloading(true);
      add_holiday_data({
        variables: {
          institution_id,
          holiday: localArray,
          academic_session_id,
        },
      })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.addSchoolCalendar.message,
            "success"
          );

          props.navigate(-1);
        })
        .catch((error) => {
          setisloading(false);
          showNotificationMsz("Not Created", "danger");
          console.log("t and c error", error);
        });
    } catch (error) {
      setisloading(false);
      showNotificationMsz("Not Created", "danger");
      console.log("t and c error", error);
    }
  };

  return (
    <div className="main_full_width">
      <div className="margin_from_both_contact">
        <Grid className="grid_main_div">
          <Grid item md={12}>
            <Holiday name={props.data.institution_name} />
            <div className="enquirydetails_background mb-2">
              <div className="enquiry_heading">
                <div className="mt-3 ml-3 Heading_bold">Create Holiday</div>
              </div>
              {/* -----------------form section------------ */}
              <Card>
                <div className="card_admissiondetails_height">
                  <div className="textfiled_margin">
                    <Grid className="grid_main_div">
                      <Grid item md={4}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1">Name</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              autoComplete="off"
                              value={name}
                              onChange={(e) => {
                                setnameerror(false);
                                setName(e.target.value);
                              }}
                            />
                            {nameerror && (
                              <span className="text-danger">
                                Enter Event Name
                              </span>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1">Start Date</label>
                          <div>
                            <input
                              type="date"
                              className="form-control"
                              id="st_date"
                              autoComplete="off"
                              // min={currentDayForMinMAx()}
                              value={startDate}
                              onChange={(e) => {
                                setstartdateerror(false);
                                setStartDate(e.target.value);
                              }}
                            />
                            {startdateerror && (
                              <span className="text-danger">Enter Date</span>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1">End Date</label>
                          <div>
                            <input
                              type="date"
                              className="form-control"
                              id="end_date"
                              autoComplete="off"
                              min={startDate}
                              value={endDate}
                              onChange={(e) => {
                                setenddateerror(false);
                                setendDate(e.target.value);
                              }}
                            />
                            {enddateerror && (
                              <span className="text-danger">Enter Date</span>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2}>
                        <div className="mt-2 mr-1">
                          <label for="exampleInputEmail1"></label>
                          <div className=" mt-3 submit_button_right_set">
                            <Button
                              variant="contained"
                              className=" "
                              onClick={addinLocalArray}
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <div className=" table_foramtitng mt-2">
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell
                                align="center"
                                className="table_header"
                              >
                                Name
                              </StyledTableCell>

                              <StyledTableCell
                                align="center"
                                className="table_header"
                              >
                                Start Date
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="table_header"
                              >
                                End Date
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="table_header"
                              >
                                Action
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(rowsPerPage > 0
                              ? localArray.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : localArray
                            ).map((row, index) => {
                              return (
                                <StyledTableRow
                                  key={index}
                                  style={{ width: "100%" }}
                                >
                                  <StyledTableCell align="center">
                                    {row.name && row.name}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {dateToDatedashtoDDMMYY(row.start_datetime)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {dateToDatedashtoDDMMYY(row.end_datetime)}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    <span
                                      role="button"
                                      className="text-info pl-1 pr-1"
                                      onClick={() => {
                                        localArray.splice(index, 1);
                                        setLocalArray([...localArray]);
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        <TablePagination
                          true
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={localArray.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChange}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </div>

                    <div className="submit_button_right_set btn_center">
                      <Button
                        variant="contained"
                        className=" "
                        onClick={submit}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
      <Loder loading={isloading} />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default connect(mapStateToProps, null)(HOC1(AddHolidayCalender));
