import React, { useState, useEffect } from "react";
import Loder from "../Loder/Loder.jsx";
import * as Service from "../../services/index";
import { useApolloClient } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  get_Meeting_join_ling,
  newDateToDate,
  changeTimeFormat,
  getHHMMSStoDate,
  getcurrentDate,
  timeStampToTimeConvertor,
  showNotificationMsz,
} from "../../utils/Validation";
export default function OnlineClassTeacher(props) {
  const [isloading, setisloading] = useState(false);
  const [meetingURL, setMeetingURL] = useState("");

  const [openJoinDailog, setopenJoinDailog] = useState(false);
  const [classData, setclassData] = useState([]);
  const temp = new Date();

  const [time, settime] = useState(temp);
  const client = useApolloClient();
  let { first_name, middle_name, last_name } = props.userName;
  let name = `${first_name + "_" + middle_name + "_" + last_name}`;
  useEffect(() => {
    const getStudentList = () => {
      try {
        setisloading(true);
        client
          .query({
            query: Service.GET_EVENT_BY_USERID,
            variables: {
              user_id: +props.user_id,
              user_type: props.userType,
            },
          })
          .then((response) => {
            if (response.data.getEventByUserId) {
              let dataForClass = response?.data?.getEventByUserId;
              let temp = [];
              for (let index = 0; index < dataForClass.length; index++) {
                let item = dataForClass[index];
                let startDate = getcurrentDate(item?.event?.start_date);
                let endDate = getcurrentDate(item?.event?.end_date);
                let currentDateandTime = new Date();
                if (
                  startDate <= currentDateandTime &&
                  endDate >= currentDateandTime
                ) {
                  console.log("item", item);
                  temp.push(item);
                }
              }
              setclassData(temp);
            }

            setisloading(false);
          })
          .catch((e) => {
            console.log("catch error", e);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
        console.log("t and c error", error);
      }
    };
    getStudentList();
  }, [client, props.section_id]);
  const openDailogToJoin = (timetable) => {
    if (timetable?.location?.mode === "Offline") {
      showNotificationMsz("No active Meeting available", "info");
      return;
    }
    let temp = JSON.parse(timetable.location.meeting_info);
    if (temp) {
      let mainurl = get_Meeting_join_ling(
        temp.response.meetingID[0],
        name,
        temp.response.attendeePW[0],
        props.userType,
        temp.response.moderatorPW[0]
      );
      window.open(mainurl);
    //  setMeetingURL(mainurl);
    }

    //setopenJoinDailog(true);
  };

  return (
    <div className="timesession_alignright mr-2">
      {classData.map((item, index) => (
        <div className="text-primary" key={index}>
          {/* <span id="circle"></span> */}
          <div>
            {item.event && (
              <div style={{ fontSize: 15 }}>
                <span style={{ paddingRight: 7 }}>
                  {timeStampToTimeConvertor(item.event?.start_date)} -{" "}
                  {timeStampToTimeConvertor(item.event?.end_date)}
                </span>
                |
                {item.event !== null ? (
                  <span style={{ paddingRight: 7, paddingLeft: 7 }}>
                    {item?.event?.event_name}
                  </span>
                ) : (
                  ""
                )}
                |
                <span
                  style={{ paddingLeft: 7 }}
                  onClick={() => {
                    openDailogToJoin(item.event);
                  }}
                >
                  {item?.event?.location?.mode === "Offline"
                    ? "Offline"
                    : "Join"}
                </span>
              </div>
            )}
          </div>
        </div>
      ))}

      <Loder loading={isloading} />

      <Dialog
        open={openJoinDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setopenJoinDailog(false);
        }}
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setopenJoinDailog(false);
              }}
            ></i>
          </div>
        </div>
        <DialogContent>
          <iframe
            src={meetingURL}
            title="description"
            className="iframe_width"
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
