import React, { useState } from "react";
import "./Events.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";

import { baseUrl } from "../../../services/index.js";
import {
  getFullName,
  CapitalizeFirstFn,
  timeStampToDateandTime,
} from "../../../utils/Validation";

import Holiday from "../../Common/Holiday/Holiday.jsx";

import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";

import ShowEventInviteesTabbar from "./GetList/ShowEventInviteesTabbar";
import OnlineLinkLocation from "./OnlineLinkLocation";
const EventDetails = (props) => {
  console.log("data======", props);
  const parse = require("html-react-parser");

  let data = props.location.state.data;
  const [OpenImageDailog, setOpenImageDailog] = useState(false);
  const [imagepath, setimagepath] = useState("");
  const [imageType, setimageType] = useState("");
  // const [openMeetingDailog, setOpenMeetingDailog] = useState(false);
  // const [displayData, setDisplayData] = useState("");

  const HandleImageDailogBox = (item) => {
    setimagepath(item.path);
    setimageType(item.type);
    setOpenImageDailog(true);
  };

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />
              <div className="enquirydetails_background">
                <div className="enquiry_heading ">
                  <Grid item md={8}>
                    <div className="mt-3 ml-3 Heading_bold">
                      {data.event_name === undefined ||
                      data.event_name === null ? (
                        ""
                      ) : (
                        <span className="text-info ">
                          {" "}
                          Event Details -{CapitalizeFirstFn(data.event_name)}
                        </span>
                      )}
                      {(props.location.state.isNotStarted ||
                        props.location.state.isOngoing) && (
                        <i
                          class="fa fa-pencil openassignemntcolor ml-3"
                          onClick={() =>
                            props.navigate("/editEvent", {
                              state: {
                                data: data,
                              },
                            })
                          }
                        ></i>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={2}>
                    {(props.location.state.isCompleted ||
                      props.location.state.isOngoing) && (
                      <div className="mt-3 ml-3 ">
                        <Button
                          variant="contained"
                          className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_size_invoice"
                          onClick={() => {
                            props.navigate("/eventMarkAttendanceView", {
                              state: {
                                type: "EVENT",
                                data,
                              },
                            });
                          }}
                        >
                          Attendance
                        </Button>
                      </div>
                    )}
                  </Grid>

                  {props.location.state.isOngoing && (
                    <Grid item md={2}>
                      <div className="mt-3 ml-3 mr-2">
                        <Button
                          variant="contained"
                          className="add_new_query_btn button_width button_login_decoration btn_font_size_invoice mr-3"
                          onClick={(e) => {
                            props.navigate("/markEventAttendance", {
                              state: {
                                data,
                              },
                            });
                          }}
                        >
                          Mark Attendance
                        </Button>{" "}
                      </div>
                    </Grid>
                  )}
                </div>
                <div className="card_margin_both_side">
                  <Card>
                    <div className="card_admissiondetails_height">
                      <div className="textfiled_margin">
                        <Grid className="grid_main_div">
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Start Time
                              </div>
                              {data.start_date === undefined ||
                              data.start_date === null ? (
                                ""
                              ) : (
                                <span>
                                  {timeStampToDateandTime(data.start_date)}
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">End Time</div>

                              {data.end_date === undefined ||
                              data.end_date === null ? (
                                ""
                              ) : (
                                <span>
                                  {timeStampToDateandTime(data.end_date)}
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">Location</div>
                              <OnlineLinkLocation
                                data={data}
                                userType={props.data.usertype}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={4}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Organizer
                              </div>
                              {getFullName(data.organizer_name)}
                            </div>
                          </Grid>
                          <Grid item md={4}></Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Event Content
                              </div>
                              <div>{parse.default(data.content)}</div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid className="grid_main_div mt-3 mb-3">
                          <Grid item md={12}>
                            <div className="mt-2">
                              <div className="text_filed_heading">
                                Attachments
                              </div>
                              <label className="mt-2">Attachment Lists</label>
                              <div className=" mb-2 attachList">
                                {data.documents.map((item, index) => (
                                  <div
                                    className="file_back_color ml-2 d-flex"
                                    key={index}
                                  >
                                    <span
                                      onClick={() => HandleImageDailogBox(item)}
                                      className="pl-2"
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="grid_main_div mt-3">
                          <Grid item md={12}>
                            <ShowEventInviteesTabbar
                              {...data}
                            ></ShowEventInviteesTabbar>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={OpenImageDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="fullWidth"
        onClose={() => {
          setOpenImageDailog(false);
        }}
        className="dailog_formatting"
      >
        <div>
          {/* <div className="dailog_heading1 pl-2">Confirmation</div> */}
          <div className="cut_dailog_icon float-right">
            <a
              href={baseUrl + "/" + imagepath}
              download
              target="_blank"
              className="mr-3"
              rel="noreferrer"
            >
              <i class="fa fa-download"></i>
            </a>
            <i
              className="fa fa-times mr-3 cursor"
              aria-hidden="true"
              onClick={() => {
                setOpenImageDailog(false);
              }}
            ></i>
          </div>
        </div>
        <div className="p-3">
          {imageType === ".png" ||
          imageType === ".PNG" ||
          imageType === ".jpg" ||
          imageType === ".JPG" ||
          imageType === ".jpeg" ||
          imageType === ".JPEG" ? (
            <span>
              <img
                src={baseUrl + "/" + imagepath}
                className="UploadDocImagewidth"
                alt="No_profile"
              />
            </span>
          ) : (
            <span className="text-center">
              There is no image please click download icon to download the file
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(HOC1(EventDetails));
